var map = {"type":"FeatureCollection", "features": [
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.926791,29.7016],[-90.927177,29.715915],[-91.006743,29.714771],[-91.012432,29.708039],[-91.082602,29.626368],[-90.919192,29.557855],[-90.848355,29.631125],[-90.926393,29.670814],[-90.952184,29.671501],[-90.967281,29.693675],[-90.926791,29.7016]]]},"properties":{"id":"70356","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.343633,30.577145],[-92.540022,30.567502],[-92.529192,30.387996],[-92.408857,30.363447],[-92.363929,30.392585],[-92.347151,30.409634],[-92.279714,30.538767],[-92.307154,30.544859],[-92.333482,30.546347],[-92.330305,30.566393],[-92.343633,30.577145]]]},"properties":{"id":"70535","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.529192,30.387996],[-92.540022,30.567502],[-92.585229,30.559228],[-92.619507,30.524975],[-92.60406,30.400042],[-92.625161,30.353776],[-92.529192,30.387996]]]},"properties":{"id":"70515","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.97403,31.159074],[-93.206697,31.067763],[-93.218427,31.029063],[-93.17118,30.951108],[-92.978234,30.861198],[-92.976699,30.825259],[-92.918073,30.757749],[-92.896426,30.712187],[-92.836549,30.653171],[-92.819034,30.817892],[-92.720971,30.891893],[-92.707607,30.921246],[-92.759294,31.036555],[-92.97403,31.159074]]]},"properties":{"id":"70656","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.953765,30.073305],[-90.917871,30.106385],[-90.915565,30.113285],[-90.986121,30.112576],[-91.059273,30.213113],[-91.105951,30.062478],[-90.963693,30.06645],[-90.953765,30.073305]]]},"properties":{"id":"70346","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.953649,29.852922],[-91.061156,29.769344],[-91.012432,29.708039],[-91.006743,29.714771],[-90.953649,29.852922]]]},"properties":{"id":"70372","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.018601,30.909263],[-92.002451,30.874255],[-91.980599,30.794892],[-91.944136,30.816262],[-91.943846,30.81634],[-91.948327,30.848841],[-91.915766,30.952772],[-91.958363,30.99515],[-91.966643,31.000144],[-92.018601,30.909263]]]},"properties":{"id":"71362","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.920076,31.019049],[-91.958363,30.99515],[-91.915766,30.952772],[-91.920076,31.019049]]]},"properties":{"id":"71339","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.956566,32.474068],[-93.002742,32.584095],[-93.147784,32.584885],[-93.173917,32.565232],[-93.156939,32.387754],[-93.091654,32.36877],[-92.956566,32.474068]]]},"properties":{"id":"71028","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.464974,32.534554],[-93.445317,32.644006],[-93.455263,32.761131],[-93.627598,32.585163],[-93.619898,32.541717],[-93.609463,32.531097],[-93.532773,32.530499],[-93.531255,32.468161],[-93.601358,32.460737],[-93.551557,32.427068],[-93.441212,32.41154],[-93.464974,32.534554]]]},"properties":{"id":"71037","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.685085,32.442511],[-93.702576,32.495252],[-93.735965,32.467053],[-93.7251,32.410885],[-93.685085,32.442511]]]},"properties":{"id":"71105","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.043155,32.697436],[-94.043258,32.516199],[-94.031901,32.518797],[-93.89209,32.668626],[-93.92907,32.770294],[-93.972585,32.705697],[-94.043155,32.697436]]]},"properties":{"id":"71060","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.043127,32.937721],[-93.924431,32.899079],[-93.924583,32.916595],[-93.9746,33.019475],[-94.043127,32.937721]]]},"properties":{"id":"71069","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.830537,32.419244],[-93.844688,32.391928],[-93.778024,32.361665],[-93.771353,32.420648],[-93.830349,32.419425],[-93.830537,32.419244]]]},"properties":{"id":"71118","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.686834,32.316747],[-93.554768,32.192312],[-93.444481,32.072423],[-93.4557,32.235957],[-93.624473,32.361664],[-93.685085,32.442511],[-93.7251,32.410885],[-93.686834,32.316747]]]},"properties":{"id":"71115","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.893772,30.156321],[-93.128674,30.103659],[-93.166058,29.942821],[-93.079525,29.933756],[-92.956991,29.928109],[-92.802853,29.976214],[-92.949854,30.08223],[-92.909418,30.120666],[-92.907195,30.129934],[-92.893772,30.156321]]]},"properties":{"id":"70630","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.7674,33.0149],[-92.76736,32.99886],[-92.746541,32.99634],[-92.690184,32.999747],[-92.685117,33.014277],[-92.7674,33.0149]]]},"properties":{"id":"71749","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.554768,32.192312],[-93.614227,32.171829],[-93.893121,31.961556],[-93.902423,31.89667],[-93.848703,31.844391],[-93.659102,31.874602],[-93.441766,31.989358],[-93.444481,32.072423],[-93.554768,32.192312]]]},"properties":{"id":"71052","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.157599,30.558874],[-91.149422,30.510262],[-91.13545,30.506304],[-91.088354,30.498894],[-91.056227,30.501569],[-91.093657,30.530899],[-91.157599,30.558874]]]},"properties":{"id":"70811","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.088354,30.498894],[-91.13545,30.506304],[-91.113693,30.480672],[-91.088354,30.498894]]]},"properties":{"id":"70812","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.297658,30.649548],[-91.195808,30.718684],[-91.174495,30.761289],[-91.124485,30.904781],[-91.159856,30.947794],[-91.18797,30.935608],[-91.310061,30.653961],[-91.297658,30.649548]]]},"properties":{"id":"70748","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.161022,32.8052],[-91.265018,33.005083],[-91.364203,32.729107],[-91.405079,32.618862],[-91.366668,32.537837],[-91.339253,32.538202],[-91.306696,32.537861],[-91.290512,32.568514],[-91.161022,32.8052]]]},"properties":{"id":"71254","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.153689,32.627963],[-91.290512,32.568514],[-91.306696,32.537861],[-91.069792,32.562803],[-91.153689,32.627963]]]},"properties":{"id":"71276","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.330305,30.566393],[-92.307154,30.544859],[-92.279714,30.538767],[-92.25412,30.517916],[-92.193956,30.659333],[-92.172414,30.762461],[-92.211343,30.793131],[-92.357379,30.94102],[-92.410831,30.891432],[-92.40722,30.891421],[-92.402845,30.867576],[-92.413517,30.874362],[-92.411027,30.888852],[-92.431149,30.97094],[-92.479135,30.952391],[-92.502091,30.899494],[-92.446953,30.71481],[-92.343633,30.577145],[-92.330305,30.566393]],[[-92.425082,30.7868],[-92.415449,30.78084],[-92.415445,30.771545],[-92.427192,30.770025],[-92.425082,30.7868]]]},"properties":{"id":"70586","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.740581,32.012014],[-91.6475,31.970428],[-91.597117,31.884713],[-91.575721,31.88256],[-91.501973,32.048766],[-91.489954,32.089188],[-91.740581,32.012014]]]},"properties":{"id":"71336","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.879742,31.810272],[-92.758038,31.655035],[-92.663163,31.633772],[-92.67218,31.709498],[-92.879742,31.810272]]]},"properties":{"id":"71404","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.715218,30.090454],[-91.649776,30.080676],[-91.657643,30.106272],[-91.715218,30.090454]]]},"properties":{"id":"70552","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.228712,30.008469],[-90.237625,29.975555],[-90.205909,29.973478],[-90.203636,29.973321],[-90.193069,29.9995],[-90.202751,30.029827],[-90.220715,30.035567],[-90.228712,30.008469]]]},"properties":{"id":"70003","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.136589,29.886757],[-90.127374,29.911005],[-90.140074,29.947904],[-90.169303,29.94419],[-90.240501,29.965255],[-90.279533,29.967971],[-90.278358,29.962472],[-90.275527,29.940189],[-90.241615,29.868602],[-90.136589,29.886757]]]},"properties":{"id":"70094","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.757675,30.320454],[-92.739163,30.187122],[-92.705648,30.211447],[-92.757675,30.320454]]]},"properties":{"id":"70581","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.911383,30.246732],[-92.929038,30.238078],[-92.913573,30.216822],[-92.911383,30.246732]]]},"properties":{"id":"70650","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.116008,30.327987],[-92.142184,30.298817],[-92.101837,30.29085],[-91.972576,30.293312],[-91.964352,30.36142],[-92.041512,30.375624],[-92.116008,30.327987]]]},"properties":{"id":"70520","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.305002,31.587146],[-92.142162,31.715349],[-92.264902,31.789924],[-92.344955,31.754846],[-92.373087,31.702678],[-92.305002,31.587146]]]},"properties":{"id":"71371","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.73157,30.52366],[-90.64006,30.40938],[-90.573425,30.44929],[-90.596393,30.476294],[-90.631226,30.569511],[-90.634603,30.649827],[-90.727996,30.710532],[-90.756598,30.649963],[-90.73157,30.52366]]]},"properties":{"id":"70744","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.631226,30.569511],[-90.596393,30.476294],[-90.567016,30.50433],[-90.5669,30.52613],[-90.576398,30.577335],[-90.631226,30.569511]]]},"properties":{"id":"70711","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.73157,30.52366],[-90.846928,30.439726],[-90.809931,30.35639],[-90.738279,30.2865],[-90.677291,30.320916],[-90.64006,30.40938],[-90.73157,30.52366]]]},"properties":{"id":"70754","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.041844,31.596787],[-93.253597,31.53793],[-93.236104,31.364507],[-92.991988,31.360473],[-93.05655,31.509515],[-93.041844,31.596787]]]},"properties":{"id":"71468","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.438357,31.903294],[-93.598861,31.845615],[-93.597234,31.765837],[-93.522358,31.749608],[-93.492215,31.748803],[-93.426881,31.889579],[-93.438357,31.903294]]]},"properties":{"id":"71065","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.021879,31.831307],[-93.040551,31.829863],[-93.032571,31.823253],[-93.021879,31.831307]]]},"properties":{"id":"71414","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.058547,29.945334],[-90.041871,29.929013],[-90.018206,29.906736],[-90.013871,29.947003],[-90.052331,29.958381],[-90.058641,29.953149],[-90.058547,29.945334]]]},"properties":{"id":"70114","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.08663,29.931845],[-90.072572,29.919004],[-90.071672,29.919304],[-90.058547,29.945334],[-90.058641,29.953149],[-90.065658,29.95859],[-90.071472,29.950903],[-90.070172,29.950603],[-90.070539,29.949666],[-90.071815,29.949934],[-90.08663,29.931845]]]},"properties":{"id":"70130","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.112045,29.942749],[-90.107523,29.9679],[-90.113699,29.981151],[-90.124775,29.975791],[-90.125755,29.972332],[-90.1277,29.968282],[-90.140074,29.947904],[-90.127374,29.911005],[-90.121963,29.909899],[-90.112045,29.942749]]]},"properties":{"id":"70118","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.086291,29.992683],[-90.08247,30.031538],[-90.120691,30.025169],[-90.124775,29.975791],[-90.113699,29.981151],[-90.086291,29.992683]]]},"properties":{"id":"70124","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.129299,32.555996],[-92.08311,32.4992],[-91.937428,32.484355],[-91.913057,32.505902],[-91.904832,32.528621],[-91.952449,32.671798],[-91.967818,32.688277],[-92.137747,32.675465],[-92.138119,32.66077],[-92.129299,32.555996]],[[-92.06926,32.52966],[-92.069733,32.527835],[-92.071535,32.529519],[-92.06926,32.52966]]]},"properties":{"id":"71203","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.660449,30.686598],[-91.55477,30.632866],[-91.531009,30.654621],[-91.534111,30.734255],[-91.581276,30.74139],[-91.660449,30.686598]]]},"properties":{"id":"70759","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.342317,30.677331],[-91.471168,30.625188],[-91.481767,30.625188],[-91.423597,30.604018],[-91.387544,30.60316],[-91.355557,30.626778],[-91.316954,30.637529],[-91.33087,30.658677],[-91.342317,30.677331]]]},"properties":{"id":"70749","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.592928,30.570594],[-91.6536,30.594096],[-91.658371,30.556802],[-91.599668,30.520789],[-91.566666,30.54183],[-91.592928,30.570594]]]},"properties":{"id":"70756","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.726451,30.864942],[-91.625116,30.840244],[-91.653498,30.915795],[-91.726451,30.864942]]]},"properties":{"id":"70747","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.759294,31.036555],[-92.721413,31.108878],[-92.727235,31.173174],[-92.742153,31.188211],[-92.829148,31.194801],[-92.97403,31.159074],[-92.759294,31.036555]]]},"properties":{"id":"71438","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.774691,31.232685],[-92.742153,31.188211],[-92.727235,31.173174],[-92.734203,31.225702],[-92.774691,31.232685]]]},"properties":{"id":"71466","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.326166,31.364186],[-93.434577,31.290127],[-93.327279,31.350697],[-93.326166,31.364186]]]},"properties":{"id":"71439","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.899323,29.876617],[-89.777168,29.85587],[-89.72586,29.785858],[-89.622583,29.838376],[-89.875363,29.880627],[-89.899323,29.876617]]]},"properties":{"id":"70085","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.953765,30.073305],[-90.79795,29.959203],[-90.812582,29.979702],[-90.917871,30.106385],[-90.953765,30.073305]]]},"properties":{"id":"70086","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.753935,30.01819],[-90.784584,30.044006],[-90.787505,30.012483],[-90.753935,30.01819]]]},"properties":{"id":"70743","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.970835,30.725686],[-91.970665,30.72992],[-91.977875,30.731789],[-91.98091,30.72912],[-91.970835,30.725686]]]},"properties":{"id":"71345","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.520785,29.885718],[-91.571557,29.949098],[-91.544683,29.864249],[-91.526774,29.85957],[-91.520785,29.885718]]]},"properties":{"id":"70523","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.041347,30.440255],[-90.124568,30.418609],[-90.162483,30.37099],[-90.120857,30.361286],[-90.003141,30.392517],[-89.975472,30.421582],[-90.041347,30.440255]]]},"properties":{"id":"70471","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.338668,30.480498],[-90.540159,30.436544],[-90.473872,30.343393],[-90.243789,30.402374],[-90.244346,30.479738],[-90.277987,30.505382],[-90.338668,30.480498]]]},"properties":{"id":"70454","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.710191,29.598721],[-90.796528,29.53538],[-90.822068,29.451502],[-90.722566,29.430049],[-90.675655,29.433449],[-90.59397,29.535135],[-90.648818,29.593451],[-90.710191,29.598721]]]},"properties":{"id":"70363","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.330258,32.536749],[-93.353252,32.454123],[-93.28187,32.505648],[-93.249578,32.559056],[-93.330258,32.536749]]]},"properties":{"id":"71073","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.596081,32.754798],[-91.364203,32.729107],[-91.265018,33.005083],[-91.435782,33.006099],[-91.500993,32.88664],[-91.596081,32.754798]],[[-91.317611,32.995897],[-91.304167,32.997022],[-91.29835,32.984489],[-91.317986,32.984417],[-91.317611,32.995897]]]},"properties":{"id":"71263","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.56331,30.236133],[-92.573618,30.216095],[-92.522296,30.193405],[-92.463422,30.197939],[-92.408419,30.235591],[-92.474508,30.259991],[-92.530994,30.270526],[-92.56331,30.236133]]]},"properties":{"id":"70531","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.35961,30.341661],[-92.287393,30.127866],[-92.242518,30.098431],[-92.168694,30.130321],[-92.193096,30.247829],[-92.173101,30.287733],[-92.235173,30.321358],[-92.35961,30.341661]]]},"properties":{"id":"70578","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.978717,30.626973],[-92.974421,30.471715],[-93.014279,30.437858],[-93.037136,30.418182],[-92.929329,30.344066],[-92.920515,30.360154],[-92.907026,30.383452],[-92.797526,30.437612],[-92.721252,30.557736],[-92.868287,30.621639],[-92.893414,30.698543],[-92.978717,30.626973]]]},"properties":{"id":"70648","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.977478,30.254609],[-91.044718,30.251857],[-91.048727,30.241484],[-91.059273,30.213113],[-90.986121,30.112576],[-90.954766,30.157727],[-90.977478,30.254609]]]},"properties":{"id":"70734","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.954766,30.157727],[-90.986121,30.112576],[-90.915565,30.113285],[-90.902381,30.115629],[-90.918861,30.149789],[-90.954766,30.157727]]]},"properties":{"id":"70725","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.029334,29.989423],[-91.05404,29.993308],[-91.065582,29.994626],[-91.064388,30.001822],[-91.138102,29.963713],[-91.109462,29.823945],[-91.061156,29.769344],[-90.953649,29.852922],[-90.885589,29.905353],[-90.959724,30.006507],[-90.999636,30.012088],[-90.992282,29.96885],[-91.029334,29.989423]]]},"properties":{"id":"70390","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.172414,30.762461],[-92.040583,30.785589],[-92.057208,30.84968],[-92.104555,30.961054],[-92.091907,31.019475],[-92.10784,31.019466],[-92.227124,31.074759],[-92.280738,30.965071],[-92.211343,30.793131],[-92.172414,30.762461]]]},"properties":{"id":"71322","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.920076,31.019049],[-91.796521,30.982842],[-91.750119,31.018814],[-91.723804,31.044254],[-91.709336,31.153846],[-91.99278,31.070446],[-92.015053,31.049632],[-91.966643,31.000144],[-91.958363,30.99515],[-91.920076,31.019049]]]},"properties":{"id":"71355","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.057208,30.84968],[-92.040583,30.785589],[-91.980599,30.794892],[-92.002451,30.874255],[-92.057208,30.84968]]]},"properties":{"id":"71356","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.23273,31.255678],[-92.251792,31.449238],[-92.368108,31.445582],[-92.406105,31.370815],[-92.447482,31.429222],[-92.480625,31.453231],[-92.525961,31.412838],[-92.631944,31.390478],[-92.483235,31.333154],[-92.451786,31.326966],[-92.438278,31.310194],[-92.232265,31.116243],[-92.143135,31.200422],[-92.192468,31.217197],[-92.23273,31.255678]]]},"properties":{"id":"71360","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.624473,32.361664],[-93.4557,32.235957],[-93.42819,32.235897],[-93.372781,32.410153],[-93.433084,32.410419],[-93.435976,32.410378],[-93.441212,32.41154],[-93.551557,32.427068],[-93.624473,32.361664]]]},"properties":{"id":"71051","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.330258,32.536749],[-93.446147,32.535523],[-93.464974,32.534554],[-93.441212,32.41154],[-93.435976,32.410378],[-93.433084,32.410419],[-93.372781,32.410153],[-93.364369,32.410095],[-93.353252,32.454123],[-93.330258,32.536749]]]},"properties":{"id":"71023","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.601358,32.460737],[-93.609463,32.531097],[-93.619898,32.541717],[-93.724807,32.508766],[-93.707662,32.500267],[-93.702576,32.495252],[-93.685085,32.442511],[-93.624473,32.361664],[-93.551557,32.427068],[-93.601358,32.460737]]]},"properties":{"id":"71112","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.89209,32.668626],[-94.031901,32.518797],[-93.813368,32.511187],[-93.79838,32.512831],[-93.78028,32.510916],[-93.744233,32.51967],[-93.779278,32.672796],[-93.826,32.71793],[-93.89209,32.668626]]]},"properties":{"id":"71107","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.771353,32.420648],[-93.778024,32.361665],[-93.763631,32.344738],[-93.75151,32.339821],[-93.686834,32.316747],[-93.7251,32.410885],[-93.735965,32.467053],[-93.755724,32.46922],[-93.771353,32.420648]]]},"properties":{"id":"71106","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.755343,32.496951],[-93.707662,32.500267],[-93.724807,32.508766],[-93.744233,32.51967],[-93.78028,32.510916],[-93.755343,32.496951]]]},"properties":{"id":"71101","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.837433,32.894616],[-93.924583,32.916595],[-93.924431,32.899079],[-93.869468,32.817777],[-93.837433,32.894616]]]},"properties":{"id":"71043","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.872953,32.804249],[-93.92907,32.770294],[-93.89209,32.668626],[-93.826,32.71793],[-93.783571,32.786091],[-93.805075,32.805021],[-93.870985,32.815206],[-93.872953,32.804249]]]},"properties":{"id":"71004","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.282246,29.915776],[-93.300963,30.051953],[-93.312527,30.052102],[-93.530545,30.051755],[-93.282246,29.915776]]]},"properties":{"id":"70645","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.238607,33.017992],[-93.237934,32.856526],[-93.237808,32.825465],[-92.897199,32.881828],[-92.849031,32.986876],[-92.8567,33.01626],[-93.238607,33.017992]]]},"properties":{"id":"71038","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.769042,31.325481],[-91.680219,31.190222],[-91.709336,31.153846],[-91.723804,31.044254],[-91.658321,30.994365],[-91.662506,30.970362],[-91.502253,31.595396],[-91.586357,31.516973],[-91.642264,31.48122],[-91.769042,31.325481]]]},"properties":{"id":"71373","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.438357,31.903294],[-93.441766,31.989358],[-93.659102,31.874602],[-93.598861,31.845615],[-93.438357,31.903294]]]},"properties":{"id":"71063","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.7176,32.226563],[-93.851613,32.214968],[-93.853696,32.158602],[-93.750516,32.168356],[-93.7176,32.226563]]]},"properties":{"id":"71030","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.639192,31.709418],[-93.597234,31.765837],[-93.598861,31.845615],[-93.659102,31.874602],[-93.848703,31.844391],[-93.774269,31.698695],[-93.639192,31.709418]]]},"properties":{"id":"71419","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.989957,30.464711],[-91.027189,30.452283],[-91.080784,30.431644],[-91.047314,30.391188],[-90.969401,30.405138],[-90.989957,30.464711]]]},"properties":{"id":"70816","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.032815,30.474452],[-91.027189,30.452283],[-90.989957,30.464711],[-90.974807,30.481763],[-91.032408,30.496341],[-91.032815,30.474452]]]},"properties":{"id":"70819","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.314839,30.579791],[-91.211987,30.580502],[-91.050888,30.576118],[-91.034789,30.595581],[-90.959712,30.670452],[-90.954265,30.685374],[-91.026233,30.713184],[-91.195808,30.718684],[-91.297658,30.649548],[-91.314839,30.579791]]]},"properties":{"id":"70791","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.411027,30.888852],[-92.413517,30.874362],[-92.402845,30.867576],[-92.40722,30.891421],[-92.410831,30.891432],[-92.411027,30.888852]]]},"properties":{"id":"70585","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.538639,31.499463],[-92.525961,31.412838],[-92.480625,31.453231],[-92.48571,31.499054],[-92.538639,31.499463]]],[[[-92.538639,31.499463],[-92.513069,31.548553],[-92.512198,31.701815],[-92.619728,31.761158],[-92.67218,31.709498],[-92.663163,31.633772],[-92.538639,31.499463]]]]},"properties":{"id":"71423","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.344955,31.754846],[-92.384835,31.796668],[-92.619728,31.761158],[-92.512198,31.701815],[-92.373087,31.702678],[-92.344955,31.754846]]]},"properties":{"id":"71432","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.512139,32.395919],[-92.537393,32.641793],[-92.724701,32.631278],[-92.72003,32.542364],[-92.726985,32.510032],[-92.756997,32.532419],[-92.805807,32.454011],[-92.570505,32.384363],[-92.525337,32.383589],[-92.512139,32.395919]],[[-92.651383,32.525154],[-92.652094,32.529201],[-92.645051,32.528529],[-92.644321,32.523974],[-92.651383,32.525154]]]},"properties":{"id":"71270","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.145121,30.02194],[-90.145773,29.997147],[-90.125755,29.972332],[-90.124775,29.975791],[-90.120691,30.025169],[-90.145121,30.02194]]]},"properties":{"id":"70005","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.203636,29.973321],[-90.1277,29.968282],[-90.125755,29.972332],[-90.145773,29.997147],[-90.177185,29.998464],[-90.193069,29.9995],[-90.203636,29.973321]]]},"properties":{"id":"70001","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.136589,29.886757],[-90.141052,29.74031],[-90.134284,29.742677],[-90.081359,29.768474],[-90.084205,29.833869],[-90.085573,29.914405],[-90.121963,29.909899],[-90.127374,29.911005],[-90.136589,29.886757]]]},"properties":{"id":"70072","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.920515,30.360154],[-92.894935,30.372402],[-92.907026,30.383452],[-92.920515,30.360154]]]},"properties":{"id":"70640","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.523923,29.698388],[-90.541385,29.67761],[-90.528173,29.681192],[-90.523923,29.698388]]]},"properties":{"id":"70375","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.913057,32.505902],[-91.637587,32.667341],[-91.629371,32.697685],[-91.829598,32.663321],[-91.904832,32.528621],[-91.913057,32.505902]]]},"properties":{"id":"71264","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.57941,32.886234],[-91.727378,32.849867],[-91.814313,32.688574],[-91.829598,32.663321],[-91.629371,32.697685],[-91.596081,32.754798],[-91.500993,32.88664],[-91.57941,32.886234]]]},"properties":{"id":"71261","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.952449,32.671798],[-91.904832,32.528621],[-91.829598,32.663321],[-91.814313,32.688574],[-91.952449,32.671798]]]},"properties":{"id":"71229","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.931995,31.608703],[-92.968275,31.564716],[-92.922438,31.547324],[-92.898957,31.576188],[-92.931995,31.608703]]]},"properties":{"id":"71452","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.94067,29.980245],[-89.947518,30.079988],[-89.952298,30.022586],[-89.997123,29.999313],[-90.021698,29.980719],[-89.99681,29.973186],[-89.996452,29.97304],[-89.945365,29.982401],[-89.94067,29.980245]]]},"properties":{"id":"70129","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.013871,29.947003],[-90.018206,29.906736],[-90.008056,29.895528],[-89.918943,29.868901],[-90.012102,29.94598],[-90.013871,29.947003]]]},"properties":{"id":"70131","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.093841,29.941569],[-90.08663,29.931845],[-90.071815,29.949934],[-90.074931,29.950455],[-90.075325,29.949327],[-90.07598,29.949477],[-90.075562,29.950808],[-90.086345,29.954339],[-90.089313,29.954342],[-90.093841,29.941569]]]},"properties":{"id":"70113","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.266,32.503109],[-92.117099,32.495845],[-92.129299,32.555996],[-92.138119,32.66077],[-92.219179,32.611476],[-92.26592,32.583452],[-92.266,32.503109]]]},"properties":{"id":"71291","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.437477,30.542731],[-91.414924,30.535166],[-91.388826,30.569012],[-91.439861,30.543551],[-91.437477,30.542731]]],[[[-91.364958,30.568992],[-91.311143,30.575237],[-91.314839,30.579791],[-91.297658,30.649548],[-91.310061,30.653961],[-91.33087,30.658677],[-91.316954,30.637529],[-91.34212,30.61013],[-91.365088,30.58805],[-91.364958,30.568992]]]]},"properties":{"id":"70729","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.726451,30.864942],[-91.801676,30.914685],[-91.815258,30.848107],[-91.749501,30.546472],[-91.658371,30.556802],[-91.6536,30.594096],[-91.660449,30.686598],[-91.581276,30.74139],[-91.515476,30.872403],[-91.590969,30.917592],[-91.625116,30.840244],[-91.726451,30.864942]]]},"properties":{"id":"70715","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.606669,31.245228],[-92.483235,31.333154],[-92.631944,31.390478],[-92.681666,31.396009],[-92.814182,31.24884],[-92.774691,31.232685],[-92.734203,31.225702],[-92.606669,31.245228]]]},"properties":{"id":"71409","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.721413,31.108878],[-92.759294,31.036555],[-92.707607,30.921246],[-92.502091,30.899494],[-92.479135,30.952391],[-92.627363,31.098155],[-92.613872,31.143734],[-92.721413,31.108878]]]},"properties":{"id":"71433","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.459012,31.181259],[-92.251532,31.091273],[-92.231304,31.108351],[-92.23152,31.110085],[-92.232265,31.116243],[-92.438278,31.310194],[-92.494298,31.223651],[-92.459012,31.181259]]]},"properties":{"id":"71302","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.859882,32.486393],[-91.855025,32.485678],[-91.854672,32.488614],[-91.858372,32.489007],[-91.859882,32.486393]]]},"properties":{"id":"71279","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.492215,31.748803],[-93.522358,31.749608],[-93.494676,31.69431],[-93.48072,31.70613],[-93.492215,31.748803]]]},"properties":{"id":"71406","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.392272,30.004507],[-90.398483,29.986959],[-90.344299,29.937725],[-90.341789,29.981658],[-90.392272,30.004507]]]},"properties":{"id":"70047","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.614212,30.095884],[-90.666191,30.073856],[-90.627311,30.075627],[-90.637184,30.050432],[-90.65137,30.054285],[-90.595031,30.050891],[-90.614212,30.095884]]]},"properties":{"id":"70051","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.05654,30.423496],[-92.150858,30.422338],[-92.116008,30.327987],[-92.041512,30.375624],[-92.038705,30.412321],[-92.05654,30.423496]]]},"properties":{"id":"70584","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.244346,30.479738],[-90.124568,30.418609],[-90.041347,30.440255],[-90.053948,30.482708],[-90.277987,30.505382],[-90.244346,30.479738]]]},"properties":{"id":"70433","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.796377,30.209823],[-89.707647,30.326578],[-89.737537,30.323276],[-89.796377,30.209823]]]},"properties":{"id":"70461","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.913179,30.662122],[-89.903401,30.65241],[-89.897499,30.665075],[-89.913179,30.662122]]]},"properties":{"id":"70463","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.53292,30.824794],[-90.532813,30.78107],[-90.51004,30.783248],[-90.510696,30.818758],[-90.53292,30.824794]]]},"properties":{"id":"70436","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.327279,31.350697],[-93.519717,31.064908],[-93.509215,31.032982],[-93.432924,30.981493],[-93.299602,30.926388],[-93.268382,30.926791],[-93.17118,30.951108],[-93.218427,31.029063],[-93.235316,31.116474],[-93.186436,31.116026],[-93.206697,31.067763],[-92.97403,31.159074],[-92.829148,31.194801],[-92.829358,31.240748],[-92.90544,31.324642],[-92.991988,31.360473],[-93.236104,31.364507],[-93.326166,31.364186],[-93.327279,31.350697]],[[-93.270704,31.116575],[-93.286463,31.107591],[-93.303445,31.131026],[-93.270704,31.116575]],[[-93.000112,31.248619],[-93.052646,31.322418],[-92.993434,31.315003],[-93.000112,31.248619]]]},"properties":{"id":"71446","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.346748,30.912005],[-90.194392,31.000753],[-90.347255,31.00053],[-90.346748,30.912005]]]},"properties":{"id":"70450","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.288852,30.349014],[-91.222302,30.323293],[-91.243468,30.371332],[-91.288852,30.349014]]]},"properties":{"id":"70710","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.466183,30.953344],[-91.425501,30.959883],[-91.446168,30.983252],[-91.466183,30.953344]]]},"properties":{"id":"70787","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.777207,31.959053],[-92.771002,31.964138],[-92.759968,31.963035],[-92.758759,31.968836],[-92.7736,31.97079],[-92.777207,31.959053]]]},"properties":{"id":"71410","state_code":"22"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.115252,44.126237],[-70.199752,44.167507],[-70.170903,44.020971],[-70.158558,44.005346],[-70.126793,44.06559],[-70.115252,44.126237]]]},"properties":{"id":"04240","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.158558,44.005346],[-70.088815,44.00989],[-70.085917,44.059682],[-70.126793,44.06559],[-70.158558,44.005346]]]},"properties":{"id":"04250","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.284557,44.197625],[-70.212493,44.178572],[-70.178351,44.25876],[-70.187903,44.343168],[-70.265223,44.362913],[-70.314493,44.237247],[-70.325218,44.208399],[-70.284557,44.197625]]]},"properties":{"id":"04282","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.056528,46.775677],[-67.926079,46.778765],[-67.919126,46.865983],[-67.931152,47.048369],[-68.075598,47.083508],[-68.063227,47.022826],[-68.178696,46.92886],[-68.177298,46.837038],[-68.056528,46.775677]]]},"properties":{"id":"04736","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.783302,46.372964],[-67.988443,46.437459],[-68.018126,46.435687],[-68.056987,46.331382],[-67.973962,46.276369],[-67.782065,46.279406],[-67.783302,46.372964]]]},"properties":{"id":"04760","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.7476,46.395433],[-68.436443,46.382121],[-68.436431,46.470228],[-68.7476,46.395433]]]},"properties":{"id":"04764","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.161548,47.155924],[-68.148862,47.142078],[-68.078612,47.111531],[-68.075598,47.083508],[-67.931152,47.048369],[-67.790187,47.05008],[-68.061536,47.255985],[-68.178653,47.200833],[-68.179497,47.180733],[-68.161548,47.155924]]]},"properties":{"id":"04785","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.177298,46.837038],[-68.30019,46.834507],[-68.300531,46.745238],[-68.055301,46.749722],[-68.056528,46.775677],[-68.177298,46.837038]]]},"properties":{"id":"04786","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.302907,47.288761],[-68.408126,47.227538],[-68.416963,47.199518],[-68.161548,47.155924],[-68.179497,47.180733],[-68.29007,47.274084],[-68.302907,47.288761]]]},"properties":{"id":"04772","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.236811,47.447902],[-69.240656,47.43709],[-69.193007,47.449948],[-69.224496,47.459854],[-69.236811,47.447902]]]},"properties":{"id":"04741","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.700311,44.099881],[-70.696726,44.100624],[-70.69881,44.104531],[-70.701213,44.103648],[-70.700311,44.099881]]]},"properties":{"id":"04057","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.575651,43.914838],[-70.516358,43.906271],[-70.448587,44.009428],[-70.480078,44.032078],[-70.544094,44.005647],[-70.575651,43.914838]]]},"properties":{"id":"04015","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.276814,43.661124],[-70.258005,43.645063],[-70.238386,43.658375],[-70.250295,43.674484],[-70.276814,43.661124]]]},"properties":{"id":"04101","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.395012,43.652807],[-70.468462,43.631319],[-70.492009,43.606769],[-70.39009,43.55371],[-70.35005,43.533732],[-70.273928,43.554696],[-70.281078,43.597494],[-70.370545,43.642438],[-70.395012,43.652807]]]},"properties":{"id":"04074","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.116449,43.698494],[-70.104111,43.657131],[-70.065169,43.6737],[-70.116449,43.698494]]]},"properties":{"id":"04019","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.613032,43.919982],[-70.575651,43.914838],[-70.544094,44.005647],[-70.595631,44.032057],[-70.633471,44.012866],[-70.689487,43.954244],[-70.613032,43.919982]]]},"properties":{"id":"04055","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.104111,43.657131],[-70.116449,43.698494],[-70.169036,43.724037],[-70.199186,43.700148],[-70.146466,43.637393],[-70.104111,43.657131]]]},"properties":{"id":"04050","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.375744,44.630838],[-70.304827,44.57344],[-70.280748,44.569619],[-70.152481,44.58931],[-70.207388,44.672182],[-70.314509,44.709478],[-70.351512,44.708845],[-70.375744,44.630838]],[[-70.197044,44.616236],[-70.196735,44.630424],[-70.186721,44.628853],[-70.197044,44.616236]]]},"properties":{"id":"04294","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.307659,44.537876],[-70.238904,44.458942],[-70.199758,44.478262],[-70.12939,44.487217],[-70.152481,44.58931],[-70.280748,44.569619],[-70.286254,44.563126],[-70.297083,44.550352],[-70.307659,44.537876]]]},"properties":{"id":"04239","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.197044,44.616236],[-70.186721,44.628853],[-70.196735,44.630424],[-70.197044,44.616236]]]},"properties":{"id":"04234","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.045281,44.390735],[-68.022688,44.342725],[-68.004916,44.384307],[-68.009036,44.389365],[-68.027317,44.395805],[-68.045281,44.390735]]]},"properties":{"id":"04613","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.767161,44.50496],[-68.625524,44.540824],[-68.595941,44.556937],[-68.624473,44.63871],[-68.651175,44.655769],[-68.767161,44.50496]],[[-68.663549,44.56448],[-68.667496,44.557414],[-68.67762,44.560555],[-68.678086,44.568238],[-68.663549,44.56448]]]},"properties":{"id":"04472","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.523956,44.417584],[-68.516357,44.439117],[-68.541731,44.434289],[-68.523956,44.417584]]]},"properties":{"id":"04629","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.578028,44.344744],[-68.632168,44.270377],[-68.516863,44.192669],[-68.473417,44.249453],[-68.510157,44.298013],[-68.578028,44.344744]]]},"properties":{"id":"04616","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.142469,44.476973],[-68.010718,44.568883],[-68.005185,44.590604],[-68.080962,44.601893],[-68.254745,44.539335],[-68.206792,44.514961],[-68.142469,44.476973]]]},"properties":{"id":"04664","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.327804,44.182015],[-68.36816,44.209664],[-68.391528,44.21149],[-68.382444,44.092879],[-68.327804,44.182015]]]},"properties":{"id":"04635","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.689364,44.331295],[-68.704205,44.302038],[-68.688971,44.293766],[-68.689364,44.331295]]]},"properties":{"id":"04673","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.251921,44.414177],[-68.253107,44.414219],[-68.252842,44.41341],[-68.250927,44.413591],[-68.251921,44.414177]]]},"properties":{"id":"04644","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.832129,44.393894],[-69.827101,44.314034],[-69.778497,44.266583],[-69.7686,44.246569],[-69.723614,44.236788],[-69.665441,44.227576],[-69.650171,44.279625],[-69.6257,44.361802],[-69.676566,44.50047],[-69.724105,44.508191],[-69.76048,44.514135],[-69.832129,44.393894]]]},"properties":{"id":"04330","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.014007,44.423997],[-69.887788,44.403234],[-69.909591,44.512433],[-69.944082,44.537828],[-70.035546,44.508083],[-70.04235,44.485235],[-70.014007,44.423997]]]},"properties":{"id":"04352","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.876186,44.258007],[-69.770232,44.234759],[-69.7686,44.246569],[-69.778497,44.266583],[-69.847701,44.285816],[-69.876186,44.258007]]]},"properties":{"id":"04344","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.128862,43.99697],[-69.109663,44.029968],[-69.133475,44.081814],[-69.133787,44.082264],[-69.184751,44.062508],[-69.197003,44.039343],[-69.15598,44.032982],[-69.128862,43.99697]]]},"properties":{"id":"04858","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.8205,44.130281],[-68.842214,44.187676],[-68.936419,44.137101],[-68.915286,44.110291],[-68.8205,44.130281]]]},"properties":{"id":"04853","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.464814,43.978032],[-69.430346,43.954399],[-69.375467,43.96463],[-69.363996,44.012873],[-69.448521,44.05704],[-69.475732,44.006952],[-69.464814,43.978032]]]},"properties":{"id":"04551","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.566212,44.270936],[-70.524149,44.20021],[-70.441137,44.20058],[-70.431849,44.251263],[-70.456843,44.311992],[-70.566212,44.270936]],[[-70.50298,44.268803],[-70.492476,44.264317],[-70.499672,44.259466],[-70.50298,44.268803]]]},"properties":{"id":"04281","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.351757,44.463316],[-70.276752,44.407534],[-70.238904,44.458942],[-70.307659,44.537876],[-70.313486,44.530901],[-70.351452,44.486033],[-70.351757,44.463316]]]},"properties":{"id":"04221","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.815803,44.20637],[-70.955792,44.220867],[-70.888408,44.109823],[-70.791782,44.180862],[-70.80251,44.192043],[-70.815803,44.20637]]]},"properties":{"id":"04051","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.861636,44.402586],[-70.859569,44.399846],[-70.858014,44.400481],[-70.859968,44.402809],[-70.861636,44.402586]]]},"properties":{"id":"04286","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.893331,44.949518],[-68.920444,44.884225],[-68.983105,44.848342],[-68.958845,44.761509],[-68.781153,44.77829],[-68.698742,44.821466],[-68.696187,44.845266],[-68.760994,44.905378],[-68.828671,44.95837],[-68.893331,44.949518]]]},"properties":{"id":"04401","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.666464,44.904682],[-68.672822,44.900417],[-68.674007,44.896323],[-68.662115,44.899765],[-68.666464,44.904682]]]},"properties":{"id":"04469","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.542761,45.155496],[-68.643758,45.219669],[-68.617016,45.142357],[-68.542761,45.155496]]]},"properties":{"id":"04475","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.800906,45.329148],[-68.776061,45.240317],[-68.700736,45.161114],[-68.630143,45.132417],[-68.635638,45.307537],[-68.658709,45.312146],[-68.701758,45.441344],[-68.782045,45.42778],[-68.800906,45.329148]]]},"properties":{"id":"04448","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.936597,44.948717],[-68.979025,44.938038],[-68.920444,44.884225],[-68.893331,44.949518],[-68.936597,44.948717]]]},"properties":{"id":"04450","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.109443,45.107192],[-69.232021,45.090166],[-69.20852,45.004433],[-69.086231,45.017387],[-69.109443,45.107192]]]},"properties":{"id":"04939","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.779566,45.541502],[-69.729215,45.379179],[-69.601924,45.400562],[-69.624778,45.47669],[-69.714458,45.585571],[-69.717665,45.584646],[-69.779566,45.541502]]]},"properties":{"id":"04442","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.034238,43.975661],[-69.874657,43.95359],[-69.86477,43.975296],[-69.935304,43.991379],[-70.035532,44.00709],[-70.051824,43.990982],[-70.034238,43.975661]]]},"properties":{"id":"04086","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.166651,45.750471],[-70.552855,45.667904],[-70.46008,45.301529],[-70.294846,45.312774],[-70.049356,45.424034],[-70.16001,45.751187],[-70.166651,45.750471]]]},"properties":{"id":"04945","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.05998,44.541611],[-69.146174,44.471555],[-69.160011,44.469835],[-69.065647,44.372954],[-68.955529,44.400482],[-68.97183,44.560241],[-68.987776,44.579689],[-69.05998,44.541611]]]},"properties":{"id":"04915","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.445498,44.463839],[-69.506382,44.34287],[-69.409635,44.327998],[-69.394361,44.342934],[-69.35744,44.429375],[-69.395987,44.457078],[-69.445498,44.463839]]]},"properties":{"id":"04354","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.20425,44.640626],[-69.146174,44.471555],[-69.05998,44.541611],[-69.110522,44.655743],[-69.183732,44.644023],[-69.20425,44.640626]]]},"properties":{"id":"04921","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.394361,44.342934],[-69.346616,44.308337],[-69.268882,44.364653],[-69.35744,44.429375],[-69.394361,44.342934]]]},"properties":{"id":"04949","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.807058,44.785169],[-67.858338,44.62221],[-67.77271,44.628578],[-67.665605,44.645044],[-67.532481,44.719053],[-67.603827,44.795397],[-67.694099,44.848708],[-67.700362,44.870367],[-67.76189,44.889791],[-67.807058,44.785169]]]},"properties":{"id":"04623","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.082348,44.90843],[-67.124428,44.88788],[-67.170325,44.80014],[-67.143466,44.751637],[-67.109018,44.695654],[-66.980766,44.873866],[-67.052269,44.910846],[-67.082348,44.90843]]]},"properties":{"id":"04652","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.532481,44.719053],[-67.665605,44.645044],[-67.639981,44.617451],[-67.542406,44.614334],[-67.532481,44.719053]]]},"properties":{"id":"04648","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.686838,45.409595],[-67.688778,45.299656],[-67.551494,45.322472],[-67.686838,45.409595]]]},"properties":{"id":"04492","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.12267,45.124813],[-67.242666,45.071983],[-67.185849,45.009661],[-67.082894,45.05546],[-67.12267,45.124813]]]},"properties":{"id":"04671","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.519216,45.587568],[-67.42514,45.504202],[-67.431055,45.584114],[-67.519216,45.587568]]]},"properties":{"id":"04491","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.908279,44.552578],[-67.886241,44.384576],[-67.815595,44.438043],[-67.876376,44.611469],[-67.908279,44.552578]]]},"properties":{"id":"04658","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.468462,43.631319],[-70.546605,43.71316],[-70.612074,43.687099],[-70.55827,43.586134],[-70.547651,43.549998],[-70.492009,43.606769],[-70.468462,43.631319]]]},"properties":{"id":"04093","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.651331,43.260175],[-70.57738,43.228137],[-70.584089,43.267044],[-70.651331,43.260175]]]},"properties":{"id":"03907","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.39009,43.55371],[-70.414413,43.502773],[-70.387541,43.506631],[-70.384409,43.496698],[-70.380711,43.495869],[-70.35005,43.533732],[-70.39009,43.55371]]]},"properties":{"id":"04064","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.12939,44.487217],[-70.199758,44.478262],[-70.153201,44.369357],[-70.132595,44.371382],[-70.100491,44.383854],[-70.112071,44.425665],[-70.117044,44.433866],[-70.116257,44.440344],[-70.12939,44.487217]]]},"properties":{"id":"04254","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.205189,45.673207],[-68.13235,45.565923],[-68.015227,45.537238],[-68.034881,45.642163],[-67.91398,45.780641],[-67.912835,45.968242],[-67.933802,45.980611],[-68.021817,45.951679],[-68.034167,45.858861],[-68.205189,45.673207]]]},"properties":{"id":"04497","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.025305,46.506579],[-68.018126,46.435687],[-67.988443,46.437459],[-67.784945,46.466602],[-67.786033,46.511009],[-67.905951,46.508813],[-68.025305,46.506579]]]},"properties":{"id":"04734","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.353447,47.356991],[-68.302907,47.288761],[-68.29007,47.274084],[-68.294451,47.358531],[-68.353447,47.356991]]]},"properties":{"id":"04756","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.788853,46.68945],[-67.921601,46.686521],[-67.920527,46.596886],[-67.908946,46.597127],[-67.788234,46.599908],[-67.788853,46.68945]]]},"properties":{"id":"04740","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.432463,45.667827],[-68.439756,45.784241],[-68.623613,45.861156],[-68.626298,45.860837],[-68.56807,45.669406],[-68.56261,45.59661],[-68.534508,45.55939],[-68.412524,45.570398],[-68.409997,45.572196],[-68.432463,45.667827]]]},"properties":{"id":"04460","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.17138,43.840707],[-70.084329,43.780957],[-70.069814,43.770816],[-70.028425,43.816697],[-70.080839,43.926458],[-70.118523,43.907967],[-70.17138,43.840707]]]},"properties":{"id":"04032","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.219208,43.748655],[-70.169036,43.724037],[-70.15515,43.738269],[-70.195078,43.781512],[-70.219208,43.748655]]]},"properties":{"id":"04110","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.953521,43.76452],[-69.989826,43.763417],[-69.992524,43.748776],[-69.953521,43.76452]]]},"properties":{"id":"04066","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.236856,44.757159],[-70.503655,44.95277],[-70.631953,44.854818],[-70.635645,44.787219],[-70.563799,44.713193],[-70.351512,44.708845],[-70.314509,44.709478],[-70.236856,44.757159]]]},"properties":{"id":"04966","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.398683,45.065633],[-70.521502,45.046527],[-70.503655,44.95277],[-70.236856,44.757159],[-70.229363,44.728055],[-70.171414,44.736104],[-70.136542,44.87643],[-70.149085,44.896102],[-70.398683,45.065633]]]},"properties":{"id":"04983","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.314509,44.709478],[-70.207388,44.672182],[-70.229363,44.728055],[-70.236856,44.757159],[-70.314509,44.709478]]]},"properties":{"id":"04984","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.241985,44.281366],[-68.28404,44.30664],[-68.275941,44.285529],[-68.241985,44.281366]]]},"properties":{"id":"04675","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.821033,44.351779],[-68.828377,44.316549],[-68.798566,44.307792],[-68.778072,44.356163],[-68.821033,44.351779]]]},"properties":{"id":"04642","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.548191,44.129468],[-68.696664,44.184238],[-68.746207,44.126785],[-68.717213,44.115375],[-68.620152,44.112792],[-68.548191,44.129468]]]},"properties":{"id":"04681","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.947191,44.2383],[-69.993792,44.180594],[-70.005471,44.124519],[-69.897783,44.107662],[-69.854983,44.167862],[-69.947191,44.2383]]]},"properties":{"id":"04350","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.723614,44.236788],[-69.7686,44.246569],[-69.770232,44.234759],[-69.723614,44.236788]]]},"properties":{"id":"04346","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.993792,44.180594],[-69.947191,44.2383],[-69.937418,44.267676],[-69.991869,44.267236],[-70.074552,44.27133],[-70.075747,44.206168],[-69.993792,44.180594]]]},"properties":{"id":"04259","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.628128,44.492753],[-69.623893,44.488679],[-69.63029,44.490187],[-69.630226,44.493088],[-69.676566,44.50047],[-69.6257,44.361802],[-69.610365,44.359215],[-69.565619,44.482596],[-69.628128,44.492753]]]},"properties":{"id":"04989","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.165437,44.222669],[-69.054399,44.188771],[-69.02725,44.246175],[-69.121622,44.255644],[-69.165437,44.222669]]]},"properties":{"id":"04843","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.133475,44.081814],[-69.109663,44.029968],[-69.100615,44.093164],[-69.133475,44.081814]]]},"properties":{"id":"04854","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.666298,44.124003],[-69.758208,44.139334],[-69.785071,44.088976],[-69.813549,44.026011],[-69.746329,44.013802],[-69.684662,44.088137],[-69.666298,44.124003]]]},"properties":{"id":"04342","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.602084,43.868375],[-69.664307,43.865653],[-69.639524,43.835696],[-69.622246,43.81733],[-69.600634,43.858745],[-69.602084,43.868375]]]},"properties":{"id":"04538","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.664136,43.928388],[-69.676727,43.922305],[-69.689908,43.875078],[-69.664136,43.928388]]]},"properties":{"id":"04571","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.495696,43.927024],[-69.531746,43.918874],[-69.535739,43.903393],[-69.542629,43.870982],[-69.537939,43.871855],[-69.49114,43.890158],[-69.483376,43.906863],[-69.495696,43.927024]]]},"properties":{"id":"04558","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.519489,44.058419],[-69.540892,44.004321],[-69.475732,44.006952],[-69.448521,44.05704],[-69.45,44.058322],[-69.519489,44.058419]]]},"properties":{"id":"04543","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.58479,43.927675],[-69.535739,43.903393],[-69.531746,43.918874],[-69.547119,43.995248],[-69.562963,43.979775],[-69.584894,43.933107],[-69.58479,43.927675]]]},"properties":{"id":"04573","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.665441,44.227576],[-69.656942,44.125461],[-69.591749,44.122576],[-69.547398,44.260988],[-69.650171,44.279625],[-69.665441,44.227576]]]},"properties":{"id":"04353","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.694244,44.409805],[-70.685633,44.280507],[-70.580761,44.294827],[-70.602902,44.325876],[-70.694244,44.409805]]]},"properties":{"id":"04255","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.804083,44.212497],[-70.815803,44.20637],[-70.80251,44.192043],[-70.804083,44.212497]]]},"properties":{"id":"04267","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.890805,44.007313],[-70.994786,43.96005],[-70.992277,43.886262],[-70.88698,43.888765],[-70.833653,43.910234],[-70.890805,44.007313]]]},"properties":{"id":"04010","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.50298,44.268803],[-70.499672,44.259466],[-70.492476,44.264317],[-70.50298,44.268803]]]},"properties":{"id":"04271","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.330949,44.986901],[-69.305521,44.895988],[-69.183531,44.91077],[-69.20852,45.004433],[-69.330949,44.986901]]]},"properties":{"id":"04928","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.226462,44.815918],[-69.190387,44.820282],[-69.160716,44.824906],[-69.183531,44.91077],[-69.305521,44.895988],[-69.281275,44.808657],[-69.23314,44.815075],[-69.224661,44.820755],[-69.226462,44.815918]]]},"properties":{"id":"04953","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.760994,44.905378],[-68.696187,44.845266],[-68.671859,44.86643],[-68.637624,44.904018],[-68.684654,44.90554],[-68.694303,44.907681],[-68.760994,44.905378]],[[-68.666464,44.904682],[-68.662115,44.899765],[-68.674007,44.896323],[-68.672822,44.900417],[-68.666464,44.904682]]]},"properties":{"id":"04473","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.288819,45.16675],[-68.422001,45.176374],[-68.542761,45.155496],[-68.617016,45.142357],[-68.645142,45.021081],[-68.501095,44.980718],[-68.43536,44.989742],[-68.375892,44.997373],[-68.288819,45.16675]]]},"properties":{"id":"04418","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.23314,44.815075],[-69.281275,44.808657],[-69.286336,44.807988],[-69.266785,44.721928],[-69.2052,44.729586],[-69.148065,44.736939],[-69.190387,44.820282],[-69.226462,44.815918],[-69.23314,44.815075]]]},"properties":{"id":"04969","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.226462,44.815918],[-69.224661,44.820755],[-69.23314,44.815075],[-69.226462,44.815918]]]},"properties":{"id":"04933","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.062387,44.926955],[-69.086231,45.017387],[-69.20852,45.004433],[-69.183531,44.91077],[-69.062387,44.926955]]]},"properties":{"id":"04435","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.684654,44.90554],[-68.688794,44.914634],[-68.694303,44.907681],[-68.684654,44.90554]]]},"properties":{"id":"04489","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-69.575167,45.309592],[-69.424481,45.243227],[-69.488745,45.417386],[-69.536875,45.409968],[-69.575167,45.309592]]],[[[-69.475896,45.429479],[-69.309554,45.373958],[-69.208137,45.366717],[-69.130122,45.378922],[-69.175303,45.444177],[-69.34388,45.565794],[-69.475896,45.429479]]]]},"properties":{"id":"04464","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.065297,45.408413],[-69.130122,45.378922],[-69.208137,45.366717],[-69.163106,45.279971],[-69.048532,45.28268],[-68.89358,45.433606],[-69.118877,45.585993],[-69.34388,45.565794],[-69.175303,45.444177],[-69.065297,45.408413]],[[-69.065297,45.408413],[-69.052563,45.408956],[-69.054717,45.388898],[-69.071626,45.395535],[-69.065297,45.408413]]]},"properties":{"id":"04414","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.788554,43.799384],[-69.773036,43.719036],[-69.700499,43.855307],[-69.743634,43.893256],[-69.756144,43.885248],[-69.788554,43.799384]]]},"properties":{"id":"04548","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.030337,44.82214],[-70.002986,44.708855],[-69.960149,44.681226],[-69.888649,44.769173],[-69.891444,44.812596],[-70.031581,44.856995],[-70.030337,44.82214]]]},"properties":{"id":"04911","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.699164,45.09396],[-69.750369,44.99384],[-69.704733,44.906989],[-69.614967,44.879807],[-69.576371,44.909886],[-69.699164,45.09396]]]},"properties":{"id":"04912","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.607465,44.852858],[-69.603624,44.70462],[-69.483395,44.720271],[-69.521651,44.812088],[-69.607465,44.852858]]]},"properties":{"id":"04924","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-67.624449,45.61649],[-67.754303,45.667575],[-67.699275,45.613505],[-67.624449,45.61649]]],[[[-67.784306,45.594433],[-67.847901,45.583976],[-67.64814,45.483786],[-67.784306,45.594433]]]]},"properties":{"id":"04413","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.77271,44.628578],[-67.858338,44.62221],[-67.876376,44.611469],[-67.815595,44.438043],[-67.766186,44.477796],[-67.77271,44.628578]]]},"properties":{"id":"04643","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.019734,44.952425],[-67.052269,44.910846],[-66.980766,44.873866],[-67.019734,44.952425]]]},"properties":{"id":"04631","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.010718,44.568883],[-67.980726,44.499121],[-67.938206,44.399989],[-67.886241,44.384576],[-67.908279,44.552578],[-68.005185,44.590604],[-68.010718,44.568883]]]},"properties":{"id":"04680","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.77162,43.473443],[-70.64261,43.420422],[-70.607252,43.457464],[-70.569503,43.49628],[-70.634256,43.565954],[-70.647803,43.582511],[-70.695518,43.534565],[-70.798365,43.574659],[-70.782957,43.491712],[-70.77162,43.473443]]]},"properties":{"id":"04002","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.754475,43.197595],[-70.733196,43.148318],[-70.679303,43.119389],[-70.655263,43.099335],[-70.61984,43.129061],[-70.625314,43.149764],[-70.613134,43.159081],[-70.596352,43.182086],[-70.704195,43.228941],[-70.754475,43.197595]],[[-70.627725,43.166446],[-70.632562,43.169173],[-70.628514,43.174061],[-70.620717,43.168987],[-70.627725,43.166446]]]},"properties":{"id":"03909","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.627725,43.166446],[-70.620717,43.168987],[-70.628514,43.174061],[-70.632562,43.169173],[-70.627725,43.166446]]]},"properties":{"id":"03910","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.088397,44.212562],[-70.115252,44.126237],[-70.126793,44.06559],[-70.085917,44.059682],[-70.025228,44.050921],[-70.005471,44.124519],[-69.993792,44.180594],[-70.075747,44.206168],[-70.088397,44.212562]]]},"properties":{"id":"04280","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.116257,44.440344],[-70.117044,44.433866],[-70.112071,44.425665],[-70.116257,44.440344]]]},"properties":{"id":"04228","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.790187,47.05008],[-67.931152,47.048369],[-67.919126,46.865983],[-67.789487,46.872181],[-67.790187,47.05008]]]},"properties":{"id":"04750","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.302907,47.288761],[-68.353447,47.356991],[-68.461664,47.289153],[-68.408126,47.227538],[-68.302907,47.288761]]]},"properties":{"id":"04745","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.826559,47.207128],[-68.875461,47.101882],[-68.818494,47.101945],[-68.826559,47.207128]]]},"properties":{"id":"04774","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.374499,43.955229],[-70.423761,43.870911],[-70.324379,43.819822],[-70.296382,43.848775],[-70.253169,43.893392],[-70.374499,43.955229]]]},"properties":{"id":"04039","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.296382,43.848775],[-70.222913,43.802176],[-70.173907,43.842932],[-70.248617,43.898282],[-70.253169,43.893392],[-70.296382,43.848775]]]},"properties":{"id":"04097","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.173907,43.842932],[-70.222913,43.802176],[-70.195078,43.781512],[-70.15515,43.738269],[-70.084329,43.780957],[-70.17138,43.840707],[-70.173907,43.842932]]]},"properties":{"id":"04096","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.799361,45.016326],[-70.906586,44.833469],[-70.769228,44.732228],[-70.635645,44.787219],[-70.631953,44.854818],[-70.799361,45.016326]]]},"properties":{"id":"04964","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.815793,44.683754],[-68.845722,44.61484],[-68.841821,44.611062],[-68.792453,44.496681],[-68.767161,44.50496],[-68.651175,44.655769],[-68.720777,44.699667],[-68.815793,44.683754]]]},"properties":{"id":"04416","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.807058,44.785169],[-68.165622,44.789802],[-68.13573,44.758494],[-68.127017,44.639038],[-68.080962,44.601893],[-68.005185,44.590604],[-67.908279,44.552578],[-67.876376,44.611469],[-67.858338,44.62221],[-67.807058,44.785169]]]},"properties":{"id":"04622","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-68.249956,44.821247],[-68.186029,44.753462],[-68.182815,44.750664],[-68.13573,44.758494],[-68.165622,44.789802],[-68.249956,44.821247]]],[[[-68.162353,45.169946],[-68.069048,45.183411],[-68.122296,45.221571],[-68.166063,45.192263],[-68.257286,45.201336],[-68.260741,45.16673],[-68.17028,45.117656],[-68.162353,45.169946]]],[[[-68.249956,44.821247],[-68.349291,44.905359],[-68.297399,44.820701],[-68.249956,44.821247]]]]},"properties":{"id":"04408","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.383985,44.315701],[-68.307109,44.299779],[-68.28404,44.30664],[-68.241985,44.281366],[-68.197248,44.289625],[-68.419837,44.385576],[-68.439712,44.384653],[-68.471143,44.362035],[-68.46259,44.324628],[-68.383985,44.315701]]]},"properties":{"id":"04660","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.142469,44.476973],[-68.206792,44.514961],[-68.204799,44.445136],[-68.142469,44.476973]]]},"properties":{"id":"04677","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.847701,44.285816],[-69.778497,44.266583],[-69.827101,44.314034],[-69.847701,44.285816]]]},"properties":{"id":"04347","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.991869,44.267236],[-70.043374,44.310205],[-70.074576,44.298614],[-70.074552,44.27133],[-69.991869,44.267236]]]},"properties":{"id":"04265","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.650171,44.279625],[-69.547398,44.260988],[-69.506382,44.34287],[-69.610365,44.359215],[-69.6257,44.361802],[-69.650171,44.279625]]]},"properties":{"id":"04363","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.185287,44.127745],[-69.133787,44.082264],[-69.133475,44.081814],[-69.100615,44.093164],[-69.080691,44.114657],[-69.160548,44.172591],[-69.185287,44.127745]]]},"properties":{"id":"04841","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.133787,44.082264],[-69.185287,44.127745],[-69.205883,44.06586],[-69.184751,44.062508],[-69.133787,44.082264]]]},"properties":{"id":"04861","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.746329,44.013802],[-69.743634,43.893256],[-69.700499,43.855307],[-69.692076,43.857175],[-69.689941,43.871287],[-69.689908,43.875078],[-69.676727,43.922305],[-69.637826,44.017106],[-69.623643,44.028799],[-69.684662,44.088137],[-69.746329,44.013802]]]},"properties":{"id":"04578","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-69.614609,43.810231],[-69.58753,43.814354],[-69.600634,43.858745],[-69.622246,43.81733],[-69.614609,43.810231]]],[[[-69.664136,43.928388],[-69.689908,43.875078],[-69.689941,43.871287],[-69.664307,43.865653],[-69.602084,43.868375],[-69.583727,43.877155],[-69.58479,43.927675],[-69.584894,43.933107],[-69.664136,43.928388]]]]},"properties":{"id":"04537","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.017317,44.415182],[-71.009459,44.268759],[-70.974338,44.257915],[-70.781307,44.235631],[-70.708954,44.268051],[-70.685633,44.280507],[-70.694244,44.409805],[-70.660973,44.457193],[-70.68644,44.498097],[-70.787844,44.486598],[-71.017317,44.415182]],[[-70.861636,44.402586],[-70.859968,44.402809],[-70.858014,44.400481],[-70.859569,44.399846],[-70.861636,44.402586]]]},"properties":{"id":"04217","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.524149,44.20021],[-70.607383,44.154404],[-70.595631,44.032057],[-70.544094,44.005647],[-70.480078,44.032078],[-70.451157,44.094549],[-70.401809,44.143657],[-70.391735,44.153562],[-70.441137,44.20058],[-70.524149,44.20021]]]},"properties":{"id":"04270","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.550116,44.479134],[-70.523448,44.436213],[-70.421998,44.42503],[-70.351757,44.463316],[-70.351452,44.486033],[-70.460161,44.529474],[-70.482864,44.52983],[-70.550116,44.479134]]]},"properties":{"id":"04290","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.992277,43.886262],[-70.989122,43.792113],[-70.880657,43.810858],[-70.88698,43.888765],[-70.992277,43.886262]]]},"properties":{"id":"04068","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.009459,44.268759],[-70.994786,43.96005],[-70.890805,44.007313],[-70.848077,44.049522],[-70.858546,44.095269],[-70.888408,44.109823],[-70.955792,44.220867],[-70.974338,44.257915],[-71.009459,44.268759]]]},"properties":{"id":"04037","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.482864,44.52983],[-70.59233,44.616014],[-70.655335,44.590802],[-70.705324,44.571477],[-70.732707,44.560905],[-70.70428,44.522464],[-70.68644,44.498097],[-70.660973,44.457193],[-70.550116,44.479134],[-70.482864,44.52983]]]},"properties":{"id":"04276","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.506196,44.371122],[-70.459703,44.316682],[-70.421998,44.42503],[-70.523448,44.436213],[-70.506196,44.371122]]]},"properties":{"id":"04292","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.715418,43.961815],[-70.848077,44.049522],[-70.890805,44.007313],[-70.833653,43.910234],[-70.72978,43.92833],[-70.715418,43.961815]]]},"properties":{"id":"04022","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.109443,45.107192],[-69.086231,45.017387],[-68.958227,45.033836],[-68.983599,45.125841],[-69.109443,45.107192]]]},"properties":{"id":"04422","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.607245,45.632296],[-68.56261,45.59661],[-68.56807,45.669406],[-68.607245,45.632296]]]},"properties":{"id":"04430","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.542761,45.155496],[-68.422001,45.176374],[-68.489248,45.292751],[-68.600305,45.327714],[-68.643758,45.219669],[-68.542761,45.155496]]]},"properties":{"id":"04493","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.958227,45.033836],[-68.936597,44.948717],[-68.893331,44.949518],[-68.828671,44.95837],[-68.830243,45.050438],[-68.958227,45.033836]]]},"properties":{"id":"04449","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.048532,45.28268],[-69.084143,45.213753],[-68.9735,45.127216],[-68.856587,45.142783],[-68.776061,45.240317],[-68.800906,45.329148],[-68.825425,45.420552],[-68.831206,45.44243],[-68.89358,45.433606],[-69.048532,45.28268]]]},"properties":{"id":"04463","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.526763,45.144998],[-69.6828,45.194878],[-69.699164,45.09396],[-69.576371,44.909886],[-69.468081,44.938953],[-69.390659,44.97893],[-69.431031,44.986951],[-69.514883,45.034807],[-69.526763,45.144998]]]},"properties":{"id":"04942","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.425552,45.170188],[-70.31525,45.164313],[-70.293826,45.109903],[-70.149085,44.896102],[-70.136542,44.87643],[-70.03327,44.86454],[-69.952169,44.96759],[-69.982894,45.319293],[-70.222978,45.301155],[-70.428434,45.180877],[-70.425552,45.170188]]]},"properties":{"id":"04961","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.830116,45.251742],[-69.991129,45.252141],[-69.966591,45.156944],[-69.813824,45.176993],[-69.830116,45.251742]]]},"properties":{"id":"04925","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.636265,44.699206],[-69.663236,44.696578],[-69.640817,44.673189],[-69.636265,44.699206]]]},"properties":{"id":"04944","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.291648,44.533908],[-69.203443,44.471355],[-69.160011,44.469835],[-69.146174,44.471555],[-69.20425,44.640626],[-69.285926,44.627118],[-69.291648,44.533908]]]},"properties":{"id":"04986","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.185849,45.009661],[-67.082348,44.90843],[-67.052269,44.910846],[-67.019734,44.952425],[-67.082894,45.05546],[-67.185849,45.009661]]]},"properties":{"id":"04667","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.421434,44.714754],[-67.420937,44.594263],[-67.412408,44.556836],[-67.333684,44.591138],[-67.323328,44.697519],[-67.338194,44.724403],[-67.421434,44.714754]]]},"properties":{"id":"04655","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.109018,44.695654],[-67.143466,44.751637],[-67.323328,44.697519],[-67.333684,44.591138],[-67.109018,44.695654]]]},"properties":{"id":"04626","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.670102,43.391224],[-70.724187,43.334896],[-70.709254,43.302448],[-70.652911,43.260861],[-70.651331,43.260175],[-70.584089,43.267044],[-70.535153,43.335107],[-70.670102,43.391224]]]},"properties":{"id":"04090","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.733196,43.148318],[-70.754475,43.197595],[-70.824741,43.179752],[-70.767788,43.093595],[-70.733196,43.148318]]]},"properties":{"id":"03903","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.64261,43.420422],[-70.77162,43.473443],[-70.844482,43.442989],[-70.813471,43.401587],[-70.724187,43.334896],[-70.670102,43.391224],[-70.64261,43.420422]]]},"properties":{"id":"04073","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.901253,43.580242],[-70.862785,43.470221],[-70.782957,43.491712],[-70.798365,43.574659],[-70.808114,43.614655],[-70.811242,43.62767],[-70.838706,43.625356],[-70.901253,43.580242]]]},"properties":{"id":"04076","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.613134,43.159081],[-70.625314,43.149764],[-70.61984,43.129061],[-70.613134,43.159081]]]},"properties":{"id":"03911","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.229244,43.974064],[-70.203647,43.947928],[-70.118523,43.907967],[-70.080839,43.926458],[-70.034238,43.975661],[-70.051824,43.990982],[-70.088815,44.00989],[-70.158558,44.005346],[-70.170903,44.020971],[-70.229244,43.974064]]]},"properties":{"id":"04222","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.063227,47.022826],[-68.237737,46.956323],[-68.271219,46.926949],[-68.178696,46.92886],[-68.063227,47.022826]]]},"properties":{"id":"04762","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.25577,45.944182],[-68.034167,45.858861],[-68.021817,45.951679],[-68.066208,45.976539],[-68.103242,46.0383],[-68.16731,46.123497],[-68.436937,46.065141],[-68.427598,45.944591],[-68.25577,45.944182]]]},"properties":{"id":"04747","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-68.436431,46.470228],[-68.436443,46.382121],[-68.43661,46.363668],[-68.419765,46.34776],[-68.343794,46.469618],[-68.304901,46.566016],[-68.300531,46.745238],[-68.436431,46.470228]]],[[[-68.819401,46.395782],[-69.145371,46.301576],[-68.820323,46.302933],[-68.819401,46.395782]]]]},"properties":{"id":"04732","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.178653,47.200833],[-68.179048,47.329886],[-68.294451,47.358531],[-68.29007,47.274084],[-68.179497,47.180733],[-68.178653,47.200833]]]},"properties":{"id":"04773","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.426621,45.85338],[-68.32611,45.748292],[-68.25577,45.944182],[-68.427598,45.944591],[-68.426621,45.85338]]]},"properties":{"id":"04776","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.228027,45.515425],[-68.33383,45.68727],[-68.432463,45.667827],[-68.409997,45.572196],[-68.363209,45.495548],[-68.266776,45.509933],[-68.228027,45.515425]]]},"properties":{"id":"04459","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.444938,47.128845],[-68.445275,47.106371],[-68.411457,47.079285],[-68.266166,47.018391],[-68.276866,47.107135],[-68.148862,47.142078],[-68.161548,47.155924],[-68.416963,47.199518],[-68.444938,47.128845]]]},"properties":{"id":"04779","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.03956,46.097614],[-67.90656,46.095262],[-67.903932,46.141129],[-68.040586,46.139082],[-68.039689,46.122825],[-68.03956,46.097614]]]},"properties":{"id":"04761","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.905951,46.508813],[-67.908946,46.597127],[-67.920527,46.596886],[-68.048247,46.594379],[-68.047088,46.568235],[-68.025305,46.506579],[-67.905951,46.508813]]]},"properties":{"id":"04787","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.448587,44.009428],[-70.516358,43.906271],[-70.467939,43.873997],[-70.423761,43.870911],[-70.374499,43.955229],[-70.401954,43.990108],[-70.448587,44.009428]]]},"properties":{"id":"04071","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.207388,44.672182],[-70.152481,44.58931],[-70.12939,44.487217],[-70.04235,44.485235],[-70.035546,44.508083],[-70.040215,44.588722],[-70.065784,44.620099],[-70.077541,44.620179],[-70.09437,44.633512],[-70.090506,44.634652],[-70.079175,44.625059],[-70.066106,44.621411],[-70.002986,44.708855],[-70.030337,44.82214],[-70.171414,44.736104],[-70.229363,44.728055],[-70.207388,44.672182]],[[-70.166119,44.661848],[-70.164789,44.664954],[-70.160991,44.663211],[-70.166119,44.661848]]]},"properties":{"id":"04938","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.805716,45.136221],[-70.503548,45.129572],[-70.424168,45.165065],[-70.425552,45.170188],[-70.428434,45.180877],[-70.46008,45.301529],[-70.552855,45.667904],[-70.835148,45.277072],[-70.805716,45.136221]]]},"properties":{"id":"04936","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.009415,44.449328],[-68.009036,44.389365],[-68.004916,44.384307],[-67.938206,44.399989],[-67.980726,44.499121],[-68.009415,44.449328]]]},"properties":{"id":"04624","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.625524,44.540824],[-68.767161,44.50496],[-68.792453,44.496681],[-68.800964,44.462031],[-68.762794,44.424831],[-68.704658,44.398074],[-68.696672,44.4063],[-68.599452,44.493084],[-68.625524,44.540824]]]},"properties":{"id":"04476","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.439712,44.384653],[-68.595941,44.556937],[-68.625524,44.540824],[-68.599452,44.493084],[-68.471143,44.362035],[-68.439712,44.384653]]]},"properties":{"id":"04684","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.762794,44.424831],[-68.843864,44.354667],[-68.861201,44.296656],[-68.730016,44.31436],[-68.704205,44.302038],[-68.689364,44.331295],[-68.704658,44.398074],[-68.762794,44.424831]],[[-68.821033,44.351779],[-68.778072,44.356163],[-68.798566,44.307792],[-68.828377,44.316549],[-68.821033,44.351779]]]},"properties":{"id":"04617","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.009415,44.449328],[-68.027317,44.395805],[-68.009036,44.389365],[-68.009415,44.449328]]]},"properties":{"id":"04669","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.36816,44.209664],[-68.327804,44.182015],[-68.31415,44.215665],[-68.34039,44.252967],[-68.36816,44.209664]]]},"properties":{"id":"04653","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.515987,44.482619],[-69.514566,44.478696],[-69.510116,44.480769],[-69.515987,44.482619]]]},"properties":{"id":"04926","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.291648,44.533908],[-69.285926,44.627118],[-69.325988,44.653887],[-69.460606,44.647054],[-69.457371,44.616432],[-69.44474,44.584221],[-69.372419,44.544585],[-69.291648,44.533908]]]},"properties":{"id":"04988","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.323039,43.940598],[-69.282268,44.052584],[-69.363996,44.012873],[-69.375467,43.96463],[-69.323039,43.940598]]]},"properties":{"id":"04547","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.282347,43.958303],[-69.301677,43.938335],[-69.176483,43.974872],[-69.15598,44.032982],[-69.197003,44.039343],[-69.282347,43.958303]]]},"properties":{"id":"04860","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.160548,44.172591],[-69.080691,44.114657],[-69.054399,44.188771],[-69.165437,44.222669],[-69.196629,44.199229],[-69.185355,44.190965],[-69.160548,44.172591]]]},"properties":{"id":"04856","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.579016,44.115628],[-69.623643,44.028799],[-69.637826,44.017106],[-69.562963,43.979775],[-69.547119,43.995248],[-69.540892,44.004321],[-69.519489,44.058419],[-69.517831,44.101012],[-69.579016,44.115628]]]},"properties":{"id":"04553","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.432184,44.157702],[-69.517831,44.101012],[-69.519489,44.058419],[-69.45,44.058322],[-69.432184,44.157702]]]},"properties":{"id":"04555","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.49114,43.890158],[-69.481896,43.875668],[-69.45731,43.904156],[-69.483376,43.906863],[-69.49114,43.890158]]]},"properties":{"id":"04541","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.639524,43.835696],[-69.664307,43.865653],[-69.689941,43.871287],[-69.692076,43.857175],[-69.68388,43.853987],[-69.639524,43.835696]]]},"properties":{"id":"04575","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.49114,43.890158],[-69.537939,43.871855],[-69.481896,43.875668],[-69.49114,43.890158]]]},"properties":{"id":"04554","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.937016,44.754712],[-70.732707,44.560905],[-70.705324,44.571477],[-70.695916,44.640581],[-70.769228,44.732228],[-70.906586,44.833469],[-70.937016,44.754712]]]},"properties":{"id":"04216","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.037978,44.760617],[-71.017317,44.415182],[-70.787844,44.486598],[-70.70428,44.522464],[-70.732707,44.560905],[-70.937016,44.754712],[-71.037978,44.760617]]]},"properties":{"id":"04261","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.524149,44.20021],[-70.566212,44.270936],[-70.580761,44.294827],[-70.685633,44.280507],[-70.708954,44.268051],[-70.624329,44.171036],[-70.607383,44.154404],[-70.524149,44.20021]]]},"properties":{"id":"04268","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.72978,43.92833],[-70.833653,43.910234],[-70.88698,43.888765],[-70.880657,43.810858],[-70.852593,43.804735],[-70.782984,43.814147],[-70.754052,43.878964],[-70.72978,43.92833]]]},"properties":{"id":"04041","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.086231,45.017387],[-69.062387,44.926955],[-68.979025,44.938038],[-68.936597,44.948717],[-68.958227,45.033836],[-69.086231,45.017387]]]},"properties":{"id":"04427","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.241319,45.4266],[-68.18069,45.23717],[-67.968434,45.263904],[-67.94323,45.278631],[-68.015227,45.537238],[-68.13235,45.565923],[-68.228027,45.515425],[-68.266776,45.509933],[-68.241319,45.4266]]]},"properties":{"id":"04487","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.781153,44.77829],[-68.958845,44.761509],[-69.081355,44.745752],[-69.060998,44.663946],[-69.014167,44.671794],[-68.837529,44.702125],[-68.787546,44.767229],[-68.781153,44.77829]]]},"properties":{"id":"04444","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.575167,45.309592],[-69.722067,45.35764],[-69.721099,45.354707],[-69.6828,45.194878],[-69.526763,45.144998],[-69.424481,45.243227],[-69.575167,45.309592]]]},"properties":{"id":"04406","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.874657,43.95359],[-69.895953,43.866238],[-69.871322,43.814468],[-69.788554,43.799384],[-69.756144,43.885248],[-69.839393,43.985646],[-69.86477,43.975296],[-69.874657,43.95359]]]},"properties":{"id":"04530","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.305521,44.895988],[-69.330949,44.986901],[-69.390659,44.97893],[-69.468081,44.938953],[-69.440164,44.87885],[-69.305521,44.895988]]]},"properties":{"id":"04971","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.751153,44.901065],[-69.759979,44.784992],[-69.731943,44.69421],[-69.663236,44.696578],[-69.636265,44.699206],[-69.632971,44.698763],[-69.603624,44.70462],[-69.607465,44.852858],[-69.614967,44.879807],[-69.704733,44.906989],[-69.751153,44.901065]]]},"properties":{"id":"04976","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.607465,44.852858],[-69.521651,44.812088],[-69.439208,44.845901],[-69.440164,44.87885],[-69.468081,44.938953],[-69.576371,44.909886],[-69.614967,44.879807],[-69.607465,44.852858]]]},"properties":{"id":"04943","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.780271,44.677119],[-69.853273,44.621506],[-69.818468,44.581312],[-69.792813,44.57733],[-69.744282,44.602607],[-69.780271,44.677119]]]},"properties":{"id":"04978","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.376835,45.070574],[-69.514883,45.034807],[-69.431031,44.986951],[-69.376835,45.070574]]]},"properties":{"id":"04923","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.110522,44.655743],[-69.05998,44.541611],[-68.987776,44.579689],[-68.966519,44.616451],[-69.014167,44.671794],[-69.060998,44.663946],[-69.110522,44.655743]]]},"properties":{"id":"04951","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.395987,44.457078],[-69.35744,44.429375],[-69.268882,44.364653],[-69.22222,44.441251],[-69.203443,44.471355],[-69.291648,44.533908],[-69.372419,44.544585],[-69.395987,44.457078]]]},"properties":{"id":"04941","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.460606,44.647054],[-69.325988,44.653887],[-69.327407,44.714442],[-69.343545,44.749819],[-69.471712,44.692899],[-69.460606,44.647054]]]},"properties":{"id":"04922","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.296804,44.96041],[-67.370412,44.861748],[-67.338247,44.776124],[-67.170325,44.80014],[-67.124428,44.88788],[-67.296804,44.96041]]]},"properties":{"id":"04628","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.338247,44.776124],[-67.338194,44.724403],[-67.323328,44.697519],[-67.143466,44.751637],[-67.170325,44.80014],[-67.338247,44.776124]]]},"properties":{"id":"04691","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.862785,43.470221],[-70.901253,43.580242],[-70.975157,43.61441],[-70.969714,43.480592],[-70.872075,43.469114],[-70.862785,43.470221]]]},"properties":{"id":"04001","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.607252,43.457464],[-70.475581,43.341475],[-70.403544,43.394208],[-70.569503,43.49628],[-70.607252,43.457464]]]},"properties":{"id":"04046","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.744389,43.280122],[-70.817626,43.237728],[-70.824741,43.179752],[-70.754475,43.197595],[-70.704195,43.228941],[-70.652911,43.260861],[-70.709254,43.302448],[-70.744389,43.280122]]]},"properties":{"id":"03908","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.634256,43.565954],[-70.569503,43.49628],[-70.403544,43.394208],[-70.357863,43.461768],[-70.547651,43.549998],[-70.55827,43.586134],[-70.634256,43.565954]]]},"properties":{"id":"04005","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.384409,43.496698],[-70.387541,43.506631],[-70.414413,43.502773],[-70.384409,43.496698]]]},"properties":{"id":"04063","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.704195,43.228941],[-70.596352,43.182086],[-70.57738,43.228137],[-70.651331,43.260175],[-70.652911,43.260861],[-70.704195,43.228941]]]},"properties":{"id":"03902","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.314834,44.03739],[-70.229244,43.974064],[-70.170903,44.020971],[-70.199752,44.167507],[-70.212493,44.178572],[-70.284557,44.197625],[-70.307785,44.079643],[-70.314834,44.03739]]]},"properties":{"id":"04210","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.314834,44.03739],[-70.307785,44.079643],[-70.359085,44.103812],[-70.451157,44.094549],[-70.480078,44.032078],[-70.448587,44.009428],[-70.401954,43.990108],[-70.314834,44.03739]]]},"properties":{"id":"04274","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.199758,44.478262],[-70.238904,44.458942],[-70.276752,44.407534],[-70.265223,44.362913],[-70.187903,44.343168],[-70.153201,44.369357],[-70.199758,44.478262]]]},"properties":{"id":"04253","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.088397,44.212562],[-70.178351,44.25876],[-70.212493,44.178572],[-70.199752,44.167507],[-70.115252,44.126237],[-70.088397,44.212562]]]},"properties":{"id":"04236","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.91398,45.780641],[-68.034881,45.642163],[-67.847901,45.583976],[-67.784306,45.594433],[-67.698594,45.610278],[-67.699275,45.613505],[-67.754303,45.667575],[-67.804785,45.77369],[-67.91398,45.780641]]]},"properties":{"id":"04424","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.066208,45.976539],[-68.021817,45.951679],[-67.933802,45.980611],[-67.911885,45.993074],[-67.78117,46.000773],[-67.782065,46.279406],[-67.973962,46.276369],[-68.040586,46.139082],[-67.903932,46.141129],[-67.90656,46.095262],[-68.03956,46.097614],[-68.039963,46.038692],[-68.066208,45.976539]]]},"properties":{"id":"04730","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.705894,46.834212],[-69.151464,46.86656],[-69.168338,46.746869],[-68.705894,46.834212]]]},"properties":{"id":"04768","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.426621,45.85338],[-68.439756,45.784241],[-68.432463,45.667827],[-68.33383,45.68727],[-68.323243,45.728507],[-68.32611,45.748292],[-68.426621,45.85338]]]},"properties":{"id":"04733","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.056528,46.775677],[-68.055301,46.749722],[-68.048247,46.594379],[-67.920527,46.596886],[-67.921601,46.686521],[-67.926079,46.778765],[-68.056528,46.775677]]]},"properties":{"id":"04769","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-70.791782,44.180862],[-70.888408,44.109823],[-70.858546,44.095269],[-70.734875,44.12191],[-70.791782,44.180862]]],[[[-70.69592,44.134217],[-70.633471,44.012866],[-70.595631,44.032057],[-70.607383,44.154404],[-70.624329,44.171036],[-70.69592,44.134217]]]]},"properties":{"id":"04040","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.311252,43.719902],[-70.245923,43.690572],[-70.199186,43.700148],[-70.169036,43.724037],[-70.219208,43.748655],[-70.336781,43.807149],[-70.355156,43.750348],[-70.311252,43.719902]]]},"properties":{"id":"04105","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.213618,43.633632],[-70.281078,43.597494],[-70.273928,43.554696],[-70.181986,43.620818],[-70.213618,43.633632]]]},"properties":{"id":"04107","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.329832,43.677076],[-70.276814,43.661124],[-70.250295,43.674484],[-70.245923,43.690572],[-70.311252,43.719902],[-70.329832,43.677076]]]},"properties":{"id":"04103","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.992524,43.748776],[-70.061246,43.707782],[-70.050866,43.678335],[-69.887414,43.755352],[-69.953521,43.76452],[-69.992524,43.748776]]]},"properties":{"id":"04003","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.149085,44.896102],[-70.293826,45.109903],[-70.398683,45.065633],[-70.149085,44.896102]]]},"properties":{"id":"04947","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.510598,44.627216],[-70.375744,44.630838],[-70.351512,44.708845],[-70.563799,44.713193],[-70.510598,44.627216]]]},"properties":{"id":"04285","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.312892,44.590659],[-68.247553,44.45261],[-68.217231,44.443282],[-68.204799,44.445136],[-68.206792,44.514961],[-68.254745,44.539335],[-68.312892,44.590659]]]},"properties":{"id":"04640","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.080962,44.601893],[-68.127017,44.639038],[-68.182815,44.750664],[-68.186029,44.753462],[-68.290856,44.782953],[-68.312892,44.590659],[-68.254745,44.539335],[-68.080962,44.601893]]]},"properties":{"id":"04634","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.28404,44.30664],[-68.307109,44.299779],[-68.280779,44.277848],[-68.275941,44.285529],[-68.28404,44.30664]]]},"properties":{"id":"04662","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.689364,44.331295],[-68.688971,44.293766],[-68.632168,44.270377],[-68.578028,44.344744],[-68.696672,44.4063],[-68.704658,44.398074],[-68.689364,44.331295]]]},"properties":{"id":"04676","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.730016,44.31436],[-68.69282,44.25319],[-68.688971,44.293766],[-68.704205,44.302038],[-68.730016,44.31436]]]},"properties":{"id":"04650","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.861201,44.296656],[-68.896384,44.209935],[-68.746207,44.126785],[-68.696664,44.184238],[-68.69282,44.25319],[-68.730016,44.31436],[-68.861201,44.296656]]]},"properties":{"id":"04683","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.665525,44.586402],[-69.724105,44.508191],[-69.676566,44.50047],[-69.630226,44.493088],[-69.628128,44.492753],[-69.565619,44.482596],[-69.513987,44.494512],[-69.44474,44.584221],[-69.457371,44.616432],[-69.587342,44.634076],[-69.665525,44.586402]]]},"properties":{"id":"04901","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.887417,44.529145],[-69.848046,44.523064],[-69.854396,44.507306],[-69.898347,44.52221],[-69.909591,44.512433],[-69.887788,44.403234],[-69.878305,44.4013],[-69.832129,44.393894],[-69.76048,44.514135],[-69.792813,44.57733],[-69.818468,44.581312],[-69.887417,44.529145]]]},"properties":{"id":"04917","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.017033,44.359271],[-70.023037,44.390737],[-70.100491,44.383854],[-70.132595,44.371382],[-70.074576,44.298614],[-70.043374,44.310205],[-70.017033,44.359271]]]},"properties":{"id":"04284","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.506382,44.34287],[-69.445498,44.463839],[-69.513987,44.494512],[-69.565619,44.482596],[-69.610365,44.359215],[-69.506382,44.34287]],[[-69.515987,44.482619],[-69.510116,44.480769],[-69.514566,44.478696],[-69.515987,44.482619]]]},"properties":{"id":"04358","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.017033,44.359271],[-70.043374,44.310205],[-69.991869,44.267236],[-69.937418,44.267676],[-69.91072,44.268833],[-69.885624,44.341958],[-70.017033,44.359271]],[[-69.889628,44.32593],[-69.897323,44.331423],[-69.886645,44.33489],[-69.889628,44.32593]]]},"properties":{"id":"04364","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.763566,44.153217],[-69.754588,44.172872],[-69.76334,44.186274],[-69.76674,44.153721],[-69.763566,44.153217]]]},"properties":{"id":"04359","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.587342,44.634076],[-69.457371,44.616432],[-69.460606,44.647054],[-69.471712,44.692899],[-69.483395,44.720271],[-69.603624,44.70462],[-69.632971,44.698763],[-69.587342,44.634076]]]},"properties":{"id":"04927","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.889628,44.32593],[-69.886645,44.33489],[-69.897323,44.331423],[-69.889628,44.32593]]]},"properties":{"id":"04343","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.268882,44.364653],[-69.346616,44.308337],[-69.372175,44.205405],[-69.321529,44.1729],[-69.185355,44.190965],[-69.196629,44.199229],[-69.187579,44.304572],[-69.268882,44.364653]]]},"properties":{"id":"04862","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.915286,44.110291],[-68.886519,44.021236],[-68.767085,44.003827],[-68.8205,44.130281],[-68.915286,44.110291]]]},"properties":{"id":"04863","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.891609,43.841139],[-68.861892,43.884206],[-68.902901,43.873398],[-68.891609,43.841139]]]},"properties":{"id":"04851","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.684662,44.088137],[-69.623643,44.028799],[-69.579016,44.115628],[-69.591749,44.122576],[-69.656942,44.125461],[-69.666298,44.124003],[-69.684662,44.088137]]]},"properties":{"id":"04535","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.302378,43.775266],[-69.312715,43.774133],[-69.324885,43.756556],[-69.312347,43.75589],[-69.298886,43.769339],[-69.302378,43.775266]]]},"properties":{"id":"04852","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.62467,43.807213],[-69.630871,43.81459],[-69.635121,43.80384],[-69.62467,43.807213]]]},"properties":{"id":"04570","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.791782,44.180862],[-70.734875,44.12191],[-70.69592,44.134217],[-70.624329,44.171036],[-70.708954,44.268051],[-70.781307,44.235631],[-70.804083,44.212497],[-70.80251,44.192043],[-70.791782,44.180862]]]},"properties":{"id":"04088","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.694244,44.409805],[-70.602902,44.325876],[-70.506196,44.371122],[-70.523448,44.436213],[-70.550116,44.479134],[-70.660973,44.457193],[-70.694244,44.409805]]]},"properties":{"id":"04219","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.528542,44.752167],[-68.473014,44.870763],[-68.671859,44.86643],[-68.696187,44.845266],[-68.698742,44.821466],[-68.684621,44.800831],[-68.528542,44.752167]]]},"properties":{"id":"04428","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.671859,44.86643],[-68.473014,44.870763],[-68.475998,44.881697],[-68.636304,44.923945],[-68.637624,44.904018],[-68.671859,44.86643]]]},"properties":{"id":"04411","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.354792,45.31561],[-68.489248,45.292751],[-68.422001,45.176374],[-68.288819,45.16675],[-68.303714,45.221531],[-68.354792,45.31561]]]},"properties":{"id":"04417","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.355812,44.798646],[-69.343545,44.749819],[-69.327407,44.714442],[-69.266785,44.721928],[-69.286336,44.807988],[-69.355812,44.798646]]]},"properties":{"id":"04929","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.759979,44.784992],[-69.751153,44.901065],[-69.859553,44.887171],[-69.891444,44.812596],[-69.888649,44.769173],[-69.759979,44.784992]]]},"properties":{"id":"04950","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.6828,45.194878],[-69.721099,45.354707],[-69.830116,45.251742],[-69.813824,45.176993],[-69.966591,45.156944],[-69.991129,45.252141],[-69.982894,45.319293],[-69.952169,44.96759],[-69.874424,44.977565],[-69.750369,44.99384],[-69.699164,45.09396],[-69.6828,45.194878]]]},"properties":{"id":"04920","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.744282,44.602607],[-69.665525,44.586402],[-69.587342,44.634076],[-69.632971,44.698763],[-69.636265,44.699206],[-69.640817,44.673189],[-69.663236,44.696578],[-69.731943,44.69421],[-69.780271,44.677119],[-69.744282,44.602607]]]},"properties":{"id":"04937","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.589528,44.621463],[-69.586076,44.625783],[-69.589724,44.625312],[-69.589528,44.621463]]]},"properties":{"id":"04975","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.521651,44.812088],[-69.483395,44.720271],[-69.471712,44.692899],[-69.343545,44.749819],[-69.355812,44.798646],[-69.439208,44.845901],[-69.521651,44.812088]]]},"properties":{"id":"04967","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.874424,44.977565],[-69.859553,44.887171],[-69.751153,44.901065],[-69.704733,44.906989],[-69.750369,44.99384],[-69.874424,44.977565]]]},"properties":{"id":"04979","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.93847,44.565179],[-68.841821,44.611062],[-68.845722,44.61484],[-68.966519,44.616451],[-68.987776,44.579689],[-68.97183,44.560241],[-68.93847,44.565179]]]},"properties":{"id":"04438","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.853052,44.412428],[-68.935358,44.405073],[-69.021484,44.231475],[-68.896384,44.209935],[-68.861201,44.296656],[-68.843864,44.354667],[-68.853052,44.412428]]]},"properties":{"id":"04848","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.065647,44.372954],[-69.160011,44.469835],[-69.203443,44.471355],[-69.22222,44.441251],[-69.108524,44.337297],[-69.065647,44.372954]]]},"properties":{"id":"04952","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-67.76189,44.889791],[-67.700362,44.870367],[-67.772944,44.956764],[-67.76189,44.889791]]],[[[-67.532481,44.719053],[-67.542406,44.614334],[-67.420937,44.594263],[-67.421434,44.714754],[-67.561037,44.885098],[-67.632053,44.863516],[-67.603827,44.795397],[-67.532481,44.719053]]]]},"properties":{"id":"04654","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.561037,44.885098],[-67.611797,44.975927],[-67.624546,44.997907],[-67.676399,45.10213],[-67.666413,45.135448],[-67.804892,45.076489],[-67.772944,44.956764],[-67.700362,44.870367],[-67.694099,44.848708],[-67.632053,44.863516],[-67.561037,44.885098]]]},"properties":{"id":"04686","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.968434,45.263904],[-67.804892,45.076489],[-67.666413,45.135448],[-67.670441,45.230718],[-67.94323,45.278631],[-67.968434,45.263904]]]},"properties":{"id":"04637","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.647323,44.522192],[-67.543849,44.456529],[-67.412408,44.556836],[-67.420937,44.594263],[-67.542406,44.614334],[-67.639981,44.617451],[-67.647323,44.522192]]]},"properties":{"id":"04649","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.475581,43.341475],[-70.607252,43.457464],[-70.64261,43.420422],[-70.670102,43.391224],[-70.535153,43.335107],[-70.475581,43.341475]]]},"properties":{"id":"04043","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.776276,43.745596],[-70.719607,43.665635],[-70.683571,43.655948],[-70.633528,43.703913],[-70.652359,43.733521],[-70.668199,43.792531],[-70.766073,43.792416],[-70.776276,43.745596]]]},"properties":{"id":"04049","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-70.852488,43.654712],[-70.842719,43.650493],[-70.838442,43.654132],[-70.838139,43.671234],[-70.852488,43.654712]]],[[[-70.852488,43.654712],[-70.868636,43.67472],[-70.887829,43.674429],[-70.838706,43.625356],[-70.811242,43.62767],[-70.814698,43.641998],[-70.838497,43.651085],[-70.842719,43.650493],[-70.852488,43.654712]]]]},"properties":{"id":"04056","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.808114,43.614655],[-70.798365,43.574659],[-70.695518,43.534565],[-70.742764,43.597393],[-70.808114,43.614655]]]},"properties":{"id":"04087","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.325218,44.208399],[-70.391735,44.153562],[-70.401809,44.143657],[-70.359085,44.103812],[-70.307785,44.079643],[-70.284557,44.197625],[-70.325218,44.208399]]]},"properties":{"id":"04258","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.074576,44.298614],[-70.132595,44.371382],[-70.153201,44.369357],[-70.187903,44.343168],[-70.178351,44.25876],[-70.088397,44.212562],[-70.075747,44.206168],[-70.074552,44.27133],[-70.074576,44.298614]]]},"properties":{"id":"04263","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.304901,46.566016],[-68.047088,46.568235],[-68.048247,46.594379],[-68.055301,46.749722],[-68.300531,46.745238],[-68.304901,46.566016]]]},"properties":{"id":"04757","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.908946,46.597127],[-67.905951,46.508813],[-67.786033,46.511009],[-67.788234,46.599908],[-67.908946,46.597127]]]},"properties":{"id":"04758","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.754052,43.878964],[-70.782984,43.814147],[-70.766073,43.792416],[-70.668199,43.792531],[-70.667647,43.793173],[-70.727383,43.858785],[-70.754052,43.878964]]]},"properties":{"id":"04091","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.633471,44.012866],[-70.69592,44.134217],[-70.734875,44.12191],[-70.858546,44.095269],[-70.848077,44.049522],[-70.715418,43.961815],[-70.689487,43.954244],[-70.633471,44.012866]],[[-70.700311,44.099881],[-70.701213,44.103648],[-70.69881,44.104531],[-70.696726,44.100624],[-70.700311,44.099881]]]},"properties":{"id":"04009","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.324379,43.819822],[-70.336781,43.807149],[-70.219208,43.748655],[-70.195078,43.781512],[-70.222913,43.802176],[-70.296382,43.848775],[-70.324379,43.819822]]]},"properties":{"id":"04021","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.689487,43.954244],[-70.715418,43.961815],[-70.72978,43.92833],[-70.754052,43.878964],[-70.727383,43.858785],[-70.626788,43.833753],[-70.619126,43.841411],[-70.613032,43.919982],[-70.689487,43.954244]]]},"properties":{"id":"04029","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.378104,43.697356],[-70.395012,43.652807],[-70.370545,43.642438],[-70.347399,43.645342],[-70.329832,43.677076],[-70.311252,43.719902],[-70.355156,43.750348],[-70.378104,43.697356]]]},"properties":{"id":"04092","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.314834,44.03739],[-70.401954,43.990108],[-70.374499,43.955229],[-70.253169,43.893392],[-70.248617,43.898282],[-70.203647,43.947928],[-70.229244,43.974064],[-70.314834,44.03739]]]},"properties":{"id":"04260","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.637452,43.823045],[-70.652359,43.733521],[-70.633528,43.703913],[-70.612074,43.687099],[-70.546605,43.71316],[-70.448599,43.816994],[-70.466163,43.846069],[-70.619126,43.841411],[-70.626788,43.833753],[-70.637452,43.823045]]]},"properties":{"id":"04084","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.166119,44.661848],[-70.160991,44.663211],[-70.164789,44.664954],[-70.166119,44.661848]]]},"properties":{"id":"04992","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.799361,45.016326],[-70.631953,44.854818],[-70.503655,44.95277],[-70.521502,45.046527],[-70.503548,45.129572],[-70.805716,45.136221],[-70.799361,45.016326]]]},"properties":{"id":"04970","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.375744,44.630838],[-70.510598,44.627216],[-70.549985,44.610727],[-70.460161,44.529474],[-70.351452,44.486033],[-70.313486,44.530901],[-70.31346,44.535263],[-70.297083,44.550352],[-70.286254,44.563126],[-70.304827,44.57344],[-70.375744,44.630838]]]},"properties":{"id":"04224","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.307109,44.299779],[-68.383985,44.315701],[-68.362493,44.284001],[-68.34039,44.252967],[-68.31415,44.215665],[-68.285904,44.223178],[-68.286306,44.240599],[-68.280779,44.277848],[-68.307109,44.299779]]]},"properties":{"id":"04679","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.045281,44.390735],[-68.165303,44.375357],[-68.155818,44.343725],[-68.022688,44.342725],[-68.045281,44.390735]]]},"properties":{"id":"04693","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.286306,44.240599],[-68.241985,44.281366],[-68.275941,44.285529],[-68.280779,44.277848],[-68.286306,44.240599]]]},"properties":{"id":"04625","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.696664,44.184238],[-68.548191,44.129468],[-68.516863,44.192669],[-68.632168,44.270377],[-68.688971,44.293766],[-68.69282,44.25319],[-68.696664,44.184238]]]},"properties":{"id":"04627","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.663549,44.56448],[-68.678086,44.568238],[-68.67762,44.560555],[-68.667496,44.557414],[-68.663549,44.56448]]]},"properties":{"id":"04431","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.898347,44.52221],[-69.854396,44.507306],[-69.848046,44.523064],[-69.887417,44.529145],[-69.898347,44.52221]]]},"properties":{"id":"04918","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.513987,44.494512],[-69.445498,44.463839],[-69.395987,44.457078],[-69.372419,44.544585],[-69.44474,44.584221],[-69.513987,44.494512]]]},"properties":{"id":"04910","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.885624,44.341958],[-69.91072,44.268833],[-69.876186,44.258007],[-69.847701,44.285816],[-69.827101,44.314034],[-69.832129,44.393894],[-69.878305,44.4013],[-69.885624,44.341958]]]},"properties":{"id":"04351","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.023037,44.390737],[-70.017033,44.359271],[-69.885624,44.341958],[-69.878305,44.4013],[-69.887788,44.403234],[-70.014007,44.423997],[-70.023037,44.390737]]]},"properties":{"id":"04355","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.187579,44.304572],[-69.196629,44.199229],[-69.165437,44.222669],[-69.121622,44.255644],[-69.170895,44.292552],[-69.187579,44.304572]]]},"properties":{"id":"04847","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.301677,43.938335],[-69.282347,43.958303],[-69.30326,43.939745],[-69.301677,43.938335]]]},"properties":{"id":"04855","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.40602,44.210674],[-69.409635,44.327998],[-69.506382,44.34287],[-69.547398,44.260988],[-69.591749,44.122576],[-69.579016,44.115628],[-69.517831,44.101012],[-69.432184,44.157702],[-69.40602,44.210674]]]},"properties":{"id":"04348","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.547119,43.995248],[-69.531746,43.918874],[-69.495696,43.927024],[-69.464814,43.978032],[-69.475732,44.006952],[-69.540892,44.004321],[-69.547119,43.995248]]]},"properties":{"id":"04539","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.695916,44.640581],[-70.655335,44.590802],[-70.59233,44.616014],[-70.549985,44.610727],[-70.510598,44.627216],[-70.563799,44.713193],[-70.635645,44.787219],[-70.769228,44.732228],[-70.695916,44.640581]]]},"properties":{"id":"04275","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.781307,44.235631],[-70.974338,44.257915],[-70.955792,44.220867],[-70.815803,44.20637],[-70.804083,44.212497],[-70.781307,44.235631]]]},"properties":{"id":"04231","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.276752,44.407534],[-70.351757,44.463316],[-70.421998,44.42503],[-70.459703,44.316682],[-70.456843,44.311992],[-70.431849,44.251263],[-70.314493,44.237247],[-70.265223,44.362913],[-70.276752,44.407534]]]},"properties":{"id":"04220","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.59233,44.616014],[-70.482864,44.52983],[-70.460161,44.529474],[-70.549985,44.610727],[-70.59233,44.616014]]]},"properties":{"id":"04257","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.426621,45.85338],[-68.427598,45.944591],[-68.623613,45.861156],[-68.439756,45.784241],[-68.426621,45.85338]]]},"properties":{"id":"04777","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.266776,45.509933],[-68.363209,45.495548],[-68.44248,45.438337],[-68.368581,45.406145],[-68.241319,45.4266],[-68.266776,45.509933]]]},"properties":{"id":"04495","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.232021,45.090166],[-69.355667,45.073469],[-69.376835,45.070574],[-69.431031,44.986951],[-69.390659,44.97893],[-69.330949,44.986901],[-69.20852,45.004433],[-69.232021,45.090166]]]},"properties":{"id":"04930","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.979025,44.938038],[-69.062387,44.926955],[-69.03921,44.840964],[-68.983105,44.848342],[-68.920444,44.884225],[-68.979025,44.938038]]]},"properties":{"id":"04456","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.787546,44.767229],[-68.735138,44.740077],[-68.684621,44.800831],[-68.698742,44.821466],[-68.781153,44.77829],[-68.787546,44.767229]]]},"properties":{"id":"04412","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.03921,44.840964],[-69.102693,44.832485],[-69.081355,44.745752],[-68.958845,44.761509],[-68.983105,44.848342],[-69.03921,44.840964]]]},"properties":{"id":"04419","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.636304,44.923945],[-68.475998,44.881697],[-68.487692,44.927812],[-68.501095,44.980718],[-68.645142,45.021081],[-68.641944,44.966655],[-68.636304,44.923945]]]},"properties":{"id":"04461","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.624778,45.47669],[-69.601924,45.400562],[-69.536875,45.409968],[-69.488745,45.417386],[-69.475896,45.429479],[-69.34388,45.565794],[-69.118877,45.585993],[-69.103955,45.684377],[-69.421732,45.699013],[-69.664239,45.920397],[-69.646566,45.863988],[-69.624778,45.47669]]]},"properties":{"id":"04441","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.065297,45.408413],[-69.071626,45.395535],[-69.054717,45.388898],[-69.052563,45.408956],[-69.065297,45.408413]]]},"properties":{"id":"04415","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.601924,45.400562],[-69.729215,45.379179],[-69.722067,45.35764],[-69.575167,45.309592],[-69.536875,45.409968],[-69.601924,45.400562]]]},"properties":{"id":"04485","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.785071,44.088976],[-69.903737,44.096266],[-69.935304,43.991379],[-69.86477,43.975296],[-69.839393,43.985646],[-69.813549,44.026011],[-69.785071,44.088976]]]},"properties":{"id":"04008","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.759979,44.784992],[-69.888649,44.769173],[-69.960149,44.681226],[-69.930451,44.610852],[-69.853273,44.621506],[-69.780271,44.677119],[-69.731943,44.69421],[-69.759979,44.784992]]]},"properties":{"id":"04957","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.16001,45.751187],[-69.717665,45.584646],[-69.714458,45.585571],[-69.646566,45.863988],[-69.664239,45.920397],[-70.001563,46.182596],[-70.002197,46.08963],[-70.166651,45.750471],[-70.16001,45.751187]]]},"properties":{"id":"04478","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.22222,44.441251],[-69.268882,44.364653],[-69.187579,44.304572],[-69.170895,44.292552],[-69.108524,44.337297],[-69.22222,44.441251]]]},"properties":{"id":"04973","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.183732,44.644023],[-69.2052,44.729586],[-69.266785,44.721928],[-69.327407,44.714442],[-69.325988,44.653887],[-69.285926,44.627118],[-69.20425,44.640626],[-69.183732,44.644023]]]},"properties":{"id":"04987","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.561037,44.885098],[-67.421434,44.714754],[-67.338194,44.724403],[-67.338247,44.776124],[-67.370412,44.861748],[-67.611797,44.975927],[-67.561037,44.885098]]]},"properties":{"id":"04630","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.082348,44.90843],[-67.185849,45.009661],[-67.242666,45.071983],[-67.274585,45.109244],[-67.30159,45.050198],[-67.296804,44.96041],[-67.124428,44.88788],[-67.082348,44.90843]]]},"properties":{"id":"04666","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.688778,45.299656],[-67.670441,45.230718],[-67.666413,45.135448],[-67.676399,45.10213],[-67.611729,45.097892],[-67.522037,45.253057],[-67.551494,45.322472],[-67.688778,45.299656]]]},"properties":{"id":"04668","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.274585,45.109244],[-67.242666,45.071983],[-67.12267,45.124813],[-67.305593,45.144822],[-67.274585,45.109244]]]},"properties":{"id":"04619","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.34041,43.44402],[-70.345095,43.444838],[-70.343492,43.441763],[-70.34041,43.44402]]]},"properties":{"id":"04006","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.852593,43.804735],[-70.839408,43.709172],[-70.776276,43.745596],[-70.766073,43.792416],[-70.782984,43.814147],[-70.852593,43.804735]]]},"properties":{"id":"04020","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.633528,43.703913],[-70.683571,43.655948],[-70.677711,43.643413],[-70.647803,43.582511],[-70.634256,43.565954],[-70.55827,43.586134],[-70.612074,43.687099],[-70.633528,43.703913]]]},"properties":{"id":"04042","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.719607,43.665635],[-70.814698,43.641998],[-70.811242,43.62767],[-70.808114,43.614655],[-70.742764,43.597393],[-70.677711,43.643413],[-70.683571,43.655948],[-70.719607,43.665635]]]},"properties":{"id":"04061","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.401809,44.143657],[-70.451157,44.094549],[-70.359085,44.103812],[-70.401809,44.143657]]]},"properties":{"id":"04256","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.875461,47.101882],[-68.411457,47.079285],[-68.445275,47.106371],[-68.697506,47.102563],[-68.818494,47.101945],[-68.875461,47.101882]]]},"properties":{"id":"04739","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.056987,46.331382],[-68.343794,46.469618],[-68.419765,46.34776],[-68.436791,46.347959],[-68.436937,46.065141],[-68.16731,46.123497],[-68.039689,46.122825],[-68.040586,46.139082],[-67.973962,46.276369],[-68.056987,46.331382]]]},"properties":{"id":"04780","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.271219,46.926949],[-68.30019,46.834507],[-68.177298,46.837038],[-68.178696,46.92886],[-68.271219,46.926949]]]},"properties":{"id":"04766","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.78117,46.000773],[-67.911885,45.993074],[-67.912835,45.968242],[-67.91398,45.780641],[-67.804785,45.77369],[-67.78117,46.000773]]]},"properties":{"id":"04471","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.444938,47.128845],[-68.697506,47.102563],[-68.445275,47.106371],[-68.444938,47.128845]]]},"properties":{"id":"04781","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.408126,47.227538],[-68.461664,47.289153],[-68.826559,47.207128],[-68.818494,47.101945],[-68.697506,47.102563],[-68.444938,47.128845],[-68.416963,47.199518],[-68.408126,47.227538]]]},"properties":{"id":"04743","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.039689,46.122825],[-68.16731,46.123497],[-68.103242,46.0383],[-68.039963,46.038692],[-68.03956,46.097614],[-68.039689,46.122825]]]},"properties":{"id":"04763","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.378104,43.697356],[-70.448599,43.816994],[-70.546605,43.71316],[-70.468462,43.631319],[-70.395012,43.652807],[-70.378104,43.697356]]]},"properties":{"id":"04038","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.258005,43.645063],[-70.347399,43.645342],[-70.370545,43.642438],[-70.281078,43.597494],[-70.213618,43.633632],[-70.238386,43.658375],[-70.258005,43.645063]]]},"properties":{"id":"04106","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.276814,43.661124],[-70.329832,43.677076],[-70.347399,43.645342],[-70.258005,43.645063],[-70.276814,43.661124]]]},"properties":{"id":"04102","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.448599,43.816994],[-70.378104,43.697356],[-70.355156,43.750348],[-70.336781,43.807149],[-70.324379,43.819822],[-70.423761,43.870911],[-70.467939,43.873997],[-70.466163,43.846069],[-70.448599,43.816994]]]},"properties":{"id":"04062","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.213618,43.633632],[-70.181986,43.620818],[-70.146466,43.637393],[-70.199186,43.700148],[-70.245923,43.690572],[-70.250295,43.674484],[-70.238386,43.658375],[-70.213618,43.633632]],[[-70.17638,43.665764],[-70.190792,43.646684],[-70.200946,43.661937],[-70.17638,43.665764]]]},"properties":{"id":"04109","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.652359,43.733521],[-70.637452,43.823045],[-70.667647,43.793173],[-70.668199,43.792531],[-70.652359,43.733521]]]},"properties":{"id":"04085","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.169036,43.724037],[-70.116449,43.698494],[-70.065169,43.6737],[-70.050866,43.678335],[-70.061246,43.707782],[-70.069814,43.770816],[-70.084329,43.780957],[-70.15515,43.738269],[-70.169036,43.724037]]]},"properties":{"id":"04017","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.066106,44.621411],[-70.079175,44.625059],[-70.090506,44.634652],[-70.09437,44.633512],[-70.077541,44.620179],[-70.065784,44.620099],[-70.066106,44.621411]]]},"properties":{"id":"04940","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.136542,44.87643],[-70.171414,44.736104],[-70.030337,44.82214],[-70.031581,44.856995],[-70.03327,44.86454],[-70.136542,44.87643]]]},"properties":{"id":"04956","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.419837,44.385576],[-68.197248,44.289625],[-68.155818,44.343725],[-68.165303,44.375357],[-68.217231,44.443282],[-68.247553,44.45261],[-68.419837,44.385576]],[[-68.251921,44.414177],[-68.250927,44.413591],[-68.252842,44.41341],[-68.253107,44.414219],[-68.251921,44.414177]]]},"properties":{"id":"04609","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.528542,44.752167],[-68.624473,44.63871],[-68.595941,44.556937],[-68.439712,44.384653],[-68.419837,44.385576],[-68.247553,44.45261],[-68.312892,44.590659],[-68.290856,44.782953],[-68.297399,44.820701],[-68.349291,44.905359],[-68.375892,44.997373],[-68.43536,44.989742],[-68.487692,44.927812],[-68.475998,44.881697],[-68.473014,44.870763],[-68.528542,44.752167]]]},"properties":{"id":"04605","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.362493,44.284001],[-68.463142,44.237903],[-68.391528,44.21149],[-68.36816,44.209664],[-68.34039,44.252967],[-68.362493,44.284001]]]},"properties":{"id":"04612","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.762794,44.424831],[-68.800964,44.462031],[-68.850852,44.41807],[-68.853052,44.412428],[-68.843864,44.354667],[-68.762794,44.424831]]]},"properties":{"id":"04421","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-69.792813,44.57733],[-69.76048,44.514135],[-69.724105,44.508191],[-69.665525,44.586402],[-69.744282,44.602607],[-69.792813,44.57733]]],[[[-69.853273,44.621506],[-69.930451,44.610852],[-69.946761,44.582346],[-69.944082,44.537828],[-69.909591,44.512433],[-69.898347,44.52221],[-69.887417,44.529145],[-69.818468,44.581312],[-69.853273,44.621506]]]]},"properties":{"id":"04963","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.630226,44.493088],[-69.63029,44.490187],[-69.623893,44.488679],[-69.628128,44.492753],[-69.630226,44.493088]]]},"properties":{"id":"04962","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.176483,43.974872],[-69.128862,43.99697],[-69.15598,44.032982],[-69.176483,43.974872]]]},"properties":{"id":"04859","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.409635,44.327998],[-69.40602,44.210674],[-69.372175,44.205405],[-69.346616,44.308337],[-69.394361,44.342934],[-69.409635,44.327998]]]},"properties":{"id":"04574","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.717213,44.115375],[-68.668693,44.012909],[-68.558994,44.02843],[-68.620152,44.112792],[-68.717213,44.115375]]]},"properties":{"id":"04645","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.600634,43.858745],[-69.58753,43.814354],[-69.572378,43.823398],[-69.583727,43.877155],[-69.602084,43.868375],[-69.600634,43.858745]]]},"properties":{"id":"04544","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.363996,44.012873],[-69.282268,44.052584],[-69.282225,44.054772],[-69.321529,44.1729],[-69.372175,44.205405],[-69.40602,44.210674],[-69.432184,44.157702],[-69.45,44.058322],[-69.448521,44.05704],[-69.363996,44.012873]]]},"properties":{"id":"04572","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.68388,43.853987],[-69.635121,43.80384],[-69.630871,43.81459],[-69.62467,43.807213],[-69.614609,43.810231],[-69.622246,43.81733],[-69.639524,43.835696],[-69.68388,43.853987]]]},"properties":{"id":"04576","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.70428,44.522464],[-70.787844,44.486598],[-70.68644,44.498097],[-70.70428,44.522464]]]},"properties":{"id":"04237","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.695916,44.640581],[-70.705324,44.571477],[-70.655335,44.590802],[-70.695916,44.640581]]]},"properties":{"id":"04226","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.787546,44.767229],[-68.837529,44.702125],[-68.815793,44.683754],[-68.720777,44.699667],[-68.735138,44.740077],[-68.787546,44.767229]]]},"properties":{"id":"04474","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.2052,44.729586],[-69.183732,44.644023],[-69.110522,44.655743],[-69.060998,44.663946],[-69.081355,44.745752],[-69.148065,44.736939],[-69.2052,44.729586]]]},"properties":{"id":"04932","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.276543,45.177582],[-69.309554,45.373958],[-69.475896,45.429479],[-69.488745,45.417386],[-69.424481,45.243227],[-69.526763,45.144998],[-69.514883,45.034807],[-69.376835,45.070574],[-69.355667,45.073469],[-69.276543,45.177582]]]},"properties":{"id":"04443","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.276543,45.177582],[-69.355667,45.073469],[-69.232021,45.090166],[-69.276543,45.177582]]]},"properties":{"id":"04479","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.887402,43.775333],[-69.773036,43.719036],[-69.788554,43.799384],[-69.871322,43.814468],[-69.887402,43.775333]]]},"properties":{"id":"04562","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.903737,44.096266],[-69.785071,44.088976],[-69.758208,44.139334],[-69.763566,44.153217],[-69.76674,44.153721],[-69.854983,44.167862],[-69.897783,44.107662],[-69.903737,44.096266]]]},"properties":{"id":"04357","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.025228,44.050921],[-70.035532,44.00709],[-69.935304,43.991379],[-69.903737,44.096266],[-69.897783,44.107662],[-70.005471,44.124519],[-70.025228,44.050921]]]},"properties":{"id":"04287","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.779566,45.541502],[-70.049356,45.424034],[-70.294846,45.312774],[-70.222978,45.301155],[-69.982894,45.319293],[-69.991129,45.252141],[-69.830116,45.251742],[-69.721099,45.354707],[-69.722067,45.35764],[-69.729215,45.379179],[-69.779566,45.541502]]]},"properties":{"id":"04985","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.014167,44.671794],[-68.966519,44.616451],[-68.845722,44.61484],[-68.815793,44.683754],[-68.837529,44.702125],[-69.014167,44.671794]]]},"properties":{"id":"04496","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.97183,44.560241],[-68.955529,44.400482],[-68.935358,44.405073],[-68.853052,44.412428],[-68.850852,44.41807],[-68.93847,44.565179],[-68.97183,44.560241]]]},"properties":{"id":"04974","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.94323,45.278631],[-67.670441,45.230718],[-67.688778,45.299656],[-67.686838,45.409595],[-67.64814,45.483786],[-67.847901,45.583976],[-68.034881,45.642163],[-68.015227,45.537238],[-67.94323,45.278631]]]},"properties":{"id":"04490","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.543849,44.456529],[-67.647323,44.522192],[-67.647982,44.451796],[-67.543849,44.456529]]]},"properties":{"id":"04611","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.857239,43.372445],[-70.922838,43.325844],[-70.817626,43.237728],[-70.744389,43.280122],[-70.857239,43.372445]]]},"properties":{"id":"03901","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.719607,43.665635],[-70.776276,43.745596],[-70.839408,43.709172],[-70.838072,43.675367],[-70.838139,43.671234],[-70.838442,43.654132],[-70.838497,43.651085],[-70.814698,43.641998],[-70.719607,43.665635]]]},"properties":{"id":"04048","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.709254,43.302448],[-70.724187,43.334896],[-70.813471,43.401587],[-70.857239,43.372445],[-70.744389,43.280122],[-70.709254,43.302448]]]},"properties":{"id":"03906","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.547651,43.549998],[-70.357863,43.461768],[-70.380711,43.495869],[-70.384409,43.496698],[-70.414413,43.502773],[-70.39009,43.55371],[-70.492009,43.606769],[-70.547651,43.549998]]]},"properties":{"id":"04072","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.77162,43.473443],[-70.782957,43.491712],[-70.862785,43.470221],[-70.872075,43.469114],[-70.844482,43.442989],[-70.77162,43.473443]]]},"properties":{"id":"04083","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.677711,43.643413],[-70.742764,43.597393],[-70.695518,43.534565],[-70.647803,43.582511],[-70.677711,43.643413]]]},"properties":{"id":"04030","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.085917,44.059682],[-70.088815,44.00989],[-70.051824,43.990982],[-70.035532,44.00709],[-70.025228,44.050921],[-70.085917,44.059682]]]},"properties":{"id":"04252","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.276866,47.107135],[-68.266166,47.018391],[-68.237737,46.956323],[-68.063227,47.022826],[-68.075598,47.083508],[-68.078612,47.111531],[-68.276866,47.107135]]]},"properties":{"id":"04783","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.179048,47.329886],[-68.178653,47.200833],[-68.061536,47.255985],[-68.179048,47.329886]]]},"properties":{"id":"04746","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.436937,46.065141],[-68.436791,46.347959],[-68.43661,46.363668],[-68.436443,46.382121],[-68.7476,46.395433],[-68.819401,46.395782],[-68.820323,46.302933],[-68.626298,45.860837],[-68.623613,45.861156],[-68.427598,45.944591],[-68.436937,46.065141]]]},"properties":{"id":"04765","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.788853,46.68945],[-67.789487,46.872181],[-67.919126,46.865983],[-67.926079,46.778765],[-67.921601,46.686521],[-67.788853,46.68945]]]},"properties":{"id":"04742","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.33383,45.68727],[-68.228027,45.515425],[-68.13235,45.565923],[-68.205189,45.673207],[-68.323243,45.728507],[-68.33383,45.68727]]]},"properties":{"id":"04451","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.783302,46.372964],[-67.784945,46.466602],[-67.988443,46.437459],[-67.783302,46.372964]]]},"properties":{"id":"04735","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.727383,43.858785],[-70.667647,43.793173],[-70.637452,43.823045],[-70.626788,43.833753],[-70.727383,43.858785]]]},"properties":{"id":"04024","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.874657,43.95359],[-70.034238,43.975661],[-70.080839,43.926458],[-70.028425,43.816697],[-69.895953,43.866238],[-69.874657,43.95359]]]},"properties":{"id":"04011","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.17638,43.665764],[-70.200946,43.661937],[-70.190792,43.646684],[-70.17638,43.665764]]]},"properties":{"id":"04108","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.203647,43.947928],[-70.248617,43.898282],[-70.173907,43.842932],[-70.17138,43.840707],[-70.118523,43.907967],[-70.203647,43.947928]]]},"properties":{"id":"04069","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.992524,43.748776],[-69.989826,43.763417],[-69.953521,43.76452],[-69.887414,43.755352],[-69.887402,43.775333],[-69.871322,43.814468],[-69.895953,43.866238],[-70.028425,43.816697],[-70.069814,43.770816],[-70.061246,43.707782],[-69.992524,43.748776]]]},"properties":{"id":"04079","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.521502,45.046527],[-70.398683,45.065633],[-70.293826,45.109903],[-70.31525,45.164313],[-70.424168,45.165065],[-70.503548,45.129572],[-70.521502,45.046527]]]},"properties":{"id":"04982","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.065784,44.620099],[-70.040215,44.588722],[-69.946761,44.582346],[-69.930451,44.610852],[-69.960149,44.681226],[-70.002986,44.708855],[-70.066106,44.621411],[-70.065784,44.620099]]]},"properties":{"id":"04955","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.010718,44.568883],[-68.142469,44.476973],[-68.204799,44.445136],[-68.217231,44.443282],[-68.165303,44.375357],[-68.045281,44.390735],[-68.027317,44.395805],[-68.009415,44.449328],[-67.980726,44.499121],[-68.010718,44.568883]]]},"properties":{"id":"04607","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.383985,44.315701],[-68.46259,44.324628],[-68.510157,44.298013],[-68.473417,44.249453],[-68.463142,44.237903],[-68.362493,44.284001],[-68.383985,44.315701]]]},"properties":{"id":"04674","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.599452,44.493084],[-68.696672,44.4063],[-68.578028,44.344744],[-68.510157,44.298013],[-68.46259,44.324628],[-68.471143,44.362035],[-68.599452,44.493084]],[[-68.523956,44.417584],[-68.541731,44.434289],[-68.516357,44.439117],[-68.523956,44.417584]]]},"properties":{"id":"04614","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.548191,44.129468],[-68.382444,44.092879],[-68.391528,44.21149],[-68.463142,44.237903],[-68.473417,44.249453],[-68.516863,44.192669],[-68.548191,44.129468]]]},"properties":{"id":"04685","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.241985,44.281366],[-68.286306,44.240599],[-68.285904,44.223178],[-68.197248,44.289625],[-68.241985,44.281366]]]},"properties":{"id":"04646","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.624473,44.63871],[-68.528542,44.752167],[-68.684621,44.800831],[-68.735138,44.740077],[-68.720777,44.699667],[-68.651175,44.655769],[-68.624473,44.63871]]]},"properties":{"id":"04429","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.947191,44.2383],[-69.854983,44.167862],[-69.76674,44.153721],[-69.76334,44.186274],[-69.754588,44.172872],[-69.763566,44.153217],[-69.758208,44.139334],[-69.666298,44.124003],[-69.656942,44.125461],[-69.665441,44.227576],[-69.723614,44.236788],[-69.770232,44.234759],[-69.876186,44.258007],[-69.91072,44.268833],[-69.937418,44.267676],[-69.947191,44.2383]]]},"properties":{"id":"04345","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.04235,44.485235],[-70.12939,44.487217],[-70.116257,44.440344],[-70.112071,44.425665],[-70.100491,44.383854],[-70.023037,44.390737],[-70.014007,44.423997],[-70.04235,44.485235]]]},"properties":{"id":"04349","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.035546,44.508083],[-69.944082,44.537828],[-69.946761,44.582346],[-70.040215,44.588722],[-70.035546,44.508083]]]},"properties":{"id":"04360","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.282347,43.958303],[-69.197003,44.039343],[-69.184751,44.062508],[-69.205883,44.06586],[-69.282225,44.054772],[-69.282268,44.052584],[-69.323039,43.940598],[-69.30326,43.939745],[-69.282347,43.958303]]]},"properties":{"id":"04563","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.185355,44.190965],[-69.321529,44.1729],[-69.282225,44.054772],[-69.205883,44.06586],[-69.185287,44.127745],[-69.160548,44.172591],[-69.185355,44.190965]]]},"properties":{"id":"04864","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.464814,43.978032],[-69.495696,43.927024],[-69.483376,43.906863],[-69.45731,43.904156],[-69.430346,43.954399],[-69.464814,43.978032]]]},"properties":{"id":"04564","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.583727,43.877155],[-69.572378,43.823398],[-69.542629,43.870982],[-69.535739,43.903393],[-69.58479,43.927675],[-69.583727,43.877155]]]},"properties":{"id":"04568","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.562963,43.979775],[-69.637826,44.017106],[-69.676727,43.922305],[-69.664136,43.928388],[-69.584894,43.933107],[-69.562963,43.979775]]]},"properties":{"id":"04556","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.835148,45.277072],[-71.037978,44.760617],[-70.937016,44.754712],[-70.906586,44.833469],[-70.799361,45.016326],[-70.805716,45.136221],[-70.835148,45.277072]]]},"properties":{"id":"03579","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.459703,44.316682],[-70.506196,44.371122],[-70.602902,44.325876],[-70.580761,44.294827],[-70.566212,44.270936],[-70.456843,44.311992],[-70.459703,44.316682]]]},"properties":{"id":"04289","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.391735,44.153562],[-70.325218,44.208399],[-70.314493,44.237247],[-70.431849,44.251263],[-70.441137,44.20058],[-70.391735,44.153562]]]},"properties":{"id":"04238","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-70.297083,44.550352],[-70.31346,44.535263],[-70.313486,44.530901],[-70.307659,44.537876],[-70.297083,44.550352]]],[[[-70.304827,44.57344],[-70.286254,44.563126],[-70.280748,44.569619],[-70.304827,44.57344]]]]},"properties":{"id":"04227","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.700736,45.161114],[-68.776061,45.240317],[-68.856587,45.142783],[-68.842312,45.095064],[-68.700736,45.161114]]]},"properties":{"id":"04453","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.062387,44.926955],[-69.183531,44.91077],[-69.160716,44.824906],[-69.102693,44.832485],[-69.03921,44.840964],[-69.062387,44.926955]]]},"properties":{"id":"04488","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-68.44248,45.438337],[-68.600305,45.327714],[-68.489248,45.292751],[-68.354792,45.31561],[-68.368581,45.406145],[-68.44248,45.438337]]],[[[-68.658709,45.312146],[-68.635638,45.307537],[-68.412524,45.570398],[-68.534508,45.55939],[-68.658709,45.312146]]]]},"properties":{"id":"04457","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.103955,45.684377],[-68.831206,45.44243],[-68.825425,45.420552],[-68.782045,45.42778],[-68.701758,45.441344],[-68.607245,45.632296],[-68.56807,45.669406],[-68.626298,45.860837],[-68.820323,46.302933],[-69.145371,46.301576],[-69.421732,45.699013],[-69.103955,45.684377]]]},"properties":{"id":"04462","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.9735,45.127216],[-68.983599,45.125841],[-68.958227,45.033836],[-68.830243,45.050438],[-68.842312,45.095064],[-68.856587,45.142783],[-68.9735,45.127216]]]},"properties":{"id":"04410","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.684654,44.90554],[-68.637624,44.904018],[-68.636304,44.923945],[-68.641944,44.966655],[-68.630143,45.132417],[-68.700736,45.161114],[-68.842312,45.095064],[-68.830243,45.050438],[-68.828671,44.95837],[-68.760994,44.905378],[-68.694303,44.907681],[-68.688794,44.914634],[-68.684654,44.90554]]]},"properties":{"id":"04468","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.354792,45.31561],[-68.303714,45.221531],[-68.18069,45.23717],[-68.241319,45.4266],[-68.368581,45.406145],[-68.354792,45.31561]]]},"properties":{"id":"04455","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.190387,44.820282],[-69.148065,44.736939],[-69.081355,44.745752],[-69.102693,44.832485],[-69.160716,44.824906],[-69.190387,44.820282]]]},"properties":{"id":"04434","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.276543,45.177582],[-69.232021,45.090166],[-69.109443,45.107192],[-68.983599,45.125841],[-68.9735,45.127216],[-69.084143,45.213753],[-69.163106,45.279971],[-69.208137,45.366717],[-69.309554,45.373958],[-69.276543,45.177582]]]},"properties":{"id":"04426","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.048532,45.28268],[-69.163106,45.279971],[-69.084143,45.213753],[-69.048532,45.28268]]]},"properties":{"id":"04481","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.743634,43.893256],[-69.746329,44.013802],[-69.813549,44.026011],[-69.839393,43.985646],[-69.756144,43.885248],[-69.743634,43.893256]]]},"properties":{"id":"04579","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.031581,44.856995],[-69.891444,44.812596],[-69.859553,44.887171],[-69.874424,44.977565],[-69.952169,44.96759],[-70.03327,44.86454],[-70.031581,44.856995]]]},"properties":{"id":"04958","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.439208,44.845901],[-69.355812,44.798646],[-69.286336,44.807988],[-69.281275,44.808657],[-69.305521,44.895988],[-69.440164,44.87885],[-69.439208,44.845901]]]},"properties":{"id":"04965","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-68.93847,44.565179],[-68.850852,44.41807],[-68.800964,44.462031],[-68.792453,44.496681],[-68.841821,44.611062],[-68.93847,44.565179]]]},"properties":{"id":"04981","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.121622,44.255644],[-69.02725,44.246175],[-69.021484,44.231475],[-68.935358,44.405073],[-68.955529,44.400482],[-69.065647,44.372954],[-69.108524,44.337297],[-69.170895,44.292552],[-69.121622,44.255644]]]},"properties":{"id":"04849","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.665605,44.645044],[-67.77271,44.628578],[-67.766186,44.477796],[-67.647982,44.451796],[-67.647323,44.522192],[-67.639981,44.617451],[-67.665605,44.645044]]]},"properties":{"id":"04606","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.30159,45.050198],[-67.624546,44.997907],[-67.611797,44.975927],[-67.370412,44.861748],[-67.296804,44.96041],[-67.30159,45.050198]]]},"properties":{"id":"04657","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.522037,45.253057],[-67.611729,45.097892],[-67.624546,44.997907],[-67.30159,45.050198],[-67.274585,45.109244],[-67.305593,45.144822],[-67.522037,45.253057]]]},"properties":{"id":"04694","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-67.698594,45.610278],[-67.42514,45.504202],[-67.519216,45.587568],[-67.624449,45.61649],[-67.699275,45.613505],[-67.698594,45.610278]]]},"properties":{"id":"04454","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-70.842719,43.650493],[-70.838497,43.651085],[-70.838442,43.654132],[-70.842719,43.650493]]],[[[-70.852488,43.654712],[-70.838139,43.671234],[-70.838072,43.675367],[-70.868636,43.67472],[-70.852488,43.654712]]],[[[-70.838706,43.625356],[-70.887829,43.674429],[-70.98031,43.684319],[-70.975157,43.61441],[-70.901253,43.580242],[-70.838706,43.625356]]]]},"properties":{"id":"04095","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.720311,43.074613],[-70.679303,43.119389],[-70.733196,43.148318],[-70.767788,43.093595],[-70.720311,43.074613]]]},"properties":{"id":"03904","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.679303,43.119389],[-70.720311,43.074613],[-70.655263,43.099335],[-70.679303,43.119389]]]},"properties":{"id":"03905","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.969714,43.480592],[-70.922838,43.325844],[-70.857239,43.372445],[-70.813471,43.401587],[-70.844482,43.442989],[-70.872075,43.469114],[-70.969714,43.480592]]]},"properties":{"id":"04027","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.989122,43.792113],[-70.98031,43.684319],[-70.887829,43.674429],[-70.868636,43.67472],[-70.838072,43.675367],[-70.839408,43.709172],[-70.852593,43.804735],[-70.880657,43.810858],[-70.989122,43.792113]]]},"properties":{"id":"04047","state_code":"23"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.742523,39.722988],[-78.789527,39.722919],[-78.783389,39.714725],[-78.742523,39.722988]]]},"properties":{"id":"21529","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.668656,39.155209],[-76.687603,39.148774],[-76.697371,39.147475],[-76.738286,39.130495],[-76.715003,39.114206],[-76.677759,39.080523],[-76.655394,39.085058],[-76.641075,39.127824],[-76.668656,39.155209]]]},"properties":{"id":"21144","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.641075,39.127824],[-76.655394,39.085058],[-76.648815,39.040774],[-76.604879,39.07727],[-76.569398,39.097565],[-76.589751,39.121552],[-76.641075,39.127824]]]},"properties":{"id":"21108","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.599854,39.137565],[-76.537935,39.163348],[-76.586755,39.207348],[-76.614258,39.206355],[-76.599854,39.137565]]]},"properties":{"id":"21060","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.659326,39.614671],[-76.569475,39.721197],[-76.654765,39.721105],[-76.653572,39.719227],[-76.651461,39.711062],[-76.751572,39.659731],[-76.755118,39.647926],[-76.780348,39.62125],[-76.747549,39.583233],[-76.668445,39.566841],[-76.659326,39.614671]]]},"properties":{"id":"21120","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.537564,39.364472],[-76.505477,39.361209],[-76.449297,39.366753],[-76.446048,39.370734],[-76.448982,39.379358],[-76.47533,39.422687],[-76.49949,39.42134],[-76.537564,39.364472]]]},"properties":{"id":"21236","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.603864,39.395267],[-76.605431,39.389942],[-76.604494,39.392945],[-76.603864,39.395267]]]},"properties":{"id":"21252","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.743168,39.298946],[-76.796511,39.305045],[-76.764575,39.249078],[-76.722851,39.227187],[-76.714158,39.252063],[-76.716665,39.255944],[-76.711168,39.25985],[-76.706046,39.254628],[-76.699902,39.264513],[-76.720657,39.290745],[-76.743168,39.298946]]]},"properties":{"id":"21228","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.516375,38.590967],[-76.672276,38.552781],[-76.675457,38.535876],[-76.66868,38.512499],[-76.674118,38.499622],[-76.610799,38.427239],[-76.600612,38.452215],[-76.513677,38.536682],[-76.510997,38.590878],[-76.516375,38.590967]]]},"properties":{"id":"20678","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.547391,38.707294],[-76.564061,38.69339],[-76.555114,38.636727],[-76.516375,38.590967],[-76.510997,38.590878],[-76.523829,38.691346],[-76.547391,38.707294]]]},"properties":{"id":"20732","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.891292,39.079825],[-75.890896,39.021072],[-75.875152,39.019847],[-75.740772,39.055831],[-75.742268,39.074562],[-75.809819,39.120403],[-75.856223,39.070567],[-75.865354,39.074443],[-75.891292,39.079825]]]},"properties":{"id":"21640","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.809819,39.120403],[-75.742268,39.074562],[-75.750734,39.180422],[-75.791079,39.132828],[-75.809819,39.120403]]]},"properties":{"id":"21649","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.895628,39.564134],[-76.896352,39.653084],[-76.933702,39.671715],[-77.036618,39.55784],[-77.089571,39.458814],[-77.070178,39.442188],[-76.946456,39.472379],[-76.895628,39.564134]]]},"properties":{"id":"21157","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.185259,39.47804],[-77.238697,39.445853],[-77.244743,39.432521],[-77.245275,39.378403],[-77.21749,39.331409],[-77.165542,39.287296],[-77.139792,39.285028],[-77.070676,39.439514],[-77.070178,39.442188],[-77.089571,39.458814],[-77.185259,39.47804]]]},"properties":{"id":"21771","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.945574,39.699792],[-75.914173,39.510617],[-75.779812,39.540876],[-75.937111,39.721878],[-75.945574,39.699792]],[[-75.864466,39.638492],[-75.866495,39.641811],[-75.857336,39.643809],[-75.857759,39.640154],[-75.864466,39.638492]],[[-75.833622,39.662648],[-75.820137,39.658855],[-75.828542,39.649997],[-75.833622,39.662648]]]},"properties":{"id":"21921","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.864262,39.410761],[-75.86278,39.4552],[-75.932709,39.482412],[-75.993932,39.375848],[-75.919789,39.366948],[-75.882131,39.364327],[-75.882979,39.365208],[-75.901971,39.370874],[-75.880157,39.378253],[-75.871474,39.398426],[-75.876694,39.406119],[-75.864262,39.410761]]]},"properties":{"id":"21919","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.833622,39.662648],[-75.828542,39.649997],[-75.820137,39.658855],[-75.833622,39.662648]]]},"properties":{"id":"21920","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.055839,38.595979],[-77.036901,38.556709],[-76.981004,38.592243],[-77.055839,38.595979]]]},"properties":{"id":"20675","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.864523,38.270868],[-76.929679,38.300768],[-76.864139,38.269519],[-76.864523,38.270868]]]},"properties":{"id":"20645","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.353421,39.534181],[-77.288504,39.499016],[-77.260111,39.50842],[-77.234519,39.539743],[-77.313652,39.580695],[-77.350191,39.563325],[-77.353421,39.534181]]]},"properties":{"id":"21798","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.345317,39.578991],[-76.330616,39.619242],[-76.38291,39.626582],[-76.460366,39.565421],[-76.394515,39.536443],[-76.345317,39.578991]]]},"properties":{"id":"21050","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.041001,39.234191],[-76.984827,39.26414],[-76.985392,39.266096],[-76.998345,39.279032],[-77.046025,39.256247],[-77.041001,39.234191]]]},"properties":{"id":"21737","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.766594,39.377563],[-75.882131,39.364327],[-75.919789,39.366948],[-75.88844,39.29679],[-75.83388,39.29608],[-75.824311,39.35086],[-75.77038,39.3013],[-75.760431,39.30128],[-75.766594,39.377563]]]},"properties":{"id":"21635","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.145416,39.037937],[-77.178993,38.972287],[-77.14862,38.972793],[-77.143035,38.971664],[-77.110107,38.969047],[-77.106628,38.977165],[-77.12635,39.030574],[-77.145416,39.037937]]]},"properties":{"id":"20817","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.079269,39.006896],[-77.086105,39.030982],[-77.096039,39.032604],[-77.12635,39.030574],[-77.106628,38.977165],[-77.079269,39.006896]]]},"properties":{"id":"20814","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.086105,39.030982],[-77.079269,39.006896],[-77.063972,39.011386],[-77.059959,39.024482],[-77.08184,39.043327],[-77.089004,39.04037],[-77.085044,39.032855],[-77.086105,39.030982]]]},"properties":{"id":"20895","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.14862,38.972793],[-77.149973,38.965397],[-77.142227,38.960017],[-77.143035,38.971664],[-77.14862,38.972793]]]},"properties":{"id":"20812","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.956591,39.112832],[-76.950607,39.085788],[-76.928677,39.076723],[-76.907301,39.12549],[-76.918924,39.132168],[-76.958733,39.134315],[-76.959094,39.134149],[-76.956591,39.112832]]]},"properties":{"id":"20866","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.103614,39.150726],[-77.074066,39.121535],[-77.040093,39.132261],[-77.040291,39.161824],[-77.106071,39.181041],[-77.103614,39.150726]]]},"properties":{"id":"20832","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.335647,39.12828],[-77.361648,39.150207],[-77.389144,39.165622],[-77.43565,39.171488],[-77.502081,39.114637],[-77.342108,39.062926],[-77.335647,39.12828]]]},"properties":{"id":"20837","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.14862,38.972793],[-77.178993,38.972287],[-77.179788,38.968326],[-77.149973,38.965397],[-77.14862,38.972793]]]},"properties":{"id":"20818","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.845714,38.916581],[-76.735977,38.901002],[-76.750821,38.950576],[-76.825702,38.947121],[-76.839075,38.947108],[-76.845265,38.930577],[-76.845714,38.916581]]]},"properties":{"id":"20721","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.761312,39.035379],[-76.750821,38.950576],[-76.693912,38.955845],[-76.703743,38.987601],[-76.706119,38.99039],[-76.75865,39.037086],[-76.761312,39.035379]]]},"properties":{"id":"20715","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.917153,39.141424],[-75.791079,39.132828],[-75.750734,39.180422],[-75.754797,39.231163],[-75.913262,39.2143],[-75.952801,39.178714],[-75.917153,39.141424]]]},"properties":{"id":"21668","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.891292,39.079825],[-76.023628,39.174969],[-76.147412,39.099748],[-76.194046,38.98387],[-76.063796,38.946244],[-75.908435,39.009658],[-75.890896,39.021072],[-75.891292,39.079825]]]},"properties":{"id":"21617","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.233924,38.938111],[-76.246916,38.985647],[-76.285756,38.999306],[-76.314341,38.89775],[-76.233924,38.938111]]]},"properties":{"id":"21619","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.433839,38.190178],[-76.430402,38.160395],[-76.434139,38.173036],[-76.420851,38.178626],[-76.433839,38.190178]]]},"properties":{"id":"20686","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.416606,38.105058],[-76.339306,38.119154],[-76.368605,38.132966],[-76.416606,38.105058]]]},"properties":{"id":"20680","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.429813,38.160316],[-76.42204,38.106338],[-76.416606,38.105058],[-76.368605,38.132966],[-76.371194,38.154294],[-76.429813,38.160316]]]},"properties":{"id":"20684","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.076149,38.711324],[-76.166977,38.661981],[-76.152824,38.631793],[-76.02481,38.573413],[-75.97055,38.637016],[-75.945243,38.674114],[-76.00753,38.720981],[-76.076149,38.711324]]]},"properties":{"id":"21673","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.78482,39.53712],[-77.731293,39.550619],[-77.755853,39.570545],[-77.76552,39.56861],[-77.774798,39.561342],[-77.79077,39.53989],[-77.78482,39.53712]]]},"properties":{"id":"21733","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.046491,39.722283],[-78.051783,39.647551],[-77.969135,39.606007],[-77.984724,39.722395],[-78.046491,39.722283]]]},"properties":{"id":"21711","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.695693,39.562706],[-77.708746,39.565156],[-77.71148,39.56209],[-77.695693,39.562706]]]},"properties":{"id":"21746","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.76552,39.56861],[-77.755853,39.570545],[-77.76079,39.57549],[-77.76552,39.56861]]]},"properties":{"id":"21781","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.393657,38.452389],[-75.349807,38.317633],[-75.316292,38.369282],[-75.341291,38.452034],[-75.393657,38.452389]]]},"properties":{"id":"21874","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.709086,38.382989],[-75.718189,38.342585],[-75.701646,38.325638],[-75.689063,38.315622],[-75.647885,38.301109],[-75.613953,38.328277],[-75.564653,38.423622],[-75.630673,38.457809],[-75.674054,38.459376],[-75.68108,38.455932],[-75.709086,38.382989]]]},"properties":{"id":"21801","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.618155,38.303784],[-75.613953,38.328277],[-75.647885,38.301109],[-75.618155,38.303784]]]},"properties":{"id":"21826","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.430602,38.067172],[-75.354434,38.063951],[-75.310515,38.094744],[-75.441718,38.127226],[-75.430602,38.067172]]]},"properties":{"id":"21829","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.567446,38.954315],[-76.588577,38.936069],[-76.593505,38.910038],[-76.51048,38.867414],[-76.497893,38.915773],[-76.538997,38.951822],[-76.567446,38.954315]]]},"properties":{"id":"21037","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.509585,39.076311],[-76.545272,39.040518],[-76.545299,39.040506],[-76.529878,39.028205],[-76.503715,39.006398],[-76.440546,39.059009],[-76.452482,39.06321],[-76.509585,39.076311]]]},"properties":{"id":"21012","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.545299,39.040506],[-76.563995,39.017669],[-76.529878,39.028205],[-76.545299,39.040506]]]},"properties":{"id":"21405","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.764379,39.165555],[-76.790486,39.177665],[-76.842478,39.147649],[-76.832782,39.136545],[-76.812503,39.138646],[-76.760614,39.13045],[-76.751792,39.128108],[-76.764379,39.165555]]]},"properties":{"id":"20794","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.587374,38.78856],[-76.550628,38.766806],[-76.522443,38.790992],[-76.552198,38.814495],[-76.589866,38.824146],[-76.587374,38.78856]]]},"properties":{"id":"20751","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.538101,39.422133],[-76.578934,39.382177],[-76.573515,39.368677],[-76.54385,39.360881],[-76.537564,39.364472],[-76.49949,39.42134],[-76.538101,39.422133]]]},"properties":{"id":"21234","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.448327,39.211566],[-76.442898,39.208984],[-76.450149,39.204425],[-76.393781,39.246707],[-76.445046,39.260743],[-76.490915,39.248031],[-76.448327,39.211566]]]},"properties":{"id":"21219","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.877816,39.52594],[-76.756699,39.553489],[-76.747549,39.583233],[-76.780348,39.62125],[-76.872754,39.554458],[-76.877816,39.52594]]]},"properties":{"id":"21155","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.818068,39.347217],[-76.871282,39.36779],[-76.899172,39.304288],[-76.822937,39.318834],[-76.818068,39.347217]]]},"properties":{"id":"21163","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.933702,39.671715],[-76.896352,39.653084],[-76.755118,39.647926],[-76.751572,39.659731],[-76.771455,39.720803],[-76.945727,39.720501],[-76.933702,39.671715]]]},"properties":{"id":"21102","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.538101,39.422133],[-76.49949,39.42134],[-76.47533,39.422687],[-76.461553,39.435664],[-76.456515,39.460591],[-76.521823,39.48836],[-76.53405,39.489518],[-76.55729,39.449306],[-76.538101,39.422133]]]},"properties":{"id":"21057","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.532943,39.298733],[-76.584297,39.296663],[-76.586588,39.277306],[-76.571887,39.256505],[-76.529486,39.218408],[-76.529668,39.250466],[-76.468965,39.289658],[-76.492585,39.303007],[-76.532943,39.298733]]]},"properties":{"id":"21224","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.706046,39.254628],[-76.711168,39.25985],[-76.716665,39.255944],[-76.714158,39.252063],[-76.706046,39.254628]]]},"properties":{"id":"21250","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.659335,39.47627],[-76.645819,39.48563],[-76.665164,39.493056],[-76.663787,39.474401],[-76.659335,39.47627]]]},"properties":{"id":"21031","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.696851,39.423099],[-76.735098,39.402509],[-76.688458,39.414387],[-76.696851,39.423099]]]},"properties":{"id":"21153","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.458497,39.47735],[-76.442711,39.468708],[-76.452509,39.477116],[-76.458497,39.47735]]]},"properties":{"id":"21051","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.672276,38.552781],[-76.516375,38.590967],[-76.555114,38.636727],[-76.608686,38.662683],[-76.686591,38.630479],[-76.672613,38.617985],[-76.672276,38.552781]]]},"properties":{"id":"20639","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.946224,38.917296],[-75.949343,38.918978],[-75.949335,38.918333],[-75.946224,38.917296]]]},"properties":{"id":"21641","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.256382,39.719888],[-77.266942,39.654517],[-77.196798,39.604742],[-77.125973,39.617326],[-77.066926,39.719943],[-77.256382,39.719888]]]},"properties":{"id":"21787","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.234519,39.539743],[-77.260111,39.50842],[-77.248128,39.487907],[-77.243615,39.47887],[-77.252319,39.475709],[-77.238697,39.445853],[-77.185259,39.47804],[-77.110801,39.568499],[-77.125973,39.617326],[-77.196798,39.604742],[-77.234519,39.539743]]]},"properties":{"id":"21791","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.107999,39.639032],[-76.069766,39.671611],[-76.128071,39.690213],[-76.107999,39.639032]]]},"properties":{"id":"21917","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.159924,38.491679],[-77.249302,38.538796],[-77.113243,38.402129],[-77.150523,38.45408],[-77.159924,38.491679]]]},"properties":{"id":"20662","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.854788,38.496664],[-76.938841,38.415243],[-76.926131,38.395081],[-76.921368,38.385644],[-76.87181,38.388973],[-76.70537,38.501831],[-76.854788,38.496664]]]},"properties":{"id":"20622","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.898406,38.540672],[-75.754121,38.525675],[-75.701569,38.560736],[-75.707444,38.635352],[-75.815238,38.630107],[-75.870925,38.588684],[-75.918472,38.559383],[-75.898406,38.540672]]]},"properties":{"id":"21659","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.918472,38.559383],[-75.980125,38.552516],[-75.950868,38.504521],[-75.898406,38.540672],[-75.918472,38.559383]]]},"properties":{"id":"21835","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.087913,38.27238],[-76.095951,38.309131],[-76.114036,38.294475],[-76.087913,38.27238]]]},"properties":{"id":"21675","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.620986,39.505058],[-77.507941,39.491981],[-77.498964,39.567396],[-77.503896,39.598898],[-77.573722,39.613037],[-77.577751,39.601608],[-77.589308,39.567092],[-77.620986,39.505058]]]},"properties":{"id":"21773","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.50698,39.416702],[-77.503911,39.418928],[-77.504482,39.420226],[-77.50698,39.416702]]]},"properties":{"id":"21714","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.231907,39.361995],[-79.160041,39.408933],[-79.232662,39.441127],[-79.231907,39.361995]]]},"properties":{"id":"21538","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.144081,39.617709],[-76.240168,39.604827],[-76.194638,39.568387],[-76.129328,39.49888],[-76.114602,39.488599],[-76.106161,39.58061],[-76.144081,39.617709]]]},"properties":{"id":"21078","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.259801,39.601454],[-76.25596,39.528336],[-76.194638,39.568387],[-76.240168,39.604827],[-76.259801,39.601454]]]},"properties":{"id":"21028","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.345317,39.578991],[-76.353728,39.497812],[-76.344194,39.486756],[-76.260438,39.476541],[-76.246944,39.481492],[-76.25596,39.528336],[-76.259801,39.601454],[-76.330616,39.619242],[-76.345317,39.578991]]]},"properties":{"id":"21015","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.38291,39.626582],[-76.496733,39.653052],[-76.525689,39.606614],[-76.501946,39.581707],[-76.460366,39.565421],[-76.38291,39.626582]]]},"properties":{"id":"21084","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.897807,39.178404],[-76.892746,39.179328],[-76.900392,39.181727],[-76.897807,39.178404]]],[[[-76.916994,39.170498],[-76.902038,39.161562],[-76.897807,39.178404],[-76.916994,39.170498]]],[[[-76.900627,39.182484],[-76.917049,39.229446],[-76.978377,39.247882],[-77.011201,39.199233],[-77.006243,39.186104],[-76.999911,39.178629],[-76.998348,39.175094],[-76.93374,39.160491],[-76.916994,39.170498],[-76.900627,39.182484]]]]},"properties":{"id":"21029","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.106071,39.181041],[-77.040291,39.161824],[-77.015373,39.166141],[-76.998597,39.16777],[-76.998348,39.175094],[-76.999911,39.178629],[-77.023765,39.175052],[-77.026582,39.192869],[-77.006243,39.186104],[-77.011201,39.199233],[-77.041001,39.234191],[-77.046025,39.256247],[-77.058417,39.273036],[-77.06513,39.250016],[-77.110412,39.183074],[-77.106071,39.181041]]]},"properties":{"id":"20833","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.270583,39.182945],[-76.18485,39.18457],[-76.169982,39.083873],[-76.147412,39.099748],[-76.023628,39.174969],[-75.952801,39.178714],[-75.913262,39.2143],[-75.913367,39.231961],[-75.931166,39.225736],[-75.924403,39.244087],[-75.96469,39.27299],[-76.01329,39.29003],[-76.184314,39.297143],[-76.270583,39.182945]]]},"properties":{"id":"21620","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.927756,39.076414],[-76.960303,39.033569],[-76.943312,39.033024],[-76.907844,39.014786],[-76.849925,39.016366],[-76.84772,39.03382],[-76.878574,39.066933],[-76.927756,39.076414]]]},"properties":{"id":"20705","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.922097,38.864131],[-76.87387,38.830733],[-76.866842,38.839974],[-76.852898,38.857147],[-76.922097,38.864131]]]},"properties":{"id":"20747","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.923922,38.780864],[-76.897705,38.790608],[-76.901622,38.798962],[-76.95811,38.854909],[-76.978173,38.839259],[-76.963803,38.814624],[-76.923922,38.780864]]]},"properties":{"id":"20748","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.851034,38.80645],[-76.897705,38.790608],[-76.923922,38.780864],[-76.98599,38.698683],[-76.966971,38.702997],[-76.85999,38.736473],[-76.843189,38.752964],[-76.851034,38.80645]]]},"properties":{"id":"20735","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.820478,38.956313],[-76.825702,38.947121],[-76.750821,38.950576],[-76.761312,39.035379],[-76.788748,39.045025],[-76.807423,39.005198],[-76.820478,38.956313]]]},"properties":{"id":"20720","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.8679,38.82957],[-76.901622,38.798962],[-76.897705,38.790608],[-76.851034,38.80645],[-76.8679,38.82957]]]},"properties":{"id":"20762","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.951805,38.984784],[-76.946968,38.98223],[-76.939831,38.992857],[-76.946583,38.998707],[-76.956098,38.986554],[-76.951805,38.984784]]]},"properties":{"id":"20742","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.752543,38.370826],[-76.834141,38.299901],[-76.81531,38.286563],[-76.771274,38.302096],[-76.758864,38.315124],[-76.752543,38.370826]]]},"properties":{"id":"20621","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.771274,38.302096],[-76.808811,38.247555],[-76.741251,38.233301],[-76.741109,38.235272],[-76.746749,38.256298],[-76.719633,38.270794],[-76.711806,38.271529],[-76.716294,38.292748],[-76.758864,38.315124],[-76.771274,38.302096]]]},"properties":{"id":"20609","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.323061,38.71594],[-76.34458,38.725247],[-76.351887,38.689161],[-76.316596,38.678153],[-76.323061,38.71594]]]},"properties":{"id":"21671","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.736722,39.387032],[-77.690185,39.38713],[-77.73334,39.50427],[-77.78482,39.53712],[-77.79077,39.53989],[-77.824921,39.528685],[-77.736722,39.387032]]]},"properties":{"id":"21782","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.75365,39.697533],[-77.74226,39.69311],[-77.74299,39.70041],[-77.75365,39.697533]]]},"properties":{"id":"21767","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.984724,39.722395],[-77.969135,39.606007],[-77.867284,39.610051],[-77.848106,39.657252],[-77.847605,39.721969],[-77.984724,39.722395]]]},"properties":{"id":"21722","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.895589,38.335482],[-75.79396,38.26657],[-75.763324,38.285967],[-75.718189,38.342585],[-75.709086,38.382989],[-75.844055,38.384543],[-75.895589,38.335482]]]},"properties":{"id":"21856","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.696526,38.496493],[-75.699122,38.458333],[-75.68108,38.455932],[-75.674054,38.459376],[-75.696526,38.496493]]],[[[-75.444056,38.453058],[-75.630673,38.457809],[-75.564653,38.423622],[-75.530957,38.417378],[-75.444056,38.453058]]]]},"properties":{"id":"21875","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.106558,38.382296],[-75.196327,38.401934],[-75.219017,38.3967],[-75.22782,38.403395],[-75.252433,38.404337],[-75.316292,38.369282],[-75.349807,38.317633],[-75.360875,38.298345],[-75.361526,38.263335],[-75.222737,38.241013],[-75.0931,38.327708],[-75.106558,38.382296]]]},"properties":{"id":"21811","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.361526,38.263335],[-75.249645,38.205581],[-75.222737,38.241013],[-75.361526,38.263335]]]},"properties":{"id":"21841","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.667055,39.265971],[-76.618612,39.237398],[-76.608288,39.254706],[-76.571887,39.256505],[-76.586588,39.277306],[-76.602588,39.279506],[-76.614743,39.282182],[-76.631959,39.285866],[-76.667055,39.265971]]]},"properties":{"id":"21230","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.652072,39.294902],[-76.651958,39.315746],[-76.683718,39.327755],[-76.686302,39.305506],[-76.669873,39.294249],[-76.652072,39.294902]]]},"properties":{"id":"21216","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.586588,39.277306],[-76.584297,39.296663],[-76.59984,39.295081],[-76.602588,39.279506],[-76.586588,39.277306]]]},"properties":{"id":"21231","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.048774,39.72285],[-79.073346,39.650952],[-78.960617,39.599508],[-78.943252,39.595199],[-78.951534,39.587101],[-78.922958,39.558631],[-78.897503,39.581935],[-78.849555,39.660629],[-78.903017,39.722851],[-79.048774,39.72285]],[[-78.949895,39.634628],[-78.972101,39.651785],[-78.955277,39.659141],[-78.949895,39.634628]]]},"properties":{"id":"21532","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.083077,39.598931],[-78.954513,39.523803],[-78.922958,39.558631],[-78.951534,39.587101],[-78.960617,39.599508],[-79.073346,39.650952],[-79.083077,39.598931]]]},"properties":{"id":"21539","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.951534,39.587101],[-78.943252,39.595199],[-78.960617,39.599508],[-78.951534,39.587101]]]},"properties":{"id":"21542","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.962997,39.514389],[-78.973099,39.444749],[-78.84771,39.562268],[-78.897503,39.581935],[-78.922958,39.558631],[-78.954513,39.523803],[-78.962997,39.514389]]]},"properties":{"id":"21557","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.604879,39.07727],[-76.648815,39.040774],[-76.625016,38.988365],[-76.608906,38.986112],[-76.563995,39.017669],[-76.545299,39.040506],[-76.545272,39.040518],[-76.604879,39.07727]]]},"properties":{"id":"21032","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.760614,39.13045],[-76.777942,39.114119],[-76.765388,39.079408],[-76.717051,39.092183],[-76.715003,39.114206],[-76.738286,39.130495],[-76.751792,39.128108],[-76.760614,39.13045]]]},"properties":{"id":"20755","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.639943,39.224362],[-76.641679,39.206347],[-76.614258,39.206355],[-76.586755,39.207348],[-76.608288,39.254706],[-76.618612,39.237398],[-76.639943,39.224362]]]},"properties":{"id":"21225","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.557293,38.849611],[-76.53928,38.839715],[-76.534035,38.845264],[-76.557293,38.849611]]]},"properties":{"id":"20765","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.569475,39.721197],[-76.659326,39.614671],[-76.525689,39.606614],[-76.496733,39.653052],[-76.487334,39.681987],[-76.492981,39.721287],[-76.569475,39.721197]]]},"properties":{"id":"21161","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.450149,39.204425],[-76.442898,39.208984],[-76.448327,39.211566],[-76.450149,39.204425]]]},"properties":{"id":"21052","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.454489,38.346892],[-76.45187,38.321726],[-76.446863,38.337274],[-76.454489,38.346892]]]},"properties":{"id":"20629","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.853221,38.766634],[-75.897178,38.815212],[-76.00753,38.720981],[-75.945243,38.674114],[-75.860893,38.700191],[-75.853221,38.766634]]]},"properties":{"id":"21655","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.946456,39.472379],[-76.886827,39.424298],[-76.877816,39.52594],[-76.872754,39.554458],[-76.895628,39.564134],[-76.946456,39.472379]]]},"properties":{"id":"21048","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.107999,39.639032],[-76.163601,39.651297],[-76.144081,39.617709],[-76.106161,39.58061],[-76.098086,39.5843],[-76.030213,39.606153],[-75.99284,39.620952],[-76.069766,39.671611],[-76.107999,39.639032]]]},"properties":{"id":"21904","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.088343,38.510083],[-77.028457,38.445096],[-77.02252,38.45209],[-77.007588,38.477374],[-77.088343,38.510083]]]},"properties":{"id":"20677","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.840708,38.266668],[-76.864523,38.270868],[-76.864139,38.269519],[-76.840708,38.266668]]]},"properties":{"id":"20625","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.162758,38.505646],[-76.204605,38.44712],[-76.262771,38.488327],[-76.289919,38.419037],[-76.26451,38.346354],[-76.211982,38.331577],[-76.17144,38.342945],[-76.067298,38.381862],[-76.162758,38.505646]]]},"properties":{"id":"21622","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.095951,38.309131],[-76.087913,38.27238],[-76.066571,38.236457],[-76.04824,38.244792],[-76.033058,38.217121],[-76.049842,38.307296],[-76.095951,38.309131]]]},"properties":{"id":"21672","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.288504,39.499016],[-77.353421,39.534181],[-77.376959,39.507055],[-77.288504,39.499016]]]},"properties":{"id":"21793","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.621115,39.386643],[-77.621802,39.396937],[-77.63528,39.397169],[-77.633651,39.389423],[-77.621115,39.386643]]]},"properties":{"id":"21718","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.998348,39.175094],[-76.998597,39.16777],[-76.970392,39.148724],[-76.962973,39.14584],[-76.93374,39.160491],[-76.998348,39.175094]]]},"properties":{"id":"20777","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.110107,38.969047],[-77.143035,38.971664],[-77.142227,38.960017],[-77.090862,38.956767],[-77.110107,38.969047]]]},"properties":{"id":"20816","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.302856,39.087913],[-77.232406,39.160858],[-77.239227,39.164752],[-77.273162,39.206756],[-77.335647,39.12828],[-77.342108,39.062926],[-77.292942,39.046378],[-77.302856,39.087913]]]},"properties":{"id":"20874","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.989936,39.038276],[-76.995384,38.999626],[-76.989485,38.990762],[-76.962097,39.031208],[-76.989936,39.038276]]]},"properties":{"id":"20903","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.013671,39.134441],[-77.015373,39.166141],[-77.040291,39.161824],[-77.040093,39.132261],[-77.033797,39.12064],[-77.013671,39.134441]]]},"properties":{"id":"20860","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.959094,39.134149],[-76.989349,39.125194],[-76.956591,39.112832],[-76.959094,39.134149]]]},"properties":{"id":"20868","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.267413,39.308296],[-77.23413,39.246913],[-77.210998,39.239406],[-77.165542,39.287296],[-77.21749,39.331409],[-77.262748,39.310485],[-77.267413,39.308296]]]},"properties":{"id":"20872","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.922097,38.864131],[-76.946759,38.863742],[-76.95811,38.854909],[-76.901622,38.798962],[-76.8679,38.82957],[-76.87387,38.830733],[-76.922097,38.864131]]]},"properties":{"id":"20746","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.873537,38.978248],[-76.893477,38.982234],[-76.893413,38.972291],[-76.873537,38.978248]]],[[[-76.873537,38.978248],[-76.865382,38.956203],[-76.851896,38.91733],[-76.845714,38.916581],[-76.845265,38.930577],[-76.839075,38.947108],[-76.825702,38.947121],[-76.820478,38.956313],[-76.84293,38.992134],[-76.873537,38.978248]]]]},"properties":{"id":"20706","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.246916,38.985647],[-76.233924,38.938111],[-76.202286,38.847396],[-76.200915,38.848971],[-76.204135,38.978384],[-76.246916,38.985647]]]},"properties":{"id":"21638","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.285756,38.999306],[-76.35262,38.968461],[-76.314341,38.89775],[-76.285756,38.999306]]]},"properties":{"id":"21666","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.601599,38.424113],[-76.654536,38.353935],[-76.572903,38.284926],[-76.485047,38.335895],[-76.483223,38.34329],[-76.506271,38.375096],[-76.601599,38.424113]]]},"properties":{"id":"20636","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.472918,38.215154],[-76.414381,38.218983],[-76.453788,38.204838],[-76.453448,38.204165],[-76.440329,38.193661],[-76.433839,38.190178],[-76.420851,38.178626],[-76.434139,38.173036],[-76.430402,38.160395],[-76.429813,38.160316],[-76.371194,38.154294],[-76.378479,38.226299],[-76.389518,38.263422],[-76.454295,38.305332],[-76.467872,38.318412],[-76.507814,38.250873],[-76.472918,38.215154]]]},"properties":{"id":"20653","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.468115,38.007697],[-75.430602,38.067172],[-75.441718,38.127226],[-75.547027,38.181403],[-75.601721,38.144765],[-75.661224,38.044162],[-75.624343,37.994208],[-75.468115,38.007697]]]},"properties":{"id":"21851","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.657524,39.467393],[-77.73334,39.50427],[-77.690185,39.38713],[-77.671065,39.415398],[-77.657524,39.467393]]]},"properties":{"id":"21756","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.046491,39.722283],[-78.342834,39.722263],[-78.333819,39.636762],[-78.051783,39.647551],[-78.046491,39.722283]]]},"properties":{"id":"21750","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.657524,39.467393],[-77.671065,39.415398],[-77.656177,39.368397],[-77.640068,39.405767],[-77.621025,39.464981],[-77.657524,39.467393]]]},"properties":{"id":"21779","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.410763,38.4525],[-75.444056,38.453058],[-75.530957,38.417378],[-75.445191,38.294407],[-75.410763,38.4525]]]},"properties":{"id":"21849","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.925492,38.266857],[-75.874654,38.220013],[-75.869647,38.26801],[-75.929067,38.286949],[-75.925492,38.266857]]]},"properties":{"id":"21840","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.106558,38.382296],[-75.0931,38.327708],[-75.073218,38.451285],[-75.106558,38.382296]]]},"properties":{"id":"21842","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.586178,39.345856],[-76.576952,39.338625],[-76.573015,39.353903],[-76.586178,39.345856]]]},"properties":{"id":"21251","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.480643,38.977038],[-76.538997,38.951822],[-76.497893,38.915773],[-76.469805,38.977612],[-76.480643,38.977038]]]},"properties":{"id":"21403","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.440546,39.059009],[-76.503715,39.006398],[-76.488583,38.994111],[-76.448761,38.986468],[-76.414638,39.049403],[-76.440546,39.059009]]]},"properties":{"id":"21409","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.673622,39.162195],[-76.694633,39.158481],[-76.687603,39.148774],[-76.668656,39.155209],[-76.673622,39.162195]]],[[[-76.694791,39.192807],[-76.696392,39.215278],[-76.697085,39.214092],[-76.764379,39.165555],[-76.751792,39.128108],[-76.738286,39.130495],[-76.697371,39.147475],[-76.693957,39.178249],[-76.694791,39.192807]]]]},"properties":{"id":"21076","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.697503,38.844786],[-76.589871,38.829894],[-76.557293,38.849611],[-76.534035,38.845264],[-76.50664,38.86279],[-76.51048,38.867414],[-76.593505,38.910038],[-76.676276,38.895163],[-76.691169,38.854401],[-76.697503,38.844786]]]},"properties":{"id":"20776","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.786843,39.388163],[-76.765484,39.367481],[-76.746666,39.359728],[-76.720798,39.361842],[-76.700082,39.370749],[-76.665996,39.397239],[-76.688458,39.414387],[-76.735098,39.402509],[-76.786843,39.388163]]]},"properties":{"id":"21208","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.449297,39.366753],[-76.505477,39.361209],[-76.543118,39.307352],[-76.532943,39.298733],[-76.492585,39.303007],[-76.474946,39.339618],[-76.449297,39.366753]]]},"properties":{"id":"21237","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.548938,39.315888],[-76.543118,39.307352],[-76.505477,39.361209],[-76.537564,39.364472],[-76.54385,39.360881],[-76.575221,39.335243],[-76.576517,39.333856],[-76.548938,39.315888]]]},"properties":{"id":"21206","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.474946,39.339618],[-76.379345,39.293581],[-76.355781,39.388806],[-76.446048,39.370734],[-76.449297,39.366753],[-76.474946,39.339618]]]},"properties":{"id":"21220","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.726748,39.533732],[-76.756699,39.553489],[-76.877816,39.52594],[-76.886827,39.424298],[-76.884117,39.396568],[-76.705078,39.456821],[-76.703158,39.459439],[-76.726748,39.533732]],[[-76.793758,39.485076],[-76.819399,39.474581],[-76.814699,39.492203],[-76.793758,39.485076]]]},"properties":{"id":"21136","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.618612,39.237398],[-76.667055,39.265971],[-76.671489,39.273887],[-76.699902,39.264513],[-76.706046,39.254628],[-76.714158,39.252063],[-76.722851,39.227187],[-76.697085,39.214092],[-76.696392,39.215278],[-76.639943,39.224362],[-76.618612,39.237398]]]},"properties":{"id":"21227","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.468965,39.289658],[-76.529668,39.250466],[-76.490915,39.248031],[-76.445046,39.260743],[-76.468965,39.289658]]]},"properties":{"id":"21222","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.597386,39.381426],[-76.599935,39.342743],[-76.586178,39.345856],[-76.573015,39.353903],[-76.573515,39.368677],[-76.578934,39.382177],[-76.597386,39.381426]]]},"properties":{"id":"21239","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.555114,38.636727],[-76.564061,38.69339],[-76.608686,38.662683],[-76.555114,38.636727]]]},"properties":{"id":"20689","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.110801,39.568499],[-77.185259,39.47804],[-77.089571,39.458814],[-77.036618,39.55784],[-77.110801,39.568499]]]},"properties":{"id":"21776","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.088343,38.510083],[-77.150523,38.45408],[-77.113243,38.402129],[-77.029659,38.441039],[-77.028457,38.445096],[-77.088343,38.510083]]]},"properties":{"id":"20693","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.864523,38.270868],[-76.840708,38.266668],[-76.921368,38.385644],[-76.926131,38.395081],[-76.986951,38.446632],[-77.02252,38.45209],[-77.028457,38.445096],[-77.029659,38.441039],[-76.929679,38.300768],[-76.864523,38.270868]]]},"properties":{"id":"20664","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.981004,38.592243],[-76.92274,38.575841],[-76.937508,38.598852],[-77.04781,38.616171],[-77.055839,38.595979],[-76.981004,38.592243]]]},"properties":{"id":"20695","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.902107,38.527382],[-76.818018,38.558943],[-76.853004,38.559755],[-76.886765,38.538538],[-76.902107,38.527382]]]},"properties":{"id":"20617","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.162758,38.505646],[-76.232147,38.538722],[-76.204605,38.44712],[-76.162758,38.505646]]]},"properties":{"id":"21677","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.950868,38.504521],[-75.964057,38.344608],[-75.925492,38.266857],[-75.929067,38.286949],[-75.912098,38.319052],[-75.895589,38.335482],[-75.844055,38.384543],[-75.8393,38.388771],[-75.757059,38.513363],[-75.754121,38.525675],[-75.898406,38.540672],[-75.950868,38.504521]]]},"properties":{"id":"21869","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.095951,38.309131],[-76.049842,38.307296],[-76.010734,38.355007],[-76.067298,38.381862],[-76.17144,38.342945],[-76.114036,38.294475],[-76.095951,38.309131]]]},"properties":{"id":"21626","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.26451,38.346354],[-76.261701,38.322334],[-76.211982,38.331577],[-76.26451,38.346354]]]},"properties":{"id":"21634","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.519134,39.280633],[-77.460508,39.258696],[-77.393532,39.304737],[-77.432269,39.332473],[-77.439264,39.332275],[-77.519674,39.302453],[-77.528412,39.290931],[-77.519134,39.280633]]]},"properties":{"id":"21710","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.43481,39.415417],[-77.504581,39.424842],[-77.504482,39.420226],[-77.503911,39.418928],[-77.50698,39.416702],[-77.51126,39.412727],[-77.519674,39.302453],[-77.439264,39.332275],[-77.438511,39.341128],[-77.410301,39.402351],[-77.43481,39.415417]]]},"properties":{"id":"21703","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.528412,39.290931],[-77.546471,39.272228],[-77.480332,39.24117],[-77.475466,39.243591],[-77.519134,39.280633],[-77.528412,39.290931]]]},"properties":{"id":"21777","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.504581,39.424842],[-77.43481,39.415417],[-77.411977,39.516172],[-77.498964,39.567396],[-77.507941,39.491981],[-77.504581,39.424842]]]},"properties":{"id":"21702","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.50836,39.684072],[-77.469145,39.720018],[-77.50691,39.719943],[-77.50836,39.684072]]]},"properties":{"id":"21719","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.356602,39.653532],[-77.31129,39.639641],[-77.266942,39.654517],[-77.256382,39.719888],[-77.40742,39.719859],[-77.408046,39.682853],[-77.356602,39.653532]]]},"properties":{"id":"21727","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.06799,39.479085],[-79.082963,39.486382],[-79.075155,39.473237],[-79.06799,39.479085]]]},"properties":{"id":"21523","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.460366,39.565421],[-76.501946,39.581707],[-76.506769,39.551683],[-76.420908,39.478452],[-76.389447,39.482913],[-76.373012,39.509369],[-76.394515,39.536443],[-76.460366,39.565421]]]},"properties":{"id":"21047","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.340447,39.688598],[-76.414841,39.721175],[-76.492981,39.721287],[-76.487334,39.681987],[-76.340447,39.688598]]]},"properties":{"id":"21132","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.174611,39.4909],[-76.114602,39.488599],[-76.129328,39.49888],[-76.174611,39.4909]]]},"properties":{"id":"21005","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.011201,39.199233],[-76.978377,39.247882],[-76.984827,39.26414],[-77.041001,39.234191],[-77.011201,39.199233]]]},"properties":{"id":"21036","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.798848,39.184397],[-76.863789,39.196963],[-76.884414,39.16867],[-76.842478,39.147649],[-76.790486,39.177665],[-76.798848,39.184397]]]},"properties":{"id":"21046","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.0238,39.32327],[-76.020644,39.368702],[-76.029885,39.37091],[-76.0819,39.35789],[-76.0238,39.32327]]]},"properties":{"id":"21667","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.20657,39.156394],[-77.194657,39.164925],[-77.15557,39.185302],[-77.199584,39.197631],[-77.21537,39.187313],[-77.221923,39.174526],[-77.20657,39.156394]]]},"properties":{"id":"20886","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.157585,39.06705],[-77.252253,39.074792],[-77.302856,39.087913],[-77.292942,39.046378],[-77.179788,38.968326],[-77.178993,38.972287],[-77.145416,39.037937],[-77.157585,39.06705]]]},"properties":{"id":"20854","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.099587,39.063542],[-77.089004,39.04037],[-77.08184,39.043327],[-77.029342,39.066497],[-77.025594,39.088994],[-77.033797,39.12064],[-77.040093,39.132261],[-77.074066,39.121535],[-77.099587,39.063542]]]},"properties":{"id":"20906","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.026596,38.984512],[-77.002388,38.965827],[-76.989485,38.990762],[-76.995384,38.999626],[-77.006263,38.995624],[-77.026596,38.984512]]]},"properties":{"id":"20912","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.109184,39.07183],[-77.13488,39.074752],[-77.157585,39.06705],[-77.145416,39.037937],[-77.12635,39.030574],[-77.096039,39.032604],[-77.085044,39.032855],[-77.089004,39.04037],[-77.099587,39.063542],[-77.109184,39.07183]]]},"properties":{"id":"20852","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.210998,39.239406],[-77.203926,39.201313],[-77.199584,39.197631],[-77.15557,39.185302],[-77.130523,39.172027],[-77.110412,39.183074],[-77.06513,39.250016],[-77.139792,39.285028],[-77.165542,39.287296],[-77.210998,39.239406]]]},"properties":{"id":"20882","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.214332,39.140685],[-77.215339,39.145866],[-77.219093,39.14701],[-77.220031,39.14516],[-77.216461,39.144336],[-77.217628,39.141727],[-77.214332,39.140685]]]},"properties":{"id":"20899","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.829498,38.732538],[-76.843189,38.752964],[-76.85999,38.736473],[-76.829498,38.732538]]]},"properties":{"id":"20623","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.878574,39.066933],[-76.84772,39.03382],[-76.807423,39.005198],[-76.788748,39.045025],[-76.830339,39.067852],[-76.832375,39.085766],[-76.878574,39.066933]]]},"properties":{"id":"20708","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.829498,38.732538],[-76.700997,38.709244],[-76.686358,38.748475],[-76.697503,38.844786],[-76.691169,38.854401],[-76.866842,38.839974],[-76.87387,38.830733],[-76.8679,38.82957],[-76.851034,38.80645],[-76.843189,38.752964],[-76.829498,38.732538]]]},"properties":{"id":"20772","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.930909,38.933479],[-76.909422,38.953109],[-76.915626,38.951782],[-76.930909,38.933479]]]},"properties":{"id":"20710","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.890499,39.130812],[-75.856223,39.070567],[-75.809819,39.120403],[-75.791079,39.132828],[-75.917153,39.141424],[-75.890499,39.130812]]]},"properties":{"id":"21607","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.023628,39.174969],[-75.891292,39.079825],[-75.865354,39.074443],[-75.890499,39.130812],[-75.917153,39.141424],[-75.952801,39.178714],[-76.023628,39.174969]]]},"properties":{"id":"21623","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.453788,38.204838],[-76.414381,38.218983],[-76.472918,38.215154],[-76.453788,38.204838]]]},"properties":{"id":"20667","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.507814,38.250873],[-76.467872,38.318412],[-76.485047,38.335895],[-76.572903,38.284926],[-76.54037,38.253547],[-76.517493,38.246778],[-76.507814,38.250873]]]},"properties":{"id":"20619","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.368605,38.132966],[-76.339306,38.119154],[-76.325429,38.113503],[-76.378479,38.226299],[-76.371194,38.154294],[-76.368605,38.132966]]]},"properties":{"id":"20628","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.771274,38.302096],[-76.81531,38.286563],[-76.80922,38.247511],[-76.808811,38.247555],[-76.771274,38.302096]]]},"properties":{"id":"20618","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.454295,38.305332],[-76.389518,38.263422],[-76.41326,38.315356],[-76.447253,38.305174],[-76.454295,38.305332]]]},"properties":{"id":"20670","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.842717,38.029806],[-75.878157,37.969794],[-75.814634,37.952161],[-75.842717,38.029806]]]},"properties":{"id":"21817","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.877714,38.175449],[-75.874652,38.205213],[-75.942269,38.191113],[-75.960656,38.129632],[-75.877714,38.175449]]]},"properties":{"id":"21821","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.832016,38.137007],[-75.818971,38.055841],[-75.661224,38.044162],[-75.601721,38.144765],[-75.832016,38.137007]],[[-75.789456,38.103342],[-75.797927,38.10707],[-75.789897,38.111722],[-75.789456,38.103342]]]},"properties":{"id":"21871","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.92511,38.837603],[-76.079473,38.890102],[-76.109409,38.900694],[-76.200915,38.848971],[-76.202286,38.847396],[-76.214293,38.826222],[-76.184787,38.755154],[-76.172615,38.753373],[-76.180261,38.745816],[-76.182179,38.751049],[-76.197875,38.740653],[-76.188043,38.673032],[-76.18491,38.673081],[-76.076149,38.711324],[-76.00753,38.720981],[-75.897178,38.815212],[-75.92511,38.837603]]]},"properties":{"id":"21601","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.079473,38.890102],[-75.92511,38.837603],[-75.946224,38.917296],[-75.949335,38.918333],[-76.048813,38.926365],[-76.079473,38.890102]]]},"properties":{"id":"21625","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.303043,38.719875],[-76.281395,38.726326],[-76.266722,38.707728],[-76.241611,38.735619],[-76.237382,38.744748],[-76.271747,38.783384],[-76.294219,38.769508],[-76.308805,38.729695],[-76.303043,38.719875]]]},"properties":{"id":"21612","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.182179,38.751049],[-76.180261,38.745816],[-76.172615,38.753373],[-76.184787,38.755154],[-76.182179,38.751049]]]},"properties":{"id":"21653","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.912098,38.319052],[-75.869647,38.26801],[-75.874654,38.220013],[-75.79396,38.26657],[-75.895589,38.335482],[-75.912098,38.319052]]]},"properties":{"id":"21865","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.699122,38.458333],[-75.8393,38.388771],[-75.844055,38.384543],[-75.709086,38.382989],[-75.68108,38.455932],[-75.699122,38.458333]]]},"properties":{"id":"21830","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.632592,39.295689],[-76.620251,39.310972],[-76.621279,39.311928],[-76.657516,39.325366],[-76.651958,39.315746],[-76.652072,39.294902],[-76.632592,39.295689]]]},"properties":{"id":"21217","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.615932,39.311071],[-76.614743,39.282182],[-76.602588,39.279506],[-76.59984,39.295081],[-76.600402,39.303008],[-76.599057,39.312961],[-76.615932,39.311071]]]},"properties":{"id":"21202","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.632592,39.295689],[-76.652072,39.294902],[-76.669873,39.294249],[-76.671489,39.273887],[-76.667055,39.265971],[-76.631959,39.285866],[-76.632592,39.295689]]]},"properties":{"id":"21223","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.633724,39.722912],[-78.742523,39.722988],[-78.783389,39.714725],[-78.803506,39.691808],[-78.849555,39.660629],[-78.897503,39.581935],[-78.84771,39.562268],[-78.72565,39.566609],[-78.655566,39.639727],[-78.633724,39.722912]]]},"properties":{"id":"21502","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.903017,39.722851],[-78.849555,39.660629],[-78.803506,39.691808],[-78.815374,39.722898],[-78.903017,39.722851]]]},"properties":{"id":"21545","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.452302,39.653217],[-78.655566,39.639727],[-78.72565,39.566609],[-78.420422,39.60326],[-78.452302,39.653217]]]},"properties":{"id":"21555","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.949895,39.634628],[-78.955277,39.659141],[-78.972101,39.651785],[-78.949895,39.634628]]]},"properties":{"id":"21543","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.601007,38.77017],[-76.593571,38.752078],[-76.562932,38.733598],[-76.547707,38.750118],[-76.550628,38.766806],[-76.587374,38.78856],[-76.601007,38.77017]]]},"properties":{"id":"20779","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.586755,39.207348],[-76.537935,39.163348],[-76.512872,39.174142],[-76.529486,39.218408],[-76.571887,39.256505],[-76.608288,39.254706],[-76.586755,39.207348]]]},"properties":{"id":"21226","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.488583,38.994111],[-76.480643,38.977038],[-76.469805,38.977612],[-76.448761,38.986468],[-76.488583,38.994111]]]},"properties":{"id":"21402","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.693957,39.178249],[-76.697371,39.147475],[-76.687603,39.148774],[-76.694633,39.158481],[-76.693957,39.178249]]]},"properties":{"id":"21077","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.623041,38.749088],[-76.566436,38.721318],[-76.562932,38.733598],[-76.593571,38.752078],[-76.623041,38.749088]]]},"properties":{"id":"20758","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.601007,38.77017],[-76.686358,38.748475],[-76.700997,38.709244],[-76.691947,38.683765],[-76.623041,38.749088],[-76.593571,38.752078],[-76.601007,38.77017]]]},"properties":{"id":"20754","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.765484,39.367481],[-76.818068,39.347217],[-76.822937,39.318834],[-76.816451,39.313176],[-76.796511,39.305045],[-76.743168,39.298946],[-76.746666,39.359728],[-76.765484,39.367481]]]},"properties":{"id":"21244","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.599935,39.342743],[-76.597386,39.381426],[-76.607032,39.383869],[-76.643259,39.382896],[-76.644788,39.378966],[-76.617127,39.34533],[-76.599935,39.342743]]]},"properties":{"id":"21212","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.420908,39.478452],[-76.506769,39.551683],[-76.5196,39.528091],[-76.53405,39.489518],[-76.521823,39.48836],[-76.458497,39.47735],[-76.452509,39.477116],[-76.442711,39.468708],[-76.44173,39.468126],[-76.420908,39.478452]]]},"properties":{"id":"21013","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.659335,39.47627],[-76.580725,39.462816],[-76.624217,39.523036],[-76.726748,39.533732],[-76.703158,39.459439],[-76.663787,39.474401],[-76.665164,39.493056],[-76.645819,39.48563],[-76.659335,39.47627]]]},"properties":{"id":"21030","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.385953,39.412083],[-76.320026,39.404407],[-76.321097,39.451252],[-76.344194,39.486756],[-76.353728,39.497812],[-76.373012,39.509369],[-76.389447,39.482913],[-76.385953,39.412083]]]},"properties":{"id":"21085","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.450996,38.450513],[-76.492306,38.489812],[-76.600612,38.452215],[-76.610799,38.427239],[-76.601599,38.424113],[-76.506271,38.375096],[-76.450996,38.450513]],[[-76.551254,38.429954],[-76.540064,38.423081],[-76.545463,38.411647],[-76.55554,38.427115],[-76.551254,38.429954]]]},"properties":{"id":"20685","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.483223,38.34329],[-76.454489,38.346892],[-76.446863,38.337274],[-76.45187,38.321726],[-76.447253,38.305174],[-76.41326,38.315356],[-76.450996,38.450513],[-76.506271,38.375096],[-76.483223,38.34329]]]},"properties":{"id":"20657","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.313652,39.580695],[-77.234519,39.539743],[-77.196798,39.604742],[-77.266942,39.654517],[-77.31129,39.639641],[-77.313652,39.580695]]]},"properties":{"id":"21757","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.880157,39.378253],[-75.882979,39.365208],[-75.882131,39.364327],[-75.766594,39.377563],[-75.776035,39.494326],[-75.86278,39.4552],[-75.864262,39.410761],[-75.871474,39.398426],[-75.880157,39.378253]]]},"properties":{"id":"21912","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.864466,39.638492],[-75.857759,39.640154],[-75.857336,39.643809],[-75.866495,39.641811],[-75.864466,39.638492]]]},"properties":{"id":"21916","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.880157,39.378253],[-75.901971,39.370874],[-75.882979,39.365208],[-75.880157,39.378253]]]},"properties":{"id":"21930","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.986951,38.446632],[-76.926131,38.395081],[-76.938841,38.415243],[-76.943205,38.470864],[-76.986951,38.446632]]]},"properties":{"id":"20632","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.70537,38.501831],[-76.87181,38.388973],[-76.834141,38.299901],[-76.752543,38.370826],[-76.709821,38.355613],[-76.707689,38.361319],[-76.709142,38.377852],[-76.69417,38.374413],[-76.654536,38.353935],[-76.601599,38.424113],[-76.610799,38.427239],[-76.674118,38.499622],[-76.682811,38.495972],[-76.70537,38.501831]]]},"properties":{"id":"20659","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.169912,38.574164],[-77.180403,38.55446],[-77.144625,38.555838],[-77.15043,38.576998],[-77.169912,38.574164]]]},"properties":{"id":"20658","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.66868,38.512499],[-76.682811,38.495972],[-76.674118,38.499622],[-76.66868,38.512499]]]},"properties":{"id":"20612","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.870925,38.588684],[-75.97055,38.637016],[-76.02481,38.573413],[-75.980125,38.552516],[-75.918472,38.559383],[-75.870925,38.588684]],[[-75.95346,38.609809],[-75.935966,38.605712],[-75.943702,38.601081],[-75.95346,38.609809]]]},"properties":{"id":"21631","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.280853,38.52267],[-76.262771,38.488327],[-76.204605,38.44712],[-76.232147,38.538722],[-76.280853,38.52267]]]},"properties":{"id":"21648","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.262771,38.488327],[-76.280853,38.52267],[-76.284158,38.523947],[-76.289919,38.419037],[-76.262771,38.488327]]]},"properties":{"id":"21669","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.355566,39.283914],[-77.32527,39.392245],[-77.410301,39.402351],[-77.438511,39.341128],[-77.432269,39.332473],[-77.393532,39.304737],[-77.355566,39.283914]]]},"properties":{"id":"21704","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.519134,39.280633],[-77.475466,39.243591],[-77.460508,39.258696],[-77.519134,39.280633]]]},"properties":{"id":"21790","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.621025,39.464981],[-77.640068,39.405767],[-77.51126,39.412727],[-77.50698,39.416702],[-77.504482,39.420226],[-77.504581,39.424842],[-77.507941,39.491981],[-77.620986,39.505058],[-77.621025,39.464981]]]},"properties":{"id":"21769","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.275969,39.380603],[-77.262748,39.310485],[-77.21749,39.331409],[-77.245275,39.378403],[-77.275969,39.380603]]]},"properties":{"id":"21770","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.459821,39.631328],[-77.503896,39.598898],[-77.498964,39.567396],[-77.411977,39.516172],[-77.376959,39.507055],[-77.353421,39.534181],[-77.350191,39.563325],[-77.356602,39.653532],[-77.408046,39.682853],[-77.459821,39.631328]]]},"properties":{"id":"21788","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.411609,39.40924],[-77.408596,39.407939],[-77.408282,39.409962],[-77.411609,39.40924]]]},"properties":{"id":"21705","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.371321,39.574457],[-79.30559,39.548145],[-79.232209,39.586915],[-79.23014,39.590974],[-79.247487,39.626078],[-79.345047,39.708558],[-79.371321,39.574457]]]},"properties":{"id":"21520","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.231907,39.361995],[-79.232662,39.441127],[-79.324445,39.520213],[-79.44908,39.557125],[-79.478657,39.556142],[-79.231907,39.361995]]]},"properties":{"id":"21550","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.129328,39.49888],[-76.194638,39.568387],[-76.25596,39.528336],[-76.246944,39.481492],[-76.219858,39.475204],[-76.247025,39.444542],[-76.243346,39.436968],[-76.174611,39.4909],[-76.129328,39.49888]],[[-76.20287,39.466797],[-76.191156,39.48816],[-76.181675,39.484436],[-76.20287,39.466797]]]},"properties":{"id":"21001","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.280865,39.679993],[-76.259801,39.601454],[-76.240168,39.604827],[-76.144081,39.617709],[-76.163601,39.651297],[-76.224277,39.702904],[-76.280865,39.679993]]]},"properties":{"id":"21034","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.340447,39.688598],[-76.280865,39.679993],[-76.224277,39.702904],[-76.233279,39.721367],[-76.414841,39.721175],[-76.340447,39.688598]]]},"properties":{"id":"21160","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.20287,39.466797],[-76.181675,39.484436],[-76.191156,39.48816],[-76.20287,39.466797]]]},"properties":{"id":"21130","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.009434,39.309699],[-76.991049,39.321241],[-77.013791,39.335872],[-77.028589,39.310127],[-77.009434,39.309699]]]},"properties":{"id":"21723","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.907301,39.12549],[-76.840362,39.103142],[-76.816148,39.121428],[-76.832782,39.136545],[-76.842478,39.147649],[-76.884414,39.16867],[-76.892746,39.179328],[-76.897807,39.178404],[-76.902038,39.161562],[-76.918924,39.132168],[-76.907301,39.12549]]]},"properties":{"id":"20723","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.0238,39.32327],[-76.01329,39.29003],[-75.96469,39.27299],[-75.88844,39.29679],[-75.919789,39.366948],[-75.993932,39.375848],[-76.020644,39.368702],[-76.0238,39.32327]]]},"properties":{"id":"21645","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.187583,39.110794],[-77.216926,39.14875],[-77.232406,39.160858],[-77.302856,39.087913],[-77.252253,39.074792],[-77.187583,39.110794]],[[-77.214332,39.140685],[-77.217628,39.141727],[-77.216461,39.144336],[-77.220031,39.14516],[-77.219093,39.14701],[-77.215339,39.145866],[-77.214332,39.140685]]]},"properties":{"id":"20878","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.20657,39.156394],[-77.216926,39.14875],[-77.187583,39.110794],[-77.174746,39.120714],[-77.173483,39.136008],[-77.179313,39.138244],[-77.177001,39.14244],[-77.165471,39.137815],[-77.149696,39.151138],[-77.194657,39.164925],[-77.20657,39.156394]]]},"properties":{"id":"20877","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.032859,39.016132],[-77.006263,38.995624],[-76.995384,38.999626],[-76.989936,39.038276],[-77.009258,39.042661],[-77.032859,39.016132]]]},"properties":{"id":"20901","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.110107,38.969047],[-77.090862,38.956767],[-77.046513,38.991324],[-77.063972,39.011386],[-77.079269,39.006896],[-77.106628,38.977165],[-77.110107,38.969047]]]},"properties":{"id":"20815","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.165471,39.137815],[-77.177001,39.14244],[-77.179313,39.138244],[-77.173483,39.136008],[-77.165471,39.137815]]]},"properties":{"id":"20880","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.361648,39.150207],[-77.335647,39.12828],[-77.273162,39.206756],[-77.345136,39.233396],[-77.370994,39.220638],[-77.361648,39.150207]]]},"properties":{"id":"20841","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.963803,38.814624],[-77.017487,38.784185],[-77.004446,38.696373],[-76.98599,38.698683],[-76.923922,38.780864],[-76.963803,38.814624]]]},"properties":{"id":"20744","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.922097,38.864131],[-76.852898,38.857147],[-76.847344,38.892164],[-76.924697,38.91323],[-76.939567,38.916735],[-76.946759,38.863742],[-76.922097,38.864131]]]},"properties":{"id":"20743","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.99723,38.961616],[-76.978628,38.947021],[-76.957941,38.948807],[-76.956372,38.948976],[-76.942611,38.965869],[-76.938072,38.971984],[-76.946968,38.98223],[-76.951805,38.984784],[-76.99723,38.961616]]]},"properties":{"id":"20782","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.941923,38.918584],[-76.956372,38.948976],[-76.957941,38.948807],[-76.958784,38.93155],[-76.941923,38.918584]]]},"properties":{"id":"20722","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.978173,38.839259],[-77.017487,38.784185],[-76.963803,38.814624],[-76.978173,38.839259]]]},"properties":{"id":"20745","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.949501,38.922717],[-75.908435,39.009658],[-76.063796,38.946244],[-76.06246,38.939312],[-76.048813,38.926365],[-75.949335,38.918333],[-75.949343,38.918978],[-75.949501,38.922717]]]},"properties":{"id":"21657","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.517493,38.246778],[-76.51282,38.210403],[-76.465583,38.206625],[-76.453448,38.204165],[-76.453788,38.204838],[-76.472918,38.215154],[-76.507814,38.250873],[-76.517493,38.246778]]]},"properties":{"id":"20634","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.548357,38.176985],[-76.497813,38.13674],[-76.513787,38.162644],[-76.537805,38.174733],[-76.548357,38.176985]]]},"properties":{"id":"20690","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.076149,38.711324],[-76.18491,38.673081],[-76.166977,38.661981],[-76.076149,38.711324]]]},"properties":{"id":"21654","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.34458,38.725247],[-76.323061,38.71594],[-76.308805,38.729695],[-76.294219,38.769508],[-76.390038,38.750161],[-76.34458,38.725247]]]},"properties":{"id":"21665","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.266722,38.707728],[-76.281395,38.726326],[-76.303043,38.719875],[-76.266722,38.707728]]]},"properties":{"id":"21652","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.71062,39.61026],[-77.712228,39.602676],[-77.706942,39.600821],[-77.703694,39.612411],[-77.71062,39.61026]]]},"properties":{"id":"21734","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.701646,38.325638],[-75.723591,38.272946],[-75.68896,38.283223],[-75.689063,38.315622],[-75.701646,38.325638]]]},"properties":{"id":"21810","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.252433,38.404337],[-75.252864,38.451384],[-75.341291,38.452034],[-75.316292,38.369282],[-75.252433,38.404337]]]},"properties":{"id":"21872","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.815374,39.722898],[-78.803506,39.691808],[-78.783389,39.714725],[-78.789527,39.722919],[-78.815374,39.722898]]]},"properties":{"id":"21524","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.053382,39.478664],[-78.973099,39.444749],[-78.962997,39.514389],[-79.134652,39.526326],[-79.082963,39.486382],[-79.06799,39.479085],[-79.067933,39.479189],[-79.053382,39.478664]]]},"properties":{"id":"21562","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.701518,39.023591],[-76.703743,38.987601],[-76.693912,38.955845],[-76.693928,38.955589],[-76.625016,38.988365],[-76.648815,39.040774],[-76.655394,39.085058],[-76.677759,39.080523],[-76.701518,39.023591]]]},"properties":{"id":"21054","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.593731,38.961983],[-76.588577,38.936069],[-76.567446,38.954315],[-76.593731,38.961983]]]},"properties":{"id":"21140","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.786843,39.388163],[-76.862743,39.395651],[-76.871282,39.36779],[-76.818068,39.347217],[-76.765484,39.367481],[-76.786843,39.388163]]]},"properties":{"id":"21133","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.600497,39.413106],[-76.665583,39.397736],[-76.643259,39.382896],[-76.607032,39.383869],[-76.605431,39.389942],[-76.603864,39.395267],[-76.600497,39.413106]]]},"properties":{"id":"21204","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.580725,39.462816],[-76.659335,39.47627],[-76.663787,39.474401],[-76.703158,39.459439],[-76.705078,39.456821],[-76.696851,39.423099],[-76.688458,39.414387],[-76.665996,39.397239],[-76.665583,39.397736],[-76.600497,39.413106],[-76.55729,39.449306],[-76.580725,39.462816]]]},"properties":{"id":"21093","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.47533,39.422687],[-76.448982,39.379358],[-76.409699,39.415716],[-76.461553,39.435664],[-76.47533,39.422687]]]},"properties":{"id":"21128","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.659326,39.614671],[-76.668445,39.566841],[-76.616531,39.534004],[-76.5196,39.528091],[-76.506769,39.551683],[-76.501946,39.581707],[-76.525689,39.606614],[-76.659326,39.614671]]]},"properties":{"id":"21111","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.700082,39.370749],[-76.720798,39.361842],[-76.683718,39.327755],[-76.651958,39.315746],[-76.657516,39.325366],[-76.656903,39.338201],[-76.700082,39.370749]]]},"properties":{"id":"21215","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.747549,39.583233],[-76.756699,39.553489],[-76.726748,39.533732],[-76.624217,39.523036],[-76.616531,39.534004],[-76.668445,39.566841],[-76.747549,39.583233]]]},"properties":{"id":"21152","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.896352,39.653084],[-76.895628,39.564134],[-76.872754,39.554458],[-76.780348,39.62125],[-76.755118,39.647926],[-76.896352,39.653084]]]},"properties":{"id":"21074","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.653572,39.719227],[-76.655822,39.718428],[-76.651461,39.711062],[-76.653572,39.719227]]]},"properties":{"id":"21105","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.5196,39.528091],[-76.616531,39.534004],[-76.624217,39.523036],[-76.580725,39.462816],[-76.55729,39.449306],[-76.53405,39.489518],[-76.5196,39.528091]]]},"properties":{"id":"21131","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.079591,39.556317],[-76.0554,39.555035],[-75.992545,39.563354],[-75.993183,39.575401],[-76.030213,39.606153],[-76.098086,39.5843],[-76.079591,39.556317]]]},"properties":{"id":"21903","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.030213,39.606153],[-75.993183,39.575401],[-75.970853,39.565323],[-75.939035,39.484716],[-75.914173,39.510617],[-75.945574,39.699792],[-75.99284,39.620952],[-76.030213,39.606153]]]},"properties":{"id":"21901","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.776035,39.494326],[-75.779812,39.540876],[-75.914173,39.510617],[-75.939035,39.484716],[-75.932709,39.482412],[-75.86278,39.4552],[-75.776035,39.494326]]]},"properties":{"id":"21915","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.114417,38.633515],[-77.249302,38.538796],[-77.159924,38.491679],[-77.065863,38.62464],[-77.114417,38.633515]],[[-77.169912,38.574164],[-77.15043,38.576998],[-77.144625,38.555838],[-77.180403,38.55446],[-77.169912,38.574164]]]},"properties":{"id":"20640","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.02481,38.573413],[-76.152824,38.631793],[-76.284158,38.523947],[-76.280853,38.52267],[-76.232147,38.538722],[-76.162758,38.505646],[-76.067298,38.381862],[-76.010734,38.355007],[-75.964057,38.344608],[-75.950868,38.504521],[-75.980125,38.552516],[-76.02481,38.573413]]]},"properties":{"id":"21613","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.356602,39.653532],[-77.350191,39.563325],[-77.313652,39.580695],[-77.31129,39.639641],[-77.356602,39.653532]]]},"properties":{"id":"21778","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.244743,39.432521],[-77.313061,39.409805],[-77.275969,39.380603],[-77.245275,39.378403],[-77.244743,39.432521]]]},"properties":{"id":"21774","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.324951,39.28119],[-77.355566,39.283914],[-77.393532,39.304737],[-77.460508,39.258696],[-77.475466,39.243591],[-77.480332,39.24117],[-77.502081,39.114637],[-77.43565,39.171488],[-77.430473,39.204479],[-77.389144,39.165622],[-77.361648,39.150207],[-77.370994,39.220638],[-77.341456,39.244996],[-77.324951,39.28119]]]},"properties":{"id":"20842","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.644463,39.308929],[-77.582988,39.303516],[-77.636207,39.332168],[-77.644463,39.308929]]]},"properties":{"id":"21716","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.56746,39.720409],[-77.573722,39.613037],[-77.503896,39.598898],[-77.459821,39.631328],[-77.50836,39.684072],[-77.50691,39.719943],[-77.56746,39.720409]]]},"properties":{"id":"21783","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.073346,39.650952],[-79.048774,39.72285],[-79.334876,39.721857],[-79.345047,39.708558],[-79.247487,39.626078],[-79.215907,39.613436],[-79.23014,39.590974],[-79.232209,39.586915],[-79.100015,39.571066],[-79.083077,39.598931],[-79.073346,39.650952]]]},"properties":{"id":"21536","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.100015,39.571066],[-79.232209,39.586915],[-79.30559,39.548145],[-79.324445,39.520213],[-79.232662,39.441127],[-79.160041,39.408933],[-79.075155,39.473237],[-79.082963,39.486382],[-79.134652,39.526326],[-79.100015,39.571066]]]},"properties":{"id":"21561","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.353728,39.497812],[-76.345317,39.578991],[-76.394515,39.536443],[-76.373012,39.509369],[-76.353728,39.497812]]]},"properties":{"id":"21014","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.247025,39.444542],[-76.321097,39.451252],[-76.320026,39.404407],[-76.243346,39.436968],[-76.247025,39.444542]]]},"properties":{"id":"21040","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.246944,39.481492],[-76.260438,39.476541],[-76.219858,39.475204],[-76.246944,39.481492]]]},"properties":{"id":"21017","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.340447,39.688598],[-76.487334,39.681987],[-76.496733,39.653052],[-76.38291,39.626582],[-76.330616,39.619242],[-76.259801,39.601454],[-76.280865,39.679993],[-76.340447,39.688598]]]},"properties":{"id":"21154","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.790486,39.177665],[-76.764379,39.165555],[-76.697085,39.214092],[-76.722851,39.227187],[-76.764575,39.249078],[-76.794513,39.198195],[-76.798848,39.184397],[-76.790486,39.177665]]]},"properties":{"id":"21075","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.899172,39.304288],[-76.930549,39.306607],[-76.985392,39.266096],[-76.984827,39.26414],[-76.978377,39.247882],[-76.917049,39.229446],[-76.837362,39.239848],[-76.816451,39.313176],[-76.822937,39.318834],[-76.899172,39.304288]]]},"properties":{"id":"21042","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.892746,39.179328],[-76.884414,39.16867],[-76.863789,39.196963],[-76.837362,39.239848],[-76.917049,39.229446],[-76.900627,39.182484],[-76.900392,39.181727],[-76.892746,39.179328]]]},"properties":{"id":"21044","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.998345,39.279032],[-76.985392,39.266096],[-76.930549,39.306607],[-76.952623,39.309039],[-76.991049,39.321241],[-77.009434,39.309699],[-76.998345,39.279032]]]},"properties":{"id":"21794","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.916994,39.170498],[-76.897807,39.178404],[-76.900392,39.181727],[-76.900627,39.182484],[-76.916994,39.170498]]],[[[-76.916994,39.170498],[-76.93374,39.160491],[-76.962973,39.14584],[-76.958733,39.134315],[-76.918924,39.132168],[-76.902038,39.161562],[-76.916994,39.170498]]]]},"properties":{"id":"20759","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.802605,39.122224],[-76.812503,39.138646],[-76.832782,39.136545],[-76.816148,39.121428],[-76.802605,39.122224]]]},"properties":{"id":"20763","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.83388,39.29608],[-75.88844,39.29679],[-75.96469,39.27299],[-75.924403,39.244087],[-75.913367,39.231961],[-75.913262,39.2143],[-75.754797,39.231163],[-75.760431,39.30128],[-75.77038,39.3013],[-75.83388,39.29608]]]},"properties":{"id":"21651","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.77038,39.3013],[-75.824311,39.35086],[-75.83388,39.29608],[-75.77038,39.3013]]]},"properties":{"id":"21650","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.187583,39.110794],[-77.252253,39.074792],[-77.157585,39.06705],[-77.13488,39.074752],[-77.122779,39.092895],[-77.12526,39.106093],[-77.174746,39.120714],[-77.187583,39.110794]]]},"properties":{"id":"20850","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.122779,39.092895],[-77.109184,39.07183],[-77.099587,39.063542],[-77.074066,39.121535],[-77.103614,39.150726],[-77.12526,39.106093],[-77.122779,39.092895]]]},"properties":{"id":"20853","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.174746,39.120714],[-77.12526,39.106093],[-77.103614,39.150726],[-77.106071,39.181041],[-77.110412,39.183074],[-77.130523,39.172027],[-77.149696,39.151138],[-77.165471,39.137815],[-77.173483,39.136008],[-77.174746,39.120714]]]},"properties":{"id":"20855","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.989936,39.038276],[-76.962097,39.031208],[-76.960303,39.033569],[-76.927756,39.076414],[-76.928677,39.076723],[-76.950607,39.085788],[-77.025594,39.088994],[-77.029342,39.066497],[-77.009258,39.042661],[-76.989936,39.038276]]]},"properties":{"id":"20904","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.025594,39.088994],[-76.950607,39.085788],[-76.956591,39.112832],[-76.989349,39.125194],[-76.959094,39.134149],[-76.958733,39.134315],[-76.962973,39.14584],[-76.970392,39.148724],[-77.013671,39.134441],[-77.033797,39.12064],[-77.025594,39.088994]]]},"properties":{"id":"20905","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.059959,39.024482],[-77.032859,39.016132],[-77.009258,39.042661],[-77.029342,39.066497],[-77.08184,39.043327],[-77.059959,39.024482]]]},"properties":{"id":"20902","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.341456,39.244996],[-77.370994,39.220638],[-77.345136,39.233396],[-77.341456,39.244996]]]},"properties":{"id":"20838","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.006243,39.186104],[-77.026582,39.192869],[-77.023765,39.175052],[-76.999911,39.178629],[-77.006243,39.186104]]]},"properties":{"id":"20862","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.943312,39.033024],[-76.956098,38.986554],[-76.946583,38.998707],[-76.939831,38.992857],[-76.946968,38.98223],[-76.938072,38.971984],[-76.902395,38.983209],[-76.907844,39.014786],[-76.943312,39.033024]]]},"properties":{"id":"20740","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.956372,38.948976],[-76.941923,38.918584],[-76.939567,38.916735],[-76.924697,38.91323],[-76.927956,38.927514],[-76.930909,38.933479],[-76.915626,38.951782],[-76.942611,38.965869],[-76.956372,38.948976]]]},"properties":{"id":"20781","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.909422,38.953109],[-76.930909,38.933479],[-76.927956,38.927514],[-76.865382,38.956203],[-76.873537,38.978248],[-76.893413,38.972291],[-76.909422,38.953109]]]},"properties":{"id":"20784","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.519896,38.206616],[-76.537805,38.174733],[-76.513787,38.162644],[-76.465583,38.206625],[-76.51282,38.210403],[-76.519896,38.206616]]]},"properties":{"id":"20692","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.54037,38.253547],[-76.519896,38.206616],[-76.51282,38.210403],[-76.517493,38.246778],[-76.54037,38.253547]]]},"properties":{"id":"20620","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.832016,38.137007],[-75.601721,38.144765],[-75.547027,38.181403],[-75.576515,38.220278],[-75.68896,38.283223],[-75.723591,38.272946],[-75.763324,38.285967],[-75.79396,38.26657],[-75.874654,38.220013],[-75.874652,38.205213],[-75.877714,38.175449],[-75.832016,38.137007]]]},"properties":{"id":"21853","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.707388,38.155582],[-75.698624,38.1571],[-75.702483,38.16015],[-75.707388,38.155582]]]},"properties":{"id":"21890","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.271642,38.832579],[-76.251342,38.836718],[-76.283322,38.845691],[-76.312288,38.815836],[-76.305144,38.817809],[-76.271642,38.832579]]]},"properties":{"id":"21624","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.305144,38.817809],[-76.265166,38.80228],[-76.271642,38.832579],[-76.305144,38.817809]]]},"properties":{"id":"21647","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.188043,38.673032],[-76.197875,38.740653],[-76.237382,38.744748],[-76.241611,38.735619],[-76.188043,38.673032]]]},"properties":{"id":"21662","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.732751,39.72124],[-77.577751,39.601608],[-77.573722,39.613037],[-77.56746,39.720409],[-77.732751,39.72124]]]},"properties":{"id":"21742","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.774798,39.561342],[-77.848106,39.657252],[-77.867284,39.610051],[-77.824921,39.528685],[-77.79077,39.53989],[-77.774798,39.561342]]]},"properties":{"id":"21795","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.349807,38.317633],[-75.393657,38.452389],[-75.410763,38.4525],[-75.445191,38.294407],[-75.449078,38.28648],[-75.360875,38.298345],[-75.349807,38.317633]]]},"properties":{"id":"21850","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.929067,38.286949],[-75.869647,38.26801],[-75.912098,38.319052],[-75.929067,38.286949]]]},"properties":{"id":"21814","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.564653,38.423622],[-75.613953,38.328277],[-75.618155,38.303784],[-75.54467,38.225962],[-75.449078,38.28648],[-75.445191,38.294407],[-75.530957,38.417378],[-75.564653,38.423622]],[[-75.581103,38.346007],[-75.579973,38.344023],[-75.583718,38.343115],[-75.585321,38.344404],[-75.581103,38.346007]]]},"properties":{"id":"21804","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.657516,39.325366],[-76.621279,39.311928],[-76.625917,39.331158],[-76.639406,39.342953],[-76.656903,39.338201],[-76.657516,39.325366]]]},"properties":{"id":"21211","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.386244,39.722716],[-78.633724,39.722912],[-78.655566,39.639727],[-78.452302,39.653217],[-78.386244,39.722716]]]},"properties":{"id":"21530","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.342834,39.722263],[-78.386244,39.722716],[-78.452302,39.653217],[-78.420422,39.60326],[-78.333819,39.636762],[-78.342834,39.722263]]]},"properties":{"id":"21766","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.777942,39.114119],[-76.802605,39.122224],[-76.816148,39.121428],[-76.840362,39.103142],[-76.832375,39.085766],[-76.830339,39.067852],[-76.765388,39.079408],[-76.777942,39.114119]]]},"properties":{"id":"20724","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.656478,39.190348],[-76.673622,39.162195],[-76.668656,39.155209],[-76.641075,39.127824],[-76.589751,39.121552],[-76.599854,39.137565],[-76.614258,39.206355],[-76.641679,39.206347],[-76.656478,39.190348]]]},"properties":{"id":"21061","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.693928,38.955589],[-76.672871,38.907363],[-76.676276,38.895163],[-76.593505,38.910038],[-76.588577,38.936069],[-76.593731,38.961983],[-76.608906,38.986112],[-76.625016,38.988365],[-76.693928,38.955589]]]},"properties":{"id":"21035","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.601007,38.77017],[-76.587374,38.78856],[-76.589866,38.824146],[-76.589871,38.829894],[-76.697503,38.844786],[-76.686358,38.748475],[-76.601007,38.77017]]]},"properties":{"id":"20711","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.521908,38.819181],[-76.503183,38.796306],[-76.50664,38.86279],[-76.534035,38.845264],[-76.53928,38.839715],[-76.521908,38.819181]]]},"properties":{"id":"20764","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.589871,38.829894],[-76.589866,38.824146],[-76.552198,38.814495],[-76.521908,38.819181],[-76.53928,38.839715],[-76.557293,38.849611],[-76.589871,38.829894]]]},"properties":{"id":"20778","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.552198,38.814495],[-76.522443,38.790992],[-76.503183,38.796306],[-76.521908,38.819181],[-76.552198,38.814495]]]},"properties":{"id":"20733","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.566436,38.721318],[-76.623041,38.749088],[-76.691947,38.683765],[-76.686591,38.630479],[-76.608686,38.662683],[-76.564061,38.69339],[-76.547391,38.707294],[-76.566436,38.721318]]]},"properties":{"id":"20736","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.771455,39.720803],[-76.751572,39.659731],[-76.651461,39.711062],[-76.655822,39.718428],[-76.653572,39.719227],[-76.654765,39.721105],[-76.771455,39.720803]]]},"properties":{"id":"21053","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.578934,39.382177],[-76.538101,39.422133],[-76.55729,39.449306],[-76.600497,39.413106],[-76.603864,39.395267],[-76.604494,39.392945],[-76.605431,39.389942],[-76.607032,39.383869],[-76.597386,39.381426],[-76.578934,39.382177]]]},"properties":{"id":"21286","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.446048,39.370734],[-76.355781,39.388806],[-76.393818,39.411527],[-76.409699,39.415716],[-76.448982,39.379358],[-76.446048,39.370734]]]},"properties":{"id":"21162","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.705078,39.456821],[-76.884117,39.396568],[-76.884646,39.38701],[-76.862743,39.395651],[-76.786843,39.388163],[-76.735098,39.402509],[-76.696851,39.423099],[-76.705078,39.456821]]]},"properties":{"id":"21117","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.442711,39.468708],[-76.458497,39.47735],[-76.521823,39.48836],[-76.456515,39.460591],[-76.44173,39.468126],[-76.442711,39.468708]]]},"properties":{"id":"21082","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.699902,39.264513],[-76.671489,39.273887],[-76.669873,39.294249],[-76.686302,39.305506],[-76.720657,39.290745],[-76.699902,39.264513]]]},"properties":{"id":"21229","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.899172,39.304288],[-76.871282,39.36779],[-76.862743,39.395651],[-76.884646,39.38701],[-76.952623,39.309039],[-76.930549,39.306607],[-76.899172,39.304288]]]},"properties":{"id":"21104","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.454489,38.346892],[-76.483223,38.34329],[-76.485047,38.335895],[-76.467872,38.318412],[-76.454295,38.305332],[-76.447253,38.305174],[-76.45187,38.321726],[-76.454489,38.346892]]]},"properties":{"id":"20688","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.551254,38.429954],[-76.55554,38.427115],[-76.545463,38.411647],[-76.540064,38.423081],[-76.551254,38.429954]]]},"properties":{"id":"20615","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.722055,38.820367],[-75.730994,38.933089],[-75.828651,38.922074],[-75.949501,38.922717],[-75.949343,38.918978],[-75.946224,38.917296],[-75.92511,38.837603],[-75.897178,38.815212],[-75.853221,38.766634],[-75.722055,38.820367]]]},"properties":{"id":"21629","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.815238,38.630107],[-75.707444,38.635352],[-75.722055,38.820367],[-75.853221,38.766634],[-75.860893,38.700191],[-75.815238,38.630107]]]},"properties":{"id":"21632","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.908435,39.009658],[-75.949501,38.922717],[-75.828651,38.922074],[-75.855323,38.991436],[-75.875152,39.019847],[-75.890896,39.021072],[-75.908435,39.009658]]]},"properties":{"id":"21660","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.886827,39.424298],[-76.946456,39.472379],[-77.070178,39.442188],[-77.070676,39.439514],[-77.013791,39.335872],[-76.991049,39.321241],[-76.952623,39.309039],[-76.884646,39.38701],[-76.884117,39.396568],[-76.886827,39.424298]]]},"properties":{"id":"21784","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.945727,39.720501],[-77.066926,39.719943],[-77.125973,39.617326],[-77.110801,39.568499],[-77.036618,39.55784],[-76.933702,39.671715],[-76.945727,39.720501]]]},"properties":{"id":"21158","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.871474,39.398426],[-75.864262,39.410761],[-75.876694,39.406119],[-75.871474,39.398426]]]},"properties":{"id":"21913","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.970853,39.565323],[-75.993183,39.575401],[-75.992545,39.563354],[-75.970853,39.565323]]]},"properties":{"id":"21914","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.0554,39.555035],[-76.079591,39.556317],[-76.064283,39.546788],[-76.0554,39.555035]]]},"properties":{"id":"21902","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.065863,38.62464],[-77.159924,38.491679],[-77.150523,38.45408],[-77.088343,38.510083],[-77.007588,38.477374],[-76.943205,38.470864],[-76.938841,38.415243],[-76.854788,38.496664],[-76.902107,38.527382],[-76.886765,38.538538],[-76.92274,38.575841],[-76.981004,38.592243],[-77.036901,38.556709],[-77.055839,38.595979],[-77.04781,38.616171],[-77.065863,38.62464]]]},"properties":{"id":"20646","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.943205,38.470864],[-77.007588,38.477374],[-77.02252,38.45209],[-76.986951,38.446632],[-76.943205,38.470864]]]},"properties":{"id":"20611","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.853004,38.559755],[-76.818018,38.558943],[-76.756787,38.562995],[-76.939222,38.678127],[-76.998596,38.654664],[-76.909436,38.623604],[-76.853004,38.559755]]]},"properties":{"id":"20601","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.114417,38.633515],[-77.065863,38.62464],[-77.04781,38.616171],[-77.077027,38.690168],[-77.114417,38.633515]]]},"properties":{"id":"20616","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.818018,38.558943],[-76.902107,38.527382],[-76.854788,38.496664],[-76.70537,38.501831],[-76.682811,38.495972],[-76.66868,38.512499],[-76.675457,38.535876],[-76.74016,38.560505],[-76.756787,38.562995],[-76.818018,38.558943]]]},"properties":{"id":"20637","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.95346,38.609809],[-75.943702,38.601081],[-75.935966,38.605712],[-75.95346,38.609809]]]},"properties":{"id":"21664","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.43481,39.415417],[-77.410301,39.402351],[-77.32527,39.392245],[-77.313061,39.409805],[-77.244743,39.432521],[-77.238697,39.445853],[-77.252319,39.475709],[-77.248128,39.487907],[-77.260111,39.50842],[-77.288504,39.499016],[-77.376959,39.507055],[-77.411977,39.516172],[-77.43481,39.415417]],[[-77.411609,39.40924],[-77.408282,39.409962],[-77.408596,39.407939],[-77.411609,39.40924]]]},"properties":{"id":"21701","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.252319,39.475709],[-77.243615,39.47887],[-77.248128,39.487907],[-77.252319,39.475709]]]},"properties":{"id":"21762","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.23014,39.590974],[-79.215907,39.613436],[-79.247487,39.626078],[-79.23014,39.590974]]]},"properties":{"id":"21522","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.334876,39.721857],[-79.478657,39.556142],[-79.44908,39.557125],[-79.371321,39.574457],[-79.345047,39.708558],[-79.334876,39.721857]]]},"properties":{"id":"21531","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.219858,39.475204],[-76.260438,39.476541],[-76.344194,39.486756],[-76.321097,39.451252],[-76.247025,39.444542],[-76.219858,39.475204]]]},"properties":{"id":"21009","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.30137,39.372186],[-76.26513,39.39252],[-76.294091,39.413097],[-76.30137,39.372186]]]},"properties":{"id":"21010","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.028589,39.310127],[-77.058417,39.273036],[-77.046025,39.256247],[-76.998345,39.279032],[-77.009434,39.309699],[-77.028589,39.310127]]]},"properties":{"id":"21738","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.863789,39.196963],[-76.798848,39.184397],[-76.794513,39.198195],[-76.837362,39.239848],[-76.863789,39.196963]]]},"properties":{"id":"21045","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.270583,39.182945],[-76.169982,39.083873],[-76.18485,39.18457],[-76.270583,39.182945]]]},"properties":{"id":"21661","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.117111,39.370794],[-76.0819,39.35789],[-76.029885,39.37091],[-76.117111,39.370794]]]},"properties":{"id":"21610","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.23413,39.246913],[-77.273162,39.206756],[-77.239227,39.164752],[-77.221923,39.174526],[-77.21537,39.187313],[-77.203926,39.201313],[-77.210998,39.239406],[-77.23413,39.246913]]]},"properties":{"id":"20876","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.015373,39.166141],[-77.013671,39.134441],[-76.970392,39.148724],[-76.998597,39.16777],[-77.015373,39.166141]]]},"properties":{"id":"20861","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.840362,39.103142],[-76.907301,39.12549],[-76.928677,39.076723],[-76.927756,39.076414],[-76.878574,39.066933],[-76.832375,39.085766],[-76.840362,39.103142]]]},"properties":{"id":"20707","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.672613,38.617985],[-76.74016,38.560505],[-76.675457,38.535876],[-76.672276,38.552781],[-76.672613,38.617985]]]},"properties":{"id":"20608","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.907844,39.014786],[-76.902395,38.983209],[-76.893477,38.982234],[-76.873537,38.978248],[-76.84293,38.992134],[-76.849925,39.016366],[-76.907844,39.014786]]]},"properties":{"id":"20770","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.924697,38.91323],[-76.847344,38.892164],[-76.851896,38.91733],[-76.865382,38.956203],[-76.927956,38.927514],[-76.924697,38.91323]]]},"properties":{"id":"20785","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.942611,38.965869],[-76.915626,38.951782],[-76.909422,38.953109],[-76.893413,38.972291],[-76.893477,38.982234],[-76.902395,38.983209],[-76.938072,38.971984],[-76.942611,38.965869]]]},"properties":{"id":"20737","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.750821,38.950576],[-76.735977,38.901002],[-76.672871,38.907363],[-76.693928,38.955589],[-76.693912,38.955845],[-76.750821,38.950576]]]},"properties":{"id":"20716","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.924403,39.244087],[-75.931166,39.225736],[-75.913367,39.231961],[-75.924403,39.244087]]]},"properties":{"id":"21628","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.890499,39.130812],[-75.865354,39.074443],[-75.856223,39.070567],[-75.890499,39.130812]]]},"properties":{"id":"21644","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.497813,38.13674],[-76.548357,38.176985],[-76.554465,38.175162],[-76.536873,38.135428],[-76.468109,38.126311],[-76.497813,38.13674]]]},"properties":{"id":"20674","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.572903,38.284926],[-76.654536,38.353935],[-76.69417,38.374413],[-76.707689,38.361319],[-76.709821,38.355613],[-76.716294,38.292748],[-76.711806,38.271529],[-76.554465,38.175162],[-76.548357,38.176985],[-76.537805,38.174733],[-76.519896,38.206616],[-76.54037,38.253547],[-76.572903,38.284926]]]},"properties":{"id":"20650","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.80922,38.247511],[-76.741251,38.233301],[-76.808811,38.247555],[-76.80922,38.247511]]]},"properties":{"id":"20626","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.468109,38.126311],[-76.440329,38.193661],[-76.453448,38.204165],[-76.465583,38.206625],[-76.513787,38.162644],[-76.497813,38.13674],[-76.468109,38.126311]]]},"properties":{"id":"20630","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.758864,38.315124],[-76.716294,38.292748],[-76.709821,38.355613],[-76.752543,38.370826],[-76.758864,38.315124]]]},"properties":{"id":"20624","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.814634,37.952161],[-75.624343,37.994208],[-75.661224,38.044162],[-75.818971,38.055841],[-75.842717,38.029806],[-75.814634,37.952161]]]},"properties":{"id":"21838","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.789456,38.103342],[-75.789897,38.111722],[-75.797927,38.10707],[-75.789456,38.103342]]]},"properties":{"id":"21867","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.701646,38.325638],[-75.718189,38.342585],[-75.763324,38.285967],[-75.723591,38.272946],[-75.701646,38.325638]]],[[[-75.68896,38.283223],[-75.576515,38.220278],[-75.54467,38.225962],[-75.618155,38.303784],[-75.647885,38.301109],[-75.689063,38.315622],[-75.68896,38.283223]]]]},"properties":{"id":"21822","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.042431,37.995939],[-76.049486,37.983569],[-76.037943,37.964885],[-76.021053,37.974474],[-76.042431,37.995939]]]},"properties":{"id":"21824","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.025168,37.96641],[-76.018901,37.965273],[-76.018701,37.969123],[-76.025168,37.96641]]]},"properties":{"id":"21866","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.271747,38.783384],[-76.237382,38.744748],[-76.197875,38.740653],[-76.182179,38.751049],[-76.184787,38.755154],[-76.214293,38.826222],[-76.251342,38.836718],[-76.271642,38.832579],[-76.265166,38.80228],[-76.271747,38.783384]]]},"properties":{"id":"21663","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.848106,39.657252],[-77.774798,39.561342],[-77.76552,39.56861],[-77.76079,39.57549],[-77.755853,39.570545],[-77.731293,39.550619],[-77.71148,39.56209],[-77.708746,39.565156],[-77.695693,39.562706],[-77.589308,39.567092],[-77.577751,39.601608],[-77.732751,39.72124],[-77.847605,39.721969],[-77.848106,39.657252]],[[-77.71062,39.61026],[-77.703694,39.612411],[-77.706942,39.600821],[-77.712228,39.602676],[-77.71062,39.61026]],[[-77.75365,39.697533],[-77.74299,39.70041],[-77.74226,39.69311],[-77.75365,39.697533]]]},"properties":{"id":"21740","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.8393,38.388771],[-75.699122,38.458333],[-75.696526,38.496493],[-75.700147,38.542619],[-75.757059,38.513363],[-75.8393,38.388771]]]},"properties":{"id":"21837","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.701569,38.560736],[-75.754121,38.525675],[-75.757059,38.513363],[-75.700147,38.542619],[-75.701569,38.560736]]]},"properties":{"id":"21861","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.581103,38.346007],[-75.585321,38.344404],[-75.583718,38.343115],[-75.579973,38.344023],[-75.581103,38.346007]]]},"properties":{"id":"21802","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.547027,38.181403],[-75.441718,38.127226],[-75.310515,38.094744],[-75.249645,38.205581],[-75.361526,38.263335],[-75.360875,38.298345],[-75.449078,38.28648],[-75.54467,38.225962],[-75.576515,38.220278],[-75.547027,38.181403]]]},"properties":{"id":"21863","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.430602,38.067172],[-75.468115,38.007697],[-75.354434,38.063951],[-75.430602,38.067172]]]},"properties":{"id":"21864","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.196327,38.401934],[-75.106558,38.382296],[-75.073218,38.451285],[-75.252864,38.451384],[-75.252433,38.404337],[-75.22782,38.403395],[-75.196327,38.401934]]]},"properties":{"id":"21813","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.196327,38.401934],[-75.22782,38.403395],[-75.219017,38.3967],[-75.196327,38.401934]]]},"properties":{"id":"21862","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.584297,39.296663],[-76.532943,39.298733],[-76.543118,39.307352],[-76.548938,39.315888],[-76.600402,39.303008],[-76.59984,39.295081],[-76.584297,39.296663]]]},"properties":{"id":"21205","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.576517,39.333856],[-76.599057,39.312961],[-76.600402,39.303008],[-76.548938,39.315888],[-76.576517,39.333856]]]},"properties":{"id":"21213","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.083077,39.598931],[-79.100015,39.571066],[-79.134652,39.526326],[-78.962997,39.514389],[-78.954513,39.523803],[-79.083077,39.598931]]]},"properties":{"id":"21521","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.053382,39.478664],[-79.067933,39.479189],[-79.060776,39.471739],[-79.053382,39.478664]]]},"properties":{"id":"21540","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.717051,39.092183],[-76.75865,39.037086],[-76.706119,38.99039],[-76.701518,39.023591],[-76.677759,39.080523],[-76.715003,39.114206],[-76.717051,39.092183]]]},"properties":{"id":"21113","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.567446,38.954315],[-76.538997,38.951822],[-76.480643,38.977038],[-76.488583,38.994111],[-76.503715,39.006398],[-76.529878,39.028205],[-76.563995,39.017669],[-76.608906,38.986112],[-76.593731,38.961983],[-76.567446,38.954315]]]},"properties":{"id":"21401","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.703743,38.987601],[-76.701518,39.023591],[-76.706119,38.99039],[-76.703743,38.987601]]]},"properties":{"id":"21114","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.547391,38.707294],[-76.523829,38.691346],[-76.547707,38.750118],[-76.562932,38.733598],[-76.566436,38.721318],[-76.547391,38.707294]]]},"properties":{"id":"20714","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.696392,39.215278],[-76.694791,39.192807],[-76.656478,39.190348],[-76.641679,39.206347],[-76.639943,39.224362],[-76.696392,39.215278]]]},"properties":{"id":"21090","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.589751,39.121552],[-76.569398,39.097565],[-76.509585,39.076311],[-76.452482,39.06321],[-76.412653,39.087262],[-76.512872,39.174142],[-76.537935,39.163348],[-76.599854,39.137565],[-76.589751,39.121552]]]},"properties":{"id":"21122","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.412653,39.087262],[-76.452482,39.06321],[-76.440546,39.059009],[-76.414638,39.049403],[-76.412653,39.087262]]]},"properties":{"id":"21056","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.569398,39.097565],[-76.604879,39.07727],[-76.545272,39.040518],[-76.509585,39.076311],[-76.569398,39.097565]]]},"properties":{"id":"21146","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.673622,39.162195],[-76.656478,39.190348],[-76.694791,39.192807],[-76.693957,39.178249],[-76.694633,39.158481],[-76.673622,39.162195]]]},"properties":{"id":"21240","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.812503,39.138646],[-76.802605,39.122224],[-76.777942,39.114119],[-76.760614,39.13045],[-76.812503,39.138646]]]},"properties":{"id":"20701","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.643259,39.382896],[-76.665583,39.397736],[-76.665996,39.397239],[-76.700082,39.370749],[-76.656903,39.338201],[-76.639406,39.342953],[-76.644788,39.378966],[-76.643259,39.382896]]]},"properties":{"id":"21209","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.44173,39.468126],[-76.456515,39.460591],[-76.461553,39.435664],[-76.409699,39.415716],[-76.393818,39.411527],[-76.385953,39.412083],[-76.389447,39.482913],[-76.420908,39.478452],[-76.44173,39.468126]]]},"properties":{"id":"21087","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.379345,39.293581],[-76.474946,39.339618],[-76.492585,39.303007],[-76.468965,39.289658],[-76.445046,39.260743],[-76.393781,39.246707],[-76.379345,39.293581]]]},"properties":{"id":"21221","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.720798,39.361842],[-76.746666,39.359728],[-76.743168,39.298946],[-76.720657,39.290745],[-76.686302,39.305506],[-76.683718,39.327755],[-76.720798,39.361842]]]},"properties":{"id":"21207","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.407898,39.427281],[-76.388727,39.437363],[-76.384118,39.450035],[-76.411725,39.436918],[-76.407898,39.427281]]]},"properties":{"id":"21156","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.837362,39.239848],[-76.794513,39.198195],[-76.764575,39.249078],[-76.796511,39.305045],[-76.816451,39.313176],[-76.837362,39.239848]]]},"properties":{"id":"21043","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.644788,39.378966],[-76.639406,39.342953],[-76.625917,39.331158],[-76.617127,39.34533],[-76.644788,39.378966]]]},"properties":{"id":"21210","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.793758,39.485076],[-76.814699,39.492203],[-76.819399,39.474581],[-76.793758,39.485076]]]},"properties":{"id":"21071","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.513677,38.536682],[-76.600612,38.452215],[-76.492306,38.489812],[-76.513677,38.536682]]]},"properties":{"id":"20676","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.740772,39.055831],[-75.875152,39.019847],[-75.855323,38.991436],[-75.735773,38.993193],[-75.740772,39.055831]]]},"properties":{"id":"21636","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.735773,38.993193],[-75.855323,38.991436],[-75.828651,38.922074],[-75.730994,38.933089],[-75.735773,38.993193]]]},"properties":{"id":"21639","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.070676,39.439514],[-77.139792,39.285028],[-77.06513,39.250016],[-77.058417,39.273036],[-77.028589,39.310127],[-77.013791,39.335872],[-77.070676,39.439514]]]},"properties":{"id":"21797","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.163601,39.651297],[-76.107999,39.639032],[-76.128071,39.690213],[-76.175422,39.721403],[-76.233279,39.721367],[-76.224277,39.702904],[-76.163601,39.651297]]]},"properties":{"id":"21918","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.175422,39.721403],[-76.128071,39.690213],[-76.069766,39.671611],[-75.99284,39.620952],[-75.945574,39.699792],[-75.937111,39.721878],[-76.175422,39.721403]]]},"properties":{"id":"21911","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.04781,38.616171],[-76.937508,38.598852],[-76.909436,38.623604],[-76.998596,38.654664],[-77.04781,38.616171]]]},"properties":{"id":"20603","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.853004,38.559755],[-76.909436,38.623604],[-76.937508,38.598852],[-76.92274,38.575841],[-76.886765,38.538538],[-76.853004,38.559755]]]},"properties":{"id":"20602","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.966971,38.702997],[-76.98599,38.698683],[-77.004446,38.696373],[-77.077027,38.690168],[-77.04781,38.616171],[-76.998596,38.654664],[-76.939222,38.678127],[-76.966971,38.702997]]]},"properties":{"id":"20607","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.939222,38.678127],[-76.756787,38.562995],[-76.74016,38.560505],[-76.672613,38.617985],[-76.686591,38.630479],[-76.691947,38.683765],[-76.700997,38.709244],[-76.829498,38.732538],[-76.85999,38.736473],[-76.966971,38.702997],[-76.939222,38.678127]]]},"properties":{"id":"20613","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.97055,38.637016],[-75.870925,38.588684],[-75.815238,38.630107],[-75.860893,38.700191],[-75.945243,38.674114],[-75.97055,38.637016]]]},"properties":{"id":"21643","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.066571,38.236457],[-76.033058,38.217121],[-76.04824,38.244792],[-76.066571,38.236457]]]},"properties":{"id":"21627","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.432269,39.332473],[-77.438511,39.341128],[-77.439264,39.332275],[-77.432269,39.332473]]]},"properties":{"id":"21717","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.262748,39.310485],[-77.275969,39.380603],[-77.313061,39.409805],[-77.32527,39.392245],[-77.355566,39.283914],[-77.324951,39.28119],[-77.267413,39.308296],[-77.262748,39.310485]]]},"properties":{"id":"21754","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.656177,39.368397],[-77.56581,39.305652],[-77.546471,39.272228],[-77.528412,39.290931],[-77.519674,39.302453],[-77.51126,39.412727],[-77.640068,39.405767],[-77.656177,39.368397]],[[-77.621115,39.386643],[-77.633651,39.389423],[-77.63528,39.397169],[-77.621802,39.396937],[-77.621115,39.386643]]]},"properties":{"id":"21755","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.50836,39.684072],[-77.459821,39.631328],[-77.408046,39.682853],[-77.40742,39.719859],[-77.469145,39.720018],[-77.50836,39.684072]]]},"properties":{"id":"21780","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.736722,39.387032],[-77.644463,39.308929],[-77.636207,39.332168],[-77.582988,39.303516],[-77.56581,39.305652],[-77.656177,39.368397],[-77.671065,39.415398],[-77.690185,39.38713],[-77.736722,39.387032]]]},"properties":{"id":"21758","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.273162,39.206756],[-77.23413,39.246913],[-77.267413,39.308296],[-77.324951,39.28119],[-77.341456,39.244996],[-77.345136,39.233396],[-77.273162,39.206756]]]},"properties":{"id":"20871","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.44908,39.557125],[-79.324445,39.520213],[-79.30559,39.548145],[-79.371321,39.574457],[-79.44908,39.557125]]]},"properties":{"id":"21541","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.184314,39.297143],[-76.01329,39.29003],[-76.0238,39.32327],[-76.0819,39.35789],[-76.117111,39.370794],[-76.184314,39.297143]]]},"properties":{"id":"21678","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.203926,39.201313],[-77.21537,39.187313],[-77.199584,39.197631],[-77.203926,39.201313]]],[[[-77.232406,39.160858],[-77.216926,39.14875],[-77.20657,39.156394],[-77.221923,39.174526],[-77.239227,39.164752],[-77.232406,39.160858]]],[[[-77.194657,39.164925],[-77.149696,39.151138],[-77.130523,39.172027],[-77.15557,39.185302],[-77.194657,39.164925]]]]},"properties":{"id":"20879","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.13488,39.074752],[-77.109184,39.07183],[-77.122779,39.092895],[-77.13488,39.074752]]]},"properties":{"id":"20851","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.063972,39.011386],[-77.046513,38.991324],[-77.026596,38.984512],[-77.006263,38.995624],[-77.032859,39.016132],[-77.059959,39.024482],[-77.063972,39.011386]]]},"properties":{"id":"20910","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.085044,39.032855],[-77.096039,39.032604],[-77.086105,39.030982],[-77.085044,39.032855]]]},"properties":{"id":"20896","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.389144,39.165622],[-77.430473,39.204479],[-77.43565,39.171488],[-77.389144,39.165622]]]},"properties":{"id":"20839","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.847344,38.892164],[-76.852898,38.857147],[-76.866842,38.839974],[-76.691169,38.854401],[-76.676276,38.895163],[-76.672871,38.907363],[-76.735977,38.901002],[-76.845714,38.916581],[-76.851896,38.91733],[-76.847344,38.892164]]]},"properties":{"id":"20774","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.84293,38.992134],[-76.820478,38.956313],[-76.807423,39.005198],[-76.84772,39.03382],[-76.849925,39.016366],[-76.84293,38.992134]]]},"properties":{"id":"20769","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.989485,38.990762],[-77.002388,38.965827],[-76.99723,38.961616],[-76.951805,38.984784],[-76.956098,38.986554],[-76.943312,39.033024],[-76.960303,39.033569],[-76.962097,39.031208],[-76.989485,38.990762]]]},"properties":{"id":"20783","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.978628,38.947021],[-76.958784,38.93155],[-76.957941,38.948807],[-76.978628,38.947021]]]},"properties":{"id":"20712","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.109409,38.900694],[-76.06246,38.939312],[-76.063796,38.946244],[-76.194046,38.98387],[-76.204135,38.978384],[-76.200915,38.848971],[-76.109409,38.900694]]]},"properties":{"id":"21658","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.339306,38.119154],[-76.416606,38.105058],[-76.42204,38.106338],[-76.325429,38.113503],[-76.339306,38.119154]]]},"properties":{"id":"20687","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.69417,38.374413],[-76.709142,38.377852],[-76.707689,38.361319],[-76.69417,38.374413]]]},"properties":{"id":"20660","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.719633,38.270794],[-76.746749,38.256298],[-76.741109,38.235272],[-76.719633,38.270794]]]},"properties":{"id":"20606","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.079473,38.890102],[-76.048813,38.926365],[-76.06246,38.939312],[-76.109409,38.900694],[-76.079473,38.890102]]]},"properties":{"id":"21679","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.271747,38.783384],[-76.265166,38.80228],[-76.305144,38.817809],[-76.312288,38.815836],[-76.390038,38.750161],[-76.294219,38.769508],[-76.271747,38.783384]]]},"properties":{"id":"21676","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.73334,39.50427],[-77.657524,39.467393],[-77.621025,39.464981],[-77.620986,39.505058],[-77.589308,39.567092],[-77.695693,39.562706],[-77.71148,39.56209],[-77.731293,39.550619],[-77.78482,39.53712],[-77.73334,39.50427]]]},"properties":{"id":"21713","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.614743,39.282182],[-76.615932,39.311071],[-76.620251,39.310972],[-76.632592,39.295689],[-76.631959,39.285866],[-76.614743,39.282182]]]},"properties":{"id":"21201","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.586178,39.345856],[-76.599935,39.342743],[-76.617127,39.34533],[-76.625917,39.331158],[-76.621279,39.311928],[-76.620251,39.310972],[-76.615932,39.311071],[-76.599057,39.312961],[-76.576517,39.333856],[-76.575221,39.335243],[-76.576952,39.338625],[-76.586178,39.345856]]]},"properties":{"id":"21218","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.54385,39.360881],[-76.573515,39.368677],[-76.573015,39.353903],[-76.576952,39.338625],[-76.575221,39.335243],[-76.54385,39.360881]]]},"properties":{"id":"21214","state_code":"24"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.620288,41.616933],[-70.588943,41.54474],[-70.527797,41.546653],[-70.539646,41.637212],[-70.601854,41.655195],[-70.60229,41.655322],[-70.620288,41.616933]]]},"properties":{"id":"02536","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.640529,41.62388],[-70.620288,41.616933],[-70.60229,41.655322],[-70.621251,41.657533],[-70.640529,41.62388]]]},"properties":{"id":"02556","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.620288,41.616933],[-70.640529,41.62388],[-70.588943,41.54474],[-70.620288,41.616933]]]},"properties":{"id":"02540","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.03717,41.672149],[-70.09826,41.679713],[-70.117657,41.655601],[-70.117592,41.655378],[-70.037962,41.666426],[-70.03717,41.672149]]]},"properties":{"id":"02646","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.369761,42.462963],[-73.182199,42.485415],[-73.165803,42.510932],[-73.192492,42.517283],[-73.196194,42.502357],[-73.199669,42.50538],[-73.195328,42.517733],[-73.174282,42.598507],[-73.171579,42.628707],[-73.329034,42.573285],[-73.369761,42.462963]]]},"properties":{"id":"01237","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.269725,42.115073],[-73.304139,42.106916],[-73.298618,42.064153],[-73.296265,42.047038],[-73.172218,42.043341],[-73.180617,42.109607],[-73.265492,42.091386],[-73.269725,42.115073]]]},"properties":{"id":"01259","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.256083,42.327115],[-73.24369,42.326987],[-73.24518,42.343723],[-73.256083,42.327115]]]},"properties":{"id":"01242","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.08705,42.186945],[-73.070516,42.140123],[-73.071067,42.148201],[-73.08705,42.186945]]],[[[-73.146402,42.180439],[-73.087078,42.186982],[-73.079159,42.235481],[-73.161574,42.247545],[-73.162083,42.198793],[-73.146402,42.180439]]]]},"properties":{"id":"01253","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.162083,42.198793],[-73.161574,42.247545],[-73.161547,42.248477],[-73.243936,42.257125],[-73.256797,42.241672],[-73.162083,42.198793]]]},"properties":{"id":"01264","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.10825,42.48668],[-72.988153,42.466848],[-72.976412,42.508597],[-72.978981,42.541631],[-73.085424,42.559501],[-73.107814,42.536487],[-73.10825,42.48668]]]},"properties":{"id":"01270","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.410843,42.351738],[-73.330816,42.351959],[-73.310852,42.39339],[-73.385635,42.419699],[-73.410843,42.351738]]]},"properties":{"id":"01254","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.063634,42.649877],[-73.095138,42.56158],[-73.085424,42.559501],[-72.978981,42.541631],[-72.975379,42.555933],[-72.951039,42.641006],[-73.063634,42.649877]]]},"properties":{"id":"01256","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.328337,42.047972],[-73.296265,42.047038],[-73.298618,42.064153],[-73.328337,42.047972]]]},"properties":{"id":"01222","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.965927,41.668334],[-70.916702,41.669343],[-70.942993,41.744668],[-70.965484,41.733902],[-70.965927,41.668334]]]},"properties":{"id":"02745","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.338698,41.898399],[-71.316444,41.775561],[-71.292456,41.77488],[-71.300719,41.903661],[-71.338698,41.898399]]]},"properties":{"id":"02771","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.916702,41.669343],[-70.917182,41.663603],[-70.858212,41.681167],[-70.864994,41.700186],[-70.907184,41.763543],[-70.942993,41.744668],[-70.916702,41.669343]]]},"properties":{"id":"02743","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.134134,42.721507],[-71.188858,42.696247],[-71.166478,42.70048],[-71.153438,42.711233],[-71.145902,42.705491],[-71.13728,42.706733],[-71.134134,42.721507]]]},"properties":{"id":"01841","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.961307,42.466134],[-71.00133,42.49179],[-70.968594,42.444396],[-70.966096,42.443507],[-70.948454,42.453734],[-70.953075,42.456484],[-70.961307,42.466134]]]},"properties":{"id":"01905","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.961307,42.466134],[-70.935454,42.491179],[-70.949917,42.494776],[-70.986911,42.511774],[-71.011592,42.501685],[-71.00133,42.49179],[-70.961307,42.466134]]]},"properties":{"id":"01904","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.969191,42.8225],[-71.004869,42.863318],[-71.053601,42.833089],[-70.999714,42.810691],[-70.969191,42.8225]]]},"properties":{"id":"01860","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.733821,42.651305],[-70.74565,42.599576],[-70.713002,42.570694],[-70.617279,42.625792],[-70.640404,42.687581],[-70.7236,42.667591],[-70.733821,42.651305]]]},"properties":{"id":"01930","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.810093,42.584095],[-70.922263,42.591512],[-70.908399,42.545558],[-70.856343,42.539344],[-70.794805,42.55739],[-70.810093,42.584095]]]},"properties":{"id":"01915","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.031619,42.718038],[-71.058883,42.732463],[-71.108632,42.735281],[-71.02231,42.641636],[-70.976721,42.62088],[-70.965225,42.672274],[-70.948594,42.680667],[-70.976411,42.694352],[-71.031619,42.718038]]]},"properties":{"id":"01921","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.058883,42.732463],[-71.031619,42.718038],[-70.959693,42.753833],[-70.967169,42.758326],[-71.020402,42.78423],[-71.042325,42.757098],[-71.058883,42.732463]]]},"properties":{"id":"01834","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.497071,42.597569],[-72.466104,42.576718],[-72.362287,42.595283],[-72.3647,42.609376],[-72.377131,42.639308],[-72.491,42.604008],[-72.497071,42.597569]]]},"properties":{"id":"01344","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.540576,42.655687],[-72.664891,42.698223],[-72.632942,42.645363],[-72.636821,42.577985],[-72.578138,42.578488],[-72.552686,42.612195],[-72.540576,42.655687]]]},"properties":{"id":"01301","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.540576,42.655687],[-72.496234,42.678541],[-72.516774,42.728531],[-72.674577,42.732889],[-72.664891,42.698223],[-72.540576,42.655687]]]},"properties":{"id":"01337","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.949742,42.705103],[-72.927162,42.739232],[-73.022903,42.741133],[-72.949742,42.705103]]]},"properties":{"id":"01350","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.840924,42.702927],[-72.951108,42.641426],[-72.951039,42.641006],[-72.975379,42.555933],[-72.876948,42.541213],[-72.8485,42.559062],[-72.800834,42.596652],[-72.815335,42.639589],[-72.840924,42.702927]]]},"properties":{"id":"01339","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.876948,42.541213],[-72.871157,42.484173],[-72.855644,42.48098],[-72.763746,42.46361],[-72.741113,42.534852],[-72.803604,42.564564],[-72.8485,42.559062],[-72.876948,42.541213]]]},"properties":{"id":"01330","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.583162,42.139774],[-72.601258,42.19501],[-72.627342,42.162032],[-72.617176,42.111717],[-72.593361,42.135488],[-72.583162,42.139774]]]},"properties":{"id":"01013","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.557323,42.098285],[-72.534358,42.081835],[-72.49111,42.087175],[-72.495542,42.102736],[-72.518458,42.100807],[-72.521523,42.103597],[-72.557323,42.098285]]]},"properties":{"id":"01118","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.582693,42.081558],[-72.563697,42.097316],[-72.580941,42.114695],[-72.59199,42.096729],[-72.588531,42.093244],[-72.582693,42.081558]]]},"properties":{"id":"01105","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.593361,42.135488],[-72.614158,42.108744],[-72.603771,42.103488],[-72.581786,42.115454],[-72.524339,42.140405],[-72.541665,42.157191],[-72.583162,42.139774],[-72.593361,42.135488]]]},"properties":{"id":"01104","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.846757,42.201135],[-72.813156,42.15552],[-72.845814,42.200141],[-72.846757,42.201135]]]},"properties":{"id":"01097","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.269919,42.328096],[-71.249171,42.323568],[-71.250444,42.32927],[-71.25867,42.336923],[-71.269919,42.328096]]]},"properties":{"id":"02462","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.772518,42.707987],[-71.898716,42.711418],[-71.85841,42.63384],[-71.775322,42.636663],[-71.775742,42.644216],[-71.772518,42.707987]]]},"properties":{"id":"01431","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.393325,42.298769],[-71.390365,42.271916],[-71.332434,42.247729],[-71.30846,42.270709],[-71.327327,42.313746],[-71.328403,42.329135],[-71.377687,42.314669],[-71.393325,42.298769]]]},"properties":{"id":"01760","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.211261,42.406074],[-71.232919,42.413171],[-71.235767,42.376349],[-71.19446,42.386317],[-71.211261,42.406074]]]},"properties":{"id":"02452","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.1639,42.405707],[-71.190494,42.427224],[-71.192211,42.425455],[-71.202612,42.414826],[-71.1639,42.405707]]]},"properties":{"id":"02476","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.327327,42.313746],[-71.30846,42.270709],[-71.294843,42.273052],[-71.264179,42.294493],[-71.279256,42.29884],[-71.317239,42.316125],[-71.327327,42.313746]]]},"properties":{"id":"02482","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.150386,42.25746],[-71.188167,42.280412],[-71.231739,42.259283],[-71.14597,42.209247],[-71.131322,42.228256],[-71.150386,42.25746]]]},"properties":{"id":"02026","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.944418,42.189954],[-70.973665,42.186992],[-70.976565,42.168331],[-70.981293,42.135502],[-70.948963,42.148156],[-70.924877,42.15758],[-70.920936,42.182178],[-70.944418,42.189954]]]},"properties":{"id":"02190","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.996739,42.266583],[-71.03149,42.25911],[-71.053246,42.229528],[-71.034168,42.226661],[-70.967991,42.237999],[-70.951524,42.254722],[-70.996739,42.266583]]]},"properties":{"id":"02169","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.798951,41.805718],[-70.778752,41.761246],[-70.746439,41.738308],[-70.737288,41.800567],[-70.77026,41.825777],[-70.798951,41.805718]]]},"properties":{"id":"02576","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.915787,42.1001],[-70.948963,42.148156],[-70.981293,42.135502],[-71.002029,42.126506],[-70.975519,42.087446],[-70.915787,42.1001]]]},"properties":{"id":"02351","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.908473,41.966346],[-70.860869,41.954998],[-70.807307,42.012871],[-70.833403,42.019563],[-70.896943,42.019149],[-70.899187,41.996371],[-70.908473,41.966346]]]},"properties":{"id":"02338","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.783272,42.247415],[-70.827048,42.200771],[-70.838791,42.194807],[-70.752394,42.163937],[-70.70883,42.20373],[-70.783272,42.247415]]]},"properties":{"id":"02066","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.798951,41.805718],[-70.896053,41.794375],[-70.921782,41.791244],[-70.907184,41.763543],[-70.864994,41.700186],[-70.799073,41.710109],[-70.778752,41.761246],[-70.798951,41.805718]]]},"properties":{"id":"02770","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.769698,42.022098],[-70.749716,42.096977],[-70.776853,42.116976],[-70.803502,42.111706],[-70.842486,42.094801],[-70.833403,42.019563],[-70.807307,42.012871],[-70.799329,42.013613],[-70.769698,42.022098]]]},"properties":{"id":"02359","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.110591,42.352585],[-71.117193,42.364229],[-71.123679,42.368385],[-71.135155,42.346023],[-71.124697,42.35126],[-71.110591,42.352585]]]},"properties":{"id":"02134","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.078823,42.314608],[-71.069938,42.305513],[-71.041687,42.302151],[-71.045414,42.323798],[-71.063553,42.330791],[-71.066431,42.326256],[-71.078823,42.314608]]]},"properties":{"id":"02125","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.046389,42.363935],[-71.057191,42.356822],[-71.06048,42.355499],[-71.056245,42.352871],[-71.045566,42.359733],[-71.046389,42.363935]]]},"properties":{"id":"02110","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.088237,42.337549],[-71.080943,42.332931],[-71.066431,42.326256],[-71.063553,42.330791],[-71.059777,42.344896],[-71.064434,42.347186],[-71.08078,42.343744],[-71.088237,42.337549]]]},"properties":{"id":"02118","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.791199,42.242971],[-71.809892,42.230934],[-71.79827,42.206757],[-71.775928,42.219287],[-71.791199,42.242971]]]},"properties":{"id":"01607","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.895135,42.192425],[-71.855917,42.160633],[-71.831426,42.164353],[-71.79827,42.206757],[-71.809892,42.230934],[-71.819639,42.230317],[-71.856562,42.226683],[-71.876218,42.214982],[-71.895135,42.192425]]]},"properties":{"id":"01501","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.883306,42.024309],[-71.804666,42.023505],[-71.830044,42.09273],[-71.881509,42.075893],[-71.883306,42.024309]]]},"properties":{"id":"01570","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.145108,42.378085],[-72.123551,42.363853],[-72.112467,42.356423],[-72.035114,42.403967],[-72.007388,42.421029],[-72.099625,42.484473],[-72.10091,42.483654],[-72.183387,42.403356],[-72.145108,42.378085]],[[-72.090109,42.388234],[-72.097807,42.381957],[-72.096325,42.387688],[-72.090109,42.388234]]]},"properties":{"id":"01005","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.642933,42.100664],[-71.679962,42.103374],[-71.691092,42.098167],[-71.680869,42.011474],[-71.591104,42.013719],[-71.591951,42.063695],[-71.592914,42.114104],[-71.62335,42.105152],[-71.623688,42.105031],[-71.642933,42.100664]]]},"properties":{"id":"01569","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.799656,42.257385],[-71.805866,42.25998],[-71.820675,42.261493],[-71.828839,42.25487],[-71.819639,42.230317],[-71.809892,42.230934],[-71.791199,42.242971],[-71.799656,42.257385]]]},"properties":{"id":"01610","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.046073,42.601835],[-72.116631,42.626607],[-72.13912,42.623923],[-72.124219,42.604375],[-72.042319,42.583772],[-72.046073,42.601835]]]},"properties":{"id":"01436","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.674455,42.252365],[-71.73118,42.241421],[-71.732724,42.234651],[-71.727345,42.209431],[-71.643699,42.222211],[-71.644438,42.226626],[-71.674455,42.252365]]]},"properties":{"id":"01536","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.992734,42.072623],[-72.042065,42.11163],[-72.099811,42.028723],[-71.993102,42.026928],[-71.992734,42.072623]]]},"properties":{"id":"01550","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.13912,42.623923],[-72.224839,42.638981],[-72.269221,42.533409],[-72.244952,42.513552],[-72.10091,42.483654],[-72.099625,42.484473],[-72.08192,42.4957],[-72.124219,42.604375],[-72.13912,42.623923]]]},"properties":{"id":"01331","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.643699,42.222211],[-71.642709,42.17412],[-71.642168,42.165662],[-71.592752,42.121744],[-71.571591,42.142367],[-71.561047,42.158286],[-71.555572,42.183374],[-71.599318,42.225959],[-71.644438,42.226626],[-71.643699,42.222211]]]},"properties":{"id":"01568","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.324075,41.638863],[-70.335896,41.687666],[-70.373908,41.676566],[-70.382566,41.661549],[-70.36415,41.623734],[-70.333965,41.635243],[-70.324075,41.638863]]]},"properties":{"id":"02632","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.021004,41.905342],[-70.006879,41.880766],[-70.004085,41.881057],[-70.021004,41.905342]]]},"properties":{"id":"02663","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.161276,41.742201],[-70.143715,41.738348],[-70.122251,41.759512],[-70.181037,41.752344],[-70.161276,41.742201]]]},"properties":{"id":"02641","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.641431,41.672798],[-70.621251,41.657533],[-70.60229,41.655322],[-70.601854,41.655195],[-70.59625,41.675302],[-70.641431,41.672798]]]},"properties":{"id":"02534","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.281005,41.687383],[-70.272005,41.627211],[-70.220092,41.636006],[-70.221679,41.689248],[-70.281005,41.687383]]]},"properties":{"id":"02673","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.32257,41.630033],[-70.297407,41.631793],[-70.306404,41.636314],[-70.32257,41.630033]]]},"properties":{"id":"02647","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.004497,41.877614],[-70.004862,41.874277],[-69.998995,41.875941],[-70.004497,41.877614]]]},"properties":{"id":"02651","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.312952,42.348495],[-73.288376,42.322062],[-73.256083,42.327115],[-73.24518,42.343723],[-73.236383,42.364545],[-73.210249,42.398815],[-73.310852,42.39339],[-73.330816,42.351959],[-73.312952,42.348495]]]},"properties":{"id":"01240","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.476856,42.172241],[-73.436744,42.050539],[-73.428768,42.121302],[-73.476856,42.172241]]]},"properties":{"id":"01258","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.159365,42.412521],[-73.068595,42.38073],[-73.019714,42.38106],[-73.007761,42.393109],[-72.992643,42.449094],[-72.988153,42.466848],[-73.10825,42.48668],[-73.159365,42.412521]]]},"properties":{"id":"01235","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.986498,42.666428],[-72.98937,42.640542],[-72.951088,42.64155],[-72.986498,42.666428]]]},"properties":{"id":"01343","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.144152,41.699479],[-71.121103,41.695455],[-71.088314,41.726329],[-71.072032,41.757493],[-71.123989,41.766701],[-71.155449,41.723795],[-71.168517,41.708227],[-71.144152,41.699479]]]},"properties":{"id":"02720","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.201162,41.681909],[-71.17588,41.671542],[-71.181977,41.696861],[-71.201162,41.681909]]]},"properties":{"id":"02724","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.162591,42.031544],[-71.168681,42.061161],[-71.178457,42.057362],[-71.288265,42.014462],[-71.288263,42.014379],[-71.268739,41.9873],[-71.259289,41.974216],[-71.158704,42.012624],[-71.162591,42.031544]]]},"properties":{"id":"02048","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.1976,41.8437],[-71.111086,41.855113],[-71.186788,41.879338],[-71.1976,41.8437]]]},"properties":{"id":"02764","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.661157,41.463389],[-70.615118,41.41367],[-70.578969,41.480712],[-70.661157,41.463389]]]},"properties":{"id":"02568","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.615118,41.41367],[-70.661157,41.463389],[-70.715252,41.414631],[-70.65095,41.33897],[-70.60623,41.34074],[-70.615118,41.41367]]]},"properties":{"id":"02575","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.134529,42.676837],[-71.188858,42.696247],[-71.238103,42.669491],[-71.256103,42.656691],[-71.165015,42.597959],[-71.135441,42.599166],[-71.076133,42.604077],[-71.134529,42.676837]]]},"properties":{"id":"01810","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.113537,42.817154],[-71.081706,42.772366],[-71.042325,42.757098],[-71.020402,42.78423],[-70.999714,42.810691],[-71.053601,42.833089],[-71.113537,42.817154]]]},"properties":{"id":"01830","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.075257,42.531122],[-71.036096,42.505195],[-71.011592,42.501685],[-70.986911,42.511774],[-71.034847,42.5655],[-71.035245,42.570378],[-71.070978,42.555889],[-71.075257,42.531122]]]},"properties":{"id":"01940","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.939371,42.456548],[-70.939618,42.417456],[-70.904182,42.425046],[-70.934781,42.440008],[-70.933347,42.455705],[-70.939371,42.456548]]]},"properties":{"id":"01908","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.808609,42.81151],[-70.917423,42.787495],[-70.925911,42.78128],[-70.864814,42.748915],[-70.811332,42.749985],[-70.808609,42.81151]]]},"properties":{"id":"01951","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.640404,42.687581],[-70.617279,42.625792],[-70.593445,42.655578],[-70.640404,42.687581]]]},"properties":{"id":"01966","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.940294,42.822548],[-70.917423,42.787495],[-70.808609,42.81151],[-70.809952,42.81862],[-70.904198,42.83389],[-70.940294,42.822548]]]},"properties":{"id":"01950","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.636821,42.577985],[-72.675452,42.553018],[-72.649962,42.524536],[-72.556786,42.50648],[-72.556767,42.506495],[-72.574705,42.579422],[-72.578138,42.578488],[-72.636821,42.577985]]]},"properties":{"id":"01342","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.772737,42.658305],[-72.78603,42.735929],[-72.854422,42.737635],[-72.840924,42.702927],[-72.815335,42.639589],[-72.772737,42.658305]]]},"properties":{"id":"01346","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.986498,42.666428],[-72.951088,42.64155],[-72.951108,42.641426],[-72.840924,42.702927],[-72.854422,42.737635],[-72.927162,42.739232],[-72.949742,42.705103],[-72.986498,42.666428]]]},"properties":{"id":"01367","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.533396,42.384581],[-72.534981,42.304225],[-72.52791,42.301437],[-72.464186,42.308011],[-72.355396,42.340678],[-72.375022,42.420819],[-72.456705,42.437947],[-72.530968,42.428853],[-72.54421,42.427081],[-72.532253,42.395491],[-72.515406,42.396081],[-72.516884,42.388686],[-72.533396,42.384581]]]},"properties":{"id":"01002","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.629206,42.414301],[-72.663341,42.457635],[-72.674392,42.456245],[-72.669193,42.409658],[-72.629206,42.414301]]]},"properties":{"id":"01093","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.627342,42.162032],[-72.684836,42.162992],[-72.694861,42.105901],[-72.639402,42.098883],[-72.588531,42.093244],[-72.59199,42.096729],[-72.603771,42.103488],[-72.614158,42.108744],[-72.617176,42.111717],[-72.627342,42.162032]]]},"properties":{"id":"01089","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.546356,42.216489],[-72.593433,42.211691],[-72.601258,42.19501],[-72.583162,42.139774],[-72.541665,42.157191],[-72.515977,42.160817],[-72.517971,42.191766],[-72.541264,42.216987],[-72.546356,42.216489]]]},"properties":{"id":"01020","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.580941,42.114695],[-72.563697,42.097316],[-72.557323,42.098285],[-72.521523,42.103597],[-72.524339,42.140405],[-72.581786,42.115454],[-72.580941,42.114695]]]},"properties":{"id":"01109","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.536639,42.063736],[-72.589401,42.072268],[-72.607933,42.030795],[-72.531575,42.034006],[-72.536639,42.063736]]]},"properties":{"id":"01106","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.192625,42.091545],[-72.134954,42.090665],[-72.135023,42.161708],[-72.141843,42.160898],[-72.238436,42.174747],[-72.263876,42.183885],[-72.27434,42.143853],[-72.270095,42.092522],[-72.192625,42.091545]]]},"properties":{"id":"01010","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.034916,42.143682],[-72.895501,42.123885],[-72.87218,42.216439],[-72.912302,42.239133],[-73.001846,42.25109],[-73.007993,42.238409],[-73.034916,42.143682]]]},"properties":{"id":"01008","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.458185,42.034063],[-72.366991,42.032705],[-72.379483,42.103131],[-72.469939,42.084764],[-72.458185,42.034063]]]},"properties":{"id":"01036","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.213946,42.29429],[-72.231728,42.339276],[-72.23409,42.373623],[-72.234774,42.373595],[-72.314599,42.343526],[-72.355942,42.303401],[-72.335638,42.220156],[-72.333875,42.220719],[-72.221218,42.245252],[-72.211079,42.251262],[-72.210815,42.29446],[-72.213946,42.29429]]]},"properties":{"id":"01082","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.221062,42.36539],[-71.243076,42.358875],[-71.235156,42.337572],[-71.21708,42.337037],[-71.213466,42.337739],[-71.221062,42.36539]]]},"properties":{"id":"02465","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.17788,42.339096],[-71.178636,42.294595],[-71.137103,42.301185],[-71.140135,42.302147],[-71.148478,42.336254],[-71.170003,42.344252],[-71.17788,42.339096]]]},"properties":{"id":"02467","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.522184,42.266444],[-71.467161,42.230787],[-71.420075,42.238123],[-71.419298,42.257281],[-71.497046,42.289829],[-71.499575,42.284992],[-71.522184,42.266444]]]},"properties":{"id":"01721","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.271039,42.451044],[-71.26723,42.424438],[-71.232919,42.413171],[-71.211261,42.406074],[-71.202612,42.414826],[-71.192211,42.425455],[-71.255717,42.479747],[-71.270122,42.463684],[-71.271039,42.451044]]]},"properties":{"id":"02421","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.24089,42.490033],[-71.204291,42.465871],[-71.170063,42.515597],[-71.205032,42.544473],[-71.231897,42.506367],[-71.24089,42.490033]]]},"properties":{"id":"01803","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.073196,42.412996],[-71.075996,42.396396],[-71.073496,42.391796],[-71.055295,42.387097],[-71.033203,42.414226],[-71.028257,42.424148],[-71.073196,42.412996]]]},"properties":{"id":"02149","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.657916,42.650023],[-71.537136,42.663495],[-71.542521,42.702689],[-71.651911,42.705194],[-71.657916,42.650023]]]},"properties":{"id":"01463","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.075257,42.531122],[-71.100722,42.502258],[-71.077985,42.473975],[-71.053496,42.475895],[-71.036096,42.505195],[-71.075257,42.531122]]]},"properties":{"id":"01880","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.604251,42.397703],[-71.603187,42.367329],[-71.475505,42.380632],[-71.472678,42.390025],[-71.559046,42.411981],[-71.604251,42.397703]]]},"properties":{"id":"01749","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.215306,42.140326],[-71.238829,42.106408],[-71.178457,42.057362],[-71.168681,42.061161],[-71.138619,42.072813],[-71.149154,42.138641],[-71.178013,42.163961],[-71.194455,42.149916],[-71.215306,42.140326]]]},"properties":{"id":"02067","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.056511,42.153815],[-71.066609,42.164042],[-71.149154,42.138641],[-71.138619,42.072813],[-71.080192,42.095699],[-71.061249,42.103215],[-71.056511,42.153815]]]},"properties":{"id":"02072","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.207763,42.297181],[-71.253797,42.301753],[-71.259199,42.297976],[-71.264179,42.294493],[-71.294843,42.273052],[-71.232404,42.258477],[-71.231739,42.259283],[-71.188167,42.280412],[-71.191155,42.283059],[-71.207763,42.297181]]]},"properties":{"id":"02492","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.279256,42.29884],[-71.264179,42.294493],[-71.259199,42.297976],[-71.279256,42.29884]]]},"properties":{"id":"02457","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.025534,42.117293],[-71.061249,42.103215],[-71.080192,42.095699],[-71.071184,42.056468],[-71.069498,42.049715],[-70.996674,42.045401],[-71.025534,42.117293]]]},"properties":{"id":"02301","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.073264,42.270317],[-71.09747,42.291121],[-71.102694,42.284538],[-71.109317,42.274455],[-71.111567,42.271515],[-71.1085,42.261061],[-71.073264,42.270317]]]},"properties":{"id":"02126","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.997994,42.378197],[-70.977946,42.393591],[-70.986053,42.388116],[-70.997994,42.378197]]]},"properties":{"id":"02152","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.057191,42.356822],[-71.046389,42.363935],[-71.049095,42.371197],[-71.059795,42.368767],[-71.058525,42.366952],[-71.052473,42.36721],[-71.052061,42.36371],[-71.057709,42.363543],[-71.05817,42.362272],[-71.057805,42.358924],[-71.057191,42.356822]]]},"properties":{"id":"02109","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.859062,42.296523],[-71.820675,42.261493],[-71.805866,42.25998],[-71.799664,42.27135],[-71.836972,42.310021],[-71.859062,42.296523]]]},"properties":{"id":"01609","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.042065,42.11163],[-71.992734,42.072623],[-71.909235,42.091261],[-71.905379,42.133519],[-71.915419,42.183213],[-71.939356,42.189238],[-72.019886,42.183762],[-72.02942,42.174428],[-72.042065,42.11163]]]},"properties":{"id":"01507","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.99378,42.319336],[-72.035114,42.403967],[-72.112467,42.356423],[-72.041168,42.308697],[-71.989503,42.310155],[-71.99378,42.319336]]]},"properties":{"id":"01068","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.728385,42.475128],[-71.830019,42.492186],[-71.831745,42.431826],[-71.817778,42.403956],[-71.741919,42.38685],[-71.725979,42.390289],[-71.718362,42.410264],[-71.728385,42.475128]]]},"properties":{"id":"01564","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.091297,42.15388],[-72.135388,42.063146],[-72.135715,42.030246],[-72.099811,42.028723],[-72.042065,42.11163],[-72.02942,42.174428],[-72.072446,42.169302],[-72.091297,42.15388]]]},"properties":{"id":"01566","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.135388,42.063146],[-72.091297,42.15388],[-72.135023,42.161708],[-72.134954,42.090665],[-72.135388,42.063146]]]},"properties":{"id":"01518","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.580091,42.305891],[-71.670115,42.278763],[-71.674455,42.252365],[-71.644438,42.226626],[-71.599318,42.225959],[-71.549466,42.266124],[-71.580091,42.305891]]]},"properties":{"id":"01581","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.686589,42.441556],[-71.68445,42.439836],[-71.679393,42.444596],[-71.685609,42.442919],[-71.686589,42.441556]]]},"properties":{"id":"01561","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-71.497955,42.296889],[-71.493182,42.297282],[-71.492804,42.298008],[-71.497955,42.296889]]],[[[-71.497955,42.296889],[-71.499575,42.284992],[-71.497046,42.289829],[-71.493346,42.296964],[-71.497955,42.296889]]]]},"properties":{"id":"01745","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.65674,42.430957],[-71.660574,42.41821],[-71.604251,42.397703],[-71.559046,42.411981],[-71.548903,42.447308],[-71.624504,42.473032],[-71.635335,42.476745],[-71.65674,42.430957]]]},"properties":{"id":"01740","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.884101,42.281361],[-71.960633,42.281421],[-71.941889,42.200262],[-71.895938,42.219253],[-71.875367,42.263983],[-71.876783,42.266694],[-71.884101,42.281361]]]},"properties":{"id":"01524","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.591951,42.063695],[-71.591104,42.013719],[-71.563115,42.014273],[-71.563917,42.064056],[-71.591951,42.063695]]]},"properties":{"id":"01529","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.135023,42.161708],[-72.091297,42.15388],[-72.072446,42.169302],[-72.073036,42.232143],[-72.111283,42.238539],[-72.144852,42.172102],[-72.141843,42.160898],[-72.135023,42.161708]]]},"properties":{"id":"01506","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.03717,41.672149],[-70.037962,41.666426],[-70.000799,41.668813],[-70.002226,41.669809],[-70.015664,41.700332],[-70.03717,41.672149]]]},"properties":{"id":"02659","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.944827,41.703807],[-69.985912,41.711937],[-69.967302,41.693217],[-69.950122,41.695828],[-69.944827,41.703807]]]},"properties":{"id":"02650","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.153566,42.080504],[-70.147534,42.056919],[-70.085375,42.00936],[-70.04842,42.037709],[-70.153566,42.080504]]]},"properties":{"id":"02652","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.992034,41.967697],[-70.04842,42.037709],[-70.085375,42.00936],[-70.078513,41.96146],[-69.992034,41.967697]]]},"properties":{"id":"02666","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.023767,41.788601],[-70.004722,41.733017],[-69.988518,41.724994],[-69.940346,41.723619],[-69.942602,41.764993],[-69.939322,41.810552],[-70.010208,41.801084],[-70.023767,41.788601]]]},"properties":{"id":"02653","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.47403,41.765265],[-70.483836,41.700844],[-70.48261,41.697728],[-70.41887,41.704092],[-70.412539,41.710452],[-70.382892,41.740832],[-70.47403,41.765265]]]},"properties":{"id":"02537","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.143715,41.738348],[-70.133847,41.706137],[-70.004722,41.733017],[-70.023767,41.788601],[-70.122251,41.759512],[-70.143715,41.738348]]]},"properties":{"id":"02631","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.071067,42.148201],[-73.034916,42.143682],[-73.007993,42.238409],[-73.079159,42.235481],[-73.087078,42.186982],[-73.08705,42.186945],[-73.071067,42.148201]]]},"properties":{"id":"01029","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.288376,42.322062],[-73.312952,42.348495],[-73.315282,42.322093],[-73.288376,42.322062]]],[[[-73.351647,42.307179],[-73.343965,42.283224],[-73.365346,42.288242],[-73.362228,42.258431],[-73.289193,42.257506],[-73.288955,42.274926],[-73.288879,42.280146],[-73.314548,42.30375],[-73.351647,42.307179]],[[-73.330087,42.284865],[-73.324271,42.271387],[-73.340664,42.274231],[-73.330087,42.284865]]]]},"properties":{"id":"01262","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.192492,42.517283],[-73.165803,42.510932],[-73.107814,42.536487],[-73.085424,42.559501],[-73.095138,42.56158],[-73.174282,42.598507],[-73.195328,42.517733],[-73.192492,42.517283]]]},"properties":{"id":"01225","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.186788,41.879338],[-71.111086,41.855113],[-71.108685,41.85407],[-71.055819,41.861417],[-71.043917,41.885411],[-71.125685,41.967084],[-71.193893,41.910765],[-71.186788,41.879338]]]},"properties":{"id":"02780","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.316444,41.775561],[-71.208292,41.690503],[-71.186813,41.740411],[-71.153188,41.789593],[-71.193537,41.816536],[-71.292456,41.77488],[-71.316444,41.775561]]]},"properties":{"id":"02777","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.125685,41.967084],[-71.043917,41.885411],[-70.992864,41.906438],[-70.999705,41.929592],[-71.054184,41.982716],[-71.054718,41.985057],[-71.100854,41.993151],[-71.125685,41.967084]]]},"properties":{"id":"02767","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.072032,41.757493],[-71.047837,41.75312],[-71.014044,41.780333],[-71.03657,41.816525],[-71.114489,41.778801],[-71.123989,41.766701],[-71.072032,41.757493]]]},"properties":{"id":"02702","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.355034,41.896542],[-71.355201,41.89723],[-71.355711,41.897154],[-71.355563,41.896514],[-71.355034,41.896542]]]},"properties":{"id":"02861","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.615118,41.41367],[-70.60623,41.34074],[-70.541501,41.422105],[-70.615118,41.41367]]]},"properties":{"id":"02539","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.188858,42.696247],[-71.134529,42.676837],[-71.13728,42.706733],[-71.145902,42.705491],[-71.166478,42.70048],[-71.188858,42.696247]]]},"properties":{"id":"01843","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.145902,42.705491],[-71.153438,42.711233],[-71.166478,42.70048],[-71.145902,42.705491]]]},"properties":{"id":"01840","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.944707,42.458843],[-70.953075,42.456484],[-70.948454,42.453734],[-70.944707,42.458843]]]},"properties":{"id":"01901","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.800316,42.728059],[-70.948594,42.680667],[-70.965225,42.672274],[-70.903607,42.659768],[-70.816945,42.641285],[-70.733821,42.651305],[-70.7236,42.667591],[-70.800316,42.728059]]]},"properties":{"id":"01938","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.924125,42.735992],[-70.976411,42.694352],[-70.948594,42.680667],[-70.800316,42.728059],[-70.811332,42.749985],[-70.864814,42.748915],[-70.924125,42.735992]]]},"properties":{"id":"01969","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.663341,42.457635],[-72.629206,42.414301],[-72.6255,42.415009],[-72.581106,42.422504],[-72.556786,42.50648],[-72.649962,42.524536],[-72.663341,42.457635]]]},"properties":{"id":"01373","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.51469,42.569085],[-72.526808,42.559899],[-72.517548,42.552641],[-72.507995,42.560569],[-72.51469,42.569085]]]},"properties":{"id":"01347","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.563697,42.097316],[-72.582693,42.081558],[-72.589401,42.072268],[-72.536639,42.063736],[-72.534358,42.081835],[-72.557323,42.098285],[-72.563697,42.097316]]]},"properties":{"id":"01108","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.845814,42.200141],[-72.813156,42.15552],[-72.853897,42.115006],[-72.837212,42.102218],[-72.705989,42.080797],[-72.694861,42.105901],[-72.684836,42.162992],[-72.686861,42.18339],[-72.813416,42.245007],[-72.845814,42.200141]],[[-72.795124,42.129594],[-72.796603,42.131502],[-72.793149,42.131136],[-72.795124,42.129594]]]},"properties":{"id":"01085","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.885635,42.340036],[-72.953347,42.343843],[-72.953931,42.343792],[-72.912302,42.239133],[-72.87218,42.216439],[-72.846757,42.201135],[-72.845814,42.200141],[-72.813416,42.245007],[-72.814405,42.256819],[-72.821125,42.338659],[-72.854462,42.343341],[-72.885635,42.340036]]]},"properties":{"id":"01050","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.639402,42.098883],[-72.663288,42.034209],[-72.607933,42.030795],[-72.589401,42.072268],[-72.582693,42.081558],[-72.588531,42.093244],[-72.639402,42.098883]]]},"properties":{"id":"01001","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.739717,42.336612],[-72.656086,42.289942],[-72.647673,42.374339],[-72.672126,42.371353],[-72.682863,42.370021],[-72.739717,42.336612]]]},"properties":{"id":"01062","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.656086,42.289942],[-72.739717,42.336612],[-72.741217,42.36273],[-72.820239,42.35307],[-72.821125,42.338659],[-72.814405,42.256819],[-72.667733,42.225461],[-72.613138,42.286265],[-72.614701,42.290199],[-72.656086,42.289942]]]},"properties":{"id":"01027","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.533396,42.384581],[-72.516884,42.388686],[-72.515406,42.396081],[-72.532253,42.395491],[-72.533396,42.384581]]]},"properties":{"id":"01003","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.21708,42.337037],[-71.235156,42.337572],[-71.250444,42.32927],[-71.249171,42.323568],[-71.228195,42.316833],[-71.225777,42.317066],[-71.214768,42.332171],[-71.21708,42.337037]]]},"properties":{"id":"02468","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.38092,42.649008],[-71.368882,42.661753],[-71.367479,42.698265],[-71.457702,42.700524],[-71.496484,42.649802],[-71.493541,42.617418],[-71.417652,42.653821],[-71.38092,42.649008]]]},"properties":{"id":"01879","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.393325,42.298769],[-71.493182,42.297282],[-71.493346,42.296964],[-71.497046,42.289829],[-71.419298,42.257281],[-71.390365,42.271916],[-71.393325,42.298769]]]},"properties":{"id":"01702","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.134514,42.404172],[-71.118159,42.388025],[-71.104022,42.391463],[-71.110446,42.399318],[-71.1324,42.414573],[-71.134514,42.404172]]]},"properties":{"id":"02144","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.118159,42.388025],[-71.134514,42.404172],[-71.152356,42.400791],[-71.156302,42.395852],[-71.112939,42.38285],[-71.118159,42.388025]]]},"properties":{"id":"02140","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.077985,42.473975],[-71.082104,42.444017],[-71.025864,42.444743],[-71.053496,42.475895],[-71.077985,42.473975]]]},"properties":{"id":"02176","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.216976,42.306607],[-71.207763,42.297181],[-71.214768,42.332171],[-71.225777,42.317066],[-71.216976,42.306607]]]},"properties":{"id":"02461","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.189599,42.449795],[-71.182277,42.436279],[-71.149798,42.435795],[-71.119402,42.453445],[-71.112116,42.473818],[-71.189599,42.449795]]]},"properties":{"id":"01890","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.194177,42.386031],[-71.154496,42.375172],[-71.156302,42.395852],[-71.152356,42.400791],[-71.1639,42.405707],[-71.202612,42.414826],[-71.211261,42.406074],[-71.19446,42.386317],[-71.194177,42.386031]]]},"properties":{"id":"02478","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.548903,42.447308],[-71.559046,42.411981],[-71.472678,42.390025],[-71.469452,42.402631],[-71.464285,42.450273],[-71.498546,42.463775],[-71.543297,42.466451],[-71.548903,42.447308]]]},"properties":{"id":"01775","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.572041,42.548987],[-71.538949,42.543069],[-71.511459,42.569386],[-71.608972,42.581183],[-71.61091,42.554966],[-71.572041,42.548987]]]},"properties":{"id":"01432","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.303751,42.1495],[-71.269569,42.200499],[-71.338435,42.215232],[-71.34417,42.200703],[-71.339638,42.149416],[-71.303751,42.1495]]]},"properties":{"id":"02052","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.381401,42.018798],[-71.381301,41.984998],[-71.288263,42.014379],[-71.288265,42.014462],[-71.289083,42.045041],[-71.381401,42.018798]]]},"properties":{"id":"02762","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.948266,42.230516],[-70.944418,42.189954],[-70.920936,42.182178],[-70.92329,42.2286],[-70.948266,42.230516]]]},"properties":{"id":"02189","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.951524,42.254722],[-70.967991,42.237999],[-70.960491,42.235099],[-70.948266,42.230516],[-70.92329,42.2286],[-70.931029,42.247121],[-70.951524,42.254722]]]},"properties":{"id":"02191","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.194455,42.149916],[-71.219905,42.168422],[-71.215306,42.140326],[-71.194455,42.149916]]]},"properties":{"id":"02032","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.131322,42.228256],[-71.14597,42.209247],[-71.154967,42.197044],[-71.178013,42.163961],[-71.149154,42.138641],[-71.066609,42.164042],[-71.10293,42.200681],[-71.130808,42.22788],[-71.131322,42.228256]]]},"properties":{"id":"02021","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.753527,41.933241],[-70.717655,41.801527],[-70.643564,41.805678],[-70.630059,41.776762],[-70.547505,41.801944],[-70.536769,41.811316],[-70.604196,42.013627],[-70.612529,42.013552],[-70.690432,41.985819],[-70.753527,41.933241]],[[-70.665912,41.841369],[-70.662537,41.855941],[-70.649214,41.852578],[-70.665912,41.841369]]]},"properties":{"id":"02360","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.799073,41.710109],[-70.750847,41.669591],[-70.71951,41.720072],[-70.746439,41.738308],[-70.778752,41.761246],[-70.799073,41.710109]]]},"properties":{"id":"02738","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.896943,42.019149],[-70.833403,42.019563],[-70.842486,42.094801],[-70.887599,42.087951],[-70.902387,42.085901],[-70.908188,42.061727],[-70.896943,42.019149]]]},"properties":{"id":"02341","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.057709,42.363543],[-71.052061,42.36371],[-71.052473,42.36721],[-71.058525,42.366952],[-71.057709,42.363543]]]},"properties":{"id":"02113","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.137103,42.301185],[-71.127925,42.300157],[-71.096402,42.293235],[-71.097017,42.312177],[-71.098917,42.325025],[-71.110823,42.331479],[-71.113437,42.331891],[-71.140135,42.302147],[-71.137103,42.301185]]]},"properties":{"id":"02130","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.078823,42.314608],[-71.097017,42.312177],[-71.096402,42.293235],[-71.095759,42.291973],[-71.071569,42.30243],[-71.069938,42.305513],[-71.078823,42.314608]]]},"properties":{"id":"02121","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.066431,42.326256],[-71.080943,42.332931],[-71.098917,42.325025],[-71.097017,42.312177],[-71.078823,42.314608],[-71.066431,42.326256]]]},"properties":{"id":"02119","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.091184,42.354233],[-71.110894,42.335505],[-71.113437,42.331891],[-71.110823,42.331479],[-71.088237,42.337549],[-71.08078,42.343744],[-71.081775,42.345386],[-71.083796,42.348367],[-71.087445,42.355417],[-71.091184,42.354233]]]},"properties":{"id":"02115","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.123679,42.368385],[-71.117193,42.364229],[-71.123168,42.36898],[-71.123679,42.368385]]]},"properties":{"id":"02163","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.263876,42.183885],[-72.238436,42.174747],[-72.239127,42.226812],[-72.263876,42.183885]]]},"properties":{"id":"01092","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.144852,42.172102],[-72.211079,42.251262],[-72.221218,42.245252],[-72.239127,42.226812],[-72.238436,42.174747],[-72.141843,42.160898],[-72.144852,42.172102]]]},"properties":{"id":"01083","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.687938,42.325516],[-71.670115,42.278763],[-71.580091,42.305891],[-71.585168,42.310977],[-71.624702,42.350465],[-71.676188,42.36485],[-71.687938,42.325516]]]},"properties":{"id":"01532","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.960633,42.281421],[-71.884101,42.281361],[-71.865221,42.292762],[-71.90486,42.320879],[-71.918768,42.347983],[-71.99378,42.319336],[-71.989503,42.310155],[-71.960633,42.281421]]]},"properties":{"id":"01612","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.231728,42.339276],[-72.213946,42.29429],[-72.157728,42.343271],[-72.151983,42.347082],[-72.231728,42.339276]]]},"properties":{"id":"01031","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.019886,42.183762],[-72.030322,42.245206],[-72.073036,42.232143],[-72.072446,42.169302],[-72.02942,42.174428],[-72.019886,42.183762]]]},"properties":{"id":"01515","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.858612,42.629045],[-71.842004,42.514679],[-71.840179,42.511744],[-71.76295,42.563924],[-71.775322,42.636663],[-71.85841,42.63384],[-71.858612,42.629045]]]},"properties":{"id":"01420","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.157728,42.343271],[-72.213946,42.29429],[-72.210815,42.29446],[-72.134811,42.281146],[-72.041168,42.308697],[-72.112467,42.356423],[-72.123551,42.363853],[-72.157728,42.343271]]]},"properties":{"id":"01531","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.002226,41.669809],[-69.975256,41.653044],[-69.950122,41.695828],[-69.967302,41.693217],[-69.985912,41.711937],[-69.944827,41.703807],[-69.940346,41.723619],[-69.988518,41.724994],[-70.015664,41.700332],[-70.002226,41.669809]]]},"properties":{"id":"02633","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.328762,41.689131],[-70.281005,41.687383],[-70.280553,41.698004],[-70.279159,41.709907],[-70.26113,41.714036],[-70.261109,41.715211],[-70.271161,41.721591],[-70.328762,41.689131]]]},"properties":{"id":"02630","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.54403,41.770063],[-70.579743,41.720114],[-70.59625,41.675302],[-70.601854,41.655195],[-70.539646,41.637212],[-70.515962,41.666769],[-70.499494,41.704257],[-70.526214,41.758131],[-70.54403,41.770063]]]},"properties":{"id":"02542","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.854546,41.45997],[-70.867694,41.422515],[-70.935531,41.407559],[-70.780537,41.443156],[-70.66655,41.510695],[-70.707334,41.532689],[-70.854546,41.45997]]]},"properties":{"id":"02543","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-70.48261,41.697728],[-70.496004,41.683591],[-70.458737,41.664041],[-70.41887,41.704092],[-70.48261,41.697728]]],[[[-70.526214,41.758131],[-70.499494,41.704257],[-70.483836,41.700844],[-70.47403,41.765265],[-70.494834,41.776657],[-70.520337,41.773464],[-70.526214,41.758131]]]]},"properties":{"id":"02563","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.147534,42.056919],[-70.153566,42.080504],[-70.2015,42.085494],[-70.190681,42.044026],[-70.147534,42.056919]]]},"properties":{"id":"02657","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.221679,41.689248],[-70.220092,41.636006],[-70.191827,41.647495],[-70.170606,41.673348],[-70.182367,41.705588],[-70.221679,41.689248]]]},"properties":{"id":"02664","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.010208,41.801084],[-69.939322,41.810552],[-69.931096,41.809554],[-69.952145,41.880616],[-69.998995,41.875941],[-70.004862,41.874277],[-70.004497,41.877614],[-70.004085,41.881057],[-70.006879,41.880766],[-70.010208,41.801084]]]},"properties":{"id":"02642","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.288955,42.274926],[-73.289193,42.257506],[-73.243936,42.257125],[-73.161547,42.248477],[-73.159998,42.304207],[-73.236383,42.364545],[-73.24518,42.343723],[-73.24369,42.326987],[-73.256083,42.327115],[-73.288376,42.322062],[-73.315282,42.322093],[-73.314548,42.30375],[-73.288879,42.280146],[-73.288955,42.274926]]]},"properties":{"id":"01238","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.079159,42.235481],[-73.007993,42.238409],[-73.001846,42.25109],[-73.000067,42.312693],[-73.068595,42.38073],[-73.159365,42.412521],[-73.195589,42.413563],[-73.159998,42.304207],[-73.161547,42.248477],[-73.161574,42.247545],[-73.079159,42.235481]]]},"properties":{"id":"01223","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.395417,42.258524],[-73.362228,42.258431],[-73.365346,42.288242],[-73.395417,42.258524]]]},"properties":{"id":"01236","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.330087,42.284865],[-73.340664,42.274231],[-73.324271,42.271387],[-73.330087,42.284865]]]},"properties":{"id":"01229","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.138464,41.603635],[-70.979327,41.512331],[-71.033099,41.570937],[-71.088314,41.726329],[-71.121103,41.695455],[-71.118971,41.680629],[-71.138464,41.603635]],[[-71.085795,41.54118],[-71.069434,41.520702],[-71.084779,41.521671],[-71.085795,41.54118]]]},"properties":{"id":"02790","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.17588,41.671542],[-71.138464,41.603635],[-71.118971,41.680629],[-71.144152,41.699479],[-71.168517,41.708227],[-71.181977,41.696861],[-71.17588,41.671542]]]},"properties":{"id":"02721","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.111086,41.855113],[-71.1976,41.8437],[-71.193537,41.816536],[-71.153188,41.789593],[-71.11961,41.790968],[-71.108685,41.85407],[-71.111086,41.855113]]]},"properties":{"id":"02715","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.74565,42.599576],[-70.733821,42.651305],[-70.816945,42.641285],[-70.800797,42.595324],[-70.74565,42.599576]]]},"properties":{"id":"01929","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.034847,42.5655],[-70.986911,42.511774],[-70.949917,42.494776],[-70.91349,42.541494],[-71.002653,42.5633],[-71.034847,42.5655]]]},"properties":{"id":"01960","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.904198,42.83389],[-70.809952,42.81862],[-70.914538,42.886619],[-70.904198,42.83389]]]},"properties":{"id":"01952","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.816945,42.641285],[-70.903607,42.659768],[-70.918925,42.622767],[-70.814493,42.593377],[-70.800797,42.595324],[-70.816945,42.641285]]]},"properties":{"id":"01982","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.976721,42.62088],[-70.964659,42.599647],[-70.93109,42.612444],[-70.918925,42.622767],[-70.903607,42.659768],[-70.965225,42.672274],[-70.976721,42.62088]]]},"properties":{"id":"01983","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.541264,42.216987],[-72.517971,42.191766],[-72.52429,42.218734],[-72.541264,42.216987]]]},"properties":{"id":"01022","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.484595,42.156524],[-72.515977,42.160817],[-72.541665,42.157191],[-72.524339,42.140405],[-72.503234,42.145341],[-72.48304,42.148893],[-72.484595,42.156524]]]},"properties":{"id":"01151","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.503234,42.145341],[-72.518458,42.100807],[-72.495542,42.102736],[-72.472274,42.09556],[-72.48304,42.148893],[-72.503234,42.145341]]]},"properties":{"id":"01129","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.34126,42.196846],[-72.34133,42.185228],[-72.322913,42.182012],[-72.331569,42.20623],[-72.34126,42.196846]]]},"properties":{"id":"01079","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.517971,42.191766],[-72.515977,42.160817],[-72.484595,42.156524],[-72.395288,42.184765],[-72.395492,42.185812],[-72.447145,42.22734],[-72.52429,42.218734],[-72.517971,42.191766]]]},"properties":{"id":"01056","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.647673,42.374339],[-72.656086,42.289942],[-72.614701,42.290199],[-72.633964,42.351152],[-72.636654,42.361519],[-72.647673,42.374339]],[[-72.63737,42.317771],[-72.639917,42.317255],[-72.640347,42.319857],[-72.637955,42.319869],[-72.63737,42.317771]]]},"properties":{"id":"01060","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.355396,42.340678],[-72.464186,42.308011],[-72.447145,42.22734],[-72.395492,42.185812],[-72.358219,42.189802],[-72.346238,42.210743],[-72.335638,42.220156],[-72.355942,42.303401],[-72.355396,42.340678]]]},"properties":{"id":"01007","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.626203,42.411176],[-72.636654,42.361519],[-72.633964,42.351152],[-72.581106,42.422504],[-72.6255,42.415009],[-72.626203,42.411176]]]},"properties":{"id":"01038","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.000415,42.369037],[-72.953931,42.343792],[-72.953347,42.343843],[-73.004083,42.388329],[-73.000415,42.369037]]],[[[-73.000415,42.369037],[-73.019714,42.38106],[-73.068595,42.38073],[-73.000067,42.312693],[-73.000415,42.369037]]]]},"properties":{"id":"01243","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.629206,42.414301],[-72.669193,42.409658],[-72.669916,42.400274],[-72.626203,42.411176],[-72.6255,42.415009],[-72.629206,42.414301]]]},"properties":{"id":"01066","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.880814,42.43301],[-72.885635,42.340036],[-72.854462,42.343341],[-72.863175,42.434871],[-72.880814,42.43301]]]},"properties":{"id":"01084","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.30461,42.529285],[-71.242829,42.587959],[-71.296271,42.612675],[-71.297028,42.613011],[-71.332543,42.560756],[-71.30461,42.529285]]]},"properties":{"id":"01862","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.242829,42.587959],[-71.30461,42.529285],[-71.306565,42.520562],[-71.231897,42.506367],[-71.205032,42.544473],[-71.211144,42.573305],[-71.242829,42.587959]]]},"properties":{"id":"01821","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.298892,42.462738],[-71.317538,42.502425],[-71.384899,42.50455],[-71.430389,42.436937],[-71.366326,42.412715],[-71.298892,42.462738]]]},"properties":{"id":"01742","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.197066,42.343516],[-71.204491,42.365808],[-71.21719,42.366534],[-71.221062,42.36539],[-71.213466,42.337739],[-71.197066,42.343516]]]},"properties":{"id":"02460","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.235767,42.376349],[-71.232919,42.413171],[-71.26723,42.424438],[-71.282825,42.399728],[-71.270644,42.369571],[-71.235767,42.376349]]]},"properties":{"id":"02451","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.271039,42.451044],[-71.270122,42.463684],[-71.285507,42.449159],[-71.271039,42.451044]]]},"properties":{"id":"01731","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.332543,42.560756],[-71.388359,42.549949],[-71.399586,42.533992],[-71.384899,42.50455],[-71.317538,42.502425],[-71.306565,42.520562],[-71.30461,42.529285],[-71.332543,42.560756]]]},"properties":{"id":"01741","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.430964,42.519951],[-71.42911,42.519219],[-71.426538,42.520571],[-71.428709,42.522073],[-71.430964,42.519951]]]},"properties":{"id":"01718","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.007021,41.25603],[-70.037608,41.239714],[-69.965074,41.251386],[-69.973881,41.302304],[-70.003748,41.258805],[-70.007021,41.25603]]]},"properties":{"id":"02564","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.279256,42.29884],[-71.259199,42.297976],[-71.253797,42.301753],[-71.227689,42.315473],[-71.228195,42.316833],[-71.249171,42.323568],[-71.269919,42.328096],[-71.317239,42.316125],[-71.279256,42.29884]]]},"properties":{"id":"02481","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.251494,42.202635],[-71.238302,42.182831],[-71.154967,42.197044],[-71.14597,42.209247],[-71.231739,42.259283],[-71.232404,42.258477],[-71.251494,42.202635]]]},"properties":{"id":"02090","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.269569,42.200499],[-71.303751,42.1495],[-71.28539,42.097661],[-71.255501,42.104078],[-71.238829,42.106408],[-71.215306,42.140326],[-71.219905,42.168422],[-71.238302,42.182831],[-71.251494,42.202635],[-71.269569,42.200499]]]},"properties":{"id":"02081","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.338435,42.215232],[-71.269569,42.200499],[-71.251494,42.202635],[-71.232404,42.258477],[-71.294843,42.273052],[-71.30846,42.270709],[-71.332434,42.247729],[-71.338435,42.215232]]]},"properties":{"id":"02030","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.207763,42.297181],[-71.216976,42.306607],[-71.227689,42.315473],[-71.253797,42.301753],[-71.207763,42.297181]]]},"properties":{"id":"02494","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.025534,42.117293],[-70.996674,42.045401],[-70.987163,42.046584],[-70.975205,42.071203],[-70.975519,42.087446],[-71.002029,42.126506],[-71.021859,42.118745],[-71.025534,42.117293]]]},"properties":{"id":"02302","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.860869,41.954998],[-70.908473,41.966346],[-70.999705,41.929592],[-70.992864,41.906438],[-70.98733,41.905839],[-70.896053,41.794375],[-70.798951,41.805718],[-70.77026,41.825777],[-70.829827,41.927699],[-70.860869,41.954998]]]},"properties":{"id":"02346","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.915787,42.1001],[-70.975519,42.087446],[-70.975205,42.071203],[-70.908188,42.061727],[-70.902387,42.085901],[-70.915787,42.1001]]]},"properties":{"id":"02382","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.685294,42.129901],[-70.691372,42.143323],[-70.694116,42.142582],[-70.685294,42.129901]]]},"properties":{"id":"02047","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.135155,42.346023],[-71.123679,42.368385],[-71.123168,42.36898],[-71.143501,42.364969],[-71.167625,42.360073],[-71.170003,42.344252],[-71.148478,42.336254],[-71.139977,42.34214],[-71.135155,42.346023]]]},"properties":{"id":"02135","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.083796,42.348367],[-71.081775,42.345386],[-71.07876,42.34788],[-71.083796,42.348367]]]},"properties":{"id":"02199","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.979721,42.532389],[-71.928022,42.602009],[-71.985017,42.64721],[-72.046073,42.601835],[-72.042319,42.583772],[-72.028913,42.567951],[-72.020669,42.559127],[-72.001396,42.546624],[-71.979721,42.532389]]]},"properties":{"id":"01440","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.687938,42.325516],[-71.764442,42.312911],[-71.765154,42.306432],[-71.758928,42.274149],[-71.73118,42.241421],[-71.674455,42.252365],[-71.670115,42.278763],[-71.687938,42.325516]]]},"properties":{"id":"01545","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.830044,42.09273],[-71.804666,42.023505],[-71.680869,42.011474],[-71.691092,42.098167],[-71.797208,42.099209],[-71.830044,42.09273]]]},"properties":{"id":"01516","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.928022,42.602009],[-71.979721,42.532389],[-71.919835,42.491447],[-71.842004,42.514679],[-71.858612,42.629045],[-71.928022,42.602009]]]},"properties":{"id":"01473","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.571591,42.142367],[-71.499575,42.103508],[-71.501491,42.116807],[-71.561047,42.158286],[-71.571591,42.142367]]]},"properties":{"id":"01747","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.25168,42.508375],[-72.234774,42.373595],[-72.23409,42.373623],[-72.183387,42.403356],[-72.10091,42.483654],[-72.244952,42.513552],[-72.25168,42.508375]]]},"properties":{"id":"01366","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.23409,42.373623],[-72.231728,42.339276],[-72.151983,42.347082],[-72.145108,42.378085],[-72.183387,42.403356],[-72.23409,42.373623]]]},"properties":{"id":"01037","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.842004,42.514679],[-71.919835,42.491447],[-71.965368,42.446559],[-71.887761,42.39495],[-71.831745,42.431826],[-71.830019,42.492186],[-71.840179,42.511744],[-71.842004,42.514679]]]},"properties":{"id":"01541","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.572041,42.548987],[-71.606651,42.517115],[-71.6199,42.501998],[-71.624504,42.473032],[-71.548903,42.447308],[-71.543297,42.466451],[-71.52991,42.519716],[-71.538949,42.543069],[-71.572041,42.548987]]]},"properties":{"id":"01451","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.692366,42.154296],[-71.62335,42.105152],[-71.592914,42.114104],[-71.592752,42.121744],[-71.642168,42.165662],[-71.691314,42.16313],[-71.692366,42.154296]]]},"properties":{"id":"01534","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.6199,42.501998],[-71.627944,42.493544],[-71.635335,42.476745],[-71.624504,42.473032],[-71.6199,42.501998]]]},"properties":{"id":"01467","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.623688,42.105031],[-71.636517,42.113361],[-71.642933,42.100664],[-71.623688,42.105031]]]},"properties":{"id":"01525","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.030322,42.245206],[-72.041168,42.308697],[-72.134811,42.281146],[-72.111283,42.238539],[-72.073036,42.232143],[-72.030322,42.245206]]]},"properties":{"id":"01535","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.382892,41.740832],[-70.412539,41.710452],[-70.373908,41.676566],[-70.335896,41.687666],[-70.328762,41.689131],[-70.271161,41.721591],[-70.382892,41.740832]]]},"properties":{"id":"02668","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.998995,41.875941],[-69.952145,41.880616],[-69.992034,41.967697],[-70.078513,41.96146],[-70.021004,41.905342],[-70.004085,41.881057],[-70.004497,41.877614],[-69.998995,41.875941]]]},"properties":{"id":"02667","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.184773,41.712867],[-70.241608,41.726321],[-70.261109,41.715211],[-70.26113,41.714036],[-70.280553,41.698004],[-70.281005,41.687383],[-70.221679,41.689248],[-70.182367,41.705588],[-70.184773,41.712867]]]},"properties":{"id":"02675","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.117657,41.655601],[-70.09826,41.679713],[-70.127271,41.685739],[-70.117657,41.655601]]]},"properties":{"id":"02671","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.499494,41.704257],[-70.515962,41.666769],[-70.496004,41.683591],[-70.48261,41.697728],[-70.483836,41.700844],[-70.499494,41.704257]]]},"properties":{"id":"02644","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.256797,42.241672],[-73.184553,42.14006],[-73.146402,42.180439],[-73.162083,42.198793],[-73.256797,42.241672]]]},"properties":{"id":"01245","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.174282,42.598507],[-73.095138,42.56158],[-73.063634,42.649877],[-73.169757,42.660173],[-73.171579,42.628707],[-73.174282,42.598507]]]},"properties":{"id":"01220","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.296414,41.968163],[-71.317105,41.953847],[-71.381467,41.9148],[-71.355563,41.896514],[-71.355711,41.897154],[-71.355201,41.89723],[-71.355034,41.896542],[-71.338698,41.898399],[-71.300719,41.903661],[-71.208162,41.908852],[-71.259289,41.974216],[-71.268739,41.9873],[-71.296414,41.968163]]]},"properties":{"id":"02703","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.162591,42.031544],[-71.087117,42.064403],[-71.072344,42.055725],[-71.071184,42.056468],[-71.080192,42.095699],[-71.138619,42.072813],[-71.168681,42.061161],[-71.162591,42.031544]]]},"properties":{"id":"02356","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.925382,41.612804],[-70.905204,41.622665],[-70.906397,41.624441],[-70.925382,41.612804]]]},"properties":{"id":"02744","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.033099,41.570937],[-70.94263,41.617432],[-70.972785,41.660506],[-70.965927,41.668334],[-70.965484,41.733902],[-71.047837,41.75312],[-71.072032,41.757493],[-71.088314,41.726329],[-71.033099,41.570937]]]},"properties":{"id":"02747","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.186813,41.740411],[-71.155449,41.723795],[-71.123989,41.766701],[-71.114489,41.778801],[-71.11961,41.790968],[-71.153188,41.789593],[-71.186813,41.740411]]]},"properties":{"id":"02726","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.296414,41.968163],[-71.312105,41.97191],[-71.317105,41.953847],[-71.296414,41.968163]]]},"properties":{"id":"02763","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.921782,41.791244],[-71.014044,41.780333],[-71.047837,41.75312],[-70.965484,41.733902],[-70.942993,41.744668],[-70.907184,41.763543],[-70.921782,41.791244]]]},"properties":{"id":"02717","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.905204,41.622665],[-70.843167,41.628422],[-70.858212,41.681167],[-70.917182,41.663603],[-70.917808,41.654687],[-70.906397,41.624441],[-70.905204,41.622665]]]},"properties":{"id":"02719","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.186813,41.740411],[-71.208292,41.690503],[-71.201162,41.681909],[-71.181977,41.696861],[-71.168517,41.708227],[-71.155449,41.723795],[-71.186813,41.740411]]]},"properties":{"id":"02725","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.959693,42.753833],[-71.031619,42.718038],[-70.976411,42.694352],[-70.924125,42.735992],[-70.959693,42.753833]]]},"properties":{"id":"01833","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.130645,42.76085],[-71.182446,42.794248],[-71.255185,42.73667],[-71.238103,42.669491],[-71.188858,42.696247],[-71.134134,42.721507],[-71.115603,42.741191],[-71.130645,42.76085]]]},"properties":{"id":"01844","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.002653,42.5633],[-70.91349,42.541494],[-70.908399,42.545558],[-70.922263,42.591512],[-70.93109,42.612444],[-70.964659,42.599647],[-71.002653,42.5633]],[[-70.987314,42.588087],[-70.97993,42.585795],[-70.982262,42.583799],[-70.98732,42.584915],[-70.987314,42.588087]]]},"properties":{"id":"01923","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.959693,42.753833],[-70.924125,42.735992],[-70.864814,42.748915],[-70.925911,42.78128],[-70.967169,42.758326],[-70.959693,42.753833]]]},"properties":{"id":"01922","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.552686,42.612195],[-72.526426,42.602334],[-72.497071,42.597569],[-72.491,42.604008],[-72.496234,42.678541],[-72.540576,42.655687],[-72.552686,42.612195]]]},"properties":{"id":"01354","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.466104,42.576718],[-72.497071,42.597569],[-72.526426,42.602334],[-72.521276,42.587806],[-72.51469,42.569085],[-72.507995,42.560569],[-72.453626,42.549745],[-72.466104,42.576718]]]},"properties":{"id":"01349","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.362287,42.595283],[-72.35679,42.548702],[-72.269221,42.533409],[-72.224839,42.638981],[-72.282981,42.721549],[-72.30483,42.722204],[-72.3647,42.609376],[-72.362287,42.595283]]]},"properties":{"id":"01364","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.440929,42.510411],[-72.395269,42.505415],[-72.35679,42.548702],[-72.362287,42.595283],[-72.466104,42.576718],[-72.453626,42.549745],[-72.459641,42.516427],[-72.440929,42.510411]]]},"properties":{"id":"01379","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.524339,42.140405],[-72.521523,42.103597],[-72.518458,42.100807],[-72.503234,42.145341],[-72.524339,42.140405]]]},"properties":{"id":"01119","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.48304,42.148893],[-72.472274,42.09556],[-72.471164,42.089719],[-72.469939,42.084764],[-72.379483,42.103131],[-72.388949,42.152434],[-72.39508,42.184061],[-72.395288,42.184765],[-72.484595,42.156524],[-72.48304,42.148893]]]},"properties":{"id":"01095","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.395492,42.185812],[-72.395288,42.184765],[-72.39508,42.184061],[-72.358219,42.189802],[-72.395492,42.185812]]]},"properties":{"id":"01080","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.270095,42.092522],[-72.270675,42.03168],[-72.198827,42.031026],[-72.192625,42.091545],[-72.270095,42.092522]]]},"properties":{"id":"01081","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.004083,42.388329],[-72.953347,42.343843],[-72.885635,42.340036],[-72.880814,42.43301],[-72.992643,42.449094],[-73.007761,42.393109],[-73.004083,42.388329]]]},"properties":{"id":"01098","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.667733,42.225461],[-72.814405,42.256819],[-72.813416,42.245007],[-72.686861,42.18339],[-72.667733,42.225461]]]},"properties":{"id":"01073","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.534981,42.304225],[-72.609202,42.285477],[-72.593433,42.211691],[-72.546356,42.216489],[-72.52791,42.301437],[-72.534981,42.304225]]]},"properties":{"id":"01075","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.847905,42.436491],[-72.834911,42.428014],[-72.757715,42.44596],[-72.763746,42.46361],[-72.855644,42.48098],[-72.847905,42.436491]]]},"properties":{"id":"01032","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.52791,42.301437],[-72.546356,42.216489],[-72.541264,42.216987],[-72.52429,42.218734],[-72.447145,42.22734],[-72.464186,42.308011],[-72.52791,42.301437]]]},"properties":{"id":"01033","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.647673,42.374339],[-72.636654,42.361519],[-72.626203,42.411176],[-72.669916,42.400274],[-72.672126,42.371353],[-72.647673,42.374339]]]},"properties":{"id":"01088","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.243076,42.358875],[-71.260385,42.353633],[-71.25867,42.336923],[-71.250444,42.32927],[-71.235156,42.337572],[-71.243076,42.358875]]]},"properties":{"id":"02466","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.214768,42.332171],[-71.207763,42.297181],[-71.191155,42.283059],[-71.178636,42.294595],[-71.17788,42.339096],[-71.197066,42.343516],[-71.213466,42.337739],[-71.21708,42.337037],[-71.214768,42.332171]]]},"properties":{"id":"02459","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.396704,42.340883],[-71.377687,42.314669],[-71.328403,42.329135],[-71.334549,42.396107],[-71.366326,42.412715],[-71.396704,42.340883]]]},"properties":{"id":"01778","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.405401,42.610393],[-71.388359,42.549949],[-71.332543,42.560756],[-71.297028,42.613011],[-71.316907,42.613687],[-71.359945,42.626008],[-71.405401,42.610393]]]},"properties":{"id":"01824","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.255717,42.479747],[-71.192211,42.425455],[-71.190494,42.427224],[-71.182277,42.436279],[-71.189599,42.449795],[-71.204291,42.465871],[-71.24089,42.490033],[-71.255717,42.479747]]]},"properties":{"id":"02420","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.52991,42.519716],[-71.473528,42.500177],[-71.428536,42.528804],[-71.498006,42.576862],[-71.511459,42.569386],[-71.538949,42.543069],[-71.52991,42.519716]]]},"properties":{"id":"01460","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.269919,42.328096],[-71.25867,42.336923],[-71.260385,42.353633],[-71.270644,42.369571],[-71.282825,42.399728],[-71.334549,42.396107],[-71.328403,42.329135],[-71.327327,42.313746],[-71.317239,42.316125],[-71.269919,42.328096]]]},"properties":{"id":"02493","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.572041,42.548987],[-71.61091,42.554966],[-71.635812,42.524091],[-71.634117,42.521824],[-71.606651,42.517115],[-71.572041,42.548987]]]},"properties":{"id":"01434","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.037608,41.239714],[-70.007021,41.25603],[-70.011187,41.259444],[-70.005933,41.260509],[-70.003748,41.258805],[-69.973881,41.302304],[-70.037608,41.239714]]]},"properties":{"id":"02554","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.034168,42.226661],[-71.049548,42.206768],[-71.021656,42.168987],[-70.976565,42.168331],[-70.973665,42.186992],[-70.960491,42.235099],[-70.967991,42.237999],[-71.034168,42.226661]]]},"properties":{"id":"02184","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.40222,42.178798],[-71.478119,42.156782],[-71.478521,42.131421],[-71.441346,42.134892],[-71.380248,42.132966],[-71.375618,42.135454],[-71.40222,42.178798]]]},"properties":{"id":"02053","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.124697,42.35126],[-71.135155,42.346023],[-71.139977,42.34214],[-71.110637,42.34041],[-71.124697,42.35126]]]},"properties":{"id":"02446","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.285131,42.08241],[-71.289083,42.045041],[-71.288265,42.014462],[-71.178457,42.057362],[-71.238829,42.106408],[-71.255501,42.104078],[-71.280268,42.088492],[-71.285131,42.08241]]]},"properties":{"id":"02035","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.3594,42.089519],[-71.380248,42.132966],[-71.441346,42.134892],[-71.455904,42.034895],[-71.3594,42.089519]]]},"properties":{"id":"02038","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.643564,41.805678],[-70.677877,41.745896],[-70.644599,41.755152],[-70.630059,41.776762],[-70.643564,41.805678]]]},"properties":{"id":"02538","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.644599,41.755152],[-70.677877,41.745896],[-70.63939,41.728018],[-70.632706,41.737759],[-70.644599,41.755152]]]},"properties":{"id":"02558","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.639776,42.074898],[-70.749716,42.096977],[-70.769698,42.022098],[-70.686105,42.002696],[-70.612529,42.013552],[-70.604196,42.013627],[-70.639776,42.074898]]]},"properties":{"id":"02332","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.948963,42.148156],[-70.915787,42.1001],[-70.902387,42.085901],[-70.887599,42.087951],[-70.884311,42.153719],[-70.890291,42.171736],[-70.924877,42.15758],[-70.948963,42.148156]]]},"properties":{"id":"02370","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.920936,42.182178],[-70.924877,42.15758],[-70.890291,42.171736],[-70.838791,42.194807],[-70.827048,42.200771],[-70.844614,42.260512],[-70.882584,42.274674],[-70.931029,42.247121],[-70.92329,42.2286],[-70.920936,42.182178]]]},"properties":{"id":"02043","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.665912,41.841369],[-70.649214,41.852578],[-70.662537,41.855941],[-70.665912,41.841369]]]},"properties":{"id":"02366","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.807307,42.012871],[-70.860869,41.954998],[-70.829827,41.927699],[-70.758692,41.947507],[-70.799329,42.013613],[-70.807307,42.012871]]]},"properties":{"id":"02367","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.979585,42.010999],[-70.987163,42.046584],[-70.996674,42.045401],[-71.069498,42.049715],[-71.054718,41.985057],[-71.054184,41.982716],[-70.979585,42.010999]]]},"properties":{"id":"02379","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.09747,42.291121],[-71.073264,42.270317],[-71.048459,42.277573],[-71.071569,42.30243],[-71.095759,42.291973],[-71.09747,42.291121]]]},"properties":{"id":"02124","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.137103,42.301185],[-71.178636,42.294595],[-71.191155,42.283059],[-71.188167,42.280412],[-71.150386,42.25746],[-71.143956,42.280502],[-71.127925,42.300157],[-71.137103,42.301185]]]},"properties":{"id":"02132","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.058525,42.366952],[-71.059795,42.368767],[-71.064095,42.368997],[-71.070738,42.367995],[-71.077095,42.358697],[-71.075219,42.355507],[-71.0611,42.360864],[-71.05817,42.362272],[-71.057709,42.363543],[-71.058525,42.366952]]]},"properties":{"id":"02114","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.0611,42.360864],[-71.075219,42.355507],[-71.072511,42.355453],[-71.062257,42.356268],[-71.06048,42.355499],[-71.057191,42.356822],[-71.057805,42.358924],[-71.0611,42.360864]]]},"properties":{"id":"02108","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.150386,42.25746],[-71.131322,42.228256],[-71.130808,42.22788],[-71.1085,42.261061],[-71.111567,42.271515],[-71.110374,42.275065],[-71.115342,42.278039],[-71.143956,42.280502],[-71.150386,42.25746]]]},"properties":{"id":"02136","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.033203,42.414226],[-71.055295,42.387097],[-71.044298,42.383209],[-71.013395,42.397398],[-71.033203,42.414226]]]},"properties":{"id":"02150","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.828839,42.25487],[-71.876783,42.266694],[-71.875367,42.263983],[-71.856562,42.226683],[-71.819639,42.230317],[-71.828839,42.25487]]]},"properties":{"id":"01603","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.718362,42.410264],[-71.725979,42.390289],[-71.678766,42.389163],[-71.660574,42.41821],[-71.65674,42.430957],[-71.718362,42.410264]]]},"properties":{"id":"01510","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.855917,42.160633],[-71.905379,42.133519],[-71.909235,42.091261],[-71.881509,42.075893],[-71.830044,42.09273],[-71.797208,42.099209],[-71.829444,42.144928],[-71.831426,42.164353],[-71.855917,42.160633]]]},"properties":{"id":"01540","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.928022,42.602009],[-71.858612,42.629045],[-71.85841,42.63384],[-71.898716,42.711418],[-71.981575,42.7133],[-71.985017,42.64721],[-71.928022,42.602009]]]},"properties":{"id":"01430","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-71.497955,42.296889],[-71.493346,42.296964],[-71.493182,42.297282],[-71.497955,42.296889]]],[[[-71.486768,42.330075],[-71.585168,42.310977],[-71.580091,42.305891],[-71.549466,42.266124],[-71.522184,42.266444],[-71.499575,42.284992],[-71.497955,42.296889],[-71.492804,42.298008],[-71.486768,42.330075]]]]},"properties":{"id":"01772","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.563917,42.064056],[-71.563115,42.014273],[-71.498258,42.017198],[-71.498284,42.064802],[-71.563917,42.064056]]]},"properties":{"id":"01504","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.028913,42.567951],[-72.038307,42.563271],[-72.020669,42.559127],[-72.028913,42.567951]]]},"properties":{"id":"01438","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.79827,42.206757],[-71.831426,42.164353],[-71.829444,42.144928],[-71.723535,42.191484],[-71.727345,42.209431],[-71.732724,42.234651],[-71.775928,42.219287],[-71.79827,42.206757]]]},"properties":{"id":"01527","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.816887,42.380704],[-71.802042,42.331345],[-71.764096,42.328121],[-71.741919,42.38685],[-71.817778,42.403956],[-71.816887,42.380704]]]},"properties":{"id":"01583","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.224839,42.638981],[-72.13912,42.623923],[-72.116631,42.626607],[-72.111071,42.717172],[-72.282981,42.721549],[-72.224839,42.638981]]]},"properties":{"id":"01368","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.035114,42.403967],[-71.99378,42.319336],[-71.918768,42.347983],[-71.887761,42.39495],[-71.965368,42.446559],[-72.007388,42.421029],[-72.035114,42.403967]]]},"properties":{"id":"01543","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.539646,41.637212],[-70.527797,41.546653],[-70.448994,41.588161],[-70.450394,41.655947],[-70.458737,41.664041],[-70.496004,41.683591],[-70.515962,41.666769],[-70.539646,41.637212]]]},"properties":{"id":"02649","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.146494,41.667237],[-70.16071,41.650247],[-70.117592,41.655378],[-70.117657,41.655601],[-70.127271,41.685739],[-70.128703,41.690214],[-70.146494,41.667237]]]},"properties":{"id":"02639","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.146494,41.667237],[-70.170606,41.673348],[-70.191827,41.647495],[-70.16071,41.650247],[-70.146494,41.667237]]]},"properties":{"id":"02670","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.195328,42.517733],[-73.199669,42.50538],[-73.196194,42.502357],[-73.192492,42.517283],[-73.195328,42.517733]]]},"properties":{"id":"01224","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.087117,42.064403],[-71.162591,42.031544],[-71.158704,42.012624],[-71.100854,41.993151],[-71.054718,41.985057],[-71.069498,42.049715],[-71.071184,42.056468],[-71.072344,42.055725],[-71.083751,42.049617],[-71.087117,42.064403]]]},"properties":{"id":"02375","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.916702,41.669343],[-70.965927,41.668334],[-70.972785,41.660506],[-70.917808,41.654687],[-70.917182,41.663603],[-70.916702,41.669343]]]},"properties":{"id":"02746","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.94263,41.617432],[-70.929522,41.608543],[-70.925382,41.612804],[-70.906397,41.624441],[-70.917808,41.654687],[-70.972785,41.660506],[-70.94263,41.617432]]]},"properties":{"id":"02740","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.085795,41.54118],[-71.084779,41.521671],[-71.069434,41.520702],[-71.085795,41.54118]]]},"properties":{"id":"02791","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.108632,42.735281],[-71.058883,42.732463],[-71.042325,42.757098],[-71.081706,42.772366],[-71.130645,42.76085],[-71.115603,42.741191],[-71.108632,42.735281]]]},"properties":{"id":"01835","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.134529,42.676837],[-71.076133,42.604077],[-71.058772,42.608996],[-71.02231,42.641636],[-71.108632,42.735281],[-71.115603,42.741191],[-71.134134,42.721507],[-71.13728,42.706733],[-71.134529,42.676837]]]},"properties":{"id":"01845","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.91349,42.541494],[-70.949917,42.494776],[-70.935454,42.491179],[-70.933987,42.49081],[-70.898994,42.484683],[-70.833855,42.526759],[-70.856343,42.539344],[-70.908399,42.545558],[-70.91349,42.541494]]]},"properties":{"id":"01970","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.987314,42.588087],[-70.98732,42.584915],[-70.982262,42.583799],[-70.97993,42.585795],[-70.987314,42.588087]]]},"properties":{"id":"01937","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.964659,42.599647],[-70.976721,42.62088],[-71.02231,42.641636],[-71.058772,42.608996],[-71.035245,42.570378],[-71.034847,42.5655],[-71.002653,42.5633],[-70.964659,42.599647]]]},"properties":{"id":"01949","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.933987,42.49081],[-70.922036,42.466919],[-70.87732,42.47762],[-70.898994,42.484683],[-70.933987,42.49081]]]},"properties":{"id":"01907","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.922263,42.591512],[-70.810093,42.584095],[-70.814493,42.593377],[-70.918925,42.622767],[-70.93109,42.612444],[-70.922263,42.591512]]]},"properties":{"id":"01984","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.440929,42.510411],[-72.459641,42.516427],[-72.520928,42.510223],[-72.530968,42.428853],[-72.456705,42.437947],[-72.440929,42.510411]]]},"properties":{"id":"01054","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.815335,42.639589],[-72.800834,42.596652],[-72.803604,42.564564],[-72.741113,42.534852],[-72.675452,42.553018],[-72.636821,42.577985],[-72.632942,42.645363],[-72.772737,42.658305],[-72.815335,42.639589]]]},"properties":{"id":"01370","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.87218,42.216439],[-72.895501,42.123885],[-72.853897,42.115006],[-72.813156,42.15552],[-72.846757,42.201135],[-72.87218,42.216439]]]},"properties":{"id":"01071","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.49111,42.087175],[-72.471164,42.089719],[-72.472274,42.09556],[-72.495542,42.102736],[-72.49111,42.087175]]]},"properties":{"id":"01128","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.593361,42.135488],[-72.617176,42.111717],[-72.614158,42.108744],[-72.593361,42.135488]],[[-72.604712,42.119639],[-72.605405,42.121385],[-72.603499,42.120951],[-72.604712,42.119639]]]},"properties":{"id":"01107","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.192625,42.091545],[-72.198827,42.031026],[-72.135715,42.030246],[-72.135388,42.063146],[-72.134954,42.090665],[-72.192625,42.091545]]]},"properties":{"id":"01521","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.388949,42.152434],[-72.379483,42.103131],[-72.366991,42.032705],[-72.270675,42.03168],[-72.270095,42.092522],[-72.27434,42.143853],[-72.388949,42.152434]]]},"properties":{"id":"01057","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.471164,42.089719],[-72.49111,42.087175],[-72.534358,42.081835],[-72.536639,42.063736],[-72.531575,42.034006],[-72.458185,42.034063],[-72.469939,42.084764],[-72.471164,42.089719]]]},"properties":{"id":"01028","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.795124,42.129594],[-72.793149,42.131136],[-72.796603,42.131502],[-72.795124,42.129594]]]},"properties":{"id":"01086","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.346238,42.210743],[-72.34126,42.196846],[-72.331569,42.20623],[-72.333875,42.220719],[-72.335638,42.220156],[-72.346238,42.210743]]]},"properties":{"id":"01009","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.739717,42.336612],[-72.682863,42.370021],[-72.723716,42.364915],[-72.741217,42.36273],[-72.739717,42.336612]]]},"properties":{"id":"01053","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.204491,42.365808],[-71.197066,42.343516],[-71.17788,42.339096],[-71.170003,42.344252],[-71.167625,42.360073],[-71.204491,42.365808]]]},"properties":{"id":"02458","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.112116,42.473818],[-71.11976,42.500292],[-71.132516,42.531286],[-71.170063,42.515597],[-71.204291,42.465871],[-71.189599,42.449795],[-71.112116,42.473818]]]},"properties":{"id":"01801","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.316907,42.613687],[-71.297028,42.613011],[-71.296271,42.612675],[-71.275154,42.656216],[-71.286011,42.649559],[-71.312395,42.652375],[-71.316782,42.64071],[-71.316907,42.613687]]]},"properties":{"id":"01852","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.322191,42.661809],[-71.368882,42.661753],[-71.38092,42.649008],[-71.364345,42.636698],[-71.316782,42.64071],[-71.312395,42.652375],[-71.322191,42.661809]]]},"properties":{"id":"01854","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.312395,42.652375],[-71.286011,42.649559],[-71.322191,42.661809],[-71.312395,42.652375]]]},"properties":{"id":"01850","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.359945,42.626008],[-71.316907,42.613687],[-71.316782,42.64071],[-71.364345,42.636698],[-71.359945,42.626008]]]},"properties":{"id":"01851","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.075996,42.396396],[-71.110446,42.399318],[-71.104022,42.391463],[-71.080884,42.382141],[-71.073496,42.391796],[-71.075996,42.396396]]]},"properties":{"id":"02145","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.746266,42.7073],[-71.772518,42.707987],[-71.775742,42.644216],[-71.732257,42.631454],[-71.746266,42.7073]]]},"properties":{"id":"01474","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.156302,42.395852],[-71.154496,42.375172],[-71.143501,42.364969],[-71.123168,42.36898],[-71.117193,42.364229],[-71.105427,42.376332],[-71.112939,42.38285],[-71.156302,42.395852]]]},"properties":{"id":"02138","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.088207,42.362608],[-71.070738,42.367995],[-71.064095,42.368997],[-71.072968,42.373342],[-71.094749,42.374292],[-71.088207,42.362608]]]},"properties":{"id":"02141","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.100722,42.502258],[-71.075257,42.531122],[-71.070978,42.555889],[-71.136522,42.552556],[-71.132516,42.531286],[-71.11976,42.500292],[-71.100722,42.502258]]]},"properties":{"id":"01867","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.21719,42.366534],[-71.194177,42.386031],[-71.19446,42.386317],[-71.235767,42.376349],[-71.270644,42.369571],[-71.260385,42.353633],[-71.243076,42.358875],[-71.221062,42.36539],[-71.21719,42.366534]]]},"properties":{"id":"02453","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.105427,42.376332],[-71.117193,42.364229],[-71.110591,42.352585],[-71.091184,42.354233],[-71.088207,42.362608],[-71.094749,42.374292],[-71.105427,42.376332]]]},"properties":{"id":"02139","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.367479,42.698265],[-71.368882,42.661753],[-71.322191,42.661809],[-71.286011,42.649559],[-71.275154,42.656216],[-71.256103,42.656691],[-71.238103,42.669491],[-71.255185,42.73667],[-71.367479,42.698265]]]},"properties":{"id":"01826","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.231897,42.506367],[-71.306565,42.520562],[-71.317538,42.502425],[-71.298892,42.462738],[-71.285507,42.449159],[-71.270122,42.463684],[-71.255717,42.479747],[-71.24089,42.490033],[-71.231897,42.506367]]]},"properties":{"id":"01730","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.498006,42.576862],[-71.493541,42.617418],[-71.496484,42.649802],[-71.537136,42.663495],[-71.657916,42.650023],[-71.660464,42.635135],[-71.608972,42.581183],[-71.511459,42.569386],[-71.498006,42.576862]]]},"properties":{"id":"01450","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.190494,42.427224],[-71.1639,42.405707],[-71.152356,42.400791],[-71.134514,42.404172],[-71.1324,42.414573],[-71.149798,42.435795],[-71.182277,42.436279],[-71.190494,42.427224]]]},"properties":{"id":"02474","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.67898,42.530351],[-71.635812,42.524091],[-71.61091,42.554966],[-71.608972,42.581183],[-71.660464,42.635135],[-71.664614,42.611589],[-71.67898,42.530351]]]},"properties":{"id":"01464","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.366326,42.412715],[-71.334549,42.396107],[-71.282825,42.399728],[-71.26723,42.424438],[-71.271039,42.451044],[-71.285507,42.449159],[-71.298892,42.462738],[-71.366326,42.412715]]]},"properties":{"id":"01773","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.464285,42.450273],[-71.469452,42.402631],[-71.430389,42.436937],[-71.464285,42.450273]]]},"properties":{"id":"01754","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.003748,41.258805],[-70.005933,41.260509],[-70.011187,41.259444],[-70.007021,41.25603],[-70.003748,41.258805]]]},"properties":{"id":"02584","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.498284,42.064802],[-71.498258,42.017198],[-71.458104,42.017798],[-71.455904,42.034895],[-71.441346,42.134892],[-71.478521,42.131421],[-71.501491,42.116807],[-71.499575,42.103508],[-71.498284,42.064802]]]},"properties":{"id":"02019","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.066609,42.164042],[-71.056511,42.153815],[-71.027233,42.132949],[-71.021656,42.168987],[-71.049548,42.206768],[-71.082707,42.204269],[-71.10293,42.200681],[-71.066609,42.164042]]]},"properties":{"id":"02368","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.339638,42.149416],[-71.34417,42.200703],[-71.384756,42.192033],[-71.40222,42.178798],[-71.375618,42.135454],[-71.339638,42.149416]]]},"properties":{"id":"02054","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.017622,42.281243],[-71.037468,42.26348],[-71.03149,42.25911],[-70.996739,42.266583],[-71.017622,42.281243]]]},"properties":{"id":"02170","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.037468,42.26348],[-71.017622,42.281243],[-71.036666,42.291383],[-71.042794,42.276998],[-71.037468,42.26348]]]},"properties":{"id":"02171","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.3594,42.089519],[-71.285131,42.08241],[-71.280268,42.088492],[-71.28539,42.097661],[-71.303751,42.1495],[-71.339638,42.149416],[-71.375618,42.135454],[-71.380248,42.132966],[-71.3594,42.089519]]]},"properties":{"id":"02056","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.737288,41.800567],[-70.746439,41.738308],[-70.71951,41.720072],[-70.63939,41.728018],[-70.677877,41.745896],[-70.643564,41.805678],[-70.717655,41.801527],[-70.737288,41.800567]]]},"properties":{"id":"02571","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.979585,42.010999],[-70.899187,41.996371],[-70.896943,42.019149],[-70.908188,42.061727],[-70.975205,42.071203],[-70.987163,42.046584],[-70.979585,42.010999]]]},"properties":{"id":"02333","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.825802,42.264682],[-70.882584,42.274674],[-70.844614,42.260512],[-70.825802,42.264682]]]},"properties":{"id":"02045","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.749716,42.096977],[-70.639776,42.074898],[-70.70883,42.20373],[-70.752394,42.163937],[-70.776853,42.116976],[-70.749716,42.096977]],[[-70.685294,42.129901],[-70.694116,42.142582],[-70.691372,42.143323],[-70.685294,42.129901]]]},"properties":{"id":"02050","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.77026,41.825777],[-70.737288,41.800567],[-70.717655,41.801527],[-70.753527,41.933241],[-70.758692,41.947507],[-70.829827,41.927699],[-70.77026,41.825777]]]},"properties":{"id":"02330","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.064434,42.347186],[-71.059777,42.344896],[-71.055335,42.346692],[-71.056245,42.352871],[-71.06048,42.355499],[-71.062257,42.356268],[-71.064472,42.352696],[-71.064434,42.347186]]]},"properties":{"id":"02111","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.056245,42.352871],[-71.055335,42.346692],[-71.012428,42.342672],[-71.045566,42.359733],[-71.056245,42.352871]]]},"properties":{"id":"02210","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.981575,42.7133],[-72.111071,42.717172],[-72.116631,42.626607],[-72.046073,42.601835],[-71.985017,42.64721],[-71.981575,42.7133]]]},"properties":{"id":"01475","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.887761,42.39495],[-71.918768,42.347983],[-71.90486,42.320879],[-71.816887,42.380704],[-71.817778,42.403956],[-71.831745,42.431826],[-71.887761,42.39495]]]},"properties":{"id":"01522","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.563917,42.064056],[-71.498284,42.064802],[-71.499575,42.103508],[-71.571591,42.142367],[-71.592752,42.121744],[-71.592914,42.114104],[-71.591951,42.063695],[-71.563917,42.064056]]]},"properties":{"id":"01756","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.692366,42.154296],[-71.679962,42.103374],[-71.642933,42.100664],[-71.636517,42.113361],[-71.623688,42.105031],[-71.62335,42.105152],[-71.692366,42.154296]]]},"properties":{"id":"01588","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.805866,42.25998],[-71.799656,42.257385],[-71.795449,42.265916],[-71.799664,42.27135],[-71.805866,42.25998]]]},"properties":{"id":"01608","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.642709,42.17412],[-71.719785,42.177331],[-71.691314,42.16313],[-71.642168,42.165662],[-71.642709,42.17412]]]},"properties":{"id":"01560","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.090109,42.388234],[-72.096325,42.387688],[-72.097807,42.381957],[-72.090109,42.388234]]]},"properties":{"id":"01074","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.875367,42.263983],[-71.895938,42.219253],[-71.876218,42.214982],[-71.856562,42.226683],[-71.875367,42.263983]]]},"properties":{"id":"01611","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.939356,42.189238],[-71.915419,42.183213],[-71.895135,42.192425],[-71.876218,42.214982],[-71.895938,42.219253],[-71.941889,42.200262],[-71.939356,42.189238]]]},"properties":{"id":"01542","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.702236,42.533895],[-71.76295,42.563924],[-71.840179,42.511744],[-71.830019,42.492186],[-71.728385,42.475128],[-71.702236,42.533895]]]},"properties":{"id":"01453","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.741919,42.38685],[-71.764096,42.328121],[-71.764442,42.312911],[-71.687938,42.325516],[-71.676188,42.36485],[-71.678766,42.389163],[-71.725979,42.390289],[-71.741919,42.38685]]]},"properties":{"id":"01505","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.123551,42.363853],[-72.145108,42.378085],[-72.151983,42.347082],[-72.157728,42.343271],[-72.123551,42.363853]]]},"properties":{"id":"01094","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.989503,42.310155],[-72.041168,42.308697],[-72.030322,42.245206],[-72.019886,42.183762],[-71.939356,42.189238],[-71.941889,42.200262],[-71.960633,42.281421],[-71.989503,42.310155]]]},"properties":{"id":"01562","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.450394,41.655947],[-70.448994,41.588161],[-70.432721,41.605367],[-70.409938,41.629529],[-70.450394,41.655947]]]},"properties":{"id":"02635","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.002226,41.669809],[-70.000799,41.668813],[-69.975256,41.653044],[-70.002226,41.669809]]]},"properties":{"id":"02669","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.409938,41.629529],[-70.432721,41.605367],[-70.36415,41.623734],[-70.382566,41.661549],[-70.409938,41.629529]]]},"properties":{"id":"02655","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.450394,41.655947],[-70.409938,41.629529],[-70.382566,41.661549],[-70.373908,41.676566],[-70.412539,41.710452],[-70.41887,41.704092],[-70.458737,41.664041],[-70.450394,41.655947]]]},"properties":{"id":"02648","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.622631,41.702152],[-70.641431,41.672798],[-70.59625,41.675302],[-70.579743,41.720114],[-70.609308,41.714075],[-70.622631,41.702152]]]},"properties":{"id":"02559","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.54403,41.770063],[-70.543366,41.775995],[-70.547505,41.801944],[-70.630059,41.776762],[-70.644599,41.755152],[-70.632706,41.737759],[-70.624303,41.720901],[-70.609308,41.714075],[-70.579743,41.720114],[-70.54403,41.770063]]]},"properties":{"id":"02532","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.170421,41.739936],[-70.184773,41.712867],[-70.182367,41.705588],[-70.170606,41.673348],[-70.146494,41.667237],[-70.128703,41.690214],[-70.133847,41.706137],[-70.143715,41.738348],[-70.161276,41.742201],[-70.169809,41.741381],[-70.170421,41.739936]]]},"properties":{"id":"02660","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.526214,41.758131],[-70.520337,41.773464],[-70.543366,41.775995],[-70.54403,41.770063],[-70.526214,41.758131]]]},"properties":{"id":"02561","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.09826,41.679713],[-70.03717,41.672149],[-70.015664,41.700332],[-69.988518,41.724994],[-70.004722,41.733017],[-70.133847,41.706137],[-70.128703,41.690214],[-70.127271,41.685739],[-70.09826,41.679713]]]},"properties":{"id":"02645","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.324075,41.638863],[-70.333965,41.635243],[-70.32257,41.630033],[-70.306404,41.636314],[-70.324075,41.638863]]]},"properties":{"id":"02672","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.269725,42.115073],[-73.265492,42.091386],[-73.255269,42.103315],[-73.269725,42.115073]]]},"properties":{"id":"01244","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.063634,42.649877],[-72.951039,42.641006],[-72.951108,42.641426],[-72.951088,42.64155],[-72.98937,42.640542],[-72.986498,42.666428],[-72.949742,42.705103],[-73.022903,42.741133],[-73.163566,42.743983],[-73.169757,42.660173],[-73.063634,42.649877]]]},"properties":{"id":"01247","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.365346,42.288242],[-73.343965,42.283224],[-73.351647,42.307179],[-73.365346,42.288242]]]},"properties":{"id":"01260","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.180617,42.109607],[-73.172218,42.043341],[-73.053254,42.039861],[-73.070516,42.140123],[-73.08705,42.186945],[-73.087078,42.186982],[-73.146402,42.180439],[-73.184553,42.14006],[-73.180617,42.109607]]]},"properties":{"id":"01255","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.417593,42.132176],[-73.428768,42.121302],[-73.436744,42.050539],[-73.328337,42.047972],[-73.298618,42.064153],[-73.304139,42.106916],[-73.404684,42.13449],[-73.417593,42.132176]]]},"properties":{"id":"01257","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.055819,41.861417],[-71.108685,41.85407],[-71.11961,41.790968],[-71.114489,41.778801],[-71.03657,41.816525],[-71.007455,41.838485],[-71.055819,41.861417]]]},"properties":{"id":"02779","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.121103,41.695455],[-71.144152,41.699479],[-71.118971,41.680629],[-71.121103,41.695455]]]},"properties":{"id":"02723","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.158704,42.012624],[-71.259289,41.974216],[-71.208162,41.908852],[-71.193893,41.910765],[-71.125685,41.967084],[-71.100854,41.993151],[-71.158704,42.012624]]]},"properties":{"id":"02766","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.381301,41.984998],[-71.381467,41.9148],[-71.317105,41.953847],[-71.312105,41.97191],[-71.296414,41.968163],[-71.268739,41.9873],[-71.288263,42.014379],[-71.381301,41.984998]]]},"properties":{"id":"02760","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.087117,42.064403],[-71.083751,42.049617],[-71.072344,42.055725],[-71.087117,42.064403]]]},"properties":{"id":"02357","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.578969,41.480712],[-70.615118,41.41367],[-70.541501,41.422105],[-70.578969,41.480712]]]},"properties":{"id":"02557","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.182446,42.794248],[-71.130645,42.76085],[-71.081706,42.772366],[-71.113537,42.817154],[-71.182446,42.794248]]]},"properties":{"id":"01832","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.953075,42.456484],[-70.944707,42.458843],[-70.939371,42.456548],[-70.933347,42.455705],[-70.922036,42.466919],[-70.933987,42.49081],[-70.935454,42.491179],[-70.961307,42.466134],[-70.953075,42.456484]]]},"properties":{"id":"01902","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.74565,42.599576],[-70.800797,42.595324],[-70.814493,42.593377],[-70.810093,42.584095],[-70.794805,42.55739],[-70.713002,42.570694],[-70.74565,42.599576]]]},"properties":{"id":"01944","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.833855,42.526759],[-70.898994,42.484683],[-70.87732,42.47762],[-70.833855,42.526759]]]},"properties":{"id":"01945","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.917423,42.787495],[-70.940294,42.822548],[-70.969191,42.8225],[-70.999714,42.810691],[-71.020402,42.78423],[-70.967169,42.758326],[-70.925911,42.78128],[-70.917423,42.787495]]]},"properties":{"id":"01985","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.574705,42.579422],[-72.556767,42.506495],[-72.520928,42.510223],[-72.459641,42.516427],[-72.453626,42.549745],[-72.507995,42.560569],[-72.517548,42.552641],[-72.526808,42.559899],[-72.51469,42.569085],[-72.521276,42.587806],[-72.574705,42.579422]]]},"properties":{"id":"01351","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.552686,42.612195],[-72.578138,42.578488],[-72.574705,42.579422],[-72.521276,42.587806],[-72.526426,42.602334],[-72.552686,42.612195]]]},"properties":{"id":"01376","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.440929,42.510411],[-72.456705,42.437947],[-72.375022,42.420819],[-72.395269,42.505415],[-72.440929,42.510411]]]},"properties":{"id":"01072","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.496234,42.678541],[-72.491,42.604008],[-72.377131,42.639308],[-72.412015,42.725435],[-72.516774,42.728531],[-72.496234,42.678541]]]},"properties":{"id":"01360","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.675452,42.553018],[-72.741113,42.534852],[-72.763746,42.46361],[-72.757715,42.44596],[-72.700959,42.45291],[-72.674392,42.456245],[-72.663341,42.457635],[-72.649962,42.524536],[-72.675452,42.553018]]]},"properties":{"id":"01341","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.800834,42.596652],[-72.8485,42.559062],[-72.803604,42.564564],[-72.800834,42.596652]]]},"properties":{"id":"01338","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.556786,42.50648],[-72.581106,42.422504],[-72.54421,42.427081],[-72.530968,42.428853],[-72.520928,42.510223],[-72.556767,42.506495],[-72.556786,42.50648]]]},"properties":{"id":"01375","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.377131,42.639308],[-72.3647,42.609376],[-72.30483,42.722204],[-72.412015,42.725435],[-72.377131,42.639308]]]},"properties":{"id":"01378","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.834891,42.036712],[-72.714137,42.036895],[-72.705989,42.080797],[-72.837212,42.102218],[-72.834891,42.036712]]]},"properties":{"id":"01077","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.000415,42.369037],[-73.004083,42.388329],[-73.007761,42.393109],[-73.019714,42.38106],[-73.000415,42.369037]]],[[[-73.000415,42.369037],[-73.000067,42.312693],[-73.001846,42.25109],[-72.912302,42.239133],[-72.953931,42.343792],[-73.000415,42.369037]]]]},"properties":{"id":"01011","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.992643,42.449094],[-72.880814,42.43301],[-72.863175,42.434871],[-72.847905,42.436491],[-72.855644,42.48098],[-72.871157,42.484173],[-72.976412,42.508597],[-72.988153,42.466848],[-72.992643,42.449094]]]},"properties":{"id":"01026","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.976412,42.508597],[-72.871157,42.484173],[-72.876948,42.541213],[-72.975379,42.555933],[-72.978981,42.541631],[-72.976412,42.508597]]]},"properties":{"id":"01070","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.633964,42.351152],[-72.614701,42.290199],[-72.613138,42.286265],[-72.609202,42.285477],[-72.534981,42.304225],[-72.533396,42.384581],[-72.532253,42.395491],[-72.54421,42.427081],[-72.581106,42.422504],[-72.633964,42.351152]]]},"properties":{"id":"01035","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.077985,42.473975],[-71.100722,42.502258],[-71.11976,42.500292],[-71.112116,42.473818],[-71.119402,42.453445],[-71.085696,42.444695],[-71.082104,42.444017],[-71.077985,42.473975]]]},"properties":{"id":"02180","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.498546,42.463775],[-71.473528,42.500177],[-71.52991,42.519716],[-71.543297,42.466451],[-71.498546,42.463775]]]},"properties":{"id":"01719","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.405401,42.610393],[-71.359945,42.626008],[-71.364345,42.636698],[-71.38092,42.649008],[-71.417652,42.653821],[-71.405401,42.610393]]]},"properties":{"id":"01863","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.486768,42.330075],[-71.492804,42.298008],[-71.493182,42.297282],[-71.393325,42.298769],[-71.377687,42.314669],[-71.396704,42.340883],[-71.483298,42.35313],[-71.486768,42.330075]]]},"properties":{"id":"01701","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.073196,42.412996],[-71.085696,42.444695],[-71.119402,42.453445],[-71.149798,42.435795],[-71.1324,42.414573],[-71.110446,42.399318],[-71.075996,42.396396],[-71.073196,42.412996]]]},"properties":{"id":"02155","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.473528,42.500177],[-71.498546,42.463775],[-71.464285,42.450273],[-71.430389,42.436937],[-71.384899,42.50455],[-71.399586,42.533992],[-71.428536,42.528804],[-71.473528,42.500177]],[[-71.430964,42.519951],[-71.428709,42.522073],[-71.426538,42.520571],[-71.42911,42.519219],[-71.430964,42.519951]]]},"properties":{"id":"01720","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.070738,42.367995],[-71.088207,42.362608],[-71.091184,42.354233],[-71.087445,42.355417],[-71.077095,42.358697],[-71.070738,42.367995]]]},"properties":{"id":"02142","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.478119,42.156782],[-71.40222,42.178798],[-71.384756,42.192033],[-71.420075,42.238123],[-71.467161,42.230787],[-71.502626,42.191416],[-71.478119,42.156782]]]},"properties":{"id":"01746","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.085696,42.444695],[-71.073196,42.412996],[-71.028257,42.424148],[-71.025777,42.444753],[-71.025864,42.444743],[-71.082104,42.444017],[-71.085696,42.444695]]]},"properties":{"id":"02148","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.135441,42.599166],[-71.136522,42.552556],[-71.070978,42.555889],[-71.035245,42.570378],[-71.058772,42.608996],[-71.076133,42.604077],[-71.135441,42.599166]]]},"properties":{"id":"01864","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.420075,42.238123],[-71.384756,42.192033],[-71.34417,42.200703],[-71.338435,42.215232],[-71.332434,42.247729],[-71.390365,42.271916],[-71.419298,42.257281],[-71.420075,42.238123]]]},"properties":{"id":"01770","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.542521,42.702689],[-71.537136,42.663495],[-71.496484,42.649802],[-71.457702,42.700524],[-71.542521,42.702689]]]},"properties":{"id":"01827","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.238302,42.182831],[-71.219905,42.168422],[-71.194455,42.149916],[-71.178013,42.163961],[-71.154967,42.197044],[-71.238302,42.182831]]]},"properties":{"id":"02062","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.148478,42.336254],[-71.140135,42.302147],[-71.113437,42.331891],[-71.110894,42.335505],[-71.110637,42.34041],[-71.139977,42.34214],[-71.148478,42.336254]]]},"properties":{"id":"02445","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.285131,42.08241],[-71.3594,42.089519],[-71.455904,42.034895],[-71.458104,42.017798],[-71.381401,42.018798],[-71.289083,42.045041],[-71.285131,42.08241]]]},"properties":{"id":"02093","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.056511,42.153815],[-71.061249,42.103215],[-71.025534,42.117293],[-71.021859,42.118745],[-71.027233,42.132949],[-71.056511,42.153815]]]},"properties":{"id":"02322","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.827048,42.200771],[-70.783272,42.247415],[-70.825802,42.264682],[-70.844614,42.260512],[-70.827048,42.200771]]]},"properties":{"id":"02025","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.007455,41.838485],[-71.03657,41.816525],[-71.014044,41.780333],[-70.921782,41.791244],[-70.896053,41.794375],[-70.98733,41.905839],[-71.007455,41.838485]]]},"properties":{"id":"02347","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.750847,41.669591],[-70.799073,41.710109],[-70.864994,41.700186],[-70.858212,41.681167],[-70.843167,41.628422],[-70.750847,41.669591]]]},"properties":{"id":"02739","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.884311,42.153719],[-70.803502,42.111706],[-70.776853,42.116976],[-70.752394,42.163937],[-70.838791,42.194807],[-70.890291,42.171736],[-70.884311,42.153719]]]},"properties":{"id":"02061","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.803502,42.111706],[-70.884311,42.153719],[-70.887599,42.087951],[-70.842486,42.094801],[-70.803502,42.111706]]]},"properties":{"id":"02339","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.899187,41.996371],[-70.979585,42.010999],[-71.054184,41.982716],[-70.999705,41.929592],[-70.908473,41.966346],[-70.899187,41.996371]]]},"properties":{"id":"02324","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.064472,42.352696],[-71.072511,42.355453],[-71.075219,42.355507],[-71.077095,42.358697],[-71.087445,42.355417],[-71.083796,42.348367],[-71.07876,42.34788],[-71.081775,42.345386],[-71.08078,42.343744],[-71.064434,42.347186],[-71.064472,42.352696]]]},"properties":{"id":"02116","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-71.109317,42.274455],[-71.110374,42.275065],[-71.111567,42.271515],[-71.109317,42.274455]]],[[[-71.143956,42.280502],[-71.115342,42.278039],[-71.102694,42.284538],[-71.09747,42.291121],[-71.095759,42.291973],[-71.096402,42.293235],[-71.127925,42.300157],[-71.143956,42.280502]]]]},"properties":{"id":"02131","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.063553,42.330791],[-71.045414,42.323798],[-71.012428,42.342672],[-71.055335,42.346692],[-71.059777,42.344896],[-71.063553,42.330791]]]},"properties":{"id":"02127","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.080943,42.332931],[-71.088237,42.337549],[-71.110823,42.331479],[-71.098917,42.325025],[-71.080943,42.332931]]]},"properties":{"id":"02120","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.057805,42.358924],[-71.05817,42.362272],[-71.0611,42.360864],[-71.057805,42.358924]]]},"properties":{"id":"02203","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.758928,42.274149],[-71.795449,42.265916],[-71.799656,42.257385],[-71.791199,42.242971],[-71.775928,42.219287],[-71.732724,42.234651],[-71.73118,42.241421],[-71.758928,42.274149]]]},"properties":{"id":"01604","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.859062,42.296523],[-71.865221,42.292762],[-71.884101,42.281361],[-71.876783,42.266694],[-71.828839,42.25487],[-71.820675,42.261493],[-71.859062,42.296523]]]},"properties":{"id":"01602","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.775322,42.636663],[-71.76295,42.563924],[-71.702236,42.533895],[-71.67898,42.530351],[-71.664614,42.611589],[-71.732257,42.631454],[-71.775742,42.644216],[-71.775322,42.636663]]]},"properties":{"id":"01462","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.679962,42.103374],[-71.692366,42.154296],[-71.691314,42.16313],[-71.719785,42.177331],[-71.723535,42.191484],[-71.829444,42.144928],[-71.797208,42.099209],[-71.691092,42.098167],[-71.679962,42.103374]]]},"properties":{"id":"01590","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.211079,42.251262],[-72.144852,42.172102],[-72.111283,42.238539],[-72.134811,42.281146],[-72.210815,42.29446],[-72.211079,42.251262]]]},"properties":{"id":"01585","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.728385,42.475128],[-71.718362,42.410264],[-71.65674,42.430957],[-71.635335,42.476745],[-71.627944,42.493544],[-71.634117,42.521824],[-71.635812,42.524091],[-71.67898,42.530351],[-71.702236,42.533895],[-71.728385,42.475128]],[[-71.686589,42.441556],[-71.685609,42.442919],[-71.679393,42.444596],[-71.68445,42.439836],[-71.686589,42.441556]]]},"properties":{"id":"01523","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.561047,42.158286],[-71.501491,42.116807],[-71.478521,42.131421],[-71.478119,42.156782],[-71.502626,42.191416],[-71.555572,42.183374],[-71.561047,42.158286]]]},"properties":{"id":"01757","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.719785,42.177331],[-71.642709,42.17412],[-71.643699,42.222211],[-71.727345,42.209431],[-71.723535,42.191484],[-71.719785,42.177331]]]},"properties":{"id":"01519","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.324075,41.638863],[-70.306404,41.636314],[-70.297407,41.631793],[-70.272005,41.627211],[-70.281005,41.687383],[-70.328762,41.689131],[-70.335896,41.687666],[-70.324075,41.638863]]]},"properties":{"id":"02601","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.609308,41.714075],[-70.624303,41.720901],[-70.622631,41.702152],[-70.609308,41.714075]]]},"properties":{"id":"02553","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.547505,41.801944],[-70.543366,41.775995],[-70.520337,41.773464],[-70.494834,41.776657],[-70.536769,41.811316],[-70.547505,41.801944]]]},"properties":{"id":"02562","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.184773,41.712867],[-70.170421,41.739936],[-70.169809,41.741381],[-70.161276,41.742201],[-70.181037,41.752344],[-70.241608,41.726321],[-70.184773,41.712867]]]},"properties":{"id":"02638","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.26113,41.714036],[-70.279159,41.709907],[-70.280553,41.698004],[-70.26113,41.714036]]]},"properties":{"id":"02637","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-69.942602,41.764993],[-69.931096,41.809554],[-69.939322,41.810552],[-69.942602,41.764993]]]},"properties":{"id":"02643","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.269725,42.115073],[-73.255269,42.103315],[-73.265492,42.091386],[-73.180617,42.109607],[-73.184553,42.14006],[-73.256797,42.241672],[-73.243936,42.257125],[-73.289193,42.257506],[-73.362228,42.258431],[-73.395417,42.258524],[-73.440786,42.270373],[-73.476856,42.172241],[-73.428768,42.121302],[-73.417593,42.132176],[-73.404684,42.13449],[-73.304139,42.106916],[-73.269725,42.115073]]]},"properties":{"id":"01230","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.182199,42.485415],[-73.369761,42.462963],[-73.385635,42.419699],[-73.310852,42.39339],[-73.210249,42.398815],[-73.195589,42.413563],[-73.182199,42.485415]]]},"properties":{"id":"01201","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.410843,42.351738],[-73.440786,42.270373],[-73.395417,42.258524],[-73.365346,42.288242],[-73.351647,42.307179],[-73.314548,42.30375],[-73.315282,42.322093],[-73.312952,42.348495],[-73.330816,42.351959],[-73.410843,42.351738]]]},"properties":{"id":"01266","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.163566,42.743983],[-73.329034,42.573285],[-73.171579,42.628707],[-73.169757,42.660173],[-73.163566,42.743983]]]},"properties":{"id":"01267","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.10825,42.48668],[-73.107814,42.536487],[-73.165803,42.510932],[-73.182199,42.485415],[-73.195589,42.413563],[-73.159365,42.412521],[-73.10825,42.48668]]]},"properties":{"id":"01226","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.043917,41.885411],[-71.055819,41.861417],[-71.007455,41.838485],[-70.98733,41.905839],[-70.992864,41.906438],[-71.043917,41.885411]]]},"properties":{"id":"02718","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.033099,41.570937],[-70.979327,41.512331],[-70.929522,41.608543],[-70.94263,41.617432],[-71.033099,41.570937]]]},"properties":{"id":"02748","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.300719,41.903661],[-71.292456,41.77488],[-71.193537,41.816536],[-71.1976,41.8437],[-71.186788,41.879338],[-71.193893,41.910765],[-71.208162,41.908852],[-71.300719,41.903661]]]},"properties":{"id":"02769","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.65095,41.33897],[-70.715252,41.414631],[-70.804987,41.309321],[-70.65095,41.33897]]]},"properties":{"id":"02535","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.935531,41.407559],[-70.867694,41.422515],[-70.854546,41.45997],[-70.935531,41.407559]]]},"properties":{"id":"02713","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.053496,42.475895],[-71.025864,42.444743],[-71.025777,42.444753],[-70.968594,42.444396],[-71.00133,42.49179],[-71.011592,42.501685],[-71.036096,42.505195],[-71.053496,42.475895]]]},"properties":{"id":"01906","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.004869,42.863318],[-70.969191,42.8225],[-70.940294,42.822548],[-70.904198,42.83389],[-70.914538,42.886619],[-71.004869,42.863318]]]},"properties":{"id":"01913","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.269221,42.533409],[-72.35679,42.548702],[-72.395269,42.505415],[-72.375022,42.420819],[-72.355396,42.340678],[-72.355942,42.303401],[-72.314599,42.343526],[-72.25168,42.508375],[-72.244952,42.513552],[-72.269221,42.533409]]]},"properties":{"id":"01355","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.78603,42.735929],[-72.772737,42.658305],[-72.632942,42.645363],[-72.664891,42.698223],[-72.674577,42.732889],[-72.78603,42.735929]]]},"properties":{"id":"01340","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.700959,42.45291],[-72.723716,42.364915],[-72.682863,42.370021],[-72.672126,42.371353],[-72.669916,42.400274],[-72.669193,42.409658],[-72.674392,42.456245],[-72.700959,42.45291]]]},"properties":{"id":"01039","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.684836,42.162992],[-72.627342,42.162032],[-72.601258,42.19501],[-72.593433,42.211691],[-72.609202,42.285477],[-72.613138,42.286265],[-72.667733,42.225461],[-72.686861,42.18339],[-72.684836,42.162992]]]},"properties":{"id":"01040","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.388949,42.152434],[-72.27434,42.143853],[-72.263876,42.183885],[-72.239127,42.226812],[-72.221218,42.245252],[-72.333875,42.220719],[-72.331569,42.20623],[-72.322913,42.182012],[-72.34133,42.185228],[-72.34126,42.196846],[-72.346238,42.210743],[-72.358219,42.189802],[-72.39508,42.184061],[-72.388949,42.152434]]]},"properties":{"id":"01069","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.581786,42.115454],[-72.603771,42.103488],[-72.59199,42.096729],[-72.580941,42.114695],[-72.581786,42.115454]]]},"properties":{"id":"01103","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.034916,42.143682],[-73.071067,42.148201],[-73.070516,42.140123],[-73.053254,42.039861],[-72.834891,42.036712],[-72.837212,42.102218],[-72.853897,42.115006],[-72.895501,42.123885],[-73.034916,42.143682]]]},"properties":{"id":"01034","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.714137,42.036895],[-72.663288,42.034209],[-72.639402,42.098883],[-72.694861,42.105901],[-72.705989,42.080797],[-72.714137,42.036895]]]},"properties":{"id":"01030","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.604712,42.119639],[-72.603499,42.120951],[-72.605405,42.121385],[-72.604712,42.119639]]]},"properties":{"id":"01199","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.757715,42.44596],[-72.834911,42.428014],[-72.820239,42.35307],[-72.741217,42.36273],[-72.723716,42.364915],[-72.700959,42.45291],[-72.757715,42.44596]]]},"properties":{"id":"01096","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.63737,42.317771],[-72.637955,42.319869],[-72.640347,42.319857],[-72.639917,42.317255],[-72.63737,42.317771]]]},"properties":{"id":"01063","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.820239,42.35307],[-72.834911,42.428014],[-72.847905,42.436491],[-72.863175,42.434871],[-72.854462,42.343341],[-72.821125,42.338659],[-72.820239,42.35307]]]},"properties":{"id":"01012","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.112939,42.38285],[-71.105427,42.376332],[-71.094749,42.374292],[-71.072968,42.373342],[-71.080884,42.382141],[-71.104022,42.391463],[-71.118159,42.388025],[-71.112939,42.38285]]]},"properties":{"id":"02143","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.657916,42.650023],[-71.651911,42.705194],[-71.746266,42.7073],[-71.732257,42.631454],[-71.664614,42.611589],[-71.660464,42.635135],[-71.657916,42.650023]]]},"properties":{"id":"01469","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.194177,42.386031],[-71.21719,42.366534],[-71.204491,42.365808],[-71.167625,42.360073],[-71.143501,42.364969],[-71.154496,42.375172],[-71.194177,42.386031]]]},"properties":{"id":"02472","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.493541,42.617418],[-71.498006,42.576862],[-71.428536,42.528804],[-71.399586,42.533992],[-71.388359,42.549949],[-71.405401,42.610393],[-71.417652,42.653821],[-71.493541,42.617418]]]},"properties":{"id":"01886","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.205032,42.544473],[-71.170063,42.515597],[-71.132516,42.531286],[-71.136522,42.552556],[-71.135441,42.599166],[-71.165015,42.597959],[-71.211144,42.573305],[-71.205032,42.544473]]]},"properties":{"id":"01887","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.467161,42.230787],[-71.522184,42.266444],[-71.549466,42.266124],[-71.599318,42.225959],[-71.555572,42.183374],[-71.502626,42.191416],[-71.467161,42.230787]]]},"properties":{"id":"01748","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.585168,42.310977],[-71.486768,42.330075],[-71.483298,42.35313],[-71.475505,42.380632],[-71.603187,42.367329],[-71.624702,42.350465],[-71.585168,42.310977]]]},"properties":{"id":"01752","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.211144,42.573305],[-71.165015,42.597959],[-71.256103,42.656691],[-71.275154,42.656216],[-71.296271,42.612675],[-71.242829,42.587959],[-71.211144,42.573305]]]},"properties":{"id":"01876","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.366326,42.412715],[-71.430389,42.436937],[-71.469452,42.402631],[-71.472678,42.390025],[-71.475505,42.380632],[-71.483298,42.35313],[-71.396704,42.340883],[-71.366326,42.412715]]]},"properties":{"id":"01776","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.216976,42.306607],[-71.225777,42.317066],[-71.228195,42.316833],[-71.227689,42.315473],[-71.216976,42.306607]]]},"properties":{"id":"02464","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.973665,42.186992],[-70.944418,42.189954],[-70.948266,42.230516],[-70.960491,42.235099],[-70.973665,42.186992]]]},"properties":{"id":"02188","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.130808,42.22788],[-71.10293,42.200681],[-71.082707,42.204269],[-71.053246,42.229528],[-71.03149,42.25911],[-71.037468,42.26348],[-71.042794,42.276998],[-71.048459,42.277573],[-71.073264,42.270317],[-71.1085,42.261061],[-71.130808,42.22788]]]},"properties":{"id":"02186","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.110591,42.352585],[-71.124697,42.35126],[-71.110637,42.34041],[-71.110894,42.335505],[-71.091184,42.354233],[-71.110591,42.352585]]]},"properties":{"id":"02215","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.28539,42.097661],[-71.280268,42.088492],[-71.255501,42.104078],[-71.28539,42.097661]]]},"properties":{"id":"02071","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.981293,42.135502],[-70.976565,42.168331],[-71.021656,42.168987],[-71.027233,42.132949],[-71.021859,42.118745],[-71.002029,42.126506],[-70.981293,42.135502]]]},"properties":{"id":"02343","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.799329,42.013613],[-70.758692,41.947507],[-70.753527,41.933241],[-70.690432,41.985819],[-70.686105,42.002696],[-70.769698,42.022098],[-70.799329,42.013613]]]},"properties":{"id":"02364","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.072968,42.373342],[-71.064095,42.368997],[-71.059795,42.368767],[-71.049095,42.371197],[-71.046995,42.372597],[-71.044298,42.383209],[-71.055295,42.387097],[-71.073496,42.391796],[-71.080884,42.382141],[-71.072968,42.373342]]]},"properties":{"id":"02129","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.013395,42.397398],[-71.044298,42.383209],[-71.046995,42.372597],[-70.997994,42.378197],[-70.986053,42.388116],[-71.013395,42.397398]]]},"properties":{"id":"02128","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.071569,42.30243],[-71.048459,42.277573],[-71.042794,42.276998],[-71.036666,42.291383],[-71.041687,42.302151],[-71.069938,42.305513],[-71.071569,42.30243]]]},"properties":{"id":"02122","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.013395,42.397398],[-70.986053,42.388116],[-70.977946,42.393591],[-70.966096,42.443507],[-70.968594,42.444396],[-71.025777,42.444753],[-71.028257,42.424148],[-71.033203,42.414226],[-71.013395,42.397398]]]},"properties":{"id":"02151","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.836972,42.310021],[-71.799664,42.27135],[-71.795449,42.265916],[-71.758928,42.274149],[-71.765154,42.306432],[-71.828863,42.314981],[-71.836972,42.310021]]]},"properties":{"id":"01605","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.802042,42.331345],[-71.828863,42.314981],[-71.765154,42.306432],[-71.764442,42.312911],[-71.764096,42.328121],[-71.802042,42.331345]]]},"properties":{"id":"01606","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.90486,42.320879],[-71.865221,42.292762],[-71.859062,42.296523],[-71.836972,42.310021],[-71.828863,42.314981],[-71.802042,42.331345],[-71.816887,42.380704],[-71.90486,42.320879]]]},"properties":{"id":"01520","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.895135,42.192425],[-71.915419,42.183213],[-71.905379,42.133519],[-71.855917,42.160633],[-71.895135,42.192425]]]},"properties":{"id":"01537","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.603187,42.367329],[-71.604251,42.397703],[-71.660574,42.41821],[-71.678766,42.389163],[-71.676188,42.36485],[-71.624702,42.350465],[-71.603187,42.367329]]]},"properties":{"id":"01503","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.099625,42.484473],[-72.007388,42.421029],[-71.965368,42.446559],[-71.919835,42.491447],[-71.979721,42.532389],[-72.001396,42.546624],[-72.08192,42.4957],[-72.099625,42.484473]]]},"properties":{"id":"01452","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.993102,42.026928],[-71.883306,42.024309],[-71.881509,42.075893],[-71.909235,42.091261],[-71.992734,42.072623],[-71.993102,42.026928]]]},"properties":{"id":"01571","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.042319,42.583772],[-72.124219,42.604375],[-72.08192,42.4957],[-72.001396,42.546624],[-72.020669,42.559127],[-72.038307,42.563271],[-72.028913,42.567951],[-72.042319,42.583772]]]},"properties":{"id":"01468","state_code":"25"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.524582,44.639525],[-83.330779,44.598448],[-83.309728,44.591084],[-83.284555,44.825321],[-83.335927,44.758146],[-83.524582,44.639525]]]},"properties":{"id":"48740","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.526104,44.874051],[-83.620468,44.770313],[-83.525966,44.741924],[-83.404637,44.815076],[-83.358198,44.858593],[-83.526104,44.874051]]]},"properties":{"id":"48762","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.864516,46.617782],[-86.074429,46.55277],[-86.12682,46.345102],[-86.114232,46.318864],[-85.99024,46.249547],[-85.864471,46.324163],[-85.864791,46.475452],[-85.864516,46.617782]]]},"properties":{"id":"49883","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.057137,46.158936],[-87.054126,46.071999],[-87.052858,45.955947],[-87.012715,45.829752],[-86.578382,45.898829],[-86.572893,45.963756],[-86.837152,46.158592],[-86.882029,46.18094],[-87.057137,46.158936]],[[-86.66424,45.839355],[-86.695752,45.894782],[-86.65889,45.890385],[-86.66424,45.839355]]]},"properties":{"id":"49878","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.748979,46.728769],[-87.704115,46.711026],[-87.503797,46.459479],[-87.507459,46.410954],[-87.365621,46.347355],[-87.116355,46.410493],[-87.116474,46.501713],[-87.505163,46.694308],[-87.748979,46.728769]]]},"properties":{"id":"49855","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.244006,42.519341],[-86.183124,42.490816],[-85.991729,42.521162],[-86.015722,42.622994],[-86.134224,42.645459],[-86.174367,42.637234],[-86.228162,42.616895],[-86.244006,42.519341]]]},"properties":{"id":"49408","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.973474,42.440547],[-85.920699,42.419319],[-85.779201,42.411211],[-85.701781,42.546749],[-85.701958,42.556459],[-85.898735,42.63736],[-86.015722,42.622994],[-85.991729,42.521162],[-86.012439,42.466056],[-85.973474,42.440547]]]},"properties":{"id":"49010","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.805391,42.30836],[-85.76448,42.400966],[-85.779201,42.411211],[-85.920699,42.419319],[-85.946525,42.317303],[-85.805931,42.307945],[-85.805391,42.30836]]]},"properties":{"id":"49055","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.673189,42.739335],[-85.672135,42.724777],[-85.6628,42.724719],[-85.666228,42.753823],[-85.673393,42.753888],[-85.673189,42.739335]]]},"properties":{"id":"49335","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.358198,44.858593],[-83.315593,44.858605],[-83.417604,45.000012],[-83.546487,44.902809],[-83.526104,44.874051],[-83.358198,44.858593]]]},"properties":{"id":"49766","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.918116,45.117216],[-84.775311,45.181058],[-84.734303,45.201936],[-84.732687,45.289382],[-84.929009,45.27499],[-84.919693,45.248039],[-84.918116,45.117216]]]},"properties":{"id":"49713","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.153763,42.649295],[-85.191459,42.551851],[-85.151586,42.493676],[-85.033604,42.538285],[-85.07553,42.668605],[-85.153763,42.649295]]]},"properties":{"id":"49073","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.051811,43.488745],[-83.961848,43.447205],[-83.940359,43.437597],[-83.938798,43.442928],[-83.927442,43.454474],[-83.931162,43.461403],[-83.910696,43.465011],[-83.885557,43.522498],[-84.014327,43.523688],[-84.051811,43.488745]]]},"properties":{"id":"48604","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.817008,44.70656],[-85.856745,44.775959],[-85.89637,44.777179],[-85.950111,44.747295],[-85.937905,44.703639],[-85.817008,44.70656]]]},"properties":{"id":"49650","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.003257,44.579083],[-86.080564,44.515102],[-85.990625,44.45566],[-85.797374,44.512931],[-85.805829,44.547611],[-85.891979,44.605823],[-86.003257,44.579083]]]},"properties":{"id":"49683","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.594909,41.932975],[-86.513832,41.899309],[-86.518931,41.971883],[-86.574223,41.986153],[-86.594909,41.932975]]]},"properties":{"id":"49106","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.712582,41.828632],[-86.66018,41.759714],[-86.66845,41.799887],[-86.712582,41.828632]]]},"properties":{"id":"49117","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.648259,41.866328],[-86.667975,41.838819],[-86.66845,41.799887],[-86.66018,41.759714],[-86.572402,41.759546],[-86.52504,41.856227],[-86.536286,41.874835],[-86.544486,41.873651],[-86.54994,41.872415],[-86.628791,41.87212],[-86.648259,41.866328]]]},"properties":{"id":"49128","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.291072,41.924453],[-86.214263,41.927589],[-86.222851,41.981752],[-86.306328,41.964212],[-86.291072,41.924453]]]},"properties":{"id":"49102","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.383528,42.182822],[-86.383516,42.181659],[-86.380493,42.1816],[-86.381664,42.183878],[-86.383528,42.182822]]]},"properties":{"id":"49084","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.039286,41.759683],[-85.157166,41.98519],[-85.175827,41.985286],[-85.263474,41.924236],[-85.282852,41.897522],[-85.24998,41.759799],[-85.039286,41.759683]]]},"properties":{"id":"49028","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.932028,41.810993],[-84.874008,41.825598],[-84.917896,41.825629],[-84.932028,41.810993]]],[[[-84.782149,41.781335],[-84.631534,41.796654],[-84.748403,41.91243],[-84.777359,41.905163],[-84.864143,41.825552],[-84.782149,41.781335]]]]},"properties":{"id":"49274","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.263474,41.924236],[-85.29344,42.050548],[-85.30566,42.045354],[-85.375993,41.997295],[-85.40989,41.951395],[-85.389608,41.91903],[-85.282852,41.897522],[-85.263474,41.924236]]]},"properties":{"id":"49040","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.175827,41.985286],[-85.196266,42.072414],[-85.29344,42.050548],[-85.263474,41.924236],[-85.175827,41.985286]]]},"properties":{"id":"49089","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.64821,42.334627],[-84.790732,42.392558],[-84.829037,42.363404],[-84.847549,42.224849],[-84.740307,42.188309],[-84.640942,42.232585],[-84.64821,42.334627]]]},"properties":{"id":"49224","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.781601,45.11599],[-84.429273,45.112658],[-84.366702,45.172995],[-84.366827,45.198762],[-84.401584,45.198772],[-84.734303,45.201936],[-84.775311,45.181058],[-84.781601,45.11599]]]},"properties":{"id":"49795","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.681003,45.678991],[-84.975471,45.670782],[-84.975552,45.636575],[-84.66621,45.56359],[-84.681003,45.678991]]]},"properties":{"id":"49755","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.48264,46.244879],[-84.459512,46.224387],[-84.457733,46.243749],[-84.48264,46.244879]]]},"properties":{"id":"49788","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.058325,46.158163],[-85.018812,46.158212],[-85.112606,46.178651],[-85.058325,46.158163]]],[[[-84.538424,46.099237],[-84.459512,46.224387],[-84.48264,46.244879],[-84.533479,46.327528],[-84.551374,46.331986],[-84.592926,46.331863],[-84.910251,46.273963],[-85.008183,46.261674],[-84.925934,46.158413],[-84.689506,46.060271],[-84.686864,46.056882],[-84.538424,46.099237]]]]},"properties":{"id":"49780","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.165096,46.476912],[-85.014305,46.559312],[-85.237123,46.76637],[-85.239624,46.534426],[-85.165096,46.476912]]]},"properties":{"id":"49768","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.967927,44.162409],[-85.04028,44.030838],[-84.946457,43.964121],[-84.918085,43.929299],[-84.784663,43.92307],[-84.701471,43.966677],[-84.644933,44.160646],[-84.851705,44.161375],[-84.967927,44.162409]]]},"properties":{"id":"48625","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.692503,43.090213],[-84.720115,42.900358],[-84.720032,42.893374],[-84.503319,42.892696],[-84.415688,42.943137],[-84.451385,43.045227],[-84.513381,43.117757],[-84.598852,43.139322],[-84.680202,43.117968],[-84.679965,43.112397],[-84.692503,43.090213]]]},"properties":{"id":"48879","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.818665,42.972949],[-84.81416,43.071396],[-84.840171,43.0619],[-84.882768,43.014192],[-84.876828,42.915085],[-84.837437,42.915104],[-84.818665,42.972949]]]},"properties":{"id":"48873","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.415688,42.943137],[-84.300379,42.955814],[-84.323865,43.042023],[-84.451385,43.045227],[-84.415688,42.943137]]]},"properties":{"id":"48866","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.287212,43.212736],[-84.346239,43.201136],[-84.503571,43.117663],[-84.513381,43.117757],[-84.451385,43.045227],[-84.323865,43.042023],[-84.250315,43.078859],[-84.286342,43.179656],[-84.286498,43.192927],[-84.287212,43.212736]]]},"properties":{"id":"48831","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.876373,42.784958],[-84.876713,42.712123],[-84.749157,42.682928],[-84.701286,42.676264],[-84.701274,42.682828],[-84.668689,42.767214],[-84.66166,42.824218],[-84.681202,42.827778],[-84.837095,42.800593],[-84.875994,42.799573],[-84.876373,42.784958]]]},"properties":{"id":"48837","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.778603,43.132976],[-84.935698,43.14854],[-84.926049,43.105439],[-84.840171,43.0619],[-84.81416,43.071396],[-84.758927,43.11838],[-84.778603,43.132976]]]},"properties":{"id":"48845","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.74192,46.003517],[-87.690432,46.001306],[-87.675608,46.036364],[-87.741683,46.048842],[-87.74192,46.003517]]],[[[-87.841363,46.110329],[-87.971035,46.125655],[-87.899715,46.082921],[-87.816303,46.08886],[-87.841363,46.110329]]],[[[-87.951373,45.907502],[-87.950001,45.980084],[-88.009868,46.048389],[-88.117483,46.013597],[-88.116845,45.921627],[-88.126926,45.827721],[-88.016848,45.802357],[-87.952057,45.818745],[-87.944495,45.869048],[-87.951373,45.907502]]]]},"properties":{"id":"49801","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.804036,46.247434],[-87.970727,46.247082],[-88.117475,46.227028],[-88.117858,46.115302],[-87.971035,46.125655],[-87.841363,46.110329],[-87.804036,46.247434]]]},"properties":{"id":"49815","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.570807,42.758709],[-84.668689,42.767214],[-84.701274,42.682828],[-84.66101,42.683049],[-84.631641,42.683017],[-84.582161,42.721127],[-84.570807,42.758709]]]},"properties":{"id":"48917","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.876373,42.784958],[-84.993456,42.71222],[-84.956216,42.674304],[-84.876713,42.712123],[-84.876373,42.784958]]]},"properties":{"id":"48861","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.794573,42.978591],[-83.795111,42.972452],[-83.790295,42.972502],[-83.786547,42.978699],[-83.794573,42.978591]]]},"properties":{"id":"48554","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.691566,43.296145],[-83.8155,43.309625],[-83.872849,43.328904],[-83.943285,43.307865],[-83.868218,43.236655],[-83.814393,43.211718],[-83.695614,43.222217],[-83.684897,43.251076],[-83.691566,43.296145]]]},"properties":{"id":"48415","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.994286,42.959277],[-83.929825,42.949981],[-83.894525,43.010079],[-83.928322,43.044511],[-83.994286,42.959277]]]},"properties":{"id":"48449","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.767629,43.248635],[-84.680064,43.207826],[-84.619797,43.204568],[-84.392315,43.248603],[-84.36906,43.258148],[-84.369259,43.277586],[-84.429113,43.306486],[-84.528095,43.34972],[-84.547873,43.349842],[-84.737739,43.292139],[-84.767629,43.248635]]]},"properties":{"id":"48847","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.36859,43.205031],[-84.503571,43.117663],[-84.346239,43.201136],[-84.36859,43.205031]]]},"properties":{"id":"48807","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.409253,43.538594],[-84.48884,43.531609],[-84.528127,43.378992],[-84.528095,43.34972],[-84.429113,43.306486],[-84.409253,43.538594]]]},"properties":{"id":"48615","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.786124,43.350188],[-84.819614,43.466231],[-84.885779,43.466157],[-84.895349,43.319219],[-84.845272,43.321246],[-84.786124,43.350188]]]},"properties":{"id":"48877","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.299472,42.049821],[-84.315645,42.087929],[-84.41717,42.091177],[-84.410093,42.068896],[-84.39159,42.015185],[-84.377004,42.015215],[-84.299472,42.049821]]]},"properties":{"id":"49233","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.451434,42.123786],[-84.520235,42.179125],[-84.569286,42.184774],[-84.594062,42.129791],[-84.51669,42.072404],[-84.495378,42.048457],[-84.445986,42.072999],[-84.451434,42.123786]]]},"properties":{"id":"49246","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.391179,47.28597],[-88.367633,47.271866],[-88.320807,47.28621],[-88.389135,47.285957],[-88.391179,47.28597]]]},"properties":{"id":"49917","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.62865,47.230137],[-88.459045,47.147471],[-88.421167,47.188765],[-88.298992,47.286097],[-88.320807,47.28621],[-88.367633,47.271866],[-88.391179,47.28597],[-88.411274,47.281377],[-88.390698,47.281745],[-88.410137,47.266566],[-88.411734,47.280641],[-88.419269,47.308348],[-88.422081,47.311796],[-88.516013,47.287528],[-88.6305,47.233138],[-88.62865,47.230137]]]},"properties":{"id":"49913","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.679392,46.420139],[-88.67892,46.637423],[-88.727002,46.592974],[-88.781554,46.420081],[-88.679392,46.420139]]]},"properties":{"id":"49961","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.316198,43.699234],[-83.312345,43.75711],[-83.33798,43.785422],[-83.411207,43.810145],[-83.467403,43.728909],[-83.35399,43.669722],[-83.316198,43.699234]]]},"properties":{"id":"48759","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.06232,43.86487],[-83.103267,43.906891],[-83.222755,43.903451],[-83.200147,43.774248],[-83.120111,43.759742],[-83.06232,43.86487]]]},"properties":{"id":"48731","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.145852,43.981512],[-83.126381,44.002659],[-83.304912,43.941609],[-83.222755,43.903451],[-83.103267,43.906891],[-83.113753,43.921064],[-83.145852,43.981512]]]},"properties":{"id":"48725","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.486933,42.720072],[-84.471609,42.725738],[-84.485056,42.735599],[-84.498148,42.73362],[-84.486933,42.720072]]]},"properties":{"id":"48825","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.462103,42.719057],[-84.462407,42.678092],[-84.363617,42.670677],[-84.367269,42.734268],[-84.41777,42.737649],[-84.462103,42.719057]]]},"properties":{"id":"48864","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.884644,44.441695],[-83.873747,44.426353],[-83.843872,44.451628],[-83.862486,44.451417],[-83.884644,44.441695]]]},"properties":{"id":"48743","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.611655,46.072805],[-88.62717,46.072723],[-88.623307,46.058264],[-88.611655,46.072805]]]},"properties":{"id":"49915","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.955761,46.083027],[-88.943161,46.078],[-88.920848,46.10014],[-88.938751,46.106364],[-88.955761,46.083027]]]},"properties":{"id":"54554","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.895808,43.517817],[-84.588202,43.640791],[-84.671361,43.725898],[-84.857014,43.712577],[-85.021288,43.624833],[-84.946404,43.547202],[-84.905704,43.510426],[-84.905842,43.516984],[-84.905961,43.525175],[-84.895887,43.525196],[-84.895808,43.517817]]]},"properties":{"id":"48858","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.551325,42.37664],[-84.531816,42.363766],[-84.36479,42.363772],[-84.374518,42.412116],[-84.50444,42.422087],[-84.551325,42.37664]]]},"properties":{"id":"49277","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.338581,42.255257],[-84.476065,42.254888],[-84.423432,42.24742],[-84.338581,42.255257]]]},"properties":{"id":"49202","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.640731,42.24527],[-85.594734,42.245174],[-85.587562,42.245118],[-85.589566,42.284113],[-85.598208,42.288745],[-85.648361,42.256796],[-85.640731,42.24527]]]},"properties":{"id":"49008","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.594734,42.245174],[-85.600649,42.157402],[-85.58651,42.157414],[-85.521482,42.178635],[-85.530612,42.192118],[-85.530877,42.244931],[-85.587562,42.245118],[-85.594734,42.245174]]]},"properties":{"id":"49002","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.450617,42.332731],[-85.413533,42.245305],[-85.336839,42.28046],[-85.366651,42.30236],[-85.399713,42.335428],[-85.450617,42.332731]]]},"properties":{"id":"49053","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.734984,42.186446],[-85.805391,42.30836],[-85.805931,42.307945],[-85.82531,42.204029],[-85.764605,42.157254],[-85.734984,42.186446]]]},"properties":{"id":"49071","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.333396,44.681956],[-85.176002,44.611724],[-84.961001,44.641727],[-84.848946,44.728471],[-84.847309,44.858037],[-85.161758,44.805628],[-85.248379,44.763731],[-85.333428,44.685126],[-85.333396,44.681956]]]},"properties":{"id":"49646","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.735253,43.008032],[-85.672757,42.999138],[-85.662423,43.02137],[-85.660943,43.029046],[-85.709043,43.092085],[-85.778063,43.0959],[-85.788181,43.06043],[-85.806385,43.030845],[-85.735253,43.008032]]]},"properties":{"id":"49544","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.662423,43.02137],[-85.590703,42.96981],[-85.560926,42.964085],[-85.545502,43.0282],[-85.63144,43.061691],[-85.660943,43.029046],[-85.662423,43.02137]]]},"properties":{"id":"49525","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.67171,43.21299],[-85.672971,43.287034],[-85.680612,43.304654],[-85.790448,43.279557],[-85.805296,43.256575],[-85.799744,43.180274],[-85.790222,43.176112],[-85.67171,43.21299]]]},"properties":{"id":"49330","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.842898,44.056276],[-85.772037,43.944325],[-85.680389,43.959318],[-85.602413,43.988439],[-85.563144,44.058312],[-85.609672,44.164789],[-85.763567,44.164704],[-85.842898,44.056276]]]},"properties":{"id":"49656","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.039243,43.68105],[-85.891356,43.697605],[-85.761016,43.728105],[-85.681766,43.780893],[-85.737383,43.814969],[-85.74201,43.829464],[-86.037884,43.815611],[-86.038369,43.794162],[-86.039243,43.68105]]]},"properties":{"id":"49309","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.275636,44.07362],[-85.304052,44.16483],[-85.334777,44.165118],[-85.609672,44.164789],[-85.563144,44.058312],[-85.325561,44.073386],[-85.275636,44.07362]]]},"properties":{"id":"49688","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.337279,42.897366],[-83.378071,42.803422],[-83.333022,42.795141],[-83.217454,42.797945],[-83.221744,42.885545],[-83.337279,42.897366]]]},"properties":{"id":"48371","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.224107,43.361093],[-83.236402,43.279235],[-83.138461,43.26863],[-83.119027,43.28368],[-83.154068,43.34087],[-83.224107,43.361093]]]},"properties":{"id":"48727","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.990961,43.068591],[-83.015905,42.980761],[-82.987408,42.975163],[-82.868499,42.984018],[-82.870797,43.070252],[-82.990961,43.068591]]]},"properties":{"id":"48014","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.981478,41.825335],[-83.931611,41.718879],[-83.820554,41.721872],[-83.822641,41.758748],[-83.767417,41.820957],[-83.76824,41.850232],[-83.866989,41.906584],[-83.981478,41.825335]]]},"properties":{"id":"49228","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.025002,41.985079],[-84.131076,41.987055],[-84.212686,41.957437],[-84.226549,41.928319],[-84.120036,41.83231],[-84.060419,41.800138],[-83.991209,41.825037],[-83.905709,41.920519],[-83.945045,41.943309],[-84.025002,41.985079]]]},"properties":{"id":"49221","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.132203,42.082968],[-84.131952,42.059205],[-84.131076,41.987055],[-84.025002,41.985079],[-84.055461,42.057402],[-84.06648,42.072607],[-84.132203,42.082968]]]},"properties":{"id":"49287","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.055461,42.057402],[-83.85199,42.081057],[-83.914926,42.109316],[-84.06648,42.072607],[-84.055461,42.057402]]]},"properties":{"id":"49236","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.866989,41.906584],[-83.76824,41.850232],[-83.718038,41.902099],[-83.761433,41.955373],[-83.867377,41.921083],[-83.866989,41.906584]]]},"properties":{"id":"49238","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.724223,42.619052],[-83.670823,42.519617],[-83.654534,42.520217],[-83.612982,42.609145],[-83.679105,42.63542],[-83.724223,42.619052]]]},"properties":{"id":"48380","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.259618,46.071478],[-84.38313,46.071437],[-84.461492,45.969601],[-84.114287,45.978239],[-84.179326,45.984954],[-84.259618,46.071478]]]},"properties":{"id":"49719","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.903369,42.510299],[-82.917496,42.480437],[-82.868274,42.475532],[-82.871013,42.509486],[-82.903369,42.510299]]]},"properties":{"id":"48081","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.025995,42.713902],[-83.014635,42.669711],[-83.010499,42.626334],[-82.973317,42.627811],[-82.97508,42.671216],[-82.976908,42.715905],[-83.025995,42.713902]]]},"properties":{"id":"48315","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.014635,42.669711],[-83.093706,42.67361],[-83.091449,42.624208],[-83.012697,42.62621],[-83.010499,42.626334],[-83.014635,42.669711]]]},"properties":{"id":"48317","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.996804,42.537644],[-82.995525,42.492139],[-82.968063,42.499014],[-82.968991,42.523618],[-82.969635,42.538429],[-82.996804,42.537644]]]},"properties":{"id":"48088","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.861313,42.762456],[-82.859339,42.718133],[-82.753867,42.720265],[-82.729964,42.720843],[-82.731992,42.765333],[-82.861313,42.762456]]]},"properties":{"id":"48048","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.911105,42.450769],[-82.927495,42.425975],[-82.914935,42.407266],[-82.898713,42.382137],[-82.861652,42.46204],[-82.911105,42.450769]]]},"properties":{"id":"48236","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.376056,46.304358],[-87.325253,46.280843],[-87.341751,46.300535],[-87.376056,46.304358]]]},"properties":{"id":"49833","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.338281,43.847738],[-86.258367,43.817456],[-86.243502,43.817481],[-86.220799,44.019566],[-86.240718,44.044944],[-86.300262,44.063032],[-86.338281,43.847738]]]},"properties":{"id":"49454","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.429044,43.733005],[-86.258367,43.817456],[-86.338281,43.847738],[-86.441411,43.81946],[-86.492537,43.734145],[-86.429044,43.733005]]]},"properties":{"id":"49449","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.324224,43.619287],[-85.284086,43.554313],[-85.284079,43.539839],[-85.234761,43.525346],[-85.224684,43.539796],[-85.206004,43.679037],[-85.224525,43.722832],[-85.324224,43.619287]]]},"properties":{"id":"49332","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.610757,45.374716],[-87.646552,45.362249],[-87.651051,45.354171],[-87.710108,45.159013],[-87.462243,45.360777],[-87.610757,45.374716]]]},"properties":{"id":"49893","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.57351,45.781841],[-87.573058,45.718654],[-87.528787,45.779783],[-87.57351,45.781841]]]},"properties":{"id":"49886","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.64859,45.794523],[-87.694085,45.626446],[-87.634919,45.637134],[-87.573563,45.636913],[-87.573058,45.718654],[-87.57351,45.781841],[-87.64859,45.794523]]]},"properties":{"id":"49847","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.337092,44.339254],[-85.156996,44.51115],[-85.432388,44.512275],[-85.582359,44.469112],[-85.588031,44.460575],[-85.535001,44.381948],[-85.337092,44.339254]]]},"properties":{"id":"49663","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.556318,41.729962],[-83.526217,41.830872],[-83.532591,41.852439],[-83.624094,41.836388],[-83.667221,41.806219],[-83.644574,41.77776],[-83.605408,41.728811],[-83.556318,41.729962]],[[-83.583612,41.81561],[-83.571158,41.793792],[-83.588611,41.800594],[-83.583612,41.81561]]]},"properties":{"id":"48182","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.4129,41.82564],[-83.43475,41.825043],[-83.442813,41.800869],[-83.4129,41.82564]]]},"properties":{"id":"48157","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.682434,43.366281],[-85.662135,43.350123],[-85.562645,43.366707],[-85.422911,43.352283],[-85.322931,43.395328],[-85.348,43.468096],[-85.52225,43.467935],[-85.682434,43.366281]]]},"properties":{"id":"49329","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.134616,43.163174],[-84.935581,43.163019],[-84.935028,43.243381],[-84.96455,43.264465],[-85.073583,43.248174],[-85.192753,43.224044],[-85.134616,43.163174]]]},"properties":{"id":"48884","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.153357,44.85484],[-84.131107,44.76847],[-84.090153,44.75305],[-83.933675,44.77895],[-83.921404,44.856612],[-83.999893,44.893378],[-84.153357,44.85484]]]},"properties":{"id":"48619","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.276508,43.373047],[-86.203498,43.262981],[-86.027854,43.290652],[-86.020074,43.29344],[-86.021792,43.350357],[-86.206136,43.448556],[-86.238788,43.454395],[-86.276508,43.373047]]]},"properties":{"id":"49457","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.565888,42.699004],[-83.562062,42.610741],[-83.51309,42.612751],[-83.515104,42.700443],[-83.565888,42.699004]]]},"properties":{"id":"48383","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.377479,42.484508],[-83.43552,42.480553],[-83.43427,42.453484],[-83.414357,42.439523],[-83.375469,42.440557],[-83.377438,42.484074],[-83.377479,42.484508]]]},"properties":{"id":"48335","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.348697,42.606026],[-83.324925,42.613493],[-83.32116,42.618418],[-83.334081,42.625357],[-83.348697,42.606026]]]},"properties":{"id":"48320","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.670649,42.517259],[-83.579846,42.492205],[-83.581252,42.509351],[-83.654534,42.520217],[-83.670823,42.519617],[-83.670751,42.518642],[-83.670649,42.517259]]]},"properties":{"id":"48165","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.21619,42.766572],[-83.21524,42.738863],[-83.097629,42.763296],[-83.099432,42.80055],[-83.179698,42.79892],[-83.21676,42.789761],[-83.21619,42.766572]]]},"properties":{"id":"48363","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.20438,42.531905],[-83.203746,42.517463],[-83.203419,42.510189],[-83.172176,42.503545],[-83.12502,42.50433],[-83.126375,42.533805],[-83.126738,42.5338],[-83.166233,42.54032],[-83.185609,42.535651],[-83.20438,42.531905]]]},"properties":{"id":"48073","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.086882,42.534792],[-83.089366,42.57845],[-83.148333,42.577302],[-83.146994,42.548301],[-83.126738,42.5338],[-83.126375,42.533805],[-83.086882,42.534792]]]},"properties":{"id":"48083","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.258224,46.669568],[-89.365312,46.419275],[-89.365408,46.332963],[-89.080952,46.332706],[-89.053777,46.525684],[-89.160151,46.699259],[-89.258224,46.669568]]]},"properties":{"id":"49912","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.544856,44.334682],[-84.551624,44.247615],[-84.368631,44.247072],[-84.370419,44.492875],[-84.544856,44.334682]]]},"properties":{"id":"48656","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.25853,43.347752],[-84.369461,43.302343],[-84.369259,43.277586],[-84.36906,43.258148],[-84.209355,43.244704],[-84.028359,43.234178],[-83.992381,43.247203],[-83.991803,43.292219],[-84.051585,43.307493],[-84.162357,43.354838],[-84.25853,43.347752]]]},"properties":{"id":"48655","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.731992,42.765333],[-82.729964,42.720843],[-82.6106,42.724669],[-82.614924,42.813654],[-82.734166,42.809556],[-82.733267,42.790826],[-82.731992,42.765333]]]},"properties":{"id":"48064","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.771813,43.317314],[-82.757297,43.24921],[-82.74462,43.204323],[-82.644985,43.192957],[-82.545111,43.17421],[-82.575671,43.342609],[-82.771813,43.317314]]]},"properties":{"id":"48422","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.575671,43.342609],[-82.545111,43.17421],[-82.524975,43.168226],[-82.503163,43.168946],[-82.531411,43.344241],[-82.534512,43.344073],[-82.575671,43.342609]]]},"properties":{"id":"48450","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.757297,43.24921],[-82.863915,43.221638],[-82.877221,43.157212],[-82.74462,43.204323],[-82.757297,43.24921]]]},"properties":{"id":"48454","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.036568,43.590438],[-83.111973,43.544602],[-83.107892,43.457522],[-83.010079,43.452799],[-83.036568,43.590438]]]},"properties":{"id":"48426","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.114232,46.318864],[-86.196041,45.957766],[-85.865501,45.962286],[-85.865402,45.968253],[-85.864357,46.056895],[-85.99024,46.249547],[-86.114232,46.318864]]]},"properties":{"id":"49840","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.985627,42.280043],[-86.127831,42.257064],[-86.089775,42.156929],[-85.993102,42.185473],[-85.985627,42.280043]]]},"properties":{"id":"49064","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.202332,42.32256],[-86.317081,42.331449],[-86.368774,42.243067],[-86.262458,42.243526],[-86.223596,42.250698],[-86.205722,42.25774],[-86.202332,42.32256]]]},"properties":{"id":"49043","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.702918,42.380589],[-83.815217,42.380366],[-83.818245,42.349219],[-83.745411,42.302987],[-83.683662,42.254587],[-83.671194,42.259158],[-83.643776,42.266776],[-83.605304,42.330193],[-83.643339,42.365811],[-83.702918,42.380589]],[[-83.712037,42.295773],[-83.703361,42.287819],[-83.719178,42.287346],[-83.712037,42.295773]]]},"properties":{"id":"48105","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.017866,42.374041],[-82.980319,42.388536],[-82.958848,42.395448],[-82.96497,42.419202],[-83.013717,42.419681],[-83.028254,42.397669],[-83.017866,42.374041]]]},"properties":{"id":"48213","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.098054,42.326684],[-83.078526,42.312752],[-83.065338,42.319541],[-83.063293,42.322401],[-83.060461,42.325079],[-83.06595,42.332282],[-83.06748,42.338017],[-83.096796,42.331271],[-83.098054,42.326684]]]},"properties":{"id":"48216","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.171265,42.445545],[-83.169768,42.409535],[-83.122085,42.410279],[-83.133115,42.446395],[-83.171265,42.445545]]]},"properties":{"id":"48221","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.980319,42.388536],[-82.952915,42.354868],[-82.92866,42.358249],[-82.946644,42.387469],[-82.958848,42.395448],[-82.980319,42.388536]]]},"properties":{"id":"48215","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.218227,42.408342],[-83.216931,42.378803],[-83.167374,42.366009],[-83.167915,42.380504],[-83.169768,42.409535],[-83.218227,42.408342]]]},"properties":{"id":"48227","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.226514,42.31089],[-83.22481,42.328641],[-83.234981,42.32983],[-83.241632,42.328045],[-83.226514,42.31089]]],[[[-83.248913,42.327117],[-83.287102,42.326688],[-83.28638,42.312186],[-83.248913,42.327117]]]]},"properties":{"id":"48128","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.179593,42.185014],[-83.18139,42.221063],[-83.200474,42.223354],[-83.229843,42.227545],[-83.228123,42.184279],[-83.179593,42.185014]]]},"properties":{"id":"48195","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.18139,42.221063],[-83.179593,42.185014],[-83.152219,42.185115],[-83.143236,42.202618],[-83.147399,42.234939],[-83.155667,42.234225],[-83.18139,42.221063]]]},"properties":{"id":"48192","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.946644,42.387469],[-82.92866,42.358249],[-82.898713,42.382137],[-82.914935,42.407266],[-82.946644,42.387469]]]},"properties":{"id":"48230","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.545375,42.305817],[-83.428283,42.308981],[-83.430038,42.352465],[-83.546481,42.327576],[-83.545375,42.305817]]]},"properties":{"id":"48187","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.951991,42.436119],[-82.927495,42.425975],[-82.911105,42.450769],[-82.928579,42.45062],[-82.941283,42.45023],[-82.951991,42.436119]]]},"properties":{"id":"48225","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.153033,42.264401],[-83.117584,42.259172],[-83.103666,42.287922],[-83.13753,42.282791],[-83.153033,42.264401]]]},"properties":{"id":"48218","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.585992,44.573285],[-83.461766,44.497143],[-83.363021,44.53872],[-83.330779,44.598448],[-83.524582,44.639525],[-83.539107,44.64927],[-83.56459,44.641387],[-83.585992,44.573285]]]},"properties":{"id":"48745","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.539107,44.64927],[-83.525966,44.741924],[-83.620468,44.770313],[-83.750444,44.769917],[-83.747265,44.599733],[-83.56459,44.641387],[-83.539107,44.64927]]]},"properties":{"id":"48705","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.888201,44.682086],[-84.090153,44.75305],[-84.131107,44.76847],[-84.292134,44.676541],[-84.229167,44.550965],[-84.129248,44.565086],[-83.887461,44.631523],[-83.888201,44.682086]]]},"properties":{"id":"48647","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.12682,46.345102],[-86.074429,46.55277],[-86.365049,46.587457],[-86.500165,46.552519],[-86.545917,46.361662],[-86.514657,46.332496],[-86.12682,46.345102]]]},"properties":{"id":"49884","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.628956,44.956592],[-83.546487,44.902809],[-83.417604,45.000012],[-83.374653,45.207091],[-83.538472,45.238309],[-83.657648,45.187774],[-83.635279,45.074566],[-83.628956,44.956592]]]},"properties":{"id":"49707","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.528193,44.754102],[-85.333428,44.685126],[-85.248379,44.763731],[-85.374857,44.89506],[-85.438252,44.895735],[-85.528193,44.754102]]]},"properties":{"id":"49690","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.350241,45.12145],[-85.223641,45.115728],[-85.222705,45.205551],[-85.40511,45.194701],[-85.388786,45.12497],[-85.373309,45.129557],[-85.350241,45.12145]]]},"properties":{"id":"49729","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.951575,44.967058],[-84.848443,44.924679],[-84.781601,45.11599],[-84.775311,45.181058],[-84.918116,45.117216],[-84.94479,45.074166],[-84.969094,45.025403],[-84.98982,44.989128],[-84.951575,44.967058]]]},"properties":{"id":"49730","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.407718,44.946054],[-85.372932,44.925166],[-85.29946,44.881072],[-85.302353,44.946161],[-85.316274,45.035178],[-85.336496,45.062871],[-85.373309,45.129557],[-85.388786,45.12497],[-85.407718,44.946054]]]},"properties":{"id":"49648","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.753071,44.233605],[-83.80581,44.162071],[-83.746388,44.084381],[-83.626006,44.114367],[-83.642197,44.180705],[-83.753071,44.233605]]]},"properties":{"id":"48765","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.753045,44.248645],[-83.642197,44.180705],[-83.626006,44.114367],[-83.56434,44.114601],[-83.49997,44.26959],[-83.578748,44.389768],[-83.728409,44.313524],[-83.745325,44.313342],[-83.753045,44.248645]]]},"properties":{"id":"48763","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.67892,46.637423],[-88.679392,46.420139],[-88.616772,46.420131],[-88.514645,46.420404],[-88.470238,46.625351],[-88.533133,46.658979],[-88.678856,46.642407],[-88.67892,46.637423]]]},"properties":{"id":"49970","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.116218,46.668966],[-88.248963,46.761969],[-88.141339,46.928895],[-88.44447,46.85766],[-88.44439,46.851883],[-88.533133,46.658979],[-88.470238,46.625351],[-88.267483,46.572777],[-88.116423,46.620312],[-88.116218,46.668966]]]},"properties":{"id":"49946","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.248963,46.761969],[-88.116218,46.668966],[-87.991289,46.71453],[-88.044709,46.765353],[-88.248963,46.761969]]],[[[-88.116423,46.620312],[-88.267483,46.572777],[-88.365808,46.419959],[-88.23951,46.420082],[-88.116571,46.419951],[-88.048064,46.50642],[-88.116423,46.620312]]]]},"properties":{"id":"49861","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.029267,43.653274],[-83.929702,43.701269],[-84.087543,43.711551],[-84.029267,43.653274]]]},"properties":{"id":"48631","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.081632,44.778431],[-86.179258,44.725747],[-86.186527,44.69564],[-85.939503,44.688121],[-85.937905,44.703639],[-85.950111,44.747295],[-86.081632,44.778431]]]},"properties":{"id":"49640","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.443208,42.006413],[-86.529567,42.050734],[-86.574223,41.986153],[-86.518931,41.971883],[-86.465638,41.997646],[-86.443208,42.006413]]]},"properties":{"id":"49127","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.481394,42.127123],[-86.403578,42.060158],[-86.349322,42.072272],[-86.22294,42.071484],[-86.202994,42.09311],[-86.205288,42.107598],[-86.28163,42.144993],[-86.419576,42.186737],[-86.481394,42.127123]],[[-86.383528,42.182822],[-86.381664,42.183878],[-86.380493,42.1816],[-86.383516,42.181659],[-86.383528,42.182822]]]},"properties":{"id":"49022","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.628791,41.87212],[-86.54994,41.872415],[-86.553998,41.874271],[-86.548349,41.878156],[-86.544486,41.873651],[-86.536286,41.874835],[-86.513832,41.899309],[-86.594909,41.932975],[-86.6267,41.897676],[-86.628791,41.87212]]]},"properties":{"id":"49125","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.544486,41.873651],[-86.548349,41.878156],[-86.553998,41.874271],[-86.54994,41.872415],[-86.544486,41.873651]]]},"properties":{"id":"49119","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.158819,42.133135],[-85.196266,42.072414],[-85.175827,41.985286],[-85.157166,41.98519],[-85.012139,42.053436],[-85.079957,42.081143],[-85.158819,42.133135]]]},"properties":{"id":"49094","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.282852,41.897522],[-85.389608,41.91903],[-85.438904,41.919116],[-85.318233,41.759888],[-85.24998,41.759799],[-85.282852,41.897522]]]},"properties":{"id":"49030","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.9769,42.035528],[-84.917896,41.825629],[-84.874008,41.825598],[-84.864143,41.825552],[-84.777359,41.905163],[-84.826126,41.993505],[-84.837688,42.058354],[-84.884636,42.072468],[-84.9769,42.035528]]]},"properties":{"id":"49082","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.93431,42.130882],[-85.027133,42.191057],[-85.079957,42.081143],[-85.012139,42.053436],[-84.9769,42.035528],[-84.884636,42.072468],[-84.93431,42.130882]]]},"properties":{"id":"49092","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.826126,41.993505],[-84.728873,41.999967],[-84.681229,42.084321],[-84.710143,42.09937],[-84.837688,42.058354],[-84.826126,41.993505]]]},"properties":{"id":"49252","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.790732,42.392558],[-84.64821,42.334627],[-84.570938,42.378748],[-84.600403,42.421984],[-84.779162,42.441966],[-84.797497,42.412646],[-84.790732,42.392558]]]},"properties":{"id":"49284","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.822833,41.798833],[-85.896759,41.795353],[-85.897015,41.780674],[-85.90032,41.759539],[-85.819082,41.759148],[-85.822833,41.798833]]]},"properties":{"id":"49130","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.897015,41.780674],[-86.107725,41.854538],[-86.108465,41.760697],[-85.90032,41.759539],[-85.897015,41.780674]]]},"properties":{"id":"49112","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.411466,45.256876],[-84.40502,45.428874],[-84.472685,45.446172],[-84.542836,45.327825],[-84.411466,45.256876]]]},"properties":{"id":"49705","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.40502,45.428874],[-84.411466,45.256876],[-84.401584,45.198772],[-84.366827,45.198762],[-84.315435,45.198705],[-84.135291,45.198743],[-84.013038,45.198621],[-84.027067,45.219887],[-84.249057,45.494825],[-84.40502,45.428874]]]},"properties":{"id":"49765","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.711054,45.458247],[-84.678906,45.418903],[-84.696961,45.447803],[-84.711054,45.458247]]]},"properties":{"id":"49717","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.833212,45.532721],[-84.730346,45.471686],[-84.676491,45.536691],[-84.690492,45.549966],[-84.833212,45.532721]]]},"properties":{"id":"49716","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.592926,46.331863],[-84.551374,46.331986],[-84.551464,46.356793],[-84.592926,46.331863]]],[[[-84.48264,46.244879],[-84.457733,46.243749],[-84.404646,46.215679],[-84.426326,46.302675],[-84.533479,46.327528],[-84.48264,46.244879]]]]},"properties":{"id":"49752","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.363878,46.331156],[-84.333893,46.244605],[-84.281277,46.28873],[-84.22218,46.318707],[-84.363878,46.331156]]],[[[-84.363878,46.331156],[-84.426668,46.374443],[-84.551464,46.356793],[-84.551374,46.331986],[-84.533479,46.327528],[-84.426326,46.302675],[-84.363878,46.331156]]]]},"properties":{"id":"49724","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.281277,46.28873],[-84.097389,46.226247],[-84.119332,46.315173],[-84.22218,46.318707],[-84.281277,46.28873]]]},"properties":{"id":"49710","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.165096,46.476912],[-85.113507,46.274705],[-85.008183,46.261674],[-84.910251,46.273963],[-84.908045,46.478713],[-85.014305,46.559312],[-85.165096,46.476912]]]},"properties":{"id":"49728","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.551464,46.356793],[-84.426668,46.374443],[-84.475594,46.452864],[-84.908045,46.478713],[-84.910251,46.273963],[-84.592926,46.331863],[-84.551464,46.356793]]]},"properties":{"id":"49715","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.668689,42.767214],[-84.570807,42.758709],[-84.561619,42.740956],[-84.548878,42.739441],[-84.512666,42.769667],[-84.49387,42.789169],[-84.5231,42.813086],[-84.66166,42.824218],[-84.668689,42.767214]]]},"properties":{"id":"48906","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.078656,45.794138],[-87.083334,45.775482],[-87.056313,45.775316],[-87.078656,45.794138]]]},"properties":{"id":"49894","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.016848,45.802357],[-88.126926,45.827721],[-88.005855,45.792604],[-88.016848,45.802357]]]},"properties":{"id":"49802","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.696885,45.886697],[-87.64859,45.794523],[-87.57351,45.781841],[-87.528787,45.779783],[-87.448805,45.773974],[-87.367633,45.924798],[-87.617039,45.986006],[-87.693291,45.898829],[-87.696885,45.886697]]]},"properties":{"id":"49873","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.483948,42.669087],[-84.579438,42.721197],[-84.582161,42.721127],[-84.631641,42.683017],[-84.602949,42.640243],[-84.483948,42.669087]]]},"properties":{"id":"48911","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.775169,42.877122],[-83.752287,42.867245],[-83.689384,42.871263],[-83.562569,42.873619],[-83.525787,42.92608],[-83.539669,42.934594],[-83.526838,42.939866],[-83.519098,42.950825],[-83.574012,42.969353],[-83.632953,42.96604],[-83.679904,42.959797],[-83.726139,42.91473],[-83.775169,42.877122]]]},"properties":{"id":"48439","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.627385,43.105857],[-83.733718,43.089536],[-83.713177,43.036734],[-83.682842,43.034847],[-83.627921,43.101131],[-83.627385,43.105857]]]},"properties":{"id":"48505","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.79199,42.869836],[-83.875567,42.816258],[-83.910994,42.78105],[-83.842523,42.734188],[-83.752287,42.867245],[-83.775169,42.877122],[-83.79199,42.869836]]]},"properties":{"id":"48451","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.929079,43.132782],[-83.948465,43.087876],[-83.928322,43.044511],[-83.894525,43.010079],[-83.86121,43.015331],[-83.773675,43.039126],[-83.793641,43.104295],[-83.813726,43.125254],[-83.830238,43.133398],[-83.929079,43.132782]]]},"properties":{"id":"48433","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.431954,43.262519],[-83.470393,43.22309],[-83.460003,43.178715],[-83.350521,43.198737],[-83.431954,43.262519]]]},"properties":{"id":"48464","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.526838,42.939866],[-83.539669,42.934594],[-83.525787,42.92608],[-83.526838,42.939866]]]},"properties":{"id":"48411","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.933526,42.854282],[-83.909843,42.919912],[-83.929825,42.949981],[-83.994286,42.959277],[-84.1231,42.946009],[-84.123796,42.909384],[-84.009866,42.840165],[-83.933526,42.854282]],[[-84.022546,42.933551],[-84.033168,42.937189],[-84.022185,42.939327],[-84.022546,42.933551]]]},"properties":{"id":"48429","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.643713,42.989319],[-83.679904,42.959797],[-83.632953,42.96604],[-83.643713,42.989319]]]},"properties":{"id":"48529","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.595511,43.047928],[-83.634839,43.018733],[-83.634489,43.004944],[-83.634498,43.004168],[-83.575318,43.009017],[-83.595511,43.047928]]]},"properties":{"id":"48509","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.636968,43.004103],[-83.644076,43.003909],[-83.643713,42.989319],[-83.632953,42.96604],[-83.574012,42.969353],[-83.575318,43.009017],[-83.634498,43.004168],[-83.636968,43.004103]]]},"properties":{"id":"48519","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.723665,42.98245],[-83.724746,42.973503],[-83.718593,42.973636],[-83.723665,42.98245]]]},"properties":{"id":"48553","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.647052,43.466046],[-84.547873,43.349842],[-84.528127,43.378992],[-84.48884,43.531609],[-84.506497,43.553379],[-84.647052,43.466046]]]},"properties":{"id":"48880","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.51669,42.072404],[-84.681229,42.084321],[-84.728873,41.999967],[-84.709336,41.946283],[-84.554614,41.970979],[-84.535674,41.985612],[-84.495378,42.048457],[-84.51669,42.072404]]]},"properties":{"id":"49250","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.748403,41.91243],[-84.631534,41.796654],[-84.592495,41.752931],[-84.476706,41.909305],[-84.459297,41.92742],[-84.554614,41.970979],[-84.709336,41.946283],[-84.748403,41.91243]]]},"properties":{"id":"49242","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.515096,41.767529],[-84.476706,41.909305],[-84.592495,41.752931],[-84.53409,41.738576],[-84.515096,41.767529]]]},"properties":{"id":"49266","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.459045,47.147471],[-88.62865,47.230137],[-88.534564,47.119027],[-88.473458,47.077779],[-88.431948,47.158426],[-88.459045,47.147471]]]},"properties":{"id":"49930","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.200147,43.774248],[-83.312345,43.75711],[-83.316198,43.699234],[-83.166802,43.717235],[-83.12997,43.718465],[-83.120111,43.759742],[-83.200147,43.774248]]]},"properties":{"id":"48754","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.103267,43.906891],[-83.06232,43.86487],[-82.842731,43.871284],[-82.884913,43.928202],[-83.113753,43.921064],[-83.103267,43.906891]]]},"properties":{"id":"48432","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.313208,43.612458],[-83.166802,43.717235],[-83.316198,43.699234],[-83.35399,43.669722],[-83.313208,43.612458]]]},"properties":{"id":"48735","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.557106,42.725593],[-84.568257,42.719575],[-84.553878,42.721941],[-84.557106,42.725593]]]},"properties":{"id":"48921","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.602949,42.640243],[-84.598282,42.596812],[-84.483946,42.669369],[-84.483948,42.669087],[-84.602949,42.640243]]]},"properties":{"id":"48842","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.265908,42.873872],[-84.341472,42.801949],[-84.32148,42.775929],[-84.18367,42.735989],[-84.121834,42.765199],[-84.089115,42.777606],[-84.074844,42.806884],[-84.220944,42.86354],[-84.265908,42.873872]]]},"properties":{"id":"48872","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.26751,42.88605],[-85.204912,42.866372],[-85.114475,42.879411],[-85.168434,43.001941],[-85.267136,42.987265],[-85.26751,42.88605]]]},"properties":{"id":"48881","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.379266,43.118384],[-85.15418,43.128582],[-85.134616,43.163174],[-85.192753,43.224044],[-85.192081,43.272025],[-85.266161,43.307806],[-85.293639,43.308377],[-85.354576,43.198279],[-85.391964,43.191043],[-85.379266,43.118384]]]},"properties":{"id":"48838","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.728409,44.313524],[-83.74531,44.318713],[-83.745325,44.313342],[-83.728409,44.313524]]],[[[-83.753071,44.233605],[-83.642197,44.180705],[-83.753045,44.248645],[-83.753071,44.233605]]],[[[-83.728409,44.313524],[-83.578748,44.389768],[-83.582105,44.412345],[-83.665162,44.3876],[-83.703449,44.372429],[-83.728409,44.313524]]]]},"properties":{"id":"48748","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.924316,44.440953],[-83.964028,44.330815],[-83.883431,44.276398],[-83.74531,44.318713],[-83.728409,44.313524],[-83.703449,44.372429],[-83.764857,44.454386],[-83.862486,44.451417],[-83.843872,44.451628],[-83.873747,44.426353],[-83.884644,44.441695],[-83.924316,44.440953]]]},"properties":{"id":"48739","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.591522,46.246264],[-88.23951,46.420082],[-88.365808,46.419959],[-88.514645,46.420404],[-88.616772,46.420131],[-88.591522,46.246264]]]},"properties":{"id":"49903","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.905704,43.510426],[-84.946404,43.547202],[-85.224684,43.539796],[-85.234761,43.525346],[-85.154945,43.481064],[-85.114643,43.466355],[-84.965497,43.466135],[-84.905771,43.466184],[-84.905704,43.510426]]]},"properties":{"id":"49310","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.681229,42.084321],[-84.51669,42.072404],[-84.594062,42.129791],[-84.697549,42.137382],[-84.710143,42.09937],[-84.681229,42.084321]]]},"properties":{"id":"49241","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.505514,42.347255],[-85.571121,42.303249],[-85.571924,42.294786],[-85.530877,42.244931],[-85.530612,42.192118],[-85.432755,42.223824],[-85.413533,42.245305],[-85.450617,42.332731],[-85.505514,42.347255]],[[-85.54089,42.314407],[-85.541871,42.317274],[-85.539216,42.317977],[-85.536178,42.316656],[-85.54089,42.314407]]]},"properties":{"id":"49048","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.648025,42.330469],[-85.648361,42.256796],[-85.598208,42.288745],[-85.609329,42.307307],[-85.648025,42.330469]]]},"properties":{"id":"49006","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.353682,42.149895],[-85.521482,42.178635],[-85.58651,42.157414],[-85.604611,42.070244],[-85.508853,42.070845],[-85.405907,42.071328],[-85.353682,42.149895]]]},"properties":{"id":"49097","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.54089,42.314407],[-85.536178,42.316656],[-85.539216,42.317977],[-85.541871,42.317274],[-85.54089,42.314407]]]},"properties":{"id":"49074","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.8585,43.197786],[-85.917573,43.133333],[-85.867411,43.060127],[-85.788181,43.06043],[-85.778063,43.0959],[-85.790222,43.176112],[-85.799744,43.180274],[-85.8585,43.197786]]]},"properties":{"id":"49403","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.608156,42.912637],[-85.633098,42.919892],[-85.650988,42.912813],[-85.657909,42.8172],[-85.63586,42.831069],[-85.590299,42.854556],[-85.608156,42.912637]]]},"properties":{"id":"49508","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.545502,43.0282],[-85.560926,42.964085],[-85.463814,42.878322],[-85.467794,42.875127],[-85.429662,42.878518],[-85.411334,43.043412],[-85.47608,43.053281],[-85.545502,43.0282]]]},"properties":{"id":"49301","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.325561,44.073386],[-85.563144,44.058312],[-85.602413,43.988439],[-85.325661,43.971738],[-85.325561,44.073386]]]},"properties":{"id":"49655","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.236402,43.279235],[-83.224107,43.361093],[-83.284251,43.381687],[-83.304002,43.268036],[-83.300563,43.265377],[-83.236402,43.279235]]]},"properties":{"id":"48760","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.102891,42.888647],[-82.983647,42.893741],[-82.987408,42.975163],[-83.015905,42.980761],[-83.082592,42.976014],[-83.102891,42.888647]]]},"properties":{"id":"48003","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.234277,41.798624],[-84.338882,41.751027],[-84.360416,41.706589],[-84.095659,41.714252],[-84.168355,41.81449],[-84.234277,41.798624]]]},"properties":{"id":"49256","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.226549,41.928319],[-84.212686,41.957437],[-84.246936,42.013021],[-84.256779,42.019173],[-84.323854,41.942742],[-84.304383,41.928132],[-84.226549,41.928319]]]},"properties":{"id":"49253","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.042393,42.500118],[-83.987409,42.424024],[-83.885684,42.425761],[-83.824906,42.460328],[-83.854574,42.500039],[-84.042393,42.500118]],[[-83.852854,42.459733],[-83.85196,42.460614],[-83.851631,42.460381],[-83.852052,42.459558],[-83.852854,42.459733]]]},"properties":{"id":"48169","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.747594,42.452116],[-83.702918,42.380589],[-83.643339,42.365811],[-83.627238,42.376131],[-83.625738,42.415411],[-83.553107,42.456879],[-83.555194,42.492867],[-83.579846,42.492205],[-83.670649,42.517259],[-83.747594,42.452116]]]},"properties":{"id":"48178","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.864155,46.702662],[-85.864516,46.617782],[-85.864791,46.475452],[-85.635628,46.245159],[-85.472943,46.245578],[-85.237839,46.245421],[-85.239624,46.534426],[-85.237123,46.76637],[-85.864155,46.702662]]]},"properties":{"id":"49868","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.690989,46.01425],[-84.686864,46.056882],[-84.689506,46.060271],[-85.04278,46.018447],[-85.050245,46.007823],[-84.690989,46.01425]]]},"properties":{"id":"49781","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.73901,42.897541],[-82.865664,42.895246],[-82.863055,42.806666],[-82.733267,42.790826],[-82.734166,42.809556],[-82.73901,42.897541]]]},"properties":{"id":"48062","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.862343,42.591409],[-82.900826,42.587883],[-82.898552,42.617587],[-82.861294,42.609875],[-82.856131,42.630234],[-82.888912,42.628969],[-82.88913,42.628958],[-82.971044,42.570227],[-82.97086,42.566443],[-82.863782,42.570027],[-82.862343,42.591409]]]},"properties":{"id":"48036","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.971044,42.570227],[-83.04973,42.579793],[-83.047743,42.536141],[-83.03569,42.536492],[-82.996804,42.537644],[-82.969635,42.538429],[-82.970227,42.553063],[-82.97086,42.566443],[-82.971044,42.570227]]]},"properties":{"id":"48312","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.861294,42.609875],[-82.898552,42.617587],[-82.900826,42.587883],[-82.862343,42.591409],[-82.861294,42.609875]]]},"properties":{"id":"48043","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.893051,44.252976],[-86.062105,44.264318],[-86.018915,44.177233],[-85.763567,44.164704],[-85.760081,44.227849],[-85.893051,44.252976]]]},"properties":{"id":"49689","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.292933,44.217504],[-86.295365,44.213803],[-86.28589,44.211977],[-86.285642,44.216294],[-86.292933,44.217504]]]},"properties":{"id":"49634","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.970727,46.247082],[-87.804036,46.247434],[-87.659214,46.246915],[-87.615101,46.333617],[-87.704115,46.711026],[-87.748979,46.728769],[-87.865152,46.723199],[-87.904128,46.36794],[-87.970727,46.247082]]]},"properties":{"id":"49849","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.240718,44.044944],[-86.116633,44.060861],[-86.088958,44.069326],[-86.080718,44.105831],[-86.395396,44.112048],[-86.300262,44.063032],[-86.240718,44.044944]]]},"properties":{"id":"49411","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.154945,43.481064],[-85.164242,43.36469],[-85.124201,43.386618],[-85.114643,43.466355],[-85.154945,43.481064]]]},"properties":{"id":"48886","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.800425,43.481333],[-85.919547,43.3607],[-85.682434,43.366281],[-85.52225,43.467935],[-85.562457,43.51202],[-85.800425,43.481333]]]},"properties":{"id":"49337","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.588087,41.96754],[-83.335679,41.891953],[-83.303126,41.943094],[-83.387155,41.99605],[-83.454441,42.003754],[-83.575984,42.004357],[-83.588087,41.96754]]]},"properties":{"id":"48162","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.761433,41.955373],[-83.718038,41.902099],[-83.598645,41.925336],[-83.593996,41.939992],[-83.588087,41.96754],[-83.575984,42.004357],[-83.668838,42.010221],[-83.751692,41.995407],[-83.761433,41.955373]]]},"properties":{"id":"48131","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.583612,41.81561],[-83.588611,41.800594],[-83.571158,41.793792],[-83.583612,41.81561]]]},"properties":{"id":"48177","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.59979,42.128159],[-83.540979,42.057657],[-83.538854,42.064701],[-83.539357,42.083408],[-83.547026,42.174276],[-83.590743,42.136445],[-83.59979,42.128159]]]},"properties":{"id":"48191","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.562645,43.366707],[-85.437134,43.294297],[-85.412934,43.323454],[-85.422911,43.352283],[-85.562645,43.366707]]]},"properties":{"id":"49339","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.441632,43.375311],[-86.238788,43.454395],[-86.206136,43.448556],[-86.221953,43.469135],[-86.36875,43.522439],[-86.496448,43.542597],[-86.528476,43.557624],[-86.441632,43.375311]]]},"properties":{"id":"49437","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.950015,43.568554],[-86.059932,43.514019],[-86.012119,43.353189],[-85.919547,43.3607],[-85.800425,43.481333],[-85.950015,43.568554]]]},"properties":{"id":"49412","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.950015,43.568554],[-85.800425,43.481333],[-85.562457,43.51202],[-85.562512,43.555047],[-85.562303,43.623671],[-85.661115,43.698345],[-85.700546,43.702842],[-85.900392,43.640229],[-85.950015,43.568554]]]},"properties":{"id":"49349","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.212757,42.69376],[-83.209216,42.620995],[-83.150492,42.622542],[-83.154023,42.695249],[-83.212757,42.69376]]]},"properties":{"id":"48309","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.321753,42.551297],[-83.323466,42.529323],[-83.32091,42.52937],[-83.249213,42.53085],[-83.236091,42.559837],[-83.264316,42.55919],[-83.322019,42.558091],[-83.321753,42.551297]]]},"properties":{"id":"48301","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.437586,42.527102],[-83.43552,42.480553],[-83.377479,42.484508],[-83.379737,42.527851],[-83.437586,42.527102]]]},"properties":{"id":"48331","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.681797,42.678419],[-83.679105,42.63542],[-83.612982,42.609145],[-83.604264,42.609392],[-83.615441,42.69752],[-83.681797,42.678419]]]},"properties":{"id":"48357","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.615441,42.69752],[-83.604264,42.609392],[-83.562062,42.610741],[-83.565888,42.699004],[-83.575811,42.698754],[-83.615441,42.69752]]]},"properties":{"id":"48356","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.3301,42.732657],[-83.214589,42.723799],[-83.21524,42.738863],[-83.21619,42.766572],[-83.314031,42.765436],[-83.3301,42.732657]]]},"properties":{"id":"48360","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.386592,42.667711],[-83.375533,42.616894],[-83.348697,42.606026],[-83.334081,42.625357],[-83.329585,42.661803],[-83.327712,42.677215],[-83.386592,42.667711]]]},"properties":{"id":"48328","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.137417,43.497528],[-86.248457,43.556276],[-86.278507,43.556521],[-86.36875,43.522439],[-86.221953,43.469135],[-86.137417,43.497528]]]},"properties":{"id":"49452","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.5576,43.646496],[-86.39823,43.670299],[-86.429044,43.733005],[-86.492537,43.734145],[-86.5576,43.646496]]]},"properties":{"id":"49436","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.74146,46.505247],[-89.485292,46.505645],[-89.408942,46.652906],[-89.539836,46.633603],[-89.74026,46.592147],[-89.74146,46.505247]]]},"properties":{"id":"49910","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.130618,43.041452],[-86.270145,43.054683],[-86.237436,42.96263],[-86.026496,43.023906],[-86.079469,43.041122],[-86.130618,43.041452]]]},"properties":{"id":"49417","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.229204,42.85637],[-86.227724,42.772321],[-86.212245,42.772966],[-86.036746,42.783428],[-86.057049,42.914117],[-86.229204,42.85637]]]},"properties":{"id":"49424","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.286498,43.192927],[-84.286342,43.179656],[-84.162592,43.128768],[-84.133782,43.129501],[-84.286498,43.192927]]]},"properties":{"id":"48649","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.994904,43.392334],[-83.994996,43.436656],[-84.046927,43.437244],[-83.994904,43.392334]]]},"properties":{"id":"48638","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.705966,42.68631],[-82.624127,42.664958],[-82.607712,42.665457],[-82.6106,42.724669],[-82.729964,42.720843],[-82.705966,42.68631]]]},"properties":{"id":"48023","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.40989,41.951395],[-85.528219,41.96721],[-85.573972,41.911206],[-85.565166,41.843095],[-85.448595,41.920403],[-85.44858,41.919128],[-85.438904,41.919116],[-85.389608,41.91903],[-85.40989,41.951395]]]},"properties":{"id":"49032","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.983418,43.402624],[-82.854363,43.523494],[-82.877073,43.595463],[-82.996953,43.606238],[-83.036568,43.590438],[-83.010079,43.452799],[-82.983418,43.402624]]]},"properties":{"id":"48472","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.596891,43.603646],[-82.816586,43.597721],[-82.877073,43.595463],[-82.854363,43.523494],[-82.751977,43.452894],[-82.566203,43.500032],[-82.561566,43.500031],[-82.596891,43.603646]]]},"properties":{"id":"48427","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.077365,42.345639],[-86.06267,42.342868],[-86.062682,42.353766],[-86.072477,42.353793],[-86.077365,42.345639]]]},"properties":{"id":"49027","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.745411,42.302987],[-83.818245,42.349219],[-83.917207,42.282035],[-83.958936,42.224761],[-83.913459,42.184368],[-83.797284,42.213353],[-83.739241,42.244041],[-83.745411,42.302987]]]},"properties":{"id":"48103","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.745411,42.302987],[-83.739241,42.244041],[-83.68339,42.245486],[-83.683662,42.254587],[-83.745411,42.302987]]]},"properties":{"id":"48104","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.712037,42.295773],[-83.719178,42.287346],[-83.703361,42.287819],[-83.712037,42.295773]]]},"properties":{"id":"48109","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.005266,42.448816],[-83.013717,42.419681],[-82.96497,42.419202],[-82.951991,42.436119],[-82.941283,42.45023],[-82.971041,42.449665],[-83.005266,42.448816]]]},"properties":{"id":"48205","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.952915,42.354868],[-82.980319,42.388536],[-83.017866,42.374041],[-83.000416,42.345086],[-82.952915,42.354868]]]},"properties":{"id":"48214","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.169907,42.264577],[-83.184696,42.270641],[-83.200474,42.223354],[-83.18139,42.221063],[-83.155667,42.234225],[-83.160859,42.255192],[-83.169907,42.264577]]]},"properties":{"id":"48146","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.184696,42.270641],[-83.18364,42.295259],[-83.206197,42.298412],[-83.217724,42.277214],[-83.231911,42.269879],[-83.229843,42.227545],[-83.200474,42.223354],[-83.184696,42.270641]]]},"properties":{"id":"48101","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.179593,42.185014],[-83.228123,42.184279],[-83.264512,42.183526],[-83.26675,42.167309],[-83.162528,42.170743],[-83.152219,42.185115],[-83.179593,42.185014]]]},"properties":{"id":"48193","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.218713,42.408328],[-83.276681,42.407278],[-83.255852,42.378916],[-83.216931,42.378803],[-83.218227,42.408342],[-83.218713,42.408328]]]},"properties":{"id":"48223","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.167074,42.289571],[-83.169907,42.264577],[-83.160859,42.255192],[-83.153033,42.264401],[-83.13753,42.282791],[-83.144473,42.293584],[-83.151079,42.296233],[-83.167074,42.289571]]]},"properties":{"id":"48217","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.308999,42.26823],[-83.264512,42.183526],[-83.228123,42.184279],[-83.229843,42.227545],[-83.231911,42.269879],[-83.308999,42.26823]]]},"properties":{"id":"48180","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.374081,42.310166],[-83.31218,42.340636],[-83.312825,42.35518],[-83.430038,42.352465],[-83.428283,42.308981],[-83.374081,42.310166]]]},"properties":{"id":"48185","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.086601,42.36539],[-83.109068,42.35747],[-83.111649,42.355732],[-83.096796,42.331271],[-83.06748,42.338017],[-83.076514,42.358757],[-83.086601,42.36539]]]},"properties":{"id":"48208","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.663952,44.343171],[-85.618882,44.251284],[-85.580307,44.348702],[-85.663952,44.343171]]]},"properties":{"id":"49618","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.376056,46.304358],[-87.341751,46.300535],[-87.325253,46.280843],[-87.353393,46.246197],[-87.116466,46.228481],[-87.06674,46.289337],[-87.071579,46.347504],[-87.116355,46.410493],[-87.365621,46.347355],[-87.376056,46.304358]]]},"properties":{"id":"49885","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.135018,42.681583],[-85.920837,42.760334],[-86.036746,42.783428],[-86.212245,42.772966],[-86.209217,42.767536],[-86.211468,42.6814],[-86.135018,42.681583]]]},"properties":{"id":"49423","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.183124,42.490816],[-86.244006,42.519341],[-86.317081,42.331449],[-86.202332,42.32256],[-86.129281,42.375486],[-86.143615,42.458789],[-86.183124,42.490816]]]},"properties":{"id":"49090","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.129281,42.375486],[-86.013541,42.325523],[-85.973474,42.440547],[-86.012439,42.466056],[-86.143615,42.458789],[-86.129281,42.375486]],[[-86.077365,42.345639],[-86.072477,42.353793],[-86.062682,42.353766],[-86.06267,42.342868],[-86.077365,42.345639]]]},"properties":{"id":"49056","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.819519,45.203479],[-83.657648,45.187774],[-83.538472,45.238309],[-83.683543,45.345799],[-83.739479,45.348151],[-83.819519,45.203479]]]},"properties":{"id":"49776","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.316274,45.035178],[-85.139165,45.033205],[-85.223641,45.115728],[-85.350241,45.12145],[-85.336496,45.062871],[-85.316274,45.035178]]]},"properties":{"id":"49622","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.883977,44.161786],[-83.958872,44.161893],[-83.903007,44.083959],[-83.745999,44.055461],[-83.746388,44.084381],[-83.80581,44.162071],[-83.883977,44.161786]]]},"properties":{"id":"48766","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.044709,46.765353],[-88.04453,46.91597],[-88.141339,46.928895],[-88.248963,46.761969],[-88.044709,46.765353]]]},"properties":{"id":"49962","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.253956,42.468629],[-85.219322,42.449667],[-85.151586,42.493676],[-85.191459,42.551851],[-85.32272,42.537011],[-85.253956,42.468629]]]},"properties":{"id":"49050","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.739487,43.579792],[-83.817228,43.522345],[-83.778067,43.478669],[-83.700497,43.565647],[-83.739487,43.579792]]]},"properties":{"id":"48747","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.857422,43.613364],[-83.739487,43.579792],[-83.700497,43.565647],[-83.699484,43.565823],[-83.699393,43.601152],[-83.69992,43.611782],[-83.848438,43.658576],[-83.857422,43.613364]]]},"properties":{"id":"48732","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.201154,44.028337],[-84.266012,43.941652],[-84.245947,43.912892],[-84.124906,43.869248],[-84.04488,43.914819],[-84.094486,43.984063],[-84.166339,44.00843],[-84.201154,44.028337]]]},"properties":{"id":"48613","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.288196,43.827635],[-84.358865,43.742393],[-84.336494,43.655413],[-84.181029,43.583335],[-84.139381,43.597525],[-84.107291,43.711508],[-84.167096,43.797285],[-84.288196,43.827635]]]},"properties":{"id":"48642","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.399589,42.016132],[-86.339542,41.976719],[-86.349322,42.072272],[-86.403578,42.060158],[-86.399589,42.016132]]]},"properties":{"id":"49126","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.712582,41.828632],[-86.66845,41.799887],[-86.667975,41.838819],[-86.69541,41.839481],[-86.712582,41.828632]]]},"properties":{"id":"49129","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.146347,41.888769],[-86.214263,41.927589],[-86.291072,41.924453],[-86.364864,41.899677],[-86.394719,41.759894],[-86.108465,41.760697],[-86.107725,41.854538],[-86.146347,41.888769]]]},"properties":{"id":"49120","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.060333,42.192526],[-85.148263,42.174093],[-85.172733,42.173947],[-85.177739,42.145282],[-85.158819,42.133135],[-85.079957,42.081143],[-85.027133,42.191057],[-85.060333,42.192526]]]},"properties":{"id":"49029","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.042573,42.28115],[-85.148263,42.174093],[-85.060333,42.192526],[-85.042573,42.28115]]]},"properties":{"id":"49033","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.579648,41.835228],[-85.799167,41.831054],[-85.822833,41.798833],[-85.819082,41.759148],[-85.554339,41.759344],[-85.579648,41.835228]]]},"properties":{"id":"49099","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.954046,42.012416],[-85.857449,42.069338],[-85.887435,42.164062],[-85.993102,42.185473],[-86.089775,42.156929],[-86.14685,42.090885],[-85.973556,42.012502],[-85.954046,42.012416]]]},"properties":{"id":"49045","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.573972,41.911206],[-85.760211,41.877224],[-85.799167,41.831054],[-85.579648,41.835228],[-85.565166,41.843095],[-85.573972,41.911206]]]},"properties":{"id":"49042","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.681003,45.678991],[-84.66621,45.56359],[-84.690492,45.549966],[-84.676491,45.536691],[-84.63553,45.498381],[-84.61515,45.48395],[-84.571553,45.498328],[-84.472685,45.446172],[-84.40502,45.428874],[-84.249057,45.494825],[-84.204029,45.630737],[-84.706223,45.781981],[-84.688354,45.729041],[-84.681003,45.678991]]]},"properties":{"id":"49721","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.691692,45.332143],[-84.542836,45.327825],[-84.472685,45.446172],[-84.571553,45.498328],[-84.595526,45.480461],[-84.61515,45.48395],[-84.63553,45.498381],[-84.666288,45.404518],[-84.691692,45.332143]]]},"properties":{"id":"49749","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.793949,45.756105],[-84.688354,45.729041],[-84.706223,45.781981],[-84.793949,45.756105]]]},"properties":{"id":"49701","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.97657,45.69305],[-84.975471,45.670782],[-84.681003,45.678991],[-84.688354,45.729041],[-84.793949,45.756105],[-84.97657,45.69305]]]},"properties":{"id":"49718","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.784663,43.92307],[-84.848651,43.741752],[-84.671361,43.725898],[-84.606167,43.829728],[-84.606468,43.903163],[-84.701471,43.966677],[-84.784663,43.92307]]]},"properties":{"id":"48617","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.462103,42.719057],[-84.41777,42.737649],[-84.363997,42.813376],[-84.378266,42.813465],[-84.49387,42.789169],[-84.512666,42.769667],[-84.509806,42.726528],[-84.462103,42.719057]],[[-84.486933,42.720072],[-84.498148,42.73362],[-84.485056,42.735599],[-84.471609,42.725738],[-84.486933,42.720072]]]},"properties":{"id":"48823","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.463537,42.865534],[-84.5231,42.813086],[-84.49387,42.789169],[-84.378266,42.813465],[-84.463537,42.865534]]]},"properties":{"id":"48808","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.415688,42.943137],[-84.503319,42.892696],[-84.463537,42.865534],[-84.378266,42.813465],[-84.363997,42.813376],[-84.341472,42.801949],[-84.265908,42.873872],[-84.300379,42.955814],[-84.415688,42.943137]]]},"properties":{"id":"48848","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.463537,42.865534],[-84.503319,42.892696],[-84.720032,42.893374],[-84.729463,42.882922],[-84.681202,42.827778],[-84.66166,42.824218],[-84.5231,42.813086],[-84.463537,42.865534]]]},"properties":{"id":"48820","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.679965,43.112397],[-84.699688,43.102859],[-84.692503,43.090213],[-84.679965,43.112397]]]},"properties":{"id":"48853","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.542422,44.887812],[-84.847309,44.858037],[-84.848946,44.728471],[-84.542422,44.887812]]]},"properties":{"id":"49733","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.459321,45.864515],[-86.459877,45.752025],[-86.196041,45.957766],[-86.114232,46.318864],[-86.12682,46.345102],[-86.514657,46.332496],[-86.489502,46.051578],[-86.459321,45.864515]]]},"properties":{"id":"49854","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.74192,46.003517],[-87.829494,45.970931],[-87.867017,45.942238],[-87.696885,45.886697],[-87.693291,45.898829],[-87.690432,46.001306],[-87.74192,46.003517]]]},"properties":{"id":"49834","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.950001,45.980084],[-87.951373,45.907502],[-87.867017,45.942238],[-87.829494,45.970931],[-87.950001,45.980084]]],[[[-87.98597,45.774746],[-87.905728,45.759356],[-87.944495,45.869048],[-87.952057,45.818745],[-87.98597,45.774746]]]]},"properties":{"id":"49870","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.905728,45.759356],[-87.777494,45.593835],[-87.694085,45.626446],[-87.64859,45.794523],[-87.696885,45.886697],[-87.867017,45.942238],[-87.951373,45.907502],[-87.944495,45.869048],[-87.905728,45.759356]],[[-87.820746,45.777336],[-87.821046,45.785456],[-87.813055,45.787991],[-87.820746,45.777336]]]},"properties":{"id":"49892","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.69893,42.611075],[-84.779162,42.441966],[-84.600403,42.421984],[-84.544558,42.50944],[-84.598282,42.596812],[-84.69893,42.611075]]]},"properties":{"id":"48827","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.749157,42.682928],[-84.69893,42.611075],[-84.700577,42.646814],[-84.701286,42.676264],[-84.749157,42.682928]]]},"properties":{"id":"48876","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.863305,45.433284],[-84.873246,45.536307],[-84.975552,45.636575],[-84.975471,45.670782],[-84.97657,45.69305],[-84.927213,45.41374],[-84.872205,45.413484],[-84.863305,45.433284]]]},"properties":{"id":"49740","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.933526,42.854282],[-83.875567,42.816258],[-83.79199,42.869836],[-83.909843,42.919912],[-83.933526,42.854282]]]},"properties":{"id":"48436","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.028359,43.234178],[-83.954909,43.190506],[-83.929079,43.132782],[-83.830238,43.133398],[-83.814393,43.211718],[-83.868218,43.236655],[-83.992381,43.247203],[-84.028359,43.234178]]]},"properties":{"id":"48457","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.887721,46.765825],[-90.338033,46.616505],[-89.930326,46.299979],[-90.053042,46.419827],[-90.046174,46.549725],[-89.863867,46.65822],[-89.86348,46.711438],[-89.887721,46.765825]]]},"properties":{"id":"49938","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.990813,46.295154],[-89.080952,46.332706],[-89.365408,46.332963],[-89.522419,46.221783],[-89.424014,46.202418],[-89.395752,46.204412],[-89.398871,46.19754],[-88.990864,46.097381],[-88.990813,46.295154]]]},"properties":{"id":"49969","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.432388,44.512275],[-85.438057,44.642071],[-85.541209,44.638676],[-85.560156,44.611132],[-85.57089,44.63037],[-85.593756,44.640147],[-85.638321,44.642913],[-85.671014,44.592542],[-85.582359,44.469112],[-85.432388,44.512275]]]},"properties":{"id":"49649","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.528193,44.754102],[-85.618275,44.773389],[-85.593756,44.640147],[-85.57089,44.63037],[-85.541209,44.638676],[-85.438057,44.642071],[-85.353362,44.641947],[-85.333396,44.681956],[-85.333428,44.685126],[-85.528193,44.754102]]]},"properties":{"id":"49686","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.767629,43.248635],[-84.737739,43.292139],[-84.766351,43.350111],[-84.786124,43.350188],[-84.845272,43.321246],[-84.835887,43.234717],[-84.797292,43.241678],[-84.767629,43.248635]]]},"properties":{"id":"48889","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.797292,43.241678],[-84.758718,43.147312],[-84.680064,43.207826],[-84.767629,43.248635],[-84.797292,43.241678]]]},"properties":{"id":"48856","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.42072,41.982325],[-84.396155,41.9453],[-84.381745,41.938631],[-84.323854,41.942742],[-84.256779,42.019173],[-84.299472,42.049821],[-84.377004,42.015215],[-84.42072,41.982325]]]},"properties":{"id":"49220","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.515096,41.767529],[-84.53409,41.738576],[-84.514609,41.703242],[-84.360416,41.706589],[-84.338882,41.751027],[-84.380436,41.767641],[-84.515096,41.767529]]]},"properties":{"id":"49288","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.554614,41.970979],[-84.459297,41.92742],[-84.396155,41.9453],[-84.42072,41.982325],[-84.535674,41.985612],[-84.554614,41.970979]]]},"properties":{"id":"49262","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.615743,47.068847],[-88.700056,47.06696],[-88.717883,47.03128],[-88.637004,47.033081],[-88.615872,47.03278],[-88.615743,47.068847]]]},"properties":{"id":"49963","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.591529,47.09106],[-88.577093,47.086339],[-88.576689,47.098132],[-88.591529,47.09106]]]},"properties":{"id":"49921","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.411274,47.281377],[-88.391179,47.28597],[-88.389135,47.285957],[-88.387415,47.290649],[-88.419269,47.308348],[-88.411734,47.280641],[-88.411274,47.281377]]],[[[-88.422081,47.311796],[-88.374392,47.318004],[-88.24079,47.431976],[-88.516013,47.287528],[-88.422081,47.311796]]]]},"properties":{"id":"49805","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.312345,43.75711],[-83.200147,43.774248],[-83.222755,43.903451],[-83.304912,43.941609],[-83.332365,43.887149],[-83.33798,43.785422],[-83.312345,43.75711]]]},"properties":{"id":"48755","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.852294,43.99372],[-82.884913,43.928202],[-82.842731,43.871284],[-82.841204,43.856951],[-82.639571,43.862742],[-82.827289,44.039184],[-82.852294,43.99372]]]},"properties":{"id":"48468","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.996953,43.606238],[-83.118656,43.704385],[-83.12997,43.718465],[-83.166802,43.717235],[-83.313208,43.612458],[-83.332443,43.590449],[-83.269094,43.497508],[-83.111973,43.544602],[-83.036568,43.590438],[-82.996953,43.606238]]]},"properties":{"id":"48726","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.839234,43.727199],[-82.817934,43.637957],[-82.619697,43.658967],[-82.608889,43.668078],[-82.639695,43.689666],[-82.839234,43.727199]]]},"properties":{"id":"48456","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.840025,43.756155],[-83.118656,43.704385],[-82.996953,43.606238],[-82.877073,43.595463],[-82.816586,43.597721],[-82.817934,43.637957],[-82.839234,43.727199],[-82.840025,43.756155]]]},"properties":{"id":"48475","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.346802,42.512697],[-84.5216,42.480018],[-84.50444,42.422087],[-84.374518,42.412116],[-84.327287,42.424711],[-84.297369,42.489857],[-84.346802,42.512697]]]},"properties":{"id":"49251","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.346802,42.512697],[-84.297369,42.489857],[-84.221348,42.552076],[-84.247447,42.600906],[-84.318465,42.600875],[-84.346802,42.512697]]]},"properties":{"id":"48819","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.18367,42.735989],[-84.32148,42.775929],[-84.367269,42.734268],[-84.363617,42.670677],[-84.318465,42.600875],[-84.247447,42.600906],[-84.18367,42.735989]]]},"properties":{"id":"48895","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.411334,43.043412],[-85.429662,42.878518],[-85.311388,42.856955],[-85.26751,42.88605],[-85.267136,42.987265],[-85.39213,43.072535],[-85.411334,43.043412]]]},"properties":{"id":"49331","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.882768,43.014192],[-84.946009,42.995702],[-84.995224,42.914784],[-84.876828,42.915085],[-84.882768,43.014192]]]},"properties":{"id":"48851","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.895808,43.517817],[-84.895887,43.525196],[-84.905961,43.525175],[-84.905842,43.516984],[-84.895808,43.517817]]]},"properties":{"id":"48896","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.299472,42.049821],[-84.256779,42.019173],[-84.246936,42.013021],[-84.131952,42.059205],[-84.132203,42.082968],[-84.153185,42.171668],[-84.220672,42.160495],[-84.220516,42.154691],[-84.309682,42.13161],[-84.315645,42.087929],[-84.299472,42.049821]],[[-84.190315,42.160527],[-84.186921,42.161703],[-84.182713,42.16122],[-84.185128,42.158531],[-84.190315,42.160527]]]},"properties":{"id":"49230","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.315058,42.250089],[-84.338806,42.236174],[-84.331614,42.218504],[-84.315058,42.250089]]]},"properties":{"id":"49254","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.190315,42.160527],[-84.185128,42.158531],[-84.182713,42.16122],[-84.186921,42.161703],[-84.190315,42.160527]]]},"properties":{"id":"49263","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.594734,42.245174],[-85.640731,42.24527],[-85.648099,42.194355],[-85.600649,42.157402],[-85.594734,42.245174]]]},"properties":{"id":"49024","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.39213,43.072535],[-85.379266,43.118384],[-85.391964,43.191043],[-85.591204,43.175438],[-85.6376,43.135491],[-85.613517,43.116906],[-85.47608,43.053281],[-85.411334,43.043412],[-85.39213,43.072535]]]},"properties":{"id":"49341","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.709043,43.092085],[-85.660943,43.029046],[-85.63144,43.061691],[-85.613517,43.116906],[-85.6376,43.135491],[-85.709043,43.092085]]]},"properties":{"id":"49321","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.804476,42.882169],[-85.793381,42.840695],[-85.722979,42.855359],[-85.735161,42.933483],[-85.782177,42.911323],[-85.804476,42.882169]]]},"properties":{"id":"49418","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.681595,43.826975],[-85.680389,43.959318],[-85.772037,43.944325],[-85.762063,43.925603],[-85.681595,43.826975]]]},"properties":{"id":"49623","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.240999,43.009517],[-83.163275,42.981781],[-83.133835,42.981831],[-83.133797,43.151303],[-83.215584,43.112206],[-83.240999,43.009517]]]},"properties":{"id":"48412","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.97315,43.119648],[-83.095604,43.166842],[-83.133797,43.151303],[-83.133835,42.981831],[-83.082592,42.976014],[-83.015905,42.980761],[-82.990961,43.068591],[-82.97315,43.119648]]]},"properties":{"id":"48444","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.951887,44.941805],[-85.947001,44.847007],[-85.89637,44.777179],[-85.856745,44.775959],[-85.814771,44.790025],[-85.872783,44.939835],[-85.951887,44.941805]]]},"properties":{"id":"49664","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.85199,42.081057],[-84.055461,42.057402],[-84.025002,41.985079],[-83.945045,41.943309],[-83.841956,42.08129],[-83.85199,42.081057]]]},"properties":{"id":"49286","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.913459,42.184368],[-83.914926,42.109316],[-83.85199,42.081057],[-83.841956,42.08129],[-83.773922,42.08243],[-83.683859,42.156871],[-83.699055,42.199779],[-83.797284,42.213353],[-83.913459,42.184368]]]},"properties":{"id":"48176","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.670823,42.519617],[-83.724223,42.619052],[-83.749155,42.633312],[-83.83396,42.563536],[-83.670751,42.518642],[-83.670823,42.519617]]]},"properties":{"id":"48114","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.757739,42.655583],[-83.842523,42.734188],[-83.910994,42.78105],[-83.970262,42.759234],[-83.991962,42.61553],[-83.749155,42.633312],[-83.757739,42.655583]],[[-83.960489,42.759452],[-83.961285,42.770346],[-83.951511,42.770617],[-83.950963,42.763619],[-83.960489,42.759452]]]},"properties":{"id":"48855","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.749155,42.633312],[-83.724223,42.619052],[-83.679105,42.63542],[-83.681797,42.678419],[-83.702047,42.677635],[-83.757739,42.655583],[-83.749155,42.633312]]]},"properties":{"id":"48353","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.04278,46.018447],[-84.689506,46.060271],[-84.925934,46.158413],[-85.018812,46.158212],[-85.058325,46.158163],[-85.04278,46.018447]]]},"properties":{"id":"49760","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.735075,46.194232],[-85.864357,46.056895],[-85.865402,45.968253],[-85.61322,46.099522],[-85.674368,46.170183],[-85.735075,46.194232]]]},"properties":{"id":"49838","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.863055,42.806666],[-82.980129,42.804932],[-82.980047,42.803332],[-82.979688,42.760227],[-82.976908,42.715905],[-82.859339,42.718133],[-82.861313,42.762456],[-82.863055,42.806666]]]},"properties":{"id":"48096","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.04973,42.579793],[-82.971044,42.570227],[-82.973317,42.627811],[-83.010499,42.626334],[-83.012697,42.62621],[-83.050411,42.594266],[-83.04973,42.579793]]]},"properties":{"id":"48313","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.041797,42.491281],[-83.085122,42.49076],[-83.084523,42.476345],[-83.083393,42.447153],[-83.024745,42.448159],[-83.02694,42.468993],[-83.03822,42.491358],[-83.041797,42.491281]]]},"properties":{"id":"48091","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.025995,42.713902],[-83.095339,42.71249],[-83.094793,42.696985],[-83.093706,42.67361],[-83.014635,42.669711],[-83.025995,42.713902]]]},"properties":{"id":"48316","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.849796,42.630391],[-82.801022,42.629545],[-82.705966,42.68631],[-82.729964,42.720843],[-82.753867,42.720265],[-82.849796,42.630391]]]},"properties":{"id":"48047","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.863782,42.570027],[-82.865107,42.540731],[-82.85514,42.540918],[-82.801022,42.629545],[-82.849796,42.630391],[-82.856131,42.630234],[-82.861294,42.609875],[-82.862343,42.591409],[-82.863782,42.570027]]]},"properties":{"id":"48045","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.037546,42.498636],[-83.041797,42.491281],[-83.03822,42.491358],[-83.037592,42.49146],[-83.037546,42.498636]]]},"properties":{"id":"48397","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.777747,44.489804],[-85.820859,44.352478],[-85.663952,44.343171],[-85.580307,44.348702],[-85.535001,44.381948],[-85.588031,44.460575],[-85.777747,44.489804]]]},"properties":{"id":"49668","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.296618,44.252113],[-86.30774,44.2462],[-86.293868,44.223653],[-86.283482,44.225253],[-86.28391,44.2482],[-86.296618,44.252113]]]},"properties":{"id":"49626","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-86.039905,44.06279],[-86.04087,44.105967],[-86.080718,44.105831],[-86.088958,44.069326],[-86.039905,44.06279]]],[[[-86.220799,44.019566],[-86.118664,43.953347],[-86.114486,43.94609],[-86.116633,44.060861],[-86.240718,44.044944],[-86.220799,44.019566]]]]},"properties":{"id":"49410","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.303988,43.554412],[-85.284086,43.554313],[-85.324224,43.619287],[-85.38434,43.630283],[-85.562303,43.623671],[-85.562512,43.555047],[-85.303988,43.554412]]]},"properties":{"id":"49346","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.661115,43.698345],[-85.562303,43.623671],[-85.38434,43.630283],[-85.323601,43.7708],[-85.463028,43.790245],[-85.661115,43.698345]]]},"properties":{"id":"49307","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.264511,43.7521],[-85.279738,43.756305],[-85.288343,43.751488],[-85.264511,43.7521]]]},"properties":{"id":"49320","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.555875,45.611295],[-87.551799,45.606212],[-87.540681,45.623182],[-87.548743,45.623283],[-87.555875,45.611295]]]},"properties":{"id":"49863","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.181029,43.583335],[-84.336494,43.655413],[-84.488486,43.625765],[-84.506497,43.553379],[-84.48884,43.531609],[-84.409253,43.538594],[-84.369788,43.509555],[-84.310629,43.52499],[-84.24939,43.513806],[-84.229615,43.510685],[-84.181029,43.583335]],[[-84.238683,43.601299],[-84.235448,43.607509],[-84.227467,43.601864],[-84.235615,43.597231],[-84.238683,43.601299]]]},"properties":{"id":"48640","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.114587,44.287853],[-84.972303,44.162472],[-84.967927,44.162409],[-84.851705,44.161375],[-84.852362,44.228523],[-84.853001,44.298028],[-85.014769,44.314807],[-85.114587,44.287853]]]},"properties":{"id":"49632","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.302699,42.022336],[-83.216762,41.988614],[-83.181261,42.028454],[-83.277091,42.077232],[-83.314426,42.065356],[-83.302699,42.022336]]]},"properties":{"id":"48179","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.394489,41.855348],[-83.51275,41.86765],[-83.532591,41.852439],[-83.526217,41.830872],[-83.43475,41.825043],[-83.4129,41.82564],[-83.394489,41.855348]]]},"properties":{"id":"48145","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.965497,43.466135],[-84.96488,43.328837],[-84.925174,43.314199],[-84.895349,43.319219],[-84.885779,43.466157],[-84.905771,43.466184],[-84.965497,43.466135]]]},"properties":{"id":"48891","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.999893,44.893378],[-84.135291,45.198743],[-84.315435,45.198705],[-84.28428,45.113091],[-84.354104,44.948319],[-84.153357,44.85484],[-83.999893,44.893378]]]},"properties":{"id":"49709","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.206136,43.448556],[-86.021792,43.350357],[-86.012119,43.353189],[-86.059932,43.514019],[-86.137417,43.497528],[-86.221953,43.469135],[-86.206136,43.448556]]]},"properties":{"id":"49425","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.027027,43.118272],[-86.079469,43.041122],[-86.026496,43.023906],[-86.000675,43.005722],[-85.904947,43.003065],[-85.867411,43.060127],[-85.917573,43.133333],[-86.027027,43.118272]]]},"properties":{"id":"49404","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.066445,43.161491],[-86.027027,43.118272],[-85.917573,43.133333],[-85.8585,43.197786],[-85.869674,43.249702],[-85.939656,43.293302],[-86.020074,43.29344],[-86.027854,43.290652],[-86.067427,43.205857],[-86.066445,43.161491]]]},"properties":{"id":"49451","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.039243,43.68105],[-86.158048,43.642169],[-86.248457,43.556276],[-86.137417,43.497528],[-86.059932,43.514019],[-85.950015,43.568554],[-85.900392,43.640229],[-85.891356,43.697605],[-86.039243,43.68105]]]},"properties":{"id":"49421","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.250759,42.666662],[-83.328059,42.683666],[-83.327712,42.677215],[-83.329585,42.661803],[-83.30479,42.644015],[-83.250759,42.666662]]]},"properties":{"id":"48340","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.113975,42.47593],[-83.13374,42.47542],[-83.154133,42.467596],[-83.171265,42.445545],[-83.133115,42.446395],[-83.109626,42.446959],[-83.113975,42.47593]]]},"properties":{"id":"48220","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.444672,42.673128],[-83.386592,42.667711],[-83.327712,42.677215],[-83.328059,42.683666],[-83.32895,42.705918],[-83.44612,42.702291],[-83.444672,42.673128]]]},"properties":{"id":"48329","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.261208,42.483461],[-83.202198,42.481107],[-83.202384,42.488359],[-83.203419,42.510189],[-83.203746,42.517463],[-83.262253,42.508798],[-83.261352,42.486996],[-83.261208,42.483461]]]},"properties":{"id":"48076","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.516309,42.524983],[-83.52833,42.558556],[-83.558559,42.560212],[-83.581252,42.509351],[-83.579846,42.492205],[-83.555194,42.492867],[-83.519118,42.494291],[-83.516309,42.524983]]]},"properties":{"id":"48393","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.214589,42.723799],[-83.3301,42.732657],[-83.349342,42.720786],[-83.32895,42.705918],[-83.21378,42.709529],[-83.214589,42.723799]]]},"properties":{"id":"48359","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.433672,42.438942],[-83.414357,42.439523],[-83.43427,42.453484],[-83.433672,42.438942]]],[[[-83.433672,42.438942],[-83.494098,42.45157],[-83.553107,42.456879],[-83.625738,42.415411],[-83.433085,42.424934],[-83.433672,42.438942]]]]},"properties":{"id":"48167","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.183376,42.488727],[-83.155626,42.474122],[-83.153503,42.474169],[-83.158629,42.489485],[-83.183376,42.488727]]]},"properties":{"id":"48070","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.39823,43.670299],[-86.5576,43.646496],[-86.528476,43.557624],[-86.496448,43.542597],[-86.278507,43.556521],[-86.248457,43.556276],[-86.158048,43.642169],[-86.39823,43.670299]]]},"properties":{"id":"49455","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.408942,46.652906],[-89.485292,46.505645],[-89.365312,46.419275],[-89.258224,46.669568],[-89.313477,46.665263],[-89.408942,46.652906]]]},"properties":{"id":"49925","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.995663,46.894478],[-89.046512,46.808027],[-89.114718,46.75758],[-89.160151,46.699259],[-89.053777,46.525684],[-88.864474,46.679245],[-88.932396,46.78957],[-88.995663,46.894478]]]},"properties":{"id":"49948","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.775432,44.324955],[-84.774255,44.322189],[-84.770045,44.320534],[-84.770308,44.32607],[-84.775432,44.324955]]]},"properties":{"id":"48630","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.286498,43.192927],[-84.133782,43.129501],[-84.069581,43.125692],[-83.954909,43.190506],[-84.028359,43.234178],[-84.209355,43.244704],[-84.287212,43.212736],[-84.286498,43.192927]]]},"properties":{"id":"48616","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.944655,43.426719],[-83.925417,43.429203],[-83.938798,43.442928],[-83.940359,43.437597],[-83.944655,43.426719]]]},"properties":{"id":"48607","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.499643,42.90561],[-82.619681,42.902364],[-82.614924,42.813654],[-82.482127,42.806802],[-82.469768,42.877459],[-82.499643,42.90561]]]},"properties":{"id":"48079","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.514614,43.010453],[-82.513872,42.994385],[-82.501219,42.935364],[-82.447719,42.937153],[-82.428422,43.040383],[-82.514614,43.010453]]]},"properties":{"id":"48060","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.524975,43.168226],[-82.538658,43.081004],[-82.514614,43.010453],[-82.428422,43.040383],[-82.503163,43.168946],[-82.524975,43.168226]]]},"properties":{"id":"48059","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.619681,42.902364],[-82.624515,42.991076],[-82.63141,42.990869],[-82.745232,42.986686],[-82.73901,42.897541],[-82.619681,42.902364]]]},"properties":{"id":"48027","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.448595,41.920403],[-85.450855,41.92038],[-85.450683,41.916569],[-85.448555,41.916042],[-85.44858,41.919128],[-85.448595,41.920403]]]},"properties":{"id":"49075","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.758546,43.350719],[-82.534512,43.344073],[-82.531411,43.344241],[-82.536357,43.401865],[-82.566203,43.500032],[-82.751977,43.452894],[-82.758546,43.350719]]]},"properties":{"id":"48419","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.854363,43.523494],[-82.983418,43.402624],[-82.885309,43.322044],[-82.871107,43.322514],[-82.805899,43.345196],[-82.758546,43.350719],[-82.751977,43.452894],[-82.854363,43.523494]]]},"properties":{"id":"48471","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.555153,43.480093],[-83.433007,43.393804],[-83.269023,43.411208],[-83.227162,43.448314],[-83.269094,43.497508],[-83.332443,43.590449],[-83.457357,43.581254],[-83.464385,43.523272],[-83.555153,43.480093]]]},"properties":{"id":"48723","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.269094,43.497508],[-83.227162,43.448314],[-83.107333,43.442629],[-83.107892,43.457522],[-83.111973,43.544602],[-83.269094,43.497508]]]},"properties":{"id":"48729","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.643776,42.266776],[-83.671194,42.259158],[-83.68339,42.245486],[-83.699055,42.199779],[-83.683859,42.156871],[-83.600075,42.135408],[-83.590743,42.136445],[-83.547026,42.174276],[-83.553048,42.206719],[-83.643776,42.266776]]]},"properties":{"id":"48197","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.017866,42.374041],[-83.054365,42.361004],[-83.054241,42.360835],[-83.043756,42.343369],[-83.038065,42.331115],[-83.037814,42.330801],[-83.040631,42.32737],[-83.000416,42.345086],[-83.017866,42.374041]]]},"properties":{"id":"48207","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.264512,42.183526],[-83.308999,42.26823],[-83.348095,42.267463],[-83.426534,42.265351],[-83.425025,42.237938],[-83.423191,42.179439],[-83.304737,42.137816],[-83.266362,42.15256],[-83.26675,42.167309],[-83.264512,42.183526]],[[-83.376343,42.194649],[-83.385085,42.208756],[-83.327852,42.240048],[-83.32591,42.195523],[-83.376343,42.194649]]]},"properties":{"id":"48174","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.428283,42.308981],[-83.545375,42.305817],[-83.543728,42.262462],[-83.44051,42.264934],[-83.426534,42.265351],[-83.427436,42.287465],[-83.428283,42.308981]]]},"properties":{"id":"48188","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.151079,42.296233],[-83.179395,42.294785],[-83.167074,42.289571],[-83.151079,42.296233]]],[[[-83.168503,42.351475],[-83.22481,42.328641],[-83.226514,42.31089],[-83.214887,42.30973],[-83.147038,42.31963],[-83.147736,42.351916],[-83.168503,42.351475]]]]},"properties":{"id":"48126","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.248867,42.342397],[-83.234981,42.32983],[-83.22481,42.328641],[-83.168503,42.351475],[-83.167374,42.366009],[-83.216931,42.378803],[-83.255852,42.378916],[-83.248867,42.342397]]]},"properties":{"id":"48228","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.276681,42.407278],[-83.218713,42.408328],[-83.219806,42.444346],[-83.258867,42.443403],[-83.287803,42.442683],[-83.285608,42.407026],[-83.276681,42.407278]]]},"properties":{"id":"48219","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.122085,42.410279],[-83.106734,42.39505],[-83.100588,42.388814],[-83.079728,42.396482],[-83.073631,42.398594],[-83.082544,42.42534],[-83.083393,42.447153],[-83.109626,42.446959],[-83.133115,42.446395],[-83.122085,42.410279]]]},"properties":{"id":"48203","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.162528,42.170743],[-83.179214,42.112658],[-83.181272,42.108378],[-83.143236,42.202618],[-83.152219,42.185115],[-83.162528,42.170743]]]},"properties":{"id":"48138","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.060461,42.325079],[-83.063293,42.322401],[-83.059481,42.323831],[-83.060461,42.325079]]]},"properties":{"id":"48233","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.731203,44.451202],[-83.590987,44.483752],[-83.585992,44.573285],[-83.56459,44.641387],[-83.747265,44.599733],[-83.828938,44.595402],[-83.764857,44.454386],[-83.731203,44.451202]]]},"properties":{"id":"48737","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.970605,46.323785],[-86.882029,46.18094],[-86.837152,46.158592],[-86.844796,46.249093],[-86.86064,46.34583],[-86.91524,46.390705],[-86.970605,46.323785]]]},"properties":{"id":"49816","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.071579,46.347504],[-87.06674,46.289337],[-87.04607,46.289292],[-87.032786,46.391108],[-87.071579,46.347504]]]},"properties":{"id":"49826","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.562684,42.537879],[-85.662809,42.594305],[-85.701958,42.556459],[-85.701781,42.546749],[-85.661482,42.486198],[-85.562684,42.537879]]]},"properties":{"id":"49070","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.887352,42.74371],[-85.839635,42.681077],[-85.721524,42.681434],[-85.673189,42.739335],[-85.673393,42.753888],[-85.841196,42.764172],[-85.874501,42.767508],[-85.887352,42.74371]]]},"properties":{"id":"49323","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.662809,42.594305],[-85.562684,42.537879],[-85.509063,42.559276],[-85.516173,42.594941],[-85.526711,42.599399],[-85.671004,42.637969],[-85.662809,42.594305]]]},"properties":{"id":"49344","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.503329,42.855271],[-85.590299,42.854556],[-85.63586,42.831069],[-85.62608,42.782761],[-85.565294,42.73215],[-85.468789,42.78312],[-85.503329,42.855271]]]},"properties":{"id":"49316","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.635279,45.074566],[-83.696147,44.923343],[-83.628956,44.956592],[-83.635279,45.074566]]]},"properties":{"id":"49744","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.438252,44.895735],[-85.374857,44.89506],[-85.372932,44.925166],[-85.407718,44.946054],[-85.438252,44.895735]]]},"properties":{"id":"49629","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.44439,46.851883],[-88.678402,46.716592],[-88.678856,46.642407],[-88.533133,46.658979],[-88.44439,46.851883]]]},"properties":{"id":"49908","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.27154,42.798712],[-85.192617,42.741688],[-85.070182,42.73059],[-85.053803,42.755894],[-84.995035,42.813966],[-85.089789,42.879129],[-85.114475,42.879411],[-85.204912,42.866372],[-85.27154,42.798712]]]},"properties":{"id":"48849","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.05,43.567324],[-84.029267,43.653274],[-84.087543,43.711551],[-84.107291,43.711508],[-84.139381,43.597525],[-84.05,43.567324]]]},"properties":{"id":"48611","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.982494,43.559936],[-83.994473,43.560874],[-83.994541,43.552337],[-83.980211,43.552107],[-83.982494,43.559936]]]},"properties":{"id":"48710","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.125643,44.632603],[-86.105066,44.515722],[-86.080564,44.515102],[-86.003257,44.579083],[-86.125643,44.632603]]]},"properties":{"id":"49616","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.125643,44.632603],[-86.003257,44.579083],[-85.891979,44.605823],[-85.939503,44.688121],[-86.186527,44.69564],[-86.125643,44.632603]]]},"properties":{"id":"49617","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.52504,41.856227],[-86.443566,41.759553],[-86.394719,41.759894],[-86.364864,41.899677],[-86.431799,41.921385],[-86.513832,41.899309],[-86.536286,41.874835],[-86.52504,41.856227]]]},"properties":{"id":"49107","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.157166,41.98519],[-85.039286,41.759683],[-84.942468,41.759713],[-84.932028,41.810993],[-84.917896,41.825629],[-84.9769,42.035528],[-85.012139,42.053436],[-85.157166,41.98519]]]},"properties":{"id":"49036","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.93431,42.130882],[-84.884636,42.072468],[-84.837688,42.058354],[-84.710143,42.09937],[-84.697549,42.137382],[-84.740307,42.188309],[-84.847549,42.224849],[-84.93431,42.130882]]]},"properties":{"id":"49245","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.740307,42.188309],[-84.697549,42.137382],[-84.594062,42.129791],[-84.569286,42.184774],[-84.612717,42.218318],[-84.640942,42.232585],[-84.740307,42.188309]]]},"properties":{"id":"49237","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.857651,41.961871],[-85.858501,41.821769],[-85.896759,41.795353],[-85.822833,41.798833],[-85.799167,41.831054],[-85.760211,41.877224],[-85.788903,41.954627],[-85.857651,41.961871]]]},"properties":{"id":"49061","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.858501,41.821769],[-85.893345,41.983713],[-85.954046,42.012416],[-85.973556,42.012502],[-86.146347,41.888769],[-86.107725,41.854538],[-85.897015,41.780674],[-85.896759,41.795353],[-85.858501,41.821769]]]},"properties":{"id":"49031","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.975397,45.27185],[-84.919693,45.248039],[-84.929009,45.27499],[-84.975397,45.27185]]]},"properties":{"id":"49796","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.66621,45.56359],[-84.975552,45.636575],[-84.873246,45.536307],[-84.833212,45.532721],[-84.690492,45.549966],[-84.66621,45.56359]]]},"properties":{"id":"49769","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.459512,46.224387],[-84.538424,46.099237],[-84.38313,46.071437],[-84.259618,46.071478],[-84.07131,46.184513],[-84.097389,46.226247],[-84.281277,46.28873],[-84.333893,46.244605],[-84.404646,46.215679],[-84.457733,46.243749],[-84.459512,46.224387]]]},"properties":{"id":"49774","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.879213,46.000543],[-84.179326,45.984954],[-84.114287,45.978239],[-83.889896,45.951413],[-83.879213,46.000543]]]},"properties":{"id":"49725","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.200782,46.245595],[-85.112606,46.178651],[-85.018812,46.158212],[-84.925934,46.158413],[-85.008183,46.261674],[-85.113507,46.274705],[-85.200782,46.245595]]]},"properties":{"id":"49793","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.946457,43.964121],[-84.921486,43.915072],[-84.918085,43.929299],[-84.946457,43.964121]]]},"properties":{"id":"48633","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.848651,43.741752],[-84.784663,43.92307],[-84.918085,43.929299],[-84.921486,43.915072],[-84.95463,43.745641],[-84.857014,43.712577],[-84.848651,43.741752]]]},"properties":{"id":"48622","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.720115,42.900358],[-84.818665,42.972949],[-84.837437,42.915104],[-84.778905,42.884735],[-84.729463,42.882922],[-84.720032,42.893374],[-84.720115,42.900358]]]},"properties":{"id":"48894","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.264596,45.859114],[-87.078656,45.794138],[-87.056313,45.775316],[-87.047347,45.775253],[-87.012715,45.829752],[-87.052858,45.955947],[-87.103133,45.978158],[-87.122522,45.956401],[-87.264596,45.859114]]]},"properties":{"id":"49837","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.054126,46.071999],[-87.103133,45.978158],[-87.052858,45.955947],[-87.054126,46.071999]]]},"properties":{"id":"49872","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.971035,46.125655],[-88.117858,46.115302],[-88.117483,46.013597],[-88.009868,46.048389],[-87.899715,46.082921],[-87.971035,46.125655]]]},"properties":{"id":"49881","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.631641,42.683017],[-84.66101,42.683049],[-84.700577,42.646814],[-84.69893,42.611075],[-84.598282,42.596812],[-84.602949,42.640243],[-84.631641,42.683017]]]},"properties":{"id":"48821","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.773675,43.039126],[-83.734602,43.017533],[-83.713177,43.036734],[-83.733718,43.089536],[-83.793641,43.104295],[-83.773675,43.039126]]]},"properties":{"id":"48504","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.580012,43.165472],[-83.597062,43.117923],[-83.601456,43.111008],[-83.570907,43.10015],[-83.47042,43.132767],[-83.460003,43.178715],[-83.470393,43.22309],[-83.580416,43.180063],[-83.580012,43.165472]]]},"properties":{"id":"48463","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.266012,43.941652],[-84.606468,43.903163],[-84.606167,43.829728],[-84.45398,43.814335],[-84.388205,43.799228],[-84.287679,43.842214],[-84.245947,43.912892],[-84.266012,43.941652]]]},"properties":{"id":"48612","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.398871,46.19754],[-89.395752,46.204412],[-89.424014,46.202418],[-89.398871,46.19754]]]},"properties":{"id":"54540","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.777747,44.489804],[-85.588031,44.460575],[-85.582359,44.469112],[-85.671014,44.592542],[-85.748923,44.576454],[-85.805829,44.547611],[-85.797374,44.512931],[-85.777747,44.489804]]]},"properties":{"id":"49620","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.333396,44.681956],[-85.353362,44.641947],[-85.176002,44.611724],[-85.333396,44.681956]]]},"properties":{"id":"49680","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.346239,43.201136],[-84.287212,43.212736],[-84.209355,43.244704],[-84.36906,43.258148],[-84.392315,43.248603],[-84.36859,43.205031],[-84.346239,43.201136]]]},"properties":{"id":"48614","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.429113,43.306486],[-84.369259,43.277586],[-84.369461,43.302343],[-84.369788,43.509555],[-84.409253,43.538594],[-84.429113,43.306486]]]},"properties":{"id":"48662","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.369788,43.509555],[-84.369461,43.302343],[-84.25853,43.347752],[-84.290783,43.508598],[-84.310629,43.52499],[-84.369788,43.509555]]]},"properties":{"id":"48637","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.410093,42.068896],[-84.415182,42.02241],[-84.39159,42.015185],[-84.410093,42.068896]]]},"properties":{"id":"49282","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.742565,46.981997],[-88.717883,47.03128],[-88.700056,47.06696],[-88.933303,47.031256],[-89.01479,46.993987],[-88.995663,46.894478],[-88.932396,46.78957],[-88.879019,46.778761],[-88.72331,46.893488],[-88.742565,46.981997]]]},"properties":{"id":"49965","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.421167,47.188765],[-88.459045,47.147471],[-88.431948,47.158426],[-88.421167,47.188765]]]},"properties":{"id":"49934","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.483946,42.669369],[-84.598282,42.596812],[-84.544558,42.50944],[-84.5216,42.480018],[-84.346802,42.512697],[-84.318465,42.600875],[-84.363617,42.670677],[-84.462407,42.678092],[-84.483946,42.669369]]]},"properties":{"id":"48854","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.611655,46.072805],[-88.623307,46.058264],[-88.568317,46.019124],[-88.518839,46.050284],[-88.611655,46.072805]]]},"properties":{"id":"49927","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.048064,46.50642],[-88.116571,46.419951],[-88.117475,46.227028],[-87.970727,46.247082],[-87.904128,46.36794],[-88.048064,46.50642]]]},"properties":{"id":"49879","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.387901,46.039783],[-88.380351,46.041861],[-88.372542,46.039651],[-88.372348,46.047281],[-88.382744,46.04716],[-88.387901,46.039783]]]},"properties":{"id":"49902","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.206004,43.679037],[-85.224684,43.539796],[-84.946404,43.547202],[-85.021288,43.624833],[-85.087226,43.715801],[-85.087287,43.726536],[-85.206004,43.679037]]]},"properties":{"id":"49340","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.590703,42.96981],[-85.662423,43.02137],[-85.672757,42.999138],[-85.673238,42.984729],[-85.600559,42.971505],[-85.590703,42.96981]]]},"properties":{"id":"49505","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.790448,43.279557],[-85.680612,43.304654],[-85.662135,43.350123],[-85.682434,43.366281],[-85.919547,43.3607],[-86.012119,43.353189],[-86.021792,43.350357],[-86.020074,43.29344],[-85.939656,43.293302],[-85.790448,43.279557]]]},"properties":{"id":"49327","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.273611,43.322672],[-85.412934,43.323454],[-85.437134,43.294297],[-85.351001,43.294376],[-85.293639,43.308377],[-85.266161,43.307806],[-85.273611,43.322672]]]},"properties":{"id":"49347","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.920021,47.484125],[-87.964081,47.333714],[-88.106803,47.2551],[-87.714827,47.397259],[-87.920021,47.484125]]]},"properties":{"id":"49918","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.374392,47.318004],[-88.422081,47.311796],[-88.419269,47.308348],[-88.387415,47.290649],[-88.374392,47.318004]]]},"properties":{"id":"49901","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.287899,43.158022],[-83.215584,43.112206],[-83.133797,43.151303],[-83.095604,43.166842],[-83.138461,43.26863],[-83.236402,43.279235],[-83.300563,43.265377],[-83.328471,43.197676],[-83.287899,43.158022]]]},"properties":{"id":"48461","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.42818,42.948956],[-83.386819,42.89345],[-83.337279,42.897366],[-83.221744,42.885545],[-83.201699,42.886045],[-83.163275,42.981781],[-83.240999,43.009517],[-83.42818,42.948956]],[[-83.42302,42.953766],[-83.403763,42.957696],[-83.406682,42.944919],[-83.42302,42.953766]]]},"properties":{"id":"48455","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.814771,44.790025],[-85.715868,44.914241],[-85.811606,44.947828],[-85.872783,44.939835],[-85.814771,44.790025]]]},"properties":{"id":"49621","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.085888,44.863011],[-85.947001,44.847007],[-85.951887,44.941805],[-86.085888,44.863011]]]},"properties":{"id":"49636","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.131076,41.987055],[-84.131952,42.059205],[-84.246936,42.013021],[-84.212686,41.957437],[-84.131076,41.987055]]]},"properties":{"id":"49265","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.793586,42.452311],[-83.802714,42.45723],[-83.821474,42.453438],[-83.793143,42.445278],[-83.793586,42.452311]]]},"properties":{"id":"48139","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.472943,46.245578],[-85.61322,46.099522],[-85.865402,45.968253],[-85.865501,45.962286],[-85.050245,46.007823],[-85.04278,46.018447],[-85.058325,46.158163],[-85.112606,46.178651],[-85.200782,46.245595],[-85.237839,46.245421],[-85.472943,46.245578]]]},"properties":{"id":"49762","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.735075,46.194232],[-85.738744,46.213622],[-85.864471,46.324163],[-85.99024,46.249547],[-85.864357,46.056895],[-85.735075,46.194232]]]},"properties":{"id":"49836","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.865664,42.895246],[-82.983647,42.893741],[-82.980129,42.804932],[-82.863055,42.806666],[-82.865664,42.895246]]]},"properties":{"id":"48005","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.760081,44.227849],[-85.618882,44.251284],[-85.663952,44.343171],[-85.820859,44.352478],[-85.849319,44.338242],[-85.893051,44.252976],[-85.760081,44.227849]]]},"properties":{"id":"49638","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.615969,46.185101],[-87.463383,46.145484],[-87.400405,46.115049],[-87.381056,46.113062],[-87.353393,46.246197],[-87.325253,46.280843],[-87.376056,46.304358],[-87.365621,46.347355],[-87.507459,46.410954],[-87.492492,46.381608],[-87.615101,46.333617],[-87.659214,46.246915],[-87.615969,46.185101]]]},"properties":{"id":"49841","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.048064,46.50642],[-87.904128,46.36794],[-87.865152,46.723199],[-87.991289,46.71453],[-88.116218,46.668966],[-88.116423,46.620312],[-88.048064,46.50642]]]},"properties":{"id":"49814","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-85.284079,43.539839],[-85.284086,43.554313],[-85.303988,43.554412],[-85.284079,43.539839]]],[[[-85.284079,43.539839],[-85.348,43.468096],[-85.322931,43.395328],[-85.263486,43.33708],[-85.164242,43.36469],[-85.154945,43.481064],[-85.234761,43.525346],[-85.284079,43.539839]]]]},"properties":{"id":"48850","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.348,43.468096],[-85.284079,43.539839],[-85.303988,43.554412],[-85.562512,43.555047],[-85.562457,43.51202],[-85.52225,43.467935],[-85.348,43.468096]]]},"properties":{"id":"49336","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.288343,43.751488],[-85.279738,43.756305],[-85.264511,43.7521],[-85.236318,43.741605],[-85.205951,43.792351],[-85.106212,43.973464],[-85.275636,44.07362],[-85.325561,44.073386],[-85.325661,43.971738],[-85.392245,43.916257],[-85.323601,43.7708],[-85.288343,43.751488]]]},"properties":{"id":"49631","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.646552,45.362249],[-87.670384,45.391224],[-87.610215,45.391417],[-87.610757,45.374716],[-87.462243,45.360777],[-87.400614,45.334985],[-87.258465,45.550075],[-87.327535,45.567181],[-87.382098,45.551025],[-87.801771,45.512294],[-87.651051,45.354171],[-87.646552,45.362249]]]},"properties":{"id":"49887","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.114587,44.287853],[-85.295509,44.260372],[-85.334777,44.165118],[-85.304052,44.16483],[-84.972303,44.162472],[-85.114587,44.287853]]]},"properties":{"id":"49657","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.538854,42.064701],[-83.454441,42.003754],[-83.387155,41.99605],[-83.302699,42.022336],[-83.314426,42.065356],[-83.3595,42.094004],[-83.479967,42.086879],[-83.539357,42.083408],[-83.538854,42.064701]]]},"properties":{"id":"48117","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.304737,42.137816],[-83.3595,42.094004],[-83.314426,42.065356],[-83.277091,42.077232],[-83.244136,42.081064],[-83.266362,42.15256],[-83.304737,42.137816]]]},"properties":{"id":"48134","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.543728,42.262462],[-83.553048,42.206719],[-83.547026,42.174276],[-83.539357,42.083408],[-83.479967,42.086879],[-83.423191,42.179439],[-83.425025,42.237938],[-83.44051,42.264934],[-83.543728,42.262462]]]},"properties":{"id":"48111","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.164242,43.36469],[-85.263486,43.33708],[-85.273611,43.322672],[-85.266161,43.307806],[-85.192081,43.272025],[-85.073583,43.248174],[-84.96455,43.264465],[-84.925174,43.314199],[-84.96488,43.328837],[-85.124201,43.386618],[-85.164242,43.36469]]]},"properties":{"id":"48888","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.244433,43.216018],[-86.215255,43.13239],[-86.19584,43.132293],[-86.106744,43.20572],[-86.244433,43.216018]]]},"properties":{"id":"49444","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.215255,43.13239],[-86.244433,43.216018],[-86.244083,43.232396],[-86.264731,43.241577],[-86.366619,43.234234],[-86.296904,43.118399],[-86.215255,43.13239]]]},"properties":{"id":"49441","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.244083,43.232396],[-86.253541,43.24868],[-86.264731,43.241577],[-86.244083,43.232396]]]},"properties":{"id":"49440","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.790448,43.279557],[-85.939656,43.293302],[-85.869674,43.249702],[-85.805296,43.256575],[-85.790448,43.279557]]]},"properties":{"id":"49303","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.32116,42.618418],[-83.25258,42.603839],[-83.248568,42.619938],[-83.248711,42.621909],[-83.30479,42.644015],[-83.329585,42.661803],[-83.334081,42.625357],[-83.32116,42.618418]]]},"properties":{"id":"48341","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.322019,42.558091],[-83.264316,42.55919],[-83.25258,42.603839],[-83.32116,42.618418],[-83.324925,42.613493],[-83.32392,42.594892],[-83.322019,42.558091]]]},"properties":{"id":"48302","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.264316,42.55919],[-83.236091,42.559837],[-83.206366,42.558417],[-83.207087,42.574069],[-83.209216,42.620995],[-83.248568,42.619938],[-83.25258,42.603839],[-83.264316,42.55919]]]},"properties":{"id":"48304","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.480028,42.742406],[-83.349342,42.720786],[-83.3301,42.732657],[-83.314031,42.765436],[-83.333022,42.795141],[-83.378071,42.803422],[-83.469895,42.79834],[-83.492986,42.785096],[-83.480028,42.742406]]]},"properties":{"id":"48348","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.480028,42.742406],[-83.498833,42.700647],[-83.44612,42.702291],[-83.32895,42.705918],[-83.349342,42.720786],[-83.480028,42.742406]]]},"properties":{"id":"48346","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.202198,42.481107],[-83.200522,42.444839],[-83.171265,42.445545],[-83.154133,42.467596],[-83.155626,42.474122],[-83.183376,42.488727],[-83.202384,42.488359],[-83.202198,42.481107]]]},"properties":{"id":"48237","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.319925,42.507373],[-83.262253,42.508798],[-83.203746,42.517463],[-83.20438,42.531905],[-83.249213,42.53085],[-83.32091,42.52937],[-83.319925,42.507373]]]},"properties":{"id":"48025","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.146994,42.548301],[-83.166233,42.54032],[-83.126738,42.5338],[-83.146994,42.548301]]]},"properties":{"id":"48017","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.883977,44.161786],[-83.883431,44.276398],[-83.964028,44.330815],[-84.046213,44.28754],[-84.068265,44.224354],[-84.060171,44.161992],[-83.958872,44.161893],[-83.883977,44.161786]]]},"properties":{"id":"48756","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.933675,44.77895],[-84.090153,44.75305],[-83.888201,44.682086],[-83.933675,44.77895]]]},"properties":{"id":"48621","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.876749,42.974176],[-85.806385,43.030845],[-85.788181,43.06043],[-85.867411,43.060127],[-85.904947,43.003065],[-85.876749,42.974176]]]},"properties":{"id":"49435","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.538472,45.238309],[-83.374653,45.207091],[-83.571375,45.375014],[-83.683543,45.345799],[-83.538472,45.238309]]]},"properties":{"id":"49777","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.624127,42.664958],[-82.711872,42.593264],[-82.518599,42.614755],[-82.510362,42.653225],[-82.607712,42.665457],[-82.624127,42.664958]]]},"properties":{"id":"48001","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.711872,42.593264],[-82.606967,42.548282],[-82.518599,42.614755],[-82.711872,42.593264]]]},"properties":{"id":"48028","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.6106,42.724669],[-82.607712,42.665457],[-82.510362,42.653225],[-82.483623,42.728522],[-82.6106,42.724669]]]},"properties":{"id":"48039","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.534512,43.344073],[-82.758546,43.350719],[-82.805899,43.345196],[-82.771813,43.317314],[-82.575671,43.342609],[-82.534512,43.344073]]]},"properties":{"id":"48401","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.619697,43.658967],[-82.604643,43.65224],[-82.608889,43.668078],[-82.619697,43.658967]]]},"properties":{"id":"48434","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.433007,43.393804],[-83.555153,43.480093],[-83.581726,43.479873],[-83.679416,43.396145],[-83.691566,43.296145],[-83.684897,43.251076],[-83.461727,43.328607],[-83.433007,43.393804]]]},"properties":{"id":"48768","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.223596,42.250698],[-86.205288,42.107598],[-86.202994,42.09311],[-86.14685,42.090885],[-86.089775,42.156929],[-86.127831,42.257064],[-86.205722,42.25774],[-86.223596,42.250698]]]},"properties":{"id":"49057","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.079728,42.396482],[-83.054365,42.361004],[-83.017866,42.374041],[-83.028254,42.397669],[-83.037812,42.404686],[-83.073631,42.398594],[-83.079728,42.396482]]]},"properties":{"id":"48211","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.179395,42.294785],[-83.151079,42.296233],[-83.144473,42.293584],[-83.147038,42.31963],[-83.214887,42.30973],[-83.206197,42.298412],[-83.18364,42.295259],[-83.179395,42.294785]]]},"properties":{"id":"48120","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.076514,42.358757],[-83.06748,42.338017],[-83.06595,42.332282],[-83.043756,42.343369],[-83.054241,42.360835],[-83.076514,42.358757]]]},"properties":{"id":"48201","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.248867,42.342397],[-83.312825,42.35518],[-83.31218,42.340636],[-83.310867,42.311709],[-83.291108,42.31205],[-83.28638,42.312186],[-83.287102,42.326688],[-83.248913,42.327117],[-83.241632,42.328045],[-83.234981,42.32983],[-83.248867,42.342397]]]},"properties":{"id":"48127","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.330779,44.598448],[-83.363021,44.53872],[-83.31458,44.511736],[-83.309728,44.591084],[-83.330779,44.598448]]]},"properties":{"id":"48738","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.933675,44.77895],[-83.888201,44.682086],[-83.887461,44.631523],[-83.887464,44.627719],[-83.828938,44.595402],[-83.747265,44.599733],[-83.750444,44.769917],[-83.746103,44.856957],[-83.888484,44.856458],[-83.921404,44.856612],[-83.933675,44.77895]]]},"properties":{"id":"48728","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.731203,44.451202],[-83.665162,44.3876],[-83.582105,44.412345],[-83.326682,44.337402],[-83.31458,44.511736],[-83.363021,44.53872],[-83.461766,44.497143],[-83.590987,44.483752],[-83.731203,44.451202]]]},"properties":{"id":"48750","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.074429,46.55277],[-85.864516,46.617782],[-85.864155,46.702662],[-86.365049,46.587457],[-86.074429,46.55277]]]},"properties":{"id":"49839","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.671004,42.637969],[-85.526711,42.599399],[-85.565294,42.73215],[-85.62608,42.782761],[-85.673393,42.753888],[-85.666228,42.753823],[-85.6628,42.724719],[-85.672135,42.724777],[-85.673189,42.739335],[-85.721524,42.681434],[-85.671004,42.637969]]]},"properties":{"id":"49348","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.211468,42.6814],[-86.223691,42.650404],[-86.174367,42.637234],[-86.134224,42.645459],[-86.135018,42.681583],[-86.211468,42.6814]]]},"properties":{"id":"49453","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.973474,42.440547],[-86.013541,42.325523],[-85.976024,42.316226],[-85.946525,42.317303],[-85.920699,42.419319],[-85.973474,42.440547]]]},"properties":{"id":"49026","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.841196,42.764172],[-85.673393,42.753888],[-85.62608,42.782761],[-85.63586,42.831069],[-85.657909,42.8172],[-85.678722,42.849086],[-85.703292,42.855282],[-85.722979,42.855359],[-85.793381,42.840695],[-85.841196,42.764172]]]},"properties":{"id":"49315","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.209217,42.767536],[-86.212245,42.772966],[-86.227724,42.772321],[-86.209217,42.767536]]]},"properties":{"id":"49434","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.373309,45.129557],[-85.336496,45.062871],[-85.350241,45.12145],[-85.373309,45.129557]]]},"properties":{"id":"49627","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.98982,44.989128],[-84.980793,44.967496],[-84.951575,44.967058],[-84.98982,44.989128]]]},"properties":{"id":"49611","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.756132,44.02656],[-83.957228,44.033384],[-84.094486,43.984063],[-84.04488,43.914819],[-83.901645,43.914694],[-83.740213,43.992373],[-83.756132,44.02656]]]},"properties":{"id":"48658","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.509063,42.559276],[-85.454462,42.43752],[-85.314511,42.429492],[-85.253956,42.468629],[-85.32272,42.537011],[-85.426888,42.60963],[-85.516173,42.594941],[-85.509063,42.559276]]]},"properties":{"id":"49046","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.482251,42.421032],[-85.405401,42.392678],[-85.308409,42.419832],[-85.314511,42.429492],[-85.454462,42.43752],[-85.482251,42.421032]]]},"properties":{"id":"49060","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.3204,42.813207],[-85.388265,42.769252],[-85.358706,42.735531],[-85.192566,42.726624],[-85.192617,42.741688],[-85.27154,42.798712],[-85.3204,42.813207]]]},"properties":{"id":"49325","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.033604,42.538285],[-85.151586,42.493676],[-85.219322,42.449667],[-85.091809,42.41432],[-85.01501,42.373793],[-84.966773,42.390774],[-84.954244,42.47726],[-84.968234,42.538657],[-85.033604,42.538285]]]},"properties":{"id":"49021","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.929388,43.73773],[-84.135723,43.812805],[-84.167096,43.797285],[-84.107291,43.711508],[-84.087543,43.711551],[-83.929702,43.701269],[-83.91106,43.701283],[-83.929388,43.73773]]]},"properties":{"id":"48634","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.857422,43.613364],[-83.885557,43.522498],[-83.817228,43.522345],[-83.739487,43.579792],[-83.857422,43.613364]]]},"properties":{"id":"48708","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.258393,44.628845],[-86.227587,44.628869],[-86.247369,44.604087],[-86.242333,44.518284],[-86.140719,44.516701],[-86.105066,44.515722],[-86.125643,44.632603],[-86.186527,44.69564],[-86.179258,44.725747],[-86.258393,44.628845]]]},"properties":{"id":"49635","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.247976,44.427808],[-86.140719,44.516701],[-86.242333,44.518284],[-86.255697,44.428691],[-86.247976,44.427808]]]},"properties":{"id":"49613","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.817008,44.70656],[-85.757499,44.666035],[-85.638321,44.642913],[-85.593756,44.640147],[-85.618275,44.773389],[-85.591448,44.874964],[-85.693492,44.923511],[-85.715868,44.914241],[-85.814771,44.790025],[-85.856745,44.775959],[-85.817008,44.70656]]]},"properties":{"id":"49684","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.648259,41.866328],[-86.659666,41.868748],[-86.69541,41.839481],[-86.667975,41.838819],[-86.648259,41.866328]]]},"properties":{"id":"49116","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.942468,41.759713],[-84.752048,41.697972],[-84.782149,41.781335],[-84.864143,41.825552],[-84.874008,41.825598],[-84.932028,41.810993],[-84.942468,41.759713]]]},"properties":{"id":"49255","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.177739,42.145282],[-85.275301,42.137039],[-85.293626,42.071553],[-85.30566,42.045354],[-85.29344,42.050548],[-85.196266,42.072414],[-85.158819,42.133135],[-85.177739,42.145282]]]},"properties":{"id":"49011","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.297252,42.275602],[-85.185445,42.31187],[-85.291764,42.369898],[-85.297776,42.332815],[-85.297252,42.275602]]]},"properties":{"id":"49037","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.185445,42.31187],[-85.297252,42.275602],[-85.297235,42.273136],[-85.237991,42.243174],[-85.217805,42.208177],[-85.182213,42.311845],[-85.185445,42.31187]]]},"properties":{"id":"49015","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.336839,42.28046],[-85.413533,42.245305],[-85.432755,42.223824],[-85.336733,42.172692],[-85.295735,42.180078],[-85.237991,42.243174],[-85.297235,42.273136],[-85.336839,42.28046]]]},"properties":{"id":"49034","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.788903,41.954627],[-85.760211,41.877224],[-85.573972,41.911206],[-85.528219,41.96721],[-85.508853,42.070845],[-85.604611,42.070244],[-85.658832,42.070201],[-85.788903,41.954627]]]},"properties":{"id":"49093","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.50051,45.753118],[-85.604677,45.68178],[-85.623167,45.584869],[-85.491593,45.607603],[-85.50051,45.753118]]]},"properties":{"id":"49782","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.571553,45.498328],[-84.61515,45.48395],[-84.595526,45.480461],[-84.571553,45.498328]]]},"properties":{"id":"49791","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.165096,46.476912],[-85.239624,46.534426],[-85.237839,46.245421],[-85.200782,46.245595],[-85.113507,46.274705],[-85.165096,46.476912]]]},"properties":{"id":"49748","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.95463,43.745641],[-84.921486,43.915072],[-84.946457,43.964121],[-85.04028,44.030838],[-85.087912,43.98402],[-85.088815,43.79999],[-85.087287,43.726536],[-85.087226,43.715801],[-84.95463,43.745641]]]},"properties":{"id":"48632","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.61629,46.043662],[-87.675608,46.036364],[-87.690432,46.001306],[-87.693291,45.898829],[-87.617039,45.986006],[-87.61629,46.043662]]],[[[-87.382901,45.766011],[-87.326819,45.602045],[-87.327535,45.567181],[-87.258465,45.550075],[-87.085863,45.685101],[-87.315553,45.85416],[-87.367633,45.924798],[-87.448805,45.773974],[-87.382901,45.766011]]]]},"properties":{"id":"49807","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.66424,45.839355],[-86.65889,45.890385],[-86.695752,45.894782],[-86.66424,45.839355]]]},"properties":{"id":"49864","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.98597,45.774746],[-87.952057,45.818745],[-88.016848,45.802357],[-88.005855,45.792604],[-87.98597,45.774746]]]},"properties":{"id":"49876","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.825402,45.423308],[-84.82523,45.426521],[-84.828174,45.426566],[-84.828162,45.424501],[-84.825402,45.423308]]]},"properties":{"id":"49764","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.682842,43.034847],[-83.634839,43.018733],[-83.595511,43.047928],[-83.570907,43.10015],[-83.601456,43.111008],[-83.627921,43.101131],[-83.682842,43.034847]]]},"properties":{"id":"48506","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.814393,43.211718],[-83.830238,43.133398],[-83.813726,43.125254],[-83.580012,43.165472],[-83.580416,43.180063],[-83.695614,43.222217],[-83.814393,43.211718]]]},"properties":{"id":"48420","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.089115,42.777606],[-83.970262,42.759234],[-83.910994,42.78105],[-83.875567,42.816258],[-83.933526,42.854282],[-84.009866,42.840165],[-84.070263,42.806952],[-84.074844,42.806884],[-84.089115,42.777606]]]},"properties":{"id":"48418","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.684897,43.251076],[-83.695614,43.222217],[-83.580416,43.180063],[-83.470393,43.22309],[-83.431954,43.262519],[-83.393219,43.292277],[-83.461727,43.328607],[-83.684897,43.251076]]]},"properties":{"id":"48746","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.459014,43.092589],[-83.287899,43.158022],[-83.328471,43.197676],[-83.350521,43.198737],[-83.460003,43.178715],[-83.47042,43.132767],[-83.459014,43.092589]]]},"properties":{"id":"48421","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.456781,42.963137],[-83.519098,42.950825],[-83.526838,42.939866],[-83.525787,42.92608],[-83.562569,42.873619],[-83.523904,42.874665],[-83.386819,42.89345],[-83.42818,42.948956],[-83.456781,42.963137]]]},"properties":{"id":"48438","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.689384,42.871263],[-83.702047,42.677635],[-83.681797,42.678419],[-83.615441,42.69752],[-83.575811,42.698754],[-83.492986,42.785096],[-83.469895,42.79834],[-83.523904,42.874665],[-83.562569,42.873619],[-83.689384,42.871263]]]},"properties":{"id":"48442","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.046174,46.549725],[-90.053042,46.419827],[-90.008359,46.456239],[-90.005388,46.473754],[-89.989695,46.481096],[-90.046174,46.549725]]]},"properties":{"id":"49911","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.794997,46.274218],[-89.522419,46.221783],[-89.365408,46.332963],[-89.365312,46.419275],[-89.485292,46.505645],[-89.74146,46.505247],[-89.794997,46.274218]]]},"properties":{"id":"49947","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.989695,46.481096],[-90.005388,46.473754],[-90.008359,46.456239],[-89.989695,46.481096]]]},"properties":{"id":"49959","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.757499,44.666035],[-85.748923,44.576454],[-85.671014,44.592542],[-85.638321,44.642913],[-85.757499,44.666035]]]},"properties":{"id":"49637","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.547873,43.349842],[-84.528095,43.34972],[-84.528127,43.378992],[-84.547873,43.349842]]],[[[-84.547873,43.349842],[-84.647052,43.466046],[-84.746672,43.466234],[-84.766351,43.350111],[-84.737739,43.292139],[-84.547873,43.349842]]]]},"properties":{"id":"48801","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.680064,43.207826],[-84.758718,43.147312],[-84.778603,43.132976],[-84.758927,43.11838],[-84.680202,43.117968],[-84.598852,43.139322],[-84.619797,43.204568],[-84.680064,43.207826]]]},"properties":{"id":"48871","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.852294,43.99372],[-83.145852,43.981512],[-83.113753,43.921064],[-82.884913,43.928202],[-82.852294,43.99372]]]},"properties":{"id":"48445","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.561619,42.740956],[-84.557106,42.725593],[-84.553878,42.721941],[-84.547393,42.725043],[-84.548878,42.739441],[-84.561619,42.740956]]]},"properties":{"id":"48933","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.512666,42.769667],[-84.548878,42.739441],[-84.547393,42.725043],[-84.509806,42.726528],[-84.512666,42.769667]]]},"properties":{"id":"48912","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.551325,42.37664],[-84.50444,42.422087],[-84.5216,42.480018],[-84.544558,42.50944],[-84.600403,42.421984],[-84.570938,42.378748],[-84.551325,42.37664]]]},"properties":{"id":"49264","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.943161,46.078],[-88.56625,46.015466],[-88.568317,46.019124],[-88.623307,46.058264],[-88.62717,46.072723],[-88.611655,46.072805],[-88.518839,46.050284],[-88.591522,46.246264],[-88.616772,46.420131],[-88.679392,46.420139],[-88.781554,46.420081],[-88.990813,46.295154],[-88.990864,46.097381],[-88.955761,46.083027],[-88.938751,46.106364],[-88.920848,46.10014],[-88.943161,46.078]]]},"properties":{"id":"49935","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.338581,42.255257],[-84.423432,42.24742],[-84.476065,42.254888],[-84.331614,42.218504],[-84.338806,42.236174],[-84.315058,42.250089],[-84.338581,42.255257]]]},"properties":{"id":"49203","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.591204,43.175438],[-85.67171,43.21299],[-85.790222,43.176112],[-85.778063,43.0959],[-85.709043,43.092085],[-85.6376,43.135491],[-85.591204,43.175438]]]},"properties":{"id":"49345","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.657909,42.8172],[-85.650988,42.912813],[-85.680945,42.920528],[-85.678722,42.849086],[-85.657909,42.8172]]]},"properties":{"id":"49548","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.63144,43.061691],[-85.545502,43.0282],[-85.47608,43.053281],[-85.613517,43.116906],[-85.63144,43.061691]]]},"properties":{"id":"49306","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.018915,44.177233],[-86.04087,44.105967],[-86.039905,44.06279],[-85.921721,44.019198],[-85.842898,44.056276],[-85.763567,44.164704],[-86.018915,44.177233]]]},"properties":{"id":"49644","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.116633,44.060861],[-86.114486,43.94609],[-86.078828,43.903004],[-86.158207,43.816127],[-86.038369,43.794162],[-86.037884,43.815611],[-85.921721,44.019198],[-86.039905,44.06279],[-86.088958,44.069326],[-86.116633,44.060861]]]},"properties":{"id":"49402","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.393219,43.292277],[-83.431954,43.262519],[-83.350521,43.198737],[-83.328471,43.197676],[-83.300563,43.265377],[-83.304002,43.268036],[-83.393219,43.292277]]]},"properties":{"id":"48435","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.226549,41.928319],[-84.304383,41.928132],[-84.234277,41.798624],[-84.168355,41.81449],[-84.120036,41.83231],[-84.226549,41.928319]]]},"properties":{"id":"49235","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.841956,42.08129],[-83.945045,41.943309],[-83.905709,41.920519],[-83.867377,41.921083],[-83.761433,41.955373],[-83.751692,41.995407],[-83.773922,42.08243],[-83.841956,42.08129]]]},"properties":{"id":"49229","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.767417,41.820957],[-83.822641,41.758748],[-83.688957,41.812573],[-83.767417,41.820957]]]},"properties":{"id":"49276","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.749155,42.633312],[-83.991962,42.61553],[-84.071753,42.553718],[-84.042393,42.500118],[-83.854574,42.500039],[-83.83396,42.563536],[-83.749155,42.633312]]]},"properties":{"id":"48843","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.970262,42.759234],[-84.089115,42.777606],[-84.121834,42.765199],[-84.142998,42.568192],[-84.142976,42.567884],[-84.106409,42.546206],[-84.071753,42.553718],[-83.991962,42.61553],[-83.970262,42.759234]]]},"properties":{"id":"48836","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.472943,46.245578],[-85.635628,46.245159],[-85.650496,46.245212],[-85.674368,46.170183],[-85.61322,46.099522],[-85.472943,46.245578]]]},"properties":{"id":"49827","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.650496,46.245212],[-85.738744,46.213622],[-85.735075,46.194232],[-85.674368,46.170183],[-85.650496,46.245212]]]},"properties":{"id":"49820","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.012697,42.62621],[-83.091449,42.624208],[-83.0899,42.593009],[-83.050411,42.594266],[-83.012697,42.62621]]]},"properties":{"id":"48314","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.973317,42.627811],[-82.971044,42.570227],[-82.88913,42.628958],[-82.973317,42.627811]]]},"properties":{"id":"48038","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.929639,42.539331],[-82.970227,42.553063],[-82.969635,42.538429],[-82.968991,42.523618],[-82.929639,42.539331]]]},"properties":{"id":"48026","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.062105,44.264318],[-85.893051,44.252976],[-85.849319,44.338242],[-85.940484,44.339174],[-86.111452,44.333502],[-86.062105,44.264318]]]},"properties":{"id":"49619","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.575239,46.051224],[-87.400405,46.115049],[-87.463383,46.145484],[-87.575239,46.051224]]]},"properties":{"id":"49819","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.503797,46.459479],[-87.601697,46.432116],[-87.492492,46.381608],[-87.507459,46.410954],[-87.503797,46.459479]]]},"properties":{"id":"49871","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.681766,43.780893],[-85.761016,43.728105],[-85.700546,43.702842],[-85.661115,43.698345],[-85.463028,43.790245],[-85.494306,43.81551],[-85.681766,43.780893]]]},"properties":{"id":"49338","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.462243,45.360777],[-87.710108,45.159013],[-87.400614,45.334985],[-87.462243,45.360777]]]},"properties":{"id":"49858","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.387155,41.99605],[-83.303126,41.943094],[-83.216762,41.988614],[-83.302699,42.022336],[-83.387155,41.99605]]]},"properties":{"id":"48166","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.51275,41.86765],[-83.394489,41.855348],[-83.335679,41.891953],[-83.588087,41.96754],[-83.593996,41.939992],[-83.51275,41.86765]]]},"properties":{"id":"48161","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.598645,41.925336],[-83.718038,41.902099],[-83.76824,41.850232],[-83.767417,41.820957],[-83.688957,41.812573],[-83.667221,41.806219],[-83.624094,41.836388],[-83.598645,41.925336]]]},"properties":{"id":"49270","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.238788,43.454395],[-86.441632,43.375311],[-86.421916,43.339075],[-86.276508,43.373047],[-86.238788,43.454395]]]},"properties":{"id":"49461","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.066445,43.161491],[-86.129346,43.102936],[-86.130618,43.041452],[-86.079469,43.041122],[-86.027027,43.118272],[-86.066445,43.161491]]]},"properties":{"id":"49448","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.444672,42.673128],[-83.44612,42.702291],[-83.498833,42.700647],[-83.515104,42.700443],[-83.51309,42.612751],[-83.441914,42.614511],[-83.441917,42.614995],[-83.444672,42.673128]]]},"properties":{"id":"48386","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.328059,42.683666],[-83.250759,42.666662],[-83.248711,42.621909],[-83.248568,42.619938],[-83.209216,42.620995],[-83.212757,42.69376],[-83.21378,42.709529],[-83.32895,42.705918],[-83.328059,42.683666]]]},"properties":{"id":"48326","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.52833,42.558556],[-83.516309,42.524983],[-83.437586,42.527102],[-83.439055,42.555305],[-83.446267,42.577943],[-83.52833,42.558556]]]},"properties":{"id":"48390","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.494098,42.45157],[-83.495937,42.489353],[-83.519118,42.494291],[-83.555194,42.492867],[-83.553107,42.456879],[-83.494098,42.45157]]]},"properties":{"id":"48374","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.516309,42.524983],[-83.519118,42.494291],[-83.495937,42.489353],[-83.43552,42.480553],[-83.437586,42.527102],[-83.516309,42.524983]]]},"properties":{"id":"48377","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.250759,42.666662],[-83.30479,42.644015],[-83.248711,42.621909],[-83.250759,42.666662]]]},"properties":{"id":"48342","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.375533,42.616894],[-83.386592,42.667711],[-83.444672,42.673128],[-83.441917,42.614995],[-83.375533,42.616894]]]},"properties":{"id":"48327","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.102891,42.888647],[-83.181203,42.886617],[-83.179698,42.79892],[-83.099432,42.80055],[-83.102891,42.888647]]]},"properties":{"id":"48367","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.188255,46.772777],[-89.313477,46.665263],[-89.258224,46.669568],[-89.160151,46.699259],[-89.114718,46.75758],[-89.188255,46.772777]]]},"properties":{"id":"49960","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.86348,46.711438],[-89.863867,46.65822],[-89.74026,46.592147],[-89.539836,46.633603],[-89.86348,46.711438]]]},"properties":{"id":"49971","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.876107,42.95792],[-85.903404,42.942323],[-85.804476,42.882169],[-85.782177,42.911323],[-85.876107,42.95792]]]},"properties":{"id":"49428","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.944655,43.426719],[-83.967026,43.386674],[-84.051585,43.307493],[-83.991803,43.292219],[-83.943285,43.307865],[-83.872849,43.328904],[-83.8175,43.379642],[-83.738033,43.407187],[-83.778067,43.478669],[-83.817228,43.522345],[-83.885557,43.522498],[-83.910696,43.465011],[-83.927442,43.454474],[-83.938798,43.442928],[-83.925417,43.429203],[-83.944655,43.426719]]]},"properties":{"id":"48601","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.050166,43.101418],[-83.948465,43.087876],[-83.929079,43.132782],[-83.954909,43.190506],[-84.069581,43.125692],[-84.050166,43.101418]]]},"properties":{"id":"48460","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.940359,43.437597],[-83.961848,43.447205],[-83.994996,43.436656],[-83.994904,43.392334],[-83.967026,43.386674],[-83.944655,43.426719],[-83.940359,43.437597]]]},"properties":{"id":"48602","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.250315,43.078859],[-84.162592,43.128768],[-84.286342,43.179656],[-84.250315,43.078859]]]},"properties":{"id":"48841","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.624515,42.991076],[-82.619681,42.902364],[-82.499643,42.90561],[-82.501219,42.935364],[-82.513872,42.994385],[-82.624515,42.991076]]]},"properties":{"id":"48074","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.637218,43.077224],[-82.63141,42.990869],[-82.624515,42.991076],[-82.513872,42.994385],[-82.514614,43.010453],[-82.538658,43.081004],[-82.637218,43.077224]]]},"properties":{"id":"48049","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.614924,42.813654],[-82.6106,42.724669],[-82.483623,42.728522],[-82.482127,42.806802],[-82.614924,42.813654]]]},"properties":{"id":"48054","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.755317,43.073264],[-82.870797,43.070252],[-82.868499,42.984018],[-82.86694,42.984002],[-82.75018,42.986567],[-82.755317,43.073264]]]},"properties":{"id":"48022","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.805899,43.345196],[-82.871107,43.322514],[-82.863915,43.221638],[-82.757297,43.24921],[-82.771813,43.317314],[-82.805899,43.345196]]]},"properties":{"id":"48466","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.050166,43.101418],[-84.1231,42.946009],[-83.994286,42.959277],[-83.928322,43.044511],[-83.948465,43.087876],[-84.050166,43.101418]]]},"properties":{"id":"48817","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.543728,42.262462],[-83.545375,42.305817],[-83.546481,42.327576],[-83.605304,42.330193],[-83.643776,42.266776],[-83.553048,42.206719],[-83.543728,42.262462]]]},"properties":{"id":"48198","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.590743,42.136445],[-83.600075,42.135408],[-83.59979,42.128159],[-83.590743,42.136445]]]},"properties":{"id":"48190","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.978456,42.390752],[-83.917207,42.282035],[-83.818245,42.349219],[-83.815217,42.380366],[-83.885684,42.425761],[-83.987409,42.424024],[-83.978456,42.390752]]]},"properties":{"id":"48130","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.109068,42.35747],[-83.130146,42.383767],[-83.167915,42.380504],[-83.167374,42.366009],[-83.168503,42.351475],[-83.147736,42.351916],[-83.111649,42.355732],[-83.109068,42.35747]]]},"properties":{"id":"48204","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.179214,42.112658],[-83.244136,42.081064],[-83.277091,42.077232],[-83.181261,42.028454],[-83.181272,42.108378],[-83.179214,42.112658]]]},"properties":{"id":"48173","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.309311,42.275505],[-83.291108,42.31205],[-83.310867,42.311709],[-83.339928,42.310986],[-83.309311,42.275505]]]},"properties":{"id":"48141","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.117584,42.259172],[-83.153033,42.264401],[-83.160859,42.255192],[-83.155667,42.234225],[-83.147399,42.234939],[-83.117584,42.259172]]]},"properties":{"id":"48229","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.44051,42.264934],[-83.425025,42.237938],[-83.426534,42.265351],[-83.44051,42.264934]]],[[[-83.426534,42.265351],[-83.348095,42.267463],[-83.427436,42.287465],[-83.426534,42.265351]]]]},"properties":{"id":"48184","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.038065,42.331115],[-83.041846,42.329656],[-83.0408,42.328837],[-83.037814,42.330801],[-83.038065,42.331115]]]},"properties":{"id":"48243","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.427436,42.287465],[-83.348095,42.267463],[-83.308999,42.26823],[-83.309311,42.275505],[-83.339928,42.310986],[-83.374081,42.310166],[-83.428283,42.308981],[-83.427436,42.287465]]]},"properties":{"id":"48186","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.06595,42.332282],[-83.060461,42.325079],[-83.059481,42.323831],[-83.063293,42.322401],[-83.065338,42.319541],[-83.040631,42.32737],[-83.037814,42.330801],[-83.0408,42.328837],[-83.041846,42.329656],[-83.038065,42.331115],[-83.043756,42.343369],[-83.06595,42.332282]]]},"properties":{"id":"48226","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.404637,44.815076],[-83.525966,44.741924],[-83.539107,44.64927],[-83.524582,44.639525],[-83.335927,44.758146],[-83.404637,44.815076]]]},"properties":{"id":"48742","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.545917,46.361662],[-86.844796,46.249093],[-86.837152,46.158592],[-86.572893,45.963756],[-86.489502,46.051578],[-86.514657,46.332496],[-86.545917,46.361662]]]},"properties":{"id":"49895","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.116474,46.501713],[-87.116355,46.410493],[-87.071579,46.347504],[-87.032786,46.391108],[-86.91524,46.390705],[-86.940447,46.499937],[-87.116474,46.501713]]]},"properties":{"id":"49822","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.032786,46.391108],[-87.04607,46.289292],[-86.970605,46.323785],[-86.91524,46.390705],[-87.032786,46.391108]]]},"properties":{"id":"49825","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.509063,42.559276],[-85.562684,42.537879],[-85.661482,42.486198],[-85.689091,42.404416],[-85.574974,42.387051],[-85.532103,42.390838],[-85.482251,42.421032],[-85.454462,42.43752],[-85.509063,42.559276]]]},"properties":{"id":"49080","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.841255,45.203261],[-83.888484,44.856458],[-83.746103,44.856957],[-83.696147,44.923343],[-83.635279,45.074566],[-83.657648,45.187774],[-83.819519,45.203479],[-83.841255,45.203261]]]},"properties":{"id":"49753","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.139165,45.033205],[-85.316274,45.035178],[-85.302353,44.946161],[-85.198578,44.886125],[-85.091249,45.015222],[-85.139165,45.033205]]]},"properties":{"id":"49615","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.745999,44.055461],[-83.903007,44.083959],[-83.957228,44.033384],[-83.756132,44.02656],[-83.745999,44.055461]]]},"properties":{"id":"48749","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.442388,46.941166],[-88.72331,46.893488],[-88.879019,46.778761],[-88.678402,46.716592],[-88.44439,46.851883],[-88.44447,46.85766],[-88.442388,46.941166]]]},"properties":{"id":"49958","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.192566,42.726624],[-85.153763,42.649295],[-85.07553,42.668605],[-85.070182,42.73059],[-85.192617,42.741688],[-85.192566,42.726624]]]},"properties":{"id":"48897","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.014327,43.523688],[-83.885557,43.522498],[-83.857422,43.613364],[-83.848438,43.658576],[-83.91106,43.701283],[-83.929702,43.701269],[-84.029267,43.653274],[-84.05,43.567324],[-84.014327,43.523688]],[[-83.982494,43.559936],[-83.980211,43.552107],[-83.994541,43.552337],[-83.994473,43.560874],[-83.982494,43.559936]]]},"properties":{"id":"48706","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.310629,43.52499],[-84.290783,43.508598],[-84.24939,43.513806],[-84.310629,43.52499]]],[[[-84.181029,43.583335],[-84.229615,43.510685],[-84.147762,43.434968],[-84.079214,43.451837],[-84.051811,43.488745],[-84.014327,43.523688],[-84.05,43.567324],[-84.139381,43.597525],[-84.181029,43.583335]]]]},"properties":{"id":"48623","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.939503,44.688121],[-85.891979,44.605823],[-85.805829,44.547611],[-85.748923,44.576454],[-85.757499,44.666035],[-85.817008,44.70656],[-85.937905,44.703639],[-85.939503,44.688121]]]},"properties":{"id":"49643","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.659666,41.868748],[-86.648259,41.866328],[-86.628791,41.87212],[-86.6267,41.897676],[-86.659666,41.868748]]]},"properties":{"id":"49115","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.339542,41.976719],[-86.399589,42.016132],[-86.443208,42.006413],[-86.465638,41.997646],[-86.431799,41.921385],[-86.364864,41.899677],[-86.291072,41.924453],[-86.306328,41.964212],[-86.339542,41.976719]],[[-86.359149,41.966838],[-86.353567,41.962631],[-86.360104,41.960984],[-86.359149,41.966838]]]},"properties":{"id":"49103","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.060333,42.192526],[-85.027133,42.191057],[-84.93431,42.130882],[-84.847549,42.224849],[-84.829037,42.363404],[-84.966773,42.390774],[-85.01501,42.373793],[-85.042573,42.28115],[-85.060333,42.192526]]]},"properties":{"id":"49068","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.66101,42.683049],[-84.701274,42.682828],[-84.701286,42.676264],[-84.700577,42.646814],[-84.66101,42.683049]]],[[[-84.956216,42.674304],[-84.968234,42.538657],[-84.954244,42.47726],[-84.797497,42.412646],[-84.779162,42.441966],[-84.69893,42.611075],[-84.749157,42.682928],[-84.876713,42.712123],[-84.956216,42.674304]]]]},"properties":{"id":"48813","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.399713,42.335428],[-85.366651,42.30236],[-85.297776,42.332815],[-85.291764,42.369898],[-85.308409,42.419832],[-85.405401,42.392678],[-85.399713,42.335428]]]},"properties":{"id":"49012","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.734303,45.201936],[-84.401584,45.198772],[-84.411466,45.256876],[-84.542836,45.327825],[-84.691692,45.332143],[-84.731546,45.332835],[-84.732687,45.289382],[-84.734303,45.201936]]]},"properties":{"id":"49799","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.07131,46.184513],[-84.259618,46.071478],[-84.179326,45.984954],[-83.879213,46.000543],[-83.875007,46.082465],[-84.07131,46.184513]]]},"properties":{"id":"49736","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.879213,46.000543],[-83.889896,45.951413],[-83.518515,45.906694],[-83.654647,46.122055],[-83.875007,46.082465],[-83.879213,46.000543]]]},"properties":{"id":"49726","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.363997,42.813376],[-84.41777,42.737649],[-84.367269,42.734268],[-84.32148,42.775929],[-84.341472,42.801949],[-84.363997,42.813376]]]},"properties":{"id":"48840","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.544856,44.334682],[-84.370419,44.492875],[-84.370643,44.507223],[-84.371019,44.610854],[-84.831698,44.511076],[-84.850929,44.511069],[-84.851874,44.493879],[-84.855795,44.421984],[-84.612446,44.334433],[-84.544856,44.334682]],[[-84.757667,44.461806],[-84.754567,44.464109],[-84.750915,44.461439],[-84.754834,44.457876],[-84.757667,44.461806]]]},"properties":{"id":"48653","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.459321,45.864515],[-86.489502,46.051578],[-86.572893,45.963756],[-86.578382,45.898829],[-86.538556,45.838608],[-86.459321,45.864515]]]},"properties":{"id":"49817","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.103133,45.978158],[-87.054126,46.071999],[-87.057137,46.158936],[-87.116466,46.228481],[-87.353393,46.246197],[-87.381056,46.113062],[-87.122522,45.956401],[-87.103133,45.978158]]]},"properties":{"id":"49880","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.264596,45.859114],[-87.122522,45.956401],[-87.381056,46.113062],[-87.400405,46.115049],[-87.575239,46.051224],[-87.61629,46.043662],[-87.617039,45.986006],[-87.367633,45.924798],[-87.315553,45.85416],[-87.264596,45.859114]]]},"properties":{"id":"49818","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.659214,46.246915],[-87.804036,46.247434],[-87.841363,46.110329],[-87.816303,46.08886],[-87.741683,46.048842],[-87.675608,46.036364],[-87.61629,46.043662],[-87.615969,46.185101],[-87.659214,46.246915]]]},"properties":{"id":"49877","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.86121,43.015331],[-83.78357,42.98732],[-83.73308,42.995417],[-83.734602,43.017533],[-83.773675,43.039126],[-83.86121,43.015331]]]},"properties":{"id":"48532","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.634489,43.004944],[-83.636968,43.004103],[-83.634498,43.004168],[-83.634489,43.004944]]],[[[-83.726139,42.91473],[-83.679904,42.959797],[-83.643713,42.989319],[-83.644076,43.003909],[-83.73308,42.995417],[-83.78357,42.98732],[-83.794573,42.978591],[-83.786547,42.978699],[-83.726139,42.91473]],[[-83.718593,42.973636],[-83.724746,42.973503],[-83.723665,42.98245],[-83.713222,42.98832],[-83.712836,42.973798],[-83.718593,42.973636]]]]},"properties":{"id":"48507","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.689248,43.00805],[-83.679611,43.010202],[-83.68678,43.017682],[-83.694521,43.014139],[-83.689248,43.00805]]]},"properties":{"id":"48502","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.723665,42.98245],[-83.718593,42.973636],[-83.712836,42.973798],[-83.713222,42.98832],[-83.723665,42.98245]]]},"properties":{"id":"48551","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.469895,42.79834],[-83.378071,42.803422],[-83.337279,42.897366],[-83.386819,42.89345],[-83.523904,42.874665],[-83.469895,42.79834]]]},"properties":{"id":"48462","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.597062,43.117923],[-83.627385,43.105857],[-83.627921,43.101131],[-83.601456,43.111008],[-83.597062,43.117923]]]},"properties":{"id":"48437","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.046174,46.549725],[-89.989695,46.481096],[-90.008359,46.456239],[-90.053042,46.419827],[-89.930326,46.299979],[-89.794997,46.274218],[-89.74146,46.505247],[-89.74026,46.592147],[-89.863867,46.65822],[-90.046174,46.549725]]]},"properties":{"id":"49968","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.541209,44.638676],[-85.57089,44.63037],[-85.560156,44.611132],[-85.541209,44.638676]]]},"properties":{"id":"49666","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.600663,43.187047],[-84.583078,43.183215],[-84.583022,43.190469],[-84.600663,43.187047]]]},"properties":{"id":"48874","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.298992,47.286097],[-88.421167,47.188765],[-88.431948,47.158426],[-88.473458,47.077779],[-88.500225,47.067498],[-88.434565,46.97184],[-88.106803,47.2551],[-87.964081,47.333714],[-88.298992,47.286097]]]},"properties":{"id":"49945","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.879019,46.778761],[-88.932396,46.78957],[-88.864474,46.679245],[-88.727002,46.592974],[-88.67892,46.637423],[-88.678856,46.642407],[-88.678402,46.716592],[-88.879019,46.778761]]]},"properties":{"id":"49952","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.840082,43.781545],[-82.639695,43.689666],[-82.608889,43.668078],[-82.604643,43.65224],[-82.59733,43.639538],[-82.639571,43.862742],[-82.841204,43.856951],[-82.840082,43.781545]]]},"properties":{"id":"48441","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.332365,43.887149],[-83.411207,43.810145],[-83.33798,43.785422],[-83.332365,43.887149]]]},"properties":{"id":"48720","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.639695,43.689666],[-82.840082,43.781545],[-82.840025,43.756155],[-82.839234,43.727199],[-82.639695,43.689666]]]},"properties":{"id":"48470","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.568257,42.719575],[-84.557106,42.725593],[-84.561619,42.740956],[-84.570807,42.758709],[-84.582161,42.721127],[-84.579438,42.721197],[-84.568257,42.719575]]]},"properties":{"id":"48915","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.579438,42.721197],[-84.483948,42.669087],[-84.483946,42.669369],[-84.462407,42.678092],[-84.462103,42.719057],[-84.509806,42.726528],[-84.547393,42.725043],[-84.553878,42.721941],[-84.568257,42.719575],[-84.579438,42.721197]]]},"properties":{"id":"48910","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.983868,43.112447],[-84.986779,43.112429],[-84.986772,43.108068],[-84.982645,43.108526],[-84.983868,43.112447]]]},"properties":{"id":"48870","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.588202,43.640791],[-84.895808,43.517817],[-84.905842,43.516984],[-84.905704,43.510426],[-84.905771,43.466184],[-84.885779,43.466157],[-84.819614,43.466231],[-84.746672,43.466234],[-84.647052,43.466046],[-84.506497,43.553379],[-84.488486,43.625765],[-84.488344,43.6405],[-84.588202,43.640791]]]},"properties":{"id":"48883","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.306186,42.331228],[-84.220672,42.160495],[-84.153185,42.171668],[-84.07401,42.250072],[-84.131204,42.411014],[-84.171708,42.419037],[-84.306186,42.331228]]]},"properties":{"id":"49240","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.306186,42.331228],[-84.171708,42.419037],[-84.314326,42.411194],[-84.323411,42.381684],[-84.306186,42.331228]]]},"properties":{"id":"49259","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.36479,42.363772],[-84.323411,42.381684],[-84.314326,42.411194],[-84.327287,42.424711],[-84.374518,42.412116],[-84.36479,42.363772]]]},"properties":{"id":"49272","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.309682,42.13161],[-84.451434,42.123786],[-84.445986,42.072999],[-84.41717,42.091177],[-84.315645,42.087929],[-84.309682,42.13161]]]},"properties":{"id":"49234","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.531816,42.363766],[-84.551325,42.37664],[-84.570938,42.378748],[-84.64821,42.334627],[-84.640942,42.232585],[-84.612717,42.218318],[-84.537329,42.247485],[-84.531816,42.363766]]]},"properties":{"id":"49269","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.574974,42.387051],[-85.689091,42.404416],[-85.76448,42.400966],[-85.805391,42.30836],[-85.734984,42.186446],[-85.648099,42.194355],[-85.640731,42.24527],[-85.648361,42.256796],[-85.648025,42.330469],[-85.574974,42.387051]]]},"properties":{"id":"49009","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.609329,42.307307],[-85.598208,42.288745],[-85.589566,42.284113],[-85.571924,42.294786],[-85.571121,42.303249],[-85.609329,42.307307]]]},"properties":{"id":"49007","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.574974,42.387051],[-85.648025,42.330469],[-85.609329,42.307307],[-85.571121,42.303249],[-85.505514,42.347255],[-85.532103,42.390838],[-85.574974,42.387051]]]},"properties":{"id":"49004","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.608156,42.912637],[-85.463814,42.878322],[-85.560926,42.964085],[-85.590703,42.96981],[-85.608156,42.912637]]]},"properties":{"id":"49546","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.672971,43.287034],[-85.67171,43.21299],[-85.591204,43.175438],[-85.391964,43.191043],[-85.354576,43.198279],[-85.359217,43.261117],[-85.672971,43.287034]]]},"properties":{"id":"49319","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.672971,43.287034],[-85.359217,43.261117],[-85.351001,43.294376],[-85.437134,43.294297],[-85.562645,43.366707],[-85.662135,43.350123],[-85.680612,43.304654],[-85.672971,43.287034]]]},"properties":{"id":"49343","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.805296,43.256575],[-85.869674,43.249702],[-85.8585,43.197786],[-85.799744,43.180274],[-85.805296,43.256575]]]},"properties":{"id":"49318","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.762063,43.925603],[-85.74201,43.829464],[-85.737383,43.814969],[-85.681595,43.826975],[-85.762063,43.925603]]]},"properties":{"id":"49642","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.983418,43.402624],[-83.010079,43.452799],[-83.107892,43.457522],[-83.107333,43.442629],[-83.154068,43.34087],[-83.119027,43.28368],[-82.885309,43.322044],[-82.983418,43.402624]]]},"properties":{"id":"48453","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.163275,42.981781],[-83.201699,42.886045],[-83.181203,42.886617],[-83.102891,42.888647],[-83.082592,42.976014],[-83.133835,42.981831],[-83.163275,42.981781]]]},"properties":{"id":"48428","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.596085,45.040681],[-85.599516,45.055064],[-85.617725,45.044081],[-85.596085,45.040681]]]},"properties":{"id":"49674","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.060419,41.800138],[-84.120036,41.83231],[-84.168355,41.81449],[-84.095659,41.714252],[-84.071305,41.715001],[-84.060419,41.800138]],[[-84.117713,41.77003],[-84.098143,41.773741],[-84.097681,41.763142],[-84.117355,41.762765],[-84.117713,41.77003]]]},"properties":{"id":"49279","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.820554,41.721872],[-83.645731,41.727476],[-83.644574,41.77776],[-83.667221,41.806219],[-83.688957,41.812573],[-83.822641,41.758748],[-83.820554,41.721872]]]},"properties":{"id":"49267","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.117713,41.77003],[-84.117355,41.762765],[-84.097681,41.763142],[-84.098143,41.773741],[-84.117713,41.77003]]]},"properties":{"id":"49289","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.83396,42.563536],[-83.854574,42.500039],[-83.824906,42.460328],[-83.802714,42.45723],[-83.793586,42.452311],[-83.747594,42.452116],[-83.670649,42.517259],[-83.670751,42.518642],[-83.83396,42.563536]]]},"properties":{"id":"48116","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.852854,42.459733],[-83.852052,42.459558],[-83.851631,42.460381],[-83.85196,42.460614],[-83.852854,42.459733]]]},"properties":{"id":"48143","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.864791,46.475452],[-85.864471,46.324163],[-85.738744,46.213622],[-85.650496,46.245212],[-85.635628,46.245159],[-85.864791,46.475452]]]},"properties":{"id":"49853","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.690989,46.01425],[-84.461492,45.969601],[-84.38313,46.071437],[-84.538424,46.099237],[-84.686864,46.056882],[-84.690989,46.01425]]]},"properties":{"id":"49745","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.56926,45.823311],[-84.6233,45.896914],[-84.672285,45.858612],[-84.594315,45.810291],[-84.56926,45.823311]]]},"properties":{"id":"49757","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.929639,42.539331],[-82.968991,42.523618],[-82.968063,42.499014],[-82.967145,42.479526],[-82.917496,42.480437],[-82.903369,42.510299],[-82.904298,42.539784],[-82.929639,42.539331]]]},"properties":{"id":"48066","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.089366,42.57845],[-83.086882,42.534792],[-83.047743,42.536141],[-83.04973,42.579793],[-83.050411,42.594266],[-83.0899,42.593009],[-83.089366,42.57845]]]},"properties":{"id":"48310","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.037546,42.498636],[-83.037592,42.49146],[-83.029359,42.491751],[-83.037546,42.498636]]],[[[-83.037546,42.498636],[-83.03569,42.536492],[-83.047743,42.536141],[-83.086882,42.534792],[-83.085122,42.49076],[-83.041797,42.491281],[-83.037546,42.498636]]]]},"properties":{"id":"48092","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.865664,42.895246],[-82.73901,42.897541],[-82.745232,42.986686],[-82.75018,42.986567],[-82.86694,42.984002],[-82.865664,42.895246]]]},"properties":{"id":"48041","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.859339,42.718133],[-82.856131,42.630234],[-82.849796,42.630391],[-82.753867,42.720265],[-82.859339,42.718133]]]},"properties":{"id":"48051","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.000757,44.40808],[-86.162724,44.345106],[-86.111452,44.333502],[-85.940484,44.339174],[-86.000757,44.40808]]]},"properties":{"id":"49645","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.395396,44.112048],[-86.080718,44.105831],[-86.04087,44.105967],[-86.018915,44.177233],[-86.062105,44.264318],[-86.111452,44.333502],[-86.162724,44.345106],[-86.176671,44.345459],[-86.269308,44.360275],[-86.415223,44.152928],[-86.395396,44.112048]],[[-86.292933,44.217504],[-86.285642,44.216294],[-86.28589,44.211977],[-86.295365,44.213803],[-86.292933,44.217504]],[[-86.296618,44.252113],[-86.28391,44.2482],[-86.283482,44.225253],[-86.293868,44.223653],[-86.30774,44.2462],[-86.296618,44.252113]]]},"properties":{"id":"49660","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.176671,44.345459],[-86.247976,44.427808],[-86.255697,44.428691],[-86.269308,44.360275],[-86.176671,44.345459]]]},"properties":{"id":"49675","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.338281,43.847738],[-86.300262,44.063032],[-86.395396,44.112048],[-86.415223,44.152928],[-86.441411,43.81946],[-86.338281,43.847738]]]},"properties":{"id":"49431","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.18837,43.816583],[-86.158207,43.816127],[-86.078828,43.903004],[-86.123791,43.913634],[-86.118664,43.953347],[-86.220799,44.019566],[-86.243502,43.817481],[-86.18837,43.816583]]]},"properties":{"id":"49405","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.106212,43.973464],[-85.205951,43.792351],[-85.088815,43.79999],[-85.087912,43.98402],[-85.106212,43.973464]]]},"properties":{"id":"49679","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.646552,45.362249],[-87.610757,45.374716],[-87.610215,45.391417],[-87.670384,45.391224],[-87.646552,45.362249]]]},"properties":{"id":"49848","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.014769,44.314807],[-84.853001,44.298028],[-84.855795,44.421984],[-84.851874,44.493879],[-85.014769,44.314807]]]},"properties":{"id":"49667","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.760081,44.227849],[-85.763567,44.164704],[-85.609672,44.164789],[-85.334777,44.165118],[-85.295509,44.260372],[-85.337092,44.339254],[-85.535001,44.381948],[-85.580307,44.348702],[-85.618882,44.251284],[-85.760081,44.227849]]]},"properties":{"id":"49601","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.773922,42.08243],[-83.751692,41.995407],[-83.668838,42.010221],[-83.540979,42.057657],[-83.59979,42.128159],[-83.600075,42.135408],[-83.683859,42.156871],[-83.773922,42.08243]]]},"properties":{"id":"48160","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.3595,42.094004],[-83.304737,42.137816],[-83.423191,42.179439],[-83.479967,42.086879],[-83.3595,42.094004]]]},"properties":{"id":"48164","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.429273,45.112658],[-84.527074,44.891596],[-84.430833,44.856031],[-84.354104,44.948319],[-84.28428,45.113091],[-84.366702,45.172995],[-84.429273,45.112658]]]},"properties":{"id":"49751","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.129346,43.102936],[-86.066445,43.161491],[-86.067427,43.205857],[-86.106744,43.20572],[-86.19584,43.132293],[-86.129346,43.102936]]]},"properties":{"id":"49415","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.203498,43.262981],[-86.276508,43.373047],[-86.421916,43.339075],[-86.366619,43.234234],[-86.264731,43.241577],[-86.253541,43.24868],[-86.203498,43.262981]]]},"properties":{"id":"49445","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.891356,43.697605],[-85.900392,43.640229],[-85.700546,43.702842],[-85.761016,43.728105],[-85.891356,43.697605]]]},"properties":{"id":"49312","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.179698,42.79892],[-83.181203,42.886617],[-83.201699,42.886045],[-83.221744,42.885545],[-83.217454,42.797945],[-83.21676,42.789761],[-83.179698,42.79892]]]},"properties":{"id":"48370","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.094793,42.696985],[-83.154023,42.695249],[-83.150492,42.622542],[-83.091449,42.624208],[-83.093706,42.67361],[-83.094793,42.696985]]]},"properties":{"id":"48307","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.558559,42.560212],[-83.52833,42.558556],[-83.446267,42.577943],[-83.440597,42.585945],[-83.441914,42.614511],[-83.51309,42.612751],[-83.562062,42.610741],[-83.558559,42.560212]]]},"properties":{"id":"48382","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.377438,42.484074],[-83.375469,42.440557],[-83.316825,42.442033],[-83.318878,42.485548],[-83.377438,42.484074]]]},"properties":{"id":"48336","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.323466,42.529323],[-83.379737,42.527851],[-83.377479,42.484508],[-83.377438,42.484074],[-83.318878,42.485548],[-83.319925,42.507373],[-83.32091,42.52937],[-83.323466,42.529323]]]},"properties":{"id":"48334","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.113975,42.47593],[-83.109626,42.446959],[-83.083393,42.447153],[-83.084523,42.476345],[-83.110736,42.476018],[-83.113975,42.47593]]]},"properties":{"id":"48030","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.375533,42.616894],[-83.441917,42.614995],[-83.441914,42.614511],[-83.440597,42.585945],[-83.32392,42.594892],[-83.324925,42.613493],[-83.348697,42.606026],[-83.375533,42.616894]]]},"properties":{"id":"48324","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.217454,42.797945],[-83.333022,42.795141],[-83.314031,42.765436],[-83.21619,42.766572],[-83.21676,42.789761],[-83.217454,42.797945]]]},"properties":{"id":"48362","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.492986,42.785096],[-83.575811,42.698754],[-83.565888,42.699004],[-83.515104,42.700443],[-83.498833,42.700647],[-83.480028,42.742406],[-83.492986,42.785096]]]},"properties":{"id":"48350","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.206366,42.558417],[-83.185609,42.535651],[-83.166233,42.54032],[-83.146994,42.548301],[-83.148333,42.577302],[-83.207087,42.574069],[-83.206366,42.558417]]]},"properties":{"id":"48084","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.172176,42.503545],[-83.203419,42.510189],[-83.202384,42.488359],[-83.183376,42.488727],[-83.158629,42.489485],[-83.172176,42.503545]]]},"properties":{"id":"48072","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.185609,42.535651],[-83.206366,42.558417],[-83.236091,42.559837],[-83.249213,42.53085],[-83.20438,42.531905],[-83.185609,42.535651]]]},"properties":{"id":"48009","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.050603,44.508533],[-84.063279,44.334683],[-84.046213,44.28754],[-83.964028,44.330815],[-83.924316,44.440953],[-84.007246,44.508861],[-84.050603,44.508533]]]},"properties":{"id":"48635","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.292134,44.676541],[-84.371684,44.688045],[-84.371019,44.610854],[-84.370643,44.507223],[-84.24976,44.507257],[-84.229167,44.550965],[-84.292134,44.676541]]]},"properties":{"id":"48636","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.739479,45.348151],[-83.916298,45.324808],[-84.027067,45.219887],[-84.013038,45.198621],[-83.841255,45.203261],[-83.819519,45.203479],[-83.739479,45.348151]]]},"properties":{"id":"49743","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.757667,44.461806],[-84.754834,44.457876],[-84.750915,44.461439],[-84.754567,44.464109],[-84.757667,44.461806]]]},"properties":{"id":"48627","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.943285,43.307865],[-83.991803,43.292219],[-83.992381,43.247203],[-83.868218,43.236655],[-83.943285,43.307865]]]},"properties":{"id":"48417","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.8175,43.379642],[-83.872849,43.328904],[-83.8155,43.309625],[-83.8175,43.379642]]]},"properties":{"id":"48722","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.910696,43.465011],[-83.931162,43.461403],[-83.927442,43.454474],[-83.910696,43.465011]]]},"properties":{"id":"48724","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.868499,42.984018],[-82.987408,42.975163],[-82.983647,42.893741],[-82.865664,42.895246],[-82.86694,42.984002],[-82.868499,42.984018]]]},"properties":{"id":"48002","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.97315,43.119648],[-82.990961,43.068591],[-82.870797,43.070252],[-82.755317,43.073264],[-82.662936,43.163414],[-82.644985,43.192957],[-82.74462,43.204323],[-82.877221,43.157212],[-82.97315,43.119648]]]},"properties":{"id":"48097","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.579648,41.835228],[-85.554339,41.759344],[-85.318233,41.759888],[-85.438904,41.919116],[-85.44858,41.919128],[-85.448555,41.916042],[-85.450683,41.916569],[-85.450855,41.92038],[-85.448595,41.920403],[-85.565166,41.843095],[-85.579648,41.835228]]]},"properties":{"id":"49091","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.508853,42.070845],[-85.528219,41.96721],[-85.40989,41.951395],[-85.375993,41.997295],[-85.371643,42.071429],[-85.405907,42.071328],[-85.508853,42.070845]]]},"properties":{"id":"49072","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.375993,41.997295],[-85.30566,42.045354],[-85.293626,42.071553],[-85.371643,42.071429],[-85.375993,41.997295]]]},"properties":{"id":"49066","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.284251,43.381687],[-83.224107,43.361093],[-83.154068,43.34087],[-83.107333,43.442629],[-83.227162,43.448314],[-83.269023,43.411208],[-83.284251,43.381687]]]},"properties":{"id":"48741","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.985627,42.280043],[-85.993102,42.185473],[-85.887435,42.164062],[-85.82531,42.204029],[-85.805931,42.307945],[-85.946525,42.317303],[-85.976024,42.316226],[-85.985627,42.280043]]]},"properties":{"id":"49079","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.82531,42.204029],[-85.887435,42.164062],[-85.857449,42.069338],[-85.773229,42.101898],[-85.764605,42.157254],[-85.82531,42.204029]]]},"properties":{"id":"49065","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.625738,42.415411],[-83.627238,42.376131],[-83.432536,42.405375],[-83.432315,42.40802],[-83.433085,42.424934],[-83.625738,42.415411]]]},"properties":{"id":"48168","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.431423,42.385895],[-83.432536,42.405375],[-83.627238,42.376131],[-83.643339,42.365811],[-83.605304,42.330193],[-83.546481,42.327576],[-83.430038,42.352465],[-83.431423,42.385895]]]},"properties":{"id":"48170","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.96497,42.419202],[-82.958848,42.395448],[-82.946644,42.387469],[-82.914935,42.407266],[-82.927495,42.425975],[-82.951991,42.436119],[-82.96497,42.419202]]]},"properties":{"id":"48224","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.098054,42.326684],[-83.147038,42.31963],[-83.144473,42.293584],[-83.13753,42.282791],[-83.103666,42.287922],[-83.078526,42.312752],[-83.098054,42.326684]]]},"properties":{"id":"48209","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.037812,42.404686],[-83.082544,42.42534],[-83.073631,42.398594],[-83.037812,42.404686]]]},"properties":{"id":"48212","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.312825,42.35518],[-83.248867,42.342397],[-83.255852,42.378916],[-83.276681,42.407278],[-83.285608,42.407026],[-83.315306,42.406434],[-83.314226,42.384131],[-83.312825,42.35518]]]},"properties":{"id":"48239","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.91524,46.390705],[-86.86064,46.34583],[-86.766122,46.51087],[-86.940447,46.499937],[-86.91524,46.390705]]]},"properties":{"id":"49806","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.882029,46.18094],[-86.970605,46.323785],[-87.04607,46.289292],[-87.06674,46.289337],[-87.116466,46.228481],[-87.057137,46.158936],[-86.882029,46.18094]]]},"properties":{"id":"49891","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.091249,45.015222],[-85.198578,44.886125],[-85.173349,44.815717],[-85.161758,44.805628],[-84.847309,44.858037],[-84.848443,44.924679],[-84.951575,44.967058],[-84.980793,44.967496],[-84.98982,44.989128],[-84.969094,45.025403],[-85.091249,45.015222]]]},"properties":{"id":"49659","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.222705,45.205551],[-85.223641,45.115728],[-85.139165,45.033205],[-85.091249,45.015222],[-84.969094,45.025403],[-84.94479,45.074166],[-85.098846,45.261956],[-85.222705,45.205551]]]},"properties":{"id":"49727","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.626006,44.114367],[-83.746388,44.084381],[-83.745999,44.055461],[-83.756132,44.02656],[-83.740213,43.992373],[-83.56434,44.114601],[-83.626006,44.114367]]]},"properties":{"id":"48703","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.470238,46.625351],[-88.514645,46.420404],[-88.365808,46.419959],[-88.267483,46.572777],[-88.470238,46.625351]]]},"properties":{"id":"49919","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.192566,42.726624],[-85.358706,42.735531],[-85.426888,42.60963],[-85.32272,42.537011],[-85.191459,42.551851],[-85.153763,42.649295],[-85.192566,42.726624]]]},"properties":{"id":"49058","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.532103,42.390838],[-85.505514,42.347255],[-85.450617,42.332731],[-85.399713,42.335428],[-85.405401,42.392678],[-85.482251,42.421032],[-85.532103,42.390838]]]},"properties":{"id":"49083","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.581726,43.479873],[-83.699484,43.565823],[-83.700497,43.565647],[-83.778067,43.478669],[-83.738033,43.407187],[-83.679416,43.396145],[-83.581726,43.479873]]]},"properties":{"id":"48757","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.167096,43.797285],[-84.135723,43.812805],[-84.124906,43.869248],[-84.245947,43.912892],[-84.287679,43.842214],[-84.288196,43.827635],[-84.167096,43.797285]]]},"properties":{"id":"48652","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.247369,44.604087],[-86.227587,44.628869],[-86.258393,44.628845],[-86.247369,44.604087]]]},"properties":{"id":"49628","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.529567,42.050734],[-86.443208,42.006413],[-86.399589,42.016132],[-86.403578,42.060158],[-86.481394,42.127123],[-86.529567,42.050734]]]},"properties":{"id":"49085","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.28163,42.144993],[-86.205288,42.107598],[-86.223596,42.250698],[-86.262458,42.243526],[-86.28163,42.144993]]]},"properties":{"id":"49098","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.419576,42.186737],[-86.28163,42.144993],[-86.262458,42.243526],[-86.368774,42.243067],[-86.419576,42.186737]]]},"properties":{"id":"49038","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.443566,41.759553],[-86.52504,41.856227],[-86.572402,41.759546],[-86.443566,41.759553]]]},"properties":{"id":"49113","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.237991,42.243174],[-85.295735,42.180078],[-85.275301,42.137039],[-85.177739,42.145282],[-85.172733,42.173947],[-85.217805,42.208177],[-85.237991,42.243174]]]},"properties":{"id":"49051","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.353682,42.149895],[-85.405907,42.071328],[-85.371643,42.071429],[-85.293626,42.071553],[-85.275301,42.137039],[-85.295735,42.180078],[-85.336733,42.172692],[-85.353682,42.149895]]]},"properties":{"id":"49052","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.954046,42.012416],[-85.893345,41.983713],[-85.857651,41.961871],[-85.788903,41.954627],[-85.658832,42.070201],[-85.773229,42.101898],[-85.857449,42.069338],[-85.954046,42.012416]]]},"properties":{"id":"49067","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.893345,41.983713],[-85.858501,41.821769],[-85.857651,41.961871],[-85.893345,41.983713]]]},"properties":{"id":"49095","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.363878,46.331156],[-84.426326,46.302675],[-84.404646,46.215679],[-84.333893,46.244605],[-84.363878,46.331156]]],[[[-84.363878,46.331156],[-84.22218,46.318707],[-84.119332,46.315173],[-84.475594,46.452864],[-84.426668,46.374443],[-84.363878,46.331156]]]]},"properties":{"id":"49783","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.275636,44.07362],[-85.106212,43.973464],[-85.087912,43.98402],[-85.04028,44.030838],[-84.967927,44.162409],[-84.972303,44.162472],[-85.304052,44.16483],[-85.275636,44.07362]]]},"properties":{"id":"49665","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.81416,43.071396],[-84.818665,42.972949],[-84.720115,42.900358],[-84.692503,43.090213],[-84.699688,43.102859],[-84.679965,43.112397],[-84.680202,43.117968],[-84.758927,43.11838],[-84.81416,43.071396]]]},"properties":{"id":"48835","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.995224,42.914784],[-85.089789,42.879129],[-84.995035,42.813966],[-84.875994,42.799573],[-84.837095,42.800593],[-84.778905,42.884735],[-84.837437,42.915104],[-84.876828,42.915085],[-84.995224,42.914784]]]},"properties":{"id":"48875","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.459877,45.752025],[-86.459321,45.864515],[-86.538556,45.838608],[-86.459877,45.752025]]]},"properties":{"id":"49835","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.820746,45.777336],[-87.813055,45.787991],[-87.821046,45.785456],[-87.820746,45.777336]]]},"properties":{"id":"49852","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.995035,42.813966],[-85.053803,42.755894],[-84.993456,42.71222],[-84.876373,42.784958],[-84.875994,42.799573],[-84.995035,42.813966]]]},"properties":{"id":"48890","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.07553,42.668605],[-85.033604,42.538285],[-84.968234,42.538657],[-84.956216,42.674304],[-84.993456,42.71222],[-85.053803,42.755894],[-85.070182,42.73059],[-85.07553,42.668605]]]},"properties":{"id":"49096","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.793641,43.104295],[-83.733718,43.089536],[-83.627385,43.105857],[-83.597062,43.117923],[-83.580012,43.165472],[-83.813726,43.125254],[-83.793641,43.104295]]]},"properties":{"id":"48458","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.842523,42.734188],[-83.757739,42.655583],[-83.702047,42.677635],[-83.689384,42.871263],[-83.752287,42.867245],[-83.842523,42.734188]]]},"properties":{"id":"48430","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.713177,43.036734],[-83.734602,43.017533],[-83.73308,42.995417],[-83.644076,43.003909],[-83.636968,43.004103],[-83.634489,43.004944],[-83.634839,43.018733],[-83.682842,43.034847],[-83.713177,43.036734]],[[-83.689248,43.00805],[-83.694521,43.014139],[-83.68678,43.017682],[-83.679611,43.010202],[-83.689248,43.00805]]]},"properties":{"id":"48503","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.45398,43.814335],[-84.606167,43.829728],[-84.671361,43.725898],[-84.588202,43.640791],[-84.488344,43.6405],[-84.45398,43.814335]]]},"properties":{"id":"48618","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.156996,44.51115],[-85.058212,44.510491],[-84.910566,44.629875],[-84.961001,44.641727],[-85.176002,44.611724],[-85.353362,44.641947],[-85.438057,44.642071],[-85.432388,44.512275],[-85.156996,44.51115]]]},"properties":{"id":"49633","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.797292,43.241678],[-84.835887,43.234717],[-84.935028,43.243381],[-84.935581,43.163019],[-84.935698,43.14854],[-84.778603,43.132976],[-84.758718,43.147312],[-84.797292,43.241678]]]},"properties":{"id":"48811","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.380436,41.767641],[-84.381745,41.938631],[-84.396155,41.9453],[-84.459297,41.92742],[-84.476706,41.909305],[-84.515096,41.767529],[-84.380436,41.767641]]]},"properties":{"id":"49271","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.631534,41.796654],[-84.782149,41.781335],[-84.752048,41.697972],[-84.514609,41.703242],[-84.53409,41.738576],[-84.592495,41.752931],[-84.631534,41.796654]]]},"properties":{"id":"49232","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.826126,41.993505],[-84.777359,41.905163],[-84.748403,41.91243],[-84.709336,41.946283],[-84.728873,41.999967],[-84.826126,41.993505]]]},"properties":{"id":"49227","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.411734,47.280641],[-88.410137,47.266566],[-88.390698,47.281745],[-88.411274,47.281377],[-88.411734,47.280641]]]},"properties":{"id":"49942","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.502672,47.078784],[-88.615872,47.03278],[-88.637004,47.033081],[-88.742565,46.981997],[-88.72331,46.893488],[-88.442388,46.941166],[-88.434565,46.97184],[-88.500225,47.067498],[-88.502672,47.078784]]]},"properties":{"id":"49916","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.473458,47.077779],[-88.534564,47.119027],[-88.502672,47.078784],[-88.500225,47.067498],[-88.473458,47.077779]]]},"properties":{"id":"49922","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.502672,47.078784],[-88.534564,47.119027],[-88.62865,47.230137],[-88.615743,47.068847],[-88.615872,47.03278],[-88.502672,47.078784]],[[-88.591529,47.09106],[-88.576689,47.098132],[-88.577093,47.086339],[-88.591529,47.09106]]]},"properties":{"id":"49931","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.118656,43.704385],[-82.840025,43.756155],[-82.840082,43.781545],[-82.841204,43.856951],[-82.842731,43.871284],[-83.06232,43.86487],[-83.120111,43.759742],[-83.12997,43.718465],[-83.118656,43.704385]]]},"properties":{"id":"48413","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.126381,44.002659],[-83.145852,43.981512],[-82.852294,43.99372],[-82.827289,44.039184],[-83.126381,44.002659]]]},"properties":{"id":"48467","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.171708,42.419037],[-84.131204,42.411014],[-84.097842,42.405378],[-84.117203,42.534207],[-84.142976,42.567884],[-84.142998,42.568192],[-84.221348,42.552076],[-84.297369,42.489857],[-84.327287,42.424711],[-84.314326,42.411194],[-84.171708,42.419037]]]},"properties":{"id":"49285","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.39213,43.072535],[-85.267136,42.987265],[-85.168434,43.001941],[-85.144885,43.05754],[-85.154952,43.119704],[-85.15418,43.128582],[-85.379266,43.118384],[-85.39213,43.072535]]]},"properties":{"id":"48809","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.154952,43.119704],[-85.057529,43.065539],[-84.962016,43.09065],[-84.926049,43.105439],[-84.935698,43.14854],[-84.935581,43.163019],[-85.134616,43.163174],[-85.15418,43.128582],[-85.154952,43.119704]],[[-84.983868,43.112447],[-84.982645,43.108526],[-84.986772,43.108068],[-84.986779,43.112429],[-84.983868,43.112447]]]},"properties":{"id":"48834","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.946009,42.995702],[-84.882768,43.014192],[-84.840171,43.0619],[-84.926049,43.105439],[-84.962016,43.09065],[-84.946009,42.995702]]]},"properties":{"id":"48860","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.883431,44.276398],[-83.883977,44.161786],[-83.80581,44.162071],[-83.753071,44.233605],[-83.753045,44.248645],[-83.745325,44.313342],[-83.74531,44.318713],[-83.883431,44.276398]]]},"properties":{"id":"48770","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.117475,46.227028],[-88.116571,46.419951],[-88.23951,46.420082],[-88.591522,46.246264],[-88.518839,46.050284],[-88.568317,46.019124],[-88.56625,46.015466],[-88.116845,45.921627],[-88.117483,46.013597],[-88.117858,46.115302],[-88.117475,46.227028]],[[-88.387901,46.039783],[-88.382744,46.04716],[-88.372348,46.047281],[-88.372542,46.039651],[-88.380351,46.041861],[-88.387901,46.039783]]]},"properties":{"id":"49920","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.021288,43.624833],[-84.857014,43.712577],[-84.95463,43.745641],[-85.087226,43.715801],[-85.021288,43.624833]]]},"properties":{"id":"48893","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.671361,43.725898],[-84.848651,43.741752],[-84.857014,43.712577],[-84.671361,43.725898]]]},"properties":{"id":"48878","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.088815,43.79999],[-85.205951,43.792351],[-85.236318,43.741605],[-85.224525,43.722832],[-85.206004,43.679037],[-85.087287,43.726536],[-85.088815,43.79999]]]},"properties":{"id":"49305","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.124201,43.386618],[-84.96488,43.328837],[-84.965497,43.466135],[-85.114643,43.466355],[-85.124201,43.386618]],[[-85.045271,43.357502],[-85.039086,43.346595],[-85.055721,43.348526],[-85.045271,43.357502]]]},"properties":{"id":"48829","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.537329,42.247485],[-84.520235,42.179125],[-84.451434,42.123786],[-84.309682,42.13161],[-84.220516,42.154691],[-84.229319,42.158001],[-84.239816,42.15529],[-84.248391,42.15909],[-84.248552,42.167974],[-84.238047,42.167538],[-84.239106,42.160949],[-84.220672,42.160495],[-84.306186,42.331228],[-84.323411,42.381684],[-84.36479,42.363772],[-84.531816,42.363766],[-84.537329,42.247485]],[[-84.476065,42.254888],[-84.338581,42.255257],[-84.315058,42.250089],[-84.331614,42.218504],[-84.476065,42.254888]]]},"properties":{"id":"49201","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.958936,42.224761],[-84.07401,42.250072],[-84.153185,42.171668],[-84.132203,42.082968],[-84.06648,42.072607],[-83.914926,42.109316],[-83.913459,42.184368],[-83.958936,42.224761]]]},"properties":{"id":"48158","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.604611,42.070244],[-85.58651,42.157414],[-85.600649,42.157402],[-85.648099,42.194355],[-85.734984,42.186446],[-85.764605,42.157254],[-85.773229,42.101898],[-85.658832,42.070201],[-85.604611,42.070244]]]},"properties":{"id":"49087","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.589566,42.284113],[-85.587562,42.245118],[-85.530877,42.244931],[-85.571924,42.294786],[-85.589566,42.284113]]]},"properties":{"id":"49001","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.521482,42.178635],[-85.353682,42.149895],[-85.336733,42.172692],[-85.432755,42.223824],[-85.530612,42.192118],[-85.521482,42.178635]]]},"properties":{"id":"49088","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.633098,42.919892],[-85.649254,42.948922],[-85.680945,42.920528],[-85.650988,42.912813],[-85.633098,42.919892]]]},"properties":{"id":"49507","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.608156,42.912637],[-85.590703,42.96981],[-85.600559,42.971505],[-85.649254,42.948922],[-85.633098,42.919892],[-85.608156,42.912637]]]},"properties":{"id":"49506","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.463814,42.878322],[-85.608156,42.912637],[-85.590299,42.854556],[-85.503329,42.855271],[-85.467794,42.875127],[-85.463814,42.878322]]]},"properties":{"id":"49512","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.735253,43.008032],[-85.806385,43.030845],[-85.876749,42.974176],[-85.876107,42.95792],[-85.782177,42.911323],[-85.735161,42.933483],[-85.716611,42.942207],[-85.711008,42.947432],[-85.735253,43.008032]]]},"properties":{"id":"49534","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.680945,42.920528],[-85.701507,42.941947],[-85.703292,42.855282],[-85.678722,42.849086],[-85.680945,42.920528]]]},"properties":{"id":"49509","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.680389,43.959318],[-85.681595,43.826975],[-85.737383,43.814969],[-85.681766,43.780893],[-85.494306,43.81551],[-85.392245,43.916257],[-85.325661,43.971738],[-85.602413,43.988439],[-85.680389,43.959318]]]},"properties":{"id":"49677","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.863915,43.221638],[-82.871107,43.322514],[-82.885309,43.322044],[-83.119027,43.28368],[-83.138461,43.26863],[-83.095604,43.166842],[-82.97315,43.119648],[-82.877221,43.157212],[-82.863915,43.221638]]]},"properties":{"id":"48416","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.552528,45.040459],[-85.692925,45.081582],[-85.700137,45.054626],[-85.704282,45.040123],[-85.617725,45.044081],[-85.599516,45.055064],[-85.596085,45.040681],[-85.552528,45.040459]]]},"properties":{"id":"49670","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.700137,45.054626],[-85.692925,45.081582],[-85.778616,44.983252],[-85.700137,45.054626]]]},"properties":{"id":"49654","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.905709,41.920519],[-83.991209,41.825037],[-83.981478,41.825335],[-83.866989,41.906584],[-83.867377,41.921083],[-83.905709,41.920519]]]},"properties":{"id":"49268","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.060419,41.800138],[-84.071305,41.715001],[-83.931611,41.718879],[-83.981478,41.825335],[-83.991209,41.825037],[-84.060419,41.800138]]]},"properties":{"id":"49248","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.960489,42.759452],[-83.950963,42.763619],[-83.951511,42.770617],[-83.961285,42.770346],[-83.960489,42.759452]]]},"properties":{"id":"48816","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.56926,45.823311],[-84.594315,45.810291],[-84.42465,45.715669],[-84.349811,45.775021],[-84.56926,45.823311]]]},"properties":{"id":"49775","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.03569,42.536492],[-83.037546,42.498636],[-83.029359,42.491751],[-83.016183,42.491717],[-82.995525,42.492139],[-82.996804,42.537644],[-83.03569,42.536492]]]},"properties":{"id":"48093","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.97508,42.671216],[-82.888912,42.628969],[-82.856131,42.630234],[-82.859339,42.718133],[-82.976908,42.715905],[-82.97508,42.671216]]]},"properties":{"id":"48042","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.911105,42.450769],[-82.861652,42.46204],[-82.868274,42.475532],[-82.917496,42.480437],[-82.928579,42.45062],[-82.911105,42.450769]]]},"properties":{"id":"48080","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.099432,42.80055],[-83.097629,42.763296],[-83.097002,42.755283],[-82.979688,42.760227],[-82.980047,42.803332],[-83.099432,42.80055]]]},"properties":{"id":"48095","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.214589,42.723799],[-83.21378,42.709529],[-83.212757,42.69376],[-83.154023,42.695249],[-83.094793,42.696985],[-83.095339,42.71249],[-83.097002,42.755283],[-83.097629,42.763296],[-83.21524,42.738863],[-83.214589,42.723799]]]},"properties":{"id":"48306","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.704115,46.711026],[-87.615101,46.333617],[-87.492492,46.381608],[-87.601697,46.432116],[-87.503797,46.459479],[-87.704115,46.711026]]]},"properties":{"id":"49866","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.323601,43.7708],[-85.392245,43.916257],[-85.494306,43.81551],[-85.463028,43.790245],[-85.323601,43.7708]]]},"properties":{"id":"49639","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.801771,45.512294],[-87.382098,45.551025],[-87.634919,45.637134],[-87.694085,45.626446],[-87.777494,45.593835],[-87.801771,45.512294]]]},"properties":{"id":"49821","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.336494,43.655413],[-84.358865,43.742393],[-84.388205,43.799228],[-84.45398,43.814335],[-84.488344,43.6405],[-84.488486,43.625765],[-84.336494,43.655413]]]},"properties":{"id":"48657","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.156996,44.51115],[-85.337092,44.339254],[-85.295509,44.260372],[-85.114587,44.287853],[-85.014769,44.314807],[-84.851874,44.493879],[-84.850929,44.511069],[-85.058212,44.510491],[-85.156996,44.51115]]]},"properties":{"id":"49651","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.454441,42.003754],[-83.538854,42.064701],[-83.540979,42.057657],[-83.668838,42.010221],[-83.575984,42.004357],[-83.454441,42.003754]]]},"properties":{"id":"48159","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.644574,41.77776],[-83.645731,41.727476],[-83.605408,41.728811],[-83.644574,41.77776]]]},"properties":{"id":"48144","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.598645,41.925336],[-83.624094,41.836388],[-83.532591,41.852439],[-83.51275,41.86765],[-83.593996,41.939992],[-83.598645,41.925336]]]},"properties":{"id":"48140","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.925174,43.314199],[-84.96455,43.264465],[-84.935028,43.243381],[-84.835887,43.234717],[-84.845272,43.321246],[-84.895349,43.319219],[-84.925174,43.314199]]]},"properties":{"id":"48818","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.045271,43.357502],[-85.055721,43.348526],[-85.039086,43.346595],[-85.045271,43.357502]]]},"properties":{"id":"48852","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.215255,43.13239],[-86.296904,43.118399],[-86.270145,43.054683],[-86.130618,43.041452],[-86.129346,43.102936],[-86.19584,43.132293],[-86.215255,43.13239]]]},"properties":{"id":"49456","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.039243,43.68105],[-86.038369,43.794162],[-86.158207,43.816127],[-86.18837,43.816583],[-86.158048,43.642169],[-86.039243,43.68105]]]},"properties":{"id":"49459","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.12502,42.50433],[-83.110736,42.476018],[-83.084523,42.476345],[-83.085122,42.49076],[-83.086882,42.534792],[-83.126375,42.533805],[-83.12502,42.50433]]]},"properties":{"id":"48071","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.43427,42.453484],[-83.43552,42.480553],[-83.495937,42.489353],[-83.494098,42.45157],[-83.433672,42.438942],[-83.43427,42.453484]]]},"properties":{"id":"48375","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.172176,42.503545],[-83.158629,42.489485],[-83.153503,42.474169],[-83.13374,42.47542],[-83.113975,42.47593],[-83.110736,42.476018],[-83.12502,42.50433],[-83.172176,42.503545]]]},"properties":{"id":"48067","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.262253,42.508798],[-83.319925,42.507373],[-83.318878,42.485548],[-83.261352,42.486996],[-83.262253,42.508798]]]},"properties":{"id":"48034","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.261208,42.483461],[-83.261352,42.486996],[-83.318878,42.485548],[-83.316825,42.442033],[-83.287803,42.442683],[-83.258867,42.443403],[-83.261208,42.483461]]]},"properties":{"id":"48033","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.261208,42.483461],[-83.258867,42.443403],[-83.219806,42.444346],[-83.200522,42.444839],[-83.202198,42.481107],[-83.261208,42.483461]]]},"properties":{"id":"48075","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.150492,42.622542],[-83.148333,42.577302],[-83.089366,42.57845],[-83.0899,42.593009],[-83.091449,42.624208],[-83.150492,42.622542]]]},"properties":{"id":"48085","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.439055,42.555305],[-83.437586,42.527102],[-83.379737,42.527851],[-83.323466,42.529323],[-83.321753,42.551297],[-83.439055,42.555305]]]},"properties":{"id":"48322","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.13374,42.47542],[-83.153503,42.474169],[-83.155626,42.474122],[-83.154133,42.467596],[-83.13374,42.47542]]]},"properties":{"id":"48069","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.278507,43.556521],[-86.496448,43.542597],[-86.36875,43.522439],[-86.278507,43.556521]]]},"properties":{"id":"49446","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.370419,44.492875],[-84.368631,44.247072],[-84.305777,44.176221],[-84.068265,44.224354],[-84.046213,44.28754],[-84.063279,44.334683],[-84.24976,44.507257],[-84.370643,44.507223],[-84.370419,44.492875]]]},"properties":{"id":"48661","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.539836,46.633603],[-89.408942,46.652906],[-89.313477,46.665263],[-89.188255,46.772777],[-89.046512,46.808027],[-88.995663,46.894478],[-89.01479,46.993987],[-89.887721,46.765825],[-89.86348,46.711438],[-89.539836,46.633603]]]},"properties":{"id":"49953","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.046512,46.808027],[-89.188255,46.772777],[-89.114718,46.75758],[-89.046512,46.808027]]]},"properties":{"id":"49929","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.429273,45.112658],[-84.781601,45.11599],[-84.848443,44.924679],[-84.847309,44.858037],[-84.542422,44.887812],[-84.527074,44.891596],[-84.429273,45.112658]]]},"properties":{"id":"49735","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.249057,45.494825],[-84.027067,45.219887],[-83.916298,45.324808],[-84.070675,45.493495],[-84.204029,45.630737],[-84.249057,45.494825]]]},"properties":{"id":"49759","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.679972,44.24788],[-84.6087,44.160484],[-84.551624,44.247615],[-84.544856,44.334682],[-84.612446,44.334433],[-84.679972,44.24788]]]},"properties":{"id":"48651","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.855795,44.421984],[-84.853001,44.298028],[-84.852362,44.228523],[-84.679972,44.24788],[-84.612446,44.334433],[-84.855795,44.421984]],[[-84.775432,44.324955],[-84.770308,44.32607],[-84.770045,44.320534],[-84.774255,44.322189],[-84.775432,44.324955]]]},"properties":{"id":"48629","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.147762,43.434968],[-84.162357,43.354838],[-84.051585,43.307493],[-83.967026,43.386674],[-83.994904,43.392334],[-84.046927,43.437244],[-84.079214,43.451837],[-84.147762,43.434968]]]},"properties":{"id":"48609","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.691566,43.296145],[-83.679416,43.396145],[-83.738033,43.407187],[-83.8175,43.379642],[-83.8155,43.309625],[-83.691566,43.296145]]]},"properties":{"id":"48734","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.051811,43.488745],[-84.079214,43.451837],[-84.046927,43.437244],[-83.994996,43.436656],[-83.961848,43.447205],[-84.051811,43.488745]]]},"properties":{"id":"48603","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.637218,43.077224],[-82.662936,43.163414],[-82.755317,43.073264],[-82.75018,42.986567],[-82.745232,42.986686],[-82.63141,42.990869],[-82.637218,43.077224]]]},"properties":{"id":"48006","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.662936,43.163414],[-82.637218,43.077224],[-82.538658,43.081004],[-82.524975,43.168226],[-82.545111,43.17421],[-82.644985,43.192957],[-82.662936,43.163414]]]},"properties":{"id":"48032","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.501219,42.935364],[-82.499643,42.90561],[-82.469768,42.877459],[-82.447719,42.937153],[-82.501219,42.935364]]]},"properties":{"id":"48040","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.566203,43.500032],[-82.536357,43.401865],[-82.561566,43.500031],[-82.566203,43.500032]]]},"properties":{"id":"48469","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.619697,43.658967],[-82.817934,43.637957],[-82.816586,43.597721],[-82.596891,43.603646],[-82.59733,43.639538],[-82.604643,43.65224],[-82.619697,43.658967]]]},"properties":{"id":"48465","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.220944,42.86354],[-84.074844,42.806884],[-84.070263,42.806952],[-84.162432,42.894124],[-84.220944,42.86354]]]},"properties":{"id":"48857","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.022546,42.933551],[-84.022185,42.939327],[-84.033168,42.937189],[-84.022546,42.933551]]]},"properties":{"id":"48476","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.917207,42.282035],[-83.978456,42.390752],[-84.097842,42.405378],[-84.131204,42.411014],[-84.07401,42.250072],[-83.958936,42.224761],[-83.917207,42.282035]]]},"properties":{"id":"48118","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.100588,42.388814],[-83.106734,42.39505],[-83.130146,42.383767],[-83.109068,42.35747],[-83.086601,42.36539],[-83.100588,42.388814]]]},"properties":{"id":"48206","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.31218,42.340636],[-83.374081,42.310166],[-83.339928,42.310986],[-83.310867,42.311709],[-83.31218,42.340636]]]},"properties":{"id":"48135","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.430038,42.352465],[-83.312825,42.35518],[-83.314226,42.384131],[-83.431423,42.385895],[-83.430038,42.352465]]]},"properties":{"id":"48150","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.432315,42.40802],[-83.315528,42.411479],[-83.316825,42.442033],[-83.375469,42.440557],[-83.414357,42.439523],[-83.433672,42.438942],[-83.433085,42.424934],[-83.432315,42.40802]]]},"properties":{"id":"48152","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.376343,42.194649],[-83.32591,42.195523],[-83.327852,42.240048],[-83.385085,42.208756],[-83.376343,42.194649]]]},"properties":{"id":"48242","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.122085,42.410279],[-83.169768,42.409535],[-83.167915,42.380504],[-83.130146,42.383767],[-83.106734,42.39505],[-83.122085,42.410279]]]},"properties":{"id":"48238","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.18364,42.295259],[-83.184696,42.270641],[-83.169907,42.264577],[-83.167074,42.289571],[-83.179395,42.294785],[-83.18364,42.295259]]]},"properties":{"id":"48122","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.007246,44.508861],[-83.924316,44.440953],[-83.884644,44.441695],[-83.862486,44.451417],[-83.764857,44.454386],[-83.828938,44.595402],[-83.887464,44.627719],[-84.007246,44.508861]]]},"properties":{"id":"48761","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.404637,44.815076],[-83.335927,44.758146],[-83.284555,44.825321],[-83.315593,44.858605],[-83.358198,44.858593],[-83.404637,44.815076]]]},"properties":{"id":"48721","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.620468,44.770313],[-83.526104,44.874051],[-83.546487,44.902809],[-83.628956,44.956592],[-83.696147,44.923343],[-83.746103,44.856957],[-83.750444,44.769917],[-83.620468,44.770313]]]},"properties":{"id":"49747","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.844796,46.249093],[-86.545917,46.361662],[-86.500165,46.552519],[-86.766122,46.51087],[-86.86064,46.34583],[-86.844796,46.249093]]]},"properties":{"id":"49862","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.701781,42.546749],[-85.779201,42.411211],[-85.76448,42.400966],[-85.689091,42.404416],[-85.661482,42.486198],[-85.701781,42.546749]]]},"properties":{"id":"49078","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.015722,42.622994],[-85.898735,42.63736],[-85.839635,42.681077],[-85.887352,42.74371],[-85.920837,42.760334],[-86.135018,42.681583],[-86.134224,42.645459],[-86.015722,42.622994]]]},"properties":{"id":"49419","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.991729,42.521162],[-86.183124,42.490816],[-86.143615,42.458789],[-86.012439,42.466056],[-85.991729,42.521162]]]},"properties":{"id":"49450","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.804476,42.882169],[-85.903404,42.942323],[-85.97258,42.943019],[-85.874501,42.767508],[-85.841196,42.764172],[-85.793381,42.840695],[-85.804476,42.882169]]]},"properties":{"id":"49426","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.174367,42.637234],[-86.223691,42.650404],[-86.228162,42.616895],[-86.174367,42.637234]]]},"properties":{"id":"49406","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.671004,42.637969],[-85.721524,42.681434],[-85.839635,42.681077],[-85.898735,42.63736],[-85.701958,42.556459],[-85.662809,42.594305],[-85.671004,42.637969]]]},"properties":{"id":"49328","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.565294,42.73215],[-85.526711,42.599399],[-85.516173,42.594941],[-85.426888,42.60963],[-85.358706,42.735531],[-85.388265,42.769252],[-85.468789,42.78312],[-85.565294,42.73215]]]},"properties":{"id":"49333","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.057049,42.914117],[-86.036746,42.783428],[-85.920837,42.760334],[-85.887352,42.74371],[-85.874501,42.767508],[-85.97258,42.943019],[-85.993815,42.97205],[-86.057049,42.914117]]]},"properties":{"id":"49464","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.135291,45.198743],[-83.999893,44.893378],[-83.921404,44.856612],[-83.888484,44.856458],[-83.841255,45.203261],[-84.013038,45.198621],[-84.135291,45.198743]]]},"properties":{"id":"49746","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.29946,44.881072],[-85.173349,44.815717],[-85.198578,44.886125],[-85.302353,44.946161],[-85.29946,44.881072]]]},"properties":{"id":"49612","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.173349,44.815717],[-85.29946,44.881072],[-85.372932,44.925166],[-85.374857,44.89506],[-85.248379,44.763731],[-85.161758,44.805628],[-85.173349,44.815717]]]},"properties":{"id":"49676","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.098846,45.261956],[-84.94479,45.074166],[-84.918116,45.117216],[-84.919693,45.248039],[-84.975397,45.27185],[-85.096789,45.328189],[-85.098846,45.261956]]]},"properties":{"id":"49712","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.40511,45.194701],[-85.222705,45.205551],[-85.098846,45.261956],[-85.096789,45.328189],[-85.096116,45.377835],[-85.40511,45.194701]]]},"properties":{"id":"49720","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.166339,44.00843],[-84.060171,44.161992],[-84.068265,44.224354],[-84.305777,44.176221],[-84.201154,44.028337],[-84.166339,44.00843]]]},"properties":{"id":"48610","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.060171,44.161992],[-84.166339,44.00843],[-84.094486,43.984063],[-83.957228,44.033384],[-83.903007,44.083959],[-83.958872,44.161893],[-84.060171,44.161992]]]},"properties":{"id":"48659","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.901645,43.914694],[-84.04488,43.914819],[-84.124906,43.869248],[-84.135723,43.812805],[-83.929388,43.73773],[-83.901645,43.914694]]]},"properties":{"id":"48650","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.291764,42.369898],[-85.185445,42.31187],[-85.182213,42.311845],[-85.091809,42.41432],[-85.219322,42.449667],[-85.253956,42.468629],[-85.314511,42.429492],[-85.308409,42.419832],[-85.291764,42.369898]]]},"properties":{"id":"49017","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.081632,44.778431],[-85.950111,44.747295],[-85.89637,44.777179],[-85.947001,44.847007],[-86.085888,44.863011],[-86.081632,44.778431]]]},"properties":{"id":"49630","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.22294,42.071484],[-86.349322,42.072272],[-86.339542,41.976719],[-86.306328,41.964212],[-86.222851,41.981752],[-86.22294,42.071484]]]},"properties":{"id":"49111","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.465638,41.997646],[-86.518931,41.971883],[-86.513832,41.899309],[-86.431799,41.921385],[-86.465638,41.997646]]]},"properties":{"id":"49101","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.359149,41.966838],[-86.360104,41.960984],[-86.353567,41.962631],[-86.359149,41.966838]]]},"properties":{"id":"49104","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.214263,41.927589],[-86.146347,41.888769],[-85.973556,42.012502],[-86.14685,42.090885],[-86.202994,42.09311],[-86.22294,42.071484],[-86.222851,41.981752],[-86.214263,41.927589]]]},"properties":{"id":"49047","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.954244,42.47726],[-84.966773,42.390774],[-84.829037,42.363404],[-84.790732,42.392558],[-84.797497,42.412646],[-84.954244,42.47726]]]},"properties":{"id":"49076","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.148263,42.174093],[-85.042573,42.28115],[-85.01501,42.373793],[-85.091809,42.41432],[-85.182213,42.311845],[-85.217805,42.208177],[-85.172733,42.173947],[-85.148263,42.174093]]]},"properties":{"id":"49014","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.096789,45.328189],[-84.975397,45.27185],[-84.929009,45.27499],[-84.732687,45.289382],[-84.731546,45.332835],[-84.853073,45.419076],[-84.872205,45.413484],[-84.927213,45.41374],[-85.096116,45.377835],[-85.096789,45.328189]]]},"properties":{"id":"49770","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.873246,45.536307],[-84.863305,45.433284],[-84.853073,45.419076],[-84.731546,45.332835],[-84.691692,45.332143],[-84.666288,45.404518],[-84.678906,45.418903],[-84.711054,45.458247],[-84.730346,45.471686],[-84.833212,45.532721],[-84.873246,45.536307]],[[-84.825402,45.423308],[-84.828162,45.424501],[-84.828174,45.426566],[-84.82523,45.426521],[-84.825402,45.423308]]]},"properties":{"id":"49706","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.701471,43.966677],[-84.606468,43.903163],[-84.266012,43.941652],[-84.201154,44.028337],[-84.305777,44.176221],[-84.368631,44.247072],[-84.551624,44.247615],[-84.6087,44.160484],[-84.644933,44.160646],[-84.701471,43.966677]]]},"properties":{"id":"48624","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.729463,42.882922],[-84.778905,42.884735],[-84.837095,42.800593],[-84.681202,42.827778],[-84.729463,42.882922]]]},"properties":{"id":"48822","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.542422,44.887812],[-84.848946,44.728471],[-84.961001,44.641727],[-84.910566,44.629875],[-84.831698,44.511076],[-84.371019,44.610854],[-84.371684,44.688045],[-84.430833,44.856031],[-84.527074,44.891596],[-84.542422,44.887812]]]},"properties":{"id":"49738","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.430833,44.856031],[-84.371684,44.688045],[-84.292134,44.676541],[-84.131107,44.76847],[-84.153357,44.85484],[-84.354104,44.948319],[-84.430833,44.856031]]]},"properties":{"id":"49756","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.078656,45.794138],[-87.264596,45.859114],[-87.315553,45.85416],[-87.085863,45.685101],[-87.047347,45.775253],[-87.056313,45.775316],[-87.083334,45.775482],[-87.078656,45.794138]]]},"properties":{"id":"49829","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-87.615969,46.185101],[-87.61629,46.043662],[-87.575239,46.051224],[-87.463383,46.145484],[-87.615969,46.185101]]],[[[-88.009868,46.048389],[-87.950001,45.980084],[-87.829494,45.970931],[-87.74192,46.003517],[-87.741683,46.048842],[-87.816303,46.08886],[-87.899715,46.082921],[-88.009868,46.048389]]]]},"properties":{"id":"49831","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.872205,45.413484],[-84.853073,45.419076],[-84.863305,45.433284],[-84.872205,45.413484]]]},"properties":{"id":"49722","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.909843,42.919912],[-83.79199,42.869836],[-83.775169,42.877122],[-83.726139,42.91473],[-83.786547,42.978699],[-83.790295,42.972502],[-83.795111,42.972452],[-83.794573,42.978591],[-83.78357,42.98732],[-83.86121,43.015331],[-83.894525,43.010079],[-83.929825,42.949981],[-83.909843,42.919912]]]},"properties":{"id":"48473","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.574012,42.969353],[-83.519098,42.950825],[-83.456781,42.963137],[-83.459014,43.092589],[-83.47042,43.132767],[-83.570907,43.10015],[-83.595511,43.047928],[-83.575318,43.009017],[-83.574012,42.969353]]]},"properties":{"id":"48423","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.287679,43.842214],[-84.388205,43.799228],[-84.358865,43.742393],[-84.288196,43.827635],[-84.287679,43.842214]]]},"properties":{"id":"48628","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.392315,43.248603],[-84.619797,43.204568],[-84.598852,43.139322],[-84.513381,43.117757],[-84.503571,43.117663],[-84.36859,43.205031],[-84.392315,43.248603]],[[-84.600663,43.187047],[-84.583022,43.190469],[-84.583078,43.183215],[-84.600663,43.187047]]]},"properties":{"id":"48806","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.766351,43.350111],[-84.746672,43.466234],[-84.819614,43.466231],[-84.786124,43.350188],[-84.766351,43.350111]]]},"properties":{"id":"48832","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.495378,42.048457],[-84.535674,41.985612],[-84.42072,41.982325],[-84.377004,42.015215],[-84.39159,42.015185],[-84.415182,42.02241],[-84.410093,42.068896],[-84.41717,42.091177],[-84.445986,42.072999],[-84.495378,42.048457]]]},"properties":{"id":"49249","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.304383,41.928132],[-84.323854,41.942742],[-84.381745,41.938631],[-84.380436,41.767641],[-84.338882,41.751027],[-84.234277,41.798624],[-84.304383,41.928132]]]},"properties":{"id":"49247","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.053777,46.525684],[-89.080952,46.332706],[-88.990813,46.295154],[-88.781554,46.420081],[-88.727002,46.592974],[-88.864474,46.679245],[-89.053777,46.525684]]]},"properties":{"id":"49967","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.615743,47.068847],[-88.62865,47.230137],[-88.6305,47.233138],[-88.933303,47.031256],[-88.700056,47.06696],[-88.615743,47.068847]]]},"properties":{"id":"49905","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.637004,47.033081],[-88.717883,47.03128],[-88.742565,46.981997],[-88.637004,47.033081]]]},"properties":{"id":"49955","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.604675,43.661222],[-83.457357,43.581254],[-83.332443,43.590449],[-83.313208,43.612458],[-83.35399,43.669722],[-83.467403,43.728909],[-83.604675,43.661222]]]},"properties":{"id":"48767","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.106409,42.546206],[-84.142976,42.567884],[-84.117203,42.534207],[-84.106409,42.546206]]],[[[-84.18367,42.735989],[-84.247447,42.600906],[-84.221348,42.552076],[-84.142998,42.568192],[-84.121834,42.765199],[-84.18367,42.735989]]]]},"properties":{"id":"48892","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.089789,42.879129],[-84.995224,42.914784],[-84.946009,42.995702],[-84.962016,43.09065],[-85.057529,43.065539],[-85.144885,43.05754],[-85.168434,43.001941],[-85.114475,42.879411],[-85.089789,42.879129]]]},"properties":{"id":"48846","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.057529,43.065539],[-85.154952,43.119704],[-85.144885,43.05754],[-85.057529,43.065539]]]},"properties":{"id":"48865","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.204912,42.866372],[-85.26751,42.88605],[-85.311388,42.856955],[-85.3204,42.813207],[-85.27154,42.798712],[-85.204912,42.866372]]]},"properties":{"id":"48815","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.582105,44.412345],[-83.578748,44.389768],[-83.49997,44.26959],[-83.326682,44.337402],[-83.582105,44.412345]]]},"properties":{"id":"48730","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.537329,42.247485],[-84.612717,42.218318],[-84.569286,42.184774],[-84.520235,42.179125],[-84.537329,42.247485]]]},"properties":{"id":"49283","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.220672,42.160495],[-84.239106,42.160949],[-84.238047,42.167538],[-84.248552,42.167974],[-84.248391,42.15909],[-84.239816,42.15529],[-84.229319,42.158001],[-84.220516,42.154691],[-84.220672,42.160495]]]},"properties":{"id":"49261","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.701507,42.941947],[-85.680945,42.920528],[-85.649254,42.948922],[-85.600559,42.971505],[-85.673238,42.984729],[-85.711008,42.947432],[-85.716611,42.942207],[-85.701507,42.941947]]]},"properties":{"id":"49503","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.735253,43.008032],[-85.711008,42.947432],[-85.673238,42.984729],[-85.672757,42.999138],[-85.735253,43.008032]]]},"properties":{"id":"49504","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.311388,42.856955],[-85.429662,42.878518],[-85.467794,42.875127],[-85.503329,42.855271],[-85.468789,42.78312],[-85.388265,42.769252],[-85.3204,42.813207],[-85.311388,42.856955]]]},"properties":{"id":"49302","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.703292,42.855282],[-85.701507,42.941947],[-85.716611,42.942207],[-85.735161,42.933483],[-85.722979,42.855359],[-85.703292,42.855282]]]},"properties":{"id":"49519","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.359217,43.261117],[-85.354576,43.198279],[-85.293639,43.308377],[-85.351001,43.294376],[-85.359217,43.261117]]]},"properties":{"id":"49326","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.298992,47.286097],[-87.964081,47.333714],[-87.920021,47.484125],[-88.24079,47.431976],[-88.374392,47.318004],[-88.387415,47.290649],[-88.389135,47.285957],[-88.320807,47.28621],[-88.298992,47.286097]]]},"properties":{"id":"49950","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.921721,44.019198],[-86.037884,43.815611],[-85.74201,43.829464],[-85.762063,43.925603],[-85.772037,43.944325],[-85.842898,44.056276],[-85.921721,44.019198]]]},"properties":{"id":"49304","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.461727,43.328607],[-83.393219,43.292277],[-83.304002,43.268036],[-83.284251,43.381687],[-83.269023,43.411208],[-83.433007,43.393804],[-83.461727,43.328607]]]},"properties":{"id":"48744","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.459014,43.092589],[-83.456781,42.963137],[-83.42818,42.948956],[-83.240999,43.009517],[-83.215584,43.112206],[-83.287899,43.158022],[-83.459014,43.092589]]]},"properties":{"id":"48446","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.42302,42.953766],[-83.406682,42.944919],[-83.403763,42.957696],[-83.42302,42.953766]]]},"properties":{"id":"48440","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.693492,44.923511],[-85.591448,44.874964],[-85.552528,45.040459],[-85.596085,45.040681],[-85.617725,45.044081],[-85.704282,45.040123],[-85.693492,44.923511]]]},"properties":{"id":"49682","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.693492,44.923511],[-85.704282,45.040123],[-85.700137,45.054626],[-85.778616,44.983252],[-85.811606,44.947828],[-85.715868,44.914241],[-85.693492,44.923511]]]},"properties":{"id":"49653","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.815217,42.380366],[-83.702918,42.380589],[-83.747594,42.452116],[-83.793586,42.452311],[-83.793143,42.445278],[-83.821474,42.453438],[-83.802714,42.45723],[-83.824906,42.460328],[-83.885684,42.425761],[-83.815217,42.380366]]]},"properties":{"id":"48189","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.987409,42.424024],[-84.042393,42.500118],[-84.071753,42.553718],[-84.106409,42.546206],[-84.117203,42.534207],[-84.097842,42.405378],[-83.978456,42.390752],[-83.987409,42.424024]]]},"properties":{"id":"48137","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.967145,42.479526],[-82.971041,42.449665],[-82.941283,42.45023],[-82.928579,42.45062],[-82.917496,42.480437],[-82.967145,42.479526]]]},"properties":{"id":"48021","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.863782,42.570027],[-82.97086,42.566443],[-82.970227,42.553063],[-82.929639,42.539331],[-82.904298,42.539784],[-82.865107,42.540731],[-82.863782,42.570027]]]},"properties":{"id":"48035","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.973317,42.627811],[-82.88913,42.628958],[-82.888912,42.628969],[-82.97508,42.671216],[-82.973317,42.627811]]]},"properties":{"id":"48044","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.095339,42.71249],[-83.025995,42.713902],[-82.976908,42.715905],[-82.979688,42.760227],[-83.097002,42.755283],[-83.095339,42.71249]]]},"properties":{"id":"48094","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.02694,42.468993],[-83.024745,42.448159],[-83.005266,42.448816],[-82.971041,42.449665],[-82.967145,42.479526],[-82.968063,42.499014],[-82.995525,42.492139],[-83.016183,42.491717],[-83.02694,42.468993]]]},"properties":{"id":"48089","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.983647,42.893741],[-83.102891,42.888647],[-83.099432,42.80055],[-82.980047,42.803332],[-82.980129,42.804932],[-82.983647,42.893741]]]},"properties":{"id":"48065","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.861313,42.762456],[-82.731992,42.765333],[-82.733267,42.790826],[-82.863055,42.806666],[-82.861313,42.762456]]]},"properties":{"id":"48050","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.016183,42.491717],[-83.029359,42.491751],[-83.037592,42.49146],[-83.03822,42.491358],[-83.02694,42.468993],[-83.016183,42.491717]]]},"properties":{"id":"48015","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.865107,42.540731],[-82.904298,42.539784],[-82.903369,42.510299],[-82.871013,42.509486],[-82.85514,42.540918],[-82.865107,42.540731]]]},"properties":{"id":"48082","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.820859,44.352478],[-85.777747,44.489804],[-85.797374,44.512931],[-85.990625,44.45566],[-86.000757,44.40808],[-85.940484,44.339174],[-85.849319,44.338242],[-85.820859,44.352478]]]},"properties":{"id":"49625","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.990625,44.45566],[-86.080564,44.515102],[-86.105066,44.515722],[-86.140719,44.516701],[-86.247976,44.427808],[-86.176671,44.345459],[-86.162724,44.345106],[-86.000757,44.40808],[-85.990625,44.45566]]]},"properties":{"id":"49614","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.748979,46.728769],[-87.505163,46.694308],[-88.04453,46.91597],[-88.044709,46.765353],[-87.991289,46.71453],[-87.865152,46.723199],[-87.748979,46.728769]]]},"properties":{"id":"49808","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.114486,43.94609],[-86.118664,43.953347],[-86.123791,43.913634],[-86.078828,43.903004],[-86.114486,43.94609]]]},"properties":{"id":"49458","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.38434,43.630283],[-85.324224,43.619287],[-85.224525,43.722832],[-85.236318,43.741605],[-85.264511,43.7521],[-85.288343,43.751488],[-85.323601,43.7708],[-85.38434,43.630283]]]},"properties":{"id":"49342","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.453712,45.704012],[-87.461036,45.704291],[-87.382901,45.766011],[-87.448805,45.773974],[-87.528787,45.779783],[-87.573058,45.718654],[-87.573563,45.636913],[-87.449329,45.647539],[-87.453712,45.704012]]]},"properties":{"id":"49874","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.634919,45.637134],[-87.382098,45.551025],[-87.327535,45.567181],[-87.326819,45.602045],[-87.449329,45.647539],[-87.573563,45.636913],[-87.634919,45.637134]],[[-87.555875,45.611295],[-87.548743,45.623283],[-87.540681,45.623182],[-87.551799,45.606212],[-87.555875,45.611295]]]},"properties":{"id":"49812","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-87.326819,45.602045],[-87.382901,45.766011],[-87.461036,45.704291],[-87.453712,45.704012],[-87.449329,45.647539],[-87.326819,45.602045]]]},"properties":{"id":"49896","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.238683,43.601299],[-84.235615,43.597231],[-84.227467,43.601864],[-84.235448,43.607509],[-84.238683,43.601299]]]},"properties":{"id":"48667","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.162357,43.354838],[-84.147762,43.434968],[-84.229615,43.510685],[-84.24939,43.513806],[-84.290783,43.508598],[-84.25853,43.347752],[-84.162357,43.354838]]]},"properties":{"id":"48626","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.526217,41.830872],[-83.556318,41.729962],[-83.442813,41.800869],[-83.43475,41.825043],[-83.526217,41.830872]]]},"properties":{"id":"48133","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.322931,43.395328],[-85.422911,43.352283],[-85.412934,43.323454],[-85.273611,43.322672],[-85.263486,43.33708],[-85.322931,43.395328]]]},"properties":{"id":"49322","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.192753,43.224044],[-85.073583,43.248174],[-85.192081,43.272025],[-85.192753,43.224044]]]},"properties":{"id":"48885","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.244433,43.216018],[-86.106744,43.20572],[-86.067427,43.205857],[-86.027854,43.290652],[-86.203498,43.262981],[-86.253541,43.24868],[-86.244083,43.232396],[-86.244433,43.216018]]]},"properties":{"id":"49442","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.322019,42.558091],[-83.32392,42.594892],[-83.440597,42.585945],[-83.446267,42.577943],[-83.439055,42.555305],[-83.321753,42.551297],[-83.322019,42.558091]]]},"properties":{"id":"48323","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.150492,42.622542],[-83.209216,42.620995],[-83.207087,42.574069],[-83.148333,42.577302],[-83.150492,42.622542]]]},"properties":{"id":"48098","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.654534,42.520217],[-83.581252,42.509351],[-83.558559,42.560212],[-83.562062,42.610741],[-83.604264,42.609392],[-83.612982,42.609145],[-83.654534,42.520217]]]},"properties":{"id":"48381","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.158048,43.642169],[-86.18837,43.816583],[-86.243502,43.817481],[-86.258367,43.817456],[-86.429044,43.733005],[-86.39823,43.670299],[-86.158048,43.642169]]]},"properties":{"id":"49420","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.129248,44.565086],[-84.229167,44.550965],[-84.24976,44.507257],[-84.063279,44.334683],[-84.050603,44.508533],[-84.129248,44.565086]]]},"properties":{"id":"48654","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-85.993815,42.97205],[-86.000675,43.005722],[-86.026496,43.023906],[-86.237436,42.96263],[-86.229204,42.85637],[-86.057049,42.914117],[-85.993815,42.97205]]]},"properties":{"id":"49460","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.000675,43.005722],[-85.993815,42.97205],[-85.97258,42.943019],[-85.903404,42.942323],[-85.876107,42.95792],[-85.876749,42.974176],[-85.904947,43.003065],[-86.000675,43.005722]]]},"properties":{"id":"49401","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.070675,45.493495],[-83.916298,45.324808],[-83.739479,45.348151],[-83.683543,45.345799],[-83.571375,45.375014],[-84.070675,45.493495]]]},"properties":{"id":"49779","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.73901,42.897541],[-82.734166,42.809556],[-82.614924,42.813654],[-82.619681,42.902364],[-82.73901,42.897541]]]},"properties":{"id":"48063","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.162592,43.128768],[-84.250315,43.078859],[-84.323865,43.042023],[-84.300379,42.955814],[-84.265908,42.873872],[-84.220944,42.86354],[-84.162432,42.894124],[-84.123796,42.909384],[-84.1231,42.946009],[-84.050166,43.101418],[-84.069581,43.125692],[-84.133782,43.129501],[-84.162592,43.128768]]]},"properties":{"id":"48867","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.162432,42.894124],[-84.070263,42.806952],[-84.009866,42.840165],[-84.123796,42.909384],[-84.162432,42.894124]]]},"properties":{"id":"48414","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.699393,43.601152],[-83.464385,43.523272],[-83.457357,43.581254],[-83.604675,43.661222],[-83.69992,43.611782],[-83.699393,43.601152]]]},"properties":{"id":"48701","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.464385,43.523272],[-83.699393,43.601152],[-83.699484,43.565823],[-83.581726,43.479873],[-83.555153,43.480093],[-83.464385,43.523272]]]},"properties":{"id":"48733","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-86.202332,42.32256],[-86.205722,42.25774],[-86.127831,42.257064],[-85.985627,42.280043],[-85.976024,42.316226],[-86.013541,42.325523],[-86.129281,42.375486],[-86.202332,42.32256]]]},"properties":{"id":"49013","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.68339,42.245486],[-83.671194,42.259158],[-83.683662,42.254587],[-83.68339,42.245486]]],[[[-83.68339,42.245486],[-83.739241,42.244041],[-83.797284,42.213353],[-83.699055,42.199779],[-83.68339,42.245486]]]]},"properties":{"id":"48108","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.024745,42.448159],[-83.083393,42.447153],[-83.082544,42.42534],[-83.037812,42.404686],[-83.028254,42.397669],[-83.013717,42.419681],[-83.005266,42.448816],[-83.024745,42.448159]]]},"properties":{"id":"48234","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.111649,42.355732],[-83.147736,42.351916],[-83.147038,42.31963],[-83.098054,42.326684],[-83.096796,42.331271],[-83.111649,42.355732]]]},"properties":{"id":"48210","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.086601,42.36539],[-83.076514,42.358757],[-83.054241,42.360835],[-83.054365,42.361004],[-83.079728,42.396482],[-83.100588,42.388814],[-83.086601,42.36539]]]},"properties":{"id":"48202","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.169768,42.409535],[-83.171265,42.445545],[-83.200522,42.444839],[-83.219806,42.444346],[-83.218713,42.408328],[-83.218227,42.408342],[-83.169768,42.409535]]]},"properties":{"id":"48235","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.248913,42.327117],[-83.28638,42.312186],[-83.217724,42.277214],[-83.206197,42.298412],[-83.214887,42.30973],[-83.226514,42.31089],[-83.241632,42.328045],[-83.248913,42.327117]]]},"properties":{"id":"48124","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.266362,42.15256],[-83.244136,42.081064],[-83.179214,42.112658],[-83.162528,42.170743],[-83.26675,42.167309],[-83.266362,42.15256]]]},"properties":{"id":"48183","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.432536,42.405375],[-83.431423,42.385895],[-83.314226,42.384131],[-83.315306,42.406434],[-83.315528,42.411479],[-83.432315,42.40802],[-83.432536,42.405375]]]},"properties":{"id":"48154","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.315306,42.406434],[-83.285608,42.407026],[-83.287803,42.442683],[-83.316825,42.442033],[-83.315528,42.411479],[-83.315306,42.406434]]]},"properties":{"id":"48240","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.291108,42.31205],[-83.309311,42.275505],[-83.308999,42.26823],[-83.231911,42.269879],[-83.217724,42.277214],[-83.28638,42.312186],[-83.291108,42.31205]]]},"properties":{"id":"48125","state_code":"26"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.475322,46.946778],[-93.677372,46.856194],[-93.776912,46.771133],[-93.777415,46.716384],[-93.46474,46.637894],[-93.360199,46.85475],[-93.475322,46.946778]]]},"properties":{"id":"56469","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.054067,46.367115],[-92.678419,46.347048],[-92.64161,46.418207],[-92.887261,46.419072],[-93.055168,46.506888],[-93.107103,46.446665],[-93.054067,46.367115]]]},"properties":{"id":"55783","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.061043,46.788277],[-92.986241,46.593185],[-92.849076,46.766257],[-93.061043,46.788277]]]},"properties":{"id":"55798","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.282094,45.069493],[-93.227236,45.064982],[-93.227696,45.124533],[-93.235187,45.124463],[-93.266928,45.124603],[-93.296095,45.121897],[-93.280826,45.071665],[-93.282094,45.069493]]]},"properties":{"id":"55432","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.422823,45.211961],[-93.373136,45.178133],[-93.362176,45.197547],[-93.357414,45.212207],[-93.34728,45.302069],[-93.347618,45.370557],[-93.347477,45.377835],[-93.42704,45.384974],[-93.51219,45.245683],[-93.422823,45.211961]]]},"properties":{"id":"55303","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.922164,46.963775],[-96.047787,46.977446],[-96.065395,46.739551],[-96.03507,46.717764],[-95.922164,46.963775]]]},"properties":{"id":"56511","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.018911,47.035612],[-95.705657,46.985493],[-95.640437,47.034846],[-95.633709,47.07831],[-95.834843,47.096403],[-95.835372,47.078404],[-95.848349,47.078526],[-95.84859,47.093169],[-95.837477,47.096446],[-96.067069,47.151813],[-96.109711,47.151669],[-96.066428,47.06444],[-96.018911,47.035612]]]},"properties":{"id":"56569","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.047787,46.977446],[-95.922164,46.963775],[-95.818762,46.974247],[-95.794499,46.977281],[-95.705657,46.985493],[-96.018911,47.035612],[-96.047787,46.977446]]]},"properties":{"id":"56521","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.734168,47.950511],[-94.547968,47.874638],[-94.41776,48.019811],[-94.423557,48.07629],[-94.423146,48.105762],[-94.551713,48.114217],[-94.721342,48.061134],[-94.725227,47.960188],[-94.734168,47.950511]]]},"properties":{"id":"56650","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.906195,48.016659],[-94.41776,48.019811],[-94.547968,47.874638],[-94.160549,47.67264],[-94.073066,47.672813],[-94.033895,47.672872],[-93.776173,47.846404],[-93.748906,47.98522],[-93.742502,48.034446],[-93.768061,48.025139],[-93.898561,48.016707],[-93.906195,48.016659]]]},"properties":{"id":"56661","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.225091,45.937238],[-94.431089,45.858297],[-94.271776,45.775208],[-94.010432,45.823981],[-94.000914,45.861429],[-94.225091,45.937238]]]},"properties":{"id":"56373","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.559391,45.561276],[-96.575729,45.56166],[-96.575008,45.558203],[-96.559391,45.561276]]]},"properties":{"id":"56210","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.838617,44.008724],[-93.697499,43.949498],[-93.647252,43.97489],[-93.573078,44.166496],[-93.646696,44.195993],[-93.727587,44.174266],[-93.788208,44.163282],[-93.848654,44.109028],[-93.853479,44.08728],[-93.856942,44.076369],[-93.888405,44.022107],[-93.838617,44.008724]]]},"properties":{"id":"56048","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.803268,46.495038],[-92.887261,46.419072],[-92.64161,46.418207],[-92.803268,46.495038]]]},"properties":{"id":"55767","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.010181,44.767963],[-93.888712,44.702477],[-93.88881,44.67702],[-93.808954,44.695358],[-93.848985,44.782209],[-94.010021,44.803989],[-94.010181,44.767963]]]},"properties":{"id":"55368","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.523025,44.891466],[-93.597179,44.863459],[-93.520735,44.81676],[-93.520632,44.858473],[-93.520706,44.891502],[-93.523025,44.891466]]]},"properties":{"id":"55317","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.706078,44.890662],[-93.784494,44.905296],[-93.838909,44.90552],[-93.849459,44.848168],[-93.71907,44.804607],[-93.693214,44.861445],[-93.706078,44.890662]]]},"properties":{"id":"55387","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.141728,46.606028],[-94.175046,46.700254],[-94.33475,46.64587],[-94.32522,46.63129],[-94.345879,46.631344],[-94.519628,46.572468],[-94.518065,46.456806],[-94.469388,46.412967],[-94.198525,46.544505],[-94.141728,46.606028]]]},"properties":{"id":"56472","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.09734,47.335789],[-93.774225,47.149715],[-93.702734,47.361103],[-93.5777,47.458031],[-93.577482,47.484031],[-93.736796,47.567922],[-93.76986,47.60133],[-93.903306,47.586186],[-94.036175,47.636456],[-94.035816,47.589],[-94.10651,47.58337],[-94.32651,47.415712],[-94.09734,47.335789]]]},"properties":{"id":"56636","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.327506,46.340987],[-94.419045,46.397558],[-94.469388,46.412967],[-94.518065,46.456806],[-94.486089,46.282611],[-94.474515,46.277126],[-94.341679,46.277705],[-94.327285,46.289746],[-94.327506,46.340987]]]},"properties":{"id":"56473","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.791355,47.106405],[-94.723033,46.807165],[-94.72253,46.804061],[-94.659387,46.890702],[-94.596318,47.030643],[-94.663794,47.063626],[-94.791355,47.106405]]]},"properties":{"id":"56433","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.431428,45.079533],[-95.381906,44.891081],[-95.280923,44.891343],[-95.248712,44.934666],[-95.369801,45.094554],[-95.410784,45.094207],[-95.431428,45.079533]]]},"properties":{"id":"56222","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.90081,44.92091],[-95.847595,44.878002],[-95.725861,44.85604],[-95.599044,44.89826],[-95.53317,45.111507],[-95.53257,45.151559],[-95.654432,45.15135],[-95.73554,45.151428],[-95.77854,45.06495],[-95.880374,45.025994],[-95.90081,44.92091]]]},"properties":{"id":"56265","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.725861,44.85604],[-95.726691,44.717327],[-95.599757,44.717169],[-95.482817,44.701834],[-95.422177,44.732],[-95.4348,44.833092],[-95.599044,44.89826],[-95.725861,44.85604]]]},"properties":{"id":"56241","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.38912,45.709021],[-93.368554,45.671384],[-93.080497,45.692069],[-93.021946,45.730732],[-93.083983,45.789972],[-93.209413,45.803105],[-93.307067,45.738288],[-93.38912,45.709021]]]},"properties":{"id":"55006","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.193379,45.639612],[-93.195187,45.636649],[-93.203724,45.639592],[-93.388906,45.637283],[-93.409595,45.615597],[-93.440558,45.50071],[-93.13545,45.485753],[-93.141974,45.558109],[-93.141988,45.614093],[-93.193379,45.639612]]]},"properties":{"id":"55008","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.44138,46.977113],[-96.420669,46.789389],[-96.173317,46.663415],[-96.17511,46.737075],[-96.174113,46.92641],[-96.299847,47.006172],[-96.407416,47.03124],[-96.44138,46.977113]]]},"properties":{"id":"56549","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.44138,46.977113],[-96.689799,47.006086],[-96.672775,46.891027],[-96.652233,46.86229],[-96.672672,46.818804],[-96.504681,46.768552],[-96.420669,46.789389],[-96.44138,46.977113]]]},"properties":{"id":"56547","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.039845,44.079224],[-94.954751,43.826086],[-94.954511,43.78994],[-94.914443,43.790163],[-94.874365,43.776182],[-94.859388,43.848093],[-94.859187,44.050093],[-95.039845,44.079224]]]},"properties":{"id":"56159","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.340569,43.963697],[-95.290894,43.935111],[-95.250249,44.021585],[-95.260656,44.079605],[-95.31106,44.086665],[-95.340569,43.963697]]]},"properties":{"id":"56174","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.380496,43.93448],[-95.452603,43.791571],[-95.375558,43.743636],[-95.276833,43.761195],[-95.175194,43.775884],[-95.290894,43.935111],[-95.340569,43.963697],[-95.380496,43.93448]]]},"properties":{"id":"56137","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.12099,46.61026],[-94.091592,46.490589],[-93.95771,46.47468],[-93.92646,46.52536],[-93.883902,46.65506],[-94.03034,46.660096],[-94.12099,46.61026]]]},"properties":{"id":"56441","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.188183,46.130826],[-94.225091,45.937238],[-94.000914,45.861429],[-93.937474,45.853236],[-93.95773,46.17103],[-94.1036,46.17073],[-94.188183,46.130826]]]},"properties":{"id":"56364","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-93.187306,44.712463],[-93.156242,44.702967],[-93.156817,44.717356],[-93.180726,44.717349],[-93.187306,44.712463]]],[[[-93.248179,44.543891],[-93.207923,44.701072],[-93.217753,44.717423],[-93.248682,44.717454],[-93.328482,44.717528],[-93.339459,44.717501],[-93.399439,44.62657],[-93.283264,44.543961],[-93.281521,44.543957],[-93.248179,44.543891]]]]},"properties":{"id":"55044","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.227673,44.828987],[-93.261714,44.809262],[-93.289828,44.800177],[-93.329589,44.791099],[-93.329615,44.791086],[-93.328704,44.74643],[-93.248704,44.731957],[-93.221464,44.776262],[-93.227673,44.828987]]]},"properties":{"id":"55337","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.164102,44.833601],[-93.166743,44.775709],[-93.156218,44.775765],[-93.106259,44.775687],[-93.105824,44.827909],[-93.164102,44.833601]]]},"properties":{"id":"55123","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.795387,44.211342],[-92.728788,44.130647],[-92.678262,44.108059],[-92.601546,44.107801],[-92.55004,44.1875],[-92.59063,44.252677],[-92.763268,44.267018],[-92.795387,44.211342]]]},"properties":{"id":"55963","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.592964,45.875758],[-95.737404,45.708762],[-95.655477,45.672523],[-95.597382,45.672433],[-95.531156,45.788016],[-95.51858,45.888242],[-95.592964,45.875758]]]},"properties":{"id":"56327","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.867179,43.688205],[-93.728059,43.586678],[-93.648388,43.543084],[-93.614823,43.55782],[-93.578317,43.760513],[-93.57445,43.766791],[-93.588186,43.811348],[-93.677384,43.876965],[-93.697487,43.876952],[-93.847496,43.760712],[-93.867179,43.688205]]]},"properties":{"id":"56097","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.948801,43.848226],[-93.967274,43.702423],[-93.887493,43.666361],[-93.867179,43.688205],[-93.847496,43.760712],[-93.888167,43.848116],[-93.948801,43.848226]]]},"properties":{"id":"56025","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.307714,43.542873],[-94.306895,43.500185],[-93.968285,43.499873],[-93.988033,43.543398],[-94.307714,43.542873]]]},"properties":{"id":"56027","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.521635,43.504684],[-91.610747,43.673761],[-91.730725,43.6736],[-91.75737,43.67071],[-91.670808,43.500598],[-91.521091,43.500614],[-91.521635,43.504684]]]},"properties":{"id":"55974","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.089287,43.499637],[-92.878525,43.49987],[-92.89864,43.572555],[-93.049445,43.55774],[-93.089287,43.499637]]]},"properties":{"id":"55953","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.548296,43.609488],[-93.528418,43.60063],[-93.528392,43.617114],[-93.548296,43.609488]]]},"properties":{"id":"56020","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.426565,43.964083],[-93.389903,43.848131],[-93.389476,43.8038],[-93.27467,43.782059],[-93.169521,43.790336],[-93.237854,43.92286],[-93.2763,43.957053],[-93.286158,43.957013],[-93.380466,44.007064],[-93.426565,43.964083]],[[-93.27082,43.8127],[-93.274278,43.82906],[-93.26315,43.823377],[-93.262648,43.813972],[-93.27082,43.8127]]]},"properties":{"id":"56026","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.542242,45.026108],[-93.614548,45.022236],[-93.630456,44.976296],[-93.621161,44.954911],[-93.542242,45.026108]]]},"properties":{"id":"55356","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.233337,44.916367],[-93.267738,44.916111],[-93.26783,44.890713],[-93.246986,44.890564],[-93.207705,44.894992],[-93.179409,44.888307],[-93.178539,44.888338],[-93.201207,44.917803],[-93.199811,44.926929],[-93.233337,44.916367]]]},"properties":{"id":"55417","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.448133,45.065535],[-93.452545,45.028486],[-93.400551,45.033314],[-93.40053,45.065582],[-93.447624,45.066902],[-93.448133,45.065535]]]},"properties":{"id":"55442","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.398775,44.861961],[-93.398697,44.836803],[-93.398827,44.798763],[-93.351882,44.789893],[-93.350189,44.85881],[-93.398775,44.861961]]]},"properties":{"id":"55438","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.367661,44.978176],[-93.400378,44.979518],[-93.404528,44.972746],[-93.398428,44.935064],[-93.374827,44.920564],[-93.370827,44.920464],[-93.367661,44.978176]]]},"properties":{"id":"55426","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.520706,44.891502],[-93.520632,44.858473],[-93.485396,44.862339],[-93.444427,44.891424],[-93.520706,44.891502]]]},"properties":{"id":"55346","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.225775,44.966191],[-93.232346,44.977833],[-93.239267,44.973229],[-93.225775,44.966191]]]},"properties":{"id":"55455","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.179409,44.888307],[-93.207705,44.894992],[-93.223076,44.861977],[-93.197918,44.861977],[-93.179409,44.888307]]]},"properties":{"id":"55111","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.432272,43.734322],[-91.37243,43.664834],[-91.291316,43.731541],[-91.392968,43.761466],[-91.432272,43.734322]]]},"properties":{"id":"55941","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.578981,45.908271],[-93.463884,45.732988],[-93.38912,45.709021],[-93.307067,45.738288],[-93.510036,45.981824],[-93.578981,45.908271]]]},"properties":{"id":"56358","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.702734,47.361103],[-93.693646,47.109394],[-93.480962,47.028832],[-93.309228,47.028002],[-93.32135,47.19642],[-93.476449,47.282237],[-93.43477,47.290693],[-93.424466,47.308662],[-93.410836,47.326191],[-93.5777,47.458031],[-93.702734,47.361103]]]},"properties":{"id":"55744","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.36719,47.32624],[-93.410836,47.326191],[-93.424466,47.308662],[-93.36357,47.31571],[-93.36719,47.32624]]]},"properties":{"id":"55786","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.806293,47.477911],[-92.802841,47.488367],[-92.954749,47.606773],[-93.062859,47.587529],[-93.069399,47.407916],[-93.069479,47.399302],[-93.091059,47.393457],[-93.091993,47.382063],[-93.070334,47.283024],[-93.066245,47.109319],[-92.938536,47.19586],[-92.789314,47.254949],[-92.789683,47.334764],[-92.738535,47.375979],[-92.806293,47.477911]]]},"properties":{"id":"55746","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.144038,43.530152],[-95.081642,43.761147],[-95.175194,43.775884],[-95.276833,43.761195],[-95.33548,43.645228],[-95.335413,43.63754],[-95.335275,43.630686],[-95.335315,43.500387],[-95.21493,43.501016],[-95.144038,43.530152]]]},"properties":{"id":"56150","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.335413,43.63754],[-95.573014,43.790417],[-95.650934,43.761627],[-95.733433,43.674224],[-95.713734,43.587577],[-95.594277,43.499902],[-95.514656,43.499867],[-95.335275,43.630686],[-95.335413,43.63754]]]},"properties":{"id":"56187","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.33548,43.645228],[-95.276833,43.761195],[-95.375558,43.743636],[-95.33548,43.645228]]]},"properties":{"id":"56161","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.728276,43.833877],[-94.719485,43.870046],[-94.71963,43.880663],[-94.859388,43.848093],[-94.874365,43.776182],[-94.854528,43.776048],[-94.723601,43.819526],[-94.728276,43.833877]]]},"properties":{"id":"56160","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.144038,43.530152],[-95.21493,43.501016],[-95.074104,43.500996],[-95.144038,43.530152]]]},"properties":{"id":"51360","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.31274,46.070007],[-93.179356,46.115344],[-93.216164,46.107514],[-93.201311,46.071617],[-92.967737,45.925727],[-92.73665,45.857368],[-92.31274,46.070007]]]},"properties":{"id":"55037","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.824872,45.399385],[-94.805177,45.282915],[-94.763417,45.253646],[-94.640731,45.272259],[-94.587151,45.38632],[-94.624527,45.43385],[-94.645095,45.426624],[-94.639941,45.437577],[-94.6293,45.498704],[-94.690984,45.538111],[-94.762123,45.52811],[-94.819144,45.528194],[-94.824872,45.399385]],[[-94.685555,45.49891],[-94.70142,45.503943],[-94.680316,45.519418],[-94.663536,45.497546],[-94.685555,45.49891]]]},"properties":{"id":"56362","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.627699,48.543774],[-96.348329,48.492913],[-96.30217,48.5738],[-96.386636,48.702913],[-96.422764,48.688335],[-96.693573,48.616064],[-96.758626,48.543592],[-96.627699,48.543774]]]},"properties":{"id":"56732","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.996858,48.948538],[-97.172612,48.919246],[-96.964182,48.919566],[-96.996858,48.948538]]]},"properties":{"id":"56731","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.428702,48.367333],[-94.423146,48.105762],[-94.423557,48.07629],[-93.906195,48.016659],[-93.898561,48.016707],[-93.89888,48.13253],[-93.744326,48.154442],[-93.768061,48.025139],[-93.742502,48.034446],[-93.903319,48.365126],[-94.114357,48.470244],[-94.327684,48.468984],[-94.428702,48.367333]]]},"properties":{"id":"56627","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.383243,48.432684],[-92.969756,48.467601],[-92.703692,48.481135],[-93.717982,48.517469],[-93.712365,48.512534],[-93.383243,48.432684]]]},"properties":{"id":"56649","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.451566,44.631349],[-96.452167,44.383594],[-96.404421,44.386068],[-96.323572,44.408105],[-96.337129,44.543595],[-96.451566,44.631349]]]},"properties":{"id":"56136","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.100237,44.459286],[-96.241539,44.372167],[-96.221041,44.342943],[-96.07936,44.38606],[-96.07936,44.41533],[-96.100237,44.459286]]]},"properties":{"id":"56113","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.8969,44.34105],[-96.0393,44.41515],[-96.07936,44.41533],[-96.07936,44.38606],[-96.015883,44.262852],[-96.00799,44.254328],[-95.89694,44.3265],[-95.8969,44.34105]]]},"properties":{"id":"56169","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.0037,44.10966],[-96.00799,44.254328],[-96.015883,44.262852],[-96.199592,44.197268],[-96.245739,44.168956],[-96.06413,44.09526],[-96.0037,44.10966]]]},"properties":{"id":"56170","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.064812,44.829759],[-94.070596,44.717389],[-94.010494,44.717464],[-94.010181,44.767963],[-94.010021,44.803989],[-94.020245,44.841297],[-94.064812,44.829759]]]},"properties":{"id":"55370","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.254614,44.657675],[-94.305135,44.790485],[-94.396283,44.772189],[-94.42187,44.630223],[-94.285167,44.630498],[-94.254614,44.657675]]]},"properties":{"id":"55312","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.649281,48.427364],[-96.533663,48.384134],[-96.365095,48.457625],[-96.348329,48.492913],[-96.627699,48.543774],[-96.649281,48.427364]]]},"properties":{"id":"56758","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.427883,43.760225],[-94.368066,43.500242],[-94.306895,43.500185],[-94.307714,43.542873],[-94.247836,43.666199],[-94.24783,43.721176],[-94.247907,43.746122],[-94.327578,43.774894],[-94.427883,43.760225]]]},"properties":{"id":"56039","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.438085,43.733304],[-94.435255,43.732865],[-94.435261,43.738585],[-94.438109,43.737474],[-94.438085,43.733304]]]},"properties":{"id":"56075","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.664761,43.587701],[-94.674443,43.573154],[-94.714245,43.500865],[-94.532385,43.500534],[-94.614442,43.587571],[-94.664761,43.587701]]]},"properties":{"id":"56121","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.5057,45.36207],[-94.530251,45.24112],[-94.37916,45.202255],[-94.383325,45.384967],[-94.42314,45.39952],[-94.5057,45.36207]]]},"properties":{"id":"55389","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.501885,44.979695],[-94.378655,44.986045],[-94.379301,45.152154],[-94.501885,44.979695]]]},"properties":{"id":"55324","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.768446,45.93907],[-93.578981,45.908271],[-93.510036,45.981824],[-93.432794,46.010538],[-93.473667,46.067907],[-93.558353,46.068134],[-93.546504,46.1543],[-93.553305,46.246816],[-93.796281,46.243199],[-93.810362,46.156585],[-93.768446,45.93907]]]},"properties":{"id":"56359","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.628825,43.500211],[-92.609013,43.586924],[-92.609225,43.616002],[-92.652873,43.615843],[-92.688718,43.500102],[-92.628825,43.500211]]]},"properties":{"id":"55977","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.894436,43.775886],[-92.829253,43.79055],[-92.829322,43.848733],[-92.934682,43.848565],[-92.950045,43.797952],[-92.894436,43.775886]]]},"properties":{"id":"55982","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.254614,44.657675],[-94.285167,44.630498],[-94.25071,44.4566],[-94.2307,44.4275],[-94.185137,44.428702],[-94.129643,44.44232],[-94.13002,44.50028],[-94.187616,44.646631],[-94.22212,44.666247],[-94.233772,44.666493],[-94.254614,44.657675]]]},"properties":{"id":"55334","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.792785,43.830324],[-95.88415,43.84856],[-95.912706,43.834186],[-95.851799,43.76108],[-95.773227,43.717647],[-95.752647,43.790485],[-95.792785,43.830324]]]},"properties":{"id":"56185","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.689789,47.310243],[-96.837012,47.318386],[-96.833393,47.238041],[-96.623107,47.22897],[-96.689789,47.310243]]]},"properties":{"id":"56550","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.469174,44.132892],[-92.573349,44.079312],[-92.55825,44.03281],[-92.46449,44.02533],[-92.469174,44.132892]],[[-92.52852,44.05791],[-92.52402,44.0579],[-92.52312,44.05339],[-92.52855,44.05521],[-92.52852,44.05791]]]},"properties":{"id":"55901","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.123773,46.412972],[-95.905596,46.405528],[-95.873937,46.434202],[-95.962913,46.486575],[-96.146933,46.500511],[-96.155845,46.456649],[-96.123773,46.412972]]]},"properties":{"id":"56534","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.65687,46.420662],[-95.584695,46.368979],[-95.429507,46.400526],[-95.407659,46.406661],[-95.532493,46.488881],[-95.615286,46.50627],[-95.65687,46.420662]]]},"properties":{"id":"56571","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.270507,46.448909],[-95.237001,46.365343],[-95.135105,46.347605],[-95.030146,46.49928],[-95.239655,46.564627],[-95.238286,46.535671],[-95.270507,46.448909]]]},"properties":{"id":"56482","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.322042,46.436729],[-95.407659,46.406661],[-95.429507,46.400526],[-95.279565,46.296863],[-95.237001,46.365343],[-95.270507,46.448909],[-95.322042,46.436729]]]},"properties":{"id":"56527","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.655911,46.293812],[-95.645392,46.136541],[-95.584339,46.135967],[-95.573884,46.284151],[-95.655911,46.293812]]]},"properties":{"id":"56524","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.187899,46.267111],[-95.187554,46.194537],[-94.990053,46.216002],[-94.91033,46.23035],[-94.946064,46.29638],[-94.967082,46.296326],[-95.029014,46.296314],[-95.187899,46.267111]]]},"properties":{"id":"56437","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.054067,46.281928],[-92.855852,46.244796],[-92.76992,46.281641],[-92.621908,46.309544],[-92.678419,46.347048],[-93.054067,46.367115],[-93.054067,46.281928]]]},"properties":{"id":"55795","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.264794,43.88923],[-96.244731,43.884983],[-96.244816,43.892133],[-96.264794,43.88923]]]},"properties":{"id":"56177","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.143717,44.985808],[-93.097916,44.963193],[-93.073671,44.991024],[-93.066121,44.991985],[-93.064656,45.037774],[-93.103307,45.043115],[-93.105334,45.035962],[-93.146464,44.991976],[-93.143717,44.985808]]]},"properties":{"id":"55117","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.0847,44.957845],[-93.08471,44.954908],[-93.079301,44.955421],[-93.0847,44.957845]]]},"properties":{"id":"55155","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.20835,44.318364],[-95.218072,44.318386],[-95.218204,44.311123],[-95.207999,44.311081],[-95.20835,44.318364]]]},"properties":{"id":"56294","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.301826,44.428558],[-93.220526,44.370417],[-93.193382,44.442562],[-93.301826,44.428558]]]},"properties":{"id":"55019","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.33206,43.74753],[-96.14267,43.7611],[-96.125051,43.797739],[-96.24531,43.8486],[-96.33206,43.74753]]]},"properties":{"id":"56134","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.140024,48.946223],[-95.99376,48.61598],[-95.863835,48.628327],[-95.84417,48.67131],[-96.140024,48.946223]]]},"properties":{"id":"56714","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.48369,48.712494],[-95.342618,48.597874],[-95.331249,48.712982],[-95.220704,48.883166],[-95.48436,48.999151],[-95.48369,48.712494]]]},"properties":{"id":"56763","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.789314,47.254949],[-92.683871,47.167417],[-92.533895,47.285085],[-92.568427,47.371901],[-92.789683,47.334764],[-92.789314,47.254949]]]},"properties":{"id":"55738","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.346448,48.134228],[-92.470728,47.915662],[-92.437862,47.713663],[-92.410677,47.690695],[-92.17984,47.800863],[-92.187896,47.8525],[-92.128676,48.083169],[-92.346448,48.134228]]]},"properties":{"id":"55790","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.410677,47.690695],[-92.369051,47.591624],[-92.306838,47.60044],[-92.181518,47.55804],[-92.052153,47.628916],[-92.050952,47.801256],[-92.15787,47.80106],[-92.17984,47.800863],[-92.410677,47.690695]]]},"properties":{"id":"55732","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.416165,47.244472],[-92.373613,47.268622],[-92.463253,47.284836],[-92.482344,47.256151],[-92.416165,47.244472]]]},"properties":{"id":"55766","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.739016,47.504261],[-92.729335,47.527949],[-92.760469,47.504519],[-92.739016,47.504261]]]},"properties":{"id":"55758","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.626735,44.718462],[-93.470332,44.706365],[-93.409092,44.753801],[-93.398827,44.798763],[-93.520431,44.804263],[-93.594334,44.776662],[-93.626735,44.718462]]]},"properties":{"id":"55379","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.470332,44.706365],[-93.626735,44.718462],[-93.67894,44.68689],[-93.623802,44.594438],[-93.501324,44.601689],[-93.470332,44.706365]]]},"properties":{"id":"55352","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.922316,45.385021],[-93.819988,45.515686],[-93.821515,45.559932],[-93.922369,45.559898],[-94.107743,45.472368],[-94.101921,45.456196],[-93.973642,45.38881],[-93.922316,45.385021]]]},"properties":{"id":"55319","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.2064,45.55861],[-94.154633,45.562513],[-94.165368,45.573766],[-94.191073,45.600226],[-94.24613,45.59048],[-94.257752,45.554328],[-94.2064,45.55861]]]},"properties":{"id":"56303","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.639941,45.437577],[-94.645095,45.426624],[-94.624527,45.43385],[-94.639941,45.437577]]]},"properties":{"id":"56371","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.024641,45.331443],[-93.97189,45.336554],[-93.973642,45.38881],[-94.101921,45.456196],[-94.194914,45.429766],[-94.069841,45.365821],[-94.024641,45.331443]]]},"properties":{"id":"55320","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.38772,45.69545],[-94.461495,45.544013],[-94.384261,45.518792],[-94.265376,45.551814],[-94.257752,45.554328],[-94.24613,45.59048],[-94.263916,45.684415],[-94.2857,45.717401],[-94.34719,45.71738],[-94.38772,45.69545]],[[-94.403926,45.580472],[-94.383034,45.586755],[-94.384941,45.573455],[-94.403926,45.580472]]]},"properties":{"id":"56374","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.104877,45.994166],[-94.919242,45.860135],[-94.845716,45.846409],[-94.76622,45.90405],[-94.714837,45.943331],[-94.648217,46.020416],[-94.647856,46.048921],[-94.964306,46.048601],[-95.021779,46.04845],[-95.105224,46.020064],[-95.104877,45.994166]]]},"properties":{"id":"56347","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.90453,46.38322],[-94.967082,46.296326],[-94.946064,46.29638],[-94.90453,46.38322]]]},"properties":{"id":"56434","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.984791,45.034049],[-92.984696,44.992273],[-92.98464,44.948522],[-92.93453,44.948833],[-92.943081,45.035902],[-92.984791,45.034049]]]},"properties":{"id":"55128","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.569576,44.080025],[-94.589744,44.08004],[-94.589711,44.064671],[-94.569575,44.065616],[-94.569576,44.080025]]]},"properties":{"id":"56056","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.656465,46.369273],[-96.591166,46.195932],[-96.387883,46.208449],[-96.4032,46.36905],[-96.48725,46.38387],[-96.656465,46.369273]]]},"properties":{"id":"56520","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.25568,45.166882],[-94.256074,44.979465],[-94.13909,44.978791],[-94.104217,45.152227],[-94.227454,45.19096],[-94.25568,45.166882]]]},"properties":{"id":"55321","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.220593,46.1587],[-93.054067,46.281928],[-93.054067,46.367115],[-93.107103,46.446665],[-93.37031,46.418712],[-93.431107,46.333292],[-93.220593,46.1587]]]},"properties":{"id":"56350","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.265828,45.211565],[-93.357414,45.212207],[-93.362176,45.197547],[-93.266938,45.128199],[-93.265828,45.211565]]]},"properties":{"id":"55448","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.174113,46.92641],[-96.17511,46.737075],[-96.065395,46.739551],[-96.047787,46.977446],[-96.018911,47.035612],[-96.066428,47.06444],[-96.130732,47.049769],[-96.174113,46.92641]]]},"properties":{"id":"56554","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.754566,46.628971],[-95.725296,46.605842],[-95.414397,46.71617],[-95.329176,46.832124],[-95.54138,46.880533],[-95.619648,46.84641],[-95.626398,46.844575],[-95.834902,46.716574],[-95.754566,46.628971]]]},"properties":{"id":"56544","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.54138,46.880533],[-95.329176,46.832124],[-95.268396,46.847523],[-95.297422,47.020178],[-95.4137,46.969986],[-95.54138,46.880533]]]},"properties":{"id":"56570","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.352371,46.803191],[-95.352322,46.804273],[-95.355514,46.804434],[-95.355754,46.80318],[-95.352371,46.803191]]]},"properties":{"id":"56593","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.771113,47.889084],[-94.954923,47.881298],[-94.948583,47.80246],[-94.806249,47.803539],[-94.734168,47.950511],[-94.725227,47.960188],[-94.771113,47.889084]]]},"properties":{"id":"56670","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.954923,47.881298],[-95.277167,48.020034],[-95.582887,48.020557],[-95.58261,48.00623],[-95.582065,47.925878],[-95.39907,47.82338],[-95.193905,47.8097],[-95.029101,47.80298],[-94.948583,47.80246],[-94.954923,47.881298]]]},"properties":{"id":"56671","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.824287,45.779228],[-93.76025,45.62617],[-93.728491,45.643674],[-93.71868,45.66705],[-93.824287,45.779228]]]},"properties":{"id":"56357","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.263916,45.684415],[-94.24613,45.59048],[-94.191073,45.600226],[-94.184184,45.679983],[-94.259481,45.692649],[-94.263916,45.684415]]]},"properties":{"id":"56377","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.636338,45.658356],[-96.616611,45.471782],[-96.322275,45.527414],[-96.57315,45.65812],[-96.636338,45.658356]],[[-96.559391,45.561276],[-96.575008,45.558203],[-96.575729,45.56166],[-96.559391,45.561276]]]},"properties":{"id":"56240","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.888167,43.848116],[-93.847496,43.760712],[-93.697487,43.876952],[-93.717927,43.949473],[-93.768107,43.964026],[-93.888167,43.848116]]]},"properties":{"id":"56068","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.838617,44.008724],[-93.768107,43.964026],[-93.717927,43.949473],[-93.697499,43.949498],[-93.838617,44.008724]]]},"properties":{"id":"56078","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.128673,44.083755],[-94.128439,43.999098],[-94.109257,43.957279],[-94.070742,43.934994],[-93.946457,44.021973],[-94.128673,44.083755]]]},"properties":{"id":"56037","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.94289,44.855075],[-93.888398,44.848256],[-93.849459,44.848168],[-93.838909,44.90552],[-93.930946,44.978262],[-93.971827,44.978262],[-94.007272,44.97862],[-94.012236,44.978712],[-94.002198,44.949711],[-93.94289,44.855075]]]},"properties":{"id":"55360","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.784494,44.905296],[-93.706078,44.890662],[-93.705981,44.90741],[-93.747292,44.91663],[-93.784494,44.905296]]]},"properties":{"id":"55375","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.90453,46.38322],[-94.765407,46.630066],[-95.030146,46.49928],[-95.135105,46.347605],[-95.029014,46.296314],[-94.967082,46.296326],[-94.90453,46.38322]]]},"properties":{"id":"56481","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.90453,46.38322],[-94.946064,46.29638],[-94.91033,46.23035],[-94.895346,46.230556],[-94.727217,46.28232],[-94.6551,46.572142],[-94.660948,46.716675],[-94.702193,46.716473],[-94.765407,46.630066],[-94.90453,46.38322]]]},"properties":{"id":"56479","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.901972,46.889239],[-94.032687,46.803222],[-94.012252,46.803641],[-93.776024,46.802891],[-93.901972,46.889239]]]},"properties":{"id":"56662","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.53317,45.111507],[-95.431428,45.079533],[-95.410784,45.094207],[-95.285958,45.282149],[-95.357434,45.405291],[-95.53257,45.151559],[-95.53317,45.111507]]]},"properties":{"id":"56271","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.369801,45.094554],[-95.248712,44.934666],[-95.201812,44.93491],[-95.125026,44.964033],[-95.064152,44.979105],[-95.144911,45.110003],[-95.206574,45.123819],[-95.369801,45.094554]]]},"properties":{"id":"56282","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.95771,46.47468],[-93.936739,46.417043],[-93.827471,46.330149],[-93.810632,46.330547],[-93.92646,46.52536],[-93.95771,46.47468]]]},"properties":{"id":"56444","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.810362,46.156585],[-93.885893,46.200112],[-93.95773,46.17103],[-93.937474,45.853236],[-93.766649,45.865822],[-93.768446,45.93907],[-93.810362,46.156585]]]},"properties":{"id":"56338","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.217753,44.717423],[-93.180726,44.717349],[-93.156817,44.717356],[-93.156218,44.775765],[-93.166743,44.775709],[-93.221464,44.776262],[-93.248704,44.731957],[-93.248682,44.717454],[-93.217753,44.717423]]]},"properties":{"id":"55124","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.065271,44.884729],[-93.105832,44.861167],[-93.105824,44.827909],[-93.106259,44.775687],[-93.020162,44.775572],[-93.062067,44.883644],[-93.065271,44.884729]]]},"properties":{"id":"55077","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.157947,44.883524],[-93.179409,44.888307],[-93.197918,44.861977],[-93.106144,44.861289],[-93.157947,44.883524]]]},"properties":{"id":"55120","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.04563,44.124353],[-93.103835,43.981335],[-92.965284,43.96476],[-92.925305,44.088033],[-93.04563,44.124353]]]},"properties":{"id":"55924","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.188003,46.106731],[-95.437662,46.060115],[-95.430981,46.041917],[-95.312629,46.019863],[-95.146429,46.034309],[-95.188003,46.106731]]]},"properties":{"id":"56354","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.24783,43.721176],[-94.247836,43.666199],[-94.087819,43.717111],[-94.078826,43.848073],[-94.26721,43.84797],[-94.327578,43.774894],[-94.247907,43.746122],[-94.23753,43.731999],[-94.24783,43.721176]]]},"properties":{"id":"56098","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.957542,44.017773],[-92.01087,43.86162],[-92.02529,43.82259],[-91.95011,43.82518],[-91.914571,43.866966],[-91.8993,44.02063],[-91.90959,44.02038],[-91.957542,44.017773]]]},"properties":{"id":"55979","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.730571,43.695181],[-91.685063,43.877035],[-91.74002,43.94052],[-91.868887,43.863806],[-91.730571,43.695181]]]},"properties":{"id":"55971","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.638234,43.915067],[-92.689391,43.84857],[-92.689283,43.833847],[-92.669799,43.833731],[-92.609649,43.833876],[-92.448976,43.811267],[-92.3489,43.81924],[-92.318206,43.904437],[-92.318214,43.906376],[-92.48406,43.89925],[-92.566305,43.926794],[-92.638234,43.915067]]]},"properties":{"id":"55976","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.289171,43.738411],[-93.408811,43.766783],[-93.456912,43.752996],[-93.518296,43.557258],[-93.446745,43.556807],[-93.41351,43.564761],[-93.268506,43.607047],[-93.249353,43.695209],[-93.169093,43.630157],[-93.169077,43.622932],[-93.159247,43.731983],[-93.289171,43.738411]]]},"properties":{"id":"56007","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.169093,43.630157],[-93.249353,43.695209],[-93.268506,43.607047],[-93.169093,43.630157]]]},"properties":{"id":"56043","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.408811,43.766783],[-93.289171,43.738411],[-93.27467,43.782059],[-93.389476,43.8038],[-93.408811,43.766783]]]},"properties":{"id":"56016","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.548183,43.760426],[-93.456912,43.752996],[-93.408811,43.766783],[-93.389476,43.8038],[-93.389903,43.848131],[-93.588186,43.811348],[-93.57445,43.766791],[-93.548183,43.760426]]]},"properties":{"id":"56042","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.27082,43.8127],[-93.262648,43.813972],[-93.26315,43.823377],[-93.274278,43.82906],[-93.27082,43.8127]]]},"properties":{"id":"56035","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.183306,46.108522],[-96.265398,46.094133],[-96.26614,46.02161],[-96.26641,45.97807],[-96.14208,45.96347],[-96.0808,46.10843],[-96.183306,46.108522]]]},"properties":{"id":"56590","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.0808,46.10843],[-96.14208,45.96347],[-96.13045,45.87581],[-96.00673,45.84666],[-95.76956,45.93368],[-95.769618,45.991627],[-95.89619,46.1083],[-95.99852,46.10842],[-96.0808,46.10843]]]},"properties":{"id":"56531","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.265398,46.094133],[-96.38943,46.05054],[-96.26614,46.02161],[-96.265398,46.094133]]]},"properties":{"id":"56565","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.32507,45.041056],[-93.360138,45.040043],[-93.360092,45.032768],[-93.359943,44.978092],[-93.328572,44.977142],[-93.318424,44.983977],[-93.31839,45.013256],[-93.319305,45.04018],[-93.32507,45.041056]]]},"properties":{"id":"55422","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.370827,44.920464],[-93.349613,44.920366],[-93.331627,44.923397],[-93.309921,44.933685],[-93.305122,44.951138],[-93.328572,44.977142],[-93.359943,44.978092],[-93.367661,44.978176],[-93.370827,44.920464]]]},"properties":{"id":"55416","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.680452,45.065283],[-93.698894,45.137598],[-93.796167,45.10747],[-93.748668,45.064651],[-93.680452,45.065283]]]},"properties":{"id":"55373","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.350189,44.85881],[-93.350223,44.889597],[-93.401085,44.891727],[-93.412654,44.891987],[-93.398775,44.861961],[-93.350189,44.85881]]]},"properties":{"id":"55439","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.401731,45.152037],[-93.360649,45.151882],[-93.328329,45.151465],[-93.373136,45.178133],[-93.422823,45.211961],[-93.421989,45.16753],[-93.401731,45.152037]]]},"properties":{"id":"55316","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.610747,43.673761],[-91.432272,43.734322],[-91.392968,43.761466],[-91.48064,43.86881],[-91.55021,43.92996],[-91.685063,43.877035],[-91.730571,43.695181],[-91.730725,43.6736],[-91.610747,43.673761]]]},"properties":{"id":"55943","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.284404,47.316964],[-93.251867,47.324861],[-93.259757,47.327687],[-93.284404,47.316964]]]},"properties":{"id":"55716","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.881117,47.64257],[-92.951061,47.719371],[-93.19534,47.65903],[-93.19536,47.63311],[-93.062859,47.587529],[-92.954749,47.606773],[-92.864738,47.632945],[-92.881117,47.64257]]]},"properties":{"id":"55781","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.058266,46.910601],[-93.061014,46.789542],[-93.061043,46.788277],[-92.849076,46.766257],[-92.808646,46.766321],[-92.806207,46.868739],[-92.679938,47.028884],[-92.938536,47.19586],[-93.066245,47.109319],[-93.156708,47.125985],[-93.191898,47.090184],[-93.084566,47.026357],[-93.058266,46.910601]]]},"properties":{"id":"55736","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.160549,47.67264],[-94.416811,47.636328],[-94.415165,47.542805],[-94.10651,47.58337],[-94.035816,47.589],[-94.06544,47.65269],[-94.073066,47.672813],[-94.160549,47.67264]]]},"properties":{"id":"56681","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.914443,43.790163],[-94.903818,43.50047],[-94.854555,43.500925],[-94.854634,43.588093],[-94.854546,43.762114],[-94.854528,43.776048],[-94.874365,43.776182],[-94.914443,43.790163]]]},"properties":{"id":"56111","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.967737,45.925727],[-93.201311,46.071617],[-93.1424,45.904662],[-93.06249,45.88971],[-92.967737,45.925727]]]},"properties":{"id":"55007","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.083411,45.232784],[-95.144911,45.110003],[-95.064152,44.979105],[-95.002572,45.008745],[-94.937118,45.066717],[-94.974719,45.182571],[-95.083411,45.232784]]]},"properties":{"id":"56201","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.974719,45.182571],[-94.937118,45.066717],[-94.905909,45.066489],[-94.859602,45.204073],[-94.974719,45.182571]]]},"properties":{"id":"56251","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.280923,44.891343],[-95.244351,44.667111],[-95.238513,44.628757],[-95.127916,44.655677],[-95.125481,44.891438],[-95.125293,44.935063],[-95.201812,44.93491],[-95.248712,44.934666],[-95.280923,44.891343]]]},"properties":{"id":"56284","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.125026,44.964033],[-95.201812,44.93491],[-95.125293,44.935063],[-95.125026,44.964033]]]},"properties":{"id":"56281","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.422764,48.688335],[-96.69388,48.645622],[-96.693573,48.616064],[-96.422764,48.688335]]]},"properties":{"id":"56729","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.898561,48.016707],[-93.768061,48.025139],[-93.744326,48.154442],[-93.89888,48.13253],[-93.898561,48.016707]]]},"properties":{"id":"56658","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.39466,45.21141],[-96.452397,45.202914],[-96.452319,45.115094],[-96.32804,45.05318],[-96.24787,45.19694],[-96.39466,45.21141]]]},"properties":{"id":"56212","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.174797,44.855314],[-96.093255,44.80476],[-95.90081,44.92091],[-95.880374,45.025994],[-95.884391,45.028972],[-95.980339,45.097574],[-95.98092,45.05925],[-96.174797,44.855314]]]},"properties":{"id":"56232","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.794148,46.989308],[-91.774569,46.976634],[-91.793981,46.940109],[-91.794531,46.928751],[-91.319279,47.245648],[-91.320496,47.245627],[-91.410042,47.18394],[-91.639658,47.430894],[-91.64987,47.428901],[-91.789996,47.437961],[-91.830646,47.198788],[-91.92148,47.025658],[-91.794148,46.989308]]]},"properties":{"id":"55616","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.484727,44.293344],[-93.666607,44.304676],[-93.722553,44.282799],[-93.646696,44.195993],[-93.573078,44.166496],[-93.52523,44.195975],[-93.484727,44.293344]]]},"properties":{"id":"56096","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.221041,44.342943],[-96.199592,44.197268],[-96.015883,44.262852],[-96.07936,44.38606],[-96.221041,44.342943]]]},"properties":{"id":"56178","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.194642,44.543377],[-96.11011,44.517466],[-96.011779,44.731806],[-96.0518,44.757385],[-96.093735,44.703026],[-96.194642,44.543377]]]},"properties":{"id":"56291","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.93794,44.43796],[-95.862926,44.543411],[-95.95838,44.45807],[-95.93794,44.43796]]]},"properties":{"id":"56239","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.5586,44.64408],[-94.457439,44.629926],[-94.42187,44.630223],[-94.396283,44.772189],[-94.559442,44.880393],[-94.5586,44.64408]]]},"properties":{"id":"55385","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.501885,44.979695],[-94.584149,44.906652],[-94.584505,44.892177],[-94.559442,44.880393],[-94.396283,44.772189],[-94.305135,44.790485],[-94.23271,44.877473],[-94.256074,44.979465],[-94.378655,44.986045],[-94.501885,44.979695]]]},"properties":{"id":"55350","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.67921,47.264287],[-95.637126,47.208768],[-95.551533,47.257534],[-95.67921,47.264287]]]},"properties":{"id":"56566","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.151127,48.285766],[-96.361402,48.174781],[-96.500843,48.174108],[-96.500888,48.035645],[-96.482586,48.021673],[-96.28859,48.00644],[-96.13318,48.03476],[-96.09612,47.963696],[-95.9034,47.99384],[-95.862473,48.03447],[-95.981147,48.300029],[-96.151127,48.285766]]]},"properties":{"id":"56701","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.710236,48.009456],[-95.58261,48.00623],[-95.582887,48.020557],[-95.851965,48.295063],[-95.980624,48.300136],[-95.981147,48.300029],[-95.862473,48.03447],[-95.710236,48.009456]]]},"properties":{"id":"56725","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.99376,48.61598],[-96.30217,48.5738],[-96.348329,48.492913],[-96.365095,48.457625],[-96.276544,48.456684],[-95.797601,48.540506],[-95.775802,48.540556],[-95.863835,48.628327],[-95.99376,48.61598]]]},"properties":{"id":"56759","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.647787,45.698258],[-93.660792,45.699647],[-93.656942,45.69408],[-93.647787,45.698258]]]},"properties":{"id":"56363","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.714837,45.943331],[-94.576856,45.817763],[-94.556102,45.816441],[-94.551002,45.816591],[-94.513629,45.865654],[-94.576997,46.005882],[-94.648217,46.020416],[-94.714837,45.943331]]]},"properties":{"id":"56382","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.714837,45.943331],[-94.76622,45.90405],[-94.64399,45.77395],[-94.58558,45.77425],[-94.571334,45.799518],[-94.576856,45.817763],[-94.714837,45.943331]]]},"properties":{"id":"56318","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.529856,45.944915],[-94.509691,45.955151],[-94.524345,45.962503],[-94.529856,45.944915]]]},"properties":{"id":"56328","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.556102,45.816441],[-94.576856,45.817763],[-94.571334,45.799518],[-94.556102,45.816441]]]},"properties":{"id":"56384","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.878525,43.49987],[-92.808627,43.499953],[-92.769547,43.63042],[-92.749325,43.673846],[-92.768117,43.674028],[-92.89864,43.572555],[-92.878525,43.49987]]]},"properties":{"id":"55970","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.623927,44.42673],[-94.491954,44.41318],[-94.4724,44.45629],[-94.457439,44.629926],[-94.5586,44.64408],[-94.629225,44.614805],[-94.623927,44.42673]]]},"properties":{"id":"55335","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.430171,47.44838],[-96.26934,47.325344],[-96.088501,47.29798],[-96.067018,47.36869],[-96.131649,47.470171],[-96.430171,47.44838]]]},"properties":{"id":"56545","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.623107,47.22897],[-96.580099,47.194888],[-96.436234,47.238216],[-96.26934,47.325344],[-96.430171,47.44838],[-96.451641,47.499129],[-96.66548,47.498949],[-96.708626,47.411837],[-96.689789,47.310243],[-96.623107,47.22897]]]},"properties":{"id":"56510","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.46303,44.02347],[-92.345006,44.03552],[-92.31897,44.09326],[-92.350206,44.194191],[-92.340713,44.233862],[-92.470152,44.184983],[-92.469174,44.132892],[-92.46449,44.02533],[-92.46303,44.02347]]]},"properties":{"id":"55906","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.345006,44.03552],[-92.318214,43.906376],[-92.318206,43.904437],[-92.19894,43.93392],[-92.19407,44.061],[-92.21931,44.09297],[-92.31897,44.09326],[-92.345006,44.03552]]]},"properties":{"id":"55934","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.189782,44.238523],[-92.290622,44.193923],[-92.21931,44.09297],[-92.19407,44.061],[-92.15035,44.05686],[-92.079491,44.106988],[-92.069635,44.193033],[-92.189782,44.238523]]]},"properties":{"id":"55964","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.123773,46.412972],[-96.197797,46.399208],[-96.280478,46.34771],[-96.265342,46.209859],[-96.183306,46.108522],[-96.0808,46.10843],[-95.99852,46.10842],[-95.896136,46.224609],[-95.905596,46.405528],[-96.123773,46.412972]]]},"properties":{"id":"56537","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.905596,46.405528],[-95.896136,46.224609],[-95.805867,46.212831],[-95.801331,46.412176],[-95.820252,46.441148],[-95.873937,46.434202],[-95.905596,46.405528]]]},"properties":{"id":"56586","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.754566,46.628971],[-95.834902,46.716574],[-95.963592,46.676119],[-95.906634,46.564606],[-95.754566,46.628971]]]},"properties":{"id":"56587","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.65687,46.420662],[-95.615286,46.50627],[-95.688705,46.520163],[-95.820252,46.441148],[-95.801331,46.412176],[-95.65687,46.420662]]]},"properties":{"id":"56576","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.837177,47.760894],[-95.794281,47.732216],[-95.707933,47.732457],[-95.5813,47.87603],[-95.582065,47.925878],[-95.58261,48.00623],[-95.710236,48.009456],[-95.784919,47.83401],[-95.837177,47.760894]]]},"properties":{"id":"56684","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.421374,47.655283],[-96.728899,47.586022],[-96.729127,47.571511],[-96.708663,47.498919],[-96.66548,47.498949],[-96.451641,47.499129],[-96.421374,47.655283]]]},"properties":{"id":"56517","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.991274,47.58605],[-95.896571,47.551728],[-95.794281,47.732216],[-95.837177,47.760894],[-95.945203,47.760561],[-95.991274,47.58605]]]},"properties":{"id":"56556","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.707933,47.732457],[-95.57542,47.67366],[-95.5813,47.87603],[-95.707933,47.732457]]]},"properties":{"id":"56646","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.77854,45.585398],[-95.932491,45.412355],[-95.932408,45.398451],[-95.849922,45.383608],[-95.747402,45.412313],[-95.644656,45.441468],[-95.582601,45.470221],[-95.69325,45.571828],[-95.77854,45.585398]]]},"properties":{"id":"56244","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.146588,44.967349],[-93.126315,44.94943],[-93.105295,44.951362],[-93.097005,44.955976],[-93.097916,44.963193],[-93.143717,44.985808],[-93.146588,44.967349]]]},"properties":{"id":"55103","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.066121,44.991985],[-93.023724,44.991952],[-92.984696,44.992273],[-92.984791,45.034049],[-92.984792,45.034223],[-93.062276,45.0397],[-93.064656,45.037774],[-93.066121,44.991985]]]},"properties":{"id":"55109","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.073671,44.991024],[-93.097916,44.963193],[-93.097005,44.955976],[-93.0847,44.957845],[-93.079301,44.955421],[-93.077368,44.957318],[-93.073671,44.991024]]]},"properties":{"id":"55130","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.330831,44.483583],[-95.331042,44.469057],[-95.320748,44.479741],[-95.330831,44.483583]]]},"properties":{"id":"56287","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.111771,44.218372],[-93.353152,44.196208],[-93.376809,44.167414],[-93.376908,44.152967],[-93.094835,44.160569],[-93.04591,44.196716],[-93.111771,44.218372]]]},"properties":{"id":"55049","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.31238,43.55829],[-96.39227,43.70374],[-96.45345,43.64593],[-96.45347,43.6089],[-96.43235,43.60899],[-96.43202,43.57289],[-96.45339,43.57318],[-96.45337,43.55863],[-96.31238,43.55829]]]},"properties":{"id":"56116","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.48369,48.712494],[-95.48436,48.999151],[-95.642878,48.999436],[-95.48369,48.712494]]]},"properties":{"id":"56756","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.301897,46.939836],[-92.352552,47.023669],[-92.555098,46.997389],[-92.613227,46.924632],[-92.57589,46.849528],[-92.357921,46.843731],[-92.322815,46.843743],[-92.301897,46.939836]]]},"properties":{"id":"55779","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.076823,46.798999],[-92.101462,46.788941],[-92.107388,46.74913],[-92.068093,46.791043],[-92.076823,46.798999]]]},"properties":{"id":"55802","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.372573,47.371427],[-92.502866,47.472386],[-92.559698,47.488941],[-92.568427,47.371901],[-92.533895,47.285085],[-92.482344,47.256151],[-92.463253,47.284836],[-92.373613,47.268622],[-92.416165,47.244472],[-92.302695,47.268736],[-92.372573,47.371427]]]},"properties":{"id":"55734","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.502866,47.472386],[-92.372573,47.371427],[-92.337681,47.426044],[-92.325477,47.521006],[-92.385424,47.545439],[-92.502866,47.472386]]]},"properties":{"id":"55741","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.59498,47.61455],[-92.564757,47.531611],[-92.565056,47.49934],[-92.559698,47.488941],[-92.502866,47.472386],[-92.385424,47.545439],[-92.369051,47.591624],[-92.410677,47.690695],[-92.437862,47.713663],[-92.560748,47.696574],[-92.574159,47.671378],[-92.59498,47.61455]]]},"properties":{"id":"55792","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.080426,46.822067],[-92.088846,46.81247],[-92.076823,46.798999],[-92.068093,46.791043],[-92.050108,46.80245],[-92.066221,46.821157],[-92.080426,46.822067]]]},"properties":{"id":"55812","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.385424,47.545439],[-92.325477,47.521006],[-92.306838,47.60044],[-92.369051,47.591624],[-92.385424,47.545439]]]},"properties":{"id":"55708","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.360217,44.565764],[-93.338756,44.565458],[-93.338578,44.576569],[-93.362413,44.576497],[-93.360217,44.565764]]]},"properties":{"id":"55054","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.083697,45.789396],[-95.13967,45.758891],[-95.13961,45.613965],[-94.995028,45.585333],[-94.89146,45.60014],[-94.829724,45.774399],[-94.845716,45.846409],[-94.919242,45.860135],[-95.078637,45.802671],[-95.083697,45.789396]]]},"properties":{"id":"56378","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.343219,45.475269],[-94.32724,45.4627],[-94.32814,45.42846],[-94.233306,45.421075],[-94.194914,45.429766],[-94.101921,45.456196],[-94.107743,45.472368],[-94.154633,45.562513],[-94.2064,45.55861],[-94.265376,45.551814],[-94.384261,45.518792],[-94.343219,45.475269]]]},"properties":{"id":"56301","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.94877,45.56438],[-94.943516,45.56131],[-94.941092,45.567407],[-94.94877,45.56438]]]},"properties":{"id":"56325","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.117772,45.585634],[-96.014801,45.412173],[-95.932491,45.412355],[-95.77854,45.585398],[-95.779176,45.716336],[-95.84122,45.75976],[-95.86225,45.759791],[-96.150559,45.629218],[-96.117772,45.585634]]]},"properties":{"id":"56267","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.964306,46.048601],[-94.647856,46.048921],[-94.647787,46.107816],[-94.720057,46.249163],[-94.727217,46.28232],[-94.895346,46.230556],[-94.964306,46.048601]]]},"properties":{"id":"56438","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.078637,45.802671],[-95.096498,45.806053],[-95.096992,45.79613],[-95.083697,45.789396],[-95.078637,45.802671]]]},"properties":{"id":"56389","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.789756,45.673177],[-96.835451,45.586129],[-96.71908,45.60034],[-96.789756,45.673177]]]},"properties":{"id":"56219","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.350206,44.194191],[-92.290622,44.193923],[-92.189782,44.238523],[-92.23948,44.300476],[-92.330675,44.298567],[-92.340713,44.233862],[-92.350206,44.194191]]]},"properties":{"id":"55957","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.19961,44.331],[-91.925704,44.291912],[-92.138091,44.424436],[-92.112025,44.409832],[-92.084268,44.40603],[-92.066226,44.389888],[-92.101317,44.395175],[-92.094867,44.408395],[-92.19961,44.331]]]},"properties":{"id":"55981","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.024641,45.331443],[-94.032525,45.157693],[-94.012298,45.143445],[-94.012282,45.152051],[-93.920869,45.28214],[-93.97189,45.336554],[-94.024641,45.331443]]]},"properties":{"id":"55358","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.971827,44.978262],[-93.913403,45.115384],[-93.997871,45.139397],[-94.012298,45.143445],[-94.007272,44.97862],[-93.971827,44.978262]]]},"properties":{"id":"55390","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.599757,44.717169],[-95.604001,44.629945],[-95.594817,44.542305],[-95.523316,44.541968],[-95.482817,44.701834],[-95.599757,44.717169]]]},"properties":{"id":"56297","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.054067,46.281928],[-93.220593,46.1587],[-93.178985,46.15855],[-92.84761,46.158016],[-92.855852,46.244796],[-93.054067,46.281928]]]},"properties":{"id":"55735","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.14213,45.376825],[-93.219288,45.297794],[-93.142287,45.286831],[-92.951209,45.325206],[-92.958054,45.36574],[-93.111698,45.382342],[-93.14213,45.376825]]]},"properties":{"id":"55092","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.234207,45.211429],[-93.235187,45.124463],[-93.227696,45.124533],[-93.18863,45.118895],[-93.184132,45.124329],[-93.142421,45.211028],[-93.234207,45.211429]]]},"properties":{"id":"55449","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.265828,45.211565],[-93.266938,45.128199],[-93.266928,45.124603],[-93.235187,45.124463],[-93.234207,45.211429],[-93.265828,45.211565]]]},"properties":{"id":"55434","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.951209,45.325206],[-93.142287,45.286831],[-93.142421,45.211028],[-93.074226,45.193832],[-92.884501,45.210318],[-92.896992,45.228198],[-92.90698,45.296244],[-92.951209,45.325206]]]},"properties":{"id":"55025","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.266938,45.128199],[-93.362176,45.197547],[-93.373136,45.178133],[-93.328329,45.151465],[-93.298986,45.128892],[-93.296095,45.121897],[-93.266928,45.124603],[-93.266938,45.128199]]]},"properties":{"id":"55433","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.14213,45.376825],[-93.265321,45.384715],[-93.270846,45.384715],[-93.347618,45.370557],[-93.34728,45.302069],[-93.219288,45.297794],[-93.14213,45.376825]]]},"properties":{"id":"55011","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.640437,47.034846],[-95.540358,46.968578],[-95.4137,46.969986],[-95.297422,47.020178],[-95.297252,47.151782],[-95.335496,47.151629],[-95.633709,47.07831],[-95.640437,47.034846]]]},"properties":{"id":"56575","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.407026,47.124209],[-96.407416,47.03124],[-96.299847,47.006172],[-96.130732,47.049769],[-96.066428,47.06444],[-96.109711,47.151669],[-96.152263,47.151425],[-96.322284,47.151321],[-96.407026,47.124209]]]},"properties":{"id":"56585","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.67921,47.264287],[-96.067054,47.209714],[-96.067069,47.151813],[-95.837477,47.096446],[-95.834843,47.096403],[-95.633709,47.07831],[-95.335496,47.151629],[-95.43712,47.21283],[-95.551533,47.257534],[-95.637126,47.208768],[-95.67921,47.264287]]]},"properties":{"id":"56589","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.837477,47.096446],[-95.84859,47.093169],[-95.848349,47.078526],[-95.835372,47.078404],[-95.834843,47.096403],[-95.837477,47.096446]]]},"properties":{"id":"56591","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.758684,45.501772],[-93.450745,45.484144],[-93.440558,45.50071],[-93.409595,45.615597],[-93.492571,45.688663],[-93.666915,45.675261],[-93.728491,45.643674],[-93.76025,45.62617],[-93.821515,45.559932],[-93.819988,45.515686],[-93.758684,45.501772]]]},"properties":{"id":"55371","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.616611,45.471782],[-96.636338,45.658356],[-96.61455,45.70184],[-96.657378,45.742601],[-96.789756,45.673177],[-96.71908,45.60034],[-96.835451,45.586129],[-96.696837,45.421014],[-96.616611,45.471782]]]},"properties":{"id":"56211","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.150559,45.629218],[-96.15091,45.701697],[-96.254081,45.716312],[-96.29547,45.68716],[-96.302064,45.527448],[-96.24127,45.426698],[-96.208526,45.398979],[-96.117036,45.411993],[-96.099695,45.411963],[-96.117772,45.585634],[-96.150559,45.629218]]]},"properties":{"id":"56221","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.623665,44.382359],[-94.572966,44.196014],[-94.369161,44.195942],[-94.311766,44.242778],[-94.250874,44.326944],[-94.276416,44.400342],[-94.491954,44.41318],[-94.623927,44.42673],[-94.623665,44.382359]]]},"properties":{"id":"56073","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.964615,44.533014],[-94.805405,44.465341],[-94.801687,44.62989],[-94.833367,44.65898],[-94.95527,44.629315],[-94.964615,44.533014]]]},"properties":{"id":"55333","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.700444,44.141555],[-94.569699,44.108853],[-94.368848,44.170332],[-94.369161,44.195942],[-94.572966,44.196014],[-94.700444,44.141555]]]},"properties":{"id":"56041","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.518227,44.923969],[-93.599021,44.923485],[-93.705981,44.90741],[-93.706078,44.890662],[-93.637132,44.866964],[-93.597179,44.863459],[-93.523025,44.891466],[-93.518227,44.923969]]]},"properties":{"id":"55331","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.010042,44.855137],[-93.94289,44.855075],[-94.002198,44.949711],[-94.012318,44.9255],[-94.010042,44.855137]]]},"properties":{"id":"55367","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.134286,44.978804],[-94.114163,44.920926],[-94.012318,44.9255],[-94.002198,44.949711],[-94.012236,44.978712],[-94.134286,44.978804]]]},"properties":{"id":"55395","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.880374,45.025994],[-95.77854,45.06495],[-95.884391,45.028972],[-95.880374,45.025994]]]},"properties":{"id":"56295","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.141974,45.558109],[-93.13545,45.485753],[-93.124168,45.471275],[-92.907396,45.437495],[-92.896969,45.437987],[-92.801225,45.491521],[-92.754489,45.523856],[-92.727879,45.520332],[-92.816842,45.560945],[-93.141974,45.558109]]]},"properties":{"id":"55056","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.28753,47.268888],[-95.24894,47.6157],[-95.298379,47.630954],[-95.553537,47.586797],[-95.596797,47.539599],[-95.551074,47.357222],[-95.43712,47.21283],[-95.335496,47.151629],[-95.297252,47.151782],[-95.28753,47.268888]]]},"properties":{"id":"56621","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.827471,46.330149],[-93.885893,46.200112],[-93.810362,46.156585],[-93.796281,46.243199],[-93.810632,46.330547],[-93.827471,46.330149]]]},"properties":{"id":"56450","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.33475,46.64587],[-94.345879,46.631344],[-94.32522,46.63129],[-94.33475,46.64587]]]},"properties":{"id":"56456","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.062067,44.883644],[-93.020162,44.775572],[-93.015961,44.775564],[-93.021395,44.789707],[-93.008428,44.858711],[-93.010076,44.863787],[-93.062067,44.883644]]]},"properties":{"id":"55076","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.157947,44.883524],[-93.155043,44.892499],[-93.178539,44.888338],[-93.179409,44.888307],[-93.157947,44.883524]]]},"properties":{"id":"55150","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.328482,44.717528],[-93.248682,44.717454],[-93.248704,44.731957],[-93.328704,44.74643],[-93.328482,44.717528]]]},"properties":{"id":"55306","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.792528,44.600687],[-92.731993,44.478056],[-92.716407,44.478127],[-92.589029,44.602234],[-92.734647,44.666194],[-92.792528,44.600687]]]},"properties":{"id":"55089","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.798752,44.047307],[-92.67819,44.05791],[-92.678262,44.108059],[-92.728788,44.130647],[-92.758943,44.127155],[-92.798752,44.047307]]]},"properties":{"id":"55955","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.511817,46.093642],[-95.613786,45.875733],[-95.592964,45.875758],[-95.51858,45.888242],[-95.430981,46.041917],[-95.437662,46.060115],[-95.511817,46.093642]]]},"properties":{"id":"56332","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.887954,43.626488],[-93.88843,43.499769],[-93.768498,43.499709],[-93.728059,43.586678],[-93.867179,43.688205],[-93.887493,43.666361],[-93.887954,43.626488]]]},"properties":{"id":"56014","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.247907,43.746122],[-94.24783,43.721176],[-94.23753,43.731999],[-94.247907,43.746122]]]},"properties":{"id":"56047","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.969879,43.608917],[-92.27934,43.547543],[-92.28945,43.54413],[-92.28939,43.500555],[-91.969679,43.500655],[-91.92982,43.61619],[-91.969879,43.608917]]]},"properties":{"id":"55939","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.92982,43.61619],[-91.969679,43.500655],[-91.871121,43.500724],[-91.831958,43.630978],[-91.92982,43.61619]]]},"properties":{"id":"55922","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.578317,43.760513],[-93.614823,43.55782],[-93.518296,43.557258],[-93.456912,43.752996],[-93.548183,43.760426],[-93.578317,43.760513]],[[-93.548296,43.609488],[-93.528392,43.617114],[-93.528418,43.60063],[-93.548296,43.609488]]]},"properties":{"id":"56009","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.169521,43.790336],[-93.27467,43.782059],[-93.289171,43.738411],[-93.159247,43.731983],[-93.1242,43.774691],[-93.169521,43.790336]]]},"properties":{"id":"56045","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.08049,44.390526],[-92.935004,44.370514],[-92.913477,44.386658],[-93.011252,44.511512],[-93.039869,44.500865],[-93.08049,44.390526]]]},"properties":{"id":"55018","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.521826,45.152564],[-93.522125,45.131092],[-93.522079,45.065537],[-93.448133,45.065535],[-93.447624,45.066902],[-93.521826,45.152564]]]},"properties":{"id":"55311","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.268271,44.923327],[-93.267738,44.916111],[-93.233337,44.916367],[-93.243217,44.955547],[-93.268944,44.955525],[-93.26889,44.937767],[-93.268271,44.923327]]]},"properties":{"id":"55407","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.268271,44.923327],[-93.296631,44.922516],[-93.308746,44.915443],[-93.308662,44.890736],[-93.26783,44.890713],[-93.267738,44.916111],[-93.268271,44.923327]]]},"properties":{"id":"55419","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.404528,44.972746],[-93.459971,44.978579],[-93.471583,44.978467],[-93.456246,44.941071],[-93.451415,44.924872],[-93.398428,44.935064],[-93.404528,44.972746]]]},"properties":{"id":"55305","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.40053,45.065582],[-93.400551,45.033314],[-93.360092,45.032768],[-93.360138,45.040043],[-93.361351,45.08725],[-93.361473,45.094448],[-93.402138,45.094582],[-93.40053,45.065582]]]},"properties":{"id":"55428","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.298454,44.861904],[-93.289828,44.800177],[-93.261714,44.809262],[-93.263062,44.861805],[-93.298454,44.861904]]]},"properties":{"id":"55420","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.243217,44.955547],[-93.233337,44.916367],[-93.199811,44.926929],[-93.20266,44.948375],[-93.207757,44.953209],[-93.225775,44.966191],[-93.236164,44.964294],[-93.243217,44.955547]]]},"properties":{"id":"55406","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.638745,45.174363],[-93.635376,45.152542],[-93.635751,45.116704],[-93.522125,45.131092],[-93.521826,45.152564],[-93.525734,45.188519],[-93.524795,45.244518],[-93.552296,45.229446],[-93.638745,45.174363]]]},"properties":{"id":"55374","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.350223,44.889597],[-93.350189,44.85881],[-93.328941,44.858841],[-93.318825,44.861988],[-93.318825,44.8908],[-93.328964,44.887001],[-93.350223,44.889597]]]},"properties":{"id":"55435","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.360649,45.151882],[-93.361473,45.094448],[-93.361351,45.08725],[-93.320017,45.087214],[-93.298986,45.128892],[-93.328329,45.151465],[-93.360649,45.151882]]]},"properties":{"id":"55443","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.392968,43.761466],[-91.291316,43.731541],[-91.26788,43.711609],[-91.343441,43.904564],[-91.48064,43.86881],[-91.392968,43.761466]]]},"properties":{"id":"55947","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.37243,43.664834],[-91.246623,43.500485],[-91.26788,43.711609],[-91.291316,43.731541],[-91.37243,43.664834]]]},"properties":{"id":"55919","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.267696,43.500923],[-91.521635,43.504684],[-91.521091,43.500614],[-91.267696,43.500923]]]},"properties":{"id":"55931","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.90859,47.17822],[-95.045853,47.152782],[-94.918176,47.1685],[-94.90859,47.17822]]]},"properties":{"id":"56458","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.696379,47.156992],[-94.67957,47.15711],[-94.67962,47.16439],[-94.69495,47.16448],[-94.696379,47.156992]]]},"properties":{"id":"56436","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.492571,45.688663],[-93.409595,45.615597],[-93.388906,45.637283],[-93.368554,45.671384],[-93.38912,45.709021],[-93.463884,45.732988],[-93.492571,45.688663]]]},"properties":{"id":"55017","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.476449,47.282237],[-93.448254,47.282534],[-93.448204,47.264584],[-93.428167,47.261355],[-93.43477,47.290693],[-93.476449,47.282237]]]},"properties":{"id":"55722","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.573014,43.790417],[-95.335413,43.63754],[-95.33548,43.645228],[-95.375558,43.743636],[-95.452603,43.791571],[-95.573014,43.790417]]]},"properties":{"id":"56119","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.129925,48.632984],[-97.163355,48.543967],[-97.041707,48.544281],[-97.129925,48.632984]]]},"properties":{"id":"58225","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.451626,44.848683],[-96.451566,44.631349],[-96.337129,44.543595],[-96.235515,44.543285],[-96.093735,44.703026],[-96.0518,44.757385],[-96.093255,44.80476],[-96.174797,44.855314],[-96.31694,44.87764],[-96.451626,44.848683]]]},"properties":{"id":"56220","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.979323,48.247463],[-92.128676,48.083169],[-92.187896,47.8525],[-92.15787,47.80106],[-92.050952,47.801256],[-91.798174,47.716294],[-91.795634,47.685894],[-91.655525,47.784639],[-91.026516,47.888278],[-91.031761,48.188486],[-91.979323,48.247463]]]},"properties":{"id":"55731","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.693918,44.522021],[-93.742967,44.50002],[-93.808621,44.366095],[-93.722553,44.282799],[-93.666607,44.304676],[-93.624816,44.369911],[-93.686688,44.471318],[-93.693918,44.522021]]]},"properties":{"id":"56057","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.877695,44.275619],[-93.76815,44.256062],[-93.722553,44.282799],[-93.808621,44.366095],[-93.879352,44.35186],[-93.877695,44.275619]]]},"properties":{"id":"56017","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.553977,44.486756],[-95.63467,44.38496],[-95.553773,44.355588],[-95.492717,44.3664],[-95.451096,44.45737],[-95.553977,44.486756]]]},"properties":{"id":"56263","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.596797,47.539599],[-95.678517,47.390039],[-95.551074,47.357222],[-95.596797,47.539599]]]},"properties":{"id":"56651","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.131649,47.470171],[-96.067018,47.36869],[-95.86921,47.440976],[-95.938979,47.499125],[-96.067621,47.499136],[-96.131649,47.470171]]]},"properties":{"id":"56516","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.122739,48.420327],[-97.084145,48.05642],[-97.04194,48.151378],[-97.056289,48.296759],[-97.107171,48.413922],[-97.122739,48.420327]]]},"properties":{"id":"56744","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.565969,48.297267],[-96.500843,48.174108],[-96.361402,48.174781],[-96.525666,48.326341],[-96.565969,48.297267]]]},"properties":{"id":"56760","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.694535,43.732566],[-94.664761,43.587701],[-94.614442,43.587571],[-94.552242,43.751149],[-94.592057,43.761065],[-94.694535,43.732566]]]},"properties":{"id":"56181","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.723601,43.819526],[-94.714327,43.819594],[-94.728276,43.833877],[-94.723601,43.819526]]],[[[-94.723601,43.819526],[-94.854528,43.776048],[-94.854546,43.762114],[-94.694535,43.732566],[-94.592057,43.761065],[-94.592271,43.819059],[-94.714327,43.819594],[-94.723601,43.819526]]]]},"properties":{"id":"56176","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.233306,45.421075],[-94.306737,45.20703],[-94.25568,45.166882],[-94.227454,45.19096],[-94.069841,45.365821],[-94.194914,45.429766],[-94.233306,45.421075]]]},"properties":{"id":"55382","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.557141,45.783867],[-93.548976,45.781796],[-93.549237,45.788306],[-93.557141,45.783867]]]},"properties":{"id":"56313","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.546504,46.1543],[-93.558353,46.068134],[-93.473667,46.067907],[-93.546504,46.1543]]]},"properties":{"id":"56386","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.84363,44.08765],[-95.98356,44.1096],[-96.02391,43.94991],[-95.90377,43.9499],[-95.84363,44.08765]]]},"properties":{"id":"56151","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.088501,47.29798],[-96.26934,47.325344],[-96.436234,47.238216],[-96.322284,47.151321],[-96.152263,47.151425],[-96.152063,47.165949],[-96.109635,47.16617],[-96.109711,47.151669],[-96.067069,47.151813],[-96.067054,47.209714],[-96.088501,47.29798]]]},"properties":{"id":"56584","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.55004,44.1875],[-92.601546,44.107801],[-92.573349,44.079312],[-92.469174,44.132892],[-92.470152,44.184983],[-92.485612,44.197843],[-92.55004,44.1875]]]},"properties":{"id":"55960","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.485612,44.197843],[-92.470152,44.184983],[-92.340713,44.233862],[-92.330675,44.298567],[-92.457093,44.318859],[-92.485612,44.197843]]]},"properties":{"id":"55991","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.52852,44.05791],[-92.52855,44.05521],[-92.52312,44.05339],[-92.52402,44.0579],[-92.52852,44.05791]]]},"properties":{"id":"55905","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.573884,46.284151],[-95.584339,46.135967],[-95.567671,46.122333],[-95.520809,46.122661],[-95.375561,46.224158],[-95.573884,46.284151]]]},"properties":{"id":"56588","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.28859,48.00644],[-96.482586,48.021673],[-96.504203,47.93458],[-96.351727,47.7603],[-96.11683,47.803322],[-96.076615,47.835441],[-96.09612,47.963696],[-96.28859,48.00644]]]},"properties":{"id":"56750","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.06249,45.88971],[-93.083983,45.789972],[-93.021946,45.730732],[-92.803971,45.749805],[-92.73665,45.857368],[-92.967737,45.925727],[-93.06249,45.88971]]]},"properties":{"id":"55063","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.265469,44.168211],[-96.184801,44.022926],[-96.06413,44.09526],[-96.245739,44.168956],[-96.265469,44.168211]]]},"properties":{"id":"56139","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.366477,43.914441],[-96.371125,43.907215],[-96.366766,43.90723],[-96.366477,43.914441]]]},"properties":{"id":"56140","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.095432,47.788832],[-95.945203,47.760561],[-95.837177,47.760894],[-95.784919,47.83401],[-95.923617,47.862411],[-96.076615,47.835441],[-96.11683,47.803322],[-96.095432,47.788832]]]},"properties":{"id":"56715","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.706094,45.381035],[-95.654432,45.15135],[-95.53257,45.151559],[-95.357434,45.405291],[-95.357735,45.412471],[-95.377716,45.412897],[-95.582601,45.470221],[-95.644656,45.441468],[-95.706094,45.381035]]]},"properties":{"id":"56215","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.105295,44.951362],[-93.092245,44.943234],[-93.079158,44.9465],[-93.077368,44.957318],[-93.079301,44.955421],[-93.08471,44.954908],[-93.0847,44.957845],[-93.097005,44.955976],[-93.105295,44.951362]]]},"properties":{"id":"55101","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.201207,44.917803],[-93.144941,44.92693],[-93.126219,44.942264],[-93.20266,44.948375],[-93.199811,44.926929],[-93.201207,44.917803]]]},"properties":{"id":"55105","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.381523,44.249706],[-93.404021,44.244621],[-93.393904,44.253258],[-93.464235,44.303345],[-93.484727,44.293344],[-93.52523,44.195975],[-93.376809,44.167414],[-93.353152,44.196208],[-93.381523,44.249706]]]},"properties":{"id":"55052","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.111906,46.794769],[-92.165389,46.760045],[-92.163913,46.758538],[-92.12529,46.74494],[-92.107388,46.74913],[-92.101462,46.788941],[-92.111906,46.794769]]]},"properties":{"id":"55806","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.565056,47.49934],[-92.738535,47.375979],[-92.789683,47.334764],[-92.568427,47.371901],[-92.559698,47.488941],[-92.565056,47.49934]]]},"properties":{"id":"55751","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.292237,46.663308],[-92.191491,46.716241],[-92.207078,46.717917],[-92.301265,46.696091],[-92.301093,46.668042],[-92.292237,46.663308]]]},"properties":{"id":"55808","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.798174,47.716294],[-92.050952,47.801256],[-92.052153,47.628916],[-91.798174,47.716294]]]},"properties":{"id":"55706","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.938536,47.19586],[-92.679938,47.028884],[-92.555229,47.026289],[-92.556498,47.099548],[-92.683871,47.167417],[-92.789314,47.254949],[-92.938536,47.19586]]]},"properties":{"id":"55765","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.458568,44.602013],[-93.283264,44.543961],[-93.399439,44.62657],[-93.458568,44.602013]],[[-93.360217,44.565764],[-93.362413,44.576497],[-93.338578,44.576569],[-93.338756,44.565458],[-93.360217,44.565764]]]},"properties":{"id":"55020","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.747249,45.63295],[-94.753962,45.621852],[-94.747315,45.625573],[-94.747249,45.63295]]]},"properties":{"id":"56356","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.92315,45.75959],[-96.15091,45.701697],[-96.150559,45.629218],[-95.86225,45.759791],[-95.92315,45.75959]]]},"properties":{"id":"56235","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.19961,44.331],[-92.23948,44.300476],[-92.189782,44.238523],[-92.069635,44.193033],[-91.949233,44.23079],[-91.925704,44.291912],[-92.19961,44.331]]]},"properties":{"id":"55945","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.983973,44.851139],[-93.008428,44.858711],[-93.021395,44.789707],[-92.983973,44.851139]]]},"properties":{"id":"55071","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.020044,44.89075],[-93.010076,44.863787],[-93.008428,44.858711],[-92.983973,44.851139],[-92.983999,44.861865],[-92.979666,44.890823],[-92.994282,44.890792],[-93.020044,44.89075]]]},"properties":{"id":"55055","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.959818,45.052938],[-92.954876,45.050167],[-92.954834,45.056291],[-92.959795,45.056287],[-92.959818,45.052938]]]},"properties":{"id":"55090","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.48064,43.86881],[-91.343441,43.904564],[-91.364321,43.933451],[-91.55021,43.92996],[-91.48064,43.86881]]]},"properties":{"id":"55925","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.868887,43.863806],[-91.74002,43.94052],[-91.856112,44.048],[-91.8993,44.02063],[-91.914571,43.866966],[-91.868887,43.863806]]]},"properties":{"id":"55952","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.523316,44.541968],[-95.401118,44.541777],[-95.360177,44.69854],[-95.422177,44.732],[-95.482817,44.701834],[-95.523316,44.541968]]]},"properties":{"id":"56237","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.775257,47.030418],[-93.677372,46.856194],[-93.475322,46.946778],[-93.480962,47.028832],[-93.693646,47.109394],[-93.774191,47.109595],[-93.775257,47.030418]]]},"properties":{"id":"55748","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.677372,46.856194],[-93.775257,47.030418],[-93.901972,46.889239],[-93.776024,46.802891],[-93.776912,46.771133],[-93.677372,46.856194]]]},"properties":{"id":"55785","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.061014,46.789542],[-93.058266,46.910601],[-93.360199,46.85475],[-93.46474,46.637894],[-93.37031,46.418712],[-93.107103,46.446665],[-93.055168,46.506888],[-93.054765,46.593239],[-93.061014,46.789542]]]},"properties":{"id":"55760","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.553305,46.246816],[-93.546504,46.1543],[-93.473667,46.067907],[-93.432794,46.010538],[-93.216164,46.107514],[-93.179356,46.115344],[-93.178985,46.15855],[-93.220593,46.1587],[-93.431107,46.333292],[-93.553305,46.246816]]]},"properties":{"id":"56342","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.282094,45.069493],[-93.282696,45.032427],[-93.208092,45.035744],[-93.227236,45.064982],[-93.282094,45.069493]]]},"properties":{"id":"55421","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.54417,47.522791],[-94.486462,47.496141],[-94.545521,47.58345],[-94.54417,47.522791]]],[[[-94.416811,47.636328],[-94.160549,47.67264],[-94.547968,47.874638],[-94.734168,47.950511],[-94.806249,47.803539],[-94.806478,47.802427],[-94.697218,47.790655],[-94.545538,47.584529],[-94.545241,47.584534],[-94.416811,47.636328]]]]},"properties":{"id":"56630","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.126633,47.583828],[-95.169522,47.277465],[-95.015656,47.325129],[-95.126633,47.583828]]]},"properties":{"id":"56678","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.002355,47.500957],[-94.988366,47.498386],[-94.983612,47.483905],[-94.970196,47.49631],[-95.020638,47.51008],[-95.02119,47.49844],[-95.002355,47.500957]]]},"properties":{"id":"56687","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.385634,47.804671],[-95.298379,47.630954],[-95.24894,47.6157],[-95.193905,47.8097],[-95.39907,47.82338],[-95.385634,47.804671]]]},"properties":{"id":"56652","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.241533,45.297518],[-96.39466,45.21141],[-96.24787,45.19694],[-96.226171,45.219492],[-96.241533,45.297518]]]},"properties":{"id":"56276","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.908756,44.196159],[-93.848654,44.109028],[-93.788208,44.163282],[-93.908756,44.196159]]]},"properties":{"id":"56024","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.109257,43.957279],[-94.348978,43.906044],[-94.26721,43.84797],[-94.078826,43.848073],[-94.028033,43.848007],[-94.070742,43.934994],[-94.109257,43.957279]]]},"properties":{"id":"56010","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.803268,46.495038],[-92.863296,46.57049],[-93.00675,46.593294],[-93.054765,46.593239],[-93.055168,46.506888],[-92.887261,46.419072],[-92.803268,46.495038]]]},"properties":{"id":"55757","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.34101,46.765028],[-92.419766,46.69955],[-92.301093,46.668042],[-92.301265,46.696091],[-92.34101,46.765028]]]},"properties":{"id":"55733","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.589233,46.63514],[-92.706402,46.736656],[-92.734433,46.65013],[-92.589233,46.63514]]]},"properties":{"id":"55780","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.520735,44.81676],[-93.597179,44.863459],[-93.637132,44.866964],[-93.693214,44.861445],[-93.71907,44.804607],[-93.672758,44.7553],[-93.594334,44.776662],[-93.520431,44.804263],[-93.520735,44.81676]]]},"properties":{"id":"55318","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.808954,44.695358],[-93.88881,44.67702],[-93.91937,44.66606],[-93.910405,44.543163],[-93.742967,44.50002],[-93.693918,44.522021],[-93.623802,44.594438],[-93.67894,44.68689],[-93.774486,44.691732],[-93.808954,44.695358]]]},"properties":{"id":"56011","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.15408,46.862541],[-94.178465,47.165648],[-94.285808,47.164041],[-94.321067,47.005744],[-94.331206,46.892115],[-94.321355,46.883049],[-94.15408,46.862541]]]},"properties":{"id":"56655","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.239655,46.564627],[-95.030146,46.49928],[-94.765407,46.630066],[-94.702193,46.716473],[-94.786953,46.777232],[-95.28824,46.683866],[-95.239655,46.564627]]]},"properties":{"id":"56477","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.801225,45.491521],[-92.896969,45.437987],[-92.839414,45.296339],[-92.75616,45.310484],[-92.766933,45.354575],[-92.801225,45.491521]]]},"properties":{"id":"55045","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.768628,45.469653],[-92.67039,45.388133],[-92.727879,45.520332],[-92.754489,45.523856],[-92.768628,45.469653]]]},"properties":{"id":"55084","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.907396,45.437495],[-92.958054,45.36574],[-92.951209,45.325206],[-92.90698,45.296244],[-92.839414,45.296339],[-92.896969,45.437987],[-92.907396,45.437495]]]},"properties":{"id":"55013","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.141988,45.614093],[-93.059611,45.633303],[-93.080497,45.692069],[-93.368554,45.671384],[-93.388906,45.637283],[-93.203724,45.639592],[-93.193379,45.639612],[-93.141988,45.614093]]]},"properties":{"id":"55080","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.758332,46.673752],[-96.758205,46.644782],[-96.737105,46.659395],[-96.758332,46.673752]]]},"properties":{"id":"56525","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.551533,47.257534],[-95.43712,47.21283],[-95.551074,47.357222],[-95.678517,47.390039],[-95.86921,47.440976],[-96.067018,47.36869],[-96.088501,47.29798],[-96.067054,47.209714],[-95.67921,47.264287],[-95.551533,47.257534]]]},"properties":{"id":"56557","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.20784,44.325614],[-95.349856,44.354704],[-95.370162,44.326598],[-95.349669,44.296954],[-95.341726,44.122943],[-95.31106,44.086665],[-95.260656,44.079605],[-95.200704,44.108161],[-95.207999,44.311081],[-95.218204,44.311123],[-95.218072,44.318386],[-95.20835,44.318364],[-95.20784,44.325614]]]},"properties":{"id":"56152","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.314015,46.295887],[-94.327506,46.340987],[-94.327285,46.289746],[-94.314015,46.295887]]]},"properties":{"id":"56425","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.198525,46.544505],[-94.178554,46.456378],[-94.072957,46.453793],[-94.091592,46.490589],[-94.12099,46.61026],[-94.141728,46.606028],[-94.198525,46.544505]]]},"properties":{"id":"56465","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.175046,46.700254],[-94.141728,46.606028],[-94.12099,46.61026],[-94.03034,46.660096],[-94.05077,46.72241],[-94.15462,46.740178],[-94.175046,46.700254]]]},"properties":{"id":"56442","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.95771,46.47468],[-94.091592,46.490589],[-94.072957,46.453793],[-93.936739,46.417043],[-93.95771,46.47468]]]},"properties":{"id":"56455","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.207756,44.843626],[-93.164102,44.833601],[-93.105824,44.827909],[-93.105832,44.861167],[-93.106144,44.861289],[-93.197918,44.861977],[-93.207756,44.843626]]]},"properties":{"id":"55121","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.062067,44.883644],[-93.010076,44.863787],[-93.020044,44.89075],[-93.050888,44.919501],[-93.065276,44.919527],[-93.065271,44.884729],[-93.062067,44.883644]]]},"properties":{"id":"55075","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.945047,43.935698],[-92.965284,43.96476],[-93.103835,43.981335],[-93.237854,43.92286],[-93.169521,43.790336],[-93.1242,43.774691],[-92.950045,43.797952],[-92.934682,43.848565],[-92.945047,43.935698]]]},"properties":{"id":"55917","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.567671,46.122333],[-95.712627,45.886051],[-95.696543,45.847471],[-95.613786,45.875733],[-95.511817,46.093642],[-95.520809,46.122661],[-95.567671,46.122333]]]},"properties":{"id":"56315","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.696543,45.847471],[-95.84122,45.75976],[-95.779176,45.716336],[-95.737404,45.708762],[-95.592964,45.875758],[-95.613786,45.875733],[-95.696543,45.847471]]]},"properties":{"id":"56343","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.510157,45.672594],[-95.377716,45.412897],[-95.357735,45.412471],[-95.291383,45.412682],[-95.150662,45.614025],[-95.330436,45.758858],[-95.387946,45.759365],[-95.510157,45.672594]]]},"properties":{"id":"56334","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.13967,45.758891],[-95.242999,45.787775],[-95.330436,45.758858],[-95.150662,45.614025],[-95.13961,45.613965],[-95.13967,45.758891]]]},"properties":{"id":"56385","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.028033,43.848007],[-94.078826,43.848073],[-94.087819,43.717111],[-93.967274,43.702423],[-93.948801,43.848226],[-94.028033,43.848007]]]},"properties":{"id":"56023","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.95011,43.82518],[-91.817683,43.645041],[-91.75737,43.67071],[-91.730725,43.6736],[-91.730571,43.695181],[-91.868887,43.863806],[-91.914571,43.866966],[-91.95011,43.82518]]]},"properties":{"id":"55962","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.817683,43.645041],[-91.95011,43.82518],[-92.02529,43.82259],[-92.024364,43.78105],[-92.04417,43.73162],[-91.969879,43.608917],[-91.92982,43.61619],[-91.831958,43.630978],[-91.817683,43.645041]]]},"properties":{"id":"55949","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.40947,43.55841],[-92.529858,43.674144],[-92.545433,43.601699],[-92.40947,43.55841]]]},"properties":{"id":"55961","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.548183,43.760426],[-93.57445,43.766791],[-93.578317,43.760513],[-93.548183,43.760426]]]},"properties":{"id":"56032","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.41351,43.564761],[-93.446745,43.556807],[-93.428714,43.556734],[-93.41351,43.564761]]]},"properties":{"id":"56089","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.389903,43.848131],[-93.426565,43.964083],[-93.627143,43.943803],[-93.677384,43.876965],[-93.588186,43.811348],[-93.389903,43.848131]]]},"properties":{"id":"56072","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.766817,44.344529],[-92.83574,44.341381],[-92.823426,44.225648],[-92.795387,44.211342],[-92.763268,44.267018],[-92.766595,44.344688],[-92.766817,44.344529]]]},"properties":{"id":"55983","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.353825,44.510452],[-92.313625,44.536873],[-92.317665,44.542802],[-92.353825,44.510452]]]},"properties":{"id":"55026","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.923527,45.809477],[-95.758639,45.904925],[-95.76956,45.93368],[-96.00673,45.84666],[-95.923527,45.809477]]]},"properties":{"id":"56311","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.13045,45.87581],[-96.31682,45.84639],[-96.254081,45.716312],[-96.15091,45.701697],[-95.92315,45.75959],[-95.923527,45.809477],[-96.00673,45.84666],[-96.13045,45.87581]]]},"properties":{"id":"56248","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.279709,44.980167],[-93.283911,44.962703],[-93.270565,44.972219],[-93.27256,44.979618],[-93.279709,44.980167]]]},"properties":{"id":"55403","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.27256,44.979618],[-93.270565,44.972219],[-93.267593,44.973621],[-93.266518,44.97707],[-93.27256,44.979618]]]},"properties":{"id":"55402","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.20754,45.006154],[-93.274425,45.005765],[-93.273645,44.992864],[-93.259618,44.986892],[-93.207558,44.991684],[-93.20754,45.006154]]]},"properties":{"id":"55413","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.308662,44.890736],[-93.318825,44.8908],[-93.318825,44.861988],[-93.298454,44.861904],[-93.263062,44.861805],[-93.237573,44.861789],[-93.246986,44.890564],[-93.26783,44.890713],[-93.308662,44.890736]]]},"properties":{"id":"55423","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.328572,44.977142],[-93.305122,44.951138],[-93.283608,44.955538],[-93.283911,44.962703],[-93.279709,44.980167],[-93.285332,44.984387],[-93.318424,44.983977],[-93.328572,44.977142]]]},"properties":{"id":"55405","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.398697,44.836803],[-93.485396,44.862339],[-93.520632,44.858473],[-93.520735,44.81676],[-93.520431,44.804263],[-93.398827,44.798763],[-93.398697,44.836803]]]},"properties":{"id":"55347","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.349613,44.920366],[-93.350223,44.889597],[-93.328964,44.887001],[-93.331627,44.923397],[-93.349613,44.920366]]]},"properties":{"id":"55424","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.203724,45.639592],[-93.195187,45.636649],[-93.193379,45.639612],[-93.203724,45.639592]]]},"properties":{"id":"55029","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-93.43477,47.290693],[-93.428167,47.261355],[-93.448204,47.264584],[-93.448254,47.282534],[-93.476449,47.282237],[-93.32135,47.19642],[-93.228516,47.195587],[-93.251867,47.324861],[-93.284404,47.316964],[-93.36357,47.31571],[-93.424466,47.308662],[-93.43477,47.290693]]],[[[-93.296431,47.645667],[-93.479353,47.580189],[-93.577482,47.484031],[-93.5777,47.458031],[-93.410836,47.326191],[-93.36719,47.32624],[-93.319726,47.340527],[-93.319533,47.356407],[-93.19536,47.63311],[-93.19534,47.65903],[-93.296431,47.645667]]]]},"properties":{"id":"55709","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.251867,47.324861],[-93.228516,47.195587],[-93.193791,47.196011],[-93.070334,47.283024],[-93.091993,47.382063],[-93.319533,47.356407],[-93.319726,47.340527],[-93.259757,47.327687],[-93.251867,47.324861]]]},"properties":{"id":"55775","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.736796,47.567922],[-93.577482,47.484031],[-93.479353,47.580189],[-93.736796,47.567922]]]},"properties":{"id":"56657","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.821479,47.66498],[-93.903306,47.586186],[-93.76986,47.60133],[-93.821479,47.66498]]]},"properties":{"id":"56637","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.06544,47.65269],[-94.035816,47.589],[-94.036175,47.636456],[-94.06544,47.65269]]]},"properties":{"id":"56659","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.955159,48.543699],[-96.955269,48.587437],[-96.867052,48.601661],[-96.867871,48.543312],[-96.758626,48.543592],[-96.693573,48.616064],[-96.69388,48.645622],[-96.823498,48.702931],[-97.123024,48.717143],[-97.129925,48.632984],[-97.041707,48.544281],[-96.955159,48.543699]]]},"properties":{"id":"56733","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.348398,48.614723],[-93.348898,48.608906],[-93.343431,48.609061],[-93.343613,48.612348],[-93.348398,48.614723]]]},"properties":{"id":"56668","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.13673,49.374996],[-95.125898,49.345099],[-94.970554,49.334998],[-94.982418,49.287472],[-94.906237,49.258068],[-94.915571,49.325791],[-94.966682,49.363764],[-95.058375,49.35313],[-95.13673,49.374996]]]},"properties":{"id":"56711","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.623802,44.594438],[-93.693918,44.522021],[-93.686688,44.471318],[-93.491447,44.471474],[-93.475588,44.529415],[-93.47096,44.587164],[-93.501324,44.601689],[-93.623802,44.594438]]]},"properties":{"id":"56071","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.129643,44.44232],[-93.879352,44.35186],[-93.808621,44.366095],[-93.742967,44.50002],[-93.910405,44.543163],[-94.07024,44.50026],[-94.13002,44.50028],[-94.129643,44.44232]]]},"properties":{"id":"56058","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.624816,44.369911],[-93.666607,44.304676],[-93.484727,44.293344],[-93.464235,44.303345],[-93.46347,44.356377],[-93.624816,44.369911]]]},"properties":{"id":"56052","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.194642,44.543377],[-96.093735,44.703026],[-96.235515,44.543285],[-96.194642,44.543377]]]},"properties":{"id":"56280","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.011779,44.731806],[-96.11011,44.517466],[-96.100237,44.459286],[-96.07936,44.41533],[-96.0393,44.41515],[-95.95838,44.45807],[-95.862926,44.543411],[-95.84945,44.58698],[-95.890063,44.65985],[-95.951335,44.732221],[-96.011779,44.731806]]]},"properties":{"id":"56264","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.98356,44.1096],[-95.84363,44.08765],[-95.82339,44.12382],[-95.7756,44.29746],[-95.81612,44.312],[-95.89694,44.3265],[-96.00799,44.254328],[-96.0037,44.10966],[-95.98356,44.1096]]]},"properties":{"id":"56115","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.797601,48.540506],[-96.276544,48.456684],[-96.151127,48.285766],[-95.981147,48.300029],[-95.980624,48.300136],[-95.86194,48.392666],[-95.797601,48.540506]]]},"properties":{"id":"56737","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.998673,48.15154],[-96.824698,48.050033],[-96.500888,48.035645],[-96.500843,48.174108],[-96.565969,48.297267],[-96.95673,48.281922],[-96.998673,48.15154]]]},"properties":{"id":"56762","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.649281,48.427364],[-97.107171,48.413922],[-97.056289,48.296759],[-96.95673,48.281922],[-96.565969,48.297267],[-96.525666,48.326341],[-96.533663,48.384134],[-96.649281,48.427364]]]},"properties":{"id":"56713","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.714327,43.819594],[-94.592271,43.819059],[-94.614371,43.848178],[-94.719485,43.870046],[-94.728276,43.833877],[-94.714327,43.819594]]]},"properties":{"id":"56162","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.271776,45.775208],[-94.431089,45.858297],[-94.513629,45.865654],[-94.551002,45.816591],[-94.315098,45.77521],[-94.271776,45.775208]]]},"properties":{"id":"56314","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.669799,43.833731],[-92.623551,43.666544],[-92.609225,43.616002],[-92.609013,43.586924],[-92.545433,43.601699],[-92.529858,43.674144],[-92.484426,43.725082],[-92.609649,43.833876],[-92.669799,43.833731]]]},"properties":{"id":"55936","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.54334,44.16687],[-95.703414,44.11414],[-95.62267,44.00769],[-95.56291,44.05092],[-95.56297,44.06556],[-95.54279,44.06542],[-95.54275,44.05092],[-95.48242,44.10885],[-95.54334,44.16687]]]},"properties":{"id":"56123","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.56291,44.05092],[-95.54275,44.05092],[-95.54279,44.06542],[-95.56297,44.06556],[-95.56291,44.05092]]]},"properties":{"id":"56125","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.15445,43.50019],[-95.903591,43.500035],[-96.053651,43.578445],[-96.133214,43.558451],[-96.17316,43.54367],[-96.15445,43.50019]]]},"properties":{"id":"56129","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.07308,43.68872],[-96.11344,43.58724],[-96.09348,43.58722],[-96.09335,43.57292],[-96.11342,43.57288],[-96.133214,43.558451],[-96.053651,43.578445],[-96.012898,43.717392],[-96.07308,43.68872]]]},"properties":{"id":"56158","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.840122,47.150475],[-96.648565,47.151356],[-96.580099,47.194888],[-96.623107,47.22897],[-96.833393,47.238041],[-96.840122,47.150475]]]},"properties":{"id":"56574","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.31897,44.09326],[-92.21931,44.09297],[-92.290622,44.193923],[-92.350206,44.194191],[-92.31897,44.09326]]]},"properties":{"id":"55932","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.962913,46.486575],[-95.873937,46.434202],[-95.820252,46.441148],[-95.688705,46.520163],[-95.725296,46.605842],[-95.754566,46.628971],[-95.906634,46.564606],[-95.962913,46.486575]]]},"properties":{"id":"56528","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.584695,46.368979],[-95.655911,46.293812],[-95.573884,46.284151],[-95.375561,46.224158],[-95.279526,46.289508],[-95.279565,46.296863],[-95.429507,46.400526],[-95.584695,46.368979]]]},"properties":{"id":"56551","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.371297,46.644041],[-95.532493,46.488881],[-95.407659,46.406661],[-95.322042,46.436729],[-95.238286,46.535671],[-95.239655,46.564627],[-95.28824,46.683866],[-95.371297,46.644041]]]},"properties":{"id":"56567","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.99852,46.10842],[-95.89619,46.1083],[-95.77004,46.165644],[-95.805867,46.212831],[-95.896136,46.224609],[-95.99852,46.10842]]]},"properties":{"id":"56324","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.197797,46.399208],[-96.123773,46.412972],[-96.155845,46.456649],[-96.197797,46.399208]]]},"properties":{"id":"56533","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.84761,46.158016],[-92.63644,46.15754],[-92.76992,46.281641],[-92.855852,46.244796],[-92.84761,46.158016]]]},"properties":{"id":"55704","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.759986,47.716992],[-96.676498,47.890872],[-96.761661,47.90569],[-96.934707,47.766794],[-96.937853,47.760237],[-96.759986,47.716992]]]},"properties":{"id":"56723","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.676498,47.890872],[-96.759986,47.716992],[-96.728899,47.586022],[-96.421374,47.655283],[-96.330728,47.70196],[-96.351727,47.7603],[-96.504203,47.93458],[-96.676498,47.890872]]]},"properties":{"id":"56716","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.126315,44.94943],[-93.126219,44.942264],[-93.144941,44.92693],[-93.138608,44.903896],[-93.128629,44.919648],[-93.092245,44.943234],[-93.105295,44.951362],[-93.126315,44.94943]]]},"properties":{"id":"55102","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.20777,44.959664],[-93.167043,44.970268],[-93.207678,44.97574],[-93.20777,44.959664]]]},"properties":{"id":"55114","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.320748,44.479741],[-95.370075,44.442468],[-95.349856,44.354704],[-95.20784,44.325614],[-95.127277,44.369054],[-95.1276,44.40555],[-95.289908,44.515565],[-95.350146,44.500659],[-95.330831,44.483583],[-95.320748,44.479741]]]},"properties":{"id":"56293","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.874385,44.892275],[-94.95527,44.629315],[-94.833367,44.65898],[-94.793541,44.892176],[-94.874385,44.892275]]]},"properties":{"id":"55310","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.016263,44.891689],[-95.096781,44.686966],[-95.014922,44.629038],[-94.95527,44.629315],[-94.874385,44.892275],[-94.982861,44.891908],[-95.016263,44.891689]]]},"properties":{"id":"56277","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.381523,44.249706],[-93.393904,44.253258],[-93.404021,44.244621],[-93.381523,44.249706]]]},"properties":{"id":"55087","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.573078,44.166496],[-93.647252,43.97489],[-93.627143,43.943803],[-93.426565,43.964083],[-93.380466,44.007064],[-93.376908,44.152967],[-93.376809,44.167414],[-93.52523,44.195975],[-93.573078,44.166496]]]},"properties":{"id":"56093","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.951061,47.719371],[-92.881117,47.64257],[-92.574159,47.671378],[-92.560748,47.696574],[-92.951061,47.719371]]]},"properties":{"id":"55703","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.191491,46.716241],[-92.12529,46.74494],[-92.163913,46.758538],[-92.207078,46.717917],[-92.191491,46.716241]]]},"properties":{"id":"55807","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.789394,47.496933],[-91.949017,47.415817],[-92.047181,47.26776],[-92.047512,47.243243],[-91.830646,47.198788],[-91.789996,47.437961],[-91.789394,47.496933]]]},"properties":{"id":"55602","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.199544,46.834395],[-92.195863,46.833349],[-92.195907,46.835743],[-92.199544,46.834395]]]},"properties":{"id":"55814","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.753962,45.621852],[-94.762123,45.52811],[-94.690984,45.538111],[-94.54954,45.77401],[-94.58558,45.77425],[-94.64399,45.77395],[-94.729777,45.761015],[-94.747249,45.63295],[-94.747315,45.625573],[-94.753962,45.621852]]]},"properties":{"id":"56331","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.85452,45.59954],[-94.859759,45.607391],[-94.870386,45.603998],[-94.870327,45.596295],[-94.85452,45.59954]]]},"properties":{"id":"56335","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.990053,46.216002],[-95.021779,46.04845],[-94.964306,46.048601],[-94.895346,46.230556],[-94.91033,46.23035],[-94.990053,46.216002]]]},"properties":{"id":"56440","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.717927,43.949473],[-93.697487,43.876952],[-93.677384,43.876965],[-93.627143,43.943803],[-93.647252,43.97489],[-93.697499,43.949498],[-93.717927,43.949473]]]},"properties":{"id":"56091","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.878268,45.180574],[-92.958088,45.123665],[-92.945204,45.108348],[-92.943011,45.036086],[-92.842822,44.948785],[-92.80912,44.949008],[-92.769504,44.974267],[-92.770308,44.980346],[-92.79479,45.008376],[-92.764604,45.028767],[-92.756681,45.151309],[-92.878268,45.180574]]]},"properties":{"id":"55082","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.93453,44.948833],[-92.903416,44.948731],[-92.862673,44.948805],[-92.842822,44.948785],[-92.943011,45.036086],[-92.943081,45.035902],[-92.93453,44.948833]]]},"properties":{"id":"55042","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.767932,44.904021],[-92.80912,44.949008],[-92.842822,44.948785],[-92.862673,44.948805],[-92.862411,44.861944],[-92.862551,44.847859],[-92.769495,44.858191],[-92.767932,44.904021]]]},"properties":{"id":"55001","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.80912,44.949008],[-92.767932,44.904021],[-92.769504,44.974267],[-92.80912,44.949008]]]},"properties":{"id":"55043","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.841801,44.050445],[-94.834937,44.050579],[-94.839427,44.057935],[-94.841801,44.050445]]]},"properties":{"id":"56022","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.738508,46.498866],[-96.656465,46.369273],[-96.48725,46.38387],[-96.52963,46.44214],[-96.738508,46.498866]]]},"properties":{"id":"56553","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.707586,44.104001],[-91.821616,44.063628],[-91.856112,44.048],[-91.74002,43.94052],[-91.685063,43.877035],[-91.55021,43.92996],[-91.364321,43.933451],[-91.707586,44.104001]]]},"properties":{"id":"55987","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.821616,44.063628],[-91.707586,44.104001],[-91.856486,44.192131],[-91.86845,44.13605],[-91.821616,44.063628]]]},"properties":{"id":"55959","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.920869,45.28214],[-94.012282,45.152051],[-93.997871,45.139397],[-93.913403,45.115384],[-93.857684,45.082882],[-93.796167,45.10747],[-93.698894,45.137598],[-93.681241,45.151263],[-93.696242,45.159079],[-93.765814,45.237876],[-93.920869,45.28214]]]},"properties":{"id":"55313","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.658677,45.28515],[-93.70427,45.246507],[-93.628709,45.237999],[-93.658677,45.28515]]]},"properties":{"id":"55301","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.111698,45.382342],[-92.958054,45.36574],[-92.907396,45.437495],[-93.124168,45.471275],[-93.142138,45.413642],[-93.111698,45.382342]]]},"properties":{"id":"55079","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.357414,45.212207],[-93.265828,45.211565],[-93.234207,45.211429],[-93.142421,45.211028],[-93.142287,45.286831],[-93.219288,45.297794],[-93.34728,45.302069],[-93.357414,45.212207]]]},"properties":{"id":"55304","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-93.270846,45.384715],[-93.347477,45.377835],[-93.347618,45.370557],[-93.270846,45.384715]]],[[[-93.265321,45.384715],[-93.14213,45.376825],[-93.111698,45.382342],[-93.142138,45.413642],[-93.271645,45.413665],[-93.265321,45.384715]]]]},"properties":{"id":"55005","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.958088,45.123665],[-92.878268,45.180574],[-92.884501,45.210318],[-93.074226,45.193832],[-93.096075,45.133696],[-93.097036,45.119103],[-93.049572,45.124],[-92.958088,45.123665]]]},"properties":{"id":"55038","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.464669,45.413921],[-93.42704,45.384974],[-93.347477,45.377835],[-93.270846,45.384715],[-93.265321,45.384715],[-93.271645,45.413665],[-93.450324,45.471676],[-93.464669,45.413921]]]},"properties":{"id":"55070","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.984792,45.034223],[-92.945204,45.108348],[-92.958088,45.123665],[-93.049572,45.124],[-93.062276,45.0397],[-92.984792,45.034223]]]},"properties":{"id":"55110","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.540358,46.968578],[-95.640437,47.034846],[-95.705657,46.985493],[-95.794499,46.977281],[-95.79439,46.970147],[-95.626398,46.844575],[-95.619648,46.84641],[-95.540358,46.968578]]]},"properties":{"id":"56578","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.806478,47.802427],[-94.805333,47.672409],[-94.671872,47.636888],[-94.697218,47.790655],[-94.806478,47.802427]]]},"properties":{"id":"56683","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.277167,48.020034],[-95.34254,48.540211],[-95.462,48.539732],[-95.602315,48.538887],[-95.765236,48.32632],[-95.851965,48.295063],[-95.582887,48.020557],[-95.277167,48.020034]]]},"properties":{"id":"56727","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.725227,47.960188],[-94.721342,48.061134],[-94.771113,47.889084],[-94.725227,47.960188]]]},"properties":{"id":"56666","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.069386,45.735651],[-93.965609,45.559567],[-93.922369,45.559898],[-93.821515,45.559932],[-93.76025,45.62617],[-93.824287,45.779228],[-93.763948,45.822143],[-93.766649,45.865822],[-93.937474,45.853236],[-94.000914,45.861429],[-94.010432,45.823981],[-94.069386,45.735651]]]},"properties":{"id":"56329","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.184184,45.679983],[-94.069386,45.735651],[-94.010432,45.823981],[-94.271776,45.775208],[-94.315098,45.77521],[-94.34719,45.71738],[-94.2857,45.717401],[-94.259481,45.692649],[-94.184184,45.679983]]]},"properties":{"id":"56367","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.107743,45.472368],[-93.922369,45.559898],[-93.965609,45.559567],[-94.165368,45.573766],[-94.154633,45.562513],[-94.107743,45.472368]]]},"properties":{"id":"56304","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.322275,45.527414],[-96.616611,45.471782],[-96.696837,45.421014],[-96.649725,45.410303],[-96.24127,45.426698],[-96.302064,45.527448],[-96.322275,45.527414]]]},"properties":{"id":"56225","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.208526,45.398979],[-96.241533,45.297518],[-96.226171,45.219492],[-96.103614,45.176675],[-96.117036,45.411993],[-96.208526,45.398979]]]},"properties":{"id":"56227","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.649725,45.410303],[-96.452397,45.202914],[-96.39466,45.21141],[-96.241533,45.297518],[-96.208526,45.398979],[-96.24127,45.426698],[-96.649725,45.410303]]]},"properties":{"id":"56278","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.29547,45.68716],[-96.61455,45.70184],[-96.57315,45.65812],[-96.322275,45.527414],[-96.302064,45.527448],[-96.29547,45.68716]]]},"properties":{"id":"56236","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.128673,44.083755],[-93.946457,44.021973],[-93.888405,44.022107],[-93.856942,44.076369],[-93.859917,44.083954],[-93.853479,44.08728],[-93.848654,44.109028],[-93.908756,44.196159],[-93.929086,44.239819],[-94.011834,44.239192],[-94.129116,44.184886],[-94.128183,44.09038],[-94.128673,44.083755]]]},"properties":{"id":"56001","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.888405,44.022107],[-93.946457,44.021973],[-94.070742,43.934994],[-94.028033,43.848007],[-93.948801,43.848226],[-93.888167,43.848116],[-93.768107,43.964026],[-93.838617,44.008724],[-93.888405,44.022107]]]},"properties":{"id":"56065","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.805405,44.465341],[-94.964615,44.533014],[-94.996619,44.485682],[-95.08803,44.427406],[-94.976852,44.340583],[-94.946936,44.311555],[-94.756249,44.447331],[-94.805405,44.465341]]]},"properties":{"id":"56266","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.589233,46.63514],[-92.449054,46.573239],[-92.291976,46.655134],[-92.292237,46.663308],[-92.301093,46.668042],[-92.419766,46.69955],[-92.589233,46.63514]]]},"properties":{"id":"55718","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.34101,46.765028],[-92.357921,46.843731],[-92.57589,46.849528],[-92.711902,46.768378],[-92.706402,46.736656],[-92.589233,46.63514],[-92.419766,46.69955],[-92.34101,46.765028]]]},"properties":{"id":"55720","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.672758,44.7553],[-93.774486,44.691732],[-93.67894,44.68689],[-93.626735,44.718462],[-93.594334,44.776662],[-93.672758,44.7553]]]},"properties":{"id":"55315","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.331206,46.892115],[-94.659387,46.890702],[-94.72253,46.804061],[-94.786953,46.777232],[-94.702193,46.716473],[-94.660948,46.716675],[-94.321355,46.883049],[-94.331206,46.892115]]]},"properties":{"id":"56435","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.327506,46.340987],[-94.314015,46.295887],[-94.1036,46.17073],[-93.95773,46.17103],[-93.885893,46.200112],[-93.827471,46.330149],[-93.936739,46.417043],[-94.072957,46.453793],[-94.178554,46.456378],[-94.419045,46.397558],[-94.327506,46.340987]]]},"properties":{"id":"56401","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.663794,47.063626],[-94.596318,47.030643],[-94.321067,47.005744],[-94.285808,47.164041],[-94.354089,47.199544],[-94.620911,47.236267],[-94.663794,47.063626]]]},"properties":{"id":"56484","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.431428,45.079533],[-95.53317,45.111507],[-95.599044,44.89826],[-95.4348,44.833092],[-95.381906,44.891081],[-95.431428,45.079533]]]},"properties":{"id":"56260","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.796709,45.180792],[-95.980339,45.097574],[-95.884391,45.028972],[-95.77854,45.06495],[-95.73554,45.151428],[-95.796709,45.180792]]]},"properties":{"id":"56262","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.840122,47.150475],[-96.821708,47.063975],[-96.689898,47.049626],[-96.648312,47.078943],[-96.648565,47.151356],[-96.840122,47.150475]]]},"properties":{"id":"56546","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.768553,47.936415],[-90.766372,47.609555],[-90.522413,47.707049],[-90.768553,47.936415]]]},"properties":{"id":"55612","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.752647,43.790485],[-95.650934,43.761627],[-95.573014,43.790417],[-95.452603,43.791571],[-95.380496,43.93448],[-95.521692,43.949479],[-95.70341,43.92055],[-95.70357,43.90607],[-95.792785,43.830324],[-95.752647,43.790485]]]},"properties":{"id":"56131","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.553773,44.355588],[-95.54334,44.16687],[-95.48242,44.10885],[-95.402076,44.123065],[-95.349669,44.296954],[-95.370162,44.326598],[-95.492717,44.3664],[-95.553773,44.355588]]]},"properties":{"id":"56180","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.157947,44.883524],[-93.106144,44.861289],[-93.105832,44.861167],[-93.065271,44.884729],[-93.065276,44.919527],[-93.128629,44.919648],[-93.138608,44.903896],[-93.155043,44.892499],[-93.157947,44.883524]]]},"properties":{"id":"55118","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.913477,44.386658],[-92.850836,44.355726],[-92.797061,44.369958],[-92.731993,44.478056],[-92.792528,44.600687],[-92.85564,44.601133],[-92.934181,44.557768],[-93.011252,44.511512],[-92.913477,44.386658]]]},"properties":{"id":"55009","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.670522,46.117784],[-95.769618,45.991627],[-95.76956,45.93368],[-95.758639,45.904925],[-95.712627,45.886051],[-95.567671,46.122333],[-95.584339,46.135967],[-95.645392,46.136541],[-95.670522,46.117784]]]},"properties":{"id":"56326","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.312629,46.019863],[-95.281644,45.933286],[-95.168398,45.976523],[-95.104877,45.994166],[-95.105224,46.020064],[-95.146429,46.034309],[-95.312629,46.019863]]]},"properties":{"id":"56319","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.88843,43.499769],[-93.887954,43.626488],[-93.988033,43.543398],[-93.968285,43.499873],[-93.88843,43.499769]]]},"properties":{"id":"56033","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.239266,43.66191],[-92.27934,43.547543],[-91.969879,43.608917],[-92.04417,43.73162],[-92.20691,43.6944],[-92.239266,43.66191]]]},"properties":{"id":"55965","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.457093,44.318859],[-92.476035,44.346492],[-92.586104,44.360562],[-92.59063,44.252677],[-92.55004,44.1875],[-92.485612,44.197843],[-92.457093,44.318859]]]},"properties":{"id":"55956","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.518227,44.923969],[-93.456246,44.941071],[-93.471583,44.978467],[-93.522343,45.020932],[-93.522217,45.027527],[-93.542242,45.026108],[-93.621161,44.954911],[-93.638337,44.939662],[-93.630176,44.932632],[-93.599021,44.923485],[-93.518227,44.923969]]]},"properties":{"id":"55391","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.747292,44.91663],[-93.705981,44.90741],[-93.599021,44.923485],[-93.630176,44.932632],[-93.644708,44.932697],[-93.638337,44.939662],[-93.621161,44.954911],[-93.630456,44.976296],[-93.747063,44.931229],[-93.747292,44.91663]]]},"properties":{"id":"55364","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.320017,45.087214],[-93.361351,45.08725],[-93.360138,45.040043],[-93.32507,45.041056],[-93.320017,45.087214]]]},"properties":{"id":"55429","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.525734,45.188519],[-93.421989,45.16753],[-93.422823,45.211961],[-93.51219,45.245683],[-93.524795,45.244518],[-93.525734,45.188519]]]},"properties":{"id":"55327","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.273645,44.992864],[-93.274425,45.005765],[-93.274889,45.013126],[-93.31839,45.013256],[-93.318424,44.983977],[-93.285332,44.984387],[-93.273645,44.992864]]]},"properties":{"id":"55411","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.26889,44.937767],[-93.268944,44.955525],[-93.283608,44.955538],[-93.305122,44.951138],[-93.309921,44.933685],[-93.308746,44.915443],[-93.296631,44.922516],[-93.26889,44.937767]]]},"properties":{"id":"55408","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.630176,44.932632],[-93.638337,44.939662],[-93.644708,44.932697],[-93.630176,44.932632]]]},"properties":{"id":"55384","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.432272,43.734322],[-91.610747,43.673761],[-91.521635,43.504684],[-91.267696,43.500923],[-91.246623,43.500485],[-91.37243,43.664834],[-91.432272,43.734322]]]},"properties":{"id":"55921","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-93.666915,45.675261],[-93.71868,45.66705],[-93.728491,45.643674],[-93.666915,45.675261]]],[[[-93.666915,45.675261],[-93.492571,45.688663],[-93.463884,45.732988],[-93.578981,45.908271],[-93.768446,45.93907],[-93.766649,45.865822],[-93.763948,45.822143],[-93.666915,45.675261]],[[-93.557141,45.783867],[-93.549237,45.788306],[-93.548976,45.781796],[-93.557141,45.783867]],[[-93.647787,45.698258],[-93.656942,45.69408],[-93.660792,45.699647],[-93.647787,45.698258]]]]},"properties":{"id":"56353","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.36357,47.31571],[-93.284404,47.316964],[-93.259757,47.327687],[-93.319726,47.340527],[-93.36719,47.32624],[-93.36357,47.31571]]]},"properties":{"id":"55764","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.693646,47.109394],[-93.702734,47.361103],[-93.774225,47.149715],[-93.774191,47.109595],[-93.693646,47.109394]]]},"properties":{"id":"55721","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.191898,47.090184],[-93.252886,47.026412],[-93.084566,47.026357],[-93.191898,47.090184]]]},"properties":{"id":"55784","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.1424,45.904662],[-93.201311,46.071617],[-93.216164,46.107514],[-93.432794,46.010538],[-93.510036,45.981824],[-93.307067,45.738288],[-93.209413,45.803105],[-93.1424,45.904662]]]},"properties":{"id":"55051","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.179356,46.115344],[-92.31274,46.070007],[-92.293065,46.287785],[-92.63644,46.15754],[-92.84761,46.158016],[-93.178985,46.15855],[-93.179356,46.115344]]]},"properties":{"id":"55072","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.706637,45.008121],[-94.763417,45.253646],[-94.805177,45.282915],[-94.805051,45.275578],[-94.859602,45.204073],[-94.905909,45.066489],[-94.757573,44.993837],[-94.706637,45.008121]]]},"properties":{"id":"56209","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.71136,48.784965],[-96.823498,48.702931],[-96.69388,48.645622],[-96.422764,48.688335],[-96.386636,48.702913],[-96.404633,48.77556],[-96.71136,48.784965]]]},"properties":{"id":"56734","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.208831,48.999982],[-96.930967,48.999977],[-96.996858,48.948538],[-96.964182,48.919566],[-96.71136,48.784965],[-96.404633,48.77556],[-96.207921,48.950949],[-96.208831,48.999982]]]},"properties":{"id":"56735","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.71136,48.784965],[-96.964182,48.919566],[-97.172612,48.919246],[-97.237418,48.965428],[-97.123024,48.717143],[-96.823498,48.702931],[-96.71136,48.784965]]]},"properties":{"id":"56728","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.867871,48.543312],[-96.867052,48.601661],[-96.955269,48.587437],[-96.955159,48.543699],[-96.867871,48.543312]]]},"properties":{"id":"56720","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.712365,48.512534],[-93.717982,48.517469],[-93.881261,48.630889],[-94.114357,48.470244],[-93.903319,48.365126],[-93.712365,48.512534]]]},"properties":{"id":"56654","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.31694,44.87764],[-96.174797,44.855314],[-95.98092,45.05925],[-96.103614,45.176675],[-96.226171,45.219492],[-96.24787,45.19694],[-96.32804,45.05318],[-96.31694,44.87764]]]},"properties":{"id":"56256","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.323572,44.408105],[-96.241539,44.372167],[-96.100237,44.459286],[-96.11011,44.517466],[-96.194642,44.543377],[-96.235515,44.543285],[-96.337129,44.543595],[-96.323572,44.408105]]]},"properties":{"id":"56142","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.720057,46.249163],[-94.647787,46.107816],[-94.495751,46.165527],[-94.474515,46.277126],[-94.486089,46.282611],[-94.720057,46.249163]]]},"properties":{"id":"56443","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.609649,43.833876],[-92.484426,43.725082],[-92.448976,43.811267],[-92.609649,43.833876]]]},"properties":{"id":"55967","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.88415,43.84856],[-95.792785,43.830324],[-95.70357,43.90607],[-95.8839,43.89198],[-95.88415,43.84856]]]},"properties":{"id":"56141","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.125051,43.797739],[-95.993016,43.790244],[-95.972887,43.833865],[-95.992828,43.848893],[-95.9931,43.819398],[-96.033317,43.819264],[-96.02374,43.84919],[-96.029226,43.93554],[-96.184696,44.008429],[-96.244731,43.884983],[-96.24531,43.8486],[-96.125051,43.797739]]]},"properties":{"id":"56128","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.250874,44.326944],[-94.311766,44.242778],[-94.248069,44.220083],[-94.250874,44.326944]]]},"properties":{"id":"56021","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.801687,44.62989],[-94.805405,44.465341],[-94.756249,44.447331],[-94.623665,44.382359],[-94.623927,44.42673],[-94.629225,44.614805],[-94.801687,44.62989]]]},"properties":{"id":"55332","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.053651,43.578445],[-95.903591,43.500035],[-95.843799,43.500023],[-95.872326,43.731814],[-96.012898,43.717392],[-96.053651,43.578445]]]},"properties":{"id":"56110","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.02374,43.84919],[-96.033317,43.819264],[-95.9931,43.819398],[-95.992828,43.848893],[-96.02374,43.84919]]]},"properties":{"id":"56153","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.14267,43.7611],[-96.07308,43.68872],[-96.012898,43.717392],[-95.993016,43.790244],[-96.125051,43.797739],[-96.14267,43.7611]]]},"properties":{"id":"56147","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.48406,43.89925],[-92.318214,43.906376],[-92.345006,44.03552],[-92.46303,44.02347],[-92.48406,43.89925]]]},"properties":{"id":"55904","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.19894,43.93392],[-92.079133,43.893255],[-92.15035,44.05686],[-92.19407,44.061],[-92.19894,43.93392]]]},"properties":{"id":"55929","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.725296,46.605842],[-95.688705,46.520163],[-95.615286,46.50627],[-95.532493,46.488881],[-95.371297,46.644041],[-95.414397,46.71617],[-95.725296,46.605842]]]},"properties":{"id":"56573","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.801331,46.412176],[-95.805867,46.212831],[-95.77004,46.165644],[-95.670522,46.117784],[-95.645392,46.136541],[-95.655911,46.293812],[-95.584695,46.368979],[-95.65687,46.420662],[-95.801331,46.412176]]]},"properties":{"id":"56515","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.029014,46.296314],[-95.135105,46.347605],[-95.237001,46.365343],[-95.279565,46.296863],[-95.279526,46.289508],[-95.187899,46.267111],[-95.029014,46.296314]]]},"properties":{"id":"56453","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.1212,45.86818],[-93.114176,45.868164],[-93.114297,45.875385],[-93.12484,45.87546],[-93.1212,45.86818]]]},"properties":{"id":"55036","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.848923,47.498991],[-96.708663,47.498919],[-96.729127,47.571511],[-96.871111,47.527115],[-96.848923,47.498991]]]},"properties":{"id":"56568","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.377716,45.412897],[-95.510157,45.672594],[-95.597382,45.672433],[-95.655477,45.672523],[-95.69325,45.571828],[-95.582601,45.470221],[-95.377716,45.412897]]]},"properties":{"id":"56381","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.227696,45.124533],[-93.227236,45.064982],[-93.208092,45.035744],[-93.147048,45.035807],[-93.18863,45.118895],[-93.227696,45.124533]]]},"properties":{"id":"55112","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.073671,44.991024],[-93.077368,44.957318],[-93.079158,44.9465],[-93.04985,44.919924],[-93.023724,44.991952],[-93.066121,44.991985],[-93.073671,44.991024]]]},"properties":{"id":"55106","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.207574,44.989504],[-93.207678,44.97574],[-93.167043,44.970268],[-93.146588,44.967349],[-93.143717,44.985808],[-93.146464,44.991976],[-93.207574,44.989504]]]},"properties":{"id":"55108","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.147048,45.035807],[-93.208092,45.035744],[-93.20754,45.006154],[-93.207558,44.991684],[-93.207574,44.989504],[-93.146464,44.991976],[-93.105334,45.035962],[-93.147048,45.035807]]]},"properties":{"id":"55113","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.289908,44.515565],[-95.1276,44.40555],[-95.08803,44.427406],[-94.996619,44.485682],[-95.014922,44.629038],[-95.096781,44.686966],[-95.127916,44.655677],[-95.238513,44.628757],[-95.289908,44.515565]]]},"properties":{"id":"56283","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.451096,44.45737],[-95.370075,44.442468],[-95.320748,44.479741],[-95.331042,44.469057],[-95.330831,44.483583],[-95.350146,44.500659],[-95.401118,44.541777],[-95.523316,44.541968],[-95.594817,44.542305],[-95.594601,44.500643],[-95.553977,44.486756],[-95.451096,44.45737]]]},"properties":{"id":"56292","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.016263,44.891689],[-95.125481,44.891438],[-95.127916,44.655677],[-95.096781,44.686966],[-95.016263,44.891689]]]},"properties":{"id":"56230","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.353152,44.196208],[-93.111771,44.218372],[-93.101227,44.298164],[-93.161369,44.344982],[-93.220526,44.370417],[-93.301826,44.428558],[-93.342285,44.455769],[-93.437637,44.382107],[-93.46347,44.356377],[-93.464235,44.303345],[-93.393904,44.253258],[-93.381523,44.249706],[-93.353152,44.196208]]]},"properties":{"id":"55021","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.31238,43.55829],[-96.45337,43.55863],[-96.332062,43.500415],[-96.31238,43.55829]]]},"properties":{"id":"56138","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.99376,48.61598],[-96.140024,48.946223],[-96.207921,48.950949],[-96.404633,48.77556],[-96.386636,48.702913],[-96.30217,48.5738],[-95.99376,48.61598]]]},"properties":{"id":"56726","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.775802,48.540556],[-95.602315,48.538887],[-95.462,48.539732],[-95.84417,48.67131],[-95.863835,48.628327],[-95.775802,48.540556]]]},"properties":{"id":"56761","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.140024,48.946223],[-95.84417,48.67131],[-95.462,48.539732],[-95.34254,48.540211],[-95.342618,48.597874],[-95.48369,48.712494],[-95.642878,48.999436],[-96.208831,48.999982],[-96.207921,48.950949],[-96.140024,48.946223]]]},"properties":{"id":"56751","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.346448,48.134228],[-92.128676,48.083169],[-91.979323,48.247463],[-92.625154,48.438228],[-92.346448,48.134228]]]},"properties":{"id":"55725","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.806207,46.868739],[-92.808646,46.766321],[-92.711902,46.768378],[-92.57589,46.849528],[-92.613227,46.924632],[-92.806207,46.868739]]]},"properties":{"id":"55711","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.806293,47.477911],[-92.739016,47.504261],[-92.760469,47.504519],[-92.801015,47.499164],[-92.802841,47.488367],[-92.806293,47.477911]]]},"properties":{"id":"55713","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.175656,47.111106],[-92.352552,47.023669],[-92.301897,46.939836],[-92.080426,46.822067],[-92.066221,46.821157],[-91.92148,47.025658],[-91.830646,47.198788],[-92.047512,47.243243],[-92.175656,47.111106]]]},"properties":{"id":"55803","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.080426,46.822067],[-92.301897,46.939836],[-92.322815,46.843743],[-92.165389,46.760045],[-92.111906,46.794769],[-92.088846,46.81247],[-92.080426,46.822067]],[[-92.199544,46.834395],[-92.195907,46.835743],[-92.195863,46.833349],[-92.199544,46.834395]]]},"properties":{"id":"55811","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.679938,47.028884],[-92.806207,46.868739],[-92.613227,46.924632],[-92.555098,46.997389],[-92.555229,47.026289],[-92.679938,47.028884]]]},"properties":{"id":"55702","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.339459,44.717501],[-93.409092,44.753801],[-93.470332,44.706365],[-93.501324,44.601689],[-93.47096,44.587164],[-93.458568,44.602013],[-93.399439,44.62657],[-93.339459,44.717501]]]},"properties":{"id":"55372","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.681643,45.302871],[-93.653261,45.410684],[-93.758343,45.472718],[-93.819578,45.324114],[-93.681643,45.302871]]]},"properties":{"id":"55309","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.265376,45.551814],[-94.2064,45.55861],[-94.257752,45.554328],[-94.265376,45.551814]]]},"properties":{"id":"56387","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.64399,45.77395],[-94.76622,45.90405],[-94.845716,45.846409],[-94.829724,45.774399],[-94.729777,45.761015],[-94.64399,45.77395]]]},"properties":{"id":"56336","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.685555,45.49891],[-94.663536,45.497546],[-94.680316,45.519418],[-94.70142,45.503943],[-94.685555,45.49891]]]},"properties":{"id":"56376","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.654432,45.15135],[-95.706094,45.381035],[-95.747402,45.412313],[-95.849922,45.383608],[-95.809488,45.246244],[-95.796709,45.180792],[-95.73554,45.151428],[-95.654432,45.15135]]]},"properties":{"id":"56231","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.576564,46.021848],[-96.337595,45.926987],[-96.26641,45.97807],[-96.26614,46.02161],[-96.38943,46.05054],[-96.563129,46.110055],[-96.576564,46.021848]]]},"properties":{"id":"56583","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.069635,44.193033],[-92.079491,44.106988],[-91.957542,44.017773],[-91.90959,44.02038],[-91.86845,44.13605],[-91.856486,44.192131],[-91.949233,44.23079],[-92.069635,44.193033]]]},"properties":{"id":"55910","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.86845,44.13605],[-91.90959,44.02038],[-91.8993,44.02063],[-91.856112,44.048],[-91.821616,44.063628],[-91.86845,44.13605]]]},"properties":{"id":"55969","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.70427,45.246507],[-93.765814,45.237876],[-93.696242,45.159079],[-93.638745,45.174363],[-93.552296,45.229446],[-93.628709,45.237999],[-93.70427,45.246507]]]},"properties":{"id":"55376","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.847595,44.878002],[-95.951335,44.732221],[-95.890063,44.65985],[-95.808414,44.663544],[-95.726691,44.717327],[-95.725861,44.85604],[-95.847595,44.878002]]]},"properties":{"id":"56223","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.061014,46.789542],[-93.054765,46.593239],[-93.00675,46.593294],[-92.986241,46.593185],[-93.061043,46.788277],[-93.061014,46.789542]]]},"properties":{"id":"55787","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-95.540358,46.968578],[-95.619648,46.84641],[-95.54138,46.880533],[-95.4137,46.969986],[-95.540358,46.968578]]],[[[-96.03507,46.717764],[-95.963592,46.676119],[-95.834902,46.716574],[-95.626398,46.844575],[-95.79439,46.970147],[-95.818762,46.974247],[-95.922164,46.963775],[-96.03507,46.717764]]]]},"properties":{"id":"56501","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.268396,46.847523],[-94.89382,46.84818],[-94.918176,47.1685],[-95.045853,47.152782],[-95.1696,47.266752],[-95.28753,47.268888],[-95.297252,47.151782],[-95.297422,47.020178],[-95.268396,46.847523]]]},"properties":{"id":"56470","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.414397,46.71617],[-95.371297,46.644041],[-95.28824,46.683866],[-94.786953,46.777232],[-94.72253,46.804061],[-94.723033,46.807165],[-94.89382,46.84818],[-95.268396,46.847523],[-95.329176,46.832124],[-95.414397,46.71617]],[[-95.352371,46.803191],[-95.355754,46.80318],[-95.355514,46.804434],[-95.352322,46.804273],[-95.352371,46.803191]]]},"properties":{"id":"56464","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.299847,47.006172],[-96.174113,46.92641],[-96.130732,47.049769],[-96.299847,47.006172]]]},"properties":{"id":"56552","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.671872,47.636888],[-94.545538,47.584529],[-94.697218,47.790655],[-94.671872,47.636888]]]},"properties":{"id":"56647","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.165368,45.573766],[-93.965609,45.559567],[-94.069386,45.735651],[-94.184184,45.679983],[-94.191073,45.600226],[-94.165368,45.573766]]]},"properties":{"id":"56379","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.76815,44.256062],[-93.877695,44.275619],[-93.888579,44.268811],[-93.929086,44.239819],[-93.908756,44.196159],[-93.788208,44.163282],[-93.727587,44.174266],[-93.76815,44.256062]]]},"properties":{"id":"56063","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.311766,44.242778],[-94.369161,44.195942],[-94.368848,44.170332],[-94.281757,44.00178],[-94.128183,44.09038],[-94.129116,44.184886],[-94.194183,44.200143],[-94.248069,44.220083],[-94.311766,44.242778]]]},"properties":{"id":"56055","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.348978,43.906044],[-94.109257,43.957279],[-94.128439,43.999098],[-94.277421,43.99631],[-94.369018,43.934939],[-94.36903,43.905922],[-94.348978,43.906044]]]},"properties":{"id":"56090","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.756249,44.447331],[-94.946936,44.311555],[-94.786142,44.195718],[-94.700379,44.137914],[-94.700444,44.141555],[-94.572966,44.196014],[-94.623665,44.382359],[-94.756249,44.447331]]]},"properties":{"id":"56085","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.020218,44.13686],[-95.099916,44.093589],[-95.059921,44.079235],[-95.039845,44.079224],[-94.859187,44.050093],[-94.841801,44.050445],[-94.839427,44.057935],[-94.7595,44.077514],[-94.700379,44.137914],[-94.786142,44.195718],[-95.020218,44.13686]]]},"properties":{"id":"56019","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.849076,46.766257],[-92.986241,46.593185],[-93.00675,46.593294],[-92.863296,46.57049],[-92.734433,46.65013],[-92.706402,46.736656],[-92.711902,46.768378],[-92.808646,46.766321],[-92.849076,46.766257]]]},"properties":{"id":"55726","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.553263,46.446795],[-92.490302,46.374455],[-92.29286,46.41722],[-92.291976,46.503997],[-92.46875,46.49335],[-92.553263,46.446795]]]},"properties":{"id":"55749","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.010021,44.803989],[-93.848985,44.782209],[-93.888398,44.848256],[-93.94289,44.855075],[-94.010042,44.855137],[-94.020245,44.841297],[-94.010021,44.803989]]]},"properties":{"id":"55397","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.010494,44.717464],[-93.888712,44.702477],[-94.010181,44.767963],[-94.010494,44.717464]]]},"properties":{"id":"55339","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.774225,47.149715],[-94.09734,47.335789],[-94.281477,47.261144],[-94.286181,47.226156],[-94.178465,47.165648],[-94.15408,46.862541],[-94.153987,46.804629],[-94.032687,46.803222],[-93.901972,46.889239],[-93.775257,47.030418],[-93.774191,47.109595],[-93.774225,47.149715]]]},"properties":{"id":"56672","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.659387,46.890702],[-94.331206,46.892115],[-94.321067,47.005744],[-94.596318,47.030643],[-94.659387,46.890702]]]},"properties":{"id":"56452","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.75616,45.310484],[-92.745683,45.296042],[-92.67039,45.388133],[-92.768628,45.469653],[-92.766933,45.354575],[-92.75616,45.310484]]]},"properties":{"id":"55074","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.141974,45.558109],[-92.816842,45.560945],[-92.889055,45.642289],[-93.059611,45.633303],[-93.141988,45.614093],[-93.141974,45.558109]]]},"properties":{"id":"55032","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.896992,45.228198],[-92.752308,45.216866],[-92.745683,45.296042],[-92.75616,45.310484],[-92.839414,45.296339],[-92.90698,45.296244],[-92.896992,45.228198]]]},"properties":{"id":"55073","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.436234,47.238216],[-96.580099,47.194888],[-96.648565,47.151356],[-96.648312,47.078943],[-96.407026,47.124209],[-96.322284,47.151321],[-96.436234,47.238216]]]},"properties":{"id":"56519","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.281191,46.592151],[-96.173317,46.663415],[-96.420669,46.789389],[-96.504681,46.768552],[-96.614609,46.630521],[-96.50887,46.50012],[-96.281191,46.592151]]]},"properties":{"id":"56514","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.341726,44.122943],[-95.402076,44.123065],[-95.48242,44.10885],[-95.54275,44.05092],[-95.521692,43.949479],[-95.380496,43.93448],[-95.340569,43.963697],[-95.31106,44.086665],[-95.341726,44.122943]]]},"properties":{"id":"56183","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.349669,44.296954],[-95.402076,44.123065],[-95.341726,44.122943],[-95.349669,44.296954]]]},"properties":{"id":"56166","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.341679,46.277705],[-94.334829,46.074382],[-94.188183,46.130826],[-94.1036,46.17073],[-94.314015,46.295887],[-94.327285,46.289746],[-94.341679,46.277705]]]},"properties":{"id":"56449","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.342285,44.455769],[-93.301826,44.428558],[-93.193382,44.442562],[-93.220526,44.370417],[-93.161369,44.344982],[-93.08049,44.390526],[-93.039869,44.500865],[-93.126755,44.55809],[-93.248179,44.543891],[-93.281521,44.543957],[-93.351936,44.464568],[-93.342285,44.455769]]]},"properties":{"id":"55057","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.934682,43.848565],[-92.829322,43.848733],[-92.689391,43.84857],[-92.638234,43.915067],[-92.647865,43.92074],[-92.809258,43.954058],[-92.945047,43.935698],[-92.934682,43.848565]]]},"properties":{"id":"55940","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.67819,44.05791],[-92.798752,44.047307],[-92.809258,43.954058],[-92.647865,43.92074],[-92.67819,44.05791]]]},"properties":{"id":"55944","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.437662,46.060115],[-95.188003,46.106731],[-95.187554,46.194537],[-95.187899,46.267111],[-95.279526,46.289508],[-95.375561,46.224158],[-95.520809,46.122661],[-95.511817,46.093642],[-95.437662,46.060115]]]},"properties":{"id":"56361","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.758639,45.904925],[-95.923527,45.809477],[-95.92315,45.75959],[-95.86225,45.759791],[-95.84122,45.75976],[-95.696543,45.847471],[-95.712627,45.886051],[-95.758639,45.904925]]]},"properties":{"id":"56339","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.263915,45.880122],[-95.168398,45.976523],[-95.281644,45.933286],[-95.263915,45.880122]]]},"properties":{"id":"56355","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.021779,46.04845],[-94.990053,46.216002],[-95.187554,46.194537],[-95.188003,46.106731],[-95.146429,46.034309],[-95.105224,46.020064],[-95.021779,46.04845]]]},"properties":{"id":"56446","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.247836,43.666199],[-94.307714,43.542873],[-93.988033,43.543398],[-93.887954,43.626488],[-93.887493,43.666361],[-93.967274,43.702423],[-94.087819,43.717111],[-94.247836,43.666199]]]},"properties":{"id":"56013","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.529858,43.674144],[-92.40947,43.55841],[-92.28945,43.54413],[-92.27934,43.547543],[-92.239266,43.66191],[-92.30895,43.79009],[-92.3489,43.81924],[-92.448976,43.811267],[-92.484426,43.725082],[-92.529858,43.674144]]]},"properties":{"id":"55975","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.024364,43.78105],[-92.20945,43.77894],[-92.20691,43.6944],[-92.04417,43.73162],[-92.024364,43.78105]]]},"properties":{"id":"55935","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.648533,43.49968],[-93.358698,43.499487],[-93.428714,43.556734],[-93.446745,43.556807],[-93.518296,43.557258],[-93.614823,43.55782],[-93.648388,43.543084],[-93.648533,43.49968]]]},"properties":{"id":"56029","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.716407,44.478127],[-92.470258,44.455433],[-92.353825,44.510452],[-92.317665,44.542802],[-92.589029,44.602234],[-92.716407,44.478127]]]},"properties":{"id":"55066","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.797061,44.369958],[-92.850836,44.355726],[-92.83574,44.341381],[-92.766817,44.344529],[-92.797061,44.369958]]],[[[-92.763268,44.267018],[-92.59063,44.252677],[-92.586104,44.360562],[-92.766595,44.344688],[-92.763268,44.267018]]]]},"properties":{"id":"55992","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.04591,44.196716],[-92.823426,44.225648],[-92.83574,44.341381],[-92.850836,44.355726],[-92.913477,44.386658],[-92.935004,44.370514],[-93.101227,44.298164],[-93.111771,44.218372],[-93.04591,44.196716]]]},"properties":{"id":"55946","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.23948,44.300476],[-92.19961,44.331],[-92.094867,44.408395],[-92.112025,44.409832],[-92.138091,44.424436],[-92.313625,44.536873],[-92.353825,44.510452],[-92.470258,44.455433],[-92.476035,44.346492],[-92.457093,44.318859],[-92.330675,44.298567],[-92.23948,44.300476]]]},"properties":{"id":"55041","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.309921,44.933685],[-93.331627,44.923397],[-93.328964,44.887001],[-93.318825,44.8908],[-93.308662,44.890736],[-93.308746,44.915443],[-93.309921,44.933685]]]},"properties":{"id":"55410","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.522343,45.020932],[-93.471583,44.978467],[-93.459971,44.978579],[-93.452545,45.028486],[-93.522343,45.020932]]]},"properties":{"id":"55447","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.522343,45.020932],[-93.452545,45.028486],[-93.448133,45.065535],[-93.522079,45.065537],[-93.522217,45.027527],[-93.522343,45.020932]]]},"properties":{"id":"55446","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.359943,44.978092],[-93.360092,45.032768],[-93.400551,45.033314],[-93.400378,44.979518],[-93.367661,44.978176],[-93.359943,44.978092]]]},"properties":{"id":"55427","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.681241,45.151263],[-93.698894,45.137598],[-93.680452,45.065283],[-93.635254,45.051136],[-93.635751,45.116704],[-93.635376,45.152542],[-93.681241,45.151263]]]},"properties":{"id":"55357","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.253997,44.969015],[-93.236164,44.964294],[-93.225775,44.966191],[-93.239267,44.973229],[-93.245005,44.978863],[-93.245506,44.977612],[-93.253997,44.969015]]]},"properties":{"id":"55454","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.268944,44.955525],[-93.243217,44.955547],[-93.236164,44.964294],[-93.253997,44.969015],[-93.267593,44.973621],[-93.270565,44.972219],[-93.283911,44.962703],[-93.283608,44.955538],[-93.268944,44.955525]]]},"properties":{"id":"55404","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.791355,47.106405],[-94.90859,47.17822],[-94.918176,47.1685],[-94.89382,46.84818],[-94.723033,46.807165],[-94.791355,47.106405]]]},"properties":{"id":"56467","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.450324,45.471676],[-93.271645,45.413665],[-93.142138,45.413642],[-93.124168,45.471275],[-93.13545,45.485753],[-93.440558,45.50071],[-93.450745,45.484144],[-93.450324,45.471676]]]},"properties":{"id":"55040","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.736796,47.567922],[-93.479353,47.580189],[-93.296431,47.645667],[-93.542038,47.751927],[-93.776284,47.811547],[-93.827369,47.673589],[-93.821479,47.66498],[-93.76986,47.60133],[-93.736796,47.567922]]]},"properties":{"id":"56628","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.542038,47.751927],[-93.296431,47.645667],[-93.19534,47.65903],[-92.951061,47.719371],[-92.560748,47.696574],[-92.437862,47.713663],[-92.470728,47.915662],[-93.247933,47.893524],[-93.542038,47.751927]]]},"properties":{"id":"55723","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.309228,47.028002],[-93.252886,47.026412],[-93.191898,47.090184],[-93.156708,47.125985],[-93.193791,47.196011],[-93.228516,47.195587],[-93.32135,47.19642],[-93.309228,47.028002]]]},"properties":{"id":"55793","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.081642,43.761147],[-95.144038,43.530152],[-95.074104,43.500996],[-94.903818,43.50047],[-94.914443,43.790163],[-94.954511,43.78994],[-95.081642,43.761147]]]},"properties":{"id":"56143","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.805051,45.275578],[-95.131923,45.2562],[-95.083411,45.232784],[-94.974719,45.182571],[-94.859602,45.204073],[-94.805051,45.275578]]]},"properties":{"id":"56288","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.291383,45.412682],[-95.131945,45.354774],[-95.131791,45.383767],[-94.995028,45.585333],[-95.13961,45.613965],[-95.150662,45.614025],[-95.291383,45.412682]]]},"properties":{"id":"56316","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.748906,47.98522],[-93.343276,48.069624],[-93.22665,48.120773],[-93.097237,48.142329],[-93.094737,48.300567],[-93.383243,48.432684],[-93.712365,48.512534],[-93.903319,48.365126],[-93.742502,48.034446],[-93.748906,47.98522]]]},"properties":{"id":"56653","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.327684,48.468984],[-94.114357,48.470244],[-93.881261,48.630889],[-94.299275,48.708888],[-94.327684,48.468984]]]},"properties":{"id":"56629","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.865895,48.876699],[-94.819459,48.629315],[-94.55944,48.367326],[-94.428702,48.367333],[-94.327684,48.468984],[-94.299275,48.708888],[-94.865895,48.876699]]]},"properties":{"id":"56623","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.22665,48.120773],[-93.22659,48.07145],[-93.096488,48.067826],[-93.097237,48.142329],[-93.22665,48.120773]]]},"properties":{"id":"55772","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.093255,44.80476],[-96.0518,44.757385],[-96.011779,44.731806],[-95.951335,44.732221],[-95.847595,44.878002],[-95.90081,44.92091],[-96.093255,44.80476]]]},"properties":{"id":"56218","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.789394,47.496933],[-91.789996,47.437961],[-91.64987,47.428901],[-91.497274,47.485987],[-91.426842,47.499809],[-91.150287,47.744617],[-91.655525,47.784639],[-91.795634,47.685894],[-91.792893,47.629834],[-91.789394,47.496933]]]},"properties":{"id":"55607","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.76815,44.256062],[-93.727587,44.174266],[-93.646696,44.195993],[-93.722553,44.282799],[-93.76815,44.256062]]]},"properties":{"id":"56028","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.01071,44.61552],[-94.07024,44.50026],[-93.910405,44.543163],[-93.91937,44.66606],[-94.01071,44.61552]]]},"properties":{"id":"56044","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.89694,44.3265],[-95.81612,44.312],[-95.63467,44.38496],[-95.553977,44.486756],[-95.594601,44.500643],[-95.84945,44.58698],[-95.862926,44.543411],[-95.93794,44.43796],[-95.8969,44.34105],[-95.89694,44.3265]]]},"properties":{"id":"56258","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.84945,44.58698],[-95.594601,44.500643],[-95.594817,44.542305],[-95.604001,44.629945],[-95.808414,44.663544],[-95.890063,44.65985],[-95.84945,44.58698]]]},"properties":{"id":"56229","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.7756,44.29746],[-95.82339,44.12382],[-95.70334,44.148286],[-95.7756,44.29746]]]},"properties":{"id":"56132","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.151611,44.86283],[-94.064812,44.829759],[-94.020245,44.841297],[-94.010042,44.855137],[-94.012318,44.9255],[-94.114163,44.920926],[-94.151611,44.86283]]]},"properties":{"id":"55354","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.365095,48.457625],[-96.533663,48.384134],[-96.525666,48.326341],[-96.361402,48.174781],[-96.151127,48.285766],[-96.276544,48.456684],[-96.365095,48.457625]]]},"properties":{"id":"56738","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.056289,48.296759],[-97.04194,48.151378],[-96.998673,48.15154],[-96.95673,48.281922],[-97.056289,48.296759]]]},"properties":{"id":"56710","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.501885,44.979695],[-94.379301,45.152154],[-94.37917,45.201959],[-94.37916,45.202255],[-94.530251,45.24112],[-94.604286,45.239682],[-94.676015,44.993574],[-94.584149,44.906652],[-94.501885,44.979695]]]},"properties":{"id":"55355","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.829322,43.848733],[-92.829253,43.79055],[-92.789777,43.761364],[-92.689283,43.833847],[-92.689391,43.84857],[-92.829322,43.848733]]]},"properties":{"id":"55973","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.894436,43.775886],[-92.789586,43.703146],[-92.789777,43.761364],[-92.829253,43.79055],[-92.894436,43.775886]]]},"properties":{"id":"55918","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.521692,43.949479],[-95.54275,44.05092],[-95.56291,44.05092],[-95.62267,44.00769],[-95.70341,43.92055],[-95.521692,43.949479]]]},"properties":{"id":"56114","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.184696,44.008429],[-96.029226,43.93554],[-96.02391,43.94991],[-95.98356,44.1096],[-96.0037,44.10966],[-96.06413,44.09526],[-96.184801,44.022926],[-96.184696,44.008429]]]},"properties":{"id":"56186","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.491954,44.41318],[-94.276416,44.400342],[-94.2307,44.4275],[-94.25071,44.4566],[-94.4724,44.45629],[-94.491954,44.41318]]]},"properties":{"id":"56054","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.843799,43.500023],[-95.754034,43.499917],[-95.713734,43.587577],[-95.733433,43.674224],[-95.773227,43.717647],[-95.851799,43.76108],[-95.872326,43.731814],[-95.843799,43.500023]]]},"properties":{"id":"56168","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.48725,46.38387],[-96.4032,46.36905],[-96.280478,46.34771],[-96.197797,46.399208],[-96.155845,46.456649],[-96.146933,46.500511],[-96.281191,46.592151],[-96.50887,46.50012],[-96.52963,46.44214],[-96.48725,46.38387]]]},"properties":{"id":"56579","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.270507,46.448909],[-95.238286,46.535671],[-95.322042,46.436729],[-95.270507,46.448909]]]},"properties":{"id":"56518","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.076615,47.835441],[-95.923617,47.862411],[-95.9034,47.99384],[-96.09612,47.963696],[-96.076615,47.835441]]]},"properties":{"id":"56748","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.144941,44.92693],[-93.201207,44.917803],[-93.178539,44.888338],[-93.155043,44.892499],[-93.138608,44.903896],[-93.144941,44.92693]]]},"properties":{"id":"55116","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.127044,44.347106],[-94.976852,44.340583],[-95.08803,44.427406],[-95.1276,44.40555],[-95.127277,44.369054],[-95.127044,44.347106]]]},"properties":{"id":"56224","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.996619,44.485682],[-94.964615,44.533014],[-94.95527,44.629315],[-95.014922,44.629038],[-94.996619,44.485682]]]},"properties":{"id":"56270","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.283264,44.543961],[-93.458568,44.602013],[-93.47096,44.587164],[-93.475588,44.529415],[-93.351936,44.464568],[-93.281521,44.543957],[-93.283264,44.543961]]]},"properties":{"id":"55088","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.802841,47.488367],[-92.801015,47.499164],[-92.864738,47.632945],[-92.954749,47.606773],[-92.802841,47.488367]]]},"properties":{"id":"55719","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.066221,46.821157],[-92.050108,46.80245],[-91.794531,46.928751],[-91.793981,46.940109],[-91.794148,46.989308],[-91.92148,47.025658],[-92.066221,46.821157]]]},"properties":{"id":"55804","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.555098,46.997389],[-92.352552,47.023669],[-92.175656,47.111106],[-92.556498,47.099548],[-92.555229,47.026289],[-92.555098,46.997389]]]},"properties":{"id":"55717","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.556498,47.099548],[-92.175656,47.111106],[-92.047512,47.243243],[-92.047181,47.26776],[-92.302695,47.268736],[-92.416165,47.244472],[-92.482344,47.256151],[-92.533895,47.285085],[-92.683871,47.167417],[-92.556498,47.099548]]]},"properties":{"id":"55724","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.187896,47.8525],[-92.17984,47.800863],[-92.15787,47.80106],[-92.187896,47.8525]]]},"properties":{"id":"55782","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.457439,44.629926],[-94.4724,44.45629],[-94.25071,44.4566],[-94.285167,44.630498],[-94.42187,44.630223],[-94.457439,44.629926]]]},"properties":{"id":"55396","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.07144,44.70259],[-94.01071,44.61552],[-93.91937,44.66606],[-93.88881,44.67702],[-93.888712,44.702477],[-94.010494,44.717464],[-94.070596,44.717389],[-94.07144,44.70259]]]},"properties":{"id":"55338","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.343219,45.475269],[-94.384261,45.518792],[-94.461495,45.544013],[-94.492202,45.53991],[-94.42314,45.39952],[-94.383325,45.384967],[-94.32814,45.42846],[-94.32724,45.4627],[-94.36388,45.46156],[-94.343219,45.475269]]]},"properties":{"id":"56320","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.259481,45.692649],[-94.2857,45.717401],[-94.263916,45.684415],[-94.259481,45.692649]]]},"properties":{"id":"56375","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.376908,44.152967],[-93.380466,44.007064],[-93.286158,43.957013],[-93.286119,43.971498],[-93.255966,43.971648],[-93.260122,43.957135],[-93.2763,43.957053],[-93.237854,43.92286],[-93.103835,43.981335],[-93.04563,44.124353],[-93.094835,44.160569],[-93.376908,44.152967]]]},"properties":{"id":"55060","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.636338,45.658356],[-96.57315,45.65812],[-96.61455,45.70184],[-96.636338,45.658356]]],[[[-96.61455,45.70184],[-96.29547,45.68716],[-96.254081,45.716312],[-96.31682,45.84639],[-96.337595,45.926987],[-96.576564,46.021848],[-96.657378,45.742601],[-96.61455,45.70184]]]]},"properties":{"id":"56296","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.764604,45.028767],[-92.79479,45.008376],[-92.770308,44.980346],[-92.764604,45.028767]]]},"properties":{"id":"55003","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.979666,44.890823],[-92.903416,44.948731],[-92.93453,44.948833],[-92.98464,44.948522],[-92.984207,44.893998],[-92.979666,44.890823]]]},"properties":{"id":"55125","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.265342,46.209859],[-96.280478,46.34771],[-96.4032,46.36905],[-96.387883,46.208449],[-96.265342,46.209859]]]},"properties":{"id":"56543","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.920869,45.28214],[-93.765814,45.237876],[-93.70427,45.246507],[-93.658677,45.28515],[-93.681643,45.302871],[-93.819578,45.324114],[-93.922316,45.385021],[-93.973642,45.38881],[-93.97189,45.336554],[-93.920869,45.28214]]]},"properties":{"id":"55362","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.604001,44.629945],[-95.599757,44.717169],[-95.726691,44.717327],[-95.808414,44.663544],[-95.604001,44.629945]]]},"properties":{"id":"56245","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.46474,46.637894],[-93.777415,46.716384],[-93.883902,46.65506],[-93.92646,46.52536],[-93.810632,46.330547],[-93.796281,46.243199],[-93.553305,46.246816],[-93.431107,46.333292],[-93.37031,46.418712],[-93.46474,46.637894]]]},"properties":{"id":"56431","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.480962,47.028832],[-93.475322,46.946778],[-93.360199,46.85475],[-93.058266,46.910601],[-93.084566,47.026357],[-93.252886,47.026412],[-93.309228,47.028002],[-93.480962,47.028832]]]},"properties":{"id":"55752","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.653261,45.410684],[-93.681643,45.302871],[-93.658677,45.28515],[-93.628709,45.237999],[-93.552296,45.229446],[-93.524795,45.244518],[-93.51219,45.245683],[-93.42704,45.384974],[-93.464669,45.413921],[-93.653261,45.410684]]]},"properties":{"id":"55330","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.096075,45.133696],[-93.184132,45.124329],[-93.18863,45.118895],[-93.147048,45.035807],[-93.105334,45.035962],[-93.103307,45.043115],[-93.097036,45.119103],[-93.096075,45.133696]]]},"properties":{"id":"55126","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.146933,46.500511],[-95.962913,46.486575],[-95.906634,46.564606],[-95.963592,46.676119],[-96.03507,46.717764],[-96.065395,46.739551],[-96.17511,46.737075],[-96.173317,46.663415],[-96.281191,46.592151],[-96.146933,46.500511]]]},"properties":{"id":"56572","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.545521,47.58345],[-94.486462,47.496141],[-94.416326,47.415622],[-94.416279,47.444828],[-94.415165,47.542805],[-94.416811,47.636328],[-94.545241,47.584534],[-94.545521,47.58345]]]},"properties":{"id":"56663","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.55944,48.367326],[-94.551713,48.114217],[-94.423146,48.105762],[-94.428702,48.367333],[-94.55944,48.367326]]]},"properties":{"id":"56685","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.824287,45.779228],[-93.71868,45.66705],[-93.666915,45.675261],[-93.763948,45.822143],[-93.824287,45.779228]]]},"properties":{"id":"56330","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.509248,43.90607],[-94.614371,43.848178],[-94.592271,43.819059],[-94.592057,43.761065],[-94.552242,43.751149],[-94.427883,43.760225],[-94.327578,43.774894],[-94.26721,43.84797],[-94.348978,43.906044],[-94.36903,43.905922],[-94.509248,43.90607]]]},"properties":{"id":"56088","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.127044,44.347106],[-95.020218,44.13686],[-94.786142,44.195718],[-94.946936,44.311555],[-94.976852,44.340583],[-95.127044,44.347106]]]},"properties":{"id":"56087","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.34101,46.765028],[-92.301265,46.696091],[-92.207078,46.717917],[-92.163913,46.758538],[-92.165389,46.760045],[-92.322815,46.843743],[-92.357921,46.843731],[-92.34101,46.765028]]]},"properties":{"id":"55810","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.889888,45.014086],[-93.766442,44.975321],[-93.748668,45.064651],[-93.796167,45.10747],[-93.857684,45.082882],[-93.889888,45.014086]]]},"properties":{"id":"55328","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.15408,46.862541],[-94.321355,46.883049],[-94.660948,46.716675],[-94.6551,46.572142],[-94.519628,46.572468],[-94.345879,46.631344],[-94.33475,46.64587],[-94.175046,46.700254],[-94.15462,46.740178],[-94.153987,46.804629],[-94.15408,46.862541]]]},"properties":{"id":"56474","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.198525,46.544505],[-94.469388,46.412967],[-94.419045,46.397558],[-94.178554,46.456378],[-94.198525,46.544505]]]},"properties":{"id":"56468","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.05077,46.72241],[-94.012252,46.803641],[-94.032687,46.803222],[-94.153987,46.804629],[-94.15462,46.740178],[-94.05077,46.72241]]]},"properties":{"id":"56448","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.285958,45.282149],[-95.410784,45.094207],[-95.369801,45.094554],[-95.206574,45.123819],[-95.246654,45.238683],[-95.285958,45.282149]]]},"properties":{"id":"56252","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.801225,45.491521],[-92.766933,45.354575],[-92.768628,45.469653],[-92.754489,45.523856],[-92.801225,45.491521]]]},"properties":{"id":"55012","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.059611,45.633303],[-92.889055,45.642289],[-92.803971,45.749805],[-93.021946,45.730732],[-93.080497,45.692069],[-93.059611,45.633303]]]},"properties":{"id":"55069","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.672775,46.891027],[-96.715032,46.890796],[-96.693744,46.862126],[-96.652233,46.86229],[-96.672775,46.891027]]]},"properties":{"id":"56529","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.672672,46.818804],[-96.652465,46.630475],[-96.614609,46.630521],[-96.504681,46.768552],[-96.672672,46.818804]]]},"properties":{"id":"56580","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.966296,47.838898],[-89.996365,48.039228],[-91.031761,48.188486],[-91.026516,47.888278],[-91.021602,47.813758],[-91.022386,47.69329],[-90.896206,47.758941],[-90.768553,47.936415],[-90.522413,47.707049],[-89.966296,47.838898]]]},"properties":{"id":"55604","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.022887,47.503754],[-91.023097,47.46494],[-90.873297,47.553654],[-90.896206,47.758941],[-91.022386,47.69329],[-91.022887,47.503754]]]},"properties":{"id":"55613","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.250249,44.021585],[-95.290894,43.935111],[-95.175194,43.775884],[-95.081642,43.761147],[-94.954511,43.78994],[-94.954751,43.826086],[-95.099794,44.021371],[-95.250249,44.021585]]]},"properties":{"id":"56101","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.250249,44.021585],[-95.099794,44.021371],[-95.059921,44.079235],[-95.099916,44.093589],[-95.200704,44.108161],[-95.260656,44.079605],[-95.250249,44.021585]]]},"properties":{"id":"56145","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.012252,46.803641],[-94.05077,46.72241],[-94.03034,46.660096],[-93.883902,46.65506],[-93.777415,46.716384],[-93.776912,46.771133],[-93.776024,46.802891],[-94.012252,46.803641]]]},"properties":{"id":"56447","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.227673,44.828987],[-93.221464,44.776262],[-93.166743,44.775709],[-93.164102,44.833601],[-93.207756,44.843626],[-93.227673,44.828987]]]},"properties":{"id":"55122","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.974217,44.772362],[-93.034815,44.674626],[-93.025002,44.639875],[-92.85564,44.601133],[-92.792528,44.600687],[-92.734647,44.666194],[-92.769495,44.858191],[-92.862551,44.847859],[-92.974217,44.772362]],[[-92.974483,44.671],[-92.961534,44.680634],[-92.954244,44.666467],[-92.974483,44.671]]]},"properties":{"id":"55033","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.035298,44.566784],[-92.934181,44.557768],[-92.85564,44.601133],[-93.025002,44.639875],[-93.035298,44.566784]]]},"properties":{"id":"55031","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.974483,44.671],[-92.954244,44.666467],[-92.961534,44.680634],[-92.974483,44.671]]]},"properties":{"id":"55085","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.168398,45.976523],[-95.263915,45.880122],[-95.242999,45.787775],[-95.13967,45.758891],[-95.083697,45.789396],[-95.096992,45.79613],[-95.096498,45.806053],[-95.078637,45.802671],[-94.919242,45.860135],[-95.104877,45.994166],[-95.168398,45.976523]]]},"properties":{"id":"56360","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.531156,45.788016],[-95.597382,45.672433],[-95.510157,45.672594],[-95.387946,45.759365],[-95.531156,45.788016]]]},"properties":{"id":"56349","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.831958,43.630978],[-91.871121,43.500724],[-91.670808,43.500598],[-91.75737,43.67071],[-91.817683,43.645041],[-91.831958,43.630978]]]},"properties":{"id":"55954","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.161369,44.344982],[-93.101227,44.298164],[-92.935004,44.370514],[-93.08049,44.390526],[-93.161369,44.344982]]]},"properties":{"id":"55053","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.337595,45.926987],[-96.31682,45.84639],[-96.13045,45.87581],[-96.14208,45.96347],[-96.26641,45.97807],[-96.337595,45.926987]]]},"properties":{"id":"56274","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.521826,45.152564],[-93.447624,45.066902],[-93.40053,45.065582],[-93.402138,45.094582],[-93.401731,45.152037],[-93.421989,45.16753],[-93.525734,45.188519],[-93.521826,45.152564]]]},"properties":{"id":"55369","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.285332,44.984387],[-93.279709,44.980167],[-93.27256,44.979618],[-93.266518,44.97707],[-93.245506,44.977612],[-93.245005,44.978863],[-93.259618,44.986892],[-93.273645,44.992864],[-93.285332,44.984387]]]},"properties":{"id":"55401","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.456246,44.941071],[-93.518227,44.923969],[-93.523025,44.891466],[-93.520706,44.891502],[-93.444427,44.891424],[-93.443268,44.891342],[-93.451415,44.924872],[-93.456246,44.941071]]]},"properties":{"id":"55345","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.459971,44.978579],[-93.404528,44.972746],[-93.400378,44.979518],[-93.400551,45.033314],[-93.452545,45.028486],[-93.459971,44.978579]]]},"properties":{"id":"55441","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.318825,44.861988],[-93.328941,44.858841],[-93.329589,44.791099],[-93.289828,44.800177],[-93.298454,44.861904],[-93.318825,44.861988]]]},"properties":{"id":"55431","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.263062,44.861805],[-93.261714,44.809262],[-93.227673,44.828987],[-93.207756,44.843626],[-93.197918,44.861977],[-93.223076,44.861977],[-93.237573,44.861789],[-93.263062,44.861805]]]},"properties":{"id":"55425","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.239267,44.973229],[-93.232346,44.977833],[-93.225775,44.966191],[-93.207757,44.953209],[-93.20777,44.959664],[-93.207678,44.97574],[-93.207574,44.989504],[-93.207558,44.991684],[-93.259618,44.986892],[-93.245005,44.978863],[-93.239267,44.973229]]]},"properties":{"id":"55414","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.635376,45.152542],[-93.638745,45.174363],[-93.696242,45.159079],[-93.681241,45.151263],[-93.635376,45.152542]]]},"properties":{"id":"55341","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.401085,44.891727],[-93.374827,44.920564],[-93.398428,44.935064],[-93.451415,44.924872],[-93.443268,44.891342],[-93.412654,44.891987],[-93.401085,44.891727]]]},"properties":{"id":"55343","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.246986,44.890564],[-93.237573,44.861789],[-93.223076,44.861977],[-93.207705,44.894992],[-93.246986,44.890564]]]},"properties":{"id":"55450","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.349613,44.920366],[-93.370827,44.920464],[-93.374827,44.920564],[-93.401085,44.891727],[-93.350223,44.889597],[-93.349613,44.920366]]]},"properties":{"id":"55436","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.033895,47.672872],[-93.827369,47.673589],[-93.776284,47.811547],[-93.776173,47.846404],[-94.033895,47.672872]]]},"properties":{"id":"56688","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.091059,47.393457],[-93.069479,47.399302],[-93.069399,47.407916],[-93.091059,47.393457]]]},"properties":{"id":"55753","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.070334,47.283024],[-93.193791,47.196011],[-93.156708,47.125985],[-93.066245,47.109319],[-93.070334,47.283024]]]},"properties":{"id":"55742","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.209413,45.803105],[-93.083983,45.789972],[-93.06249,45.88971],[-93.1424,45.904662],[-93.209413,45.803105]],[[-93.1212,45.86818],[-93.12484,45.87546],[-93.114297,45.875385],[-93.114176,45.868164],[-93.1212,45.86818]]]},"properties":{"id":"55030","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.131945,45.354774],[-95.291383,45.412682],[-95.357735,45.412471],[-95.357434,45.405291],[-95.285958,45.282149],[-95.246654,45.238683],[-95.13214,45.326302],[-95.131945,45.354774]]]},"properties":{"id":"56289","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.937118,45.066717],[-95.002572,45.008745],[-94.982861,44.891908],[-94.874385,44.892275],[-94.793541,44.892176],[-94.758189,44.892097],[-94.757573,44.993837],[-94.905909,45.066489],[-94.937118,45.066717]]]},"properties":{"id":"56253","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.237418,48.965428],[-97.172612,48.919246],[-96.996858,48.948538],[-96.930967,48.999977],[-97.237418,48.965428]]]},"properties":{"id":"56755","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.906195,48.016659],[-94.423557,48.07629],[-94.41776,48.019811],[-93.906195,48.016659]]]},"properties":{"id":"56660","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.969756,48.467601],[-93.383243,48.432684],[-93.094737,48.300567],[-92.969756,48.467601]]]},"properties":{"id":"56669","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.639658,47.430894],[-91.410042,47.18394],[-91.410395,47.246739],[-91.320496,47.245627],[-91.319279,47.245648],[-91.023097,47.46494],[-91.022887,47.503754],[-91.426842,47.499809],[-91.497274,47.485987],[-91.639658,47.430894]]]},"properties":{"id":"55614","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-91.497274,47.485987],[-91.64987,47.428901],[-91.639658,47.430894],[-91.497274,47.485987]]],[[[-91.022386,47.69329],[-91.021602,47.813758],[-91.150287,47.744617],[-91.426842,47.499809],[-91.022887,47.503754],[-91.022386,47.69329]]]]},"properties":{"id":"55603","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.320496,47.245627],[-91.410395,47.246739],[-91.410042,47.18394],[-91.320496,47.245627]]]},"properties":{"id":"55601","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.866298,49.300226],[-94.828445,49.306442],[-94.854283,49.324108],[-94.866298,49.300226]]]},"properties":{"id":"56741","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.220704,48.883166],[-95.331249,48.712982],[-95.044618,48.743817],[-94.99875,48.967959],[-95.220704,48.883166]]]},"properties":{"id":"56673","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.686688,44.471318],[-93.624816,44.369911],[-93.46347,44.356377],[-93.437637,44.382107],[-93.491447,44.471474],[-93.686688,44.471318]]]},"properties":{"id":"56069","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.888579,44.268811],[-94.013274,44.268469],[-94.011834,44.239192],[-93.929086,44.239819],[-93.888579,44.268811]]]},"properties":{"id":"56050","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.452167,44.383594],[-96.452442,44.284237],[-96.404421,44.386068],[-96.452167,44.383594]]],[[[-96.452635,44.225695],[-96.403872,44.255111],[-96.452489,44.268583],[-96.452635,44.225695]]]]},"properties":{"id":"57026","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.452635,44.225695],[-96.453533,43.906408],[-96.366477,43.914441],[-96.366766,43.90723],[-96.264794,43.88923],[-96.244816,43.892133],[-96.244731,43.884983],[-96.184696,44.008429],[-96.184801,44.022926],[-96.265469,44.168211],[-96.403872,44.255111],[-96.452635,44.225695]]]},"properties":{"id":"56164","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.63467,44.38496],[-95.81612,44.312],[-95.7756,44.29746],[-95.70334,44.148286],[-95.703414,44.11414],[-95.54334,44.16687],[-95.553773,44.355588],[-95.63467,44.38496]]]},"properties":{"id":"56175","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.070596,44.717389],[-94.064812,44.829759],[-94.151611,44.86283],[-94.23271,44.877473],[-94.305135,44.790485],[-94.254614,44.657675],[-94.233772,44.666493],[-94.23349,44.68103],[-94.224116,44.680906],[-94.22212,44.666247],[-94.187616,44.646631],[-94.07144,44.70259],[-94.070596,44.717389]]]},"properties":{"id":"55336","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.132371,47.579226],[-96.067621,47.499136],[-95.938979,47.499125],[-95.896571,47.551728],[-95.991274,47.58605],[-96.132371,47.579226]]]},"properties":{"id":"56592","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.614442,43.587571],[-94.532385,43.500534],[-94.368066,43.500242],[-94.427883,43.760225],[-94.552242,43.751149],[-94.614442,43.587571]],[[-94.438085,43.733304],[-94.438109,43.737474],[-94.435261,43.738585],[-94.435255,43.732865],[-94.438085,43.733304]]]},"properties":{"id":"56031","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.854634,43.588093],[-94.854555,43.500925],[-94.714245,43.500865],[-94.674443,43.573154],[-94.854634,43.588093]]]},"properties":{"id":"56127","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.379301,45.152154],[-94.378655,44.986045],[-94.256074,44.979465],[-94.25568,45.166882],[-94.306737,45.20703],[-94.37917,45.201959],[-94.379301,45.152154]]]},"properties":{"id":"55325","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.426472,46.093033],[-94.576997,46.005882],[-94.513629,45.865654],[-94.431089,45.858297],[-94.225091,45.937238],[-94.188183,46.130826],[-94.334829,46.074382],[-94.426472,46.093033]],[[-94.529856,45.944915],[-94.524345,45.962503],[-94.509691,45.955151],[-94.529856,45.944915]]]},"properties":{"id":"56345","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.556102,45.816441],[-94.571334,45.799518],[-94.58558,45.77425],[-94.54954,45.77401],[-94.494631,45.712335],[-94.38772,45.69545],[-94.34719,45.71738],[-94.315098,45.77521],[-94.551002,45.816591],[-94.556102,45.816441]]]},"properties":{"id":"56340","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.749325,43.673846],[-92.769547,43.63042],[-92.652873,43.615843],[-92.609225,43.616002],[-92.623551,43.666544],[-92.749325,43.673846]]]},"properties":{"id":"55933","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.769547,43.63042],[-92.808627,43.499953],[-92.688718,43.500102],[-92.652873,43.615843],[-92.769547,43.63042]]]},"properties":{"id":"55909","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.958232,43.746658],[-92.969844,43.753969],[-92.971108,43.739254],[-92.958232,43.746658]]]},"properties":{"id":"55950","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.029226,43.93554],[-96.02374,43.84919],[-95.992828,43.848893],[-95.972887,43.833865],[-95.912706,43.834186],[-95.88415,43.84856],[-95.8839,43.89198],[-95.90377,43.9499],[-96.02391,43.94991],[-96.029226,43.93554]]]},"properties":{"id":"56122","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.993016,43.790244],[-96.012898,43.717392],[-95.872326,43.731814],[-95.851799,43.76108],[-95.912706,43.834186],[-95.972887,43.833865],[-95.993016,43.790244]]]},"properties":{"id":"56155","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.708663,47.498919],[-96.848923,47.498991],[-96.854483,47.454816],[-96.708626,47.411837],[-96.66548,47.498949],[-96.708663,47.498919]]]},"properties":{"id":"56581","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.854483,47.454816],[-96.837012,47.318386],[-96.689789,47.310243],[-96.708626,47.411837],[-96.854483,47.454816]]]},"properties":{"id":"56548","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.566305,43.926794],[-92.48406,43.89925],[-92.46303,44.02347],[-92.46449,44.02533],[-92.55825,44.03281],[-92.566305,43.926794]]]},"properties":{"id":"55902","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.15035,44.05686],[-92.079133,43.893255],[-92.01087,43.86162],[-91.957542,44.017773],[-92.079491,44.106988],[-92.15035,44.05686]]]},"properties":{"id":"55972","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.563129,46.110055],[-96.38943,46.05054],[-96.265398,46.094133],[-96.183306,46.108522],[-96.265342,46.209859],[-96.387883,46.208449],[-96.591166,46.195932],[-96.563129,46.110055]]]},"properties":{"id":"56522","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.09612,47.963696],[-96.13318,48.03476],[-96.28859,48.00644],[-96.09612,47.963696]]]},"properties":{"id":"56754","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.862473,48.03447],[-95.9034,47.99384],[-95.923617,47.862411],[-95.784919,47.83401],[-95.710236,48.009456],[-95.862473,48.03447]]]},"properties":{"id":"56742","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.453533,43.906408],[-96.4533,43.76217],[-96.3721,43.71827],[-96.33206,43.74753],[-96.24531,43.8486],[-96.244731,43.884983],[-96.264794,43.88923],[-96.366766,43.90723],[-96.371125,43.907215],[-96.366477,43.914441],[-96.453533,43.906408]]]},"properties":{"id":"56144","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.04194,48.151378],[-97.084145,48.05642],[-96.934707,47.766794],[-96.761661,47.90569],[-96.824698,48.050033],[-96.998673,48.15154],[-97.04194,48.151378]]]},"properties":{"id":"56721","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.330728,47.70196],[-96.139553,47.614589],[-96.095432,47.788832],[-96.11683,47.803322],[-96.351727,47.7603],[-96.330728,47.70196]]]},"properties":{"id":"56736","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.132371,47.579226],[-95.991274,47.58605],[-95.945203,47.760561],[-96.095432,47.788832],[-96.139553,47.614589],[-96.132371,47.579226]]]},"properties":{"id":"56535","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.759986,47.716992],[-96.937853,47.760237],[-96.871111,47.527115],[-96.729127,47.571511],[-96.728899,47.586022],[-96.759986,47.716992]]]},"properties":{"id":"56523","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.644656,45.441468],[-95.747402,45.412313],[-95.706094,45.381035],[-95.644656,45.441468]]]},"properties":{"id":"56226","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.126315,44.94943],[-93.146588,44.967349],[-93.167043,44.970268],[-93.20777,44.959664],[-93.207757,44.953209],[-93.20266,44.948375],[-93.126219,44.942264],[-93.126315,44.94943]]]},"properties":{"id":"55104","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.097036,45.119103],[-93.103307,45.043115],[-93.064656,45.037774],[-93.062276,45.0397],[-93.049572,45.124],[-93.097036,45.119103]]]},"properties":{"id":"55127","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.023724,44.991952],[-93.04985,44.919924],[-93.050888,44.919501],[-93.020044,44.89075],[-92.994282,44.890792],[-92.984207,44.893998],[-92.98464,44.948522],[-92.984696,44.992273],[-93.023724,44.991952]]]},"properties":{"id":"55119","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.244351,44.667111],[-95.360177,44.69854],[-95.401118,44.541777],[-95.350146,44.500659],[-95.289908,44.515565],[-95.238513,44.628757],[-95.244351,44.667111]]]},"properties":{"id":"56214","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.801687,44.62989],[-94.629225,44.614805],[-94.671399,44.87692],[-94.758189,44.892097],[-94.793541,44.892176],[-94.833367,44.65898],[-94.801687,44.62989]]]},"properties":{"id":"55342","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.5586,44.64408],[-94.559442,44.880393],[-94.584505,44.892177],[-94.671399,44.87692],[-94.629225,44.614805],[-94.5586,44.64408]]]},"properties":{"id":"55314","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.491447,44.471474],[-93.437637,44.382107],[-93.342285,44.455769],[-93.351936,44.464568],[-93.475588,44.529415],[-93.491447,44.471474]]]},"properties":{"id":"55046","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.180464,47.541349],[-92.04738,47.383382],[-91.949017,47.415817],[-91.789394,47.496933],[-91.792893,47.629834],[-92.180464,47.541349]]]},"properties":{"id":"55750","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.088846,46.81247],[-92.111906,46.794769],[-92.101462,46.788941],[-92.076823,46.798999],[-92.088846,46.81247]]]},"properties":{"id":"55805","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.233772,44.666493],[-94.22212,44.666247],[-94.224116,44.680906],[-94.23349,44.68103],[-94.233772,44.666493]]]},"properties":{"id":"55366","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.187616,44.646631],[-94.13002,44.50028],[-94.07024,44.50026],[-94.01071,44.61552],[-94.07144,44.70259],[-94.187616,44.646631]]]},"properties":{"id":"55307","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.729777,45.761015],[-94.829724,45.774399],[-94.89146,45.60014],[-94.819144,45.528194],[-94.762123,45.52811],[-94.753962,45.621852],[-94.747249,45.63295],[-94.729777,45.761015]],[[-94.85452,45.59954],[-94.870327,45.596295],[-94.870386,45.603998],[-94.859759,45.607391],[-94.85452,45.59954]]]},"properties":{"id":"56352","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.587151,45.38632],[-94.5057,45.36207],[-94.42314,45.39952],[-94.492202,45.53991],[-94.53597,45.54684],[-94.6293,45.498704],[-94.639941,45.437577],[-94.624527,45.43385],[-94.587151,45.38632]]]},"properties":{"id":"56368","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.54954,45.77401],[-94.690984,45.538111],[-94.6293,45.498704],[-94.53597,45.54684],[-94.494631,45.712335],[-94.54954,45.77401]]]},"properties":{"id":"56307","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.32724,45.4627],[-94.343219,45.475269],[-94.36388,45.46156],[-94.32724,45.4627]]]},"properties":{"id":"56369","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.099695,45.411963],[-96.014801,45.412173],[-96.117772,45.585634],[-96.099695,45.411963]]]},"properties":{"id":"56207","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.932408,45.398451],[-95.809488,45.246244],[-95.849922,45.383608],[-95.932408,45.398451]]]},"properties":{"id":"56249","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.094867,44.408395],[-92.101317,44.395175],[-92.066226,44.389888],[-92.084268,44.40603],[-92.112025,44.409832],[-92.094867,44.408395]]]},"properties":{"id":"55968","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.979666,44.890823],[-92.984207,44.893998],[-92.994282,44.890792],[-92.979666,44.890823]]],[[[-92.979666,44.890823],[-92.983999,44.861865],[-92.862411,44.861944],[-92.862673,44.948805],[-92.903416,44.948731],[-92.979666,44.890823]]]]},"properties":{"id":"55129","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.983973,44.851139],[-93.021395,44.789707],[-93.015961,44.775564],[-92.974217,44.772362],[-92.862551,44.847859],[-92.862411,44.861944],[-92.983999,44.861865],[-92.983973,44.851139]]]},"properties":{"id":"55016","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.509248,43.90607],[-94.36903,43.905922],[-94.369018,43.934939],[-94.489041,43.978534],[-94.509248,43.90607]]]},"properties":{"id":"56060","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.784381,46.630504],[-96.738508,46.498866],[-96.52963,46.44214],[-96.50887,46.50012],[-96.614609,46.630521],[-96.652465,46.630475],[-96.784381,46.630504]]]},"properties":{"id":"56594","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.930946,44.978262],[-93.889888,45.014086],[-93.857684,45.082882],[-93.913403,45.115384],[-93.971827,44.978262],[-93.930946,44.978262]]]},"properties":{"id":"55363","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.069841,45.365821],[-94.227454,45.19096],[-94.104217,45.152227],[-94.032525,45.157693],[-94.024641,45.331443],[-94.069841,45.365821]]]},"properties":{"id":"55302","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.142421,45.211028],[-93.184132,45.124329],[-93.096075,45.133696],[-93.074226,45.193832],[-93.142421,45.211028]]]},"properties":{"id":"55014","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.818762,46.974247],[-95.79439,46.970147],[-95.794499,46.977281],[-95.818762,46.974247]]]},"properties":{"id":"56577","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.71371,47.366],[-94.620911,47.236267],[-94.354089,47.199544],[-94.286181,47.226156],[-94.281477,47.261144],[-94.416326,47.415622],[-94.486462,47.496141],[-94.54417,47.522791],[-94.71371,47.366]]]},"properties":{"id":"56633","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.24894,47.6157],[-95.28753,47.268888],[-95.1696,47.266752],[-95.169522,47.277465],[-95.126633,47.583828],[-95.056509,47.673036],[-95.029101,47.80298],[-95.193905,47.8097],[-95.24894,47.6157]]]},"properties":{"id":"56676","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.545538,47.584529],[-94.671872,47.636888],[-94.805333,47.672409],[-95.056509,47.673036],[-95.126633,47.583828],[-95.015656,47.325129],[-94.71371,47.366],[-94.54417,47.522791],[-94.545521,47.58345],[-94.545241,47.584534],[-94.545538,47.584529]],[[-95.002355,47.500957],[-95.02119,47.49844],[-95.020638,47.51008],[-94.970196,47.49631],[-94.983612,47.483905],[-94.988366,47.498386],[-95.002355,47.500957]]]},"properties":{"id":"56601","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.029101,47.80298],[-95.056509,47.673036],[-94.805333,47.672409],[-94.806478,47.802427],[-94.806249,47.803539],[-94.948583,47.80246],[-95.029101,47.80298]]]},"properties":{"id":"56667","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.489041,43.978534],[-94.369018,43.934939],[-94.277421,43.99631],[-94.281757,44.00178],[-94.368848,44.170332],[-94.569699,44.108853],[-94.569576,44.080025],[-94.489041,43.978534]]]},"properties":{"id":"56062","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.853479,44.08728],[-93.859917,44.083954],[-93.856942,44.076369],[-93.853479,44.08728]]]},"properties":{"id":"56080","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.128183,44.09038],[-94.281757,44.00178],[-94.277421,43.99631],[-94.128439,43.999098],[-94.128673,44.083755],[-94.128183,44.09038]]]},"properties":{"id":"56034","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.569576,44.080025],[-94.569699,44.108853],[-94.700444,44.141555],[-94.700379,44.137914],[-94.7595,44.077514],[-94.71963,43.880663],[-94.719485,43.870046],[-94.614371,43.848178],[-94.509248,43.90607],[-94.489041,43.978534],[-94.569576,44.080025]],[[-94.569576,44.080025],[-94.569575,44.065616],[-94.589711,44.064671],[-94.589744,44.08004],[-94.569576,44.080025]]]},"properties":{"id":"56081","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.020218,44.13686],[-95.127044,44.347106],[-95.127277,44.369054],[-95.20784,44.325614],[-95.20835,44.318364],[-95.207999,44.311081],[-95.200704,44.108161],[-95.099916,44.093589],[-95.020218,44.13686]]]},"properties":{"id":"56083","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.449054,46.573239],[-92.589233,46.63514],[-92.734433,46.65013],[-92.863296,46.57049],[-92.803268,46.495038],[-92.64161,46.418207],[-92.553263,46.446795],[-92.46875,46.49335],[-92.449054,46.573239]]]},"properties":{"id":"55707","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.449054,46.573239],[-92.46875,46.49335],[-92.291976,46.503997],[-92.291976,46.655134],[-92.449054,46.573239]]]},"properties":{"id":"55797","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.64161,46.418207],[-92.678419,46.347048],[-92.621908,46.309544],[-92.490302,46.374455],[-92.553263,46.446795],[-92.64161,46.418207]]]},"properties":{"id":"55756","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.774486,44.691732],[-93.672758,44.7553],[-93.71907,44.804607],[-93.849459,44.848168],[-93.888398,44.848256],[-93.848985,44.782209],[-93.808954,44.695358],[-93.774486,44.691732]]]},"properties":{"id":"55322","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.693214,44.861445],[-93.637132,44.866964],[-93.706078,44.890662],[-93.693214,44.861445]]]},"properties":{"id":"55386","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.930946,44.978262],[-93.838909,44.90552],[-93.784494,44.905296],[-93.747292,44.91663],[-93.747063,44.931229],[-93.766442,44.975321],[-93.889888,45.014086],[-93.930946,44.978262]]]},"properties":{"id":"55388","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.6551,46.572142],[-94.727217,46.28232],[-94.720057,46.249163],[-94.486089,46.282611],[-94.518065,46.456806],[-94.519628,46.572468],[-94.6551,46.572142]]]},"properties":{"id":"56466","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.281477,47.261144],[-94.09734,47.335789],[-94.32651,47.415712],[-94.416279,47.444828],[-94.416326,47.415622],[-94.281477,47.261144]]]},"properties":{"id":"56626","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.178465,47.165648],[-94.286181,47.226156],[-94.354089,47.199544],[-94.285808,47.164041],[-94.178465,47.165648]]]},"properties":{"id":"56641","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.71371,47.366],[-95.015656,47.325129],[-95.169522,47.277465],[-95.1696,47.266752],[-95.045853,47.152782],[-94.90859,47.17822],[-94.791355,47.106405],[-94.663794,47.063626],[-94.620911,47.236267],[-94.71371,47.366]],[[-94.696379,47.156992],[-94.69495,47.16448],[-94.67962,47.16439],[-94.67957,47.15711],[-94.696379,47.156992]]]},"properties":{"id":"56461","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.980339,45.097574],[-95.796709,45.180792],[-95.809488,45.246244],[-95.932408,45.398451],[-95.932491,45.412355],[-96.014801,45.412173],[-96.099695,45.411963],[-96.117036,45.411993],[-96.103614,45.176675],[-95.98092,45.05925],[-95.980339,45.097574]]]},"properties":{"id":"56208","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.407026,47.124209],[-96.648312,47.078943],[-96.689898,47.049626],[-96.689799,47.006086],[-96.44138,46.977113],[-96.407416,47.03124],[-96.407026,47.124209]]]},"properties":{"id":"56536","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.821708,47.063975],[-96.784381,46.630504],[-96.652465,46.630475],[-96.672672,46.818804],[-96.652233,46.86229],[-96.693744,46.862126],[-96.715032,46.890796],[-96.672775,46.891027],[-96.689799,47.006086],[-96.689898,47.049626],[-96.821708,47.063975]],[[-96.758332,46.673752],[-96.737105,46.659395],[-96.758205,46.644782],[-96.758332,46.673752]]]},"properties":{"id":"56560","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.385634,47.804671],[-95.553392,47.67361],[-95.553537,47.586797],[-95.298379,47.630954],[-95.385634,47.804671]]]},"properties":{"id":"56634","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.5813,47.87603],[-95.57542,47.67366],[-95.553392,47.67361],[-95.385634,47.804671],[-95.39907,47.82338],[-95.582065,47.925878],[-95.5813,47.87603]]]},"properties":{"id":"56644","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.996365,48.039228],[-89.966296,47.838898],[-89.868711,47.8744],[-89.886528,47.986305],[-89.996365,48.039228]]]},"properties":{"id":"55606","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.768553,47.936415],[-90.896206,47.758941],[-90.873297,47.553654],[-90.766372,47.609555],[-90.768553,47.936415]]]},"properties":{"id":"55615","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.868711,47.8744],[-89.697619,47.941288],[-89.886528,47.986305],[-89.868711,47.8744]]]},"properties":{"id":"55605","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.954751,43.826086],[-95.039845,44.079224],[-95.059921,44.079235],[-95.099794,44.021371],[-94.954751,43.826086]]]},"properties":{"id":"56118","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-93.217753,44.717423],[-93.207923,44.701072],[-93.187306,44.712463],[-93.180726,44.717349],[-93.217753,44.717423]]],[[[-93.156218,44.775765],[-93.156817,44.717356],[-93.156242,44.702967],[-93.034815,44.674626],[-92.974217,44.772362],[-93.015961,44.775564],[-93.020162,44.775572],[-93.106259,44.775687],[-93.156218,44.775765]]]]},"properties":{"id":"55068","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.934181,44.557768],[-93.035298,44.566784],[-93.126755,44.55809],[-93.039869,44.500865],[-93.011252,44.511512],[-92.934181,44.557768]]]},"properties":{"id":"55065","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.207923,44.701072],[-93.248179,44.543891],[-93.126755,44.55809],[-93.035298,44.566784],[-93.025002,44.639875],[-93.034815,44.674626],[-93.156242,44.702967],[-93.187306,44.712463],[-93.207923,44.701072]]]},"properties":{"id":"55024","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.925305,44.088033],[-92.965284,43.96476],[-92.945047,43.935698],[-92.809258,43.954058],[-92.798752,44.047307],[-92.758943,44.127155],[-92.925305,44.088033]]]},"properties":{"id":"55927","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.823426,44.225648],[-93.04591,44.196716],[-93.094835,44.160569],[-93.04563,44.124353],[-92.925305,44.088033],[-92.758943,44.127155],[-92.728788,44.130647],[-92.795387,44.211342],[-92.823426,44.225648]]]},"properties":{"id":"55985","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.531156,45.788016],[-95.387946,45.759365],[-95.330436,45.758858],[-95.242999,45.787775],[-95.263915,45.880122],[-95.281644,45.933286],[-95.312629,46.019863],[-95.430981,46.041917],[-95.51858,45.888242],[-95.531156,45.788016]]]},"properties":{"id":"56308","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.768498,43.499709],[-93.648533,43.49968],[-93.648388,43.543084],[-93.728059,43.586678],[-93.768498,43.499709]]]},"properties":{"id":"56051","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.079133,43.893255],[-92.19894,43.93392],[-92.318206,43.904437],[-92.3489,43.81924],[-92.30895,43.79009],[-92.20945,43.77894],[-92.024364,43.78105],[-92.02529,43.82259],[-92.01087,43.86162],[-92.079133,43.893255]]]},"properties":{"id":"55923","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.20691,43.6944],[-92.20945,43.77894],[-92.30895,43.79009],[-92.239266,43.66191],[-92.20691,43.6944]]]},"properties":{"id":"55990","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.609013,43.586924],[-92.628825,43.500211],[-92.28939,43.500555],[-92.28945,43.54413],[-92.40947,43.55841],[-92.545433,43.601699],[-92.609013,43.586924]]]},"properties":{"id":"55951","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.41351,43.564761],[-93.428714,43.556734],[-93.358698,43.499487],[-93.089287,43.499637],[-93.049445,43.55774],[-93.169077,43.622932],[-93.169093,43.630157],[-93.268506,43.607047],[-93.41351,43.564761]]]},"properties":{"id":"56036","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.1242,43.774691],[-93.159247,43.731983],[-93.169077,43.622932],[-93.049445,43.55774],[-92.89864,43.572555],[-92.768117,43.674028],[-92.789586,43.703146],[-92.894436,43.775886],[-92.950045,43.797952],[-93.1242,43.774691]],[[-92.958232,43.746658],[-92.971108,43.739254],[-92.969844,43.753969],[-92.958232,43.746658]]]},"properties":{"id":"55912","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.470258,44.455433],[-92.716407,44.478127],[-92.731993,44.478056],[-92.797061,44.369958],[-92.766817,44.344529],[-92.766595,44.344688],[-92.586104,44.360562],[-92.476035,44.346492],[-92.470258,44.455433]]]},"properties":{"id":"55027","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.89619,46.1083],[-95.769618,45.991627],[-95.670522,46.117784],[-95.77004,46.165644],[-95.89619,46.1083]]]},"properties":{"id":"56309","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.350189,44.85881],[-93.351882,44.789893],[-93.329615,44.791086],[-93.329589,44.791099],[-93.328941,44.858841],[-93.350189,44.85881]]]},"properties":{"id":"55437","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.26889,44.937767],[-93.296631,44.922516],[-93.268271,44.923327],[-93.26889,44.937767]]]},"properties":{"id":"55409","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.274425,45.005765],[-93.20754,45.006154],[-93.208092,45.035744],[-93.282696,45.032427],[-93.274889,45.013126],[-93.274425,45.005765]]]},"properties":{"id":"55418","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.319305,45.04018],[-93.282696,45.032427],[-93.282094,45.069493],[-93.280826,45.071665],[-93.320017,45.087214],[-93.32507,45.041056],[-93.319305,45.04018]]]},"properties":{"id":"55430","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.444427,44.891424],[-93.485396,44.862339],[-93.398697,44.836803],[-93.398775,44.861961],[-93.412654,44.891987],[-93.443268,44.891342],[-93.444427,44.891424]]]},"properties":{"id":"55344","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.31839,45.013256],[-93.274889,45.013126],[-93.282696,45.032427],[-93.319305,45.04018],[-93.31839,45.013256]]]},"properties":{"id":"55412","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.361473,45.094448],[-93.360649,45.151882],[-93.401731,45.152037],[-93.402138,45.094582],[-93.361473,45.094448]]]},"properties":{"id":"55445","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.320017,45.087214],[-93.280826,45.071665],[-93.296095,45.121897],[-93.298986,45.128892],[-93.320017,45.087214]]]},"properties":{"id":"55444","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.748668,45.064651],[-93.766442,44.975321],[-93.747063,44.931229],[-93.630456,44.976296],[-93.614548,45.022236],[-93.635254,45.051136],[-93.680452,45.065283],[-93.748668,45.064651]]]},"properties":{"id":"55359","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.267593,44.973621],[-93.253997,44.969015],[-93.245506,44.977612],[-93.266518,44.97707],[-93.267593,44.973621]]]},"properties":{"id":"55415","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.522079,45.065537],[-93.522125,45.131092],[-93.635751,45.116704],[-93.635254,45.051136],[-93.614548,45.022236],[-93.542242,45.026108],[-93.522217,45.027527],[-93.522079,45.065537]]]},"properties":{"id":"55340","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.758343,45.472718],[-93.653261,45.410684],[-93.464669,45.413921],[-93.450324,45.471676],[-93.450745,45.484144],[-93.758684,45.501772],[-93.758343,45.472718]]]},"properties":{"id":"55398","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.19536,47.63311],[-93.319533,47.356407],[-93.091993,47.382063],[-93.091059,47.393457],[-93.069399,47.407916],[-93.062859,47.587529],[-93.19536,47.63311]]]},"properties":{"id":"55769","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.06544,47.65269],[-94.036175,47.636456],[-93.903306,47.586186],[-93.821479,47.66498],[-93.827369,47.673589],[-94.033895,47.672872],[-94.073066,47.672813],[-94.06544,47.65269]]]},"properties":{"id":"56680","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.776284,47.811547],[-93.542038,47.751927],[-93.247933,47.893524],[-93.343276,48.069624],[-93.748906,47.98522],[-93.776173,47.846404],[-93.776284,47.811547]]]},"properties":{"id":"56639","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.335275,43.630686],[-95.514656,43.499867],[-95.335315,43.500387],[-95.335275,43.630686]]]},"properties":{"id":"56167","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.131923,45.2562],[-94.805051,45.275578],[-94.805177,45.282915],[-94.824872,45.399385],[-95.131791,45.383767],[-95.131945,45.354774],[-95.13214,45.326302],[-95.131923,45.2562]]]},"properties":{"id":"56273","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.131791,45.383767],[-94.824872,45.399385],[-94.819144,45.528194],[-94.89146,45.60014],[-94.995028,45.585333],[-95.131791,45.383767]],[[-94.94877,45.56438],[-94.941092,45.567407],[-94.943516,45.56131],[-94.94877,45.56438]]]},"properties":{"id":"56312","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.131923,45.2562],[-95.13214,45.326302],[-95.246654,45.238683],[-95.206574,45.123819],[-95.144911,45.110003],[-95.083411,45.232784],[-95.131923,45.2562]]]},"properties":{"id":"56279","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.125481,44.891438],[-95.016263,44.891689],[-94.982861,44.891908],[-95.002572,45.008745],[-95.064152,44.979105],[-95.125026,44.964033],[-95.125293,44.935063],[-95.125481,44.891438]]]},"properties":{"id":"56216","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.346448,48.134228],[-92.625154,48.438228],[-92.703692,48.481135],[-92.969756,48.467601],[-93.094737,48.300567],[-93.097237,48.142329],[-93.096488,48.067826],[-93.22659,48.07145],[-93.22665,48.120773],[-93.343276,48.069624],[-93.247933,47.893524],[-92.470728,47.915662],[-92.346448,48.134228]]]},"properties":{"id":"55771","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.452319,45.115094],[-96.451626,44.848683],[-96.31694,44.87764],[-96.32804,45.05318],[-96.452319,45.115094]]]},"properties":{"id":"56257","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.793981,46.940109],[-91.774569,46.976634],[-91.794148,46.989308],[-91.793981,46.940109]]]},"properties":{"id":"55609","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.99875,48.967959],[-95.044618,48.743817],[-94.819459,48.629315],[-94.865895,48.876699],[-94.99875,48.967959]]]},"properties":{"id":"56686","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.879352,44.35186],[-94.129643,44.44232],[-94.185137,44.428702],[-94.10508,44.28303],[-94.013274,44.268469],[-93.888579,44.268811],[-93.877695,44.275619],[-93.879352,44.35186]]]},"properties":{"id":"56082","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.404421,44.386068],[-96.452442,44.284237],[-96.452489,44.268583],[-96.403872,44.255111],[-96.265469,44.168211],[-96.245739,44.168956],[-96.199592,44.197268],[-96.221041,44.342943],[-96.241539,44.372167],[-96.323572,44.408105],[-96.404421,44.386068]]]},"properties":{"id":"56149","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.8969,44.34105],[-95.93794,44.43796],[-95.95838,44.45807],[-96.0393,44.41515],[-95.8969,44.34105]]]},"properties":{"id":"56157","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.256074,44.979465],[-94.23271,44.877473],[-94.151611,44.86283],[-94.114163,44.920926],[-94.134286,44.978804],[-94.13909,44.978791],[-94.256074,44.979465]]]},"properties":{"id":"55381","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.57542,47.67366],[-95.707933,47.732457],[-95.794281,47.732216],[-95.896571,47.551728],[-95.938979,47.499125],[-95.86921,47.440976],[-95.678517,47.390039],[-95.596797,47.539599],[-95.553537,47.586797],[-95.553392,47.67361],[-95.57542,47.67366]]]},"properties":{"id":"56542","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.797601,48.540506],[-95.86194,48.392666],[-95.765236,48.32632],[-95.602315,48.538887],[-95.775802,48.540556],[-95.797601,48.540506]]]},"properties":{"id":"56724","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.163355,48.543967],[-97.122739,48.420327],[-97.107171,48.413922],[-96.649281,48.427364],[-96.627699,48.543774],[-96.758626,48.543592],[-96.867871,48.543312],[-96.955159,48.543699],[-97.041707,48.544281],[-97.163355,48.543967]]]},"properties":{"id":"56757","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.854634,43.588093],[-94.674443,43.573154],[-94.664761,43.587701],[-94.694535,43.732566],[-94.854546,43.762114],[-94.854634,43.588093]]]},"properties":{"id":"56171","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.763417,45.253646],[-94.706637,45.008121],[-94.676015,44.993574],[-94.604286,45.239682],[-94.640731,45.272259],[-94.763417,45.253646]]]},"properties":{"id":"56243","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.587151,45.38632],[-94.640731,45.272259],[-94.604286,45.239682],[-94.530251,45.24112],[-94.5057,45.36207],[-94.587151,45.38632]]]},"properties":{"id":"55329","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.383325,45.384967],[-94.37916,45.202255],[-94.37917,45.201959],[-94.306737,45.20703],[-94.233306,45.421075],[-94.32814,45.42846],[-94.383325,45.384967]]]},"properties":{"id":"55353","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.584149,44.906652],[-94.676015,44.993574],[-94.706637,45.008121],[-94.757573,44.993837],[-94.758189,44.892097],[-94.671399,44.87692],[-94.584505,44.892177],[-94.584149,44.906652]]]},"properties":{"id":"56228","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.648217,46.020416],[-94.576997,46.005882],[-94.426472,46.093033],[-94.495751,46.165527],[-94.647787,46.107816],[-94.647856,46.048921],[-94.648217,46.020416]]]},"properties":{"id":"56475","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.768117,43.674028],[-92.749325,43.673846],[-92.623551,43.666544],[-92.669799,43.833731],[-92.689283,43.833847],[-92.789777,43.761364],[-92.789586,43.703146],[-92.768117,43.674028]]]},"properties":{"id":"55926","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.90377,43.9499],[-95.8839,43.89198],[-95.70357,43.90607],[-95.70341,43.92055],[-95.62267,44.00769],[-95.703414,44.11414],[-95.70334,44.148286],[-95.82339,44.12382],[-95.84363,44.08765],[-95.90377,43.9499]]]},"properties":{"id":"56172","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.10508,44.28303],[-94.194183,44.200143],[-94.129116,44.184886],[-94.011834,44.239192],[-94.013274,44.268469],[-94.10508,44.28303]]]},"properties":{"id":"56003","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.10508,44.28303],[-94.185137,44.428702],[-94.2307,44.4275],[-94.276416,44.400342],[-94.250874,44.326944],[-94.248069,44.220083],[-94.194183,44.200143],[-94.10508,44.28303]]]},"properties":{"id":"56074","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.754034,43.499917],[-95.594277,43.499902],[-95.713734,43.587577],[-95.754034,43.499917]]]},"properties":{"id":"56117","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.650934,43.761627],[-95.752647,43.790485],[-95.773227,43.717647],[-95.733433,43.674224],[-95.650934,43.761627]]]},"properties":{"id":"56165","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.330728,47.70196],[-96.421374,47.655283],[-96.451641,47.499129],[-96.430171,47.44838],[-96.131649,47.470171],[-96.067621,47.499136],[-96.132371,47.579226],[-96.139553,47.614589],[-96.330728,47.70196]]]},"properties":{"id":"56540","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.109711,47.151669],[-96.109635,47.16617],[-96.152063,47.165949],[-96.152263,47.151425],[-96.109711,47.151669]]]},"properties":{"id":"56541","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.67819,44.05791],[-92.647865,43.92074],[-92.638234,43.915067],[-92.566305,43.926794],[-92.55825,44.03281],[-92.573349,44.079312],[-92.601546,44.107801],[-92.678262,44.108059],[-92.67819,44.05791]]]},"properties":{"id":"55920","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.293065,46.287785],[-92.29286,46.41722],[-92.490302,46.374455],[-92.621908,46.309544],[-92.76992,46.281641],[-92.63644,46.15754],[-92.293065,46.287785]]]},"properties":{"id":"55712","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.761661,47.90569],[-96.676498,47.890872],[-96.504203,47.93458],[-96.482586,48.021673],[-96.500888,48.035645],[-96.824698,48.050033],[-96.761661,47.90569]]]},"properties":{"id":"56722","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.77854,45.585398],[-95.69325,45.571828],[-95.655477,45.672523],[-95.737404,45.708762],[-95.779176,45.716336],[-95.77854,45.585398]]]},"properties":{"id":"56323","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.128629,44.919648],[-93.065276,44.919527],[-93.050888,44.919501],[-93.04985,44.919924],[-93.079158,44.9465],[-93.092245,44.943234],[-93.128629,44.919648]]]},"properties":{"id":"55107","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.492717,44.3664],[-95.370162,44.326598],[-95.349856,44.354704],[-95.370075,44.442468],[-95.451096,44.45737],[-95.492717,44.3664]]]},"properties":{"id":"56255","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.4348,44.833092],[-95.422177,44.732],[-95.360177,44.69854],[-95.244351,44.667111],[-95.280923,44.891343],[-95.381906,44.891081],[-95.4348,44.833092]]]},"properties":{"id":"56285","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.332062,43.500415],[-96.15445,43.50019],[-96.17316,43.54367],[-96.31238,43.55829],[-96.332062,43.500415]]]},"properties":{"id":"56173","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.31238,43.55829],[-96.17316,43.54367],[-96.133214,43.558451],[-96.11342,43.57288],[-96.11344,43.58724],[-96.07308,43.68872],[-96.14267,43.7611],[-96.33206,43.74753],[-96.3721,43.71827],[-96.39227,43.70374],[-96.31238,43.55829]]]},"properties":{"id":"56156","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.4533,43.76217],[-96.45345,43.64593],[-96.39227,43.70374],[-96.3721,43.71827],[-96.4533,43.76217]]]},"properties":{"id":"57030","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.45339,43.57318],[-96.43202,43.57289],[-96.43235,43.60899],[-96.45347,43.6089],[-96.45339,43.57318]]]},"properties":{"id":"57068","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.11342,43.57288],[-96.09335,43.57292],[-96.09348,43.58722],[-96.11344,43.58724],[-96.11342,43.57288]]]},"properties":{"id":"56146","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.04738,47.383382],[-92.337681,47.426044],[-92.372573,47.371427],[-92.302695,47.268736],[-92.047181,47.26776],[-91.949017,47.415817],[-92.04738,47.383382]]]},"properties":{"id":"55763","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.337681,47.426044],[-92.04738,47.383382],[-92.180464,47.541349],[-92.181518,47.55804],[-92.306838,47.60044],[-92.325477,47.521006],[-92.337681,47.426044]]]},"properties":{"id":"55705","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.806293,47.477911],[-92.738535,47.375979],[-92.565056,47.49934],[-92.564757,47.531611],[-92.688862,47.572874],[-92.729335,47.527949],[-92.739016,47.504261],[-92.806293,47.477911]]]},"properties":{"id":"55768","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.688862,47.572874],[-92.59498,47.61455],[-92.574159,47.671378],[-92.881117,47.64257],[-92.864738,47.632945],[-92.801015,47.499164],[-92.760469,47.504519],[-92.729335,47.527949],[-92.688862,47.572874]]]},"properties":{"id":"55710","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.409092,44.753801],[-93.339459,44.717501],[-93.328482,44.717528],[-93.328704,44.74643],[-93.329615,44.791086],[-93.351882,44.789893],[-93.398827,44.798763],[-93.409092,44.753801]]]},"properties":{"id":"55378","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.819988,45.515686],[-93.922316,45.385021],[-93.819578,45.324114],[-93.758343,45.472718],[-93.758684,45.501772],[-93.819988,45.515686]]]},"properties":{"id":"55308","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.494631,45.712335],[-94.53597,45.54684],[-94.492202,45.53991],[-94.461495,45.544013],[-94.38772,45.69545],[-94.494631,45.712335]]]},"properties":{"id":"56310","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.403926,45.580472],[-94.384941,45.573455],[-94.383034,45.586755],[-94.403926,45.580472]]]},"properties":{"id":"56321","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.2763,43.957053],[-93.260122,43.957135],[-93.255966,43.971648],[-93.286119,43.971498],[-93.286158,43.957013],[-93.2763,43.957053]]]},"properties":{"id":"56046","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.945204,45.108348],[-92.984792,45.034223],[-92.984791,45.034049],[-92.943081,45.035902],[-92.943011,45.036086],[-92.945204,45.108348]],[[-92.959818,45.052938],[-92.959795,45.056287],[-92.954834,45.056291],[-92.954876,45.050167],[-92.959818,45.052938]]]},"properties":{"id":"55115","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.878268,45.180574],[-92.756681,45.151309],[-92.752308,45.216866],[-92.896992,45.228198],[-92.884501,45.210318],[-92.878268,45.180574]]]},"properties":{"id":"55047","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.839427,44.057935],[-94.834937,44.050579],[-94.841801,44.050445],[-94.859187,44.050093],[-94.859388,43.848093],[-94.71963,43.880663],[-94.7595,44.077514],[-94.839427,44.057935]]]},"properties":{"id":"56120","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.012298,45.143445],[-93.997871,45.139397],[-94.012282,45.152051],[-94.012298,45.143445]]],[[[-94.104217,45.152227],[-94.13909,44.978791],[-94.134286,44.978804],[-94.012236,44.978712],[-94.007272,44.97862],[-94.012298,45.143445],[-94.032525,45.157693],[-94.104217,45.152227]]]]},"properties":{"id":"55349","state_code":"27"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.042066,31.336008],[-90.940174,31.610566],[-91.153864,31.610068],[-91.15815,31.346695],[-91.095398,31.320975],[-91.042066,31.336008]]]},"properties":{"id":"39661","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.365253,34.895238],[-88.405649,34.787466],[-88.404223,34.755714],[-88.344283,34.726919],[-88.307194,34.728603],[-88.363932,34.971984],[-88.365253,34.895238]]]},"properties":{"id":"38833","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.940174,31.610566],[-91.042066,31.336008],[-90.888081,31.349078],[-90.789692,31.349503],[-90.719314,31.43351],[-90.767775,31.611377],[-90.84394,31.64786],[-90.940174,31.610566]],[[-90.855704,31.440108],[-90.86041,31.4693],[-90.82636,31.46811],[-90.855704,31.440108]]]},"properties":{"id":"39653","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.122759,34.75012],[-89.022208,34.821839],[-89.105107,34.948809],[-89.188197,34.895591],[-89.263073,34.852846],[-89.252383,34.772591],[-89.234426,34.719082],[-89.158037,34.750401],[-89.122759,34.75012]]]},"properties":{"id":"38603","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.799346,34.120614],[-90.677047,34.060678],[-90.764432,34.17634],[-90.955974,34.120125],[-90.826351,34.120709],[-90.80249,34.141914],[-90.788286,34.150636],[-90.773414,34.137506],[-90.799346,34.120614]]]},"properties":{"id":"38720","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.764565,34.072123],[-89.507594,34.041107],[-89.384666,34.08884],[-89.456624,34.162049],[-89.451111,34.234448],[-89.59616,34.260225],[-89.603424,34.257228],[-89.720715,34.235683],[-89.773961,34.245782],[-89.77636,34.163684],[-89.764565,34.072123]]]},"properties":{"id":"38965","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.785763,33.599448],[-89.607045,33.714497],[-89.613972,33.814599],[-89.798069,33.897272],[-89.831498,33.897674],[-89.934743,33.877206],[-89.995939,33.809711],[-89.887215,33.676525],[-89.874733,33.59871],[-89.806988,33.59803],[-89.785763,33.599448]]]},"properties":{"id":"38901","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.837933,34.074968],[-88.70235,34.037304],[-88.650132,34.09907],[-88.66083,34.177374],[-88.864137,34.183406],[-88.837933,34.074968]]]},"properties":{"id":"38868","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.368134,33.285886],[-89.200686,33.197562],[-89.178061,33.197614],[-89.302474,33.3907],[-89.353906,33.411684],[-89.381737,33.352111],[-89.368134,33.285886]]]},"properties":{"id":"39772","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.55667,31.96586],[-90.450627,32.084704],[-90.450312,32.150499],[-90.560523,32.161332],[-90.727881,32.226019],[-90.795763,32.143247],[-90.81981,32.06499],[-90.620954,31.948159],[-90.55667,31.96586]]]},"properties":{"id":"39175","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.872516,32.327057],[-88.95649,32.262073],[-88.954053,32.179277],[-88.931394,32.170649],[-88.849805,32.044715],[-88.810203,32.065545],[-88.767152,32.166535],[-88.73193,32.22533],[-88.872516,32.327057]]]},"properties":{"id":"39330","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.03637,33.541546],[-88.871503,33.564644],[-88.959717,33.717256],[-88.972189,33.710685],[-89.017148,33.639927],[-89.03637,33.541546]]]},"properties":{"id":"39755","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.46843,34.465065],[-90.459122,34.433189],[-90.451219,34.454839],[-90.46843,34.465065]]],[[[-90.448724,34.334932],[-90.39906,34.308579],[-90.399822,34.334878],[-90.448724,34.334932]]],[[[-90.490646,34.428616],[-90.585781,34.383599],[-90.607111,34.350197],[-90.54495,34.284325],[-90.465894,34.319647],[-90.463683,34.327744],[-90.442224,34.407334],[-90.490646,34.428616]]]]},"properties":{"id":"38617","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.46843,34.465065],[-90.539861,34.507476],[-90.536855,34.475391],[-90.490646,34.428616],[-90.442224,34.407334],[-90.459122,34.433189],[-90.46843,34.465065]]]},"properties":{"id":"38644","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.679602,31.568316],[-89.620916,31.548722],[-89.601503,31.535945],[-89.40028,31.632395],[-89.400539,31.686169],[-89.400626,31.700459],[-89.486202,31.771587],[-89.755567,31.642406],[-89.679602,31.568316]]]},"properties":{"id":"39428","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.64483,31.87308],[-89.83993,31.78365],[-89.839201,31.769354],[-89.82291,31.748136],[-89.755567,31.642406],[-89.486202,31.771587],[-89.498132,31.80814],[-89.64483,31.87308]]]},"properties":{"id":"39119","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.458782,31.246443],[-89.330866,31.213983],[-89.403872,31.434023],[-89.477961,31.434019],[-89.458782,31.246443]]]},"properties":{"id":"39402","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.855704,31.440108],[-90.82636,31.46811],[-90.86041,31.4693],[-90.855704,31.440108]]]},"properties":{"id":"39630","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.767775,31.611377],[-90.719314,31.43351],[-90.632929,31.42216],[-90.61205,31.46135],[-90.684016,31.600766],[-90.767775,31.611377]]]},"properties":{"id":"39647","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.098522,30.214136],[-89.076518,30.230257],[-89.115879,30.23058],[-89.098522,30.214136]]],[[[-88.915842,30.419485],[-88.933829,30.39797],[-88.934231,30.392495],[-88.915842,30.419485]]]]},"properties":{"id":"39530","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.190816,32.301978],[-90.186816,32.326762],[-90.207742,32.317966],[-90.211121,32.300393],[-90.210103,32.297546],[-90.191179,32.295283],[-90.190816,32.301978]]]},"properties":{"id":"39203","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.207742,32.317966],[-90.265622,32.356434],[-90.32795,32.295655],[-90.330202,32.267434],[-90.280737,32.279144],[-90.211197,32.297761],[-90.211121,32.300393],[-90.207742,32.317966]]],[[[-90.287514,32.472714],[-90.394259,32.487714],[-90.403294,32.453332],[-90.269835,32.377291],[-90.287514,32.472714]]]]},"properties":{"id":"39209","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.485525,32.28592],[-90.560523,32.161332],[-90.450312,32.150499],[-90.329667,32.210216],[-90.330202,32.267434],[-90.32795,32.295655],[-90.407349,32.292532],[-90.485525,32.28592]]]},"properties":{"id":"39154","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.05925,32.88548],[-91.007427,32.957937],[-91.033971,32.929271],[-91.052035,32.924051],[-91.05925,32.88548]]]},"properties":{"id":"39113","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.246955,34.33947],[-88.309093,34.133484],[-88.203264,34.091511],[-88.1727,34.32833],[-88.246955,34.33947]]]},"properties":{"id":"38876","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.530694,30.384199],[-88.522494,30.340092],[-88.44667,30.384301],[-88.507168,30.390039],[-88.530694,30.384199]]]},"properties":{"id":"39581","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.293978,32.411083],[-89.35024,32.20396],[-89.228396,32.22366],[-89.236327,32.385277],[-89.235656,32.400211],[-89.293978,32.411083]]]},"properties":{"id":"39336","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.101575,31.706438],[-90.006142,31.436776],[-89.959103,31.504896],[-89.974917,31.681535],[-90.101575,31.706438]]]},"properties":{"id":"39663","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.891293,31.402521],[-89.80962,31.434073],[-89.74857,31.543525],[-89.891009,31.522138],[-89.891293,31.402521]]]},"properties":{"id":"39427","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.101575,31.706438],[-89.974917,31.681535],[-89.82291,31.748136],[-89.839201,31.769354],[-89.886773,31.766588],[-90.138079,31.874429],[-90.159168,31.844743],[-90.123103,31.751972],[-90.101575,31.706438]]]},"properties":{"id":"39140","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.166024,31.443641],[-89.059856,31.398265],[-88.98186,31.57633],[-89.073708,31.603928],[-89.166024,31.443641]]]},"properties":{"id":"39464","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.948587,32.59039],[-88.915309,32.459946],[-88.884843,32.442707],[-88.794416,32.572623],[-88.839695,32.601911],[-88.915235,32.726407],[-88.930143,32.669337],[-88.948587,32.59039]]]},"properties":{"id":"39325","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.915235,32.726407],[-88.898062,32.798566],[-89.014904,32.898632],[-89.240536,32.982795],[-89.302374,32.996899],[-89.319047,32.985291],[-89.319163,32.931654],[-89.34613,32.679218],[-89.317883,32.620138],[-88.930143,32.669337],[-88.915235,32.726407]]]},"properties":{"id":"39350","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.456624,34.162049],[-89.34193,34.16169],[-89.451111,34.234448],[-89.456624,34.162049]]]},"properties":{"id":"38949","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.982962,32.409133],[-88.95649,32.262073],[-88.872516,32.327057],[-88.902872,32.397921],[-88.884843,32.442707],[-88.915309,32.459946],[-88.986796,32.416321],[-88.982962,32.409133]]]},"properties":{"id":"39323","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.794416,32.572623],[-88.884843,32.442707],[-88.902872,32.397921],[-88.712719,32.385297],[-88.663025,32.404688],[-88.637387,32.438099],[-88.60497,32.426228],[-88.581694,32.492496],[-88.615219,32.544556],[-88.62428,32.550108],[-88.709938,32.542584],[-88.794416,32.572623]]]},"properties":{"id":"39305","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.649829,32.50624],[-89.467868,32.576398],[-89.505849,32.630847],[-89.730304,32.634228],[-89.863104,32.575772],[-89.854493,32.564987],[-89.827096,32.483707],[-89.773218,32.494093],[-89.649829,32.50624]]]},"properties":{"id":"39094","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.755502,34.297577],[-88.878082,34.249658],[-88.864137,34.183406],[-88.66083,34.177374],[-88.681546,34.220664],[-88.755502,34.297577]]]},"properties":{"id":"38801","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.453233,33.329484],[-90.407571,33.323673],[-90.453181,33.349456],[-90.453233,33.329484]]]},"properties":{"id":"38958","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.336173,31.466515],[-90.61205,31.46135],[-90.632929,31.42216],[-90.59561,31.39441],[-90.37039,31.349953],[-90.342773,31.35013],[-90.336173,31.466515]]]},"properties":{"id":"39629","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.016914,33.444496],[-88.871214,33.286377],[-88.813053,33.286424],[-88.72392,33.286539],[-88.669937,33.371341],[-88.670538,33.426022],[-88.640415,33.456455],[-88.632499,33.506519],[-88.770596,33.565212],[-88.871503,33.564644],[-89.03637,33.541546],[-89.016914,33.444496]],[[-88.706258,33.462607],[-88.706385,33.46589],[-88.704643,33.466364],[-88.703721,33.462579],[-88.706258,33.462607]],[[-88.800349,33.449146],[-88.795873,33.458598],[-88.787143,33.4549],[-88.790088,33.447043],[-88.800349,33.449146]]]},"properties":{"id":"39759","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.484232,33.287911],[-88.72392,33.286539],[-88.813053,33.286424],[-88.813172,33.173511],[-88.313634,33.213743],[-88.304434,33.28832],[-88.484232,33.287911]]]},"properties":{"id":"39739","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.056899,34.42278],[-90.091769,34.481336],[-90.106589,34.376116],[-90.056899,34.42278]]],[[[-90.041378,34.427683],[-89.721137,34.444606],[-89.721013,34.448581],[-90.041378,34.427683]]]]},"properties":{"id":"38666","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.162178,34.177553],[-90.127938,34.15989],[-90.141105,34.181734],[-90.162178,34.177553]]]},"properties":{"id":"38622","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.02714,34.183508],[-90.119944,34.160058],[-90.126241,34.072991],[-90.02248,34.110465],[-89.931485,34.164303],[-90.015983,34.18458],[-90.02714,34.183508]]]},"properties":{"id":"38927","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.824966,34.364759],[-88.855314,34.36507],[-88.82476,34.343259],[-88.824966,34.364759]]]},"properties":{"id":"38869","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.120607,34.709081],[-88.307194,34.728603],[-88.344283,34.726919],[-88.3307,34.592056],[-88.330813,34.580412],[-88.171636,34.606545],[-88.135204,34.615878],[-88.120607,34.709081]]]},"properties":{"id":"38873","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.426991,34.231002],[-90.168644,34.233624],[-90.193528,34.33374],[-90.254224,34.350079],[-90.330144,34.358902],[-90.399753,34.345008],[-90.399822,34.334878],[-90.39906,34.308579],[-90.399032,34.279519],[-90.426991,34.231002]]]},"properties":{"id":"38646","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.181908,32.201912],[-90.13692,32.195605],[-90.167107,32.279547],[-90.167234,32.279568],[-90.179701,32.276162],[-90.186774,32.27263],[-90.190994,32.20966],[-90.181908,32.201912]]]},"properties":{"id":"39218","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.598406,33.705728],[-90.451574,33.699559],[-90.451698,33.803616],[-90.598628,33.768785],[-90.611366,33.770072],[-90.598406,33.705728]]]},"properties":{"id":"38771","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.400743,33.958244],[-90.42644,33.931207],[-90.379526,33.902233],[-90.284121,33.896701],[-90.300434,33.97933],[-90.400743,33.958244]]]},"properties":{"id":"38966","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.009598,33.994698],[-89.776964,33.995089],[-89.764565,34.072123],[-89.77636,34.163684],[-89.808797,34.163889],[-89.931485,34.164303],[-90.02248,34.110465],[-90.009598,33.994698]]]},"properties":{"id":"38948","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.234794,34.995492],[-88.234351,34.988726],[-88.214895,34.995585],[-88.234794,34.995492]]]},"properties":{"id":"38326","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.060053,33.077764],[-91.040125,33.010758],[-91.015926,33.058225],[-91.060053,33.077764]]]},"properties":{"id":"38731","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.654589,32.556241],[-90.630121,32.54606],[-90.54408,32.66209],[-90.670064,32.669581],[-90.723159,32.662128],[-90.720102,32.618858],[-90.654589,32.556241]]]},"properties":{"id":"39162","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.000202,34.918442],[-88.80017,34.846107],[-88.753015,34.799779],[-88.752972,34.842651],[-88.753522,34.995295],[-89.104247,34.994727],[-89.105107,34.948809],[-89.000202,34.918442]]]},"properties":{"id":"38683","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.25977,31.055704],[-90.34454,31.06256],[-90.668203,31.068632],[-90.753343,30.99929],[-90.259555,31.000679],[-90.25977,31.055704]]]},"properties":{"id":"39657","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.15815,31.346695],[-91.338981,31.342144],[-91.192417,31.218084],[-91.095398,31.320975],[-91.15815,31.346695]]]},"properties":{"id":"39633","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.688836,33.091374],[-89.730003,32.876936],[-89.730113,32.800906],[-89.319163,32.931654],[-89.319047,32.985291],[-89.318827,33.000061],[-89.5825,33.182206],[-89.688836,33.091374]]]},"properties":{"id":"39090","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.368134,33.285886],[-89.381737,33.352111],[-89.453923,33.355033],[-89.545423,33.285838],[-89.560293,33.273376],[-89.503936,33.226198],[-89.368134,33.285886]]]},"properties":{"id":"39745","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.466306,34.993879],[-89.263073,34.852846],[-89.188197,34.895591],[-89.352679,34.993863],[-89.466306,34.993879]]]},"properties":{"id":"38642","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.77135,33.915972],[-90.762822,33.901484],[-90.74335,33.903531],[-90.754071,33.91615],[-90.77135,33.915972]]]},"properties":{"id":"38781","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.631519,33.599537],[-90.658579,33.689118],[-90.892949,33.718476],[-90.902053,33.703683],[-90.9632,33.552014],[-90.884181,33.530414],[-90.763509,33.529389],[-90.631519,33.599537]]]},"properties":{"id":"38773","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.799346,34.120614],[-90.773414,34.137506],[-90.80249,34.141914],[-90.826351,34.120709],[-90.799346,34.120614]]],[[[-90.764432,34.17634],[-90.677047,34.060678],[-90.660243,34.016825],[-90.657002,33.987586],[-90.577802,33.986732],[-90.56485,33.986598],[-90.450981,34.072932],[-90.450693,34.139902],[-90.54495,34.284325],[-90.607111,34.350197],[-90.639758,34.348753],[-90.764432,34.17634]],[[-90.68525,34.294944],[-90.668744,34.280936],[-90.676225,34.261989],[-90.703471,34.281465],[-90.68525,34.294944]]]]},"properties":{"id":"38614","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.48261,33.656267],[-89.393656,33.709081],[-89.507269,33.80896],[-89.613972,33.814599],[-89.607045,33.714497],[-89.48261,33.656267]]]},"properties":{"id":"38929","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.897935,33.51973],[-89.889282,33.524519],[-89.908287,33.521044],[-89.897935,33.51973]]],[[[-89.897935,33.51973],[-89.897443,33.409359],[-89.88798,33.393306],[-89.823765,33.393578],[-89.645899,33.346294],[-89.621488,33.518258],[-89.785763,33.599448],[-89.806988,33.59803],[-89.880481,33.524654],[-89.897935,33.51973]]]]},"properties":{"id":"38967","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.995939,33.809711],[-90.136491,33.809319],[-90.136787,33.721648],[-90.073441,33.668664],[-89.887215,33.676525],[-89.995939,33.809711]]]},"properties":{"id":"38940","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.122199,33.822004],[-88.93123,33.804922],[-88.856833,33.811798],[-88.791623,33.840474],[-88.882589,34.00219],[-89.138228,33.971846],[-89.122199,33.822004]]]},"properties":{"id":"38851","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.232316,33.448455],[-89.088657,33.441821],[-89.13359,33.647248],[-89.164493,33.655031],[-89.232316,33.448455]]]},"properties":{"id":"39752","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.108824,33.215886],[-89.088658,33.440936],[-89.088657,33.441821],[-89.232316,33.448455],[-89.302474,33.3907],[-89.178061,33.197614],[-89.108824,33.215886]]]},"properties":{"id":"39735","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.13359,33.647248],[-89.088657,33.441821],[-89.088658,33.440936],[-89.016914,33.444496],[-89.03637,33.541546],[-89.017148,33.639927],[-89.13359,33.647248]]]},"properties":{"id":"39750","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.18441,31.92533],[-90.922362,31.84293],[-90.887678,31.91941],[-90.81981,32.06499],[-90.795763,32.143247],[-91.030207,32.114353],[-91.18441,31.92533]]]},"properties":{"id":"39150","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.89233,31.76856],[-90.736702,31.724767],[-90.716782,31.845833],[-90.887678,31.91941],[-90.922362,31.84293],[-90.89233,31.76856]]]},"properties":{"id":"39144","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.935414,31.7409],[-88.867348,31.696956],[-88.53918,31.93152],[-88.80742,31.96344],[-88.88093,31.87341],[-88.935414,31.7409]]]},"properties":{"id":"39360","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.690253,31.698272],[-90.133738,31.754579],[-90.123103,31.751972],[-90.159168,31.844743],[-90.244845,31.86823],[-90.55667,31.96586],[-90.620954,31.948159],[-90.716782,31.845833],[-90.736702,31.724767],[-90.690253,31.698272]]]},"properties":{"id":"39083","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.679602,31.568316],[-89.755567,31.642406],[-89.82291,31.748136],[-89.974917,31.681535],[-89.959103,31.504896],[-89.891009,31.522138],[-89.74857,31.543525],[-89.679602,31.568316]]]},"properties":{"id":"39474","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.892715,34.877422],[-89.825763,34.840917],[-89.724324,34.99479],[-89.936754,34.994876],[-89.919152,34.906521],[-89.892715,34.877422]]]},"properties":{"id":"38654","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.724324,34.99479],[-89.825763,34.840917],[-89.753114,34.772374],[-89.688969,34.685657],[-89.590549,34.833486],[-89.593748,34.924311],[-89.529393,34.956194],[-89.528748,34.99473],[-89.724324,34.99479]]]},"properties":{"id":"38611","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.006387,34.918994],[-90.078305,34.93327],[-90.078059,34.860275],[-89.892715,34.877422],[-89.919152,34.906521],[-89.992154,34.918982],[-90.006387,34.918994]]]},"properties":{"id":"38651","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.638317,31.238286],[-89.654038,31.002502],[-89.341167,30.806922],[-89.27488,30.82751],[-89.246643,31.003545],[-89.276966,31.031751],[-89.554251,31.242943],[-89.638317,31.238286]]]},"properties":{"id":"39455","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.336173,31.466515],[-90.244497,31.474149],[-90.244653,31.548484],[-90.278095,31.632681],[-90.6558,31.67293],[-90.684016,31.600766],[-90.61205,31.46135],[-90.336173,31.466515]]]},"properties":{"id":"39601","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.340947,30.373273],[-89.186885,30.330823],[-89.203209,30.415178],[-89.311732,30.529123],[-89.326802,30.557844],[-89.341534,30.550867],[-89.361756,30.412103],[-89.340947,30.373273]]]},"properties":{"id":"39571","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.340947,30.373273],[-89.361756,30.412103],[-89.401943,30.365004],[-89.340947,30.373273]]]},"properties":{"id":"39525","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.82257,30.624803],[-89.492612,30.586728],[-89.47417,30.647979],[-89.835963,30.735846],[-89.82257,30.624803]]]},"properties":{"id":"39426","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.124338,30.353697],[-89.001334,30.387022],[-89.001241,30.388065],[-89.057439,30.396774],[-89.136636,30.398449],[-89.124338,30.353697]]]},"properties":{"id":"39501","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.88313,30.418798],[-88.88343,30.464665],[-88.917203,30.453691],[-88.916752,30.423156],[-88.88313,30.418798]]]},"properties":{"id":"39540","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.403294,32.453332],[-90.407349,32.292532],[-90.32795,32.295655],[-90.265622,32.356434],[-90.269835,32.377291],[-90.403294,32.453332]]]},"properties":{"id":"39056","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.364031,33.010596],[-90.293188,32.995353],[-90.208677,33.22052],[-90.330578,33.197637],[-90.43195,33.095917],[-90.364031,33.010596]]]},"properties":{"id":"39169","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.971909,33.021063],[-90.140199,32.940757],[-90.097392,32.882537],[-90.050975,32.734565],[-89.957619,32.745274],[-89.869719,32.865056],[-89.951241,33.003263],[-89.971909,33.021063]]]},"properties":{"id":"39146","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.536795,33.020508],[-90.590664,33.055897],[-90.657797,33.066298],[-90.656632,32.92251],[-90.512553,32.978125],[-90.536795,33.020508]]]},"properties":{"id":"39097","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.293188,32.995353],[-90.364031,33.010596],[-90.512553,32.978125],[-90.656632,32.92251],[-90.734157,32.856729],[-90.670064,32.669581],[-90.54408,32.66209],[-90.303801,32.739583],[-90.173932,32.952697],[-90.293188,32.995353]]]},"properties":{"id":"39194","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.68633,33.18405],[-90.546717,33.282003],[-90.706454,33.308731],[-90.716277,33.270032],[-90.68633,33.18405]]]},"properties":{"id":"38754","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.125875,33.040003],[-91.052035,32.924051],[-91.033971,32.929271],[-91.034361,32.967387],[-90.914337,33.007697],[-90.91397,33.029724],[-91.015926,33.058225],[-91.040125,33.010758],[-91.060053,33.077764],[-91.125875,33.040003]],[[-91.040125,33.010758],[-91.002116,33.010693],[-91.001814,33.003332],[-91.040211,33.003419],[-91.040125,33.010758]]]},"properties":{"id":"38744","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.544271,34.162162],[-88.401713,34.087487],[-88.309093,34.133484],[-88.246955,34.33947],[-88.33875,34.463766],[-88.356438,34.464086],[-88.407377,34.367342],[-88.517528,34.241408],[-88.562052,34.187378],[-88.544271,34.162162]]]},"properties":{"id":"38843","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.763351,34.422666],[-88.544646,34.334386],[-88.538605,34.386325],[-88.82471,34.563611],[-88.833346,34.547589],[-88.83283,34.540662],[-88.763351,34.422666]]]},"properties":{"id":"38849","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.098173,32.222752],[-89.077408,32.16572],[-89.06608,32.21468],[-89.098173,32.222752]]],[[[-89.098173,32.222752],[-89.053566,32.380365],[-89.236327,32.385277],[-89.228396,32.22366],[-89.098173,32.222752]]]]},"properties":{"id":"39345","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.18312,31.65337],[-89.138877,31.656157],[-89.11377,31.68501],[-89.15337,31.75232],[-89.18312,31.65337]]]},"properties":{"id":"39440","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.014904,32.898632],[-88.898062,32.798566],[-88.737031,32.926502],[-88.812721,32.992012],[-88.813179,32.991809],[-88.968513,32.926554],[-89.014904,32.898632]]]},"properties":{"id":"39354","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.725571,32.635852],[-88.709938,32.542584],[-88.62428,32.550108],[-88.620459,32.580541],[-88.725571,32.635852]]]},"properties":{"id":"39326","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.610165,34.504554],[-89.255315,34.469783],[-89.246133,34.495633],[-89.40435,34.527108],[-89.468008,34.545327],[-89.610165,34.504554]]]},"properties":{"id":"38601","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.403842,32.448773],[-88.581694,32.492496],[-88.60497,32.426228],[-88.418006,32.338177],[-88.403842,32.448773]]]},"properties":{"id":"39364","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.637387,32.438099],[-88.663025,32.404688],[-88.643314,32.406149],[-88.637387,32.438099]]]},"properties":{"id":"39342","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.66083,34.177374],[-88.650132,34.09907],[-88.544271,34.162162],[-88.562052,34.187378],[-88.681546,34.220664],[-88.66083,34.177374]]]},"properties":{"id":"38862","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.787409,31.088987],[-89.93354,31.08914],[-89.940973,31.001479],[-89.752642,31.001853],[-89.787409,31.088987]]]},"properties":{"id":"39478","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.968513,32.926554],[-89.240536,32.982795],[-89.014904,32.898632],[-88.968513,32.926554]]]},"properties":{"id":"39346","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.931485,34.164303],[-89.808797,34.163889],[-90.015983,34.18458],[-89.931485,34.164303]]]},"properties":{"id":"38658","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.404223,34.755714],[-88.718508,34.756781],[-88.780925,34.648233],[-88.784058,34.643394],[-88.496724,34.496599],[-88.410343,34.583729],[-88.3307,34.592056],[-88.344283,34.726919],[-88.404223,34.755714]]]},"properties":{"id":"38829","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.330144,34.358902],[-90.268194,34.382422],[-90.254224,34.350079],[-90.193528,34.33374],[-90.24764,34.510395],[-90.399498,34.423807],[-90.399753,34.345008],[-90.330144,34.358902]]]},"properties":{"id":"38670","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.13692,32.195605],[-90.181908,32.201912],[-90.248421,32.153664],[-90.247204,32.032667],[-90.224273,31.996597],[-90.06999,31.99102],[-90.039212,32.087972],[-90.035559,32.088953],[-90.032063,32.085202],[-90.001926,32.155121],[-90.027105,32.204546],[-90.13692,32.195605]]]},"properties":{"id":"39073","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.001926,32.155121],[-89.885521,32.09073],[-89.729889,32.075667],[-89.720695,32.08286],[-89.731159,32.195444],[-89.88555,32.381416],[-90.025078,32.324251],[-90.03792,32.325926],[-90.027105,32.204546],[-90.001926,32.155121]]]},"properties":{"id":"39042","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.941963,31.902159],[-89.95847,31.883988],[-89.886773,31.766588],[-89.839201,31.769354],[-89.83993,31.78365],[-89.729889,32.075667],[-89.885521,32.09073],[-89.89436,31.99161],[-89.906933,31.97959],[-89.922191,31.990344],[-89.941963,31.902159]]]},"properties":{"id":"39114","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.467868,32.576398],[-89.649829,32.50624],[-89.516201,32.311002],[-89.526373,32.152544],[-89.426022,32.147597],[-89.378166,32.198617],[-89.337916,32.47109],[-89.368197,32.514266],[-89.467868,32.576398]]]},"properties":{"id":"39074","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.670064,32.669581],[-90.734157,32.856729],[-90.736023,32.855142],[-90.776869,32.814233],[-90.823851,32.69297],[-90.758863,32.662176],[-90.723159,32.662128],[-90.670064,32.669581]]]},"properties":{"id":"39088","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.224273,31.996597],[-90.195658,31.943182],[-90.138341,31.875319],[-90.045087,31.917724],[-90.06999,31.99102],[-90.224273,31.996597]]]},"properties":{"id":"39082","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.519908,33.964465],[-90.492183,33.935833],[-90.450317,33.963727],[-90.536076,33.98624],[-90.519908,33.964465]]]},"properties":{"id":"38768","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.171636,34.606545],[-88.330813,34.580412],[-88.329193,34.463571],[-88.253155,34.478106],[-88.171636,34.606545]]]},"properties":{"id":"38838","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.932478,33.226751],[-90.840528,33.270094],[-90.932439,33.298537],[-90.932478,33.226751]]]},"properties":{"id":"38722","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.463783,34.810296],[-88.752972,34.842651],[-88.753015,34.799779],[-88.718508,34.756781],[-88.404223,34.755714],[-88.405649,34.787466],[-88.463783,34.810296]]]},"properties":{"id":"38865","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.365253,34.895238],[-88.463783,34.810296],[-88.405649,34.787466],[-88.365253,34.895238]]]},"properties":{"id":"38846","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.719314,31.43351],[-90.789692,31.349503],[-90.61569,31.22198],[-90.59561,31.39441],[-90.632929,31.42216],[-90.719314,31.43351]]]},"properties":{"id":"39664","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.260129,31.1377],[-90.44491,31.18851],[-90.47103,31.1894],[-90.526693,31.166611],[-90.602007,31.195016],[-90.668203,31.068632],[-90.34454,31.06256],[-90.259838,31.072907],[-90.260129,31.1377]]]},"properties":{"id":"39652","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.963332,31.103945],[-90.888081,31.349078],[-91.042066,31.336008],[-91.095398,31.320975],[-91.192417,31.218084],[-91.197877,31.177524],[-90.963332,31.103945]]]},"properties":{"id":"39638","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.870384,33.004044],[-89.799732,32.885249],[-89.730003,32.876936],[-89.688836,33.091374],[-89.821743,33.073812],[-89.870384,33.004044]]]},"properties":{"id":"39160","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.503936,33.226198],[-89.560293,33.273376],[-89.604297,33.238942],[-89.5825,33.182206],[-89.318827,33.000061],[-89.503936,33.226198]]]},"properties":{"id":"39067","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.000202,34.918442],[-89.105107,34.948809],[-89.022208,34.821839],[-88.893858,34.837909],[-89.000202,34.918442]]]},"properties":{"id":"38629","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.656952,33.952275],[-90.754071,33.91615],[-90.74335,33.903531],[-90.762822,33.901484],[-90.77135,33.915972],[-90.842787,33.897609],[-90.867494,33.864352],[-90.610035,33.849437],[-90.656952,33.952275]]]},"properties":{"id":"38762","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.457285,33.951217],[-89.388391,33.931677],[-89.262682,33.916507],[-89.195313,34.002981],[-89.187049,34.030317],[-89.26064,34.050863],[-89.457285,33.951217]]]},"properties":{"id":"38951","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.889463,33.552672],[-89.908287,33.521044],[-89.889282,33.524519],[-89.889463,33.552672]]]},"properties":{"id":"38947","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.088658,33.440936],[-89.108824,33.215886],[-88.871214,33.286377],[-89.016914,33.444496],[-89.088658,33.440936]]]},"properties":{"id":"39769","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.216616,31.781254],[-90.936278,31.702724],[-90.89233,31.76856],[-90.922362,31.84293],[-91.18441,31.92533],[-91.266024,31.768904],[-91.216616,31.781254]]]},"properties":{"id":"39096","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.741568,32.152867],[-88.443603,32.131021],[-88.418006,32.338177],[-88.60497,32.426228],[-88.637387,32.438099],[-88.643314,32.406149],[-88.663025,32.404688],[-88.712719,32.385297],[-88.73193,32.22533],[-88.767152,32.166535],[-88.741568,32.152867]]]},"properties":{"id":"39301","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.118544,34.655828],[-89.713407,34.640567],[-89.688969,34.685657],[-89.753114,34.772374],[-90.200199,34.724418],[-90.230345,34.703084],[-90.231583,34.699525],[-90.118544,34.655828]]]},"properties":{"id":"38618","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.334329,31.324768],[-89.329019,31.324788],[-89.329045,31.331967],[-89.334759,31.332038],[-89.334329,31.324768]]]},"properties":{"id":"39406","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.764565,34.072123],[-89.776964,33.995089],[-89.798069,33.897272],[-89.613972,33.814599],[-89.507269,33.80896],[-89.507269,33.809013],[-89.507382,33.922563],[-89.507594,34.041107],[-89.764565,34.072123]]]},"properties":{"id":"38922","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.560245,30.267747],[-89.631614,30.257013],[-89.592369,30.205287],[-89.560245,30.267747]]]},"properties":{"id":"39572","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.47417,30.647979],[-89.341167,30.806922],[-89.654038,31.002502],[-89.728119,31.0023],[-89.835963,30.735846],[-89.47417,30.647979]]]},"properties":{"id":"39470","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.081993,30.677011],[-89.206654,30.677089],[-89.326802,30.557844],[-89.311732,30.529123],[-89.050273,30.50766],[-88.889709,30.511101],[-88.884417,30.65155],[-89.081993,30.677011]]]},"properties":{"id":"39574","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.162149,32.410397],[-90.153596,32.393098],[-90.147027,32.380369],[-90.145156,32.399788],[-90.162149,32.410397]]],[[[-90.287514,32.472714],[-90.269835,32.377291],[-90.265622,32.356434],[-90.207742,32.317966],[-90.186816,32.326762],[-90.183279,32.341172],[-90.176765,32.400242],[-90.243737,32.459118],[-90.248092,32.487577],[-90.287514,32.472714]]]]},"properties":{"id":"39213","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.153596,32.393098],[-90.176765,32.400242],[-90.183279,32.341172],[-90.154391,32.348352],[-90.147027,32.380369],[-90.153596,32.393098]]]},"properties":{"id":"39206","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.870384,33.004044],[-89.821743,33.073812],[-89.936618,33.235685],[-89.971909,33.021063],[-89.951241,33.003263],[-89.870384,33.004044]]]},"properties":{"id":"39063","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.590664,33.055897],[-90.536795,33.020508],[-90.554714,33.068607],[-90.590664,33.055897]]]},"properties":{"id":"39115","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.84815,33.031633],[-90.768856,33.059544],[-90.79525,33.074025],[-90.821811,33.082147],[-90.84815,33.031633]]],[[[-90.922226,32.77451],[-90.947597,32.772371],[-90.909227,32.823698],[-90.776869,32.814233],[-90.736023,32.855142],[-90.888008,33.037947],[-90.913906,33.047095],[-90.91397,33.029724],[-90.914337,33.007697],[-91.007427,32.957937],[-91.05925,32.88548],[-90.958477,32.652709],[-90.758863,32.662176],[-90.823851,32.69297],[-90.922226,32.77451]]]]},"properties":{"id":"39159","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.1727,34.32833],[-88.152982,34.488027],[-88.253155,34.478106],[-88.329193,34.463571],[-88.33875,34.463766],[-88.246955,34.33947],[-88.1727,34.32833]]]},"properties":{"id":"38847","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.544639,34.324271],[-88.54454,34.264801],[-88.517528,34.241408],[-88.407377,34.367342],[-88.503843,34.408217],[-88.538605,34.386325],[-88.544646,34.334386],[-88.544639,34.324271]]]},"properties":{"id":"38855","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.568014,34.331338],[-88.794048,34.393706],[-88.824966,34.364759],[-88.82476,34.343259],[-88.755502,34.297577],[-88.681546,34.220664],[-88.562052,34.187378],[-88.517528,34.241408],[-88.54454,34.264801],[-88.568014,34.331338]]]},"properties":{"id":"38804","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.216616,31.781254],[-91.153864,31.610068],[-90.940174,31.610566],[-90.84394,31.64786],[-90.936278,31.702724],[-91.216616,31.781254]]]},"properties":{"id":"39069","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.668755,34.574212],[-90.056564,34.554176],[-90.092047,34.525057],[-90.091769,34.481336],[-90.056899,34.42278],[-90.041378,34.427683],[-89.721013,34.448581],[-89.610165,34.504554],[-89.468008,34.545327],[-89.668448,34.554367],[-89.668755,34.574212]]]},"properties":{"id":"38619","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.12264,34.117741],[-88.856731,34.074911],[-88.837933,34.074968],[-88.864137,34.183406],[-88.878082,34.249658],[-88.882355,34.30996],[-88.928403,34.365014],[-89.092121,34.380201],[-89.138026,34.379942],[-89.246053,34.255762],[-89.24593,34.234127],[-89.12264,34.117741]]]},"properties":{"id":"38863","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.902872,32.397921],[-88.872516,32.327057],[-88.73193,32.22533],[-88.712719,32.385297],[-88.902872,32.397921]]]},"properties":{"id":"39307","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.658579,33.689118],[-90.631519,33.599537],[-90.609364,33.565311],[-90.452251,33.609438],[-90.436499,33.665391],[-90.451574,33.699559],[-90.598406,33.705728],[-90.658643,33.693604],[-90.658579,33.689118]]]},"properties":{"id":"38736","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.270781,33.564924],[-88.364961,33.549115],[-88.392486,33.520027],[-88.302759,33.302211],[-88.270781,33.564924]]]},"properties":{"id":"39702","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.367093,33.909798],[-88.55427,34.022601],[-88.617774,33.995559],[-88.711305,33.840153],[-88.672975,33.720021],[-88.498265,33.680137],[-88.358084,33.828835],[-88.367093,33.909798]]]},"properties":{"id":"39730","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.407423,34.087586],[-88.55427,34.022601],[-88.367093,33.909798],[-88.288433,34.008286],[-88.407423,34.087586]]]},"properties":{"id":"38821","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.231979,33.890472],[-88.24154,33.802962],[-88.229771,33.886954],[-88.231979,33.890472]]]},"properties":{"id":"38844","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.235656,32.400211],[-89.236327,32.385277],[-89.053566,32.380365],[-88.982962,32.409133],[-88.986796,32.416321],[-89.064767,32.494535],[-89.252279,32.534088],[-89.235656,32.400211]]]},"properties":{"id":"39327","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.337916,32.47109],[-89.293978,32.411083],[-89.235656,32.400211],[-89.252279,32.534088],[-89.322591,32.571919],[-89.33861,32.559421],[-89.368197,32.514266],[-89.337916,32.47109]]]},"properties":{"id":"39057","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.773961,34.245782],[-90.02714,34.183508],[-90.015983,34.18458],[-89.808797,34.163889],[-89.77636,34.163684],[-89.773961,34.245782]]]},"properties":{"id":"38620","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.991309,31.22601],[-89.03088,31.17133],[-88.92288,31.07855],[-88.9,31.07036],[-88.841193,31.193403],[-88.841121,31.207177],[-88.991309,31.22601]]]},"properties":{"id":"39423","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.032063,32.085202],[-90.035559,32.088953],[-90.039212,32.087972],[-90.032063,32.085202]]]},"properties":{"id":"39167","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.081993,30.677011],[-89.165924,30.709793],[-89.206654,30.677089],[-89.081993,30.677011]]]},"properties":{"id":"39561","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.494417,33.927858],[-90.519908,33.964465],[-90.536076,33.98624],[-90.56485,33.986598],[-90.577802,33.986732],[-90.494417,33.927858]]]},"properties":{"id":"38738","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.764439,33.444373],[-90.746794,33.445786],[-90.746383,33.451236],[-90.753636,33.448331],[-90.760453,33.448317],[-90.764444,33.444668],[-90.764439,33.444373]]]},"properties":{"id":"38749","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.118544,34.655828],[-90.056564,34.554176],[-89.668755,34.574212],[-89.713407,34.640567],[-90.118544,34.655828]]]},"properties":{"id":"38668","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.230345,34.703084],[-90.464899,34.772883],[-90.542678,34.509137],[-90.246409,34.510395],[-90.198593,34.548409],[-90.231583,34.699525],[-90.230345,34.703084]]]},"properties":{"id":"38676","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.304532,34.860588],[-90.464899,34.772883],[-90.230345,34.703084],[-90.200199,34.724418],[-90.202126,34.860374],[-90.304532,34.860588]]]},"properties":{"id":"38664","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.050975,32.734565],[-90.097392,32.882537],[-90.12628,32.699882],[-90.050975,32.734565]]]},"properties":{"id":"39179","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.61569,31.22198],[-90.789692,31.349503],[-90.888081,31.349078],[-90.963332,31.103945],[-90.980297,30.999083],[-90.753343,30.99929],[-90.668203,31.068632],[-90.602007,31.195016],[-90.61569,31.22198]]]},"properties":{"id":"39645","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.503936,33.226198],[-89.318827,33.000061],[-89.319047,32.985291],[-89.302374,32.996899],[-89.200686,33.197562],[-89.368134,33.285886],[-89.503936,33.226198]]]},"properties":{"id":"39108","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.104247,34.994727],[-89.352679,34.993863],[-89.188197,34.895591],[-89.105107,34.948809],[-89.104247,34.994727]]]},"properties":{"id":"38647","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.05324,34.569466],[-88.922261,34.597415],[-89.122759,34.75012],[-89.158037,34.750401],[-89.095549,34.655691],[-89.05324,34.569466]]]},"properties":{"id":"38610","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.874714,33.732872],[-90.658643,33.693604],[-90.598406,33.705728],[-90.611366,33.770072],[-90.832276,33.791874],[-90.871261,33.761913],[-90.874714,33.732872]]]},"properties":{"id":"38732","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.1651,33.529423],[-91.152769,33.543408],[-91.173797,33.544652],[-91.1651,33.529423]]]},"properties":{"id":"38702","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.507269,33.809013],[-89.388391,33.931677],[-89.457285,33.951217],[-89.507382,33.922563],[-89.507269,33.809013]]]},"properties":{"id":"38914","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.187049,34.030317],[-89.195313,34.002981],[-89.138228,33.971846],[-88.882589,34.00219],[-88.856731,34.074911],[-89.12264,34.117741],[-89.187049,34.030317]]]},"properties":{"id":"38850","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.071808,33.347355],[-90.208677,33.22052],[-90.293188,32.995353],[-90.173932,32.952697],[-90.140199,32.940757],[-89.971909,33.021063],[-89.936618,33.235685],[-89.875732,33.259576],[-89.96301,33.286212],[-90.071808,33.347355]]]},"properties":{"id":"39095","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.321513,33.375121],[-90.174786,33.359229],[-90.252299,33.494577],[-90.306339,33.417292],[-90.313264,33.391464],[-90.321513,33.375121]]]},"properties":{"id":"38954","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.617774,33.995559],[-88.70235,34.037304],[-88.837933,34.074968],[-88.856731,34.074911],[-88.882589,34.00219],[-88.791623,33.840474],[-88.711305,33.840153],[-88.617774,33.995559]]]},"properties":{"id":"38860","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.190561,33.789061],[-88.972189,33.710685],[-88.959717,33.717256],[-88.931525,33.76478],[-88.93123,33.804922],[-89.122199,33.822004],[-89.190561,33.789061]]]},"properties":{"id":"39776","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.191116,33.763777],[-89.164493,33.655031],[-89.13359,33.647248],[-89.017148,33.639927],[-88.972189,33.710685],[-89.190561,33.789061],[-89.191116,33.763777]]]},"properties":{"id":"39751","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.054448,31.962218],[-88.88093,31.87341],[-88.80742,31.96344],[-88.860807,31.992466],[-88.960821,32.009597],[-89.054448,31.962218]]]},"properties":{"id":"39366","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.05525,31.78487],[-89.053159,31.806101],[-89.019173,31.791442],[-89.045001,31.772075],[-88.935414,31.7409],[-88.88093,31.87341],[-89.054448,31.962218],[-89.110807,31.965342],[-89.116301,31.890746],[-89.05525,31.78487]]]},"properties":{"id":"39439","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.773414,34.137506],[-90.788286,34.150636],[-90.80249,34.141914],[-90.773414,34.137506]]]},"properties":{"id":"38767","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.490646,34.428616],[-90.536855,34.475391],[-90.627573,34.376174],[-90.585781,34.383599],[-90.490646,34.428616]]],[[[-90.539861,34.507476],[-90.46843,34.465065],[-90.451219,34.454839],[-90.399498,34.423807],[-90.24764,34.510395],[-90.246409,34.510395],[-90.542678,34.509137],[-90.539861,34.507476]]]]},"properties":{"id":"38626","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.450627,32.084704],[-90.55667,31.96586],[-90.244845,31.86823],[-90.195658,31.943182],[-90.224273,31.996597],[-90.247204,32.032667],[-90.450627,32.084704]]]},"properties":{"id":"39059","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.299027,31.434149],[-89.3846,31.458758],[-89.403872,31.434023],[-89.330866,31.213983],[-89.246967,31.068259],[-89.145481,31.166854],[-89.145594,31.224317],[-89.299027,31.434149]],[[-89.334329,31.324768],[-89.334759,31.332038],[-89.329045,31.331967],[-89.329019,31.324788],[-89.334329,31.324768]]]},"properties":{"id":"39401","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.992154,34.918982],[-90.011221,34.928276],[-90.006387,34.918994],[-89.992154,34.918982]]],[[[-89.992154,34.918982],[-89.919152,34.906521],[-89.936754,34.994876],[-89.955897,34.994955],[-89.936778,34.927644],[-89.945627,34.927797],[-89.992154,34.918982]]]]},"properties":{"id":"38672","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.884668,30.840463],[-88.874153,30.909918],[-88.9,31.07036],[-88.92288,31.07855],[-89.246643,31.003545],[-89.27488,30.82751],[-88.884668,30.840463]]]},"properties":{"id":"39577","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.884668,30.840463],[-88.661054,30.734722],[-88.641144,30.610749],[-88.409429,30.668593],[-88.4284,31.048588],[-88.75262,30.997464],[-88.874153,30.909918],[-88.884668,30.840463]]]},"properties":{"id":"39452","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.688994,31.477358],[-88.64152,31.328578],[-88.43898,31.246896],[-88.455448,31.546836],[-88.601949,31.520475],[-88.688994,31.477358]]]},"properties":{"id":"39362","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.34503,30.293771],[-89.408292,30.363066],[-89.477687,30.342434],[-89.560245,30.267747],[-89.592369,30.205287],[-89.405294,30.260522],[-89.387533,30.312222],[-89.34503,30.293771]]]},"properties":{"id":"39520","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.697419,32.347789],[-90.727881,32.226019],[-90.560523,32.161332],[-90.485525,32.28592],[-90.603065,32.481918],[-90.697419,32.347789]]]},"properties":{"id":"39066","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.154391,32.348352],[-90.129056,32.331677],[-90.117078,32.342256],[-90.06678,32.399036],[-90.067549,32.399545],[-90.145156,32.399788],[-90.147027,32.380369],[-90.154391,32.348352]]]},"properties":{"id":"39211","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.546717,33.282003],[-90.68633,33.18405],[-90.701845,33.096294],[-90.657797,33.066298],[-90.590664,33.055897],[-90.554714,33.068607],[-90.43195,33.095917],[-90.330578,33.197637],[-90.321513,33.375121],[-90.313264,33.391464],[-90.407571,33.323673],[-90.453233,33.329484],[-90.546717,33.282003]]]},"properties":{"id":"39038","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.554714,33.068607],[-90.536795,33.020508],[-90.512553,32.978125],[-90.364031,33.010596],[-90.43195,33.095917],[-90.554714,33.068607]]]},"properties":{"id":"39166","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.95649,32.262073],[-88.982962,32.409133],[-89.053566,32.380365],[-89.098173,32.222752],[-89.06608,32.21468],[-88.954053,32.179277],[-88.95649,32.262073]]]},"properties":{"id":"39332","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.073708,31.603928],[-89.138877,31.656157],[-89.18312,31.65337],[-89.25032,31.68012],[-89.400539,31.686169],[-89.40028,31.632395],[-89.36498,31.54097],[-89.231763,31.433931],[-89.166024,31.443641],[-89.073708,31.603928]]]},"properties":{"id":"39437","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.246053,34.255762],[-89.138026,34.379942],[-89.139869,34.379874],[-89.29211,34.408702],[-89.246053,34.255762]]]},"properties":{"id":"38871","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.29211,34.408702],[-89.139869,34.379874],[-89.140085,34.437834],[-89.246114,34.495862],[-89.246133,34.495633],[-89.255315,34.469783],[-89.29211,34.408702]]]},"properties":{"id":"38627","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.59616,34.260225],[-89.544369,34.314071],[-89.614325,34.314128],[-89.603424,34.257228],[-89.59616,34.260225]]]},"properties":{"id":"38673","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.248756,33.545608],[-90.452754,33.552912],[-90.452571,33.479382],[-90.363662,33.40459],[-90.306339,33.417292],[-90.252299,33.494577],[-90.248756,33.545608]]]},"properties":{"id":"38941","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.564729,33.468302],[-90.555243,33.400879],[-90.452782,33.375542],[-90.363662,33.40459],[-90.452571,33.479382],[-90.564729,33.468302]]]},"properties":{"id":"38761","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.082196,31.282701],[-90.031898,31.181205],[-90.04829,31.32073],[-90.082196,31.282701]]]},"properties":{"id":"39643","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.29694,31.21112],[-90.260129,31.1377],[-90.259838,31.072907],[-90.25977,31.055704],[-90.259555,31.000679],[-89.940973,31.001479],[-89.93354,31.08914],[-90.031898,31.181205],[-90.082196,31.282701],[-90.275478,31.294285],[-90.29694,31.21112]]]},"properties":{"id":"39667","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.706258,33.462607],[-88.703721,33.462579],[-88.704643,33.466364],[-88.706385,33.46589],[-88.706258,33.462607]]]},"properties":{"id":"39760","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.145594,31.224317],[-89.145481,31.166854],[-89.03088,31.17133],[-88.991309,31.22601],[-89.087396,31.22671],[-89.145594,31.224317]]]},"properties":{"id":"39462","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.254224,34.350079],[-90.268194,34.382422],[-90.330144,34.358902],[-90.254224,34.350079]]]},"properties":{"id":"38623","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.117078,32.342256],[-90.025078,32.324251],[-89.88555,32.381416],[-89.827096,32.483707],[-89.854493,32.564987],[-90.06678,32.399036],[-90.117078,32.342256]]]},"properties":{"id":"39047","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.88555,32.381416],[-89.731159,32.195444],[-89.773218,32.494093],[-89.827096,32.483707],[-89.88555,32.381416]]]},"properties":{"id":"39145","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.170504,32.284762],[-90.178878,32.281634],[-90.167234,32.279568],[-90.170504,32.284762]]],[[[-90.03792,32.325926],[-90.167107,32.279547],[-90.13692,32.195605],[-90.027105,32.204546],[-90.03792,32.325926]],[[-90.072422,32.236548],[-90.08203,32.224686],[-90.099486,32.237492],[-90.072422,32.236548]]]]},"properties":{"id":"39208","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.167234,32.279568],[-90.178878,32.281634],[-90.179701,32.276162],[-90.167234,32.279568]]],[[[-90.167234,32.279568],[-90.167107,32.279547],[-90.03792,32.325926],[-90.025078,32.324251],[-90.117078,32.342256],[-90.129056,32.331677],[-90.152957,32.320775],[-90.168055,32.28738],[-90.170504,32.284762],[-90.167234,32.279568]]]]},"properties":{"id":"39232","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.840528,33.270094],[-90.932478,33.226751],[-91.044207,33.268893],[-91.125875,33.040003],[-91.060053,33.077764],[-91.015926,33.058225],[-90.91397,33.029724],[-90.913906,33.047095],[-90.861094,33.095205],[-90.79525,33.074025],[-90.768856,33.059544],[-90.701845,33.096294],[-90.68633,33.18405],[-90.716277,33.270032],[-90.763773,33.270359],[-90.840528,33.270094]],[[-91.030489,33.222519],[-91.047431,33.229947],[-91.045478,33.236094],[-91.030494,33.231071],[-91.030489,33.222519]]]},"properties":{"id":"38748","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.729889,32.075667],[-89.83993,31.78365],[-89.64483,31.87308],[-89.591967,31.970934],[-89.67254,32.04866],[-89.720695,32.08286],[-89.729889,32.075667]]]},"properties":{"id":"39111","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.922191,31.990344],[-89.906933,31.97959],[-89.89436,31.99161],[-89.922191,31.990344]]]},"properties":{"id":"39062","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.564459,32.14279],[-89.67254,32.04866],[-89.591967,31.970934],[-89.48678,31.96115],[-89.422153,31.98437],[-89.323357,32.020392],[-89.426022,32.147597],[-89.526373,32.152544],[-89.564459,32.14279]]]},"properties":{"id":"39153","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.609364,33.565311],[-90.564729,33.468302],[-90.452571,33.479382],[-90.452754,33.552912],[-90.452251,33.609438],[-90.609364,33.565311]]]},"properties":{"id":"38778","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.893858,34.837909],[-88.80017,34.846107],[-89.000202,34.918442],[-88.893858,34.837909]]]},"properties":{"id":"38674","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.173932,32.952697],[-90.303801,32.739583],[-90.201199,32.664855],[-90.12628,32.699882],[-90.097392,32.882537],[-90.140199,32.940757],[-90.173932,32.952697]]]},"properties":{"id":"39039","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.15815,31.346695],[-91.153864,31.610068],[-91.216616,31.781254],[-91.266024,31.768904],[-91.589451,31.19114],[-91.338981,31.342144],[-91.15815,31.346695]]]},"properties":{"id":"39120","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.951241,33.003263],[-89.869719,32.865056],[-89.799732,32.885249],[-89.870384,33.004044],[-89.951241,33.003263]]]},"properties":{"id":"39079","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.234426,34.719082],[-89.245921,34.539765],[-89.095549,34.655691],[-89.158037,34.750401],[-89.234426,34.719082]]]},"properties":{"id":"38633","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.252383,34.772591],[-89.396936,34.634059],[-89.40435,34.527108],[-89.246133,34.495633],[-89.246114,34.495862],[-89.245921,34.539765],[-89.234426,34.719082],[-89.252383,34.772591]]]},"properties":{"id":"38659","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.022208,34.821839],[-89.122759,34.75012],[-88.922261,34.597415],[-88.88505,34.597144],[-88.780925,34.648233],[-88.718508,34.756781],[-88.753015,34.799779],[-88.80017,34.846107],[-88.893858,34.837909],[-89.022208,34.821839]]]},"properties":{"id":"38663","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.660243,34.016825],[-90.862547,33.988777],[-90.842787,33.897609],[-90.77135,33.915972],[-90.754071,33.91615],[-90.656952,33.952275],[-90.657002,33.987586],[-90.660243,34.016825]]]},"properties":{"id":"38774","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.961381,33.978881],[-90.885112,33.864381],[-90.867494,33.864352],[-90.842787,33.897609],[-90.862547,33.988777],[-90.881183,34.095826],[-90.961381,33.978881]]]},"properties":{"id":"38746","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.262682,33.916507],[-89.223589,33.772886],[-89.191116,33.763777],[-89.190561,33.789061],[-89.122199,33.822004],[-89.138228,33.971846],[-89.195313,34.002981],[-89.262682,33.916507]]]},"properties":{"id":"38878","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.456624,34.162049],[-89.384666,34.08884],[-89.302698,34.118885],[-89.300678,34.161425],[-89.34193,34.16169],[-89.456624,34.162049]]]},"properties":{"id":"38913","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.365183,33.690916],[-89.393656,33.709081],[-89.48261,33.656267],[-89.506443,33.597618],[-89.353906,33.411684],[-89.302474,33.3907],[-89.232316,33.448455],[-89.164493,33.655031],[-89.191116,33.763777],[-89.223589,33.772886],[-89.323998,33.721611],[-89.322291,33.668784],[-89.365183,33.690916]],[[-89.280269,33.599859],[-89.280385,33.613992],[-89.270236,33.614024],[-89.269715,33.600184],[-89.280269,33.599859]]]},"properties":{"id":"39744","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.711305,33.840153],[-88.791623,33.840474],[-88.856833,33.811798],[-88.672975,33.720021],[-88.711305,33.840153]]]},"properties":{"id":"39756","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.506616,33.576289],[-89.453923,33.355033],[-89.381737,33.352111],[-89.353906,33.411684],[-89.506443,33.597618],[-89.506616,33.576289]]]},"properties":{"id":"39767","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.767152,32.166535],[-88.810203,32.065545],[-88.741568,32.152867],[-88.767152,32.166535]]]},"properties":{"id":"39363","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.849805,32.044715],[-88.860807,31.992466],[-88.80742,31.96344],[-88.53918,31.93152],[-88.468883,31.930324],[-88.443603,32.131021],[-88.741568,32.152867],[-88.810203,32.065545],[-88.849805,32.044715]]]},"properties":{"id":"39355","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.498265,33.680137],[-88.672975,33.720021],[-88.856833,33.811798],[-88.93123,33.804922],[-88.931525,33.76478],[-88.770596,33.565212],[-88.632499,33.506519],[-88.480299,33.522741],[-88.513917,33.650209],[-88.498265,33.680137]]]},"properties":{"id":"39773","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.627573,34.376174],[-90.639758,34.348753],[-90.607111,34.350197],[-90.585781,34.383599],[-90.627573,34.376174]]]},"properties":{"id":"38631","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.133738,31.754579],[-90.690253,31.698272],[-90.6558,31.67293],[-90.278095,31.632681],[-90.2017,31.706136],[-90.133738,31.754579]]]},"properties":{"id":"39191","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.40028,31.632395],[-89.601503,31.535945],[-89.477961,31.434019],[-89.403872,31.434023],[-89.3846,31.458758],[-89.36498,31.54097],[-89.40028,31.632395]]]},"properties":{"id":"39479","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.137647,34.994714],[-90.304532,34.860588],[-90.202126,34.860374],[-90.078059,34.860275],[-90.078305,34.93327],[-90.0961,34.933311],[-90.137647,34.994714]]]},"properties":{"id":"38641","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.458782,31.246443],[-89.554251,31.242943],[-89.276966,31.031751],[-89.246967,31.068259],[-89.330866,31.213983],[-89.458782,31.246443]]]},"properties":{"id":"39475","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.710197,31.123669],[-88.705837,31.186642],[-88.79269,31.16813],[-88.710197,31.123669]]]},"properties":{"id":"39461","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.934743,33.877206],[-89.831498,33.897674],[-90.020568,33.942808],[-89.934743,33.877206]]]},"properties":{"id":"38953","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.607045,33.714497],[-89.785763,33.599448],[-89.621488,33.518258],[-89.506616,33.576289],[-89.506443,33.597618],[-89.48261,33.656267],[-89.607045,33.714497]]]},"properties":{"id":"38925","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.34503,30.293771],[-89.387533,30.312222],[-89.405294,30.260522],[-89.34503,30.293771]]]},"properties":{"id":"39576","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.45954,30.527004],[-89.4827,30.443934],[-89.477687,30.342434],[-89.408292,30.363066],[-89.401943,30.365004],[-89.361756,30.412103],[-89.341534,30.550867],[-89.45954,30.527004]]]},"properties":{"id":"39556","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.82257,30.624803],[-89.4827,30.443934],[-89.45954,30.527004],[-89.492612,30.586728],[-89.82257,30.624803]]]},"properties":{"id":"39466","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.203209,30.415178],[-89.136636,30.398449],[-89.057439,30.396774],[-89.001434,30.415897],[-88.978833,30.415897],[-89.050273,30.50766],[-89.311732,30.529123],[-89.203209,30.415178]]]},"properties":{"id":"39503","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.186816,32.326762],[-90.190816,32.301978],[-90.168055,32.28738],[-90.152957,32.320775],[-90.186816,32.326762]]]},"properties":{"id":"39202","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.218903,32.220537],[-90.29627,32.208275],[-90.248421,32.153664],[-90.181908,32.201912],[-90.190994,32.20966],[-90.218903,32.220537]]]},"properties":{"id":"39272","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.29627,32.208275],[-90.218903,32.220537],[-90.280737,32.279144],[-90.330202,32.267434],[-90.329667,32.210216],[-90.29627,32.208275]]]},"properties":{"id":"39212","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.183279,32.341172],[-90.186816,32.326762],[-90.152957,32.320775],[-90.129056,32.331677],[-90.154391,32.348352],[-90.183279,32.341172]]]},"properties":{"id":"39216","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.706454,33.308731],[-90.546717,33.282003],[-90.453233,33.329484],[-90.453181,33.349456],[-90.452782,33.375542],[-90.555243,33.400879],[-90.706454,33.308731]]]},"properties":{"id":"38753","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.040125,33.010758],[-91.040211,33.003419],[-91.001814,33.003332],[-91.002116,33.010693],[-91.040125,33.010758]]]},"properties":{"id":"38704","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.496724,34.496599],[-88.503843,34.408217],[-88.407377,34.367342],[-88.356438,34.464086],[-88.410343,34.583729],[-88.496724,34.496599]]]},"properties":{"id":"38856","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.284687,31.828682],[-89.184,31.889802],[-89.321127,31.885149],[-89.31666,31.817262],[-89.284687,31.828682]]]},"properties":{"id":"39481","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.25032,31.68012],[-89.284687,31.828682],[-89.31666,31.817262],[-89.400626,31.700459],[-89.400539,31.686169],[-89.25032,31.68012]]]},"properties":{"id":"39480","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.966305,31.390493],[-89.787409,31.088987],[-89.752642,31.001853],[-89.728119,31.0023],[-89.654038,31.002502],[-89.638317,31.238286],[-89.675169,31.418101],[-89.80962,31.434073],[-89.891293,31.402521],[-89.966305,31.390493]]]},"properties":{"id":"39429","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.581694,32.492496],[-88.403842,32.448773],[-88.385235,32.607143],[-88.541286,32.577931],[-88.620459,32.580541],[-88.62428,32.550108],[-88.619904,32.555566],[-88.607251,32.555312],[-88.605775,32.545522],[-88.615219,32.544556],[-88.581694,32.492496]]]},"properties":{"id":"39335","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.396936,34.634059],[-89.668448,34.554367],[-89.468008,34.545327],[-89.40435,34.527108],[-89.396936,34.634059]]]},"properties":{"id":"38685","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.62428,32.550108],[-88.615219,32.544556],[-88.605775,32.545522],[-88.607251,32.555312],[-88.619904,32.555566],[-88.62428,32.550108]]]},"properties":{"id":"39309","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.928403,34.365014],[-88.882355,34.30996],[-88.855314,34.36507],[-88.824966,34.364759],[-88.794048,34.393706],[-88.763351,34.422666],[-88.83283,34.540662],[-88.977217,34.379678],[-88.928403,34.365014]]]},"properties":{"id":"38828","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.363662,33.40459],[-90.452782,33.375542],[-90.453181,33.349456],[-90.407571,33.323673],[-90.313264,33.391464],[-90.306339,33.417292],[-90.363662,33.40459]]]},"properties":{"id":"38946","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.513917,33.650209],[-88.480299,33.522741],[-88.392486,33.520027],[-88.364961,33.549115],[-88.37728,33.60979],[-88.428832,33.673508],[-88.513917,33.650209]]]},"properties":{"id":"39705","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.37728,33.60979],[-88.364961,33.549115],[-88.270781,33.564924],[-88.259844,33.65305],[-88.37728,33.60979]]]},"properties":{"id":"39766","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.670538,33.426022],[-88.64898,33.41848],[-88.640415,33.456455],[-88.670538,33.426022]]]},"properties":{"id":"39736","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.358084,33.828835],[-88.498265,33.680137],[-88.513917,33.650209],[-88.428832,33.673508],[-88.315295,33.790242],[-88.358084,33.828835]]]},"properties":{"id":"39746","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.621488,33.518258],[-89.645899,33.346294],[-89.545423,33.285838],[-89.453923,33.355033],[-89.506616,33.576289],[-89.621488,33.518258]]]},"properties":{"id":"39747","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.092121,34.380201],[-88.928403,34.365014],[-88.977217,34.379678],[-89.092121,34.380201]]]},"properties":{"id":"38841","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.3307,34.592056],[-88.410343,34.583729],[-88.356438,34.464086],[-88.33875,34.463766],[-88.329193,34.463571],[-88.330813,34.580412],[-88.3307,34.592056]]]},"properties":{"id":"38859","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.368916,34.053599],[-90.33097,34.00589],[-90.312008,34.072781],[-90.434266,34.155175],[-90.450693,34.139902],[-90.450981,34.072932],[-90.368916,34.053599]]]},"properties":{"id":"38964","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.039212,32.087972],[-90.06999,31.99102],[-90.045087,31.917724],[-89.95847,31.883988],[-89.941963,31.902159],[-89.922191,31.990344],[-89.89436,31.99161],[-89.885521,32.09073],[-90.001926,32.155121],[-90.032063,32.085202],[-90.039212,32.087972]]]},"properties":{"id":"39044","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.591967,31.970934],[-89.64483,31.87308],[-89.498132,31.80814],[-89.48678,31.96115],[-89.591967,31.970934]]]},"properties":{"id":"39116","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.494417,33.927858],[-90.577802,33.986732],[-90.657002,33.987586],[-90.656952,33.952275],[-90.610035,33.849437],[-90.598628,33.768785],[-90.451698,33.803616],[-90.333513,33.810172],[-90.379526,33.902233],[-90.42644,33.931207],[-90.450317,33.963727],[-90.492183,33.935833],[-90.494417,33.927858]]]},"properties":{"id":"38737","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.02248,34.110465],[-90.126241,34.072991],[-90.192442,34.022117],[-90.277682,34.009687],[-90.300434,33.97933],[-90.284121,33.896701],[-90.209098,33.904176],[-90.156466,33.89762],[-90.026244,33.945397],[-90.009598,33.994698],[-90.02248,34.110465]]]},"properties":{"id":"38921","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.092121,34.380201],[-88.977217,34.379678],[-88.83283,34.540662],[-88.833346,34.547589],[-88.88505,34.597144],[-88.922261,34.597415],[-89.05324,34.569466],[-89.140085,34.437834],[-89.139869,34.379874],[-89.138026,34.379942],[-89.092121,34.380201]]]},"properties":{"id":"38652","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.871214,33.286377],[-89.108824,33.215886],[-89.178061,33.197614],[-89.200686,33.197562],[-89.302374,32.996899],[-89.240536,32.982795],[-88.968513,32.926554],[-88.813179,32.991809],[-88.813172,33.173511],[-88.813053,33.286424],[-88.871214,33.286377]]]},"properties":{"id":"39339","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.363531,34.995475],[-88.753522,34.995295],[-88.752972,34.842651],[-88.463783,34.810296],[-88.365253,34.895238],[-88.363932,34.971984],[-88.363531,34.995475]]]},"properties":{"id":"38834","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.197877,31.177524],[-91.231531,30.999273],[-90.980297,30.999083],[-90.963332,31.103945],[-91.197877,31.177524]]]},"properties":{"id":"39631","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.095549,34.655691],[-89.245921,34.539765],[-89.246114,34.495862],[-89.140085,34.437834],[-89.05324,34.569466],[-89.095549,34.655691]]]},"properties":{"id":"38650","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.143295,33.747036],[-90.902053,33.703683],[-90.892949,33.718476],[-90.874714,33.732872],[-90.871261,33.761913],[-90.893675,33.791319],[-91.022541,33.805172],[-91.143295,33.747036]]]},"properties":{"id":"38726","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.991047,33.559173],[-91.041648,33.530068],[-91.084346,33.594904],[-91.132926,33.616006],[-91.058013,33.446215],[-91.005833,33.326493],[-90.971892,33.449711],[-91.022324,33.443666],[-91.022344,33.457791],[-90.98268,33.472864],[-90.970367,33.450547],[-90.884181,33.530414],[-90.9632,33.552014],[-90.991047,33.559173]]]},"properties":{"id":"38703","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.892949,33.718476],[-90.658579,33.689118],[-90.658643,33.693604],[-90.874714,33.732872],[-90.892949,33.718476]]]},"properties":{"id":"38730","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.893675,33.791319],[-90.871261,33.761913],[-90.832276,33.791874],[-90.848573,33.807401],[-90.893675,33.791319]]]},"properties":{"id":"38764","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.323998,33.721611],[-89.223589,33.772886],[-89.262682,33.916507],[-89.388391,33.931677],[-89.507269,33.809013],[-89.507269,33.80896],[-89.393656,33.709081],[-89.365183,33.690916],[-89.347333,33.696762],[-89.323998,33.721611]]]},"properties":{"id":"38916","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.073441,33.668664],[-90.136787,33.721648],[-90.22005,33.722116],[-90.248756,33.545608],[-90.252299,33.494577],[-90.174786,33.359229],[-90.094532,33.367119],[-89.995594,33.458555],[-90.073441,33.668664]],[[-90.158776,33.633066],[-90.136745,33.601171],[-90.193625,33.588558],[-90.208088,33.654657],[-90.158776,33.633066]]]},"properties":{"id":"38930","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.907622,33.407181],[-89.88798,33.393306],[-89.897443,33.409359],[-89.907622,33.407181]]],[[[-89.907622,33.407181],[-89.995594,33.458555],[-90.094532,33.367119],[-90.071808,33.347355],[-89.96301,33.286212],[-89.913918,33.347218],[-89.907622,33.407181]]]]},"properties":{"id":"38923","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.620954,31.948159],[-90.81981,32.06499],[-90.887678,31.91941],[-90.716782,31.845833],[-90.620954,31.948159]]]},"properties":{"id":"39086","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.882724,31.552417],[-88.688994,31.477358],[-88.601949,31.520475],[-88.463854,31.688894],[-88.468883,31.930324],[-88.53918,31.93152],[-88.867348,31.696956],[-88.882724,31.552417]]]},"properties":{"id":"39367","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.02519,32.07677],[-88.960821,32.009597],[-88.860807,31.992466],[-88.849805,32.044715],[-88.931394,32.170649],[-89.02519,32.07677]]]},"properties":{"id":"39347","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.492183,33.935833],[-90.519908,33.964465],[-90.494417,33.927858],[-90.492183,33.935833]]],[[[-90.312008,34.072781],[-90.33097,34.00589],[-90.277682,34.009687],[-90.192442,34.022117],[-90.312008,34.072781]]],[[[-90.536076,33.98624],[-90.450317,33.963727],[-90.42644,33.931207],[-90.400743,33.958244],[-90.368916,34.053599],[-90.450981,34.072932],[-90.56485,33.986598],[-90.536076,33.98624]]]]},"properties":{"id":"38963","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.68525,34.294944],[-90.703471,34.281465],[-90.676225,34.261989],[-90.668744,34.280936],[-90.68525,34.294944]]]},"properties":{"id":"38630","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.195658,31.943182],[-90.244845,31.86823],[-90.159168,31.844743],[-90.138079,31.874429],[-90.138341,31.875319],[-90.195658,31.943182]]]},"properties":{"id":"39078","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.486202,31.771587],[-89.400626,31.700459],[-89.31666,31.817262],[-89.321127,31.885149],[-89.422153,31.98437],[-89.48678,31.96115],[-89.498132,31.80814],[-89.486202,31.771587]]]},"properties":{"id":"39168","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.137647,34.994714],[-90.0961,34.933311],[-90.09626,34.977005],[-90.096338,34.994762],[-90.137647,34.994714]]]},"properties":{"id":"38680","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.884668,30.840463],[-89.27488,30.82751],[-89.341167,30.806922],[-89.47417,30.647979],[-89.492612,30.586728],[-89.45954,30.527004],[-89.341534,30.550867],[-89.326802,30.557844],[-89.206654,30.677089],[-89.165924,30.709793],[-89.081993,30.677011],[-88.884417,30.65155],[-88.661054,30.734722],[-88.884668,30.840463]]]},"properties":{"id":"39573","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.710197,31.123669],[-88.75262,30.997464],[-88.4284,31.048588],[-88.43898,31.246896],[-88.64152,31.328578],[-88.841121,31.207177],[-88.841193,31.193403],[-88.79269,31.16813],[-88.705837,31.186642],[-88.710197,31.123669]]]},"properties":{"id":"39451","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.933829,30.39797],[-88.915842,30.419485],[-88.930736,30.412974],[-88.933829,30.39797]]]},"properties":{"id":"39534","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.203209,30.415178],[-89.186885,30.330823],[-89.124338,30.353697],[-89.136636,30.398449],[-89.203209,30.415178]]]},"properties":{"id":"39560","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.889709,30.511101],[-88.842971,30.454062],[-88.665105,30.434156],[-88.619221,30.456118],[-88.602714,30.516],[-88.641144,30.610749],[-88.661054,30.734722],[-88.884417,30.65155],[-88.889709,30.511101]]]},"properties":{"id":"39565","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.407349,32.292532],[-90.403294,32.453332],[-90.394259,32.487714],[-90.450037,32.573783],[-90.553821,32.507574],[-90.603065,32.481918],[-90.485525,32.28592],[-90.407349,32.292532]]]},"properties":{"id":"39041","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.1885,32.300392],[-90.18852,32.301828],[-90.189278,32.301904],[-90.189315,32.300473],[-90.1885,32.300392]]]},"properties":{"id":"39269","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.841265,32.575293],[-90.720102,32.618858],[-90.723159,32.662128],[-90.758863,32.662176],[-90.958477,32.652709],[-90.841265,32.575293]]]},"properties":{"id":"39177","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.82471,34.563611],[-88.538605,34.386325],[-88.503843,34.408217],[-88.496724,34.496599],[-88.784058,34.643394],[-88.82471,34.563611]]]},"properties":{"id":"38824","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.507168,30.390039],[-88.560988,30.451844],[-88.544301,30.389387],[-88.530694,30.384199],[-88.507168,30.390039]]]},"properties":{"id":"39563","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.422153,31.98437],[-89.321127,31.885149],[-89.184,31.889802],[-89.116301,31.890746],[-89.110807,31.965342],[-89.108597,31.966753],[-89.323357,32.020392],[-89.422153,31.98437]]]},"properties":{"id":"39422","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.323357,32.020392],[-89.108597,31.966753],[-89.080482,32.049492],[-89.077408,32.16572],[-89.098173,32.222752],[-89.228396,32.22366],[-89.35024,32.20396],[-89.378166,32.198617],[-89.426022,32.147597],[-89.323357,32.020392]]]},"properties":{"id":"39338","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.954053,32.179277],[-89.06608,32.21468],[-89.077408,32.16572],[-89.080482,32.049492],[-89.02519,32.07677],[-88.931394,32.170649],[-88.954053,32.179277]]]},"properties":{"id":"39356","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.74857,31.543525],[-89.80962,31.434073],[-89.675169,31.418101],[-89.620916,31.548722],[-89.679602,31.568316],[-89.74857,31.543525]]]},"properties":{"id":"39421","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.52834,32.787368],[-88.373328,32.711896],[-88.344718,32.955281],[-88.447176,32.943709],[-88.597502,32.927304],[-88.52834,32.787368]]]},"properties":{"id":"39358","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.737031,32.926502],[-88.898062,32.798566],[-88.915235,32.726407],[-88.839695,32.601911],[-88.725571,32.635852],[-88.620459,32.580541],[-88.541286,32.577931],[-88.52834,32.787368],[-88.597502,32.927304],[-88.737031,32.926502]]]},"properties":{"id":"39328","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.597502,32.927304],[-88.447176,32.943709],[-88.812721,32.992012],[-88.737031,32.926502],[-88.597502,32.927304]]]},"properties":{"id":"39361","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.244497,31.474149],[-90.342773,31.35013],[-90.31226,31.32582],[-90.275478,31.294285],[-90.082196,31.282701],[-90.04829,31.32073],[-90.040485,31.394641],[-90.244497,31.474149]]]},"properties":{"id":"39641","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.544646,34.334386],[-88.763351,34.422666],[-88.794048,34.393706],[-88.568014,34.331338],[-88.544639,34.324271],[-88.544646,34.334386]]]},"properties":{"id":"38866","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.568014,34.331338],[-88.54454,34.264801],[-88.544639,34.324271],[-88.568014,34.331338]]]},"properties":{"id":"38857","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.452754,33.552912],[-90.248756,33.545608],[-90.22005,33.722116],[-90.302671,33.702832],[-90.436499,33.665391],[-90.452251,33.609438],[-90.452754,33.552912]]]},"properties":{"id":"38952","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.484232,33.287911],[-88.669937,33.371341],[-88.72392,33.286539],[-88.484232,33.287911]]]},"properties":{"id":"39743","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.025405,32.484442],[-90.201252,32.596716],[-90.248092,32.487577],[-90.243737,32.459118],[-90.071635,32.426989],[-90.025405,32.484442]]]},"properties":{"id":"39110","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.071635,32.426989],[-90.243737,32.459118],[-90.176765,32.400242],[-90.162149,32.410397],[-90.145156,32.399788],[-90.067549,32.399545],[-90.071635,32.426989]]]},"properties":{"id":"39157","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.229771,33.886954],[-88.203864,34.086564],[-88.288433,34.008286],[-88.367093,33.909798],[-88.358084,33.828835],[-88.315295,33.790242],[-88.231979,33.890472],[-88.229771,33.886954]]]},"properties":{"id":"38848","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.812721,32.992012],[-88.447176,32.943709],[-88.344718,32.955281],[-88.313634,33.213743],[-88.813172,33.173511],[-88.813179,32.991809],[-88.812721,32.992012]]]},"properties":{"id":"39341","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.34454,31.06256],[-90.25977,31.055704],[-90.259838,31.072907],[-90.34454,31.06256]]],[[[-90.44491,31.18851],[-90.260129,31.1377],[-90.29694,31.21112],[-90.526693,31.166611],[-90.47103,31.1894],[-90.45733,31.196535],[-90.44491,31.18851]]]]},"properties":{"id":"39648","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.47103,31.1894],[-90.44491,31.18851],[-90.45733,31.196535],[-90.47103,31.1894]]]},"properties":{"id":"39635","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.426991,34.231002],[-90.434266,34.155175],[-90.312008,34.072781],[-90.192442,34.022117],[-90.126241,34.072991],[-90.119944,34.160058],[-90.127938,34.15989],[-90.162178,34.177553],[-90.168644,34.233624],[-90.426991,34.231002]]]},"properties":{"id":"38643","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.526373,32.152544],[-89.516201,32.311002],[-89.564459,32.14279],[-89.526373,32.152544]]]},"properties":{"id":"39152","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.325478,32.576431],[-89.33861,32.559421],[-89.322591,32.571919],[-89.325478,32.576431]]]},"properties":{"id":"39359","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.657797,33.066298],[-90.701845,33.096294],[-90.768856,33.059544],[-90.84815,33.031633],[-90.888008,33.037947],[-90.736023,32.855142],[-90.734157,32.856729],[-90.656632,32.92251],[-90.657797,33.066298]]]},"properties":{"id":"38721","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.909227,32.823698],[-90.947597,32.772371],[-90.922226,32.77451],[-90.909227,32.823698]]]},"properties":{"id":"39054","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.95847,31.883988],[-90.045087,31.917724],[-90.138341,31.875319],[-90.138079,31.874429],[-89.886773,31.766588],[-89.95847,31.883988]]]},"properties":{"id":"39149","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.368916,34.053599],[-90.400743,33.958244],[-90.300434,33.97933],[-90.277682,34.009687],[-90.33097,34.00589],[-90.368916,34.053599]]]},"properties":{"id":"38957","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.194748,33.809397],[-90.302671,33.702832],[-90.22005,33.722116],[-90.136787,33.721648],[-90.136491,33.809319],[-90.182346,33.814257],[-90.194748,33.809397]]]},"properties":{"id":"38950","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.209098,33.904176],[-90.197699,33.816468],[-90.156466,33.89762],[-90.209098,33.904176]]]},"properties":{"id":"38962","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.171636,34.606545],[-88.253155,34.478106],[-88.152982,34.488027],[-88.135204,34.615878],[-88.171636,34.606545]]]},"properties":{"id":"38827","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.463854,31.688894],[-88.601949,31.520475],[-88.455448,31.546836],[-88.463854,31.688894]]]},"properties":{"id":"39322","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.201199,32.664855],[-90.303801,32.739583],[-90.54408,32.66209],[-90.630121,32.54606],[-90.553821,32.507574],[-90.450037,32.573783],[-90.282828,32.645966],[-90.201199,32.664855]]]},"properties":{"id":"39040","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.37039,31.349953],[-90.59561,31.39441],[-90.61569,31.22198],[-90.602007,31.195016],[-90.526693,31.166611],[-90.29694,31.21112],[-90.275478,31.294285],[-90.31226,31.32582],[-90.37039,31.349953]]]},"properties":{"id":"39666","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.319163,32.931654],[-89.730113,32.800906],[-89.738718,32.694377],[-89.730304,32.634228],[-89.505849,32.630847],[-89.34613,32.679218],[-89.319163,32.931654]]]},"properties":{"id":"39051","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.902053,33.703683],[-91.143295,33.747036],[-91.132926,33.616006],[-91.084346,33.594904],[-90.991047,33.559173],[-90.9632,33.552014],[-90.902053,33.703683]]]},"properties":{"id":"38725","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.084346,33.594904],[-91.041648,33.530068],[-90.991047,33.559173],[-91.084346,33.594904]]]},"properties":{"id":"38772","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.881183,34.095826],[-90.862547,33.988777],[-90.660243,34.016825],[-90.677047,34.060678],[-90.799346,34.120614],[-90.826351,34.120709],[-90.955974,34.120125],[-90.881183,34.095826]]]},"properties":{"id":"38740","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.302698,34.118885],[-89.384666,34.08884],[-89.507594,34.041107],[-89.507382,33.922563],[-89.457285,33.951217],[-89.26064,34.050863],[-89.302698,34.118885]]]},"properties":{"id":"38915","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-89.897935,33.51973],[-89.880481,33.524654],[-89.889282,33.524519],[-89.897935,33.51973]]],[[[-89.907622,33.407181],[-89.913918,33.347218],[-89.823765,33.393578],[-89.88798,33.393306],[-89.907622,33.407181]]],[[[-89.907622,33.407181],[-89.897443,33.409359],[-89.897935,33.51973],[-89.908287,33.521044],[-89.889463,33.552672],[-89.874733,33.59871],[-89.887215,33.676525],[-90.073441,33.668664],[-89.995594,33.458555],[-89.907622,33.407181]]]]},"properties":{"id":"38917","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.174786,33.359229],[-90.321513,33.375121],[-90.330578,33.197637],[-90.208677,33.22052],[-90.071808,33.347355],[-90.094532,33.367119],[-90.174786,33.359229]]]},"properties":{"id":"38924","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.874733,33.59871],[-89.889463,33.552672],[-89.889282,33.524519],[-89.880481,33.524654],[-89.806988,33.59803],[-89.874733,33.59871]]]},"properties":{"id":"38943","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.399032,34.279519],[-90.465894,34.319647],[-90.54495,34.284325],[-90.450693,34.139902],[-90.434266,34.155175],[-90.426991,34.231002],[-90.399032,34.279519]]]},"properties":{"id":"38645","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.477961,31.434019],[-89.601503,31.535945],[-89.620916,31.548722],[-89.675169,31.418101],[-89.638317,31.238286],[-89.554251,31.242943],[-89.458782,31.246443],[-89.477961,31.434019]]]},"properties":{"id":"39482","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.078059,34.860275],[-90.202126,34.860374],[-90.200199,34.724418],[-89.753114,34.772374],[-89.825763,34.840917],[-89.892715,34.877422],[-90.078059,34.860275]]]},"properties":{"id":"38632","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.078305,34.93327],[-90.006387,34.918994],[-90.011221,34.928276],[-90.09626,34.977005],[-90.0961,34.933311],[-90.078305,34.93327]]]},"properties":{"id":"38637","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.246643,31.003545],[-88.92288,31.07855],[-89.03088,31.17133],[-89.145481,31.166854],[-89.246967,31.068259],[-89.276966,31.031751],[-89.246643,31.003545]]]},"properties":{"id":"39425","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.736702,31.724767],[-90.89233,31.76856],[-90.936278,31.702724],[-90.84394,31.64786],[-90.767775,31.611377],[-90.684016,31.600766],[-90.6558,31.67293],[-90.690253,31.698272],[-90.736702,31.724767]]]},"properties":{"id":"39668","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.059856,31.398265],[-89.087396,31.22671],[-88.991309,31.22601],[-88.841121,31.207177],[-88.64152,31.328578],[-88.688994,31.477358],[-88.882724,31.552417],[-88.98186,31.57633],[-89.059856,31.398265]]]},"properties":{"id":"39476","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.842971,30.454062],[-88.889709,30.511101],[-89.050273,30.50766],[-88.978833,30.415897],[-88.939898,30.41788],[-88.916752,30.423156],[-88.917203,30.453691],[-88.88343,30.464665],[-88.88313,30.418798],[-88.861843,30.415621],[-88.842971,30.454062]]]},"properties":{"id":"39532","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.001434,30.415897],[-89.057439,30.396774],[-89.001241,30.388065],[-89.001434,30.415897]]]},"properties":{"id":"39507","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.280737,32.279144],[-90.218903,32.220537],[-90.190994,32.20966],[-90.186774,32.27263],[-90.191179,32.295283],[-90.210103,32.297546],[-90.211197,32.297761],[-90.280737,32.279144]]]},"properties":{"id":"39204","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.190816,32.301978],[-90.191179,32.295283],[-90.186774,32.27263],[-90.179701,32.276162],[-90.178878,32.281634],[-90.170504,32.284762],[-90.168055,32.28738],[-90.190816,32.301978]],[[-90.1885,32.300392],[-90.189315,32.300473],[-90.189278,32.301904],[-90.18852,32.301828],[-90.1885,32.300392]]]},"properties":{"id":"39201","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.153596,32.393098],[-90.162149,32.410397],[-90.176765,32.400242],[-90.153596,32.393098]]]},"properties":{"id":"39174","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.210103,32.297546],[-90.211121,32.300393],[-90.211197,32.297761],[-90.210103,32.297546]]]},"properties":{"id":"39217","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.885771,32.3508],[-90.697419,32.347789],[-90.603065,32.481918],[-90.553821,32.507574],[-90.630121,32.54606],[-90.654589,32.556241],[-90.861252,32.454833],[-90.885771,32.3508]]]},"properties":{"id":"39183","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.203264,34.091511],[-88.309093,34.133484],[-88.401713,34.087487],[-88.407423,34.087586],[-88.288433,34.008286],[-88.203864,34.086564],[-88.203264,34.091511]]]},"properties":{"id":"38870","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.665105,30.434156],[-88.664712,30.362042],[-88.619221,30.456118],[-88.665105,30.434156]]]},"properties":{"id":"39553","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.664712,30.362042],[-88.665105,30.434156],[-88.842971,30.454062],[-88.861843,30.415621],[-88.664712,30.362042]]]},"properties":{"id":"39564","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.960821,32.009597],[-89.02519,32.07677],[-89.080482,32.049492],[-89.108597,31.966753],[-89.110807,31.965342],[-89.054448,31.962218],[-88.960821,32.009597]]]},"properties":{"id":"39348","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.045001,31.772075],[-89.019173,31.791442],[-89.053159,31.806101],[-89.05525,31.78487],[-89.045001,31.772075]]]},"properties":{"id":"39477","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.3846,31.458758],[-89.299027,31.434149],[-89.231763,31.433931],[-89.36498,31.54097],[-89.3846,31.458758]]]},"properties":{"id":"39459","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.52834,32.787368],[-88.541286,32.577931],[-88.385235,32.607143],[-88.373328,32.711896],[-88.52834,32.787368]]]},"properties":{"id":"39352","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.709938,32.542584],[-88.725571,32.635852],[-88.839695,32.601911],[-88.794416,32.572623],[-88.709938,32.542584]]]},"properties":{"id":"39320","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.544059,34.357297],[-89.531996,34.362342],[-89.525613,34.371709],[-89.546535,34.368178],[-89.544059,34.357297]]]},"properties":{"id":"38677","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.168644,34.233624],[-90.162178,34.177553],[-90.141105,34.181734],[-90.127938,34.15989],[-90.119944,34.160058],[-90.02714,34.183508],[-89.773961,34.245782],[-89.720715,34.235683],[-89.721137,34.444606],[-90.041378,34.427683],[-90.056899,34.42278],[-90.106589,34.376116],[-90.193528,34.33374],[-90.168644,34.233624]]]},"properties":{"id":"38606","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.244497,31.474149],[-90.040485,31.394641],[-89.97537,31.394691],[-90.006142,31.436776],[-90.101575,31.706438],[-90.123103,31.751972],[-90.133738,31.754579],[-90.2017,31.706136],[-90.244653,31.548484],[-90.244497,31.474149]]]},"properties":{"id":"39654","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.34613,32.679218],[-89.505849,32.630847],[-89.467868,32.576398],[-89.368197,32.514266],[-89.33861,32.559421],[-89.325478,32.576431],[-89.317883,32.620138],[-89.34613,32.679218]]]},"properties":{"id":"39189","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.728435,34.183459],[-88.725195,34.174697],[-88.719536,34.168761],[-88.71985,34.185011],[-88.728435,34.183459]]]},"properties":{"id":"38879","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.158776,33.633066],[-90.208088,33.654657],[-90.193625,33.588558],[-90.136745,33.601171],[-90.158776,33.633066]]]},"properties":{"id":"38945","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.201252,32.596716],[-90.025405,32.484442],[-89.863104,32.575772],[-89.730304,32.634228],[-89.738718,32.694377],[-89.957619,32.745274],[-90.050975,32.734565],[-90.12628,32.699882],[-90.201199,32.664855],[-90.282828,32.645966],[-90.201252,32.596716]]]},"properties":{"id":"39046","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.957619,32.745274],[-89.738718,32.694377],[-89.730113,32.800906],[-89.730003,32.876936],[-89.799732,32.885249],[-89.869719,32.865056],[-89.957619,32.745274]]]},"properties":{"id":"39045","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.031898,31.181205],[-89.93354,31.08914],[-89.787409,31.088987],[-89.966305,31.390493],[-89.97537,31.394691],[-90.040485,31.394641],[-90.04829,31.32073],[-90.031898,31.181205]]]},"properties":{"id":"39483","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.529393,34.956194],[-89.593748,34.924311],[-89.590549,34.833486],[-89.529393,34.956194]]]},"properties":{"id":"38661","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.337916,32.47109],[-89.378166,32.198617],[-89.35024,32.20396],[-89.293978,32.411083],[-89.337916,32.47109]]]},"properties":{"id":"39092","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.800349,33.449146],[-88.790088,33.447043],[-88.787143,33.4549],[-88.795873,33.458598],[-88.800349,33.449146]]]},"properties":{"id":"39762","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.091769,34.481336],[-90.092047,34.525057],[-90.198593,34.548409],[-90.246409,34.510395],[-90.24764,34.510395],[-90.193528,34.33374],[-90.106589,34.376116],[-90.091769,34.481336]]]},"properties":{"id":"38621","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.231583,34.699525],[-90.198593,34.548409],[-90.092047,34.525057],[-90.056564,34.554176],[-90.118544,34.655828],[-90.231583,34.699525]]]},"properties":{"id":"38665","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.731159,32.195444],[-89.720695,32.08286],[-89.67254,32.04866],[-89.564459,32.14279],[-89.516201,32.311002],[-89.649829,32.50624],[-89.773218,32.494093],[-89.731159,32.195444]]]},"properties":{"id":"39117","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.072422,32.236548],[-90.099486,32.237492],[-90.08203,32.224686],[-90.072422,32.236548]]]},"properties":{"id":"39193","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.888008,33.037947],[-90.84815,33.031633],[-90.821811,33.082147],[-90.861094,33.095205],[-90.913906,33.047095],[-90.888008,33.037947]]]},"properties":{"id":"38765","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.631519,33.599537],[-90.763509,33.529389],[-90.764444,33.444668],[-90.760453,33.448317],[-90.753636,33.448331],[-90.746383,33.451236],[-90.746794,33.445786],[-90.764439,33.444373],[-90.763773,33.270359],[-90.716277,33.270032],[-90.706454,33.308731],[-90.555243,33.400879],[-90.564729,33.468302],[-90.609364,33.565311],[-90.631519,33.599537]]]},"properties":{"id":"38751","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.182346,33.814257],[-90.136491,33.809319],[-89.995939,33.809711],[-89.934743,33.877206],[-90.020568,33.942808],[-90.026244,33.945397],[-90.156466,33.89762],[-90.197699,33.816468],[-90.182346,33.814257]]]},"properties":{"id":"38920","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.284121,33.896701],[-90.379526,33.902233],[-90.333513,33.810172],[-90.194748,33.809397],[-90.182346,33.814257],[-90.197699,33.816468],[-90.209098,33.904176],[-90.284121,33.896701]]]},"properties":{"id":"38928","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.020568,33.942808],[-89.831498,33.897674],[-89.798069,33.897272],[-89.776964,33.995089],[-90.009598,33.994698],[-90.026244,33.945397],[-90.020568,33.942808]]]},"properties":{"id":"38961","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.861252,32.454833],[-90.654589,32.556241],[-90.720102,32.618858],[-90.841265,32.575293],[-90.861252,32.454833]]]},"properties":{"id":"39156","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.005833,33.326493],[-91.058013,33.446215],[-91.044207,33.268893],[-90.932478,33.226751],[-90.932439,33.298537],[-91.005833,33.326493]]]},"properties":{"id":"38701","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.971892,33.449711],[-91.005833,33.326493],[-90.932439,33.298537],[-90.840528,33.270094],[-90.763773,33.270359],[-90.764439,33.444373],[-90.764444,33.444668],[-90.763509,33.529389],[-90.884181,33.530414],[-90.970367,33.450547],[-90.971892,33.449711]]]},"properties":{"id":"38756","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.970367,33.450547],[-90.98268,33.472864],[-91.022344,33.457791],[-91.022324,33.443666],[-90.971892,33.449711],[-90.970367,33.450547]]]},"properties":{"id":"38760","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.030489,33.222519],[-91.030494,33.231071],[-91.045478,33.236094],[-91.047431,33.229947],[-91.030489,33.222519]]]},"properties":{"id":"38723","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.365183,33.690916],[-89.322291,33.668784],[-89.323998,33.721611],[-89.347333,33.696762],[-89.365183,33.690916]]]},"properties":{"id":"39737","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.5825,33.182206],[-89.604297,33.238942],[-89.875732,33.259576],[-89.936618,33.235685],[-89.821743,33.073812],[-89.688836,33.091374],[-89.5825,33.182206]]]},"properties":{"id":"39192","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.96301,33.286212],[-89.875732,33.259576],[-89.604297,33.238942],[-89.560293,33.273376],[-89.545423,33.285838],[-89.645899,33.346294],[-89.823765,33.393578],[-89.913918,33.347218],[-89.96301,33.286212]]]},"properties":{"id":"39176","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.263073,34.852846],[-89.466306,34.993879],[-89.528748,34.99473],[-89.529393,34.956194],[-89.590549,34.833486],[-89.688969,34.685657],[-89.713407,34.640567],[-89.668755,34.574212],[-89.668448,34.554367],[-89.396936,34.634059],[-89.252383,34.772591],[-89.263073,34.852846]]]},"properties":{"id":"38635","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.961381,33.978881],[-91.022541,33.805172],[-90.893675,33.791319],[-90.848573,33.807401],[-90.885112,33.864381],[-90.961381,33.978881]]]},"properties":{"id":"38769","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.832276,33.791874],[-90.611366,33.770072],[-90.598628,33.768785],[-90.610035,33.849437],[-90.867494,33.864352],[-90.885112,33.864381],[-90.848573,33.807401],[-90.832276,33.791874]]]},"properties":{"id":"38759","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.26064,34.050863],[-89.187049,34.030317],[-89.12264,34.117741],[-89.24593,34.234127],[-89.300678,34.161425],[-89.302698,34.118885],[-89.26064,34.050863]]]},"properties":{"id":"38864","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.959717,33.717256],[-88.871503,33.564644],[-88.770596,33.565212],[-88.931525,33.76478],[-88.959717,33.717256]]]},"properties":{"id":"39741","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.465894,34.319647],[-90.399032,34.279519],[-90.39906,34.308579],[-90.448724,34.334932],[-90.463683,34.327744],[-90.465894,34.319647]]]},"properties":{"id":"38639","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.450627,32.084704],[-90.247204,32.032667],[-90.248421,32.153664],[-90.29627,32.208275],[-90.329667,32.210216],[-90.450312,32.150499],[-90.450627,32.084704]]]},"properties":{"id":"39170","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.09626,34.977005],[-90.011221,34.928276],[-89.992154,34.918982],[-89.945627,34.927797],[-89.936778,34.927644],[-89.955897,34.994955],[-90.096338,34.994762],[-90.09626,34.977005]]]},"properties":{"id":"38671","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.299027,31.434149],[-89.145594,31.224317],[-89.087396,31.22671],[-89.059856,31.398265],[-89.166024,31.443641],[-89.231763,31.433931],[-89.299027,31.434149]]]},"properties":{"id":"39465","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.841193,31.193403],[-88.9,31.07036],[-88.874153,30.909918],[-88.75262,30.997464],[-88.710197,31.123669],[-88.79269,31.16813],[-88.841193,31.193403]]]},"properties":{"id":"39456","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.001241,30.388065],[-89.001334,30.387022],[-88.934231,30.392495],[-88.933829,30.39797],[-88.930736,30.412974],[-88.939898,30.41788],[-88.978833,30.415897],[-89.001434,30.415897],[-89.001241,30.388065]]]},"properties":{"id":"39531","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.450037,32.573783],[-90.394259,32.487714],[-90.287514,32.472714],[-90.248092,32.487577],[-90.201252,32.596716],[-90.282828,32.645966],[-90.450037,32.573783]]]},"properties":{"id":"39071","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.914337,33.007697],[-91.034361,32.967387],[-91.033971,32.929271],[-91.007427,32.957937],[-90.914337,33.007697]]]},"properties":{"id":"38745","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.70235,34.037304],[-88.617774,33.995559],[-88.55427,34.022601],[-88.407423,34.087586],[-88.401713,34.087487],[-88.544271,34.162162],[-88.650132,34.09907],[-88.70235,34.037304]]]},"properties":{"id":"38858","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.560988,30.451844],[-88.507168,30.390039],[-88.44667,30.384301],[-88.409429,30.668593],[-88.641144,30.610749],[-88.602714,30.516],[-88.560988,30.451844]]]},"properties":{"id":"39562","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-88.770144,30.244995],[-88.740647,30.238665],[-88.713836,30.242845],[-88.662637,30.228274],[-88.678866,30.242689],[-88.716136,30.252897],[-88.733658,30.246253],[-88.770144,30.244995]]],[[[-88.530694,30.384199],[-88.544301,30.389387],[-88.522494,30.340092],[-88.530694,30.384199]]]]},"properties":{"id":"39567","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.116301,31.890746],[-89.184,31.889802],[-89.284687,31.828682],[-89.25032,31.68012],[-89.18312,31.65337],[-89.15337,31.75232],[-89.11377,31.68501],[-89.138877,31.656157],[-89.073708,31.603928],[-88.98186,31.57633],[-88.882724,31.552417],[-88.867348,31.696956],[-88.935414,31.7409],[-89.045001,31.772075],[-89.05525,31.78487],[-89.116301,31.890746]]]},"properties":{"id":"39443","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.966305,31.390493],[-89.891293,31.402521],[-89.891009,31.522138],[-89.959103,31.504896],[-90.006142,31.436776],[-89.97537,31.394691],[-89.966305,31.390493]]]},"properties":{"id":"39656","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.325478,32.576431],[-89.322591,32.571919],[-89.252279,32.534088],[-89.064767,32.494535],[-88.948587,32.59039],[-88.930143,32.669337],[-89.317883,32.620138],[-89.325478,32.576431]]]},"properties":{"id":"39365","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.721137,34.444606],[-89.720715,34.235683],[-89.603424,34.257228],[-89.614325,34.314128],[-89.544369,34.314071],[-89.59616,34.260225],[-89.451111,34.234448],[-89.34193,34.16169],[-89.300678,34.161425],[-89.24593,34.234127],[-89.246053,34.255762],[-89.29211,34.408702],[-89.255315,34.469783],[-89.610165,34.504554],[-89.721013,34.448581],[-89.721137,34.444606]],[[-89.544059,34.357297],[-89.546535,34.368178],[-89.525613,34.371709],[-89.531996,34.362342],[-89.544059,34.357297]]]},"properties":{"id":"38655","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.278095,31.632681],[-90.244653,31.548484],[-90.2017,31.706136],[-90.278095,31.632681]]]},"properties":{"id":"39665","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.342773,31.35013],[-90.37039,31.349953],[-90.31226,31.32582],[-90.342773,31.35013]]],[[[-90.342773,31.35013],[-90.244497,31.474149],[-90.336173,31.466515],[-90.342773,31.35013]]]]},"properties":{"id":"39662","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.878082,34.249658],[-88.755502,34.297577],[-88.82476,34.343259],[-88.855314,34.36507],[-88.882355,34.30996],[-88.878082,34.249658]]]},"properties":{"id":"38826","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.302671,33.702832],[-90.194748,33.809397],[-90.333513,33.810172],[-90.451698,33.803616],[-90.451574,33.699559],[-90.436499,33.665391],[-90.302671,33.702832]]]},"properties":{"id":"38944","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.24154,33.802962],[-88.231979,33.890472],[-88.315295,33.790242],[-88.428832,33.673508],[-88.37728,33.60979],[-88.259844,33.65305],[-88.24154,33.802962]]]},"properties":{"id":"39740","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.669937,33.371341],[-88.484232,33.287911],[-88.304434,33.28832],[-88.302759,33.302211],[-88.392486,33.520027],[-88.480299,33.522741],[-88.632499,33.506519],[-88.640415,33.456455],[-88.64898,33.41848],[-88.670538,33.426022],[-88.669937,33.371341]]]},"properties":{"id":"39701","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.948587,32.59039],[-89.064767,32.494535],[-88.986796,32.416321],[-88.915309,32.459946],[-88.948587,32.59039]]]},"properties":{"id":"39337","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.861094,33.095205],[-90.821811,33.082147],[-90.79525,33.074025],[-90.861094,33.095205]]]},"properties":{"id":"39061","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.780925,34.648233],[-88.88505,34.597144],[-88.833346,34.547589],[-88.82471,34.563611],[-88.784058,34.643394],[-88.780925,34.648233]]]},"properties":{"id":"38625","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-88.363531,34.995475],[-88.363932,34.971984],[-88.307194,34.728603],[-88.120607,34.709081],[-88.214895,34.995585],[-88.234351,34.988726],[-88.234794,34.995492],[-88.363531,34.995475]]]},"properties":{"id":"38852","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.697419,32.347789],[-90.885771,32.3508],[-91.030207,32.114353],[-90.795763,32.143247],[-90.727881,32.226019],[-90.697419,32.347789]]]},"properties":{"id":"39180","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.280269,33.599859],[-89.269715,33.600184],[-89.270236,33.614024],[-89.280385,33.613992],[-89.280269,33.599859]]]},"properties":{"id":"39771","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.192417,31.218084],[-91.338981,31.342144],[-91.589451,31.19114],[-91.231531,30.999273],[-91.197877,31.177524],[-91.192417,31.218084]]]},"properties":{"id":"39669","state_code":"28"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.76703,40.372622],[-92.816078,40.069109],[-92.777681,40.063567],[-92.686283,40.314226],[-92.684933,40.343461],[-92.76703,40.372622]]]},"properties":{"id":"63559","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.350336,40.095684],[-94.501756,40.143802],[-94.624407,40.126303],[-94.65773,40.100954],[-94.657056,40.061035],[-94.510307,39.958796],[-94.294173,40.037938],[-94.322026,40.109787],[-94.350336,40.095684]]]},"properties":{"id":"64463","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.6591,40.51999],[-95.651719,40.399171],[-95.642028,40.569738],[-95.6591,40.51999]]]},"properties":{"id":"64496","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.612724,38.31721],[-94.498449,38.354198],[-94.50932,38.426666],[-94.61334,38.406892],[-94.612724,38.31721]]]},"properties":{"id":"64723","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.556088,38.16602],[-93.868816,38.112517],[-93.89242,38.058856],[-93.854144,38.022666],[-93.822142,37.994301],[-93.80739,37.985659],[-93.826266,37.975982],[-93.808664,37.887961],[-93.75157,37.879949],[-93.580543,37.951722],[-93.508355,37.993242],[-93.471601,38.10123],[-93.523484,38.207216],[-93.556088,38.16602]]]},"properties":{"id":"64776","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.444174,37.99857],[-93.414325,37.874004],[-93.360488,37.861946],[-93.313991,37.895021],[-93.311647,37.980809],[-93.324684,38.066928],[-93.436582,38.070563],[-93.444174,37.99857]]]},"properties":{"id":"65779","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.935796,37.458903],[-89.901988,37.428711],[-89.861749,37.50613],[-89.861391,37.531548],[-89.861326,37.557082],[-90.028666,37.598114],[-89.935796,37.458903]]]},"properties":{"id":"63781","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.140115,37.517588],[-90.346243,37.314577],[-90.275609,37.283526],[-90.218782,37.25701],[-90.100241,37.381837],[-90.069264,37.465286],[-90.140115,37.517588]]]},"properties":{"id":"63655","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.220164,37.190999],[-90.220509,37.167849],[-90.221553,37.12892],[-90.207219,37.173654],[-90.220164,37.190999]]]},"properties":{"id":"63750","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.39051,39.13048],[-92.474223,39.069138],[-92.461241,38.972519],[-92.33363,38.96901],[-92.095297,38.953349],[-92.079132,38.95302],[-92.07308,38.982619],[-92.120052,39.043167],[-92.16784,39.04409],[-92.332589,39.125116],[-92.39051,39.13048]]]},"properties":{"id":"65202","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.491481,39.185253],[-92.508666,39.098318],[-92.474223,39.069138],[-92.39051,39.13048],[-92.413133,39.161633],[-92.491481,39.185253]]]},"properties":{"id":"65256","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.993745,39.676565],[-94.979239,39.436839],[-94.95653,39.527335],[-94.974232,39.618339],[-94.993745,39.676565]]]},"properties":{"id":"64484","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.854632,39.733807],[-94.824647,39.711911],[-94.84054,39.759385],[-94.854632,39.733807]]]},"properties":{"id":"64503","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.28106,36.768619],[-90.302532,36.676522],[-90.140719,36.640935],[-90.144642,36.673111],[-90.28106,36.768619]]]},"properties":{"id":"63932","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.465687,36.660176],[-90.574959,36.576822],[-90.659327,36.49854],[-90.431733,36.498401],[-90.345195,36.587118],[-90.465687,36.660176]]]},"properties":{"id":"63954","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.802157,39.84942],[-93.870526,39.786798],[-93.869957,39.676751],[-93.86089,39.665311],[-93.758333,39.654793],[-93.740471,39.698443],[-93.759145,39.808879],[-93.797371,39.844806],[-93.802157,39.84942]]]},"properties":{"id":"64625","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.159316,38.787366],[-92.214876,38.708927],[-92.181766,38.683218],[-92.031421,38.660017],[-91.94938,38.706989],[-92.159316,38.787366]]]},"properties":{"id":"65063","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.747018,38.808694],[-91.760271,38.707311],[-91.736928,38.702345],[-91.710843,38.707583],[-91.64233,38.841775],[-91.641583,38.86073],[-91.747018,38.808694]]]},"properties":{"id":"65067","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.860835,38.146131],[-92.764441,38.194313],[-92.736675,38.220733],[-92.775559,38.270195],[-92.803792,38.314556],[-92.94426,38.29145],[-92.937823,38.225223],[-92.88056,38.163657],[-92.860835,38.146131]]]},"properties":{"id":"65037","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.506138,37.755269],[-92.550705,37.92139],[-92.636989,37.90714],[-92.6353,37.899074],[-92.506138,37.755269]]]},"properties":{"id":"65567","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.816302,37.049685],[-89.687027,37.00524],[-89.556229,37.039284],[-89.59447,37.042675],[-89.62237,37.03887],[-89.612693,37.057721],[-89.630864,37.115803],[-89.8164,37.152209],[-89.816302,37.049685]],[[-89.774786,37.095687],[-89.748625,37.11129],[-89.744157,37.082039],[-89.774786,37.095687]]]},"properties":{"id":"63771","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.55861,37.177013],[-89.558918,37.145515],[-89.443259,37.15484],[-89.450202,37.154548],[-89.450105,37.163475],[-89.443384,37.163489],[-89.454125,37.185069],[-89.48872,37.251457],[-89.567264,37.244995],[-89.620256,37.233008],[-89.563754,37.188276],[-89.558155,37.186005],[-89.55861,37.177013]]]},"properties":{"id":"63780","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.734951,37.601558],[-89.685032,37.591281],[-89.714322,37.610852],[-89.734951,37.601558]]]},"properties":{"id":"63770","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.889522,37.089873],[-91.10269,37.086665],[-91.160321,37.085176],[-91.079696,36.823255],[-90.946726,36.817554],[-90.935774,36.860844],[-90.865251,37.051671],[-90.889522,37.089873]]]},"properties":{"id":"63965","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.436622,38.572362],[-94.281291,38.529101],[-94.205879,38.658201],[-94.226722,38.663982],[-94.240303,38.670111],[-94.37484,38.72899],[-94.449307,38.639926],[-94.436622,38.572362]]]},"properties":{"id":"64701","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.564921,38.845221],[-94.608033,38.847207],[-94.609504,38.721705],[-94.562967,38.721525],[-94.48111,38.753908],[-94.490031,38.841713],[-94.49213,38.841707],[-94.529564,38.843652],[-94.564921,38.845221]]]},"properties":{"id":"64012","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.054774,37.675493],[-93.965513,37.692601],[-93.808664,37.887961],[-93.826266,37.975982],[-93.822142,37.994301],[-93.854144,38.022666],[-93.949466,38.003844],[-94.063991,37.928513],[-94.098358,37.868789],[-94.17386,37.845822],[-94.181739,37.802361],[-94.097551,37.713335],[-94.054774,37.675493]]]},"properties":{"id":"64744","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.121874,36.916443],[-93.071529,36.812763],[-92.941202,36.853753],[-92.908284,36.852922],[-93.057651,36.942543],[-93.121874,36.916443]]]},"properties":{"id":"65629","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.982024,36.810932],[-92.984441,36.685414],[-92.949632,36.65191],[-92.851949,36.660051],[-92.908829,36.824663],[-92.908284,36.852922],[-92.941202,36.853753],[-92.982024,36.810932]]]},"properties":{"id":"65614","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.45724,39.244715],[-94.473337,39.311202],[-94.491761,39.310042],[-94.507276,39.243436],[-94.45724,39.244715]]]},"properties":{"id":"64157","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.236225,39.398093],[-94.323481,39.324659],[-94.286718,39.246793],[-94.278222,39.245632],[-94.117157,39.31422],[-94.145236,39.366917],[-94.236225,39.398093]]]},"properties":{"id":"64024","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.286718,39.246793],[-94.304609,39.231411],[-94.278325,39.236159],[-94.278222,39.245632],[-94.286718,39.246793]]]},"properties":{"id":"64072","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.27634,39.45528],[-94.236225,39.398093],[-94.145236,39.366917],[-94.075061,39.42767],[-94.153327,39.526229],[-94.256977,39.483933],[-94.27634,39.45528]]]},"properties":{"id":"64062","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.458691,38.539989],[-92.615108,38.456155],[-92.625189,38.430778],[-92.546029,38.428926],[-92.406243,38.425406],[-92.349699,38.446512],[-92.343659,38.495242],[-92.458691,38.539989]]]},"properties":{"id":"65074","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.056324,38.780091],[-92.939923,38.758646],[-92.84222,38.842774],[-92.914954,38.924787],[-92.993749,38.940287],[-93.053011,38.859377],[-93.056324,38.780091]]]},"properties":{"id":"65276","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.66957,38.82258],[-92.84222,38.842774],[-92.939923,38.758646],[-92.85643,38.699112],[-92.83947,38.691848],[-92.734596,38.733626],[-92.670194,38.735986],[-92.636949,38.758552],[-92.66957,38.82258]]]},"properties":{"id":"65237","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.841364,38.616061],[-92.83947,38.691848],[-92.85643,38.699112],[-92.9345,38.66],[-92.91691,38.579172],[-92.841364,38.616061]]]},"properties":{"id":"65354","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.62404,38.466378],[-91.565907,38.152743],[-91.534384,38.152593],[-91.36648,38.263811],[-91.366464,38.264492],[-91.367665,38.461479],[-91.368415,38.535363],[-91.37558,38.542236],[-91.618903,38.467647],[-91.62404,38.466378]]]},"properties":{"id":"65066","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.713527,37.173016],[-93.644953,37.141059],[-93.607976,37.14204],[-93.629545,37.156398],[-93.626505,37.189824],[-93.60669,37.20413],[-93.47952,37.27863],[-93.510893,37.306302],[-93.622785,37.38373],[-93.689087,37.192119],[-93.713527,37.173016]]]},"properties":{"id":"65604","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.936969,37.554061],[-92.983284,37.520097],[-92.963198,37.431152],[-92.874297,37.440644],[-92.657762,37.480817],[-92.647436,37.524841],[-92.704442,37.568783],[-92.936969,37.554061]]]},"properties":{"id":"65632","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.022583,39.829274],[-93.870526,39.786798],[-93.802157,39.84942],[-93.896369,40.012121],[-94.072258,39.988316],[-94.103636,39.959885],[-94.043283,39.848993],[-94.022583,39.829274]]]},"properties":{"id":"64640","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.26545,37.591344],[-91.376654,37.272743],[-91.232292,37.24789],[-91.080174,37.405789],[-91.079295,37.405429],[-91.110065,37.494432],[-91.26545,37.591344]]]},"properties":{"id":"63629","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.908829,36.824663],[-92.851949,36.660051],[-92.849268,36.66108],[-92.767093,36.712366],[-92.71448,36.76214],[-92.529504,36.802134],[-92.382848,36.799584],[-92.386294,36.903536],[-92.468183,36.999272],[-92.700964,37.042084],[-92.904208,37.008816],[-92.904623,36.985266],[-92.908829,36.824663]]]},"properties":{"id":"65608","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.248599,36.128814],[-90.248516,36.130786],[-90.297871,36.107662],[-90.297732,35.99633],[-90.186073,35.997318],[-90.169189,36.061112],[-90.248599,36.128814]]]},"properties":{"id":"63821","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.368415,38.535363],[-91.367665,38.461479],[-91.33266,38.318187],[-91.294602,38.306562],[-91.255485,38.478372],[-91.368415,38.535363]]]},"properties":{"id":"63037","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.895968,38.540589],[-90.964461,38.547545],[-91.123011,38.597883],[-91.138401,38.494906],[-91.134052,38.475589],[-91.12461,38.447565],[-90.973353,38.47455],[-90.910574,38.497866],[-90.895968,38.540589]]]},"properties":{"id":"63090","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.372843,38.583723],[-91.224986,38.620783],[-91.304157,38.699977],[-91.372843,38.583723]]]},"properties":{"id":"63014","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.744027,38.394278],[-91.644877,38.179545],[-91.565907,38.152743],[-91.62404,38.466378],[-91.629478,38.466846],[-91.744027,38.394278]]]},"properties":{"id":"65014","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.922111,38.440358],[-91.820046,38.388823],[-91.744027,38.394278],[-91.629478,38.466846],[-91.682294,38.508186],[-91.81919,38.562347],[-91.916076,38.543316],[-91.922111,38.440358]]]},"properties":{"id":"65051","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.542115,40.327361],[-94.667808,40.285786],[-94.666675,40.271244],[-94.665496,40.254936],[-94.668779,40.242127],[-94.66894,40.234885],[-94.687648,40.234941],[-94.687935,40.222404],[-94.624407,40.126303],[-94.501756,40.143802],[-94.444528,40.216075],[-94.424416,40.295343],[-94.542115,40.327361]]]},"properties":{"id":"64489","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.44731,37.2753],[-93.183597,37.240227],[-93.185369,37.318893],[-93.27081,37.35351],[-93.318039,37.368022],[-93.32046,37.362247],[-93.44731,37.2753]]]},"properties":{"id":"65803","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.311983,37.19707],[-93.27608,37.20388],[-93.28136,37.20896],[-93.311983,37.19707]]]},"properties":{"id":"65806","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.369531,37.517991],[-93.396267,37.43952],[-93.32046,37.362247],[-93.318039,37.368022],[-93.325775,37.490652],[-93.369531,37.517991]]]},"properties":{"id":"65617","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.554817,40.26446],[-95.282097,39.974832],[-95.266707,40.074156],[-95.313712,40.262732],[-95.554817,40.26446]]]},"properties":{"id":"64437","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.693771,37.499255],[-90.479292,37.448666],[-90.546769,37.524407],[-90.693771,37.499255]]]},"properties":{"id":"63621","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.667931,37.274455],[-90.588557,37.244529],[-90.452965,37.316226],[-90.45262,37.316227],[-90.660541,37.316199],[-90.667931,37.274455]]]},"properties":{"id":"63636","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.881841,37.532027],[-90.770618,37.513865],[-90.770235,37.549446],[-90.692016,37.651324],[-90.88085,37.602813],[-90.881841,37.532027]]]},"properties":{"id":"63656","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-94.468127,39.058191],[-94.474714,39.06534],[-94.472529,39.0591],[-94.468127,39.058191]]],[[[-94.429866,39.079706],[-94.447553,39.049464],[-94.415739,39.034056],[-94.383044,39.019503],[-94.360626,39.026159],[-94.355865,39.030899],[-94.379097,39.077839],[-94.429866,39.079706]]]]},"properties":{"id":"64055","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.55363,39.077135],[-94.554704,39.055477],[-94.516741,39.05384],[-94.515662,39.075917],[-94.55363,39.077135]]]},"properties":{"id":"64128","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.450085,39.097535],[-94.438005,39.094594],[-94.439262,39.13177],[-94.444292,39.129007],[-94.450085,39.097535]]]},"properties":{"id":"64054","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.55363,39.077135],[-94.515662,39.075917],[-94.515285,39.083118],[-94.514419,39.101285],[-94.518438,39.101438],[-94.55825,39.102702],[-94.560834,39.09511],[-94.561708,39.077401],[-94.55363,39.077135]]]},"properties":{"id":"64127","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.502405,38.992424],[-94.521118,38.967457],[-94.519654,38.9674],[-94.514885,38.967208],[-94.5251,38.953124],[-94.521882,38.952946],[-94.438015,38.942095],[-94.416372,38.941056],[-94.417808,38.984751],[-94.502405,38.992424]]]},"properties":{"id":"64138","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.584481,39.000083],[-94.607868,39.00109],[-94.607986,38.912924],[-94.589377,38.914363],[-94.584481,39.000083]]]},"properties":{"id":"64114","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.607868,39.00109],[-94.584481,39.000083],[-94.583843,39.012828],[-94.583071,39.027508],[-94.607565,39.028344],[-94.607868,39.00109]]]},"properties":{"id":"64113","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.596457,39.097125],[-94.607205,39.100132],[-94.607234,39.083301],[-94.596521,39.09698],[-94.596457,39.097125]]]},"properties":{"id":"64102","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.360626,39.026159],[-94.274492,38.965714],[-94.270813,39.052824],[-94.355865,39.030899],[-94.360626,39.026159]]]},"properties":{"id":"64015","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.181921,39.079077],[-94.220541,38.916072],[-94.203209,38.920209],[-94.077567,38.916583],[-94.109325,39.033189],[-94.106286,39.109169],[-94.154018,39.093232],[-94.181921,39.079077]]]},"properties":{"id":"64075","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.267661,39.158733],[-94.187174,39.183843],[-94.278325,39.236159],[-94.304609,39.231411],[-94.408628,39.193603],[-94.401575,39.166823],[-94.385463,39.128992],[-94.262769,39.138312],[-94.267661,39.158733]]]},"properties":{"id":"64058","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.588932,39.112903],[-94.583396,39.096038],[-94.560834,39.09511],[-94.55825,39.102702],[-94.560673,39.113072],[-94.579019,39.117367],[-94.588932,39.112903]]]},"properties":{"id":"64106","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.469653,38.900995],[-94.48902,38.899884],[-94.49213,38.841707],[-94.490031,38.841713],[-94.430756,38.839608],[-94.469653,38.900995]]]},"properties":{"id":"64149","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.104864,39.142565],[-94.150453,39.143823],[-94.133123,39.132457],[-94.105225,39.131501],[-94.104864,39.142565]]]},"properties":{"id":"64066","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.372666,38.293594],[-90.471518,38.297942],[-90.462277,38.262111],[-90.425852,38.255283],[-90.371754,38.280536],[-90.372666,38.293594]]]},"properties":{"id":"63070","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.50125,38.37206],[-90.370819,38.333554],[-90.347526,38.379704],[-90.460967,38.449434],[-90.489906,38.442878],[-90.508164,38.420283],[-90.50125,38.37206]],[[-90.3658,38.36636],[-90.36549,38.36797],[-90.36239,38.36756],[-90.36372,38.3661],[-90.3658,38.36636]]]},"properties":{"id":"63052","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.574426,38.457899],[-90.508164,38.420283],[-90.489906,38.442878],[-90.508077,38.535962],[-90.529711,38.530421],[-90.574426,38.457899]]]},"properties":{"id":"63049","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.109325,39.033189],[-94.077567,38.916583],[-94.077386,38.892607],[-94.039889,38.875705],[-94.03594,38.915144],[-94.053906,39.031931],[-94.109325,39.033189]]]},"properties":{"id":"64011","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.329656,40.259174],[-92.23921,40.22052],[-92.126084,40.296144],[-92.196998,40.373293],[-92.349996,40.353607],[-92.347801,40.302756],[-92.329656,40.259174]]]},"properties":{"id":"63531","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.488061,40.024453],[-91.560309,39.946634],[-91.612832,39.911791],[-91.439196,39.856567],[-91.488061,40.024453]]]},"properties":{"id":"63471","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.361422,39.880137],[-93.266968,39.703562],[-93.235461,39.827998],[-93.361422,39.880137]]]},"properties":{"id":"64659","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.629877,39.762899],[-93.620758,39.740748],[-93.608551,39.75937],[-93.613171,39.764078],[-93.629877,39.762899]]]},"properties":{"id":"64686","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.312092,36.705325],[-94.269052,36.633114],[-94.224776,36.633272],[-94.157068,36.750971],[-94.14559,36.808611],[-94.210986,36.810797],[-94.243446,36.792665],[-94.321894,36.724649],[-94.312092,36.705325]]]},"properties":{"id":"64867","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.425717,36.975764],[-94.534807,36.940394],[-94.528556,36.905977],[-94.533356,36.895186],[-94.505747,36.762922],[-94.321894,36.724649],[-94.243446,36.792665],[-94.306699,36.951256],[-94.425717,36.975764]]]},"properties":{"id":"64850","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.546769,37.524407],[-90.479292,37.448666],[-90.418415,37.315719],[-90.346243,37.314577],[-90.140115,37.517588],[-90.146763,37.597434],[-90.092067,37.69582],[-90.111062,37.721921],[-90.175609,37.721072],[-90.534701,37.643624],[-90.535016,37.643641],[-90.546769,37.524407]]]},"properties":{"id":"63645","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.054014,38.159119],[-91.888558,38.072971],[-91.88869,38.080154],[-91.794364,38.173135],[-91.862664,38.292889],[-91.959875,38.289745],[-92.008145,38.253163],[-92.045239,38.177386],[-92.060728,38.159168],[-92.054014,38.159119]]]},"properties":{"id":"65582","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.644877,38.179545],[-91.744027,38.394278],[-91.820046,38.388823],[-91.862664,38.292889],[-91.794364,38.173135],[-91.718841,38.16925],[-91.644877,38.179545]]]},"properties":{"id":"65013","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.075056,38.289771],[-92.008145,38.253163],[-91.959875,38.289745],[-92.028067,38.33139],[-92.042872,38.324679],[-92.075056,38.289771]]]},"properties":{"id":"65001","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.811352,39.824574],[-91.813131,39.759792],[-91.850678,39.58173],[-91.713444,39.511886],[-91.647009,39.508028],[-91.58557,39.634031],[-91.60359,39.685608],[-91.700119,39.773304],[-91.811352,39.824574]]]},"properties":{"id":"63456","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.447139,38.374189],[-92.546029,38.428926],[-92.625189,38.430778],[-92.691383,38.308346],[-92.688133,38.223986],[-92.58969,38.210746],[-92.537293,38.213153],[-92.440595,38.282923],[-92.447139,38.374189]]]},"properties":{"id":"65026","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.159853,37.863418],[-92.18467,38.030639],[-92.406275,38.021179],[-92.287896,37.887081],[-92.159853,37.863418]]]},"properties":{"id":"65452","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.220711,36.921425],[-89.225347,36.904834],[-89.217294,36.909138],[-89.220711,36.921425]]]},"properties":{"id":"63882","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.319229,36.819663],[-89.319231,36.832177],[-89.333423,36.819588],[-89.319229,36.819663]]]},"properties":{"id":"63820","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.115939,39.591677],[-92.140078,39.434178],[-92.094937,39.369861],[-92.024397,39.340332],[-91.968648,39.339576],[-91.837542,39.37789],[-91.861535,39.416403],[-91.894594,39.556476],[-91.933177,39.596914],[-92.115796,39.596758],[-92.115939,39.591677]]]},"properties":{"id":"65275","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.202958,39.60441],[-92.140078,39.434178],[-92.115939,39.591677],[-92.167921,39.605806],[-92.202958,39.60441]]]},"properties":{"id":"65258","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.827218,36.510216],[-89.827065,36.495753],[-89.817552,36.495764],[-89.817514,36.510228],[-89.827218,36.510216]]]},"properties":{"id":"63878","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.759592,40.416422],[-94.929435,40.447529],[-95.006511,40.405381],[-94.930457,40.238389],[-94.819171,40.272723],[-94.755664,40.30045],[-94.759592,40.416422]]]},"properties":{"id":"64468","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.740204,40.459717],[-94.916566,40.49009],[-94.929435,40.447529],[-94.759592,40.416422],[-94.740204,40.459717]]]},"properties":{"id":"64476","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.740204,40.459717],[-94.759592,40.416422],[-94.560135,40.386925],[-94.509619,40.386887],[-94.541241,40.507907],[-94.707529,40.487455],[-94.740204,40.459717]]]},"properties":{"id":"64475","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.193749,36.537648],[-91.332919,36.552259],[-91.361638,36.4973],[-91.172371,36.497515],[-91.193749,36.537648]]]},"properties":{"id":"65778","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.349174,37.078629],[-91.333915,36.793988],[-91.267056,36.765223],[-91.160321,37.085176],[-91.10269,37.086665],[-91.168492,37.132815],[-91.349174,37.078629]]]},"properties":{"id":"65588","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.836512,36.595466],[-92.735102,36.497992],[-92.617338,36.498199],[-92.622147,36.515485],[-92.649064,36.586723],[-92.767093,36.712366],[-92.849268,36.66108],[-92.836512,36.595466]]]},"properties":{"id":"65761","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.392232,36.790054],[-92.441772,36.708203],[-92.31935,36.71297],[-92.30238,36.73598],[-92.310045,36.744606],[-92.392232,36.790054]]]},"properties":{"id":"65618","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.714322,37.610852],[-89.685032,37.591281],[-89.664373,37.59073],[-89.654359,37.598957],[-89.710674,37.617017],[-89.714322,37.610852]]]},"properties":{"id":"63783","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.695714,37.721839],[-89.705557,37.705481],[-89.691061,37.698682],[-89.695714,37.721839]]]},"properties":{"id":"63746","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.255042,39.232938],[-93.328308,39.096817],[-93.224824,38.967403],[-93.191787,38.899901],[-93.118049,38.931395],[-92.964379,39.111057],[-93.133155,39.241159],[-93.255042,39.232938]]]},"properties":{"id":"65340","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.710239,40.444455],[-92.780679,40.429471],[-92.686595,40.386902],[-92.670966,40.392806],[-92.710239,40.444455]]]},"properties":{"id":"63567","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.195674,40.385549],[-93.147721,40.413779],[-93.184948,40.485825],[-93.365888,40.500887],[-93.195674,40.385549]]]},"properties":{"id":"64655","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.418978,39.474644],[-91.526312,39.616901],[-91.58557,39.634031],[-91.647009,39.508028],[-91.560928,39.410171],[-91.456597,39.428702],[-91.457833,39.436034],[-91.418978,39.474644]]]},"properties":{"id":"63436","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.332691,39.502546],[-92.314256,39.537404],[-92.530336,39.570803],[-92.475582,39.483367],[-92.332691,39.502546]]]},"properties":{"id":"65239","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.066671,37.382053],[-91.079295,37.405429],[-91.080174,37.405789],[-91.066671,37.382053]]]},"properties":{"id":"63666","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.636903,36.719589],[-90.709133,36.593173],[-90.647231,36.633794],[-90.628047,36.692968],[-90.636903,36.719589]]]},"properties":{"id":"63939","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.91269,38.65136],[-90.75609,38.611318],[-90.742412,38.634563],[-90.72828,38.72461],[-90.79589,38.732713],[-90.91375,38.68287],[-90.91529,38.65872],[-90.91269,38.65136]]]},"properties":{"id":"63341","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.434405,38.965745],[-90.32566,38.90922],[-90.32358,38.929653],[-90.434405,38.965745]]]},"properties":{"id":"63373","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.75157,37.879949],[-93.738248,37.831943],[-93.570277,37.877028],[-93.580543,37.951722],[-93.75157,37.879949]]]},"properties":{"id":"64738","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.598104,37.864161],[-90.605849,37.853767],[-90.578554,37.852816],[-90.57816,37.86396],[-90.598104,37.864161]]]},"properties":{"id":"63653","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.477908,38.596153],[-90.603515,38.58679],[-90.603021,38.573997],[-90.610152,38.540724],[-90.534422,38.540553],[-90.476188,38.567144],[-90.461787,38.581193],[-90.477908,38.596153]]]},"properties":{"id":"63021","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.490482,38.626323],[-90.477908,38.596153],[-90.461787,38.581193],[-90.40105,38.611148],[-90.405373,38.652068],[-90.486233,38.630254],[-90.490482,38.626323]]]},"properties":{"id":"63131","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.371144,38.740081],[-90.407296,38.722293],[-90.408563,38.710965],[-90.370826,38.724919],[-90.371144,38.740081]]]},"properties":{"id":"63074","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.762631,37.094311],[-91.525708,37.076542],[-91.490397,37.117723],[-91.538174,37.30468],[-91.756728,37.297527],[-91.757457,37.285851],[-91.761548,37.203871],[-91.762188,37.168274],[-91.762631,37.094311]]]},"properties":{"id":"65571","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.892436,36.831178],[-90.059774,36.876999],[-90.138998,36.694234],[-89.871923,36.700642],[-89.892436,36.831178]]]},"properties":{"id":"63841","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.282164,36.746226],[-93.348917,36.612723],[-93.313402,36.568027],[-93.172508,36.627094],[-93.139231,36.65575],[-93.132714,36.711614],[-93.185693,36.725216],[-93.282164,36.746226]]]},"properties":{"id":"65616","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.176888,37.976359],[-94.098358,37.868789],[-94.063991,37.928513],[-94.176888,37.976359]]]},"properties":{"id":"64750","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.094779,37.957889],[-90.98265,37.738999],[-90.943583,37.737812],[-90.798793,37.798513],[-90.773847,37.811282],[-90.699672,37.853402],[-90.76624,37.931216],[-90.807321,38.056729],[-90.793698,38.08352],[-90.838139,38.102353],[-91.038146,38.081233],[-91.094779,37.957889]]]},"properties":{"id":"63664","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.892409,38.205011],[-90.838139,38.102353],[-90.793698,38.08352],[-90.777571,38.12349],[-90.740792,38.164041],[-90.780185,38.204112],[-90.81561,38.204203],[-90.892409,38.205011]]]},"properties":{"id":"63071","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.220509,37.167849],[-90.278033,37.069564],[-90.222557,37.071277],[-90.22323,37.086527],[-90.221553,37.12892],[-90.220509,37.167849]]]},"properties":{"id":"63951","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.236718,38.625991],[-90.201681,38.618733],[-90.198682,38.626623],[-90.196483,38.634427],[-90.229955,38.641238],[-90.234264,38.633006],[-90.236718,38.625991]],[[-90.204689,38.626483],[-90.207004,38.626958],[-90.206345,38.6293],[-90.203769,38.628699],[-90.204689,38.626483]]]},"properties":{"id":"63103","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.300678,38.649418],[-90.301022,38.647334],[-90.27462,38.645004],[-90.272626,38.657125],[-90.262913,38.672568],[-90.26284,38.673732],[-90.282744,38.679683],[-90.296727,38.661932],[-90.300678,38.649418]]]},"properties":{"id":"63112","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.884186,40.109552],[-92.856372,40.060926],[-92.816078,40.069109],[-92.76703,40.372622],[-92.912107,40.385476],[-92.884305,40.124239],[-92.884186,40.109552]]]},"properties":{"id":"63544","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.329656,40.259174],[-92.373588,40.12414],[-92.352312,40.095343],[-92.34294,40.037163],[-92.260101,40.052191],[-92.23921,40.22052],[-92.329656,40.259174]]]},"properties":{"id":"63547","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.459251,40.09258],[-92.352312,40.095343],[-92.373588,40.12414],[-92.440443,40.109964],[-92.459251,40.09258]]]},"properties":{"id":"63540","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.69383,39.966567],[-94.690369,39.907457],[-94.626516,39.878076],[-94.604142,39.892697],[-94.69383,39.966567]]]},"properties":{"id":"64459","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.064771,36.876399],[-94.0293,36.858356],[-93.915554,37.030488],[-93.932518,37.059738],[-93.950186,37.073365],[-94.133022,37.006237],[-94.1876,37.007463],[-94.13484,36.939687],[-94.064771,36.876399]]]},"properties":{"id":"65723","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.409555,37.415921],[-94.200409,37.43233],[-94.077614,37.494058],[-94.076854,37.521707],[-94.073524,37.639722],[-94.389973,37.649302],[-94.400596,37.649321],[-94.409555,37.415921]]]},"properties":{"id":"64759","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.614111,37.973797],[-94.484902,37.984807],[-94.580693,38.197945],[-94.61265,38.218569],[-94.614111,37.973797]]]},"properties":{"id":"64752","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.067294,38.529995],[-93.095922,38.315958],[-93.077692,38.263092],[-93.016893,38.207799],[-92.937823,38.225223],[-92.94426,38.29145],[-92.94493,38.54598],[-92.99223,38.56694],[-93.067294,38.529995]]]},"properties":{"id":"65078","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.081981,36.89199],[-90.013494,37.007547],[-90.002303,37.040288],[-90.032432,37.06955],[-90.111043,37.046354],[-90.14852,37.04144],[-90.25413,36.871239],[-90.220971,36.848843],[-90.081981,36.89199]]]},"properties":{"id":"63960","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.87522,39.777311],[-94.860519,39.74914],[-94.854632,39.733807],[-94.84054,39.759385],[-94.806044,39.762471],[-94.836137,39.780924],[-94.87522,39.777311]]]},"properties":{"id":"64501","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.880328,39.63963],[-94.974232,39.618339],[-94.95653,39.527335],[-94.872439,39.532021],[-94.864293,39.548257],[-94.880328,39.63963]]]},"properties":{"id":"64440","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.677478,39.820356],[-94.703017,39.688148],[-94.664336,39.667581],[-94.646699,39.667534],[-94.602494,39.780765],[-94.625707,39.81966],[-94.677478,39.820356]]]},"properties":{"id":"64443","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.351454,36.983614],[-90.353152,36.904098],[-90.296134,36.880425],[-90.25413,36.871239],[-90.14852,37.04144],[-90.222557,37.071277],[-90.278033,37.069564],[-90.351454,36.983614]]]},"properties":{"id":"63966","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.935774,36.860844],[-90.662617,36.811453],[-90.568094,36.901315],[-90.630509,36.926168],[-90.748468,37.049721],[-90.865251,37.051671],[-90.935774,36.860844]]]},"properties":{"id":"63937","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.153327,39.526229],[-94.168313,39.595234],[-94.170765,39.613926],[-94.250863,39.609505],[-94.38789,39.604673],[-94.400941,39.484661],[-94.256977,39.483933],[-94.153327,39.526229]]]},"properties":{"id":"64465","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.949292,38.706982],[-91.881916,38.652713],[-91.736928,38.702345],[-91.760271,38.707311],[-91.846111,38.751052],[-91.949292,38.706982]]]},"properties":{"id":"65059","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.58969,38.210746],[-92.598788,38.163128],[-92.655844,38.118341],[-92.579063,38.096036],[-92.516154,38.193521],[-92.537293,38.213153],[-92.58969,38.210746]]]},"properties":{"id":"65047","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.550705,37.92139],[-92.506138,37.755269],[-92.411696,37.66092],[-92.328829,37.628399],[-92.318629,37.777279],[-92.287896,37.887081],[-92.406275,38.021179],[-92.424631,38.021396],[-92.515979,38.022336],[-92.550705,37.92139]]]},"properties":{"id":"65556","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.858325,37.81284],[-92.705852,37.893871],[-92.854366,37.91156],[-92.886799,37.896657],[-92.858325,37.81284]]]},"properties":{"id":"65463","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.647436,37.524841],[-92.521491,37.509465],[-92.411696,37.66092],[-92.506138,37.755269],[-92.6353,37.899074],[-92.705852,37.893871],[-92.858325,37.81284],[-92.900789,37.733194],[-92.890173,37.684127],[-92.86687,37.659014],[-92.704442,37.568783],[-92.647436,37.524841]]]},"properties":{"id":"65536","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.567264,37.244995],[-89.51528,37.29693],[-89.454405,37.453102],[-89.74026,37.304256],[-89.758142,37.235892],[-89.743187,37.231687],[-89.715923,37.249629],[-89.658532,37.231728],[-89.620256,37.233008],[-89.567264,37.244995]]]},"properties":{"id":"63701","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.837169,37.90232],[-90.092067,37.69582],[-90.146763,37.597434],[-90.028666,37.598114],[-89.861326,37.557082],[-89.77286,37.591255],[-89.734951,37.601558],[-89.714322,37.610852],[-89.710674,37.617017],[-89.691061,37.698682],[-89.705557,37.705481],[-89.695714,37.721839],[-89.665991,37.753571],[-89.837169,37.90232]]]},"properties":{"id":"63775","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.833585,37.316763],[-89.793118,37.33233],[-89.806188,37.375483],[-89.862363,37.369974],[-89.833585,37.316763]]]},"properties":{"id":"63739","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.516806,37.656992],[-89.654359,37.598957],[-89.664373,37.59073],[-89.660267,37.552148],[-89.521855,37.566214],[-89.516806,37.656992]]]},"properties":{"id":"63732","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.497017,39.614167],[-93.515411,39.613959],[-93.403242,39.5292],[-93.282941,39.532754],[-93.268162,39.569662],[-93.285892,39.666819],[-93.402612,39.716016],[-93.497017,39.614167]]]},"properties":{"id":"64643","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.709133,36.593173],[-90.636903,36.719589],[-90.720553,36.782503],[-90.946726,36.817554],[-91.079696,36.823255],[-91.213492,36.731376],[-91.166956,36.627329],[-90.971979,36.498391],[-90.71994,36.498496],[-90.688042,36.56333],[-90.709133,36.593173]]]},"properties":{"id":"63935","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.490031,38.841713],[-94.48111,38.753908],[-94.403051,38.7662],[-94.400986,38.811708],[-94.430756,38.839608],[-94.490031,38.841713]]]},"properties":{"id":"64083","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.529539,38.669154],[-94.526529,38.563263],[-94.464581,38.576322],[-94.436622,38.572362],[-94.449307,38.639926],[-94.529539,38.669154]]]},"properties":{"id":"64746","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.529564,38.843652],[-94.557239,38.866764],[-94.565006,38.859724],[-94.564921,38.845221],[-94.529564,38.843652]]]},"properties":{"id":"64147","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.204897,39.440438],[-93.137909,39.491368],[-93.199809,39.572014],[-93.268162,39.569662],[-93.282941,39.532754],[-93.229501,39.441693],[-93.204897,39.440438]]]},"properties":{"id":"65286","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.285892,39.666819],[-93.268162,39.569662],[-93.199809,39.572014],[-93.150538,39.638214],[-93.149453,39.652898],[-93.167508,39.704504],[-93.266968,39.703562],[-93.285892,39.666819]]]},"properties":{"id":"64681","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.329179,36.962921],[-93.214811,36.858589],[-93.184457,36.845248],[-93.139713,36.910121],[-93.143162,37.04159],[-93.23007,37.10357],[-93.278397,37.09385],[-93.329179,36.962921]]]},"properties":{"id":"65721","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.608135,37.13475],[-93.41733,37.080871],[-93.404291,37.083679],[-93.42428,37.16161],[-93.528159,37.185475],[-93.629545,37.156398],[-93.607976,37.14204],[-93.608135,37.13475]]]},"properties":{"id":"65738","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.400788,37.068298],[-93.364207,36.966236],[-93.329179,36.962921],[-93.278397,37.09385],[-93.297659,37.094451],[-93.352702,37.094952],[-93.400788,37.068298]]]},"properties":{"id":"65714","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.079096,40.560866],[-92.037026,40.418443],[-91.974914,40.402933],[-91.945394,40.438862],[-91.943369,40.584317],[-91.985387,40.584789],[-92.079096,40.560866]]]},"properties":{"id":"63432","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.548958,39.179774],[-94.60146,39.180141],[-94.601733,39.159603],[-94.607354,39.113444],[-94.588932,39.112903],[-94.579019,39.117367],[-94.533728,39.146017],[-94.548958,39.179774]]]},"properties":{"id":"64116","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.491865,39.331976],[-94.560573,39.332642],[-94.542914,39.31096],[-94.491761,39.310231],[-94.491865,39.331976]]]},"properties":{"id":"64166","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.491761,39.310231],[-94.491761,39.310042],[-94.473337,39.311202],[-94.491857,39.332443],[-94.491865,39.331976],[-94.491761,39.310231]]]},"properties":{"id":"64167","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.319483,39.81946],[-94.433468,39.835264],[-94.433469,39.835186],[-94.432365,39.681755],[-94.396443,39.674216],[-94.327331,39.664329],[-94.319483,39.81946]]]},"properties":{"id":"64474","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.318387,38.43026],[-92.291516,38.298567],[-92.232974,38.327748],[-92.258997,38.371285],[-92.303838,38.440189],[-92.318387,38.43026]]]},"properties":{"id":"65040","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.145444,38.327448],[-92.250791,38.39043],[-92.258997,38.371285],[-92.232974,38.327748],[-92.223352,38.193989],[-92.045239,38.177386],[-92.008145,38.253163],[-92.075056,38.289771],[-92.145444,38.327448]]]},"properties":{"id":"65058","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.118049,38.931395],[-93.090166,38.870867],[-93.053011,38.859377],[-92.993749,38.940287],[-92.934569,39.064547],[-92.917169,39.074041],[-92.945302,39.111195],[-92.964379,39.111057],[-93.118049,38.931395]],[[-92.942894,39.069546],[-92.951754,39.067319],[-92.952667,39.070806],[-92.942894,39.069546]]]},"properties":{"id":"65347","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.274026,37.699358],[-91.153345,37.69734],[-91.146839,37.709849],[-91.11962,37.740383],[-91.276487,37.856921],[-91.274026,37.699358]]]},"properties":{"id":"65456","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.306321,37.861672],[-91.27627,37.6994],[-91.274026,37.699358],[-91.276487,37.856921],[-91.306321,37.861672]]]},"properties":{"id":"65446","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.684905,37.443171],[-93.622785,37.38373],[-93.510893,37.306302],[-93.467367,37.4491],[-93.485469,37.493432],[-93.636848,37.50016],[-93.684905,37.443171]]]},"properties":{"id":"65770","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.072447,37.902627],[-93.212572,37.890489],[-93.254178,37.876517],[-93.275123,37.80442],[-93.254838,37.788536],[-93.189477,37.769604],[-93.087654,37.793139],[-93.049656,37.810496],[-93.046138,37.890742],[-93.072447,37.902627]]]},"properties":{"id":"65767","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.99057,40.068895],[-94.072258,39.988316],[-93.896369,40.012121],[-93.887158,40.090253],[-93.91087,40.090486],[-93.99057,40.068895]]]},"properties":{"id":"64647","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.102614,37.587757],[-91.153345,37.69734],[-91.274026,37.699358],[-91.27627,37.6994],[-91.26545,37.591344],[-91.110065,37.494432],[-91.060522,37.525658],[-91.102614,37.587757]]]},"properties":{"id":"65440","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.854867,37.773639],[-91.732971,37.700316],[-91.710045,37.695057],[-91.619361,37.843134],[-91.746815,38.053249],[-91.88869,38.080154],[-91.888558,38.072971],[-91.896128,38.048695],[-91.854867,37.773639]]]},"properties":{"id":"65401","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.688217,37.263438],[-92.899344,37.215375],[-92.903441,37.039634],[-92.903494,37.037644],[-92.904208,37.008816],[-92.700964,37.042084],[-92.664154,37.219436],[-92.688217,37.263438]]]},"properties":{"id":"65746","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.30238,36.73598],[-92.24653,36.6555],[-92.22959,36.658619],[-92.147343,36.794971],[-92.186623,36.851546],[-92.263966,36.854728],[-92.310045,36.744606],[-92.30238,36.73598]]]},"properties":{"id":"65637","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.702903,38.406578],[-90.801137,38.429314],[-90.750222,38.355078],[-90.72103,38.37739],[-90.703616,38.386097],[-90.702903,38.406578]]]},"properties":{"id":"63015","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.7802,38.306355],[-90.799937,38.277034],[-90.818898,38.269311],[-90.81561,38.204203],[-90.780185,38.204112],[-90.755996,38.302768],[-90.7802,38.306355]]]},"properties":{"id":"63041","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.501756,40.143802],[-94.350336,40.095684],[-94.444528,40.216075],[-94.501756,40.143802]]]},"properties":{"id":"64438","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.764169,40.069457],[-93.702364,39.960698],[-93.540841,39.921264],[-93.502245,39.994295],[-93.456453,40.054235],[-93.461455,40.192544],[-93.737235,40.206095],[-93.764169,40.069457]]]},"properties":{"id":"64683","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.864829,38.647059],[-93.915621,38.600823],[-93.845371,38.499921],[-93.761807,38.525257],[-93.776937,38.634449],[-93.794064,38.674537],[-93.864829,38.647059]]]},"properties":{"id":"64733","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.229194,37.96312],[-93.212572,37.890489],[-93.072447,37.902627],[-93.070523,37.95114],[-93.229194,37.96312]]]},"properties":{"id":"65732","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.154203,40.079597],[-95.143802,39.901918],[-94.993382,39.897906],[-94.997748,39.967303],[-94.994261,39.971208],[-95.055109,40.073503],[-95.135516,40.07944],[-95.154203,40.079597]]]},"properties":{"id":"64473","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.779428,39.06914],[-92.746012,38.981122],[-92.558086,38.970692],[-92.591693,39.021671],[-92.779428,39.06914]]]},"properties":{"id":"65274","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.87484,36.945895],[-91.767267,36.890625],[-91.65747,36.91026],[-91.525708,37.076542],[-91.762631,37.094311],[-91.87484,36.945895]]]},"properties":{"id":"65548","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.22774,36.55244],[-92.09216,36.498673],[-92.029373,36.498636],[-91.993859,36.562028],[-92.052883,36.644973],[-92.22959,36.658619],[-92.24653,36.6555],[-92.248283,36.561881],[-92.22774,36.55244]]]},"properties":{"id":"65626","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.993859,36.562028],[-92.029373,36.498636],[-91.954598,36.498508],[-91.993859,36.562028]]]},"properties":{"id":"65777","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.052883,36.644973],[-92.147343,36.794971],[-92.22959,36.658619],[-92.052883,36.644973]]]},"properties":{"id":"65790","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.705528,37.688044],[-90.724324,37.702433],[-90.692016,37.651324],[-90.770235,37.549446],[-90.693771,37.499255],[-90.546769,37.524407],[-90.535016,37.643641],[-90.552496,37.720046],[-90.705528,37.688044]],[[-90.653773,37.624001],[-90.636904,37.628154],[-90.636362,37.62116],[-90.653773,37.624001]]]},"properties":{"id":"63650","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.653773,37.624001],[-90.636362,37.62116],[-90.636904,37.628154],[-90.653773,37.624001]]]},"properties":{"id":"63663","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.622727,37.824952],[-90.734593,37.779614],[-90.705528,37.688044],[-90.552496,37.720046],[-90.552364,37.729225],[-90.622727,37.824952]]]},"properties":{"id":"63624","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.429866,39.079706],[-94.379097,39.077839],[-94.379616,39.090056],[-94.385463,39.128992],[-94.401575,39.166823],[-94.439262,39.13177],[-94.438005,39.094594],[-94.429866,39.079706]]]},"properties":{"id":"64050","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.513143,39.10499],[-94.548152,39.113575],[-94.560673,39.113072],[-94.55825,39.102702],[-94.518438,39.101438],[-94.513143,39.10499]]]},"properties":{"id":"64124","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.583396,39.096038],[-94.596521,39.09698],[-94.607234,39.083301],[-94.607334,39.072431],[-94.580043,39.070835],[-94.561708,39.077401],[-94.560834,39.09511],[-94.583396,39.096038]]]},"properties":{"id":"64108","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.55798,38.956318],[-94.566043,38.925122],[-94.560828,38.910095],[-94.523868,38.909368],[-94.521882,38.952946],[-94.5251,38.953124],[-94.519654,38.9674],[-94.521118,38.967457],[-94.55798,38.956318]]]},"properties":{"id":"64137","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.39968,37.238392],[-94.420319,37.225789],[-94.418089,37.225793],[-94.400173,37.215108],[-94.39968,37.238392]]]},"properties":{"id":"64830","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.630684,38.317681],[-90.555533,38.351012],[-90.703616,38.386097],[-90.72103,38.37739],[-90.630684,38.317681]]]},"properties":{"id":"63016","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.3658,38.36636],[-90.36372,38.3661],[-90.36239,38.36756],[-90.36549,38.36797],[-90.3658,38.36636]]]},"properties":{"id":"63053","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.740792,38.164041],[-90.737908,38.161154],[-90.67805,38.18428],[-90.630684,38.317681],[-90.72103,38.37739],[-90.750222,38.355078],[-90.755996,38.302768],[-90.780185,38.204112],[-90.740792,38.164041]]]},"properties":{"id":"63023","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.48568,38.19334],[-90.481214,38.199097],[-90.496394,38.193499],[-90.48568,38.19334]]]},"properties":{"id":"63047","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.416022,38.042315],[-90.491983,38.02517],[-90.423062,37.980585],[-90.415849,38.042195],[-90.416022,38.042315]]]},"properties":{"id":"63087","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.927098,38.874852],[-93.864829,38.647059],[-93.794064,38.674537],[-93.85158,38.882849],[-93.927098,38.874852]]]},"properties":{"id":"64019","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.076178,39.987731],[-92.096004,39.964491],[-92.07682,39.973213],[-92.076178,39.987731]]]},"properties":{"id":"63464","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.999972,37.064656],[-94.133022,37.006237],[-93.950186,37.073365],[-93.999972,37.064656]]]},"properties":{"id":"64873","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.602421,39.985359],[-91.663478,40.048785],[-91.858596,40.019011],[-91.859306,39.948513],[-91.840978,39.913431],[-91.746008,39.879262],[-91.707826,39.917476],[-91.723855,39.976503],[-91.644938,39.97616],[-91.602421,39.985359]]]},"properties":{"id":"63440","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.644938,39.97616],[-91.707826,39.917476],[-91.746008,39.879262],[-91.687162,39.861336],[-91.612832,39.911791],[-91.560309,39.946634],[-91.602421,39.985359],[-91.644938,39.97616]]]},"properties":{"id":"63454","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.887553,39.234392],[-90.896042,39.178788],[-90.970845,39.115746],[-90.89096,39.083067],[-90.68523,39.115527],[-90.721835,39.224108],[-90.887553,39.234392]]]},"properties":{"id":"63343","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.892492,38.886495],[-90.826982,38.878964],[-90.812586,38.98307],[-90.859427,38.982389],[-90.957555,38.893285],[-90.892492,38.886495]]]},"properties":{"id":"63362","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.879396,39.043696],[-90.685046,39.001708],[-90.68523,39.115527],[-90.89096,39.083067],[-90.879396,39.043696]]]},"properties":{"id":"63347","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.221421,38.947179],[-91.110303,38.92521],[-91.095887,39.017648],[-91.206458,39.039824],[-91.221421,38.947179]]]},"properties":{"id":"63349","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.256582,39.071018],[-91.384517,39.052807],[-91.355203,38.992469],[-91.318301,38.9725],[-91.227263,39.034777],[-91.256582,39.071018]]]},"properties":{"id":"63333","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.976219,39.761423],[-92.835015,39.69998],[-92.808686,39.856966],[-92.809974,39.907082],[-92.957117,39.877094],[-92.976219,39.761423]]]},"properties":{"id":"64631","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.235461,39.827998],[-93.266968,39.703562],[-93.167508,39.704504],[-93.150956,39.839105],[-93.235461,39.827998]]]},"properties":{"id":"64651","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.321894,36.724649],[-94.505747,36.762922],[-94.591801,36.707697],[-94.312092,36.705325],[-94.321894,36.724649]]]},"properties":{"id":"64843","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.199384,39.830281],[-92.289242,39.894564],[-92.308334,39.873683],[-92.299287,39.609377],[-92.299316,39.605344],[-92.265092,39.573858],[-92.202958,39.60441],[-92.167921,39.605806],[-92.146889,39.735518],[-92.14596,39.769974],[-92.199384,39.830281]]]},"properties":{"id":"63437","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.419517,38.165945],[-92.442955,38.101567],[-92.424631,38.021396],[-92.406275,38.021179],[-92.18467,38.030639],[-92.226867,38.178058],[-92.327485,38.188399],[-92.419517,38.165945]]]},"properties":{"id":"65486","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.790491,36.494114],[-89.808373,36.496131],[-89.817514,36.510228],[-89.817552,36.495764],[-89.827065,36.495753],[-89.827218,36.510216],[-89.866836,36.523943],[-89.959928,36.498126],[-89.963883,36.411135],[-89.928511,36.321596],[-89.879629,36.386587],[-89.790491,36.494114]]]},"properties":{"id":"63848","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.683331,36.757781],[-89.701091,36.757352],[-89.700145,36.742915],[-89.682729,36.743343],[-89.683331,36.757781]]]},"properties":{"id":"63828","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.686358,36.84515],[-89.687303,36.859586],[-89.704833,36.859285],[-89.70417,36.83932],[-89.686358,36.84515]]]},"properties":{"id":"63868","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.809948,36.583975],[-89.666004,36.67195],[-89.682729,36.743343],[-89.700145,36.742915],[-89.729075,36.74389],[-89.809948,36.583975]]]},"properties":{"id":"63833","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.609834,36.555107],[-89.626003,36.540849],[-89.58148,36.533186],[-89.609834,36.555107]]]},"properties":{"id":"63866","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.069923,40.510805],[-95.036265,40.576354],[-95.202265,40.578488],[-95.202017,40.479135],[-95.196509,40.479117],[-95.069923,40.510805]]]},"properties":{"id":"64445","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.916076,38.543316],[-91.81919,38.562347],[-91.91082,38.621459],[-91.944238,38.596925],[-92.012936,38.506505],[-91.916076,38.543316]]]},"properties":{"id":"65016","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.826498,36.321003],[-89.862416,36.386583],[-89.879629,36.386587],[-89.928511,36.321596],[-89.960856,36.30862],[-89.959586,36.171608],[-89.914504,36.175679],[-89.869767,36.168485],[-89.829894,36.182315],[-89.826498,36.321003]]]},"properties":{"id":"63827","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.654359,37.598957],[-89.516806,37.656992],[-89.665991,37.753571],[-89.695714,37.721839],[-89.691061,37.698682],[-89.710674,37.617017],[-89.654359,37.598957]]]},"properties":{"id":"63748","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.355079,38.805223],[-93.426919,38.719963],[-93.336563,38.536631],[-93.290454,38.535388],[-93.24269,38.534921],[-93.178157,38.555966],[-93.056921,38.765409],[-93.056324,38.780091],[-93.053011,38.859377],[-93.090166,38.870867],[-93.355079,38.805223]]]},"properties":{"id":"65301","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-92.066984,37.707987],[-92.10235,37.646063],[-92.070985,37.657567],[-92.066984,37.707987]]],[[[-91.854867,37.773639],[-91.896128,38.048695],[-91.977067,37.933205],[-91.975258,37.920044],[-92.014547,37.92886],[-92.076648,37.853072],[-92.071679,37.849653],[-92.025122,37.770025],[-92.025273,37.763086],[-91.987748,37.661861],[-91.938922,37.644807],[-91.854867,37.773639]]]]},"properties":{"id":"65550","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.46076,37.525122],[-93.369531,37.517991],[-93.325775,37.490652],[-93.264415,37.486698],[-93.281528,37.725067],[-93.299038,37.804925],[-93.372107,37.807193],[-93.466291,37.766703],[-93.463562,37.700349],[-93.515072,37.665062],[-93.523318,37.585416],[-93.46076,37.525122]]]},"properties":{"id":"65613","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.188369,40.338438],[-93.059818,40.313542],[-93.027525,40.385747],[-93.147721,40.413779],[-93.195674,40.385549],[-93.188369,40.338438]]]},"properties":{"id":"63560","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.890151,39.192012],[-93.976773,39.211004],[-93.988892,39.152786],[-93.950458,39.150845],[-93.890151,39.192012]]]},"properties":{"id":"64036","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.362311,37.937178],[-90.328448,37.954275],[-90.316774,37.980063],[-90.415849,38.042195],[-90.423062,37.980585],[-90.362311,37.937178]]]},"properties":{"id":"63036","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.300678,38.649418],[-90.347971,38.657977],[-90.351008,38.639077],[-90.304533,38.632634],[-90.301022,38.647334],[-90.300678,38.649418]]]},"properties":{"id":"63105","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.362258,38.630116],[-90.329814,38.62278],[-90.308204,38.621488],[-90.301652,38.632372],[-90.304533,38.632634],[-90.351008,38.639077],[-90.362258,38.630116]]]},"properties":{"id":"63117","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.519963,38.737413],[-90.408716,38.696322],[-90.408563,38.710965],[-90.407296,38.722293],[-90.455649,38.75461],[-90.482299,38.764348],[-90.482455,38.764013],[-90.519963,38.737413]]]},"properties":{"id":"63043","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.370826,38.724919],[-90.333282,38.720763],[-90.313153,38.73045],[-90.318944,38.731822],[-90.329028,38.734512],[-90.319066,38.745652],[-90.324852,38.764772],[-90.374893,38.759273],[-90.371144,38.740081],[-90.370826,38.724919]]]},"properties":{"id":"63134","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.240643,38.723303],[-90.21141,38.72135],[-90.166409,38.772649],[-90.238736,38.770634],[-90.240643,38.723303]]]},"properties":{"id":"63137","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.104485,39.383656],[-93.252726,39.338784],[-93.301963,39.309773],[-93.255042,39.232938],[-93.133155,39.241159],[-93.076948,39.342506],[-93.104485,39.383656]]]},"properties":{"id":"65344","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.133155,39.241159],[-92.964379,39.111057],[-92.945302,39.111195],[-92.905391,39.161951],[-93.012214,39.173991],[-92.957986,39.288063],[-92.958148,39.311142],[-93.01136,39.313232],[-93.076948,39.342506],[-93.133155,39.241159]]]},"properties":{"id":"65349","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.477233,39.292796],[-93.484899,39.161232],[-93.394371,39.099847],[-93.328308,39.096817],[-93.255042,39.232938],[-93.301963,39.309773],[-93.477233,39.292796]]]},"properties":{"id":"65339","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.582381,40.474744],[-92.710239,40.444455],[-92.670966,40.392806],[-92.389633,40.39116],[-92.434475,40.461244],[-92.582381,40.474744]]]},"properties":{"id":"63561","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.082318,40.602177],[-92.079096,40.560866],[-91.985387,40.584789],[-91.998645,40.604434],[-92.082318,40.602177]]]},"properties":{"id":"52542","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.55861,37.177013],[-89.558155,37.186005],[-89.563754,37.188276],[-89.55861,37.177013]]]},"properties":{"id":"63758","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.646626,37.422731],[-91.756728,37.297527],[-91.538174,37.30468],[-91.537514,37.350357],[-91.569826,37.421359],[-91.612053,37.422023],[-91.646626,37.422731]]]},"properties":{"id":"65479","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.959392,37.094587],[-89.959333,37.079822],[-89.954852,37.081195],[-89.959392,37.094587]]]},"properties":{"id":"63738","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.448765,36.498676],[-93.315332,36.49814],[-93.313648,36.557583],[-93.313402,36.568027],[-93.348917,36.612723],[-93.388617,36.575247],[-93.448765,36.498676]]]},"properties":{"id":"65611","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.398212,36.605168],[-93.468922,36.612212],[-93.5144,36.498881],[-93.448765,36.498676],[-93.388617,36.575247],[-93.398212,36.605168]]]},"properties":{"id":"65681","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.984441,36.685414],[-93.037037,36.705082],[-93.065611,36.651507],[-93.033838,36.627042],[-92.955064,36.627147],[-92.949632,36.65191],[-92.984441,36.685414]]]},"properties":{"id":"65680","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.132714,36.711614],[-93.148799,36.726017],[-93.185693,36.725216],[-93.132714,36.711614]]]},"properties":{"id":"65740","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.76624,37.931216],[-90.699672,37.853402],[-90.642853,37.868177],[-90.642477,37.897396],[-90.666273,37.979065],[-90.76624,37.931216]]]},"properties":{"id":"63660","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.660388,38.045336],[-90.666747,38.019185],[-90.640804,38.022345],[-90.660388,38.045336]]]},"properties":{"id":"63674","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.566323,37.021631],[-90.748468,37.049721],[-90.630509,36.926168],[-90.566323,37.021631]]]},"properties":{"id":"63952","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.22323,37.086527],[-90.222557,37.071277],[-90.14852,37.04144],[-90.111043,37.046354],[-90.22323,37.086527]]]},"properties":{"id":"63763","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.686283,40.314226],[-92.460385,40.280301],[-92.347801,40.302756],[-92.349996,40.353607],[-92.350087,40.374764],[-92.389633,40.39116],[-92.670966,40.392806],[-92.686595,40.386902],[-92.684933,40.343461],[-92.686283,40.314226]]]},"properties":{"id":"63546","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.347801,40.302756],[-92.460385,40.280301],[-92.440443,40.109964],[-92.373588,40.12414],[-92.329656,40.259174],[-92.347801,40.302756]]]},"properties":{"id":"63533","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.510307,39.958796],[-94.657056,40.061035],[-94.700837,40.003435],[-94.69383,39.966567],[-94.604142,39.892697],[-94.565866,39.907784],[-94.528986,39.944108],[-94.510307,39.958796]]]},"properties":{"id":"64494","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.930457,40.238389],[-95.006511,40.405381],[-95.235763,40.364219],[-95.25658,40.262778],[-95.181777,40.218146],[-95.068365,40.202615],[-94.934297,40.215814],[-94.930457,40.238389]]]},"properties":{"id":"64487","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.779982,37.02851],[-93.765281,36.812964],[-93.68512,36.670186],[-93.649694,36.686298],[-93.612929,36.820562],[-93.612775,36.834776],[-93.61046,36.966564],[-93.66839,37.069007],[-93.779982,37.02851]]]},"properties":{"id":"65605","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.0293,36.858356],[-93.843028,36.806099],[-93.861547,37.015022],[-93.897518,37.030103],[-93.898567,37.015524],[-93.915957,37.015979],[-93.915554,37.030488],[-94.0293,36.858356]]]},"properties":{"id":"65708","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.200409,37.43233],[-94.142862,37.268591],[-94.143531,37.24641],[-94.108389,37.242151],[-94.035373,37.250856],[-94.000052,37.250766],[-93.981602,37.27526],[-94.077614,37.494058],[-94.200409,37.43233]]]},"properties":{"id":"64748","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.523484,38.207216],[-93.471601,38.10123],[-93.436582,38.070563],[-93.324684,38.066928],[-93.313121,38.066634],[-93.206599,38.257687],[-93.51632,38.359991],[-93.521653,38.248351],[-93.523484,38.207216]]]},"properties":{"id":"65355","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.471601,38.10123],[-93.508355,37.993242],[-93.444174,37.99857],[-93.436582,38.070563],[-93.471601,38.10123]]]},"properties":{"id":"65735","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.99814,38.592394],[-93.178157,38.555966],[-93.24269,38.534921],[-93.067294,38.529995],[-92.99223,38.56694],[-92.99814,38.592394]]]},"properties":{"id":"65345","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.32004,38.95245],[-92.32001,38.95389],[-92.32166,38.95389],[-92.32171,38.95247],[-92.32004,38.95245]]]},"properties":{"id":"65215","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.140719,36.640935],[-90.302532,36.676522],[-90.345195,36.587118],[-90.431733,36.498401],[-90.220732,36.497859],[-90.148398,36.630656],[-90.140719,36.640935]]]},"properties":{"id":"63961","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.28106,36.768619],[-90.144642,36.673111],[-90.139678,36.694414],[-90.220971,36.848843],[-90.25413,36.871239],[-90.296134,36.880425],[-90.279851,36.845981],[-90.281186,36.84332],[-90.28106,36.768619]]]},"properties":{"id":"63940","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.353152,36.904098],[-90.351454,36.983614],[-90.513273,37.061444],[-90.566323,37.021631],[-90.630509,36.926168],[-90.568094,36.901315],[-90.353152,36.904098]]]},"properties":{"id":"63967","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.758333,39.654793],[-93.86089,39.665311],[-93.851897,39.467953],[-93.79623,39.438133],[-93.701658,39.510095],[-93.663903,39.53934],[-93.702335,39.636883],[-93.758333,39.654793]]]},"properties":{"id":"64624","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.64233,38.841775],[-91.710843,38.707583],[-91.640372,38.703792],[-91.546289,38.676627],[-91.535607,38.819888],[-91.585608,38.836363],[-91.64233,38.841775]]]},"properties":{"id":"65069","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.648054,37.986237],[-92.756579,38.085221],[-92.851579,38.144467],[-92.851156,37.99006],[-92.854366,37.91156],[-92.705852,37.893871],[-92.6353,37.899074],[-92.636989,37.90714],[-92.648054,37.986237]]]},"properties":{"id":"65020","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.630864,37.115803],[-89.558918,37.145515],[-89.55861,37.177013],[-89.563754,37.188276],[-89.620256,37.233008],[-89.658532,37.231728],[-89.743187,37.231687],[-89.758142,37.235892],[-89.79953,37.223156],[-89.8164,37.152209],[-89.630864,37.115803]],[[-89.739785,37.200338],[-89.736001,37.20081],[-89.735133,37.193865],[-89.739785,37.200338]]]},"properties":{"id":"63740","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.415974,39.464515],[-93.328668,39.38511],[-93.229501,39.441693],[-93.282941,39.532754],[-93.403242,39.5292],[-93.436335,39.499935],[-93.415974,39.464515]]]},"properties":{"id":"64623","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.561539,39.585774],[-93.645299,39.524945],[-93.415974,39.464515],[-93.436335,39.499935],[-93.561539,39.585774]]]},"properties":{"id":"64622","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.935774,36.860844],[-90.946726,36.817554],[-90.720553,36.782503],[-90.662617,36.811453],[-90.935774,36.860844]]]},"properties":{"id":"63943","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.160321,37.085176],[-91.267056,36.765223],[-91.213492,36.731376],[-91.079696,36.823255],[-91.160321,37.085176]]]},"properties":{"id":"63941","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.529539,38.669154],[-94.449307,38.639926],[-94.37484,38.72899],[-94.403051,38.7662],[-94.48111,38.753908],[-94.562967,38.721525],[-94.529539,38.669154]]]},"properties":{"id":"64078","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.646614,37.731542],[-93.463562,37.700349],[-93.466291,37.766703],[-93.543207,37.86358],[-93.570277,37.877028],[-93.738248,37.831943],[-93.646614,37.731542]]]},"properties":{"id":"65674","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.955457,39.347446],[-92.939186,39.332791],[-92.869208,39.585128],[-92.972975,39.553158],[-92.996662,39.524588],[-93.009756,39.436513],[-92.955457,39.347446]]]},"properties":{"id":"65261","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.150538,39.638214],[-93.199809,39.572014],[-93.137909,39.491368],[-92.996662,39.524588],[-92.972975,39.553158],[-92.991982,39.615307],[-93.150538,39.638214]]]},"properties":{"id":"64660","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.496927,36.935583],[-93.393648,36.949228],[-93.41733,37.080871],[-93.608135,37.13475],[-93.496927,36.935583]]]},"properties":{"id":"65610","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.913108,40.256471],[-91.884569,40.255852],[-91.836508,40.327923],[-91.869359,40.415717],[-91.945394,40.438862],[-91.974914,40.402933],[-91.949747,40.300832],[-91.950812,40.25721],[-91.913108,40.256471]]]},"properties":{"id":"63474","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.507243,39.243195],[-94.492954,39.223538],[-94.45724,39.244715],[-94.507276,39.243436],[-94.507243,39.243195]]]},"properties":{"id":"64158","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.278325,39.236159],[-94.187174,39.183843],[-94.104823,39.143507],[-94.027379,39.152853],[-94.024206,39.154537],[-94.089059,39.24246],[-94.098717,39.304317],[-94.117157,39.31422],[-94.278222,39.245632],[-94.278325,39.236159]]]},"properties":{"id":"64077","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.449719,39.41013],[-94.27634,39.45528],[-94.256977,39.483933],[-94.400941,39.484661],[-94.449176,39.462741],[-94.449719,39.41013]]]},"properties":{"id":"64048","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.449245,38.569381],[-92.348942,38.576573],[-92.352805,38.677445],[-92.360216,38.730133],[-92.394866,38.73913],[-92.472329,38.704319],[-92.449245,38.569381]]]},"properties":{"id":"65023","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.472329,38.704319],[-92.588509,38.751271],[-92.636949,38.758552],[-92.670194,38.735986],[-92.662346,38.592654],[-92.655116,38.508682],[-92.615108,38.456155],[-92.458691,38.539989],[-92.449245,38.569381],[-92.472329,38.704319]]]},"properties":{"id":"65018","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.36648,38.263811],[-91.366988,38.228875],[-91.038146,38.081233],[-90.838139,38.102353],[-90.892409,38.205011],[-90.944827,38.2693],[-91.130539,38.332184],[-91.294602,38.306562],[-91.33266,38.318187],[-91.366464,38.264492],[-91.36648,38.263811]],[[-91.097186,38.268481],[-91.089927,38.260438],[-91.096363,38.250986],[-91.108494,38.254847],[-91.097186,38.268481]]]},"properties":{"id":"63080","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.956074,37.692399],[-93.013498,37.6018],[-92.983284,37.520097],[-92.936969,37.554061],[-92.86687,37.659014],[-92.890173,37.684127],[-92.956074,37.692399]]]},"properties":{"id":"65590","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.216535,40.194731],[-94.322026,40.109787],[-94.294173,40.037938],[-94.294065,40.037931],[-94.084925,40.15656],[-94.169554,40.197444],[-94.216535,40.194731]]]},"properties":{"id":"64657","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.263966,36.854728],[-92.343681,36.944866],[-92.386294,36.903536],[-92.382848,36.799584],[-92.392232,36.790054],[-92.310045,36.744606],[-92.263966,36.854728]]]},"properties":{"id":"65638","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.928595,37.140694],[-92.188672,36.912432],[-92.188513,36.883239],[-92.022121,36.836364],[-91.87484,36.945895],[-91.762631,37.094311],[-91.762188,37.168274],[-91.928595,37.140694]]]},"properties":{"id":"65793","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.169189,36.061112],[-90.186073,35.997318],[-89.995632,35.999036],[-89.959491,36.102926],[-90.026213,36.132442],[-90.110582,36.104835],[-90.169189,36.061112]]]},"properties":{"id":"63855","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.914504,36.175679],[-89.959491,36.102926],[-89.995632,35.999036],[-89.737628,36.00086],[-89.771236,36.155314],[-89.829468,36.175541],[-89.869767,36.168485],[-89.914504,36.175679]],[[-89.873289,36.05497],[-89.882446,36.0741],[-89.860062,36.07395],[-89.873289,36.05497]],[[-89.8063,36.036378],[-89.827106,36.036532],[-89.815327,36.058495],[-89.799698,36.049162],[-89.8063,36.036378]]]},"properties":{"id":"63877","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.769277,38.569482],[-90.732531,38.593247],[-90.732441,38.600684],[-90.732328,38.639302],[-90.742412,38.634563],[-90.75609,38.611318],[-90.816536,38.583414],[-90.769277,38.569482]]]},"properties":{"id":"63073","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.560135,40.386925],[-94.759592,40.416422],[-94.755664,40.30045],[-94.667808,40.285786],[-94.542115,40.327361],[-94.560135,40.386925]]]},"properties":{"id":"64479","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.27608,37.20388],[-93.311983,37.19707],[-93.38448,37.16889],[-93.36209,37.13881],[-93.278644,37.137122],[-93.276053,37.203128],[-93.27608,37.20388]]]},"properties":{"id":"65807","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.700631,38.488907],[-93.515785,38.371152],[-93.514634,38.392328],[-93.675869,38.545641],[-93.700631,38.488907]]]},"properties":{"id":"65323","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.372107,37.807193],[-93.299038,37.804925],[-93.275123,37.80442],[-93.254178,37.876517],[-93.313991,37.895021],[-93.360488,37.861946],[-93.372107,37.807193]]]},"properties":{"id":"65724","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.143802,39.901918],[-95.154203,40.079597],[-95.266707,40.074156],[-95.282097,39.974832],[-95.143802,39.901918]]]},"properties":{"id":"64451","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.658413,36.857609],[-91.737074,36.788773],[-91.646926,36.766092],[-91.658413,36.857609]]]},"properties":{"id":"65788","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.112527,37.716584],[-91.11962,37.740383],[-91.146839,37.709849],[-91.112527,37.716584]]]},"properties":{"id":"65566","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.502405,38.992424],[-94.417808,38.984751],[-94.412689,38.990687],[-94.415739,39.034056],[-94.447553,39.049464],[-94.468127,39.058191],[-94.472529,39.0591],[-94.515781,39.01525],[-94.502405,38.992424]]]},"properties":{"id":"64133","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.515662,39.075917],[-94.516741,39.05384],[-94.517897,39.015126],[-94.515781,39.01525],[-94.472529,39.0591],[-94.474714,39.06534],[-94.470183,39.081314],[-94.515285,39.083118],[-94.515662,39.075917]]]},"properties":{"id":"64129","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.404393,38.925964],[-94.409139,38.944243],[-94.416372,38.941056],[-94.438015,38.942095],[-94.458036,38.913429],[-94.348628,38.866542],[-94.341841,38.865594],[-94.329854,38.901726],[-94.376746,38.903082],[-94.404393,38.925964]]]},"properties":{"id":"64081","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.385463,39.128992],[-94.379616,39.090056],[-94.255787,39.081131],[-94.262769,39.138312],[-94.385463,39.128992]]]},"properties":{"id":"64056","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.203209,38.920209],[-94.211258,38.856769],[-94.206311,38.843712],[-94.119096,38.833167],[-94.077386,38.892607],[-94.077567,38.916583],[-94.203209,38.920209]]]},"properties":{"id":"64070","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.519654,38.9674],[-94.5251,38.953124],[-94.514885,38.967208],[-94.519654,38.9674]]]},"properties":{"id":"64192","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.386428,37.075284],[-94.618029,37.087141],[-94.618243,36.970897],[-94.534807,36.940394],[-94.425717,36.975764],[-94.368415,37.08105],[-94.386428,37.075284]]]},"properties":{"id":"64804","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.210536,37.080863],[-94.108389,37.242151],[-94.143531,37.24641],[-94.210536,37.080863]],[[-94.132071,37.195467],[-94.127422,37.199482],[-94.127743,37.188103],[-94.132271,37.18814],[-94.132071,37.195467]]]},"properties":{"id":"64859","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.545422,37.259424],[-94.618313,37.194687],[-94.618041,37.142341],[-94.510047,37.157199],[-94.543513,37.259363],[-94.545422,37.259424]]]},"properties":{"id":"64834","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.422859,37.07432],[-94.413889,37.07382],[-94.413483,37.083224],[-94.422622,37.083285],[-94.422859,37.07432]]]},"properties":{"id":"64841","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.425717,36.975764],[-94.306699,36.951256],[-94.247421,37.023502],[-94.267872,37.053063],[-94.368415,37.08105],[-94.425717,36.975764]]]},"properties":{"id":"64840","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.52844,38.217833],[-90.67805,38.18428],[-90.737908,38.161154],[-90.683474,38.093191],[-90.686027,38.076797],[-90.619641,38.036399],[-90.617067,38.006759],[-90.491983,38.02517],[-90.416022,38.042315],[-90.52844,38.217833]]]},"properties":{"id":"63020","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.328448,37.954275],[-90.11408,38.028978],[-90.276541,38.158981],[-90.316774,37.980063],[-90.328448,37.954275]]]},"properties":{"id":"63627","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.508077,38.535962],[-90.489906,38.442878],[-90.460967,38.449434],[-90.415011,38.462959],[-90.41312,38.504727],[-90.433838,38.543431],[-90.463552,38.552502],[-90.508077,38.535962]]]},"properties":{"id":"63026","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.666273,37.979065],[-90.642477,37.897396],[-90.49912,37.868815],[-90.363345,37.884392],[-90.362311,37.937178],[-90.423062,37.980585],[-90.491983,38.02517],[-90.617067,38.006759],[-90.666273,37.979065]]]},"properties":{"id":"63628","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.63392,38.888769],[-93.638062,38.662518],[-93.593229,38.639224],[-93.546283,38.646329],[-93.506416,38.679722],[-93.49507,38.869296],[-93.524936,38.896598],[-93.63392,38.888769]],[[-93.578263,38.726168],[-93.538937,38.751887],[-93.532402,38.713395],[-93.578263,38.726168]]]},"properties":{"id":"65336","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.064885,40.216108],[-92.054378,40.052286],[-91.952337,39.992777],[-91.913108,40.256471],[-91.950812,40.25721],[-92.064885,40.216108]]]},"properties":{"id":"63446","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.952337,39.992777],[-92.054378,40.052286],[-92.091836,40.037948],[-92.066828,39.987224],[-92.063637,39.9513],[-91.952726,39.949426],[-91.952337,39.992777]]]},"properties":{"id":"63458","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.591152,39.043886],[-93.56561,39.150181],[-93.635814,39.195022],[-93.653552,39.166696],[-93.649611,39.028945],[-93.591152,39.043886]]]},"properties":{"id":"64021","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.950458,39.150845],[-93.911172,39.088319],[-93.824324,39.097644],[-93.704213,39.16745],[-93.700057,39.209725],[-93.758463,39.207021],[-93.840147,39.215467],[-93.890151,39.192012],[-93.950458,39.150845]]]},"properties":{"id":"64067","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.070008,39.313897],[-91.119104,39.179674],[-90.896042,39.178788],[-90.887553,39.234392],[-90.899994,39.261691],[-91.070008,39.313897]]]},"properties":{"id":"63344","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.95989,40.072661],[-92.994403,39.985579],[-92.856372,40.060926],[-92.884186,40.109552],[-92.95989,40.072661]]]},"properties":{"id":"63566","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.759145,39.808879],[-93.740471,39.698443],[-93.626595,39.700016],[-93.593629,39.701972],[-93.613171,39.764078],[-93.608551,39.75937],[-93.620758,39.740748],[-93.629877,39.762899],[-93.759145,39.808879]]]},"properties":{"id":"64664","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.157068,36.750971],[-94.224776,36.633272],[-94.147388,36.629246],[-94.104994,36.661998],[-94.067145,36.74774],[-94.068027,36.762259],[-94.157068,36.750971]]]},"properties":{"id":"64861","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.617709,36.624745],[-94.528459,36.499259],[-94.550437,36.583616],[-94.617709,36.624745]]]},"properties":{"id":"64863","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.472581,36.616329],[-94.42616,36.567518],[-94.424437,36.60423],[-94.472581,36.616329]]]},"properties":{"id":"64847","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.365234,39.866259],[-92.516225,39.837288],[-92.550681,39.656507],[-92.363962,39.657418],[-92.365234,39.866259]]]},"properties":{"id":"63552","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.060728,38.159168],[-92.117423,38.15999],[-92.127396,38.101158],[-92.054014,38.159119],[-92.060728,38.159168]]]},"properties":{"id":"65443","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.882406,39.760496],[-91.933177,39.596914],[-91.894594,39.556476],[-91.850678,39.58173],[-91.813131,39.759792],[-91.882406,39.760496]]]},"properties":{"id":"63443","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.440595,38.282923],[-92.537293,38.213153],[-92.516154,38.193521],[-92.487041,38.168084],[-92.419517,38.165945],[-92.327485,38.188399],[-92.315383,38.247984],[-92.440595,38.282923]]]},"properties":{"id":"65082","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.223352,38.193989],[-92.232974,38.327748],[-92.291516,38.298567],[-92.315383,38.247984],[-92.327485,38.188399],[-92.226867,38.178058],[-92.223352,38.193989]]]},"properties":{"id":"65075","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.115796,39.596758],[-91.933177,39.596914],[-91.882406,39.760496],[-91.919383,39.775108],[-92.14596,39.769974],[-92.146889,39.735518],[-92.115796,39.596758]]]},"properties":{"id":"63468","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.056921,38.765409],[-93.178157,38.555966],[-92.99814,38.592394],[-93.032412,38.621405],[-93.056921,38.765409]]]},"properties":{"id":"65350","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.574318,36.627042],[-89.609834,36.555107],[-89.58148,36.533186],[-89.564621,36.533006],[-89.327588,36.632276],[-89.445994,36.673919],[-89.555262,36.685974],[-89.574318,36.627042]]]},"properties":{"id":"63869","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.809303,36.555284],[-89.82773,36.555126],[-89.827664,36.539003],[-89.809198,36.539048],[-89.809285,36.553523],[-89.809303,36.555284]]]},"properties":{"id":"63874","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.687087,40.571968],[-94.707529,40.487455],[-94.541241,40.507907],[-94.470541,40.571022],[-94.687087,40.571968]]]},"properties":{"id":"64486","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.529736,36.662015],[-91.365288,36.605328],[-91.172636,36.614785],[-91.166956,36.627329],[-91.213492,36.731376],[-91.267056,36.765223],[-91.333915,36.793988],[-91.5285,36.762216],[-91.529736,36.662015]]]},"properties":{"id":"65606","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.349174,37.078629],[-91.490397,37.117723],[-91.525708,37.076542],[-91.65747,36.91026],[-91.658413,36.857609],[-91.646926,36.766092],[-91.625854,36.765876],[-91.5285,36.762216],[-91.333915,36.793988],[-91.349174,37.078629]]]},"properties":{"id":"65438","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.971979,36.498391],[-91.166956,36.627329],[-91.172636,36.614785],[-91.193749,36.537648],[-91.172371,36.497515],[-90.971979,36.498391]]]},"properties":{"id":"63942","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.566119,36.57879],[-92.622147,36.515485],[-92.617338,36.498199],[-92.55984,36.4983],[-92.566119,36.57879]]]},"properties":{"id":"65729","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.029258,37.602542],[-91.882374,37.599502],[-91.938922,37.644807],[-91.987748,37.661861],[-92.029258,37.602542]]]},"properties":{"id":"65436","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.070985,37.657567],[-92.042918,37.602804],[-92.029258,37.602542],[-91.987748,37.661861],[-92.025273,37.763086],[-92.066984,37.707987],[-92.070985,37.657567]]]},"properties":{"id":"65461","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.95653,39.527335],[-94.979239,39.436839],[-94.88136,39.370383],[-94.810881,39.469246],[-94.872439,39.532021],[-94.95653,39.527335]]]},"properties":{"id":"64098","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.658288,39.246848],[-94.658801,39.239669],[-94.66256,39.181432],[-94.601463,39.181766],[-94.600977,39.246375],[-94.658288,39.246848]]]},"properties":{"id":"64151","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.601463,39.181766],[-94.66256,39.181432],[-94.659571,39.174674],[-94.601733,39.159603],[-94.60146,39.180141],[-94.601463,39.181766]]]},"properties":{"id":"64150","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.281528,37.725067],[-93.264415,37.486698],[-93.20335,37.483066],[-93.230372,37.713487],[-93.189477,37.769604],[-93.254838,37.788536],[-93.281528,37.725067]]]},"properties":{"id":"65663","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.40446,39.342527],[-92.412909,39.341472],[-92.409724,39.338964],[-92.40446,39.342527]]]},"properties":{"id":"65278","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.075061,39.42767],[-94.145236,39.366917],[-94.117157,39.31422],[-94.098717,39.304317],[-93.973735,39.452459],[-94.075061,39.42767]]]},"properties":{"id":"64084","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.773915,37.154802],[-90.770348,37.258557],[-90.806177,37.365171],[-90.884507,37.432414],[-90.946481,37.38098],[-90.773915,37.154802]]]},"properties":{"id":"63665","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.91269,38.65136],[-90.964461,38.547545],[-90.895968,38.540589],[-90.816536,38.583414],[-90.75609,38.611318],[-90.91269,38.65136]]]},"properties":{"id":"63332","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.464242,37.765143],[-90.49912,37.868815],[-90.642477,37.897396],[-90.642853,37.868177],[-90.622727,37.824952],[-90.552364,37.729225],[-90.464242,37.765143]],[[-90.598104,37.864161],[-90.57816,37.86396],[-90.578554,37.852816],[-90.605849,37.853767],[-90.598104,37.864161]]]},"properties":{"id":"63601","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.280519,38.726895],[-90.269103,38.702682],[-90.249475,38.706821],[-90.240643,38.723303],[-90.238736,38.770634],[-90.234299,38.785376],[-90.27997,38.771158],[-90.280519,38.726895]]]},"properties":{"id":"63136","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.349783,38.661432],[-90.394252,38.66096],[-90.405373,38.652068],[-90.40105,38.611148],[-90.379739,38.609238],[-90.362946,38.612863],[-90.362258,38.630116],[-90.351008,38.639077],[-90.347971,38.657977],[-90.349783,38.661432]]]},"properties":{"id":"63124","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.347971,38.657977],[-90.300678,38.649418],[-90.296727,38.661932],[-90.328791,38.683567],[-90.347838,38.68307],[-90.349783,38.661432],[-90.347971,38.657977]]]},"properties":{"id":"63130","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.488091,38.682946],[-90.404676,38.676448],[-90.408716,38.696322],[-90.519963,38.737413],[-90.537616,38.688018],[-90.488091,38.682946]]]},"properties":{"id":"63146","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.428935,38.821312],[-90.374893,38.759273],[-90.324852,38.764772],[-90.324215,38.768563],[-90.428935,38.821312]]]},"properties":{"id":"63042","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.596493,40.54745],[-92.582381,40.474744],[-92.434475,40.461244],[-92.394114,40.596251],[-92.559328,40.592909],[-92.596493,40.54745]]]},"properties":{"id":"63548","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.037026,40.418443],[-92.079096,40.560866],[-92.082318,40.602177],[-92.302808,40.598379],[-92.350087,40.374764],[-92.349996,40.353607],[-92.196998,40.373293],[-92.096537,40.390288],[-92.037026,40.418443]]]},"properties":{"id":"63555","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.612693,37.057721],[-89.62237,37.03887],[-89.59447,37.042675],[-89.612693,37.057721]]]},"properties":{"id":"63767","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.840978,39.913431],[-91.859306,39.948513],[-91.952726,39.949426],[-92.063637,39.9513],[-91.867141,39.856051],[-91.842461,39.861356],[-91.840978,39.913431]]]},"properties":{"id":"63434","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.013494,37.007547],[-90.081981,36.89199],[-90.059774,36.876999],[-89.892436,36.831178],[-89.841491,36.952677],[-89.866543,36.962374],[-90.013494,37.007547]]]},"properties":{"id":"63825","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.059818,40.313542],[-92.884305,40.124239],[-92.912107,40.385476],[-93.027525,40.385747],[-93.059818,40.313542]]]},"properties":{"id":"63545","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.929161,36.497922],[-92.932682,36.598208],[-92.955064,36.627147],[-93.033838,36.627042],[-93.014362,36.498091],[-92.98787,36.497887],[-92.993996,36.515935],[-92.980541,36.51778],[-92.967738,36.49793],[-92.929161,36.497922]]]},"properties":{"id":"65627","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.010548,37.209184],[-91.928595,37.140694],[-91.762188,37.168274],[-91.761548,37.203871],[-91.801067,37.224218],[-91.868052,37.239746],[-91.938093,37.229003],[-92.010548,37.209184]]]},"properties":{"id":"65464","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.147622,37.422887],[-92.057582,37.461504],[-92.048652,37.510477],[-92.132344,37.508336],[-92.147622,37.422887]]]},"properties":{"id":"65570","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.740792,38.164041],[-90.777571,38.12349],[-90.747556,38.095877],[-90.683474,38.093191],[-90.737908,38.161154],[-90.740792,38.164041]]]},"properties":{"id":"63030","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.513273,37.061444],[-90.351454,36.983614],[-90.278033,37.069564],[-90.220509,37.167849],[-90.220164,37.190999],[-90.218782,37.25701],[-90.275609,37.283526],[-90.345821,37.214528],[-90.430418,37.165749],[-90.466066,37.165646],[-90.472944,37.117444],[-90.513273,37.061444]]]},"properties":{"id":"63944","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.345821,37.214528],[-90.416781,37.204939],[-90.430418,37.165749],[-90.345821,37.214528]]]},"properties":{"id":"63934","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.286316,40.490849],[-94.295643,40.490761],[-94.296124,40.479908],[-94.281502,40.479973],[-94.286316,40.490849]]]},"properties":{"id":"64420","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.231659,38.65977],[-90.211133,38.679581],[-90.249475,38.706821],[-90.26284,38.673732],[-90.262913,38.672568],[-90.231659,38.65977]]]},"properties":{"id":"63115","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.270885,38.593899],[-90.315733,38.599859],[-90.318926,38.598264],[-90.313205,38.586816],[-90.295356,38.563477],[-90.270885,38.593899]]]},"properties":{"id":"63109","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.244657,38.581008],[-90.270152,38.553049],[-90.270506,38.553014],[-90.257413,38.531852],[-90.221103,38.576798],[-90.244657,38.581008]]]},"properties":{"id":"63111","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.459251,40.09258],[-92.690845,40.038233],[-92.587576,39.957978],[-92.344708,39.953297],[-92.34294,40.037163],[-92.352312,40.095343],[-92.459251,40.09258]]]},"properties":{"id":"63549","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.856372,40.060926],[-92.994403,39.985579],[-93.000468,39.982269],[-92.991392,39.95185],[-92.957117,39.877094],[-92.809974,39.907082],[-92.734429,40.016597],[-92.733642,40.038056],[-92.777681,40.063567],[-92.816078,40.069109],[-92.856372,40.060926]]]},"properties":{"id":"63557","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.881584,39.90162],[-94.997748,39.967303],[-94.993382,39.897906],[-94.881013,39.828922],[-94.881584,39.90162]]]},"properties":{"id":"64421","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.738925,39.886238],[-94.756534,39.821331],[-94.677478,39.820356],[-94.625707,39.81966],[-94.626516,39.878076],[-94.690369,39.907457],[-94.738925,39.886238]]]},"properties":{"id":"64436","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.907376,40.024649],[-94.926984,40.078191],[-95.002736,40.100754],[-95.040561,40.096799],[-95.055109,40.073503],[-94.994261,39.971208],[-94.907376,40.024649]]]},"properties":{"id":"64449","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.657056,40.061035],[-94.65773,40.100954],[-94.709291,40.112292],[-94.766848,40.061757],[-94.756542,40.018905],[-94.700837,40.003435],[-94.657056,40.061035]]]},"properties":{"id":"64480","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.554817,40.26446],[-95.313712,40.262732],[-95.25658,40.262778],[-95.235763,40.364219],[-95.425481,40.394381],[-95.627392,40.353135],[-95.554817,40.26446]]]},"properties":{"id":"64446","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.487694,39.205535],[-92.491481,39.185253],[-92.413133,39.161633],[-92.22926,39.29428],[-92.227188,39.344999],[-92.309335,39.363248],[-92.40446,39.342527],[-92.409724,39.338964],[-92.42065,39.303065],[-92.487694,39.205535]]]},"properties":{"id":"65243","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.739086,39.296256],[-91.798172,39.222199],[-91.713629,39.185991],[-91.739086,39.296256]]]},"properties":{"id":"65280","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.798172,39.222199],[-91.773242,39.089779],[-91.705832,39.149136],[-91.713629,39.185991],[-91.798172,39.222199]]]},"properties":{"id":"65232","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.688753,36.550299],[-93.689356,36.49914],[-93.598679,36.4989],[-93.67234,36.586603],[-93.688753,36.550299]]]},"properties":{"id":"65658","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.689356,36.49914],[-93.688753,36.550299],[-93.838744,36.569316],[-93.834042,36.498854],[-93.689356,36.49914]]]},"properties":{"id":"65641","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.393648,36.949228],[-93.496927,36.935583],[-93.61046,36.966564],[-93.612775,36.834776],[-93.378777,36.908584],[-93.385832,36.939226],[-93.393648,36.949228]]]},"properties":{"id":"65633","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.474063,36.651123],[-93.341308,36.817292],[-93.341049,36.858346],[-93.34747,36.881698],[-93.378777,36.908584],[-93.612775,36.834776],[-93.612929,36.820562],[-93.534882,36.67872],[-93.482359,36.648057],[-93.474063,36.651123]]]},"properties":{"id":"65656","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.068027,36.762259],[-94.066378,36.830228],[-94.14559,36.808611],[-94.157068,36.750971],[-94.068027,36.762259]]]},"properties":{"id":"64842","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.545422,37.259424],[-94.500904,37.389532],[-94.617258,37.439191],[-94.618313,37.194687],[-94.545422,37.259424]]]},"properties":{"id":"64832","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.61705,37.56706],[-94.617258,37.439191],[-94.500904,37.389532],[-94.491819,37.389297],[-94.61705,37.56706]]]},"properties":{"id":"64769","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.084386,38.214674],[-93.867773,38.192154],[-93.866589,38.28551],[-93.978588,38.357201],[-94.088901,38.345378],[-94.084386,38.214674]]]},"properties":{"id":"64770","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.526529,38.563263],[-94.611803,38.580235],[-94.61334,38.406892],[-94.50932,38.426666],[-94.455759,38.474413],[-94.464581,38.576322],[-94.526529,38.563263]]]},"properties":{"id":"64742","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.862306,37.292255],[-89.896465,37.181328],[-89.866721,37.184568],[-89.862306,37.292255]]]},"properties":{"id":"63760","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.901988,37.428711],[-89.862363,37.369974],[-89.806188,37.375483],[-89.759122,37.445337],[-89.833765,37.506701],[-89.861749,37.50613],[-89.901988,37.428711]]]},"properties":{"id":"63766","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.147938,38.847319],[-92.347715,38.834547],[-92.356093,38.791812],[-92.214876,38.708927],[-92.159316,38.787366],[-92.147938,38.847319]]]},"properties":{"id":"65010","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.279851,36.845981],[-90.281214,36.845983],[-90.281186,36.84332],[-90.279851,36.845981]]]},"properties":{"id":"63962","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.079132,38.95302],[-91.807807,38.909954],[-91.809802,38.951096],[-92.07308,38.982619],[-92.079132,38.95302]]]},"properties":{"id":"65262","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.809802,38.951096],[-91.807807,38.909954],[-91.80037,38.824126],[-91.747018,38.808694],[-91.641583,38.86073],[-91.712904,38.960348],[-91.809802,38.951096]]]},"properties":{"id":"63388","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.756579,38.085221],[-92.648054,37.986237],[-92.570114,38.066632],[-92.579063,38.096036],[-92.655844,38.118341],[-92.711646,38.102822],[-92.756579,38.085221]]]},"properties":{"id":"65052","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.833765,37.506701],[-89.759122,37.445337],[-89.660267,37.552148],[-89.664373,37.59073],[-89.685032,37.591281],[-89.734951,37.601558],[-89.77286,37.591255],[-89.793238,37.523392],[-89.833765,37.506701]]]},"properties":{"id":"63769","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.739785,37.200338],[-89.735133,37.193865],[-89.736001,37.20081],[-89.739785,37.200338]]]},"properties":{"id":"63744","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.561539,39.585774],[-93.436335,39.499935],[-93.403242,39.5292],[-93.515411,39.613959],[-93.561539,39.585774]]]},"properties":{"id":"64682","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.206311,38.843712],[-94.372834,38.823018],[-94.400986,38.811708],[-94.403051,38.7662],[-94.37484,38.72899],[-94.240303,38.670111],[-94.226429,38.669728],[-94.226722,38.663982],[-94.205879,38.658201],[-94.148441,38.70047],[-94.158677,38.756745],[-94.166202,38.756842],[-94.166073,38.760677],[-94.16127,38.759976],[-94.119096,38.833167],[-94.206311,38.843712]]]},"properties":{"id":"64080","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.142676,38.451302],[-94.028519,38.528949],[-94.031568,38.56666],[-94.147682,38.450673],[-94.142676,38.451302]]]},"properties":{"id":"64739","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.515072,37.665062],[-93.463562,37.700349],[-93.646614,37.731542],[-93.647512,37.66841],[-93.515072,37.665062]]]},"properties":{"id":"65640","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.976219,39.761423],[-92.957117,39.877094],[-92.991392,39.95185],[-93.050984,39.942369],[-93.150956,39.839105],[-93.167508,39.704504],[-93.149453,39.652898],[-93.027221,39.681897],[-92.976219,39.761423]]]},"properties":{"id":"64628","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.41733,37.080871],[-93.393648,36.949228],[-93.385832,36.939226],[-93.364207,36.966236],[-93.400788,37.068298],[-93.404291,37.083679],[-93.41733,37.080871]]]},"properties":{"id":"65631","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.057651,36.942543],[-92.904623,36.985266],[-92.904208,37.008816],[-92.903494,37.037644],[-92.9657,37.04725],[-92.977954,37.061051],[-93.143162,37.04159],[-93.139713,36.910121],[-93.121874,36.916443],[-93.057651,36.942543]]]},"properties":{"id":"65753","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.341308,36.817292],[-93.474063,36.651123],[-93.398212,36.605168],[-93.388617,36.575247],[-93.348917,36.612723],[-93.282164,36.746226],[-93.277197,36.81655],[-93.341308,36.817292]]]},"properties":{"id":"65737","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.38448,37.16889],[-93.42428,37.16161],[-93.404291,37.083679],[-93.400788,37.068298],[-93.352702,37.094952],[-93.36209,37.13881],[-93.38448,37.16889]]]},"properties":{"id":"65619","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.770654,40.560082],[-91.660885,40.469816],[-91.567055,40.462115],[-91.696339,40.588135],[-91.770654,40.560082]]]},"properties":{"id":"63465","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.553938,39.248004],[-94.548944,39.18083],[-94.493591,39.180577],[-94.475188,39.194837],[-94.492954,39.223538],[-94.507243,39.243195],[-94.547376,39.249032],[-94.553938,39.248004]]]},"properties":{"id":"64119","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.600621,39.307397],[-94.600614,39.307079],[-94.556714,39.311124],[-94.542914,39.31096],[-94.560573,39.332642],[-94.60079,39.33611],[-94.600621,39.307397]]]},"properties":{"id":"64165","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.38789,39.604673],[-94.250863,39.609505],[-94.327331,39.664329],[-94.396443,39.674216],[-94.38789,39.604673]]]},"properties":{"id":"64493","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.449245,38.569381],[-92.458691,38.539989],[-92.343659,38.495242],[-92.348942,38.576573],[-92.449245,38.569381]]]},"properties":{"id":"65053","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.526437,38.944776],[-92.583993,38.89263],[-92.539224,38.845207],[-92.448527,38.845604],[-92.476105,38.904387],[-92.526437,38.944776]]]},"properties":{"id":"65287","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.038146,38.081233],[-91.366988,38.228875],[-91.333588,38.135688],[-91.208015,38.031667],[-91.094779,37.957889],[-91.038146,38.081233]]]},"properties":{"id":"65441","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.936969,37.554061],[-92.704442,37.568783],[-92.86687,37.659014],[-92.936969,37.554061]]]},"properties":{"id":"65722","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.280493,39.979319],[-94.242914,39.876182],[-94.205561,39.847281],[-94.139569,39.960996],[-94.280493,39.979319]]]},"properties":{"id":"64497","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.858815,40.271608],[-93.934869,40.133755],[-93.91087,40.090486],[-93.887158,40.090253],[-93.764169,40.069457],[-93.737235,40.206095],[-93.763183,40.248748],[-93.858815,40.271608]]]},"properties":{"id":"64642","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.433468,39.835264],[-94.319483,39.81946],[-94.242914,39.876182],[-94.280493,39.979319],[-94.294065,40.037931],[-94.294173,40.037938],[-94.510307,39.958796],[-94.528986,39.944108],[-94.433468,39.835264]]]},"properties":{"id":"64469","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.519369,37.139314],[-92.523955,37.028418],[-92.465478,37.017681],[-92.519369,37.139314]]]},"properties":{"id":"65702","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.960856,36.30862],[-90.018306,36.324222],[-90.027951,36.324259],[-90.027967,36.331522],[-90.082259,36.321859],[-90.248516,36.130786],[-90.248599,36.128814],[-90.110582,36.104835],[-90.026213,36.132442],[-89.959586,36.171608],[-89.960856,36.30862]]]},"properties":{"id":"63857","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.049595,36.433553],[-90.029989,36.443865],[-90.040481,36.453783],[-90.049595,36.433553]]]},"properties":{"id":"63847","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.973353,38.47455],[-90.888972,38.399083],[-90.801764,38.438776],[-90.82253,38.483826],[-90.853469,38.509501],[-90.910574,38.497866],[-90.973353,38.47455]]]},"properties":{"id":"63089","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.134052,38.475589],[-91.117237,38.332519],[-91.11223,38.347076],[-91.12461,38.447565],[-91.134052,38.475589]]]},"properties":{"id":"63013","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.640372,38.703792],[-91.653734,38.515844],[-91.627652,38.494558],[-91.546289,38.676627],[-91.640372,38.703792]]]},"properties":{"id":"65061","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.682294,38.508186],[-91.629478,38.466846],[-91.62404,38.466378],[-91.618903,38.467647],[-91.627652,38.494558],[-91.653734,38.515844],[-91.682294,38.508186]]]},"properties":{"id":"65062","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.406041,40.34761],[-94.479542,40.386613],[-94.509619,40.386887],[-94.560135,40.386925],[-94.542115,40.327361],[-94.424416,40.295343],[-94.406041,40.34761]]]},"properties":{"id":"64453","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.23007,37.10357],[-93.22708,37.11542],[-93.226332,37.194701],[-93.276053,37.203128],[-93.278644,37.137122],[-93.297659,37.094451],[-93.278397,37.09385],[-93.23007,37.10357]]]},"properties":{"id":"65804","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.461475,40.265359],[-93.461455,40.192544],[-93.456453,40.054235],[-93.36672,40.118548],[-93.324015,40.197805],[-93.337521,40.252241],[-93.423321,40.265597],[-93.461475,40.265359]]]},"properties":{"id":"64641","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.593229,38.639224],[-93.638062,38.662518],[-93.776937,38.634449],[-93.761807,38.525257],[-93.700631,38.488907],[-93.675869,38.545641],[-93.593229,38.639224]]]},"properties":{"id":"64761","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.487694,39.205535],[-92.614679,39.265773],[-92.73355,39.219563],[-92.807323,39.09387],[-92.779428,39.06914],[-92.591693,39.021671],[-92.508666,39.098318],[-92.491481,39.185253],[-92.487694,39.205535]]]},"properties":{"id":"65248","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.614679,39.265773],[-92.487694,39.205535],[-92.42065,39.303065],[-92.481888,39.372747],[-92.631259,39.352832],[-92.614679,39.265773]]]},"properties":{"id":"65257","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.98265,37.738999],[-91.067869,37.674138],[-91.013604,37.624525],[-90.88085,37.602813],[-90.692016,37.651324],[-90.724324,37.702433],[-90.874134,37.734196],[-90.943583,37.737812],[-90.98265,37.738999]]]},"properties":{"id":"63623","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.102614,37.587757],[-91.060522,37.525658],[-90.977844,37.49483],[-90.881841,37.532027],[-90.88085,37.602813],[-91.013604,37.624525],[-91.102614,37.587757]]]},"properties":{"id":"63625","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.881841,37.532027],[-90.977844,37.49483],[-90.884507,37.432414],[-90.806177,37.365171],[-90.770618,37.513865],[-90.881841,37.532027]]]},"properties":{"id":"63654","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.255787,39.081131],[-94.379616,39.090056],[-94.379097,39.077839],[-94.355865,39.030899],[-94.270813,39.052824],[-94.249609,39.05201],[-94.245197,39.066289],[-94.255787,39.081131]]]},"properties":{"id":"64057","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.383044,39.019503],[-94.389196,38.98349],[-94.386154,38.958149],[-94.261346,38.945969],[-94.261202,38.957383],[-94.274492,38.965714],[-94.360626,39.026159],[-94.383044,39.019503]]]},"properties":{"id":"64064","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.404393,38.925964],[-94.376746,38.903082],[-94.329854,38.901726],[-94.404393,38.925964]]]},"properties":{"id":"64063","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.404393,38.925964],[-94.329854,38.901726],[-94.341841,38.865594],[-94.211258,38.856769],[-94.203209,38.920209],[-94.220541,38.916072],[-94.261346,38.945969],[-94.386154,38.958149],[-94.401952,38.962251],[-94.409139,38.944243],[-94.404393,38.925964]]]},"properties":{"id":"64086","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.514419,39.101285],[-94.477813,39.099297],[-94.489418,39.118706],[-94.501659,39.109704],[-94.513143,39.10499],[-94.518438,39.101438],[-94.514419,39.101285]]]},"properties":{"id":"64125","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.470183,39.081314],[-94.474714,39.06534],[-94.468127,39.058191],[-94.447553,39.049464],[-94.429866,39.079706],[-94.438005,39.094594],[-94.450085,39.097535],[-94.477447,39.092355],[-94.470183,39.081314]]]},"properties":{"id":"64052","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.417808,38.984751],[-94.416372,38.941056],[-94.409139,38.944243],[-94.414421,38.955601],[-94.401952,38.962251],[-94.386154,38.958149],[-94.389196,38.98349],[-94.412689,38.990687],[-94.417808,38.984751]]]},"properties":{"id":"64139","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.132071,37.195467],[-94.132271,37.18814],[-94.127743,37.188103],[-94.127422,37.199482],[-94.132071,37.195467]]]},"properties":{"id":"64833","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.630684,38.317681],[-90.67805,38.18428],[-90.52844,38.217833],[-90.462277,38.262111],[-90.471518,38.297942],[-90.517344,38.347933],[-90.555533,38.351012],[-90.630684,38.317681]]]},"properties":{"id":"63050","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.407188,38.343723],[-90.4011,38.34318],[-90.401229,38.345075],[-90.407188,38.343723]]]},"properties":{"id":"63057","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.128304,38.677754],[-94.008462,38.566166],[-93.915621,38.600823],[-93.864829,38.647059],[-93.927098,38.874852],[-94.03594,38.915144],[-94.039889,38.875705],[-94.128304,38.677754]]]},"properties":{"id":"64040","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.649611,39.028945],[-93.653552,39.166696],[-93.704213,39.16745],[-93.824324,39.097644],[-93.792884,38.936253],[-93.714067,38.934347],[-93.649611,39.028945]]]},"properties":{"id":"64037","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.492775,39.004865],[-93.394371,39.099847],[-93.484899,39.161232],[-93.48567,39.146747],[-93.534513,39.049077],[-93.492775,39.004865]]]},"properties":{"id":"65321","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.824324,39.097644],[-93.911172,39.088319],[-93.916921,39.078846],[-93.816661,38.937196],[-93.792884,38.936253],[-93.824324,39.097644]]]},"properties":{"id":"64071","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.915554,37.030488],[-93.915957,37.015979],[-93.898567,37.015524],[-93.897518,37.030103],[-93.915554,37.030488]]]},"properties":{"id":"65654","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.970845,39.115746],[-90.896042,39.178788],[-91.119104,39.179674],[-91.184693,39.167777],[-91.24264,39.096974],[-91.238365,39.084037],[-91.243157,39.080626],[-91.256582,39.071018],[-91.227263,39.034777],[-91.206458,39.039824],[-91.095887,39.017648],[-90.970845,39.115746]]]},"properties":{"id":"63377","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.085049,38.889756],[-91.110303,38.92521],[-91.221421,38.947179],[-91.240038,38.947683],[-91.331243,38.774694],[-91.304056,38.733098],[-91.091841,38.731468],[-91.085049,38.889756]]]},"properties":{"id":"63383","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.814293,38.878508],[-90.669249,38.934159],[-90.663181,38.934224],[-90.674362,38.95783],[-90.812586,38.98307],[-90.826982,38.878964],[-90.814293,38.878508]]]},"properties":{"id":"63369","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.012608,39.186395],[-91.020668,39.184324],[-91.017181,39.182206],[-91.012608,39.186395]]]},"properties":{"id":"63387","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.050984,39.942369],[-93.309802,39.997778],[-93.323974,39.982943],[-93.323962,39.93911],[-93.361422,39.880137],[-93.235461,39.827998],[-93.150956,39.839105],[-93.050984,39.942369]]]},"properties":{"id":"64653","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.313239,36.49938],[-94.155858,36.498713],[-94.141978,36.53779],[-94.162127,36.612017],[-94.264066,36.62419],[-94.424437,36.60423],[-94.42616,36.567518],[-94.313239,36.49938]]]},"properties":{"id":"64856","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.550681,39.656507],[-92.516225,39.837288],[-92.599463,39.87086],[-92.61594,39.652539],[-92.550681,39.656507]]]},"properties":{"id":"63532","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.365234,39.866259],[-92.363962,39.657418],[-92.299287,39.609377],[-92.308334,39.873683],[-92.365234,39.866259]]]},"properties":{"id":"63431","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.700119,39.773304],[-91.60359,39.685608],[-91.367854,39.729136],[-91.439196,39.856567],[-91.612832,39.911791],[-91.687162,39.861336],[-91.700119,39.773304]]]},"properties":{"id":"63461","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.653471,40.499129],[-93.374471,40.471949],[-93.374997,40.500847],[-93.374386,40.580333],[-93.677155,40.578088],[-93.653471,40.499129]]]},"properties":{"id":"64661","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.374997,40.500847],[-93.374471,40.471949],[-93.4432,40.364225],[-93.195764,40.318898],[-93.188369,40.338438],[-93.195674,40.385549],[-93.365888,40.500887],[-93.374997,40.500847]]]},"properties":{"id":"64667","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.319229,36.819663],[-89.175011,36.757772],[-89.38506,37.058012],[-89.449173,37.039978],[-89.466357,36.971981],[-89.410343,36.851165],[-89.319229,36.819663]],[[-89.220711,36.921425],[-89.217294,36.909138],[-89.225347,36.904834],[-89.220711,36.921425]],[[-89.319229,36.819663],[-89.333423,36.819588],[-89.319231,36.832177],[-89.319229,36.819663]]]},"properties":{"id":"63834","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.728865,38.556003],[-92.841364,38.616061],[-92.91691,38.579172],[-92.9285,38.582925],[-92.721404,38.504941],[-92.728865,38.556003]]]},"properties":{"id":"65034","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.668518,38.487116],[-92.803792,38.314556],[-92.775559,38.270195],[-92.691383,38.308346],[-92.625189,38.430778],[-92.615108,38.456155],[-92.655116,38.508682],[-92.668518,38.487116]]]},"properties":{"id":"65011","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.535607,38.819888],[-91.41656,38.803746],[-91.388939,38.844976],[-91.332439,38.950318],[-91.318301,38.9725],[-91.355203,38.992469],[-91.585608,38.836363],[-91.535607,38.819888]]]},"properties":{"id":"63363","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.314257,38.949107],[-91.388939,38.844976],[-91.41656,38.803746],[-91.331243,38.774694],[-91.240038,38.947683],[-91.314257,38.949107]]]},"properties":{"id":"63351","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.032412,38.621405],[-92.99814,38.592394],[-92.99223,38.56694],[-92.94493,38.54598],[-92.9285,38.582925],[-92.91691,38.579172],[-92.9345,38.66],[-93.032412,38.621405]]]},"properties":{"id":"65329","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.583506,36.67133],[-89.574318,36.627042],[-89.555262,36.685974],[-89.583506,36.67133]]]},"properties":{"id":"63860","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.13484,36.939687],[-94.210986,36.810797],[-94.14559,36.808611],[-94.066378,36.830228],[-94.064771,36.876399],[-94.13484,36.939687]]]},"properties":{"id":"64866","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.666675,40.271244],[-94.671623,40.260056],[-94.665496,40.254936],[-94.666675,40.271244]]]},"properties":{"id":"64432","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.91082,38.621459],[-91.81919,38.562347],[-91.682294,38.508186],[-91.653734,38.515844],[-91.640372,38.703792],[-91.710843,38.707583],[-91.736928,38.702345],[-91.881916,38.652713],[-91.91082,38.621459]]]},"properties":{"id":"65024","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.593452,36.622415],[-92.649064,36.586723],[-92.622147,36.515485],[-92.566119,36.57879],[-92.593452,36.622415]]]},"properties":{"id":"65676","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.24653,36.6555],[-92.30238,36.73598],[-92.31935,36.71297],[-92.31437,36.69775],[-92.30271,36.68031],[-92.268197,36.552242],[-92.248283,36.561881],[-92.24653,36.6555]]]},"properties":{"id":"65760","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.248283,36.561881],[-92.268197,36.552242],[-92.258391,36.51906],[-92.22774,36.55244],[-92.248283,36.561881]]]},"properties":{"id":"65766","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.685092,36.219746],[-89.771236,36.155314],[-89.737628,36.00086],[-89.685092,36.219746]]]},"properties":{"id":"63830","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.8063,36.036378],[-89.799698,36.049162],[-89.815327,36.058495],[-89.827106,36.036532],[-89.8063,36.036378]]]},"properties":{"id":"63839","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.965283,37.902357],[-90.111062,37.721921],[-90.092067,37.69582],[-89.837169,37.90232],[-89.965283,37.902357]]]},"properties":{"id":"63673","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.49507,38.869296],[-93.506416,38.679722],[-93.426919,38.719963],[-93.355079,38.805223],[-93.329639,38.848001],[-93.435099,38.870789],[-93.49507,38.869296]]]},"properties":{"id":"65337","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.977067,37.933205],[-91.988183,37.92488],[-92.014547,37.92886],[-91.975258,37.920044],[-91.977067,37.933205]]]},"properties":{"id":"65529","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.456597,39.428702],[-91.406337,39.257353],[-91.301255,39.249971],[-91.301344,39.398863],[-91.457833,39.436034],[-91.456597,39.428702]]]},"properties":{"id":"63339","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.208063,39.43508],[-91.057191,39.3547],[-91.024351,39.439158],[-91.062941,39.481364],[-91.193252,39.545739],[-91.202702,39.521775],[-91.208063,39.43508]]]},"properties":{"id":"63353","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.369531,37.517991],[-93.46076,37.525122],[-93.485469,37.493432],[-93.467367,37.4491],[-93.396267,37.43952],[-93.369531,37.517991]]]},"properties":{"id":"65710","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.159635,37.863056],[-92.180897,37.797572],[-92.096066,37.795852],[-92.071679,37.849653],[-92.076648,37.853072],[-92.159635,37.863056]]]},"properties":{"id":"65584","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.233373,37.710458],[-92.248935,37.635276],[-92.10235,37.646063],[-92.066984,37.707987],[-92.025273,37.763086],[-92.025122,37.770025],[-92.096066,37.795852],[-92.180897,37.797572],[-92.233373,37.710458]]]},"properties":{"id":"65473","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.596493,40.54745],[-92.559328,40.592909],[-92.748277,40.58903],[-92.748505,40.562742],[-92.678967,40.523434],[-92.596493,40.54745]]]},"properties":{"id":"63535","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.280736,39.651475],[-91.262026,39.642712],[-91.273218,39.65431],[-91.280736,39.651475]]]},"properties":{"id":"63467","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.080174,37.405789],[-91.232292,37.24789],[-91.168492,37.132815],[-91.10269,37.086665],[-90.889522,37.089873],[-90.773915,37.154802],[-90.946481,37.38098],[-91.066671,37.382053],[-91.080174,37.405789]]]},"properties":{"id":"63638","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.56503,38.79352],[-90.482455,38.764013],[-90.482299,38.764348],[-90.476577,38.780716],[-90.440617,38.82765],[-90.350337,38.840253],[-90.289787,38.882838],[-90.32566,38.90922],[-90.434405,38.965745],[-90.663181,38.934224],[-90.669249,38.934159],[-90.6191,38.85912],[-90.56503,38.79352]]]},"properties":{"id":"63301","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.32566,38.90922],[-90.289787,38.882838],[-90.234407,38.842003],[-90.117707,38.805748],[-90.32358,38.929653],[-90.32566,38.90922]]]},"properties":{"id":"63386","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.313153,38.73045],[-90.333282,38.720763],[-90.322523,38.695954],[-90.279828,38.683274],[-90.269103,38.702682],[-90.280519,38.726895],[-90.313153,38.73045]]]},"properties":{"id":"63121","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.41312,38.504727],[-90.415011,38.462959],[-90.376841,38.452861],[-90.33862,38.503549],[-90.361469,38.525204],[-90.380811,38.529418],[-90.41312,38.504727]]]},"properties":{"id":"63128","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.397862,38.561725],[-90.433838,38.543431],[-90.41312,38.504727],[-90.380811,38.529418],[-90.397862,38.561725]]]},"properties":{"id":"63127","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.774786,37.095687],[-89.744157,37.082039],[-89.748625,37.11129],[-89.774786,37.095687]]]},"properties":{"id":"63774","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.841491,36.952677],[-89.892436,36.831178],[-89.871923,36.700642],[-89.729075,36.74389],[-89.700145,36.742915],[-89.701091,36.757352],[-89.741601,36.889002],[-89.841491,36.952677]]]},"properties":{"id":"63846","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.871923,36.700642],[-90.138998,36.694234],[-90.139678,36.694414],[-90.144642,36.673111],[-90.140719,36.640935],[-90.148398,36.630656],[-90.059225,36.630313],[-89.958786,36.629331],[-89.871923,36.700642]]]},"properties":{"id":"63822","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.34747,36.881698],[-93.341049,36.858346],[-93.340732,36.8733],[-93.34747,36.881698]]]},"properties":{"id":"65728","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.851949,36.660051],[-92.949632,36.65191],[-92.955064,36.627147],[-92.932682,36.598208],[-92.836512,36.595466],[-92.849268,36.66108],[-92.851949,36.660051]]]},"properties":{"id":"65744","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.209876,36.498269],[-93.313648,36.557583],[-93.315332,36.49814],[-93.209876,36.498269]]]},"properties":{"id":"65739","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.172508,36.627094],[-93.151151,36.498015],[-93.014362,36.498091],[-93.033838,36.627042],[-93.065611,36.651507],[-93.109032,36.654582],[-93.134911,36.654831],[-93.139231,36.65575],[-93.172508,36.627094]]]},"properties":{"id":"65679","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.009383,37.241709],[-91.938093,37.229003],[-91.868052,37.239746],[-91.848174,37.280457],[-91.86445,37.357311],[-91.946631,37.429949],[-92.088027,37.312401],[-92.009383,37.241709]]]},"properties":{"id":"65483","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.132344,37.508336],[-92.048652,37.510477],[-92.045694,37.51558],[-92.132344,37.508336]]]},"properties":{"id":"65557","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.17386,37.845822],[-94.321589,37.972967],[-94.471462,37.853703],[-94.511469,37.820565],[-94.403373,37.725478],[-94.394963,37.700033],[-94.307798,37.728864],[-94.181739,37.802361],[-94.17386,37.845822]]]},"properties":{"id":"64772","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.617458,37.786439],[-94.617698,37.653578],[-94.400596,37.649321],[-94.389973,37.649302],[-94.394963,37.700033],[-94.403373,37.725478],[-94.528072,37.769031],[-94.617458,37.786439]]]},"properties":{"id":"64728","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.614111,37.973797],[-94.616273,37.851651],[-94.471462,37.853703],[-94.321589,37.972967],[-94.319808,37.999748],[-94.484902,37.984807],[-94.614111,37.973797]]]},"properties":{"id":"64778","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.447582,37.996477],[-94.441831,37.996343],[-94.443064,37.998347],[-94.447488,37.998437],[-94.447582,37.996477]]]},"properties":{"id":"64765","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.588557,37.244529],[-90.472944,37.117444],[-90.466066,37.165646],[-90.452965,37.316226],[-90.588557,37.244529]]]},"properties":{"id":"63956","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.494985,37.433473],[-92.635014,37.34963],[-92.691254,37.307183],[-92.688217,37.263438],[-92.664154,37.219436],[-92.492313,37.19449],[-92.383046,37.229268],[-92.403447,37.430886],[-92.494985,37.433473]]]},"properties":{"id":"65667","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.244657,38.581008],[-90.241854,38.604986],[-90.26865,38.607793],[-90.270885,38.593899],[-90.295356,38.563477],[-90.270152,38.553049],[-90.244657,38.581008]]]},"properties":{"id":"63116","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.240643,38.723303],[-90.249475,38.706821],[-90.211133,38.679581],[-90.193545,38.652525],[-90.18145,38.659668],[-90.21141,38.72135],[-90.240643,38.723303]]]},"properties":{"id":"63147","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.686283,40.314226],[-92.777681,40.063567],[-92.733642,40.038056],[-92.690845,40.038233],[-92.459251,40.09258],[-92.440443,40.109964],[-92.460385,40.280301],[-92.686283,40.314226]]]},"properties":{"id":"63501","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.907376,40.024649],[-94.756542,40.018905],[-94.766848,40.061757],[-94.926984,40.078191],[-94.907376,40.024649]]]},"properties":{"id":"64483","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.625707,39.81966],[-94.602494,39.780765],[-94.517747,39.841982],[-94.565866,39.907784],[-94.604142,39.892697],[-94.626516,39.878076],[-94.625707,39.81966]]]},"properties":{"id":"64430","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.76784,39.793391],[-94.836137,39.780924],[-94.806044,39.762471],[-94.76784,39.793391]]]},"properties":{"id":"64506","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.069923,40.510805],[-95.196509,40.479117],[-95.235763,40.364219],[-95.006511,40.405381],[-94.929435,40.447529],[-94.916566,40.49009],[-94.935611,40.490598],[-95.069923,40.510805]]]},"properties":{"id":"64428","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.907459,36.749899],[-93.979092,36.727242],[-93.923694,36.657437],[-93.884961,36.587058],[-93.838744,36.569316],[-93.688753,36.550299],[-93.67234,36.586603],[-93.68512,36.670186],[-93.765281,36.812964],[-93.802115,36.79579],[-93.907459,36.749899]],[[-93.907459,36.749899],[-93.905488,36.749876],[-93.905176,36.747803],[-93.907517,36.747807],[-93.907459,36.749899]]]},"properties":{"id":"65625","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.067145,36.74774],[-94.041118,36.761214],[-94.068027,36.762259],[-94.067145,36.74774]]]},"properties":{"id":"64874","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.907459,36.749899],[-93.907517,36.747807],[-93.905176,36.747803],[-93.905488,36.749876],[-93.907459,36.749899]]]},"properties":{"id":"65623","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.175313,38.121647],[-93.89242,38.058856],[-93.868816,38.112517],[-93.850953,38.169934],[-93.867773,38.192154],[-94.084386,38.214674],[-94.175313,38.121647]]]},"properties":{"id":"64724","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.498449,38.354198],[-94.612724,38.31721],[-94.61265,38.218569],[-94.580693,38.197945],[-94.543791,38.226435],[-94.457465,38.338675],[-94.498449,38.354198]]]},"properties":{"id":"64722","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.36671,38.479469],[-93.095922,38.315958],[-93.067294,38.529995],[-93.24269,38.534921],[-93.290454,38.535388],[-93.36671,38.479469]]]},"properties":{"id":"65325","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.901746,38.462168],[-93.978588,38.357201],[-93.866589,38.28551],[-93.521653,38.248351],[-93.51632,38.359991],[-93.515785,38.371152],[-93.700631,38.488907],[-93.761807,38.525257],[-93.845371,38.499921],[-93.901746,38.462168]]]},"properties":{"id":"64735","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.069264,37.465286],[-89.935796,37.458903],[-90.028666,37.598114],[-90.146763,37.597434],[-90.140115,37.517588],[-90.069264,37.465286]]]},"properties":{"id":"63662","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.347715,38.834547],[-92.33363,38.96901],[-92.461241,38.972519],[-92.476105,38.904387],[-92.448527,38.845604],[-92.394866,38.73913],[-92.360216,38.730133],[-92.356093,38.791812],[-92.347715,38.834547]]]},"properties":{"id":"65203","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.461241,38.972519],[-92.474223,39.069138],[-92.508666,39.098318],[-92.591693,39.021671],[-92.558086,38.970692],[-92.526437,38.944776],[-92.476105,38.904387],[-92.461241,38.972519]]]},"properties":{"id":"65279","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.332589,39.125116],[-92.16784,39.04409],[-92.258649,39.156925],[-92.332589,39.125116]]]},"properties":{"id":"65255","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.356093,38.791812],[-92.360216,38.730133],[-92.352805,38.677445],[-92.220661,38.643167],[-92.191491,38.622246],[-92.181766,38.683218],[-92.214876,38.708927],[-92.356093,38.791812]]]},"properties":{"id":"65039","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.993745,39.676565],[-94.974232,39.618339],[-94.880328,39.63963],[-94.860991,39.66119],[-94.824647,39.711911],[-94.854632,39.733807],[-94.860519,39.74914],[-94.993745,39.676565]]]},"properties":{"id":"64504","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.689858,39.422253],[-94.658371,39.406824],[-94.600819,39.456155],[-94.601177,39.526429],[-94.658915,39.545832],[-94.662263,39.486787],[-94.689858,39.422253]]]},"properties":{"id":"64444","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.327331,39.664329],[-94.250863,39.609505],[-94.170765,39.613926],[-94.111944,39.683377],[-94.120971,39.822808],[-94.205561,39.847281],[-94.242914,39.876182],[-94.319483,39.81946],[-94.327331,39.664329]]]},"properties":{"id":"64429","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.191491,38.622246],[-92.037909,38.562261],[-92.031421,38.660017],[-92.181766,38.683218],[-92.191491,38.622246]]]},"properties":{"id":"65043","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.846111,38.751052],[-91.80037,38.824126],[-91.807807,38.909954],[-92.079132,38.95302],[-92.095297,38.953349],[-92.147938,38.847319],[-92.159316,38.787366],[-91.94938,38.706989],[-91.949292,38.706982],[-91.846111,38.751052]]]},"properties":{"id":"65251","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.94935,38.074315],[-92.88056,38.163657],[-92.937823,38.225223],[-93.016893,38.207799],[-93.065199,38.062479],[-93.067462,38.018327],[-92.94935,38.074315]]]},"properties":{"id":"65324","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.842251,39.409549],[-93.840147,39.215467],[-93.758463,39.207021],[-93.842251,39.409549]]]},"properties":{"id":"64035","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.211258,38.856769],[-94.341841,38.865594],[-94.348628,38.866542],[-94.372834,38.823018],[-94.206311,38.843712],[-94.211258,38.856769]]]},"properties":{"id":"64034","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.967683,37.579674],[-93.945211,37.579329],[-93.773585,37.57663],[-93.741509,37.629421],[-93.647512,37.66841],[-93.646614,37.731542],[-93.738248,37.831943],[-93.75157,37.879949],[-93.808664,37.887961],[-93.965513,37.692601],[-93.967683,37.579674]]]},"properties":{"id":"65785","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.746439,37.459182],[-93.684905,37.443171],[-93.636848,37.50016],[-93.709705,37.596647],[-93.741509,37.629421],[-93.773585,37.57663],[-93.789227,37.549722],[-93.746439,37.459182]]]},"properties":{"id":"65635","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.009756,39.436513],[-93.01136,39.313232],[-92.958148,39.311142],[-92.955457,39.347446],[-93.009756,39.436513]]]},"properties":{"id":"65246","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.782697,39.341509],[-92.939186,39.332791],[-92.955457,39.347446],[-92.958148,39.311142],[-92.957986,39.288063],[-92.905391,39.161951],[-92.945302,39.111195],[-92.917169,39.074041],[-92.807323,39.09387],[-92.73355,39.219563],[-92.782697,39.341509]]]},"properties":{"id":"65254","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.150538,39.638214],[-92.991982,39.615307],[-93.027221,39.681897],[-93.149453,39.652898],[-93.150538,39.638214]]]},"properties":{"id":"64676","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.214811,36.858589],[-93.329179,36.962921],[-93.364207,36.966236],[-93.385832,36.939226],[-93.378777,36.908584],[-93.34747,36.881698],[-93.340732,36.8733],[-93.227985,36.863073],[-93.214811,36.858589]]]},"properties":{"id":"65669","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.832888,40.557513],[-91.869359,40.415717],[-91.836508,40.327923],[-91.676276,40.252086],[-91.596005,40.346139],[-91.572855,40.394237],[-91.593431,40.394823],[-91.590491,40.412883],[-91.660885,40.469816],[-91.770654,40.560082],[-91.832888,40.557513]]]},"properties":{"id":"63445","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.607427,40.075714],[-91.497284,40.077052],[-91.490901,40.283535],[-91.596005,40.346139],[-91.676276,40.252086],[-91.664723,40.226287],[-91.671233,40.088762],[-91.607427,40.075714]]]},"properties":{"id":"63435","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.600977,39.246375],[-94.553938,39.248004],[-94.547376,39.249032],[-94.556714,39.311124],[-94.600614,39.307079],[-94.600977,39.246375]]]},"properties":{"id":"64155","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.60146,39.180141],[-94.548958,39.179774],[-94.548944,39.18083],[-94.553938,39.248004],[-94.600977,39.246375],[-94.601463,39.181766],[-94.60146,39.180141]]]},"properties":{"id":"64118","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.498387,39.15046],[-94.46723,39.124304],[-94.444292,39.129007],[-94.439262,39.13177],[-94.401575,39.166823],[-94.408628,39.193603],[-94.475188,39.194837],[-94.493591,39.180577],[-94.498387,39.15046]]]},"properties":{"id":"64161","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.547376,39.249032],[-94.507243,39.243195],[-94.507276,39.243436],[-94.491761,39.310042],[-94.491761,39.310231],[-94.542914,39.31096],[-94.556714,39.311124],[-94.547376,39.249032]]]},"properties":{"id":"64156","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.600819,39.456155],[-94.480757,39.417865],[-94.449719,39.41013],[-94.449176,39.462741],[-94.528277,39.539871],[-94.601177,39.526429],[-94.600819,39.456155]]]},"properties":{"id":"64492","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.583993,38.89263],[-92.66957,38.82258],[-92.636949,38.758552],[-92.588509,38.751271],[-92.539224,38.845207],[-92.583993,38.89263]]]},"properties":{"id":"65068","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.841364,38.616061],[-92.728865,38.556003],[-92.703914,38.587971],[-92.734596,38.733626],[-92.83947,38.691848],[-92.841364,38.616061]]]},"properties":{"id":"65081","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.208015,38.031667],[-91.333588,38.135688],[-91.304672,38.041167],[-91.208015,38.031667]]]},"properties":{"id":"65535","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.526223,37.970853],[-91.304672,38.041167],[-91.333588,38.135688],[-91.366988,38.228875],[-91.36648,38.263811],[-91.534384,38.152593],[-91.526223,37.970853]]]},"properties":{"id":"65453","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.41728,37.85692],[-91.446174,37.848442],[-91.43707,37.82974],[-91.41728,37.85692]]]},"properties":{"id":"65586","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.746439,37.459182],[-93.793746,37.372811],[-93.766973,37.284979],[-93.689087,37.192119],[-93.622785,37.38373],[-93.684905,37.443171],[-93.746439,37.459182]]]},"properties":{"id":"65646","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.860887,37.389156],[-93.793746,37.372811],[-93.746439,37.459182],[-93.789227,37.549722],[-93.913688,37.520827],[-93.860887,37.389156]]]},"properties":{"id":"65661","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.189477,37.769604],[-93.230372,37.713487],[-93.087654,37.793139],[-93.189477,37.769604]]]},"properties":{"id":"65685","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.27081,37.35351],[-93.185369,37.318893],[-93.075004,37.356916],[-93.054911,37.414657],[-93.089821,37.491366],[-93.22327,37.445066],[-93.27081,37.35351]]]},"properties":{"id":"65648","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.992968,37.742933],[-92.956074,37.692399],[-92.890173,37.684127],[-92.900789,37.733194],[-92.992968,37.742933]]]},"properties":{"id":"65783","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.280493,39.979319],[-94.139569,39.960996],[-94.130509,39.931667],[-94.103636,39.959885],[-94.072258,39.988316],[-93.99057,40.068895],[-94.028581,40.13401],[-94.084925,40.15656],[-94.294065,40.037931],[-94.280493,39.979319]]]},"properties":{"id":"64670","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.759798,39.847267],[-93.797371,39.844806],[-93.759145,39.808879],[-93.759798,39.847267]]]},"properties":{"id":"64654","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.433468,39.835264],[-94.528986,39.944108],[-94.565866,39.907784],[-94.517747,39.841982],[-94.433469,39.835186],[-94.433468,39.835264]]]},"properties":{"id":"64422","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.569826,37.421359],[-91.529232,37.420491],[-91.536782,37.491983],[-91.57106,37.486781],[-91.612053,37.422023],[-91.569826,37.421359]]]},"properties":{"id":"65501","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.343681,36.944866],[-92.263966,36.854728],[-92.186623,36.851546],[-92.188513,36.883239],[-92.188672,36.912432],[-92.198935,36.970887],[-92.318183,36.981375],[-92.343681,36.944866]]]},"properties":{"id":"65768","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.465478,37.017681],[-92.523955,37.028418],[-92.519369,37.139314],[-92.492313,37.19449],[-92.664154,37.219436],[-92.700964,37.042084],[-92.468183,36.999272],[-92.465478,37.017681]]]},"properties":{"id":"65704","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.027967,36.331522],[-90.027951,36.324259],[-90.018306,36.324222],[-90.027967,36.331522]]]},"properties":{"id":"63880","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.059225,36.630313],[-90.017254,36.498091],[-89.959928,36.498126],[-89.866836,36.523943],[-89.958786,36.629331],[-90.059225,36.630313]]]},"properties":{"id":"63863","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.859677,38.3436],[-90.7802,38.306355],[-90.755996,38.302768],[-90.750222,38.355078],[-90.801137,38.429314],[-90.801764,38.438776],[-90.888972,38.399083],[-90.889564,38.389829],[-90.859677,38.3436]]]},"properties":{"id":"63072","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.322026,40.109787],[-94.216535,40.194731],[-94.25833,40.319747],[-94.34268,40.386672],[-94.404392,40.38654],[-94.406041,40.34761],[-94.424416,40.295343],[-94.444528,40.216075],[-94.350336,40.095684],[-94.322026,40.109787]]]},"properties":{"id":"64402","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.124038,37.195032],[-93.003039,37.262986],[-93.075004,37.356916],[-93.185369,37.318893],[-93.183597,37.240227],[-93.124038,37.195032]]]},"properties":{"id":"65757","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.361855,39.982174],[-93.36672,40.118548],[-93.456453,40.054235],[-93.502245,39.994295],[-93.361855,39.982174]]]},"properties":{"id":"64652","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.746012,38.981122],[-92.779428,39.06914],[-92.807323,39.09387],[-92.917169,39.074041],[-92.934569,39.064547],[-92.847561,38.980943],[-92.746012,38.981122]]]},"properties":{"id":"65250","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.022121,36.836364],[-91.767267,36.890625],[-91.87484,36.945895],[-92.022121,36.836364]]]},"properties":{"id":"65789","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.259457,36.498048],[-92.09216,36.498673],[-92.22774,36.55244],[-92.258391,36.51906],[-92.259457,36.498048]]]},"properties":{"id":"65609","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.798793,37.798513],[-90.874134,37.734196],[-90.724324,37.702433],[-90.705528,37.688044],[-90.734593,37.779614],[-90.773847,37.811282],[-90.798793,37.798513]]]},"properties":{"id":"63631","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.561708,39.077401],[-94.580043,39.070835],[-94.58,39.056349],[-94.561743,39.055711],[-94.554704,39.055477],[-94.55363,39.077135],[-94.561708,39.077401]]]},"properties":{"id":"64109","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.607281,39.108901],[-94.607205,39.100132],[-94.596457,39.097125],[-94.607281,39.108901]]]},"properties":{"id":"64101","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.274492,38.965714],[-94.261202,38.957383],[-94.249609,39.05201],[-94.270813,39.052824],[-94.274492,38.965714]]]},"properties":{"id":"64014","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.560828,38.910095],[-94.557239,38.866764],[-94.529564,38.843652],[-94.49213,38.841707],[-94.48902,38.899884],[-94.523868,38.909368],[-94.560828,38.910095]]]},"properties":{"id":"64030","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.401952,38.962251],[-94.414421,38.955601],[-94.409139,38.944243],[-94.401952,38.962251]]]},"properties":{"id":"64065","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.400173,37.215108],[-94.429032,37.17878],[-94.386428,37.075284],[-94.368415,37.08105],[-94.267872,37.053063],[-94.210536,37.080863],[-94.143531,37.24641],[-94.142862,37.268591],[-94.39968,37.238392],[-94.400173,37.215108]]]},"properties":{"id":"64836","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.44008,37.257007],[-94.448685,37.260692],[-94.448828,37.253594],[-94.444552,37.24978],[-94.44008,37.257007]]]},"properties":{"id":"64849","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.366948,38.248344],[-90.38862,38.21849],[-90.35543,38.216306],[-90.366948,38.248344]]]},"properties":{"id":"63019","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.370819,38.333554],[-90.50125,38.37206],[-90.517344,38.347933],[-90.471518,38.297942],[-90.372666,38.293594],[-90.370819,38.333554]],[[-90.407188,38.343723],[-90.401229,38.345075],[-90.4011,38.34318],[-90.407188,38.343723]]]},"properties":{"id":"63012","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.574426,38.457899],[-90.652163,38.431475],[-90.702903,38.406578],[-90.703616,38.386097],[-90.555533,38.351012],[-90.517344,38.347933],[-90.50125,38.37206],[-90.508164,38.420283],[-90.574426,38.457899]]]},"properties":{"id":"63051","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.610152,38.540724],[-90.676629,38.527598],[-90.652163,38.431475],[-90.574426,38.457899],[-90.529711,38.530421],[-90.534422,38.540553],[-90.610152,38.540724]]]},"properties":{"id":"63025","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.196998,40.373293],[-92.126084,40.296144],[-92.064885,40.216108],[-91.950812,40.25721],[-91.949747,40.300832],[-92.096537,40.390288],[-92.196998,40.373293]]]},"properties":{"id":"63563","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.60669,37.20413],[-93.626505,37.189824],[-93.60695,37.19307],[-93.60669,37.20413]]]},"properties":{"id":"65664","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.497284,40.077052],[-91.607427,40.075714],[-91.663478,40.048785],[-91.602421,39.985359],[-91.560309,39.946634],[-91.488061,40.024453],[-91.497284,40.077052]]]},"properties":{"id":"63448","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.879396,39.043696],[-90.859427,38.982389],[-90.812586,38.98307],[-90.674362,38.95783],[-90.685046,39.001708],[-90.879396,39.043696]]]},"properties":{"id":"63389","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.318301,38.9725],[-91.332439,38.950318],[-91.314257,38.949107],[-91.240038,38.947683],[-91.221421,38.947179],[-91.206458,39.039824],[-91.227263,39.034777],[-91.318301,38.9725]]]},"properties":{"id":"63381","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.266968,39.703562],[-93.361422,39.880137],[-93.323962,39.93911],[-93.447134,39.879113],[-93.402612,39.716016],[-93.285892,39.666819],[-93.266968,39.703562]]]},"properties":{"id":"64688","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.593629,39.701972],[-93.497017,39.614167],[-93.402612,39.716016],[-93.447134,39.879113],[-93.540841,39.921264],[-93.702364,39.960698],[-93.759798,39.847267],[-93.759145,39.808879],[-93.629877,39.762899],[-93.613171,39.764078],[-93.593629,39.701972]]]},"properties":{"id":"64601","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.591801,36.707697],[-94.618077,36.667921],[-94.617709,36.624745],[-94.550437,36.583616],[-94.472581,36.616329],[-94.424437,36.60423],[-94.264066,36.62419],[-94.269052,36.633114],[-94.312092,36.705325],[-94.591801,36.707697]]]},"properties":{"id":"64831","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.363962,39.657418],[-92.550681,39.656507],[-92.61594,39.652539],[-92.62102,39.609285],[-92.531626,39.608252],[-92.299316,39.605344],[-92.299287,39.609377],[-92.363962,39.657418]]]},"properties":{"id":"65247","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.688133,38.223986],[-92.691383,38.308346],[-92.775559,38.270195],[-92.736675,38.220733],[-92.688133,38.223986]]]},"properties":{"id":"65072","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.319229,36.819663],[-89.410343,36.851165],[-89.483201,36.832764],[-89.48327,36.818111],[-89.445994,36.673919],[-89.327588,36.632276],[-89.175011,36.757772],[-89.319229,36.819663]]]},"properties":{"id":"63845","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.332439,38.950318],[-91.388939,38.844976],[-91.314257,38.949107],[-91.332439,38.950318]]]},"properties":{"id":"63350","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.58148,36.533186],[-89.626003,36.540849],[-89.790491,36.494114],[-89.879629,36.386587],[-89.862416,36.386583],[-89.75528,36.342541],[-89.620166,36.322805],[-89.564621,36.533006],[-89.58148,36.533186]]]},"properties":{"id":"63873","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.741601,36.889002],[-89.701091,36.757352],[-89.683331,36.757781],[-89.48327,36.818111],[-89.483201,36.832764],[-89.466357,36.971981],[-89.449173,37.039978],[-89.526032,37.008464],[-89.523268,37.006672],[-89.526797,37.002781],[-89.529026,37.005079],[-89.556229,37.039284],[-89.687027,37.00524],[-89.686874,36.994415],[-89.695994,36.976002],[-89.741601,36.889002]],[[-89.686358,36.84515],[-89.70417,36.83932],[-89.704833,36.859285],[-89.687303,36.859586],[-89.686358,36.84515]]]},"properties":{"id":"63801","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.555262,36.685974],[-89.445994,36.673919],[-89.48327,36.818111],[-89.683331,36.757781],[-89.682729,36.743343],[-89.666004,36.67195],[-89.583506,36.67133],[-89.555262,36.685974]]]},"properties":{"id":"63867","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.306699,36.951256],[-94.243446,36.792665],[-94.210986,36.810797],[-94.13484,36.939687],[-94.1876,37.007463],[-94.247421,37.023502],[-94.306699,36.951256]]]},"properties":{"id":"64844","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.528556,36.905977],[-94.537386,36.902878],[-94.533356,36.895186],[-94.528556,36.905977]]]},"properties":{"id":"64858","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.668779,40.242127],[-94.687648,40.234941],[-94.66894,40.234885],[-94.668779,40.242127]]]},"properties":{"id":"64433","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.971573,40.129426],[-94.746809,40.170356],[-94.749709,40.206626],[-94.819171,40.272723],[-94.930457,40.238389],[-94.934297,40.215814],[-94.971573,40.129426]]]},"properties":{"id":"64423","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.887084,40.574694],[-94.935611,40.490598],[-94.916566,40.49009],[-94.740204,40.459717],[-94.707529,40.487455],[-94.687087,40.571968],[-94.887084,40.574694]]]},"properties":{"id":"64461","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.024851,38.40131],[-92.042872,38.324679],[-92.028067,38.33139],[-92.024851,38.40131]]]},"properties":{"id":"65048","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.62144,36.72489],[-92.53265,36.70578],[-92.52485,36.70382],[-92.5506,36.75838],[-92.62144,36.72489]]]},"properties":{"id":"65715","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.873289,36.05497],[-89.860062,36.07395],[-89.882446,36.0741],[-89.873289,36.05497]]]},"properties":{"id":"63853","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.118049,38.931395],[-93.191787,38.899901],[-93.329639,38.848001],[-93.355079,38.805223],[-93.090166,38.870867],[-93.118049,38.931395]]]},"properties":{"id":"65334","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.070008,39.313897],[-90.899994,39.261691],[-90.868432,39.355578],[-91.024351,39.439158],[-91.057191,39.3547],[-91.070008,39.313897]]]},"properties":{"id":"63336","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.899994,39.261691],[-90.887553,39.234392],[-90.721835,39.224108],[-90.868432,39.355578],[-90.899994,39.261691]]]},"properties":{"id":"63330","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.787611,39.471354],[-94.689858,39.422253],[-94.662263,39.486787],[-94.787611,39.471354]]]},"properties":{"id":"64018","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.254838,37.788536],[-93.275123,37.80442],[-93.299038,37.804925],[-93.281528,37.725067],[-93.254838,37.788536]]]},"properties":{"id":"65727","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.110065,37.494432],[-91.079295,37.405429],[-91.066671,37.382053],[-90.946481,37.38098],[-90.884507,37.432414],[-90.977844,37.49483],[-91.060522,37.525658],[-91.110065,37.494432]]]},"properties":{"id":"63633","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.688042,36.56333],[-90.647231,36.633794],[-90.709133,36.593173],[-90.688042,36.56333]]]},"properties":{"id":"63955","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.77999,38.804171],[-90.73981,38.773262],[-90.697707,38.780162],[-90.6191,38.85912],[-90.669249,38.934159],[-90.814293,38.878508],[-90.77999,38.804171]]]},"properties":{"id":"63366","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.269103,38.702682],[-90.279828,38.683274],[-90.282744,38.679683],[-90.26284,38.673732],[-90.249475,38.706821],[-90.269103,38.702682]]]},"properties":{"id":"63120","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.476188,38.567144],[-90.534422,38.540553],[-90.529711,38.530421],[-90.508077,38.535962],[-90.463552,38.552502],[-90.476188,38.567144]]]},"properties":{"id":"63088","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.38378,38.572122],[-90.379739,38.609238],[-90.40105,38.611148],[-90.461787,38.581193],[-90.476188,38.567144],[-90.463552,38.552502],[-90.433838,38.543431],[-90.397862,38.561725],[-90.38378,38.572122]]]},"properties":{"id":"63122","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.280519,38.726895],[-90.27997,38.771158],[-90.307628,38.772663],[-90.324215,38.768563],[-90.324852,38.764772],[-90.319066,38.745652],[-90.318944,38.731822],[-90.313153,38.73045],[-90.280519,38.726895]]]},"properties":{"id":"63135","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.362258,38.630116],[-90.362946,38.612863],[-90.3344,38.606586],[-90.329814,38.62278],[-90.362258,38.630116]]]},"properties":{"id":"63144","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.623039,38.63097],[-90.585654,38.608557],[-90.571822,38.681052],[-90.732328,38.639302],[-90.732441,38.600684],[-90.623039,38.63097]]]},"properties":{"id":"63005","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.408716,38.696322],[-90.347838,38.68307],[-90.328791,38.683567],[-90.322523,38.695954],[-90.333282,38.720763],[-90.370826,38.724919],[-90.408563,38.710965],[-90.408716,38.696322]]]},"properties":{"id":"63114","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.328791,38.683567],[-90.296727,38.661932],[-90.282744,38.679683],[-90.279828,38.683274],[-90.322523,38.695954],[-90.328791,38.683567]]]},"properties":{"id":"63133","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.455649,38.75461],[-90.407296,38.722293],[-90.371144,38.740081],[-90.374893,38.759273],[-90.428935,38.821312],[-90.440617,38.82765],[-90.476577,38.780716],[-90.455649,38.75461]]]},"properties":{"id":"63044","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.455649,38.75461],[-90.476577,38.780716],[-90.482299,38.764348],[-90.455649,38.75461]]]},"properties":{"id":"63045","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.318944,38.731822],[-90.319066,38.745652],[-90.329028,38.734512],[-90.318944,38.731822]]]},"properties":{"id":"63140","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.942894,39.069546],[-92.952667,39.070806],[-92.951754,39.067319],[-92.942894,39.069546]]]},"properties":{"id":"65320","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.394114,40.596251],[-92.434475,40.461244],[-92.389633,40.39116],[-92.350087,40.374764],[-92.302808,40.598379],[-92.394114,40.596251]]]},"properties":{"id":"63536","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.582381,40.474744],[-92.596493,40.54745],[-92.678967,40.523434],[-92.710239,40.444455],[-92.582381,40.474744]]]},"properties":{"id":"63541","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.686874,36.994415],[-89.700558,36.99046],[-89.695994,36.976002],[-89.686874,36.994415]]]},"properties":{"id":"63784","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.199384,39.830281],[-92.120373,39.95283],[-92.287882,39.95249],[-92.289242,39.894564],[-92.199384,39.830281]]]},"properties":{"id":"63451","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.398212,36.605168],[-93.474063,36.651123],[-93.482359,36.648057],[-93.468922,36.612212],[-93.398212,36.605168]]]},"properties":{"id":"65686","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.313648,36.557583],[-93.209876,36.498269],[-93.151151,36.498015],[-93.172508,36.627094],[-93.313402,36.568027],[-93.313648,36.557583]]]},"properties":{"id":"65672","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.967738,36.49793],[-92.980541,36.51778],[-92.993996,36.515935],[-92.98787,36.497887],[-92.967738,36.49793]]]},"properties":{"id":"72644","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.009383,37.241709],[-92.010548,37.209184],[-91.938093,37.229003],[-92.009383,37.241709]]]},"properties":{"id":"65564","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.170029,37.341849],[-92.25293,37.411899],[-92.253209,37.386537],[-92.215813,37.30579],[-92.207397,37.298453],[-92.124326,37.332527],[-92.170029,37.341849]]]},"properties":{"id":"65484","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.616273,37.851651],[-94.617458,37.786439],[-94.528072,37.769031],[-94.511469,37.820565],[-94.471462,37.853703],[-94.616273,37.851651]]]},"properties":{"id":"64741","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.963741,37.231763],[-92.899344,37.215375],[-92.688217,37.263438],[-92.691254,37.307183],[-92.874297,37.440644],[-92.963198,37.431152],[-93.054911,37.414657],[-93.075004,37.356916],[-93.003039,37.262986],[-92.963741,37.231763]]]},"properties":{"id":"65706","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.187385,38.635854],[-90.196483,38.634427],[-90.198682,38.626623],[-90.187385,38.635854]]]},"properties":{"id":"63101","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.27462,38.645004],[-90.234264,38.633006],[-90.229955,38.641238],[-90.23301,38.643971],[-90.272626,38.657125],[-90.27462,38.645004]]]},"properties":{"id":"63108","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.229955,38.641238],[-90.196483,38.634427],[-90.187385,38.635854],[-90.193545,38.652525],[-90.222054,38.655459],[-90.23301,38.643971],[-90.229955,38.641238]]]},"properties":{"id":"63106","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.881584,39.90162],[-94.738925,39.886238],[-94.690369,39.907457],[-94.69383,39.966567],[-94.700837,40.003435],[-94.756542,40.018905],[-94.907376,40.024649],[-94.994261,39.971208],[-94.997748,39.967303],[-94.881584,39.90162]]]},"properties":{"id":"64485","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.068365,40.202615],[-95.040561,40.096799],[-95.002736,40.100754],[-94.971573,40.129426],[-94.934297,40.215814],[-95.068365,40.202615]]]},"properties":{"id":"64455","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.638208,40.584137],[-95.6591,40.51999],[-95.642028,40.569738],[-95.638208,40.584137]]]},"properties":{"id":"51640","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.16784,39.04409],[-92.120052,39.043167],[-92.091254,39.064297],[-92.024397,39.340332],[-92.094937,39.369861],[-92.227188,39.344999],[-92.22926,39.29428],[-92.258649,39.156925],[-92.16784,39.04409]]]},"properties":{"id":"65240","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.798172,39.222199],[-91.739086,39.296256],[-91.756628,39.338862],[-91.810224,39.338059],[-91.837542,39.37789],[-91.968648,39.339576],[-92.03325,39.063005],[-91.774497,39.060787],[-91.773242,39.089779],[-91.798172,39.222199]]]},"properties":{"id":"65265","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.589178,39.323463],[-91.539646,39.219422],[-91.555221,39.190275],[-91.474895,39.188758],[-91.406337,39.257353],[-91.456597,39.428702],[-91.560928,39.410171],[-91.612872,39.353011],[-91.589178,39.323463]]]},"properties":{"id":"63382","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.589178,39.323463],[-91.612872,39.353011],[-91.756628,39.338862],[-91.739086,39.296256],[-91.713629,39.185991],[-91.705832,39.149136],[-91.629598,39.147853],[-91.555221,39.190275],[-91.539646,39.219422],[-91.610042,39.250765],[-91.589178,39.323463]]]},"properties":{"id":"63352","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.907459,36.749899],[-93.802115,36.79579],[-93.843028,36.806099],[-94.0293,36.858356],[-94.064771,36.876399],[-94.066378,36.830228],[-94.068027,36.762259],[-94.041118,36.761214],[-93.979092,36.727242],[-93.907459,36.749899]]]},"properties":{"id":"65734","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.155858,36.498713],[-93.834042,36.498854],[-93.838744,36.569316],[-93.884961,36.587058],[-94.141978,36.53779],[-94.155858,36.498713]]]},"properties":{"id":"65745","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.534882,36.67872],[-93.612929,36.820562],[-93.649694,36.686298],[-93.534882,36.67872]]]},"properties":{"id":"65624","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.073524,37.639722],[-94.054774,37.675493],[-94.097551,37.713335],[-94.307798,37.728864],[-94.394963,37.700033],[-94.389973,37.649302],[-94.073524,37.639722]]]},"properties":{"id":"64784","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.200409,37.43233],[-94.409555,37.415921],[-94.446868,37.372506],[-94.420319,37.225789],[-94.39968,37.238392],[-94.142862,37.268591],[-94.200409,37.43233]]]},"properties":{"id":"64755","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.028519,38.528949],[-94.142676,38.451302],[-94.178421,38.39082],[-94.088901,38.345378],[-93.978588,38.357201],[-93.901746,38.462168],[-94.028519,38.528949]]]},"properties":{"id":"64788","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.281291,38.529101],[-94.436622,38.572362],[-94.464581,38.576322],[-94.455759,38.474413],[-94.249865,38.455912],[-94.281291,38.529101]]]},"properties":{"id":"64725","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.51632,38.359991],[-93.206599,38.257687],[-93.077692,38.263092],[-93.095922,38.315958],[-93.36671,38.479469],[-93.40341,38.480488],[-93.514634,38.392328],[-93.515785,38.371152],[-93.51632,38.359991]]]},"properties":{"id":"65338","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.365505,38.50841],[-93.40341,38.480488],[-93.36671,38.479469],[-93.290454,38.535388],[-93.336563,38.536631],[-93.365505,38.50841]]]},"properties":{"id":"65335","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.207219,37.173654],[-90.221553,37.12892],[-90.22323,37.086527],[-90.111043,37.046354],[-90.032432,37.06955],[-90.014188,37.098395],[-90.02509,37.108698],[-90.072189,37.152254],[-90.110007,37.204569],[-90.207219,37.173654]]]},"properties":{"id":"63787","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.02509,37.108698],[-90.014188,37.098395],[-90.002303,37.040288],[-90.013494,37.007547],[-89.866543,36.962374],[-89.816302,37.049685],[-89.8164,37.152209],[-89.79953,37.223156],[-89.866721,37.184568],[-89.896465,37.181328],[-90.013756,37.171828],[-90.02509,37.108698]],[[-89.959392,37.094587],[-89.954852,37.081195],[-89.959333,37.079822],[-89.959392,37.094587]]]},"properties":{"id":"63730","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.100241,37.381837],[-90.110007,37.204569],[-90.072189,37.152254],[-90.013756,37.171828],[-89.896465,37.181328],[-89.862306,37.292255],[-89.833585,37.316763],[-89.862363,37.369974],[-89.901988,37.428711],[-89.935796,37.458903],[-90.069264,37.465286],[-90.100241,37.381837]]]},"properties":{"id":"63764","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.110007,37.204569],[-90.100241,37.381837],[-90.218782,37.25701],[-90.220164,37.190999],[-90.207219,37.173654],[-90.110007,37.204569]]]},"properties":{"id":"63751","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.862306,37.292255],[-89.866721,37.184568],[-89.79953,37.223156],[-89.758142,37.235892],[-89.74026,37.304256],[-89.793118,37.33233],[-89.833585,37.316763],[-89.862306,37.292255]]]},"properties":{"id":"63785","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.880328,39.63963],[-94.864293,39.548257],[-94.738218,39.579396],[-94.787841,39.657764],[-94.860991,39.66119],[-94.880328,39.63963]]]},"properties":{"id":"64448","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.465687,36.660176],[-90.566509,36.672546],[-90.574959,36.576822],[-90.465687,36.660176]]]},"properties":{"id":"63945","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.153327,39.526229],[-94.075061,39.42767],[-93.973735,39.452459],[-93.946459,39.467152],[-93.983247,39.597527],[-94.168313,39.595234],[-94.153327,39.526229]]]},"properties":{"id":"64671","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.869957,39.676751],[-93.973111,39.643154],[-93.983247,39.597527],[-93.946459,39.467152],[-93.851897,39.467953],[-93.86089,39.665311],[-93.869957,39.676751]]]},"properties":{"id":"64637","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.043283,39.848993],[-94.093024,39.843864],[-94.120971,39.822808],[-94.111944,39.683377],[-94.101865,39.687661],[-94.022583,39.829274],[-94.043283,39.848993]]]},"properties":{"id":"64649","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.809802,38.951096],[-91.712904,38.960348],[-91.698013,39.014008],[-91.774497,39.060787],[-92.03325,39.063005],[-92.091254,39.064297],[-92.120052,39.043167],[-92.07308,38.982619],[-91.809802,38.951096]]]},"properties":{"id":"65231","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.655844,38.118341],[-92.598788,38.163128],[-92.641314,38.171631],[-92.711646,38.102822],[-92.655844,38.118341]]]},"properties":{"id":"65065","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.764441,38.194313],[-92.860835,38.146131],[-92.851579,38.144467],[-92.756579,38.085221],[-92.711646,38.102822],[-92.641314,38.171631],[-92.764441,38.194313]]]},"properties":{"id":"65079","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.430756,38.839608],[-94.400986,38.811708],[-94.372834,38.823018],[-94.348628,38.866542],[-94.458036,38.913429],[-94.469653,38.900995],[-94.430756,38.839608]]]},"properties":{"id":"64082","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.611803,38.580235],[-94.526529,38.563263],[-94.529539,38.669154],[-94.562967,38.721525],[-94.609504,38.721705],[-94.611803,38.580235]]]},"properties":{"id":"64734","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.709705,37.596647],[-93.523318,37.585416],[-93.515072,37.665062],[-93.647512,37.66841],[-93.741509,37.629421],[-93.709705,37.596647]]]},"properties":{"id":"65649","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.840561,39.66274],[-92.696592,39.610371],[-92.692149,39.610265],[-92.685775,39.860833],[-92.808686,39.856966],[-92.835015,39.69998],[-92.840561,39.66274]]]},"properties":{"id":"63558","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.137909,39.491368],[-93.204897,39.440438],[-93.104485,39.383656],[-93.076948,39.342506],[-93.01136,39.313232],[-93.009756,39.436513],[-92.996662,39.524588],[-93.137909,39.491368]]]},"properties":{"id":"65236","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.163251,37.183686],[-93.22708,37.11542],[-93.23007,37.10357],[-93.143162,37.04159],[-92.977954,37.061051],[-92.963741,37.231763],[-93.003039,37.262986],[-93.124038,37.195032],[-93.163251,37.183686]]]},"properties":{"id":"65742","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.057651,36.942543],[-92.908284,36.852922],[-92.908829,36.824663],[-92.904623,36.985266],[-93.057651,36.942543]]]},"properties":{"id":"65720","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.963741,37.231763],[-92.977954,37.061051],[-92.9657,37.04725],[-92.903441,37.039634],[-92.899344,37.215375],[-92.963741,37.231763]]]},"properties":{"id":"65652","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.676276,40.252086],[-91.836508,40.327923],[-91.884569,40.255852],[-91.845669,40.182457],[-91.769605,40.167073],[-91.664723,40.226287],[-91.676276,40.252086]]]},"properties":{"id":"63473","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.572855,40.394237],[-91.590491,40.412883],[-91.593431,40.394823],[-91.572855,40.394237]]]},"properties":{"id":"63472","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.27634,39.45528],[-94.449719,39.41013],[-94.480757,39.417865],[-94.463846,39.367902],[-94.323481,39.324659],[-94.236225,39.398093],[-94.27634,39.45528]]]},"properties":{"id":"64060","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.318387,38.43026],[-92.349699,38.446512],[-92.406243,38.425406],[-92.447139,38.374189],[-92.440595,38.282923],[-92.315383,38.247984],[-92.291516,38.298567],[-92.318387,38.43026]]]},"properties":{"id":"65032","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.84222,38.842774],[-92.66957,38.82258],[-92.583993,38.89263],[-92.526437,38.944776],[-92.558086,38.970692],[-92.746012,38.981122],[-92.847561,38.980943],[-92.914954,38.924787],[-92.84222,38.842774]]]},"properties":{"id":"65233","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.056921,38.765409],[-93.032412,38.621405],[-92.9345,38.66],[-92.85643,38.699112],[-92.939923,38.758646],[-93.056324,38.780091],[-93.056921,38.765409]]]},"properties":{"id":"65348","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.612053,37.422023],[-91.57106,37.486781],[-91.536782,37.491983],[-91.529232,37.420491],[-91.569826,37.421359],[-91.537514,37.350357],[-91.376654,37.272743],[-91.26545,37.591344],[-91.27627,37.6994],[-91.306321,37.861672],[-91.41728,37.85692],[-91.43707,37.82974],[-91.538924,37.821478],[-91.619361,37.843134],[-91.710045,37.695057],[-91.754795,37.598768],[-91.68052,37.423241],[-91.646626,37.422731],[-91.612053,37.422023]]]},"properties":{"id":"65560","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.077614,37.494058],[-93.981602,37.27526],[-93.882147,37.287114],[-93.860887,37.389156],[-93.913688,37.520827],[-93.945211,37.579329],[-93.967683,37.579674],[-94.076854,37.521707],[-94.077614,37.494058]]]},"properties":{"id":"65682","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.049656,37.810496],[-92.992968,37.742933],[-92.900789,37.733194],[-92.858325,37.81284],[-92.886799,37.896657],[-93.046138,37.890742],[-93.049656,37.810496]]]},"properties":{"id":"65764","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.99057,40.068895],[-93.91087,40.090486],[-93.934869,40.133755],[-94.028581,40.13401],[-93.99057,40.068895]]]},"properties":{"id":"64636","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.732971,37.700316],[-91.809105,37.598863],[-91.754795,37.598768],[-91.710045,37.695057],[-91.732971,37.700316]]]},"properties":{"id":"65541","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.854867,37.773639],[-91.938922,37.644807],[-91.882374,37.599502],[-91.809105,37.598863],[-91.732971,37.700316],[-91.854867,37.773639]]]},"properties":{"id":"65462","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.009383,37.241709],[-92.088027,37.312401],[-92.124326,37.332527],[-92.207397,37.298453],[-92.198935,36.970887],[-92.188672,36.912432],[-91.928595,37.140694],[-92.010548,37.209184],[-92.009383,37.241709]]]},"properties":{"id":"65689","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.767267,36.890625],[-92.022121,36.836364],[-92.188513,36.883239],[-92.186623,36.851546],[-92.147343,36.794971],[-92.052883,36.644973],[-91.993859,36.562028],[-91.954598,36.498508],[-91.75927,36.499208],[-91.625854,36.765876],[-91.646926,36.766092],[-91.737074,36.788773],[-91.658413,36.857609],[-91.65747,36.91026],[-91.767267,36.890625]]]},"properties":{"id":"65775","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.220732,36.497859],[-90.125378,36.410982],[-90.049595,36.433553],[-90.040481,36.453783],[-90.017254,36.498091],[-90.059225,36.630313],[-90.148398,36.630656],[-90.220732,36.497859]]]},"properties":{"id":"63933","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.297871,36.107662],[-90.37789,35.995683],[-90.297732,35.99633],[-90.297871,36.107662]]]},"properties":{"id":"63829","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.294602,38.306562],[-91.130539,38.332184],[-91.117237,38.332519],[-91.134052,38.475589],[-91.138401,38.494906],[-91.255485,38.478372],[-91.294602,38.306562]]]},"properties":{"id":"63056","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.769277,38.569482],[-90.810848,38.486527],[-90.82253,38.483826],[-90.801764,38.438776],[-90.801137,38.429314],[-90.702903,38.406578],[-90.652163,38.431475],[-90.676629,38.527598],[-90.732531,38.593247],[-90.769277,38.569482]]]},"properties":{"id":"63069","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.130539,38.332184],[-90.944827,38.2693],[-90.859677,38.3436],[-90.889564,38.389829],[-91.11223,38.347076],[-91.117237,38.332519],[-91.130539,38.332184]]]},"properties":{"id":"63077","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.097186,38.268481],[-91.108494,38.254847],[-91.096363,38.250986],[-91.089927,38.260438],[-91.097186,38.268481]]]},"properties":{"id":"63079","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.304157,38.699977],[-91.304056,38.733098],[-91.331243,38.774694],[-91.41656,38.803746],[-91.535607,38.819888],[-91.546289,38.676627],[-91.627652,38.494558],[-91.618903,38.467647],[-91.37558,38.542236],[-91.372843,38.583723],[-91.304157,38.699977]]]},"properties":{"id":"65041","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.27081,37.35351],[-93.22327,37.445066],[-93.20335,37.483066],[-93.264415,37.486698],[-93.325775,37.490652],[-93.318039,37.368022],[-93.27081,37.35351]]]},"properties":{"id":"65725","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.323974,39.982943],[-93.361855,39.982174],[-93.502245,39.994295],[-93.540841,39.921264],[-93.447134,39.879113],[-93.323962,39.93911],[-93.323974,39.982943]]]},"properties":{"id":"64635","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.060375,40.573209],[-94.119716,40.441009],[-94.075245,40.425858],[-93.951385,40.439492],[-93.907992,40.575717],[-94.060375,40.573209]]]},"properties":{"id":"64442","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.23224,40.571901],[-94.192478,40.47071],[-94.119716,40.441009],[-94.060375,40.573209],[-94.23224,40.571901]]]},"properties":{"id":"64458","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.774344,40.577548],[-93.899518,40.439056],[-93.763554,40.336461],[-93.653471,40.499129],[-93.677155,40.578088],[-93.774344,40.577548]]]},"properties":{"id":"64632","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.479542,40.386613],[-94.404392,40.38654],[-94.34268,40.386672],[-94.214619,40.470464],[-94.192478,40.47071],[-94.23224,40.571901],[-94.470541,40.571022],[-94.541241,40.507907],[-94.509619,40.386887],[-94.479542,40.386613]],[[-94.286316,40.490849],[-94.281502,40.479973],[-94.296124,40.479908],[-94.295643,40.490761],[-94.286316,40.490849]]]},"properties":{"id":"64456","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.313991,37.895021],[-93.254178,37.876517],[-93.212572,37.890489],[-93.229194,37.96312],[-93.311647,37.980809],[-93.313991,37.895021]]]},"properties":{"id":"65668","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.660541,37.316199],[-90.717289,37.271959],[-90.667931,37.274455],[-90.660541,37.316199]]]},"properties":{"id":"63675","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.607565,39.028344],[-94.583071,39.027508],[-94.5823,39.041964],[-94.607371,39.04464],[-94.607565,39.028344]]]},"properties":{"id":"64112","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.533728,39.146017],[-94.579019,39.117367],[-94.560673,39.113072],[-94.548152,39.113575],[-94.501659,39.109704],[-94.489418,39.118706],[-94.46723,39.124304],[-94.498387,39.15046],[-94.533728,39.146017]]]},"properties":{"id":"64120","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.607986,38.912924],[-94.608033,38.847207],[-94.564921,38.845221],[-94.565006,38.859724],[-94.584397,38.911299],[-94.589377,38.914363],[-94.607986,38.912924]]]},"properties":{"id":"64145","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.187174,39.183843],[-94.267661,39.158733],[-94.154018,39.093232],[-94.106286,39.109169],[-94.105225,39.131501],[-94.133123,39.132457],[-94.150453,39.143823],[-94.104864,39.142565],[-94.104823,39.143507],[-94.187174,39.183843]]]},"properties":{"id":"64088","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.510047,37.157199],[-94.438399,37.136845],[-94.454325,37.141594],[-94.442963,37.163178],[-94.429032,37.17878],[-94.400173,37.215108],[-94.418089,37.225793],[-94.543513,37.259363],[-94.510047,37.157199]]]},"properties":{"id":"64870","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.618029,37.087141],[-94.386428,37.075284],[-94.429032,37.17878],[-94.442963,37.163178],[-94.438399,37.136845],[-94.510047,37.157199],[-94.618041,37.142341],[-94.618029,37.087141]]]},"properties":{"id":"64801","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.85158,38.882849],[-93.794064,38.674537],[-93.776937,38.634449],[-93.638062,38.662518],[-93.63392,38.888769],[-93.714067,38.934347],[-93.792884,38.936253],[-93.816661,38.937196],[-93.85158,38.882849]]]},"properties":{"id":"64093","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.091836,40.037948],[-92.260101,40.052191],[-92.34294,40.037163],[-92.344708,39.953297],[-92.287882,39.95249],[-92.120373,39.95283],[-92.096004,39.964491],[-92.076178,39.987731],[-92.066828,39.987224],[-92.091836,40.037948]]]},"properties":{"id":"63460","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.054378,40.052286],[-92.064885,40.216108],[-92.126084,40.296144],[-92.23921,40.22052],[-92.260101,40.052191],[-92.091836,40.037948],[-92.054378,40.052286]]]},"properties":{"id":"63537","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.10235,37.646063],[-92.248935,37.635276],[-92.300187,37.610393],[-92.260649,37.561081],[-92.25293,37.411899],[-92.170029,37.341849],[-92.147622,37.422887],[-92.132344,37.508336],[-92.045694,37.51558],[-92.042918,37.602804],[-92.070985,37.657567],[-92.10235,37.646063]]]},"properties":{"id":"65552","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.496927,36.935583],[-93.608135,37.13475],[-93.607976,37.14204],[-93.644953,37.141059],[-93.66839,37.069007],[-93.61046,36.966564],[-93.496927,36.935583]]]},"properties":{"id":"65705","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.845669,40.182457],[-91.858596,40.019011],[-91.663478,40.048785],[-91.607427,40.075714],[-91.671233,40.088762],[-91.769605,40.167073],[-91.845669,40.182457]]]},"properties":{"id":"63452","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.664723,40.226287],[-91.769605,40.167073],[-91.671233,40.088762],[-91.664723,40.226287]]]},"properties":{"id":"63457","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.119104,39.179674],[-91.070008,39.313897],[-91.057191,39.3547],[-91.208063,39.43508],[-91.301344,39.398863],[-91.301255,39.249971],[-91.184693,39.167777],[-91.119104,39.179674]]]},"properties":{"id":"63334","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.24264,39.096974],[-91.243157,39.080626],[-91.238365,39.084037],[-91.24264,39.096974]]]},"properties":{"id":"63370","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.472581,36.616329],[-94.550437,36.583616],[-94.528459,36.499259],[-94.313239,36.49938],[-94.42616,36.567518],[-94.472581,36.616329]]]},"properties":{"id":"64854","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.618243,36.970897],[-94.618077,36.667921],[-94.591801,36.707697],[-94.505747,36.762922],[-94.533356,36.895186],[-94.537386,36.902878],[-94.528556,36.905977],[-94.534807,36.940394],[-94.618243,36.970897]]]},"properties":{"id":"64865","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.808686,39.856966],[-92.685775,39.860833],[-92.688044,39.873089],[-92.734429,40.016597],[-92.809974,39.907082],[-92.808686,39.856966]]]},"properties":{"id":"63539","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.88869,38.080154],[-91.746815,38.053249],[-91.718841,38.16925],[-91.794364,38.173135],[-91.88869,38.080154]]]},"properties":{"id":"65580","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.466357,36.971981],[-89.483201,36.832764],[-89.410343,36.851165],[-89.466357,36.971981]]]},"properties":{"id":"63823","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.588509,38.751271],[-92.472329,38.704319],[-92.394866,38.73913],[-92.448527,38.845604],[-92.539224,38.845207],[-92.588509,38.751271]]]},"properties":{"id":"65046","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.409724,39.338964],[-92.412909,39.341472],[-92.40446,39.342527],[-92.309335,39.363248],[-92.332691,39.502546],[-92.475582,39.483367],[-92.481888,39.372747],[-92.42065,39.303065],[-92.409724,39.338964]]]},"properties":{"id":"65270","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.935611,40.490598],[-94.887084,40.574694],[-95.036265,40.576354],[-95.069923,40.510805],[-94.935611,40.490598]]]},"properties":{"id":"64431","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.767093,36.712366],[-92.649064,36.586723],[-92.593452,36.622415],[-92.53265,36.70578],[-92.62144,36.72489],[-92.638,36.7321],[-92.71448,36.76214],[-92.767093,36.712366]]]},"properties":{"id":"65762","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.31437,36.69775],[-92.330441,36.682266],[-92.30271,36.68031],[-92.31437,36.69775]]]},"properties":{"id":"65784","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.803789,39.24386],[-94.771115,39.191468],[-94.659571,39.174674],[-94.66256,39.181432],[-94.658801,39.239669],[-94.803789,39.24386]]]},"properties":{"id":"64152","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.666288,39.307707],[-94.658288,39.246848],[-94.600977,39.246375],[-94.600614,39.307079],[-94.600621,39.307397],[-94.648932,39.307639],[-94.666288,39.307707]]]},"properties":{"id":"64154","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.287896,37.887081],[-92.318629,37.777279],[-92.233373,37.710458],[-92.180897,37.797572],[-92.159635,37.863056],[-92.159853,37.863418],[-92.287896,37.887081]]]},"properties":{"id":"65583","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.374386,40.580333],[-93.374997,40.500847],[-93.365888,40.500887],[-93.184948,40.485825],[-93.120004,40.583272],[-93.374386,40.580333]]]},"properties":{"id":"64672","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.6191,38.85912],[-90.697707,38.780162],[-90.687603,38.760281],[-90.583759,38.748139],[-90.56503,38.79352],[-90.6191,38.85912]]]},"properties":{"id":"63376","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.77999,38.804171],[-90.80116,38.74072],[-90.73981,38.773262],[-90.77999,38.804171]]]},"properties":{"id":"63367","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.304056,38.733098],[-91.304157,38.699977],[-91.224986,38.620783],[-91.123011,38.597883],[-90.964461,38.547545],[-90.91269,38.65136],[-90.91529,38.65872],[-90.985564,38.738948],[-91.091841,38.731468],[-91.304056,38.733098]]]},"properties":{"id":"63357","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.949466,38.003844],[-94.23075,38.026532],[-94.266219,38.032267],[-94.176888,37.976359],[-94.063991,37.928513],[-93.949466,38.003844]]]},"properties":{"id":"64783","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.363345,37.884392],[-90.175609,37.721072],[-90.111062,37.721921],[-89.965283,37.902357],[-90.11408,38.028978],[-90.328448,37.954275],[-90.362311,37.937178],[-90.363345,37.884392]]]},"properties":{"id":"63670","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.807321,38.056729],[-90.76624,37.931216],[-90.666273,37.979065],[-90.617067,38.006759],[-90.619641,38.036399],[-90.640804,38.022345],[-90.666747,38.019185],[-90.660388,38.045336],[-90.686027,38.076797],[-90.683474,38.093191],[-90.747556,38.095877],[-90.807321,38.056729]]]},"properties":{"id":"63630","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.464242,37.765143],[-90.552364,37.729225],[-90.552496,37.720046],[-90.535016,37.643641],[-90.534701,37.643624],[-90.464242,37.765143]]]},"properties":{"id":"63637","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.734593,37.779614],[-90.622727,37.824952],[-90.642853,37.868177],[-90.699672,37.853402],[-90.773847,37.811282],[-90.734593,37.779614]]]},"properties":{"id":"63648","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.490482,38.626323],[-90.585654,38.608557],[-90.623039,38.63097],[-90.638625,38.583961],[-90.603515,38.58679],[-90.477908,38.596153],[-90.490482,38.626323]]]},"properties":{"id":"63011","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.313205,38.586816],[-90.368117,38.562372],[-90.361469,38.525204],[-90.33862,38.503549],[-90.33598,38.511421],[-90.270506,38.553014],[-90.270152,38.553049],[-90.295356,38.563477],[-90.313205,38.586816]]]},"properties":{"id":"63123","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.486233,38.630254],[-90.405373,38.652068],[-90.394252,38.66096],[-90.404676,38.676448],[-90.488091,38.682946],[-90.486233,38.630254]]]},"properties":{"id":"63141","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.229581,38.814669],[-90.234299,38.785376],[-90.238736,38.770634],[-90.166409,38.772649],[-90.117707,38.805748],[-90.234407,38.842003],[-90.229581,38.814669]]]},"properties":{"id":"63138","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.380811,38.529418],[-90.361469,38.525204],[-90.368117,38.562372],[-90.38378,38.572122],[-90.397862,38.561725],[-90.380811,38.529418]]]},"properties":{"id":"63126","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.623039,38.63097],[-90.732441,38.600684],[-90.732531,38.593247],[-90.676629,38.527598],[-90.610152,38.540724],[-90.603021,38.573997],[-90.638625,38.583961],[-90.623039,38.63097]]]},"properties":{"id":"63038","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.603515,38.58679],[-90.638625,38.583961],[-90.603021,38.573997],[-90.603515,38.58679]]]},"properties":{"id":"63040","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.329814,38.62278],[-90.3344,38.606586],[-90.318926,38.598264],[-90.315733,38.599859],[-90.308204,38.621488],[-90.329814,38.62278]]]},"properties":{"id":"63143","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.096537,40.390288],[-91.949747,40.300832],[-91.974914,40.402933],[-92.037026,40.418443],[-92.096537,40.390288]]]},"properties":{"id":"63543","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.695994,36.976002],[-89.700558,36.99046],[-89.686874,36.994415],[-89.687027,37.00524],[-89.816302,37.049685],[-89.866543,36.962374],[-89.841491,36.952677],[-89.741601,36.889002],[-89.695994,36.976002]]]},"properties":{"id":"63735","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.529026,37.005079],[-89.526797,37.002781],[-89.523268,37.006672],[-89.526032,37.008464],[-89.529026,37.005079]]]},"properties":{"id":"63824","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.138998,36.694234],[-90.059774,36.876999],[-90.081981,36.89199],[-90.220971,36.848843],[-90.139678,36.694414],[-90.138998,36.694234]]]},"properties":{"id":"63936","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.037037,36.705082],[-92.984441,36.685414],[-92.982024,36.810932],[-93.054661,36.812378],[-93.037037,36.705082]]]},"properties":{"id":"65759","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.68052,37.423241],[-91.86445,37.357311],[-91.848174,37.280457],[-91.829913,37.280212],[-91.757457,37.285851],[-91.756728,37.297527],[-91.646626,37.422731],[-91.68052,37.423241]]]},"properties":{"id":"65555","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.829913,37.280212],[-91.801067,37.224218],[-91.761548,37.203871],[-91.757457,37.285851],[-91.829913,37.280212]]]},"properties":{"id":"65468","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.798793,37.798513],[-90.943583,37.737812],[-90.874134,37.734196],[-90.798793,37.798513]]]},"properties":{"id":"63622","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.301022,38.647334],[-90.304533,38.632634],[-90.301652,38.632372],[-90.26865,38.607793],[-90.241854,38.604986],[-90.241175,38.608091],[-90.236718,38.625991],[-90.234264,38.633006],[-90.27462,38.645004],[-90.301022,38.647334]]]},"properties":{"id":"63110","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.315733,38.599859],[-90.270885,38.593899],[-90.26865,38.607793],[-90.301652,38.632372],[-90.308204,38.621488],[-90.315733,38.599859]]]},"properties":{"id":"63139","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.241175,38.608091],[-90.201607,38.589337],[-90.183799,38.614528],[-90.201681,38.618733],[-90.236718,38.625991],[-90.241175,38.608091]]]},"properties":{"id":"63104","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.881013,39.828922],[-94.87522,39.777311],[-94.836137,39.780924],[-94.76784,39.793391],[-94.756534,39.821331],[-94.738925,39.886238],[-94.881584,39.90162],[-94.881013,39.828922]]]},"properties":{"id":"64505","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.202265,40.578488],[-95.488144,40.581733],[-95.461192,40.510193],[-95.202017,40.479135],[-95.202265,40.578488]]]},"properties":{"id":"64498","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.091254,39.064297],[-92.03325,39.063005],[-91.968648,39.339576],[-92.024397,39.340332],[-92.091254,39.064297]]]},"properties":{"id":"65285","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.67234,36.586603],[-93.598679,36.4989],[-93.5144,36.498881],[-93.468922,36.612212],[-93.482359,36.648057],[-93.534882,36.67872],[-93.649694,36.686298],[-93.68512,36.670186],[-93.67234,36.586603]]]},"properties":{"id":"65747","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.979092,36.727242],[-94.041118,36.761214],[-94.067145,36.74774],[-94.104994,36.661998],[-93.923694,36.657437],[-93.979092,36.727242]]]},"properties":{"id":"65647","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.141978,36.53779],[-93.884961,36.587058],[-93.923694,36.657437],[-94.104994,36.661998],[-94.147388,36.629246],[-94.162127,36.612017],[-94.141978,36.53779]]]},"properties":{"id":"65772","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.543513,37.259363],[-94.418089,37.225793],[-94.420319,37.225789],[-94.446868,37.372506],[-94.491819,37.389297],[-94.500904,37.389532],[-94.545422,37.259424],[-94.543513,37.259363]]]},"properties":{"id":"64855","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.206599,38.257687],[-93.313121,38.066634],[-93.065199,38.062479],[-93.016893,38.207799],[-93.077692,38.263092],[-93.206599,38.257687]]]},"properties":{"id":"65326","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.229194,37.96312],[-93.070523,37.95114],[-93.067462,38.018327],[-93.065199,38.062479],[-93.313121,38.066634],[-93.324684,38.066928],[-93.311647,37.980809],[-93.229194,37.96312]]]},"properties":{"id":"65634","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.002303,37.040288],[-90.014188,37.098395],[-90.032432,37.06955],[-90.002303,37.040288]]],[[[-90.072189,37.152254],[-90.02509,37.108698],[-90.013756,37.171828],[-90.072189,37.152254]]]]},"properties":{"id":"63782","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.33363,38.96901],[-92.347715,38.834547],[-92.147938,38.847319],[-92.095297,38.953349],[-92.33363,38.96901]],[[-92.32004,38.95245],[-92.32171,38.95247],[-92.32166,38.95389],[-92.32001,38.95389],[-92.32004,38.95245]]]},"properties":{"id":"65201","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.664336,39.667581],[-94.703017,39.688148],[-94.787841,39.657764],[-94.738218,39.579396],[-94.715773,39.579037],[-94.664336,39.667581]]]},"properties":{"id":"64401","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.872439,39.532021],[-94.810881,39.469246],[-94.787611,39.471354],[-94.662263,39.486787],[-94.658915,39.545832],[-94.715773,39.579037],[-94.738218,39.579396],[-94.864293,39.548257],[-94.872439,39.532021]]]},"properties":{"id":"64439","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.664336,39.667581],[-94.715773,39.579037],[-94.658915,39.545832],[-94.601177,39.526429],[-94.528277,39.539871],[-94.517822,39.645204],[-94.646699,39.667534],[-94.664336,39.667581]]]},"properties":{"id":"64454","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.517747,39.841982],[-94.602494,39.780765],[-94.646699,39.667534],[-94.517822,39.645204],[-94.432365,39.681755],[-94.433469,39.835186],[-94.517747,39.841982]]]},"properties":{"id":"64490","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.428025,36.767375],[-90.426226,36.766833],[-90.426204,36.768417],[-90.427687,36.768432],[-90.428025,36.767375]]]},"properties":{"id":"63902","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.022583,39.829274],[-94.101865,39.687661],[-93.973111,39.643154],[-93.869957,39.676751],[-93.870526,39.786798],[-94.022583,39.829274]]]},"properties":{"id":"64644","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.168313,39.595234],[-93.983247,39.597527],[-93.973111,39.643154],[-94.101865,39.687661],[-94.111944,39.683377],[-94.170765,39.613926],[-94.168313,39.595234]]]},"properties":{"id":"64650","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.220661,38.643167],[-92.303838,38.440189],[-92.258997,38.371285],[-92.250791,38.39043],[-92.125177,38.389015],[-91.972233,38.477209],[-92.012936,38.506505],[-91.944238,38.596925],[-92.037909,38.562261],[-92.191491,38.622246],[-92.220661,38.643167]]]},"properties":{"id":"65101","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.80037,38.824126],[-91.846111,38.751052],[-91.760271,38.707311],[-91.747018,38.808694],[-91.80037,38.824126]]]},"properties":{"id":"65077","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.441909,39.068153],[-91.659726,39.020745],[-91.698013,39.014008],[-91.712904,38.960348],[-91.641583,38.86073],[-91.64233,38.841775],[-91.585608,38.836363],[-91.355203,38.992469],[-91.384517,39.052807],[-91.441909,39.068153]]]},"properties":{"id":"63361","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.598788,38.163128],[-92.58969,38.210746],[-92.688133,38.223986],[-92.736675,38.220733],[-92.764441,38.194313],[-92.641314,38.171631],[-92.598788,38.163128]]]},"properties":{"id":"65049","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.806188,37.375483],[-89.793118,37.33233],[-89.74026,37.304256],[-89.454405,37.453102],[-89.521855,37.566214],[-89.660267,37.552148],[-89.759122,37.445337],[-89.806188,37.375483]]]},"properties":{"id":"63755","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.861391,37.531548],[-89.861749,37.50613],[-89.833765,37.506701],[-89.793238,37.523392],[-89.861391,37.531548]]]},"properties":{"id":"63743","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.658532,37.231728],[-89.715923,37.249629],[-89.743187,37.231687],[-89.658532,37.231728]]]},"properties":{"id":"63745","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.842251,39.409549],[-93.758463,39.207021],[-93.700057,39.209725],[-93.658009,39.238478],[-93.60641,39.241303],[-93.701658,39.510095],[-93.79623,39.438133],[-93.842251,39.409549]]]},"properties":{"id":"64668","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.976219,39.761423],[-93.027221,39.681897],[-92.991982,39.615307],[-92.972975,39.553158],[-92.869208,39.585128],[-92.840561,39.66274],[-92.835015,39.69998],[-92.976219,39.761423]]]},"properties":{"id":"64658","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.869208,39.585128],[-92.939186,39.332791],[-92.782697,39.341509],[-92.705094,39.370747],[-92.696592,39.610371],[-92.840561,39.66274],[-92.869208,39.585128]]]},"properties":{"id":"65281","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.941202,36.853753],[-93.071529,36.812763],[-93.054661,36.812378],[-92.982024,36.810932],[-92.941202,36.853753]]]},"properties":{"id":"65657","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.493591,39.180577],[-94.548944,39.18083],[-94.548958,39.179774],[-94.533728,39.146017],[-94.498387,39.15046],[-94.493591,39.180577]]]},"properties":{"id":"64117","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.560573,39.332642],[-94.491865,39.331976],[-94.491857,39.332443],[-94.463846,39.367902],[-94.480757,39.417865],[-94.600819,39.456155],[-94.658371,39.406824],[-94.657878,39.360911],[-94.657508,39.354314],[-94.60079,39.33611],[-94.560573,39.332642]]]},"properties":{"id":"64089","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.250791,38.39043],[-92.145444,38.327448],[-92.125177,38.389015],[-92.250791,38.39043]]]},"properties":{"id":"65076","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.934569,39.064547],[-92.993749,38.940287],[-92.914954,38.924787],[-92.847561,38.980943],[-92.934569,39.064547]]]},"properties":{"id":"65322","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.11962,37.740383],[-91.112527,37.716584],[-91.067869,37.674138],[-90.98265,37.738999],[-91.094779,37.957889],[-91.208015,38.031667],[-91.304672,38.041167],[-91.526223,37.970853],[-91.526686,37.92123],[-91.41728,37.85692],[-91.306321,37.861672],[-91.276487,37.856921],[-91.11962,37.740383]]]},"properties":{"id":"65565","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.538924,37.821478],[-91.43707,37.82974],[-91.446174,37.848442],[-91.41728,37.85692],[-91.526686,37.92123],[-91.538924,37.821478]]]},"properties":{"id":"65449","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.860887,37.389156],[-93.882147,37.287114],[-93.766973,37.284979],[-93.793746,37.372811],[-93.860887,37.389156]]]},"properties":{"id":"65752","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.887158,40.090253],[-93.896369,40.012121],[-93.802157,39.84942],[-93.797371,39.844806],[-93.759798,39.847267],[-93.702364,39.960698],[-93.764169,40.069457],[-93.887158,40.090253]]]},"properties":{"id":"64648","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.68052,37.423241],[-91.754795,37.598768],[-91.809105,37.598863],[-91.882374,37.599502],[-92.029258,37.602542],[-92.042918,37.602804],[-92.045694,37.51558],[-92.048652,37.510477],[-92.057582,37.461504],[-91.946631,37.429949],[-91.86445,37.357311],[-91.68052,37.423241]]]},"properties":{"id":"65542","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.383046,37.229268],[-92.492313,37.19449],[-92.519369,37.139314],[-92.465478,37.017681],[-92.468183,36.999272],[-92.386294,36.903536],[-92.343681,36.944866],[-92.318183,36.981375],[-92.383046,37.229268]]]},"properties":{"id":"65717","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.638,36.7321],[-92.529504,36.802134],[-92.71448,36.76214],[-92.638,36.7321]]]},"properties":{"id":"65755","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.383046,37.229268],[-92.318183,36.981375],[-92.198935,36.970887],[-92.207397,37.298453],[-92.215813,37.30579],[-92.337845,37.384201],[-92.35969,37.473856],[-92.403447,37.430886],[-92.383046,37.229268]]]},"properties":{"id":"65711","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.248599,36.128814],[-90.169189,36.061112],[-90.110582,36.104835],[-90.248599,36.128814]]]},"properties":{"id":"63876","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.029989,36.443865],[-90.049595,36.433553],[-90.125378,36.410982],[-90.082259,36.321859],[-90.027967,36.331522],[-90.018306,36.324222],[-89.960856,36.30862],[-89.928511,36.321596],[-89.963883,36.411135],[-90.029989,36.443865]]]},"properties":{"id":"63852","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.029989,36.443865],[-89.963883,36.411135],[-89.959928,36.498126],[-90.017254,36.498091],[-90.040481,36.453783],[-90.029989,36.443865]]]},"properties":{"id":"63837","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.368415,38.535363],[-91.255485,38.478372],[-91.138401,38.494906],[-91.123011,38.597883],[-91.224986,38.620783],[-91.372843,38.583723],[-91.37558,38.542236],[-91.368415,38.535363]]]},"properties":{"id":"63068","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.973353,38.47455],[-91.12461,38.447565],[-91.11223,38.347076],[-90.889564,38.389829],[-90.888972,38.399083],[-90.973353,38.47455]]]},"properties":{"id":"63084","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.810848,38.486527],[-90.853469,38.509501],[-90.82253,38.483826],[-90.810848,38.486527]]]},"properties":{"id":"63039","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.818898,38.269311],[-90.799937,38.277034],[-90.848828,38.276448],[-90.844769,38.267882],[-90.818898,38.269311]]]},"properties":{"id":"63061","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.169554,40.197444],[-94.176386,40.325265],[-94.215087,40.340499],[-94.25833,40.319747],[-94.216535,40.194731],[-94.169554,40.197444]]]},"properties":{"id":"64471","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.215087,40.340499],[-94.214619,40.470464],[-94.34268,40.386672],[-94.25833,40.319747],[-94.215087,40.340499]]]},"properties":{"id":"64441","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.183597,37.240227],[-93.44731,37.2753],[-93.476185,37.244242],[-93.528159,37.185475],[-93.42428,37.16161],[-93.38448,37.16889],[-93.311983,37.19707],[-93.28136,37.20896],[-93.27608,37.20388],[-93.276053,37.203128],[-93.226332,37.194701],[-93.163251,37.183686],[-93.124038,37.195032],[-93.183597,37.240227]]]},"properties":{"id":"65802","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.626505,37.189824],[-93.629545,37.156398],[-93.528159,37.185475],[-93.476185,37.244242],[-93.47952,37.27863],[-93.60669,37.20413],[-93.60695,37.19307],[-93.626505,37.189824]]]},"properties":{"id":"65612","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.737235,40.206095],[-93.461455,40.192544],[-93.461475,40.265359],[-93.763324,40.263988],[-93.763183,40.248748],[-93.737235,40.206095]]]},"properties":{"id":"64679","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.951385,40.439492],[-94.075245,40.425858],[-94.072819,40.382207],[-93.858815,40.271608],[-93.763183,40.248748],[-93.763324,40.263988],[-93.763554,40.336461],[-93.899518,40.439056],[-93.951385,40.439492]]]},"properties":{"id":"64481","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.072819,40.382207],[-94.075245,40.425858],[-94.119716,40.441009],[-94.192478,40.47071],[-94.214619,40.470464],[-94.215087,40.340499],[-94.176386,40.325265],[-94.072819,40.382207]]]},"properties":{"id":"64467","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.521653,38.248351],[-93.866589,38.28551],[-93.867773,38.192154],[-93.850953,38.169934],[-93.556088,38.16602],[-93.523484,38.207216],[-93.521653,38.248351]]]},"properties":{"id":"64740","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.135516,40.07944],[-95.055109,40.073503],[-95.040561,40.096799],[-95.068365,40.202615],[-95.181777,40.218146],[-95.135516,40.07944]]]},"properties":{"id":"64466","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.705094,39.370747],[-92.782697,39.341509],[-92.73355,39.219563],[-92.614679,39.265773],[-92.631259,39.352832],[-92.705094,39.370747]]]},"properties":{"id":"65230","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.479292,37.448666],[-90.693771,37.499255],[-90.770235,37.549446],[-90.770618,37.513865],[-90.806177,37.365171],[-90.770348,37.258557],[-90.717289,37.271959],[-90.660541,37.316199],[-90.45262,37.316227],[-90.418415,37.315719],[-90.479292,37.448666]]]},"properties":{"id":"63620","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.477813,39.099297],[-94.514419,39.101285],[-94.515285,39.083118],[-94.470183,39.081314],[-94.477447,39.092355],[-94.477813,39.099297]]]},"properties":{"id":"64126","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.563068,39.012779],[-94.517897,39.015126],[-94.516741,39.05384],[-94.554704,39.055477],[-94.561743,39.055711],[-94.564775,39.012854],[-94.563068,39.012779]]]},"properties":{"id":"64130","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.580043,39.070835],[-94.607334,39.072431],[-94.607371,39.04464],[-94.5823,39.041964],[-94.58,39.056349],[-94.580043,39.070835]]]},"properties":{"id":"64111","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.517897,39.015126],[-94.563068,39.012779],[-94.55798,38.956318],[-94.521118,38.967457],[-94.502405,38.992424],[-94.515781,39.01525],[-94.517897,39.015126]]]},"properties":{"id":"64132","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.584397,38.911299],[-94.565006,38.859724],[-94.557239,38.866764],[-94.560828,38.910095],[-94.566043,38.925122],[-94.584397,38.911299]]]},"properties":{"id":"64146","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.442963,37.163178],[-94.454325,37.141594],[-94.438399,37.136845],[-94.442963,37.163178]]]},"properties":{"id":"64835","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.415849,38.042195],[-90.316774,37.980063],[-90.276541,38.158981],[-90.35543,38.216306],[-90.38862,38.21849],[-90.366948,38.248344],[-90.368444,38.261634],[-90.425852,38.255283],[-90.462277,38.262111],[-90.52844,38.217833],[-90.416022,38.042315],[-90.415849,38.042195]],[[-90.48568,38.19334],[-90.496394,38.193499],[-90.481214,38.199097],[-90.48568,38.19334]]]},"properties":{"id":"63028","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.425852,38.255283],[-90.368444,38.261634],[-90.371754,38.280536],[-90.425852,38.255283]]]},"properties":{"id":"63048","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.578263,38.726168],[-93.532402,38.713395],[-93.538937,38.751887],[-93.578263,38.726168]]]},"properties":{"id":"65305","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.546283,38.646329],[-93.365505,38.50841],[-93.336563,38.536631],[-93.426919,38.719963],[-93.506416,38.679722],[-93.546283,38.646329]]]},"properties":{"id":"65332","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.524936,38.896598],[-93.493006,38.983733],[-93.494003,38.973422],[-93.499578,38.972117],[-93.494165,38.984812],[-93.492775,39.004865],[-93.534513,39.049077],[-93.591152,39.043886],[-93.649611,39.028945],[-93.714067,38.934347],[-93.63392,38.888769],[-93.524936,38.896598]]]},"properties":{"id":"64020","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.224824,38.967403],[-93.328308,39.096817],[-93.394371,39.099847],[-93.492775,39.004865],[-93.494165,38.984812],[-93.493006,38.983733],[-93.524936,38.896598],[-93.49507,38.869296],[-93.435099,38.870789],[-93.224824,38.967403]]]},"properties":{"id":"65351","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.120373,39.95283],[-92.199384,39.830281],[-92.14596,39.769974],[-91.919383,39.775108],[-91.867141,39.856051],[-92.063637,39.9513],[-92.066828,39.987224],[-92.076178,39.987731],[-92.07682,39.973213],[-92.096004,39.964491],[-92.120373,39.95283]]]},"properties":{"id":"63469","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.411696,37.66092],[-92.521491,37.509465],[-92.494985,37.433473],[-92.403447,37.430886],[-92.35969,37.473856],[-92.260649,37.561081],[-92.300187,37.610393],[-92.328829,37.628399],[-92.411696,37.66092]]]},"properties":{"id":"65470","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.521491,37.509465],[-92.647436,37.524841],[-92.657762,37.480817],[-92.635014,37.34963],[-92.494985,37.433473],[-92.521491,37.509465]]]},"properties":{"id":"65662","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.318629,37.777279],[-92.328829,37.628399],[-92.300187,37.610393],[-92.248935,37.635276],[-92.233373,37.710458],[-92.318629,37.777279]]]},"properties":{"id":"65534","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.911172,39.088319],[-93.950458,39.150845],[-93.988892,39.152786],[-94.024206,39.154537],[-94.027379,39.152853],[-94.032068,39.075181],[-93.916921,39.078846],[-93.911172,39.088319]]]},"properties":{"id":"64097","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.700057,39.209725],[-93.704213,39.16745],[-93.653552,39.166696],[-93.635814,39.195022],[-93.658009,39.238478],[-93.700057,39.209725]]]},"properties":{"id":"64022","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.534513,39.049077],[-93.48567,39.146747],[-93.56561,39.150181],[-93.591152,39.043886],[-93.534513,39.049077]]]},"properties":{"id":"64001","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.895493,37.175446],[-93.921494,37.128744],[-93.932518,37.059738],[-93.915554,37.030488],[-93.897518,37.030103],[-93.861547,37.015022],[-93.779982,37.02851],[-93.66839,37.069007],[-93.644953,37.141059],[-93.713527,37.173016],[-93.895493,37.175446]]]},"properties":{"id":"65712","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.913108,40.256471],[-91.952337,39.992777],[-91.952726,39.949426],[-91.859306,39.948513],[-91.858596,40.019011],[-91.845669,40.182457],[-91.884569,40.255852],[-91.913108,40.256471]]]},"properties":{"id":"63447","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.644938,39.97616],[-91.723855,39.976503],[-91.707826,39.917476],[-91.644938,39.97616]]]},"properties":{"id":"63438","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.91375,38.68287],[-90.892492,38.886495],[-90.957555,38.893285],[-90.971704,38.880466],[-90.985564,38.738948],[-90.91529,38.65872],[-90.91375,38.68287]]]},"properties":{"id":"63348","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.309817,40.012391],[-93.000468,39.982269],[-92.994403,39.985579],[-92.95989,40.072661],[-93.253874,40.100665],[-93.309817,40.012391]]]},"properties":{"id":"64630","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.36672,40.118548],[-93.361855,39.982174],[-93.323974,39.982943],[-93.309802,39.997778],[-93.309817,40.012391],[-93.253874,40.100665],[-93.324015,40.197805],[-93.36672,40.118548]]]},"properties":{"id":"64646","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.587576,39.957978],[-92.690845,40.038233],[-92.733642,40.038056],[-92.734429,40.016597],[-92.688044,39.873089],[-92.62723,39.87176],[-92.587576,39.957978]]]},"properties":{"id":"63538","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.287882,39.95249],[-92.344708,39.953297],[-92.587576,39.957978],[-92.62723,39.87176],[-92.599463,39.87086],[-92.516225,39.837288],[-92.365234,39.866259],[-92.308334,39.873683],[-92.289242,39.894564],[-92.287882,39.95249]]]},"properties":{"id":"63530","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.746008,39.879262],[-91.840978,39.913431],[-91.842461,39.861356],[-91.811352,39.824574],[-91.700119,39.773304],[-91.687162,39.861336],[-91.746008,39.879262]]]},"properties":{"id":"63463","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.60359,39.685608],[-91.58557,39.634031],[-91.526312,39.616901],[-91.280736,39.651475],[-91.273218,39.65431],[-91.262026,39.642712],[-91.256521,39.646157],[-91.367854,39.729136],[-91.60359,39.685608]]]},"properties":{"id":"63401","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.423321,40.265597],[-93.337521,40.252241],[-93.195764,40.318898],[-93.4432,40.364225],[-93.423321,40.265597]]]},"properties":{"id":"64645","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.713444,39.511886],[-91.832551,39.425309],[-91.810224,39.338059],[-91.756628,39.338862],[-91.612872,39.353011],[-91.560928,39.410171],[-91.647009,39.508028],[-91.713444,39.511886]]]},"properties":{"id":"63462","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.894594,39.556476],[-91.861535,39.416403],[-91.832551,39.425309],[-91.713444,39.511886],[-91.850678,39.58173],[-91.894594,39.556476]]]},"properties":{"id":"65283","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.314256,39.537404],[-92.332691,39.502546],[-92.309335,39.363248],[-92.227188,39.344999],[-92.094937,39.369861],[-92.140078,39.434178],[-92.202958,39.60441],[-92.265092,39.573858],[-92.314256,39.537404]]]},"properties":{"id":"65263","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.146889,39.735518],[-92.167921,39.605806],[-92.115939,39.591677],[-92.115796,39.596758],[-92.146889,39.735518]]]},"properties":{"id":"63450","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.666004,36.67195],[-89.809948,36.583975],[-89.809303,36.555284],[-89.809285,36.553523],[-89.808373,36.496131],[-89.790491,36.494114],[-89.626003,36.540849],[-89.609834,36.555107],[-89.574318,36.627042],[-89.583506,36.67133],[-89.666004,36.67195]]]},"properties":{"id":"63862","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.922111,38.440358],[-91.969064,38.456769],[-92.024851,38.40131],[-92.028067,38.33139],[-91.959875,38.289745],[-91.862664,38.292889],[-91.820046,38.388823],[-91.922111,38.440358]]]},"properties":{"id":"65035","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.62144,36.72489],[-92.5506,36.75838],[-92.52485,36.70382],[-92.441772,36.708203],[-92.392232,36.790054],[-92.382848,36.799584],[-92.529504,36.802134],[-92.638,36.7321],[-92.62144,36.72489]]]},"properties":{"id":"65773","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.566119,36.57879],[-92.55984,36.4983],[-92.259457,36.498048],[-92.258391,36.51906],[-92.268197,36.552242],[-92.30271,36.68031],[-92.330441,36.682266],[-92.31437,36.69775],[-92.31935,36.71297],[-92.441772,36.708203],[-92.52485,36.70382],[-92.53265,36.70578],[-92.593452,36.622415],[-92.566119,36.57879]]]},"properties":{"id":"65655","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.932682,36.598208],[-92.929161,36.497922],[-92.735102,36.497992],[-92.836512,36.595466],[-92.932682,36.598208]]]},"properties":{"id":"65733","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.829894,36.182315],[-89.869767,36.168485],[-89.829468,36.175541],[-89.829894,36.182315]]]},"properties":{"id":"63826","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.418978,39.474644],[-91.457833,39.436034],[-91.301344,39.398863],[-91.208063,39.43508],[-91.202702,39.521775],[-91.418978,39.474644]]]},"properties":{"id":"63441","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.193252,39.545739],[-91.062941,39.481364],[-91.178012,39.598196],[-91.193252,39.545739]]]},"properties":{"id":"63433","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.689858,39.422253],[-94.787611,39.471354],[-94.810881,39.469246],[-94.88136,39.370383],[-94.837855,39.262417],[-94.732955,39.338954],[-94.657878,39.360911],[-94.658371,39.406824],[-94.689858,39.422253]]]},"properties":{"id":"64079","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.657508,39.354314],[-94.648932,39.307639],[-94.600621,39.307397],[-94.60079,39.33611],[-94.657508,39.354314]]]},"properties":{"id":"64164","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.657878,39.360911],[-94.732955,39.338954],[-94.666288,39.307707],[-94.648932,39.307639],[-94.657508,39.354314],[-94.657878,39.360911]]]},"properties":{"id":"64163","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.748505,40.562742],[-92.780679,40.429471],[-92.710239,40.444455],[-92.678967,40.523434],[-92.748505,40.562742]]]},"properties":{"id":"63551","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.705094,39.370747],[-92.631259,39.352832],[-92.640058,39.609544],[-92.692149,39.610265],[-92.696592,39.610371],[-92.705094,39.370747]]]},"properties":{"id":"65244","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.687603,38.760281],[-90.72828,38.72461],[-90.742412,38.634563],[-90.732328,38.639302],[-90.571822,38.681052],[-90.567835,38.681242],[-90.583759,38.748139],[-90.687603,38.760281]]]},"properties":{"id":"63304","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.826266,37.975982],[-93.80739,37.985659],[-93.822142,37.994301],[-93.826266,37.975982]]]},"properties":{"id":"64781","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-90.747556,38.095877],[-90.777571,38.12349],[-90.793698,38.08352],[-90.807321,38.056729],[-90.747556,38.095877]]],[[[-90.660388,38.045336],[-90.640804,38.022345],[-90.619641,38.036399],[-90.686027,38.076797],[-90.660388,38.045336]]]]},"properties":{"id":"63626","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.571822,38.681052],[-90.585654,38.608557],[-90.490482,38.626323],[-90.486233,38.630254],[-90.488091,38.682946],[-90.537616,38.688018],[-90.567835,38.681242],[-90.571822,38.681052]]]},"properties":{"id":"63017","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.33598,38.511421],[-90.274711,38.486607],[-90.257413,38.531852],[-90.270506,38.553014],[-90.33598,38.511421]]]},"properties":{"id":"63125","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.38378,38.572122],[-90.368117,38.562372],[-90.313205,38.586816],[-90.318926,38.598264],[-90.3344,38.606586],[-90.362946,38.612863],[-90.379739,38.609238],[-90.38378,38.572122]]]},"properties":{"id":"63119","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.428935,38.821312],[-90.324215,38.768563],[-90.307628,38.772663],[-90.30682,38.82144],[-90.350337,38.840253],[-90.440617,38.82765],[-90.428935,38.821312]]]},"properties":{"id":"63031","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.905391,39.161951],[-92.957986,39.288063],[-93.012214,39.173991],[-92.905391,39.161951]]]},"properties":{"id":"65330","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.168492,37.132815],[-91.232292,37.24789],[-91.376654,37.272743],[-91.537514,37.350357],[-91.538174,37.30468],[-91.490397,37.117723],[-91.349174,37.078629],[-91.168492,37.132815]]]},"properties":{"id":"65466","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.188369,40.338438],[-93.195764,40.318898],[-93.337521,40.252241],[-93.324015,40.197805],[-93.253874,40.100665],[-92.95989,40.072661],[-92.884186,40.109552],[-92.884305,40.124239],[-93.059818,40.313542],[-93.188369,40.338438]]]},"properties":{"id":"63556","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.057582,37.461504],[-92.147622,37.422887],[-92.170029,37.341849],[-92.124326,37.332527],[-92.088027,37.312401],[-91.946631,37.429949],[-92.057582,37.461504]]]},"properties":{"id":"65444","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.307798,37.728864],[-94.097551,37.713335],[-94.181739,37.802361],[-94.307798,37.728864]]]},"properties":{"id":"64767","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.452965,37.316226],[-90.466066,37.165646],[-90.430418,37.165749],[-90.416781,37.204939],[-90.345821,37.214528],[-90.275609,37.283526],[-90.346243,37.314577],[-90.418415,37.315719],[-90.45262,37.316227],[-90.452965,37.316226]]]},"properties":{"id":"63964","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.657762,37.480817],[-92.874297,37.440644],[-92.691254,37.307183],[-92.635014,37.34963],[-92.657762,37.480817]]]},"properties":{"id":"65713","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.211133,38.679581],[-90.231659,38.65977],[-90.222054,38.655459],[-90.193545,38.652525],[-90.211133,38.679581]]]},"properties":{"id":"63107","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.204689,38.626483],[-90.203769,38.628699],[-90.206345,38.6293],[-90.207004,38.626958],[-90.204689,38.626483]]]},"properties":{"id":"63155","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.926984,40.078191],[-94.766848,40.061757],[-94.709291,40.112292],[-94.746809,40.170356],[-94.971573,40.129426],[-95.002736,40.100754],[-94.926984,40.078191]]]},"properties":{"id":"64427","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.709291,40.112292],[-94.65773,40.100954],[-94.624407,40.126303],[-94.687935,40.222404],[-94.749709,40.206626],[-94.746809,40.170356],[-94.709291,40.112292]]]},"properties":{"id":"64457","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.461192,40.510193],[-95.425481,40.394381],[-95.235763,40.364219],[-95.196509,40.479117],[-95.202017,40.479135],[-95.461192,40.510193]]]},"properties":{"id":"64491","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.627392,40.353135],[-95.425481,40.394381],[-95.461192,40.510193],[-95.488144,40.581733],[-95.638208,40.584137],[-95.642028,40.569738],[-95.651719,40.399171],[-95.627392,40.353135]]]},"properties":{"id":"64482","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.659726,39.020745],[-91.441909,39.068153],[-91.474895,39.188758],[-91.555221,39.190275],[-91.629598,39.147853],[-91.659726,39.020745]]]},"properties":{"id":"63384","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.589178,39.323463],[-91.610042,39.250765],[-91.539646,39.219422],[-91.589178,39.323463]]]},"properties":{"id":"63345","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.22926,39.29428],[-92.413133,39.161633],[-92.39051,39.13048],[-92.332589,39.125116],[-92.258649,39.156925],[-92.22926,39.29428]]]},"properties":{"id":"65284","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.629598,39.147853],[-91.705832,39.149136],[-91.773242,39.089779],[-91.774497,39.060787],[-91.698013,39.014008],[-91.659726,39.020745],[-91.629598,39.147853]]]},"properties":{"id":"65264","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.474895,39.188758],[-91.441909,39.068153],[-91.384517,39.052807],[-91.256582,39.071018],[-91.243157,39.080626],[-91.24264,39.096974],[-91.184693,39.167777],[-91.301255,39.249971],[-91.406337,39.257353],[-91.474895,39.188758]]]},"properties":{"id":"63359","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.802115,36.79579],[-93.765281,36.812964],[-93.779982,37.02851],[-93.861547,37.015022],[-93.843028,36.806099],[-93.802115,36.79579]]]},"properties":{"id":"65769","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.617698,37.653578],[-94.61705,37.56706],[-94.491819,37.389297],[-94.446868,37.372506],[-94.409555,37.415921],[-94.400596,37.649321],[-94.617698,37.653578]]]},"properties":{"id":"64762","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.178421,38.39082],[-94.457465,38.338675],[-94.543791,38.226435],[-94.184721,38.121898],[-94.175313,38.121647],[-94.084386,38.214674],[-94.088901,38.345378],[-94.178421,38.39082]]]},"properties":{"id":"64730","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.580693,38.197945],[-94.484902,37.984807],[-94.319808,37.999748],[-94.266219,38.032267],[-94.23075,38.026532],[-94.184721,38.121898],[-94.543791,38.226435],[-94.580693,38.197945]],[[-94.447582,37.996477],[-94.447488,37.998437],[-94.443064,37.998347],[-94.441831,37.996343],[-94.447582,37.996477]],[[-94.513503,38.144633],[-94.509903,38.173452],[-94.480453,38.16567],[-94.513503,38.144633]]]},"properties":{"id":"64779","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.23075,38.026532],[-93.949466,38.003844],[-93.854144,38.022666],[-93.89242,38.058856],[-94.175313,38.121647],[-94.184721,38.121898],[-94.23075,38.026532]]]},"properties":{"id":"64780","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.457465,38.338675],[-94.178421,38.39082],[-94.142676,38.451302],[-94.147682,38.450673],[-94.249865,38.455912],[-94.455759,38.474413],[-94.50932,38.426666],[-94.498449,38.354198],[-94.457465,38.338675]]]},"properties":{"id":"64720","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.513503,38.144633],[-94.480453,38.16567],[-94.509903,38.173452],[-94.513503,38.144633]]]},"properties":{"id":"64745","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.365505,38.50841],[-93.546283,38.646329],[-93.593229,38.639224],[-93.675869,38.545641],[-93.514634,38.392328],[-93.40341,38.480488],[-93.365505,38.50841]]]},"properties":{"id":"65360","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.84054,39.759385],[-94.824647,39.711911],[-94.860991,39.66119],[-94.787841,39.657764],[-94.703017,39.688148],[-94.677478,39.820356],[-94.756534,39.821331],[-94.76784,39.793391],[-94.806044,39.762471],[-94.84054,39.759385]]]},"properties":{"id":"64507","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.345195,36.587118],[-90.302532,36.676522],[-90.28106,36.768619],[-90.281186,36.84332],[-90.281214,36.845983],[-90.279851,36.845981],[-90.296134,36.880425],[-90.353152,36.904098],[-90.568094,36.901315],[-90.662617,36.811453],[-90.720553,36.782503],[-90.636903,36.719589],[-90.628047,36.692968],[-90.566509,36.672546],[-90.465687,36.660176],[-90.345195,36.587118]],[[-90.428025,36.767375],[-90.427687,36.768432],[-90.426204,36.768417],[-90.426226,36.766833],[-90.428025,36.767375]]]},"properties":{"id":"63901","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.688042,36.56333],[-90.71994,36.498496],[-90.659327,36.49854],[-90.574959,36.576822],[-90.566509,36.672546],[-90.628047,36.692968],[-90.647231,36.633794],[-90.688042,36.56333]]]},"properties":{"id":"63953","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.881916,38.652713],[-91.949292,38.706982],[-91.94938,38.706989],[-92.031421,38.660017],[-92.037909,38.562261],[-91.944238,38.596925],[-91.91082,38.621459],[-91.881916,38.652713]]]},"properties":{"id":"65080","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.88056,38.163657],[-92.94935,38.074315],[-92.851156,37.99006],[-92.851579,38.144467],[-92.860835,38.146131],[-92.88056,38.163657]]]},"properties":{"id":"65787","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.94935,38.074315],[-93.067462,38.018327],[-93.070523,37.95114],[-93.072447,37.902627],[-93.046138,37.890742],[-92.886799,37.896657],[-92.854366,37.91156],[-92.851156,37.99006],[-92.94935,38.074315]]]},"properties":{"id":"65786","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.570114,38.066632],[-92.648054,37.986237],[-92.636989,37.90714],[-92.550705,37.92139],[-92.515979,38.022336],[-92.570114,38.066632]]]},"properties":{"id":"65591","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.515979,38.022336],[-92.424631,38.021396],[-92.442955,38.101567],[-92.487041,38.168084],[-92.516154,38.193521],[-92.579063,38.096036],[-92.570114,38.066632],[-92.515979,38.022336]]]},"properties":{"id":"65017","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.861391,37.531548],[-89.793238,37.523392],[-89.77286,37.591255],[-89.861326,37.557082],[-89.861391,37.531548]]]},"properties":{"id":"63747","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.567264,37.244995],[-89.48872,37.251457],[-89.51528,37.29693],[-89.567264,37.244995]]]},"properties":{"id":"63703","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.701658,39.510095],[-93.60641,39.241303],[-93.477233,39.292796],[-93.301963,39.309773],[-93.252726,39.338784],[-93.328668,39.38511],[-93.415974,39.464515],[-93.645299,39.524945],[-93.663903,39.53934],[-93.701658,39.510095]]]},"properties":{"id":"64633","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.561539,39.585774],[-93.515411,39.613959],[-93.497017,39.614167],[-93.593629,39.701972],[-93.626595,39.700016],[-93.702335,39.636883],[-93.663903,39.53934],[-93.645299,39.524945],[-93.561539,39.585774]]]},"properties":{"id":"64638","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.328668,39.38511],[-93.252726,39.338784],[-93.104485,39.383656],[-93.204897,39.440438],[-93.229501,39.441693],[-93.328668,39.38511]]]},"properties":{"id":"64639","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.205879,38.658201],[-94.281291,38.529101],[-94.249865,38.455912],[-94.147682,38.450673],[-94.031568,38.56666],[-94.008462,38.566166],[-94.128304,38.677754],[-94.148441,38.70047],[-94.205879,38.658201]]]},"properties":{"id":"64747","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.077386,38.892607],[-94.119096,38.833167],[-94.16127,38.759976],[-94.158677,38.756745],[-94.148441,38.70047],[-94.128304,38.677754],[-94.039889,38.875705],[-94.077386,38.892607]]]},"properties":{"id":"64061","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.226722,38.663982],[-94.226429,38.669728],[-94.240303,38.670111],[-94.226722,38.663982]]]},"properties":{"id":"64743","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.16127,38.759976],[-94.166073,38.760677],[-94.166202,38.756842],[-94.158677,38.756745],[-94.16127,38.759976]]]},"properties":{"id":"64090","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.054774,37.675493],[-94.073524,37.639722],[-94.076854,37.521707],[-93.967683,37.579674],[-93.965513,37.692601],[-94.054774,37.675493]]]},"properties":{"id":"64756","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.709705,37.596647],[-93.636848,37.50016],[-93.485469,37.493432],[-93.46076,37.525122],[-93.523318,37.585416],[-93.709705,37.596647]]]},"properties":{"id":"65601","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.27261,36.816523],[-93.181416,36.814495],[-93.184457,36.845248],[-93.214811,36.858589],[-93.227985,36.863073],[-93.27261,36.816523]]]},"properties":{"id":"65630","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.341049,36.858346],[-93.341308,36.817292],[-93.277197,36.81655],[-93.27261,36.816523],[-93.227985,36.863073],[-93.340732,36.8733],[-93.341049,36.858346]]]},"properties":{"id":"65754","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.9657,37.04725],[-92.903494,37.037644],[-92.903441,37.039634],[-92.9657,37.04725]]]},"properties":{"id":"65620","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.037037,36.705082],[-93.054661,36.812378],[-93.071529,36.812763],[-93.121874,36.916443],[-93.139713,36.910121],[-93.184457,36.845248],[-93.181416,36.814495],[-93.148799,36.726017],[-93.132714,36.711614],[-93.139231,36.65575],[-93.134911,36.654831],[-93.112733,36.668675],[-93.109032,36.654582],[-93.065611,36.651507],[-93.037037,36.705082]]]},"properties":{"id":"65653","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.567055,40.462115],[-91.660885,40.469816],[-91.590491,40.412883],[-91.572855,40.394237],[-91.596005,40.346139],[-91.490901,40.283535],[-91.567055,40.462115]]]},"properties":{"id":"63430","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.869359,40.415717],[-91.832888,40.557513],[-91.907348,40.606998],[-91.943112,40.605827],[-91.943369,40.584317],[-91.945394,40.438862],[-91.869359,40.415717]]]},"properties":{"id":"63453","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.907348,40.606998],[-91.832888,40.557513],[-91.770654,40.560082],[-91.696339,40.588135],[-91.907348,40.606998]]]},"properties":{"id":"52626","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.475188,39.194837],[-94.408628,39.193603],[-94.304609,39.231411],[-94.286718,39.246793],[-94.323481,39.324659],[-94.463846,39.367902],[-94.491857,39.332443],[-94.473337,39.311202],[-94.45724,39.244715],[-94.492954,39.223538],[-94.475188,39.194837]]]},"properties":{"id":"64068","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.528277,39.539871],[-94.449176,39.462741],[-94.400941,39.484661],[-94.38789,39.604673],[-94.396443,39.674216],[-94.432365,39.681755],[-94.517822,39.645204],[-94.528277,39.539871]]]},"properties":{"id":"64477","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.349699,38.446512],[-92.318387,38.43026],[-92.303838,38.440189],[-92.220661,38.643167],[-92.352805,38.677445],[-92.348942,38.576573],[-92.343659,38.495242],[-92.349699,38.446512]]]},"properties":{"id":"65109","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.662346,38.592654],[-92.670194,38.735986],[-92.734596,38.733626],[-92.703914,38.587971],[-92.662346,38.592654]]]},"properties":{"id":"65025","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.746815,38.053249],[-91.619361,37.843134],[-91.538924,37.821478],[-91.526686,37.92123],[-91.526223,37.970853],[-91.534384,38.152593],[-91.565907,38.152743],[-91.644877,38.179545],[-91.718841,38.16925],[-91.746815,38.053249]]]},"properties":{"id":"65559","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.913688,37.520827],[-93.789227,37.549722],[-93.773585,37.57663],[-93.945211,37.579329],[-93.913688,37.520827]]]},"properties":{"id":"65603","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.089821,37.491366],[-93.013498,37.6018],[-92.956074,37.692399],[-92.992968,37.742933],[-93.049656,37.810496],[-93.087654,37.793139],[-93.230372,37.713487],[-93.20335,37.483066],[-93.22327,37.445066],[-93.089821,37.491366]]]},"properties":{"id":"65622","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.054911,37.414657],[-92.963198,37.431152],[-92.983284,37.520097],[-93.013498,37.6018],[-93.089821,37.491366],[-93.054911,37.414657]]]},"properties":{"id":"65644","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.139569,39.960996],[-94.205561,39.847281],[-94.120971,39.822808],[-94.093024,39.843864],[-94.130509,39.931667],[-94.139569,39.960996]]]},"properties":{"id":"64689","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.043283,39.848993],[-94.103636,39.959885],[-94.130509,39.931667],[-94.093024,39.843864],[-94.043283,39.848993]]]},"properties":{"id":"64620","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.959586,36.171608],[-90.026213,36.132442],[-89.959491,36.102926],[-89.914504,36.175679],[-89.959586,36.171608]]]},"properties":{"id":"63849","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.367665,38.461479],[-91.366464,38.264492],[-91.33266,38.318187],[-91.367665,38.461479]]]},"properties":{"id":"63091","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.944827,38.2693],[-90.892409,38.205011],[-90.81561,38.204203],[-90.818898,38.269311],[-90.844769,38.267882],[-90.848828,38.276448],[-90.799937,38.277034],[-90.7802,38.306355],[-90.859677,38.3436],[-90.944827,38.2693]]]},"properties":{"id":"63060","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.853469,38.509501],[-90.810848,38.486527],[-90.769277,38.569482],[-90.816536,38.583414],[-90.895968,38.540589],[-90.910574,38.497866],[-90.853469,38.509501]]]},"properties":{"id":"63055","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.479542,40.386613],[-94.406041,40.34761],[-94.404392,40.38654],[-94.479542,40.386613]]]},"properties":{"id":"64499","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.476185,37.244242],[-93.44731,37.2753],[-93.32046,37.362247],[-93.396267,37.43952],[-93.467367,37.4491],[-93.510893,37.306302],[-93.47952,37.27863],[-93.476185,37.244242]]]},"properties":{"id":"65781","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.36209,37.13881],[-93.352702,37.094952],[-93.297659,37.094451],[-93.278644,37.137122],[-93.36209,37.13881]]]},"properties":{"id":"65810","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.22708,37.11542],[-93.163251,37.183686],[-93.226332,37.194701],[-93.22708,37.11542]]]},"properties":{"id":"65809","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.028581,40.13401],[-93.934869,40.133755],[-93.858815,40.271608],[-94.072819,40.382207],[-94.176386,40.325265],[-94.169554,40.197444],[-94.084925,40.15656],[-94.028581,40.13401]]]},"properties":{"id":"64424","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.774344,40.577548],[-93.907992,40.575717],[-93.951385,40.439492],[-93.899518,40.439056],[-93.774344,40.577548]]]},"properties":{"id":"64426","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.845371,38.499921],[-93.915621,38.600823],[-94.008462,38.566166],[-94.031568,38.56666],[-94.028519,38.528949],[-93.901746,38.462168],[-93.845371,38.499921]]]},"properties":{"id":"64726","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.543207,37.86358],[-93.466291,37.766703],[-93.372107,37.807193],[-93.360488,37.861946],[-93.414325,37.874004],[-93.543207,37.86358]]]},"properties":{"id":"65650","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.570277,37.877028],[-93.543207,37.86358],[-93.414325,37.874004],[-93.444174,37.99857],[-93.508355,37.993242],[-93.580543,37.951722],[-93.570277,37.877028]]]},"properties":{"id":"65774","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.313712,40.262732],[-95.266707,40.074156],[-95.154203,40.079597],[-95.135516,40.07944],[-95.181777,40.218146],[-95.25658,40.262778],[-95.313712,40.262732]]]},"properties":{"id":"64470","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.625854,36.765876],[-91.75927,36.499208],[-91.663148,36.499423],[-91.529736,36.662015],[-91.5285,36.762216],[-91.625854,36.765876]]]},"properties":{"id":"65692","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.112527,37.716584],[-91.146839,37.709849],[-91.153345,37.69734],[-91.102614,37.587757],[-91.013604,37.624525],[-91.067869,37.674138],[-91.112527,37.716584]]]},"properties":{"id":"65439","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.489418,39.118706],[-94.477813,39.099297],[-94.477447,39.092355],[-94.450085,39.097535],[-94.444292,39.129007],[-94.46723,39.124304],[-94.489418,39.118706]]]},"properties":{"id":"64053","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.412689,38.990687],[-94.389196,38.98349],[-94.383044,39.019503],[-94.415739,39.034056],[-94.412689,38.990687]]]},"properties":{"id":"64136","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.5823,39.041964],[-94.583071,39.027508],[-94.583843,39.012828],[-94.564775,39.012854],[-94.561743,39.055711],[-94.58,39.056349],[-94.5823,39.041964]]]},"properties":{"id":"64110","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.548152,39.113575],[-94.513143,39.10499],[-94.501659,39.109704],[-94.548152,39.113575]]]},"properties":{"id":"64123","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.583396,39.096038],[-94.588932,39.112903],[-94.607354,39.113444],[-94.607281,39.108901],[-94.596457,39.097125],[-94.596521,39.09698],[-94.583396,39.096038]]]},"properties":{"id":"64105","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.220541,38.916072],[-94.181921,39.079077],[-94.245197,39.066289],[-94.249609,39.05201],[-94.261202,38.957383],[-94.261346,38.945969],[-94.220541,38.916072]]]},"properties":{"id":"64029","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.521882,38.952946],[-94.523868,38.909368],[-94.48902,38.899884],[-94.469653,38.900995],[-94.458036,38.913429],[-94.438015,38.942095],[-94.521882,38.952946]]]},"properties":{"id":"64134","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.583843,39.012828],[-94.584481,39.000083],[-94.589377,38.914363],[-94.584397,38.911299],[-94.566043,38.925122],[-94.55798,38.956318],[-94.563068,39.012779],[-94.564775,39.012854],[-94.583843,39.012828]]]},"properties":{"id":"64131","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.154018,39.093232],[-94.267661,39.158733],[-94.262769,39.138312],[-94.255787,39.081131],[-94.245197,39.066289],[-94.181921,39.079077],[-94.154018,39.093232]]]},"properties":{"id":"64016","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.444552,37.24978],[-94.435608,37.244193],[-94.435183,37.256944],[-94.44008,37.257007],[-94.444552,37.24978]]]},"properties":{"id":"64857","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.267872,37.053063],[-94.247421,37.023502],[-94.1876,37.007463],[-94.133022,37.006237],[-93.999972,37.064656],[-94.05692,37.127637],[-94.035373,37.250856],[-94.108389,37.242151],[-94.210536,37.080863],[-94.267872,37.053063]]]},"properties":{"id":"64862","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.000052,37.250766],[-94.035373,37.250856],[-94.05692,37.127637],[-93.921494,37.128744],[-93.895493,37.175446],[-94.000052,37.250766]]]},"properties":{"id":"64848","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.415011,38.462959],[-90.460967,38.449434],[-90.347526,38.379704],[-90.341446,38.388298],[-90.376841,38.452861],[-90.415011,38.462959]]]},"properties":{"id":"63010","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.03594,38.915144],[-93.927098,38.874852],[-93.85158,38.882849],[-93.816661,38.937196],[-93.916921,39.078846],[-94.032068,39.075181],[-94.053906,39.031931],[-94.03594,38.915144]]]},"properties":{"id":"64076","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.35969,37.473856],[-92.337845,37.384201],[-92.253209,37.386537],[-92.25293,37.411899],[-92.260649,37.561081],[-92.35969,37.473856]]]},"properties":{"id":"65543","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.106286,39.109169],[-94.109325,39.033189],[-94.053906,39.031931],[-94.032068,39.075181],[-94.027379,39.152853],[-94.104823,39.143507],[-94.104864,39.142565],[-94.105225,39.131501],[-94.106286,39.109169]]]},"properties":{"id":"64074","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.48567,39.146747],[-93.484899,39.161232],[-93.477233,39.292796],[-93.60641,39.241303],[-93.658009,39.238478],[-93.635814,39.195022],[-93.56561,39.150181],[-93.48567,39.146747]]]},"properties":{"id":"64096","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.493006,38.983733],[-93.494165,38.984812],[-93.499578,38.972117],[-93.494003,38.973422],[-93.493006,38.983733]]]},"properties":{"id":"65327","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.976773,39.211004],[-94.089059,39.24246],[-94.024206,39.154537],[-93.988892,39.152786],[-93.976773,39.211004]]]},"properties":{"id":"64017","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.766973,37.284979],[-93.882147,37.287114],[-93.981602,37.27526],[-94.000052,37.250766],[-93.895493,37.175446],[-93.713527,37.173016],[-93.689087,37.192119],[-93.766973,37.284979]]]},"properties":{"id":"65707","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.950186,37.073365],[-93.932518,37.059738],[-93.921494,37.128744],[-94.05692,37.127637],[-93.999972,37.064656],[-93.950186,37.073365]]]},"properties":{"id":"65756","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.110303,38.92521],[-91.085049,38.889756],[-90.971704,38.880466],[-90.957555,38.893285],[-90.859427,38.982389],[-90.879396,39.043696],[-90.89096,39.083067],[-90.970845,39.115746],[-91.095887,39.017648],[-91.110303,38.92521]]]},"properties":{"id":"63379","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.085049,38.889756],[-91.091841,38.731468],[-90.985564,38.738948],[-90.971704,38.880466],[-91.085049,38.889756]]]},"properties":{"id":"63390","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.000468,39.982269],[-93.309817,40.012391],[-93.309802,39.997778],[-93.050984,39.942369],[-92.991392,39.95185],[-93.000468,39.982269]]]},"properties":{"id":"64674","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.758333,39.654793],[-93.702335,39.636883],[-93.626595,39.700016],[-93.740471,39.698443],[-93.758333,39.654793]]]},"properties":{"id":"64656","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.147388,36.629246],[-94.224776,36.633272],[-94.269052,36.633114],[-94.264066,36.62419],[-94.162127,36.612017],[-94.147388,36.629246]]]},"properties":{"id":"65730","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.692149,39.610265],[-92.640058,39.609544],[-92.62102,39.609285],[-92.61594,39.652539],[-92.599463,39.87086],[-92.62723,39.87176],[-92.688044,39.873089],[-92.685775,39.860833],[-92.692149,39.610265]]]},"properties":{"id":"63534","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.299316,39.605344],[-92.531626,39.608252],[-92.530336,39.570803],[-92.314256,39.537404],[-92.265092,39.573858],[-92.299316,39.605344]]]},"properties":{"id":"65260","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.18467,38.030639],[-92.159853,37.863418],[-92.159635,37.863056],[-92.076648,37.853072],[-92.014547,37.92886],[-91.988183,37.92488],[-91.977067,37.933205],[-91.896128,38.048695],[-91.888558,38.072971],[-92.054014,38.159119],[-92.127396,38.101158],[-92.117423,38.15999],[-92.060728,38.159168],[-92.045239,38.177386],[-92.223352,38.193989],[-92.226867,38.178058],[-92.18467,38.030639]]]},"properties":{"id":"65459","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.867141,39.856051],[-91.919383,39.775108],[-91.882406,39.760496],[-91.813131,39.759792],[-91.811352,39.824574],[-91.842461,39.861356],[-91.867141,39.856051]]]},"properties":{"id":"63439","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.374471,40.471949],[-93.653471,40.499129],[-93.763554,40.336461],[-93.763324,40.263988],[-93.461475,40.265359],[-93.423321,40.265597],[-93.4432,40.364225],[-93.374471,40.471949]]]},"properties":{"id":"64673","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.487041,38.168084],[-92.442955,38.101567],[-92.419517,38.165945],[-92.487041,38.168084]]]},"properties":{"id":"65083","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.546029,38.428926],[-92.447139,38.374189],[-92.406243,38.425406],[-92.546029,38.428926]]]},"properties":{"id":"65064","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.803792,38.314556],[-92.668518,38.487116],[-92.721404,38.504941],[-92.9285,38.582925],[-92.94493,38.54598],[-92.94426,38.29145],[-92.803792,38.314556]]]},"properties":{"id":"65084","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.662346,38.592654],[-92.703914,38.587971],[-92.728865,38.556003],[-92.721404,38.504941],[-92.668518,38.487116],[-92.655116,38.508682],[-92.662346,38.592654]]]},"properties":{"id":"65050","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.837542,39.37789],[-91.810224,39.338059],[-91.832551,39.425309],[-91.861535,39.416403],[-91.837542,39.37789]]]},"properties":{"id":"65282","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.871923,36.700642],[-89.958786,36.629331],[-89.866836,36.523943],[-89.827218,36.510216],[-89.817514,36.510228],[-89.808373,36.496131],[-89.809285,36.553523],[-89.809198,36.539048],[-89.827664,36.539003],[-89.82773,36.555126],[-89.809303,36.555284],[-89.809948,36.583975],[-89.729075,36.74389],[-89.871923,36.700642]]]},"properties":{"id":"63870","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.819171,40.272723],[-94.749709,40.206626],[-94.687935,40.222404],[-94.687648,40.234941],[-94.668779,40.242127],[-94.665496,40.254936],[-94.671623,40.260056],[-94.666675,40.271244],[-94.667808,40.285786],[-94.755664,40.30045],[-94.819171,40.272723]]]},"properties":{"id":"64434","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.332919,36.552259],[-91.193749,36.537648],[-91.172636,36.614785],[-91.365288,36.605328],[-91.332919,36.552259]]]},"properties":{"id":"65690","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.365288,36.605328],[-91.529736,36.662015],[-91.663148,36.499423],[-91.361638,36.4973],[-91.332919,36.552259],[-91.365288,36.605328]]]},"properties":{"id":"65791","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.916076,38.543316],[-92.012936,38.506505],[-91.972233,38.477209],[-91.969064,38.456769],[-91.922111,38.440358],[-91.916076,38.543316]]]},"properties":{"id":"65054","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.024851,38.40131],[-91.969064,38.456769],[-91.972233,38.477209],[-92.125177,38.389015],[-92.145444,38.327448],[-92.075056,38.289771],[-92.042872,38.324679],[-92.024851,38.40131]]]},"properties":{"id":"65085","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.829468,36.175541],[-89.771236,36.155314],[-89.685092,36.219746],[-89.620166,36.322805],[-89.75528,36.342541],[-89.826498,36.321003],[-89.829894,36.182315],[-89.829468,36.175541]]]},"properties":{"id":"63851","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.826498,36.321003],[-89.75528,36.342541],[-89.862416,36.386583],[-89.826498,36.321003]]]},"properties":{"id":"63879","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.224824,38.967403],[-93.435099,38.870789],[-93.329639,38.848001],[-93.191787,38.899901],[-93.224824,38.967403]]]},"properties":{"id":"65333","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.280736,39.651475],[-91.526312,39.616901],[-91.418978,39.474644],[-91.202702,39.521775],[-91.193252,39.545739],[-91.178012,39.598196],[-91.256521,39.646157],[-91.262026,39.642712],[-91.280736,39.651475]]]},"properties":{"id":"63459","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.803789,39.24386],[-94.658801,39.239669],[-94.658288,39.246848],[-94.666288,39.307707],[-94.732955,39.338954],[-94.837855,39.262417],[-94.826175,39.244849],[-94.803789,39.24386]]]},"properties":{"id":"64153","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.771115,39.191468],[-94.803789,39.24386],[-94.826175,39.244849],[-94.771115,39.191468]]]},"properties":{"id":"64092","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.071679,37.849653],[-92.096066,37.795852],[-92.025122,37.770025],[-92.071679,37.849653]]]},"properties":{"id":"65457","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.780679,40.429471],[-92.748505,40.562742],[-92.748277,40.58903],[-93.120004,40.583272],[-93.184948,40.485825],[-93.147721,40.413779],[-93.027525,40.385747],[-92.912107,40.385476],[-92.76703,40.372622],[-92.684933,40.343461],[-92.686595,40.386902],[-92.780679,40.429471]]]},"properties":{"id":"63565","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.640058,39.609544],[-92.631259,39.352832],[-92.481888,39.372747],[-92.475582,39.483367],[-92.530336,39.570803],[-92.531626,39.608252],[-92.62102,39.609285],[-92.640058,39.609544]]]},"properties":{"id":"65259","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.973735,39.452459],[-94.098717,39.304317],[-94.089059,39.24246],[-93.976773,39.211004],[-93.890151,39.192012],[-93.840147,39.215467],[-93.842251,39.409549],[-93.79623,39.438133],[-93.851897,39.467953],[-93.946459,39.467152],[-93.973735,39.452459]]]},"properties":{"id":"64085","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.472944,37.117444],[-90.588557,37.244529],[-90.667931,37.274455],[-90.717289,37.271959],[-90.770348,37.258557],[-90.773915,37.154802],[-90.889522,37.089873],[-90.865251,37.051671],[-90.748468,37.049721],[-90.566323,37.021631],[-90.513273,37.061444],[-90.472944,37.117444]]]},"properties":{"id":"63957","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.72828,38.72461],[-90.687603,38.760281],[-90.697707,38.780162],[-90.73981,38.773262],[-90.80116,38.74072],[-90.79589,38.732713],[-90.72828,38.72461]]]},"properties":{"id":"63368","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.583759,38.748139],[-90.567835,38.681242],[-90.537616,38.688018],[-90.519963,38.737413],[-90.482455,38.764013],[-90.56503,38.79352],[-90.583759,38.748139]]]},"properties":{"id":"63303","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.892492,38.886495],[-90.91375,38.68287],[-90.79589,38.732713],[-90.80116,38.74072],[-90.77999,38.804171],[-90.814293,38.878508],[-90.826982,38.878964],[-90.892492,38.886495]]]},"properties":{"id":"63385","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.868816,38.112517],[-93.556088,38.16602],[-93.850953,38.169934],[-93.868816,38.112517]]]},"properties":{"id":"64763","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.49912,37.868815],[-90.464242,37.765143],[-90.534701,37.643624],[-90.175609,37.721072],[-90.363345,37.884392],[-90.49912,37.868815]]]},"properties":{"id":"63640","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.376841,38.452861],[-90.341446,38.388298],[-90.274711,38.486607],[-90.33598,38.511421],[-90.33862,38.503549],[-90.376841,38.452861]]]},"properties":{"id":"63129","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.27997,38.771158],[-90.234299,38.785376],[-90.229581,38.814669],[-90.30682,38.82144],[-90.307628,38.772663],[-90.27997,38.771158]]]},"properties":{"id":"63033","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.350337,38.840253],[-90.30682,38.82144],[-90.229581,38.814669],[-90.234407,38.842003],[-90.289787,38.882838],[-90.350337,38.840253]]]},"properties":{"id":"63034","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.347838,38.68307],[-90.408716,38.696322],[-90.404676,38.676448],[-90.394252,38.66096],[-90.349783,38.661432],[-90.347838,38.68307]]]},"properties":{"id":"63132","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.998645,40.604434],[-91.985387,40.584789],[-91.943369,40.584317],[-91.943112,40.605827],[-91.998645,40.604434]]]},"properties":{"id":"52573","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.556229,37.039284],[-89.529026,37.005079],[-89.526032,37.008464],[-89.449173,37.039978],[-89.38506,37.058012],[-89.454125,37.185069],[-89.443384,37.163489],[-89.443259,37.15484],[-89.558918,37.145515],[-89.630864,37.115803],[-89.612693,37.057721],[-89.59447,37.042675],[-89.556229,37.039284]]]},"properties":{"id":"63736","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-89.443384,37.163489],[-89.450105,37.163475],[-89.450202,37.154548],[-89.443259,37.15484],[-89.443384,37.163489]]]},"properties":{"id":"63742","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.282164,36.746226],[-93.185693,36.725216],[-93.148799,36.726017],[-93.181416,36.814495],[-93.27261,36.816523],[-93.277197,36.81655],[-93.282164,36.746226]]]},"properties":{"id":"65771","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-93.109032,36.654582],[-93.112733,36.668675],[-93.134911,36.654831],[-93.109032,36.654582]]]},"properties":{"id":"65731","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-91.868052,37.239746],[-91.801067,37.224218],[-91.829913,37.280212],[-91.848174,37.280457],[-91.868052,37.239746]]]},"properties":{"id":"65589","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-92.337845,37.384201],[-92.215813,37.30579],[-92.253209,37.386537],[-92.337845,37.384201]]]},"properties":{"id":"65660","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.266219,38.032267],[-94.319808,37.999748],[-94.321589,37.972967],[-94.17386,37.845822],[-94.098358,37.868789],[-94.176888,37.976359],[-94.266219,38.032267]]]},"properties":{"id":"64790","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-94.528072,37.769031],[-94.403373,37.725478],[-94.511469,37.820565],[-94.528072,37.769031]]]},"properties":{"id":"64771","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.187385,38.635854],[-90.198682,38.626623],[-90.201681,38.618733],[-90.183799,38.614528],[-90.18145,38.659668],[-90.193545,38.652525],[-90.187385,38.635854]]]},"properties":{"id":"63102","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.23301,38.643971],[-90.222054,38.655459],[-90.231659,38.65977],[-90.262913,38.672568],[-90.272626,38.657125],[-90.23301,38.643971]]]},"properties":{"id":"63113","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-90.241854,38.604986],[-90.244657,38.581008],[-90.221103,38.576798],[-90.201607,38.589337],[-90.241175,38.608091],[-90.241854,38.604986]]]},"properties":{"id":"63118","state_code":"29"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.845139,45.265049],[-108.115808,45.293133],[-107.851751,45.105521],[-107.880053,45.166465],[-107.845139,45.265049]]]},"properties":{"id":"59035","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.672999,45.440665],[-108.699432,45.329781],[-108.447076,45.218889],[-108.316968,45.460509],[-108.672999,45.440665]]]},"properties":{"id":"59066","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.473048,46.149726],[-107.125413,45.888827],[-107.122465,45.768039],[-107.017052,45.691437],[-106.912997,45.684087],[-106.891118,45.83169],[-107.007415,46.266806],[-107.137981,46.263543],[-107.137059,46.293607],[-107.007357,46.290041],[-107.153579,46.467787],[-107.413415,46.482317],[-107.738547,46.481715],[-107.473048,46.149726]]]},"properties":{"id":"59038","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.306416,45.451406],[-107.527913,45.533446],[-107.479453,45.434972],[-107.306416,45.451406]]]},"properties":{"id":"59031","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.876687,45.684039],[-106.768606,45.352883],[-106.526681,45.387252],[-106.358389,45.49399],[-106.478069,45.688903],[-106.706932,45.676441],[-106.876687,45.684039]]]},"properties":{"id":"59043","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.236497,48.999609],[-108.864082,48.828315],[-108.684309,48.577657],[-108.467664,48.655415],[-108.236368,48.985936],[-108.236497,48.999609]]]},"properties":{"id":"59529","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-108.865627,45.435355],[-108.882123,45.435043],[-108.88198,45.42716],[-108.865627,45.435355]]],[[[-108.865627,45.435355],[-108.750844,45.508558],[-108.882129,45.435921],[-108.865627,45.435355]]]]},"properties":{"id":"59026","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.436602,46.237554],[-104.387106,46.165124],[-104.239745,46.212505],[-104.438169,46.281152],[-104.436602,46.237554]]]},"properties":{"id":"59354","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.300593,48.341834],[-114.73463,48.226909],[-114.350939,48.035713],[-114.322055,48.048915],[-114.287994,48.059693],[-114.116645,48.092223],[-113.920108,48.140728],[-114.07904,48.284451],[-114.300593,48.341834]]]},"properties":{"id":"59901","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.176251,48.030044],[-114.287994,48.059693],[-114.322055,48.048915],[-114.321807,47.962401],[-114.212858,47.942496],[-114.110845,47.958842],[-114.176251,48.030044]]]},"properties":{"id":"59922","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.950648,47.839922],[-113.848041,47.600423],[-113.633957,47.599861],[-113.920108,48.140728],[-114.116645,48.092223],[-114.176251,48.030044],[-114.110845,47.958842],[-114.101635,47.884919],[-113.950648,47.839922]]]},"properties":{"id":"59911","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.77066,48.355044],[-113.357533,48.317083],[-113.744027,48.653347],[-114.068153,48.998917],[-114.188611,48.99952],[-114.163977,48.603552],[-114.070851,48.465309],[-113.851485,48.366107],[-113.841554,48.370387],[-113.77066,48.355044]]]},"properties":{"id":"59936","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.383717,48.956719],[-110.467289,48.740335],[-110.367077,48.335385],[-110.171538,48.422009],[-110.133476,48.798289],[-110.383717,48.956719]]]},"properties":{"id":"59525","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.367077,48.335385],[-110.467289,48.740335],[-110.432151,48.306567],[-110.367363,48.306114],[-110.367077,48.335385]]]},"properties":{"id":"59528","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.041087,48.421952],[-110.133476,48.798289],[-110.171538,48.422009],[-110.041087,48.421952]]]},"properties":{"id":"59532","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.636327,46.913123],[-110.652877,46.823626],[-110.511893,46.712496],[-110.503446,46.708985],[-110.030594,47.040621],[-110.021287,47.199726],[-110.127964,47.300948],[-110.220508,47.320747],[-110.561061,47.056595],[-110.636327,46.913123]]]},"properties":{"id":"59479","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.767077,47.32042],[-110.641125,47.095477],[-110.561061,47.056595],[-110.220508,47.320747],[-110.211599,47.359103],[-110.213293,47.416659],[-110.511751,47.416495],[-110.639564,47.416259],[-110.767077,47.32042]]]},"properties":{"id":"59447","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.330869,46.656252],[-112.29911,46.618105],[-111.967471,46.611977],[-111.723279,46.569204],[-111.635758,46.569115],[-111.497961,46.762469],[-111.658131,46.913364],[-111.790092,46.91393],[-112.176334,46.855001],[-112.288148,46.752139],[-112.31119,46.713473],[-112.330869,46.656252]],[[-112.125837,46.615745],[-112.116538,46.630373],[-112.09485,46.630657],[-112.095421,46.612278],[-112.125837,46.615745]]]},"properties":{"id":"59602","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.316947,47.01529],[-112.535369,46.77173],[-112.464366,46.699479],[-112.330869,46.656252],[-112.31119,46.713473],[-112.331474,46.728013],[-112.288148,46.752139],[-112.176334,46.855001],[-112.316947,47.01529]]]},"properties":{"id":"59633","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.056027,48.840589],[-115.009694,48.716142],[-114.61911,48.742601],[-114.727548,48.821171],[-115.056027,48.840589]]]},"properties":{"id":"59918","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.086777,47.180938],[-105.685707,47.181098],[-106.170464,47.325601],[-106.170196,47.267609],[-106.170434,47.18093],[-106.086777,47.180938]]]},"properties":{"id":"59214","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-106.658964,47.927816],[-106.524447,47.984165],[-106.610104,47.978446],[-106.658964,47.927816]]],[[[-106.493423,48.000605],[-106.519622,47.986896],[-106.4904,47.962237],[-106.260982,47.714145],[-105.876471,47.8295],[-105.868218,47.844426],[-106.363704,48.046508],[-106.490645,48.001089],[-106.493423,48.000605]]]]},"properties":{"id":"59223","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.255966,45.680528],[-112.557558,45.538897],[-112.505346,45.443048],[-112.284457,45.22162],[-112.25603,45.332379],[-112.072235,45.568095],[-112.10452,45.616301],[-112.255966,45.680528]]]},"properties":{"id":"59754","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.835294,44.938911],[-111.893638,44.78101],[-111.472921,44.709214],[-111.377682,44.751744],[-111.377955,44.852242],[-111.676318,45.232332],[-111.835294,44.938911]]]},"properties":{"id":"59720","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.563281,47.067335],[-114.934872,46.919691],[-114.674995,46.736866],[-114.377439,46.837644],[-114.376126,47.008373],[-114.563281,47.067335]]]},"properties":{"id":"59820","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.658288,47.174167],[-113.466429,47.282903],[-113.633957,47.599861],[-113.848041,47.600423],[-113.948763,47.600517],[-113.887652,47.36477],[-113.824845,47.274222],[-113.658288,47.174167]]]},"properties":{"id":"59826","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.883324,46.489622],[-108.778898,46.219216],[-108.572483,46.13283],[-108.404681,46.221542],[-108.297211,46.264155],[-108.155569,46.550591],[-108.366496,46.750546],[-108.609979,46.906213],[-108.715592,46.749466],[-108.973038,46.750863],[-108.883324,46.489622]]]},"properties":{"id":"59072","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.247223,45.177142],[-109.960462,45.018459],[-109.962007,45.003207],[-109.798687,45.002188],[-109.798221,45.167405],[-109.812235,45.171916],[-110.063819,45.172102],[-110.247223,45.177142]]]},"properties":{"id":"59020","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.918634,45.351324],[-111.045366,45.07372],[-110.661755,45.176857],[-110.718797,45.365812],[-110.917716,45.367057],[-110.918634,45.351324]]]},"properties":{"id":"59027","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.597043,47.99221],[-108.885183,47.922741],[-108.891022,47.734407],[-108.675337,47.622191],[-108.36439,47.576066],[-108.371487,47.792465],[-108.597043,47.99221]]]},"properties":{"id":"59546","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.754498,48.34993],[-111.982848,48.394234],[-112.308093,48.186904],[-112.264253,48.131137],[-112.221236,48.056497],[-112.199536,48.038713],[-111.983757,48.005319],[-111.409097,48.132218],[-111.754498,48.34993]]]},"properties":{"id":"59425","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.279682,45.179892],[-106.263586,44.993788],[-106.019638,44.99358],[-106.043098,45.11336],[-106.279609,45.21397],[-106.279682,45.179892]]]},"properties":{"id":"59062","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.680188,46.622952],[-112.828963,46.484385],[-112.619418,46.496243],[-112.680188,46.622952]]]},"properties":{"id":"59731","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.817137,46.588895],[-114.329765,46.575665],[-114.383591,46.466589],[-114.099063,46.406811],[-113.857017,46.419184],[-113.817137,46.588895]]]},"properties":{"id":"59870","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.523752,47.86767],[-104.510573,47.572917],[-104.261446,47.586644],[-104.221291,47.578153],[-104.269832,47.555873],[-104.420373,47.354596],[-104.420263,47.354596],[-104.045069,47.397461],[-104.042725,47.781862],[-104.442495,47.939871],[-104.523752,47.86767]]]},"properties":{"id":"59270","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.442495,47.939871],[-104.042725,47.781862],[-104.04412,47.996107],[-104.474795,48.12364],[-104.442495,47.939871]]]},"properties":{"id":"59221","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.840429,48.476475],[-104.755766,48.301901],[-104.319483,48.230422],[-104.173081,48.389369],[-104.367883,48.389422],[-104.578979,48.389278],[-104.757861,48.476556],[-104.840429,48.476475]]]},"properties":{"id":"59226","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.474795,48.12364],[-104.04412,47.996107],[-104.046765,48.389429],[-104.173081,48.389369],[-104.319483,48.230422],[-104.474795,48.12364]]]},"properties":{"id":"59212","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.752272,47.758767],[-114.731672,47.638894],[-114.626202,47.702985],[-114.752272,47.758767]]]},"properties":{"id":"59848","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.935002,48.821797],[-104.7769,48.633356],[-104.448834,48.64997],[-104.253443,48.794909],[-104.57005,48.823731],[-104.935002,48.821797]]]},"properties":{"id":"59254","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.296847,48.57758],[-104.757861,48.476556],[-104.578979,48.389278],[-104.324852,48.494853],[-104.296847,48.57758]]]},"properties":{"id":"59247","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.578979,48.389278],[-104.367883,48.389422],[-104.324852,48.494853],[-104.578979,48.389278]]]},"properties":{"id":"59242","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.994768,45.98942],[-109.172501,45.798064],[-109.063487,45.725205],[-108.904962,45.740837],[-108.80283,45.74058],[-108.755616,45.870833],[-108.776183,45.944022],[-108.994768,45.98942]]]},"properties":{"id":"59057","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.063487,45.725205],[-109.035245,45.596345],[-108.924111,45.605176],[-108.904962,45.740837],[-109.063487,45.725205]]]},"properties":{"id":"59063","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.711178,46.219297],[-110.008575,46.220008],[-109.591221,46.045262],[-109.616412,46.13207],[-109.711178,46.219297]]]},"properties":{"id":"59055","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.408762,47.944063],[-111.983553,47.991764],[-111.901426,47.813903],[-111.408874,47.784641],[-111.408762,47.944063]]]},"properties":{"id":"59433","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.594884,48.023925],[-112.221236,48.056497],[-112.264253,48.131137],[-112.65643,48.13271],[-112.900374,48.13247],[-113.016894,48.13246],[-112.951196,48.044686],[-112.594884,48.023925]]]},"properties":{"id":"59467","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.754498,48.34993],[-111.275597,48.408694],[-111.512946,48.696761],[-111.862138,48.653211],[-112.189346,48.647312],[-112.184375,48.477593],[-111.982848,48.394234],[-111.754498,48.34993]]]},"properties":{"id":"59474","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.516338,45.938638],[-113.937974,45.695749],[-113.686141,45.257093],[-113.278823,45.519095],[-113.516338,45.938638]]]},"properties":{"id":"59761","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.375151,45.768209],[-107.25567,45.768305],[-107.125413,45.888827],[-107.473048,46.149726],[-107.511497,46.043257],[-107.375151,45.768209]]]},"properties":{"id":"59010","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.236368,48.985936],[-108.467664,48.655415],[-108.455124,48.568505],[-108.346193,48.56845],[-108.31873,48.684187],[-108.236368,48.985936]]]},"properties":{"id":"59542","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.16129,45.185927],[-109.173118,45.086281],[-109.085147,45.174192],[-109.16129,45.185927]]]},"properties":{"id":"59007","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.063487,45.725205],[-109.172501,45.798064],[-109.339149,45.841669],[-109.469912,45.596676],[-109.356178,45.464446],[-109.200928,45.464302],[-109.035245,45.596345],[-109.063487,45.725205]]]},"properties":{"id":"59019","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.458942,45.436467],[-105.038538,45.156845],[-105.0383,45.090632],[-105.038405,45.000345],[-104.040394,45.346985],[-104.263562,45.470293],[-104.458942,45.436467]]]},"properties":{"id":"59311","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.155689,47.843169],[-110.976382,47.698605],[-110.896984,47.759827],[-110.874299,47.762838],[-111.155689,47.843169]]]},"properties":{"id":"59440","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.047164,47.437053],[-111.821252,47.402998],[-111.815528,47.480552],[-111.92253,47.505078],[-112.009634,47.512674],[-112.047164,47.437053]]]},"properties":{"id":"59477","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.251645,47.323352],[-111.285957,47.000707],[-111.080633,47.087892],[-111.00386,47.121409],[-111.103584,47.377146],[-111.251645,47.323352]]]},"properties":{"id":"59480","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.367363,48.306114],[-109.644335,48.132793],[-110.041087,48.421952],[-110.171538,48.422009],[-110.367077,48.335385],[-110.367363,48.306114]]]},"properties":{"id":"59521","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.596146,46.578255],[-105.418871,46.570854],[-104.870561,46.569101],[-105.117082,46.855899],[-105.324785,47.093263],[-105.406237,47.181563],[-105.685707,47.181098],[-106.086777,47.180938],[-106.08667,46.860264],[-105.57608,46.720185],[-105.596146,46.578255]]]},"properties":{"id":"59349","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.826467,48.925112],[-105.758097,48.563235],[-105.371331,48.563177],[-105.294719,48.563132],[-105.278501,48.881925],[-105.322169,48.999335],[-105.945964,48.999677],[-105.826467,48.925112]]]},"properties":{"id":"59263","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.278501,48.881925],[-105.294719,48.563132],[-104.973354,48.56319],[-104.973353,48.632891],[-105.038187,48.881874],[-105.278501,48.881925]]]},"properties":{"id":"59222","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.705293,47.412892],[-104.420373,47.354596],[-104.269832,47.555873],[-104.261446,47.586644],[-104.510573,47.572917],[-104.700818,47.616147],[-104.700812,47.601699],[-104.705293,47.412892]]]},"properties":{"id":"59262","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.211599,47.359103],[-110.127964,47.300948],[-110.021287,47.199726],[-109.803004,47.185564],[-109.638388,47.413417],[-109.590061,47.559252],[-109.645874,47.736286],[-109.829623,47.715278],[-110.213293,47.416659],[-110.211599,47.359103]]]},"properties":{"id":"59430","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.638388,47.413417],[-109.110021,47.255484],[-109.207991,47.472731],[-109.590061,47.559252],[-109.638388,47.413417]]]},"properties":{"id":"59451","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.053012,48.40866],[-113.92793,48.356627],[-113.851485,48.366107],[-114.070851,48.465309],[-114.053012,48.40866]]]},"properties":{"id":"59913","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.477397,47.808644],[-114.492147,47.886097],[-114.492347,47.943626],[-114.626722,47.944323],[-114.828851,47.875783],[-114.752272,47.758767],[-114.626202,47.702985],[-114.731672,47.638894],[-114.457912,47.32687],[-114.33729,47.376854],[-114.282757,47.47141],[-114.350245,47.601923],[-114.475073,47.72222],[-114.477397,47.808644]]]},"properties":{"id":"59845","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.918634,45.351324],[-111.350907,45.6045],[-111.350897,45.584693],[-111.373033,45.345454],[-111.372376,44.911742],[-111.056102,44.91241],[-111.044275,45.001345],[-111.045366,45.07372],[-110.918634,45.351324]]]},"properties":{"id":"59730","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.392921,48.65301],[-113.240859,48.998756],[-113.609138,48.998306],[-113.392921,48.65301]]]},"properties":{"id":"59411","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.661991,46.004957],[-112.621535,45.893117],[-112.567417,45.791753],[-112.22437,46.041625],[-112.339781,46.204562],[-112.585621,46.239531],[-112.694534,46.089531],[-112.661991,46.004957]],[[-112.528879,46.051146],[-112.535219,46.035173],[-112.546209,46.036986],[-112.545074,46.048341],[-112.528879,46.051146]]]},"properties":{"id":"59701","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.22437,46.041625],[-112.567417,45.791753],[-112.574521,45.748371],[-112.534819,45.697721],[-112.255966,45.680528],[-112.10452,45.616301],[-112.037402,45.7312],[-112.003914,45.789777],[-111.988894,46.011052],[-112.22437,46.041625]]]},"properties":{"id":"59759","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.138995,47.138096],[-113.979196,47.049501],[-113.821478,47.241729],[-114.168791,47.257738],[-114.20291,47.280203],[-114.335867,47.149568],[-114.309585,47.13737],[-114.138995,47.138096]]]},"properties":{"id":"59821","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.790092,46.91393],[-112.046262,47.364708],[-112.428878,47.142691],[-112.316947,47.01529],[-112.176334,46.855001],[-111.790092,46.91393]]]},"properties":{"id":"59648","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.125837,46.615745],[-112.095421,46.612278],[-112.09485,46.630657],[-112.116538,46.630373],[-112.125837,46.615745]]]},"properties":{"id":"59636","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.363704,48.046508],[-105.868218,47.844426],[-105.662582,47.707243],[-105.194221,47.984086],[-105.371331,48.563177],[-105.758097,48.563235],[-105.913152,48.563337],[-106.108337,48.006233],[-106.363704,48.046508]]]},"properties":{"id":"59201","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.024614,45.347844],[-112.25603,45.332379],[-112.284457,45.22162],[-112.146719,44.826143],[-111.893638,44.78101],[-111.835294,44.938911],[-111.890465,45.163075],[-112.024614,45.347844]]]},"properties":{"id":"59710","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.293651,47.354537],[-115.303125,47.476084],[-115.395253,47.484951],[-115.293651,47.354537]]]},"properties":{"id":"59830","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.370598,47.009016],[-114.309585,47.13737],[-114.335867,47.149568],[-114.482489,47.219981],[-114.687155,47.27426],[-114.797235,47.268901],[-114.563281,47.067335],[-114.376126,47.008373],[-114.370598,47.009016]]]},"properties":{"id":"59846","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.778898,46.219216],[-109.082847,46.13204],[-108.994768,45.98942],[-108.776183,45.944022],[-108.675096,46.0889],[-108.572483,46.13283],[-108.778898,46.219216]]]},"properties":{"id":"59015","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.220356,45.747423],[-110.23054,45.74118],[-110.220324,45.736334],[-110.220356,45.747423]]]},"properties":{"id":"59082","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.312615,47.583452],[-108.601709,47.270433],[-108.609979,46.906213],[-108.366496,46.750546],[-108.008613,46.755085],[-107.892117,46.851537],[-107.920294,47.443943],[-107.908907,47.450638],[-108.312615,47.583452]]]},"properties":{"id":"59087","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.048696,48.310028],[-112.901078,48.159354],[-112.586805,48.309874],[-113.048696,48.310028]]]},"properties":{"id":"59448","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.661991,46.004957],[-112.694534,46.089531],[-112.733954,46.011912],[-112.661991,46.004957]]]},"properties":{"id":"59748","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.339149,45.841669],[-109.172501,45.798064],[-108.994768,45.98942],[-109.082847,46.13204],[-109.169882,46.132067],[-109.417297,46.044485],[-109.423259,45.960214],[-109.339149,45.841669]]]},"properties":{"id":"59067","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.202946,46.521253],[-110.281858,46.220519],[-110.015241,46.220118],[-110.202946,46.521253]]]},"properties":{"id":"59085","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.566064,45.1436],[-113.055442,44.64022],[-112.360219,44.788502],[-112.146719,44.826143],[-112.284457,45.22162],[-112.505346,45.443048],[-112.841045,45.565255],[-112.779199,45.677546],[-112.96689,45.555783],[-113.017191,45.441566],[-113.182549,45.320878],[-113.566064,45.1436]]]},"properties":{"id":"59725","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.360219,44.788502],[-113.055442,44.64022],[-112.78636,44.47875],[-112.360219,44.788502]]]},"properties":{"id":"59724","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.669274,45.942161],[-107.511497,46.043257],[-107.473048,46.149726],[-107.738547,46.481715],[-107.781965,46.424213],[-108.029531,46.263812],[-107.840458,46.041695],[-107.798047,45.985681],[-107.669274,45.942161]]]},"properties":{"id":"59024","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.785913,46.266295],[-111.274254,46.187866],[-111.06256,46.192897],[-111.497961,46.762469],[-111.635758,46.569115],[-111.786119,46.424862],[-111.786221,46.352348],[-111.786177,46.314629],[-111.785913,46.266295]]]},"properties":{"id":"59644","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.526061,45.642248],[-111.321403,45.955531],[-111.349387,46.124767],[-111.784206,46.093663],[-111.784333,46.050004],[-111.659995,45.833512],[-111.556942,45.671202],[-111.526061,45.642248]]]},"properties":{"id":"59752","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.967471,46.611977],[-111.935142,46.569194],[-111.786014,46.475834],[-111.723279,46.569204],[-111.967471,46.611977]]]},"properties":{"id":"59635","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.047038,45.347592],[-108.938976,45.347051],[-108.88198,45.42716],[-108.882123,45.435043],[-108.882129,45.435921],[-108.750844,45.508558],[-108.699089,45.508324],[-108.699178,45.518823],[-108.924111,45.605176],[-109.035245,45.596345],[-109.200928,45.464302],[-109.102186,45.449161],[-109.100046,45.457586],[-109.055962,45.461285],[-109.047038,45.347592]]]},"properties":{"id":"59041","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.16104,45.23283],[-109.37473,45.449819],[-109.452767,45.382607],[-109.691105,45.167298],[-109.798221,45.167405],[-109.798687,45.002188],[-109.250218,45.005539],[-109.173118,45.086281],[-109.16129,45.185927],[-109.16104,45.23283]]]},"properties":{"id":"59068","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.458942,45.436467],[-104.981341,45.52969],[-105.038538,45.156845],[-104.458942,45.436467]]]},"properties":{"id":"59332","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.499177,47.553297],[-111.695435,47.527651],[-111.527906,47.446996],[-111.500842,47.431212],[-111.312758,47.506893],[-111.291082,47.520538],[-111.242615,47.528988],[-111.049847,47.598333],[-110.976382,47.698605],[-111.155689,47.843169],[-111.322498,47.943153],[-111.408762,47.944063],[-111.408874,47.784641],[-111.499177,47.553297]]]},"properties":{"id":"59404","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.251645,47.323352],[-111.103584,47.377146],[-111.116573,47.460168],[-111.251645,47.323352]]]},"properties":{"id":"59472","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.00386,47.121409],[-110.772285,47.096121],[-110.767077,47.32042],[-110.639564,47.416259],[-110.863942,47.52506],[-111.116573,47.460168],[-111.103584,47.377146],[-111.00386,47.121409]]]},"properties":{"id":"59412","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.03612,46.402011],[-113.036834,46.266822],[-112.777791,46.22293],[-112.776903,46.208557],[-112.558457,46.265925],[-112.49838,46.341834],[-112.571927,46.482316],[-112.619418,46.496243],[-112.828963,46.484385],[-113.03612,46.402011]]]},"properties":{"id":"59722","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.777791,46.22293],[-112.873403,46.183039],[-112.8777,46.13881],[-112.782827,46.181764],[-112.776903,46.208557],[-112.777791,46.22293]]]},"properties":{"id":"59756","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.51584,46.136344],[-104.044028,45.881971],[-104.045268,46.641443],[-104.581079,46.68483],[-104.438169,46.281152],[-104.239745,46.212505],[-104.387106,46.165124],[-104.436602,46.237554],[-104.51584,46.136344]]]},"properties":{"id":"59313","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.745747,46.799039],[-109.972477,46.751357],[-110.029697,46.736495],[-109.65388,46.49007],[-109.388677,46.546823],[-109.388963,46.751009],[-109.494595,46.791035],[-109.745747,46.799039]]]},"properties":{"id":"59453","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.23018,48.486461],[-114.494603,48.605526],[-114.849776,48.455936],[-114.848465,48.366627],[-114.847946,48.226159],[-114.73463,48.226909],[-114.300593,48.341834],[-114.23018,48.486461]]]},"properties":{"id":"59937","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-114.368241,47.962194],[-114.321807,47.962401],[-114.322055,48.048915],[-114.350939,48.035713],[-114.368241,47.962194]]],[[[-114.287994,48.059693],[-114.176251,48.030044],[-114.116645,48.092223],[-114.287994,48.059693]]]]},"properties":{"id":"59932","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.350907,45.6045],[-110.918634,45.351324],[-110.917716,45.367057],[-110.91866,45.456583],[-111.081788,45.76572],[-111.248023,45.772626],[-111.351346,45.641938],[-111.350907,45.6045]]]},"properties":{"id":"59718","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-113.392921,48.65301],[-113.609138,48.998306],[-114.068153,48.998917],[-113.744027,48.653347],[-113.392921,48.65301]]],[[[-112.586805,48.309874],[-112.576892,48.483478],[-112.96211,48.998689],[-113.240859,48.998756],[-113.392921,48.65301],[-113.048696,48.310028],[-112.586805,48.309874]]]]},"properties":{"id":"59417","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.610728,48.971919],[-110.755575,48.408123],[-110.755807,48.247055],[-110.610728,48.971919]]]},"properties":{"id":"59530","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.988894,46.011052],[-112.003914,45.789777],[-111.867564,45.730669],[-111.760252,45.772589],[-111.659995,45.833512],[-111.784333,46.050004],[-111.988894,46.011052]]]},"properties":{"id":"59721","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.352987,47.885032],[-114.255033,47.884934],[-114.212858,47.942496],[-114.321807,47.962401],[-114.368241,47.962194],[-114.492347,47.943626],[-114.492147,47.886097],[-114.352987,47.885032]]]},"properties":{"id":"59929","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.212858,47.942496],[-114.255033,47.884934],[-114.18252,47.872517],[-114.101635,47.884919],[-114.110845,47.958842],[-114.212858,47.942496]]]},"properties":{"id":"59931","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.61911,48.742601],[-115.009694,48.716142],[-115.019382,48.664642],[-115.042168,48.598534],[-114.848465,48.366627],[-114.849776,48.455936],[-114.76582,48.659004],[-114.73126,48.683866],[-114.689874,48.65899],[-114.648377,48.731328],[-114.61911,48.742601]]]},"properties":{"id":"59934","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.883821,45.379879],[-111.660522,45.503649],[-111.915905,45.560262],[-112.034881,45.552366],[-111.97277,45.516498],[-111.883821,45.379879]]]},"properties":{"id":"59740","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.037402,45.7312],[-111.867564,45.730669],[-112.003914,45.789777],[-112.037402,45.7312]]]},"properties":{"id":"59747","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.255966,45.680528],[-112.534819,45.697721],[-112.68719,45.600795],[-112.557558,45.538897],[-112.255966,45.680528]]]},"properties":{"id":"59751","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.674995,46.736866],[-114.332887,46.660756],[-114.05081,46.728105],[-114.094705,46.803845],[-114.377439,46.837644],[-114.674995,46.736866]]]},"properties":{"id":"59847","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.850708,46.881935],[-113.479916,46.802438],[-113.303005,46.832132],[-113.302868,47.106559],[-113.46287,47.077607],[-113.845612,46.923277],[-113.850708,46.881935]]]},"properties":{"id":"59823","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.008613,46.755085],[-108.09206,46.585175],[-107.781965,46.424213],[-107.738547,46.481715],[-107.413415,46.482317],[-107.485203,46.851316],[-107.892117,46.851537],[-108.008613,46.755085]]]},"properties":{"id":"59054","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.292662,45.785022],[-110.795493,45.719313],[-110.91866,45.456583],[-110.917716,45.367057],[-110.718797,45.365812],[-110.648054,45.166575],[-110.444581,45.206987],[-110.218449,45.627439],[-110.220324,45.736334],[-110.23054,45.74118],[-110.220356,45.747423],[-110.292662,45.785022]]]},"properties":{"id":"59047","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.718797,45.365812],[-110.661755,45.176857],[-110.648054,45.166575],[-110.718797,45.365812]]]},"properties":{"id":"59065","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.31873,48.684187],[-108.0542,48.633302],[-107.85013,48.684158],[-107.831825,48.999831],[-108.236497,48.999609],[-108.236368,48.985936],[-108.31873,48.684187]]]},"properties":{"id":"59537","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.215579,46.338393],[-114.239907,46.341968],[-114.228337,46.323698],[-114.215579,46.338393]]]},"properties":{"id":"59841","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.442495,47.939871],[-104.474795,48.12364],[-104.319483,48.230422],[-104.755766,48.301901],[-104.676842,47.946966],[-104.523752,47.86767],[-104.442495,47.939871]]]},"properties":{"id":"59218","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.656042,45.995085],[-106.686413,46.051095],[-106.741327,46.062189],[-106.715971,45.953117],[-106.656042,45.995085]]]},"properties":{"id":"59323","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.154899,46.304328],[-106.198053,46.274912],[-106.154869,46.274792],[-106.154899,46.304328]]]},"properties":{"id":"59333","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.935002,48.821797],[-104.57005,48.823731],[-104.569987,48.838193],[-104.57472,48.983092],[-104.574508,48.999471],[-105.057514,48.999178],[-105.038938,48.910672],[-104.935002,48.821797]]]},"properties":{"id":"59252","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.528879,46.051146],[-112.545074,46.048341],[-112.546209,46.036986],[-112.535219,46.035173],[-112.528879,46.051146]]]},"properties":{"id":"59703","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.566133,45.493211],[-109.812235,45.171916],[-109.798221,45.167405],[-109.691105,45.167298],[-109.500175,45.406126],[-109.566133,45.493211]]]},"properties":{"id":"59028","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.423259,45.960214],[-109.62871,45.784933],[-109.697225,45.700406],[-109.835868,45.674079],[-109.88128,45.674025],[-109.931136,45.522604],[-109.808808,45.551396],[-109.469912,45.596676],[-109.339149,45.841669],[-109.423259,45.960214]]]},"properties":{"id":"59069","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.193406,48.972147],[-112.18928,48.783831],[-111.862935,48.769081],[-111.604056,48.938542],[-112.193406,48.972147]]]},"properties":{"id":"59482","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.645741,48.664797],[-107.414722,47.804038],[-107.414886,47.691677],[-107.221679,47.661636],[-106.692988,47.776712],[-106.658964,47.927816],[-106.610104,47.978446],[-106.493423,48.000605],[-106.490645,48.001089],[-106.493551,48.479082],[-106.479185,48.664696],[-106.645741,48.664797]],[[-106.563589,48.382565],[-106.563379,48.407765],[-106.545526,48.399246],[-106.545093,48.385668],[-106.563589,48.382565]]]},"properties":{"id":"59230","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.999343,48.563236],[-106.108337,48.006233],[-105.913152,48.563337],[-105.999343,48.563236]]]},"properties":{"id":"59225","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.755616,45.870833],[-108.80283,45.74058],[-108.67572,45.697049],[-108.604346,45.732603],[-108.568094,45.803753],[-108.633534,45.88996],[-108.755616,45.870833]]]},"properties":{"id":"59106","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.278823,45.519095],[-113.686141,45.257093],[-113.566064,45.1436],[-113.182549,45.320878],[-113.152652,45.497315],[-113.278823,45.519095]]]},"properties":{"id":"59736","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.102492,45.321351],[-107.020255,45.186242],[-106.768046,45.186082],[-106.768383,45.258951],[-106.768606,45.352883],[-106.876687,45.684039],[-106.912997,45.684087],[-107.017052,45.691437],[-107.019337,45.529606],[-107.195377,45.445371],[-107.102492,45.321351]]]},"properties":{"id":"59016","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.500175,45.406126],[-109.691105,45.167298],[-109.452767,45.382607],[-109.500175,45.406126]]]},"properties":{"id":"59071","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.961682,45.138272],[-108.447076,45.218889],[-108.699432,45.329781],[-108.938976,45.347051],[-109.047038,45.347592],[-109.16104,45.23283],[-109.16129,45.185927],[-109.085147,45.174192],[-108.961682,45.138272]]]},"properties":{"id":"59014","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.37473,45.449819],[-109.16104,45.23283],[-109.047038,45.347592],[-109.055962,45.461285],[-109.102186,45.449161],[-109.200928,45.464302],[-109.356178,45.464446],[-109.37473,45.449819]]]},"properties":{"id":"59070","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.493335,47.639863],[-110.062865,47.820716],[-110.224389,48.031435],[-110.632439,48.219525],[-110.755649,48.219671],[-111.007617,48.132952],[-111.143747,48.132866],[-111.129342,48.031777],[-110.874299,47.762838],[-110.896984,47.759827],[-110.493335,47.639863]]]},"properties":{"id":"59442","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.632439,48.219525],[-110.224389,48.031435],[-110.52217,48.306791],[-110.632439,48.219525]]]},"properties":{"id":"59460","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.826467,48.925112],[-106.021307,48.563225],[-105.999343,48.563236],[-105.913152,48.563337],[-105.758097,48.563235],[-105.826467,48.925112]]]},"properties":{"id":"59253","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.057514,48.999178],[-105.322169,48.999335],[-105.278501,48.881925],[-105.038187,48.881874],[-105.038938,48.910672],[-105.057514,48.999178]]]},"properties":{"id":"59276","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.732853,46.612672],[-104.678215,46.136008],[-104.51584,46.136344],[-104.436602,46.237554],[-104.438169,46.281152],[-104.581079,46.68483],[-104.607017,46.684926],[-104.732853,46.612672]]]},"properties":{"id":"59344","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.638388,47.413417],[-109.803004,47.185564],[-109.756513,47.124127],[-109.494595,46.791035],[-109.388963,46.751009],[-109.19631,46.751401],[-109.192299,46.751324],[-108.900895,46.991511],[-108.800144,47.202148],[-109.110021,47.255484],[-109.638388,47.413417]]]},"properties":{"id":"59457","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.972477,46.751357],[-109.745747,46.799039],[-109.830364,46.838148],[-109.972477,46.751357]]]},"properties":{"id":"59418","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.675337,47.622191],[-109.207991,47.472731],[-109.110021,47.255484],[-108.800144,47.202148],[-108.601709,47.270433],[-108.312615,47.583452],[-108.36439,47.576066],[-108.675337,47.622191]]]},"properties":{"id":"59471","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.76582,48.659004],[-114.849776,48.455936],[-114.494603,48.605526],[-114.648377,48.731328],[-114.689874,48.65899],[-114.76582,48.659004]]]},"properties":{"id":"59927","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.23018,48.486461],[-114.300593,48.341834],[-114.07904,48.284451],[-114.059959,48.37417],[-114.04796,48.389423],[-114.053012,48.40866],[-114.070851,48.465309],[-114.163977,48.603552],[-114.23018,48.486461]]]},"properties":{"id":"59912","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.73463,48.226909],[-114.626722,47.944323],[-114.492347,47.943626],[-114.368241,47.962194],[-114.350939,48.035713],[-114.73463,48.226909]]]},"properties":{"id":"59920","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.053012,48.40866],[-114.04796,48.389423],[-114.038853,48.364301],[-113.92793,48.356627],[-114.053012,48.40866]]]},"properties":{"id":"59926","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.081788,45.76572],[-110.91866,45.456583],[-110.795493,45.719313],[-110.788352,45.904959],[-110.783982,46.192408],[-110.790022,46.192567],[-110.981614,46.192729],[-111.081788,45.76572]]]},"properties":{"id":"59715","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.920294,47.443943],[-107.784956,47.433508],[-107.275993,47.291877],[-107.221679,47.661636],[-107.414886,47.691677],[-107.908907,47.450638],[-107.920294,47.443943]]]},"properties":{"id":"59318","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.19631,46.751401],[-109.169882,46.132067],[-109.082847,46.13204],[-108.778898,46.219216],[-108.883324,46.489622],[-109.009802,46.750432],[-109.192299,46.751324],[-109.19631,46.751401]]]},"properties":{"id":"59046","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.772627,46.245438],[-113.776925,46.031571],[-113.672795,46.01309],[-113.036834,46.266822],[-113.03612,46.402011],[-113.03609,46.411056],[-113.143795,46.488402],[-113.466482,46.571507],[-113.77437,46.314447],[-113.784117,46.284964],[-113.772627,46.245438]]]},"properties":{"id":"59858","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.479916,46.802438],[-113.850708,46.881935],[-113.863598,46.84288],[-113.856045,46.726644],[-113.817137,46.588895],[-113.857017,46.419184],[-113.77437,46.314447],[-113.466482,46.571507],[-113.384085,46.656553],[-113.479916,46.802438]]]},"properties":{"id":"59825","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.975663,48.997828],[-110.755575,48.408123],[-110.610728,48.971919],[-110.611149,48.998913],[-110.975663,48.997828]]]},"properties":{"id":"59531","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.285295,47.821472],[-114.352987,47.885032],[-114.492147,47.886097],[-114.477397,47.808644],[-114.285295,47.821472]]]},"properties":{"id":"59915","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.255033,47.884934],[-114.352987,47.885032],[-114.285295,47.821472],[-114.18252,47.872517],[-114.255033,47.884934]]]},"properties":{"id":"59914","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-105.868218,47.844426],[-105.876471,47.8295],[-105.662582,47.707243],[-105.868218,47.844426]]],[[[-105.662582,47.707243],[-105.36337,47.664975],[-105.195013,47.904328],[-105.194221,47.984086],[-105.662582,47.707243]]]]},"properties":{"id":"59274","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.783982,46.192408],[-110.598448,46.287261],[-110.765222,46.40848],[-110.790022,46.192567],[-110.783982,46.192408]]]},"properties":{"id":"59642","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.960462,45.018459],[-110.001507,45.003381],[-109.962007,45.003207],[-109.960462,45.018459]]]},"properties":{"id":"59081","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.444581,45.206987],[-110.648054,45.166575],[-110.661755,45.176857],[-111.045366,45.07372],[-111.044275,45.001345],[-110.001507,45.003381],[-109.960462,45.018459],[-110.247223,45.177142],[-110.444581,45.206987]]]},"properties":{"id":"59030","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.0542,48.633302],[-108.371487,47.792465],[-108.36439,47.576066],[-108.312615,47.583452],[-107.908907,47.450638],[-107.414886,47.691677],[-107.414722,47.804038],[-107.289641,48.304658],[-107.654259,48.625646],[-107.85013,48.684158],[-108.0542,48.633302]]]},"properties":{"id":"59538","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.448514,45.788423],[-105.673885,45.589101],[-105.282464,45.787697],[-105.448514,45.788423]]]},"properties":{"id":"59343","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.29911,46.618105],[-112.330869,46.656252],[-112.464366,46.699479],[-112.571927,46.482316],[-112.49838,46.341834],[-112.31061,46.421006],[-112.29911,46.618105]]]},"properties":{"id":"59728","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.888735,45.980423],[-114.08745,45.783341],[-114.027663,45.648132],[-113.937974,45.695749],[-113.516338,45.938638],[-113.517481,45.939898],[-113.672795,46.01309],[-113.776925,46.031571],[-113.888735,45.980423]]]},"properties":{"id":"59871","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.893224,45.985514],[-114.08745,45.783341],[-113.888735,45.980423],[-113.893224,45.985514]]]},"properties":{"id":"59827","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.849216,47.911192],[-104.700818,47.616147],[-104.510573,47.572917],[-104.523752,47.86767],[-104.676842,47.946966],[-104.849216,47.911192]]]},"properties":{"id":"59243","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.100951,46.859209],[-107.457602,46.860313],[-107.485203,46.851316],[-107.413415,46.482317],[-107.153579,46.467787],[-107.037426,46.85926],[-107.100951,46.859209]]]},"properties":{"id":"59039","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.968855,47.356029],[-114.687155,47.27426],[-114.482489,47.219981],[-114.457912,47.32687],[-114.731672,47.638894],[-114.752272,47.758767],[-114.828851,47.875783],[-114.98963,47.872819],[-115.156925,47.448038],[-114.968855,47.356029]]]},"properties":{"id":"59859","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.038187,48.881874],[-104.973353,48.632891],[-104.7769,48.633356],[-104.935002,48.821797],[-105.038938,48.910672],[-105.038187,48.881874]]]},"properties":{"id":"59257","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.591221,46.045262],[-110.008575,46.220008],[-110.015241,46.220118],[-110.281858,46.220519],[-110.28186,46.18421],[-110.292826,45.95808],[-110.292662,45.785022],[-110.220356,45.747423],[-110.220324,45.736334],[-110.218449,45.627439],[-109.88128,45.674025],[-109.835868,45.674079],[-109.62871,45.784933],[-109.423259,45.960214],[-109.417297,46.044485],[-109.591221,46.045262]]]},"properties":{"id":"59011","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.604056,48.938542],[-111.862935,48.769081],[-111.862138,48.653211],[-111.512946,48.696761],[-111.524796,48.912176],[-111.604056,48.938542]]]},"properties":{"id":"59466","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.633534,45.88996],[-108.568094,45.803753],[-108.533809,45.801744],[-108.363113,45.868566],[-108.320144,45.90384],[-108.591384,45.95814],[-108.633534,45.88996]]]},"properties":{"id":"59105","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.149213,45.939695],[-108.257135,45.967673],[-108.320144,45.90384],[-108.363113,45.868566],[-108.069337,45.711977],[-108.06906,45.782497],[-108.047394,45.797994],[-108.149213,45.939695]]]},"properties":{"id":"59037","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.675096,46.0889],[-108.776183,45.944022],[-108.755616,45.870833],[-108.633534,45.88996],[-108.591384,45.95814],[-108.675096,46.0889]]]},"properties":{"id":"59002","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.92364,45.935062],[-112.96689,45.555783],[-112.779199,45.677546],[-112.574521,45.748371],[-112.567417,45.791753],[-112.621535,45.893117],[-112.85115,45.930945],[-112.92364,45.935062]]]},"properties":{"id":"59727","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.360219,44.788502],[-112.78636,44.47875],[-111.472921,44.709214],[-111.893638,44.78101],[-112.146719,44.826143],[-112.360219,44.788502]]]},"properties":{"id":"59739","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.102492,45.321351],[-107.195377,45.445371],[-107.306416,45.451406],[-107.479453,45.434972],[-107.686343,45.421403],[-107.755938,45.220067],[-107.880053,45.166465],[-107.851751,45.105521],[-107.625955,45.000711],[-107.568058,45.177176],[-107.283241,45.186852],[-107.102492,45.321351]]]},"properties":{"id":"59050","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.527913,45.533446],[-107.306416,45.451406],[-107.195377,45.445371],[-107.019337,45.529606],[-107.419046,45.627984],[-107.527913,45.533446]]]},"properties":{"id":"59022","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.533809,45.801744],[-108.604346,45.732603],[-108.67572,45.697049],[-108.699178,45.518823],[-108.699089,45.508324],[-108.672999,45.440665],[-108.316968,45.460509],[-108.070656,45.518246],[-108.069701,45.51928],[-108.069337,45.711977],[-108.363113,45.868566],[-108.533809,45.801744]]]},"properties":{"id":"59101","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.045972,48.771419],[-109.489694,49.000585],[-109.533927,48.180567],[-109.070639,48.027942],[-108.887594,48.160888],[-108.980092,48.622593],[-109.045882,48.748232],[-109.045972,48.771419]]]},"properties":{"id":"59523","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.085147,45.174192],[-109.173118,45.086281],[-109.250218,45.005539],[-108.961682,45.138272],[-109.085147,45.174192]]]},"properties":{"id":"59008","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-108.865627,45.435355],[-108.882129,45.435921],[-108.882123,45.435043],[-108.865627,45.435355]]],[[[-108.938976,45.347051],[-108.699432,45.329781],[-108.672999,45.440665],[-108.699089,45.508324],[-108.750844,45.508558],[-108.865627,45.435355],[-108.88198,45.42716],[-108.938976,45.347051]]]]},"properties":{"id":"59029","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.808808,45.551396],[-109.566133,45.493211],[-109.500175,45.406126],[-109.452767,45.382607],[-109.37473,45.449819],[-109.356178,45.464446],[-109.469912,45.596676],[-109.808808,45.551396]]]},"properties":{"id":"59001","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.263562,45.470293],[-104.040394,45.346985],[-104.042362,45.635843],[-104.263562,45.470293]]]},"properties":{"id":"57724","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.743002,47.572406],[-111.815528,47.480552],[-111.821252,47.402998],[-111.614986,47.393343],[-111.527906,47.446996],[-111.695435,47.527651],[-111.743002,47.572406]]]},"properties":{"id":"59483","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.678215,46.136008],[-104.732853,46.612672],[-104.870561,46.569101],[-105.418871,46.570854],[-104.939509,45.853871],[-104.678215,46.136008]]]},"properties":{"id":"59336","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.57608,46.720185],[-105.672969,46.714082],[-105.75873,46.598444],[-105.596146,46.578255],[-105.57608,46.720185]]]},"properties":{"id":"59338","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.448514,45.788423],[-105.976978,45.650686],[-105.928885,45.593839],[-105.673885,45.589101],[-105.448514,45.788423]]]},"properties":{"id":"59351","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.200652,47.473444],[-105.087749,47.45675],[-104.700812,47.601699],[-104.700818,47.616147],[-104.849216,47.911192],[-104.894338,47.972642],[-105.195013,47.904328],[-105.36337,47.664975],[-105.200652,47.473444]]]},"properties":{"id":"59259","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.087749,47.45675],[-104.960104,47.326169],[-104.705293,47.412892],[-104.700812,47.601699],[-105.087749,47.45675]]]},"properties":{"id":"59315","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.324785,47.093263],[-104.960104,47.326169],[-105.087749,47.45675],[-105.200652,47.473444],[-105.406237,47.181563],[-105.324785,47.093263]]]},"properties":{"id":"59339","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.036834,46.266822],[-113.672795,46.01309],[-113.517481,45.939898],[-112.92364,45.935062],[-112.85115,45.930945],[-112.733954,46.011912],[-112.694534,46.089531],[-112.585621,46.239531],[-112.558457,46.265925],[-112.776903,46.208557],[-112.782827,46.181764],[-112.8777,46.13881],[-112.873403,46.183039],[-112.777791,46.22293],[-113.036834,46.266822]]]},"properties":{"id":"59711","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.830364,46.838148],[-109.745747,46.799039],[-109.494595,46.791035],[-109.756513,47.124127],[-109.755997,47.028689],[-109.830364,46.838148]]]},"properties":{"id":"59464","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.127964,47.300948],[-110.211599,47.359103],[-110.220508,47.320747],[-110.127964,47.300948]]]},"properties":{"id":"59424","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-114.059959,48.37417],[-114.038853,48.364301],[-114.04796,48.389423],[-114.059959,48.37417]]],[[[-113.841554,48.370387],[-113.144926,47.596363],[-112.984705,47.953982],[-112.951196,48.044686],[-113.016894,48.13246],[-113.228934,48.22265],[-113.77066,48.355044],[-113.841554,48.370387]]]]},"properties":{"id":"59919","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.676318,45.232332],[-111.377955,44.852242],[-111.372376,44.911742],[-111.373033,45.345454],[-111.676318,45.232332]]]},"properties":{"id":"59716","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.784956,47.433508],[-107.45768,46.913986],[-107.275993,47.291877],[-107.784956,47.433508]]]},"properties":{"id":"59077","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.048696,48.310028],[-113.392921,48.65301],[-113.744027,48.653347],[-113.357533,48.317083],[-113.34947,48.310155],[-113.048696,48.310028]]]},"properties":{"id":"59434","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.384085,46.656553],[-113.143795,46.488402],[-113.03609,46.411056],[-113.038315,46.613158],[-113.265209,46.8259],[-113.303005,46.832132],[-113.479916,46.802438],[-113.384085,46.656553]]]},"properties":{"id":"59832","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.133476,48.798289],[-110.041087,48.421952],[-109.644335,48.132793],[-109.534295,48.135086],[-109.533927,48.180567],[-109.489694,49.000585],[-110.53103,48.998414],[-110.383717,48.956719],[-110.133476,48.798289]]]},"properties":{"id":"59501","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.079995,46.404312],[-111.786221,46.352348],[-111.786119,46.424862],[-111.786014,46.475834],[-111.935142,46.569194],[-112.233799,46.428879],[-112.205691,46.421515],[-112.079995,46.404312]]]},"properties":{"id":"59634","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.22437,46.041625],[-111.988894,46.011052],[-111.784333,46.050004],[-111.784206,46.093663],[-111.785913,46.266295],[-111.786177,46.314629],[-112.079995,46.404312],[-112.205691,46.421515],[-112.339781,46.204562],[-112.22437,46.041625]]]},"properties":{"id":"59632","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.967471,46.611977],[-112.29911,46.618105],[-112.31061,46.421006],[-112.233799,46.428879],[-111.935142,46.569194],[-111.967471,46.611977]]]},"properties":{"id":"59601","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.767077,47.32042],[-110.772285,47.096121],[-110.641125,47.095477],[-110.767077,47.32042]]]},"properties":{"id":"59469","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.285295,47.821472],[-114.477397,47.808644],[-114.475073,47.72222],[-114.101635,47.884919],[-114.18252,47.872517],[-114.285295,47.821472]]]},"properties":{"id":"59910","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.203293,47.355899],[-114.20291,47.280203],[-114.168791,47.257738],[-114.179014,47.356009],[-114.203293,47.355899]]]},"properties":{"id":"59863","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.796208,47.058825],[-112.679074,46.831339],[-112.535369,46.77173],[-112.316947,47.01529],[-112.428878,47.142691],[-113.059444,47.488096],[-113.058898,47.189989],[-112.796208,47.058825]]]},"properties":{"id":"59639","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.638173,45.714161],[-111.915905,45.560262],[-111.660522,45.503649],[-111.350897,45.584693],[-111.350907,45.6045],[-111.351346,45.641938],[-111.526061,45.642248],[-111.556942,45.671202],[-111.638173,45.714161]]]},"properties":{"id":"59745","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.981614,46.192729],[-110.790022,46.192567],[-110.765222,46.40848],[-110.511893,46.712496],[-110.652877,46.823626],[-110.854976,46.930438],[-111.080633,47.087892],[-111.285957,47.000707],[-111.534265,47.000413],[-111.658131,46.913364],[-111.497961,46.762469],[-111.06256,46.192897],[-110.981614,46.192729]]]},"properties":{"id":"59645","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.293651,47.354537],[-115.294785,47.220914],[-115.216274,47.145303],[-114.967636,47.312603],[-114.968855,47.356029],[-115.156925,47.448038],[-115.303125,47.476084],[-115.293651,47.354537]]]},"properties":{"id":"59866","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.628695,47.479641],[-115.561268,47.352189],[-115.56464,47.47166],[-115.628695,47.479641]]]},"properties":{"id":"59867","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.797235,47.268901],[-114.967636,47.312603],[-115.216274,47.145303],[-114.934872,46.919691],[-114.563281,47.067335],[-114.797235,47.268901]]]},"properties":{"id":"59872","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.872383,46.866834],[-113.870432,46.875158],[-113.887291,46.871956],[-113.872383,46.866834]]]},"properties":{"id":"59851","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.297211,46.264155],[-108.029531,46.263812],[-107.781965,46.424213],[-108.09206,46.585175],[-108.155569,46.550591],[-108.297211,46.264155]]]},"properties":{"id":"59059","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.153866,46.303716],[-114.215579,46.338393],[-114.228337,46.323698],[-114.239907,46.341968],[-114.421721,46.329344],[-114.500927,46.04154],[-114.052066,46.05227],[-113.772627,46.245438],[-113.784117,46.284964],[-114.153866,46.303716]]]},"properties":{"id":"59840","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.448834,48.64997],[-104.7769,48.633356],[-104.973353,48.632891],[-104.973354,48.56319],[-104.931387,48.476237],[-104.840429,48.476475],[-104.757861,48.476556],[-104.296847,48.57758],[-104.275122,48.606554],[-104.448834,48.64997]]]},"properties":{"id":"59258","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.56464,47.47166],[-115.395253,47.484951],[-115.303125,47.476084],[-115.156925,47.448038],[-114.98963,47.872819],[-115.010806,48.017256],[-115.283222,47.890877],[-115.69478,47.614855],[-115.628695,47.479641],[-115.56464,47.47166]]]},"properties":{"id":"59873","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.62871,45.784933],[-109.835868,45.674079],[-109.697225,45.700406],[-109.62871,45.784933]]]},"properties":{"id":"59033","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.193406,48.972147],[-111.604056,48.938542],[-111.524796,48.912176],[-111.270398,48.95476],[-111.270702,48.99741],[-112.193588,48.998836],[-112.193406,48.972147]]]},"properties":{"id":"59484","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.205235,48.91057],[-106.247722,48.794989],[-106.173736,48.794871],[-106.205235,48.91057]]]},"properties":{"id":"59240","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.568094,45.803753],[-108.604346,45.732603],[-108.533809,45.801744],[-108.568094,45.803753]]]},"properties":{"id":"59102","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.404681,46.221542],[-108.257135,45.967673],[-108.149213,45.939695],[-108.027428,45.983487],[-107.840458,46.041695],[-108.029531,46.263812],[-108.297211,46.264155],[-108.404681,46.221542]]]},"properties":{"id":"59088","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.010155,45.096031],[-107.005161,44.99639],[-106.892952,44.996113],[-107.010155,45.096031]]]},"properties":{"id":"82801","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.534295,48.135086],[-109.538454,47.738894],[-108.891022,47.734407],[-108.885183,47.922741],[-108.886185,48.016291],[-109.070639,48.027942],[-109.533927,48.180567],[-109.534295,48.135086]]]},"properties":{"id":"59535","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.887594,48.160888],[-108.886185,48.016291],[-108.885183,47.922741],[-108.597043,47.99221],[-108.580045,48.088824],[-108.887594,48.160888]]]},"properties":{"id":"59527","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.371487,47.792465],[-108.0542,48.633302],[-108.31873,48.684187],[-108.346193,48.56845],[-108.580045,48.088824],[-108.597043,47.99221],[-108.371487,47.792465]]]},"properties":{"id":"59524","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.408874,47.784641],[-111.901426,47.813903],[-111.85935,47.741457],[-111.773775,47.626534],[-111.705973,47.633792],[-111.499177,47.553297],[-111.408874,47.784641]]]},"properties":{"id":"59468","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.500842,47.431212],[-111.527906,47.446996],[-111.614986,47.393343],[-111.502346,47.430277],[-111.500842,47.431212]]]},"properties":{"id":"59485","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.636327,46.913123],[-110.854976,46.930438],[-110.652877,46.823626],[-110.636327,46.913123]]]},"properties":{"id":"59465","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.641125,47.095477],[-110.772285,47.096121],[-111.00386,47.121409],[-111.080633,47.087892],[-110.854976,46.930438],[-110.636327,46.913123],[-110.561061,47.056595],[-110.641125,47.095477]]]},"properties":{"id":"59463","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.155689,47.843169],[-110.874299,47.762838],[-111.129342,48.031777],[-111.322498,47.943153],[-111.155689,47.843169]]]},"properties":{"id":"59420","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.983553,47.991764],[-111.408762,47.944063],[-111.322498,47.943153],[-111.129342,48.031777],[-111.143747,48.132866],[-111.409097,48.132218],[-111.983757,48.005319],[-111.983553,47.991764]]]},"properties":{"id":"59416","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.493335,47.639863],[-110.511751,47.416495],[-110.213293,47.416659],[-109.829623,47.715278],[-110.062865,47.820716],[-110.493335,47.639863]]]},"properties":{"id":"59446","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.367363,48.306114],[-110.432151,48.306567],[-110.52217,48.306791],[-110.224389,48.031435],[-110.062865,47.820716],[-109.829623,47.715278],[-109.645874,47.736286],[-109.538454,47.738894],[-109.534295,48.135086],[-109.644335,48.132793],[-110.367363,48.306114]]]},"properties":{"id":"59520","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.154899,46.304328],[-106.512001,46.729868],[-106.191565,46.094444],[-106.154869,46.274792],[-106.198053,46.274912],[-106.154899,46.304328]]]},"properties":{"id":"59347","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.603766,46.86088],[-105.117082,46.855899],[-104.870561,46.569101],[-104.732853,46.612672],[-104.607017,46.684926],[-104.603766,46.86088]]]},"properties":{"id":"59326","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.170464,47.325601],[-105.685707,47.181098],[-105.406237,47.181563],[-105.200652,47.473444],[-105.36337,47.664975],[-105.662582,47.707243],[-105.876471,47.8295],[-106.260982,47.714145],[-106.170464,47.325601]]]},"properties":{"id":"59215","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.357533,48.317083],[-113.77066,48.355044],[-113.228934,48.22265],[-113.34947,48.310155],[-113.357533,48.317083]]]},"properties":{"id":"59916","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.06256,46.192897],[-111.274254,46.187866],[-111.349387,46.124767],[-111.321403,45.955531],[-111.248023,45.772626],[-111.081788,45.76572],[-110.981614,46.192729],[-111.06256,46.192897]]]},"properties":{"id":"59714","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.377682,44.751744],[-111.056102,44.91241],[-111.372376,44.911742],[-111.377955,44.852242],[-111.377682,44.751744]]]},"properties":{"id":"59758","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.321403,45.955531],[-111.526061,45.642248],[-111.351346,45.641938],[-111.248023,45.772626],[-111.321403,45.955531]]]},"properties":{"id":"59741","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.783982,46.192408],[-110.788352,45.904959],[-110.292826,45.95808],[-110.28186,46.18421],[-110.598448,46.287261],[-110.783982,46.192408]]]},"properties":{"id":"59086","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-106.276993,47.149061],[-106.424868,47.062325],[-106.418828,47.062721],[-106.276993,47.149061]]],[[[-106.260982,47.714145],[-106.4904,47.962237],[-106.692988,47.776712],[-107.221679,47.661636],[-107.275993,47.291877],[-107.45768,46.913986],[-107.457602,46.860313],[-107.100951,46.859209],[-106.253685,47.210257],[-106.170196,47.267609],[-106.170464,47.325601],[-106.260982,47.714145]]]]},"properties":{"id":"59337","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.467289,48.740335],[-110.383717,48.956719],[-110.53103,48.998414],[-110.611149,48.998913],[-110.610728,48.971919],[-110.755807,48.247055],[-110.755649,48.219671],[-110.632439,48.219525],[-110.52217,48.306791],[-110.432151,48.306567],[-110.467289,48.740335]]]},"properties":{"id":"59540","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.989707,47.43386],[-114.282757,47.47141],[-114.33729,47.376854],[-114.203293,47.355899],[-114.179014,47.356009],[-113.989707,47.43386]]]},"properties":{"id":"59824","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.101635,47.884919],[-114.475073,47.72222],[-114.350245,47.601923],[-114.123804,47.616642],[-114.113162,47.616678],[-113.962051,47.608627],[-113.950648,47.839922],[-114.101635,47.884919]]]},"properties":{"id":"59860","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.488819,47.613912],[-112.984705,47.953982],[-113.144926,47.596363],[-113.059444,47.488096],[-112.428878,47.142691],[-112.046262,47.364708],[-112.047164,47.437053],[-112.009634,47.512674],[-112.488819,47.613912]]]},"properties":{"id":"59410","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.754498,48.34993],[-111.409097,48.132218],[-111.143747,48.132866],[-111.007617,48.132952],[-111.225719,48.394453],[-111.275563,48.416472],[-111.275597,48.408694],[-111.754498,48.34993]]]},"properties":{"id":"59456","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.741692,48.450772],[-116.049763,48.215095],[-115.888716,48.196723],[-115.73731,48.263203],[-115.741692,48.450772]]]},"properties":{"id":"59935","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.170694,48.999573],[-115.019382,48.664642],[-115.009694,48.716142],[-115.056027,48.840589],[-115.170694,48.999573]]]},"properties":{"id":"59930","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.689874,48.65899],[-114.73126,48.683866],[-114.76582,48.659004],[-114.689874,48.65899]]]},"properties":{"id":"59933","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.25603,45.332379],[-112.024614,45.347844],[-111.97277,45.516498],[-112.034881,45.552366],[-112.072235,45.568095],[-112.25603,45.332379]]]},"properties":{"id":"59749","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.024614,45.347844],[-111.890465,45.163075],[-111.883821,45.379879],[-111.97277,45.516498],[-112.024614,45.347844]]]},"properties":{"id":"59755","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.561268,47.352189],[-115.294785,47.220914],[-115.293651,47.354537],[-115.395253,47.484951],[-115.56464,47.47166],[-115.561268,47.352189]]]},"properties":{"id":"59842","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.863598,46.84288],[-113.850708,46.881935],[-113.845612,46.923277],[-113.981617,46.936446],[-114.018598,46.87585],[-113.976331,46.863354],[-113.863598,46.84288]],[[-113.872383,46.866834],[-113.887291,46.871956],[-113.870432,46.875158],[-113.872383,46.866834]]]},"properties":{"id":"59802","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.043721,46.834191],[-113.976331,46.863354],[-114.018598,46.87585],[-114.038408,46.882289],[-114.043721,46.834191]]]},"properties":{"id":"59801","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.654259,48.625646],[-107.289641,48.304658],[-107.206379,48.775274],[-107.654259,48.625646]]]},"properties":{"id":"59261","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.282464,45.787697],[-105.673885,45.589101],[-105.928885,45.593839],[-106.043098,45.11336],[-106.019638,44.99358],[-105.699671,45.000338],[-105.0383,45.090632],[-105.038538,45.156845],[-104.981341,45.52969],[-104.982036,45.787009],[-105.282464,45.787697]]]},"properties":{"id":"59317","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.680188,46.622952],[-112.747153,46.772629],[-113.038315,46.613158],[-113.03609,46.411056],[-113.03612,46.402011],[-112.828963,46.484385],[-112.680188,46.622952]]]},"properties":{"id":"59733","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.099063,46.406811],[-114.383591,46.466589],[-114.421721,46.329344],[-114.239907,46.341968],[-114.215579,46.338393],[-114.153866,46.303716],[-114.099063,46.406811]]]},"properties":{"id":"59875","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.153866,46.303716],[-113.784117,46.284964],[-113.77437,46.314447],[-113.857017,46.419184],[-114.099063,46.406811],[-114.153866,46.303716]]]},"properties":{"id":"59828","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.371331,48.563177],[-105.194221,47.984086],[-105.195013,47.904328],[-104.894338,47.972642],[-104.931387,48.476237],[-104.973354,48.56319],[-105.294719,48.563132],[-105.371331,48.563177]]]},"properties":{"id":"59255","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.261446,47.586644],[-104.269832,47.555873],[-104.221291,47.578153],[-104.261446,47.586644]]]},"properties":{"id":"59217","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.888716,48.196723],[-115.93085,47.90694],[-115.561713,48.014144],[-115.73731,48.263203],[-115.888716,48.196723]]]},"properties":{"id":"59853","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.448834,48.64997],[-104.275122,48.606554],[-104.220678,48.707938],[-104.253443,48.794909],[-104.448834,48.64997]]]},"properties":{"id":"59211","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.57005,48.823731],[-104.253443,48.794909],[-104.220678,48.707938],[-104.048526,48.737021],[-104.574508,48.999471],[-104.57472,48.983092],[-104.395424,48.896204],[-104.569987,48.838193],[-104.57005,48.823731]]]},"properties":{"id":"59275","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.862935,48.769081],[-112.18928,48.783831],[-112.189346,48.647312],[-111.862138,48.653211],[-111.862935,48.769081]]]},"properties":{"id":"59454","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-106.519622,47.986896],[-106.493423,48.000605],[-106.610104,47.978446],[-106.524447,47.984165],[-106.519622,47.986896]]],[[[-106.021307,48.563225],[-106.216171,48.523021],[-106.493551,48.479082],[-106.490645,48.001089],[-106.363704,48.046508],[-106.108337,48.006233],[-105.999343,48.563236],[-106.021307,48.563225]]]]},"properties":{"id":"59248","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.289641,48.304658],[-107.414722,47.804038],[-106.645741,48.664797],[-107.180049,48.927191],[-107.206379,48.775274],[-107.289641,48.304658]]]},"properties":{"id":"59241","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.257135,45.967673],[-108.404681,46.221542],[-108.572483,46.13283],[-108.675096,46.0889],[-108.591384,45.95814],[-108.320144,45.90384],[-108.257135,45.967673]]]},"properties":{"id":"59079","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.841045,45.565255],[-112.505346,45.443048],[-112.557558,45.538897],[-112.68719,45.600795],[-112.841045,45.565255]]]},"properties":{"id":"59732","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.892952,44.996113],[-106.263586,44.993788],[-106.279682,45.179892],[-106.768046,45.186082],[-107.020255,45.186242],[-107.010155,45.096031],[-106.892952,44.996113]]]},"properties":{"id":"59025","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.242615,47.528988],[-111.291082,47.520538],[-111.264237,47.519087],[-111.242615,47.528988]]]},"properties":{"id":"59414","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.502346,47.430277],[-111.614986,47.393343],[-111.821252,47.402998],[-112.047164,47.437053],[-112.046262,47.364708],[-111.790092,46.91393],[-111.658131,46.913364],[-111.534265,47.000413],[-111.502346,47.430277]]]},"properties":{"id":"59421","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.493335,47.639863],[-110.896984,47.759827],[-110.976382,47.698605],[-111.049847,47.598333],[-110.863942,47.52506],[-110.639564,47.416259],[-110.511751,47.416495],[-110.493335,47.639863]]]},"properties":{"id":"59450","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.424868,47.062325],[-106.543039,46.845451],[-106.512001,46.729868],[-106.154899,46.304328],[-106.154869,46.274792],[-106.191565,46.094444],[-106.221077,45.788465],[-105.976978,45.650686],[-105.448514,45.788423],[-105.282464,45.787697],[-104.982036,45.787009],[-104.939509,45.853871],[-105.418871,46.570854],[-105.596146,46.578255],[-105.75873,46.598444],[-105.672969,46.714082],[-105.57608,46.720185],[-106.08667,46.860264],[-106.418828,47.062721],[-106.424868,47.062325]]]},"properties":{"id":"59301","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.601709,47.270433],[-108.800144,47.202148],[-108.900895,46.991511],[-108.715592,46.749466],[-108.609979,46.906213],[-108.601709,47.270433]]]},"properties":{"id":"59032","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.675337,47.622191],[-108.891022,47.734407],[-109.538454,47.738894],[-109.645874,47.736286],[-109.590061,47.559252],[-109.207991,47.472731],[-108.675337,47.622191]]]},"properties":{"id":"59489","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.494603,48.605526],[-114.23018,48.486461],[-114.163977,48.603552],[-114.188611,48.99952],[-114.727997,49.000681],[-114.727548,48.821171],[-114.61911,48.742601],[-114.648377,48.731328],[-114.494603,48.605526]]]},"properties":{"id":"59928","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.276993,47.149061],[-106.418828,47.062721],[-106.08667,46.860264],[-106.086777,47.180938],[-106.170434,47.18093],[-106.253685,47.210257],[-106.276993,47.149061]]]},"properties":{"id":"59312","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-106.253685,47.210257],[-106.170434,47.18093],[-106.170196,47.267609],[-106.253685,47.210257]]],[[[-107.037426,46.85926],[-106.543039,46.845451],[-106.424868,47.062325],[-106.276993,47.149061],[-106.253685,47.210257],[-107.100951,46.859209],[-107.037426,46.85926]]]]},"properties":{"id":"59322","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.892117,46.851537],[-107.485203,46.851316],[-107.457602,46.860313],[-107.45768,46.913986],[-107.784956,47.433508],[-107.920294,47.443943],[-107.892117,46.851537]]]},"properties":{"id":"59058","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.576892,48.483478],[-112.184375,48.477593],[-112.189346,48.647312],[-112.18928,48.783831],[-112.193406,48.972147],[-112.193588,48.998836],[-112.96211,48.998689],[-112.576892,48.483478]]]},"properties":{"id":"59427","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.143795,46.488402],[-113.384085,46.656553],[-113.466482,46.571507],[-113.143795,46.488402]]]},"properties":{"id":"59837","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.079995,46.404312],[-111.786177,46.314629],[-111.786221,46.352348],[-112.079995,46.404312]]]},"properties":{"id":"59638","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.233799,46.428879],[-112.31061,46.421006],[-112.49838,46.341834],[-112.558457,46.265925],[-112.585621,46.239531],[-112.339781,46.204562],[-112.205691,46.421515],[-112.233799,46.428879]]]},"properties":{"id":"59631","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.123804,47.616642],[-114.123798,47.595143],[-114.11305,47.595002],[-114.113162,47.616678],[-114.123804,47.616642]]]},"properties":{"id":"59855","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.288148,46.752139],[-112.331474,46.728013],[-112.31119,46.713473],[-112.288148,46.752139]]]},"properties":{"id":"59640","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.270398,48.95476],[-111.273324,48.841862],[-111.069054,48.997724],[-111.270702,48.99741],[-111.270398,48.95476]]]},"properties":{"id":"59545","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.512946,48.696761],[-111.275597,48.408694],[-111.275563,48.416472],[-111.273213,48.566634],[-111.273324,48.841862],[-111.270398,48.95476],[-111.524796,48.912176],[-111.512946,48.696761]]]},"properties":{"id":"59444","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.273213,48.566634],[-111.275563,48.416472],[-111.225719,48.394453],[-111.273213,48.566634]]]},"properties":{"id":"59461","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.848465,48.366627],[-115.042168,48.598534],[-115.741692,48.450772],[-115.73731,48.263203],[-115.561713,48.014144],[-115.283222,47.890877],[-115.010806,48.017256],[-114.847946,48.226159],[-114.848465,48.366627]]]},"properties":{"id":"59923","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.170694,48.999573],[-115.056027,48.840589],[-114.727548,48.821171],[-114.727997,49.000681],[-115.170694,48.999573]]]},"properties":{"id":"59917","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.867564,45.730669],[-112.037402,45.7312],[-112.10452,45.616301],[-112.072235,45.568095],[-112.034881,45.552366],[-111.915905,45.560262],[-111.638173,45.714161],[-111.760252,45.772589],[-111.867564,45.730669]]]},"properties":{"id":"59735","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.350897,45.584693],[-111.660522,45.503649],[-111.883821,45.379879],[-111.890465,45.163075],[-111.835294,44.938911],[-111.676318,45.232332],[-111.373033,45.345454],[-111.350897,45.584693]]]},"properties":{"id":"59729","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.309585,47.13737],[-114.370598,47.009016],[-114.267827,47.006396],[-114.138995,47.138096],[-114.309585,47.13737]]]},"properties":{"id":"59834","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.376126,47.008373],[-114.377439,46.837644],[-114.094705,46.803845],[-114.043721,46.834191],[-114.038408,46.882289],[-114.267827,47.006396],[-114.370598,47.009016],[-114.376126,47.008373]]]},"properties":{"id":"59804","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.976331,46.863354],[-114.043721,46.834191],[-114.094705,46.803845],[-114.05081,46.728105],[-113.856045,46.726644],[-113.863598,46.84288],[-113.976331,46.863354]]]},"properties":{"id":"59803","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.329765,46.575665],[-113.817137,46.588895],[-113.856045,46.726644],[-114.05081,46.728105],[-114.332887,46.660756],[-114.329765,46.575665]]]},"properties":{"id":"59833","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.292826,45.95808],[-110.788352,45.904959],[-110.795493,45.719313],[-110.292662,45.785022],[-110.292826,45.95808]]]},"properties":{"id":"59018","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.218449,45.627439],[-110.444581,45.206987],[-110.247223,45.177142],[-110.063819,45.172102],[-109.931136,45.522604],[-109.88128,45.674025],[-110.218449,45.627439]]]},"properties":{"id":"59052","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.576892,48.483478],[-112.586805,48.309874],[-112.901078,48.159354],[-112.900374,48.13247],[-112.65643,48.13271],[-112.308093,48.186904],[-111.982848,48.394234],[-112.184375,48.477593],[-112.576892,48.483478]]]},"properties":{"id":"59486","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.0383,45.090632],[-105.699671,45.000338],[-105.038405,45.000345],[-105.0383,45.090632]]]},"properties":{"id":"59314","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.265209,46.8259],[-113.038315,46.613158],[-112.747153,46.772629],[-112.679074,46.831339],[-112.796208,47.058825],[-113.265209,46.8259]]]},"properties":{"id":"59843","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.893224,45.985514],[-114.052066,46.05227],[-114.500927,46.04154],[-114.027663,45.648132],[-114.08745,45.783341],[-113.893224,45.985514]]]},"properties":{"id":"59829","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.526681,45.387252],[-106.768383,45.258951],[-106.768046,45.186082],[-106.279682,45.179892],[-106.279609,45.21397],[-106.358389,45.49399],[-106.526681,45.387252]]]},"properties":{"id":"59012","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.191565,46.094444],[-106.512001,46.729868],[-106.543039,46.845451],[-107.037426,46.85926],[-107.153579,46.467787],[-107.007357,46.290041],[-107.007415,46.266806],[-106.891118,45.83169],[-106.706932,45.676441],[-106.478069,45.688903],[-106.221077,45.788465],[-106.191565,46.094444]],[[-106.656042,45.995085],[-106.715971,45.953117],[-106.741327,46.062189],[-106.686413,46.051095],[-106.656042,45.995085]]]},"properties":{"id":"59327","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.561713,48.014144],[-115.93085,47.90694],[-115.955227,47.894718],[-115.69478,47.614855],[-115.283222,47.890877],[-115.561713,48.014144]]]},"properties":{"id":"59874","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.457912,47.32687],[-114.482489,47.219981],[-114.335867,47.149568],[-114.20291,47.280203],[-114.203293,47.355899],[-114.33729,47.376854],[-114.457912,47.32687]]]},"properties":{"id":"59831","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.049763,48.215095],[-115.955227,47.894718],[-115.93085,47.90694],[-115.888716,48.196723],[-116.049763,48.215095]]]},"properties":{"id":"59844","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.661991,46.004957],[-112.733954,46.011912],[-112.85115,45.930945],[-112.621535,45.893117],[-112.661991,46.004957]]]},"properties":{"id":"59750","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.221236,48.056497],[-112.594884,48.023925],[-112.199536,48.038713],[-112.221236,48.056497]]]},"properties":{"id":"59419","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.85935,47.741457],[-112.488819,47.613912],[-112.009634,47.512674],[-111.92253,47.505078],[-111.773775,47.626534],[-111.85935,47.741457]]]},"properties":{"id":"59436","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.479185,48.664696],[-106.493551,48.479082],[-106.216171,48.523021],[-106.173736,48.794871],[-106.247722,48.794989],[-106.479185,48.664696]]]},"properties":{"id":"59244","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.645741,48.664797],[-106.479185,48.664696],[-106.247722,48.794989],[-106.205235,48.91057],[-106.363744,48.999641],[-107.179864,49.000021],[-107.180049,48.927191],[-106.645741,48.664797]]]},"properties":{"id":"59250","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.563589,48.382565],[-106.545093,48.385668],[-106.545526,48.399246],[-106.563379,48.407765],[-106.563589,48.382565]]]},"properties":{"id":"59231","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.045268,46.641443],[-104.045069,47.397461],[-104.420263,47.354596],[-104.603766,46.86088],[-104.607017,46.684926],[-104.581079,46.68483],[-104.045268,46.641443]]]},"properties":{"id":"59353","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.149213,45.939695],[-108.047394,45.797994],[-108.027428,45.983487],[-108.149213,45.939695]]]},"properties":{"id":"59006","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.516338,45.938638],[-113.278823,45.519095],[-113.152652,45.497315],[-113.017191,45.441566],[-112.96689,45.555783],[-112.92364,45.935062],[-113.517481,45.939898],[-113.516338,45.938638]]]},"properties":{"id":"59762","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.182549,45.320878],[-113.017191,45.441566],[-113.152652,45.497315],[-113.182549,45.320878]]]},"properties":{"id":"59746","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.779199,45.677546],[-112.841045,45.565255],[-112.68719,45.600795],[-112.534819,45.697721],[-112.574521,45.748371],[-112.779199,45.677546]]]},"properties":{"id":"59743","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-107.125413,45.888827],[-107.25567,45.768305],[-107.122465,45.768039],[-107.125413,45.888827]]],[[[-107.686343,45.421403],[-107.479453,45.434972],[-107.527913,45.533446],[-107.419046,45.627984],[-107.375151,45.768209],[-107.511497,46.043257],[-107.669274,45.942161],[-107.720589,45.808103],[-108.06906,45.782497],[-108.069337,45.711977],[-108.069701,45.51928],[-107.686343,45.421403]]]]},"properties":{"id":"59034","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.283241,45.186852],[-107.568058,45.177176],[-107.625955,45.000711],[-107.283241,45.186852]]]},"properties":{"id":"59089","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.027428,45.983487],[-108.047394,45.797994],[-108.06906,45.782497],[-107.720589,45.808103],[-107.798047,45.985681],[-107.840458,46.041695],[-108.027428,45.983487]]]},"properties":{"id":"59064","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.070656,45.518246],[-108.115808,45.293133],[-107.845139,45.265049],[-107.755938,45.220067],[-107.686343,45.421403],[-108.069701,45.51928],[-108.070656,45.518246]]]},"properties":{"id":"59075","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.980092,48.622593],[-108.887594,48.160888],[-108.580045,48.088824],[-108.346193,48.56845],[-108.455124,48.568505],[-108.684309,48.577657],[-108.864082,48.828315],[-109.045972,48.771419],[-109.045882,48.748232],[-108.981289,48.700167],[-108.980092,48.622593]]]},"properties":{"id":"59526","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.045882,48.748232],[-108.980092,48.622593],[-108.981289,48.700167],[-109.045882,48.748232]]]},"properties":{"id":"59547","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.274254,46.187866],[-111.785913,46.266295],[-111.784206,46.093663],[-111.349387,46.124767],[-111.274254,46.187866]]]},"properties":{"id":"59643","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.786119,46.424862],[-111.635758,46.569115],[-111.723279,46.569204],[-111.786014,46.475834],[-111.786119,46.424862]]]},"properties":{"id":"59647","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.055962,45.461285],[-109.100046,45.457586],[-109.102186,45.449161],[-109.055962,45.461285]]]},"properties":{"id":"59013","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.904962,45.740837],[-108.924111,45.605176],[-108.699178,45.518823],[-108.67572,45.697049],[-108.80283,45.74058],[-108.904962,45.740837]]]},"properties":{"id":"59044","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.044028,45.881971],[-104.51584,46.136344],[-104.678215,46.136008],[-104.939509,45.853871],[-104.982036,45.787009],[-104.981341,45.52969],[-104.458942,45.436467],[-104.263562,45.470293],[-104.042362,45.635843],[-104.044028,45.881971]]]},"properties":{"id":"59324","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.312758,47.506893],[-111.500842,47.431212],[-111.502346,47.430277],[-111.534265,47.000413],[-111.285957,47.000707],[-111.251645,47.323352],[-111.116573,47.460168],[-110.863942,47.52506],[-111.049847,47.598333],[-111.242615,47.528988],[-111.264237,47.519087],[-111.312758,47.506893]]]},"properties":{"id":"59405","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.705973,47.633792],[-111.743002,47.572406],[-111.695435,47.527651],[-111.499177,47.553297],[-111.705973,47.633792]]]},"properties":{"id":"59487","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.312758,47.506893],[-111.264237,47.519087],[-111.291082,47.520538],[-111.312758,47.506893]]]},"properties":{"id":"59401","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.743002,47.572406],[-111.705973,47.633792],[-111.773775,47.626534],[-111.92253,47.505078],[-111.815528,47.480552],[-111.743002,47.572406]]]},"properties":{"id":"59443","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.173736,48.794871],[-106.216171,48.523021],[-106.021307,48.563225],[-105.826467,48.925112],[-105.945964,48.999677],[-106.363744,48.999641],[-106.205235,48.91057],[-106.173736,48.794871]]]},"properties":{"id":"59260","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.705293,47.412892],[-104.960104,47.326169],[-105.324785,47.093263],[-105.117082,46.855899],[-104.603766,46.86088],[-104.420263,47.354596],[-104.420373,47.354596],[-104.705293,47.412892]]]},"properties":{"id":"59330","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.900895,46.991511],[-109.192299,46.751324],[-109.009802,46.750432],[-108.973038,46.750863],[-108.715592,46.749466],[-108.900895,46.991511]]]},"properties":{"id":"59441","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.010806,48.017256],[-114.98963,47.872819],[-114.828851,47.875783],[-114.626722,47.944323],[-114.73463,48.226909],[-114.847946,48.226159],[-115.010806,48.017256]]]},"properties":{"id":"59925","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-111.638173,45.714161],[-111.556942,45.671202],[-111.659995,45.833512],[-111.760252,45.772589],[-111.638173,45.714161]]]},"properties":{"id":"59760","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.169882,46.132067],[-109.19631,46.751401],[-109.388963,46.751009],[-109.388677,46.546823],[-109.616412,46.13207],[-109.591221,46.045262],[-109.417297,46.044485],[-109.169882,46.132067]]]},"properties":{"id":"59074","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.711178,46.219297],[-109.616412,46.13207],[-109.388677,46.546823],[-109.65388,46.49007],[-109.711178,46.219297]]]},"properties":{"id":"59078","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.503446,46.708985],[-110.273374,46.71064],[-110.029697,46.736495],[-109.972477,46.751357],[-109.830364,46.838148],[-109.755997,47.028689],[-110.030594,47.040621],[-110.503446,46.708985]]]},"properties":{"id":"59452","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.030594,47.040621],[-109.755997,47.028689],[-109.756513,47.124127],[-109.803004,47.185564],[-110.021287,47.199726],[-110.030594,47.040621]]]},"properties":{"id":"59462","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.168791,47.257738],[-113.821478,47.241729],[-113.824845,47.274222],[-113.887652,47.36477],[-113.989707,47.43386],[-114.179014,47.356009],[-114.168791,47.257738]]]},"properties":{"id":"59865","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.123804,47.616642],[-114.350245,47.601923],[-114.282757,47.47141],[-113.989707,47.43386],[-113.887652,47.36477],[-113.948763,47.600517],[-113.962051,47.608627],[-114.113162,47.616678],[-114.11305,47.595002],[-114.123798,47.595143],[-114.123804,47.616642]]]},"properties":{"id":"59864","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.755575,48.408123],[-110.975663,48.997828],[-111.069054,48.997724],[-111.273324,48.841862],[-111.273213,48.566634],[-111.225719,48.394453],[-111.007617,48.132952],[-110.755649,48.219671],[-110.755807,48.247055],[-110.755575,48.408123]]]},"properties":{"id":"59522","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.273374,46.71064],[-110.503446,46.708985],[-110.511893,46.712496],[-110.765222,46.40848],[-110.598448,46.287261],[-110.28186,46.18421],[-110.281858,46.220519],[-110.202946,46.521253],[-110.273374,46.71064]]]},"properties":{"id":"59053","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.267827,47.006396],[-114.038408,46.882289],[-114.018598,46.87585],[-113.981617,46.936446],[-113.979196,47.049501],[-114.138995,47.138096],[-114.267827,47.006396]]]},"properties":{"id":"59808","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.466429,47.282903],[-113.658288,47.174167],[-113.46287,47.077607],[-113.302868,47.106559],[-113.302413,47.134099],[-113.466429,47.282903]]]},"properties":{"id":"59868","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.831825,48.999831],[-107.85013,48.684158],[-107.654259,48.625646],[-107.206379,48.775274],[-107.180049,48.927191],[-107.179864,49.000021],[-107.831825,48.999831]]]},"properties":{"id":"59544","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.308093,48.186904],[-112.65643,48.13271],[-112.264253,48.131137],[-112.308093,48.186904]]]},"properties":{"id":"59432","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.358389,45.49399],[-106.279609,45.21397],[-106.043098,45.11336],[-105.928885,45.593839],[-105.976978,45.650686],[-106.221077,45.788465],[-106.478069,45.688903],[-106.358389,45.49399]]]},"properties":{"id":"59003","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-113.303005,46.832132],[-113.265209,46.8259],[-112.796208,47.058825],[-113.058898,47.189989],[-113.302413,47.134099],[-113.302868,47.106559],[-113.303005,46.832132]]]},"properties":{"id":"59854","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.535369,46.77173],[-112.679074,46.831339],[-112.747153,46.772629],[-112.680188,46.622952],[-112.619418,46.496243],[-112.571927,46.482316],[-112.464366,46.699479],[-112.535369,46.77173]]]},"properties":{"id":"59713","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.755766,48.301901],[-104.840429,48.476475],[-104.931387,48.476237],[-104.894338,47.972642],[-104.849216,47.911192],[-104.676842,47.946966],[-104.755766,48.301901]]]},"properties":{"id":"59213","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.967636,47.312603],[-114.797235,47.268901],[-114.687155,47.27426],[-114.968855,47.356029],[-114.967636,47.312603]]]},"properties":{"id":"59856","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.296847,48.57758],[-104.324852,48.494853],[-104.367883,48.389422],[-104.173081,48.389369],[-104.046765,48.389429],[-104.048526,48.737021],[-104.220678,48.707938],[-104.275122,48.606554],[-104.296847,48.57758]]]},"properties":{"id":"59219","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.57472,48.983092],[-104.569987,48.838193],[-104.395424,48.896204],[-104.57472,48.983092]]]},"properties":{"id":"59256","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.931136,45.522604],[-110.063819,45.172102],[-109.812235,45.171916],[-109.566133,45.493211],[-109.808808,45.551396],[-109.931136,45.522604]]]},"properties":{"id":"59061","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-112.199536,48.038713],[-112.594884,48.023925],[-112.951196,48.044686],[-112.984705,47.953982],[-112.488819,47.613912],[-111.85935,47.741457],[-111.901426,47.813903],[-111.983553,47.991764],[-111.983757,48.005319],[-112.199536,48.038713]]]},"properties":{"id":"59422","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.007357,46.290041],[-107.137059,46.293607],[-107.137981,46.263543],[-107.007415,46.266806],[-107.007357,46.290041]]]},"properties":{"id":"59076","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-110.008575,46.220008],[-109.711178,46.219297],[-109.65388,46.49007],[-110.029697,46.736495],[-110.273374,46.71064],[-110.202946,46.521253],[-110.015241,46.220118],[-110.008575,46.220008]]]},"properties":{"id":"59036","state_code":"30"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.492563,40.698604],[-98.629202,40.683806],[-98.64815,40.524142],[-98.629066,40.520293],[-98.496738,40.495464],[-98.477252,40.698566],[-98.492563,40.698604]]]},"properties":{"id":"68955","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.83101,41.422333],[-103.859039,41.001751],[-103.544295,41.001492],[-103.492019,41.393851],[-103.520363,41.450038],[-103.83101,41.422333]]]},"properties":{"id":"69145","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.052529,41.46537],[-103.859039,41.001751],[-103.83101,41.422333],[-104.052529,41.46537]]]},"properties":{"id":"69128","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.267622,42.086147],[-100.267694,42.04423],[-100.09205,42.024821],[-100.074954,42.085532],[-100.167605,42.085848],[-100.267622,42.086147]]]},"properties":{"id":"69157","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.309771,42.882605],[-98.531944,42.998456],[-98.545461,42.799443],[-98.56837,42.766247],[-98.305069,42.665806],[-98.305148,42.760384],[-98.309771,42.882605]]]},"properties":{"id":"68746","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.105525,40.699424],[-99.105405,40.702835],[-99.112759,40.701428],[-99.113273,40.699444],[-99.105525,40.699424]]]},"properties":{"id":"68849","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.014202,41.104954],[-99.135659,41.046894],[-99.007818,41.002814],[-99.014202,41.104954]]]},"properties":{"id":"68844","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.392946,41.886978],[-96.34733,41.901291],[-96.385807,42.016292],[-96.554866,42.015875],[-96.555159,41.927622],[-96.5552,41.90138],[-96.392946,41.886978]]]},"properties":{"id":"68038","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.281405,41.293939],[-97.196024,41.148425],[-97.138406,41.104618],[-97.100537,41.118814],[-96.985299,41.227297],[-97.023228,41.33599],[-97.061609,41.365285],[-97.281405,41.293939]]]},"properties":{"id":"68632","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.349559,41.307515],[-97.281405,41.293939],[-97.061609,41.365285],[-97.059704,41.410986],[-97.197579,41.385069],[-97.368273,41.337153],[-97.349559,41.307515]]]},"properties":{"id":"68624","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.985225,41.328418],[-96.946243,41.321569],[-96.935842,41.341316],[-96.985225,41.328418]]]},"properties":{"id":"68001","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.485502,42.598116],[-97.691127,42.510589],[-97.720192,42.437964],[-97.698902,42.43806],[-97.568157,42.438526],[-97.485298,42.438578],[-97.465319,42.467512],[-97.406511,42.525933],[-97.485502,42.598116]]]},"properties":{"id":"68786","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.465319,42.467512],[-97.485298,42.438578],[-97.465446,42.438625],[-97.465319,42.467512]]]},"properties":{"id":"68749","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.843719,41.85064],[-100.513829,41.739882],[-100.362474,41.739641],[-100.28147,42.086201],[-100.458588,42.32086],[-100.848624,42.426594],[-100.809277,42.321053],[-100.691166,42.087491],[-100.845146,41.972587],[-100.843719,41.85064]]]},"properties":{"id":"69166","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.183167,42.433497],[-100.397919,42.303131],[-100.458588,42.32086],[-100.28147,42.086201],[-100.267622,42.086147],[-100.167605,42.085848],[-100.167454,42.368181],[-100.183167,42.433497]]]},"properties":{"id":"69135","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.845146,41.972587],[-100.691166,42.087491],[-100.809277,42.321053],[-100.845146,41.972587]]]},"properties":{"id":"69161","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.852753,40.43756],[-97.862211,40.350514],[-97.824114,40.350541],[-97.824246,40.43758],[-97.852753,40.43756]]]},"properties":{"id":"68452","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.616339,41.665847],[-99.443034,41.49627],[-99.405178,41.46704],[-99.366487,41.481398],[-99.212192,41.668709],[-99.379711,41.741507],[-99.5982,41.740477],[-99.616339,41.665847]]]},"properties":{"id":"68874","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.212192,41.668709],[-99.366487,41.481398],[-99.289674,41.466618],[-99.134754,41.553111],[-99.134948,41.625284],[-99.134895,41.639723],[-99.212192,41.668709]]]},"properties":{"id":"68828","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.522804,42.469835],[-96.398808,42.422494],[-96.506521,42.485278],[-96.522804,42.469835]]]},"properties":{"id":"68776","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.522804,42.469835],[-96.551053,42.423275],[-96.526449,42.37941],[-96.408532,42.379078],[-96.398808,42.422494],[-96.522804,42.469835]]]},"properties":{"id":"68731","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.50335,42.801556],[-103.196212,42.640764],[-103.504819,42.977196],[-103.50335,42.801556]]]},"properties":{"id":"69367","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.504819,42.977196],[-103.196212,42.640764],[-103.067265,42.579648],[-102.851551,42.568982],[-102.672366,42.870484],[-102.712846,42.999981],[-103.5051,43.00077],[-103.504819,42.977196]]]},"properties":{"id":"69337","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.493549,40.633064],[-99.425974,40.858575],[-99.425749,40.873089],[-99.636799,40.872559],[-99.58656,40.678055],[-99.53036,40.62653],[-99.493549,40.633064]]]},"properties":{"id":"68863","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.062256,40.902331],[-100.09091,40.887525],[-100.052783,40.872898],[-100.062256,40.902331]]]},"properties":{"id":"69171","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.015358,42.591074],[-97.01796,42.394774],[-96.939922,42.412486],[-96.93715,42.525064],[-96.937243,42.539481],[-97.015422,42.597558],[-97.015358,42.591074]]]},"properties":{"id":"68732","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.019359,42.090577],[-96.932053,42.127318],[-96.998291,42.35127],[-97.017789,42.351347],[-97.115286,42.31488],[-97.173768,42.220571],[-97.057063,42.090598],[-97.019359,42.090577]]]},"properties":{"id":"68787","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.023841,41.317329],[-96.081329,41.317677],[-96.076132,41.277804],[-96.023863,41.277798],[-96.023841,41.317329]]]},"properties":{"id":"68134","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.947038,41.226202],[-95.975839,41.224725],[-95.981898,41.190776],[-95.93346,41.190982],[-95.923548,41.19085],[-95.910912,41.231546],[-95.947038,41.226202]]]},"properties":{"id":"68107","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.980341,41.273642],[-95.980581,41.317123],[-96.018144,41.317275],[-96.023841,41.317329],[-96.023863,41.277798],[-96.023862,41.273261],[-95.980344,41.273174],[-95.980341,41.273642]]]},"properties":{"id":"68104","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.482796,40.534557],[-97.671992,40.582278],[-97.730387,40.49549],[-97.634018,40.415367],[-97.520416,40.423047],[-97.463302,40.495762],[-97.482796,40.534557]]]},"properties":{"id":"68361","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.463401,40.769526],[-97.501906,40.712916],[-97.463667,40.539011],[-97.368402,40.553496],[-97.368432,40.72765],[-97.36842,40.771172],[-97.463401,40.769526]]]},"properties":{"id":"68351","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.02751,40.350533],[-99.064677,40.35055],[-99.142516,40.219772],[-99.123009,40.205197],[-99.017862,40.205112],[-99.009521,40.234423],[-98.962375,40.350576],[-99.02751,40.350533]]]},"properties":{"id":"68947","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.315112,40.131226],[-100.365753,40.35146],[-100.364254,40.40961],[-100.477339,40.439516],[-100.543775,40.386472],[-100.476963,40.089725],[-100.343056,40.065226],[-100.315112,40.131226]]]},"properties":{"id":"69034","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.981223,40.446323],[-99.981495,40.496431],[-100.170107,40.555014],[-100.320799,40.49241],[-100.364254,40.40961],[-100.365753,40.35146],[-100.19383,40.102541],[-100.042326,40.176206],[-99.981193,40.438291],[-99.981223,40.446323]]]},"properties":{"id":"69022","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.780447,40.363943],[-96.916139,40.320728],[-96.916289,40.26272],[-96.916383,40.175643],[-96.878708,40.146622],[-96.784593,40.146712],[-96.539376,40.204173],[-96.539375,40.269556],[-96.647863,40.349326],[-96.780447,40.363943]]]},"properties":{"id":"68310","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.046395,40.436718],[-97.046283,40.349655],[-96.916139,40.320728],[-96.780447,40.363943],[-96.789144,40.430685],[-96.893931,40.450978],[-97.046395,40.436718]]]},"properties":{"id":"68341","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.425837,40.638946],[-96.51139,40.604823],[-96.50637,40.5956],[-96.51592,40.595651],[-96.520573,40.595676],[-96.520289,40.566847],[-96.647589,40.465346],[-96.618649,40.407185],[-96.5719,40.363689],[-96.46379,40.349043],[-96.463632,40.523013],[-96.444665,40.624449],[-96.425837,40.638946]]]},"properties":{"id":"68301","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.647863,40.349326],[-96.539375,40.269556],[-96.463695,40.276664],[-96.46379,40.349043],[-96.5719,40.363689],[-96.647863,40.349326]]]},"properties":{"id":"68357","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.779856,40.43641],[-96.796584,40.523076],[-96.89339,40.523543],[-96.893931,40.450978],[-96.789144,40.430685],[-96.779856,40.43641]]]},"properties":{"id":"68328","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.784687,40.117617],[-96.784593,40.146712],[-96.878708,40.146622],[-96.916407,40.001511],[-96.727644,40.00146],[-96.784687,40.117617]]]},"properties":{"id":"68415","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.5719,40.363689],[-96.618649,40.407185],[-96.779856,40.43641],[-96.789144,40.430685],[-96.780447,40.363943],[-96.647863,40.349326],[-96.5719,40.363689]]]},"properties":{"id":"68422","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.796584,40.523076],[-96.779856,40.43641],[-96.618649,40.407185],[-96.647589,40.465346],[-96.707154,40.523077],[-96.741956,40.523104],[-96.796584,40.523076]]]},"properties":{"id":"68331","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.339964,41.046104],[-98.378564,40.829328],[-98.282569,40.872296],[-98.144028,40.985088],[-98.26893,41.075358],[-98.339964,41.046104]]]},"properties":{"id":"68801","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.144028,40.985088],[-98.282569,40.872296],[-98.2256,40.820939],[-98.187551,40.821942],[-98.0936,40.959131],[-98.097111,41.008176],[-98.144028,40.985088]]]},"properties":{"id":"68865","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.754327,42.537323],[-98.755623,42.479153],[-98.856496,42.422643],[-98.885771,42.393703],[-98.827885,42.349754],[-98.690563,42.175713],[-98.515051,42.262863],[-98.515125,42.422715],[-98.305394,42.523863],[-98.305069,42.665806],[-98.56837,42.766247],[-98.794977,42.754544],[-98.754327,42.537323]]]},"properties":{"id":"68763","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.657654,41.139515],[-98.604591,41.191523],[-98.612246,41.249282],[-98.689817,41.26455],[-98.710171,41.191652],[-98.657654,41.139515]]]},"properties":{"id":"68838","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.066707,40.102947],[-97.010354,40.001436],[-97.004907,40.001521],[-97.048476,40.09554],[-97.066707,40.102947]]]},"properties":{"id":"68440","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.067084,40.34913],[-96.236195,40.261954],[-96.117064,40.262357],[-96.067025,40.305371],[-96.067084,40.34913]]]},"properties":{"id":"68348","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.956971,41.003289],[-101.804812,41.002886],[-101.786963,41.227885],[-102.024832,41.290782],[-102.055351,41.290489],[-102.051761,41.00389],[-101.956971,41.003289]]]},"properties":{"id":"69127","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.606154,40.769196],[-96.606282,40.697279],[-96.568337,40.697209],[-96.549488,40.733227],[-96.56789,40.769618],[-96.602358,40.76973],[-96.606154,40.769196]]]},"properties":{"id":"68526","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.693919,40.802495],[-96.723627,40.799113],[-96.718552,40.770183],[-96.683025,40.769568],[-96.672871,40.769866],[-96.672872,40.796712],[-96.693919,40.802495]]]},"properties":{"id":"68502","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.650145,40.883547],[-96.64365,40.958301],[-96.642993,40.972726],[-96.700603,40.995537],[-96.738672,41.002213],[-96.767624,41.00225],[-96.681649,40.915068],[-96.682128,40.88643],[-96.650145,40.883547]]]},"properties":{"id":"68336","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.74094,40.697493],[-96.686805,40.624779],[-96.57241,40.639942],[-96.568337,40.697209],[-96.606282,40.697279],[-96.682509,40.711876],[-96.720492,40.711981],[-96.74094,40.697493]]]},"properties":{"id":"68430","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.463714,40.870951],[-96.586562,40.870983],[-96.586801,40.835127],[-96.586887,40.82784],[-96.482716,40.812957],[-96.463714,40.870951]]]},"properties":{"id":"68527","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.235485,41.221815],[-100.351581,41.187556],[-100.489964,40.883103],[-100.337123,40.74475],[-100.319729,40.879458],[-100.235485,41.221815]]]},"properties":{"id":"69123","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.100748,41.395076],[-101.018945,40.98947],[-100.944866,41.076169],[-100.956572,41.394571],[-101.100748,41.395076]]]},"properties":{"id":"69143","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.621994,42.037122],[-97.639581,41.87325],[-97.523154,41.916411],[-97.621994,42.037122]]]},"properties":{"id":"68715","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.697535,41.858586],[-97.754719,41.656088],[-97.715708,41.598362],[-97.677201,41.598366],[-97.638786,41.61298],[-97.639376,41.800742],[-97.697535,41.858586]]]},"properties":{"id":"68644","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.131637,42.003245],[-103.125362,41.850808],[-102.93678,41.753598],[-102.810468,41.790639],[-103.131637,42.003245]]]},"properties":{"id":"69331","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.839612,40.479788],[-95.952595,40.436211],[-96.028961,40.290532],[-95.860242,40.261417],[-95.766458,40.290859],[-95.735632,40.363793],[-95.744037,40.423998],[-95.839612,40.479788]]]},"properties":{"id":"68305","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.105127,40.000728],[-95.909502,40.000589],[-95.90704,40.033272],[-96.048314,40.109298],[-96.105127,40.000728]]]},"properties":{"id":"68345","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.771025,41.133625],[-97.6557,41.061236],[-97.502259,41.046798],[-97.464912,41.119493],[-97.464651,41.134004],[-97.74276,41.162785],[-97.771025,41.133625]]]},"properties":{"id":"68666","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.444968,41.32085],[-96.52189,41.321596],[-96.541875,41.278241],[-96.541711,41.191757],[-96.483434,41.179875],[-96.445001,41.204813],[-96.444968,41.32085]]]},"properties":{"id":"68041","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.541875,41.278241],[-96.52189,41.321596],[-96.563355,41.379636],[-96.701928,41.343979],[-96.697863,41.256416],[-96.541875,41.278241]]]},"properties":{"id":"68018","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.563355,41.379636],[-96.577075,41.434049],[-96.67434,41.426179],[-96.695406,41.426193],[-96.736746,41.352408],[-96.701928,41.343979],[-96.563355,41.379636]]]},"properties":{"id":"68015","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.519116,41.78993],[-103.515254,41.783508],[-103.492632,41.778528],[-103.519116,41.78993]]]},"properties":{"id":"69355","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.253887,40.843722],[-97.368403,40.843733],[-97.36842,40.771172],[-97.368432,40.72765],[-97.215996,40.698412],[-97.169934,40.727608],[-97.158782,40.792618],[-97.253887,40.843722]]]},"properties":{"id":"68313","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.368115,40.974394],[-97.368403,40.843733],[-97.253887,40.843722],[-97.272787,40.959967],[-97.272811,40.974461],[-97.368115,40.974394]]]},"properties":{"id":"68456","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.26305,42.455394],[-102.474491,42.937283],[-102.561107,42.999781],[-102.712846,42.999981],[-102.672366,42.870484],[-102.498869,42.470967],[-102.26305,42.455394]]]},"properties":{"id":"69360","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.136809,42.090654],[-97.077728,41.902427],[-97.077902,41.85909],[-97.019677,41.873508],[-97.019359,42.090577],[-97.057063,42.090598],[-97.136809,42.090654]]]},"properties":{"id":"68768","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.425958,40.175229],[-97.46358,40.147051],[-97.413394,40.143426],[-97.40728,40.161593],[-97.425958,40.175229]]]},"properties":{"id":"68362","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.28147,42.086201],[-100.362474,41.739641],[-100.267694,42.04423],[-100.267622,42.086147],[-100.28147,42.086201]]]},"properties":{"id":"69142","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.41643,42.190872],[-96.414371,42.104307],[-96.366214,42.060528],[-96.27312,42.047158],[-96.347206,42.191328],[-96.41643,42.190872]]]},"properties":{"id":"68039","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.134948,41.625284],[-99.134754,41.553111],[-98.943214,41.437554],[-98.751302,41.57974],[-98.752225,41.740372],[-98.759133,41.740382],[-99.000933,41.683369],[-99.134948,41.625284]]]},"properties":{"id":"68862","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.09818,41.422175],[-96.016608,41.481918],[-96.122604,41.68304],[-96.154744,41.682994],[-96.300015,41.581866],[-96.280819,41.509281],[-96.155832,41.412844],[-96.136659,41.407708],[-96.09818,41.422175]]]},"properties":{"id":"68008","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.203897,41.400394],[-96.213511,41.400416],[-96.213488,41.39607],[-96.204074,41.394754],[-96.203897,41.400394]]]},"properties":{"id":"68068","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.173768,42.220571],[-97.290863,42.221149],[-97.15444,42.090659],[-97.136809,42.090654],[-97.057063,42.090598],[-97.173768,42.220571]]]},"properties":{"id":"68790","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.290863,42.221149],[-97.173768,42.220571],[-97.115286,42.31488],[-97.173927,42.351444],[-97.251888,42.351626],[-97.310356,42.250061],[-97.290863,42.221149]]]},"properties":{"id":"68723","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.538023,40.219701],[-98.505361,40.364998],[-98.52464,40.39383],[-98.629005,40.393697],[-98.726106,40.234227],[-98.726115,40.176395],[-98.613546,40.161676],[-98.538023,40.219701]]]},"properties":{"id":"68928","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.812451,40.350375],[-98.839944,40.233469],[-98.726106,40.234227],[-98.629005,40.393697],[-98.724085,40.408317],[-98.812451,40.350375]]]},"properties":{"id":"68932","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.067812,42.413767],[-98.067849,42.262829],[-97.93218,42.270725],[-97.951136,42.349862],[-98.067812,42.413767]]]},"properties":{"id":"68720","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.291263,41.596355],[-98.195263,41.646634],[-98.290999,41.712172],[-98.291263,41.596355]]]},"properties":{"id":"68655","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.303657,41.046965],[-99.409167,41.046505],[-99.425749,40.873089],[-99.425974,40.858575],[-99.406603,40.85882],[-99.293039,41.032399],[-99.303657,41.046965]]]},"properties":{"id":"68858","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.32872,41.697832],[-96.154744,41.682994],[-96.122604,41.68304],[-96.104887,41.699379],[-96.09472,41.724586],[-96.1597,41.898823],[-96.320957,41.901435],[-96.32872,41.697832]]]},"properties":{"id":"68061","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.1597,41.898823],[-96.27312,42.047158],[-96.366214,42.060528],[-96.385807,42.016292],[-96.34733,41.901291],[-96.320957,41.901435],[-96.1597,41.898823]]]},"properties":{"id":"68020","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.908628,41.234887],[-96.908528,41.321791],[-96.935842,41.341316],[-96.946243,41.321569],[-96.985225,41.328418],[-97.023228,41.33599],[-96.985299,41.227297],[-96.908473,41.220402],[-96.908628,41.234887]]]},"properties":{"id":"68014","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.044863,40.929472],[-95.872951,40.871346],[-95.848571,40.860016],[-95.879487,41.053299],[-95.996743,41.056999],[-96.012858,41.06081],[-96.02901,41.063986],[-96.063826,40.929271],[-96.044863,40.929472]]]},"properties":{"id":"68048","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.172083,42.802925],[-97.393964,42.86425],[-97.396639,42.84457],[-97.308733,42.757193],[-97.231935,42.761129],[-97.172083,42.802925]]]},"properties":{"id":"68774","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.113489,42.583237],[-97.193293,42.43844],[-97.173927,42.351444],[-97.115286,42.31488],[-97.017789,42.351347],[-97.01796,42.394774],[-97.015358,42.591074],[-97.113489,42.583237]]]},"properties":{"id":"68745","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.251888,42.351626],[-97.173927,42.351444],[-97.193293,42.43844],[-97.232508,42.438532],[-97.251888,42.351626]]]},"properties":{"id":"68717","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.876654,40.698524],[-101.956971,41.003289],[-102.051761,41.00389],[-102.051717,41.002359],[-102.051499,40.639483],[-101.875823,40.625147],[-101.876654,40.698524]]]},"properties":{"id":"69168","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.766699,40.510045],[-97.767193,40.698582],[-97.825723,40.698555],[-97.938445,40.684017],[-97.957243,40.560942],[-97.919378,40.503145],[-97.852753,40.43756],[-97.824246,40.43758],[-97.766699,40.510045]]]},"properties":{"id":"68979","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.011841,41.583168],[-97.020626,41.626768],[-97.213964,41.569026],[-97.214151,41.554648],[-97.197579,41.385069],[-97.059704,41.410986],[-96.90591,41.456436],[-96.90597,41.462418],[-97.011841,41.583168]]]},"properties":{"id":"68661","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.329249,41.77216],[-97.310543,41.61267],[-97.214151,41.554648],[-97.213964,41.569026],[-97.213604,41.815409],[-97.329468,41.837528],[-97.329249,41.77216]]]},"properties":{"id":"68643","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.815285,41.046674],[-99.652575,40.919068],[-99.55959,41.127752],[-99.606702,41.15541],[-99.815285,41.046674]]]},"properties":{"id":"68834","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.77707,42.350051],[-103.067287,42.568143],[-103.60129,42.436434],[-103.77707,42.350051]]]},"properties":{"id":"69354","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.170107,40.555014],[-100.109119,40.786431],[-100.16056,40.8293],[-100.319729,40.879458],[-100.337123,40.74475],[-100.326026,40.526376],[-100.320799,40.49241],[-100.170107,40.555014]]]},"properties":{"id":"69029","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.476486,41.221967],[-102.621033,41.002597],[-102.232276,41.002333],[-102.208127,41.221556],[-102.476486,41.221967]]]},"properties":{"id":"69129","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.081396,41.190756],[-96.081537,41.221543],[-96.15552,41.21928],[-96.158333,41.190762],[-96.110031,41.190664],[-96.081396,41.190756]]]},"properties":{"id":"68137","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.281858,41.292048],[-96.38313,41.300828],[-96.319896,41.132099],[-96.316296,41.125199],[-96.309513,41.190105],[-96.281858,41.292048]]]},"properties":{"id":"68069","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.09818,41.422175],[-96.136659,41.407708],[-96.138867,41.321106],[-96.081544,41.321165],[-96.078959,41.422211],[-96.09818,41.422175]]]},"properties":{"id":"68142","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.406707,40.430172],[-97.463302,40.495762],[-97.520416,40.423047],[-97.509748,40.379546],[-97.440695,40.350462],[-97.368688,40.350392],[-97.406707,40.430172]]]},"properties":{"id":"68416","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.878579,40.20517],[-98.933916,40.002188],[-98.726373,40.0024],[-98.726115,40.176395],[-98.726106,40.234227],[-98.839944,40.233469],[-98.878579,40.20517]]]},"properties":{"id":"68972","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.063888,40.08303],[-99.017862,40.205112],[-99.123009,40.205197],[-99.063888,40.08303]]]},"properties":{"id":"68929","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.387283,40.538976],[-100.477339,40.439516],[-100.364254,40.40961],[-100.320799,40.49241],[-100.326026,40.526376],[-100.387283,40.538976]]]},"properties":{"id":"69042","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.388212,40.030059],[-96.463616,40.189699],[-96.53935,40.189653],[-96.539211,40.146759],[-96.557944,40.001113],[-96.463712,40.001023],[-96.388212,40.030059]]]},"properties":{"id":"68381","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.294237,41.70158],[-102.18896,41.221519],[-102.055351,41.290489],[-102.024832,41.290782],[-101.984491,41.467109],[-101.985523,41.74229],[-102.205778,41.818894],[-102.294237,41.70158]]]},"properties":{"id":"69147","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.179257,40.219957],[-99.309801,40.001812],[-99.179133,40.002126],[-99.179257,40.219957]]]},"properties":{"id":"68971","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.515125,42.422715],[-98.515051,42.262863],[-98.456341,42.350113],[-98.515125,42.422715]]]},"properties":{"id":"68742","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.856496,42.422643],[-98.755623,42.479153],[-98.754327,42.537323],[-98.856496,42.422643]]]},"properties":{"id":"68734","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.721975,41.046674],[-98.613753,41.069989],[-98.657654,41.139515],[-98.710171,41.191652],[-98.7485,41.177318],[-98.748771,41.068874],[-98.721975,41.046674]]]},"properties":{"id":"68820","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.837839,42.655498],[-97.953962,42.684271],[-97.917427,42.611734],[-97.864256,42.579167],[-97.838003,42.555961],[-97.837839,42.655498]]]},"properties":{"id":"68724","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.795917,40.91545],[-96.9105,40.87574],[-96.9106,40.835744],[-96.745845,40.83182],[-96.739212,40.877855],[-96.73914,40.915289],[-96.795917,40.91545]]]},"properties":{"id":"68524","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.723627,40.799113],[-96.724359,40.80658],[-96.796333,40.799193],[-96.811068,40.769576],[-96.796615,40.767899],[-96.720806,40.770184],[-96.718552,40.770183],[-96.723627,40.799113]]]},"properties":{"id":"68522","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.796615,40.767899],[-96.790035,40.697624],[-96.74094,40.697493],[-96.720492,40.711981],[-96.720806,40.770184],[-96.796615,40.767899]]]},"properties":{"id":"68523","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.644134,40.835286],[-96.586801,40.835127],[-96.586562,40.870983],[-96.586419,40.885415],[-96.643066,40.8838],[-96.644134,40.835286]]]},"properties":{"id":"68507","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.723627,40.799113],[-96.693919,40.802495],[-96.693859,40.813615],[-96.682222,40.836087],[-96.682223,40.836584],[-96.712142,40.8335],[-96.724359,40.80658],[-96.723627,40.799113]]]},"properties":{"id":"68508","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.693859,40.813615],[-96.656114,40.815279],[-96.682222,40.836087],[-96.693859,40.813615]]]},"properties":{"id":"68503","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.741272,40.627504],[-96.748144,40.62849],[-96.748318,40.624857],[-96.741123,40.624794],[-96.741272,40.627504]]]},"properties":{"id":"68438","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.910753,40.790486],[-96.932121,40.697992],[-96.838123,40.683133],[-96.790035,40.697624],[-96.796615,40.767899],[-96.811068,40.769576],[-96.910753,40.790486]]]},"properties":{"id":"68339","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.73914,40.915289],[-96.739212,40.877855],[-96.700808,40.915254],[-96.73914,40.915289]]]},"properties":{"id":"68531","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-101.01952,40.960582],[-101.000964,40.89531],[-100.944866,41.076169],[-101.018945,40.98947],[-101.01952,40.960582]]],[[[-101.000908,40.872881],[-101.045579,40.699145],[-100.905537,40.700221],[-100.905496,40.833042],[-101.000908,40.872881]]]]},"properties":{"id":"69132","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.40726,41.395416],[-101.249975,41.00395],[-101.249198,40.975308],[-101.01952,40.960582],[-101.018945,40.98947],[-101.100748,41.395076],[-101.406743,41.656057],[-101.40726,41.395416]]]},"properties":{"id":"69165","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.078755,41.249679],[-98.134844,41.104155],[-98.077382,41.018399],[-97.96376,41.087278],[-97.904989,41.10721],[-97.964074,41.293716],[-98.059634,41.278579],[-98.078755,41.249679]]]},"properties":{"id":"68826","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.134844,41.104155],[-98.078755,41.249679],[-98.193049,41.147652],[-98.173776,41.133149],[-98.134844,41.104155]]]},"properties":{"id":"68816","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.125362,41.850808],[-103.369865,41.655993],[-103.368201,41.583849],[-103.273318,41.438095],[-102.983458,41.481941],[-102.93678,41.753598],[-103.125362,41.850808]]]},"properties":{"id":"69336","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.022469,41.510569],[-98.291344,41.480727],[-98.286584,41.393898],[-98.157729,41.393758],[-98.003135,41.510603],[-98.022469,41.510569]]]},"properties":{"id":"68623","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.709558,40.262018],[-95.766458,40.290859],[-95.860242,40.261417],[-95.784462,40.203656],[-95.784444,40.189137],[-95.72828,40.218285],[-95.709558,40.262018]]]},"properties":{"id":"68442","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.865534,40.522978],[-95.869377,40.522969],[-95.868395,40.516185],[-95.865209,40.517933],[-95.865534,40.522978]]]},"properties":{"id":"68379","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.915691,40.292681],[-97.820738,40.201361],[-97.707925,40.190648],[-97.726616,40.219891],[-97.726819,40.350545],[-97.824114,40.350541],[-97.862211,40.350514],[-97.915691,40.292681]]]},"properties":{"id":"68335","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.236195,40.261954],[-96.350557,40.262542],[-96.388423,40.262478],[-96.369401,40.233566],[-96.293783,40.160704],[-96.132147,40.204036],[-96.117064,40.262357],[-96.236195,40.261954]]]},"properties":{"id":"68441","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.465412,42.322378],[-97.757167,42.227776],[-97.77646,42.177075],[-97.659711,42.090289],[-97.368228,42.134444],[-97.367993,42.2357],[-97.445674,42.307924],[-97.465412,42.322378]]]},"properties":{"id":"68767","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.343056,40.065226],[-100.476963,40.089725],[-100.532916,40.001919],[-100.363489,40.001833],[-100.343056,40.065226]]]},"properties":{"id":"69026","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.662679,42.173094],[-99.662379,42.086009],[-99.462158,42.08665],[-99.234623,42.202283],[-99.234366,42.261274],[-99.234073,42.431649],[-99.244591,42.434639],[-99.402774,42.77158],[-99.481276,42.733186],[-99.677684,42.730903],[-99.676824,42.592117],[-99.662679,42.173094]]]},"properties":{"id":"68714","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.16676,41.14705],[-96.167726,41.126673],[-96.119578,41.126811],[-96.100419,41.16168],[-96.110031,41.190664],[-96.158333,41.190762],[-96.16676,41.14705]]]},"properties":{"id":"68138","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.407258,41.32505],[-96.444968,41.32085],[-96.445001,41.204813],[-96.319896,41.132099],[-96.38313,41.300828],[-96.407258,41.32505]]]},"properties":{"id":"68073","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.701928,41.343979],[-96.736746,41.352408],[-96.775373,41.34635],[-96.813454,41.260471],[-96.69764,41.227125],[-96.697863,41.256416],[-96.701928,41.343979]]]},"properties":{"id":"68040","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.078959,41.422211],[-96.023876,41.393154],[-95.969735,41.428847],[-95.920846,41.443449],[-96.016608,41.481918],[-96.09818,41.422175],[-96.078959,41.422211]]]},"properties":{"id":"68023","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.280819,41.509281],[-96.252016,41.414901],[-96.213511,41.400416],[-96.203897,41.400394],[-96.155832,41.412844],[-96.280819,41.509281]]]},"properties":{"id":"68034","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.613546,40.161676],[-98.726115,40.176395],[-98.726373,40.0024],[-98.575553,40.00247],[-98.613546,40.161676]]]},"properties":{"id":"68952","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.344369,40.415462],[-98.27809,40.350347],[-98.203576,40.350392],[-98.203636,40.364903],[-98.165086,40.45774],[-98.278243,40.526093],[-98.353779,40.480852],[-98.344369,40.415462]]]},"properties":{"id":"68941","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.723955,40.509722],[-98.724085,40.408317],[-98.629005,40.393697],[-98.629066,40.520293],[-98.64815,40.524142],[-98.724032,40.524222],[-98.723955,40.509722]]]},"properties":{"id":"68950","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.184081,40.698353],[-98.282827,40.712788],[-98.335287,40.69836],[-98.260376,40.640342],[-98.221827,40.64043],[-98.184081,40.698353]]]},"properties":{"id":"68980","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.83101,41.422333],[-103.520363,41.450038],[-103.368201,41.583849],[-103.369865,41.655993],[-103.494158,41.69949],[-103.846712,41.697988],[-104.052688,41.697954],[-104.052735,41.613676],[-103.988469,41.536874],[-104.052434,41.494678],[-104.052529,41.46537],[-103.83101,41.422333]]]},"properties":{"id":"69345","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.513829,41.739882],[-100.2924,41.566856],[-100.252804,41.566751],[-99.93804,41.920537],[-100.09205,42.024821],[-100.267694,42.04423],[-100.362474,41.739641],[-100.513829,41.739882]]]},"properties":{"id":"68833","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.022976,41.575041],[-98.022469,41.510569],[-98.003135,41.510603],[-97.897663,41.466357],[-97.715708,41.598362],[-97.754719,41.656088],[-97.869294,41.69907],[-98.022976,41.575041]]]},"properties":{"id":"68660","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.810468,41.790639],[-102.732625,41.714741],[-102.671927,41.765053],[-102.534335,42.356682],[-102.871631,42.43892],[-103.401639,42.00354],[-103.363337,42.00293],[-103.131637,42.003245],[-102.810468,41.790639]]]},"properties":{"id":"69301","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.803535,42.825868],[-98.801503,42.998293],[-98.96219,42.998288],[-99.021172,42.884033],[-98.803535,42.825868]]]},"properties":{"id":"68722","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.985938,42.780878],[-100.134432,42.821222],[-100.198478,42.846102],[-100.183167,42.433497],[-100.167454,42.368181],[-99.985938,42.780878]]]},"properties":{"id":"69214","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.368273,41.337153],[-97.197579,41.385069],[-97.214151,41.554648],[-97.310543,41.61267],[-97.407083,41.641919],[-97.407333,41.612983],[-97.522711,41.474949],[-97.703881,41.420939],[-97.703765,41.394876],[-97.598237,41.331044],[-97.464415,41.308237],[-97.368273,41.337153]],[[-97.501687,41.389363],[-97.488358,41.3952],[-97.488268,41.384564],[-97.501687,41.389363]]]},"properties":{"id":"68601","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.054459,40.837695],[-96.04502,40.769644],[-96.002588,40.755133],[-95.949768,40.784077],[-95.959223,40.87135],[-96.044863,40.929472],[-96.063826,40.929271],[-96.054459,40.837695]]]},"properties":{"id":"68413","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.120799,41.037988],[-96.229589,40.999592],[-96.239256,40.996411],[-96.250919,40.97193],[-96.168654,40.928834],[-96.159034,40.928912],[-96.063826,40.929271],[-96.02901,41.063986],[-96.081073,41.057125],[-96.120799,41.037988]]]},"properties":{"id":"68037","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.120799,41.037988],[-96.081073,41.057125],[-96.100192,41.053248],[-96.120799,41.037988]]]},"properties":{"id":"68016","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.521344,41.045248],[-96.350017,40.957036],[-96.250919,40.97193],[-96.239256,40.996411],[-96.273646,41.019868],[-96.316296,41.125199],[-96.319896,41.132099],[-96.445001,41.204813],[-96.483434,41.179875],[-96.514478,41.07425],[-96.521344,41.045248]],[[-96.454376,41.100243],[-96.43533,41.103163],[-96.437494,41.088638],[-96.454376,41.100243]]]},"properties":{"id":"68003","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.872951,40.871346],[-96.044863,40.929472],[-95.959223,40.87135],[-95.872951,40.871346]]]},"properties":{"id":"68409","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.273646,41.019868],[-96.239256,40.996411],[-96.229589,40.999592],[-96.234502,41.004348],[-96.273646,41.019868]]]},"properties":{"id":"68058","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.720683,42.847317],[-97.4856,42.627175],[-97.44591,42.685155],[-97.400549,42.844597],[-97.433812,42.850164],[-97.720683,42.847317]]]},"properties":{"id":"68730","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.485564,40.495438],[-101.496624,40.698499],[-101.589698,40.69976],[-101.876654,40.698524],[-101.875823,40.625147],[-101.72176,40.349644],[-101.683009,40.349695],[-101.589214,40.349928],[-101.485564,40.495438]]]},"properties":{"id":"69033","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.862762,42.999374],[-101.277483,42.613694],[-101.256419,42.659856],[-101.51424,42.997054],[-101.862762,42.999374]]]},"properties":{"id":"69218","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.944532,41.612019],[-96.905984,41.539316],[-96.828769,41.607522],[-96.790196,41.74257],[-96.922866,41.844185],[-96.961688,41.85722],[-96.944532,41.612019]]]},"properties":{"id":"68633","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.905984,41.539316],[-96.944532,41.612019],[-97.011841,41.583168],[-96.90597,41.462418],[-96.905984,41.539316]]]},"properties":{"id":"68659","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.76824,42.003489],[-96.903387,41.873181],[-96.729176,41.98902],[-96.76824,42.003489]]]},"properties":{"id":"68716","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.777838,42.017082],[-96.901153,42.119429],[-96.932053,42.127318],[-97.019359,42.090577],[-97.019677,41.873508],[-96.961688,41.85722],[-96.922866,41.844185],[-96.903387,41.873181],[-96.76824,42.003489],[-96.777838,42.017082]]]},"properties":{"id":"68791","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.010289,41.451897],[-99.86702,41.437688],[-99.673527,41.567914],[-100.020441,41.480878],[-100.010289,41.451897]]]},"properties":{"id":"68856","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.506521,42.485278],[-96.631492,42.524318],[-96.722231,42.524652],[-96.687382,42.380026],[-96.551053,42.423275],[-96.522804,42.469835],[-96.506521,42.485278]]]},"properties":{"id":"68743","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.577075,41.434049],[-96.616306,41.524454],[-96.674349,41.553498],[-96.67434,41.426179],[-96.577075,41.434049]]]},"properties":{"id":"68621","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.386513,41.59635],[-96.541079,41.561415],[-96.434785,41.495195],[-96.386513,41.59635]]]},"properties":{"id":"68044","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.138808,41.277663],[-96.081325,41.277789],[-96.076132,41.277804],[-96.081329,41.317677],[-96.081544,41.321165],[-96.138867,41.321106],[-96.138808,41.277663]]]},"properties":{"id":"68164","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.158055,41.277544],[-96.196548,41.277572],[-96.196287,41.248491],[-96.157857,41.248537],[-96.158055,41.277544]]]},"properties":{"id":"68118","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.018144,41.317275],[-95.980581,41.317123],[-95.98055,41.317592],[-95.969735,41.428847],[-96.023876,41.393154],[-96.018144,41.317275]]]},"properties":{"id":"68152","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.969735,41.428847],[-95.98055,41.317592],[-95.946923,41.317589],[-95.927206,41.322267],[-95.920846,41.443449],[-95.969735,41.428847]]]},"properties":{"id":"68112","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.081396,41.190756],[-96.009261,41.190702],[-96.009134,41.1907],[-96.023897,41.22144],[-96.023894,41.221681],[-96.081537,41.221543],[-96.081396,41.190756]]]},"properties":{"id":"68127","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.982237,41.255616],[-95.947561,41.253902],[-95.94682,41.26374],[-95.951309,41.26397],[-95.952208,41.267951],[-95.947024,41.267964],[-95.947028,41.27199],[-95.948567,41.274709],[-95.980341,41.273642],[-95.980344,41.273174],[-95.982237,41.255616]]]},"properties":{"id":"68131","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.947108,41.250616],[-95.917472,41.249826],[-95.917872,41.274085],[-95.947028,41.27199],[-95.947024,41.267964],[-95.94682,41.26374],[-95.947561,41.253902],[-95.947108,41.250616]]]},"properties":{"id":"68102","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.671992,40.582278],[-97.482796,40.534557],[-97.463667,40.539011],[-97.501906,40.712916],[-97.710003,40.698628],[-97.671992,40.582278]]]},"properties":{"id":"68354","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.274012,40.480774],[-97.406707,40.430172],[-97.368688,40.350392],[-97.273962,40.350096],[-97.274012,40.480774]]]},"properties":{"id":"68453","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.482809,40.350866],[-99.595031,40.351159],[-99.698647,40.351171],[-99.761424,40.176139],[-99.742468,40.104017],[-99.573379,40.169025],[-99.45275,40.292885],[-99.454414,40.350682],[-99.482809,40.350866]]]},"properties":{"id":"68967","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.966826,40.168763],[-99.96708,40.059763],[-99.948531,40.001818],[-99.741159,40.001761],[-99.742468,40.104017],[-99.761424,40.176139],[-99.818624,40.205002],[-99.959513,40.175917],[-99.966826,40.168763]]]},"properties":{"id":"68926","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.422704,41.742246],[-102.553107,41.3743],[-102.476486,41.221967],[-102.208127,41.221556],[-102.18896,41.221519],[-102.294237,41.70158],[-102.422704,41.742246]]]},"properties":{"id":"69154","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.559344,41.452411],[-98.540333,41.394085],[-98.514906,41.394118],[-98.315727,41.336187],[-98.286584,41.393898],[-98.291344,41.480727],[-98.291362,41.523913],[-98.559344,41.452411]]]},"properties":{"id":"68882","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.465174,40.902303],[-98.493551,40.800542],[-98.444354,40.811812],[-98.460976,40.916914],[-98.465174,40.902303]]]},"properties":{"id":"68810","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.282827,40.712788],[-98.184081,40.698353],[-98.07309,40.741837],[-98.187551,40.821942],[-98.2256,40.820939],[-98.282827,40.712788]]]},"properties":{"id":"68841","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.29244,40.314398],[-99.292609,40.306638],[-99.273816,40.307107],[-99.29244,40.314398]]]},"properties":{"id":"68969","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.305394,42.523863],[-98.515125,42.422715],[-98.456341,42.350113],[-98.339633,42.321002],[-98.305436,42.515788],[-98.305394,42.523863]]]},"properties":{"id":"68766","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.885925,42.088324],[-98.827885,42.349754],[-98.885771,42.393703],[-99.234366,42.261274],[-99.234623,42.202283],[-98.885925,42.088324]]]},"properties":{"id":"68711","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.066707,40.102947],[-97.10515,40.001725],[-97.010354,40.001436],[-97.066707,40.102947]]]},"properties":{"id":"68350","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.179383,40.612161],[-99.179415,40.423295],[-99.064677,40.35055],[-99.02751,40.350533],[-99.066003,40.626424],[-99.084784,40.626425],[-99.179383,40.612161]]]},"properties":{"id":"68924","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.962375,40.350576],[-98.812451,40.350375],[-98.724085,40.408317],[-98.723955,40.509722],[-98.837867,40.611155],[-98.95142,40.641009],[-99.066003,40.626424],[-99.02751,40.350533],[-98.962375,40.350576]]]},"properties":{"id":"68959","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.249975,41.00395],[-101.40726,41.395416],[-101.500449,41.395354],[-101.596056,41.163594],[-101.478646,41.003216],[-101.249975,41.00395]]]},"properties":{"id":"69155","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.094339,42.532501],[-97.864256,42.579167],[-97.917427,42.611734],[-98.094339,42.532501]]]},"properties":{"id":"68789","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.796333,40.799193],[-96.724359,40.80658],[-96.712142,40.8335],[-96.745845,40.83182],[-96.9106,40.835744],[-96.910757,40.799368],[-96.796333,40.799193]]]},"properties":{"id":"68528","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.586801,40.835127],[-96.644134,40.835286],[-96.641825,40.813475],[-96.603397,40.813416],[-96.586887,40.82784],[-96.586801,40.835127]]]},"properties":{"id":"68505","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.956572,41.394571],[-100.811734,41.393781],[-100.842459,41.740404],[-101.406466,41.7432],[-101.406682,41.673403],[-101.406743,41.656057],[-101.100748,41.395076],[-100.956572,41.394571]]]},"properties":{"id":"69167","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.523154,41.916411],[-97.639581,41.87325],[-97.697536,41.873071],[-97.697535,41.858586],[-97.639376,41.800742],[-97.397016,41.743155],[-97.329249,41.77216],[-97.329468,41.837528],[-97.309795,41.935176],[-97.523154,41.916411]]]},"properties":{"id":"68748","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.598237,41.331044],[-97.703765,41.394876],[-97.811147,41.379955],[-97.848627,41.307801],[-97.752346,41.220823],[-97.598237,41.331044]]]},"properties":{"id":"68663","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.059634,41.278579],[-97.964074,41.293716],[-97.848627,41.307801],[-97.811147,41.379955],[-97.897663,41.466357],[-98.003135,41.510603],[-98.157729,41.393758],[-98.059634,41.278579]]]},"properties":{"id":"68638","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.159038,40.746864],[-96.121126,40.697073],[-96.006894,40.740636],[-96.002588,40.755133],[-96.04502,40.769644],[-96.159038,40.746864]]]},"properties":{"id":"68417","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.324071,40.624059],[-96.255461,40.595391],[-96.236187,40.769308],[-96.236322,40.783734],[-96.321824,40.78351],[-96.324071,40.624059]]]},"properties":{"id":"68454","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.53036,40.62653],[-99.549403,40.437068],[-99.454945,40.569075],[-99.493549,40.633064],[-99.53036,40.62653]]]},"properties":{"id":"68958","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.698902,42.43806],[-97.757167,42.227776],[-97.465412,42.322378],[-97.568157,42.438526],[-97.698902,42.43806]]]},"properties":{"id":"68765","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.464415,41.308237],[-97.598237,41.331044],[-97.752346,41.220823],[-97.74276,41.162785],[-97.464651,41.134004],[-97.464415,41.308237]]]},"properties":{"id":"68651","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.6557,41.061236],[-97.712081,41.046625],[-97.69324,40.973917],[-97.521347,40.987973],[-97.520779,41.009395],[-97.502259,41.046798],[-97.6557,41.061236]]]},"properties":{"id":"68316","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.822119,40.0005],[-95.671368,40.000339],[-95.671383,40.102065],[-95.765822,40.11663],[-95.822119,40.0005]]]},"properties":{"id":"68433","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.158333,41.190762],[-96.215757,41.190504],[-96.16676,41.14705],[-96.158333,41.190762]]]},"properties":{"id":"68136","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.7168,41.125737],[-96.563274,41.089091],[-96.541711,41.191757],[-96.541875,41.278241],[-96.697863,41.256416],[-96.69764,41.227125],[-96.7168,41.125737]]]},"properties":{"id":"68066","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.910565,40.929891],[-96.967862,40.901105],[-97.006023,40.843244],[-96.990876,40.697942],[-96.932121,40.697992],[-96.910753,40.790486],[-96.910757,40.799368],[-96.9106,40.835744],[-96.9105,40.87574],[-96.910565,40.929891]]]},"properties":{"id":"68423","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.910565,40.929891],[-96.910621,40.965395],[-96.929734,40.980026],[-96.948874,40.987855],[-97.006124,40.966442],[-96.967862,40.901105],[-96.910565,40.929891]]]},"properties":{"id":"68360","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.218399,40.836055],[-97.221626,40.829322],[-97.216133,40.829371],[-97.218399,40.836055]]]},"properties":{"id":"68364","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.038575,42.375904],[-102.203614,42.38389],[-102.211226,42.008334],[-102.039662,42.183954],[-102.038575,42.375904]]]},"properties":{"id":"69340","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.726616,40.219891],[-97.617766,40.249126],[-97.613359,40.292795],[-97.653382,40.350563],[-97.726819,40.350545],[-97.726616,40.219891]]]},"properties":{"id":"68326","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.617766,40.249126],[-97.726616,40.219891],[-97.707925,40.190648],[-97.689529,40.0456],[-97.538829,40.060079],[-97.369112,40.074518],[-97.369121,40.190712],[-97.40728,40.161593],[-97.413394,40.143426],[-97.46358,40.147051],[-97.425958,40.175229],[-97.463425,40.205066],[-97.617766,40.249126]]]},"properties":{"id":"68370","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.820199,41.394513],[-98.751558,41.394084],[-98.751302,41.57974],[-98.943214,41.437554],[-98.961999,41.394346],[-98.820199,41.394513]]]},"properties":{"id":"68859","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.710003,40.698628],[-97.501906,40.712916],[-97.463401,40.769526],[-97.692595,40.756557],[-97.711595,40.698623],[-97.710003,40.698628]]]},"properties":{"id":"68401","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.721532,40.719249],[-98.847673,40.666381],[-98.800403,40.625698],[-98.724032,40.524222],[-98.64815,40.524142],[-98.629202,40.683806],[-98.721532,40.719249]]]},"properties":{"id":"68956","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.09505,42.148358],[-98.315176,42.088804],[-98.373596,41.98736],[-98.295602,41.914954],[-98.293768,41.914957],[-97.968936,41.915746],[-97.959807,41.988051],[-98.021591,42.089382],[-98.09505,42.148358]]]},"properties":{"id":"68636","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.968936,41.915746],[-97.833303,41.901764],[-97.794876,41.901699],[-97.834858,42.162251],[-97.834945,42.205707],[-97.931479,42.110894],[-97.959807,41.988051],[-97.968936,41.915746]]]},"properties":{"id":"68781","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.984491,41.467109],[-101.661081,41.395353],[-101.500449,41.395354],[-101.40726,41.395416],[-101.406743,41.656057],[-101.406682,41.673403],[-101.573048,41.699683],[-101.854424,41.742194],[-101.985523,41.74229],[-101.984491,41.467109]]]},"properties":{"id":"69121","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.252804,41.566751],[-100.020441,41.480878],[-99.673527,41.567914],[-99.616339,41.665847],[-99.5982,41.740477],[-99.68647,41.907227],[-99.93804,41.920537],[-100.252804,41.566751]]]},"properties":{"id":"68813","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.084646,40.851466],[-99.084784,40.626425],[-99.066003,40.626424],[-98.95142,40.641009],[-98.969175,40.858872],[-98.988273,40.873372],[-99.084581,40.873161],[-99.084646,40.851466]]]},"properties":{"id":"68847","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.293039,41.032399],[-99.406603,40.85882],[-99.312269,40.786612],[-99.236083,40.786338],[-99.197764,40.916456],[-99.293039,41.032399]]]},"properties":{"id":"68812","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.463714,40.870979],[-96.3888,40.82742],[-96.332093,40.885325],[-96.351157,40.914066],[-96.46372,40.899983],[-96.463714,40.870979]]]},"properties":{"id":"68304","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.433812,42.850164],[-97.400549,42.844597],[-97.396639,42.84457],[-97.393964,42.86425],[-97.433812,42.850164]]]},"properties":{"id":"57078","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.937243,42.539481],[-96.780947,42.663547],[-97.015849,42.761338],[-97.054369,42.683848],[-97.015422,42.597558],[-96.937243,42.539481]],[[-96.986704,42.691706],[-96.975957,42.691367],[-96.976087,42.68768],[-96.984184,42.687746],[-96.986704,42.691706]]]},"properties":{"id":"68757","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.445674,42.307924],[-97.367993,42.2357],[-97.310356,42.250061],[-97.251888,42.351626],[-97.232508,42.438532],[-97.308841,42.496606],[-97.406511,42.525933],[-97.465319,42.467512],[-97.465446,42.438625],[-97.445674,42.307924]]]},"properties":{"id":"68771","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.532109,40.350038],[-101.485564,40.495438],[-101.589214,40.349928],[-101.532109,40.350038]]]},"properties":{"id":"69027","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.995227,40.561064],[-97.957243,40.560942],[-97.938445,40.684017],[-97.976462,40.669627],[-97.995227,40.561064]]]},"properties":{"id":"68975","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.077902,41.85909],[-97.020626,41.626768],[-97.011841,41.583168],[-96.944532,41.612019],[-96.961688,41.85722],[-97.019677,41.873508],[-97.077902,41.85909]]]},"properties":{"id":"68641","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.616339,41.665847],[-99.673527,41.567914],[-99.86702,41.437688],[-99.720336,41.219715],[-99.606702,41.15541],[-99.55959,41.127752],[-99.464623,41.081169],[-99.501319,41.14741],[-99.443034,41.49627],[-99.616339,41.665847]]]},"properties":{"id":"68822","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.052783,40.872898],[-100.16056,40.8293],[-100.109119,40.786431],[-99.95679,40.743202],[-99.901377,40.742885],[-99.843274,41.046634],[-99.964027,41.047076],[-100.034541,41.046929],[-100.062256,40.902331],[-100.052783,40.872898]]]},"properties":{"id":"69130","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.981495,40.496431],[-99.95679,40.743202],[-100.109119,40.786431],[-100.170107,40.555014],[-99.981495,40.496431]]]},"properties":{"id":"69028","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.93715,42.525064],[-96.939922,42.412486],[-96.881641,42.351261],[-96.842555,42.351186],[-96.765298,42.394607],[-96.770513,42.474593],[-96.93715,42.525064]]]},"properties":{"id":"68710","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.578811,41.742068],[-96.674357,41.563622],[-96.674349,41.553498],[-96.616306,41.524454],[-96.541079,41.561415],[-96.386513,41.59635],[-96.386416,41.610814],[-96.42508,41.683652],[-96.463463,41.74178],[-96.482288,41.741838],[-96.482839,41.727393],[-96.502257,41.712714],[-96.503967,41.741882],[-96.574538,41.742053],[-96.578811,41.742068]],[[-96.505048,41.607271],[-96.506896,41.607297],[-96.506985,41.609365],[-96.504106,41.60939],[-96.505048,41.607271]]]},"properties":{"id":"68031","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.674357,41.563622],[-96.828769,41.607522],[-96.905984,41.539316],[-96.90597,41.462418],[-96.90591,41.456436],[-96.859707,41.448745],[-96.695406,41.426193],[-96.67434,41.426179],[-96.674349,41.553498],[-96.674357,41.563622]]]},"properties":{"id":"68649","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.084221,41.25011],[-96.119575,41.248635],[-96.148236,41.248569],[-96.154766,41.248559],[-96.157324,41.221426],[-96.15552,41.21928],[-96.081537,41.221543],[-96.081444,41.248722],[-96.084221,41.25011]]]},"properties":{"id":"68144","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.02393,41.248778],[-96.023894,41.221681],[-96.023897,41.22144],[-95.981168,41.223296],[-95.984392,41.253357],[-96.023898,41.256025],[-96.02393,41.248778]]]},"properties":{"id":"68106","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.947561,41.253902],[-95.982237,41.255616],[-95.984392,41.253357],[-95.981168,41.223296],[-95.975839,41.224725],[-95.947038,41.226202],[-95.947108,41.250616],[-95.947561,41.253902]]]},"properties":{"id":"68105","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.081444,41.248722],[-96.081537,41.221543],[-96.023894,41.221681],[-96.02393,41.248778],[-96.081444,41.248722]]]},"properties":{"id":"68124","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.281858,41.292048],[-96.309513,41.190105],[-96.234288,41.190349],[-96.234339,41.219302],[-96.196287,41.248491],[-96.196548,41.277572],[-96.196692,41.320927],[-96.253408,41.378981],[-96.281858,41.292048]]]},"properties":{"id":"68022","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.155832,41.412844],[-96.203897,41.400394],[-96.204074,41.394754],[-96.213488,41.39607],[-96.213511,41.400416],[-96.252016,41.414901],[-96.269528,41.393139],[-96.253408,41.378981],[-96.196692,41.320927],[-96.138867,41.321106],[-96.136659,41.407708],[-96.155832,41.412844]]]},"properties":{"id":"68007","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.119575,41.248635],[-96.119614,41.263124],[-96.146273,41.262708],[-96.148236,41.248569],[-96.119575,41.248635]]]},"properties":{"id":"68010","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.777796,40.349689],[-101.865489,40.003023],[-101.746106,40.002851],[-101.683009,40.349695],[-101.72176,40.349644],[-101.777796,40.349689]]]},"properties":{"id":"69041","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.009521,40.234423],[-98.878579,40.20517],[-98.839944,40.233469],[-98.812451,40.350375],[-98.962375,40.350576],[-99.009521,40.234423]]]},"properties":{"id":"68981","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.063888,40.08303],[-99.123009,40.205197],[-99.142516,40.219772],[-99.179257,40.219957],[-99.179133,40.002126],[-99.066106,40.002344],[-99.063888,40.08303]]]},"properties":{"id":"68960","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.273816,40.307107],[-99.179257,40.219957],[-99.142516,40.219772],[-99.064677,40.35055],[-99.179415,40.423295],[-99.198416,40.423225],[-99.29244,40.314398],[-99.273816,40.307107]]]},"properties":{"id":"68982","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.811698,40.453308],[-99.818624,40.205002],[-99.761424,40.176139],[-99.698647,40.351171],[-99.736998,40.438269],[-99.779744,40.467492],[-99.811698,40.453308]]]},"properties":{"id":"68936","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.784687,40.117617],[-96.539211,40.146759],[-96.53935,40.189653],[-96.539376,40.204173],[-96.784593,40.146712],[-96.784687,40.117617]]]},"properties":{"id":"68318","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.048476,40.09554],[-97.004907,40.001521],[-96.916407,40.001511],[-96.878708,40.146622],[-96.916383,40.175643],[-97.010895,40.160946],[-97.048476,40.09554]]]},"properties":{"id":"68342","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.378564,40.829328],[-98.339964,41.046104],[-98.469818,41.046454],[-98.49279,41.046492],[-98.460976,40.916914],[-98.444354,40.811812],[-98.378564,40.829328]]]},"properties":{"id":"68803","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.96376,41.087278],[-97.902692,41.003021],[-97.826241,41.017395],[-97.847079,41.118705],[-97.904989,41.10721],[-97.96376,41.087278]]]},"properties":{"id":"68846","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.985061,40.378334],[-101.174188,40.495151],[-101.325279,40.293077],[-101.172881,40.205856],[-101.003003,40.249286],[-100.985061,40.378334]]]},"properties":{"id":"69040","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.540333,41.394085],[-98.74433,41.394178],[-98.689817,41.26455],[-98.612246,41.249282],[-98.514906,41.394118],[-98.540333,41.394085]]]},"properties":{"id":"68835","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.123727,40.349784],[-97.123748,40.291463],[-97.104929,40.277122],[-96.916289,40.26272],[-96.916139,40.320728],[-97.046283,40.349655],[-97.122189,40.349779],[-97.123727,40.349784]]]},"properties":{"id":"68424","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.116732,40.450689],[-96.255435,40.450413],[-96.362482,40.348983],[-96.350557,40.262542],[-96.236195,40.261954],[-96.067084,40.34913],[-96.116732,40.450689]]]},"properties":{"id":"68450","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.741956,40.523104],[-96.790899,40.624718],[-96.912247,40.552667],[-96.89339,40.523543],[-96.796584,40.523076],[-96.741956,40.523104]]]},"properties":{"id":"68368","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.563274,41.089091],[-96.7168,41.125737],[-96.764359,41.132707],[-96.719863,41.031325],[-96.700603,40.995537],[-96.642993,40.972726],[-96.604171,40.986853],[-96.563469,41.045342],[-96.521344,41.045248],[-96.514478,41.07425],[-96.563274,41.089091]]]},"properties":{"id":"68017","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.956572,41.394571],[-100.944866,41.076169],[-101.000964,40.89531],[-101.000908,40.872881],[-100.905496,40.833042],[-100.639982,40.888831],[-100.580598,41.292278],[-100.811734,41.393781],[-100.956572,41.394571]]]},"properties":{"id":"69101","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.873031,40.700254],[-100.60983,40.700114],[-100.564451,40.814425],[-100.639982,40.888831],[-100.905496,40.833042],[-100.905537,40.700221],[-100.873031,40.700254]]]},"properties":{"id":"69170","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.26893,41.075358],[-98.144028,40.985088],[-98.097111,41.008176],[-98.077382,41.018399],[-98.134844,41.104155],[-98.173776,41.133149],[-98.26893,41.075358]]]},"properties":{"id":"68827","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.18045,40.176496],[-98.123857,40.293059],[-98.203576,40.350392],[-98.27809,40.350347],[-98.292571,40.227154],[-98.256097,40.133059],[-98.18045,40.176496]]]},"properties":{"id":"68957","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.123857,40.293059],[-98.18045,40.176496],[-97.934055,40.125418],[-97.877506,40.176107],[-97.953433,40.292515],[-98.085872,40.307559],[-98.123857,40.293059]]]},"properties":{"id":"68961","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.067012,40.523148],[-95.923899,40.523018],[-95.991741,40.581189],[-96.067402,40.595662],[-96.105151,40.566878],[-96.067012,40.523148]]]},"properties":{"id":"68448","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.023438,40.595573],[-96.023172,40.599197],[-96.025744,40.599166],[-96.025598,40.595589],[-96.023438,40.595573]]]},"properties":{"id":"68382","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.236187,40.769308],[-96.255461,40.595391],[-96.236468,40.537286],[-96.105151,40.566878],[-96.067402,40.595662],[-96.121126,40.697073],[-96.159038,40.746864],[-96.236187,40.769308]]]},"properties":{"id":"68446","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.236468,40.537286],[-96.255461,40.595391],[-96.324071,40.624059],[-96.331256,40.609833],[-96.349762,40.522868],[-96.236461,40.52279],[-96.236468,40.537286]]]},"properties":{"id":"68324","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.407334,40.262431],[-96.369401,40.233566],[-96.388423,40.262478],[-96.407334,40.262431]]]},"properties":{"id":"68380","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.179248,40.660541],[-99.322746,40.612681],[-99.198416,40.423225],[-99.179415,40.423295],[-99.179383,40.612161],[-99.179248,40.660541]]]},"properties":{"id":"68940","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.568157,42.438526],[-97.465412,42.322378],[-97.445674,42.307924],[-97.465446,42.438625],[-97.485298,42.438578],[-97.568157,42.438526]]]},"properties":{"id":"68747","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.648205,40.189457],[-95.671383,40.102065],[-95.671368,40.000339],[-95.455283,40.000307],[-95.48256,40.192519],[-95.552473,40.261904],[-95.564093,40.261891],[-95.648205,40.189457]]]},"properties":{"id":"68355","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.72828,40.218285],[-95.648205,40.189457],[-95.564093,40.261891],[-95.709558,40.262018],[-95.72828,40.218285]]]},"properties":{"id":"68437","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.986893,41.161691],[-95.996743,41.056999],[-95.879487,41.053299],[-95.863131,41.093733],[-95.909008,41.099054],[-95.926351,41.135479],[-95.925445,41.167338],[-95.984145,41.161726],[-95.986893,41.161691]]]},"properties":{"id":"68123","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.215757,41.190504],[-96.234288,41.190349],[-96.309513,41.190105],[-96.316296,41.125199],[-96.273646,41.019868],[-96.234502,41.004348],[-96.167726,41.126673],[-96.16676,41.14705],[-96.215757,41.190504]]]},"properties":{"id":"68028","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.052688,41.697954],[-103.846712,41.697988],[-103.868983,41.812924],[-103.907272,41.856912],[-104.052725,41.971999],[-104.052688,41.697954]]]},"properties":{"id":"69352","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.561107,42.999781],[-102.474491,42.937283],[-102.470904,42.999731],[-102.561107,42.999781]]]},"properties":{"id":"69365","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.97716,41.133806],[-98.748771,41.068874],[-98.7485,41.177318],[-98.861353,41.206874],[-98.977143,41.169243],[-98.97716,41.133806]]]},"properties":{"id":"68871","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.5051,43.00077],[-104.05265,42.249962],[-103.914637,42.257018],[-103.777053,42.349804],[-103.77707,42.350051],[-103.60129,42.436434],[-103.50335,42.801556],[-103.504819,42.977196],[-103.5051,43.00077]]]},"properties":{"id":"69346","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.554179,42.191364],[-96.628772,42.249565],[-96.795921,42.191811],[-96.589833,42.160775],[-96.554179,42.191364]]]},"properties":{"id":"68062","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.628772,42.249565],[-96.554179,42.191364],[-96.41643,42.190872],[-96.347206,42.191328],[-96.356395,42.276494],[-96.485103,42.276923],[-96.550816,42.277731],[-96.628772,42.249565]]]},"properties":{"id":"68071","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.414371,42.104307],[-96.55091,42.111317],[-96.570396,42.090016],[-96.554866,42.015875],[-96.385807,42.016292],[-96.366214,42.060528],[-96.414371,42.104307]]]},"properties":{"id":"68055","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.521347,40.987973],[-97.463401,40.769526],[-97.36842,40.771172],[-97.368403,40.843733],[-97.368115,40.974394],[-97.520779,41.009395],[-97.521347,40.987973]]]},"properties":{"id":"68460","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.629005,40.393697],[-98.52464,40.39383],[-98.496738,40.495464],[-98.629066,40.520293],[-98.629005,40.393697]]]},"properties":{"id":"68973","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.465174,40.902303],[-98.684549,40.916766],[-98.721532,40.719249],[-98.629202,40.683806],[-98.492563,40.698604],[-98.493551,40.800542],[-98.465174,40.902303]]]},"properties":{"id":"68883","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.067849,42.262829],[-98.09505,42.148358],[-98.021591,42.089382],[-97.931479,42.110894],[-97.834945,42.205707],[-97.93218,42.270725],[-98.067849,42.262829]]]},"properties":{"id":"68756","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.021591,42.089382],[-97.959807,41.988051],[-97.931479,42.110894],[-98.021591,42.089382]]]},"properties":{"id":"68761","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.12486,42.437228],[-98.116038,42.27729],[-98.067849,42.262829],[-98.067812,42.413767],[-98.12486,42.437228]]]},"properties":{"id":"68773","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.108643,42.497192],[-98.305436,42.515788],[-98.339633,42.321002],[-98.242672,42.262852],[-98.116038,42.27729],[-98.12486,42.437228],[-98.108643,42.497192]]]},"properties":{"id":"68764","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.052735,41.613676],[-104.052434,41.494678],[-103.988469,41.536874],[-104.052735,41.613676]]]},"properties":{"id":"82082","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.067287,42.568143],[-103.77707,42.350051],[-103.777053,42.349804],[-103.679397,42.030406],[-103.519556,42.003731],[-103.401639,42.00354],[-102.871631,42.43892],[-102.851551,42.568982],[-103.067265,42.579648],[-103.067287,42.568143]]]},"properties":{"id":"69348","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.614281,42.998446],[-98.545461,42.799443],[-98.531944,42.998456],[-98.614281,42.998446]]]},"properties":{"id":"68719","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.662679,42.173094],[-99.760873,42.349525],[-99.676824,42.592117],[-99.677684,42.730903],[-99.985938,42.780878],[-100.167454,42.368181],[-100.167605,42.085848],[-100.074954,42.085532],[-99.686959,42.086075],[-99.662379,42.086009],[-99.662679,42.173094]]]},"properties":{"id":"69210","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.969175,40.858872],[-98.95142,40.641009],[-98.837867,40.611155],[-98.800403,40.625698],[-98.847673,40.666381],[-98.836008,40.872984],[-98.969175,40.858872]]]},"properties":{"id":"68840","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.836008,40.872984],[-98.847673,40.666381],[-98.721532,40.719249],[-98.684549,40.916766],[-98.721831,40.945745],[-98.836008,40.872984]]]},"properties":{"id":"68876","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.25533,40.699646],[-99.260284,40.705593],[-99.274253,40.706751],[-99.25533,40.699646]]]},"properties":{"id":"68861","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.059704,41.410986],[-97.061609,41.365285],[-97.023228,41.33599],[-96.985225,41.328418],[-96.935842,41.341316],[-96.908528,41.321791],[-96.870497,41.37972],[-96.859707,41.448745],[-96.90591,41.456436],[-97.059704,41.410986]]]},"properties":{"id":"68036","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.985299,41.227297],[-97.100537,41.118814],[-96.90886,41.133268],[-96.908734,41.162434],[-96.908473,41.220402],[-96.985299,41.227297]]]},"properties":{"id":"68626","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.463807,40.754956],[-96.369009,40.783924],[-96.3888,40.82742],[-96.463714,40.870979],[-96.463714,40.870951],[-96.482716,40.812957],[-96.463739,40.798461],[-96.463807,40.754956]]]},"properties":{"id":"68347","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.350017,40.957036],[-96.521344,41.045248],[-96.563469,41.045342],[-96.46372,40.899983],[-96.351157,40.914066],[-96.350017,40.957036]]]},"properties":{"id":"68366","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.400549,42.844597],[-97.44591,42.685155],[-97.308733,42.757193],[-97.396639,42.84457],[-97.400549,42.844597]]]},"properties":{"id":"68736","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.113489,42.583237],[-97.015358,42.591074],[-97.015422,42.597558],[-97.054369,42.683848],[-97.231935,42.761129],[-97.308733,42.757193],[-97.44591,42.685155],[-97.4856,42.627175],[-97.485502,42.598116],[-97.406511,42.525933],[-97.308841,42.496606],[-97.113489,42.583237]]]},"properties":{"id":"68739","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.051444,40.393027],[-102.051779,40.182619],[-101.977012,40.177112],[-101.986601,40.121199],[-102.051826,40.112066],[-101.865489,40.003023],[-101.777796,40.349689],[-102.051444,40.393027]]]},"properties":{"id":"69030","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.244657,42.865256],[-100.397919,42.303131],[-100.183167,42.433497],[-100.198478,42.846102],[-100.244657,42.865256]]]},"properties":{"id":"69221","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.753026,41.439316],[-102.983458,41.481941],[-103.273318,41.438095],[-103.146814,41.351944],[-102.763793,41.337339],[-102.753026,41.439316]]]},"properties":{"id":"69131","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.146619,41.279207],[-102.763694,41.26518],[-102.763793,41.337339],[-103.146814,41.351944],[-103.146619,41.279207]]]},"properties":{"id":"69141","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.995227,40.561064],[-98.109053,40.582635],[-98.108303,40.466655],[-98.014062,40.466602],[-97.919378,40.503145],[-97.957243,40.560942],[-97.995227,40.561064]]]},"properties":{"id":"68933","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.86702,41.437688],[-100.010289,41.451897],[-100.160555,41.248832],[-100.034541,41.046929],[-99.964027,41.047076],[-99.720336,41.219715],[-99.86702,41.437688]]]},"properties":{"id":"68825","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.443034,41.49627],[-99.501319,41.14741],[-99.204049,41.306136],[-99.204085,41.321929],[-99.324424,41.386685],[-99.405178,41.46704],[-99.443034,41.49627]]]},"properties":{"id":"68814","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.770513,42.474593],[-96.765298,42.394607],[-96.686934,42.35107],[-96.687382,42.380026],[-96.722231,42.524652],[-96.770513,42.474593]]]},"properties":{"id":"68785","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.485103,42.276923],[-96.356395,42.276494],[-96.408532,42.379078],[-96.526449,42.37941],[-96.485103,42.276923]]]},"properties":{"id":"68030","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.843274,41.046634],[-99.901377,40.742885],[-99.779071,40.687443],[-99.643349,40.682917],[-99.58656,40.678055],[-99.636799,40.872559],[-99.652575,40.919068],[-99.815285,41.046674],[-99.843274,41.046634]]]},"properties":{"id":"68850","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.95679,40.743202],[-99.981495,40.496431],[-99.981223,40.446323],[-99.811698,40.453308],[-99.779744,40.467492],[-99.779071,40.687443],[-99.901377,40.742885],[-99.95679,40.743202]]]},"properties":{"id":"68937","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.998291,42.35127],[-96.881641,42.351261],[-96.939922,42.412486],[-97.01796,42.394774],[-97.017789,42.351347],[-96.998291,42.35127]]]},"properties":{"id":"68728","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.986704,42.691706],[-96.984184,42.687746],[-96.976087,42.68768],[-96.975957,42.691367],[-96.986704,42.691706]]]},"properties":{"id":"68751","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.577075,41.434049],[-96.563355,41.379636],[-96.52189,41.321596],[-96.444968,41.32085],[-96.407258,41.32505],[-96.328935,41.399901],[-96.434785,41.495195],[-96.541079,41.561415],[-96.616306,41.524454],[-96.577075,41.434049]]]},"properties":{"id":"68025","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.980581,41.317123],[-95.980341,41.273642],[-95.948567,41.274709],[-95.946923,41.317589],[-95.98055,41.317592],[-95.980581,41.317123]]]},"properties":{"id":"68111","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.947038,41.226202],[-95.910912,41.231546],[-95.917472,41.249826],[-95.947108,41.250616],[-95.947038,41.226202]]]},"properties":{"id":"68108","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.196692,41.320927],[-96.196548,41.277572],[-96.158055,41.277544],[-96.138808,41.277663],[-96.138867,41.321106],[-96.196692,41.320927]]]},"properties":{"id":"68116","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.081329,41.317677],[-96.023841,41.317329],[-96.018144,41.317275],[-96.023876,41.393154],[-96.078959,41.422211],[-96.081544,41.321165],[-96.081329,41.317677]]]},"properties":{"id":"68122","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.325268,40.263418],[-101.474754,40.277733],[-101.32518,40.124385],[-101.325268,40.263418]]]},"properties":{"id":"69037","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.780725,40.437378],[-100.75883,40.002302],[-100.532916,40.001919],[-100.476963,40.089725],[-100.543775,40.386472],[-100.625859,40.526482],[-100.782383,40.511038],[-100.780725,40.437378]]]},"properties":{"id":"69001","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.489551,40.828844],[-100.387283,40.538976],[-100.326026,40.526376],[-100.337123,40.74475],[-100.489964,40.883103],[-100.489551,40.828844]]]},"properties":{"id":"69039","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.959513,40.175917],[-99.818624,40.205002],[-99.811698,40.453308],[-99.981223,40.446323],[-99.981193,40.438291],[-99.959513,40.175917]]]},"properties":{"id":"68922","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.966826,40.168763],[-100.023181,40.169609],[-99.96708,40.059763],[-99.966826,40.168763]]]},"properties":{"id":"68946","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.576767,40.066412],[-96.580277,40.046039],[-96.558097,40.052938],[-96.576767,40.066412]]]},"properties":{"id":"68309","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.160163,40.524212],[-97.12239,40.422263],[-97.046395,40.436718],[-96.893931,40.450978],[-96.89339,40.523543],[-96.912247,40.552667],[-97.075684,40.538379],[-97.160163,40.524212]]]},"properties":{"id":"68465","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.826241,41.017395],[-97.902692,41.003021],[-97.940544,40.937713],[-97.883003,40.771543],[-97.825995,40.85821],[-97.826194,40.988415],[-97.826241,41.017395]]]},"properties":{"id":"68843","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.847079,41.118705],[-97.826241,41.017395],[-97.826194,40.988415],[-97.712081,41.046625],[-97.6557,41.061236],[-97.771025,41.133625],[-97.847079,41.118705]]]},"properties":{"id":"68654","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.904989,41.10721],[-97.847079,41.118705],[-97.771025,41.133625],[-97.74276,41.162785],[-97.752346,41.220823],[-97.848627,41.307801],[-97.964074,41.293716],[-97.904989,41.10721]]]},"properties":{"id":"68628","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.985061,40.378334],[-100.780725,40.437378],[-100.782383,40.511038],[-100.873031,40.700254],[-100.905537,40.700221],[-101.045579,40.699145],[-101.231812,40.697845],[-101.269343,40.610734],[-101.174188,40.495151],[-100.985061,40.378334]]]},"properties":{"id":"69032","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.231812,40.697845],[-101.249198,40.975308],[-101.249975,41.00395],[-101.478646,41.003216],[-101.47661,40.698263],[-101.269343,40.610734],[-101.231812,40.697845]]]},"properties":{"id":"69134","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.827885,42.349754],[-98.885925,42.088324],[-98.761155,42.088518],[-98.690563,42.175713],[-98.827885,42.349754]]]},"properties":{"id":"68725","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.193049,41.147652],[-98.078755,41.249679],[-98.059634,41.278579],[-98.157729,41.393758],[-98.286584,41.393898],[-98.315727,41.336187],[-98.344489,41.133597],[-98.193049,41.147652]]]},"properties":{"id":"68864","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.368721,40.236689],[-97.369121,40.190712],[-97.369112,40.074518],[-97.369199,40.001966],[-97.10515,40.001725],[-97.066707,40.102947],[-97.048476,40.09554],[-97.010895,40.160946],[-97.104929,40.277122],[-97.123748,40.291463],[-97.368721,40.236689]]]},"properties":{"id":"68352","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.067025,40.305371],[-96.033688,40.261681],[-96.028961,40.290532],[-95.952595,40.436211],[-96.06656,40.450659],[-96.116732,40.450689],[-96.067084,40.34913],[-96.067025,40.305371]]]},"properties":{"id":"68378","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.378358,42.997984],[-99.402774,42.77158],[-99.244591,42.434639],[-99.257039,42.804296],[-99.254455,42.998074],[-99.378358,42.997984]]]},"properties":{"id":"68759","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.305436,42.515788],[-98.108643,42.497192],[-98.094339,42.532501],[-97.917427,42.611734],[-97.953962,42.684271],[-98.305148,42.760384],[-98.305069,42.665806],[-98.305394,42.523863],[-98.305436,42.515788]]]},"properties":{"id":"68783","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.656114,40.815279],[-96.693859,40.813615],[-96.693919,40.802495],[-96.672872,40.796712],[-96.606101,40.798831],[-96.603397,40.813416],[-96.641825,40.813475],[-96.656114,40.815279]]]},"properties":{"id":"68510","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.720492,40.711981],[-96.682509,40.711876],[-96.683025,40.769568],[-96.718552,40.770183],[-96.720806,40.770184],[-96.720492,40.711981]]]},"properties":{"id":"68512","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.643066,40.8838],[-96.586419,40.885415],[-96.604171,40.986853],[-96.642993,40.972726],[-96.64365,40.958301],[-96.643441,40.883774],[-96.643066,40.8838]]]},"properties":{"id":"68517","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.463834,40.733212],[-96.549488,40.733227],[-96.568337,40.697209],[-96.57241,40.639942],[-96.520573,40.595676],[-96.51592,40.595651],[-96.51139,40.604823],[-96.425837,40.638946],[-96.463834,40.733212]]]},"properties":{"id":"68317","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.463739,40.798461],[-96.56789,40.769618],[-96.549488,40.733227],[-96.463834,40.733212],[-96.463807,40.754956],[-96.463739,40.798461]]]},"properties":{"id":"68461","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.446845,41.30624],[-100.580598,41.292278],[-100.639982,40.888831],[-100.564451,40.814425],[-100.489551,40.828844],[-100.489964,40.883103],[-100.351581,41.187556],[-100.446845,41.30624]]]},"properties":{"id":"69151","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.291223,42.0765],[-97.368228,42.134444],[-97.659711,42.090289],[-97.621994,42.037122],[-97.523154,41.916411],[-97.309795,41.935176],[-97.291223,42.0765]]]},"properties":{"id":"68701","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.93678,41.753598],[-102.983458,41.481941],[-102.753026,41.439316],[-102.70351,41.439067],[-102.732625,41.714741],[-102.810468,41.790639],[-102.93678,41.753598]]]},"properties":{"id":"69125","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.715708,41.598362],[-97.897663,41.466357],[-97.811147,41.379955],[-97.703765,41.394876],[-97.703881,41.420939],[-97.677201,41.598366],[-97.715708,41.598362]]]},"properties":{"id":"68640","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.63909,40.363764],[-95.735632,40.363793],[-95.766458,40.290859],[-95.709558,40.262018],[-95.564093,40.261891],[-95.552473,40.261904],[-95.63909,40.363764]]]},"properties":{"id":"68414","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.923899,40.523018],[-96.067012,40.523148],[-96.06656,40.450659],[-95.952595,40.436211],[-95.839612,40.479788],[-95.840153,40.523022],[-95.865534,40.522978],[-95.865209,40.517933],[-95.868395,40.516185],[-95.869377,40.522969],[-95.923899,40.523018]]]},"properties":{"id":"68320","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.033688,40.261681],[-96.066961,40.123543],[-96.048314,40.109298],[-95.90704,40.033272],[-95.784462,40.203656],[-95.860242,40.261417],[-96.028961,40.290532],[-96.033688,40.261681]]]},"properties":{"id":"68376","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.820738,40.201361],[-97.915691,40.292681],[-97.953433,40.292515],[-97.877506,40.176107],[-97.820787,40.176308],[-97.820738,40.201361]]]},"properties":{"id":"68964","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.991741,40.581189],[-95.923899,40.523018],[-95.869377,40.522969],[-95.865534,40.522978],[-95.840153,40.523022],[-95.709974,40.523798],[-95.83412,40.783783],[-95.949768,40.784077],[-96.002588,40.755133],[-96.006894,40.740636],[-95.991741,40.581189]]]},"properties":{"id":"68410","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.349762,40.522868],[-96.331256,40.609833],[-96.444665,40.624449],[-96.463632,40.523013],[-96.349762,40.522868]]]},"properties":{"id":"68344","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.369009,40.783924],[-96.463807,40.754956],[-96.463834,40.733212],[-96.425837,40.638946],[-96.444665,40.624449],[-96.331256,40.609833],[-96.324071,40.624059],[-96.321824,40.78351],[-96.369009,40.783924]]]},"properties":{"id":"68418","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.132147,40.204036],[-96.293783,40.160704],[-96.274801,40.031657],[-96.274766,40.000843],[-96.105127,40.000728],[-96.048314,40.109298],[-96.066961,40.123543],[-96.132147,40.204036]]]},"properties":{"id":"68420","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.310543,41.61267],[-97.329249,41.77216],[-97.397016,41.743155],[-97.407083,41.641919],[-97.310543,41.61267]]]},"properties":{"id":"68631","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.455283,40.000307],[-95.396549,40.124701],[-95.48256,40.192519],[-95.455283,40.000307]]]},"properties":{"id":"68431","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.273962,40.350096],[-97.123727,40.349784],[-97.122189,40.349779],[-97.12239,40.422263],[-97.160163,40.524212],[-97.225292,40.531769],[-97.254756,40.509706],[-97.274012,40.480774],[-97.273962,40.350096]]]},"properties":{"id":"68464","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.368402,40.553496],[-97.254756,40.509706],[-97.225292,40.531769],[-97.215996,40.698412],[-97.368432,40.72765],[-97.368402,40.553496]]]},"properties":{"id":"68359","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.119578,41.126811],[-96.100192,41.053248],[-96.081073,41.057125],[-96.02901,41.063986],[-96.012858,41.06081],[-96.023684,41.169352],[-96.023692,41.176195],[-96.100419,41.16168],[-96.119578,41.126811]]]},"properties":{"id":"68046","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.167726,41.126673],[-96.234502,41.004348],[-96.229589,40.999592],[-96.120799,41.037988],[-96.100192,41.053248],[-96.119578,41.126811],[-96.167726,41.126673]]]},"properties":{"id":"68059","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.023684,41.169352],[-96.012858,41.06081],[-95.996743,41.056999],[-95.986893,41.161691],[-96.005834,41.18324],[-96.023684,41.169352]]]},"properties":{"id":"68133","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.541711,41.191757],[-96.563274,41.089091],[-96.514478,41.07425],[-96.483434,41.179875],[-96.541711,41.191757]]]},"properties":{"id":"68033","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.714291,41.858658],[-103.679397,42.030406],[-103.777053,42.349804],[-103.914637,42.257018],[-103.907272,41.856912],[-103.868983,41.812924],[-103.714291,41.858658]]]},"properties":{"id":"69357","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.046058,40.6982],[-96.990876,40.697942],[-97.006023,40.843244],[-97.158782,40.792618],[-97.169934,40.727608],[-97.046058,40.6982]]]},"properties":{"id":"68405","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.082137,41.04638],[-97.006124,40.966442],[-96.948874,40.987855],[-96.948873,41.046194],[-97.082137,41.04638]]]},"properties":{"id":"68314","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.369112,40.074518],[-97.538829,40.060079],[-97.538649,40.002026],[-97.369199,40.001966],[-97.369112,40.074518]]]},"properties":{"id":"68375","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.821495,40.002047],[-97.708342,40.002125],[-97.708233,40.038199],[-97.82087,40.103596],[-97.820956,40.089132],[-97.821495,40.002047]]]},"properties":{"id":"68325","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.613359,40.292795],[-97.617766,40.249126],[-97.463425,40.205066],[-97.501209,40.2922],[-97.613359,40.292795]]]},"properties":{"id":"68315","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.386513,41.59635],[-96.434785,41.495195],[-96.328935,41.399901],[-96.269528,41.393139],[-96.252016,41.414901],[-96.280819,41.509281],[-96.300015,41.581866],[-96.386416,41.610814],[-96.386513,41.59635]]]},"properties":{"id":"68002","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.613546,40.161676],[-98.575553,40.00247],[-98.420261,40.002439],[-98.39652,40.176408],[-98.538023,40.219701],[-98.613546,40.161676]]]},"properties":{"id":"68970","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.477252,40.698566],[-98.496738,40.495464],[-98.353779,40.480852],[-98.278243,40.526093],[-98.147022,40.582774],[-98.203395,40.582416],[-98.260376,40.640342],[-98.335287,40.69836],[-98.477252,40.698566]]]},"properties":{"id":"68901","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.242672,42.262852],[-98.315176,42.088804],[-98.09505,42.148358],[-98.067849,42.262829],[-98.116038,42.27729],[-98.242672,42.262852]]]},"properties":{"id":"68726","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.456341,42.350113],[-98.515051,42.262863],[-98.690563,42.175713],[-98.761155,42.088518],[-98.760794,41.985975],[-98.373596,41.98736],[-98.315176,42.088804],[-98.242672,42.262852],[-98.339633,42.321002],[-98.456341,42.350113]]]},"properties":{"id":"68735","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.369865,41.655993],[-103.125362,41.850808],[-103.131637,42.003245],[-103.363337,42.00293],[-103.460385,41.770159],[-103.494158,41.69949],[-103.369865,41.655993]],[[-103.418405,41.74516],[-103.417635,41.748986],[-103.414599,41.748929],[-103.418405,41.74516]]]},"properties":{"id":"69334","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.614281,42.998446],[-98.801503,42.998293],[-98.803535,42.825868],[-98.794977,42.754544],[-98.56837,42.766247],[-98.545461,42.799443],[-98.614281,42.998446]]]},"properties":{"id":"68777","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.676824,42.592117],[-99.760873,42.349525],[-99.662679,42.173094],[-99.676824,42.592117]]]},"properties":{"id":"69217","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.425974,40.858575],[-99.493549,40.633064],[-99.454945,40.569075],[-99.322746,40.612681],[-99.179248,40.660541],[-99.25533,40.699646],[-99.274253,40.706751],[-99.312269,40.786612],[-99.406603,40.85882],[-99.425974,40.858575]]]},"properties":{"id":"68836","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.42508,41.683652],[-96.32872,41.697832],[-96.320957,41.901435],[-96.34733,41.901291],[-96.392946,41.886978],[-96.463463,41.74178],[-96.42508,41.683652]]]},"properties":{"id":"68019","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.574538,41.742053],[-96.503967,41.741882],[-96.482288,41.741838],[-96.463463,41.74178],[-96.392946,41.886978],[-96.5552,41.90138],[-96.574538,41.742053]]]},"properties":{"id":"68045","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.368086,41.13398],[-97.234363,41.134104],[-97.196024,41.148425],[-97.281405,41.293939],[-97.349559,41.307515],[-97.368086,41.13398]]]},"properties":{"id":"68658","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.234363,41.134104],[-97.368086,41.13398],[-97.368088,41.109015],[-97.234363,41.134104]]]},"properties":{"id":"68667","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.178172,40.914199],[-96.216367,40.870617],[-96.216852,40.823222],[-96.054459,40.837695],[-96.063826,40.929271],[-96.159034,40.928912],[-96.178172,40.914199]]]},"properties":{"id":"68463","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.949768,40.784077],[-95.83412,40.783783],[-95.848571,40.860016],[-95.872951,40.871346],[-95.959223,40.87135],[-95.949768,40.784077]]]},"properties":{"id":"68455","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.604171,40.986853],[-96.586419,40.885415],[-96.586562,40.870983],[-96.463714,40.870951],[-96.463714,40.870979],[-96.46372,40.899983],[-96.563469,41.045342],[-96.604171,40.986853]]]},"properties":{"id":"68462","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.277483,42.613694],[-101.402503,42.495284],[-101.400552,42.491372],[-101.163025,42.524118],[-100.830296,42.773543],[-100.925631,42.786945],[-101.043147,42.99796],[-101.140807,42.997893],[-101.256419,42.659856],[-101.277483,42.613694]]]},"properties":{"id":"69219","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.400552,42.491372],[-101.406466,41.7432],[-100.842459,41.740404],[-100.843719,41.85064],[-100.845146,41.972587],[-100.809277,42.321053],[-100.848624,42.426594],[-101.163025,42.524118],[-101.400552,42.491372]]]},"properties":{"id":"69152","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.51424,42.997054],[-101.256419,42.659856],[-101.140807,42.997893],[-101.51424,42.997054]]]},"properties":{"id":"69211","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.098397,42.89692],[-100.244657,42.865256],[-100.198478,42.846102],[-100.134432,42.821222],[-100.098397,42.89692]]]},"properties":{"id":"69220","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.0936,40.959131],[-98.187551,40.821942],[-98.07309,40.741837],[-97.976462,40.669627],[-97.938445,40.684017],[-97.825723,40.698555],[-97.883003,40.771543],[-97.940544,40.937713],[-98.0936,40.959131]]]},"properties":{"id":"68818","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.085872,40.307559],[-98.059229,40.350651],[-98.203636,40.364903],[-98.203576,40.350392],[-98.123857,40.293059],[-98.085872,40.307559]]]},"properties":{"id":"68934","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.221827,40.64043],[-98.260376,40.640342],[-98.203395,40.582416],[-98.221827,40.64043]]]},"properties":{"id":"68954","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.790196,41.74257],[-96.578811,41.742068],[-96.574538,41.742053],[-96.5552,41.90138],[-96.555159,41.927622],[-96.729176,41.98902],[-96.903387,41.873181],[-96.922866,41.844185],[-96.790196,41.74257]]]},"properties":{"id":"68788","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.823023,42.227228],[-96.901153,42.119429],[-96.777838,42.017082],[-96.570396,42.090016],[-96.55091,42.111317],[-96.589833,42.160775],[-96.795921,42.191811],[-96.823023,42.227228]]]},"properties":{"id":"68047","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.324424,41.386685],[-99.204085,41.321929],[-98.961999,41.394346],[-98.943214,41.437554],[-99.134754,41.553111],[-99.289674,41.466618],[-99.324424,41.386685]]]},"properties":{"id":"68815","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.550816,42.277731],[-96.686934,42.35107],[-96.765298,42.394607],[-96.842555,42.351186],[-96.823023,42.227228],[-96.795921,42.191811],[-96.628772,42.249565],[-96.550816,42.277731]]]},"properties":{"id":"68733","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.208127,41.221556],[-102.232276,41.002333],[-102.051717,41.002359],[-102.051761,41.00389],[-102.055351,41.290489],[-102.18896,41.221519],[-102.208127,41.221556]]]},"properties":{"id":"69122","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.828769,41.607522],[-96.674357,41.563622],[-96.578811,41.742068],[-96.790196,41.74257],[-96.828769,41.607522]],[[-96.789908,41.698994],[-96.792618,41.706428],[-96.783693,41.709964],[-96.780695,41.699851],[-96.789908,41.698994]]]},"properties":{"id":"68057","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.407258,41.32505],[-96.38313,41.300828],[-96.281858,41.292048],[-96.253408,41.378981],[-96.269528,41.393139],[-96.328935,41.399901],[-96.407258,41.32505]]]},"properties":{"id":"68064","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.023898,41.256025],[-95.984392,41.253357],[-95.982237,41.255616],[-95.980344,41.273174],[-96.023862,41.273261],[-96.023898,41.256025]]]},"properties":{"id":"68132","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.474754,40.277733],[-101.532109,40.350038],[-101.589214,40.349928],[-101.683009,40.349695],[-101.746106,40.002851],[-101.325514,40.002702],[-101.32518,40.124385],[-101.474754,40.277733]]]},"properties":{"id":"69021","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.254756,40.509706],[-97.368402,40.553496],[-97.463667,40.539011],[-97.482796,40.534557],[-97.463302,40.495762],[-97.406707,40.430172],[-97.274012,40.480774],[-97.254756,40.509706]]]},"properties":{"id":"68406","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.023181,40.169609],[-99.966826,40.168763],[-99.959513,40.175917],[-99.981193,40.438291],[-100.042326,40.176206],[-100.023181,40.169609]]]},"properties":{"id":"68948","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.647589,40.465346],[-96.520289,40.566847],[-96.667445,40.581398],[-96.707154,40.523077],[-96.647589,40.465346]]]},"properties":{"id":"68358","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.70351,41.439067],[-102.553107,41.3743],[-102.422704,41.742246],[-102.671927,41.765053],[-102.732625,41.714741],[-102.70351,41.439067]]]},"properties":{"id":"69148","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.779071,40.687443],[-99.779744,40.467492],[-99.736998,40.438269],[-99.643349,40.682917],[-99.779071,40.687443]]]},"properties":{"id":"68976","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.53036,40.62653],[-99.58656,40.678055],[-99.643349,40.682917],[-99.736998,40.438269],[-99.698647,40.351171],[-99.595031,40.351159],[-99.549403,40.437068],[-99.53036,40.62653]]]},"properties":{"id":"68927","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.74433,41.394178],[-98.540333,41.394085],[-98.559344,41.452411],[-98.540739,41.696838],[-98.546584,41.740499],[-98.752225,41.740372],[-98.751302,41.57974],[-98.751558,41.394084],[-98.74433,41.394178]]]},"properties":{"id":"68875","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.540739,41.696838],[-98.291219,41.58193],[-98.291263,41.596355],[-98.290999,41.712172],[-98.295633,41.900424],[-98.54727,41.827058],[-98.546584,41.740499],[-98.540739,41.696838]]]},"properties":{"id":"68665","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.540739,41.696838],[-98.559344,41.452411],[-98.291362,41.523913],[-98.291219,41.58193],[-98.540739,41.696838]]]},"properties":{"id":"68842","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.079779,42.859122],[-99.234073,42.431649],[-99.234366,42.261274],[-98.885771,42.393703],[-98.856496,42.422643],[-98.754327,42.537323],[-98.794977,42.754544],[-98.803535,42.825868],[-99.021172,42.884033],[-99.079779,42.859122]]]},"properties":{"id":"68713","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.657654,41.139515],[-98.613753,41.069989],[-98.49279,41.046492],[-98.469818,41.046454],[-98.459833,41.119802],[-98.604591,41.191523],[-98.657654,41.139515]]]},"properties":{"id":"68831","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.067012,40.523148],[-96.105151,40.566878],[-96.236468,40.537286],[-96.236461,40.52279],[-96.255435,40.450413],[-96.116732,40.450689],[-96.06656,40.450659],[-96.067012,40.523148]]]},"properties":{"id":"68329","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.066961,40.123543],[-96.033688,40.261681],[-96.067025,40.305371],[-96.117064,40.262357],[-96.132147,40.204036],[-96.066961,40.123543]]]},"properties":{"id":"68447","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.022947,42.998367],[-100.098397,42.89692],[-100.134432,42.821222],[-99.985938,42.780878],[-99.677684,42.730903],[-99.481276,42.733186],[-99.577332,42.997974],[-100.022947,42.998367]]]},"properties":{"id":"68778","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.779358,42.850953],[-98.309771,42.882605],[-98.305148,42.760384],[-97.953962,42.684271],[-97.837839,42.655498],[-97.779358,42.850953]]]},"properties":{"id":"68760","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.779358,42.850953],[-97.837839,42.655498],[-97.838003,42.555961],[-97.691127,42.510589],[-97.485502,42.598116],[-97.4856,42.627175],[-97.720683,42.847317],[-97.779358,42.850953]]]},"properties":{"id":"68718","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.407083,41.641919],[-97.397016,41.743155],[-97.639376,41.800742],[-97.638786,41.61298],[-97.581287,41.598524],[-97.407333,41.612983],[-97.407083,41.641919]]]},"properties":{"id":"68642","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.744037,40.423998],[-95.735632,40.363793],[-95.63909,40.363764],[-95.657502,40.44051],[-95.744037,40.423998]]]},"properties":{"id":"68321","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.657502,40.44051],[-95.709974,40.523798],[-95.840153,40.523022],[-95.839612,40.479788],[-95.744037,40.423998],[-95.657502,40.44051]]]},"properties":{"id":"68421","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.121126,40.697073],[-96.067402,40.595662],[-95.991741,40.581189],[-96.006894,40.740636],[-96.121126,40.697073]],[[-96.023438,40.595573],[-96.025598,40.595589],[-96.025744,40.599166],[-96.023172,40.599197],[-96.023438,40.595573]]]},"properties":{"id":"68346","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.388212,40.030059],[-96.463712,40.001023],[-96.274766,40.000843],[-96.274801,40.031657],[-96.388212,40.030059]]]},"properties":{"id":"66541","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.496624,40.698499],[-101.47661,40.698263],[-101.478646,41.003216],[-101.632482,41.00306],[-101.589698,40.69976],[-101.496624,40.698499]]]},"properties":{"id":"69150","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.677201,41.598366],[-97.703881,41.420939],[-97.522711,41.474949],[-97.581287,41.598524],[-97.638786,41.61298],[-97.677201,41.598366]]]},"properties":{"id":"68647","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.501687,41.389363],[-97.488268,41.384564],[-97.488358,41.3952],[-97.501687,41.389363]]]},"properties":{"id":"68634","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.343056,40.065226],[-100.363489,40.001833],[-100.193599,40.001663],[-100.193755,40.092452],[-100.315112,40.131226],[-100.343056,40.065226]]]},"properties":{"id":"69036","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.926351,41.135479],[-95.909008,41.099054],[-95.892417,41.107074],[-95.926351,41.135479]]]},"properties":{"id":"68113","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.454376,41.100243],[-96.437494,41.088638],[-96.43533,41.103163],[-96.454376,41.100243]]]},"properties":{"id":"68042","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.519556,42.003731],[-103.519116,41.78993],[-103.492632,41.778528],[-103.460385,41.770159],[-103.363337,42.00293],[-103.401639,42.00354],[-103.519556,42.003731]]]},"properties":{"id":"69356","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.418405,41.74516],[-103.414599,41.748929],[-103.417635,41.748986],[-103.418405,41.74516]]]},"properties":{"id":"69353","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.039662,42.183954],[-102.211226,42.008334],[-102.09853,42.00916],[-102.039662,42.183954]]]},"properties":{"id":"69335","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.203774,41.296497],[-98.977143,41.169243],[-98.861353,41.206874],[-98.820199,41.394513],[-98.961999,41.394346],[-99.204085,41.321929],[-99.204049,41.306136],[-99.203774,41.296497]]]},"properties":{"id":"68853","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.977143,41.169243],[-99.203774,41.296497],[-99.206801,41.097384],[-99.135659,41.046894],[-99.014202,41.104954],[-98.97716,41.133806],[-98.977143,41.169243]]]},"properties":{"id":"68852","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.369121,40.190712],[-97.368721,40.236689],[-97.368688,40.350392],[-97.440695,40.350462],[-97.501209,40.2922],[-97.463425,40.205066],[-97.425958,40.175229],[-97.40728,40.161593],[-97.369121,40.190712]]]},"properties":{"id":"68303","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.820787,40.176308],[-97.82087,40.103596],[-97.708233,40.038199],[-97.689529,40.0456],[-97.707925,40.190648],[-97.820738,40.201361],[-97.820787,40.176308]]]},"properties":{"id":"68340","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.689529,40.0456],[-97.708233,40.038199],[-97.708342,40.002125],[-97.538649,40.002026],[-97.538829,40.060079],[-97.689529,40.0456]]]},"properties":{"id":"68327","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.134895,41.639723],[-99.134948,41.625284],[-99.000933,41.683369],[-99.134895,41.639723]]]},"properties":{"id":"68837","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.463401,40.769526],[-97.521347,40.987973],[-97.69324,40.973917],[-97.711787,40.821543],[-97.692595,40.756557],[-97.463401,40.769526]]]},"properties":{"id":"68467","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.094339,42.532501],[-98.108643,42.497192],[-98.12486,42.437228],[-98.067812,42.413767],[-97.951136,42.349862],[-97.720192,42.437964],[-97.691127,42.510589],[-97.838003,42.555961],[-97.864256,42.579167],[-98.094339,42.532501]]]},"properties":{"id":"68729","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.801495,42.319061],[-101.573048,41.699683],[-101.406682,41.673403],[-101.406466,41.7432],[-101.400552,42.491372],[-101.402503,42.495284],[-101.829257,42.44697],[-101.801495,42.319061]]]},"properties":{"id":"69366","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.573048,41.699683],[-101.801495,42.319061],[-101.854424,41.742194],[-101.573048,41.699683]]]},"properties":{"id":"69350","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.492019,41.393851],[-103.382416,41.026211],[-103.146619,41.279207],[-103.146814,41.351944],[-103.273318,41.438095],[-103.368201,41.583849],[-103.520363,41.450038],[-103.492019,41.393851]]]},"properties":{"id":"69156","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.290999,41.712172],[-98.195263,41.646634],[-98.022976,41.575041],[-97.869294,41.69907],[-97.871733,41.843586],[-98.293768,41.914957],[-98.295602,41.914954],[-98.295633,41.900424],[-98.290999,41.712172]]]},"properties":{"id":"68620","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.291344,41.480727],[-98.022469,41.510569],[-98.022976,41.575041],[-98.195263,41.646634],[-98.291263,41.596355],[-98.291219,41.58193],[-98.291362,41.523913],[-98.291344,41.480727]]]},"properties":{"id":"68627","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.794876,41.901699],[-97.833303,41.901764],[-97.871733,41.843586],[-97.869294,41.69907],[-97.754719,41.656088],[-97.697535,41.858586],[-97.697536,41.873071],[-97.794876,41.901699]]]},"properties":{"id":"68758","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.96219,42.998288],[-99.254455,42.998074],[-99.257039,42.804296],[-99.079779,42.859122],[-99.021172,42.884033],[-98.96219,42.998288]]]},"properties":{"id":"68755","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.197764,40.916456],[-99.084581,40.873161],[-98.988273,40.873372],[-99.007818,41.002814],[-99.135659,41.046894],[-99.206801,41.097384],[-99.303657,41.046965],[-99.293039,41.032399],[-99.197764,40.916456]]]},"properties":{"id":"68866","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.32872,41.697832],[-96.42508,41.683652],[-96.386416,41.610814],[-96.300015,41.581866],[-96.154744,41.682994],[-96.32872,41.697832]]]},"properties":{"id":"68029","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.09472,41.724586],[-96.104887,41.699379],[-96.077712,41.700022],[-96.09472,41.724586]]]},"properties":{"id":"51557","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.082137,41.04638],[-96.948873,41.046194],[-96.90886,41.133268],[-97.100537,41.118814],[-97.138406,41.104618],[-97.119046,41.046445],[-97.082137,41.04638]]]},"properties":{"id":"68635","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.350017,40.957036],[-96.351157,40.914066],[-96.332093,40.885325],[-96.216367,40.870617],[-96.178172,40.914199],[-96.168654,40.928834],[-96.250919,40.97193],[-96.350017,40.957036]]]},"properties":{"id":"68407","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.172083,42.802925],[-97.231935,42.761129],[-97.054369,42.683848],[-97.015849,42.761338],[-97.172083,42.802925]]]},"properties":{"id":"68792","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.875823,40.625147],[-102.051499,40.639483],[-102.051444,40.393027],[-101.777796,40.349689],[-101.72176,40.349644],[-101.875823,40.625147]]]},"properties":{"id":"69023","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.474491,42.937283],[-102.26305,42.455394],[-102.203614,42.38389],[-102.038575,42.375904],[-101.829257,42.44697],[-101.402503,42.495284],[-101.277483,42.613694],[-101.862762,42.999374],[-102.470904,42.999731],[-102.474491,42.937283]]]},"properties":{"id":"69343","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.163025,42.524118],[-100.848624,42.426594],[-100.458588,42.32086],[-100.397919,42.303131],[-100.244657,42.865256],[-100.098397,42.89692],[-100.022947,42.998367],[-100.748953,42.998185],[-100.830296,42.773543],[-101.163025,42.524118]]]},"properties":{"id":"69201","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.203395,40.582416],[-98.147022,40.582774],[-98.109053,40.582635],[-97.995227,40.561064],[-97.976462,40.669627],[-98.07309,40.741837],[-98.184081,40.698353],[-98.221827,40.64043],[-98.203395,40.582416]]]},"properties":{"id":"68944","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.16056,40.8293],[-100.052783,40.872898],[-100.09091,40.887525],[-100.062256,40.902331],[-100.034541,41.046929],[-100.160555,41.248832],[-100.235485,41.221815],[-100.319729,40.879458],[-100.16056,40.8293]]]},"properties":{"id":"69138","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.020441,41.480878],[-100.252804,41.566751],[-100.2924,41.566856],[-100.446845,41.30624],[-100.351581,41.187556],[-100.235485,41.221815],[-100.160555,41.248832],[-100.010289,41.451897],[-100.020441,41.480878]]]},"properties":{"id":"69120","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.464623,41.081169],[-99.409167,41.046505],[-99.303657,41.046965],[-99.206801,41.097384],[-99.203774,41.296497],[-99.204049,41.306136],[-99.501319,41.14741],[-99.464623,41.081169]]]},"properties":{"id":"68855","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.405178,41.46704],[-99.324424,41.386685],[-99.289674,41.466618],[-99.366487,41.481398],[-99.405178,41.46704]]]},"properties":{"id":"68881","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.503967,41.741882],[-96.502257,41.712714],[-96.482839,41.727393],[-96.482288,41.741838],[-96.503967,41.741882]]]},"properties":{"id":"68063","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.505048,41.607271],[-96.504106,41.60939],[-96.506985,41.609365],[-96.506896,41.607297],[-96.505048,41.607271]]]},"properties":{"id":"68072","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.023863,41.277798],[-96.076132,41.277804],[-96.081325,41.277789],[-96.084221,41.25011],[-96.081444,41.248722],[-96.02393,41.248778],[-96.023898,41.256025],[-96.023862,41.273261],[-96.023863,41.277798]]]},"properties":{"id":"68114","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.157324,41.221426],[-96.234339,41.219302],[-96.234288,41.190349],[-96.215757,41.190504],[-96.158333,41.190762],[-96.15552,41.21928],[-96.157324,41.221426]]]},"properties":{"id":"68135","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.234339,41.219302],[-96.157324,41.221426],[-96.154766,41.248559],[-96.157857,41.248537],[-96.196287,41.248491],[-96.234339,41.219302]]]},"properties":{"id":"68130","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.981168,41.223296],[-96.023897,41.22144],[-96.009134,41.1907],[-95.981898,41.190776],[-95.975839,41.224725],[-95.981168,41.223296]]]},"properties":{"id":"68117","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.925445,41.167338],[-95.93346,41.190982],[-95.981898,41.190776],[-95.984145,41.161726],[-95.925445,41.167338]]]},"properties":{"id":"68147","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.051779,40.182619],[-102.051826,40.112066],[-101.986601,40.121199],[-101.977012,40.177112],[-102.051779,40.182619]]]},"properties":{"id":"80758","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.520416,40.423047],[-97.634018,40.415367],[-97.653334,40.379529],[-97.509748,40.379546],[-97.520416,40.423047]]]},"properties":{"id":"68444","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.933916,40.002188],[-98.878579,40.20517],[-99.009521,40.234423],[-99.017862,40.205112],[-99.063888,40.08303],[-99.066106,40.002344],[-98.933916,40.002188]]]},"properties":{"id":"68939","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.873031,40.700254],[-100.782383,40.511038],[-100.625859,40.526482],[-100.60983,40.700114],[-100.873031,40.700254]]]},"properties":{"id":"69038","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.387283,40.538976],[-100.489551,40.828844],[-100.564451,40.814425],[-100.60983,40.700114],[-100.625859,40.526482],[-100.543775,40.386472],[-100.477339,40.439516],[-100.387283,40.538976]]]},"properties":{"id":"69025","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.573379,40.169025],[-99.742468,40.104017],[-99.741159,40.001761],[-99.517851,40.001968],[-99.573379,40.169025]]]},"properties":{"id":"68977","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.539375,40.269556],[-96.539376,40.204173],[-96.53935,40.189653],[-96.463616,40.189699],[-96.463667,40.262173],[-96.463695,40.276664],[-96.539375,40.269556]]]},"properties":{"id":"68458","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.534335,42.356682],[-102.671927,41.765053],[-102.422704,41.742246],[-102.294237,41.70158],[-102.205778,41.818894],[-102.09853,42.00916],[-102.211226,42.008334],[-102.203614,42.38389],[-102.26305,42.455394],[-102.498869,42.470967],[-102.534335,42.356682]]]},"properties":{"id":"69351","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.212192,41.668709],[-99.134895,41.639723],[-99.000933,41.683369],[-98.759133,41.740382],[-98.760622,41.913801],[-98.760794,41.985975],[-98.761155,42.088518],[-98.885925,42.088324],[-99.234623,42.202283],[-99.462158,42.08665],[-99.379711,41.741507],[-99.212192,41.668709]]]},"properties":{"id":"68823","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.825995,40.85821],[-97.883003,40.771543],[-97.825723,40.698555],[-97.767193,40.698582],[-97.711595,40.698623],[-97.692595,40.756557],[-97.711787,40.821543],[-97.825995,40.85821]]]},"properties":{"id":"68371","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.273816,40.307107],[-99.292609,40.306638],[-99.368376,40.299925],[-99.414065,40.00192],[-99.309801,40.001812],[-99.179257,40.219957],[-99.273816,40.307107]]]},"properties":{"id":"68920","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.595031,40.351159],[-99.482809,40.350866],[-99.477696,40.364933],[-99.454239,40.365155],[-99.454414,40.350682],[-99.45275,40.292885],[-99.368376,40.299925],[-99.292609,40.306638],[-99.29244,40.314398],[-99.198416,40.423225],[-99.322746,40.612681],[-99.454945,40.569075],[-99.549403,40.437068],[-99.595031,40.351159]]]},"properties":{"id":"68949","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.916289,40.26272],[-97.104929,40.277122],[-97.010895,40.160946],[-96.916383,40.175643],[-96.916289,40.26272]]]},"properties":{"id":"68377","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.123727,40.349784],[-97.273962,40.350096],[-97.368688,40.350392],[-97.368721,40.236689],[-97.123748,40.291463],[-97.123727,40.349784]]]},"properties":{"id":"68338","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.362482,40.348983],[-96.46379,40.349043],[-96.463695,40.276664],[-96.463667,40.262173],[-96.407334,40.262431],[-96.388423,40.262478],[-96.350557,40.262542],[-96.362482,40.348983]]]},"properties":{"id":"68332","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.492019,41.393851],[-103.544295,41.001492],[-103.382492,41.002252],[-103.382416,41.026211],[-103.492019,41.393851]]]},"properties":{"id":"69133","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.603397,40.813416],[-96.606101,40.798831],[-96.602358,40.76973],[-96.56789,40.769618],[-96.463739,40.798461],[-96.482716,40.812957],[-96.586887,40.82784],[-96.603397,40.813416]]]},"properties":{"id":"68520","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.700603,40.995537],[-96.719863,41.031325],[-96.738672,41.002213],[-96.700603,40.995537]]],[[[-96.795917,40.91545],[-96.73914,40.915289],[-96.700808,40.915254],[-96.681649,40.915068],[-96.767624,41.00225],[-96.929734,40.980026],[-96.910621,40.965395],[-96.795917,40.91545]]]]},"properties":{"id":"68428","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.910753,40.790486],[-96.811068,40.769576],[-96.796333,40.799193],[-96.910757,40.799368],[-96.910753,40.790486]]]},"properties":{"id":"68532","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.686805,40.624779],[-96.667445,40.581398],[-96.520289,40.566847],[-96.520573,40.595676],[-96.57241,40.639942],[-96.686805,40.624779]]]},"properties":{"id":"68372","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.046058,40.6982],[-97.075684,40.538379],[-96.912247,40.552667],[-96.790899,40.624718],[-96.838123,40.683133],[-96.932121,40.697992],[-96.990876,40.697942],[-97.046058,40.6982]]]},"properties":{"id":"68333","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.64365,40.958301],[-96.650145,40.883547],[-96.643441,40.883774],[-96.64365,40.958301]]]},"properties":{"id":"68514","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.51139,40.604823],[-96.51592,40.595651],[-96.50637,40.5956],[-96.51139,40.604823]]]},"properties":{"id":"68419","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.842459,41.740404],[-100.811734,41.393781],[-100.580598,41.292278],[-100.446845,41.30624],[-100.2924,41.566856],[-100.513829,41.739882],[-100.843719,41.85064],[-100.842459,41.740404]]]},"properties":{"id":"69163","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.420261,40.002439],[-98.141917,40.002493],[-98.256097,40.133059],[-98.292571,40.227154],[-98.39652,40.176408],[-98.420261,40.002439]]]},"properties":{"id":"68942","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.581287,41.598524],[-97.522711,41.474949],[-97.407333,41.612983],[-97.581287,41.598524]]]},"properties":{"id":"68653","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.520779,41.009395],[-97.368115,40.974394],[-97.272811,40.974461],[-97.253513,41.046668],[-97.368128,41.07597],[-97.464912,41.119493],[-97.502259,41.046798],[-97.520779,41.009395]]]},"properties":{"id":"68367","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.075684,40.538379],[-97.046058,40.6982],[-97.169934,40.727608],[-97.215996,40.698412],[-97.225292,40.531769],[-97.160163,40.524212],[-97.075684,40.538379]]]},"properties":{"id":"68343","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.009261,41.190702],[-96.081396,41.190756],[-96.110031,41.190664],[-96.100419,41.16168],[-96.023692,41.176195],[-96.005834,41.18324],[-96.009261,41.190702]]]},"properties":{"id":"68128","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.926351,41.135479],[-95.892417,41.107074],[-95.909008,41.099054],[-95.863131,41.093733],[-95.923548,41.19085],[-95.93346,41.190982],[-95.925445,41.167338],[-95.926351,41.135479]]]},"properties":{"id":"68005","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.870497,41.37972],[-96.775373,41.34635],[-96.736746,41.352408],[-96.695406,41.426193],[-96.859707,41.448745],[-96.870497,41.37972]]]},"properties":{"id":"68648","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.775373,41.34635],[-96.870497,41.37972],[-96.908528,41.321791],[-96.908628,41.234887],[-96.813454,41.260471],[-96.775373,41.34635]]]},"properties":{"id":"68050","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.679397,42.030406],[-103.714291,41.858658],[-103.519116,41.78993],[-103.519556,42.003731],[-103.679397,42.030406]]]},"properties":{"id":"69361","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.05265,42.249962],[-104.052725,41.971999],[-103.907272,41.856912],[-103.914637,42.257018],[-104.05265,42.249962]]]},"properties":{"id":"69358","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.158324,41.046499],[-97.272787,40.959967],[-97.253887,40.843722],[-97.158782,40.792618],[-97.006023,40.843244],[-96.967862,40.901105],[-97.006124,40.966442],[-97.082137,41.04638],[-97.119046,41.046445],[-97.158324,41.046499]],[[-97.218399,40.836055],[-97.216133,40.829371],[-97.221626,40.829322],[-97.218399,40.836055]]]},"properties":{"id":"68434","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.310356,42.250061],[-97.367993,42.2357],[-97.368228,42.134444],[-97.291223,42.0765],[-97.15444,42.090659],[-97.290863,42.221149],[-97.310356,42.250061]]]},"properties":{"id":"68740","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.373596,41.98736],[-98.760794,41.985975],[-98.760622,41.913801],[-98.54727,41.827058],[-98.295633,41.900424],[-98.295602,41.914954],[-98.373596,41.98736]]]},"properties":{"id":"68622","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.825995,40.85821],[-97.711787,40.821543],[-97.69324,40.973917],[-97.712081,41.046625],[-97.826194,40.988415],[-97.825995,40.85821]]]},"properties":{"id":"68319","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.505361,40.364998],[-98.344369,40.415462],[-98.353779,40.480852],[-98.496738,40.495464],[-98.52464,40.39383],[-98.505361,40.364998]]]},"properties":{"id":"68925","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.344369,40.415462],[-98.505361,40.364998],[-98.538023,40.219701],[-98.39652,40.176408],[-98.292571,40.227154],[-98.27809,40.350347],[-98.344369,40.415462]]]},"properties":{"id":"68930","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.720192,42.437964],[-97.951136,42.349862],[-97.93218,42.270725],[-97.834945,42.205707],[-97.834858,42.162251],[-97.77646,42.177075],[-97.757167,42.227776],[-97.698902,42.43806],[-97.720192,42.437964]]]},"properties":{"id":"68769","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.984491,41.467109],[-102.024832,41.290782],[-101.786963,41.227885],[-101.671949,41.216593],[-101.661081,41.395353],[-101.984491,41.467109]]]},"properties":{"id":"69146","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.519116,41.78993],[-103.714291,41.858658],[-103.868983,41.812924],[-103.846712,41.697988],[-103.494158,41.69949],[-103.460385,41.770159],[-103.492632,41.778528],[-103.515254,41.783508],[-103.519116,41.78993]]]},"properties":{"id":"69341","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.93804,41.920537],[-99.68647,41.907227],[-99.686959,42.086075],[-100.074954,42.085532],[-100.09205,42.024821],[-99.93804,41.920537]]]},"properties":{"id":"68821","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.293768,41.914957],[-97.871733,41.843586],[-97.833303,41.901764],[-97.968936,41.915746],[-98.293768,41.914957]]]},"properties":{"id":"68652","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.084784,40.626425],[-99.084646,40.851466],[-99.236083,40.786338],[-99.312269,40.786612],[-99.274253,40.706751],[-99.260284,40.705593],[-99.25533,40.699646],[-99.179248,40.660541],[-99.179383,40.612161],[-99.084784,40.626425]],[[-99.105525,40.699424],[-99.113273,40.699444],[-99.112759,40.701428],[-99.105405,40.702835],[-99.105525,40.699424]]]},"properties":{"id":"68845","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.014202,41.104954],[-99.007818,41.002814],[-98.988273,40.873372],[-98.969175,40.858872],[-98.836008,40.872984],[-98.721831,40.945745],[-98.721975,41.046674],[-98.748771,41.068874],[-98.97716,41.133806],[-99.014202,41.104954]]]},"properties":{"id":"68869","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.197764,40.916456],[-99.236083,40.786338],[-99.084646,40.851466],[-99.084581,40.873161],[-99.197764,40.916456]]]},"properties":{"id":"68870","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.234363,41.134104],[-97.368088,41.109015],[-97.368128,41.07597],[-97.253513,41.046668],[-97.158324,41.046499],[-97.119046,41.046445],[-97.138406,41.104618],[-97.196024,41.148425],[-97.234363,41.134104]]]},"properties":{"id":"68669","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.464415,41.308237],[-97.464651,41.134004],[-97.464912,41.119493],[-97.368128,41.07597],[-97.368088,41.109015],[-97.368086,41.13398],[-97.349559,41.307515],[-97.368273,41.337153],[-97.464415,41.308237]]]},"properties":{"id":"68662","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.3888,40.82742],[-96.369009,40.783924],[-96.321824,40.78351],[-96.236322,40.783734],[-96.216852,40.823222],[-96.216367,40.870617],[-96.332093,40.885325],[-96.3888,40.82742]]]},"properties":{"id":"68349","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.178172,40.914199],[-96.159034,40.928912],[-96.168654,40.928834],[-96.178172,40.914199]]]},"properties":{"id":"68403","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.159038,40.746864],[-96.04502,40.769644],[-96.054459,40.837695],[-96.216852,40.823222],[-96.236322,40.783734],[-96.236187,40.769308],[-96.159038,40.746864]]]},"properties":{"id":"68307","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.232508,42.438532],[-97.193293,42.43844],[-97.113489,42.583237],[-97.308841,42.496606],[-97.232508,42.438532]]]},"properties":{"id":"68727","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.532109,40.350038],[-101.474754,40.277733],[-101.325268,40.263418],[-101.325279,40.293077],[-101.174188,40.495151],[-101.269343,40.610734],[-101.47661,40.698263],[-101.496624,40.698499],[-101.485564,40.495438],[-101.532109,40.350038]]]},"properties":{"id":"69045","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.829257,42.44697],[-102.038575,42.375904],[-102.039662,42.183954],[-102.09853,42.00916],[-102.205778,41.818894],[-101.985523,41.74229],[-101.854424,41.742194],[-101.801495,42.319061],[-101.829257,42.44697]]]},"properties":{"id":"69333","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.806908,42.998659],[-100.925631,42.786945],[-100.830296,42.773543],[-100.748953,42.998185],[-100.806908,42.998659]]]},"properties":{"id":"69212","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.043147,42.99796],[-100.925631,42.786945],[-100.806908,42.998659],[-101.043147,42.99796]]]},"properties":{"id":"69216","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.382492,41.002252],[-102.792815,41.002317],[-102.763694,41.26518],[-103.146619,41.279207],[-103.382416,41.026211],[-103.382492,41.002252]]]},"properties":{"id":"69162","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.763694,41.26518],[-102.792815,41.002317],[-102.621033,41.002597],[-102.476486,41.221967],[-102.553107,41.3743],[-102.70351,41.439067],[-102.753026,41.439316],[-102.763793,41.337339],[-102.763694,41.26518]]]},"properties":{"id":"69149","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.852753,40.43756],[-97.919378,40.503145],[-98.014062,40.466602],[-98.059229,40.350651],[-98.085872,40.307559],[-97.953433,40.292515],[-97.915691,40.292681],[-97.862211,40.350514],[-97.852753,40.43756]]]},"properties":{"id":"68935","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.108303,40.466655],[-98.165086,40.45774],[-98.203636,40.364903],[-98.059229,40.350651],[-98.014062,40.466602],[-98.108303,40.466655]]]},"properties":{"id":"68938","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.213604,41.815409],[-97.213964,41.569026],[-97.020626,41.626768],[-97.077902,41.85909],[-97.077728,41.902427],[-97.213604,41.815409]]]},"properties":{"id":"68629","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.729176,41.98902],[-96.555159,41.927622],[-96.554866,42.015875],[-96.570396,42.090016],[-96.777838,42.017082],[-96.76824,42.003489],[-96.729176,41.98902]]]},"properties":{"id":"68004","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.720336,41.219715],[-99.964027,41.047076],[-99.843274,41.046634],[-99.815285,41.046674],[-99.606702,41.15541],[-99.720336,41.219715]]]},"properties":{"id":"68860","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.55959,41.127752],[-99.652575,40.919068],[-99.636799,40.872559],[-99.425749,40.873089],[-99.409167,41.046505],[-99.464623,41.081169],[-99.55959,41.127752]]]},"properties":{"id":"68878","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.550816,42.277731],[-96.485103,42.276923],[-96.526449,42.37941],[-96.551053,42.423275],[-96.687382,42.380026],[-96.686934,42.35107],[-96.550816,42.277731]]]},"properties":{"id":"68741","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.631492,42.524318],[-96.780947,42.663547],[-96.937243,42.539481],[-96.93715,42.525064],[-96.770513,42.474593],[-96.722231,42.524652],[-96.631492,42.524318]]]},"properties":{"id":"68770","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.50335,42.801556],[-103.60129,42.436434],[-103.067287,42.568143],[-103.067265,42.579648],[-103.196212,42.640764],[-103.50335,42.801556]]]},"properties":{"id":"69339","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.672366,42.870484],[-102.851551,42.568982],[-102.871631,42.43892],[-102.534335,42.356682],[-102.498869,42.470967],[-102.672366,42.870484]]]},"properties":{"id":"69347","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.823023,42.227228],[-96.842555,42.351186],[-96.881641,42.351261],[-96.998291,42.35127],[-96.932053,42.127318],[-96.901153,42.119429],[-96.823023,42.227228]]]},"properties":{"id":"68784","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.789908,41.698994],[-96.780695,41.699851],[-96.783693,41.709964],[-96.792618,41.706428],[-96.789908,41.698994]]]},"properties":{"id":"68664","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.946923,41.317589],[-95.948567,41.274709],[-95.947028,41.27199],[-95.917872,41.274085],[-95.927206,41.322267],[-95.946923,41.317589]]]},"properties":{"id":"68110","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.119575,41.248635],[-96.084221,41.25011],[-96.081325,41.277789],[-96.138808,41.277663],[-96.158055,41.277544],[-96.157857,41.248537],[-96.154766,41.248559],[-96.148236,41.248569],[-96.146273,41.262708],[-96.119614,41.263124],[-96.119575,41.248635]]]},"properties":{"id":"68154","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.94682,41.26374],[-95.947024,41.267964],[-95.952208,41.267951],[-95.951309,41.26397],[-95.94682,41.26374]]]},"properties":{"id":"68178","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-96.005834,41.18324],[-96.023692,41.176195],[-96.023684,41.169352],[-96.005834,41.18324]]],[[[-96.005834,41.18324],[-95.986893,41.161691],[-95.984145,41.161726],[-95.981898,41.190776],[-96.009134,41.1907],[-96.009261,41.190702],[-96.005834,41.18324]]]]},"properties":{"id":"68157","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.767193,40.698582],[-97.766699,40.510045],[-97.730387,40.49549],[-97.671992,40.582278],[-97.710003,40.698628],[-97.711595,40.698623],[-97.767193,40.698582]]]},"properties":{"id":"68365","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.824246,40.43758],[-97.824114,40.350541],[-97.726819,40.350545],[-97.653382,40.350563],[-97.653334,40.379529],[-97.634018,40.415367],[-97.730387,40.49549],[-97.766699,40.510045],[-97.824246,40.43758]]]},"properties":{"id":"68436","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.613359,40.292795],[-97.501209,40.2922],[-97.440695,40.350462],[-97.509748,40.379546],[-97.653334,40.379529],[-97.653382,40.350563],[-97.613359,40.292795]]]},"properties":{"id":"68322","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.042326,40.176206],[-100.19383,40.102541],[-100.193755,40.092452],[-100.193599,40.001663],[-99.948531,40.001818],[-99.96708,40.059763],[-100.023181,40.169609],[-100.042326,40.176206]]]},"properties":{"id":"69046","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.727644,40.00146],[-96.557944,40.001113],[-96.539211,40.146759],[-96.784687,40.117617],[-96.727644,40.00146]],[[-96.576767,40.066412],[-96.558097,40.052938],[-96.580277,40.046039],[-96.576767,40.066412]]]},"properties":{"id":"68466","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.493551,40.800542],[-98.492563,40.698604],[-98.477252,40.698566],[-98.335287,40.69836],[-98.282827,40.712788],[-98.2256,40.820939],[-98.282569,40.872296],[-98.378564,40.829328],[-98.444354,40.811812],[-98.493551,40.800542]]]},"properties":{"id":"68832","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.684549,40.916766],[-98.465174,40.902303],[-98.460976,40.916914],[-98.49279,41.046492],[-98.613753,41.069989],[-98.721975,41.046674],[-98.721831,40.945745],[-98.684549,40.916766]]]},"properties":{"id":"68824","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.077382,41.018399],[-98.097111,41.008176],[-98.0936,40.959131],[-97.940544,40.937713],[-97.902692,41.003021],[-97.96376,41.087278],[-98.077382,41.018399]]]},"properties":{"id":"68854","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.573379,40.169025],[-99.517851,40.001968],[-99.414065,40.00192],[-99.368376,40.299925],[-99.45275,40.292885],[-99.573379,40.169025]]]},"properties":{"id":"68966","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.75883,40.002302],[-100.780725,40.437378],[-100.985061,40.378334],[-101.003003,40.249286],[-100.871047,40.002157],[-100.75883,40.002302]]]},"properties":{"id":"69024","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.231812,40.697845],[-101.045579,40.699145],[-101.000908,40.872881],[-101.000964,40.89531],[-101.01952,40.960582],[-101.249198,40.975308],[-101.231812,40.697845]]]},"properties":{"id":"69169","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.32518,40.124385],[-101.325514,40.002702],[-101.116341,40.002451],[-101.172881,40.205856],[-101.325279,40.293077],[-101.325268,40.263418],[-101.32518,40.124385]]]},"properties":{"id":"69043","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.003003,40.249286],[-101.172881,40.205856],[-101.116341,40.002451],[-100.871047,40.002157],[-101.003003,40.249286]]]},"properties":{"id":"69044","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.257039,42.804296],[-99.244591,42.434639],[-99.234073,42.431649],[-99.079779,42.859122],[-99.257039,42.804296]]]},"properties":{"id":"68780","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.604591,41.191523],[-98.459833,41.119802],[-98.344489,41.133597],[-98.315727,41.336187],[-98.514906,41.394118],[-98.612246,41.249282],[-98.604591,41.191523]]]},"properties":{"id":"68873","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.751558,41.394084],[-98.820199,41.394513],[-98.861353,41.206874],[-98.7485,41.177318],[-98.710171,41.191652],[-98.689817,41.26455],[-98.74433,41.394178],[-98.751558,41.394084]]]},"properties":{"id":"68817","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.26893,41.075358],[-98.173776,41.133149],[-98.193049,41.147652],[-98.344489,41.133597],[-98.459833,41.119802],[-98.469818,41.046454],[-98.339964,41.046104],[-98.26893,41.075358]]]},"properties":{"id":"68872","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.46379,40.349043],[-96.362482,40.348983],[-96.255435,40.450413],[-96.236461,40.52279],[-96.349762,40.522868],[-96.463632,40.523013],[-96.46379,40.349043]]]},"properties":{"id":"68443","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.724032,40.524222],[-98.800403,40.625698],[-98.837867,40.611155],[-98.723955,40.509722],[-98.724032,40.524222]]]},"properties":{"id":"68945","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.786963,41.227885],[-101.804812,41.002886],[-101.632482,41.00306],[-101.478646,41.003216],[-101.596056,41.163594],[-101.671949,41.216593],[-101.786963,41.227885]]]},"properties":{"id":"69153","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.661081,41.395353],[-101.671949,41.216593],[-101.596056,41.163594],[-101.500449,41.395354],[-101.661081,41.395353]]]},"properties":{"id":"69144","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.577332,42.997974],[-99.481276,42.733186],[-99.402774,42.77158],[-99.378358,42.997984],[-99.577332,42.997974]]]},"properties":{"id":"68753","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.606154,40.769196],[-96.672871,40.769866],[-96.683025,40.769568],[-96.682509,40.711876],[-96.606282,40.697279],[-96.606154,40.769196]]]},"properties":{"id":"68516","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.672871,40.769866],[-96.606154,40.769196],[-96.602358,40.76973],[-96.606101,40.798831],[-96.672872,40.796712],[-96.672871,40.769866]]]},"properties":{"id":"68506","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.644134,40.835286],[-96.643066,40.8838],[-96.643441,40.883774],[-96.650145,40.883547],[-96.682128,40.88643],[-96.682223,40.836584],[-96.682222,40.836087],[-96.656114,40.815279],[-96.641825,40.813475],[-96.644134,40.835286]]]},"properties":{"id":"68504","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.790899,40.624718],[-96.741956,40.523104],[-96.707154,40.523077],[-96.667445,40.581398],[-96.686805,40.624779],[-96.74094,40.697493],[-96.790035,40.697624],[-96.838123,40.683133],[-96.790899,40.624718]],[[-96.741272,40.627504],[-96.741123,40.624794],[-96.748318,40.624857],[-96.748144,40.62849],[-96.741272,40.627504]]]},"properties":{"id":"68404","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.700808,40.915254],[-96.739212,40.877855],[-96.745845,40.83182],[-96.712142,40.8335],[-96.682223,40.836584],[-96.682128,40.88643],[-96.681649,40.915068],[-96.700808,40.915254]]]},"properties":{"id":"68521","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.9105,40.87574],[-96.795917,40.91545],[-96.910621,40.965395],[-96.910565,40.929891],[-96.9105,40.87574]]]},"properties":{"id":"68402","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.929734,40.980026],[-96.767624,41.00225],[-96.738672,41.002213],[-96.719863,41.031325],[-96.764359,41.132707],[-96.908734,41.162434],[-96.90886,41.133268],[-96.948873,41.046194],[-96.948874,40.987855],[-96.929734,40.980026]]]},"properties":{"id":"68065","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.379711,41.741507],[-99.462158,42.08665],[-99.662379,42.086009],[-99.686959,42.086075],[-99.68647,41.907227],[-99.5982,41.740477],[-99.379711,41.741507]]]},"properties":{"id":"68879","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.621994,42.037122],[-97.659711,42.090289],[-97.77646,42.177075],[-97.834858,42.162251],[-97.794876,41.901699],[-97.697536,41.873071],[-97.639581,41.87325],[-97.621994,42.037122]]]},"properties":{"id":"68752","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.972348,40.002124],[-97.821495,40.002047],[-97.820956,40.089132],[-97.934155,40.103649],[-97.972348,40.002124]]]},"properties":{"id":"68943","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.82087,40.103596],[-97.820787,40.176308],[-97.877506,40.176107],[-97.934055,40.125418],[-97.934155,40.103649],[-97.820956,40.089132],[-97.82087,40.103596]]]},"properties":{"id":"68974","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.18045,40.176496],[-98.256097,40.133059],[-98.141917,40.002493],[-97.972348,40.002124],[-97.934155,40.103649],[-97.934055,40.125418],[-98.18045,40.176496]]]},"properties":{"id":"68978","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.388212,40.030059],[-96.274801,40.031657],[-96.293783,40.160704],[-96.369401,40.233566],[-96.407334,40.262431],[-96.463667,40.262173],[-96.463616,40.189699],[-96.388212,40.030059]]]},"properties":{"id":"68323","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.804812,41.002886],[-101.956971,41.003289],[-101.876654,40.698524],[-101.589698,40.69976],[-101.632482,41.00306],[-101.804812,41.002886]]]},"properties":{"id":"69140","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.454414,40.350682],[-99.454239,40.365155],[-99.477696,40.364933],[-99.482809,40.350866],[-99.454414,40.350682]]]},"properties":{"id":"68923","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.365753,40.35146],[-100.315112,40.131226],[-100.193755,40.092452],[-100.19383,40.102541],[-100.365753,40.35146]]]},"properties":{"id":"69020","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.909502,40.000589],[-95.822119,40.0005],[-95.765822,40.11663],[-95.784444,40.189137],[-95.784462,40.203656],[-95.90704,40.033272],[-95.909502,40.000589]]]},"properties":{"id":"68337","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-95.784444,40.189137],[-95.765822,40.11663],[-95.671383,40.102065],[-95.648205,40.189457],[-95.72828,40.218285],[-95.784444,40.189137]]]},"properties":{"id":"68457","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.122189,40.349779],[-97.046283,40.349655],[-97.046395,40.436718],[-97.12239,40.422263],[-97.122189,40.349779]]]},"properties":{"id":"68445","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.908734,41.162434],[-96.764359,41.132707],[-96.7168,41.125737],[-96.69764,41.227125],[-96.813454,41.260471],[-96.908628,41.234887],[-96.908473,41.220402],[-96.908734,41.162434]]]},"properties":{"id":"68070","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.158324,41.046499],[-97.253513,41.046668],[-97.272811,40.974461],[-97.272787,40.959967],[-97.158324,41.046499]]]},"properties":{"id":"68439","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.291223,42.0765],[-97.309795,41.935176],[-97.329468,41.837528],[-97.213604,41.815409],[-97.077728,41.902427],[-97.136809,42.090654],[-97.15444,42.090659],[-97.291223,42.0765]]]},"properties":{"id":"68779","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.41643,42.190872],[-96.554179,42.191364],[-96.589833,42.160775],[-96.55091,42.111317],[-96.414371,42.104307],[-96.41643,42.190872]]]},"properties":{"id":"68067","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.54727,41.827058],[-98.760622,41.913801],[-98.759133,41.740382],[-98.752225,41.740372],[-98.546584,41.740499],[-98.54727,41.827058]]]},"properties":{"id":"68637","state_code":"31"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.990108,36.011805],[-114.918841,35.983522],[-114.901781,36.104029],[-115.019527,36.038282],[-115.015167,36.033431],[-114.990108,36.011805]]]},"properties":{"id":"89015","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.974202,35.942441],[-114.696858,35.830594],[-114.695386,35.830575],[-114.73729,36.016179],[-114.741129,36.065454],[-114.856999,36.121545],[-114.904899,36.122235],[-114.901781,36.104029],[-114.918841,35.983522],[-114.985492,35.983106],[-114.974202,35.942441]]]},"properties":{"id":"89005","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.31577,36.028484],[-115.315922,36.023474],[-115.241228,35.998768],[-115.237339,36.005778],[-115.243506,36.02243],[-115.279224,36.02029],[-115.31577,36.028484]]]},"properties":{"id":"89178","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.118925,36.099947],[-115.118269,36.055029],[-115.108929,36.053287],[-115.100314,36.055322],[-115.059344,36.085242],[-115.063931,36.100234],[-115.118925,36.099947]]]},"properties":{"id":"89120","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.011213,36.26575],[-115.057559,36.203539],[-115.019956,36.224948],[-115.011213,36.26575]]]},"properties":{"id":"89191","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.128026,36.144354],[-115.1629,36.100834],[-115.119029,36.118442],[-115.118691,36.140702],[-115.128026,36.144354]]]},"properties":{"id":"89169","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.31577,36.028484],[-115.351866,36.035175],[-115.3588,36.034935],[-115.422656,36.015028],[-115.401263,35.954459],[-115.315922,36.023474],[-115.31577,36.028484]]]},"properties":{"id":"89161","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.200678,35.955061],[-115.23148,35.881032],[-115.182147,35.955033],[-115.200678,35.955061]]]},"properties":{"id":"89054","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.813405,40.754371],[-115.456383,40.919024],[-115.373905,41.214744],[-115.930977,41.762157],[-116.146067,41.564762],[-116.092805,41.145053],[-115.956453,40.728793],[-116.005817,40.666673],[-115.813405,40.754371]]]},"properties":{"id":"89801","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.816203,41.886061],[-115.290641,41.195963],[-115.153215,40.821024],[-115.141891,40.720801],[-114.410258,40.975862],[-114.512518,41.539983],[-114.76394,41.751972],[-114.816203,41.886061]]]},"properties":{"id":"89835","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.430138,37.905113],[-117.904625,37.515836],[-117.991633,37.973604],[-118.430138,37.905113]]]},"properties":{"id":"89010","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.818953,38.633346],[-116.071811,38.398955],[-115.761922,38.208707],[-115.507916,38.581936],[-115.768388,38.63099],[-115.818953,38.633346]]]},"properties":{"id":"89049","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.517606,41.316066],[-117.640125,41.561124],[-117.705675,41.324515],[-117.517606,41.316066]]]},"properties":{"id":"89426","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.868185,41.193196],[-117.857365,41.210306],[-117.86772,41.236712],[-117.890503,41.236551],[-117.888652,41.207634],[-117.868185,41.193196]]]},"properties":{"id":"89446","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.24274,37.851406],[-115.360425,37.619756],[-115.104843,37.48986],[-115.24274,37.851406]]]},"properties":{"id":"89017","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.629266,39.274571],[-119.652953,39.26522],[-119.633629,39.24408],[-119.629266,39.274571]]]},"properties":{"id":"89428","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.939991,38.962953],[-118.314485,38.933103],[-118.241792,38.657833],[-118.04011,38.710445],[-117.939991,38.962953]]]},"properties":{"id":"89409","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.262894,40.581865],[-118.157874,40.328961],[-118.085047,40.523499],[-118.262894,40.581865]]]},"properties":{"id":"89419","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.77173,39.658349],[-119.750415,39.56551],[-119.733421,39.563212],[-119.647482,39.562865],[-119.605653,39.619027],[-119.7613,39.668837],[-119.77173,39.658349]]]},"properties":{"id":"89436","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.812067,39.537002],[-119.803216,39.530042],[-119.778087,39.5276],[-119.779487,39.56495],[-119.803859,39.573169],[-119.824875,39.569473],[-119.812067,39.537002]]]},"properties":{"id":"89512","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.812067,39.537002],[-119.824414,39.520535],[-119.808427,39.516514],[-119.803216,39.530042],[-119.812067,39.537002]]]},"properties":{"id":"89501","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.232237,39.092185],[-114.310288,38.91309],[-114.04812,38.900193],[-114.048943,39.057116],[-114.232237,39.092185]]]},"properties":{"id":"89311","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.786335,39.113895],[-119.766937,39.171703],[-119.804328,39.208426],[-119.882644,39.165513],[-119.786335,39.113895]]]},"properties":{"id":"89703","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.049991,36.799364],[-114.168812,36.843392],[-114.191398,36.764862],[-114.049991,36.799364]]]},"properties":{"id":"89027","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.114271,36.007214],[-115.118778,36.013339],[-115.180593,36.013393],[-115.181913,35.961835],[-115.155061,35.977779],[-115.114271,36.007214]]]},"properties":{"id":"89183","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.029505,36.031898],[-115.100314,36.055322],[-115.108929,36.053287],[-115.118778,36.013339],[-115.114271,36.007214],[-115.084868,36.02442],[-115.029505,36.031898]]]},"properties":{"id":"89074","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.323091,36.160745],[-115.314083,36.158946],[-115.243707,36.159127],[-115.243661,36.176697],[-115.287172,36.177663],[-115.323091,36.160745]]]},"properties":{"id":"89145","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.119029,36.118442],[-115.1629,36.100834],[-115.180482,36.100698],[-115.180606,36.057054],[-115.118269,36.055029],[-115.118925,36.099947],[-115.119029,36.118442]]]},"properties":{"id":"89119","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.3588,36.034935],[-115.426387,36.129249],[-115.449946,36.000509],[-115.422656,36.015028],[-115.3588,36.034935]]]},"properties":{"id":"89004","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.948153,35.255243],[-114.84929,35.17584],[-114.792523,35.175727],[-114.948153,35.255243]]]},"properties":{"id":"89039","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.243983,35.782904],[-115.103998,35.923188],[-115.155061,35.977779],[-115.181913,35.961835],[-115.182147,35.955033],[-115.23148,35.881032],[-115.288033,35.831802],[-115.284256,35.831446],[-115.243983,35.782904]]]},"properties":{"id":"89044","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.245236,36.125701],[-115.315028,36.122539],[-115.315313,36.096483],[-115.293316,36.100048],[-115.243063,36.099795],[-115.242971,36.125728],[-115.245236,36.125701]]]},"properties":{"id":"89147","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.278786,36.218634],[-115.287191,36.178012],[-115.287172,36.177663],[-115.243661,36.176697],[-115.244607,36.181264],[-115.246725,36.21821],[-115.278786,36.218634]]]},"properties":{"id":"89128","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.072272,36.290996],[-115.147039,36.308169],[-115.134838,36.276273],[-115.080113,36.27663],[-115.072272,36.290996]]]},"properties":{"id":"89086","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.207239,36.305966],[-115.188994,36.305681],[-115.189016,36.31322],[-115.207188,36.313437],[-115.207239,36.305966]]]},"properties":{"id":"89085","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.835411,38.971044],[-119.761026,38.937229],[-119.666381,38.988252],[-119.68608,39.084799],[-119.761213,39.103155],[-119.803918,39.057102],[-119.835411,38.971044]]]},"properties":{"id":"89423","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.761026,38.937229],[-119.726793,38.81104],[-119.500922,38.654322],[-119.562624,38.823588],[-119.666381,38.988252],[-119.761026,38.937229]]]},"properties":{"id":"89410","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.410258,40.975862],[-114.26669,40.901691],[-114.041843,41.116732],[-114.512518,41.539983],[-114.410258,40.975862]]]},"properties":{"id":"89830","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.418657,40.823736],[-115.224944,40.843425],[-115.153215,40.821024],[-115.290641,41.195963],[-115.373905,41.214744],[-115.456383,40.919024],[-115.418657,40.823736]]]},"properties":{"id":"89823","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.233181,41.835812],[-116.07799,41.926344],[-116.262885,41.956967],[-116.233181,41.835812]]]},"properties":{"id":"89832","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.053064,41.413602],[-117.705675,41.324515],[-117.640125,41.561124],[-117.771817,41.827785],[-118.123483,41.898118],[-118.053064,41.413602]]]},"properties":{"id":"89425","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.791583,39.998234],[-117.696265,39.385976],[-117.727453,39.263526],[-117.319379,38.947144],[-116.691778,39.161989],[-116.590242,39.707714],[-116.590438,39.953528],[-116.791583,39.998234]]]},"properties":{"id":"89310","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.309948,39.460428],[-119.481934,39.336116],[-119.401509,39.276786],[-119.118166,39.403031],[-119.118105,39.438145],[-119.226037,39.459959],[-119.309948,39.460428]]]},"properties":{"id":"89429","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.590314,38.317521],[-118.771867,38.141871],[-118.461322,37.920152],[-118.335345,38.238364],[-118.495152,38.330357],[-118.590314,38.317521]]]},"properties":{"id":"89422","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.753587,39.467443],[-119.792049,39.331729],[-119.677494,39.287275],[-119.619874,39.339664],[-119.696219,39.449143],[-119.753587,39.467443]]]},"properties":{"id":"89521","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.790656,39.476934],[-119.753587,39.467443],[-119.696219,39.449143],[-119.695946,39.462516],[-119.692093,39.522008],[-119.778087,39.5276],[-119.803216,39.530042],[-119.808427,39.516514],[-119.790656,39.476934]]]},"properties":{"id":"89502","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.981513,39.266122],[-114.930615,39.297834],[-114.964,39.317771],[-114.996904,39.295857],[-114.981513,39.266122]]]},"properties":{"id":"89319","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.098284,36.188129],[-115.098081,36.160653],[-115.062275,36.159033],[-115.000015,36.142809],[-115.06217,36.190672],[-115.098333,36.191884],[-115.098284,36.188129]]]},"properties":{"id":"89110","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.084868,36.02442],[-115.011637,35.998991],[-114.990108,36.011805],[-115.015167,36.033431],[-115.029505,36.031898],[-115.084868,36.02442]]]},"properties":{"id":"89012","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.192486,36.195626],[-115.212094,36.217137],[-115.246574,36.247878],[-115.246725,36.21821],[-115.244607,36.181264],[-115.179178,36.181084],[-115.192486,36.195626]]]},"properties":{"id":"89108","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.284256,35.831446],[-115.343949,35.70985],[-115.243983,35.782904],[-115.284256,35.831446]]]},"properties":{"id":"89026","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.337276,36.165044],[-115.382666,36.146495],[-115.426387,36.129249],[-115.3588,36.034935],[-115.351866,36.035175],[-115.315313,36.096483],[-115.315028,36.122539],[-115.314083,36.158946],[-115.323091,36.160745],[-115.337276,36.165044]]]},"properties":{"id":"89135","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.660459,36.577747],[-114.856999,36.121545],[-114.741129,36.065454],[-114.405751,36.585217],[-114.547657,36.642996],[-114.660459,36.577747]]]},"properties":{"id":"89040","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.315028,36.122539],[-115.245236,36.125701],[-115.243707,36.159127],[-115.314083,36.158946],[-115.315028,36.122539]]]},"properties":{"id":"89117","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.278447,36.336008],[-115.28577,36.298492],[-115.267452,36.277903],[-115.206612,36.276272],[-115.207239,36.305966],[-115.207188,36.313437],[-115.207019,36.328337],[-115.207139,36.334821],[-115.278447,36.336008]]]},"properties":{"id":"89131","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.134879,36.274485],[-115.206612,36.276272],[-115.207607,36.238765],[-115.134731,36.246944],[-115.134879,36.274485]]]},"properties":{"id":"89031","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.31577,36.028484],[-115.279224,36.02029],[-115.293316,36.100048],[-115.315313,36.096483],[-115.351866,36.035175],[-115.31577,36.028484]]]},"properties":{"id":"89148","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.158507,36.143693],[-115.180776,36.126207],[-115.180482,36.100698],[-115.1629,36.100834],[-115.128026,36.144354],[-115.158507,36.143693]]]},"properties":{"id":"89109","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.319082,40.393654],[-116.027035,40.080625],[-116.009051,40.156387],[-116.005817,40.666673],[-115.956453,40.728793],[-116.440139,40.662705],[-116.319082,40.393654]]]},"properties":{"id":"89822","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.233181,41.835812],[-116.275811,41.601122],[-116.146067,41.564762],[-115.930977,41.762157],[-116.233181,41.835812]]]},"properties":{"id":"89831","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.644312,40.734663],[-117.018419,41.000254],[-116.767324,41.000231],[-117.584454,41.043135],[-117.644312,40.734663]]]},"properties":{"id":"89414","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.832323,36.890292],[-116.619474,36.76508],[-116.553357,37.016441],[-116.698266,37.104375],[-116.832323,36.890292]]]},"properties":{"id":"89003","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.319379,38.947144],[-116.938688,38.691837],[-116.812592,38.63041],[-116.691778,39.161989],[-117.319379,38.947144]]]},"properties":{"id":"89045","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.312655,40.674776],[-119.691598,40.373175],[-119.340042,40.158906],[-119.3387,40.1576],[-119.312655,40.674776]]]},"properties":{"id":"89405","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.874208,39.548482],[-119.85897,39.511866],[-119.824414,39.520535],[-119.812067,39.537002],[-119.824875,39.569473],[-119.845104,39.559709],[-119.874208,39.548482]]]},"properties":{"id":"89503","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.340042,40.158906],[-119.473773,39.800667],[-119.25876,39.649213],[-119.189342,39.65087],[-119.3387,40.1576],[-119.340042,40.158906]]]},"properties":{"id":"89424","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-119.030552,39.567123],[-119.118104,39.438699],[-119.118105,39.438145],[-119.118166,39.403031],[-119.030552,39.567123]]],[[[-117.696265,39.385976],[-117.979887,39.71691],[-118.433466,39.561125],[-117.727453,39.263526],[-117.696265,39.385976]]]]},"properties":{"id":"89406","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.063931,36.100234],[-115.059344,36.085242],[-115.049642,36.083318],[-114.984366,36.088372],[-115.028859,36.136912],[-115.060713,36.137033],[-115.063931,36.100234]]]},"properties":{"id":"89122","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.649818,35.955304],[-115.343949,35.70985],[-115.284256,35.831446],[-115.288033,35.831802],[-115.401263,35.954459],[-115.422656,36.015028],[-115.449946,36.000509],[-115.649818,35.955304]]]},"properties":{"id":"89019","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.118269,36.055029],[-115.180606,36.057054],[-115.180593,36.013393],[-115.118778,36.013339],[-115.108929,36.053287],[-115.118269,36.055029]]]},"properties":{"id":"89123","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.15353,36.158846],[-115.158507,36.143693],[-115.128026,36.144354],[-115.118691,36.140702],[-115.065458,36.142332],[-115.062275,36.159033],[-115.098081,36.160653],[-115.15353,36.158846]]]},"properties":{"id":"89104","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.333249,36.24778],[-115.327362,36.218537],[-115.278786,36.218634],[-115.246725,36.21821],[-115.246574,36.247878],[-115.314888,36.247891],[-115.333249,36.24778]]]},"properties":{"id":"89129","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.080113,36.27663],[-115.134838,36.276273],[-115.134879,36.274485],[-115.134731,36.246944],[-115.134328,36.23962],[-115.101628,36.240191],[-115.080113,36.27663]]]},"properties":{"id":"89081","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.84929,35.17584],[-114.633487,35.001857],[-114.571802,35.199507],[-114.792523,35.175727],[-114.84929,35.17584]]]},"properties":{"id":"89029","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.241228,35.998768],[-115.315922,36.023474],[-115.401263,35.954459],[-115.288033,35.831802],[-115.23148,35.881032],[-115.200678,35.955061],[-115.241228,35.998768]]]},"properties":{"id":"89179","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.548201,36.666664],[-114.660072,36.843485],[-114.9561,36.853384],[-114.956115,36.853384],[-115.207139,36.334821],[-114.660459,36.577747],[-114.547657,36.642996],[-114.548201,36.666664]]]},"properties":{"id":"89025","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.89437,39.000551],[-119.889862,39.037967],[-119.950052,39.029203],[-119.95387,38.975598],[-119.89437,39.000551]]]},"properties":{"id":"89448","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.224944,40.843425],[-115.370139,40.598037],[-115.509936,40.314924],[-115.141891,40.720801],[-115.153215,40.821024],[-115.224944,40.843425]]]},"properties":{"id":"89833","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.018541,40.793578],[-117.018616,40.705451],[-116.791583,39.998234],[-116.594765,40.304787],[-116.51337,40.698022],[-116.767324,41.000231],[-117.018419,41.000254],[-117.018541,40.793578]]]},"properties":{"id":"89820","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.278084,38.841812],[-119.37005,38.751537],[-119.296008,38.700288],[-119.278084,38.841812]]]},"properties":{"id":"89430","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.301959,36.409636],[-116.189653,36.229846],[-116.108531,36.167138],[-115.895552,36.246183],[-115.895368,36.580168],[-116.301959,36.409636]]]},"properties":{"id":"89060","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.768388,38.63099],[-115.663175,38.848277],[-115.83838,38.967835],[-115.818953,38.633346],[-115.768388,38.63099]]]},"properties":{"id":"89314","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.77173,39.658349],[-119.803859,39.573169],[-119.779487,39.56495],[-119.750415,39.56551],[-119.77173,39.658349]]]},"properties":{"id":"89433","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.743166,39.724373],[-119.7613,39.668837],[-119.605653,39.619027],[-119.583816,39.641961],[-119.743166,39.724373]]]},"properties":{"id":"89441","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.807946,39.476511],[-119.85897,39.511866],[-119.899688,39.490623],[-119.807946,39.476511]]]},"properties":{"id":"89519","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.772988,39.40335],[-114.751191,39.482296],[-114.868296,39.459466],[-114.772988,39.40335]]]},"properties":{"id":"89318","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.158489,36.20297],[-115.192486,36.195626],[-115.179178,36.181084],[-115.17779,36.162564],[-115.154237,36.158851],[-115.141859,36.181372],[-115.158489,36.20297]]]},"properties":{"id":"89106","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.341633,36.191538],[-115.340689,36.181672],[-115.337276,36.165044],[-115.323091,36.160745],[-115.287172,36.177663],[-115.287191,36.178012],[-115.341633,36.191538]]]},"properties":{"id":"89144","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.205854,36.15986],[-115.243707,36.159127],[-115.245236,36.125701],[-115.242971,36.125728],[-115.208105,36.126265],[-115.205854,36.15986]]]},"properties":{"id":"89146","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.918841,35.983522],[-114.990108,36.011805],[-115.011637,35.998991],[-114.985492,35.983106],[-114.918841,35.983522]]]},"properties":{"id":"89002","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.000015,36.142809],[-115.062275,36.159033],[-115.065458,36.142332],[-115.060713,36.137033],[-115.028859,36.136912],[-115.000015,36.142809]]]},"properties":{"id":"89142","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.500922,38.654322],[-119.106506,38.440582],[-119.296008,38.700288],[-119.37005,38.751537],[-119.278084,38.841812],[-119.402212,38.964263],[-119.562624,38.823588],[-119.500922,38.654322]]]},"properties":{"id":"89444","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.869406,38.962695],[-119.89437,39.000551],[-119.95387,38.975598],[-119.900596,38.93076],[-119.869406,38.962695]]]},"properties":{"id":"89449","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.092805,41.145053],[-116.146067,41.564762],[-116.275811,41.601122],[-116.092805,41.145053]]]},"properties":{"id":"89834","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.76394,41.751972],[-114.686722,41.872418],[-114.816203,41.886061],[-114.76394,41.751972]]]},"properties":{"id":"89825","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.027035,40.080625],[-116.590438,39.953528],[-116.590242,39.707714],[-116.009051,40.156387],[-116.027035,40.080625]]]},"properties":{"id":"89316","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.123483,41.898118],[-117.771817,41.827785],[-117.711884,41.986806],[-118.12404,41.997367],[-118.123483,41.898118]]]},"properties":{"id":"89421","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.312655,40.674776],[-119.996284,40.245449],[-119.691598,40.373175],[-119.312655,40.674776]]]},"properties":{"id":"89412","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.31353,37.80155],[-114.210957,38.50865],[-114.945759,38.216781],[-114.518716,37.868564],[-114.31353,37.80155]]]},"properties":{"id":"89043","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.941386,37.294648],[-115.104843,37.48986],[-115.360425,37.619756],[-115.24274,37.851406],[-115.408991,37.795388],[-115.444017,37.787703],[-115.287954,36.853593],[-114.956115,36.853384],[-114.9561,36.853384],[-114.941386,37.294648]]]},"properties":{"id":"89001","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.278084,38.841812],[-119.296008,38.700288],[-119.106506,38.440582],[-119.300852,39.142559],[-119.395861,39.08517],[-119.402212,38.964263],[-119.278084,38.841812]]]},"properties":{"id":"89447","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.712645,39.250871],[-119.804328,39.208426],[-119.766937,39.171703],[-119.667195,39.203092],[-119.648497,39.195271],[-119.633629,39.24408],[-119.652953,39.26522],[-119.712645,39.250871]]]},"properties":{"id":"89706","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.495152,38.330357],[-118.688829,38.580394],[-118.939858,38.387591],[-118.649098,38.430434],[-118.590314,38.317521],[-118.495152,38.330357]]]},"properties":{"id":"89415","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.301959,36.409636],[-116.303088,36.624034],[-116.710248,36.627535],[-116.189653,36.229846],[-116.301959,36.409636]]]},"properties":{"id":"89020","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.895606,36.22679],[-115.962489,36.05396],[-115.845809,36.121389],[-115.895606,36.22679]]]},"properties":{"id":"89061","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.262894,40.581865],[-118.085047,40.523499],[-118.157874,40.328961],[-117.863091,40.550274],[-117.984897,40.814745],[-118.262894,40.581865]]]},"properties":{"id":"89418","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.629233,39.274597],[-119.619874,39.339664],[-119.677494,39.287275],[-119.712645,39.250871],[-119.652953,39.26522],[-119.629266,39.274571],[-119.629233,39.274597]]]},"properties":{"id":"89440","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.473773,39.800667],[-119.583816,39.641961],[-119.605653,39.619027],[-119.647482,39.562865],[-119.556103,39.553398],[-119.286004,39.618142],[-119.25876,39.649213],[-119.473773,39.800667]]]},"properties":{"id":"89442","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.791039,39.846442],[-119.998833,39.923559],[-119.941469,39.633401],[-119.791039,39.846442]]]},"properties":{"id":"89508","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.85897,39.511866],[-119.807946,39.476511],[-119.790656,39.476934],[-119.808427,39.516514],[-119.824414,39.520535],[-119.85897,39.511866]]]},"properties":{"id":"89509","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.141859,36.181372],[-115.154237,36.158851],[-115.15353,36.158846],[-115.098081,36.160653],[-115.098284,36.188129],[-115.141859,36.181372]]]},"properties":{"id":"89101","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.237339,36.005778],[-115.180593,36.013393],[-115.180606,36.057054],[-115.243048,36.055813],[-115.243506,36.02243],[-115.237339,36.005778]]]},"properties":{"id":"89139","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.179178,36.181084],[-115.244607,36.181264],[-115.243661,36.176697],[-115.243707,36.159127],[-115.205854,36.15986],[-115.17779,36.162564],[-115.179178,36.181084]]]},"properties":{"id":"89107","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.243048,36.055813],[-115.243063,36.099795],[-115.293316,36.100048],[-115.279224,36.02029],[-115.243506,36.02243],[-115.243048,36.055813]]]},"properties":{"id":"89113","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.314888,36.247891],[-115.293062,36.306517],[-115.320361,36.336393],[-115.423509,36.425947],[-115.548321,36.523608],[-115.626621,36.480862],[-115.333249,36.24778],[-115.314888,36.247891]]]},"properties":{"id":"89166","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.794237,36.56985],[-115.626621,36.480862],[-115.548321,36.523608],[-115.794237,36.56985]]]},"properties":{"id":"89018","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.761026,38.937229],[-119.835411,38.971044],[-119.869406,38.962695],[-119.900596,38.93076],[-119.726793,38.81104],[-119.761026,38.937229]]]},"properties":{"id":"89460","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.26669,40.901691],[-114.043588,40.726035],[-114.041843,41.116732],[-114.26669,40.901691]]]},"properties":{"id":"89883","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.164765,37.987856],[-117.191069,37.525757],[-117.799679,37.440192],[-117.166001,36.971111],[-117.164765,37.987856]]]},"properties":{"id":"89013","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.51337,40.698022],[-116.594765,40.304787],[-116.319082,40.393654],[-116.440139,40.662705],[-116.51337,40.698022]]]},"properties":{"id":"89821","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.31353,37.80155],[-114.518716,37.868564],[-114.50945,37.809918],[-114.32611,37.636089],[-114.31353,37.80155]]]},"properties":{"id":"89042","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.648497,39.195271],[-119.395861,39.08517],[-119.300852,39.142559],[-119.401509,39.276786],[-119.481934,39.336116],[-119.629233,39.274597],[-119.629266,39.274571],[-119.633629,39.24408],[-119.648497,39.195271]]]},"properties":{"id":"89403","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.24415,38.528444],[-118.173999,38.572018],[-118.277648,38.592829],[-118.24415,38.528444]]]},"properties":{"id":"89420","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.803859,39.573169],[-119.77173,39.658349],[-119.7613,39.668837],[-119.743166,39.724373],[-119.791039,39.846442],[-119.941469,39.633401],[-119.845104,39.559709],[-119.824875,39.569473],[-119.803859,39.573169]]]},"properties":{"id":"89506","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.807946,39.476511],[-119.899688,39.490623],[-119.972895,39.493043],[-120.003056,39.463185],[-120.006473,39.272878],[-119.919549,39.301384],[-119.792049,39.331729],[-119.753587,39.467443],[-119.790656,39.476934],[-119.807946,39.476511]]]},"properties":{"id":"89511","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.027469,40.122383],[-115.126347,39.430203],[-114.621152,39.227979],[-114.510651,40.032159],[-114.771604,40.120918],[-115.027469,40.122383]],[[-114.772988,39.40335],[-114.868296,39.459466],[-114.751191,39.482296],[-114.772988,39.40335]]]},"properties":{"id":"89301","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.786335,39.113895],[-119.761213,39.103155],[-119.68608,39.084799],[-119.667195,39.203092],[-119.766937,39.171703],[-119.786335,39.113895]],[[-119.74165,39.161288],[-119.727867,39.160934],[-119.734098,39.155776],[-119.74165,39.161288]]]},"properties":{"id":"89701","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.114271,36.007214],[-115.155061,35.977779],[-115.103998,35.923188],[-114.974202,35.942441],[-114.985492,35.983106],[-115.011637,35.998991],[-115.084868,36.02442],[-115.114271,36.007214]]]},"properties":{"id":"89052","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.180776,36.126207],[-115.158507,36.143693],[-115.15353,36.158846],[-115.154237,36.158851],[-115.17779,36.162564],[-115.205854,36.15986],[-115.208105,36.126265],[-115.180776,36.126207]]]},"properties":{"id":"89102","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-115.287954,36.853593],[-115.423509,36.425947],[-115.320361,36.336393],[-115.278447,36.336008],[-115.207139,36.334821],[-114.956115,36.853384],[-115.287954,36.853593]]],[[[-115.449946,36.000509],[-115.426387,36.129249],[-115.382666,36.146495],[-115.340689,36.181672],[-115.341633,36.191538],[-115.327362,36.218537],[-115.333249,36.24778],[-115.626621,36.480862],[-115.794237,36.56985],[-115.895368,36.580168],[-115.895552,36.246183],[-115.895606,36.22679],[-115.845809,36.121389],[-115.649818,35.955304],[-115.449946,36.000509]]]]},"properties":{"id":"89124","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.948153,35.255243],[-114.792523,35.175727],[-114.571802,35.199507],[-114.695386,35.830575],[-114.696858,35.830594],[-114.948153,35.255243]]]},"properties":{"id":"89046","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.340689,36.181672],[-115.382666,36.146495],[-115.337276,36.165044],[-115.340689,36.181672]]]},"properties":{"id":"89138","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.547657,36.642996],[-114.405751,36.585217],[-114.548201,36.666664],[-114.547657,36.642996]]]},"properties":{"id":"89021","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.101628,36.240191],[-115.098333,36.191884],[-115.06217,36.190672],[-115.057559,36.203539],[-115.011213,36.26575],[-115.072272,36.290996],[-115.080113,36.27663],[-115.101628,36.240191]]]},"properties":{"id":"89115","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.287191,36.178012],[-115.278786,36.218634],[-115.327362,36.218537],[-115.341633,36.191538],[-115.287191,36.178012]]]},"properties":{"id":"89134","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.207607,36.238765],[-115.206612,36.276272],[-115.267452,36.277903],[-115.246574,36.247878],[-115.212094,36.217137],[-115.207607,36.238765]]]},"properties":{"id":"89130","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.206612,36.276272],[-115.134879,36.274485],[-115.134838,36.276273],[-115.147039,36.308169],[-115.207019,36.328337],[-115.207188,36.313437],[-115.189016,36.31322],[-115.188994,36.305681],[-115.207239,36.305966],[-115.206612,36.276272]]]},"properties":{"id":"89084","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.456383,40.919024],[-115.813405,40.754371],[-115.509936,40.314924],[-115.370139,40.598037],[-115.418657,40.823736],[-115.456383,40.919024]]]},"properties":{"id":"89815","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.224944,40.843425],[-115.418657,40.823736],[-115.370139,40.598037],[-115.224944,40.843425]]]},"properties":{"id":"89828","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.018541,40.793578],[-117.129923,40.791044],[-117.018616,40.705451],[-117.018541,40.793578]]]},"properties":{"id":"89438","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.944005,41.907148],[-118.548547,41.76585],[-118.542049,41.994781],[-118.944005,41.907148]]]},"properties":{"id":"89404","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.891435,39.015279],[-118.758295,38.793789],[-118.530414,39.006668],[-118.891435,39.015279]]]},"properties":{"id":"89427","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-116.938688,38.691837],[-117.211386,38.487447],[-116.97946,38.448122],[-116.812592,38.63041],[-116.938688,38.691837]]]},"properties":{"id":"89022","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.647482,39.562865],[-119.733421,39.563212],[-119.692093,39.522008],[-119.695946,39.462516],[-119.556103,39.553398],[-119.647482,39.562865]]]},"properties":{"id":"89434","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.779487,39.56495],[-119.778087,39.5276],[-119.692093,39.522008],[-119.733421,39.563212],[-119.750415,39.56551],[-119.779487,39.56495]]]},"properties":{"id":"89431","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.964729,39.527006],[-120.003056,39.463185],[-119.972895,39.493043],[-119.964729,39.527006]]]},"properties":{"id":"89439","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.919549,39.301384],[-120.006473,39.272878],[-120.005668,39.22936],[-120.000289,39.232771],[-119.935207,39.165604],[-119.882644,39.165513],[-119.919549,39.301384]],[[-119.941637,39.240181],[-119.938204,39.242417],[-119.936762,39.240849],[-119.939379,39.238882],[-119.941637,39.240181]]]},"properties":{"id":"89451","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-120.005668,39.22936],[-120.00561,39.221754],[-120.000859,39.220992],[-120.000289,39.232771],[-120.005668,39.22936]]]},"properties":{"id":"89402","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.226037,39.459959],[-119.118104,39.438699],[-119.030552,39.567123],[-119.189342,39.65087],[-119.25876,39.649213],[-119.286004,39.618142],[-119.309948,39.460428],[-119.226037,39.459959]]]},"properties":{"id":"89408","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.06217,36.190672],[-115.000015,36.142809],[-115.028859,36.136912],[-114.984366,36.088372],[-114.97535,36.089831],[-115.019956,36.224948],[-115.057559,36.203539],[-115.06217,36.190672]]]},"properties":{"id":"89156","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.278447,36.336008],[-115.320361,36.336393],[-115.293062,36.306517],[-115.28577,36.298492],[-115.278447,36.336008]]]},"properties":{"id":"89143","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.293062,36.306517],[-115.314888,36.247891],[-115.246574,36.247878],[-115.267452,36.277903],[-115.28577,36.298492],[-115.293062,36.306517]]]},"properties":{"id":"89149","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.191398,36.764862],[-114.309479,36.66555],[-114.043135,36.380478],[-114.049991,36.799364],[-114.191398,36.764862]]]},"properties":{"id":"89007","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.241228,35.998768],[-115.200678,35.955061],[-115.182147,35.955033],[-115.181913,35.961835],[-115.180593,36.013393],[-115.237339,36.005778],[-115.241228,35.998768]]]},"properties":{"id":"89141","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.134328,36.23962],[-115.158489,36.20297],[-115.141859,36.181372],[-115.098284,36.188129],[-115.098333,36.191884],[-115.101628,36.240191],[-115.134328,36.23962]]]},"properties":{"id":"89030","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.119029,36.118442],[-115.118925,36.099947],[-115.063931,36.100234],[-115.060713,36.137033],[-115.065458,36.142332],[-115.118691,36.140702],[-115.119029,36.118442]]]},"properties":{"id":"89121","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.180482,36.100698],[-115.243063,36.099795],[-115.243048,36.055813],[-115.180606,36.057054],[-115.180482,36.100698]]]},"properties":{"id":"89118","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.134731,36.246944],[-115.207607,36.238765],[-115.212094,36.217137],[-115.192486,36.195626],[-115.158489,36.20297],[-115.134328,36.23962],[-115.134731,36.246944]]]},"properties":{"id":"89032","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.243063,36.099795],[-115.180482,36.100698],[-115.180776,36.126207],[-115.208105,36.126265],[-115.242971,36.125728],[-115.243063,36.099795]]]},"properties":{"id":"89103","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.984366,36.088372],[-115.049642,36.083318],[-115.019527,36.038282],[-114.901781,36.104029],[-114.904899,36.122235],[-114.97535,36.089831],[-114.984366,36.088372]]]},"properties":{"id":"89011","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.100314,36.055322],[-115.029505,36.031898],[-115.015167,36.033431],[-115.019527,36.038282],[-115.049642,36.083318],[-115.059344,36.085242],[-115.100314,36.055322]]]},"properties":{"id":"89014","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.786335,39.113895],[-119.882644,39.165513],[-119.935207,39.165604],[-119.957839,39.112706],[-119.887674,39.05057],[-119.803918,39.057102],[-119.761213,39.103155],[-119.786335,39.113895]]]},"properties":{"id":"89705","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.835411,38.971044],[-119.803918,39.057102],[-119.887674,39.05057],[-119.889862,39.037967],[-119.89437,39.000551],[-119.869406,38.962695],[-119.835411,38.971044]]]},"properties":{"id":"89411","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.887674,39.05057],[-119.957839,39.112706],[-119.950052,39.029203],[-119.889862,39.037967],[-119.887674,39.05057]]]},"properties":{"id":"89413","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.446286,41.878739],[-115.320611,41.797831],[-115.323234,41.962426],[-115.446286,41.878739]]]},"properties":{"id":"89826","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-117.64005,37.750497],[-117.629584,37.790453],[-117.651027,37.792642],[-117.666774,37.754884],[-117.64005,37.750497]]]},"properties":{"id":"89047","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-118.548547,41.76585],[-118.944005,41.907148],[-117.984897,40.814745],[-117.863091,40.550274],[-117.644312,40.734663],[-117.584454,41.043135],[-117.517606,41.316066],[-117.705675,41.324515],[-118.053064,41.413602],[-118.548547,41.76585]],[[-117.868185,41.193196],[-117.888652,41.207634],[-117.890503,41.236551],[-117.86772,41.236712],[-117.857365,41.210306],[-117.868185,41.193196]]]},"properties":{"id":"89445","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.32611,37.636089],[-114.50945,37.809918],[-114.941386,37.294648],[-114.9561,36.853384],[-114.660072,36.843485],[-114.32611,37.636089]]]},"properties":{"id":"89008","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.962489,36.05396],[-115.895606,36.22679],[-115.895552,36.246183],[-116.108531,36.167138],[-115.962489,36.05396]]]},"properties":{"id":"89048","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-115.113162,38.362871],[-115.201073,38.403422],[-115.25018,38.334542],[-115.113162,38.362871]]]},"properties":{"id":"89317","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.473773,39.800667],[-119.340042,40.158906],[-119.691598,40.373175],[-119.996284,40.245449],[-119.998833,39.923559],[-119.791039,39.846442],[-119.743166,39.724373],[-119.583816,39.641961],[-119.473773,39.800667]]]},"properties":{"id":"89510","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.712645,39.250871],[-119.677494,39.287275],[-119.792049,39.331729],[-119.919549,39.301384],[-119.882644,39.165513],[-119.804328,39.208426],[-119.712645,39.250871]]]},"properties":{"id":"89704","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.964729,39.527006],[-119.972895,39.493043],[-119.899688,39.490623],[-119.85897,39.511866],[-119.874208,39.548482],[-119.964729,39.527006]]]},"properties":{"id":"89523","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.941637,39.240181],[-119.939379,39.238882],[-119.936762,39.240849],[-119.938204,39.242417],[-119.941637,39.240181]]]},"properties":{"id":"89450","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-114.227724,39.990663],[-114.107087,39.938528],[-114.060215,39.993227],[-114.227724,39.990663]]]},"properties":{"id":"84034","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-119.74165,39.161288],[-119.734098,39.155776],[-119.727867,39.160934],[-119.74165,39.161288]]]},"properties":{"id":"89702","state_code":"32"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.62518,43.578324],[-71.524216,43.667872],[-71.582088,43.691931],[-71.648057,43.686154],[-71.654232,43.633715],[-71.72775,43.563524],[-71.706127,43.538182],[-71.62518,43.578324]]]},"properties":{"id":"03256","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.450857,43.617742],[-71.44909,43.505083],[-71.395009,43.481181],[-71.317037,43.509414],[-71.344184,43.629327],[-71.34442,43.62952],[-71.450857,43.617742]]]},"properties":{"id":"03249","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.250908,43.635005],[-71.284881,43.588365],[-71.198651,43.55891],[-71.163626,43.53937],[-71.127733,43.573012],[-71.059234,43.636388],[-71.185812,43.679297],[-71.250908,43.635005]]]},"properties":{"id":"03894","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.300896,43.661437],[-71.284881,43.588365],[-71.250908,43.635005],[-71.274697,43.680805],[-71.307093,43.678061],[-71.300896,43.661437]]]},"properties":{"id":"03853","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.159337,44.076756],[-71.156644,44.024977],[-71.102671,43.986378],[-71.084057,44.07524],[-71.099064,44.075336],[-71.117339,44.060565],[-71.131738,44.076207],[-71.159337,44.076756]]]},"properties":{"id":"03860","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.451256,42.72668],[-72.203644,42.719535],[-72.218461,42.790931],[-72.238285,42.81523],[-72.388987,42.848134],[-72.426963,42.850228],[-72.476947,42.853053],[-72.465461,42.796469],[-72.429243,42.792791],[-72.446981,42.762645],[-72.451256,42.72668]]]},"properties":{"id":"03470","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.446981,42.762645],[-72.429243,42.792791],[-72.465461,42.796469],[-72.446981,42.762645]]]},"properties":{"id":"03441","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.555228,42.858176],[-72.451256,42.72668],[-72.446981,42.762645],[-72.465461,42.796469],[-72.476947,42.853053],[-72.497498,42.854208],[-72.555228,42.858176]]]},"properties":{"id":"03451","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.248689,44.44859],[-71.260545,44.22797],[-71.09749,44.235393],[-71.009964,44.284783],[-71.018782,44.440424],[-71.248689,44.44859]]]},"properties":{"id":"03581","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-71.532146,44.978543],[-71.481781,44.994002],[-71.488471,45.000731],[-71.532146,44.978543]]],[[[-71.439568,44.787838],[-71.462894,44.645648],[-71.324212,44.661791],[-71.325182,44.705099],[-71.360463,44.795672],[-71.439568,44.787838]]]]},"properties":{"id":"03597","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.687756,43.934709],[-71.732143,43.930898],[-71.560558,43.875146],[-71.564615,43.89311],[-71.571176,43.898522],[-71.613635,43.996547],[-71.641294,44.023559],[-71.669134,43.969486],[-71.687756,43.934709]]]},"properties":{"id":"03285","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.817603,44.183677],[-71.755969,44.256317],[-71.79423,44.271248],[-71.817603,44.183677]]]},"properties":{"id":"03586","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.676868,43.747018],[-71.582088,43.691931],[-71.532369,43.765028],[-71.543649,43.796226],[-71.669248,43.782773],[-71.676868,43.747018]]]},"properties":{"id":"03245","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.433315,42.987482],[-71.45932,42.990591],[-71.471796,42.97414],[-71.475011,42.966284],[-71.45268,42.911069],[-71.453824,42.903408],[-71.44181,42.889586],[-71.413313,42.934997],[-71.433315,42.987482]]]},"properties":{"id":"03103","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.498812,42.804947],[-71.493347,42.764038],[-71.445109,42.766388],[-71.46381,42.797287],[-71.46831,42.800487],[-71.498812,42.804947]]]},"properties":{"id":"03064","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.90072,42.788332],[-71.821827,42.786451],[-71.810972,42.787569],[-71.828198,42.865497],[-71.852742,42.882385],[-71.881416,42.87853],[-71.90072,42.788332]]]},"properties":{"id":"03084","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.913255,43.207263],[-71.809658,43.124485],[-71.733116,43.139372],[-71.787672,43.231922],[-71.849862,43.219308],[-71.913255,43.207263]]]},"properties":{"id":"03242","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.754117,43.44934],[-71.742942,43.453048],[-71.757039,43.47199],[-71.766649,43.46131],[-71.754117,43.44934]]]},"properties":{"id":"03231","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.473412,43.035083],[-71.39581,43.019084],[-71.392246,43.040611],[-71.356182,43.101971],[-71.466346,43.123947],[-71.512721,43.076406],[-71.518521,43.068914],[-71.512713,43.048283],[-71.473412,43.035083]]]},"properties":{"id":"03106","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.524935,43.303065],[-71.480947,43.407897],[-71.485264,43.407433],[-71.646247,43.363694],[-71.588363,43.287373],[-71.524935,43.303065]]]},"properties":{"id":"03224","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.360002,43.268394],[-71.381363,43.31279],[-71.449649,43.245239],[-71.402878,43.200846],[-71.360002,43.268394]]]},"properties":{"id":"03258","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.242469,42.847998],[-71.28446,42.760108],[-71.245504,42.742589],[-71.166665,42.808338],[-71.206542,42.852365],[-71.242469,42.847998]]]},"properties":{"id":"03079","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.916896,42.898386],[-70.99751,42.958298],[-71.023474,42.894138],[-70.997454,42.864431],[-70.914503,42.886599],[-70.916896,42.898386]]]},"properties":{"id":"03827","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.887699,42.971088],[-70.783896,42.95229],[-70.770296,42.96949],[-70.81454,43.001714],[-70.868149,43.005224],[-70.887699,42.971088]]]},"properties":{"id":"03862","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.139905,43.18624],[-71.148438,43.176333],[-71.140128,43.17348],[-71.139905,43.18624]]]},"properties":{"id":"03291","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.163626,43.53937],[-71.160134,43.35586],[-71.088798,43.422432],[-71.111818,43.506935],[-71.127733,43.573012],[-71.163626,43.53937]]]},"properties":{"id":"03855","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.054708,43.288581],[-71.072708,43.299481],[-71.160134,43.35586],[-71.220538,43.299168],[-71.235828,43.284717],[-71.247215,43.274201],[-71.133525,43.203724],[-71.054708,43.288581]]]},"properties":{"id":"03884","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.171164,43.574021],[-72.214855,43.488942],[-72.113118,43.459516],[-72.103852,43.482834],[-72.094113,43.503071],[-72.171164,43.574021]]]},"properties":{"id":"03753","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.439621,43.696002],[-71.532369,43.765028],[-71.582088,43.691931],[-71.524216,43.667872],[-71.439621,43.696002]]]},"properties":{"id":"03226","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.190785,43.83676],[-71.175814,43.956475],[-71.223585,43.923401],[-71.194034,43.849082],[-71.190785,43.83676]]]},"properties":{"id":"03875","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.059234,43.636388],[-71.127733,43.573012],[-71.111818,43.506935],[-70.999322,43.507289],[-70.962163,43.541103],[-70.974089,43.592927],[-71.02017,43.672483],[-71.059234,43.636388]]]},"properties":{"id":"03872","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.084213,43.960015],[-70.993308,43.92911],[-71.009964,44.284783],[-71.09749,44.235393],[-71.097955,44.160176],[-71.084057,44.07524],[-71.102671,43.986378],[-71.084213,43.960015]]]},"properties":{"id":"03813","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.156644,44.024977],[-71.382619,44.022529],[-71.377766,43.997962],[-71.363682,43.924732],[-71.290506,43.915193],[-71.223585,43.923401],[-71.175814,43.956475],[-71.101951,43.924526],[-71.084213,43.960015],[-71.102671,43.986378],[-71.156644,44.024977]]]},"properties":{"id":"03818","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.131738,44.076207],[-71.117339,44.060565],[-71.099064,44.075336],[-71.131738,44.076207]]]},"properties":{"id":"03847","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.101951,43.924526],[-71.081213,43.855434],[-70.991746,43.877587],[-70.993308,43.92911],[-71.084213,43.960015],[-71.101951,43.924526]]]},"properties":{"id":"03832","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.097955,44.160176],[-71.09749,44.235393],[-71.260545,44.22797],[-71.302744,44.137197],[-71.171324,44.133133],[-71.097955,44.160176]]]},"properties":{"id":"03846","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.139813,42.846508],[-72.223492,42.873285],[-72.238285,42.81523],[-72.218461,42.790931],[-72.132631,42.810755],[-72.139813,42.846508]]]},"properties":{"id":"03465","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.203644,42.719535],[-72.078446,42.71624],[-72.091303,42.793294],[-72.132631,42.810755],[-72.218461,42.790931],[-72.203644,42.719535]]]},"properties":{"id":"03447","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.001993,42.919535],[-72.156478,42.923774],[-72.13761,42.868909],[-71.990393,42.865309],[-72.001993,42.919535]]]},"properties":{"id":"03444","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.030313,44.644337],[-71.325182,44.705099],[-71.324212,44.661791],[-71.332842,44.57025],[-71.356114,44.524713],[-71.02849,44.610344],[-71.030313,44.644337]]]},"properties":{"id":"03588","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.543183,44.44529],[-71.429752,44.460774],[-71.437701,44.519319],[-71.558891,44.564163],[-71.660046,44.441025],[-71.543183,44.44529]]]},"properties":{"id":"03584","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.168322,43.872289],[-72.205195,43.770955],[-72.08961,43.744665],[-72.0582,43.737365],[-72.026903,43.837949],[-72.168322,43.872289]]]},"properties":{"id":"03768","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.984617,44.336243],[-72.052606,44.218047],[-72.041671,44.212897],[-72.014937,44.240803],[-71.939279,44.304153],[-71.984617,44.336243]]]},"properties":{"id":"03771","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.44913,42.700376],[-71.493347,42.764038],[-71.527811,42.739488],[-71.543011,42.702689],[-71.44913,42.700376]]]},"properties":{"id":"03062","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.386607,42.698789],[-71.245504,42.742589],[-71.28446,42.760108],[-71.363624,42.783046],[-71.386607,42.698789]]]},"properties":{"id":"03076","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.810972,42.787569],[-71.715531,42.777276],[-71.729918,42.850885],[-71.828198,42.865497],[-71.810972,42.787569]]]},"properties":{"id":"03086","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.933243,42.983055],[-71.945423,42.939861],[-71.881416,42.87853],[-71.852742,42.882385],[-71.806229,42.950821],[-71.874135,42.98798],[-71.933243,42.983055]]]},"properties":{"id":"03047","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.856952,43.38906],[-71.862967,43.459827],[-71.960259,43.501629],[-71.991753,43.466245],[-71.932315,43.437969],[-71.920666,43.433357],[-71.933666,43.417238],[-71.907704,43.402581],[-71.884544,43.364443],[-71.856952,43.38906]]]},"properties":{"id":"03287","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.875486,43.080457],[-70.981056,43.082447],[-71.012242,43.082038],[-71.013305,43.052585],[-70.912665,43.052937],[-70.907674,43.059854],[-70.880185,43.077178],[-70.875486,43.080457]]]},"properties":{"id":"03857","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.242469,42.847998],[-71.331059,42.841143],[-71.360808,42.798988],[-71.363624,42.783046],[-71.28446,42.760108],[-71.242469,42.847998]]]},"properties":{"id":"03087","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.93405,43.021482],[-70.887699,42.971088],[-70.868149,43.005224],[-70.907674,43.059854],[-70.912665,43.052937],[-70.93405,43.021482]]]},"properties":{"id":"03885","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.156723,42.898287],[-71.146404,42.897687],[-71.149504,42.959886],[-71.179305,42.960486],[-71.219977,42.922305],[-71.156723,42.898287]]]},"properties":{"id":"03873","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.136469,42.860798],[-71.207847,42.859966],[-71.206542,42.852365],[-71.166665,42.808338],[-71.118802,42.818088],[-71.136469,42.860798]]]},"properties":{"id":"03811","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.914503,42.886599],[-70.81064,42.896619],[-70.823839,42.897857],[-70.918008,42.906655],[-70.916896,42.898386],[-70.914503,42.886599]]]},"properties":{"id":"03874","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.81454,43.001714],[-70.770296,42.96949],[-70.708568,43.048037],[-70.734996,43.059489],[-70.809798,43.019888],[-70.81454,43.001714]],[[-70.773167,42.988358],[-70.766882,42.984384],[-70.77367,42.976177],[-70.783883,42.981962],[-70.773167,42.988358]]]},"properties":{"id":"03870","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.901599,42.967288],[-70.823839,42.897857],[-70.81064,42.896619],[-70.783896,42.95229],[-70.887699,42.971088],[-70.901599,42.967288]]]},"properties":{"id":"03842","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.734996,43.059489],[-70.708568,43.048037],[-70.737969,43.073728],[-70.734996,43.059489]]]},"properties":{"id":"03854","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.819922,43.197027],[-70.886303,43.223431],[-70.927105,43.254982],[-70.949505,43.229482],[-70.952805,43.225782],[-70.970875,43.205276],[-70.864321,43.134088],[-70.847129,43.126249],[-70.820019,43.122545],[-70.819922,43.197027]]]},"properties":{"id":"03820","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.999322,43.507289],[-70.966467,43.435452],[-70.962163,43.541103],[-70.999322,43.507289]]]},"properties":{"id":"03852","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.264053,43.29256],[-72.269503,43.423786],[-72.39636,43.428868],[-72.406857,43.337403],[-72.264053,43.29256]]]},"properties":{"id":"03743","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.5106,43.501869],[-71.625673,43.471047],[-71.648509,43.384494],[-71.646247,43.363694],[-71.485264,43.407433],[-71.5106,43.501869]]]},"properties":{"id":"03276","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.467375,43.409391],[-71.361202,43.354126],[-71.346983,43.346573],[-71.33694,43.357645],[-71.309862,43.462132],[-71.317037,43.509414],[-71.395009,43.481181],[-71.467375,43.409391]]]},"properties":{"id":"03237","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.532369,43.765028],[-71.439621,43.696002],[-71.34442,43.62952],[-71.344184,43.629327],[-71.341131,43.62715],[-71.300896,43.661437],[-71.307093,43.678061],[-71.302298,43.714728],[-71.245465,43.770575],[-71.280052,43.775109],[-71.329895,43.782968],[-71.532369,43.765028]]]},"properties":{"id":"03254","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.361889,43.915549],[-71.350086,43.836592],[-71.261616,43.795425],[-71.220843,43.821521],[-71.20288,43.826979],[-71.19278,43.830109],[-71.189358,43.831128],[-71.190785,43.83676],[-71.194034,43.849082],[-71.290506,43.915193],[-71.363682,43.924732],[-71.361889,43.915549]]]},"properties":{"id":"03886","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.111818,43.506935],[-71.088798,43.422432],[-71.08608,43.424983],[-70.999322,43.507289],[-71.111818,43.506935]]]},"properties":{"id":"03887","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.081213,43.855434],[-71.183641,43.832837],[-71.167972,43.816618],[-71.081313,43.797519],[-70.989122,43.792049],[-70.991746,43.877587],[-71.081213,43.855434]]]},"properties":{"id":"03836","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.176069,43.023399],[-72.0581,43.018904],[-72.042112,43.082276],[-72.038149,43.128807],[-72.178457,43.131955],[-72.194038,43.076201],[-72.199559,43.053362],[-72.176069,43.023399]]]},"properties":{"id":"03464","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.167249,42.962561],[-72.061244,42.965842],[-72.058913,43.005118],[-72.0581,43.018904],[-72.176069,43.023399],[-72.178936,42.965481],[-72.177753,42.963038],[-72.167249,42.962561]]]},"properties":{"id":"03457","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.194038,43.076201],[-72.243178,43.070968],[-72.319551,43.064205],[-72.252289,43.012837],[-72.199559,43.053362],[-72.194038,43.076201]]]},"properties":{"id":"03448","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.018782,44.440424],[-71.02849,44.610344],[-71.356114,44.524713],[-71.305372,44.447369],[-71.248689,44.44859],[-71.018782,44.440424]]]},"properties":{"id":"03570","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.558891,44.564163],[-71.437701,44.519319],[-71.332842,44.57025],[-71.324212,44.661791],[-71.462894,44.645648],[-71.557838,44.642115],[-71.558891,44.564163]]]},"properties":{"id":"03582","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.429752,44.460774],[-71.543183,44.44529],[-71.524428,44.355441],[-71.458504,44.327251],[-71.384622,44.335318],[-71.385879,44.393744],[-71.429752,44.460774]]]},"properties":{"id":"03583","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.825926,43.885837],[-72.026903,43.837949],[-72.0582,43.737365],[-71.925837,43.704367],[-71.776427,43.770949],[-71.749421,43.802476],[-71.825926,43.885837]]]},"properties":{"id":"03266","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.696665,44.332326],[-71.79423,44.271248],[-71.755969,44.256317],[-71.537367,44.197882],[-71.422613,44.168807],[-71.412851,44.166367],[-71.415801,44.212082],[-71.507085,44.26181],[-71.587113,44.304786],[-71.696665,44.332326]]]},"properties":{"id":"03574","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.654232,43.633715],[-71.734413,43.686049],[-71.885104,43.695588],[-71.900163,43.606582],[-71.864053,43.599442],[-71.81794,43.544618],[-71.72775,43.563524],[-71.654232,43.633715]]]},"properties":{"id":"03222","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.098995,43.957511],[-72.168322,43.872289],[-72.026903,43.837949],[-71.975098,43.89503],[-72.098995,43.957511]]]},"properties":{"id":"03777","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.556213,42.792587],[-71.527811,42.739488],[-71.493347,42.764038],[-71.498812,42.804947],[-71.556213,42.792587]]]},"properties":{"id":"03063","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.46381,42.797287],[-71.427077,42.82489],[-71.44181,42.889586],[-71.453824,42.903408],[-71.46831,42.800487],[-71.46381,42.797287]]]},"properties":{"id":"03052","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.0581,43.018904],[-72.058913,43.005118],[-71.941691,43.019007],[-71.911404,43.051395],[-72.042112,43.082276],[-72.0581,43.018904]]]},"properties":{"id":"03440","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.765,43.342796],[-71.725768,43.244152],[-71.638527,43.216107],[-71.588363,43.287373],[-71.646247,43.363694],[-71.648509,43.384494],[-71.669222,43.376625],[-71.765,43.342796]]]},"properties":{"id":"03303","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.049829,43.380265],[-72.01431,43.383411],[-71.907704,43.402581],[-71.933666,43.417238],[-71.932315,43.437969],[-71.991753,43.466245],[-72.053802,43.441078],[-72.057641,43.424586],[-72.049829,43.380265]]]},"properties":{"id":"03257","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.932315,43.437969],[-71.933666,43.417238],[-71.920666,43.433357],[-71.932315,43.437969]]]},"properties":{"id":"03233","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.628438,43.160283],[-71.667826,43.152588],[-71.641252,43.054173],[-71.518521,43.068914],[-71.512721,43.076406],[-71.628438,43.160283]]]},"properties":{"id":"03046","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.820019,43.122545],[-70.847129,43.126249],[-70.875486,43.080457],[-70.880185,43.077178],[-70.809798,43.019888],[-70.734996,43.059489],[-70.737969,43.073728],[-70.820019,43.122545]]]},"properties":{"id":"03801","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.118802,42.818088],[-71.053601,42.833089],[-71.079002,42.857588],[-71.125603,42.877188],[-71.133003,42.866188],[-71.136469,42.860798],[-71.118802,42.818088]]]},"properties":{"id":"03865","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.230531,43.079981],[-71.261283,43.01602],[-71.167805,42.984586],[-71.150055,43.021132],[-71.124025,43.076931],[-71.213246,43.077091],[-71.230531,43.079981]]]},"properties":{"id":"03077","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.013305,43.052585],[-71.013884,43.03386],[-70.93405,43.021482],[-70.912665,43.052937],[-71.013305,43.052585]]]},"properties":{"id":"03856","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.280985,43.218538],[-71.317752,43.162556],[-71.335528,43.132097],[-71.230531,43.079981],[-71.213246,43.077091],[-71.156659,43.167019],[-71.280985,43.218538]]]},"properties":{"id":"03037","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.907674,43.059854],[-70.868149,43.005224],[-70.81454,43.001714],[-70.809798,43.019888],[-70.880185,43.077178],[-70.907674,43.059854]]]},"properties":{"id":"03840","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.99751,42.958298],[-71.088013,42.959296],[-71.092203,42.959187],[-71.103703,42.895888],[-71.125603,42.877188],[-71.079002,42.857588],[-71.023474,42.894138],[-70.99751,42.958298]]]},"properties":{"id":"03848","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.927105,43.254982],[-70.886303,43.223431],[-70.838879,43.244511],[-70.898664,43.287363],[-70.905122,43.279891],[-70.927105,43.254982]]]},"properties":{"id":"03878","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.159651,43.280907],[-72.236165,43.25526],[-72.219784,43.17479],[-72.156548,43.181962],[-72.123421,43.249397],[-72.159651,43.280907]]]},"properties":{"id":"03605","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.285497,43.512387],[-72.292817,43.533595],[-72.247108,43.556249],[-72.232696,43.542731],[-72.256002,43.520745],[-72.227309,43.49257],[-72.214855,43.488942],[-72.171164,43.574021],[-72.208145,43.585162],[-72.302671,43.596579],[-72.32962,43.600201],[-72.396397,43.519154],[-72.285497,43.512387]]]},"properties":{"id":"03781","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.247401,43.25416],[-72.318141,43.20336],[-72.262294,43.169545],[-72.219784,43.17479],[-72.236165,43.25526],[-72.247401,43.25416]]]},"properties":{"id":"03607","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.309862,43.462132],[-71.33694,43.357645],[-71.310398,43.387114],[-71.281331,43.418196],[-71.288497,43.445848],[-71.309862,43.462132]]]},"properties":{"id":"03837","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.50086,43.530726],[-71.44909,43.505083],[-71.450857,43.617742],[-71.510096,43.552861],[-71.50086,43.530726]]]},"properties":{"id":"03246","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.091303,42.793294],[-72.078446,42.71624],[-71.928811,42.712234],[-71.944792,42.788586],[-71.974312,42.789161],[-72.091303,42.793294]]]},"properties":{"id":"03461","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.252289,43.012837],[-72.178936,42.965481],[-72.176069,43.023399],[-72.199559,43.053362],[-72.252289,43.012837]]]},"properties":{"id":"03445","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.223492,42.873285],[-72.139813,42.846508],[-72.13761,42.868909],[-72.156478,42.923774],[-72.167249,42.962561],[-72.177753,42.963038],[-72.234068,42.90707],[-72.223492,42.873285]]]},"properties":{"id":"03455","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.37911,43.132481],[-72.3859,43.128605],[-72.369857,43.119414],[-72.37911,43.132481]]]},"properties":{"id":"03604","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.455088,42.921527],[-72.497498,42.854208],[-72.476947,42.853053],[-72.426963,42.850228],[-72.455088,42.921527]]]},"properties":{"id":"03443","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.944792,42.788586],[-71.90072,42.788332],[-71.881416,42.87853],[-71.945423,42.939861],[-72.003426,42.931104],[-72.001993,42.919535],[-71.990393,42.865309],[-71.974312,42.789161],[-71.944792,42.788586]]]},"properties":{"id":"03458","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.587113,44.304786],[-71.507085,44.26181],[-71.458504,44.327251],[-71.524428,44.355441],[-71.587113,44.304786]]]},"properties":{"id":"03595","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.696665,44.332326],[-71.587113,44.304786],[-71.524428,44.355441],[-71.543183,44.44529],[-71.660046,44.441025],[-71.764745,44.406635],[-71.696665,44.332326]]]},"properties":{"id":"03598","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.439568,44.787838],[-71.551568,44.853108],[-71.557838,44.642115],[-71.462894,44.645648],[-71.439568,44.787838]]]},"properties":{"id":"03590","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.560558,43.875146],[-71.732143,43.930898],[-71.801019,43.950942],[-71.825926,43.885837],[-71.749421,43.802476],[-71.669248,43.782773],[-71.543649,43.796226],[-71.560558,43.875146]]]},"properties":{"id":"03223","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.749421,43.802476],[-71.776427,43.770949],[-71.734413,43.686049],[-71.654232,43.633715],[-71.648057,43.686154],[-71.676868,43.747018],[-71.669248,43.782773],[-71.749421,43.802476]]]},"properties":{"id":"03264","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.821933,43.983624],[-71.801019,43.950942],[-71.732143,43.930898],[-71.687756,43.934709],[-71.680108,43.958374],[-71.669134,43.969486],[-71.641294,44.023559],[-71.772287,44.07144],[-71.791684,44.077287],[-71.821933,43.983624]]]},"properties":{"id":"03262","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.078713,44.029592],[-72.098995,43.957511],[-71.975098,43.89503],[-71.959209,43.916703],[-71.963253,43.998261],[-72.018493,44.011764],[-72.078713,44.029592]]]},"properties":{"id":"03779","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.669134,43.969486],[-71.680108,43.958374],[-71.687756,43.934709],[-71.669134,43.969486]]]},"properties":{"id":"03293","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.45932,42.990591],[-71.4696,43.003536],[-71.471796,42.97414],[-71.45932,42.990591]]]},"properties":{"id":"03101","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.493347,42.764038],[-71.44913,42.700376],[-71.432308,42.699989],[-71.445109,42.766388],[-71.493347,42.764038]]]},"properties":{"id":"03060","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.821827,42.786451],[-71.805419,42.708987],[-71.701216,42.706388],[-71.714017,42.769087],[-71.715531,42.777276],[-71.810972,42.787569],[-71.821827,42.786451]]]},"properties":{"id":"03048","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.933243,42.983055],[-71.874135,42.98798],[-71.88176,43.032055],[-71.911404,43.051395],[-71.941691,43.019007],[-71.933243,42.983055]]]},"properties":{"id":"03442","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.512721,43.076406],[-71.466346,43.123947],[-71.495154,43.158177],[-71.603042,43.165188],[-71.628438,43.160283],[-71.512721,43.076406]]]},"properties":{"id":"03304","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.730955,43.432065],[-71.669222,43.376625],[-71.648509,43.384494],[-71.625673,43.471047],[-71.690664,43.518337],[-71.733266,43.503289],[-71.730955,43.432065]]]},"properties":{"id":"03235","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.449649,43.245239],[-71.456501,43.249133],[-71.495154,43.158177],[-71.466346,43.123947],[-71.356182,43.101971],[-71.335528,43.132097],[-71.317752,43.162556],[-71.402878,43.200846],[-71.449649,43.245239]]]},"properties":{"id":"03275","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.99751,42.958298],[-70.916896,42.898386],[-70.918008,42.906655],[-70.901599,42.967288],[-70.887699,42.971088],[-70.93405,43.021482],[-71.013884,43.03386],[-71.085185,43.020906],[-71.088013,42.959296],[-70.99751,42.958298]]]},"properties":{"id":"03833","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.122355,43.196797],[-71.139905,43.18624],[-71.140128,43.17348],[-71.148438,43.176333],[-71.156659,43.167019],[-71.213246,43.077091],[-71.124025,43.076931],[-71.072984,43.081748],[-71.026688,43.138086],[-71.122355,43.196797]]]},"properties":{"id":"03290","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.351355,42.942649],[-71.331059,42.841143],[-71.242469,42.847998],[-71.206542,42.852365],[-71.207847,42.859966],[-71.219406,42.916656],[-71.311203,42.936469],[-71.351355,42.942649]]]},"properties":{"id":"03038","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.999322,43.507289],[-71.08608,43.424983],[-70.990809,43.37416],[-70.985672,43.379205],[-70.966467,43.435452],[-70.999322,43.507289]]]},"properties":{"id":"03851","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.026688,43.138086],[-71.072984,43.081748],[-71.012242,43.082038],[-70.981056,43.082447],[-70.96072,43.162884],[-70.998106,43.17245],[-71.026688,43.138086]]]},"properties":{"id":"03861","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.990975,43.373986],[-71.072708,43.299481],[-71.054708,43.288581],[-71.020464,43.268131],[-70.949505,43.229482],[-70.927105,43.254982],[-70.905122,43.279891],[-70.990975,43.373986]]]},"properties":{"id":"03867","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.072708,43.299481],[-70.990975,43.373986],[-70.990809,43.37416],[-71.08608,43.424983],[-71.088798,43.422432],[-71.160134,43.35586],[-71.072708,43.299481]]]},"properties":{"id":"03835","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.886303,43.223431],[-70.819922,43.197027],[-70.838879,43.244511],[-70.886303,43.223431]]]},"properties":{"id":"03869","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.264359,43.267882],[-72.345274,43.216728],[-72.318141,43.20336],[-72.247401,43.25416],[-72.264359,43.267882]]]},"properties":{"id":"03601","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.198651,43.55891],[-71.288497,43.445848],[-71.281331,43.418196],[-71.160134,43.35586],[-71.163626,43.53937],[-71.198651,43.55891]]]},"properties":{"id":"03809","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.365965,43.821893],[-71.458203,43.904098],[-71.564615,43.89311],[-71.560558,43.875146],[-71.543649,43.796226],[-71.532369,43.765028],[-71.329895,43.782968],[-71.365965,43.821893]]]},"properties":{"id":"03227","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.185812,43.679297],[-71.059234,43.636388],[-71.02017,43.672483],[-71.013089,43.679129],[-71.110344,43.736656],[-71.206427,43.710878],[-71.185812,43.679297]]]},"properties":{"id":"03864","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.262294,43.169545],[-72.318141,43.20336],[-72.345274,43.216728],[-72.432057,43.159732],[-72.37911,43.132481],[-72.369857,43.119414],[-72.359703,43.061617],[-72.319551,43.064205],[-72.243178,43.070968],[-72.262294,43.169545]]]},"properties":{"id":"03602","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.440217,43.135301],[-72.443879,43.160747],[-72.452274,43.161431],[-72.440217,43.135301]]]},"properties":{"id":"03609","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.455088,42.921527],[-72.426963,42.850228],[-72.388987,42.848134],[-72.346753,42.910677],[-72.367889,42.92642],[-72.453977,42.930734],[-72.455088,42.921527]]]},"properties":{"id":"03462","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.497498,42.854208],[-72.455088,42.921527],[-72.453977,42.930734],[-72.526662,42.934119],[-72.555228,42.858176],[-72.497498,42.854208]]]},"properties":{"id":"03466","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.507085,44.26181],[-71.415801,44.212082],[-71.395246,44.21491],[-71.363543,44.296273],[-71.384622,44.335318],[-71.458504,44.327251],[-71.507085,44.26181]]]},"properties":{"id":"03575","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.532146,44.978543],[-71.551568,44.853108],[-71.439568,44.787838],[-71.360463,44.795672],[-71.220029,44.888052],[-71.225544,44.964727],[-71.481781,44.994002],[-71.532146,44.978543]]]},"properties":{"id":"03576","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.932981,44.065221],[-72.041246,44.154037],[-72.051338,44.065948],[-72.035386,44.032523],[-71.932981,44.065221]]]},"properties":{"id":"03774","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.041246,44.154037],[-71.932981,44.065221],[-71.901176,43.991735],[-71.869695,43.988367],[-71.821933,43.983624],[-71.791684,44.077287],[-71.854022,44.100917],[-71.913539,44.116536],[-72.041671,44.212897],[-72.052606,44.218047],[-72.041246,44.154037]]]},"properties":{"id":"03785","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.571176,43.898522],[-71.564615,43.89311],[-71.458203,43.904098],[-71.361889,43.915549],[-71.363682,43.924732],[-71.377766,43.997962],[-71.398867,43.998909],[-71.520606,43.985934],[-71.571176,43.898522]]]},"properties":{"id":"03215","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.928811,42.712234],[-71.805419,42.708987],[-71.821827,42.786451],[-71.90072,42.788332],[-71.944792,42.788586],[-71.928811,42.712234]]]},"properties":{"id":"03071","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.00441,43.367662],[-71.954958,43.316692],[-71.949436,43.324118],[-72.00441,43.367662]]],[[[-71.953398,43.279723],[-72.065429,43.255044],[-72.008995,43.187874],[-71.913255,43.207263],[-71.849862,43.219308],[-71.924195,43.283141],[-71.953398,43.279723]]]]},"properties":{"id":"03221","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.381363,43.31279],[-71.360002,43.268394],[-71.26164,43.251369],[-71.247215,43.274201],[-71.235828,43.284717],[-71.346983,43.346573],[-71.361202,43.354126],[-71.381363,43.31279]]]},"properties":{"id":"03263","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.402878,43.200846],[-71.317752,43.162556],[-71.280985,43.218538],[-71.26164,43.251369],[-71.360002,43.268394],[-71.402878,43.200846]]]},"properties":{"id":"03234","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.725768,43.244152],[-71.765,43.342796],[-71.838481,43.395675],[-71.856952,43.38906],[-71.884544,43.364443],[-71.885747,43.289137],[-71.924195,43.283141],[-71.849862,43.219308],[-71.787672,43.231922],[-71.725768,43.244152]]]},"properties":{"id":"03278","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.954958,43.316692],[-71.953398,43.279723],[-71.924195,43.283141],[-71.885747,43.289137],[-71.949436,43.324118],[-71.954958,43.316692]]]},"properties":{"id":"03273","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.167805,42.984586],[-71.261283,43.01602],[-71.288115,43.021396],[-71.311203,42.936469],[-71.219406,42.916656],[-71.219977,42.922305],[-71.179305,42.960486],[-71.167805,42.984586]]]},"properties":{"id":"03036","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.413313,42.934997],[-71.44181,42.889586],[-71.427077,42.82489],[-71.360808,42.798988],[-71.331059,42.841143],[-71.351355,42.942649],[-71.377409,42.947185],[-71.413313,42.934997]]]},"properties":{"id":"03053","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.990975,43.373986],[-70.905122,43.279891],[-70.898664,43.287363],[-70.985672,43.379205],[-70.990809,43.37416],[-70.990975,43.373986]]]},"properties":{"id":"03868","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.178457,43.131955],[-72.038149,43.128807],[-72.008995,43.187874],[-72.065429,43.255044],[-72.123421,43.249397],[-72.156548,43.181962],[-72.178457,43.131955]]]},"properties":{"id":"03280","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.5106,43.501869],[-71.485264,43.407433],[-71.480947,43.407897],[-71.467375,43.409391],[-71.395009,43.481181],[-71.44909,43.505083],[-71.50086,43.530726],[-71.5106,43.501869]]]},"properties":{"id":"03220","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.310398,43.387114],[-71.220538,43.299168],[-71.160134,43.35586],[-71.281331,43.418196],[-71.310398,43.387114]]]},"properties":{"id":"03225","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.346983,43.346573],[-71.235828,43.284717],[-71.220538,43.299168],[-71.310398,43.387114],[-71.33694,43.357645],[-71.346983,43.346573]]]},"properties":{"id":"03218","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-71.300896,43.661437],[-71.341131,43.62715],[-71.284881,43.588365],[-71.300896,43.661437]]],[[[-71.302298,43.714728],[-71.274697,43.680805],[-71.250908,43.635005],[-71.185812,43.679297],[-71.206427,43.710878],[-71.245465,43.770575],[-71.302298,43.714728]]]]},"properties":{"id":"03816","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.302298,43.714728],[-71.307093,43.678061],[-71.274697,43.680805],[-71.302298,43.714728]]]},"properties":{"id":"03850","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.290506,43.915193],[-71.194034,43.849082],[-71.223585,43.923401],[-71.290506,43.915193]]]},"properties":{"id":"03817","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.365965,43.821893],[-71.329895,43.782968],[-71.280052,43.775109],[-71.261616,43.795425],[-71.350086,43.836592],[-71.365965,43.821893]]]},"properties":{"id":"03883","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.311265,44.094951],[-71.160774,44.090744],[-71.171324,44.133133],[-71.302744,44.137197],[-71.307731,44.112579],[-71.311265,44.094951]]]},"properties":{"id":"03838","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.156478,42.923774],[-72.001993,42.919535],[-72.003426,42.931104],[-72.061244,42.965842],[-72.167249,42.962561],[-72.156478,42.923774]]]},"properties":{"id":"03450","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.160332,43.658591],[-72.020152,43.611388],[-71.900163,43.606582],[-71.885104,43.695588],[-71.925837,43.704367],[-72.0582,43.737365],[-72.08961,43.744665],[-72.160332,43.658591]]]},"properties":{"id":"03741","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.078713,44.029592],[-72.018493,44.011764],[-72.035386,44.032523],[-72.051338,44.065948],[-72.078713,44.029592]]]},"properties":{"id":"03765","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.93522,43.528449],[-71.864053,43.599442],[-71.900163,43.606582],[-72.020152,43.611388],[-72.060056,43.543159],[-71.93522,43.528449]]]},"properties":{"id":"03240","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.772287,44.07144],[-71.641294,44.023559],[-71.613635,43.996547],[-71.520606,43.985934],[-71.398867,43.998909],[-71.422613,44.168807],[-71.537367,44.197882],[-71.772287,44.07144]]]},"properties":{"id":"03251","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.79423,44.271248],[-71.939279,44.304153],[-72.014937,44.240803],[-71.913539,44.116536],[-71.854022,44.100917],[-71.817603,44.183677],[-71.79423,44.271248]]]},"properties":{"id":"03585","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.582088,43.691931],[-71.676868,43.747018],[-71.648057,43.686154],[-71.582088,43.691931]]]},"properties":{"id":"03217","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.39581,43.019084],[-71.473412,43.035083],[-71.4696,43.003536],[-71.45932,42.990591],[-71.433315,42.987482],[-71.391866,43.00785],[-71.39581,43.019084]]]},"properties":{"id":"03104","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.473412,43.035083],[-71.512713,43.048283],[-71.517893,42.980483],[-71.475011,42.966284],[-71.471796,42.97414],[-71.4696,43.003536],[-71.473412,43.035083]]]},"properties":{"id":"03102","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.88176,43.032055],[-71.874135,42.98798],[-71.806229,42.950821],[-71.748632,42.947308],[-71.762447,43.014065],[-71.785829,43.03844],[-71.88176,43.032055]]]},"properties":{"id":"03043","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.911404,43.051395],[-71.88176,43.032055],[-71.785829,43.03844],[-71.809658,43.124485],[-71.913255,43.207263],[-72.008995,43.187874],[-72.038149,43.128807],[-72.042112,43.082276],[-71.911404,43.051395]]]},"properties":{"id":"03244","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.933243,42.983055],[-71.941691,43.019007],[-72.058913,43.005118],[-72.061244,42.965842],[-72.003426,42.931104],[-71.945423,42.939861],[-71.933243,42.983055]]]},"properties":{"id":"03449","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.480947,43.407897],[-71.524935,43.303065],[-71.456501,43.249133],[-71.449649,43.245239],[-71.381363,43.31279],[-71.361202,43.354126],[-71.467375,43.409391],[-71.480947,43.407897]]]},"properties":{"id":"03307","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.838481,43.395675],[-71.730955,43.432065],[-71.733266,43.503289],[-71.817053,43.475142],[-71.862967,43.459827],[-71.856952,43.38906],[-71.838481,43.395675]],[[-71.754117,43.44934],[-71.766649,43.46131],[-71.757039,43.47199],[-71.742942,43.453048],[-71.754117,43.44934]]]},"properties":{"id":"03216","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.864053,43.599442],[-71.93522,43.528449],[-71.960259,43.501629],[-71.862967,43.459827],[-71.817053,43.475142],[-71.81794,43.544618],[-71.864053,43.599442]]]},"properties":{"id":"03230","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.391866,43.00785],[-71.377409,42.947185],[-71.351355,42.942649],[-71.311203,42.936469],[-71.288115,43.021396],[-71.392246,43.040611],[-71.39581,43.019084],[-71.391866,43.00785]]]},"properties":{"id":"03032","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.773167,42.988358],[-70.783883,42.981962],[-70.77367,42.976177],[-70.766882,42.984384],[-70.773167,42.988358]]]},"properties":{"id":"03871","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.62518,43.578324],[-71.510096,43.552861],[-71.450857,43.617742],[-71.34442,43.62952],[-71.439621,43.696002],[-71.524216,43.667872],[-71.62518,43.578324]]]},"properties":{"id":"03253","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.706127,43.538182],[-71.690664,43.518337],[-71.625673,43.471047],[-71.5106,43.501869],[-71.50086,43.530726],[-71.510096,43.552861],[-71.62518,43.578324],[-71.706127,43.538182]]]},"properties":{"id":"03269","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.20288,43.826979],[-71.178523,43.791611],[-71.210493,43.787394],[-71.220843,43.821521],[-71.261616,43.795425],[-71.280052,43.775109],[-71.245465,43.770575],[-71.206427,43.710878],[-71.110344,43.736656],[-71.081313,43.797519],[-71.167972,43.816618],[-71.19278,43.830109],[-71.20288,43.826979]]]},"properties":{"id":"03814","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.361889,43.915549],[-71.458203,43.904098],[-71.365965,43.821893],[-71.350086,43.836592],[-71.361889,43.915549]]]},"properties":{"id":"03259","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.02017,43.672483],[-70.974089,43.592927],[-70.98031,43.684319],[-71.013089,43.679129],[-71.02017,43.672483]]]},"properties":{"id":"03830","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-71.189358,43.831128],[-71.19278,43.830109],[-71.167972,43.816618],[-71.183641,43.832837],[-71.189358,43.831128]]],[[[-71.20288,43.826979],[-71.220843,43.821521],[-71.210493,43.787394],[-71.178523,43.791611],[-71.20288,43.826979]]]]},"properties":{"id":"03890","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.367889,42.92642],[-72.360323,43.01543],[-72.459538,43.020574],[-72.526662,42.934119],[-72.453977,42.930734],[-72.367889,42.92642]]]},"properties":{"id":"03467","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.443879,43.160747],[-72.440217,43.135301],[-72.459538,43.020574],[-72.360323,43.01543],[-72.359703,43.061617],[-72.369857,43.119414],[-72.3859,43.128605],[-72.37911,43.132481],[-72.432057,43.159732],[-72.443879,43.160747]]]},"properties":{"id":"03608","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.091303,42.793294],[-71.974312,42.789161],[-71.990393,42.865309],[-72.13761,42.868909],[-72.139813,42.846508],[-72.132631,42.810755],[-72.091303,42.793294]]]},"properties":{"id":"03452","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.194038,43.076201],[-72.178457,43.131955],[-72.156548,43.181962],[-72.219784,43.17479],[-72.262294,43.169545],[-72.243178,43.070968],[-72.194038,43.076201]]]},"properties":{"id":"03456","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.305372,44.447369],[-71.385879,44.393744],[-71.384622,44.335318],[-71.363543,44.296273],[-71.307731,44.112579],[-71.302744,44.137197],[-71.260545,44.22797],[-71.248689,44.44859],[-71.305372,44.447369]]]},"properties":{"id":"03593","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.302671,43.596579],[-72.208145,43.585162],[-72.160332,43.658591],[-72.172269,43.661401],[-72.223542,43.673596],[-72.281845,43.687148],[-72.302671,43.596579]]]},"properties":{"id":"03766","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.963253,43.998261],[-71.959209,43.916703],[-71.825926,43.885837],[-71.801019,43.950942],[-71.821933,43.983624],[-71.869695,43.988367],[-71.924894,43.994221],[-71.963253,43.998261]]]},"properties":{"id":"03279","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.041671,44.212897],[-71.913539,44.116536],[-72.014937,44.240803],[-72.041671,44.212897]]]},"properties":{"id":"03740","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.755969,44.256317],[-71.817603,44.183677],[-71.854022,44.100917],[-71.791684,44.077287],[-71.772287,44.07144],[-71.537367,44.197882],[-71.755969,44.256317]]]},"properties":{"id":"03580","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.885104,43.695588],[-71.734413,43.686049],[-71.776427,43.770949],[-71.925837,43.704367],[-71.885104,43.695588]]]},"properties":{"id":"03241","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.026903,43.837949],[-71.825926,43.885837],[-71.959209,43.916703],[-71.975098,43.89503],[-72.026903,43.837949]]]},"properties":{"id":"03282","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.433315,42.987482],[-71.413313,42.934997],[-71.377409,42.947185],[-71.391866,43.00785],[-71.433315,42.987482]]]},"properties":{"id":"03109","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.427077,42.82489],[-71.46381,42.797287],[-71.445109,42.766388],[-71.432308,42.699989],[-71.386607,42.698789],[-71.363624,42.783046],[-71.360808,42.798988],[-71.427077,42.82489]]]},"properties":{"id":"03051","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.498812,42.804947],[-71.46831,42.800487],[-71.453824,42.903408],[-71.45268,42.911069],[-71.570013,42.896285],[-71.558427,42.803278],[-71.556213,42.792587],[-71.498812,42.804947]]]},"properties":{"id":"03054","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.748632,42.947308],[-71.806229,42.950821],[-71.852742,42.882385],[-71.828198,42.865497],[-71.729918,42.850885],[-71.694744,42.863409],[-71.737719,42.922584],[-71.748632,42.947308]]]},"properties":{"id":"03082","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.617115,42.971779],[-71.638506,43.032961],[-71.762447,43.014065],[-71.748632,42.947308],[-71.737719,42.922584],[-71.640016,42.935384],[-71.608707,42.93993],[-71.617115,42.971779]]]},"properties":{"id":"03070","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.638506,43.032961],[-71.617115,42.971779],[-71.517893,42.980483],[-71.512713,43.048283],[-71.518521,43.068914],[-71.641252,43.054173],[-71.638506,43.032961]]]},"properties":{"id":"03045","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.64255,42.778038],[-71.630414,42.704688],[-71.543011,42.702689],[-71.527811,42.739488],[-71.556213,42.792587],[-71.558427,42.803278],[-71.607214,42.798187],[-71.64255,42.778038]]]},"properties":{"id":"03049","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.570013,42.896285],[-71.608707,42.93993],[-71.640016,42.935384],[-71.666416,42.866585],[-71.607214,42.798187],[-71.558427,42.803278],[-71.570013,42.896285]]]},"properties":{"id":"03031","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.588363,43.287373],[-71.638527,43.216107],[-71.603042,43.165188],[-71.495154,43.158177],[-71.456501,43.249133],[-71.524935,43.303065],[-71.588363,43.287373]]]},"properties":{"id":"03301","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.638527,43.216107],[-71.725768,43.244152],[-71.787672,43.231922],[-71.733116,43.139372],[-71.667826,43.152588],[-71.628438,43.160283],[-71.603042,43.165188],[-71.638527,43.216107]]]},"properties":{"id":"03229","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.838481,43.395675],[-71.765,43.342796],[-71.669222,43.376625],[-71.730955,43.432065],[-71.838481,43.395675]]]},"properties":{"id":"03268","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.907704,43.402581],[-72.01431,43.383411],[-72.00441,43.367662],[-71.949436,43.324118],[-71.885747,43.289137],[-71.884544,43.364443],[-71.907704,43.402581]]]},"properties":{"id":"03260","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.72775,43.563524],[-71.81794,43.544618],[-71.817053,43.475142],[-71.733266,43.503289],[-71.690664,43.518337],[-71.706127,43.538182],[-71.72775,43.563524]]]},"properties":{"id":"03243","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.150055,43.021132],[-71.085185,43.020906],[-71.013884,43.03386],[-71.013305,43.052585],[-71.012242,43.082038],[-71.072984,43.081748],[-71.124025,43.076931],[-71.150055,43.021132]]]},"properties":{"id":"03042","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.230531,43.079981],[-71.335528,43.132097],[-71.356182,43.101971],[-71.392246,43.040611],[-71.288115,43.021396],[-71.261283,43.01602],[-71.230531,43.079981]]]},"properties":{"id":"03034","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.103703,42.895888],[-71.146404,42.897687],[-71.156723,42.898287],[-71.133003,42.866188],[-71.125603,42.877188],[-71.103703,42.895888]]]},"properties":{"id":"03826","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.146404,42.897687],[-71.103703,42.895888],[-71.092203,42.959187],[-71.149504,42.959886],[-71.146404,42.897687]]]},"properties":{"id":"03819","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.149504,42.959886],[-71.092203,42.959187],[-71.088013,42.959296],[-71.085185,43.020906],[-71.150055,43.021132],[-71.167805,42.984586],[-71.179305,42.960486],[-71.149504,42.959886]]]},"properties":{"id":"03044","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.901599,42.967288],[-70.918008,42.906655],[-70.823839,42.897857],[-70.901599,42.967288]]]},"properties":{"id":"03844","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.020464,43.268131],[-70.952805,43.225782],[-70.949505,43.229482],[-71.020464,43.268131]]]},"properties":{"id":"03839","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.113118,43.459516],[-72.12748,43.329807],[-72.078666,43.33859],[-72.049829,43.380265],[-72.057641,43.424586],[-72.113118,43.459516]]]},"properties":{"id":"03782","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.345274,43.216728],[-72.264359,43.267882],[-72.264053,43.29256],[-72.406857,43.337403],[-72.452274,43.161431],[-72.443879,43.160747],[-72.432057,43.159732],[-72.345274,43.216728]]]},"properties":{"id":"03603","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.269503,43.423786],[-72.264053,43.29256],[-72.264359,43.267882],[-72.247401,43.25416],[-72.236165,43.25526],[-72.159651,43.280907],[-72.12748,43.329807],[-72.113118,43.459516],[-72.214855,43.488942],[-72.227309,43.49257],[-72.269857,43.471967],[-72.269503,43.423786]],[[-72.136435,43.374854],[-72.141873,43.378871],[-72.140693,43.379745],[-72.135642,43.377075],[-72.136435,43.374854]]]},"properties":{"id":"03773","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.103852,43.482834],[-72.053802,43.441078],[-71.991753,43.466245],[-71.960259,43.501629],[-71.93522,43.528449],[-72.060056,43.543159],[-72.094113,43.503071],[-72.103852,43.482834]]]},"properties":{"id":"03284","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.103852,43.482834],[-72.113118,43.459516],[-72.057641,43.424586],[-72.053802,43.441078],[-72.103852,43.482834]]]},"properties":{"id":"03751","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.285497,43.512387],[-72.396397,43.519154],[-72.39636,43.428868],[-72.269503,43.423786],[-72.269857,43.471967],[-72.256002,43.520745],[-72.285497,43.512387]]]},"properties":{"id":"03745","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.288497,43.445848],[-71.198651,43.55891],[-71.284881,43.588365],[-71.341131,43.62715],[-71.344184,43.629327],[-71.317037,43.509414],[-71.309862,43.462132],[-71.288497,43.445848]]]},"properties":{"id":"03810","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.171324,44.133133],[-71.160774,44.090744],[-71.159337,44.076756],[-71.131738,44.076207],[-71.099064,44.075336],[-71.084057,44.07524],[-71.097955,44.160176],[-71.171324,44.133133]]]},"properties":{"id":"03845","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.081313,43.797519],[-71.110344,43.736656],[-71.013089,43.679129],[-70.98031,43.684319],[-70.989122,43.792049],[-71.081313,43.797519]]]},"properties":{"id":"03882","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.412851,44.166367],[-71.382619,44.022529],[-71.156644,44.024977],[-71.159337,44.076756],[-71.160774,44.090744],[-71.311265,44.094951],[-71.395246,44.21491],[-71.415801,44.212082],[-71.412851,44.166367]]]},"properties":{"id":"03812","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.101951,43.924526],[-71.175814,43.956475],[-71.190785,43.83676],[-71.189358,43.831128],[-71.183641,43.832837],[-71.081213,43.855434],[-71.101951,43.924526]]]},"properties":{"id":"03849","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.319551,43.064205],[-72.359703,43.061617],[-72.360323,43.01543],[-72.367889,42.92642],[-72.346753,42.910677],[-72.234068,42.90707],[-72.177753,42.963038],[-72.178936,42.965481],[-72.252289,43.012837],[-72.319551,43.064205]]]},"properties":{"id":"03431","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.346753,42.910677],[-72.388987,42.848134],[-72.238285,42.81523],[-72.223492,42.873285],[-72.234068,42.90707],[-72.346753,42.910677]]]},"properties":{"id":"03446","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.225544,44.964727],[-71.488471,45.000731],[-71.481781,44.994002],[-71.225544,44.964727]]]},"properties":{"id":"03592","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.220029,44.888052],[-71.360463,44.795672],[-71.325182,44.705099],[-71.030313,44.644337],[-71.220029,44.888052]]]},"properties":{"id":"03579","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.302671,43.596579],[-72.281845,43.687148],[-72.305095,43.692614],[-72.32962,43.600201],[-72.302671,43.596579]]]},"properties":{"id":"03784","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.035386,44.032523],[-72.018493,44.011764],[-71.963253,43.998261],[-71.924894,43.994221],[-71.901176,43.991735],[-71.932981,44.065221],[-72.035386,44.032523]]]},"properties":{"id":"03780","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.171164,43.574021],[-72.094113,43.503071],[-72.060056,43.543159],[-72.020152,43.611388],[-72.160332,43.658591],[-72.208145,43.585162],[-72.171164,43.574021]]]},"properties":{"id":"03748","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.223542,43.673596],[-72.228194,43.731397],[-72.171922,43.727062],[-72.172269,43.661401],[-72.160332,43.658591],[-72.08961,43.744665],[-72.205195,43.770955],[-72.305095,43.692614],[-72.281845,43.687148],[-72.223542,43.673596]]]},"properties":{"id":"03755","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.172269,43.661401],[-72.171922,43.727062],[-72.228194,43.731397],[-72.223542,43.673596],[-72.172269,43.661401]]]},"properties":{"id":"03750","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.764745,44.406635],[-71.984617,44.336243],[-71.939279,44.304153],[-71.79423,44.271248],[-71.696665,44.332326],[-71.764745,44.406635]]]},"properties":{"id":"03561","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.924894,43.994221],[-71.869695,43.988367],[-71.901176,43.991735],[-71.924894,43.994221]]]},"properties":{"id":"03238","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.694744,42.863409],[-71.729918,42.850885],[-71.715531,42.777276],[-71.714017,42.769087],[-71.64255,42.778038],[-71.607214,42.798187],[-71.666416,42.866585],[-71.694744,42.863409]]]},"properties":{"id":"03055","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.737719,42.922584],[-71.694744,42.863409],[-71.666416,42.866585],[-71.640016,42.935384],[-71.737719,42.922584]]]},"properties":{"id":"03057","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.809658,43.124485],[-71.785829,43.03844],[-71.762447,43.014065],[-71.638506,43.032961],[-71.641252,43.054173],[-71.667826,43.152588],[-71.733116,43.139372],[-71.809658,43.124485]]]},"properties":{"id":"03281","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.630414,42.704688],[-71.64255,42.778038],[-71.714017,42.769087],[-71.701216,42.706388],[-71.630414,42.704688]]]},"properties":{"id":"03033","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.608707,42.93993],[-71.570013,42.896285],[-71.45268,42.911069],[-71.475011,42.966284],[-71.517893,42.980483],[-71.617115,42.971779],[-71.608707,42.93993]]]},"properties":{"id":"03110","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.01431,43.383411],[-72.049829,43.380265],[-72.078666,43.33859],[-72.065429,43.255044],[-71.953398,43.279723],[-71.954958,43.316692],[-72.00441,43.367662],[-72.01431,43.383411]]]},"properties":{"id":"03255","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.207847,42.859966],[-71.136469,42.860798],[-71.133003,42.866188],[-71.156723,42.898287],[-71.219977,42.922305],[-71.219406,42.916656],[-71.207847,42.859966]]]},"properties":{"id":"03841","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.133525,43.203724],[-71.247215,43.274201],[-71.26164,43.251369],[-71.280985,43.218538],[-71.156659,43.167019],[-71.148438,43.176333],[-71.139905,43.18624],[-71.122355,43.196797],[-71.133525,43.203724]]]},"properties":{"id":"03261","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.053601,42.833089],[-70.997454,42.864431],[-71.023474,42.894138],[-71.079002,42.857588],[-71.053601,42.833089]]]},"properties":{"id":"03858","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-71.122355,43.196797],[-71.026688,43.138086],[-70.998106,43.17245],[-70.970875,43.205276],[-70.952805,43.225782],[-71.020464,43.268131],[-71.054708,43.288581],[-71.133525,43.203724],[-71.122355,43.196797]]]},"properties":{"id":"03825","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.864321,43.134088],[-70.970875,43.205276],[-70.998106,43.17245],[-70.96072,43.162884],[-70.864321,43.134088]]]},"properties":{"id":"03823","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-70.981056,43.082447],[-70.875486,43.080457],[-70.847129,43.126249],[-70.864321,43.134088],[-70.96072,43.162884],[-70.981056,43.082447]]]},"properties":{"id":"03824","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.136435,43.374854],[-72.135642,43.377075],[-72.140693,43.379745],[-72.141873,43.378871],[-72.136435,43.374854]]]},"properties":{"id":"03754","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.256002,43.520745],[-72.232696,43.542731],[-72.247108,43.556249],[-72.292817,43.533595],[-72.285497,43.512387],[-72.256002,43.520745]]]},"properties":{"id":"03770","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.256002,43.520745],[-72.269857,43.471967],[-72.227309,43.49257],[-72.256002,43.520745]]]},"properties":{"id":"03746","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.159651,43.280907],[-72.123421,43.249397],[-72.065429,43.255044],[-72.078666,43.33859],[-72.12748,43.329807],[-72.159651,43.280907]]]},"properties":{"id":"03752","state_code":"33"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.906182,39.546356],[-74.937037,39.517847],[-74.94927,39.508167],[-74.908477,39.495066],[-74.867185,39.516467],[-74.863437,39.541657],[-74.873444,39.564597],[-74.888029,39.564787],[-74.906182,39.546356]]]},"properties":{"id":"08310","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.513963,39.337642],[-74.541137,39.333373],[-74.556858,39.298823],[-74.552779,39.300382],[-74.515843,39.317071],[-74.513963,39.337642]]]},"properties":{"id":"08403","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.558834,39.464227],[-74.522738,39.434914],[-74.461033,39.415511],[-74.413825,39.397167],[-74.394496,39.437303],[-74.401019,39.536681],[-74.442293,39.543418],[-74.517427,39.509327],[-74.52927,39.501393],[-74.528387,39.474523],[-74.548894,39.477727],[-74.558834,39.464227]]]},"properties":{"id":"08205","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.580013,39.368206],[-74.596103,39.33608],[-74.54606,39.346515],[-74.580013,39.368206]]]},"properties":{"id":"08221","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.103787,41.086896],[-74.213291,41.133995],[-74.248939,41.06382],[-74.201773,41.043778],[-74.16758,41.023781],[-74.155604,41.02585],[-74.150305,41.040506],[-74.116381,41.07277],[-74.103787,41.086896]],[[-74.16204,41.102397],[-74.163975,41.102572],[-74.165739,41.105279],[-74.163288,41.104353],[-74.16204,41.102397]]]},"properties":{"id":"07430","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.991325,40.835884],[-73.987985,40.850884],[-74.006059,40.85697],[-74.010749,40.846203],[-73.991325,40.835884]]]},"properties":{"id":"07650","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.089319,40.919644],[-74.068909,40.908532],[-74.05056,40.910578],[-74.041843,40.911425],[-74.038844,40.943689],[-74.048967,40.965291],[-74.055184,40.969797],[-74.077766,40.978377],[-74.099983,40.955272],[-74.089319,40.919644]]]},"properties":{"id":"07652","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.10277,40.841478],[-74.060685,40.8057],[-74.044977,40.807702],[-74.032084,40.8129],[-74.034984,40.8253],[-74.053074,40.833653],[-74.076142,40.837593],[-74.098923,40.850412],[-74.10277,40.841478]]]},"properties":{"id":"07072","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.038844,40.943689],[-74.041843,40.911425],[-74.030164,40.913233],[-74.030194,40.913327],[-74.026115,40.940142],[-74.038844,40.943689]]]},"properties":{"id":"07661","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.611009,39.941349],[-74.596975,39.899219],[-74.529336,39.86848],[-74.521747,39.871578],[-74.449266,39.881242],[-74.45584,39.893842],[-74.506474,39.991205],[-74.628608,39.966463],[-74.611009,39.941349]]]},"properties":{"id":"08015","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.904577,40.013644],[-74.882226,40.000745],[-74.872424,39.997706],[-74.867901,40.008504],[-74.872247,40.009967],[-74.869978,40.012327],[-74.855651,40.031994],[-74.893415,40.053088],[-74.920727,40.023739],[-74.904577,40.013644]]]},"properties":{"id":"08046","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.785889,40.037794],[-74.74259,40.022642],[-74.698932,40.117971],[-74.682235,40.160628],[-74.702113,40.176087],[-74.719623,40.14763],[-74.724174,40.147322],[-74.776027,40.125004],[-74.777328,40.109094],[-74.76907,40.095026],[-74.785889,40.037794]]]},"properties":{"id":"08505","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.632346,39.96649],[-74.611009,39.941349],[-74.628608,39.966463],[-74.632346,39.96649]]]},"properties":{"id":"08064","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.670743,40.017521],[-74.669498,40.004552],[-74.65608,40.010164],[-74.653777,40.025595],[-74.670743,40.017521]]]},"properties":{"id":"08042","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.489657,39.613092],[-74.461779,39.582708],[-74.449659,39.611935],[-74.489657,39.613092]]]},"properties":{"id":"08224","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.022074,39.903338],[-74.982744,39.940373],[-75.01345,39.954188],[-75.066171,39.925876],[-75.04223,39.91815],[-75.022074,39.903338]]]},"properties":{"id":"08002","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.067088,39.948462],[-75.108519,39.958112],[-75.104472,39.94398],[-75.067088,39.948462]]]},"properties":{"id":"08105","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.956989,39.809175],[-74.907959,39.78376],[-74.902546,39.790797],[-74.908607,39.809486],[-74.95691,39.816714],[-74.956989,39.809175]]]},"properties":{"id":"08091","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.926935,39.873839],[-75.015983,39.85935],[-75.001577,39.836523],[-74.985855,39.834095],[-74.964652,39.845701],[-74.957964,39.817611],[-74.95691,39.816714],[-74.908607,39.809486],[-74.926935,39.873839]]]},"properties":{"id":"08043","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.080122,39.881666],[-75.082147,39.8724],[-75.067716,39.872113],[-75.048488,39.875294],[-75.051473,39.889879],[-75.080122,39.881666]]]},"properties":{"id":"08035","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.921714,39.033874],[-74.885915,39.031097],[-74.901102,39.046359],[-74.921714,39.033874]]],[[[-74.961923,38.997127],[-74.977156,38.939574],[-74.939754,38.927514],[-74.86992,38.938824],[-74.872607,39.005406],[-74.921868,39.004523],[-74.961923,38.997127]]]]},"properties":{"id":"08204","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.921714,39.033874],[-74.901102,39.046359],[-74.924135,39.065953],[-74.961923,38.997127],[-74.921868,39.004523],[-74.921714,39.033874]]]},"properties":{"id":"08251","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.729775,39.171622],[-74.762042,39.204699],[-74.896304,39.16204],[-74.924135,39.065953],[-74.901102,39.046359],[-74.885915,39.031097],[-74.84994,39.030706],[-74.820816,39.054335],[-74.76642,39.074104],[-74.738928,39.124236],[-74.729775,39.171622]],[[-74.768608,39.17434],[-74.769178,39.186283],[-74.760021,39.185284],[-74.768608,39.17434]]]},"properties":{"id":"08210","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.126964,39.467728],[-75.126313,39.47524],[-75.12038,39.47672],[-75.121933,39.480475],[-75.131266,39.478155],[-75.128585,39.467324],[-75.126964,39.467728]]]},"properties":{"id":"08352","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.030807,39.2131],[-74.968177,39.239755],[-74.923957,39.251235],[-74.930845,39.259139],[-74.981514,39.271353],[-75.002285,39.263275],[-75.044071,39.212896],[-75.030807,39.2131]]]},"properties":{"id":"08327","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.162253,39.389625],[-75.169855,39.38218],[-75.151762,39.382675],[-75.162253,39.389625]]]},"properties":{"id":"08320","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.212065,40.742364],[-74.243722,40.730831],[-74.25629,40.7133],[-74.244311,40.71119],[-74.228571,40.70829],[-74.218172,40.721254],[-74.215042,40.729488],[-74.212065,40.742364]]]},"properties":{"id":"07111","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.236957,39.819836],[-75.227914,39.825931],[-75.22766,39.826065],[-75.180841,39.829362],[-75.185096,39.833323],[-75.228634,39.85856],[-75.257146,39.851829],[-75.236957,39.819836]]]},"properties":{"id":"08066","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.188045,39.799818],[-75.22766,39.826065],[-75.227914,39.825931],[-75.197475,39.788031],[-75.188045,39.799818]]]},"properties":{"id":"08061","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.047717,39.578039],[-75.063819,39.569968],[-75.061973,39.568643],[-75.047717,39.578039]]]},"properties":{"id":"08328","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.107974,40.69789],[-74.144826,40.650092],[-74.065931,40.666915],[-74.107974,40.69789]]]},"properties":{"id":"07002","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.102669,40.721107],[-75.068542,40.709703],[-74.997253,40.727853],[-74.951498,40.727654],[-74.911653,40.767063],[-74.942062,40.769798],[-75.042778,40.780524],[-75.071734,40.776579],[-75.102669,40.721107]],[[-75.047367,40.73414],[-75.047333,40.732022],[-75.049874,40.729761],[-75.051779,40.732131],[-75.047367,40.73414]]]},"properties":{"id":"07882","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.72065,40.687797],[-74.715093,40.713903],[-74.720152,40.720857],[-74.724445,40.719482],[-74.732456,40.719928],[-74.744802,40.697551],[-74.721707,40.685341],[-74.72065,40.687797]]]},"properties":{"id":"07979","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.991479,40.481561],[-74.956082,40.506944],[-75.041482,40.573121],[-75.066815,40.536544],[-75.064435,40.476984],[-74.991479,40.481561]]]},"properties":{"id":"08825","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.771419,40.528844],[-74.78515,40.509303],[-74.69617,40.546714],[-74.743089,40.553257],[-74.753282,40.553141],[-74.778425,40.532614],[-74.771419,40.528844]]]},"properties":{"id":"08853","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.778425,40.532614],[-74.805908,40.523925],[-74.792798,40.515987],[-74.786697,40.526887],[-74.771419,40.528844],[-74.778425,40.532614]]]},"properties":{"id":"08887","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.72007,40.223088],[-74.69458,40.207348],[-74.671608,40.269808],[-74.731896,40.242071],[-74.728837,40.23754],[-74.722702,40.230883],[-74.72007,40.223088]]]},"properties":{"id":"08619","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.742936,40.217034],[-74.757994,40.220799],[-74.758307,40.220752],[-74.771093,40.215198],[-74.724174,40.147322],[-74.719623,40.14763],[-74.737106,40.21],[-74.742936,40.217034]]]},"properties":{"id":"08611","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.425692,40.3847],[-74.409816,40.379437],[-74.411894,40.385307],[-74.425692,40.3847]]]},"properties":{"id":"08828","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.296171,40.471082],[-74.323097,40.441445],[-74.296922,40.454362],[-74.27708,40.444829],[-74.270838,40.451038],[-74.296171,40.471082]]]},"properties":{"id":"08859","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.081866,40.434609],[-74.109085,40.443207],[-74.096869,40.42244],[-74.081866,40.434609]]]},"properties":{"id":"07758","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.370457,40.233007],[-74.353526,40.208899],[-74.290227,40.293699],[-74.296999,40.322167],[-74.327404,40.344579],[-74.331678,40.34121],[-74.408085,40.273791],[-74.370457,40.233007]]]},"properties":{"id":"07726","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.106383,40.151221],[-74.117188,40.143171],[-74.114467,40.138014],[-74.096201,40.12463],[-74.092101,40.140839],[-74.106383,40.151221]]]},"properties":{"id":"08720","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.02133,40.197406],[-74.025897,40.184617],[-74.006481,40.187313],[-74.007481,40.195613],[-74.02133,40.197406]]]},"properties":{"id":"07717","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.542912,40.216653],[-74.488669,40.216393],[-74.46407,40.209161],[-74.370457,40.233007],[-74.408085,40.273791],[-74.484287,40.253299],[-74.542912,40.216653]]]},"properties":{"id":"08535","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.396009,40.881951],[-74.361668,40.867421],[-74.362203,40.870486],[-74.375159,40.891945],[-74.396009,40.881951]]]},"properties":{"id":"07034","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.451083,40.838346],[-74.383959,40.824991],[-74.341957,40.846781],[-74.361668,40.867421],[-74.396009,40.881951],[-74.428655,40.881762],[-74.450256,40.879959],[-74.442573,40.866949],[-74.451083,40.838346]]]},"properties":{"id":"07054","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.543908,40.96843],[-74.531543,40.960915],[-74.430178,41.018128],[-74.406653,41.024287],[-74.445434,41.075242],[-74.46471,41.077748],[-74.541211,40.970557],[-74.543908,40.96843]]]},"properties":{"id":"07435","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.71343,40.902351],[-74.806827,40.882127],[-74.741095,40.848938],[-74.71278,40.892148],[-74.71343,40.902351]]]},"properties":{"id":"07828","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.341063,40.946313],[-74.363304,40.957444],[-74.363667,40.955394],[-74.338999,40.894069],[-74.322804,40.908834],[-74.341063,40.946313]]]},"properties":{"id":"07082","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.701037,40.725162],[-74.637427,40.77286],[-74.62379,40.818481],[-74.654662,40.824019],[-74.724439,40.817848],[-74.724445,40.719482],[-74.720152,40.720857],[-74.701037,40.725162]]]},"properties":{"id":"07930","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.438198,40.752308],[-74.392063,40.751379],[-74.438712,40.776028],[-74.438505,40.753384],[-74.438198,40.752308]]]},"properties":{"id":"07940","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.458649,40.739575],[-74.510472,40.734665],[-74.513699,40.697701],[-74.478338,40.708773],[-74.449736,40.718831],[-74.458649,40.739575]]]},"properties":{"id":"07976","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.450256,40.879959],[-74.428655,40.881762],[-74.44056,40.907151],[-74.450256,40.879959]]]},"properties":{"id":"07046","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.300327,41.002743],[-74.328411,41.001419],[-74.331401,40.988262],[-74.297281,40.983576],[-74.300327,41.002743]]]},"properties":{"id":"07457","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.121173,40.020601],[-74.158765,40.023834],[-74.182158,40.032166],[-74.20732,39.949963],[-74.189313,39.948063],[-74.156317,39.940352],[-74.138968,39.933703],[-74.124121,39.934756],[-74.096877,39.941275],[-74.097785,39.958302],[-74.121173,40.020601]]]},"properties":{"id":"08753","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.139814,39.844229],[-74.219449,39.863613],[-74.343309,39.828045],[-74.300229,39.793802],[-74.163147,39.809641],[-74.139814,39.844229]]],[[[-74.212774,39.884939],[-74.310317,39.92761],[-74.389999,39.891005],[-74.21977,39.863722],[-74.212774,39.884939]]]]},"properties":{"id":"08731","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.110685,39.737721],[-74.105169,39.766621],[-74.133497,39.78528],[-74.148647,39.698607],[-74.185638,39.654049],[-74.324058,39.576783],[-74.308398,39.505389],[-74.110685,39.737721]]]},"properties":{"id":"08008","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.182418,39.934578],[-74.147997,39.937046],[-74.156317,39.940352],[-74.189313,39.948063],[-74.190077,39.945668],[-74.182418,39.934578]]]},"properties":{"id":"08741","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.345696,40.045951],[-74.345368,40.02434],[-74.309988,40.016842],[-74.317784,40.037068],[-74.345696,40.045951]]]},"properties":{"id":"08733","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.163147,39.809641],[-74.300229,39.793802],[-74.187094,39.762398],[-74.163147,39.809641]]]},"properties":{"id":"08758","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.270651,39.987373],[-74.310317,39.92761],[-74.212774,39.884939],[-74.21191,39.884594],[-74.2115,39.912444],[-74.190077,39.945668],[-74.189313,39.948063],[-74.20732,39.949963],[-74.270651,39.987373]]]},"properties":{"id":"08757","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.282134,40.936204],[-74.270702,40.89692],[-74.254476,40.883098],[-74.230555,40.883071],[-74.203329,40.924459],[-74.211732,40.980156],[-74.24847,40.992303],[-74.266713,41.014063],[-74.281276,40.979953],[-74.278062,40.95532],[-74.282134,40.936204]]]},"properties":{"id":"07470","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.246691,40.880799],[-74.204821,40.860915],[-74.1935,40.865073],[-74.174588,40.899398],[-74.195689,40.907398],[-74.230555,40.883071],[-74.254476,40.883098],[-74.246691,40.880799]]]},"properties":{"id":"07424","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.158502,40.835699],[-74.130031,40.819962],[-74.124176,40.822629],[-74.121853,40.834098],[-74.142936,40.844974],[-74.158502,40.835699]]]},"properties":{"id":"07014","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.167318,40.918116],[-74.175621,40.919118],[-74.173536,40.912077],[-74.167913,40.913097],[-74.167318,40.918116]]]},"properties":{"id":"07505","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.509367,39.685317],[-75.484163,39.678901],[-75.504538,39.69817],[-75.509367,39.685317]]]},"properties":{"id":"08023","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.602269,41.092905],[-74.615605,41.071097],[-74.598549,41.061171],[-74.577004,41.078996],[-74.585763,41.083491],[-74.602269,41.092905]]]},"properties":{"id":"07439","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.246652,40.663187],[-74.254216,40.670169],[-74.271817,40.670806],[-74.285666,40.66611],[-74.283511,40.658187],[-74.246652,40.663187]]]},"properties":{"id":"07204","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.310392,40.678402],[-74.285666,40.66611],[-74.271817,40.670806],[-74.307753,40.680292],[-74.310392,40.678402]]]},"properties":{"id":"07033","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.401749,40.640393],[-74.384457,40.630453],[-74.372027,40.645004],[-74.386745,40.653316],[-74.401749,40.640393]]]},"properties":{"id":"07023","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.647214,39.624861],[-74.754184,39.714357],[-74.895905,39.715251],[-74.896513,39.710375],[-74.930052,39.688723],[-74.861151,39.586053],[-74.832907,39.56667],[-74.729407,39.565606],[-74.72006,39.57367],[-74.721417,39.574607],[-74.717945,39.577629],[-74.716645,39.576754],[-74.647214,39.624861]],[[-74.844583,39.648839],[-74.851993,39.63024],[-74.87269,39.649614],[-74.865048,39.661663],[-74.844583,39.648839]]]},"properties":{"id":"08037","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.896626,39.452953],[-74.867851,39.473841],[-74.908477,39.495066],[-74.94927,39.508167],[-74.972075,39.505469],[-74.896626,39.452953]]],[[[-75.024903,39.423244],[-74.97436,39.507065],[-74.947487,39.533011],[-74.947201,39.543788],[-74.953027,39.543061],[-75.072264,39.524335],[-75.084014,39.457372],[-75.024903,39.423244]]]]},"properties":{"id":"08360","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.873444,39.564597],[-74.863437,39.541657],[-74.832907,39.56667],[-74.861151,39.586053],[-74.873444,39.564597]]]},"properties":{"id":"08346","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.85349,39.372178],[-74.863683,39.343985],[-74.754189,39.389234],[-74.777632,39.405343],[-74.852062,39.380829],[-74.85349,39.372178]]]},"properties":{"id":"08319","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975769,40.954119],[-74.003159,40.955121],[-74.002583,40.952098],[-74.010459,40.934896],[-73.979705,40.936636],[-73.975696,40.953083],[-73.975769,40.954119]]]},"properties":{"id":"07628","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.05056,40.910578],[-74.064149,40.88681],[-74.068385,40.875999],[-74.059104,40.86962],[-74.058162,40.870841],[-74.038284,40.858999],[-74.032581,40.858698],[-74.035684,40.868999],[-74.034289,40.884637],[-74.030164,40.913233],[-74.041843,40.911425],[-74.05056,40.910578]]]},"properties":{"id":"07601","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.077307,41.004475],[-74.046249,40.998521],[-74.020055,41.019815],[-74.026406,41.020594],[-74.076096,41.014726],[-74.077307,41.004475]]]},"properties":{"id":"07642","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.953982,40.848],[-73.976382,40.8276],[-73.994412,40.806453],[-73.992214,40.800623],[-73.953982,40.848]]]},"properties":{"id":"07020","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.9962,40.824465],[-73.998,40.80847],[-73.994412,40.806453],[-73.976382,40.8276],[-73.988761,40.83452],[-73.9962,40.824465]]]},"properties":{"id":"07010","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.053981,40.853863],[-74.072779,40.847395],[-74.075122,40.848628],[-74.076142,40.837593],[-74.053074,40.833653],[-74.039312,40.830861],[-74.053981,40.853863]]]},"properties":{"id":"07074","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.079369,41.048492],[-74.076096,41.014726],[-74.026406,41.020594],[-74.069675,41.041541],[-74.079369,41.048492]]]},"properties":{"id":"07677","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.982744,39.940373],[-74.983717,39.92694],[-74.966743,39.93772],[-75.005502,39.972982],[-75.019212,39.967462],[-75.01345,39.954188],[-74.982744,39.940373]]]},"properties":{"id":"08052","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.614968,40.024094],[-74.645879,40.029441],[-74.653777,40.025595],[-74.65608,40.010164],[-74.632346,39.96649],[-74.628608,39.966463],[-74.506474,39.991205],[-74.52863,40.03668],[-74.559886,40.027424],[-74.614968,40.024094]]]},"properties":{"id":"08640","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.737106,40.21],[-74.719623,40.14763],[-74.702113,40.176087],[-74.675767,40.195512],[-74.670079,40.197039],[-74.69458,40.207348],[-74.72007,40.223088],[-74.737106,40.21]]]},"properties":{"id":"08610","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.920727,40.023739],[-74.932645,40.068785],[-74.990195,40.036343],[-74.958302,39.991812],[-74.904577,40.013644],[-74.920727,40.023739]]]},"properties":{"id":"08075","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.573742,40.049344],[-74.559886,40.027424],[-74.52863,40.03668],[-74.540585,40.057028],[-74.573742,40.049344]]]},"properties":{"id":"08511","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.573742,40.049344],[-74.614968,40.024094],[-74.559886,40.027424],[-74.573742,40.049344]]]},"properties":{"id":"08641","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.777328,40.109094],[-74.776027,40.125004],[-74.791543,40.121186],[-74.787896,40.108424],[-74.782825,40.106726],[-74.777328,40.109094]]]},"properties":{"id":"08554","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.645879,40.029441],[-74.653184,40.064768],[-74.731206,40.025265],[-74.721272,40.022225],[-74.670743,40.017521],[-74.653777,40.025595],[-74.645879,40.029441]]]},"properties":{"id":"08041","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.682235,40.160628],[-74.698932,40.117971],[-74.644072,40.063213],[-74.611675,40.10276],[-74.600267,40.136997],[-74.682235,40.160628]]]},"properties":{"id":"08515","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.965775,39.920615],[-75.016593,39.873617],[-75.018663,39.872292],[-75.015983,39.85935],[-74.926935,39.873839],[-74.957223,39.91428],[-74.965775,39.920615]]]},"properties":{"id":"08003","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.041275,39.8737],[-75.040899,39.862302],[-75.022167,39.857724],[-75.015983,39.85935],[-75.018663,39.872292],[-75.041275,39.8737]]]},"properties":{"id":"08045","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.022167,39.857724],[-75.040899,39.862302],[-75.048382,39.854225],[-75.051874,39.850082],[-75.053424,39.844414],[-75.038089,39.845268],[-75.022167,39.857724]]]},"properties":{"id":"08049","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.108519,39.958112],[-75.067088,39.948462],[-75.019212,39.967462],[-75.019533,39.989823],[-75.059913,39.991529],[-75.123821,39.962744],[-75.108519,39.958112]]]},"properties":{"id":"08110","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.091415,39.792444],[-75.032519,39.726985],[-75.008019,39.779617],[-75.06001,39.83568],[-75.038089,39.845268],[-75.053424,39.844414],[-75.085211,39.83685],[-75.091415,39.792444]]]},"properties":{"id":"08012","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.762042,39.204699],[-74.729775,39.171622],[-74.666842,39.236017],[-74.683237,39.246764],[-74.762042,39.204699]]]},"properties":{"id":"08230","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.145488,39.324679],[-75.168899,39.372962],[-75.280996,39.29916],[-75.243713,39.283464],[-75.145488,39.324679]]]},"properties":{"id":"08311","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.968177,39.239755],[-74.976988,39.192403],[-74.914909,39.177723],[-74.89715,39.234773],[-74.923957,39.251235],[-74.968177,39.239755]]]},"properties":{"id":"08314","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.195144,40.827896],[-74.21873,40.788323],[-74.218485,40.788074],[-74.21189,40.7856],[-74.194196,40.829002],[-74.195144,40.827896]]]},"properties":{"id":"07028","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.218172,40.721254],[-74.191466,40.712415],[-74.184236,40.724266],[-74.184345,40.727604],[-74.215042,40.729488],[-74.218172,40.721254]]]},"properties":{"id":"07108","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.255273,40.758115],[-74.22089,40.7898],[-74.232573,40.814863],[-74.264305,40.825431],[-74.276321,40.813877],[-74.280592,40.807],[-74.305049,40.757881],[-74.295759,40.757539],[-74.276992,40.7605],[-74.255273,40.758115]]]},"properties":{"id":"07052","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.23813,40.752431],[-74.255273,40.758115],[-74.276992,40.7605],[-74.283427,40.748194],[-74.251361,40.737534],[-74.234841,40.7507],[-74.23813,40.752431]]]},"properties":{"id":"07079","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.222252,40.675048],[-74.244311,40.71119],[-74.25629,40.7133],[-74.273626,40.718628],[-74.297829,40.713991],[-74.307753,40.680292],[-74.271817,40.670806],[-74.254216,40.670169],[-74.222252,40.675048]]]},"properties":{"id":"07083","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.180841,39.829362],[-75.22766,39.826065],[-75.188045,39.799818],[-75.177366,39.809153],[-75.180841,39.829362]]],[[[-75.228634,39.85856],[-75.185096,39.833323],[-75.16513,39.855487],[-75.162872,39.882649],[-75.189255,39.88066],[-75.171474,39.869006],[-75.182076,39.856783],[-75.199857,39.873222],[-75.228634,39.85856]]]]},"properties":{"id":"08086","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.063819,39.569968],[-75.047717,39.578039],[-74.95725,39.610331],[-75.031768,39.662635],[-75.099024,39.640906],[-75.100154,39.601085],[-75.087519,39.586947],[-75.063819,39.569968]]]},"properties":{"id":"08322","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.997967,40.790972],[-74.023138,40.782873],[-74.014798,40.779711],[-74.008323,40.778093],[-73.997967,40.790972]]]},"properties":{"id":"07093","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.056895,40.768867],[-74.04586,40.75737],[-74.023138,40.782873],[-73.997967,40.790972],[-73.992214,40.800623],[-73.994412,40.806453],[-73.998,40.80847],[-74.013625,40.822909],[-74.032084,40.8129],[-74.044977,40.807702],[-74.056895,40.768867]]]},"properties":{"id":"07047","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.914339,40.403757],[-74.955467,40.379316],[-74.942909,40.341683],[-74.855516,40.347685],[-74.824682,40.363288],[-74.847278,40.387175],[-74.914339,40.403757]]]},"properties":{"id":"08530","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.78515,40.509303],[-74.771419,40.528844],[-74.786697,40.526887],[-74.792798,40.515987],[-74.805908,40.523925],[-74.778425,40.532614],[-74.753282,40.553141],[-74.824769,40.577501],[-74.867073,40.575267],[-74.910718,40.574278],[-74.956082,40.506944],[-74.991479,40.481561],[-74.892329,40.469534],[-74.772288,40.469005],[-74.78515,40.509303]]]},"properties":{"id":"08822","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.671608,40.269808],[-74.69458,40.207348],[-74.670079,40.197039],[-74.640279,40.244424],[-74.664039,40.267171],[-74.671608,40.269808]]]},"properties":{"id":"08690","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.761889,40.284218],[-74.731896,40.242071],[-74.671608,40.269808],[-74.664039,40.267171],[-74.670809,40.279383],[-74.7458,40.326111],[-74.761889,40.284218]]]},"properties":{"id":"08648","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.7458,40.326111],[-74.670809,40.279383],[-74.613741,40.324185],[-74.589343,40.35806],[-74.576057,40.397933],[-74.571633,40.423908],[-74.581416,40.460396],[-74.591703,40.457941],[-74.594936,40.455014],[-74.631525,40.420933],[-74.636377,40.406399],[-74.629154,40.399472],[-74.610144,40.385757],[-74.618495,40.380217],[-74.629139,40.399209],[-74.647345,40.402577],[-74.736995,40.370155],[-74.751641,40.364152],[-74.7458,40.326111]],[[-74.656267,40.344057],[-74.664429,40.348232],[-74.669459,40.357609],[-74.664443,40.360675],[-74.657309,40.353798],[-74.656267,40.344057]]]},"properties":{"id":"08540","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.855516,40.347685],[-74.811177,40.287563],[-74.800116,40.289748],[-74.798212,40.290636],[-74.761889,40.284218],[-74.7458,40.326111],[-74.751641,40.364152],[-74.824682,40.363288],[-74.855516,40.347685]]]},"properties":{"id":"08534","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.856508,40.277407],[-74.811177,40.287563],[-74.855516,40.347685],[-74.942909,40.341683],[-74.856508,40.277407]]]},"properties":{"id":"08560","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.656267,40.344057],[-74.657309,40.353798],[-74.664443,40.360675],[-74.669459,40.357609],[-74.664429,40.348232],[-74.656267,40.344057]]]},"properties":{"id":"08542","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.736995,40.370155],[-74.647345,40.402577],[-74.636377,40.406399],[-74.631525,40.420933],[-74.715816,40.441973],[-74.752023,40.43138],[-74.736995,40.370155]]]},"properties":{"id":"08558","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.294412,40.57482],[-74.337175,40.585776],[-74.332485,40.566433],[-74.330092,40.556678],[-74.317156,40.55273],[-74.311484,40.555624],[-74.292849,40.571552],[-74.294412,40.57482]]]},"properties":{"id":"08830","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.244188,40.579726],[-74.24779,40.600103],[-74.258114,40.60225],[-74.290861,40.592007],[-74.294412,40.57482],[-74.292849,40.571552],[-74.266902,40.571016],[-74.244188,40.579726]]]},"properties":{"id":"07001","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.519989,40.428381],[-74.531476,40.441383],[-74.571633,40.423908],[-74.576057,40.397933],[-74.519989,40.428381]]]},"properties":{"id":"08824","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.629139,40.399209],[-74.618495,40.380217],[-74.610144,40.385757],[-74.629154,40.399472],[-74.629139,40.399209]]]},"properties":{"id":"08528","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.013947,40.214341],[-74.110838,40.252683],[-74.125604,40.249303],[-74.111133,40.17865],[-74.025897,40.184617],[-74.02133,40.197406],[-74.016023,40.208452],[-74.013947,40.214341]]]},"properties":{"id":"07753","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.038643,40.104456],[-74.085878,40.09797],[-74.08559,40.097611],[-74.038643,40.104456]]]},"properties":{"id":"08730","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.025897,40.184617],[-74.111133,40.17865],[-74.106383,40.151221],[-74.092101,40.140839],[-74.068231,40.133107],[-74.058608,40.146411],[-74.015081,40.166914],[-74.006481,40.187313],[-74.025897,40.184617]]]},"properties":{"id":"07719","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.009807,40.275389],[-74.012545,40.304855],[-74.032545,40.298748],[-74.027625,40.272741],[-74.009807,40.275389]]]},"properties":{"id":"07764","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.051136,40.347982],[-74.063691,40.338267],[-74.040653,40.320494],[-74.032923,40.322618],[-74.002658,40.334039],[-74.003188,40.339947],[-74.037668,40.352242],[-74.051136,40.347982]]]},"properties":{"id":"07739","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.469326,40.81166],[-74.533554,40.833144],[-74.571312,40.810239],[-74.56638,40.805706],[-74.566343,40.795659],[-74.57736,40.804873],[-74.57821,40.805158],[-74.548751,40.74601],[-74.548999,40.740994],[-74.510472,40.734665],[-74.458649,40.739575],[-74.438505,40.753384],[-74.438712,40.776028],[-74.434962,40.778736],[-74.435315,40.780866],[-74.41077,40.792649],[-74.40394,40.800385],[-74.455208,40.806102],[-74.469326,40.81166]]]},"properties":{"id":"07960","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.468221,40.874551],[-74.483432,40.871418],[-74.472873,40.866644],[-74.468221,40.874551]]]},"properties":{"id":"07878","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.533554,40.833144],[-74.469326,40.81166],[-74.451083,40.838346],[-74.442573,40.866949],[-74.468221,40.874551],[-74.472873,40.866644],[-74.483432,40.871418],[-74.533554,40.833144]]]},"properties":{"id":"07950","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.623369,40.867392],[-74.646186,40.874059],[-74.662598,40.864626],[-74.651429,40.840085],[-74.624862,40.864607],[-74.623369,40.867392]]]},"properties":{"id":"07876","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.665679,40.917208],[-74.692165,40.904432],[-74.685018,40.892452],[-74.657146,40.891685],[-74.651805,40.923298],[-74.665679,40.917208]]]},"properties":{"id":"07850","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.432025,40.992541],[-74.462413,40.933936],[-74.44056,40.907151],[-74.428655,40.881762],[-74.396009,40.881951],[-74.375159,40.891945],[-74.363667,40.955394],[-74.363304,40.957444],[-74.432025,40.992541]]]},"properties":{"id":"07005","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.383959,40.824991],[-74.451083,40.838346],[-74.455208,40.806102],[-74.40394,40.800385],[-74.383959,40.824991]]]},"properties":{"id":"07981","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.392063,40.751379],[-74.438198,40.752308],[-74.449736,40.718831],[-74.444333,40.687969],[-74.429023,40.698754],[-74.392757,40.718046],[-74.371923,40.739229],[-74.363072,40.750403],[-74.392063,40.751379]]]},"properties":{"id":"07928","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.817563,40.797744],[-74.816124,40.806782],[-74.826661,40.807584],[-74.817563,40.797744]]]},"properties":{"id":"07870","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.124574,39.869011],[-74.21191,39.884594],[-74.212774,39.884939],[-74.21977,39.863722],[-74.219449,39.863613],[-74.139814,39.844229],[-74.124574,39.869011]]]},"properties":{"id":"08734","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.155941,40.078191],[-74.158765,40.023834],[-74.121173,40.020601],[-74.086735,40.008035],[-74.051573,40.059015],[-74.053281,40.059316],[-74.155941,40.078191]]]},"properties":{"id":"08723","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.154318,40.91671],[-74.132409,40.90406],[-74.130243,40.918119],[-74.154318,40.91671]]]},"properties":{"id":"07504","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.346195,41.060796],[-74.300255,41.051792],[-74.294761,41.051544],[-74.250595,41.060676],[-74.248939,41.06382],[-74.213291,41.133995],[-74.279953,41.162994],[-74.324374,41.101959],[-74.346195,41.060796]]]},"properties":{"id":"07456","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.300255,41.051792],[-74.346195,41.060796],[-74.351283,41.04575],[-74.322386,41.03843],[-74.300255,41.051792]]],[[[-74.250595,41.060676],[-74.294761,41.051544],[-74.290135,41.015525],[-74.270521,41.022131],[-74.250595,41.060676]]]]},"properties":{"id":"07465","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.166032,40.926261],[-74.156454,40.92541],[-74.148059,40.94159],[-74.166612,40.93433],[-74.166816,40.931361],[-74.166032,40.926261]]]},"properties":{"id":"07524","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.528019,39.500414],[-75.455477,39.414111],[-75.455155,39.50357],[-75.528019,39.500414]]]},"properties":{"id":"08038","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.591703,40.457941],[-74.58932,40.527916],[-74.598937,40.557642],[-74.608096,40.558356],[-74.626395,40.563153],[-74.648725,40.556202],[-74.687409,40.555354],[-74.69617,40.546714],[-74.78515,40.509303],[-74.772288,40.469005],[-74.76247,40.451117],[-74.752023,40.43138],[-74.715816,40.441973],[-74.594936,40.455014],[-74.591703,40.457941]],[[-74.685226,40.517336],[-74.692947,40.51787],[-74.691951,40.521986],[-74.684861,40.522823],[-74.685226,40.517336]]]},"properties":{"id":"08844","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.631525,40.420933],[-74.594936,40.455014],[-74.715816,40.441973],[-74.631525,40.420933]]]},"properties":{"id":"08502","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.414729,40.641539],[-74.3898,40.622079],[-74.384457,40.630453],[-74.401749,40.640393],[-74.408264,40.644185],[-74.414729,40.641539]]]},"properties":{"id":"07062","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.629154,40.399472],[-74.636377,40.406399],[-74.647345,40.402577],[-74.629139,40.399209],[-74.629154,40.399472]]]},"properties":{"id":"08553","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.582221,40.535036],[-74.558598,40.546798],[-74.566194,40.542879],[-74.578585,40.540781],[-74.582221,40.535036]]]},"properties":{"id":"08890","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.867771,41.192904],[-74.82678,41.235962],[-74.836027,41.278992],[-74.87148,41.202635],[-74.867771,41.192904]]]},"properties":{"id":"07851","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.392757,40.718046],[-74.429023,40.698754],[-74.381404,40.688332],[-74.392757,40.718046]]]},"properties":{"id":"07974","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.36039,40.699821],[-74.331358,40.682192],[-74.322371,40.677443],[-74.310392,40.678402],[-74.307753,40.680292],[-74.297829,40.713991],[-74.29869,40.71479],[-74.318609,40.7192],[-74.332088,40.720176],[-74.36039,40.699821]]]},"properties":{"id":"07081","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.326593,40.657702],[-74.319678,40.641989],[-74.312298,40.654042],[-74.326593,40.657702]]]},"properties":{"id":"07027","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.069277,41.019348],[-75.014017,40.868236],[-74.982155,40.879127],[-74.887969,40.933194],[-74.880745,40.963888],[-74.873612,40.970258],[-74.938971,41.068729],[-74.966923,41.09452],[-75.069277,41.019348]]]},"properties":{"id":"07825","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.957153,40.834799],[-75.042778,40.780524],[-74.942062,40.769798],[-74.887783,40.81611],[-74.895533,40.851298],[-74.957153,40.834799]]]},"properties":{"id":"07863","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.880745,40.963888],[-74.873381,40.96668],[-74.873612,40.970258],[-74.880745,40.963888]]]},"properties":{"id":"07846","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.047367,40.73414],[-75.051779,40.732131],[-75.049874,40.729761],[-75.047333,40.732022],[-75.047367,40.73414]]]},"properties":{"id":"08808","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.413825,39.397167],[-74.40413,39.365522],[-74.394496,39.437303],[-74.413825,39.397167]]]},"properties":{"id":"08203","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.54606,39.346515],[-74.596103,39.33608],[-74.626025,39.292847],[-74.598797,39.305196],[-74.556858,39.298823],[-74.541137,39.333373],[-74.54606,39.346515]]]},"properties":{"id":"08244","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.517427,39.509327],[-74.442293,39.543418],[-74.479558,39.55597],[-74.517427,39.509327]]]},"properties":{"id":"08241","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.777632,39.405343],[-74.837742,39.424432],[-74.857991,39.396402],[-74.852062,39.380829],[-74.777632,39.405343]]]},"properties":{"id":"08317","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.991325,40.835884],[-73.988761,40.83452],[-73.976382,40.8276],[-73.953982,40.848],[-73.948385,40.858471],[-73.966833,40.868573],[-73.97569,40.868903],[-73.987985,40.850884],[-73.991325,40.835884]]]},"properties":{"id":"07024","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.093876,41.005263],[-74.077307,41.004475],[-74.076096,41.014726],[-74.079369,41.048492],[-74.071915,41.072883],[-74.103787,41.086896],[-74.116381,41.07277],[-74.114553,41.043631],[-74.121264,41.019945],[-74.093876,41.005263]]]},"properties":{"id":"07458","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.137536,41.007495],[-74.13087,41.006192],[-74.122484,41.006097],[-74.093876,41.005263],[-74.121264,41.019945],[-74.147648,41.022058],[-74.137536,41.007495]]]},"properties":{"id":"07463","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.988761,40.83452],[-73.991325,40.835884],[-74.010749,40.846203],[-74.029887,40.846659],[-74.035384,40.8288],[-74.034984,40.8253],[-74.032084,40.8129],[-74.013625,40.822909],[-73.9962,40.824465],[-73.988761,40.83452]]]},"properties":{"id":"07657","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.055286,40.976604],[-74.046249,40.998521],[-74.077307,41.004475],[-74.079652,40.989891],[-74.077766,40.978377],[-74.055184,40.969797],[-74.055286,40.976604]]]},"properties":{"id":"07676","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.104182,40.893664],[-74.091913,40.88662],[-74.078923,40.895068],[-74.089319,40.919644],[-74.113374,40.917579],[-74.104182,40.893664]]]},"properties":{"id":"07663","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.614968,40.024094],[-74.573742,40.049344],[-74.540585,40.057028],[-74.553105,40.07913],[-74.611675,40.10276],[-74.644072,40.063213],[-74.653184,40.064768],[-74.645879,40.029441],[-74.614968,40.024094]]]},"properties":{"id":"08562","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.449659,39.611935],[-74.461779,39.582708],[-74.489657,39.613092],[-74.479558,39.55597],[-74.442293,39.543418],[-74.401019,39.536681],[-74.308398,39.505389],[-74.324058,39.576783],[-74.278757,39.603538],[-74.352516,39.70632],[-74.369734,39.705693],[-74.447422,39.626913],[-74.449659,39.611935]]]},"properties":{"id":"08087","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.782825,40.106726],[-74.76907,40.095026],[-74.777328,40.109094],[-74.782825,40.106726]]],[[[-74.828736,40.120343],[-74.787896,40.108424],[-74.791543,40.121186],[-74.828736,40.120343]]]]},"properties":{"id":"08518","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.867901,40.008504],[-74.869978,40.012327],[-74.872247,40.009967],[-74.867901,40.008504]]]},"properties":{"id":"08073","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.089906,39.921833],[-75.134896,39.927351],[-75.129515,39.905302],[-75.101019,39.898664],[-75.094759,39.901483],[-75.089929,39.921822],[-75.089906,39.921833]]]},"properties":{"id":"08104","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.139832,39.888317],[-75.120635,39.87261],[-75.108105,39.877995],[-75.101019,39.898664],[-75.129515,39.905302],[-75.139832,39.888317]]]},"properties":{"id":"08030","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.067716,39.872113],[-75.064216,39.859913],[-75.048382,39.854225],[-75.040899,39.862302],[-75.041275,39.8737],[-75.048488,39.875294],[-75.067716,39.872113]]]},"properties":{"id":"08007","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.64215,39.209054],[-74.552779,39.300382],[-74.556858,39.298823],[-74.598797,39.305196],[-74.64666,39.23257],[-74.647843,39.211346],[-74.64215,39.209054]]]},"properties":{"id":"08226","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.666842,39.236017],[-74.64666,39.23257],[-74.598797,39.305196],[-74.626025,39.292847],[-74.640088,39.29599],[-74.683237,39.246764],[-74.666842,39.236017]]]},"properties":{"id":"08223","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.673548,39.183567],[-74.665899,39.179692],[-74.64215,39.209054],[-74.647843,39.211346],[-74.673548,39.183567]]]},"properties":{"id":"08248","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.939754,38.927514],[-74.977156,38.939574],[-74.971993,38.929257],[-74.939754,38.927514]]]},"properties":{"id":"08212","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.411242,39.420364],[-75.406841,39.38061],[-75.356954,39.344143],[-75.411242,39.420364]]]},"properties":{"id":"08323","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.981514,39.271353],[-74.930845,39.259139],[-74.966807,39.290831],[-74.991343,39.28584],[-74.981514,39.271353]]]},"properties":{"id":"08316","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.255273,40.758115],[-74.23813,40.752431],[-74.220968,40.768024],[-74.218485,40.788074],[-74.21873,40.788323],[-74.22089,40.7898],[-74.255273,40.758115]]]},"properties":{"id":"07050","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.220968,40.768024],[-74.197589,40.7562],[-74.189989,40.7686],[-74.21189,40.7856],[-74.218485,40.788074],[-74.220968,40.768024]]]},"properties":{"id":"07017","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.367278,40.761067],[-74.305049,40.757881],[-74.280592,40.807],[-74.334342,40.818416],[-74.366169,40.794786],[-74.367278,40.761067]]]},"properties":{"id":"07039","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.219574,40.836049],[-74.195144,40.827896],[-74.194196,40.829002],[-74.182689,40.848799],[-74.1935,40.865073],[-74.204821,40.860915],[-74.219574,40.836049]]]},"properties":{"id":"07043","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.148266,40.779876],[-74.17476,40.785607],[-74.184667,40.750817],[-74.173566,40.747955],[-74.165985,40.748036],[-74.164799,40.754718],[-74.148266,40.779876]]]},"properties":{"id":"07104","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.184345,40.727604],[-74.184236,40.724266],[-74.174889,40.723921],[-74.161669,40.735781],[-74.165985,40.748036],[-74.173566,40.747955],[-74.184345,40.727604]]]},"properties":{"id":"07102","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.29869,40.71479],[-74.285299,40.722055],[-74.289092,40.736201],[-74.308199,40.729965],[-74.318609,40.7192],[-74.29869,40.71479]]]},"properties":{"id":"07041","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.285299,40.722055],[-74.273626,40.718628],[-74.25629,40.7133],[-74.243722,40.730831],[-74.251361,40.737534],[-74.283427,40.748194],[-74.289092,40.736201],[-74.285299,40.722055]]]},"properties":{"id":"07040","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.264077,39.761781],[-75.264734,39.698862],[-75.266613,39.682184],[-75.230956,39.662361],[-75.176789,39.686279],[-75.153105,39.723327],[-75.156732,39.725425],[-75.212687,39.756937],[-75.264077,39.761781]],[[-75.169337,39.708468],[-75.166293,39.722482],[-75.158035,39.71354],[-75.169337,39.708468]]]},"properties":{"id":"08062","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.450212,39.774761],[-75.367293,39.70573],[-75.286921,39.680258],[-75.264734,39.698862],[-75.264077,39.761781],[-75.28626,39.792795],[-75.334596,39.830854],[-75.398417,39.810467],[-75.450212,39.774761]]]},"properties":{"id":"08085","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.137492,40.741398],[-74.164799,40.754718],[-74.165985,40.748036],[-74.161669,40.735781],[-74.137492,40.741398]]]},"properties":{"id":"07029","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.064435,40.476984],[-74.955467,40.379316],[-74.914339,40.403757],[-74.892329,40.469534],[-74.991479,40.481561],[-75.064435,40.476984]]]},"properties":{"id":"08559","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.892329,40.469534],[-74.914339,40.403757],[-74.847278,40.387175],[-74.76247,40.451117],[-74.772288,40.469005],[-74.892329,40.469534]]]},"properties":{"id":"08551","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.1896,40.591776],[-75.066815,40.536544],[-75.041482,40.573121],[-75.015096,40.602541],[-75.009158,40.621625],[-75.070091,40.629254],[-75.155778,40.620837],[-75.1896,40.591776]]]},"properties":{"id":"08848","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.910617,40.67626],[-74.944808,40.677773],[-74.948673,40.634174],[-74.926067,40.626402],[-74.89132,40.656501],[-74.910617,40.67626]]]},"properties":{"id":"08809","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.758307,40.220752],[-74.778302,40.219714],[-74.771093,40.215198],[-74.758307,40.220752]]]},"properties":{"id":"08608","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.332485,40.566433],[-74.337175,40.585776],[-74.338493,40.605503],[-74.359118,40.603539],[-74.394634,40.600901],[-74.40448,40.54972],[-74.381048,40.549749],[-74.344913,40.553083],[-74.332485,40.566433]]]},"properties":{"id":"08820","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.589343,40.35806],[-74.613741,40.324185],[-74.588728,40.313724],[-74.560603,40.358859],[-74.589343,40.35806]]]},"properties":{"id":"08536","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.016023,40.208452],[-74.003181,40.208513],[-73.998481,40.216812],[-74.013947,40.214341],[-74.016023,40.208452]]]},"properties":{"id":"07756","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.043385,40.273422],[-74.019689,40.253485],[-74.0002,40.26345],[-74.009807,40.275389],[-74.027625,40.272741],[-74.043385,40.273422]]]},"properties":{"id":"07755","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.471477,40.188793],[-74.540807,40.08432],[-74.444959,40.109524],[-74.442336,40.147423],[-74.471477,40.188793]]]},"properties":{"id":"08514","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.208301,40.381761],[-74.20062,40.347117],[-74.197556,40.345363],[-74.148915,40.324721],[-74.148892,40.365535],[-74.133206,40.426617],[-74.135956,40.426786],[-74.209053,40.405025],[-74.208301,40.381761]]]},"properties":{"id":"07733","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.058608,40.146411],[-74.02148,40.137514],[-74.015081,40.166914],[-74.058608,40.146411]]]},"properties":{"id":"07762","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.524895,40.880551],[-74.57818,40.877195],[-74.624862,40.864607],[-74.651429,40.840085],[-74.654662,40.824019],[-74.62379,40.818481],[-74.578541,40.805908],[-74.571312,40.810239],[-74.533554,40.833144],[-74.525099,40.879628],[-74.524895,40.880551]]]},"properties":{"id":"07869","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.525099,40.879628],[-74.533554,40.833144],[-74.483432,40.871418],[-74.468221,40.874551],[-74.442573,40.866949],[-74.450256,40.879959],[-74.44056,40.907151],[-74.462413,40.933936],[-74.525099,40.879628]]]},"properties":{"id":"07834","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.451083,40.838346],[-74.469326,40.81166],[-74.455208,40.806102],[-74.451083,40.838346]]]},"properties":{"id":"07927","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.432025,40.992541],[-74.363304,40.957444],[-74.341063,40.946313],[-74.330459,40.966053],[-74.331401,40.988262],[-74.328411,41.001419],[-74.363009,41.013169],[-74.406653,41.024287],[-74.430178,41.018128],[-74.432025,40.992541]]]},"properties":{"id":"07405","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.61539,40.930334],[-74.636163,40.942639],[-74.651805,40.923298],[-74.657146,40.891685],[-74.638022,40.893172],[-74.63356,40.894631],[-74.61539,40.930334]]]},"properties":{"id":"07856","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.484598,40.696521],[-74.482306,40.666038],[-74.461231,40.673397],[-74.444333,40.687969],[-74.449736,40.718831],[-74.478338,40.708773],[-74.484598,40.696521]]]},"properties":{"id":"07933","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.266067,40.055649],[-74.317784,40.037068],[-74.309988,40.016842],[-74.345368,40.02434],[-74.345696,40.045951],[-74.434141,40.033872],[-74.45584,39.893842],[-74.449266,39.881242],[-74.389999,39.891005],[-74.310317,39.92761],[-74.270651,39.987373],[-74.266067,40.055649]]]},"properties":{"id":"08759","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.066641,39.936712],[-74.096956,39.926034],[-74.101274,39.899021],[-74.133497,39.78528],[-74.105169,39.766621],[-74.094357,39.76306],[-74.066641,39.936712]]]},"properties":{"id":"08752","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.300229,39.793802],[-74.343309,39.828045],[-74.369734,39.705693],[-74.352516,39.70632],[-74.343048,39.707082],[-74.185706,39.723327],[-74.187094,39.762398],[-74.300229,39.793802]]]},"properties":{"id":"08005","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.182418,39.934578],[-74.2115,39.912444],[-74.21191,39.884594],[-74.124574,39.869011],[-74.101274,39.899021],[-74.096956,39.926034],[-74.096877,39.941275],[-74.124121,39.934756],[-74.123984,39.922968],[-74.147201,39.923113],[-74.138968,39.933703],[-74.147997,39.937046],[-74.182418,39.934578]]]},"properties":{"id":"08721","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.105169,39.766621],[-74.110685,39.737721],[-74.094357,39.76306],[-74.105169,39.766621]]]},"properties":{"id":"08006","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.211732,40.980156],[-74.203329,40.924459],[-74.198877,40.924748],[-74.193143,40.930375],[-74.166816,40.931361],[-74.166612,40.93433],[-74.170988,40.976997],[-74.191835,40.978688],[-74.211732,40.980156]]]},"properties":{"id":"07508","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.156454,40.92541],[-74.154318,40.91671],[-74.130243,40.918119],[-74.131926,40.922168],[-74.148059,40.94159],[-74.156454,40.92541]]]},"properties":{"id":"07514","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.625383,40.573897],[-74.608096,40.558356],[-74.598937,40.557642],[-74.566194,40.542879],[-74.558598,40.546798],[-74.543759,40.554559],[-74.54279,40.555196],[-74.557502,40.579477],[-74.584277,40.624537],[-74.633147,40.642467],[-74.667274,40.615956],[-74.687409,40.555354],[-74.648725,40.556202],[-74.625383,40.573897]]]},"properties":{"id":"08807","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.618517,40.744137],[-74.597843,40.697474],[-74.548999,40.740994],[-74.548751,40.74601],[-74.618517,40.744137]]]},"properties":{"id":"07924","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.682377,40.931984],[-74.665679,40.917208],[-74.651805,40.923298],[-74.636163,40.942639],[-74.641424,40.961365],[-74.682377,40.931984]]]},"properties":{"id":"07843","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.326593,40.657702],[-74.312298,40.654042],[-74.319678,40.641989],[-74.313192,40.638202],[-74.28982,40.639951],[-74.281488,40.650145],[-74.283511,40.658187],[-74.285666,40.66611],[-74.310392,40.678402],[-74.322371,40.677443],[-74.326593,40.657702]]]},"properties":{"id":"07016","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.209042,40.690428],[-74.222252,40.675048],[-74.254216,40.670169],[-74.246652,40.663187],[-74.244351,40.663509],[-74.215358,40.667633],[-74.201889,40.686702],[-74.209042,40.690428]]]},"properties":{"id":"07208","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.381404,40.688332],[-74.429023,40.698754],[-74.444333,40.687969],[-74.461231,40.673397],[-74.448544,40.655415],[-74.403353,40.666537],[-74.387621,40.678234],[-74.37945,40.685397],[-74.381404,40.688332]]]},"properties":{"id":"07922","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.303392,40.608903],[-74.290861,40.592007],[-74.258114,40.60225],[-74.276918,40.62753],[-74.303392,40.608903]]]},"properties":{"id":"07065","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.28982,40.639951],[-74.313192,40.638202],[-74.325635,40.625052],[-74.359118,40.603539],[-74.338493,40.605503],[-74.303392,40.608903],[-74.276918,40.62753],[-74.28982,40.639951]]]},"properties":{"id":"07066","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.895533,40.851298],[-74.896541,40.871402],[-74.887278,40.88024],[-74.843362,40.919097],[-74.840433,40.921315],[-74.887969,40.933194],[-74.982155,40.879127],[-74.957153,40.834799],[-74.895533,40.851298]]]},"properties":{"id":"07838","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.102669,40.721107],[-75.124454,40.656026],[-75.062338,40.68266],[-75.068542,40.709703],[-75.102669,40.721107]]]},"properties":{"id":"08886","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.896541,40.871402],[-74.884513,40.870698],[-74.887278,40.88024],[-74.896541,40.871402]]]},"properties":{"id":"07880","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.957153,40.834799],[-74.982155,40.879127],[-75.014017,40.868236],[-75.088876,40.832533],[-75.110675,40.79016],[-75.071734,40.776579],[-75.042778,40.780524],[-74.957153,40.834799]]]},"properties":{"id":"07823","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.525084,39.414413],[-74.488279,39.385885],[-74.461033,39.415511],[-74.522738,39.434914],[-74.525084,39.414413]]]},"properties":{"id":"08201","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.522738,39.434914],[-74.558834,39.464227],[-74.586322,39.466395],[-74.717772,39.403964],[-74.640088,39.29599],[-74.626025,39.292847],[-74.596103,39.33608],[-74.580013,39.368206],[-74.550464,39.386531],[-74.525084,39.414413],[-74.522738,39.434914]]]},"properties":{"id":"08234","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.059104,40.86962],[-74.072779,40.847395],[-74.053981,40.853863],[-74.051677,40.857692],[-74.058162,40.870841],[-74.059104,40.86962]]]},"properties":{"id":"07608","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.965253,41.011672],[-73.925991,40.9936],[-73.92208,41.005897],[-73.959047,41.022542],[-73.965253,41.011672]]]},"properties":{"id":"07647","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.941681,40.925898],[-73.919898,40.913604],[-73.92208,41.005897],[-73.925991,40.9936],[-73.934346,40.976957],[-73.944962,40.957477],[-73.942473,40.942901],[-73.941681,40.925898]]]},"properties":{"id":"07620","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.016544,40.914606],[-73.990651,40.911281],[-73.979782,40.934098],[-73.979705,40.936636],[-74.010459,40.934896],[-74.016544,40.914606]]]},"properties":{"id":"07621","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.048967,40.965291],[-74.038844,40.943689],[-74.026115,40.940142],[-74.002583,40.952098],[-74.003159,40.955121],[-74.016399,40.968335],[-74.048967,40.965291]]]},"properties":{"id":"07649","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.064149,40.88681],[-74.05056,40.910578],[-74.068909,40.908532],[-74.072284,40.895109],[-74.064149,40.88681]]]},"properties":{"id":"07607","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.069675,41.041541],[-74.021468,41.037736],[-74.012583,41.046396],[-74.071915,41.072883],[-74.079369,41.048492],[-74.069675,41.041541]]]},"properties":{"id":"07645","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.201773,41.043778],[-74.248939,41.06382],[-74.250595,41.060676],[-74.270521,41.022131],[-74.266713,41.014063],[-74.24847,40.992303],[-74.201773,41.043778]]]},"properties":{"id":"07436","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.979782,40.934098],[-73.941681,40.925898],[-73.942473,40.942901],[-73.975696,40.953083],[-73.979705,40.936636],[-73.979782,40.934098]]]},"properties":{"id":"07626","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.141048,40.966348],[-74.146429,40.948441],[-74.099983,40.955272],[-74.141048,40.966348]]]},"properties":{"id":"07452","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.013625,40.822909],[-73.998,40.80847],[-73.9962,40.824465],[-74.013625,40.822909]]]},"properties":{"id":"07022","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.529336,39.86848],[-74.596975,39.899219],[-74.715513,39.96892],[-74.71698,39.976228],[-74.750103,39.978343],[-74.787087,39.932718],[-74.812471,39.785269],[-74.60623,39.785256],[-74.529336,39.86848]]]},"properties":{"id":"08088","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.966743,39.93772],[-74.882226,40.000745],[-74.904577,40.013644],[-74.958302,39.991812],[-75.005502,39.972982],[-74.966743,39.93772]]]},"properties":{"id":"08057","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.802352,39.987747],[-74.750103,39.978343],[-74.71698,39.976228],[-74.710356,39.976415],[-74.71823,40.00011],[-74.721272,40.022225],[-74.731206,40.025265],[-74.74259,40.022642],[-74.785889,40.037794],[-74.855651,40.031994],[-74.869978,40.012327],[-74.867901,40.008504],[-74.872424,39.997706],[-74.859488,39.9964],[-74.802352,39.987747]]]},"properties":{"id":"08060","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.71698,39.976228],[-74.715513,39.96892],[-74.710356,39.976415],[-74.71698,39.976228]]]},"properties":{"id":"08011","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.982744,39.940373],[-75.022074,39.903338],[-75.016593,39.873617],[-74.965775,39.920615],[-74.983717,39.92694],[-74.982744,39.940373]]]},"properties":{"id":"08034","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.889811,39.737482],[-74.895905,39.715251],[-74.754184,39.714357],[-74.839774,39.748728],[-74.889811,39.737482]]]},"properties":{"id":"08089","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.907959,39.78376],[-74.889811,39.737482],[-74.839774,39.748728],[-74.827558,39.788737],[-74.862857,39.785334],[-74.889757,39.781981],[-74.907959,39.78376]]]},"properties":{"id":"08004","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.108105,39.877995],[-75.082147,39.8724],[-75.080122,39.881666],[-75.093842,39.899609],[-75.094759,39.901483],[-75.101019,39.898664],[-75.108105,39.877995]]]},"properties":{"id":"08059","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.76642,39.074104],[-74.820816,39.054335],[-74.785789,39.013867],[-74.742607,39.060097],[-74.76642,39.074104]]]},"properties":{"id":"08247","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.968177,39.239755],[-75.030807,39.2131],[-74.976988,39.192403],[-74.968177,39.239755]]]},"properties":{"id":"08324","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.298346,40.83042],[-74.276321,40.813877],[-74.264305,40.825431],[-74.259805,40.834876],[-74.298346,40.83042]]]},"properties":{"id":"07021","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.367278,40.761067],[-74.363072,40.750403],[-74.371923,40.739229],[-74.332088,40.720176],[-74.318609,40.7192],[-74.308199,40.729965],[-74.295759,40.757539],[-74.305049,40.757881],[-74.367278,40.761067]]]},"properties":{"id":"07078","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.243722,40.730831],[-74.212065,40.742364],[-74.206172,40.74957],[-74.234841,40.7507],[-74.251361,40.737534],[-74.243722,40.730831]]]},"properties":{"id":"07106","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.135692,39.809479],[-75.1293,39.808079],[-75.091415,39.792444],[-75.085211,39.83685],[-75.083855,39.840502],[-75.099536,39.854472],[-75.16513,39.855487],[-75.185096,39.833323],[-75.180841,39.829362],[-75.177366,39.809153],[-75.16879,39.803097],[-75.16572,39.811029],[-75.135692,39.809479]]]},"properties":{"id":"08096","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.177366,39.809153],[-75.188045,39.799818],[-75.197475,39.788031],[-75.197789,39.785685],[-75.159918,39.789577],[-75.16879,39.803097],[-75.177366,39.809153]]]},"properties":{"id":"08051","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.169337,39.708468],[-75.158035,39.71354],[-75.166293,39.722482],[-75.169337,39.708468]]]},"properties":{"id":"08074","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.148266,40.779876],[-74.164799,40.754718],[-74.137492,40.741398],[-74.120987,40.722901],[-74.108154,40.715963],[-74.099086,40.726101],[-74.098539,40.727282],[-74.078686,40.750601],[-74.090694,40.762114],[-74.110887,40.7768],[-74.147529,40.786491],[-74.148266,40.779876]]]},"properties":{"id":"07032","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.060685,40.8057],[-74.081794,40.787994],[-74.083351,40.786316],[-74.090694,40.762114],[-74.078686,40.750601],[-74.075751,40.752378],[-74.056895,40.768867],[-74.044977,40.807702],[-74.060685,40.8057]]]},"properties":{"id":"07094","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.721707,40.685341],[-74.744802,40.697551],[-74.752798,40.683708],[-74.749243,40.672701],[-74.728903,40.660711],[-74.721707,40.685341]]]},"properties":{"id":"08858","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.856508,40.277407],[-74.819507,40.238508],[-74.800116,40.289748],[-74.811177,40.287563],[-74.856508,40.277407]]]},"properties":{"id":"08628","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.488669,40.216393],[-74.542912,40.216653],[-74.579053,40.240214],[-74.59473,40.250282],[-74.640279,40.244424],[-74.670079,40.197039],[-74.675767,40.195512],[-74.609642,40.174178],[-74.47202,40.19113],[-74.476996,40.211982],[-74.488669,40.216393]]]},"properties":{"id":"08691","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.268194,40.390998],[-74.208301,40.381761],[-74.209053,40.405025],[-74.211852,40.422612],[-74.230111,40.428193],[-74.247371,40.431459],[-74.270838,40.451038],[-74.27708,40.444829],[-74.293733,40.427564],[-74.268194,40.390998]]]},"properties":{"id":"07747","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.296922,40.454362],[-74.293733,40.427564],[-74.27708,40.444829],[-74.296922,40.454362]]],[[[-74.296171,40.471082],[-74.270838,40.451038],[-74.247371,40.431459],[-74.242638,40.441633],[-74.236885,40.457601],[-74.269921,40.494999],[-74.292226,40.508182],[-74.300832,40.509005],[-74.296171,40.471082]]]]},"properties":{"id":"08879","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.294412,40.57482],[-74.290861,40.592007],[-74.303392,40.608903],[-74.338493,40.605503],[-74.337175,40.585776],[-74.294412,40.57482]]]},"properties":{"id":"07067","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.306564,40.547604],[-74.300318,40.533385],[-74.267446,40.544293],[-74.267596,40.555461],[-74.266902,40.571016],[-74.292849,40.571552],[-74.311484,40.555624],[-74.306564,40.547604]]]},"properties":{"id":"07095","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.267596,40.555461],[-74.267446,40.544293],[-74.250205,40.539629],[-74.231693,40.558457],[-74.267596,40.555461]]]},"properties":{"id":"07077","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.269921,40.494999],[-74.250205,40.539629],[-74.267446,40.544293],[-74.300318,40.533385],[-74.301087,40.52302],[-74.292226,40.508182],[-74.269921,40.494999]]]},"properties":{"id":"08861","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.516867,40.359925],[-74.482128,40.346136],[-74.505668,40.391585],[-74.516867,40.359925]]]},"properties":{"id":"08810","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.323097,40.441445],[-74.296171,40.471082],[-74.300832,40.509005],[-74.310699,40.505592],[-74.316653,40.501883],[-74.366569,40.471177],[-74.375652,40.461097],[-74.356333,40.429593],[-74.358981,40.421576],[-74.323097,40.441445]]]},"properties":{"id":"08872","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.068231,40.133107],[-74.02828,40.117415],[-74.02148,40.137514],[-74.058608,40.146411],[-74.068231,40.133107]]]},"properties":{"id":"08750","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.148336,40.442236],[-74.135956,40.426786],[-74.133206,40.426617],[-74.116886,40.446107],[-74.15556,40.452337],[-74.148336,40.442236]]]},"properties":{"id":"07734","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.476996,40.211982],[-74.47202,40.19113],[-74.471477,40.188793],[-74.442336,40.147423],[-74.406777,40.172355],[-74.353526,40.208899],[-74.370457,40.233007],[-74.46407,40.209161],[-74.476996,40.211982]]]},"properties":{"id":"08510","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.148336,40.442236],[-74.211852,40.422612],[-74.209053,40.405025],[-74.135956,40.426786],[-74.148336,40.442236]]]},"properties":{"id":"07730","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.040042,40.305715],[-74.032545,40.298748],[-74.012545,40.304855],[-73.997233,40.329783],[-74.002658,40.334039],[-74.032923,40.322618],[-74.040042,40.305715]]]},"properties":{"id":"07757","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.636163,40.942639],[-74.61539,40.930334],[-74.585754,40.986819],[-74.584198,41.01709],[-74.614808,40.990284],[-74.641424,40.961365],[-74.636163,40.942639]]]},"properties":{"id":"07849","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.278062,40.95532],[-74.313359,40.951932],[-74.282134,40.936204],[-74.278062,40.95532]]]},"properties":{"id":"07440","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.801768,40.921657],[-74.843362,40.919097],[-74.887278,40.88024],[-74.884513,40.870698],[-74.896541,40.871402],[-74.895533,40.851298],[-74.887783,40.81611],[-74.863313,40.803342],[-74.817563,40.797744],[-74.826661,40.807584],[-74.816124,40.806782],[-74.768539,40.839296],[-74.741095,40.848938],[-74.806827,40.882127],[-74.801768,40.921657]]]},"properties":{"id":"07840","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.57818,40.877195],[-74.524895,40.880551],[-74.567892,40.904219],[-74.578201,40.885996],[-74.57818,40.877195]]],[[[-74.543908,40.96843],[-74.583991,40.934015],[-74.577755,40.914414],[-74.515772,40.954162],[-74.531543,40.960915],[-74.543908,40.96843]]]]},"properties":{"id":"07801","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.605993,40.889112],[-74.623369,40.867392],[-74.624862,40.864607],[-74.57818,40.877195],[-74.578201,40.885996],[-74.605993,40.889112]]]},"properties":{"id":"07803","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.478338,40.708773],[-74.513699,40.697701],[-74.523627,40.649702],[-74.503862,40.659627],[-74.484598,40.696521],[-74.478338,40.708773]]]},"properties":{"id":"07946","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.484598,40.696521],[-74.503862,40.659627],[-74.482306,40.666038],[-74.484598,40.696521]]]},"properties":{"id":"07980","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.138968,39.933703],[-74.147201,39.923113],[-74.123984,39.922968],[-74.124121,39.934756],[-74.138968,39.933703]]]},"properties":{"id":"08740","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.167322,40.895238],[-74.143391,40.859402],[-74.117804,40.876812],[-74.130187,40.886899],[-74.129233,40.894081],[-74.167322,40.895238]]]},"properties":{"id":"07011","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.290135,41.015525],[-74.302993,41.011397],[-74.300327,41.002743],[-74.297281,40.983576],[-74.281276,40.979953],[-74.266713,41.014063],[-74.270521,41.022131],[-74.290135,41.015525]]]},"properties":{"id":"07442","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.322386,41.03843],[-74.302993,41.011397],[-74.290135,41.015525],[-74.294761,41.051544],[-74.300255,41.051792],[-74.322386,41.03843]]]},"properties":{"id":"07420","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.685226,40.517336],[-74.684861,40.522823],[-74.691951,40.521986],[-74.692947,40.51787],[-74.685226,40.517336]]]},"properties":{"id":"08821","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.686727,41.037134],[-74.720408,41.112296],[-74.746949,41.119559],[-74.849977,41.145861],[-74.938971,41.068729],[-74.873612,40.970258],[-74.873381,40.96668],[-74.687912,41.020195],[-74.686727,41.037134]]]},"properties":{"id":"07860","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.638551,41.118611],[-74.686727,41.037134],[-74.687912,41.020195],[-74.614808,40.990284],[-74.584198,41.01709],[-74.536002,41.058497],[-74.577004,41.078996],[-74.598549,41.061171],[-74.615605,41.071097],[-74.602269,41.092905],[-74.638551,41.118611]]]},"properties":{"id":"07871","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.720408,41.112296],[-74.660801,41.166128],[-74.667272,41.172585],[-74.746949,41.119559],[-74.720408,41.112296]]]},"properties":{"id":"07822","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.392757,40.718046],[-74.381404,40.688332],[-74.37945,40.685397],[-74.36039,40.699821],[-74.332088,40.720176],[-74.371923,40.739229],[-74.392757,40.718046]]]},"properties":{"id":"07901","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.273626,40.718628],[-74.285299,40.722055],[-74.29869,40.71479],[-74.297829,40.713991],[-74.273626,40.718628]]]},"properties":{"id":"07088","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.95725,39.610331],[-74.888029,39.564787],[-74.873444,39.564597],[-74.861151,39.586053],[-74.930052,39.688723],[-75.023874,39.718564],[-75.082175,39.708496],[-75.069111,39.678942],[-75.031768,39.662635],[-74.95725,39.610331]]]},"properties":{"id":"08094","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.54606,39.346515],[-74.541137,39.333373],[-74.513963,39.337642],[-74.506431,39.344381],[-74.502382,39.356318],[-74.550464,39.386531],[-74.580013,39.368206],[-74.54606,39.346515]]]},"properties":{"id":"08225","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.087519,39.586947],[-75.100154,39.601085],[-75.131738,39.603308],[-75.087519,39.586947]]],[[[-74.906182,39.546356],[-74.888029,39.564787],[-74.95725,39.610331],[-75.047717,39.578039],[-75.061973,39.568643],[-75.072264,39.524335],[-74.953027,39.543061],[-74.926832,39.550092],[-74.906182,39.546356]]]]},"properties":{"id":"08344","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.485085,39.322971],[-74.506431,39.344381],[-74.513963,39.337642],[-74.515843,39.317071],[-74.485085,39.322971]]]},"properties":{"id":"08402","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.941681,40.925898],[-73.979782,40.934098],[-73.990651,40.911281],[-73.989286,40.911479],[-73.949229,40.900817],[-73.927119,40.895496],[-73.919898,40.913604],[-73.941681,40.925898]]]},"properties":{"id":"07670","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.124176,40.822629],[-74.083351,40.786316],[-74.081794,40.787994],[-74.121404,40.847184],[-74.121853,40.834098],[-74.124176,40.822629]]]},"properties":{"id":"07070","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.997936,40.981505],[-74.055286,40.976604],[-74.055184,40.969797],[-74.048967,40.965291],[-74.016399,40.968335],[-74.001844,40.970789],[-73.993681,40.975427],[-73.997936,40.981505]]]},"properties":{"id":"07630","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.993933,40.876539],[-73.97569,40.868903],[-73.966833,40.868573],[-73.949229,40.900817],[-73.989286,40.911479],[-73.993933,40.876539]]]},"properties":{"id":"07631","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.039312,40.830861],[-74.053074,40.833653],[-74.034984,40.8253],[-74.035384,40.8288],[-74.039312,40.830861]]],[[[-74.038284,40.858999],[-74.058162,40.870841],[-74.051677,40.857692],[-74.038284,40.858999]]]]},"properties":{"id":"07606","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.097846,40.861458],[-74.107338,40.863077],[-74.119462,40.85335],[-74.10277,40.841478],[-74.098923,40.850412],[-74.097846,40.861458]]]},"properties":{"id":"07057","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.132409,40.90406],[-74.133552,40.901949],[-74.129233,40.894081],[-74.130187,40.886899],[-74.104182,40.893664],[-74.113374,40.917579],[-74.131926,40.922168],[-74.130243,40.918119],[-74.132409,40.90406]]]},"properties":{"id":"07407","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.963488,40.984273],[-73.993681,40.975427],[-74.001844,40.970789],[-73.974682,40.967598],[-73.944962,40.957477],[-73.934346,40.976957],[-73.963488,40.984273]]]},"properties":{"id":"07624","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.068385,40.875999],[-74.08761,40.861442],[-74.075122,40.848628],[-74.072779,40.847395],[-74.059104,40.86962],[-74.068385,40.875999]]]},"properties":{"id":"07604","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.949229,40.900817],[-73.966833,40.868573],[-73.948385,40.858471],[-73.927119,40.895496],[-73.949229,40.900817]]]},"properties":{"id":"07632","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.983717,39.92694],[-74.965775,39.920615],[-74.957223,39.91428],[-74.862474,39.919828],[-74.862313,39.931103],[-74.862434,39.943083],[-74.859488,39.9964],[-74.872424,39.997706],[-74.882226,40.000745],[-74.966743,39.93772],[-74.983717,39.92694]]]},"properties":{"id":"08054","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.750103,39.978343],[-74.802352,39.987747],[-74.862434,39.943083],[-74.862313,39.931103],[-74.787087,39.932718],[-74.750103,39.978343]]]},"properties":{"id":"08048","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.670743,40.017521],[-74.721272,40.022225],[-74.71823,40.00011],[-74.669498,40.004552],[-74.670743,40.017521]]],[[[-74.698932,40.117971],[-74.74259,40.022642],[-74.731206,40.025265],[-74.653184,40.064768],[-74.644072,40.063213],[-74.698932,40.117971]]]]},"properties":{"id":"08022","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.609642,40.174178],[-74.600267,40.136997],[-74.611675,40.10276],[-74.553105,40.07913],[-74.540807,40.08432],[-74.471477,40.188793],[-74.47202,40.19113],[-74.609642,40.174178]]]},"properties":{"id":"08501","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.932645,40.068785],[-74.920727,40.023739],[-74.893415,40.053088],[-74.898776,40.073325],[-74.932645,40.068785]]]},"properties":{"id":"08010","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.682235,40.160628],[-74.600267,40.136997],[-74.609642,40.174178],[-74.675767,40.195512],[-74.702113,40.176087],[-74.682235,40.160628]]]},"properties":{"id":"08620","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.907959,39.78376],[-74.889757,39.781981],[-74.902546,39.790797],[-74.907959,39.78376]]],[[[-74.907959,39.78376],[-74.956989,39.809175],[-74.975918,39.773426],[-74.896513,39.710375],[-74.895905,39.715251],[-74.889811,39.737482],[-74.907959,39.78376]]]]},"properties":{"id":"08009","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.103191,39.856328],[-75.064216,39.859913],[-75.067716,39.872113],[-75.082147,39.8724],[-75.108105,39.877995],[-75.120635,39.87261],[-75.103191,39.856328]]]},"properties":{"id":"08031","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.135436,39.949404],[-75.106979,39.943984],[-75.104472,39.94398],[-75.108519,39.958112],[-75.123821,39.962744],[-75.135436,39.949404]]]},"properties":{"id":"08102","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.067088,39.948462],[-75.104472,39.94398],[-75.106979,39.943984],[-75.083197,39.925099],[-75.066171,39.925876],[-75.01345,39.954188],[-75.019212,39.967462],[-75.067088,39.948462]]]},"properties":{"id":"08109","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.089929,39.921822],[-75.065197,39.901425],[-75.062879,39.897952],[-75.04223,39.91815],[-75.066171,39.925876],[-75.083197,39.925099],[-75.089906,39.921833],[-75.089929,39.921822]]]},"properties":{"id":"08108","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.089929,39.921822],[-75.094759,39.901483],[-75.093842,39.899609],[-75.065197,39.901425],[-75.089929,39.921822]]]},"properties":{"id":"08107","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.048488,39.875294],[-75.041275,39.8737],[-75.018663,39.872292],[-75.016593,39.873617],[-75.022074,39.903338],[-75.04223,39.91815],[-75.062879,39.897952],[-75.051473,39.889879],[-75.048488,39.875294]]]},"properties":{"id":"08033","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.844583,39.648839],[-74.865048,39.661663],[-74.87269,39.649614],[-74.851993,39.63024],[-74.844583,39.648839]]]},"properties":{"id":"08095","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.785789,39.013867],[-74.820816,39.054335],[-74.84994,39.030706],[-74.872607,39.005406],[-74.86992,38.938824],[-74.785789,39.013867]]]},"properties":{"id":"08260","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.673548,39.183567],[-74.718021,39.124215],[-74.704845,39.117486],[-74.665899,39.179692],[-74.673548,39.183567]]]},"properties":{"id":"08243","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.095667,39.346356],[-74.995091,39.289263],[-74.991343,39.28584],[-74.966807,39.290831],[-74.971217,39.299914],[-74.98808,39.311395],[-74.958281,39.315757],[-74.85349,39.372178],[-74.852062,39.380829],[-74.857991,39.396402],[-74.908103,39.416074],[-75.024903,39.423244],[-75.084014,39.457372],[-75.102822,39.470376],[-75.126964,39.467728],[-75.128585,39.467324],[-75.168899,39.372962],[-75.145488,39.324679],[-75.095667,39.346356]]]},"properties":{"id":"08332","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.243713,39.283464],[-75.178442,39.242739],[-75.112683,39.23891],[-75.095667,39.346356],[-75.145488,39.324679],[-75.243713,39.283464]]]},"properties":{"id":"08345","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.024903,39.423244],[-74.908103,39.416074],[-74.896626,39.452953],[-74.972075,39.505469],[-74.97436,39.507065],[-75.024903,39.423244]]]},"properties":{"id":"08361","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.189989,40.7686],[-74.197589,40.7562],[-74.202332,40.750696],[-74.184667,40.750817],[-74.17476,40.785607],[-74.186253,40.774661],[-74.189989,40.7686]]]},"properties":{"id":"07107","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.22099,40.834799],[-74.251892,40.849829],[-74.259805,40.834876],[-74.264305,40.825431],[-74.232573,40.814863],[-74.22099,40.834799]]]},"properties":{"id":"07044","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.228571,40.70829],[-74.21241,40.69926],[-74.191466,40.712415],[-74.218172,40.721254],[-74.228571,40.70829]]]},"properties":{"id":"07112","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.298346,40.83042],[-74.329172,40.838299],[-74.334342,40.818416],[-74.280592,40.807],[-74.276321,40.813877],[-74.298346,40.83042]]]},"properties":{"id":"07068","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.246685,39.625038],[-75.131738,39.603308],[-75.100154,39.601085],[-75.099024,39.640906],[-75.120892,39.662039],[-75.176789,39.686279],[-75.230956,39.662361],[-75.246685,39.625038]]]},"properties":{"id":"08343","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.120892,39.662039],[-75.099024,39.640906],[-75.031768,39.662635],[-75.069111,39.678942],[-75.120892,39.662039]]]},"properties":{"id":"08312","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.266613,39.682184],[-75.264734,39.698862],[-75.286921,39.680258],[-75.266613,39.682184]]]},"properties":{"id":"08039","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.0472,40.735341],[-74.075751,40.752378],[-74.078686,40.750601],[-74.098539,40.727282],[-74.064193,40.723768],[-74.051624,40.731125],[-74.0472,40.735341]]]},"properties":{"id":"07306","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.033065,40.721378],[-74.032194,40.71674],[-74.031601,40.721247],[-74.033065,40.721378]]]},"properties":{"id":"07311","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.843909,40.758212],[-74.877724,40.684294],[-74.851217,40.690881],[-74.752798,40.683708],[-74.744802,40.697551],[-74.732456,40.719928],[-74.843909,40.758212]]]},"properties":{"id":"07830","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.944808,40.677773],[-74.910617,40.67626],[-74.890652,40.68075],[-74.877724,40.684294],[-74.843909,40.758212],[-74.848398,40.761331],[-74.911653,40.767063],[-74.951498,40.727654],[-74.944808,40.677773]]]},"properties":{"id":"08826","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.911653,40.767063],[-74.848398,40.761331],[-74.863313,40.803342],[-74.887783,40.81611],[-74.942062,40.769798],[-74.911653,40.767063]]]},"properties":{"id":"07865","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.76247,40.451117],[-74.847278,40.387175],[-74.824682,40.363288],[-74.751641,40.364152],[-74.736995,40.370155],[-74.752023,40.43138],[-74.76247,40.451117]]]},"properties":{"id":"08525","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.009158,40.621625],[-75.015096,40.602541],[-74.948673,40.634174],[-74.944808,40.677773],[-74.951498,40.727654],[-74.997253,40.727853],[-75.009158,40.621625]]]},"properties":{"id":"08827","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.009158,40.621625],[-74.997253,40.727853],[-75.068542,40.709703],[-75.062338,40.68266],[-75.070091,40.629254],[-75.009158,40.621625]]]},"properties":{"id":"08802","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.890652,40.68075],[-74.910617,40.67626],[-74.89132,40.656501],[-74.890652,40.68075]]]},"properties":{"id":"08829","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.756647,40.228058],[-74.757994,40.220799],[-74.742936,40.217034],[-74.722702,40.230883],[-74.728837,40.23754],[-74.756647,40.228058]]]},"properties":{"id":"08609","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.59473,40.250282],[-74.579053,40.240214],[-74.585488,40.259947],[-74.59473,40.250282]]]},"properties":{"id":"08561","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.508979,40.282755],[-74.484287,40.253299],[-74.408085,40.273791],[-74.331678,40.34121],[-74.381618,40.382923],[-74.409816,40.379437],[-74.425692,40.3847],[-74.464958,40.410127],[-74.498775,40.397891],[-74.505668,40.391585],[-74.482128,40.346136],[-74.508979,40.282755]]]},"properties":{"id":"08831","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.469653,40.585552],[-74.519503,40.555324],[-74.515782,40.544955],[-74.467181,40.508425],[-74.446986,40.506009],[-74.439947,40.514464],[-74.408682,40.543835],[-74.437334,40.597188],[-74.455865,40.595502],[-74.469653,40.585552]]]},"properties":{"id":"08854","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.385028,40.462661],[-74.356333,40.429593],[-74.375652,40.461097],[-74.385028,40.462661]]]},"properties":{"id":"08882","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.415951,40.492712],[-74.439947,40.514464],[-74.446986,40.506009],[-74.415951,40.492712]]]},"properties":{"id":"08904","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.292226,40.508182],[-74.301087,40.52302],[-74.317759,40.526935],[-74.329537,40.51556],[-74.310699,40.505592],[-74.300832,40.509005],[-74.292226,40.508182]]]},"properties":{"id":"08832","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.15556,40.452337],[-74.236885,40.457601],[-74.242638,40.441633],[-74.230111,40.428193],[-74.211852,40.422612],[-74.148336,40.442236],[-74.15556,40.452337]]]},"properties":{"id":"07735","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.437334,40.597188],[-74.408682,40.543835],[-74.40448,40.54972],[-74.394634,40.600901],[-74.424699,40.598513],[-74.437334,40.597188]]]},"properties":{"id":"07080","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.096869,40.42244],[-74.082985,40.406508],[-74.068,40.428265],[-74.081866,40.434609],[-74.096869,40.42244]]]},"properties":{"id":"07718","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.116886,40.446107],[-74.133206,40.426617],[-74.148892,40.365535],[-74.111469,40.344309],[-74.068842,40.386098],[-74.078847,40.398978],[-74.082985,40.406508],[-74.096869,40.42244],[-74.109085,40.443207],[-74.116886,40.446107]]]},"properties":{"id":"07748","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.043385,40.273422],[-74.106018,40.274773],[-74.110838,40.252683],[-74.013947,40.214341],[-73.998481,40.216812],[-73.994705,40.230067],[-74.01764,40.249613],[-74.019689,40.253485],[-74.043385,40.273422]]]},"properties":{"id":"07712","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.0002,40.26345],[-74.019689,40.253485],[-74.01764,40.249613],[-73.992881,40.237266],[-73.986681,40.260211],[-74.0002,40.26345]]]},"properties":{"id":"07723","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.01764,40.249613],[-73.994705,40.230067],[-73.992881,40.237266],[-74.01764,40.249613]]]},"properties":{"id":"07711","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.296999,40.322167],[-74.290227,40.293699],[-74.222916,40.277376],[-74.197556,40.345363],[-74.20062,40.347117],[-74.296999,40.322167]]]},"properties":{"id":"07746","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.646186,40.874059],[-74.623369,40.867392],[-74.605993,40.889112],[-74.63356,40.894631],[-74.638022,40.893172],[-74.646186,40.874059]]]},"properties":{"id":"07847","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.71278,40.892148],[-74.741095,40.848938],[-74.768539,40.839296],[-74.724439,40.817848],[-74.654662,40.824019],[-74.651429,40.840085],[-74.662598,40.864626],[-74.6908,40.890096],[-74.71278,40.892148]]]},"properties":{"id":"07836","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.585754,40.986819],[-74.541211,40.970557],[-74.46471,41.077748],[-74.536002,41.058497],[-74.584198,41.01709],[-74.585754,40.986819]]]},"properties":{"id":"07438","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.278062,40.95532],[-74.281276,40.979953],[-74.297281,40.983576],[-74.331401,40.988262],[-74.330459,40.966053],[-74.313359,40.951932],[-74.278062,40.95532]]]},"properties":{"id":"07444","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.458649,40.739575],[-74.449736,40.718831],[-74.438198,40.752308],[-74.438505,40.753384],[-74.458649,40.739575]]]},"properties":{"id":"07935","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.597843,40.697474],[-74.630869,40.667574],[-74.633147,40.642467],[-74.584277,40.624537],[-74.563146,40.610738],[-74.523627,40.649702],[-74.513699,40.697701],[-74.510472,40.734665],[-74.548999,40.740994],[-74.597843,40.697474]],[[-74.555669,40.672482],[-74.545787,40.665603],[-74.560919,40.661738],[-74.555669,40.672482]]]},"properties":{"id":"07920","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.087339,39.959555],[-74.062924,39.959141],[-74.05381,40.000332],[-74.068605,40.003583],[-74.087339,39.959555]]]},"properties":{"id":"08735","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.040105,40.058062],[-74.051573,40.059015],[-74.086735,40.008035],[-74.068605,40.003583],[-74.05381,40.000332],[-74.040105,40.058062]]]},"properties":{"id":"08738","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.2115,39.912444],[-74.182418,39.934578],[-74.190077,39.945668],[-74.2115,39.912444]]]},"properties":{"id":"08722","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.352516,39.70632],[-74.278757,39.603538],[-74.217891,39.647234],[-74.343048,39.707082],[-74.352516,39.70632]]]},"properties":{"id":"08092","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.143391,40.859402],[-74.167322,40.895238],[-74.169788,40.896584],[-74.174588,40.899398],[-74.1935,40.865073],[-74.182689,40.848799],[-74.179403,40.846972],[-74.14611,40.85195],[-74.143391,40.859402]]]},"properties":{"id":"07013","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.198877,40.924748],[-74.195689,40.907398],[-74.183789,40.912198],[-74.193143,40.930375],[-74.198877,40.924748]]]},"properties":{"id":"07502","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.409478,39.660767],[-75.364247,39.564696],[-75.339135,39.558651],[-75.321718,39.561957],[-75.246685,39.625038],[-75.230956,39.662361],[-75.266613,39.682184],[-75.286921,39.680258],[-75.367293,39.70573],[-75.389367,39.681624],[-75.409478,39.660767]]]},"properties":{"id":"08098","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.484163,39.678901],[-75.509367,39.685317],[-75.512998,39.57845],[-75.476226,39.672747],[-75.484163,39.678901]]]},"properties":{"id":"08070","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.3898,40.622079],[-74.414729,40.641539],[-74.453212,40.619904],[-74.462913,40.608891],[-74.424699,40.598513],[-74.394634,40.600901],[-74.3898,40.622079]]]},"properties":{"id":"07060","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.571633,40.423908],[-74.531476,40.441383],[-74.581416,40.460396],[-74.571633,40.423908]]]},"properties":{"id":"08823","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.6245,41.161219],[-74.634186,41.132604],[-74.575475,41.101214],[-74.516991,41.155786],[-74.549498,41.195119],[-74.6245,41.161219]]]},"properties":{"id":"07419","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.849977,41.145861],[-74.746949,41.119559],[-74.667272,41.172585],[-74.713333,41.225197],[-74.82678,41.235962],[-74.867771,41.192904],[-74.849977,41.145861]]]},"properties":{"id":"07826","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.836027,41.278992],[-74.82678,41.235962],[-74.713333,41.225197],[-74.648979,41.336383],[-74.836027,41.278992]]]},"properties":{"id":"07827","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.233325,40.654464],[-74.200469,40.63263],[-74.187797,40.644604],[-74.202173,40.65763],[-74.215358,40.667633],[-74.244351,40.663509],[-74.233325,40.654464]]]},"properties":{"id":"07202","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.379359,40.667584],[-74.325635,40.625052],[-74.313192,40.638202],[-74.319678,40.641989],[-74.326593,40.657702],[-74.322371,40.677443],[-74.331358,40.682192],[-74.379359,40.667584]]]},"properties":{"id":"07090","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.244311,40.71119],[-74.222252,40.675048],[-74.209042,40.690428],[-74.21241,40.69926],[-74.228571,40.70829],[-74.244311,40.71119]]]},"properties":{"id":"07205","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.233325,40.654464],[-74.281488,40.650145],[-74.28982,40.639951],[-74.276918,40.62753],[-74.258114,40.60225],[-74.24779,40.600103],[-74.203688,40.592691],[-74.200469,40.63263],[-74.233325,40.654464]]]},"properties":{"id":"07036","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.843362,40.919097],[-74.801768,40.921657],[-74.781041,40.923835],[-74.840433,40.921315],[-74.843362,40.919097]]]},"properties":{"id":"07820","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.447422,39.626913],[-74.521748,39.661583],[-74.545046,39.675911],[-74.647214,39.624861],[-74.716645,39.576754],[-74.72006,39.57367],[-74.729407,39.565606],[-74.586322,39.466395],[-74.558834,39.464227],[-74.548894,39.477727],[-74.52927,39.501393],[-74.517427,39.509327],[-74.479558,39.55597],[-74.489657,39.613092],[-74.449659,39.611935],[-74.447422,39.626913]]]},"properties":{"id":"08215","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.525084,39.414413],[-74.550464,39.386531],[-74.502382,39.356318],[-74.501592,39.357255],[-74.488279,39.385885],[-74.525084,39.414413]]]},"properties":{"id":"08232","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.867851,39.473841],[-74.896626,39.452953],[-74.908103,39.416074],[-74.857991,39.396402],[-74.837742,39.424432],[-74.853604,39.472418],[-74.867851,39.473841]]]},"properties":{"id":"08340","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.863683,39.343985],[-74.89715,39.234773],[-74.914909,39.177723],[-74.896304,39.16204],[-74.762042,39.204699],[-74.683237,39.246764],[-74.640088,39.29599],[-74.717772,39.403964],[-74.754189,39.389234],[-74.863683,39.343985]]]},"properties":{"id":"08270","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.501592,39.357255],[-74.451466,39.339844],[-74.40413,39.365522],[-74.413825,39.397167],[-74.461033,39.415511],[-74.488279,39.385885],[-74.501592,39.357255]]]},"properties":{"id":"08401","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.52927,39.501393],[-74.548894,39.477727],[-74.528387,39.474523],[-74.52927,39.501393]]]},"properties":{"id":"08240","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.121404,40.847184],[-74.081794,40.787994],[-74.060685,40.8057],[-74.10277,40.841478],[-74.119462,40.85335],[-74.121404,40.847184]]]},"properties":{"id":"07073","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.997936,40.981505],[-73.993681,40.975427],[-73.963488,40.984273],[-73.968682,41.006789],[-73.997936,40.981505]]]},"properties":{"id":"07640","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.968682,41.006789],[-73.963488,40.984273],[-73.934346,40.976957],[-73.925991,40.9936],[-73.965253,41.011672],[-73.968682,41.006789]]]},"properties":{"id":"07648","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.012583,41.046396],[-74.021468,41.037736],[-74.020055,41.019815],[-74.046249,40.998521],[-74.055286,40.976604],[-73.997936,40.981505],[-73.968682,41.006789],[-73.965253,41.011672],[-73.959047,41.022542],[-74.012583,41.046396]]]},"properties":{"id":"07675","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.150305,41.040506],[-74.155604,41.02585],[-74.147648,41.022058],[-74.121264,41.019945],[-74.114553,41.043631],[-74.150305,41.040506]]]},"properties":{"id":"07401","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.122484,41.006097],[-74.079652,40.989891],[-74.077307,41.004475],[-74.093876,41.005263],[-74.122484,41.006097]]]},"properties":{"id":"07423","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.993933,40.876539],[-74.004683,40.859899],[-74.006059,40.85697],[-73.987985,40.850884],[-73.97569,40.868903],[-73.993933,40.876539]]]},"properties":{"id":"07605","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.068385,40.875999],[-74.064149,40.88681],[-74.072284,40.895109],[-74.078923,40.895068],[-74.091913,40.88662],[-74.093921,40.865096],[-74.08761,40.861442],[-74.068385,40.875999]]]},"properties":{"id":"07644","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.038284,40.858999],[-74.051677,40.857692],[-74.053981,40.853863],[-74.039312,40.830861],[-74.035384,40.8288],[-74.029887,40.846659],[-74.032581,40.858698],[-74.038284,40.858999]]]},"properties":{"id":"07643","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.022399,40.866152],[-74.034289,40.884637],[-74.035684,40.868999],[-74.022399,40.866152]]]},"properties":{"id":"07603","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975769,40.954119],[-73.975696,40.953083],[-73.942473,40.942901],[-73.944962,40.957477],[-73.974682,40.967598],[-73.975769,40.954119]]]},"properties":{"id":"07627","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.529336,39.86848],[-74.60623,39.785256],[-74.545046,39.675911],[-74.521748,39.661583],[-74.521747,39.871578],[-74.529336,39.86848]]]},"properties":{"id":"08019","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.908607,39.809486],[-74.902546,39.790797],[-74.889757,39.781981],[-74.862857,39.785334],[-74.862474,39.919828],[-74.957223,39.91428],[-74.926935,39.873839],[-74.908607,39.809486]]]},"properties":{"id":"08053","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.019533,39.989823],[-75.030583,40.016194],[-75.059913,39.991529],[-75.019533,39.989823]]]},"properties":{"id":"08065","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.019533,39.989823],[-75.019212,39.967462],[-75.005502,39.972982],[-74.958302,39.991812],[-74.990195,40.036343],[-75.030583,40.016194],[-75.019533,39.989823]]]},"properties":{"id":"08077","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.080122,39.881666],[-75.051473,39.889879],[-75.062879,39.897952],[-75.065197,39.901425],[-75.093842,39.899609],[-75.080122,39.881666]]]},"properties":{"id":"08106","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.985855,39.834095],[-74.957964,39.817611],[-74.964652,39.845701],[-74.985855,39.834095]]]},"properties":{"id":"08026","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.921868,39.004523],[-74.872607,39.005406],[-74.84994,39.030706],[-74.885915,39.031097],[-74.921714,39.033874],[-74.921868,39.004523]]]},"properties":{"id":"08242","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.76642,39.074104],[-74.742607,39.060097],[-74.704845,39.117486],[-74.718021,39.124215],[-74.738928,39.124236],[-74.76642,39.074104]]]},"properties":{"id":"08202","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.768608,39.17434],[-74.760021,39.185284],[-74.769178,39.186283],[-74.768608,39.17434]]]},"properties":{"id":"08246","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.131738,39.603308],[-75.246685,39.625038],[-75.321718,39.561957],[-75.102822,39.470376],[-75.084014,39.457372],[-75.072264,39.524335],[-75.061973,39.568643],[-75.063819,39.569968],[-75.087519,39.586947],[-75.131738,39.603308]]]},"properties":{"id":"08318","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.995091,39.289263],[-75.002285,39.263275],[-74.981514,39.271353],[-74.991343,39.28584],[-74.995091,39.289263]]]},"properties":{"id":"08329","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.112683,39.23891],[-75.178442,39.242739],[-75.108003,39.210487],[-75.112683,39.23891]]]},"properties":{"id":"08321","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.197589,40.7562],[-74.220968,40.768024],[-74.23813,40.752431],[-74.234841,40.7507],[-74.206172,40.74957],[-74.202332,40.750696],[-74.197589,40.7562]]]},"properties":{"id":"07018","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.17476,40.785607],[-74.148266,40.779876],[-74.147529,40.786491],[-74.139882,40.805282],[-74.139923,40.80555],[-74.175309,40.809489],[-74.186253,40.774661],[-74.17476,40.785607]]]},"properties":{"id":"07109","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.161669,40.735781],[-74.174889,40.723921],[-74.117267,40.697808],[-74.120987,40.722901],[-74.137492,40.741398],[-74.161669,40.735781]]]},"properties":{"id":"07105","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.174889,40.723921],[-74.184236,40.724266],[-74.191466,40.712415],[-74.21241,40.69926],[-74.209042,40.690428],[-74.201889,40.686702],[-74.136703,40.674444],[-74.117267,40.697808],[-74.174889,40.723921]]]},"properties":{"id":"07114","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.175309,40.809489],[-74.139923,40.80555],[-74.130031,40.819962],[-74.158502,40.835699],[-74.162628,40.837869],[-74.175309,40.809489]]]},"properties":{"id":"07110","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.251529,39.818486],[-75.28626,39.792795],[-75.264077,39.761781],[-75.212687,39.756937],[-75.216336,39.770925],[-75.251529,39.818486]]]},"properties":{"id":"08056","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.16879,39.803097],[-75.159918,39.789577],[-75.1293,39.808079],[-75.135692,39.809479],[-75.144564,39.802603],[-75.16572,39.811029],[-75.16879,39.803097]]]},"properties":{"id":"08090","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.064193,40.723768],[-74.054865,40.710648],[-74.03866,40.710175],[-74.032194,40.71674],[-74.033065,40.721378],[-74.051624,40.731125],[-74.064193,40.723768]]]},"properties":{"id":"07302","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.064193,40.723768],[-74.098539,40.727282],[-74.099086,40.726101],[-74.054865,40.710648],[-74.064193,40.723768]]]},"properties":{"id":"07304","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.033607,40.757433],[-74.014798,40.779711],[-74.023138,40.782873],[-74.04586,40.75737],[-74.03762,40.750614],[-74.033607,40.757433]]]},"properties":{"id":"07087","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.014798,40.779711],[-74.033607,40.757433],[-74.026222,40.757428],[-74.008323,40.778093],[-74.014798,40.779711]]]},"properties":{"id":"07086","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.051624,40.731125],[-74.033065,40.721378],[-74.031601,40.721247],[-74.030525,40.733683],[-74.042931,40.736251],[-74.0472,40.735341],[-74.051624,40.731125]]]},"properties":{"id":"07310","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.124454,40.656026],[-75.155778,40.620837],[-75.070091,40.629254],[-75.062338,40.68266],[-75.124454,40.656026]]]},"properties":{"id":"08804","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.608096,40.558356],[-74.625383,40.573897],[-74.626395,40.563153],[-74.608096,40.558356]]],[[[-74.726942,40.634077],[-74.743089,40.553257],[-74.69617,40.546714],[-74.687409,40.555354],[-74.667274,40.615956],[-74.726942,40.634077]]]]},"properties":{"id":"08876","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.585488,40.259947],[-74.567594,40.282532],[-74.567079,40.287521],[-74.572559,40.301054],[-74.588728,40.313724],[-74.613741,40.324185],[-74.670809,40.279383],[-74.664039,40.267171],[-74.640279,40.244424],[-74.59473,40.250282],[-74.585488,40.259947]]]},"properties":{"id":"08550","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.56226,40.284983],[-74.567079,40.287521],[-74.567594,40.282532],[-74.56226,40.284983]]],[[[-74.56226,40.284983],[-74.508979,40.282755],[-74.482128,40.346136],[-74.516867,40.359925],[-74.560603,40.358859],[-74.588728,40.313724],[-74.572559,40.301054],[-74.56226,40.284983]]]]},"properties":{"id":"08512","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.296922,40.454362],[-74.323097,40.441445],[-74.358981,40.421576],[-74.367854,40.406864],[-74.381618,40.382923],[-74.331678,40.34121],[-74.327404,40.344579],[-74.268194,40.390998],[-74.293733,40.427564],[-74.296922,40.454362]]]},"properties":{"id":"08857","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.310699,40.505592],[-74.329537,40.51556],[-74.316653,40.501883],[-74.310699,40.505592]]],[[[-74.317759,40.526935],[-74.301087,40.52302],[-74.300318,40.533385],[-74.306564,40.547604],[-74.317156,40.55273],[-74.328098,40.546667],[-74.317759,40.526935]]]]},"properties":{"id":"08863","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.344913,40.553083],[-74.370137,40.529881],[-74.371069,40.481387],[-74.366569,40.471177],[-74.316653,40.501883],[-74.329537,40.51556],[-74.317759,40.526935],[-74.328098,40.546667],[-74.330092,40.556678],[-74.332485,40.566433],[-74.344913,40.553083]]]},"properties":{"id":"08837","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.469653,40.585552],[-74.455865,40.595502],[-74.462913,40.608891],[-74.453212,40.619904],[-74.482432,40.621267],[-74.517733,40.587354],[-74.512183,40.582029],[-74.469653,40.585552]]]},"properties":{"id":"08812","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.330092,40.556678],[-74.328098,40.546667],[-74.317156,40.55273],[-74.330092,40.556678]]],[[[-74.370137,40.529881],[-74.344913,40.553083],[-74.381048,40.549749],[-74.370137,40.529881]]]]},"properties":{"id":"08840","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.519503,40.555324],[-74.469653,40.585552],[-74.512183,40.582029],[-74.527025,40.559601],[-74.519503,40.555324]]]},"properties":{"id":"08846","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.112961,40.320252],[-74.106018,40.274773],[-74.043385,40.273422],[-74.027625,40.272741],[-74.032545,40.298748],[-74.040042,40.305715],[-74.064438,40.311532],[-74.074386,40.333817],[-74.098871,40.324692],[-74.112961,40.320252]]]},"properties":{"id":"07724","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.290227,40.293699],[-74.353526,40.208899],[-74.406777,40.172355],[-74.263763,40.168264],[-74.239031,40.205088],[-74.194376,40.227503],[-74.222916,40.277376],[-74.290227,40.293699]]]},"properties":{"id":"07728","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.242957,40.115797],[-74.154404,40.099719],[-74.114467,40.138014],[-74.117188,40.143171],[-74.239031,40.205088],[-74.263763,40.168264],[-74.242957,40.115797]]]},"properties":{"id":"07731","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.194376,40.227503],[-74.239031,40.205088],[-74.117188,40.143171],[-74.106383,40.151221],[-74.111133,40.17865],[-74.125604,40.249303],[-74.194376,40.227503]]]},"properties":{"id":"07727","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.003181,40.208513],[-74.016023,40.208452],[-74.02133,40.197406],[-74.007481,40.195613],[-74.003181,40.208513]]]},"properties":{"id":"07720","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.978102,40.388916],[-74.045756,40.387221],[-74.033694,40.375656],[-74.037668,40.352242],[-74.003188,40.339947],[-73.971881,40.34581],[-73.974875,40.399487],[-73.978102,40.388916]]]},"properties":{"id":"07760","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.068842,40.386098],[-74.045756,40.387221],[-73.978102,40.388916],[-74.001401,40.411335],[-74.047266,40.419705],[-74.078847,40.398978],[-74.068842,40.386098]]]},"properties":{"id":"07716","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.074386,40.333817],[-74.064438,40.311532],[-74.040653,40.320494],[-74.063691,40.338267],[-74.074386,40.333817]]]},"properties":{"id":"07702","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.033694,40.375656],[-74.051136,40.347982],[-74.037668,40.352242],[-74.033694,40.375656]]]},"properties":{"id":"07704","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.986681,40.260211],[-73.974257,40.322409],[-73.997233,40.329783],[-74.012545,40.304855],[-74.009807,40.275389],[-74.0002,40.26345],[-73.986681,40.260211]]]},"properties":{"id":"07740","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.567892,40.904219],[-74.538669,40.930325],[-74.504122,40.951489],[-74.515772,40.954162],[-74.577755,40.914414],[-74.583991,40.934015],[-74.543908,40.96843],[-74.541211,40.970557],[-74.585754,40.986819],[-74.61539,40.930334],[-74.63356,40.894631],[-74.605993,40.889112],[-74.578201,40.885996],[-74.567892,40.904219]]]},"properties":{"id":"07885","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.618517,40.744137],[-74.548751,40.74601],[-74.57821,40.805158],[-74.578541,40.805908],[-74.62379,40.818481],[-74.637427,40.77286],[-74.618517,40.744137]]]},"properties":{"id":"07945","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.668017,40.694641],[-74.676335,40.705226],[-74.715093,40.713903],[-74.72065,40.687797],[-74.668017,40.694641]]],[[[-74.597843,40.697474],[-74.618517,40.744137],[-74.637427,40.77286],[-74.701037,40.725162],[-74.679406,40.71933],[-74.653273,40.718398],[-74.651143,40.696496],[-74.630869,40.667574],[-74.597843,40.697474]]]]},"properties":{"id":"07931","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.361668,40.867421],[-74.341957,40.846781],[-74.32991,40.849161],[-74.337474,40.867756],[-74.362203,40.870486],[-74.361668,40.867421]]]},"properties":{"id":"07058","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.6908,40.890096],[-74.662598,40.864626],[-74.646186,40.874059],[-74.638022,40.893172],[-74.657146,40.891685],[-74.685018,40.892452],[-74.6908,40.890096]]]},"properties":{"id":"07852","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.270702,40.89692],[-74.282134,40.936204],[-74.313359,40.951932],[-74.330459,40.966053],[-74.341063,40.946313],[-74.322804,40.908834],[-74.270702,40.89692]]]},"properties":{"id":"07035","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.138968,39.933703],[-74.156317,39.940352],[-74.147997,39.937046],[-74.138968,39.933703]]]},"properties":{"id":"08732","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.270651,39.987373],[-74.20732,39.949963],[-74.182158,40.032166],[-74.253088,40.058716],[-74.266067,40.055649],[-74.270651,39.987373]]]},"properties":{"id":"08755","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.242957,40.115797],[-74.253088,40.058716],[-74.182158,40.032166],[-74.158765,40.023834],[-74.155941,40.078191],[-74.154404,40.099719],[-74.242957,40.115797]]]},"properties":{"id":"08701","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.540807,40.08432],[-74.553105,40.07913],[-74.540585,40.057028],[-74.52863,40.03668],[-74.444524,40.049518],[-74.444959,40.109524],[-74.540807,40.08432]]]},"properties":{"id":"08533","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.154318,40.91671],[-74.156454,40.92541],[-74.166032,40.926261],[-74.183789,40.912198],[-74.195689,40.907398],[-74.174588,40.899398],[-74.169788,40.896584],[-74.158222,40.904074],[-74.154318,40.91671]],[[-74.167318,40.918116],[-74.167913,40.913097],[-74.173536,40.912077],[-74.175621,40.919118],[-74.167318,40.918116]]]},"properties":{"id":"07501","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.302993,41.011397],[-74.322386,41.03843],[-74.351283,41.04575],[-74.363009,41.013169],[-74.328411,41.001419],[-74.300327,41.002743],[-74.302993,41.011397]]]},"properties":{"id":"07403","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.367293,39.70573],[-75.450212,39.774761],[-75.466235,39.750795],[-75.389367,39.681624],[-75.367293,39.70573]]]},"properties":{"id":"08067","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.728903,40.660711],[-74.726942,40.634077],[-74.667274,40.615956],[-74.633147,40.642467],[-74.630869,40.667574],[-74.651143,40.696496],[-74.668017,40.694641],[-74.72065,40.687797],[-74.721707,40.685341],[-74.728903,40.660711]]]},"properties":{"id":"07921","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.715093,40.713903],[-74.676335,40.705226],[-74.679406,40.71933],[-74.701037,40.725162],[-74.720152,40.720857],[-74.715093,40.713903]]]},"properties":{"id":"07934","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.519731,40.5885],[-74.557502,40.579477],[-74.54279,40.555196],[-74.527025,40.559601],[-74.512183,40.582029],[-74.517733,40.587354],[-74.519731,40.5885]]]},"properties":{"id":"08805","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.598937,40.557642],[-74.58932,40.527916],[-74.582221,40.535036],[-74.578585,40.540781],[-74.566194,40.542879],[-74.598937,40.557642]]]},"properties":{"id":"08835","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.462913,40.608891],[-74.455865,40.595502],[-74.437334,40.597188],[-74.424699,40.598513],[-74.462913,40.608891]]]},"properties":{"id":"07063","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.448544,40.655415],[-74.482432,40.621267],[-74.453212,40.619904],[-74.414729,40.641539],[-74.408264,40.644185],[-74.403353,40.666537],[-74.448544,40.655415]]]},"properties":{"id":"07069","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.543759,40.554559],[-74.515782,40.544955],[-74.519503,40.555324],[-74.527025,40.559601],[-74.54279,40.555196],[-74.543759,40.554559]]]},"properties":{"id":"08880","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.713333,41.225197],[-74.667272,41.172585],[-74.660801,41.166128],[-74.6245,41.161219],[-74.549498,41.195119],[-74.507884,41.203017],[-74.503293,41.269078],[-74.648979,41.336383],[-74.713333,41.225197]]]},"properties":{"id":"07461","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.638551,41.118611],[-74.602269,41.092905],[-74.585763,41.083491],[-74.575475,41.101214],[-74.634186,41.132604],[-74.638551,41.118611]]]},"properties":{"id":"07416","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.420202,41.229591],[-74.492766,41.162811],[-74.432399,41.165795],[-74.400182,41.219676],[-74.420202,41.229591]]]},"properties":{"id":"07422","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.966923,41.09452],[-74.938971,41.068729],[-74.849977,41.145861],[-74.867771,41.192904],[-74.87148,41.202635],[-74.966923,41.09452]]]},"properties":{"id":"07881","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.46837,41.253105],[-74.503293,41.269078],[-74.507884,41.203017],[-74.46837,41.253105]]]},"properties":{"id":"07418","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.832907,39.56667],[-74.863437,39.541657],[-74.867185,39.516467],[-74.853604,39.472418],[-74.837742,39.424432],[-74.777632,39.405343],[-74.754189,39.389234],[-74.717772,39.403964],[-74.586322,39.466395],[-74.729407,39.565606],[-74.832907,39.56667]]]},"properties":{"id":"08330","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.908477,39.495066],[-74.867851,39.473841],[-74.853604,39.472418],[-74.867185,39.516467],[-74.908477,39.495066]]]},"properties":{"id":"08350","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.116381,41.07277],[-74.150305,41.040506],[-74.114553,41.043631],[-74.116381,41.07277]]]},"properties":{"id":"07446","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.089319,40.919644],[-74.078923,40.895068],[-74.072284,40.895109],[-74.068909,40.908532],[-74.089319,40.919644]]]},"properties":{"id":"07662","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.141048,40.966348],[-74.099983,40.955272],[-74.077766,40.978377],[-74.079652,40.989891],[-74.122484,41.006097],[-74.13087,41.006192],[-74.14121,40.982761],[-74.149088,40.971137],[-74.141048,40.966348]]]},"properties":{"id":"07450","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.026115,40.940142],[-74.030194,40.913327],[-74.016544,40.914606],[-74.010459,40.934896],[-74.002583,40.952098],[-74.026115,40.940142]]]},"properties":{"id":"07646","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.147529,40.786491],[-74.110887,40.7768],[-74.139882,40.805282],[-74.147529,40.786491]]]},"properties":{"id":"07031","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.137536,41.007495],[-74.14121,40.982761],[-74.13087,41.006192],[-74.137536,41.007495]]]},"properties":{"id":"07432","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.130031,40.819962],[-74.139923,40.80555],[-74.139882,40.805282],[-74.110887,40.7768],[-74.090694,40.762114],[-74.083351,40.786316],[-74.124176,40.822629],[-74.130031,40.819962]]]},"properties":{"id":"07071","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.003159,40.955121],[-73.975769,40.954119],[-73.974682,40.967598],[-74.001844,40.970789],[-74.016399,40.968335],[-74.003159,40.955121]]]},"properties":{"id":"07641","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.131926,40.922168],[-74.113374,40.917579],[-74.089319,40.919644],[-74.099983,40.955272],[-74.146429,40.948441],[-74.148059,40.94159],[-74.131926,40.922168]]]},"properties":{"id":"07410","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.862434,39.943083],[-74.802352,39.987747],[-74.859488,39.9964],[-74.862434,39.943083]]]},"properties":{"id":"08036","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.06001,39.83568],[-75.008019,39.779617],[-74.975918,39.773426],[-74.956989,39.809175],[-74.95691,39.816714],[-74.957964,39.817611],[-74.985855,39.834095],[-75.001577,39.836523],[-75.004953,39.834133],[-75.01508,39.818835],[-75.038942,39.829554],[-75.06001,39.83568]]]},"properties":{"id":"08021","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.106979,39.943984],[-75.135436,39.949404],[-75.134896,39.927351],[-75.089906,39.921833],[-75.083197,39.925099],[-75.106979,39.943984]]]},"properties":{"id":"08103","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.004953,39.834133],[-75.038942,39.829554],[-75.01508,39.818835],[-75.004953,39.834133]]]},"properties":{"id":"08084","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.085211,39.83685],[-75.053424,39.844414],[-75.051874,39.850082],[-75.083855,39.840502],[-75.085211,39.83685]]]},"properties":{"id":"08029","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.349171,39.534356],[-75.411242,39.420364],[-75.356954,39.344143],[-75.280996,39.29916],[-75.168899,39.372962],[-75.128585,39.467324],[-75.131266,39.478155],[-75.121933,39.480475],[-75.12038,39.47672],[-75.126313,39.47524],[-75.126964,39.467728],[-75.102822,39.470376],[-75.321718,39.561957],[-75.339135,39.558651],[-75.349171,39.534356]],[[-75.162253,39.389625],[-75.151762,39.382675],[-75.169855,39.38218],[-75.162253,39.389625]],[[-75.309311,39.45328],[-75.310564,39.46535],[-75.283776,39.464255],[-75.279931,39.454493],[-75.309311,39.45328]]]},"properties":{"id":"08302","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.958281,39.315757],[-74.98808,39.311395],[-74.971217,39.299914],[-74.958281,39.315757]]]},"properties":{"id":"08348","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.329172,40.838299],[-74.298346,40.83042],[-74.259805,40.834876],[-74.251892,40.849829],[-74.246691,40.880799],[-74.254476,40.883098],[-74.328535,40.844667],[-74.329172,40.838299]]]},"properties":{"id":"07006","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.219574,40.836049],[-74.204821,40.860915],[-74.246691,40.880799],[-74.251892,40.849829],[-74.22099,40.834799],[-74.219574,40.836049]]]},"properties":{"id":"07009","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.184345,40.727604],[-74.173566,40.747955],[-74.184667,40.750817],[-74.202332,40.750696],[-74.206172,40.74957],[-74.212065,40.742364],[-74.215042,40.729488],[-74.184345,40.727604]]]},"properties":{"id":"07103","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.199857,39.873222],[-75.182076,39.856783],[-75.171474,39.869006],[-75.189255,39.88066],[-75.199857,39.873222]]]},"properties":{"id":"08063","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.091415,39.792444],[-75.1293,39.808079],[-75.159918,39.789577],[-75.197789,39.785685],[-75.216336,39.770925],[-75.212687,39.756937],[-75.156732,39.725425],[-75.113842,39.73739],[-75.082175,39.708496],[-75.023874,39.718564],[-75.032519,39.726985],[-75.091415,39.792444]]]},"properties":{"id":"08080","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.16572,39.811029],[-75.144564,39.802603],[-75.135692,39.809479],[-75.16572,39.811029]]]},"properties":{"id":"08097","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.113842,39.73739],[-75.156732,39.725425],[-75.153105,39.723327],[-75.113842,39.73739]]]},"properties":{"id":"08071","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.227914,39.825931],[-75.236957,39.819836],[-75.251529,39.818486],[-75.216336,39.770925],[-75.197789,39.785685],[-75.197475,39.788031],[-75.227914,39.825931]]]},"properties":{"id":"08020","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.054865,40.710648],[-74.099086,40.726101],[-74.108154,40.715963],[-74.107974,40.69789],[-74.065931,40.666915],[-74.03866,40.710175],[-74.054865,40.710648]]]},"properties":{"id":"07305","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.075751,40.752378],[-74.0472,40.735341],[-74.042931,40.736251],[-74.03762,40.750614],[-74.04586,40.75737],[-74.056895,40.768867],[-74.075751,40.752378]]]},"properties":{"id":"07307","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.89132,40.656501],[-74.926067,40.626402],[-74.910718,40.574278],[-74.867073,40.575267],[-74.851217,40.690881],[-74.877724,40.684294],[-74.890652,40.68075],[-74.89132,40.656501]]]},"properties":{"id":"08801","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.722702,40.230883],[-74.742936,40.217034],[-74.737106,40.21],[-74.72007,40.223088],[-74.722702,40.230883]]]},"properties":{"id":"08629","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.778302,40.219714],[-74.758307,40.220752],[-74.757994,40.220799],[-74.756647,40.228058],[-74.798212,40.290636],[-74.800116,40.289748],[-74.819507,40.238508],[-74.778302,40.219714]]]},"properties":{"id":"08618","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.432635,40.4598],[-74.460571,40.439875],[-74.464958,40.410127],[-74.425692,40.3847],[-74.411894,40.385307],[-74.367854,40.406864],[-74.358981,40.421576],[-74.356333,40.429593],[-74.385028,40.462661],[-74.376808,40.4778],[-74.392521,40.485235],[-74.432635,40.4598]]]},"properties":{"id":"08816","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.439947,40.514464],[-74.415951,40.492712],[-74.392521,40.485235],[-74.376808,40.4778],[-74.371069,40.481387],[-74.370137,40.529881],[-74.381048,40.549749],[-74.40448,40.54972],[-74.408682,40.543835],[-74.439947,40.514464]]]},"properties":{"id":"08817","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.489524,40.474415],[-74.531476,40.441383],[-74.519989,40.428381],[-74.498775,40.397891],[-74.464958,40.410127],[-74.460571,40.439875],[-74.44699,40.463767],[-74.489524,40.474415]]]},"properties":{"id":"08902","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.489524,40.474415],[-74.44699,40.463767],[-74.432635,40.4598],[-74.392521,40.485235],[-74.415951,40.492712],[-74.446986,40.506009],[-74.467181,40.508425],[-74.489524,40.474415]]]},"properties":{"id":"08901","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.432635,40.4598],[-74.44699,40.463767],[-74.460571,40.439875],[-74.432635,40.4598]]]},"properties":{"id":"08850","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.576057,40.397933],[-74.589343,40.35806],[-74.560603,40.358859],[-74.516867,40.359925],[-74.505668,40.391585],[-74.498775,40.397891],[-74.519989,40.428381],[-74.576057,40.397933]]]},"properties":{"id":"08852","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.078847,40.398978],[-74.047266,40.419705],[-74.068,40.428265],[-74.082985,40.406508],[-74.078847,40.398978]]]},"properties":{"id":"07737","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.222916,40.277376],[-74.194376,40.227503],[-74.125604,40.249303],[-74.110838,40.252683],[-74.106018,40.274773],[-74.112961,40.320252],[-74.148915,40.324721],[-74.197556,40.345363],[-74.222916,40.277376]]]},"properties":{"id":"07722","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.863313,40.803342],[-74.848398,40.761331],[-74.843909,40.758212],[-74.732456,40.719928],[-74.724445,40.719482],[-74.724439,40.817848],[-74.768539,40.839296],[-74.816124,40.806782],[-74.817563,40.797744],[-74.863313,40.803342]]]},"properties":{"id":"07853","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.504122,40.951489],[-74.501409,40.941924],[-74.538669,40.930325],[-74.567892,40.904219],[-74.524895,40.880551],[-74.525099,40.879628],[-74.462413,40.933936],[-74.432025,40.992541],[-74.430178,41.018128],[-74.531543,40.960915],[-74.515772,40.954162],[-74.504122,40.951489]]]},"properties":{"id":"07866","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.392063,40.751379],[-74.363072,40.750403],[-74.367278,40.761067],[-74.366169,40.794786],[-74.41077,40.792649],[-74.435315,40.780866],[-74.431476,40.782312],[-74.429358,40.780344],[-74.434962,40.778736],[-74.438712,40.776028],[-74.392063,40.751379]]]},"properties":{"id":"07932","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.538669,40.930325],[-74.501409,40.941924],[-74.504122,40.951489],[-74.538669,40.930325]]]},"properties":{"id":"07842","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.434962,40.778736],[-74.429358,40.780344],[-74.431476,40.782312],[-74.435315,40.780866],[-74.434962,40.778736]]]},"properties":{"id":"07961","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.442336,40.147423],[-74.444959,40.109524],[-74.444524,40.049518],[-74.434141,40.033872],[-74.345696,40.045951],[-74.317784,40.037068],[-74.266067,40.055649],[-74.253088,40.058716],[-74.242957,40.115797],[-74.263763,40.168264],[-74.406777,40.172355],[-74.442336,40.147423]]]},"properties":{"id":"08527","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.41779,41.125915],[-74.324374,41.101959],[-74.279953,41.162994],[-74.400182,41.219676],[-74.432399,41.165795],[-74.41779,41.125915]]]},"properties":{"id":"07421","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.167322,40.895238],[-74.129233,40.894081],[-74.133552,40.901949],[-74.158222,40.904074],[-74.169788,40.896584],[-74.167322,40.895238]]]},"properties":{"id":"07503","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.555669,40.672482],[-74.560919,40.661738],[-74.545787,40.665603],[-74.555669,40.672482]]]},"properties":{"id":"07939","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.519731,40.5885],[-74.563146,40.610738],[-74.584277,40.624537],[-74.557502,40.579477],[-74.519731,40.5885]]]},"properties":{"id":"08836","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.679406,40.71933],[-74.676335,40.705226],[-74.668017,40.694641],[-74.651143,40.696496],[-74.653273,40.718398],[-74.679406,40.71933]]]},"properties":{"id":"07977","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.625383,40.573897],[-74.648725,40.556202],[-74.626395,40.563153],[-74.625383,40.573897]]]},"properties":{"id":"08869","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.687912,41.020195],[-74.873381,40.96668],[-74.880745,40.963888],[-74.887969,40.933194],[-74.840433,40.921315],[-74.781041,40.923835],[-74.682377,40.931984],[-74.641424,40.961365],[-74.614808,40.990284],[-74.687912,41.020195]]]},"properties":{"id":"07821","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.634186,41.132604],[-74.6245,41.161219],[-74.660801,41.166128],[-74.720408,41.112296],[-74.686727,41.037134],[-74.638551,41.118611],[-74.634186,41.132604]]]},"properties":{"id":"07848","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.202173,40.65763],[-74.153041,40.6548],[-74.136703,40.674444],[-74.201889,40.686702],[-74.215358,40.667633],[-74.202173,40.65763]]]},"properties":{"id":"07201","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.283511,40.658187],[-74.281488,40.650145],[-74.233325,40.654464],[-74.244351,40.663509],[-74.246652,40.663187],[-74.283511,40.658187]]]},"properties":{"id":"07203","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.331358,40.682192],[-74.36039,40.699821],[-74.37945,40.685397],[-74.387621,40.678234],[-74.379359,40.667584],[-74.331358,40.682192]]]},"properties":{"id":"07092","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.014017,40.868236],[-75.069277,41.019348],[-75.075231,40.899947],[-75.060472,40.88885],[-75.062073,40.882188],[-75.088876,40.832533],[-75.014017,40.868236]]]},"properties":{"id":"07832","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.062073,40.882188],[-75.060472,40.88885],[-75.075231,40.899947],[-75.062073,40.882188]]]},"properties":{"id":"07833","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.953027,39.543061],[-74.947201,39.543788],[-74.926832,39.550092],[-74.953027,39.543061]]],[[[-74.947487,39.533011],[-74.97436,39.507065],[-74.972075,39.505469],[-74.94927,39.508167],[-74.937037,39.517847],[-74.947487,39.533011]]]]},"properties":{"id":"08341","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.947487,39.533011],[-74.937037,39.517847],[-74.906182,39.546356],[-74.926832,39.550092],[-74.947201,39.543788],[-74.947487,39.533011]]]},"properties":{"id":"08326","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.451466,39.339844],[-74.501592,39.357255],[-74.502382,39.356318],[-74.506431,39.344381],[-74.485085,39.322971],[-74.451466,39.339844]]]},"properties":{"id":"08406","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.72006,39.57367],[-74.716645,39.576754],[-74.717945,39.577629],[-74.721417,39.574607],[-74.72006,39.57367]]]},"properties":{"id":"08217","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.201773,41.043778],[-74.24847,40.992303],[-74.211732,40.980156],[-74.191835,40.978688],[-74.16758,41.023781],[-74.201773,41.043778]]]},"properties":{"id":"07417","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.004683,40.859899],[-74.022399,40.866152],[-74.035684,40.868999],[-74.032581,40.858698],[-74.029887,40.846659],[-74.010749,40.846203],[-74.006059,40.85697],[-74.004683,40.859899]]]},"properties":{"id":"07660","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.021468,41.037736],[-74.069675,41.041541],[-74.026406,41.020594],[-74.020055,41.019815],[-74.021468,41.037736]]]},"properties":{"id":"07656","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.034289,40.884637],[-74.022399,40.866152],[-74.004683,40.859899],[-73.993933,40.876539],[-73.989286,40.911479],[-73.990651,40.911281],[-74.016544,40.914606],[-74.030194,40.913327],[-74.030164,40.913233],[-74.034289,40.884637]]]},"properties":{"id":"07666","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.091913,40.88662],[-74.104182,40.893664],[-74.130187,40.886899],[-74.117804,40.876812],[-74.107338,40.863077],[-74.097846,40.861458],[-74.093921,40.865096],[-74.091913,40.88662]]]},"properties":{"id":"07026","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.098923,40.850412],[-74.076142,40.837593],[-74.075122,40.848628],[-74.08761,40.861442],[-74.093921,40.865096],[-74.097846,40.861458],[-74.098923,40.850412]]]},"properties":{"id":"07075","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.16758,41.023781],[-74.191835,40.978688],[-74.170988,40.976997],[-74.149088,40.971137],[-74.14121,40.982761],[-74.137536,41.007495],[-74.147648,41.022058],[-74.155604,41.02585],[-74.16758,41.023781]]]},"properties":{"id":"07481","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.16204,41.102397],[-74.163288,41.104353],[-74.165739,41.105279],[-74.163975,41.102572],[-74.16204,41.102397]]]},"properties":{"id":"07495","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.669498,40.004552],[-74.71823,40.00011],[-74.710356,39.976415],[-74.715513,39.96892],[-74.596975,39.899219],[-74.611009,39.941349],[-74.632346,39.96649],[-74.65608,40.010164],[-74.669498,40.004552]]]},"properties":{"id":"08068","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.828736,40.120343],[-74.898776,40.073325],[-74.893415,40.053088],[-74.855651,40.031994],[-74.785889,40.037794],[-74.76907,40.095026],[-74.782825,40.106726],[-74.787896,40.108424],[-74.828736,40.120343]]]},"properties":{"id":"08016","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.787087,39.932718],[-74.862313,39.931103],[-74.862474,39.919828],[-74.862857,39.785334],[-74.827558,39.788737],[-74.812471,39.785269],[-74.787087,39.932718]]]},"properties":{"id":"08055","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.064216,39.859913],[-75.103191,39.856328],[-75.099536,39.854472],[-75.083855,39.840502],[-75.051874,39.850082],[-75.048382,39.854225],[-75.064216,39.859913]]]},"properties":{"id":"08078","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.032519,39.726985],[-75.023874,39.718564],[-74.930052,39.688723],[-74.896513,39.710375],[-74.975918,39.773426],[-75.008019,39.779617],[-75.032519,39.726985]]]},"properties":{"id":"08081","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.004953,39.834133],[-75.001577,39.836523],[-75.015983,39.85935],[-75.022167,39.857724],[-75.038089,39.845268],[-75.06001,39.83568],[-75.038942,39.829554],[-75.004953,39.834133]]]},"properties":{"id":"08083","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.095667,39.346356],[-75.112683,39.23891],[-75.108003,39.210487],[-75.044071,39.212896],[-75.002285,39.263275],[-74.995091,39.289263],[-75.095667,39.346356]]]},"properties":{"id":"08349","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.309311,39.45328],[-75.279931,39.454493],[-75.283776,39.464255],[-75.310564,39.46535],[-75.309311,39.45328]]]},"properties":{"id":"08353","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.328535,40.844667],[-74.254476,40.883098],[-74.270702,40.89692],[-74.322804,40.908834],[-74.338999,40.894069],[-74.337474,40.867756],[-74.32991,40.849161],[-74.328535,40.844667]]]},"properties":{"id":"07004","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.194196,40.829002],[-74.21189,40.7856],[-74.189989,40.7686],[-74.186253,40.774661],[-74.175309,40.809489],[-74.162628,40.837869],[-74.179403,40.846972],[-74.182689,40.848799],[-74.194196,40.829002]]]},"properties":{"id":"07003","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.21873,40.788323],[-74.195144,40.827896],[-74.219574,40.836049],[-74.22099,40.834799],[-74.232573,40.814863],[-74.22089,40.7898],[-74.21873,40.788323]]]},"properties":{"id":"07042","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.069111,39.678942],[-75.082175,39.708496],[-75.113842,39.73739],[-75.153105,39.723327],[-75.176789,39.686279],[-75.120892,39.662039],[-75.069111,39.678942]]]},"properties":{"id":"08028","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.16513,39.855487],[-75.099536,39.854472],[-75.103191,39.856328],[-75.120635,39.87261],[-75.139832,39.888317],[-75.162872,39.882649],[-75.16513,39.855487]]]},"properties":{"id":"08093","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.398417,39.810467],[-75.334596,39.830854],[-75.345812,39.844368],[-75.398417,39.810467]]]},"properties":{"id":"08014","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.334596,39.830854],[-75.28626,39.792795],[-75.251529,39.818486],[-75.236957,39.819836],[-75.257146,39.851829],[-75.345812,39.844368],[-75.334596,39.830854]]]},"properties":{"id":"08027","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.03762,40.750614],[-74.042931,40.736251],[-74.030525,40.733683],[-74.026222,40.757428],[-74.033607,40.757433],[-74.03762,40.750614]]]},"properties":{"id":"07030","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.749243,40.672701],[-74.824769,40.577501],[-74.753282,40.553141],[-74.743089,40.553257],[-74.726942,40.634077],[-74.728903,40.660711],[-74.749243,40.672701]]]},"properties":{"id":"08889","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.752798,40.683708],[-74.851217,40.690881],[-74.867073,40.575267],[-74.824769,40.577501],[-74.749243,40.672701],[-74.752798,40.683708]]]},"properties":{"id":"08833","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.041482,40.573121],[-74.956082,40.506944],[-74.910718,40.574278],[-74.926067,40.626402],[-74.948673,40.634174],[-75.015096,40.602541],[-75.041482,40.573121]]]},"properties":{"id":"08867","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.798212,40.290636],[-74.756647,40.228058],[-74.728837,40.23754],[-74.731896,40.242071],[-74.761889,40.284218],[-74.798212,40.290636]]]},"properties":{"id":"08638","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.56226,40.284983],[-74.572559,40.301054],[-74.567079,40.287521],[-74.56226,40.284983]]],[[[-74.56226,40.284983],[-74.567594,40.282532],[-74.585488,40.259947],[-74.579053,40.240214],[-74.542912,40.216653],[-74.484287,40.253299],[-74.508979,40.282755],[-74.56226,40.284983]]]]},"properties":{"id":"08520","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.244188,40.579726],[-74.266902,40.571016],[-74.267596,40.555461],[-74.231693,40.558457],[-74.22908,40.558228],[-74.244188,40.579726]]]},"properties":{"id":"07064","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.244188,40.579726],[-74.22908,40.558228],[-74.203688,40.592691],[-74.24779,40.600103],[-74.244188,40.579726]]]},"properties":{"id":"07008","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.230111,40.428193],[-74.242638,40.441633],[-74.247371,40.431459],[-74.230111,40.428193]]]},"properties":{"id":"07721","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.367854,40.406864],[-74.411894,40.385307],[-74.409816,40.379437],[-74.381618,40.382923],[-74.367854,40.406864]]]},"properties":{"id":"08884","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.978102,40.388916],[-73.974875,40.399487],[-74.001401,40.411335],[-73.978102,40.388916]]]},"properties":{"id":"07732","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.063691,40.338267],[-74.051136,40.347982],[-74.033694,40.375656],[-74.045756,40.387221],[-74.068842,40.386098],[-74.111469,40.344309],[-74.098871,40.324692],[-74.074386,40.333817],[-74.063691,40.338267]]]},"properties":{"id":"07701","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.148892,40.365535],[-74.148915,40.324721],[-74.112961,40.320252],[-74.098871,40.324692],[-74.111469,40.344309],[-74.148892,40.365535]]]},"properties":{"id":"07738","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.327404,40.344579],[-74.296999,40.322167],[-74.20062,40.347117],[-74.208301,40.381761],[-74.268194,40.390998],[-74.327404,40.344579]]]},"properties":{"id":"07751","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.02828,40.117415],[-74.068231,40.133107],[-74.092101,40.140839],[-74.096201,40.12463],[-74.085878,40.09797],[-74.038643,40.104456],[-74.029107,40.099769],[-74.02828,40.117415]]]},"properties":{"id":"08736","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.997233,40.329783],[-73.974257,40.322409],[-73.971881,40.34581],[-74.003188,40.339947],[-74.002658,40.334039],[-73.997233,40.329783]]]},"properties":{"id":"07750","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.154404,40.099719],[-74.155941,40.078191],[-74.053281,40.059316],[-74.08559,40.097611],[-74.085878,40.09797],[-74.096201,40.12463],[-74.114467,40.138014],[-74.154404,40.099719]]]},"properties":{"id":"08724","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.040653,40.320494],[-74.064438,40.311532],[-74.040042,40.305715],[-74.032923,40.322618],[-74.040653,40.320494]]]},"properties":{"id":"07703","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.46407,40.209161],[-74.488669,40.216393],[-74.476996,40.211982],[-74.46407,40.209161]]]},"properties":{"id":"08555","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.338999,40.894069],[-74.363667,40.955394],[-74.375159,40.891945],[-74.362203,40.870486],[-74.337474,40.867756],[-74.338999,40.894069]]]},"properties":{"id":"07045","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.71343,40.902351],[-74.71278,40.892148],[-74.6908,40.890096],[-74.685018,40.892452],[-74.692165,40.904432],[-74.71343,40.902351]]]},"properties":{"id":"07857","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.516991,41.155786],[-74.575475,41.101214],[-74.585763,41.083491],[-74.577004,41.078996],[-74.536002,41.058497],[-74.46471,41.077748],[-74.445434,41.075242],[-74.41779,41.125915],[-74.432399,41.165795],[-74.492766,41.162811],[-74.516991,41.155786]]]},"properties":{"id":"07460","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.32991,40.849161],[-74.341957,40.846781],[-74.383959,40.824991],[-74.40394,40.800385],[-74.41077,40.792649],[-74.366169,40.794786],[-74.334342,40.818416],[-74.329172,40.838299],[-74.328535,40.844667],[-74.32991,40.849161]]]},"properties":{"id":"07936","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.57736,40.804873],[-74.566343,40.795659],[-74.56638,40.805706],[-74.57736,40.804873]]]},"properties":{"id":"07926","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.56638,40.805706],[-74.571312,40.810239],[-74.578541,40.805908],[-74.57821,40.805158],[-74.57736,40.804873],[-74.56638,40.805706]]]},"properties":{"id":"07970","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.062924,39.959141],[-74.087339,39.959555],[-74.097785,39.958302],[-74.096877,39.941275],[-74.096956,39.926034],[-74.066641,39.936712],[-74.062924,39.959141]]]},"properties":{"id":"08751","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.08559,40.097611],[-74.053281,40.059316],[-74.051573,40.059015],[-74.040105,40.058062],[-74.029107,40.099769],[-74.038643,40.104456],[-74.08559,40.097611]]]},"properties":{"id":"08742","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.185706,39.723327],[-74.343048,39.707082],[-74.217891,39.647234],[-74.185638,39.654049],[-74.148647,39.698607],[-74.185706,39.723327]]]},"properties":{"id":"08050","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.445434,41.075242],[-74.406653,41.024287],[-74.363009,41.013169],[-74.351283,41.04575],[-74.346195,41.060796],[-74.324374,41.101959],[-74.41779,41.125915],[-74.445434,41.075242]]]},"properties":{"id":"07480","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.14611,40.85195],[-74.179403,40.846972],[-74.162628,40.837869],[-74.158502,40.835699],[-74.142936,40.844974],[-74.14611,40.85195]]]},"properties":{"id":"07012","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.149088,40.971137],[-74.170988,40.976997],[-74.166612,40.93433],[-74.148059,40.94159],[-74.146429,40.948441],[-74.141048,40.966348],[-74.149088,40.971137]]]},"properties":{"id":"07506","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.117804,40.876812],[-74.143391,40.859402],[-74.14611,40.85195],[-74.142936,40.844974],[-74.121853,40.834098],[-74.121404,40.847184],[-74.119462,40.85335],[-74.107338,40.863077],[-74.117804,40.876812]]]},"properties":{"id":"07055","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.158222,40.904074],[-74.133552,40.901949],[-74.132409,40.90406],[-74.154318,40.91671],[-74.158222,40.904074]]]},"properties":{"id":"07513","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.166816,40.931361],[-74.193143,40.930375],[-74.183789,40.912198],[-74.166032,40.926261],[-74.166816,40.931361]]]},"properties":{"id":"07522","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.203329,40.924459],[-74.230555,40.883071],[-74.195689,40.907398],[-74.198877,40.924748],[-74.203329,40.924459]]]},"properties":{"id":"07512","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.364247,39.564696],[-75.409478,39.660767],[-75.476226,39.672747],[-75.512998,39.57845],[-75.528019,39.500414],[-75.455155,39.50357],[-75.455477,39.414111],[-75.406841,39.38061],[-75.411242,39.420364],[-75.349171,39.534356],[-75.364247,39.564696]],[[-75.42364,39.541823],[-75.418829,39.547562],[-75.411247,39.544259],[-75.42364,39.541823]]]},"properties":{"id":"08079","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.504538,39.69817],[-75.484163,39.678901],[-75.476226,39.672747],[-75.409478,39.660767],[-75.389367,39.681624],[-75.466235,39.750795],[-75.504538,39.69817]]]},"properties":{"id":"08069","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.364247,39.564696],[-75.349171,39.534356],[-75.339135,39.558651],[-75.364247,39.564696]]]},"properties":{"id":"08001","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.42364,39.541823],[-75.411247,39.544259],[-75.418829,39.547562],[-75.42364,39.541823]]]},"properties":{"id":"08072","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.448544,40.655415],[-74.461231,40.673397],[-74.482306,40.666038],[-74.503862,40.659627],[-74.523627,40.649702],[-74.563146,40.610738],[-74.519731,40.5885],[-74.517733,40.587354],[-74.482432,40.621267],[-74.448544,40.655415]]]},"properties":{"id":"07059","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.543759,40.554559],[-74.558598,40.546798],[-74.582221,40.535036],[-74.58932,40.527916],[-74.591703,40.457941],[-74.581416,40.460396],[-74.531476,40.441383],[-74.489524,40.474415],[-74.467181,40.508425],[-74.515782,40.544955],[-74.543759,40.554559]]]},"properties":{"id":"08873","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.549498,41.195119],[-74.516991,41.155786],[-74.492766,41.162811],[-74.420202,41.229591],[-74.46837,41.253105],[-74.507884,41.203017],[-74.549498,41.195119]]]},"properties":{"id":"07462","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.801768,40.921657],[-74.806827,40.882127],[-74.71343,40.902351],[-74.692165,40.904432],[-74.665679,40.917208],[-74.682377,40.931984],[-74.781041,40.923835],[-74.801768,40.921657]]]},"properties":{"id":"07874","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.153041,40.6548],[-74.202173,40.65763],[-74.187797,40.644604],[-74.153041,40.6548]]]},"properties":{"id":"07206","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.401749,40.640393],[-74.386745,40.653316],[-74.372027,40.645004],[-74.384457,40.630453],[-74.3898,40.622079],[-74.394634,40.600901],[-74.359118,40.603539],[-74.325635,40.625052],[-74.379359,40.667584],[-74.387621,40.678234],[-74.403353,40.666537],[-74.408264,40.644185],[-74.401749,40.640393]]]},"properties":{"id":"07076","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.110675,40.79016],[-75.1896,40.591776],[-75.155778,40.620837],[-75.124454,40.656026],[-75.102669,40.721107],[-75.071734,40.776579],[-75.110675,40.79016]]]},"properties":{"id":"08865","state_code":"34"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.595254,35.058226],[-106.569579,35.051247],[-106.550452,35.066134],[-106.550592,35.087348],[-106.603181,35.087476],[-106.6043,35.058258],[-106.595254,35.058226]]]},"properties":{"id":"87108","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.667366,35.074992],[-106.657956,35.112254],[-106.689882,35.130944],[-106.692339,35.106509],[-106.667366,35.074992]]]},"properties":{"id":"87104","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.604087,35.129745],[-106.551016,35.127546],[-106.533435,35.159747],[-106.551093,35.174244],[-106.605631,35.14029],[-106.604087,35.129745]]]},"properties":{"id":"87109","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.004365,34.017297],[-107.39376,34.579535],[-107.577323,34.579498],[-107.716277,34.246897],[-108.466736,33.640018],[-108.496621,33.431026],[-108.20837,33.200967],[-108.155355,33.201245],[-107.448875,33.476422],[-107.351956,33.477807],[-107.220462,33.47813],[-107.004365,34.017297]]]},"properties":{"id":"87825","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.19617,35.219323],[-107.566318,35.303761],[-107.534835,35.226836],[-107.556431,35.07129],[-107.544826,35.067583],[-107.477238,35.102882],[-107.444335,35.153561],[-107.35573,35.15374],[-107.178524,35.182169],[-107.19617,35.219323]]]},"properties":{"id":"87014","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.032738,35.559652],[-108.114926,35.283569],[-108.04746,35.305442],[-107.919274,35.305237],[-107.835386,35.305154],[-107.936182,35.507832],[-108.032738,35.559652]]]},"properties":{"id":"87045","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.360821,36.655717],[-103.002119,36.801307],[-104.27404,36.994369],[-104.008188,36.810169],[-103.996031,36.753373],[-103.361011,36.707163],[-103.360821,36.655717]]]},"properties":{"id":"88419","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.472589,34.3034],[-103.073524,34.302673],[-103.214798,34.719662],[-103.495951,34.715953],[-103.472589,34.3034]],[[-103.318947,34.395342],[-103.302622,34.36563],[-103.337067,34.372681],[-103.318947,34.395342]]]},"properties":{"id":"88101","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.68285,32.128772],[-106.627046,32.204088],[-106.680116,32.188565],[-106.70623,32.17987],[-106.68285,32.128772]]]},"properties":{"id":"88048","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.75388,32.28447],[-106.74962,32.27576],[-106.74617,32.28077],[-106.74727,32.28524],[-106.75388,32.28447]]]},"properties":{"id":"88003","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.0373,32.37119],[-107.101711,32.252632],[-106.939018,32.270804],[-107.0373,32.37119]]]},"properties":{"id":"88033","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.8081,32.3902],[-106.818853,32.402903],[-106.81151,32.39057],[-106.8081,32.3902]]]},"properties":{"id":"88032","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.101218,32.219921],[-103.983427,32.000121],[-103.722853,32.000206],[-103.722917,32.274282],[-103.722752,32.405904],[-104.101218,32.219921]]]},"properties":{"id":"88256","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.155536,32.817574],[-108.182806,32.764471],[-108.153635,32.782255],[-108.155536,32.817574]]]},"properties":{"id":"88022","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.422795,35.166361],[-104.17639,35.06585],[-104.125094,35.079375],[-104.216107,35.215806],[-104.296228,35.351335],[-104.318449,35.381124],[-104.443294,35.216266],[-104.422795,35.166361]]]},"properties":{"id":"88431","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.041409,35.739274],[-103.040791,36.083433],[-103.20323,36.070788],[-103.636652,35.830791],[-103.379326,35.636287],[-103.041409,35.739274]]]},"properties":{"id":"88410","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.642163,35.822876],[-103.807127,35.546482],[-103.70741,35.4222],[-103.424082,35.282899],[-103.209752,35.390273],[-103.379326,35.636287],[-103.636652,35.830791],[-103.642163,35.822876]]]},"properties":{"id":"88426","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.064704,32.334653],[-103.442086,32.418671],[-103.722917,32.274282],[-103.722853,32.000206],[-103.064704,32.334653]]]},"properties":{"id":"88252","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-105.312762,34.463254],[-105.430739,34.482895],[-105.312968,34.34672],[-105.312762,34.463254]]],[[[-105.72568,34.052491],[-105.925878,33.825553],[-106.098993,33.389411],[-105.931629,33.389874],[-105.927915,33.389938],[-105.73835,33.605582],[-105.540664,33.996947],[-105.72568,34.052491]]]]},"properties":{"id":"88301","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.540664,33.996947],[-104.893505,34.047515],[-104.893141,34.088304],[-104.892245,34.275158],[-104.89185,34.346989],[-105.312968,34.34672],[-105.430739,34.482895],[-105.699978,34.379246],[-105.839548,34.307383],[-105.90182,34.259985],[-105.72568,34.052491],[-105.540664,33.996947]]]},"properties":{"id":"88318","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.268971,35.781696],[-106.413108,35.827672],[-106.418208,35.827695],[-106.268971,35.781696]]]},"properties":{"id":"87544","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.046358,35.592638],[-109.046711,35.406774],[-109.024223,35.343498],[-108.847819,35.364115],[-108.821873,35.479219],[-108.865882,35.514982],[-108.955031,35.623676],[-109.046358,35.592638]]]},"properties":{"id":"87319","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.358594,35.53114],[-108.370121,35.556073],[-108.639285,35.490264],[-108.624932,35.443209],[-108.430734,35.499022],[-108.381357,35.453514],[-108.472545,35.358303],[-108.318139,35.306506],[-108.280313,35.348979],[-108.391681,35.41],[-108.358594,35.53114]]]},"properties":{"id":"87316","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-107.624879,36.566271],[-107.62514,36.308062],[-107.62425,36.219799],[-107.460358,36.217775],[-107.590475,36.566482],[-107.624879,36.566271]]],[[[-107.411418,36.217636],[-107.625775,36.088427],[-107.81595,36.001407],[-107.838651,35.805726],[-107.936182,35.507832],[-107.835386,35.305154],[-107.732829,35.348332],[-107.121757,35.476655],[-107.257512,35.680058],[-106.975824,35.816849],[-106.858924,35.894067],[-106.708414,35.999544],[-106.719411,36.037502],[-106.886094,36.096164],[-106.992354,36.156238],[-106.992055,36.21653],[-107.136092,36.21622],[-107.411418,36.217636]]]]},"properties":{"id":"87013","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.522531,32.422131],[-104.847757,32.00052],[-104.851525,32.520505],[-105.110455,32.520652],[-105.522531,32.422131]]]},"properties":{"id":"79837","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.922999,32.959507],[-105.882406,32.715823],[-105.820163,32.726532],[-105.813554,32.845315],[-105.825411,32.980106],[-105.922999,32.959507]]]},"properties":{"id":"88325","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.125094,35.079375],[-104.129424,34.779324],[-104.129279,34.737965],[-103.95034,34.86671],[-103.773904,34.836424],[-103.496022,35.146812],[-103.424082,35.282899],[-103.70741,35.4222],[-104.150642,35.354442],[-104.216107,35.215806],[-104.125094,35.079375]]]},"properties":{"id":"88401","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.848408,36.5211],[-106.993628,36.259998],[-106.8676,36.347387],[-106.742149,36.506771],[-106.848408,36.5211]]]},"properties":{"id":"87029","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.706832,36.193146],[-106.619102,35.999925],[-106.528864,36.006177],[-106.426736,36.23837],[-106.660866,36.350478],[-106.706832,36.193146]]]},"properties":{"id":"87064","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.956296,36.17215],[-105.971981,36.197877],[-106.083956,36.124712],[-106.034933,36.124668],[-105.94583,36.159953],[-105.956296,36.17215]]]},"properties":{"id":"87582","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.215582,36.207421],[-106.161717,36.127343],[-106.142515,36.109891],[-106.106874,36.219007],[-106.215582,36.207421]]]},"properties":{"id":"87548","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.861724,34.273694],[-103.518648,34.055286],[-103.618016,34.3031],[-103.861724,34.273694]]]},"properties":{"id":"88118","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.073524,34.302673],[-103.472589,34.3034],[-103.618016,34.3031],[-103.518648,34.055286],[-103.440337,33.87219],[-103.336063,33.878694],[-103.334891,33.909408],[-103.043617,34.00364],[-103.043936,34.302585],[-103.073524,34.302673]]]},"properties":{"id":"88130","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.350131,35.638538],[-106.408112,35.582099],[-106.332502,35.638483],[-106.350131,35.638538]]]},"properties":{"id":"87072","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.573747,35.217933],[-106.459379,35.217942],[-106.418652,35.217428],[-106.355168,35.365633],[-106.494379,35.358544],[-106.573747,35.217933]]]},"properties":{"id":"87043","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.283886,32.80214],[-107.292424,32.794535],[-107.25945,32.780119],[-107.283886,32.80214]]]},"properties":{"id":"87933","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-105.646594,36.533309],[-105.621216,36.534194],[-105.632449,36.541363],[-105.646594,36.533309]]],[[[-105.452501,36.533854],[-105.506971,36.574831],[-105.59947,36.532157],[-105.602205,36.523782],[-105.596192,36.52218],[-105.548296,36.519044],[-105.452501,36.533854]]]]},"properties":{"id":"87514","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.683117,36.16251],[-105.662565,36.231113],[-105.756181,36.206069],[-105.711778,36.162741],[-105.683117,36.16251]]]},"properties":{"id":"87553","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-105.621216,36.534194],[-105.646594,36.533309],[-105.64888,36.533042],[-105.602205,36.523782],[-105.59947,36.532157],[-105.621216,36.534194]]],[[[-105.596192,36.52218],[-105.553153,36.50717],[-105.548296,36.519044],[-105.596192,36.52218]]]]},"properties":{"id":"87529","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.569543,36.903731],[-105.298782,36.804877],[-105.233938,36.784683],[-105.522323,36.995826],[-105.569543,36.903731]]]},"properties":{"id":"87512","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.782075,35.041145],[-105.817567,35.004928],[-105.66405,35.00828],[-105.682815,35.041571],[-105.782075,35.041145]]]},"properties":{"id":"87070","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.771207,34.570596],[-106.74423,34.60145],[-106.772353,34.610202],[-106.771207,34.570596]]]},"properties":{"id":"87023","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.6043,35.058258],[-106.603181,35.087476],[-106.604197,35.105162],[-106.612858,35.107132],[-106.637625,35.047562],[-106.602873,35.029317],[-106.607875,35.029268],[-106.608655,35.057949],[-106.604308,35.057937],[-106.6043,35.058258]]]},"properties":{"id":"87106","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.533435,35.159747],[-106.551016,35.127546],[-106.552164,35.11643],[-106.480304,35.112821],[-106.436182,35.127353],[-106.415641,35.182724],[-106.533435,35.159747]]]},"properties":{"id":"87111","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.569579,35.051247],[-106.595254,35.058226],[-106.604308,35.057937],[-106.608655,35.057949],[-106.607875,35.029268],[-106.602873,35.029317],[-106.538215,34.947616],[-106.490177,35.042648],[-106.532552,35.054681],[-106.569579,35.051247]]]},"properties":{"id":"87117","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.466736,33.640018],[-107.716277,34.246897],[-107.724854,34.57806],[-107.794853,34.579392],[-108.244448,34.161148],[-108.560378,33.996658],[-108.473046,33.798258],[-108.466736,33.640018]]]},"properties":{"id":"87821","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.244448,34.161148],[-107.794853,34.579392],[-108.218433,34.579968],[-108.244448,34.161148]]]},"properties":{"id":"87827","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.448875,33.476422],[-108.155355,33.201245],[-108.029376,33.201507],[-107.855199,33.041117],[-107.568235,33.038251],[-107.532524,33.062171],[-107.565374,33.170071],[-107.551586,33.347375],[-107.448875,33.476422]]]},"properties":{"id":"87943","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.477238,35.102882],[-107.449464,35.041755],[-107.378554,35.061258],[-107.444335,35.153561],[-107.477238,35.102882]]]},"properties":{"id":"87038","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.906726,35.189809],[-107.876682,35.208248],[-107.906275,35.167989],[-108.134014,35.098606],[-108.273274,34.697214],[-108.218433,34.579968],[-107.794853,34.579392],[-107.724854,34.57806],[-107.708661,34.577673],[-107.806015,34.987006],[-107.8946,34.984957],[-107.890091,35.083764],[-107.751774,35.072866],[-107.751639,35.078622],[-107.534835,35.226836],[-107.566318,35.303761],[-107.732829,35.348332],[-107.835386,35.305154],[-107.919274,35.305237],[-107.933251,35.217687],[-107.906726,35.189809]]]},"properties":{"id":"87020","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.518959,34.958988],[-108.922029,34.579936],[-108.362216,34.696796],[-108.518959,34.958988]]]},"properties":{"id":"87315","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.751774,35.072866],[-107.890091,35.083764],[-107.8946,34.984957],[-107.806015,34.987006],[-107.751774,35.072866]]]},"properties":{"id":"87051","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.04746,35.305442],[-107.933251,35.217687],[-107.919274,35.305237],[-108.04746,35.305442]]]},"properties":{"id":"87005","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.870094,36.261123],[-104.831955,36.43522],[-104.889,36.261137],[-104.870094,36.261123]]]},"properties":{"id":"87729","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.021183,36.260675],[-105.15769,36.28321],[-105.211859,36.261868],[-105.195549,36.175694],[-105.058548,36.067615],[-104.998556,36.045341],[-105.021183,36.260675]]]},"properties":{"id":"87734","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.495951,34.715953],[-103.471127,34.865625],[-103.706635,34.619772],[-103.948839,34.605058],[-103.946052,34.244072],[-103.861724,34.273694],[-103.618016,34.3031],[-103.472589,34.3034],[-103.495951,34.715953]]]},"properties":{"id":"88124","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.421984,32.587789],[-104.469954,32.653692],[-104.551372,32.613304],[-104.421984,32.587789]]]},"properties":{"id":"88254","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.985283,32.82842],[-104.004657,32.819408],[-103.967951,32.801834],[-103.960384,32.819285],[-103.985283,32.82842]]]},"properties":{"id":"88255","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.000927,32.517088],[-107.820763,32.604647],[-108.05081,32.618841],[-108.027007,32.542054],[-108.000927,32.517088]]]},"properties":{"id":"88034","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.237917,32.896006],[-108.182598,32.891872],[-108.194957,32.941088],[-108.237917,32.896006]]]},"properties":{"id":"88053","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.17953,35.305539],[-105.273698,35.248078],[-105.291139,35.185589],[-105.290798,35.085541],[-105.096193,35.167224],[-105.06396,35.19322],[-105.04836,35.16957],[-104.903133,35.21646],[-105.17953,35.305539]]]},"properties":{"id":"87711","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.422795,35.166361],[-104.54961,34.99346],[-104.268453,34.979502],[-104.17639,35.06585],[-104.422795,35.166361]]]},"properties":{"id":"88417","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.79763,32.1834],[-109.045347,32.081837],[-109.049037,31.707727],[-108.851105,31.332301],[-108.54433,31.778032],[-108.79763,32.1834]]]},"properties":{"id":"88020","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.735015,33.390559],[-105.643316,33.344751],[-105.509633,33.42322],[-105.518727,33.446832],[-105.523743,33.494933],[-105.527274,33.496888],[-105.686235,33.448943],[-105.784501,33.390482],[-105.735015,33.390559]]]},"properties":{"id":"88312","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.523743,33.494933],[-105.518727,33.446832],[-105.341927,33.424905],[-105.272453,33.437799],[-105.487731,33.517671],[-105.522801,33.498829],[-105.523743,33.494933]]]},"properties":{"id":"88338","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.527274,33.496888],[-105.523743,33.494933],[-105.522801,33.498829],[-105.527274,33.496888]]]},"properties":{"id":"88323","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-108.77473,35.74105],[-108.722545,35.771942],[-108.772619,35.769958],[-108.822095,35.740964],[-108.77473,35.74105]]],[[[-108.647905,35.659812],[-108.774584,35.737843],[-108.906467,35.631739],[-108.810588,35.57077],[-108.705718,35.603482],[-108.70575,35.600293],[-108.647905,35.659812]]]]},"properties":{"id":"87375","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-109.024223,35.343498],[-109.046711,35.406774],[-109.046587,35.330478],[-109.024223,35.343498]]],[[[-108.831938,35.550587],[-108.865882,35.514982],[-108.821873,35.479219],[-108.657206,35.507781],[-108.644746,35.52307],[-108.655041,35.515805],[-108.667414,35.528519],[-108.635188,35.533792],[-108.70575,35.600293],[-108.705718,35.603482],[-108.831938,35.550587]]]]},"properties":{"id":"87301","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.705718,35.603482],[-108.810588,35.57077],[-108.831938,35.550587],[-108.705718,35.603482]]]},"properties":{"id":"87317","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.532456,36.176063],[-108.860265,36.094934],[-108.894728,36.08397],[-108.98214,35.843088],[-108.957622,35.817934],[-108.772619,35.769958],[-108.722545,35.771942],[-108.392875,35.901865],[-108.324508,36.203199],[-108.469251,36.175924],[-108.532456,36.176063]]]},"properties":{"id":"87325","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.429361,36.079927],[-105.319889,36.037731],[-105.344202,36.085964],[-105.429361,36.079927]]]},"properties":{"id":"87723","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.4124,35.80068],[-105.302956,35.85755],[-105.621326,35.977334],[-105.717374,35.97876],[-105.72028,35.871015],[-105.524329,35.870721],[-105.439141,35.804195],[-105.4124,35.80068]]]},"properties":{"id":"87742","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.813554,32.845315],[-105.820163,32.726532],[-105.710764,32.727648],[-105.746411,32.755797],[-105.813554,32.845315]]]},"properties":{"id":"88349","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.081011,32.84353],[-106.082394,32.824475],[-106.067189,32.832054],[-106.081011,32.84353]]]},"properties":{"id":"88330","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.1457,32.401834],[-106.079036,32.375894],[-106.064907,32.388137],[-106.1457,32.401834]]]},"properties":{"id":"88342","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.95034,34.86671],[-104.129279,34.737965],[-104.022608,34.605093],[-103.948839,34.605058],[-103.706635,34.619772],[-103.95034,34.86671]]]},"properties":{"id":"88121","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.379326,35.636287],[-103.209752,35.390273],[-103.042256,35.39489],[-103.041409,35.739274],[-103.379326,35.636287]]]},"properties":{"id":"88430","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.619102,35.999925],[-106.706832,36.193146],[-106.770547,36.199542],[-106.719411,36.037502],[-106.708414,35.999544],[-106.636247,35.999801],[-106.619102,35.999925]]]},"properties":{"id":"87012","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.846513,36.042247],[-105.885411,36.008425],[-105.791397,36.002151],[-105.846513,36.042247]]]},"properties":{"id":"87523","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.992354,36.156238],[-106.886148,36.148049],[-106.885266,36.200466],[-106.8676,36.347387],[-106.993628,36.259998],[-106.992055,36.21653],[-106.992354,36.156238]]]},"properties":{"id":"87046","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.332502,35.638483],[-106.408112,35.582099],[-106.382886,35.53926],[-106.24859,35.530492],[-106.24856,35.565893],[-106.268971,35.781696],[-106.418208,35.827695],[-106.442155,35.70013],[-106.348036,35.660708],[-106.332502,35.638483]]]},"properties":{"id":"87041","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.382886,35.53926],[-106.347554,35.465969],[-106.248491,35.443199],[-106.248472,35.444914],[-106.24859,35.530492],[-106.382886,35.53926]]]},"properties":{"id":"87052","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.680136,35.650047],[-106.637477,35.623561],[-106.60637,35.699967],[-106.680136,35.650047]]]},"properties":{"id":"87044","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.975824,35.816849],[-107.257512,35.680058],[-107.121757,35.476655],[-106.975824,35.816849]]]},"properties":{"id":"87053","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.763162,35.218141],[-106.662621,35.217323],[-106.627871,35.265621],[-106.857297,35.356708],[-106.859383,35.218023],[-106.763162,35.218141]]]},"properties":{"id":"87124","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.886094,36.096164],[-106.886148,36.148049],[-106.992354,36.156238],[-106.886094,36.096164]]]},"properties":{"id":"87027","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.727889,36.322968],[-107.96615,36.117704],[-107.81595,36.001407],[-107.625775,36.088427],[-107.62425,36.219799],[-107.62514,36.308062],[-107.727889,36.322968]]]},"properties":{"id":"87037","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.918663,36.198492],[-108.842264,36.202551],[-108.837214,36.171363],[-108.532456,36.176063],[-108.469251,36.175924],[-108.28195,36.363113],[-108.370529,36.467876],[-108.614432,36.417933],[-108.768803,36.369376],[-108.918663,36.198492]]]},"properties":{"id":"87455","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.219511,36.916223],[-108.170742,36.885319],[-108.032359,36.981143],[-108.249358,36.999015],[-108.219511,36.916223]]]},"properties":{"id":"87418","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.718063,35.652701],[-105.669139,35.534314],[-105.506437,35.566685],[-105.506138,35.696334],[-105.548277,35.780919],[-105.717995,35.687682],[-105.718063,35.652701]]]},"properties":{"id":"87552","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.984422,35.69817],[-105.97687,35.677742],[-105.858973,35.688753],[-105.717995,35.687682],[-105.721078,35.795388],[-105.856184,35.82089],[-105.893645,35.793821],[-105.984422,35.69817]]]},"properties":{"id":"87501","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.162664,34.95335],[-106.149575,34.870142],[-106.02608,34.8702],[-105.949265,34.87032],[-105.817567,35.004928],[-105.782075,35.041145],[-106.050049,35.040807],[-106.162664,34.95335]]]},"properties":{"id":"87035","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.568235,33.038251],[-107.495102,32.945867],[-107.297151,32.87941],[-107.293872,32.896695],[-107.277254,33.041153],[-107.532524,33.062171],[-107.568235,33.038251]]]},"properties":{"id":"87931","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.299378,32.750921],[-107.495102,32.945867],[-107.568235,33.038251],[-107.855199,33.041117],[-107.855879,33.004526],[-107.608283,32.605382],[-107.299463,32.605534],[-107.299378,32.750921]]]},"properties":{"id":"88042","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.676131,36.799416],[-105.603889,36.800146],[-105.595293,36.73424],[-105.671264,36.757667],[-105.707182,36.693196],[-105.695803,36.625588],[-105.540456,36.624571],[-105.298782,36.804877],[-105.569543,36.903731],[-105.676133,36.79969],[-105.676131,36.799416]]]},"properties":{"id":"87556","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.585411,36.335883],[-105.71504,36.350211],[-105.836321,36.20881],[-105.756181,36.206069],[-105.662565,36.231113],[-105.593382,36.211411],[-105.585411,36.335883]]]},"properties":{"id":"87557","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.949265,34.87032],[-106.02608,34.8702],[-106.03463,34.84115],[-105.938559,34.841226],[-105.949265,34.87032]]]},"properties":{"id":"87032","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.040791,36.083433],[-103.04131,36.228978],[-103.20323,36.070788],[-103.040791,36.083433]]]},"properties":{"id":"88436","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.418652,35.217428],[-106.459379,35.217942],[-106.415641,35.182724],[-106.436182,35.127353],[-106.42915,35.108359],[-106.323215,35.138362],[-106.418652,35.217428]]]},"properties":{"id":"87008","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.261442,33.139225],[-104.595188,33.138446],[-104.664063,33.02928],[-104.241881,33.099476],[-104.261442,33.139225]]]},"properties":{"id":"88232","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.27404,36.994369],[-104.736026,36.598006],[-104.53157,36.533851],[-104.138278,36.430756],[-104.008447,36.572659],[-104.007733,36.742379],[-104.008188,36.810169],[-104.27404,36.994369]]]},"properties":{"id":"87740","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.22387,36.535064],[-105.335095,36.484072],[-105.353031,36.443061],[-105.328033,36.276469],[-105.340907,36.262245],[-105.322493,36.262297],[-105.211859,36.261868],[-105.15769,36.28321],[-105.133905,36.368288],[-105.22387,36.535064]]]},"properties":{"id":"87710","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.831973,36.482694],[-105.280094,36.636731],[-105.196775,36.566899],[-105.12601,36.612903],[-104.958538,36.493812],[-105.014489,36.483075],[-105.132541,36.546411],[-105.22387,36.535064],[-105.133905,36.368288],[-105.01714,36.260789],[-104.889,36.261137],[-104.831955,36.43522],[-104.831973,36.482694]]]},"properties":{"id":"87714","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.132541,36.546411],[-105.014489,36.483075],[-104.958538,36.493812],[-105.12601,36.612903],[-105.196775,36.566899],[-105.132541,36.546411]]]},"properties":{"id":"87749","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.463073,33.996416],[-104.049564,34.082564],[-104.022608,34.605093],[-104.129279,34.737965],[-104.129424,34.779324],[-104.607952,34.604384],[-104.892245,34.275158],[-104.893141,34.088304],[-104.463073,33.996416]]]},"properties":{"id":"88119","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.892245,34.275158],[-104.607952,34.604384],[-104.891953,34.582968],[-104.89185,34.346989],[-104.892245,34.275158]]]},"properties":{"id":"88136","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.79796,32.22828],[-106.79062,32.24406],[-106.80265,32.25968],[-106.816539,32.251463],[-106.798362,32.227739],[-106.79796,32.22828]]]},"properties":{"id":"88046","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.64964,32.07455],[-106.64961,32.06807],[-106.632962,32.068179],[-106.64476,32.08261],[-106.64964,32.07455]]]},"properties":{"id":"88024","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.551372,32.613304],[-104.469954,32.653692],[-104.421984,32.587789],[-103.813853,32.529317],[-103.814678,32.825308],[-103.8146,32.965203],[-104.182525,32.965146],[-104.655297,32.963743],[-104.841229,32.963303],[-104.841516,32.887651],[-104.629137,32.599199],[-104.551372,32.613304]],[[-103.985283,32.82842],[-103.960384,32.819285],[-103.967951,32.801834],[-104.004657,32.819408],[-103.985283,32.82842]]]},"properties":{"id":"88210","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.045347,32.081837],[-108.79763,32.1834],[-108.525087,31.979758],[-108.327838,32.091158],[-108.689713,32.603597],[-108.942699,32.777842],[-109.047117,32.777569],[-109.0483,32.081826],[-109.045347,32.081837]]]},"properties":{"id":"88045","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.736988,32.906253],[-108.616207,32.773743],[-108.594205,32.940707],[-108.638925,33.100164],[-108.736988,32.906253]]]},"properties":{"id":"88028","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.04836,35.16957],[-105.06396,35.19322],[-105.096193,35.167224],[-105.04836,35.16957]]]},"properties":{"id":"87724","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.371498,36.067042],[-103.991785,36.174626],[-104.170145,36.218344],[-104.436121,36.218761],[-104.371498,36.067042]]]},"properties":{"id":"87730","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.0483,32.081826],[-109.049037,31.707727],[-109.045347,32.081837],[-109.0483,32.081826]]]},"properties":{"id":"88056","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.27483,32.856743],[-103.093286,32.827444],[-103.12709,32.870787],[-103.27483,32.856743]]]},"properties":{"id":"88242","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.643316,33.344751],[-105.61402,33.305128],[-105.463184,33.305149],[-105.463032,33.314228],[-105.509633,33.42322],[-105.643316,33.344751]]]},"properties":{"id":"88346","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.333533,33.411479],[-105.382006,33.422095],[-105.386707,33.375633],[-105.333533,33.411479]]]},"properties":{"id":"88348","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.046184,35.72514],[-109.046358,35.592638],[-108.955031,35.623676],[-108.906467,35.631739],[-108.774584,35.737843],[-108.77473,35.74105],[-108.822095,35.740964],[-108.939468,35.76526],[-109.046184,35.72514]]]},"properties":{"id":"86515","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.998556,36.045341],[-104.885924,35.947849],[-104.63881,35.778612],[-104.367447,35.781428],[-104.371498,36.067042],[-104.436121,36.218761],[-104.870094,36.261123],[-104.889,36.261137],[-105.01714,36.260789],[-105.021183,36.260675],[-104.998556,36.045341]]]},"properties":{"id":"87752","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.183296,36.039844],[-105.147873,35.993157],[-105.058548,36.067615],[-105.195549,36.175694],[-105.183296,36.039844]]]},"properties":{"id":"87735","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.885924,35.947849],[-105.151834,35.962104],[-105.166667,35.938625],[-105.05904,35.780098],[-105.023855,35.756966],[-104.63881,35.778612],[-104.885924,35.947849]],[[-104.923067,35.80764],[-104.949201,35.840999],[-104.916575,35.854521],[-104.889532,35.822386],[-104.923067,35.80764]]]},"properties":{"id":"87753","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.249957,35.941058],[-105.166667,35.938625],[-105.151834,35.962104],[-105.147873,35.993157],[-105.183296,36.039844],[-105.229419,36.016329],[-105.249957,35.941058]]]},"properties":{"id":"87736","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.319889,36.037731],[-105.429361,36.079927],[-105.479281,36.052952],[-105.53038,36.013014],[-105.5367,35.99148],[-105.319889,36.037731]]]},"properties":{"id":"87715","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.098993,33.389411],[-106.195761,32.746862],[-106.082394,32.824475],[-106.081011,32.84353],[-105.916129,33.026867],[-105.857979,33.217571],[-105.931629,33.389874],[-106.098993,33.389411]]]},"properties":{"id":"88352","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.063943,36.436794],[-105.94462,36.449373],[-105.952998,36.534364],[-106.011959,36.534203],[-106.063943,36.436794]]]},"properties":{"id":"87554","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.797772,36.079448],[-105.865102,36.055865],[-105.846513,36.042247],[-105.791397,36.002151],[-105.71689,35.994642],[-105.797772,36.079448]]]},"properties":{"id":"87578","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.797772,36.079448],[-105.71689,35.994642],[-105.717374,35.97876],[-105.621326,35.977334],[-105.5367,35.99148],[-105.53038,36.013014],[-105.61309,36.048558],[-105.711778,36.162741],[-105.756181,36.206069],[-105.836321,36.20881],[-105.832912,36.112482],[-105.797772,36.079448]]]},"properties":{"id":"87521","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.310306,36.438426],[-106.215582,36.207421],[-106.106874,36.219007],[-106.054673,36.281005],[-106.147751,36.405939],[-106.275248,36.535624],[-106.310306,36.438426]]]},"properties":{"id":"87530","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.283545,33.821635],[-103.458021,33.788295],[-103.410234,33.570356],[-103.05262,33.570599],[-103.051767,33.627747],[-103.11495,33.773986],[-103.283545,33.821635]]]},"properties":{"id":"88125","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.046341,33.879447],[-103.11495,33.773986],[-103.051767,33.627747],[-103.046341,33.879447]]]},"properties":{"id":"88113","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.723407,35.83893],[-106.680136,35.650047],[-106.60637,35.699967],[-106.442155,35.70013],[-106.418208,35.827695],[-106.413108,35.827672],[-106.636247,35.999801],[-106.708414,35.999544],[-106.858924,35.894067],[-106.723407,35.83893]]]},"properties":{"id":"87025","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.324508,36.203199],[-108.130356,36.104493],[-108.130236,36.15893],[-108.128177,36.64024],[-108.210493,36.639048],[-108.370529,36.467876],[-108.28195,36.363113],[-108.324508,36.203199]]]},"properties":{"id":"87499","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.768803,36.369376],[-109.04572,36.454381],[-109.045998,36.1401],[-108.894728,36.08397],[-108.860265,36.094934],[-108.837214,36.171363],[-108.842264,36.202551],[-108.918663,36.198492],[-108.768803,36.369376]]]},"properties":{"id":"87461","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.672688,35.523922],[-105.717886,35.384162],[-105.715494,35.273626],[-105.59853,35.412894],[-105.64643,35.4679],[-105.672688,35.523922]]]},"properties":{"id":"87562","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.771798,35.42129],[-105.824644,35.549739],[-105.982835,35.625735],[-106.113996,35.534607],[-106.024042,35.399933],[-105.780654,35.428518],[-105.771798,35.42129]]]},"properties":{"id":"87508","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.982835,35.625735],[-105.824644,35.549739],[-105.774525,35.64635],[-105.858973,35.688753],[-105.97687,35.677742],[-105.982835,35.625735]]]},"properties":{"id":"87505","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.448875,33.476422],[-107.551586,33.347375],[-107.351956,33.477807],[-107.448875,33.476422]]]},"properties":{"id":"87939","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.840947,34.28233],[-106.904891,34.118252],[-106.89418,33.946934],[-106.76667,34.166067],[-106.78212,34.305675],[-106.840947,34.28233]]]},"properties":{"id":"87801","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.373193,34.870338],[-106.410977,34.870254],[-106.443161,34.870193],[-106.25398,34.6815],[-106.06107,34.60905],[-105.83322,34.65444],[-105.66405,35.00828],[-105.817567,35.004928],[-105.949265,34.87032],[-105.938559,34.841226],[-106.03463,34.84115],[-106.02608,34.8702],[-106.149575,34.870142],[-106.373193,34.870338]]]},"properties":{"id":"87016","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.652772,35.182083],[-106.758499,35.188763],[-106.703689,35.106815],[-106.692339,35.106509],[-106.689882,35.130944],[-106.652772,35.182083]]]},"properties":{"id":"87120","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.703689,35.106815],[-106.758499,35.188763],[-106.763162,35.218141],[-106.859383,35.218023],[-106.952958,35.058376],[-107.025022,34.871013],[-106.699414,34.946886],[-106.703689,35.106815]]]},"properties":{"id":"87121","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.261103,35.121849],[-106.162664,34.95335],[-106.050049,35.040807],[-106.155949,35.19925],[-106.261103,35.121849]]]},"properties":{"id":"87015","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.662621,35.217323],[-106.606073,35.217439],[-106.597672,35.280886],[-106.627871,35.265621],[-106.662621,35.217323]]]},"properties":{"id":"87048","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.241881,33.099476],[-104.664063,33.02928],[-104.655297,32.963743],[-104.182525,32.965146],[-104.241881,33.099476]]]},"properties":{"id":"88253","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.544826,35.067583],[-107.545038,34.973823],[-107.449464,35.041755],[-107.477238,35.102882],[-107.544826,35.067583]]]},"properties":{"id":"87007","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.518959,34.958988],[-108.362216,34.696796],[-108.273274,34.697214],[-108.134014,35.098606],[-108.539169,35.291796],[-108.518959,34.958988]],[[-108.398812,34.882839],[-108.338576,34.927128],[-108.309731,34.99728],[-108.256171,34.886815],[-108.398812,34.882839]]]},"properties":{"id":"87321","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.906275,35.167989],[-107.876682,35.208248],[-107.906726,35.189809],[-107.906275,35.167989]]]},"properties":{"id":"87021","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.35573,35.15374],[-107.444335,35.153561],[-107.378554,35.061258],[-107.35573,35.15374]]]},"properties":{"id":"87040","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.673786,32.062415],[-106.683955,32.026158],[-106.816414,31.97042],[-106.944601,32.072089],[-107.298208,32.220107],[-107.296825,31.78368],[-107.296825,31.783671],[-106.68194,31.783926],[-106.630269,31.885832],[-106.623225,32.086284],[-106.668801,32.113514],[-106.64476,32.08261],[-106.632962,32.068179],[-106.64961,32.06807],[-106.64964,32.07455],[-106.673786,32.062415]]]},"properties":{"id":"88021","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.627046,32.204088],[-106.68285,32.128772],[-106.668801,32.113514],[-106.623225,32.086284],[-106.581367,32.204976],[-106.627046,32.204088]]]},"properties":{"id":"88072","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.15455,32.6834],[-106.997284,32.562581],[-106.91032,32.52452],[-106.958289,32.622259],[-107.15455,32.6834]]]},"properties":{"id":"87940","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.129823,32.756707],[-108.136946,32.774003],[-108.157605,32.724369],[-108.129823,32.756707]]]},"properties":{"id":"88023","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.66405,35.00828],[-105.83322,34.65444],[-105.699978,34.379246],[-105.430739,34.482895],[-105.312762,34.463254],[-105.080342,34.879128],[-105.290884,35.042093],[-105.572471,35.041851],[-105.682815,35.041571],[-105.66405,35.00828]]]},"properties":{"id":"88321","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.720781,36.085602],[-103.6961,35.950998],[-103.642163,35.822876],[-103.636652,35.830791],[-103.20323,36.070788],[-103.04131,36.228978],[-103.002119,36.801307],[-103.360821,36.655717],[-103.720781,36.085602]]]},"properties":{"id":"88415","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.03026,35.853824],[-104.367447,35.781428],[-104.549541,35.361917],[-104.318449,35.381124],[-103.857993,35.623167],[-104.03026,35.853824]]]},"properties":{"id":"87746","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.12709,32.870787],[-103.093286,32.827444],[-103.27483,32.856743],[-103.624218,32.829043],[-103.814678,32.825308],[-103.813853,32.529317],[-103.722938,32.522531],[-103.379252,32.604225],[-103.152991,32.591216],[-103.064734,32.562206],[-103.064566,32.899909],[-103.12709,32.870787]]]},"properties":{"id":"88240","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.814678,32.825308],[-103.624218,32.829043],[-103.766341,32.965411],[-103.8146,32.965203],[-103.814678,32.825308]]]},"properties":{"id":"88264","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.643316,33.344751],[-105.735015,33.390559],[-105.61402,33.305128],[-105.643316,33.344751]],[[-105.669076,33.360079],[-105.667462,33.360723],[-105.666988,33.359879],[-105.668545,33.359416],[-105.669076,33.360079]]]},"properties":{"id":"88345","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.73835,33.605582],[-105.927915,33.389938],[-105.784501,33.390482],[-105.686235,33.448943],[-105.73835,33.605582]]]},"properties":{"id":"88341","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.885477,33.349854],[-105.18467,33.315517],[-105.282513,33.132407],[-105.162144,33.132196],[-104.885322,33.344195],[-104.885477,33.349854]]]},"properties":{"id":"88343","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.509633,33.42322],[-105.463032,33.314228],[-105.386707,33.375633],[-105.382006,33.422095],[-105.333533,33.411479],[-105.341927,33.424905],[-105.518727,33.446832],[-105.509633,33.42322]]]},"properties":{"id":"88324","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.000927,32.517088],[-108.217286,32.016201],[-108.208394,31.783599],[-107.865886,31.783595],[-107.296825,31.78368],[-107.298208,32.220107],[-107.298715,32.385984],[-107.29792,32.512401],[-107.299463,32.605534],[-107.608283,32.605382],[-107.820763,32.604647],[-108.000927,32.517088]]]},"properties":{"id":"88030","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.370121,35.556073],[-108.309907,35.608853],[-108.449641,35.700016],[-108.647905,35.659812],[-108.70575,35.600293],[-108.635188,35.533792],[-108.644746,35.52307],[-108.657206,35.507781],[-108.639285,35.490264],[-108.370121,35.556073]]]},"properties":{"id":"87311","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.644746,35.52307],[-108.635188,35.533792],[-108.667414,35.528519],[-108.655041,35.515805],[-108.644746,35.52307]]]},"properties":{"id":"87322","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.774584,35.737843],[-108.647905,35.659812],[-108.449641,35.700016],[-108.392875,35.901865],[-108.722545,35.771942],[-108.77473,35.74105],[-108.774584,35.737843]]]},"properties":{"id":"87310","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.032738,35.559652],[-107.936182,35.507832],[-107.838651,35.805726],[-108.130356,36.104493],[-108.324508,36.203199],[-108.392875,35.901865],[-108.449641,35.700016],[-108.309907,35.608853],[-108.032738,35.559652]]]},"properties":{"id":"87313","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.358594,35.53114],[-108.391681,35.41],[-108.280313,35.348979],[-108.358594,35.53114]]]},"properties":{"id":"87312","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.249957,35.941058],[-105.239615,35.838882],[-105.05904,35.780098],[-105.166667,35.938625],[-105.249957,35.941058]]]},"properties":{"id":"87712","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-105.439141,35.804195],[-105.524329,35.870721],[-105.540073,35.792842],[-105.439141,35.804195]]],[[[-105.302956,35.85755],[-105.4124,35.80068],[-105.36057,35.74575],[-105.023855,35.756966],[-105.05904,35.780098],[-105.239615,35.838882],[-105.302956,35.85755]]]]},"properties":{"id":"87745","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.923067,35.80764],[-104.889532,35.822386],[-104.916575,35.854521],[-104.949201,35.840999],[-104.923067,35.80764]]]},"properties":{"id":"87750","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.95034,34.86671],[-103.706635,34.619772],[-103.471127,34.865625],[-103.773904,34.836424],[-103.95034,34.86671]]]},"properties":{"id":"88427","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.895767,37.000132],[-106.562486,36.779459],[-106.535265,36.74272],[-106.309113,36.768552],[-106.895767,37.000132]]]},"properties":{"id":"87520","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.096148,36.116068],[-106.07964,36.029069],[-106.025078,36.058795],[-106.034933,36.124668],[-106.083956,36.124712],[-106.096148,36.116068]]]},"properties":{"id":"87566","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.161717,36.127343],[-106.303068,36.037666],[-106.32971,36.001231],[-106.07964,36.029069],[-106.096148,36.116068],[-106.142515,36.109891],[-106.161717,36.127343]]]},"properties":{"id":"87537","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.034933,36.124668],[-106.025078,36.058795],[-105.905594,36.043095],[-105.865102,36.055865],[-105.797772,36.079448],[-105.832912,36.112482],[-105.94583,36.159953],[-106.034933,36.124668]]]},"properties":{"id":"87511","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.305295,36.626141],[-106.460732,36.577642],[-106.499584,36.453703],[-106.310306,36.438426],[-106.275248,36.535624],[-106.290151,36.606618],[-106.305295,36.626141]]]},"properties":{"id":"87515","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.290151,36.606618],[-106.011959,36.534203],[-105.952998,36.534364],[-105.819226,36.495197],[-105.73298,36.476505],[-105.725592,36.489769],[-105.714638,36.567685],[-106.008612,36.995331],[-106.305295,36.626141],[-106.290151,36.606618]]]},"properties":{"id":"87577","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.11495,33.773986],[-103.046341,33.879447],[-103.043617,34.00364],[-103.334891,33.909408],[-103.334592,33.88013],[-103.283545,33.821635],[-103.11495,33.773986]]]},"properties":{"id":"88132","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.332502,35.638483],[-106.348036,35.660708],[-106.350131,35.638538],[-106.332502,35.638483]]]},"properties":{"id":"87083","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.625775,36.088427],[-107.411418,36.217636],[-107.460358,36.217775],[-107.62425,36.219799],[-107.625775,36.088427]]]},"properties":{"id":"87018","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.16783,36.791535],[-108.145194,36.750765],[-108.097324,36.78467],[-108.134092,36.820516],[-108.16783,36.791535]]]},"properties":{"id":"87402","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.063507,36.850112],[-108.134092,36.820516],[-108.097324,36.78467],[-108.063507,36.850112]]]},"properties":{"id":"87415","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.299378,32.750921],[-107.299377,32.779884],[-107.292424,32.794535],[-107.283886,32.80214],[-107.297151,32.87941],[-107.495102,32.945867],[-107.299378,32.750921]]]},"properties":{"id":"87930","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.565374,33.170071],[-107.277254,33.041153],[-107.293872,32.896695],[-107.043287,33.478031],[-107.220462,33.47813],[-107.351956,33.477807],[-107.551586,33.347375],[-107.565374,33.170071]],[[-107.258368,33.290914],[-107.177543,33.29825],[-107.201806,33.194561],[-107.258368,33.290914]]]},"properties":{"id":"87901","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.799549,34.417483],[-106.848171,34.405812],[-106.88152,34.325403],[-106.840947,34.28233],[-106.78212,34.305675],[-106.625586,34.339188],[-106.799549,34.417483]]]},"properties":{"id":"87028","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.537333,36.610462],[-105.506971,36.574831],[-105.452501,36.533854],[-105.415545,36.549415],[-105.537333,36.610462]]]},"properties":{"id":"87525","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.585411,36.335883],[-105.328033,36.276469],[-105.353031,36.443061],[-105.553153,36.50717],[-105.596192,36.52218],[-105.602205,36.523782],[-105.64888,36.533042],[-105.725592,36.489769],[-105.73298,36.476505],[-105.730829,36.339246],[-105.71504,36.350211],[-105.585411,36.335883]]]},"properties":{"id":"87571","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.537333,36.610462],[-105.626841,36.56777],[-105.632449,36.541363],[-105.621216,36.534194],[-105.59947,36.532157],[-105.506971,36.574831],[-105.537333,36.610462]]]},"properties":{"id":"87580","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.846491,36.560259],[-103.883512,36.174191],[-103.720781,36.085602],[-103.360821,36.655717],[-103.361011,36.707163],[-103.846491,36.560259]]]},"properties":{"id":"88424","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.552164,35.11643],[-106.550592,35.087348],[-106.480304,35.112821],[-106.552164,35.11643]]]},"properties":{"id":"87112","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.261103,35.121849],[-106.155949,35.19925],[-106.083831,35.29235],[-106.248888,35.377506],[-106.355168,35.365633],[-106.418652,35.217428],[-106.323215,35.138362],[-106.261103,35.121849]]]},"properties":{"id":"87047","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.671068,33.967543],[-108.473046,33.798258],[-108.560378,33.996658],[-108.671068,33.967543]]]},"properties":{"id":"87820","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.510267,33.570256],[-104.463073,33.996416],[-104.893141,34.088304],[-104.893505,34.047515],[-104.894473,33.911787],[-104.885477,33.349854],[-104.885322,33.344195],[-104.431446,33.395072],[-104.42272,33.39723],[-103.721459,33.435581],[-103.510267,33.570256]]]},"properties":{"id":"88201","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.946052,34.244072],[-104.049564,34.082564],[-104.463073,33.996416],[-103.510267,33.570256],[-103.50402,33.570316],[-103.410234,33.570356],[-103.458021,33.788295],[-103.440337,33.87219],[-103.518648,34.055286],[-103.861724,34.273694],[-103.946052,34.244072]]]},"properties":{"id":"88116","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.569724,32.548051],[-105.522531,32.422131],[-105.110455,32.520652],[-105.24591,32.798077],[-105.352367,32.793387],[-105.460189,32.670895],[-105.569724,32.548051]]]},"properties":{"id":"88344","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.841229,32.963303],[-105.162144,33.132196],[-105.282513,33.132407],[-105.316882,33.132218],[-105.316224,33.002719],[-105.626268,32.821233],[-105.544031,32.804206],[-105.352367,32.793387],[-105.24591,32.798077],[-104.841516,32.887651],[-104.841229,32.963303]]]},"properties":{"id":"88339","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.556431,35.07129],[-107.751639,35.078622],[-107.751774,35.072866],[-107.806015,34.987006],[-107.708661,34.577673],[-107.577323,34.579498],[-107.39376,34.579535],[-107.20441,34.578813],[-107.203012,34.796969],[-107.545038,34.973823],[-107.544826,35.067583],[-107.556431,35.07129]]]},"properties":{"id":"87034","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.358594,35.53114],[-108.280313,35.348979],[-108.318139,35.306506],[-108.114926,35.283569],[-108.032738,35.559652],[-108.309907,35.608853],[-108.370121,35.556073],[-108.358594,35.53114]]]},"properties":{"id":"87323","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.751639,35.078622],[-107.556431,35.07129],[-107.534835,35.226836],[-107.751639,35.078622]]]},"properties":{"id":"87049","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.805161,36.55257],[-104.53157,36.533851],[-104.736026,36.598006],[-104.805161,36.55257]]]},"properties":{"id":"87728","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.471127,34.865625],[-103.495951,34.715953],[-103.214798,34.719662],[-103.214691,34.748878],[-103.26423,34.954208],[-103.496022,35.146812],[-103.773904,34.836424],[-103.471127,34.865625]]]},"properties":{"id":"88120","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.26423,34.954208],[-103.214691,34.748878],[-103.042923,34.821133],[-103.042687,34.954101],[-103.165543,34.954039],[-103.26423,34.954208]]]},"properties":{"id":"88112","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.376975,32.343593],[-106.079036,32.375894],[-106.1457,32.401834],[-106.151339,32.404933],[-106.376946,32.388705],[-106.376975,32.343593]]]},"properties":{"id":"88081","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.7697,32.34792],[-106.714533,32.236465],[-106.680116,32.188565],[-106.627046,32.204088],[-106.581367,32.204976],[-106.513902,32.344735],[-106.581273,32.431258],[-106.581205,32.423791],[-106.61333,32.42025],[-106.7697,32.34792]]]},"properties":{"id":"88011","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.78902,32.33023],[-106.74011,32.23352],[-106.714533,32.236465],[-106.7697,32.34792],[-106.76981,32.34809],[-106.77607,32.34294],[-106.783688,32.334193],[-106.78902,32.33023]],[[-106.75388,32.28447],[-106.74727,32.28524],[-106.74617,32.28077],[-106.74962,32.27576],[-106.75388,32.28447]]]},"properties":{"id":"88001","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.90355,32.20882],[-106.944601,32.072089],[-106.816414,31.97042],[-106.673786,32.062415],[-106.64964,32.07455],[-106.64476,32.08261],[-106.668801,32.113514],[-106.68285,32.128772],[-106.70623,32.17987],[-106.73744,32.20554],[-106.79796,32.22828],[-106.798362,32.227739],[-106.90355,32.20882]]]},"properties":{"id":"88044","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.292424,32.794535],[-107.299377,32.779884],[-107.213315,32.694169],[-107.225429,32.743162],[-107.25945,32.780119],[-107.292424,32.794535]]]},"properties":{"id":"87936","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.851525,32.520505],[-104.847757,32.00052],[-104.176271,31.999991],[-104.101218,32.219921],[-103.722752,32.405904],[-103.722938,32.522531],[-103.813853,32.529317],[-104.421984,32.587789],[-104.551372,32.613304],[-104.629137,32.599199],[-104.851525,32.520505]],[[-104.383732,32.172942],[-104.383878,32.17777],[-104.379645,32.177611],[-104.377562,32.173537],[-104.383732,32.172942]]]},"properties":{"id":"88220","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.182806,32.764471],[-108.155536,32.817574],[-108.058877,32.887241],[-108.029376,33.201507],[-108.155355,33.201245],[-108.20837,33.200967],[-108.294954,33.20102],[-108.194957,32.941088],[-108.182598,32.891872],[-108.237917,32.896006],[-108.594205,32.940707],[-108.616207,32.773743],[-108.689713,32.603597],[-108.327838,32.091158],[-108.217286,32.016201],[-108.000927,32.517088],[-108.027007,32.542054],[-108.164037,32.723852],[-108.182806,32.764471]],[[-108.31534,32.688847],[-108.323966,32.665731],[-108.336472,32.674629],[-108.31534,32.688847]]]},"properties":{"id":"88061","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.058877,32.887241],[-107.855879,33.004526],[-107.855199,33.041117],[-108.029376,33.201507],[-108.058877,32.887241]]]},"properties":{"id":"88049","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.080342,34.879128],[-104.891953,34.582968],[-104.607952,34.604384],[-104.129424,34.779324],[-104.125094,35.079375],[-104.17639,35.06585],[-104.268453,34.979502],[-104.54961,34.99346],[-104.422795,35.166361],[-104.443294,35.216266],[-104.630679,35.215951],[-104.903133,35.21646],[-105.04836,35.16957],[-105.096193,35.167224],[-105.290798,35.085541],[-105.290884,35.042093],[-105.080342,34.879128]]]},"properties":{"id":"88435","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.6961,35.950998],[-103.720781,36.085602],[-103.883512,36.174191],[-103.991785,36.174626],[-104.371498,36.067042],[-104.367447,35.781428],[-104.03026,35.853824],[-103.6961,35.950998]]]},"properties":{"id":"87743","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.3265,32.544313],[-103.442086,32.418671],[-103.064704,32.334653],[-103.064734,32.562206],[-103.152991,32.591216],[-103.3265,32.544313]]]},"properties":{"id":"88231","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.50402,33.570316],[-103.054381,33.483558],[-103.05262,33.570599],[-103.410234,33.570356],[-103.50402,33.570316]]]},"properties":{"id":"88114","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.847819,35.364115],[-109.024223,35.343498],[-109.046587,35.330478],[-108.814959,35.122112],[-108.65331,35.306713],[-108.847819,35.364115]]]},"properties":{"id":"87326","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.046184,35.72514],[-108.939468,35.76526],[-108.957622,35.817934],[-108.98214,35.843088],[-109.046152,35.828724],[-109.046184,35.72514]]]},"properties":{"id":"86504","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.916129,33.026867],[-105.922999,32.959507],[-105.825411,32.980106],[-105.775404,33.003982],[-105.827982,33.053993],[-105.916129,33.026867]]]},"properties":{"id":"88337","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.626268,32.821233],[-105.746411,32.755797],[-105.710764,32.727648],[-105.66058,32.713593],[-105.544031,32.804206],[-105.626268,32.821233]]]},"properties":{"id":"88347","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.165543,34.954039],[-103.042487,35.212023],[-103.042256,35.39489],[-103.209752,35.390273],[-103.424082,35.282899],[-103.496022,35.146812],[-103.26423,34.954208],[-103.165543,34.954039]]]},"properties":{"id":"88434","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.275248,36.535624],[-106.147751,36.405939],[-106.063943,36.436794],[-106.011959,36.534203],[-106.290151,36.606618],[-106.275248,36.535624]]]},"properties":{"id":"87581","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.656666,35.377719],[-106.597672,35.280886],[-106.606073,35.217439],[-106.593216,35.217953],[-106.573747,35.217933],[-106.494379,35.358544],[-106.489001,35.395755],[-106.656666,35.377719]]]},"properties":{"id":"87004","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.723407,35.83893],[-106.757982,35.575224],[-106.637477,35.623561],[-106.680136,35.650047],[-106.723407,35.83893]]]},"properties":{"id":"87024","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.903133,35.21646],[-104.630679,35.215951],[-104.549541,35.361917],[-104.367447,35.781428],[-104.63881,35.778612],[-105.023855,35.756966],[-105.36057,35.74575],[-105.506138,35.696334],[-105.506437,35.566685],[-105.43591,35.436141],[-105.394015,35.434129],[-105.17953,35.305539],[-104.903133,35.21646]]]},"properties":{"id":"87701","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.715494,35.273626],[-105.572471,35.041851],[-105.290884,35.042093],[-105.290798,35.085541],[-105.291139,35.185589],[-105.335112,35.263175],[-105.394015,35.434129],[-105.43591,35.436141],[-105.59853,35.412894],[-105.715494,35.273626]]]},"properties":{"id":"87560","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.64643,35.4679],[-105.506437,35.566685],[-105.669139,35.534314],[-105.672688,35.523922],[-105.64643,35.4679]]]},"properties":{"id":"87538","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.824644,35.549739],[-105.771798,35.42129],[-105.717886,35.384162],[-105.672688,35.523922],[-105.669139,35.534314],[-105.718063,35.652701],[-105.774525,35.64635],[-105.824644,35.549739]]]},"properties":{"id":"87535","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.296228,35.351335],[-104.216107,35.215806],[-104.150642,35.354442],[-104.296228,35.351335]]]},"properties":{"id":"88416","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.856184,35.82089],[-105.908591,35.8253],[-105.893645,35.793821],[-105.856184,35.82089]]]},"properties":{"id":"87574","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.987729,36.002084],[-106.040522,36.002093],[-106.025418,35.983353],[-105.978443,36.000501],[-105.987729,36.002084]]]},"properties":{"id":"87567","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.258368,33.290914],[-107.201806,33.194561],[-107.177543,33.29825],[-107.258368,33.290914]]]},"properties":{"id":"87935","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.959445,34.227922],[-106.924726,34.191094],[-106.90462,34.221172],[-106.922981,34.289268],[-106.959445,34.227922]]]},"properties":{"id":"87831","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.90462,34.221172],[-106.924726,34.191094],[-106.907362,34.196493],[-106.90462,34.221172]]]},"properties":{"id":"87828","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.298782,36.804877],[-105.540456,36.624571],[-105.537333,36.610462],[-105.415545,36.549415],[-105.3777,36.552295],[-105.261542,36.719665],[-105.233938,36.784683],[-105.298782,36.804877]]]},"properties":{"id":"87558","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.711778,36.162741],[-105.61309,36.048558],[-105.683117,36.16251],[-105.711778,36.162741]]]},"properties":{"id":"87543","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.604087,35.129745],[-106.604197,35.105162],[-106.603181,35.087476],[-106.550592,35.087348],[-106.552164,35.11643],[-106.551016,35.127546],[-106.604087,35.129745]]]},"properties":{"id":"87110","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.652772,35.182083],[-106.620021,35.170474],[-106.593216,35.217953],[-106.606073,35.217439],[-106.662621,35.217323],[-106.763162,35.218141],[-106.758499,35.188763],[-106.652772,35.182083]]]},"properties":{"id":"87114","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.652772,35.182083],[-106.689882,35.130944],[-106.657956,35.112254],[-106.612858,35.107132],[-106.604197,35.105162],[-106.604087,35.129745],[-106.605631,35.14029],[-106.620021,35.170474],[-106.652772,35.182083]]]},"properties":{"id":"87107","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.046156,34.579291],[-109.046805,33.956747],[-108.912873,33.930079],[-108.671068,33.967543],[-108.560378,33.996658],[-108.244448,34.161148],[-108.218433,34.579968],[-108.273274,34.697214],[-108.362216,34.696796],[-108.922029,34.579936],[-109.046156,34.579291]]]},"properties":{"id":"87829","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.046854,33.617154],[-108.496621,33.431026],[-108.466736,33.640018],[-108.473046,33.798258],[-108.671068,33.967543],[-108.912873,33.930079],[-109.046918,33.691131],[-109.046854,33.617154]]]},"properties":{"id":"87830","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.046805,33.956747],[-109.046918,33.691131],[-108.912873,33.930079],[-109.046805,33.956747]]]},"properties":{"id":"87824","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-104.595188,33.138446],[-104.431446,33.395072],[-104.885322,33.344195],[-105.162144,33.132196],[-104.841229,32.963303],[-104.655297,32.963743],[-104.664063,33.02928],[-104.595188,33.138446]]],[[[-104.182525,32.965146],[-103.8146,32.965203],[-103.766341,32.965411],[-103.766252,33.162698],[-103.721459,33.435581],[-104.42272,33.39723],[-104.261442,33.139225],[-104.241881,33.099476],[-104.182525,32.965146]]]]},"properties":{"id":"88203","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.595188,33.138446],[-104.261442,33.139225],[-104.42272,33.39723],[-104.431446,33.395072],[-104.595188,33.138446]]]},"properties":{"id":"88230","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.831955,36.43522],[-104.870094,36.261123],[-104.436121,36.218761],[-104.170145,36.218344],[-104.138278,36.430756],[-104.53157,36.533851],[-104.805161,36.55257],[-104.831973,36.482694],[-104.831955,36.43522]]]},"properties":{"id":"87747","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.214798,34.719662],[-103.073524,34.302673],[-103.043936,34.302585],[-103.042923,34.821133],[-103.214691,34.748878],[-103.214798,34.719662]]]},"properties":{"id":"88135","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.022608,34.605093],[-104.049564,34.082564],[-103.946052,34.244072],[-103.948839,34.605058],[-104.022608,34.605093]]]},"properties":{"id":"88134","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.606141,31.846201],[-106.630269,31.885832],[-106.68194,31.783926],[-106.569932,31.783953],[-106.64493,31.82992],[-106.606141,31.846201]]]},"properties":{"id":"88008","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.648401,32.562395],[-106.581273,32.431258],[-106.513902,32.344735],[-106.376975,32.343593],[-106.376946,32.388705],[-106.648401,32.562395]]]},"properties":{"id":"88002","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.73744,32.20554],[-106.70623,32.17987],[-106.680116,32.188565],[-106.714533,32.236465],[-106.74011,32.23352],[-106.73744,32.20554]]]},"properties":{"id":"88047","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.299377,32.779884],[-107.299378,32.750921],[-107.299463,32.605534],[-107.29792,32.512401],[-107.10038,32.54767],[-106.997284,32.562581],[-107.15455,32.6834],[-107.183281,32.692578],[-107.213315,32.694169],[-107.299377,32.779884]]]},"properties":{"id":"87937","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.909597,32.242292],[-107.04391,32.25761],[-107.026643,32.168491],[-106.92222,32.20374],[-106.909597,32.242292]]]},"properties":{"id":"88004","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.101218,32.219921],[-104.176271,31.999991],[-103.983427,32.000121],[-104.101218,32.219921]]]},"properties":{"id":"88263","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.383732,32.172942],[-104.377562,32.173537],[-104.379645,32.177611],[-104.383878,32.17777],[-104.383732,32.172942]]]},"properties":{"id":"88268","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.136946,32.774003],[-108.129823,32.756707],[-108.05081,32.618841],[-107.820763,32.604647],[-107.608283,32.605382],[-107.855879,33.004526],[-108.058877,32.887241],[-108.155536,32.817574],[-108.153635,32.782255],[-108.136946,32.774003]]]},"properties":{"id":"88041","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.594205,32.940707],[-108.237917,32.896006],[-108.194957,32.941088],[-108.294954,33.20102],[-108.743776,33.209626],[-108.638925,33.100164],[-108.594205,32.940707]]]},"properties":{"id":"88038","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.736988,32.906253],[-108.832251,32.889103],[-108.942699,32.777842],[-108.689713,32.603597],[-108.616207,32.773743],[-108.736988,32.906253]]]},"properties":{"id":"88055","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.03026,35.853824],[-103.857993,35.623167],[-103.807127,35.546482],[-103.642163,35.822876],[-103.6961,35.950998],[-104.03026,35.853824]]]},"properties":{"id":"87733","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.624218,32.829043],[-103.27483,32.856743],[-103.12709,32.870787],[-103.064566,32.899909],[-103.061736,33.140884],[-103.300303,33.140884],[-103.453372,33.082928],[-103.507275,33.170077],[-103.766252,33.162698],[-103.766341,32.965411],[-103.624218,32.829043]]]},"properties":{"id":"88260","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.300303,33.140884],[-103.507275,33.170077],[-103.453372,33.082928],[-103.300303,33.140884]]]},"properties":{"id":"88262","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.885477,33.349854],[-104.894473,33.911787],[-105.487731,33.517671],[-105.272453,33.437799],[-105.18467,33.315517],[-104.885477,33.349854]]]},"properties":{"id":"88351","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.90182,34.259985],[-106.116385,34.173635],[-105.925878,33.825553],[-105.72568,34.052491],[-105.90182,34.259985]]]},"properties":{"id":"87011","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.296825,31.78368],[-107.865886,31.783595],[-107.296825,31.783671],[-107.296825,31.78368]]]},"properties":{"id":"88029","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.472545,35.358303],[-108.381357,35.453514],[-108.430734,35.499022],[-108.472545,35.358303]]]},"properties":{"id":"87347","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.045998,36.1401],[-109.046152,35.828724],[-108.98214,35.843088],[-108.894728,36.08397],[-109.045998,36.1401]]]},"properties":{"id":"87328","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.319889,36.037731],[-105.5367,35.99148],[-105.621326,35.977334],[-105.302956,35.85755],[-105.239615,35.838882],[-105.249957,35.941058],[-105.229419,36.016329],[-105.327216,36.121715],[-105.344202,36.085964],[-105.319889,36.037731]]]},"properties":{"id":"87732","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.857979,33.217571],[-105.916129,33.026867],[-105.827982,33.053993],[-105.857979,33.217571]]]},"properties":{"id":"88314","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.827982,33.053993],[-105.775404,33.003982],[-105.316224,33.002719],[-105.316882,33.132218],[-105.463184,33.305149],[-105.61402,33.305128],[-105.735015,33.390559],[-105.784501,33.390482],[-105.927915,33.389938],[-105.931629,33.389874],[-105.857979,33.217571],[-105.827982,33.053993]]]},"properties":{"id":"88340","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.042487,35.212023],[-103.165543,34.954039],[-103.042687,34.954101],[-103.042487,35.212023]]]},"properties":{"id":"88411","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.469456,36.087849],[-106.426736,36.23837],[-106.528864,36.006177],[-106.469456,36.087849]]]},"properties":{"id":"87516","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.32971,36.001231],[-106.166302,35.93076],[-105.962213,35.925143],[-105.978443,36.000501],[-106.025418,35.983353],[-106.040522,36.002093],[-105.987729,36.002084],[-105.905594,36.043095],[-106.025078,36.058795],[-106.07964,36.029069],[-106.32971,36.001231]],[[-106.064899,36.021122],[-106.063713,36.023999],[-106.068982,36.023942],[-106.069004,36.024698],[-106.064058,36.024769],[-106.063612,36.023248],[-106.064899,36.021122]]]},"properties":{"id":"87532","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.535265,36.74272],[-106.772229,36.664488],[-106.848408,36.5211],[-106.742149,36.506771],[-106.72915,36.507058],[-106.674842,36.565889],[-106.460732,36.577642],[-106.305295,36.626141],[-106.008612,36.995331],[-106.309113,36.768552],[-106.535265,36.74272]]]},"properties":{"id":"87575","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.495194,37.000005],[-107.661804,37.000003],[-107.727483,36.820064],[-107.265142,36.857957],[-107.420915,37],[-107.451445,36.904036],[-107.536773,36.907144],[-107.495194,37.000005]]]},"properties":{"id":"87419","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.94583,36.159953],[-105.832912,36.112482],[-105.836321,36.20881],[-105.956296,36.17215],[-105.94583,36.159953]]]},"properties":{"id":"87527","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.334891,33.909408],[-103.336063,33.878694],[-103.334592,33.88013],[-103.334891,33.909408]]]},"properties":{"id":"88115","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.656666,35.377719],[-106.857297,35.356708],[-106.627871,35.265621],[-106.597672,35.280886],[-106.656666,35.377719]]]},"properties":{"id":"87144","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.128177,36.64024],[-108.130236,36.15893],[-107.96615,36.117704],[-107.727889,36.322968],[-107.839568,36.585171],[-107.856611,36.734233],[-108.014091,36.761001],[-108.128177,36.64024]]]},"properties":{"id":"87413","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.219511,36.916223],[-108.29287,36.737059],[-108.314194,36.723885],[-108.252669,36.709598],[-108.210493,36.639048],[-108.128177,36.64024],[-108.014091,36.761001],[-108.145194,36.750765],[-108.16783,36.791535],[-108.170742,36.885319],[-108.219511,36.916223]]]},"properties":{"id":"87401","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.860265,36.094934],[-108.532456,36.176063],[-108.837214,36.171363],[-108.860265,36.094934]]]},"properties":{"id":"87364","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.04572,36.454381],[-108.768803,36.369376],[-108.614432,36.417933],[-108.561808,36.714567],[-108.542223,36.744186],[-108.634222,36.806119],[-109.04572,36.454381]]]},"properties":{"id":"87420","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.634222,36.806119],[-108.542223,36.744186],[-108.443951,36.77202],[-108.634222,36.806119]]]},"properties":{"id":"87421","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.17953,35.305539],[-105.394015,35.434129],[-105.335112,35.263175],[-105.273698,35.248078],[-105.17953,35.305539]]]},"properties":{"id":"87569","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.840947,34.28233],[-106.88152,34.325403],[-106.922981,34.289268],[-106.90462,34.221172],[-106.907362,34.196493],[-106.924726,34.191094],[-106.959445,34.227922],[-106.904891,34.118252],[-106.840947,34.28233]]]},"properties":{"id":"87823","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.507394,34.457741],[-106.77269,34.533133],[-106.799549,34.417483],[-106.625586,34.339188],[-106.507394,34.457741]]]},"properties":{"id":"87062","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.819226,36.495197],[-105.820284,36.292113],[-105.730829,36.339246],[-105.73298,36.476505],[-105.819226,36.495197]]]},"properties":{"id":"87517","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.671264,36.757667],[-105.595293,36.73424],[-105.603889,36.800146],[-105.676131,36.799416],[-105.671264,36.757667]]]},"properties":{"id":"87519","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.846491,36.560259],[-103.361011,36.707163],[-103.996031,36.753373],[-104.007733,36.742379],[-104.008447,36.572659],[-103.846491,36.560259]]]},"properties":{"id":"88418","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.882636,34.579456],[-106.77269,34.533133],[-106.507394,34.457741],[-106.466192,34.67043],[-106.466203,34.67095],[-106.882636,34.579456]],[[-106.771207,34.570596],[-106.772353,34.610202],[-106.74423,34.60145],[-106.771207,34.570596]]]},"properties":{"id":"87002","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.657956,35.112254],[-106.667366,35.074992],[-106.637625,35.047562],[-106.612858,35.107132],[-106.657956,35.112254]]]},"properties":{"id":"87102","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.323215,35.138362],[-106.42915,35.108359],[-106.426053,35.011795],[-106.373193,34.870338],[-106.149575,34.870142],[-106.162664,34.95335],[-106.261103,35.121849],[-106.323215,35.138362]]]},"properties":{"id":"87059","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.398812,34.882839],[-108.256171,34.886815],[-108.309731,34.99728],[-108.338576,34.927128],[-108.398812,34.882839]]]},"properties":{"id":"87357","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.518959,34.958988],[-108.539169,35.291796],[-108.5724,35.306858],[-108.65331,35.306713],[-108.814959,35.122112],[-109.046156,34.579291],[-108.922029,34.579936],[-108.518959,34.958988]]]},"properties":{"id":"87327","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.196775,36.566899],[-105.280094,36.636731],[-105.261542,36.719665],[-105.3777,36.552295],[-105.335095,36.484072],[-105.22387,36.535064],[-105.132541,36.546411],[-105.196775,36.566899]]]},"properties":{"id":"87718","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.318947,34.395342],[-103.337067,34.372681],[-103.302622,34.36563],[-103.318947,34.395342]]]},"properties":{"id":"88103","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.92222,32.20374],[-106.90355,32.20882],[-106.798362,32.227739],[-106.816539,32.251463],[-106.80265,32.25968],[-106.79062,32.24406],[-106.79796,32.22828],[-106.73744,32.20554],[-106.74011,32.23352],[-106.78902,32.33023],[-106.909597,32.242292],[-106.92222,32.20374]]]},"properties":{"id":"88005","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.606141,31.846201],[-106.64493,31.82992],[-106.569932,31.783953],[-106.606141,31.846201]]]},"properties":{"id":"88063","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.673786,32.062415],[-106.816414,31.97042],[-106.683955,32.026158],[-106.673786,32.062415]]]},"properties":{"id":"88027","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.648401,32.562395],[-106.958289,32.622259],[-106.91032,32.52452],[-106.818853,32.402903],[-106.8081,32.3902],[-106.76981,32.34809],[-106.7697,32.34792],[-106.61333,32.42025],[-106.581273,32.431258],[-106.648401,32.562395]]]},"properties":{"id":"88012","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.54433,31.778032],[-108.851105,31.332301],[-108.208394,31.783599],[-108.217286,32.016201],[-108.327838,32.091158],[-108.525087,31.979758],[-108.54433,31.778032]]]},"properties":{"id":"88040","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.047117,32.777569],[-108.942699,32.777842],[-108.832251,32.889103],[-108.841428,33.208678],[-109.047237,33.208965],[-109.047117,32.777569]]]},"properties":{"id":"88051","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.31534,32.688847],[-108.336472,32.674629],[-108.323966,32.665731],[-108.31534,32.688847]]]},"properties":{"id":"88065","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.743776,33.209626],[-108.841428,33.208678],[-108.832251,32.889103],[-108.736988,32.906253],[-108.638925,33.100164],[-108.743776,33.209626]]]},"properties":{"id":"88025","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.153635,32.782255],[-108.182806,32.764471],[-108.164037,32.723852],[-108.157605,32.724369],[-108.136946,32.774003],[-108.153635,32.782255]]]},"properties":{"id":"88026","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.721459,33.435581],[-103.766252,33.162698],[-103.507275,33.170077],[-103.300303,33.140884],[-103.061736,33.140884],[-103.054381,33.483558],[-103.50402,33.570316],[-103.510267,33.570256],[-103.721459,33.435581]]]},"properties":{"id":"88267","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.463184,33.305149],[-105.316882,33.132218],[-105.282513,33.132407],[-105.18467,33.315517],[-105.272453,33.437799],[-105.341927,33.424905],[-105.333533,33.411479],[-105.386707,33.375633],[-105.463032,33.314228],[-105.463184,33.305149]]]},"properties":{"id":"88336","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-108.865882,35.514982],[-108.831938,35.550587],[-108.810588,35.57077],[-108.906467,35.631739],[-108.955031,35.623676],[-108.865882,35.514982]]],[[[-108.847819,35.364115],[-108.65331,35.306713],[-108.5724,35.306858],[-108.624932,35.443209],[-108.639285,35.490264],[-108.657206,35.507781],[-108.821873,35.479219],[-108.847819,35.364115]]]]},"properties":{"id":"87305","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.340907,36.262245],[-105.420959,36.147292],[-105.479281,36.052952],[-105.429361,36.079927],[-105.344202,36.085964],[-105.327216,36.121715],[-105.322493,36.262297],[-105.340907,36.262245]]]},"properties":{"id":"87713","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.66058,32.713593],[-105.460189,32.670895],[-105.352367,32.793387],[-105.544031,32.804206],[-105.66058,32.713593]]]},"properties":{"id":"88354","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.569724,32.548051],[-105.460189,32.670895],[-105.66058,32.713593],[-105.710764,32.727648],[-105.820163,32.726532],[-105.882406,32.715823],[-105.732761,32.567607],[-105.569724,32.548051]]]},"properties":{"id":"88350","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.916129,33.026867],[-106.081011,32.84353],[-106.067189,32.832054],[-106.082394,32.824475],[-106.195761,32.746862],[-106.151339,32.404933],[-106.1457,32.401834],[-106.064907,32.388137],[-105.732761,32.567607],[-105.882406,32.715823],[-105.922999,32.959507],[-105.916129,33.026867]],[[-105.998056,32.822741],[-105.975897,32.815379],[-105.979048,32.796745],[-105.998305,32.787982],[-105.998056,32.822741]]]},"properties":{"id":"88310","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.998056,32.822741],[-105.998305,32.787982],[-105.979048,32.796745],[-105.975897,32.815379],[-105.998056,32.822741]]]},"properties":{"id":"88311","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.562486,36.779459],[-106.772229,36.664488],[-106.535265,36.74272],[-106.562486,36.779459]]]},"properties":{"id":"87551","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.460732,36.577642],[-106.674842,36.565889],[-106.499584,36.453703],[-106.460732,36.577642]]]},"properties":{"id":"87518","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.147751,36.405939],[-106.054673,36.281005],[-105.978343,36.343049],[-105.94462,36.449373],[-106.063943,36.436794],[-106.147751,36.405939]]]},"properties":{"id":"87539","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.791397,36.002151],[-105.885411,36.008425],[-105.846513,36.042247],[-105.865102,36.055865],[-105.905594,36.043095],[-105.987729,36.002084],[-105.978443,36.000501],[-105.962213,35.925143],[-105.721847,35.822597],[-105.72028,35.871015],[-105.717374,35.97876],[-105.71689,35.994642],[-105.791397,36.002151]]]},"properties":{"id":"87522","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.336063,33.878694],[-103.440337,33.87219],[-103.458021,33.788295],[-103.283545,33.821635],[-103.334592,33.88013],[-103.336063,33.878694]]]},"properties":{"id":"88126","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.506138,35.696334],[-105.36057,35.74575],[-105.4124,35.80068],[-105.439141,35.804195],[-105.540073,35.792842],[-105.548277,35.780919],[-105.506138,35.696334]]]},"properties":{"id":"87731","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-105.717995,35.687682],[-105.858973,35.688753],[-105.774525,35.64635],[-105.718063,35.652701],[-105.717995,35.687682]]],[[[-105.717995,35.687682],[-105.548277,35.780919],[-105.540073,35.792842],[-105.524329,35.870721],[-105.72028,35.871015],[-105.721847,35.822597],[-105.721078,35.795388],[-105.717995,35.687682]]]]},"properties":{"id":"87573","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.318449,35.381124],[-104.549541,35.361917],[-104.630679,35.215951],[-104.443294,35.216266],[-104.318449,35.381124]]]},"properties":{"id":"88421","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.24856,35.565893],[-106.24859,35.530492],[-106.248472,35.444914],[-106.113996,35.534607],[-105.982835,35.625735],[-105.97687,35.677742],[-105.984422,35.69817],[-106.090725,35.652728],[-106.24856,35.565893]]]},"properties":{"id":"87507","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.984422,35.69817],[-105.893645,35.793821],[-105.908591,35.8253],[-105.856184,35.82089],[-105.721078,35.795388],[-105.721847,35.822597],[-105.962213,35.925143],[-106.166302,35.93076],[-106.090725,35.652728],[-105.984422,35.69817]]]},"properties":{"id":"87506","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.083831,35.29235],[-106.004915,35.385192],[-106.024042,35.399933],[-106.113996,35.534607],[-106.248472,35.444914],[-106.248491,35.443199],[-106.248888,35.377506],[-106.083831,35.29235]]]},"properties":{"id":"87010","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.507394,34.457741],[-106.625586,34.339188],[-106.56432,34.243587],[-106.411785,34.047493],[-106.116385,34.173635],[-105.90182,34.259985],[-105.839548,34.307383],[-106.06107,34.60905],[-106.25398,34.6815],[-106.466192,34.67043],[-106.507394,34.457741]]]},"properties":{"id":"87036","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.725592,36.489769],[-105.64888,36.533042],[-105.646594,36.533309],[-105.632449,36.541363],[-105.626841,36.56777],[-105.695803,36.625588],[-105.707182,36.693196],[-105.714638,36.567685],[-105.725592,36.489769]]]},"properties":{"id":"87513","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.25398,34.6815],[-106.443161,34.870193],[-106.464524,34.810517],[-106.466203,34.67095],[-106.466192,34.67043],[-106.25398,34.6815]]]},"properties":{"id":"87061","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.699414,34.946886],[-106.735238,34.870027],[-106.722732,34.87006],[-106.685889,34.90615],[-106.437024,34.90333],[-106.538215,34.947616],[-106.602873,35.029317],[-106.637625,35.047562],[-106.667366,35.074992],[-106.692339,35.106509],[-106.703689,35.106815],[-106.699414,34.946886]]]},"properties":{"id":"87105","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.573747,35.217933],[-106.551093,35.174244],[-106.533435,35.159747],[-106.415641,35.182724],[-106.459379,35.217942],[-106.573747,35.217933]]]},"properties":{"id":"87122","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.593216,35.217953],[-106.620021,35.170474],[-106.605631,35.14029],[-106.551093,35.174244],[-106.573747,35.217933],[-106.593216,35.217953]]]},"properties":{"id":"87113","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.532552,35.054681],[-106.490177,35.042648],[-106.426053,35.011795],[-106.42915,35.108359],[-106.436182,35.127353],[-106.480304,35.112821],[-106.550592,35.087348],[-106.550452,35.066134],[-106.532552,35.054681]]]},"properties":{"id":"87123","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.178524,35.182169],[-107.378554,35.061258],[-107.449464,35.041755],[-107.545038,34.973823],[-107.203012,34.796969],[-107.025022,34.871013],[-106.952958,35.058376],[-107.19617,35.219323],[-107.178524,35.182169]]]},"properties":{"id":"87026","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.685889,34.90615],[-106.722732,34.87006],[-106.720666,34.870053],[-106.685889,34.90615]]]},"properties":{"id":"87022","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-106.595254,35.058226],[-106.6043,35.058258],[-106.604308,35.057937],[-106.595254,35.058226]]],[[[-106.550452,35.066134],[-106.569579,35.051247],[-106.532552,35.054681],[-106.550452,35.066134]]]]},"properties":{"id":"87116","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-109.046854,33.617154],[-109.047237,33.208965],[-108.841428,33.208678],[-108.743776,33.209626],[-108.294954,33.20102],[-108.20837,33.200967],[-108.496621,33.431026],[-109.046854,33.617154]]]},"properties":{"id":"88039","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.629137,32.599199],[-104.841516,32.887651],[-105.24591,32.798077],[-105.110455,32.520652],[-104.851525,32.520505],[-104.629137,32.599199]]]},"properties":{"id":"88250","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.138278,36.430756],[-104.170145,36.218344],[-103.991785,36.174626],[-103.883512,36.174191],[-103.846491,36.560259],[-104.008447,36.572659],[-104.138278,36.430756]]]},"properties":{"id":"88422","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.10038,32.54767],[-107.298715,32.385984],[-107.298208,32.220107],[-106.944601,32.072089],[-106.90355,32.20882],[-106.92222,32.20374],[-107.026643,32.168491],[-107.04391,32.25761],[-106.909597,32.242292],[-106.78902,32.33023],[-106.783688,32.334193],[-106.77607,32.34294],[-106.76981,32.34809],[-106.8081,32.3902],[-106.81151,32.39057],[-106.818853,32.402903],[-106.91032,32.52452],[-106.997284,32.562581],[-107.10038,32.54767]],[[-107.0373,32.37119],[-106.939018,32.270804],[-107.101711,32.252632],[-107.0373,32.37119]]]},"properties":{"id":"88007","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.581273,32.431258],[-106.61333,32.42025],[-106.581205,32.423791],[-106.581273,32.431258]]]},"properties":{"id":"88052","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.183281,32.692578],[-107.225429,32.743162],[-107.213315,32.694169],[-107.183281,32.692578]]]},"properties":{"id":"87941","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.164037,32.723852],[-108.027007,32.542054],[-108.05081,32.618841],[-108.129823,32.756707],[-108.157605,32.724369],[-108.164037,32.723852]]]},"properties":{"id":"88043","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.891953,34.582968],[-105.080342,34.879128],[-105.312762,34.463254],[-105.312968,34.34672],[-104.89185,34.346989],[-104.891953,34.582968]]]},"properties":{"id":"88353","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.525087,31.979758],[-108.79763,32.1834],[-108.54433,31.778032],[-108.525087,31.979758]]]},"properties":{"id":"88009","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.152991,32.591216],[-103.379252,32.604225],[-103.3265,32.544313],[-103.152991,32.591216]]]},"properties":{"id":"88265","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.893505,34.047515],[-105.540664,33.996947],[-105.73835,33.605582],[-105.686235,33.448943],[-105.527274,33.496888],[-105.522801,33.498829],[-105.487731,33.517671],[-104.894473,33.911787],[-104.893505,34.047515]]]},"properties":{"id":"88316","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.669076,33.360079],[-105.668545,33.359416],[-105.666988,33.359879],[-105.667462,33.360723],[-105.669076,33.360079]]]},"properties":{"id":"88355","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.772619,35.769958],[-108.957622,35.817934],[-108.939468,35.76526],[-108.822095,35.740964],[-108.772619,35.769958]]]},"properties":{"id":"87320","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.327216,36.121715],[-105.229419,36.016329],[-105.183296,36.039844],[-105.195549,36.175694],[-105.211859,36.261868],[-105.322493,36.262297],[-105.327216,36.121715]]]},"properties":{"id":"87722","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.813554,32.845315],[-105.746411,32.755797],[-105.626268,32.821233],[-105.316224,33.002719],[-105.775404,33.003982],[-105.825411,32.980106],[-105.813554,32.845315]]]},"properties":{"id":"88317","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.706832,36.193146],[-106.660866,36.350478],[-106.72915,36.507058],[-106.742149,36.506771],[-106.8676,36.347387],[-106.885266,36.200466],[-106.770547,36.199542],[-106.706832,36.193146]]]},"properties":{"id":"87017","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.895767,37.000132],[-107.136092,36.21622],[-106.992055,36.21653],[-106.993628,36.259998],[-106.848408,36.5211],[-106.772229,36.664488],[-106.562486,36.779459],[-106.895767,37.000132]]]},"properties":{"id":"87528","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.72915,36.507058],[-106.660866,36.350478],[-106.426736,36.23837],[-106.469456,36.087849],[-106.303068,36.037666],[-106.161717,36.127343],[-106.215582,36.207421],[-106.310306,36.438426],[-106.499584,36.453703],[-106.674842,36.565889],[-106.72915,36.507058]]]},"properties":{"id":"87510","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.106874,36.219007],[-106.142515,36.109891],[-106.096148,36.116068],[-106.083956,36.124712],[-105.971981,36.197877],[-105.898493,36.244354],[-105.978343,36.343049],[-106.054673,36.281005],[-106.106874,36.219007]]]},"properties":{"id":"87549","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.727483,36.820064],[-107.856611,36.734233],[-107.839568,36.585171],[-107.624879,36.566271],[-107.590475,36.566482],[-107.265142,36.857957],[-107.727483,36.820064]]]},"properties":{"id":"87412","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.064899,36.021122],[-106.063612,36.023248],[-106.064058,36.024769],[-106.069004,36.024698],[-106.068982,36.023942],[-106.063713,36.023999],[-106.064899,36.021122]]]},"properties":{"id":"87533","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.956296,36.17215],[-105.836321,36.20881],[-105.71504,36.350211],[-105.730829,36.339246],[-105.820284,36.292113],[-105.898493,36.244354],[-105.971981,36.197877],[-105.956296,36.17215]]]},"properties":{"id":"87531","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.489001,35.395755],[-106.494379,35.358544],[-106.355168,35.365633],[-106.248888,35.377506],[-106.248491,35.443199],[-106.347554,35.465969],[-106.489001,35.395755]]]},"properties":{"id":"87001","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.661804,37.000003],[-108.063507,36.850112],[-108.097324,36.78467],[-108.145194,36.750765],[-108.014091,36.761001],[-107.856611,36.734233],[-107.727483,36.820064],[-107.661804,37.000003]]]},"properties":{"id":"87410","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.495194,37.000005],[-107.536773,36.907144],[-107.451445,36.904036],[-107.420915,37],[-107.495194,37.000005]]]},"properties":{"id":"81137","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.443951,36.77202],[-108.542223,36.744186],[-108.561808,36.714567],[-108.252669,36.709598],[-108.314194,36.723885],[-108.376147,36.764168],[-108.443951,36.77202]]]},"properties":{"id":"87416","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-108.376147,36.764168],[-108.314194,36.723885],[-108.29287,36.737059],[-108.376147,36.764168]]]},"properties":{"id":"87417","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.335112,35.263175],[-105.291139,35.185589],[-105.273698,35.248078],[-105.335112,35.263175]]]},"properties":{"id":"87583","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.506437,35.566685],[-105.64643,35.4679],[-105.59853,35.412894],[-105.43591,35.436141],[-105.506437,35.566685]]]},"properties":{"id":"87565","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.155949,35.19925],[-106.050049,35.040807],[-105.782075,35.041145],[-105.682815,35.041571],[-105.572471,35.041851],[-105.715494,35.273626],[-105.717886,35.384162],[-105.771798,35.42129],[-105.780654,35.428518],[-106.004915,35.385192],[-106.083831,35.29235],[-106.155949,35.19925]]]},"properties":{"id":"87056","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.780654,35.428518],[-106.024042,35.399933],[-106.004915,35.385192],[-105.780654,35.428518]]]},"properties":{"id":"87540","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-107.277254,33.041153],[-107.565374,33.170071],[-107.532524,33.062171],[-107.277254,33.041153]]]},"properties":{"id":"87942","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.76667,34.166067],[-106.89418,33.946934],[-107.004365,34.017297],[-107.220462,33.47813],[-107.043287,33.478031],[-106.411785,34.047493],[-106.56432,34.243587],[-106.76667,34.166067]]]},"properties":{"id":"87832","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.77269,34.533133],[-106.882636,34.579456],[-106.892729,34.579902],[-106.848171,34.405812],[-106.799549,34.417483],[-106.77269,34.533133]]]},"properties":{"id":"87006","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.683117,36.16251],[-105.61309,36.048558],[-105.53038,36.013014],[-105.479281,36.052952],[-105.420959,36.147292],[-105.593382,36.211411],[-105.662565,36.231113],[-105.683117,36.16251]]]},"properties":{"id":"87579","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.540456,36.624571],[-105.695803,36.625588],[-105.626841,36.56777],[-105.537333,36.610462],[-105.540456,36.624571]]]},"properties":{"id":"87564","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-105.522323,36.995826],[-105.676133,36.79969],[-105.569543,36.903731],[-105.522323,36.995826]]]},"properties":{"id":"87524","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.06107,34.60905],[-105.839548,34.307383],[-105.699978,34.379246],[-105.83322,34.65444],[-106.06107,34.60905]]]},"properties":{"id":"87063","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.996031,36.753373],[-104.008188,36.810169],[-104.007733,36.742379],[-103.996031,36.753373]]]},"properties":{"id":"88414","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.882636,34.579456],[-106.466203,34.67095],[-106.464524,34.810517],[-106.667254,34.815433],[-106.710589,34.820051],[-106.720666,34.870053],[-106.722732,34.87006],[-106.735238,34.870027],[-107.025022,34.871013],[-107.203012,34.796969],[-107.20441,34.578813],[-106.892729,34.579902],[-106.882636,34.579456]]]},"properties":{"id":"87031","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.437024,34.90333],[-106.685889,34.90615],[-106.720666,34.870053],[-106.710589,34.820051],[-106.685069,34.844302],[-106.667254,34.815433],[-106.464524,34.810517],[-106.443161,34.870193],[-106.410977,34.870254],[-106.437024,34.90333]]]},"properties":{"id":"87068","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-106.710589,34.820051],[-106.667254,34.815433],[-106.685069,34.844302],[-106.710589,34.820051]]]},"properties":{"id":"87042","state_code":"35"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.861895,42.761967],[-73.879516,42.740509],[-73.877814,42.719396],[-73.830238,42.694137],[-73.780018,42.681732],[-73.793759,42.726423],[-73.823538,42.757175],[-73.861895,42.761967]]]},"properties":{"id":"12205","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.122679,42.694684],[-74.09674,42.628827],[-74.031914,42.678041],[-73.920489,42.690931],[-73.920824,42.707029],[-73.963082,42.735629],[-74.029904,42.748447],[-74.1128,42.738478],[-74.122679,42.694684]],[[-73.964174,42.708807],[-73.964999,42.70597],[-73.957883,42.702709],[-73.962658,42.701751],[-73.966471,42.705478],[-73.964174,42.708807]]]},"properties":{"id":"12009","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.878796,42.368322],[-77.959498,42.299502],[-77.839235,42.267465],[-77.736485,42.288231],[-77.753219,42.348234],[-77.859063,42.368398],[-77.878796,42.368322]]]},"properties":{"id":"14804","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.955993,42.474325],[-77.968373,42.452107],[-77.969416,42.435186],[-77.95477,42.435323],[-77.944536,42.43536],[-77.955993,42.474325]]],[[[-77.955993,42.474325],[-77.840694,42.517771],[-77.840848,42.557438],[-77.954931,42.556431],[-77.955993,42.474325]]]]},"properties":{"id":"14836","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.043418,42.518464],[-78.045237,42.570018],[-78.10394,42.564888],[-78.133574,42.564883],[-78.145392,42.55133],[-78.128068,42.521367],[-78.043418,42.518464]]]},"properties":{"id":"14536","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.792942,40.883411],[-73.815165,40.888999],[-73.816752,40.864544],[-73.792942,40.883411]]]},"properties":{"id":"10464","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.852678,40.909899],[-73.877855,40.905922],[-73.878189,40.905983],[-73.862875,40.895154],[-73.844728,40.904732],[-73.852678,40.909899]]]},"properties":{"id":"10470","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.911948,40.820154],[-73.922088,40.813731],[-73.902988,40.809364],[-73.895428,40.815845],[-73.90299,40.820027],[-73.911948,40.820154]]]},"properties":{"id":"10455","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.884328,40.822614],[-73.842425,40.82806],[-73.842683,40.828497],[-73.84572,40.828284],[-73.884568,40.82316],[-73.884328,40.822614]]]},"properties":{"id":"10473","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.016936,42.258887],[-75.983166,42.347983],[-76.004005,42.377896],[-76.013987,42.385677],[-76.065572,42.399169],[-76.079773,42.356833],[-76.100612,42.278484],[-76.056226,42.271427],[-76.021647,42.252287],[-76.016936,42.258887]]]},"properties":{"id":"13797","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.755963,42.346702],[-78.812924,42.456159],[-78.986338,42.368134],[-78.978322,42.325091],[-78.958566,42.249165],[-78.888053,42.23401],[-78.755963,42.346702]]]},"properties":{"id":"14719","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.43471,42.823235],[-76.516577,42.811031],[-76.500135,42.713106],[-76.487615,42.698975],[-76.323463,42.666311],[-76.26851,42.6735],[-76.286039,42.785563],[-76.344229,42.831934],[-76.43471,42.823235]]]},"properties":{"id":"13118","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.29586,42.630184],[-76.523685,42.610305],[-76.482161,42.538822],[-76.296244,42.573564],[-76.29586,42.630184]]]},"properties":{"id":"13073","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.56449,43.254986],[-76.479984,43.240296],[-76.529687,43.346712],[-76.605967,43.384826],[-76.609083,43.317115],[-76.56449,43.254986]]]},"properties":{"id":"13074","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.420945,42.445322],[-79.271054,42.462108],[-79.253073,42.477363],[-79.226157,42.501498],[-79.249818,42.537275],[-79.411395,42.462196],[-79.420945,42.445322]]]},"properties":{"id":"14048","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.624008,42.239822],[-79.725132,42.167925],[-79.730951,42.120298],[-79.593146,42.103227],[-79.517498,42.098584],[-79.481197,42.128623],[-79.589976,42.228588],[-79.624008,42.239822]]]},"properties":{"id":"14781","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.431788,42.171175],[-79.470615,42.25661],[-79.460732,42.334975],[-79.484211,42.334591],[-79.589976,42.228588],[-79.481197,42.128623],[-79.431788,42.171175]],[[-79.469566,42.207774],[-79.478025,42.21353],[-79.473382,42.215573],[-79.463454,42.210574],[-79.469566,42.207774]]]},"properties":{"id":"14757","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.244262,42.17322],[-79.158009,42.096441],[-79.134349,42.094199],[-79.162721,42.204908],[-79.244262,42.17322]]]},"properties":{"id":"14733","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.496706,42.390531],[-79.484211,42.334591],[-79.460732,42.334975],[-79.460755,42.337287],[-79.496706,42.390531]]]},"properties":{"id":"14769","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.421432,42.196222],[-79.417382,42.197769],[-79.419944,42.201644],[-79.424054,42.200788],[-79.421432,42.196222]]]},"properties":{"id":"14756","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.869265,42.261329],[-76.838264,42.229404],[-76.823463,42.29402],[-76.854562,42.314953],[-76.869265,42.261329]]]},"properties":{"id":"14864","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.398385,42.45879],[-75.42726,42.468591],[-75.521928,42.443354],[-75.52225,42.375143],[-75.452691,42.394936],[-75.398385,42.45879]]]},"properties":{"id":"13780","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.608852,42.809194],[-75.700133,42.770511],[-75.684093,42.73415],[-75.549952,42.699319],[-75.42951,42.75742],[-75.481053,42.796279],[-75.608852,42.809194]]]},"properties":{"id":"13332","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.800979,42.697557],[-75.830731,42.685707],[-75.830492,42.685084],[-75.815121,42.619732],[-75.801985,42.596707],[-75.768483,42.609511],[-75.727132,42.641242],[-75.732767,42.704723],[-75.800979,42.697557]]]},"properties":{"id":"13155","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.837258,42.804621],[-75.800979,42.697557],[-75.732767,42.704723],[-75.704221,42.708036],[-75.684093,42.73415],[-75.700133,42.770511],[-75.721814,42.814134],[-75.795668,42.825064],[-75.837258,42.804621]]]},"properties":{"id":"13072","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.519309,45.00686],[-73.675459,45.002907],[-73.637671,44.926609],[-73.554859,44.897487],[-73.526832,44.911629],[-73.521015,44.917032],[-73.519309,45.00686]]]},"properties":{"id":"12958","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.519309,45.00686],[-73.521015,44.917032],[-73.385728,44.93259],[-73.363222,44.975322],[-73.391408,44.98251],[-73.370902,45.010458],[-73.519309,45.00686]]]},"properties":{"id":"12919","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.363222,44.975322],[-73.370902,45.010458],[-73.391408,44.98251],[-73.363222,44.975322]]]},"properties":{"id":"12979","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.721926,42.123271],[-73.688681,42.059364],[-73.643751,42.005919],[-73.591062,42.081149],[-73.613045,42.118661],[-73.656674,42.132112],[-73.721926,42.123271]]]},"properties":{"id":"12502","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.625225,42.33864],[-73.669808,42.356851],[-73.704956,42.348963],[-73.665134,42.253236],[-73.631208,42.256483],[-73.508214,42.294837],[-73.517361,42.299779],[-73.625225,42.33864]]]},"properties":{"id":"12075","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.579681,42.142606],[-73.656674,42.132112],[-73.613045,42.118661],[-73.579681,42.142606]]],[[[-73.633851,42.244461],[-73.634884,42.221108],[-73.570055,42.156361],[-73.527392,42.137721],[-73.476856,42.172241],[-73.431615,42.295306],[-73.508214,42.294837],[-73.631208,42.256483],[-73.633851,42.244461]]]]},"properties":{"id":"12529","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.643875,42.478382],[-73.615672,42.475202],[-73.624194,42.480398],[-73.643875,42.478382]]]},"properties":{"id":"12132","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.494007,42.319863],[-73.508214,42.294837],[-73.431615,42.295306],[-73.414251,42.342469],[-73.445833,42.366592],[-73.504826,42.347301],[-73.494007,42.319863]]]},"properties":{"id":"12017","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.235387,42.82609],[-76.196703,42.783691],[-76.195944,42.77473],[-76.132479,42.731956],[-76.076567,42.731998],[-76.063317,42.72853],[-76.005195,42.784871],[-76.028944,42.82899],[-76.0696,42.825401],[-76.069403,42.816819],[-76.078062,42.806607],[-76.082473,42.810881],[-76.075761,42.817827],[-76.077323,42.825126],[-76.238935,42.87854],[-76.235387,42.82609]]]},"properties":{"id":"13159","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.215763,42.428237],[-76.215695,42.44304],[-76.23101,42.427987],[-76.215763,42.428237]]]},"properties":{"id":"13784","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.486613,42.333588],[-74.443506,42.355017],[-74.526693,42.388085],[-74.563207,42.362254],[-74.486613,42.333588]]]},"properties":{"id":"12434","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.025587,42.26601],[-75.021117,42.131357],[-74.944055,42.094413],[-74.967022,42.21926],[-75.025587,42.26601]]]},"properties":{"id":"13782","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.685824,42.413116],[-74.563203,42.362207],[-74.563207,42.362254],[-74.526693,42.388085],[-74.484482,42.436133],[-74.484383,42.466114],[-74.657011,42.462885],[-74.685824,42.413116]]]},"properties":{"id":"12167","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.470022,42.171911],[-74.49673,42.148278],[-74.485462,42.099495],[-74.489047,42.135012],[-74.470022,42.171911]]]},"properties":{"id":"12441","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.728915,42.429612],[-74.738272,42.333981],[-74.719049,42.419434],[-74.728915,42.429612]]]},"properties":{"id":"13842","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.852078,42.401277],[-74.817864,42.313255],[-74.741561,42.319239],[-74.738272,42.333981],[-74.728915,42.429612],[-74.733061,42.433783],[-74.779837,42.433572],[-74.852078,42.401277]]]},"properties":{"id":"13739","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.955837,42.445689],[-74.935856,42.445905],[-74.92079,42.454105],[-74.955837,42.445689]]]},"properties":{"id":"13860","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.86648,41.560405],[-73.950439,41.529368],[-73.941143,41.516061],[-73.936679,41.522386],[-73.932755,41.517519],[-73.918758,41.489783],[-73.859995,41.495741],[-73.86648,41.560405]]]},"properties":{"id":"12524","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.915789,41.616448],[-73.852278,41.735793],[-73.855903,41.756536],[-73.936216,41.743008],[-73.946521,41.615536],[-73.915789,41.616448]]]},"properties":{"id":"12601","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.852278,41.735793],[-73.915789,41.616448],[-73.78932,41.636991],[-73.805006,41.672416],[-73.852278,41.735793]],[[-73.88788,41.691708],[-73.891373,41.684995],[-73.89432,41.685369],[-73.892298,41.689335],[-73.88788,41.691708]]]},"properties":{"id":"12603","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.689308,41.627308],[-73.632837,41.674588],[-73.648605,41.708979],[-73.679234,41.709864],[-73.742801,41.715663],[-73.805006,41.672416],[-73.78932,41.636991],[-73.789078,41.629987],[-73.689308,41.627308]]]},"properties":{"id":"12540","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.859995,41.495741],[-73.83694,41.45305],[-73.821149,41.468065],[-73.77068,41.504413],[-73.727329,41.593666],[-73.689308,41.627308],[-73.789078,41.629987],[-73.86648,41.560405],[-73.859995,41.495741]]]},"properties":{"id":"12533","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.709483,41.557579],[-73.715337,41.510049],[-73.650599,41.477103],[-73.621968,41.521831],[-73.663758,41.588457],[-73.709483,41.557579]]]},"properties":{"id":"12531","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.941856,41.835912],[-73.936216,41.743008],[-73.855903,41.756536],[-73.81723,41.786513],[-73.837102,41.833576],[-73.941856,41.835912]]]},"properties":{"id":"12538","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.795698,41.884126],[-73.928439,41.872921],[-73.941856,41.835912],[-73.837102,41.833576],[-73.812186,41.843802],[-73.795698,41.884126]]]},"properties":{"id":"12580","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.784477,42.890077],[-78.792349,42.871571],[-78.701222,42.863759],[-78.728099,42.907034],[-78.784297,42.900528],[-78.784346,42.900522],[-78.784477,42.890077]]]},"properties":{"id":"14227","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.807976,42.771407],[-78.744569,42.674613],[-78.699476,42.695793],[-78.688817,42.737683],[-78.683497,42.796328],[-78.696915,42.811166],[-78.778911,42.804084],[-78.799725,42.796799],[-78.807976,42.771407]]]},"properties":{"id":"14127","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.864588,42.805422],[-78.799725,42.796799],[-78.778911,42.804084],[-78.79363,42.828632],[-78.84395,42.845103],[-78.862283,42.83227],[-78.864588,42.805422]]]},"properties":{"id":"14218","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.943952,42.516789],[-78.779668,42.465595],[-78.81049,42.511454],[-78.943952,42.516789]],[[-78.85065,42.493612],[-78.839953,42.490867],[-78.839982,42.487311],[-78.850397,42.487608],[-78.85065,42.493612]]]},"properties":{"id":"14034","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.799725,42.796799],[-78.864588,42.805422],[-78.869143,42.771294],[-78.807976,42.771407],[-78.799725,42.796799]]]},"properties":{"id":"14219","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.623453,42.64289],[-78.609888,42.584062],[-78.566847,42.58782],[-78.46237,42.606545],[-78.463636,42.678378],[-78.495844,42.6933],[-78.61409,42.693716],[-78.639983,42.667892],[-78.623453,42.64289]]]},"properties":{"id":"14080","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.463636,42.678378],[-78.435995,42.672622],[-78.396382,42.678168],[-78.387929,42.704757],[-78.384629,42.787468],[-78.39775,42.791853],[-78.463233,42.780461],[-78.475492,42.735813],[-78.495844,42.6933],[-78.463636,42.678378]]]},"properties":{"id":"14145","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.073154,42.602712],[-79.090932,42.594243],[-79.069707,42.587421],[-79.069672,42.602702],[-79.073154,42.602712]]]},"properties":{"id":"14061","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.525072,44.109095],[-73.470953,44.084596],[-73.444061,44.092989],[-73.353488,44.238627],[-73.482379,44.296079],[-73.485003,44.309837],[-73.567434,44.247573],[-73.536434,44.209729],[-73.52883,44.196792],[-73.525072,44.109095]]]},"properties":{"id":"12993","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.410368,44.426846],[-73.501932,44.395813],[-73.485003,44.309837],[-73.482379,44.296079],[-73.34673,44.323062],[-73.410368,44.426846]]]},"properties":{"id":"12996","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.509386,44.061254],[-73.472961,44.004455],[-73.452188,44.005693],[-73.444061,44.092989],[-73.470953,44.084596],[-73.507232,44.061631],[-73.509386,44.061254]]]},"properties":{"id":"12974","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.2891,43.88085],[-74.235542,43.830984],[-74.02175,43.951568],[-73.951675,43.993718],[-74.265714,44.025799],[-74.336826,43.925223],[-74.2891,43.88085]]]},"properties":{"id":"12852","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.486767,44.783469],[-74.614223,44.752374],[-74.653988,44.699635],[-74.611775,44.688004],[-74.471892,44.684761],[-74.486767,44.783469]]]},"properties":{"id":"12930","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.611775,44.688004],[-74.70624,44.689807],[-74.719153,44.597136],[-74.566447,44.439597],[-74.433015,44.395012],[-74.435865,44.412008],[-74.288093,44.488016],[-74.297642,44.499909],[-74.330936,44.551057],[-74.346597,44.670952],[-74.471892,44.684761],[-74.611775,44.688004]]]},"properties":{"id":"12980","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.478675,42.899926],[-74.473437,42.897793],[-74.314345,42.933458],[-74.292928,42.941667],[-74.28834,42.944809],[-74.282913,42.957422],[-74.28856,42.9798],[-74.447193,42.993405],[-74.478234,42.923257],[-74.478675,42.899926]]]},"properties":{"id":"12068","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.30271,42.950046],[-78.192369,42.913796],[-78.113781,42.96391],[-78.115808,43.014158],[-78.123965,43.030301],[-78.123854,43.044988],[-78.202686,43.049925],[-78.330211,43.043781],[-78.336159,43.025363],[-78.30271,42.950046]]]},"properties":{"id":"14020","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.340512,43.130803],[-78.330211,43.043781],[-78.202686,43.049925],[-78.23451,43.131617],[-78.251949,43.131341],[-78.340512,43.130803]]]},"properties":{"id":"14125","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.115808,43.014158],[-78.113781,42.96391],[-78.072073,42.936718],[-78.02944,42.956342],[-78.080871,43.018557],[-78.115808,43.014158]]]},"properties":{"id":"14143","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.099229,43.131401],[-78.123854,43.044988],[-78.123965,43.030301],[-78.016842,43.132419],[-78.099229,43.131401]]]},"properties":{"id":"14422","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.888937,42.382493],[-73.859367,42.37099],[-73.8745,42.387286],[-73.888937,42.382493]]],[[[-73.969502,42.441027],[-73.952851,42.399452],[-73.951812,42.399352],[-73.948602,42.40337],[-73.948216,42.412315],[-73.969502,42.441027]]]]},"properties":{"id":"12042","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.16014,42.381737],[-74.105364,42.336624],[-74.092872,42.341163],[-74.059107,42.361477],[-74.079713,42.398553],[-74.103811,42.408396],[-74.134527,42.411295],[-74.148406,42.399081],[-74.16014,42.381737]]]},"properties":{"id":"12423","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.171105,42.188943],[-74.11279,42.182208],[-74.13432,42.224861],[-74.168882,42.22339],[-74.171105,42.188943]]]},"properties":{"id":"12485","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.439853,42.16473],[-74.395836,42.06108],[-74.328737,42.118191],[-74.32448,42.120753],[-74.439853,42.16473]]]},"properties":{"id":"12480","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.992424,42.37362],[-73.951301,42.356856],[-73.950374,42.356918],[-73.949051,42.391637],[-73.951812,42.399352],[-73.952851,42.399452],[-73.992424,42.37362]]]},"properties":{"id":"12176","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.801684,43.800628],[-74.668346,43.730944],[-74.55962,43.841822],[-74.801684,43.800628]]]},"properties":{"id":"13436","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.837936,43.952966],[-75.005764,43.85904],[-74.813155,43.766887],[-74.820108,43.818507],[-74.837936,43.952966]]]},"properties":{"id":"13331","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.018811,43.016189],[-75.029275,43.121082],[-75.049969,43.129967],[-75.181502,43.092873],[-75.209411,43.062765],[-75.1963,43.035533],[-75.188095,42.996576],[-75.160045,42.952614],[-75.157562,42.941789],[-75.018811,43.016189]]]},"properties":{"id":"13340","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.88246,44.124679],[-75.880358,44.048138],[-75.833527,44.044172],[-75.829336,44.052666],[-75.776079,44.073511],[-75.732655,44.103329],[-75.784204,44.135684],[-75.881647,44.137129],[-75.88246,44.124679]]]},"properties":{"id":"13637","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.693971,44.229003],[-75.784204,44.135684],[-75.732655,44.103329],[-75.657539,44.16949],[-75.693971,44.229003]]]},"properties":{"id":"13673","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.981769,44.301535],[-76.024121,44.295571],[-76.019449,44.285977],[-75.981821,44.301488],[-75.981769,44.301535]]]},"properties":{"id":"13640","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.854062,44.270564],[-75.832299,44.287766],[-75.8617,44.279057],[-75.854062,44.270564]]]},"properties":{"id":"13675","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.61981,44.302183],[-75.569334,44.239076],[-75.446124,44.217655],[-75.318664,44.236302],[-75.275332,44.346704],[-75.308761,44.385938],[-75.423711,44.46968],[-75.428462,44.503658],[-75.428826,44.503605],[-75.547452,44.447017],[-75.61964,44.367424],[-75.61981,44.302183]]]},"properties":{"id":"13642","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.680246,44.027123],[-75.6767,44.029831],[-75.683412,44.032615],[-75.685304,44.028998],[-75.680246,44.027123]]]},"properties":{"id":"13628","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.803198,44.403481],[-75.61964,44.367424],[-75.547452,44.447017],[-75.536744,44.477511],[-75.546416,44.552965],[-75.658186,44.516725],[-75.732497,44.528949],[-75.757124,44.453803],[-75.752095,44.441969],[-75.763135,44.441863],[-75.803198,44.403481]]]},"properties":{"id":"13646","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.931842,40.58292],[-73.898261,40.622989],[-73.922207,40.641065],[-73.934305,40.633886],[-73.944827,40.616046],[-73.926104,40.584825],[-73.931842,40.58292]]]},"properties":{"id":"11234","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.944827,40.616046],[-73.934305,40.633886],[-73.946509,40.640104],[-73.9584,40.636327],[-73.952577,40.611037],[-73.944827,40.616046]]]},"properties":{"id":"11210","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.998272,40.671153],[-73.993985,40.647101],[-73.989059,40.644119],[-73.975157,40.658723],[-73.970419,40.672511],[-73.970749,40.672639],[-73.98856,40.679559],[-73.998659,40.671705],[-73.998272,40.671153]]]},"properties":{"id":"11215","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.982812,40.583792],[-73.988222,40.580281],[-73.959395,40.574077],[-73.966614,40.584575],[-73.982812,40.583792]]]},"properties":{"id":"11224","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.878436,40.649327],[-73.889994,40.651138],[-73.878901,40.638038],[-73.875052,40.64517],[-73.878436,40.649327]]]},"properties":{"id":"11239","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.696283,43.569999],[-75.663693,43.645833],[-75.674873,43.651977],[-75.741106,43.646808],[-75.696283,43.569999]]],[[[-75.885857,43.324742],[-75.784051,43.249982],[-75.75301,43.239105],[-75.692598,43.312854],[-75.6395,43.444149],[-75.658189,43.495782],[-75.696283,43.569999],[-75.757569,43.492522],[-75.838997,43.429425],[-75.805747,43.409723],[-75.861113,43.38029],[-75.892875,43.338592],[-75.885857,43.324742]]]]},"properties":{"id":"13316","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.401204,43.903849],[-75.417005,43.901844],[-75.4145,43.890044],[-75.401204,43.903849]]]},"properties":{"id":"13305","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.341176,43.708746],[-75.361123,43.686635],[-75.310031,43.681925],[-75.341176,43.708746]]]},"properties":{"id":"13345","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.001673,43.065795],[-76.006698,43.048986],[-75.932381,43.058802],[-75.907071,43.128682],[-75.983659,43.137319],[-76.001177,43.083555],[-76.001673,43.065795]]]},"properties":{"id":"13082","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.440332,42.893691],[-75.544967,42.956309],[-75.566898,42.931592],[-75.541364,42.884066],[-75.475014,42.847493],[-75.452778,42.86763],[-75.440332,42.893691]]]},"properties":{"id":"13402","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.404148,42.853004],[-75.355599,42.8115],[-75.357649,42.826209],[-75.35391,42.867338],[-75.404148,42.853004]]]},"properties":{"id":"13418","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.643213,42.880853],[-75.642296,42.851818],[-75.677632,42.859755],[-75.685816,42.864297],[-75.721814,42.814134],[-75.700133,42.770511],[-75.608852,42.809194],[-75.583425,42.861034],[-75.584013,42.874826],[-75.643213,42.880853]]]},"properties":{"id":"13334","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.637564,43.169527],[-77.659409,43.187376],[-77.680342,43.192545],[-77.743904,43.189021],[-77.753729,43.166525],[-77.673731,43.157125],[-77.630767,43.15941],[-77.637564,43.169527]]]},"properties":{"id":"14606","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.827886,43.130734],[-77.78769,43.110938],[-77.79669,43.090277],[-77.780377,43.089345],[-77.686064,43.098694],[-77.642539,43.121661],[-77.645393,43.123223],[-77.665944,43.140222],[-77.673731,43.157125],[-77.753729,43.166525],[-77.827481,43.137077],[-77.827886,43.130734]]]},"properties":{"id":"14624","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.47945,43.099409],[-77.507236,43.110783],[-77.573327,43.087295],[-77.593006,43.059534],[-77.563125,43.021934],[-77.524696,43.008176],[-77.484718,43.008387],[-77.456757,43.034662],[-77.47945,43.099409]]]},"properties":{"id":"14534","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.688976,43.08277],[-77.730461,43.0917],[-77.795984,43.070356],[-77.866084,42.99756],[-77.846364,42.98801],[-77.730957,42.988372],[-77.723086,43.016962],[-77.688976,43.08277]]]},"properties":{"id":"14546","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.607221,43.151059],[-77.575187,43.143347],[-77.569673,43.153357],[-77.585157,43.161835],[-77.595667,43.159523],[-77.607221,43.151059]]]},"properties":{"id":"14607","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.593006,43.059534],[-77.652083,43.058694],[-77.648001,43.048026],[-77.595222,43.017563],[-77.563125,43.021934],[-77.593006,43.059534]]]},"properties":{"id":"14467","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.207546,42.836553],[-74.112059,42.816269],[-74.047792,42.870985],[-74.073552,42.891623],[-74.084844,42.896869],[-74.207546,42.836553]]]},"properties":{"id":"12137","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.594242,40.856779],[-73.621023,40.898514],[-73.648801,40.853566],[-73.635706,40.846082],[-73.594242,40.856779]]]},"properties":{"id":"11542","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.746702,40.633387],[-73.745633,40.611756],[-73.73815,40.594539],[-73.73815,40.594229],[-73.70613,40.590958],[-73.685975,40.591704],[-73.705036,40.614325],[-73.71164,40.618546],[-73.736361,40.632362],[-73.740115,40.635511],[-73.746702,40.633387]]]},"properties":{"id":"11559","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.686015,40.711934],[-73.686636,40.695123],[-73.67979,40.683557],[-73.667481,40.682967],[-73.66377,40.710776],[-73.684779,40.714708],[-73.686015,40.711934]]]},"properties":{"id":"11010","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.707283,40.785434],[-73.73376,40.772469],[-73.713034,40.759566],[-73.712761,40.759415],[-73.695834,40.767159],[-73.707283,40.785434]]]},"properties":{"id":"11020","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.725956,40.685682],[-73.686636,40.695123],[-73.686015,40.711934],[-73.729176,40.719167],[-73.727707,40.701565],[-73.725956,40.685682]]]},"properties":{"id":"11003","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.594242,40.856779],[-73.580806,40.847885],[-73.568315,40.867187],[-73.57199,40.895195],[-73.590862,40.909295],[-73.621023,40.898514],[-73.594242,40.856779]]]},"properties":{"id":"11560","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.732105,40.639535],[-73.71164,40.618546],[-73.705036,40.614325],[-73.68899,40.623137],[-73.714626,40.647607],[-73.732105,40.639535]]]},"properties":{"id":"11598","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.941803,40.812815],[-73.937181,40.819144],[-73.942924,40.82326],[-73.946078,40.821264],[-73.941803,40.812815]]]},"properties":{"id":"10030","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.940404,40.808958],[-73.933219,40.807255],[-73.933377,40.819473],[-73.937181,40.819144],[-73.941803,40.812815],[-73.940404,40.808958]]]},"properties":{"id":"10037","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.979864,40.734975],[-73.988643,40.722933],[-73.973635,40.718612],[-73.97365,40.73231],[-73.979864,40.734975]]]},"properties":{"id":"10009","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.96323,40.777677],[-73.947361,40.770062],[-73.944337,40.773108],[-73.959921,40.782213],[-73.96323,40.777677]]]},"properties":{"id":"10028","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.972553,40.764898],[-73.959263,40.759284],[-73.956126,40.760401],[-73.952085,40.764474],[-73.968441,40.770517],[-73.972553,40.764898]]]},"properties":{"id":"10065","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.009331,40.742572],[-74.000954,40.731706],[-73.999604,40.731043],[-73.990945,40.739673],[-73.992063,40.743919],[-74.005733,40.75597],[-74.006145,40.756189],[-74.009331,40.742572]]]},"properties":{"id":"10011","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.980886,40.75927],[-73.97845,40.759065],[-73.980426,40.759899],[-73.980886,40.75927]]]},"properties":{"id":"10112","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.950403,40.834372],[-73.960007,40.820422],[-73.946078,40.821264],[-73.942924,40.82326],[-73.940167,40.830383],[-73.950403,40.834372]]]},"properties":{"id":"10031","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.976516,40.754974],[-73.975702,40.753811],[-73.975209,40.754425],[-73.975807,40.754678],[-73.976516,40.754974]]]},"properties":{"id":"10169","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.011248,40.708304],[-74.010201,40.707694],[-74.009829,40.708117],[-74.010846,40.708797],[-74.011248,40.708304]]]},"properties":{"id":"10271","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.933696,43.142968],[-78.884584,43.105028],[-78.829469,43.126826],[-78.855553,43.189919],[-78.930186,43.184822],[-78.933696,43.142968]]]},"properties":{"id":"14132","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.718749,43.343467],[-78.733919,43.331964],[-78.748091,43.33819],[-78.754358,43.337055],[-78.755064,43.287187],[-78.667745,43.294921],[-78.699478,43.346933],[-78.718749,43.343467]]]},"properties":{"id":"14028","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.748091,43.33819],[-78.733919,43.331964],[-78.718749,43.343467],[-78.748091,43.33819]]]},"properties":{"id":"14126","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.486497,43.121785],[-75.407907,43.158168],[-75.341177,43.178441],[-75.319077,43.207328],[-75.304278,43.23117],[-75.372144,43.283053],[-75.454481,43.297969],[-75.466301,43.303916],[-75.565126,43.298988],[-75.59319,43.267632],[-75.645333,43.20945],[-75.607664,43.191923],[-75.513543,43.132449],[-75.486497,43.121785]],[[-75.39043,43.207057],[-75.427219,43.225803],[-75.421517,43.248441],[-75.391913,43.227657],[-75.39043,43.207057]]]},"properties":{"id":"13440","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.323735,43.164102],[-75.254011,43.112839],[-75.220586,43.178642],[-75.263628,43.216139],[-75.319077,43.207328],[-75.341177,43.178441],[-75.323735,43.164102]]]},"properties":{"id":"13403","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.39043,43.207057],[-75.391913,43.227657],[-75.421517,43.248441],[-75.427219,43.225803],[-75.39043,43.207057]]]},"properties":{"id":"13441","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.732281,43.221316],[-75.732744,43.191081],[-75.728487,43.195199],[-75.71712,43.202585],[-75.732281,43.221316]]]},"properties":{"id":"13157","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.392465,43.035783],[-75.400853,43.038095],[-75.400578,43.032305],[-75.392465,43.035783]]]},"properties":{"id":"13341","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.27482,43.317569],[-76.268348,43.313216],[-76.24882,43.336982],[-76.263839,43.346918],[-76.27482,43.317569]]],[[[-76.27482,43.317569],[-76.423952,43.229449],[-76.281027,43.201091],[-76.217646,43.204821],[-76.273367,43.306158],[-76.268348,43.313216],[-76.27482,43.317569]]]]},"properties":{"id":"13135","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.243284,43.116494],[-76.171353,43.137219],[-76.134875,43.150792],[-76.255227,43.189374],[-76.25155,43.122367],[-76.243539,43.116424],[-76.243284,43.116494]]]},"properties":{"id":"13090","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.144203,43.050849],[-76.142757,43.035709],[-76.124451,43.007835],[-76.109484,43.005509],[-76.115595,43.054776],[-76.121868,43.053298],[-76.144203,43.050849]]]},"properties":{"id":"13210","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.290565,43.014295],[-76.273877,42.927626],[-76.245424,42.916919],[-76.162747,42.984366],[-76.192867,43.029153],[-76.258014,43.029909],[-76.290565,43.014295]]]},"properties":{"id":"13215","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.243279,43.052833],[-76.258014,43.029909],[-76.192867,43.029153],[-76.186846,43.032049],[-76.197002,43.051376],[-76.243279,43.052833]]]},"properties":{"id":"13219","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.461737,42.988321],[-76.43993,42.995854],[-76.460009,43.001788],[-76.461737,42.988321]]]},"properties":{"id":"13153","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.268171,43.091817],[-77.29516,43.034706],[-77.307723,43.008652],[-77.184321,43.016662],[-77.17101,43.039842],[-77.152742,43.03865],[-77.147036,43.039865],[-77.167039,43.107764],[-77.241079,43.127389],[-77.268171,43.091817]]]},"properties":{"id":"14522","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.329137,42.724774],[-77.162227,42.739098],[-77.214174,42.805733],[-77.265979,42.799596],[-77.329137,42.724774]]]},"properties":{"id":"14544","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.482517,42.943164],[-77.517891,42.934293],[-77.485537,42.931499],[-77.482517,42.943164]]]},"properties":{"id":"14475","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.555629,42.90864],[-77.552162,42.903734],[-77.551336,42.907477],[-77.555629,42.90864]]]},"properties":{"id":"14585","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.321702,41.182697],[-74.314363,41.178955],[-74.308435,41.186995],[-74.321702,41.182697]]]},"properties":{"id":"10979","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.988305,41.320021],[-74.014966,41.317305],[-74.009828,41.304702],[-73.988305,41.320021]]]},"properties":{"id":"10911","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.303632,41.694589],[-74.236555,41.601846],[-74.102581,41.576308],[-74.042259,41.58157],[-74.026526,41.633898],[-74.056768,41.656507],[-74.078222,41.6613],[-74.119524,41.632043],[-74.249511,41.693172],[-74.266032,41.701427],[-74.303632,41.694589]]]},"properties":{"id":"12589","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.485351,41.317087],[-74.519327,41.306581],[-74.53676,41.284742],[-74.473132,41.255276],[-74.438384,41.317736],[-74.469584,41.315209],[-74.485351,41.317087]]]},"properties":{"id":"10969","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.618355,41.418288],[-74.697836,41.495678],[-74.721097,41.494835],[-74.690847,41.392518],[-74.618355,41.418288]]]},"properties":{"id":"12746","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.569298,41.299966],[-74.53676,41.284742],[-74.519327,41.306581],[-74.569298,41.299966]]]},"properties":{"id":"10988","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.198475,41.432121],[-74.212443,41.415934],[-74.204828,41.397477],[-74.194096,41.398258],[-74.198475,41.432121]]]},"properties":{"id":"10914","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.960783,43.343057],[-75.972956,43.243261],[-75.924738,43.242126],[-75.885857,43.324742],[-75.892875,43.338592],[-75.960783,43.343057]]]},"properties":{"id":"13028","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.491583,42.76387],[-74.660305,42.730275],[-74.649074,42.694348],[-74.643407,42.675906],[-74.50728,42.660006],[-74.431363,42.644895],[-74.397764,42.679895],[-74.419948,42.723389],[-74.422989,42.723886],[-74.428804,42.756855],[-74.491583,42.76387]]]},"properties":{"id":"12043","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.845821,41.335906],[-73.826961,41.31905],[-73.813059,41.332277],[-73.797456,41.340647],[-73.806522,41.357135],[-73.845821,41.335906]]]},"properties":{"id":"10588","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.838002,40.754438],[-73.835902,40.743327],[-73.809441,40.738674],[-73.807506,40.750137],[-73.807514,40.762179],[-73.838002,40.754438]]]},"properties":{"id":"11355","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.757642,40.717971],[-73.76631,40.726375],[-73.777236,40.722284],[-73.780359,40.708464],[-73.775465,40.705319],[-73.753356,40.710101],[-73.753708,40.713325],[-73.757642,40.717971]]]},"properties":{"id":"11423","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.843016,40.709176],[-73.818383,40.706729],[-73.824304,40.713869],[-73.830627,40.714341],[-73.843016,40.709176]]]},"properties":{"id":"11415","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.807506,40.750137],[-73.780088,40.752494],[-73.789309,40.769708],[-73.791635,40.774239],[-73.803985,40.77419],[-73.807514,40.762179],[-73.807506,40.750137]]]},"properties":{"id":"11358","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.865594,40.566931],[-73.86298,40.576215],[-73.935387,40.559275],[-73.936329,40.549651],[-73.865594,40.566931]]]},"properties":{"id":"11697","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.86298,40.576215],[-73.865594,40.566931],[-73.815506,40.582508],[-73.819093,40.588139],[-73.86298,40.576215]]]},"properties":{"id":"11694","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.71371,40.736175],[-73.700983,40.738896],[-73.70542,40.754606],[-73.707825,40.756216],[-73.721178,40.756871],[-73.722917,40.754098],[-73.71371,40.736175]]]},"properties":{"id":"11004","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.830627,40.714341],[-73.824304,40.713869],[-73.825956,40.716022],[-73.830627,40.714341]]]},"properties":{"id":"11424","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.665759,42.833373],[-73.573863,42.809352],[-73.520172,42.819411],[-73.565822,42.869013],[-73.662553,42.887596],[-73.670823,42.840312],[-73.665759,42.833373]]]},"properties":{"id":"12121","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.565822,42.869013],[-73.538282,42.990557],[-73.597445,43.007372],[-73.662943,42.929556],[-73.662553,42.887596],[-73.565822,42.869013]]]},"properties":{"id":"12154","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.447894,42.561865],[-73.45055,42.53504],[-73.396456,42.50577],[-73.352527,42.510002],[-73.312511,42.617715],[-73.358098,42.614289],[-73.406914,42.610484],[-73.447894,42.561865]]]},"properties":{"id":"12168","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.927304,41.070437],[-73.921988,41.041414],[-73.918846,41.05189],[-73.906101,41.13509],[-73.946428,41.100603],[-73.94668,41.085734],[-73.927304,41.070437]]]},"properties":{"id":"10960","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.00406,41.217788],[-74.022641,41.197053],[-74.005088,41.190659],[-73.989872,41.192448],[-73.98338,41.200932],[-73.990659,41.214996],[-74.00406,41.217788]]]},"properties":{"id":"10923","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.969284,41.123778],[-73.986848,41.121196],[-73.99669,41.07403],[-73.994882,41.070847],[-73.967045,41.078323],[-73.94668,41.085734],[-73.946428,41.100603],[-73.964282,41.122495],[-73.969284,41.123778]]]},"properties":{"id":"10994","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.964116,41.220899],[-73.990659,41.214996],[-73.98338,41.200932],[-73.962003,41.20499],[-73.964116,41.220899]]]},"properties":{"id":"10993","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.024405,44.77362],[-75.086817,44.734653],[-75.099161,44.703121],[-75.089528,44.686998],[-74.917304,44.757834],[-74.891804,44.808248],[-75.024405,44.77362]]]},"properties":{"id":"13668","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.716355,44.771666],[-74.84163,44.843384],[-74.891804,44.808248],[-74.917304,44.757834],[-74.899913,44.713054],[-74.88367,44.698737],[-74.74766,44.703353],[-74.677514,44.71032],[-74.716355,44.771666]]]},"properties":{"id":"13697","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.099161,44.703121],[-75.086817,44.734653],[-75.119344,44.70226],[-75.099161,44.703121]]],[[[-75.089528,44.686998],[-75.019669,44.563339],[-74.835884,44.626825],[-74.719153,44.597136],[-74.70624,44.689807],[-74.74766,44.703353],[-74.88367,44.698737],[-74.902642,44.692912],[-74.899913,44.713054],[-74.917304,44.757834],[-75.089528,44.686998]],[[-74.977473,44.604383],[-74.982604,44.602543],[-74.982604,44.60927],[-74.978718,44.60927],[-74.977473,44.604383]]]]},"properties":{"id":"13676","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.23625,44.652069],[-75.341475,44.545585],[-75.205706,44.521539],[-75.186393,44.514725],[-75.178894,44.514502],[-75.171284,44.508903],[-75.071406,44.478189],[-75.024389,44.476916],[-75.019669,44.563339],[-75.089528,44.686998],[-75.099161,44.703121],[-75.119344,44.70226],[-75.183056,44.696556],[-75.23625,44.652069]]]},"properties":{"id":"13617","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.642167,44.289335],[-74.533821,44.231338],[-74.5535,44.351887],[-74.685027,44.342688],[-74.642167,44.289335]]]},"properties":{"id":"12973","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.422798,44.519554],[-75.536744,44.477511],[-75.547452,44.447017],[-75.428826,44.503605],[-75.422798,44.519554]]]},"properties":{"id":"13633","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.852905,44.868948],[-74.976849,44.839942],[-74.971646,44.830503],[-74.978929,44.82502],[-74.980489,44.811498],[-74.996052,44.809101],[-74.994625,44.816528],[-74.983644,44.820821],[-74.979879,44.839614],[-75.040855,44.873242],[-75.075949,44.773079],[-75.024405,44.77362],[-74.891804,44.808248],[-74.84163,44.843384],[-74.852905,44.868948]]]},"properties":{"id":"13667","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.923242,44.326312],[-74.942156,44.465345],[-75.024389,44.476916],[-75.071406,44.478189],[-75.192228,44.373554],[-75.166665,44.305786],[-75.089101,44.304386],[-75.041009,44.213003],[-74.918495,44.287509],[-74.923242,44.326312]]]},"properties":{"id":"13684","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.422989,42.723886],[-74.419948,42.723389],[-74.384207,42.728196],[-74.422989,42.723886]]],[[[-74.326951,42.742545],[-74.383667,42.728437],[-74.32722,42.717859],[-74.326951,42.742545]]]]},"properties":{"id":"12035","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.326951,42.742545],[-74.411384,42.77649],[-74.427902,42.77268],[-74.428804,42.756855],[-74.422989,42.723886],[-74.384207,42.728196],[-74.383667,42.728437],[-74.326951,42.742545]]]},"properties":{"id":"12160","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.623889,42.560023],[-74.500377,42.519439],[-74.500177,42.560515],[-74.628535,42.566408],[-74.623889,42.560023]]]},"properties":{"id":"12175","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.442643,42.471739],[-74.463531,42.564294],[-74.500177,42.560515],[-74.500377,42.519439],[-74.45532,42.468803],[-74.442643,42.471739]]]},"properties":{"id":"12194","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.108299,42.46874],[-77.106635,42.465888],[-77.094927,42.419826],[-77.049151,42.39661],[-76.98624,42.424583],[-76.910691,42.478282],[-76.916832,42.574412],[-77.005779,42.555997],[-77.099999,42.541168],[-77.120951,42.493173],[-77.108299,42.46874]]]},"properties":{"id":"14837","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.897132,42.31726],[-76.854562,42.314953],[-76.823463,42.29402],[-76.81329,42.29412],[-76.799405,42.386034],[-76.854869,42.387884],[-76.897132,42.31726]]]},"properties":{"id":"14865","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.109518,42.283738],[-77.285148,42.272468],[-77.295845,42.272528],[-77.313526,42.205055],[-77.187096,42.182804],[-77.109518,42.283738]]]},"properties":{"id":"14821","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.502985,42.00007],[-77.338994,41.999855],[-77.442016,42.140918],[-77.513625,42.095283],[-77.502985,42.00007]]]},"properties":{"id":"14898","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.371179,42.382804],[-77.372645,42.376651],[-77.364192,42.369895],[-77.358129,42.375386],[-77.370824,42.383031],[-77.371179,42.382804]]]},"properties":{"id":"14856","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.149627,40.723869],[-73.171265,40.735787],[-73.156103,40.743237],[-73.15259,40.748251],[-73.2012,40.742723],[-73.2046,40.70413],[-73.127237,40.721618],[-73.149627,40.723869]]]},"properties":{"id":"11730","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.199935,40.755511],[-73.2012,40.742723],[-73.15259,40.748251],[-73.150293,40.747275],[-73.154709,40.764289],[-73.199935,40.755511]]]},"properties":{"id":"11752","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.087855,40.78231],[-73.083038,40.764458],[-73.061604,40.769076],[-73.046975,40.77224],[-73.042453,40.785337],[-73.066868,40.818546],[-73.087855,40.78231]]]},"properties":{"id":"11741","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.569894,40.954395],[-72.565103,40.947527],[-72.564692,40.947689],[-72.569894,40.954395]]],[[[-72.582422,40.984897],[-72.598112,41.002946],[-72.713689,40.98578],[-72.723383,40.844497],[-72.693829,40.85358],[-72.673937,40.859484],[-72.616527,40.870534],[-72.561377,40.875952],[-72.565642,40.910965],[-72.615214,40.928819],[-72.631659,40.932025],[-72.618768,40.939143],[-72.608916,40.938431],[-72.59669,40.93287],[-72.590169,40.929984],[-72.58864,40.931537],[-72.579833,40.944709],[-72.572748,40.957647],[-72.582422,40.984897]]]]},"properties":{"id":"11901","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.344931,40.742041],[-73.306479,40.741467],[-73.291028,40.770168],[-73.294966,40.784297],[-73.352132,40.766845],[-73.349373,40.75104],[-73.344931,40.742041]]]},"properties":{"id":"11729","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.658057,40.808874],[-72.566271,40.815316],[-72.567605,40.817054],[-72.594258,40.810487],[-72.617574,40.833468],[-72.616527,40.870534],[-72.673937,40.859484],[-72.658057,40.808874]]]},"properties":{"id":"11978","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.983727,40.974946],[-72.963457,40.945234],[-72.954815,40.974805],[-72.983727,40.974946]]]},"properties":{"id":"11789","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.324742,41.147298],[-72.311302,41.106756],[-72.301989,41.103086],[-72.324742,41.147298]]]},"properties":{"id":"11957","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.311302,41.106756],[-72.324742,41.147298],[-72.363009,41.140082],[-72.342102,41.103943],[-72.311302,41.106756]]]},"properties":{"id":"11939","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.976789,40.903614],[-72.975258,40.868953],[-72.942672,40.863276],[-72.928701,40.865889],[-72.941954,40.907818],[-72.984573,40.910711],[-72.976789,40.903614]]]},"properties":{"id":"11953","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.837361,40.820022],[-72.823628,40.78856],[-72.818963,40.783767],[-72.808948,40.823162],[-72.837361,40.820022]]]},"properties":{"id":"11955","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.114816,41.013764],[-72.143018,40.951968],[-72.020157,40.99419],[-72.06269,41.025691],[-72.114816,41.013764]]]},"properties":{"id":"11930","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.859883,41.484412],[-74.849947,41.488753],[-74.862812,41.497069],[-74.86002,41.486084],[-74.859883,41.484412]]],[[[-74.859883,41.484412],[-74.861668,41.484305],[-74.861185,41.472501],[-74.812966,41.443639],[-74.752399,41.493743],[-74.783831,41.604143],[-74.785244,41.604279],[-74.790473,41.604722],[-74.818621,41.556861],[-74.854969,41.504542],[-74.840951,41.49175],[-74.859883,41.484412]]]]},"properties":{"id":"12737","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.818621,41.556861],[-74.868837,41.573322],[-74.854969,41.504542],[-74.818621,41.556861]]]},"properties":{"id":"12743","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.5756,41.837458],[-74.525506,41.796817],[-74.490335,41.831864],[-74.484261,41.838445],[-74.369504,41.900569],[-74.355392,41.91307],[-74.348368,41.928268],[-74.33779,41.951056],[-74.392393,41.980052],[-74.589327,41.889972],[-74.5756,41.837458]]]},"properties":{"id":"12740","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.601246,41.67303],[-74.650301,41.672629],[-74.609391,41.656945],[-74.601246,41.67303]]]},"properties":{"id":"12784","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.898022,41.876718],[-74.975943,41.838958],[-74.954798,41.822729],[-74.915447,41.838431],[-74.898022,41.876718]]]},"properties":{"id":"12724","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.157444,42.084407],[-76.17356,42.183635],[-76.262662,42.18523],[-76.416084,42.154638],[-76.416396,42.143716],[-76.331561,42.07617],[-76.292735,41.998393],[-76.204853,41.998538],[-76.157444,42.084407]]]},"properties":{"id":"13827","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.416599,42.226497],[-76.416084,42.154638],[-76.262662,42.18523],[-76.267163,42.271412],[-76.314197,42.308299],[-76.315663,42.308305],[-76.416599,42.226497]]]},"properties":{"id":"13743","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.292735,41.998393],[-76.331561,42.07617],[-76.43366,42.034944],[-76.47303,41.999142],[-76.292735,41.998393]]]},"properties":{"id":"13812","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.53706,42.368053],[-76.660045,42.416405],[-76.687822,42.412175],[-76.691069,42.313319],[-76.657456,42.283672],[-76.538349,42.281755],[-76.53706,42.368053]]]},"properties":{"id":"14867","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.106474,41.839861],[-74.114363,41.845831],[-74.184156,41.8143],[-74.255064,41.739181],[-74.266032,41.701427],[-74.249511,41.693172],[-74.173817,41.749501],[-74.099915,41.823825],[-74.106474,41.839861]]]},"properties":{"id":"12440","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.132189,41.930118],[-74.088822,41.98949],[-74.134791,42.000884],[-74.17655,41.983268],[-74.175484,41.981179],[-74.180772,41.94908],[-74.132189,41.930118]]]},"properties":{"id":"12491","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.231592,41.887494],[-74.184156,41.8143],[-74.114363,41.845831],[-74.118619,41.866045],[-74.192505,41.925327],[-74.231592,41.887494]]]},"properties":{"id":"12484","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.198467,42.105981],[-74.217339,42.06144],[-74.199078,42.037824],[-74.127883,42.100718],[-74.198467,42.105981]]]},"properties":{"id":"12448","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.277715,41.99217],[-74.248835,42.012426],[-74.24959,42.019474],[-74.305289,42.03363],[-74.31745,42.000311],[-74.277715,41.99217]]]},"properties":{"id":"12412","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.268601,42.083941],[-74.217339,42.06144],[-74.198467,42.105981],[-74.224748,42.107975],[-74.242294,42.109407],[-74.268601,42.083941]]]},"properties":{"id":"12495","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.941054,43.63347],[-74.024212,43.619735],[-74.008073,43.609737],[-74.03633,43.591273],[-74.050546,43.60233],[-74.09002,43.590298],[-74.151369,43.523019],[-73.971656,43.535553],[-73.890021,43.610622],[-73.941054,43.63347]]]},"properties":{"id":"12843","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.397222,43.293389],[-73.258697,43.230555],[-73.249694,43.438101],[-73.27101,43.489821],[-73.40193,43.477309],[-73.368613,43.445469],[-73.399899,43.370751],[-73.408367,43.312418],[-73.397222,43.293389]],[[-73.283491,43.433426],[-73.320794,43.446568],[-73.283186,43.462348],[-73.283491,43.433426]]]},"properties":{"id":"12832","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.602969,43.384421],[-73.59496,43.306118],[-73.600244,43.278807],[-73.499497,43.328312],[-73.602969,43.384421]]]},"properties":{"id":"12839","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.602969,43.384421],[-73.499497,43.328312],[-73.470078,43.304683],[-73.420123,43.327302],[-73.399899,43.370751],[-73.368613,43.445469],[-73.429464,43.474526],[-73.470126,43.539071],[-73.485302,43.534469],[-73.553476,43.569096],[-73.584134,43.57298],[-73.619685,43.520394],[-73.623458,43.457795],[-73.602969,43.384421]]]},"properties":{"id":"12827","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.939492,43.250025],[-76.836222,43.181149],[-76.826116,43.141079],[-76.735345,43.172964],[-76.759,43.323715],[-76.943921,43.273252],[-76.939492,43.250025]]]},"properties":{"id":"14590","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.693258,41.296462],[-73.728942,41.318273],[-73.753398,41.287372],[-73.768764,41.277282],[-73.702247,41.268127],[-73.693258,41.296462]]],[[[-73.727803,41.246365],[-73.653966,41.229249],[-73.619728,41.229552],[-73.62142,41.255742],[-73.612218,41.284586],[-73.614497,41.302955],[-73.709372,41.265112],[-73.729834,41.250445],[-73.727803,41.246365]]]]},"properties":{"id":"10536","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.858652,40.997989],[-73.883565,41.003851],[-73.888824,40.976542],[-73.872624,40.97929],[-73.86759,40.980105],[-73.845461,40.983509],[-73.843467,40.996169],[-73.858652,40.997989]]]},"properties":{"id":"10706","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.849633,40.946863],[-73.83344,40.949478],[-73.823815,40.97759],[-73.845461,40.983509],[-73.86759,40.980105],[-73.871857,40.95763],[-73.849633,40.946863]]]},"properties":{"id":"10710","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.801772,40.944289],[-73.803896,40.928346],[-73.768809,40.934414],[-73.771812,40.955677],[-73.79734,40.96411],[-73.801772,40.944289]]]},"properties":{"id":"10804","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.817977,41.2374],[-73.876196,41.17722],[-73.867034,41.139577],[-73.807433,41.18474],[-73.785253,41.208538],[-73.772774,41.218388],[-73.766118,41.229274],[-73.817977,41.2374]],[[-73.834665,41.17521],[-73.839625,41.177402],[-73.835975,41.182446],[-73.831504,41.180407],[-73.834665,41.17521]]]},"properties":{"id":"10562","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.807433,41.18474],[-73.807432,41.180124],[-73.785253,41.208538],[-73.807433,41.18474]]]},"properties":{"id":"10546","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.670005,41.354053],[-73.658844,41.332683],[-73.63788,41.357348],[-73.660619,41.355026],[-73.670005,41.354053]]]},"properties":{"id":"10519","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.76137,42.791771],[-73.732127,42.765655],[-73.698857,42.751143],[-73.684362,42.760778],[-73.683269,42.767949],[-73.676762,42.783277],[-73.731669,42.821305],[-73.76137,42.791771]]]},"properties":{"id":"12047","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.745497,42.714073],[-73.732127,42.765655],[-73.76137,42.791771],[-73.768764,42.785877],[-73.809369,42.778869],[-73.823538,42.757175],[-73.793759,42.726423],[-73.745497,42.714073]]]},"properties":{"id":"12110","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.776897,42.62545],[-73.759739,42.61379],[-73.747644,42.643233],[-73.761956,42.649191],[-73.768137,42.651338],[-73.772669,42.647334],[-73.776897,42.62545]]]},"properties":{"id":"12202","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.729167,42.664999],[-73.749046,42.669532],[-73.761956,42.649191],[-73.747644,42.643233],[-73.729167,42.664999]]]},"properties":{"id":"12207","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.83074,42.69256],[-73.833268,42.687148],[-73.824572,42.677849],[-73.820366,42.689707],[-73.83074,42.69256]]]},"properties":{"id":"12222","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.270091,42.629335],[-74.201858,42.561837],[-74.116494,42.539842],[-74.08061,42.555899],[-74.09674,42.628827],[-74.122679,42.694684],[-74.168867,42.681187],[-74.270091,42.629335]]]},"properties":{"id":"12023","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.430945,42.593387],[-74.41463,42.499358],[-74.432769,42.473398],[-74.326265,42.461298],[-74.216976,42.497958],[-74.201858,42.561837],[-74.270091,42.629335],[-74.414819,42.637923],[-74.430945,42.593387]]]},"properties":{"id":"12122","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.266508,42.727578],[-74.168867,42.681187],[-74.122679,42.694684],[-74.1128,42.738478],[-74.143337,42.79342],[-74.112059,42.816269],[-74.207546,42.836553],[-74.26287,42.823409],[-74.266508,42.727578]]]},"properties":{"id":"12053","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.964174,42.708807],[-73.966471,42.705478],[-73.962658,42.701751],[-73.957883,42.702709],[-73.964999,42.70597],[-73.964174,42.708807]]]},"properties":{"id":"12085","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.740245,42.216741],[-77.839024,42.226878],[-77.881402,42.173718],[-77.801872,42.075487],[-77.746741,42.072623],[-77.704053,42.101044],[-77.709655,42.191315],[-77.632925,42.20185],[-77.740245,42.216741]]]},"properties":{"id":"14806","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.251153,42.36565],[-78.177044,42.403205],[-78.265406,42.410662],[-78.308448,42.413504],[-78.308812,42.370911],[-78.251153,42.36565]]]},"properties":{"id":"14777","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.899949,40.85742],[-73.911049,40.845143],[-73.913137,40.839814],[-73.900567,40.836592],[-73.892169,40.84105],[-73.88382,40.851499],[-73.899949,40.85742]]]},"properties":{"id":"10457","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.920956,40.887288],[-73.908605,40.871678],[-73.887105,40.882923],[-73.89571,40.885781],[-73.920956,40.887288]]]},"properties":{"id":"10463","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.931681,40.807955],[-73.901892,40.805007],[-73.902988,40.809364],[-73.922088,40.813731],[-73.931681,40.807955]]]},"properties":{"id":"10454","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.17356,42.183635],[-76.067906,42.225265],[-76.056226,42.271427],[-76.100612,42.278484],[-76.267163,42.271412],[-76.262662,42.18523],[-76.17356,42.183635]]]},"properties":{"id":"13811","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.618643,42.086329],[-78.517558,42.005903],[-78.492041,42.082796],[-78.480342,42.08158],[-78.443624,42.164464],[-78.486312,42.237014],[-78.489375,42.236344],[-78.580303,42.151248],[-78.618643,42.086329]]]},"properties":{"id":"14706","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.986043,42.426471],[-78.976239,42.41599],[-78.968752,42.424632],[-78.986043,42.426471]]]},"properties":{"id":"14041","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.489375,42.236344],[-78.574038,42.297645],[-78.69693,42.259099],[-78.676504,42.172325],[-78.580303,42.151248],[-78.489375,42.236344]]]},"properties":{"id":"14741","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.137873,42.570462],[-79.080904,42.512687],[-79.061017,42.51303],[-78.993082,42.521197],[-78.992326,42.521743],[-78.988305,42.521684],[-78.982035,42.540604],[-78.980232,42.566705],[-79.01734,42.600451],[-79.069672,42.602702],[-79.069707,42.587421],[-79.090932,42.594243],[-79.073154,42.602712],[-79.113331,42.610471],[-79.137873,42.570462]]]},"properties":{"id":"14081","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.478568,42.530227],[-78.464228,42.524986],[-78.47017,42.533534],[-78.478568,42.530227]]]},"properties":{"id":"14173","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.586788,42.710231],[-76.595275,42.724489],[-76.668641,42.678084],[-76.646243,42.624292],[-76.555854,42.62415],[-76.586788,42.710231]]]},"properties":{"id":"13081","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.460732,42.334975],[-79.470615,42.25661],[-79.439825,42.225479],[-79.33824,42.216329],[-79.38221,42.265146],[-79.437455,42.33708],[-79.460755,42.337287],[-79.460732,42.334975]]]},"properties":{"id":"14728","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.965433,42.192226],[-76.925877,42.096461],[-76.925449,42.145741],[-76.965433,42.192226]]]},"properties":{"id":"14814","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.620605,42.123237],[-76.633981,42.108188],[-76.589207,42.093439],[-76.452404,42.107327],[-76.461668,42.127576],[-76.536507,42.155403],[-76.613077,42.130191],[-76.620605,42.123237]]]},"properties":{"id":"14859","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.65722,42.039303],[-76.62108,42.00048],[-76.596123,42.000449],[-76.589207,42.093439],[-76.633981,42.108188],[-76.65722,42.039303]]]},"properties":{"id":"14825","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.840271,42.072777],[-76.781825,42.050539],[-76.77952,42.051496],[-76.810256,42.086167],[-76.840271,42.072777]]]},"properties":{"id":"14904","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.840271,42.072777],[-76.810256,42.086167],[-76.835693,42.109518],[-76.888734,42.093023],[-76.840271,42.072777]]]},"properties":{"id":"14905","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.596123,42.000449],[-76.47303,41.999142],[-76.43366,42.034944],[-76.452404,42.107327],[-76.589207,42.093439],[-76.596123,42.000449]]]},"properties":{"id":"14892","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.581357,42.623993],[-75.727132,42.641242],[-75.768483,42.609511],[-75.700643,42.58357],[-75.581357,42.623993]]]},"properties":{"id":"13844","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.830731,42.685707],[-75.826601,42.644548],[-75.830492,42.685084],[-75.830731,42.685707]]],[[[-75.838639,42.639353],[-75.885433,42.674327],[-75.883843,42.655634],[-75.877159,42.575737],[-75.820128,42.580321],[-75.801985,42.596707],[-75.815121,42.619732],[-75.838639,42.639353]]]]},"properties":{"id":"13136","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.59475,44.48978],[-73.614453,44.454386],[-73.56241,44.479308],[-73.59475,44.48978]]]},"properties":{"id":"12924","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.96691,44.852376],[-73.897588,44.765385],[-73.849292,44.961343],[-73.986594,44.887275],[-73.96691,44.852376]]]},"properties":{"id":"12934","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.733379,42.044045],[-73.715716,41.981074],[-73.624956,41.906018],[-73.601171,41.887702],[-73.586519,41.98648],[-73.643751,42.005919],[-73.688681,42.059364],[-73.733379,42.044045]]]},"properties":{"id":"12567","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.806215,42.044541],[-73.733379,42.044045],[-73.688681,42.059364],[-73.721926,42.123271],[-73.733169,42.137302],[-73.805312,42.099234],[-73.811398,42.076442],[-73.806215,42.044541]]]},"properties":{"id":"12523","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.722154,42.432109],[-73.669808,42.356851],[-73.625225,42.33864],[-73.590224,42.402456],[-73.592288,42.439546],[-73.615672,42.475202],[-73.643875,42.478382],[-73.679929,42.482923],[-73.722154,42.432109]],[[-73.658324,42.433451],[-73.660933,42.439655],[-73.67077,42.445416],[-73.668878,42.449409],[-73.657864,42.445668],[-73.651156,42.438287],[-73.658324,42.433451]]]},"properties":{"id":"12184","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.625225,42.33864],[-73.517361,42.299779],[-73.494007,42.319863],[-73.504826,42.347301],[-73.549933,42.373769],[-73.590224,42.402456],[-73.625225,42.33864]]]},"properties":{"id":"12037","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.590224,42.402456],[-73.549933,42.373769],[-73.520455,42.481999],[-73.548999,42.476777],[-73.592288,42.439546],[-73.590224,42.402456]]]},"properties":{"id":"12136","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.52069,42.492207],[-73.624194,42.480398],[-73.615672,42.475202],[-73.592288,42.439546],[-73.548999,42.476777],[-73.520521,42.484589],[-73.52069,42.492207]]]},"properties":{"id":"12115","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.498571,41.890138],[-73.503143,42.069661],[-73.525785,42.073229],[-73.532346,42.067703],[-73.586519,41.98648],[-73.601171,41.887702],[-73.498571,41.890138]]]},"properties":{"id":"12546","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.679834,42.486151],[-73.773161,42.509377],[-73.786128,42.478017],[-73.783721,42.464231],[-73.781428,42.464438],[-73.778766,42.46468],[-73.772774,42.465225],[-73.731264,42.431733],[-73.722154,42.432109],[-73.679929,42.482923],[-73.679834,42.486151]]]},"properties":{"id":"12156","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.112619,42.555668],[-76.018073,42.527308],[-76.024741,42.649125],[-76.072768,42.671958],[-76.112619,42.555668]]]},"properties":{"id":"13101","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.591997,42.222896],[-74.593992,42.223677],[-74.595466,42.213959],[-74.606473,42.204231],[-74.599679,42.20268],[-74.598905,42.20804],[-74.593049,42.213054],[-74.591997,42.222896]]]},"properties":{"id":"12438","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.090905,42.102521],[-75.168428,41.981645],[-75.179975,41.969426],[-75.133589,41.963607],[-75.029328,41.903972],[-75.047347,41.995437],[-75.090905,42.102521]]]},"properties":{"id":"13756","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.877539,42.498202],[-74.968706,42.468435],[-74.955837,42.445689],[-74.92079,42.454105],[-74.920544,42.450026],[-74.877845,42.464221],[-74.779837,42.433572],[-74.733061,42.433783],[-74.750672,42.451713],[-74.787911,42.499211],[-74.877539,42.498202]]]},"properties":{"id":"13750","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.926757,42.015754],[-73.908703,42.012016],[-73.90919,42.034793],[-73.924897,42.049562],[-73.926757,42.015754]]]},"properties":{"id":"12504","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.593197,41.843528],[-73.650411,41.866038],[-73.709481,41.840761],[-73.731894,41.818003],[-73.750126,41.770864],[-73.743165,41.731317],[-73.679234,41.709864],[-73.648605,41.708979],[-73.592101,41.787797],[-73.593197,41.843528]]]},"properties":{"id":"12545","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.859427,42.855819],[-78.86252,42.902163],[-78.868971,42.90221],[-78.89456,42.87841],[-78.862283,42.83227],[-78.84395,42.845103],[-78.848784,42.859464],[-78.859427,42.855819]]]},"properties":{"id":"14203","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.79963,42.919007],[-78.784297,42.900528],[-78.728099,42.907034],[-78.696938,42.929984],[-78.696955,42.93673],[-78.696925,42.949866],[-78.765507,42.949616],[-78.784058,42.949161],[-78.79963,42.919007]]]},"properties":{"id":"14225","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.980232,42.566705],[-78.797883,42.554638],[-78.783784,42.584161],[-78.963621,42.619647],[-79.01734,42.600451],[-78.980232,42.566705]]]},"properties":{"id":"14111","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.93759,42.696319],[-78.941956,42.696231],[-78.940487,42.69964],[-78.970197,42.71994],[-79.065287,42.662384],[-78.954947,42.674921],[-78.935917,42.696215],[-78.93759,42.696319]]]},"properties":{"id":"14047","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.737883,43.024916],[-78.681987,43.000239],[-78.666733,43.085364],[-78.727647,43.08622],[-78.734222,43.04713],[-78.737883,43.024916]]]},"properties":{"id":"14051","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.685498,42.863958],[-78.562934,42.862751],[-78.56252,42.86806],[-78.578048,42.954758],[-78.660457,42.955861],[-78.677082,42.947798],[-78.696955,42.93673],[-78.696938,42.929984],[-78.685498,42.863958]]]},"properties":{"id":"14086","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.85065,42.493612],[-78.850397,42.487608],[-78.839982,42.487311],[-78.839953,42.490867],[-78.85065,42.493612]]]},"properties":{"id":"14035","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.86779,42.927927],[-78.859741,42.903909],[-78.852752,42.90382],[-78.843426,42.921934],[-78.865779,42.929484],[-78.86779,42.927927]]]},"properties":{"id":"14208","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.501932,44.395813],[-73.657743,44.342114],[-73.672837,44.278737],[-73.567434,44.247573],[-73.485003,44.309837],[-73.501932,44.395813]]]},"properties":{"id":"12950","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.557041,44.066898],[-73.617678,44.066167],[-73.623193,44.030972],[-73.557041,44.066898]]],[[[-73.509386,44.061254],[-73.622367,44.024592],[-73.61757,43.989401],[-73.472961,44.004455],[-73.509386,44.061254]]]]},"properties":{"id":"12960","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.486767,44.783469],[-74.471892,44.684761],[-74.350123,44.693162],[-74.377694,44.925923],[-74.401444,44.924319],[-74.430351,44.905294],[-74.486767,44.783469]]]},"properties":{"id":"12966","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.441856,44.996488],[-74.571636,44.998872],[-74.571982,44.967286],[-74.506194,44.930947],[-74.430351,44.905294],[-74.401444,44.924319],[-74.441856,44.996488]]]},"properties":{"id":"12937","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.258348,44.407263],[-74.313435,44.415057],[-74.267622,44.268561],[-74.214257,44.263224],[-74.258348,44.407263]]]},"properties":{"id":"12945","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.506194,44.930947],[-74.63121,44.875391],[-74.628489,44.855697],[-74.617402,44.774552],[-74.506194,44.930947]]]},"properties":{"id":"12957","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.216995,43.04744],[-74.10203,43.011771],[-74.082805,43.026224],[-74.068017,43.129578],[-74.043244,43.160852],[-74.12343,43.148218],[-74.215127,43.070458],[-74.216995,43.04744]]]},"properties":{"id":"12025","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.824478,43.134287],[-74.802408,43.137034],[-74.821397,43.143143],[-74.824478,43.134287]]],[[[-74.824478,43.134287],[-74.761889,43.059199],[-74.546406,43.08913],[-74.553389,43.154329],[-74.72244,43.146222],[-74.802408,43.137034],[-74.824478,43.134287]]]]},"properties":{"id":"13329","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.117019,42.881337],[-78.026769,42.801786],[-77.955567,42.800477],[-77.955355,42.810524],[-77.920992,42.884992],[-77.955455,42.901193],[-78.02944,42.956342],[-78.072073,42.936718],[-78.117019,42.881337]]]},"properties":{"id":"14525","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.839175,42.428109],[-73.805843,42.440742],[-73.808021,42.46165],[-73.838981,42.444617],[-73.839175,42.428109]]],[[[-73.839175,42.428109],[-73.881435,42.395874],[-73.8745,42.387286],[-73.859367,42.37099],[-73.794999,42.393363],[-73.783625,42.43136],[-73.803156,42.433517],[-73.839175,42.428109]]]]},"properties":{"id":"12192","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.190568,42.415289],[-74.148406,42.399081],[-74.134527,42.411295],[-74.164963,42.418106],[-74.190568,42.415289]]]},"properties":{"id":"12460","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.190568,42.415289],[-74.251965,42.400783],[-74.244692,42.377159],[-74.229985,42.372685],[-74.16014,42.381737],[-74.148406,42.399081],[-74.190568,42.415289]]]},"properties":{"id":"12422","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.439853,42.16473],[-74.32448,42.120753],[-74.286479,42.112814],[-74.246332,42.171017],[-74.325722,42.215722],[-74.376285,42.245934],[-74.440799,42.219997],[-74.450322,42.168698],[-74.439853,42.16473]]]},"properties":{"id":"12492","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.056377,42.221623],[-74.042393,42.170386],[-73.996421,42.17339],[-74.013191,42.242092],[-74.056377,42.221623]]]},"properties":{"id":"12463","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.246332,42.171017],[-74.286479,42.112814],[-74.242294,42.109407],[-74.224748,42.107975],[-74.212299,42.14139],[-74.246332,42.171017]]]},"properties":{"id":"12450","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.820108,43.818507],[-74.813155,43.766887],[-74.797795,43.653398],[-74.660611,43.673682],[-74.668346,43.730944],[-74.801684,43.800628],[-74.820108,43.818507]]]},"properties":{"id":"13360","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.333749,43.443568],[-74.36332,43.655084],[-74.516383,43.800991],[-74.538754,43.822278],[-74.478258,43.380476],[-74.333749,43.443568]]]},"properties":{"id":"12108","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.813155,43.766887],[-75.005764,43.85904],[-75.133487,43.802515],[-75.130204,43.776311],[-75.123087,43.72037],[-74.997855,43.70799],[-75.085618,43.6611],[-75.119785,43.693737],[-75.115369,43.658032],[-75.113605,43.64386],[-75.11016,43.615229],[-75.106727,43.585618],[-75.101669,43.541097],[-74.797795,43.653398],[-74.813155,43.766887]]]},"properties":{"id":"13420","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.072762,43.151548],[-75.049969,43.129967],[-75.029275,43.121082],[-74.973276,43.130023],[-74.867885,43.154493],[-74.880123,43.241401],[-75.020546,43.22792],[-75.072762,43.151548]]]},"properties":{"id":"13416","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.120772,43.778742],[-76.0729,43.740668],[-76.067308,43.737923],[-76.044731,43.738596],[-75.982968,43.72272],[-75.913002,43.784242],[-75.975473,43.833146],[-76.076022,43.900952],[-76.127549,43.903742],[-76.132646,43.894393],[-76.168284,43.857257],[-76.120772,43.778742]]]},"properties":{"id":"13605","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.122263,44.038085],[-76.063008,43.995579],[-76.057633,43.957114],[-75.981498,44.000612],[-76.000418,44.049956],[-75.980703,44.067309],[-76.01415,44.071396],[-76.122263,44.038085]]]},"properties":{"id":"13634","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.067994,43.736173],[-76.067308,43.737923],[-76.0729,43.740668],[-76.067994,43.736173]]],[[[-76.067308,43.737923],[-76.058681,43.734974],[-76.044731,43.738596],[-76.067308,43.737923]]]]},"properties":{"id":"13674","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.062622,43.596111],[-75.894699,43.604269],[-75.866141,43.695482],[-75.976531,43.703003],[-76.066391,43.671328],[-76.087531,43.613476],[-76.062622,43.596111]]]},"properties":{"id":"13083","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.957132,40.688463],[-73.972687,40.687415],[-73.970749,40.672639],[-73.970419,40.672511],[-73.962894,40.663798],[-73.957974,40.670657],[-73.957132,40.688463]]]},"properties":{"id":"11238","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.95702,40.698974],[-73.95181,40.691599],[-73.945642,40.692033],[-73.927588,40.701762],[-73.934802,40.713373],[-73.95702,40.698974]]]},"properties":{"id":"11206","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.947731,40.669705],[-73.940029,40.663021],[-73.931607,40.663557],[-73.924244,40.666445],[-73.937398,40.67973],[-73.947731,40.669705]]]},"properties":{"id":"11213","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.946509,40.640104],[-73.947264,40.656437],[-73.9619,40.654871],[-73.966213,40.653189],[-73.965382,40.636279],[-73.9584,40.636327],[-73.946509,40.640104]]]},"properties":{"id":"11226","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.965382,40.636279],[-73.977249,40.630707],[-73.972994,40.608814],[-73.96238,40.609986],[-73.952577,40.611037],[-73.9584,40.636327],[-73.965382,40.636279]]]},"properties":{"id":"11230","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.39869,43.676147],[-75.455746,43.698365],[-75.567227,43.656081],[-75.384474,43.580683],[-75.383105,43.582143],[-75.383691,43.610387],[-75.39869,43.676147]]]},"properties":{"id":"13473","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.671059,42.865608],[-77.533081,42.826536],[-77.552162,42.903734],[-77.555629,42.90864],[-77.567615,42.931345],[-77.644495,42.927138],[-77.671059,42.865608]]]},"properties":{"id":"14485","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.685504,42.67227],[-77.718062,42.672494],[-77.718231,42.661444],[-77.685504,42.67227]]]},"properties":{"id":"14545","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.608852,42.809194],[-75.481053,42.796279],[-75.475014,42.847493],[-75.541364,42.884066],[-75.583425,42.861034],[-75.608852,42.809194]]]},"properties":{"id":"13346","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.709565,43.151437],[-75.709535,43.088988],[-75.70321,43.07557],[-75.669334,42.996914],[-75.644027,42.96543],[-75.574205,43.036461],[-75.550017,43.063855],[-75.583287,43.074293],[-75.585812,43.059244],[-75.616032,43.066269],[-75.611328,43.08179],[-75.584445,43.078139],[-75.588295,43.100981],[-75.641492,43.117273],[-75.709565,43.151437]]]},"properties":{"id":"13421","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.270455,42.825147],[-75.282316,42.809812],[-75.250267,42.797375],[-75.270455,42.825147]]]},"properties":{"id":"13364","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.746208,42.913396],[-75.813279,42.869539],[-75.795668,42.825064],[-75.721814,42.814134],[-75.685816,42.864297],[-75.746208,42.913396]]]},"properties":{"id":"13061","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.576939,43.197937],[-77.582098,43.175657],[-77.585157,43.161835],[-77.569673,43.153357],[-77.528403,43.163304],[-77.519506,43.17446],[-77.525221,43.203563],[-77.576939,43.197937]]]},"properties":{"id":"14609","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.652083,43.058694],[-77.688976,43.08277],[-77.723086,43.016962],[-77.648001,43.048026],[-77.652083,43.058694]]]},"properties":{"id":"14586","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.075674,43.299393],[-78.142152,43.165291],[-78.099229,43.131401],[-78.016842,43.132419],[-77.99729,43.132981],[-77.965723,43.28974],[-77.980447,43.308935],[-78.075674,43.299393]]]},"properties":{"id":"14470","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.616249,42.934123],[-74.585283,42.909103],[-74.478675,42.899926],[-74.478234,42.923257],[-74.613784,42.93472],[-74.610065,42.929804],[-74.610893,42.926529],[-74.616249,42.934123]]]},"properties":{"id":"13428","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.613784,42.93472],[-74.616249,42.934123],[-74.610893,42.926529],[-74.610065,42.929804],[-74.613784,42.93472]]]},"properties":{"id":"13410","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.70542,40.754606],[-73.700983,40.738896],[-73.687896,40.723318],[-73.650997,40.736848],[-73.657632,40.751753],[-73.660393,40.760611],[-73.686552,40.766589],[-73.691638,40.768568],[-73.70542,40.754606]]]},"properties":{"id":"11040","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.657312,40.779816],[-73.65875,40.760861],[-73.63655,40.766984],[-73.657312,40.779816]]]},"properties":{"id":"11507","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.622242,40.795495],[-73.666362,40.789395],[-73.66169,40.779651],[-73.657312,40.779816],[-73.63655,40.766984],[-73.626601,40.759635],[-73.623794,40.759992],[-73.622242,40.795495]]]},"properties":{"id":"11577","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.66169,40.779651],[-73.686552,40.766589],[-73.660393,40.760611],[-73.65875,40.760861],[-73.657312,40.779816],[-73.66169,40.779651]]],[[[-73.666362,40.789395],[-73.622242,40.795495],[-73.612371,40.8062],[-73.639274,40.828133],[-73.652058,40.827595],[-73.668472,40.804791],[-73.666362,40.789395]]]]},"properties":{"id":"11576","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.62128,40.756541],[-73.603449,40.745269],[-73.582996,40.736899],[-73.542955,40.733793],[-73.54569,40.743582],[-73.551026,40.77361],[-73.568826,40.777324],[-73.62128,40.756541]]]},"properties":{"id":"11590","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.599255,40.715797],[-73.607467,40.684493],[-73.601439,40.686065],[-73.575052,40.690846],[-73.575946,40.694497],[-73.581083,40.729677],[-73.609095,40.720783],[-73.603532,40.714288],[-73.605668,40.719614],[-73.602703,40.720341],[-73.599255,40.715797]],[[-73.580662,40.718243],[-73.585546,40.718248],[-73.587728,40.719918],[-73.58201,40.721184],[-73.580662,40.718243]]]},"properties":{"id":"11553","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.728305,40.665917],[-73.740115,40.635511],[-73.736361,40.632362],[-73.732105,40.639535],[-73.714626,40.647607],[-73.689862,40.649978],[-73.689896,40.657963],[-73.728305,40.665917]]]},"properties":{"id":"11581","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.51337,40.630801],[-73.501053,40.645695],[-73.492249,40.705384],[-73.52627,40.707722],[-73.53188,40.706379],[-73.51337,40.630801]]]},"properties":{"id":"11793","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.483611,40.876613],[-73.528378,40.914936],[-73.548644,40.902962],[-73.568315,40.867187],[-73.580806,40.847885],[-73.567301,40.832434],[-73.555534,40.837281],[-73.520561,40.852903],[-73.517431,40.84578],[-73.483611,40.876613]]]},"properties":{"id":"11771","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.442868,40.704529],[-73.449429,40.654185],[-73.425549,40.654611],[-73.429962,40.706699],[-73.442868,40.704529]]],[[[-73.450214,40.705561],[-73.471009,40.717274],[-73.482723,40.712576],[-73.486542,40.648867],[-73.454168,40.651001],[-73.450214,40.705561]]]]},"properties":{"id":"11758","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.450214,40.705561],[-73.454168,40.651001],[-73.449429,40.654185],[-73.442868,40.704529],[-73.450214,40.705561]]]},"properties":{"id":"11762","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.75358,40.797692],[-73.71313,40.806639],[-73.74287,40.833939],[-73.75358,40.797692]]]},"properties":{"id":"11024","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.599255,40.715797],[-73.602703,40.720341],[-73.605668,40.719614],[-73.603532,40.714288],[-73.599255,40.715797]]]},"properties":{"id":"11549","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.947361,40.770062],[-73.96323,40.777677],[-73.965148,40.775045],[-73.951858,40.769441],[-73.94827,40.769505],[-73.947973,40.769512],[-73.947361,40.770062]]]},"properties":{"id":"10075","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.956806,40.807546],[-73.9659,40.808806],[-73.96701,40.788476],[-73.958619,40.799977],[-73.956806,40.807546]]]},"properties":{"id":"10025","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.942924,40.82326],[-73.937181,40.819144],[-73.933377,40.819473],[-73.929107,40.845586],[-73.934671,40.842958],[-73.940167,40.830383],[-73.942924,40.82326]]]},"properties":{"id":"10039","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.007769,40.702879],[-74.010542,40.709154],[-74.010846,40.708797],[-74.009829,40.708117],[-74.010201,40.707694],[-74.011248,40.708304],[-74.012508,40.706767],[-74.007769,40.702879]]]},"properties":{"id":"10005","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.980395,40.754155],[-73.978783,40.753481],[-73.978332,40.7541],[-73.979937,40.754783],[-73.980395,40.754155]]]},"properties":{"id":"10173","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.944486,40.766369],[-73.940229,40.770852],[-73.942146,40.772037],[-73.946677,40.768007],[-73.944486,40.766369]]]},"properties":{"id":"10044","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.060653,43.096026],[-79.010708,43.093901],[-78.973006,43.144593],[-78.988789,43.160996],[-79.003972,43.138035],[-79.020886,43.136097],[-79.051034,43.132055],[-79.062213,43.095946],[-79.060653,43.096026]]]},"properties":{"id":"14305","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.01084,43.09382],[-79.060653,43.096026],[-79.062213,43.095946],[-79.025893,43.078359],[-79.01084,43.09382]]]},"properties":{"id":"14303","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.624751,43.360708],[-78.567637,43.304686],[-78.519825,43.292106],[-78.465595,43.291165],[-78.466429,43.302775],[-78.465475,43.371422],[-78.624751,43.360708]]]},"properties":{"id":"14012","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.524968,43.127969],[-78.519825,43.292106],[-78.567637,43.304686],[-78.620206,43.265441],[-78.557474,43.117944],[-78.524968,43.127969]]]},"properties":{"id":"14067","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.900763,43.303755],[-78.814201,43.220304],[-78.77935,43.219551],[-78.755064,43.287187],[-78.754358,43.337055],[-78.900763,43.303755]]]},"properties":{"id":"14172","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.201375,43.410806],[-75.229227,43.424145],[-75.238807,43.404832],[-75.201375,43.410806]]]},"properties":{"id":"13301","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.861113,43.38029],[-75.805747,43.409723],[-75.838997,43.429425],[-75.861113,43.38029]]]},"properties":{"id":"13483","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.641492,43.117273],[-75.588295,43.100981],[-75.513543,43.132449],[-75.607664,43.191923],[-75.641492,43.117273]]]},"properties":{"id":"13478","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.736685,43.164131],[-75.728487,43.195199],[-75.732744,43.191081],[-75.737774,43.164673],[-75.736685,43.164131]]]},"properties":{"id":"13162","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.154411,43.301933],[-75.149454,43.304819],[-75.155325,43.30688],[-75.154411,43.301933]]]},"properties":{"id":"13435","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.023981,42.991839],[-75.930496,43.04899],[-75.932381,43.058802],[-76.006698,43.048986],[-76.051856,43.043368],[-76.066432,43.01692],[-76.023981,42.991839]]]},"properties":{"id":"13066","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.163629,43.093124],[-76.156142,43.091894],[-76.116747,43.092687],[-76.093698,43.092221],[-76.094038,43.120461],[-76.163629,43.093124]]]},"properties":{"id":"13211","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.155609,43.033623],[-76.153576,43.056361],[-76.168207,43.069491],[-76.177961,43.067549],[-76.192836,43.066498],[-76.197002,43.051376],[-76.186846,43.032049],[-76.155609,43.033623]]]},"properties":{"id":"13204","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.129814,43.148925],[-76.137813,43.188633],[-76.201355,43.213977],[-76.217646,43.204821],[-76.281027,43.201091],[-76.255227,43.189374],[-76.134875,43.150792],[-76.129814,43.148925]]]},"properties":{"id":"13041","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.221085,42.910369],[-76.135357,42.957882],[-76.133804,42.987623],[-76.148277,42.991597],[-76.162747,42.984366],[-76.245424,42.916919],[-76.221085,42.910369]]]},"properties":{"id":"13120","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.153989,43.031752],[-76.148277,42.991597],[-76.133804,42.987623],[-76.124451,43.007835],[-76.142757,43.035709],[-76.153989,43.031752]]]},"properties":{"id":"13205","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.077323,42.825126],[-76.075761,42.817827],[-76.082473,42.810881],[-76.078062,42.806607],[-76.069403,42.816819],[-76.0696,42.825401],[-76.077323,42.825126]]]},"properties":{"id":"13020","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.141793,42.901274],[-77.078134,42.886087],[-77.093771,42.911347],[-77.098005,42.986343],[-77.147036,43.039865],[-77.152742,43.03865],[-77.160115,43.032303],[-77.17101,43.039842],[-77.184321,43.016662],[-77.209791,42.922476],[-77.141793,42.901274]]]},"properties":{"id":"14432","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.307723,43.008652],[-77.2939,42.944204],[-77.209791,42.922476],[-77.184321,43.016662],[-77.307723,43.008652]],[[-77.217307,42.976521],[-77.221949,42.961283],[-77.246156,42.971351],[-77.217307,42.976521]]]},"properties":{"id":"14548","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.111271,41.396779],[-74.118584,41.46477],[-74.132375,41.453266],[-74.139486,41.408036],[-74.122466,41.387387],[-74.111271,41.396779]]]},"properties":{"id":"12577","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.382578,41.590113],[-74.370324,41.588481],[-74.369291,41.57912],[-74.370723,41.543964],[-74.361775,41.542369],[-74.356426,41.54372],[-74.23607,41.588672],[-74.236555,41.601846],[-74.303632,41.694589],[-74.332138,41.684801],[-74.342881,41.678242],[-74.403933,41.666894],[-74.421428,41.658506],[-74.418297,41.627765],[-74.382578,41.590113]]]},"properties":{"id":"12566","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.118058,43.374637],[-76.110091,43.329356],[-76.108076,43.321587],[-76.078162,43.365871],[-76.118058,43.374637]]],[[[-76.116831,43.322807],[-76.194521,43.372104],[-76.200033,43.399732],[-76.256677,43.396928],[-76.263839,43.346918],[-76.24882,43.336982],[-76.241868,43.328277],[-76.20633,43.270582],[-76.129542,43.234972],[-76.118787,43.256335],[-76.109505,43.318487],[-76.116831,43.322807]]]]},"properties":{"id":"13036","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.256677,43.396928],[-76.200033,43.399732],[-76.117716,43.468333],[-76.239877,43.533913],[-76.270777,43.526379],[-76.361108,43.423272],[-76.256677,43.396928]]]},"properties":{"id":"13114","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.990433,43.403254],[-75.995342,43.391784],[-75.980151,43.356514],[-75.960783,43.343057],[-75.892875,43.338592],[-75.861113,43.38029],[-75.838997,43.429425],[-75.757569,43.492522],[-75.890768,43.551674],[-75.990998,43.435342],[-75.990433,43.403254]]]},"properties":{"id":"13493","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.273367,43.306158],[-76.217646,43.204821],[-76.201355,43.213977],[-76.20633,43.270582],[-76.241868,43.328277],[-76.273367,43.306158]]]},"properties":{"id":"13132","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.166927,43.639451],[-76.087531,43.613476],[-76.066391,43.671328],[-76.166927,43.639451]]]},"properties":{"id":"13145","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.017678,42.780896],[-75.027521,42.716286],[-74.942258,42.801711],[-75.017678,42.780896]]]},"properties":{"id":"13337","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.201163,42.577566],[-75.304369,42.569065],[-75.32488,42.517994],[-75.300899,42.472867],[-75.211835,42.495969],[-75.182532,42.532987],[-75.179525,42.53209],[-75.181169,42.572945],[-75.184398,42.588173],[-75.201163,42.577566]]]},"properties":{"id":"13808","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.810702,42.845842],[-74.834275,42.833839],[-74.814915,42.831096],[-74.80438,42.843798],[-74.810702,42.845842]]]},"properties":{"id":"13333","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.821149,41.468065],[-73.763012,41.430259],[-73.694765,41.351387],[-73.670005,41.354053],[-73.660619,41.355026],[-73.62959,41.444106],[-73.650599,41.477103],[-73.715337,41.510049],[-73.77068,41.504413],[-73.821149,41.468065]]]},"properties":{"id":"10512","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.757605,41.344904],[-73.735816,41.347123],[-73.734867,41.337616],[-73.734631,41.337191],[-73.694765,41.351387],[-73.763012,41.430259],[-73.806522,41.357135],[-73.797456,41.340647],[-73.78826,41.341737],[-73.757605,41.344904]]]},"properties":{"id":"10541","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.888259,41.409764],[-73.94694,41.402003],[-73.979528,41.322105],[-73.89346,41.330892],[-73.892449,41.343747],[-73.888259,41.409764]]]},"properties":{"id":"10524","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.62959,41.444106],[-73.660619,41.355026],[-73.63788,41.357348],[-73.544728,41.366375],[-73.533947,41.480115],[-73.62959,41.444106]]]},"properties":{"id":"10509","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.8281,40.776031],[-73.803985,40.77419],[-73.791635,40.774239],[-73.792599,40.790455],[-73.832144,40.790095],[-73.824998,40.781732],[-73.825667,40.77973],[-73.825993,40.77982],[-73.8281,40.776031]]]},"properties":{"id":"11357","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.807858,40.687123],[-73.831746,40.679537],[-73.832906,40.666651],[-73.832644,40.665184],[-73.801663,40.666889],[-73.805768,40.682932],[-73.807858,40.687123]]]},"properties":{"id":"11420","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.763454,40.61369],[-73.789471,40.597611],[-73.770197,40.590913],[-73.73815,40.594539],[-73.745633,40.611756],[-73.763454,40.61369]]]},"properties":{"id":"11691","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.862346,40.679165],[-73.861318,40.675021],[-73.832906,40.666651],[-73.831746,40.679537],[-73.835111,40.685088],[-73.862346,40.679165]]]},"properties":{"id":"11417","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.945111,40.763086],[-73.95685,40.748225],[-73.956208,40.743872],[-73.961544,40.743081],[-73.962795,40.737674],[-73.929223,40.727849],[-73.925851,40.735645],[-73.925787,40.736139],[-73.925467,40.747432],[-73.909813,40.751574],[-73.911498,40.754557],[-73.92246,40.757427],[-73.945111,40.763086]]]},"properties":{"id":"11101","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.818383,40.706729],[-73.843016,40.709176],[-73.854747,40.70683],[-73.852145,40.702375],[-73.844307,40.689442],[-73.837544,40.691364],[-73.813659,40.697133],[-73.818383,40.706729]]]},"properties":{"id":"11418","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.770107,40.731779],[-73.810886,40.727172],[-73.80904,40.719912],[-73.777236,40.722284],[-73.76631,40.726375],[-73.765558,40.732535],[-73.770107,40.731779]]]},"properties":{"id":"11366","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.91431,40.771523],[-73.905239,40.767776],[-73.900659,40.767211],[-73.892348,40.775847],[-73.920425,40.782874],[-73.91431,40.771523]]]},"properties":{"id":"11105","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.729633,40.722453],[-73.71371,40.736175],[-73.722917,40.754098],[-73.732335,40.743576],[-73.732168,40.729155],[-73.729633,40.722453]]]},"properties":{"id":"11426","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.727707,40.701565],[-73.748643,40.701587],[-73.748123,40.690131],[-73.73222,40.685121],[-73.725861,40.683223],[-73.725956,40.685682],[-73.727707,40.701565]]]},"properties":{"id":"11411","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.767353,40.65616],[-73.801663,40.666889],[-73.832644,40.665184],[-73.82641,40.648261],[-73.766954,40.623172],[-73.746702,40.633387],[-73.753214,40.648278],[-73.767353,40.65616]]]},"properties":{"id":"11430","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.467214,42.606027],[-73.447894,42.561865],[-73.406914,42.610484],[-73.428918,42.608887],[-73.467214,42.606027]]]},"properties":{"id":"12169","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.366791,42.624795],[-73.368178,42.634795],[-73.349088,42.642486],[-73.33062,42.639841],[-73.361092,42.625928],[-73.358098,42.614289],[-73.312511,42.617715],[-73.27414,42.721195],[-73.366791,42.624795]]]},"properties":{"id":"12022","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.324181,42.863577],[-73.319234,42.860704],[-73.307893,42.86741],[-73.306,42.871916],[-73.299566,42.873816],[-73.299965,42.878787],[-73.313094,42.868458],[-73.324181,42.863577]]]},"properties":{"id":"12089","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.134558,40.551255],[-74.091164,40.566718],[-74.098493,40.587106],[-74.113023,40.600135],[-74.114871,40.602137],[-74.169611,40.566443],[-74.164941,40.560112],[-74.134558,40.551255]]]},"properties":{"id":"10306","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.13061,40.623495],[-74.126962,40.640181],[-74.143086,40.639784],[-74.14612,40.62493],[-74.13061,40.623495]]]},"properties":{"id":"10302","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.206392,40.558102],[-74.197544,40.511187],[-74.14517,40.535756],[-74.164941,40.560112],[-74.169611,40.566443],[-74.206392,40.558102]]]},"properties":{"id":"10312","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.988305,41.320021],[-74.009828,41.304702],[-74.02668,41.29283],[-73.976422,41.243841],[-73.986746,41.320963],[-73.988305,41.320021]]]},"properties":{"id":"10986","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.966982,41.026197],[-73.938881,41.013597],[-73.932045,41.019913],[-73.937305,41.037846],[-73.966982,41.026197]]]},"properties":{"id":"10983","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.566447,44.439597],[-74.719153,44.597136],[-74.835884,44.626825],[-74.832307,44.549189],[-74.691433,44.37816],[-74.566447,44.439597]]]},"properties":{"id":"13672","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.341475,44.545585],[-75.23625,44.652069],[-75.315535,44.655502],[-75.422798,44.519554],[-75.428826,44.503605],[-75.428462,44.503658],[-75.341475,44.545585]]]},"properties":{"id":"13680","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.119706,44.889216],[-75.25451,44.851137],[-75.228451,44.844071],[-75.109714,44.842835],[-75.119181,44.888673],[-75.119706,44.889216]]]},"properties":{"id":"13694","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.040855,44.873242],[-75.119181,44.888673],[-75.109714,44.842835],[-75.075949,44.773079],[-75.040855,44.873242]]]},"properties":{"id":"13621","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.298959,44.431745],[-75.205706,44.521539],[-75.341475,44.545585],[-75.428462,44.503658],[-75.423711,44.46968],[-75.298959,44.431745]]]},"properties":{"id":"13630","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.763515,43.22539],[-73.786902,43.193],[-73.773712,43.113016],[-73.65213,43.134512],[-73.580254,43.155539],[-73.580363,43.156432],[-73.74,43.262127],[-73.763515,43.22539]]]},"properties":{"id":"12831","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.92286,43.132905],[-73.862295,43.095465],[-73.773712,43.113016],[-73.786902,43.193],[-73.85532,43.19665],[-73.92286,43.132905]]]},"properties":{"id":"12833","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.65213,43.134512],[-73.65976,43.054686],[-73.593635,43.01433],[-73.580254,43.155539],[-73.65213,43.134512]],[[-73.594175,43.082257],[-73.600134,43.083646],[-73.58283,43.096185],[-73.585303,43.084909],[-73.594175,43.082257]]]},"properties":{"id":"12871","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.803929,42.95088],[-73.80202,42.925965],[-73.770166,42.907758],[-73.803929,42.95088]]]},"properties":{"id":"12151","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.427902,42.77268],[-74.476343,42.781074],[-74.491583,42.76387],[-74.428804,42.756855],[-74.427902,42.77268]]]},"properties":{"id":"12031","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.665839,42.593733],[-76.780189,42.544633],[-76.790077,42.514512],[-76.766194,42.430976],[-76.729901,42.433468],[-76.687822,42.412175],[-76.660045,42.416405],[-76.567804,42.530552],[-76.665839,42.593733]],[[-76.615364,42.502739],[-76.615567,42.506974],[-76.614628,42.50659],[-76.614383,42.502762],[-76.615364,42.502739]]]},"properties":{"id":"14886","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.873661,42.632514],[-76.880935,42.541902],[-76.800326,42.557638],[-76.785242,42.629319],[-76.873661,42.632514]]]},"properties":{"id":"14860","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.225109,42.669053],[-77.265877,42.635127],[-77.312848,42.57625],[-77.155525,42.54729],[-77.1539,42.555961],[-77.140749,42.597252],[-77.141754,42.629603],[-77.225109,42.669053]]]},"properties":{"id":"14418","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.709655,42.191315],[-77.704053,42.101044],[-77.63231,42.088514],[-77.56358,42.096683],[-77.548625,42.163132],[-77.709655,42.191315]]]},"properties":{"id":"14839","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.649414,42.54202],[-77.634512,42.536507],[-77.631899,42.542648],[-77.649414,42.54202]]]},"properties":{"id":"14529","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.106635,42.465888],[-77.108299,42.46874],[-77.109765,42.467855],[-77.106635,42.465888]]]},"properties":{"id":"14893","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.865056,40.833675],[-72.866312,40.850816],[-72.890798,40.844382],[-72.88596,40.802146],[-72.89062,40.762274],[-72.862844,40.740694],[-72.852789,40.782773],[-72.865056,40.833675]]]},"properties":{"id":"11967","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.228311,40.754848],[-73.225368,40.702978],[-73.2046,40.70413],[-73.2012,40.742723],[-73.199935,40.755511],[-73.209223,40.755529],[-73.228311,40.754848]]]},"properties":{"id":"11751","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.26936,40.805785],[-73.231246,40.804197],[-73.197293,40.804726],[-73.154225,40.81737],[-73.147861,40.826241],[-73.168311,40.827658],[-73.254694,40.830889],[-73.26936,40.805785]]]},"properties":{"id":"11788","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.154225,40.81737],[-73.171495,40.796684],[-73.165459,40.792813],[-73.087855,40.78231],[-73.066868,40.818546],[-73.064762,40.818874],[-73.076171,40.842201],[-73.101483,40.848719],[-73.122205,40.834903],[-73.147861,40.826241],[-73.154225,40.81737]]]},"properties":{"id":"11779","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.15259,40.748251],[-73.156103,40.743237],[-73.149627,40.723869],[-73.127237,40.721618],[-73.108039,40.717186],[-73.108872,40.742828],[-73.11247,40.75002],[-73.150293,40.747275],[-73.15259,40.748251]]]},"properties":{"id":"11769","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.231246,40.804197],[-73.26936,40.805785],[-73.294652,40.803983],[-73.294966,40.784297],[-73.291028,40.770168],[-73.228311,40.754848],[-73.209223,40.755529],[-73.231246,40.804197]]]},"properties":{"id":"11717","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.042485,40.673317],[-73.105109,40.657268],[-73.10279,40.654946],[-73.040035,40.670883],[-73.042485,40.673317]]],[[[-73.069845,40.722565],[-73.061604,40.769076],[-73.083038,40.764458],[-73.11247,40.75002],[-73.108872,40.742828],[-73.089667,40.720355],[-73.069845,40.722565]]]]},"properties":{"id":"11782","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.156103,40.743237],[-73.171265,40.735787],[-73.149627,40.723869],[-73.156103,40.743237]]]},"properties":{"id":"11739","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.865056,40.833675],[-72.852789,40.782773],[-72.823628,40.78856],[-72.837361,40.820022],[-72.865056,40.833675]]]},"properties":{"id":"11950","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.566271,40.815316],[-72.54076,40.813709],[-72.540523,40.825869],[-72.57165,40.821172],[-72.567605,40.817054],[-72.566271,40.815316]]],[[[-72.561377,40.875952],[-72.616527,40.870534],[-72.617574,40.833468],[-72.582512,40.831599],[-72.548524,40.851239],[-72.561377,40.875952]]]]},"properties":{"id":"11942","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.038452,40.937698],[-73.054717,40.974434],[-73.100159,40.97885],[-73.072709,40.931544],[-73.038452,40.937698]]]},"properties":{"id":"11777","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.254694,40.830889],[-73.168311,40.827658],[-73.160437,40.859702],[-73.208725,40.898738],[-73.248614,40.866393],[-73.254694,40.830889]]]},"properties":{"id":"11787","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.288521,40.873218],[-73.248614,40.866393],[-73.208725,40.898738],[-73.232677,40.913281],[-73.253102,40.92115],[-73.287921,40.883895],[-73.288521,40.873218]]]},"properties":{"id":"11754","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.708103,40.831241],[-72.723383,40.844497],[-72.740113,40.848476],[-72.748697,40.833716],[-72.728759,40.814994],[-72.723923,40.816212],[-72.713164,40.820339],[-72.708103,40.831241]]]},"properties":{"id":"11941","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.024346,41.27304],[-72.03472,41.25642],[-72.002844,41.252769],[-71.994177,41.27066],[-72.024346,41.27304]]]},"properties":{"id":"06390","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.400103,41.075573],[-72.402383,41.105508],[-72.483338,41.06026],[-72.436136,41.035653],[-72.400103,41.075573]]]},"properties":{"id":"11971","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.402383,41.105508],[-72.400103,41.075573],[-72.393878,41.072461],[-72.342102,41.103943],[-72.363009,41.140082],[-72.402383,41.105508]]]},"properties":{"id":"11944","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.5352,40.974213],[-72.582422,40.984897],[-72.572748,40.957647],[-72.569894,40.954395],[-72.564692,40.947689],[-72.563324,40.945805],[-72.5352,40.974213]]]},"properties":{"id":"11948","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.471431,40.979428],[-72.468205,40.967653],[-72.453211,40.95789],[-72.458491,40.975781],[-72.471431,40.979428]]]},"properties":{"id":"11956","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.06269,41.025691],[-72.020157,40.99419],[-71.866044,41.045319],[-71.960411,41.082373],[-72.06269,41.025691]]]},"properties":{"id":"11954","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.336808,40.856428],[-73.335164,40.836086],[-73.293683,40.818457],[-73.288521,40.873218],[-73.287921,40.883895],[-73.33884,40.881064],[-73.336808,40.856428]]]},"properties":{"id":"11731","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.650301,41.672629],[-74.601246,41.67303],[-74.599477,41.689893],[-74.607326,41.705963],[-74.60392,41.730241],[-74.638923,41.735587],[-74.66782,41.726893],[-74.664062,41.720114],[-74.654547,41.676713],[-74.650301,41.672629]]]},"properties":{"id":"12779","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.820745,41.682527],[-74.819368,41.674968],[-74.815739,41.674875],[-74.810933,41.689635],[-74.820745,41.682527]]],[[[-74.857458,41.78891],[-74.874301,41.802011],[-74.919162,41.723017],[-74.897272,41.706609],[-74.864774,41.680427],[-74.861814,41.680693],[-74.813316,41.70274],[-74.77704,41.69415],[-74.770435,41.767123],[-74.817468,41.786967],[-74.857458,41.78891]]]]},"properties":{"id":"12783","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.60392,41.730241],[-74.607326,41.705963],[-74.584924,41.738164],[-74.60392,41.730241]]],[[[-74.60392,41.730241],[-74.619498,41.755554],[-74.638923,41.735587],[-74.60392,41.730241]]]]},"properties":{"id":"12733","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.939124,41.657936],[-74.917163,41.615882],[-74.910807,41.615382],[-74.864774,41.680427],[-74.897272,41.706609],[-74.939124,41.657936]]]},"properties":{"id":"12720","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.43589,42.002155],[-74.502964,42.038031],[-74.52959,42.027736],[-74.626225,41.889986],[-74.589327,41.889972],[-74.392393,41.980052],[-74.43589,42.002155]]]},"properties":{"id":"12725","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.002404,41.864599],[-75.020653,41.826281],[-74.997832,41.822077],[-74.979735,41.839803],[-75.002404,41.864599]]]},"properties":{"id":"12767","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.315663,42.308305],[-76.415205,42.336487],[-76.443064,42.306518],[-76.416599,42.226497],[-76.315663,42.308305]]]},"properties":{"id":"13864","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.646243,42.624292],[-76.519431,42.509202],[-76.475292,42.524657],[-76.482161,42.538822],[-76.523685,42.610305],[-76.536106,42.624908],[-76.555854,42.62415],[-76.646243,42.624292]]]},"properties":{"id":"14882","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.006217,41.80158],[-74.059522,41.716505],[-74.056768,41.656507],[-74.026526,41.633898],[-74.0255,41.638525],[-73.951581,41.670729],[-73.952342,41.766009],[-74.006217,41.80158]]]},"properties":{"id":"12528","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.931105,42.102959],[-73.937543,42.103384],[-73.937702,42.081066],[-73.931105,42.102959]]]},"properties":{"id":"12453","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.328737,42.118191],[-74.395836,42.06108],[-74.43589,42.002155],[-74.392393,41.980052],[-74.33779,41.951056],[-74.31745,42.000311],[-74.305289,42.03363],[-74.286609,42.068265],[-74.328737,42.118191]]]},"properties":{"id":"12464","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.286479,42.112814],[-74.32448,42.120753],[-74.328737,42.118191],[-74.286609,42.068265],[-74.268601,42.083941],[-74.242294,42.109407],[-74.286479,42.112814]]]},"properties":{"id":"12416","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.771083,43.670807],[-73.70684,43.634239],[-73.632652,43.678377],[-73.638602,43.743025],[-73.738116,43.725524],[-73.775762,43.67593],[-73.771083,43.670807]]]},"properties":{"id":"12815","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.832086,43.248854],[-73.764792,43.373532],[-73.791931,43.424187],[-73.874126,43.424903],[-73.883299,43.397988],[-73.827072,43.289932],[-73.832086,43.248854]]]},"properties":{"id":"12846","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.641494,43.465817],[-73.764792,43.373532],[-73.832086,43.248854],[-73.763515,43.22539],[-73.74,43.262127],[-73.663766,43.275212],[-73.660461,43.290572],[-73.666457,43.323401],[-73.619736,43.30494],[-73.59496,43.306118],[-73.602969,43.384421],[-73.623458,43.457795],[-73.629648,43.480283],[-73.641494,43.465817]]]},"properties":{"id":"12804","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.058313,43.744384],[-74.203537,43.657166],[-74.182555,43.52088],[-74.151369,43.523019],[-74.09002,43.590298],[-74.058313,43.744384]]]},"properties":{"id":"12856","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.024212,43.619735],[-74.050546,43.60233],[-74.03633,43.591273],[-74.008073,43.609737],[-74.024212,43.619735]]]},"properties":{"id":"12811","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.266164,43.087998],[-73.261631,43.169499],[-73.345881,43.112952],[-73.266164,43.087998]]]},"properties":{"id":"12873","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.429464,43.474526],[-73.368613,43.445469],[-73.40193,43.477309],[-73.429464,43.474526]]]},"properties":{"id":"12821","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.530591,43.194639],[-73.443055,43.185804],[-73.401821,43.218267],[-73.397222,43.293389],[-73.408367,43.312418],[-73.420123,43.327302],[-73.470078,43.304683],[-73.530591,43.194639]]]},"properties":{"id":"12809","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.242507,43.195435],[-77.241079,43.127389],[-77.167039,43.107764],[-77.085031,43.161666],[-77.101465,43.194222],[-77.242507,43.199301],[-77.242507,43.195435]]]},"properties":{"id":"14505","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.933775,41.268408],[-73.928985,41.263491],[-73.928735,41.26856],[-73.933775,41.268408]]],[[[-73.950506,41.255459],[-73.934461,41.268149],[-73.940746,41.272612],[-73.960463,41.265107],[-73.950506,41.255459]]]]},"properties":{"id":"10511","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.677526,41.302008],[-73.659996,41.323823],[-73.683694,41.321134],[-73.68155,41.297625],[-73.677526,41.302008]]]},"properties":{"id":"10578","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.872624,40.97929],[-73.871857,40.95763],[-73.86759,40.980105],[-73.872624,40.97929]]]},"properties":{"id":"10703","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.715712,40.959277],[-73.744747,40.980429],[-73.757471,40.961611],[-73.73687,40.932645],[-73.716529,40.945286],[-73.715712,40.959277]]]},"properties":{"id":"10543","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.76651,41.306291],[-73.746264,41.304493],[-73.748438,41.318206],[-73.767994,41.337458],[-73.779212,41.33742],[-73.76651,41.306291]]]},"properties":{"id":"10527","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.724046,41.153036],[-73.745298,41.129295],[-73.743234,41.124402],[-73.754585,41.0952],[-73.718555,41.072022],[-73.718264,41.07203],[-73.717737,41.088803],[-73.644074,41.139501],[-73.667753,41.175372],[-73.724046,41.153036]]]},"properties":{"id":"10504","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.826961,41.31905],[-73.845821,41.335906],[-73.86201,41.334264],[-73.865057,41.303081],[-73.856268,41.292625],[-73.838955,41.28875],[-73.826961,41.31905]]]},"properties":{"id":"10547","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.744747,40.980429],[-73.715712,40.959277],[-73.726273,41.000038],[-73.744656,40.982445],[-73.744747,40.980429]]]},"properties":{"id":"10528","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.020461,42.6994],[-78.014662,42.697582],[-78.013548,42.702787],[-78.019148,42.70283],[-78.020461,42.6994]]]},"properties":{"id":"14549","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.162227,42.739098],[-77.329137,42.724774],[-77.35756,42.666585],[-77.265877,42.635127],[-77.225109,42.669053],[-77.152191,42.739205],[-77.162227,42.739098]]]},"properties":{"id":"14507","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.808225,42.481545],[-73.847563,42.529541],[-73.896088,42.520261],[-73.886126,42.451507],[-73.838981,42.444617],[-73.808021,42.46165],[-73.791862,42.463367],[-73.808225,42.481545]]]},"properties":{"id":"12143","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.897925,42.685531],[-73.877814,42.719396],[-73.920824,42.707029],[-73.920489,42.690931],[-73.908536,42.682892],[-73.897925,42.685531]]]},"properties":{"id":"12084","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.074077,42.086204],[-78.074215,42.075552],[-78.054045,42.065697],[-78.074077,42.086204]]]},"properties":{"id":"14707","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.859063,42.368398],[-77.751401,42.47187],[-77.840638,42.503237],[-77.944536,42.43536],[-77.95477,42.435323],[-77.878796,42.368322],[-77.859063,42.368398]]]},"properties":{"id":"14822","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.220414,42.064708],[-78.257927,42.031783],[-78.268811,41.999005],[-78.167537,42.000015],[-78.220414,42.064708]]]},"properties":{"id":"14754","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.142451,42.419709],[-78.249724,42.466717],[-78.265406,42.410662],[-78.177044,42.403205],[-78.142451,42.419709]]]},"properties":{"id":"14744","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.249653,42.471098],[-78.369595,42.436169],[-78.386669,42.435731],[-78.308448,42.413504],[-78.265406,42.410662],[-78.249724,42.466717],[-78.249653,42.471098]]]},"properties":{"id":"14060","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.967205,42.09541],[-75.975779,42.085051],[-75.964678,42.081705],[-75.960508,42.090904],[-75.967205,42.09541]]]},"properties":{"id":"13902","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.605703,42.195486],[-75.620555,42.290181],[-75.756141,42.260052],[-75.733348,42.249324],[-75.760629,42.156486],[-75.684959,42.130162],[-75.647199,42.123378],[-75.602916,42.121259],[-75.605703,42.195486]]]},"properties":{"id":"13787","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.772834,42.42283],[-75.878386,42.414927],[-75.871369,42.36361],[-75.779337,42.249305],[-75.756141,42.260052],[-75.620555,42.290181],[-75.618711,42.300316],[-75.609221,42.337669],[-75.748972,42.453051],[-75.772834,42.42283]]]},"properties":{"id":"13778","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.278356,42.378114],[-76.314197,42.308299],[-76.267163,42.271412],[-76.100612,42.278484],[-76.079773,42.356833],[-76.278356,42.378114]]]},"properties":{"id":"13736","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.004005,42.377896],[-76.022247,42.400597],[-76.013987,42.385677],[-76.004005,42.377896]]]},"properties":{"id":"13794","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.695609,42.15148],[-78.618643,42.086329],[-78.580303,42.151248],[-78.676504,42.172325],[-78.695609,42.15148]]]},"properties":{"id":"14748","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.464228,42.524986],[-78.478568,42.530227],[-78.559836,42.509369],[-78.583417,42.465472],[-78.554323,42.444312],[-78.452444,42.402442],[-78.386669,42.435731],[-78.369595,42.436169],[-78.432354,42.490076],[-78.464228,42.524986]]]},"properties":{"id":"14042","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.622726,42.333261],[-78.555554,42.337497],[-78.452444,42.402442],[-78.554323,42.444312],[-78.622726,42.333261]]]},"properties":{"id":"14101","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.134349,42.094199],[-79.019307,42.08571],[-79.034031,42.184279],[-79.101366,42.216643],[-79.128335,42.216487],[-79.162721,42.204908],[-79.134349,42.094199]]]},"properties":{"id":"14747","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.595367,43.010298],[-76.682242,42.954729],[-76.633575,42.869391],[-76.599479,42.83758],[-76.516577,42.811031],[-76.43471,42.823235],[-76.461737,42.988321],[-76.460009,43.001788],[-76.492667,43.015312],[-76.595367,43.010298]],[[-76.570472,42.934863],[-76.577184,42.933131],[-76.578014,42.934982],[-76.570472,42.934863]]]},"properties":{"id":"13021","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.29586,42.630184],[-76.323463,42.666311],[-76.487615,42.698975],[-76.536106,42.624908],[-76.523685,42.610305],[-76.29586,42.630184]]]},"properties":{"id":"13092","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.704959,43.316579],[-76.699742,43.32971],[-76.702538,43.344424],[-76.712915,43.344153],[-76.713004,43.327272],[-76.704959,43.316579]]]},"properties":{"id":"13064","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.270465,42.111041],[-79.273665,42.10723],[-79.288139,42.107722],[-79.295063,42.100964],[-79.356281,42.050158],[-79.351147,41.998687],[-79.145488,41.9994],[-79.158009,42.096441],[-79.244262,42.17322],[-79.273493,42.207107],[-79.323009,42.197914],[-79.305981,42.123019],[-79.30156,42.117503],[-79.270465,42.111041]]]},"properties":{"id":"14701","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.761698,42.107741],[-79.730951,42.120298],[-79.725132,42.167925],[-79.761741,42.162695],[-79.761698,42.107741]]]},"properties":{"id":"14736","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.437455,42.33708],[-79.38221,42.265146],[-79.319615,42.282157],[-79.354606,42.363374],[-79.411545,42.359143],[-79.437455,42.33708]]]},"properties":{"id":"14784","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.484211,42.334591],[-79.496706,42.390531],[-79.528482,42.385935],[-79.645283,42.322848],[-79.624008,42.239822],[-79.589976,42.228588],[-79.484211,42.334591]]]},"properties":{"id":"14787","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.305981,42.123019],[-79.312223,42.116009],[-79.30156,42.117503],[-79.305981,42.123019]]]},"properties":{"id":"14742","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.420945,42.445322],[-79.411545,42.359143],[-79.354606,42.363374],[-79.222615,42.380791],[-79.22001,42.383108],[-79.271054,42.462108],[-79.420945,42.445322]]]},"properties":{"id":"14063","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.705707,42.001319],[-76.62108,42.00048],[-76.65722,42.039303],[-76.724864,42.016907],[-76.705707,42.001319]]],[[[-76.756107,42.163698],[-76.810646,42.136122],[-76.835693,42.109518],[-76.810256,42.086167],[-76.77952,42.051496],[-76.731508,42.018043],[-76.729403,42.164755],[-76.756107,42.163698]]]]},"properties":{"id":"14901","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.724864,42.016907],[-76.65722,42.039303],[-76.633981,42.108188],[-76.620605,42.123237],[-76.726122,42.16862],[-76.729403,42.164755],[-76.731508,42.018043],[-76.724864,42.016907]]]},"properties":{"id":"14861","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.67656,42.532348],[-75.521928,42.443354],[-75.42726,42.468591],[-75.423942,42.62937],[-75.540266,42.641115],[-75.58239,42.629404],[-75.581357,42.623993],[-75.700643,42.58357],[-75.67656,42.532348]]]},"properties":{"id":"13815","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.878386,42.414927],[-75.772834,42.42283],[-75.867514,42.45992],[-75.882294,42.420293],[-75.878386,42.414927]]]},"properties":{"id":"13841","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.849292,44.961343],[-73.897588,44.765385],[-73.927314,44.749068],[-73.836341,44.71046],[-73.738826,44.718723],[-73.735511,44.720464],[-73.671913,44.78401],[-73.737917,44.913014],[-73.762986,45.003239],[-73.815325,45.002468],[-73.849292,44.961343]]]},"properties":{"id":"12935","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.546701,44.626793],[-73.579656,44.733539],[-73.623006,44.789274],[-73.669488,44.773357],[-73.648631,44.63161],[-73.546701,44.626793]]]},"properties":{"id":"12962","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.708318,44.719009],[-73.735511,44.720464],[-73.738826,44.718723],[-73.708318,44.719009]]]},"properties":{"id":"12929","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.427301,42.456771],[-73.382016,42.429488],[-73.352527,42.510002],[-73.396456,42.50577],[-73.454579,42.489448],[-73.474893,42.485245],[-73.427301,42.456771]]]},"properties":{"id":"12125","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.549963,42.579096],[-73.513312,42.493161],[-73.497691,42.495232],[-73.454579,42.489448],[-73.396456,42.50577],[-73.45055,42.53504],[-73.549963,42.579096]]]},"properties":{"id":"12062","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.669808,42.356851],[-73.722154,42.432109],[-73.731264,42.431733],[-73.720463,42.367571],[-73.719205,42.345894],[-73.704956,42.348963],[-73.669808,42.356851]]]},"properties":{"id":"12106","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.924897,42.049562],[-73.90919,42.034793],[-73.806215,42.044541],[-73.811398,42.076442],[-73.921576,42.077333],[-73.924897,42.049562]]]},"properties":{"id":"12583","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.643751,42.005919],[-73.586519,41.98648],[-73.532346,42.067703],[-73.591062,42.081149],[-73.643751,42.005919]]]},"properties":{"id":"12503","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.613045,42.118661],[-73.591062,42.081149],[-73.532346,42.067703],[-73.525785,42.073229],[-73.527392,42.137721],[-73.570055,42.156361],[-73.579681,42.142606],[-73.613045,42.118661]]]},"properties":{"id":"12516","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.658324,42.433451],[-73.651156,42.438287],[-73.657864,42.445668],[-73.668878,42.449409],[-73.67077,42.445416],[-73.660933,42.439655],[-73.658324,42.433451]]]},"properties":{"id":"12130","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.548999,42.476777],[-73.520455,42.481999],[-73.520521,42.484589],[-73.548999,42.476777]]]},"properties":{"id":"12024","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.179975,41.969426],[-75.141981,41.946589],[-75.133589,41.963607],[-75.179975,41.969426]]]},"properties":{"id":"13774","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.787911,42.499211],[-74.750672,42.451713],[-74.657011,42.462885],[-74.484383,42.466114],[-74.45532,42.468803],[-74.500377,42.519439],[-74.623889,42.560023],[-74.707573,42.522675],[-74.772888,42.505169],[-74.787911,42.499211]]]},"properties":{"id":"12093","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.877845,42.464221],[-74.920544,42.450026],[-74.919674,42.446585],[-74.877845,42.464221]]]},"properties":{"id":"13751","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.731894,41.818003],[-73.812186,41.843802],[-73.837102,41.833576],[-73.81723,41.786513],[-73.750126,41.770864],[-73.731894,41.818003]]]},"properties":{"id":"12578","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.908703,42.012016],[-73.926757,42.015754],[-73.92698,42.012025],[-73.908703,42.012016]]]},"properties":{"id":"12507","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.813902,42.959443],[-78.833947,42.923073],[-78.79963,42.919007],[-78.784058,42.949161],[-78.813902,42.959443]]]},"properties":{"id":"14215","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.701222,42.863759],[-78.696956,42.860034],[-78.685498,42.863958],[-78.696938,42.929984],[-78.728099,42.907034],[-78.701222,42.863759]]]},"properties":{"id":"14043","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.699476,42.695793],[-78.639983,42.667892],[-78.61409,42.693716],[-78.688817,42.737683],[-78.699476,42.695793]]]},"properties":{"id":"14170","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.813902,42.959443],[-78.784058,42.949161],[-78.765507,42.949616],[-78.797942,42.99078],[-78.802252,42.994255],[-78.822389,43.001374],[-78.822967,42.979692],[-78.82384,42.958126],[-78.813902,42.959443]]]},"properties":{"id":"14226","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.909873,42.974633],[-78.91245,42.956248],[-78.902057,42.931238],[-78.888804,42.936594],[-78.877406,42.934226],[-78.889249,42.958572],[-78.909873,42.974633]]]},"properties":{"id":"14207","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.908044,42.696563],[-78.91651,42.739966],[-78.970197,42.71994],[-78.940487,42.69964],[-78.93759,42.696319],[-78.935917,42.696215],[-78.908044,42.696563]]]},"properties":{"id":"14085","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.87302,42.902227],[-78.904412,42.8945],[-78.89456,42.87841],[-78.868971,42.90221],[-78.87302,42.902227]]]},"properties":{"id":"14202","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.639983,42.667892],[-78.699476,42.695793],[-78.744569,42.674613],[-78.748854,42.664544],[-78.665681,42.62585],[-78.623453,42.64289],[-78.639983,42.667892]]]},"properties":{"id":"14033","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.670013,43.00026],[-78.660457,42.955861],[-78.578048,42.954758],[-78.552723,42.963916],[-78.576245,43.031222],[-78.670013,43.00026]]]},"properties":{"id":"14031","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.734222,43.04713],[-78.790693,43.01251],[-78.780179,43.00827],[-78.775512,43.006532],[-78.737883,43.024916],[-78.734222,43.04713]]]},"properties":{"id":"14068","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.940487,42.69964],[-78.941956,42.696231],[-78.93759,42.696319],[-78.940487,42.69964]]]},"properties":{"id":"14112","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.721596,44.313438],[-73.790759,44.334175],[-73.806681,44.305628],[-73.721596,44.313438]]]},"properties":{"id":"12987","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.419212,44.540986],[-73.460146,44.525873],[-73.402983,44.523869],[-73.419212,44.540986]]]},"properties":{"id":"12975","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.936128,44.501738],[-74.088449,44.412479],[-73.909687,44.429699],[-73.936128,44.501738]]]},"properties":{"id":"12913","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.288093,44.488016],[-74.178012,44.444245],[-74.147768,44.44436],[-74.169761,44.559826],[-74.280075,44.548896],[-74.297642,44.499909],[-74.288093,44.488016]]]},"properties":{"id":"12976","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.978838,43.1789],[-73.986197,43.266267],[-74.083988,43.210025],[-74.12343,43.148218],[-74.043244,43.160852],[-73.977482,43.166942],[-73.978838,43.1789]]],[[[-74.182849,43.224268],[-74.093698,43.213806],[-74.158083,43.359527],[-74.472144,43.336536],[-74.457719,43.232885],[-74.319063,43.192086],[-74.182849,43.224268]]]]},"properties":{"id":"12134","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.888937,42.382493],[-73.8745,42.387286],[-73.881435,42.395874],[-73.904465,42.399567],[-73.888937,42.382493]]],[[[-73.888937,42.382493],[-73.89227,42.321565],[-73.850381,42.308661],[-73.786866,42.321363],[-73.794999,42.393363],[-73.859367,42.37099],[-73.888937,42.382493]]]]},"properties":{"id":"12051","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.16014,42.381737],[-74.229985,42.372685],[-74.162265,42.335161],[-74.150226,42.338299],[-74.105364,42.336624],[-74.16014,42.381737]]]},"properties":{"id":"12418","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.168882,42.22339],[-74.204296,42.262577],[-74.239653,42.266225],[-74.32465,42.241622],[-74.325722,42.215722],[-74.246332,42.171017],[-74.212299,42.14139],[-74.171105,42.188943],[-74.168882,42.22339]]]},"properties":{"id":"12442","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.32465,42.241622],[-74.239653,42.266225],[-74.225797,42.280058],[-74.323066,42.284867],[-74.32465,42.241622]]]},"properties":{"id":"12444","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.827401,42.89524],[-74.831523,42.944583],[-74.875869,42.910862],[-74.84155,42.873654],[-74.810702,42.845842],[-74.80438,42.843798],[-74.763303,42.863237],[-74.826673,42.894904],[-74.828713,42.893995],[-74.832957,42.893173],[-74.834061,42.892093],[-74.834692,42.89237],[-74.833022,42.893445],[-74.830393,42.893986],[-74.827401,42.89524]]]},"properties":{"id":"13361","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.072762,43.151548],[-75.020546,43.22792],[-75.086692,43.287581],[-75.153061,43.272648],[-75.106448,43.218448],[-75.072762,43.151548]]]},"properties":{"id":"13431","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.20649,42.931426],[-75.242745,42.877869],[-75.283162,42.870334],[-75.301721,42.846469],[-75.290371,42.822699],[-75.270455,42.825147],[-75.250267,42.797375],[-75.212289,42.798477],[-75.110014,42.810113],[-75.064286,42.925472],[-75.157562,42.941789],[-75.160045,42.952614],[-75.20649,42.931426]]]},"properties":{"id":"13491","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.715916,44.032786],[-75.719275,44.033724],[-75.717338,44.029474],[-75.715916,44.032786]]]},"properties":{"id":"13643","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.880358,44.048138],[-75.829408,44.019118],[-75.814873,44.022739],[-75.833527,44.044172],[-75.880358,44.048138]]]},"properties":{"id":"13616","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.192712,44.207412],[-76.295807,44.046057],[-76.212253,44.105193],[-76.188604,44.132401],[-76.192712,44.207412]]]},"properties":{"id":"13618","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.693971,44.229003],[-75.657539,44.16949],[-75.569334,44.239076],[-75.61981,44.302183],[-75.628517,44.299102],[-75.693971,44.229003]]]},"properties":{"id":"13608","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.966614,40.584575],[-73.959395,40.574077],[-73.931842,40.58292],[-73.926104,40.584825],[-73.958998,40.591784],[-73.966614,40.584575]]]},"properties":{"id":"11235","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.979233,40.705769],[-73.973126,40.689622],[-73.972687,40.687415],[-73.957132,40.688463],[-73.95181,40.691599],[-73.95702,40.698974],[-73.97588,40.701927],[-73.979233,40.705769]]]},"properties":{"id":"11205","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.113605,43.64386],[-75.383691,43.610387],[-75.383105,43.582143],[-75.225253,43.562204],[-75.11016,43.615229],[-75.113605,43.64386]]]},"properties":{"id":"13433","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.39869,43.676147],[-75.383691,43.610387],[-75.113605,43.64386],[-75.115369,43.658032],[-75.310031,43.681925],[-75.361123,43.686635],[-75.39869,43.676147]]]},"properties":{"id":"13368","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.695498,42.829622],[-77.705428,42.855319],[-77.715155,42.855871],[-77.715993,42.823388],[-77.710411,42.82341],[-77.695498,42.829622]]]},"properties":{"id":"14480","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.852765,42.786599],[-77.956334,42.667322],[-77.988904,42.625263],[-78.015381,42.609449],[-77.980141,42.609937],[-77.820979,42.606605],[-77.784397,42.680686],[-77.752791,42.714478],[-77.852765,42.786599]]]},"properties":{"id":"14510","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.820979,42.606605],[-77.980141,42.609937],[-77.954931,42.556431],[-77.840848,42.557438],[-77.820979,42.606605]]]},"properties":{"id":"14517","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.595222,43.017563],[-77.69941,42.945584],[-77.644495,42.927138],[-77.567615,42.931345],[-77.517891,42.934293],[-77.482517,42.943164],[-77.48445,42.998326],[-77.524696,43.008176],[-77.563125,43.021934],[-77.595222,43.017563]]]},"properties":{"id":"14472","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.880723,42.903933],[-77.821143,42.889834],[-77.758727,42.940461],[-77.730957,42.988372],[-77.846364,42.98801],[-77.878331,42.987862],[-77.919921,42.912802],[-77.880723,42.903933]]]},"properties":{"id":"14423","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.803937,42.992402],[-75.691717,42.974637],[-75.669334,42.996914],[-75.70321,43.07557],[-75.716991,43.07443],[-75.709535,43.088988],[-75.709565,43.151437],[-75.736685,43.164131],[-75.737774,43.164673],[-75.86451,43.146995],[-75.803937,42.992402]]]},"properties":{"id":"13032","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.609346,43.151139],[-77.620232,43.165805],[-77.626145,43.173244],[-77.628201,43.181017],[-77.637564,43.169527],[-77.630767,43.15941],[-77.634224,43.130863],[-77.623424,43.132963],[-77.609346,43.151139]]]},"properties":{"id":"14608","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.694393,43.238451],[-77.634827,43.252859],[-77.63027,43.224926],[-77.626685,43.205991],[-77.611139,43.249121],[-77.601951,43.25952],[-77.70541,43.318934],[-77.737394,43.250471],[-77.694393,43.238451]]]},"properties":{"id":"14612","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.730461,43.0917],[-77.688976,43.08277],[-77.652083,43.058694],[-77.593006,43.059534],[-77.573327,43.087295],[-77.603185,43.109618],[-77.630184,43.123226],[-77.63256,43.130984],[-77.623908,43.131912],[-77.623424,43.132963],[-77.634224,43.130863],[-77.642539,43.121661],[-77.686064,43.098694],[-77.730461,43.0917]]]},"properties":{"id":"14623","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.576939,43.197937],[-77.525221,43.203563],[-77.533831,43.236397],[-77.574489,43.245376],[-77.577021,43.199045],[-77.576939,43.197937]]]},"properties":{"id":"14622","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.524696,43.008176],[-77.48445,42.998326],[-77.484718,43.008387],[-77.524696,43.008176]]]},"properties":{"id":"14506","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.376046,43.276211],[-77.365561,43.187947],[-77.320382,43.174225],[-77.242507,43.195435],[-77.242507,43.199301],[-77.252316,43.278162],[-77.376046,43.276211]]]},"properties":{"id":"14519","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.645393,43.123223],[-77.63605,43.142226],[-77.665944,43.140222],[-77.645393,43.123223]]]},"properties":{"id":"14619","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.48445,42.998326],[-77.482517,42.943164],[-77.485537,42.931499],[-77.379959,42.924785],[-77.370591,42.944241],[-77.364968,43.034671],[-77.388141,43.03456],[-77.456757,43.034662],[-77.484718,43.008387],[-77.48445,42.998326]]]},"properties":{"id":"14564","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.292928,42.941667],[-74.271703,42.952993],[-74.275898,42.95198],[-74.28834,42.944809],[-74.292928,42.941667]]]},"properties":{"id":"12069","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.282913,42.957422],[-74.28834,42.944809],[-74.275898,42.95198],[-74.282913,42.957422]]]},"properties":{"id":"12177","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.622242,40.795495],[-73.623794,40.759992],[-73.62128,40.756541],[-73.568826,40.777324],[-73.548363,40.799048],[-73.58305,40.807167],[-73.59859,40.815299],[-73.612371,40.8062],[-73.622242,40.795495]]]},"properties":{"id":"11568","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.560088,40.699066],[-73.53188,40.706379],[-73.52627,40.707722],[-73.542955,40.733793],[-73.582996,40.736899],[-73.581083,40.729677],[-73.575946,40.694497],[-73.560088,40.699066]]]},"properties":{"id":"11554","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.656246,40.649099],[-73.622758,40.657434],[-73.619878,40.680358],[-73.632737,40.690284],[-73.66014,40.671096],[-73.659389,40.667495],[-73.656246,40.649099]]]},"properties":{"id":"11570","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.609095,40.720783],[-73.581083,40.729677],[-73.582996,40.736899],[-73.603449,40.745269],[-73.620736,40.742318],[-73.650997,40.736848],[-73.687896,40.723318],[-73.684779,40.714708],[-73.66377,40.710776],[-73.643173,40.712701],[-73.609095,40.720783]]]},"properties":{"id":"11530","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.619878,40.680358],[-73.622758,40.657434],[-73.613673,40.624703],[-73.585672,40.625902],[-73.601666,40.672393],[-73.601439,40.686065],[-73.607467,40.684493],[-73.619878,40.680358]]]},"properties":{"id":"11510","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.695834,40.767159],[-73.712761,40.759415],[-73.707825,40.756216],[-73.70542,40.754606],[-73.691638,40.768568],[-73.695834,40.767159]]]},"properties":{"id":"11042","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.587257,40.593495],[-73.590007,40.585668],[-73.577792,40.585924],[-73.576015,40.594777],[-73.587257,40.593495]]]},"properties":{"id":"11569","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975702,40.753811],[-73.976516,40.754974],[-73.975352,40.755303],[-73.974897,40.755928],[-73.974439,40.756556],[-73.977655,40.757912],[-73.978116,40.757284],[-73.979937,40.754783],[-73.978332,40.7541],[-73.978783,40.753481],[-73.980395,40.754155],[-73.980888,40.753481],[-73.981822,40.752197],[-73.968192,40.747251],[-73.964492,40.751445],[-73.97282,40.75587],[-73.973276,40.755243],[-73.973735,40.754619],[-73.975209,40.754425],[-73.975702,40.753811]],[[-73.975702,40.753811],[-73.975558,40.752116],[-73.973959,40.751441],[-73.974449,40.750766],[-73.976048,40.751441],[-73.978159,40.75146],[-73.979768,40.752141],[-73.979287,40.752801],[-73.977677,40.752119],[-73.977107,40.751883],[-73.975702,40.753811]]]},"properties":{"id":"10017","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.9659,40.808806],[-73.956806,40.807546],[-73.944667,40.80313],[-73.940404,40.808958],[-73.941803,40.812815],[-73.946078,40.821264],[-73.960007,40.820422],[-73.962067,40.818099],[-73.964019,40.811331],[-73.963058,40.810947],[-73.963477,40.810373],[-73.964424,40.810755],[-73.9659,40.808806]]]},"properties":{"id":"10027","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.940167,40.830383],[-73.934671,40.842958],[-73.943186,40.849745],[-73.947051,40.850517],[-73.950403,40.834372],[-73.940167,40.830383]]]},"properties":{"id":"10032","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.997532,40.714023],[-73.991896,40.709535],[-73.973635,40.718612],[-73.988643,40.722933],[-73.992604,40.724136],[-73.994035,40.720323],[-73.997532,40.714023]]]},"properties":{"id":"10002","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.94922,40.79691],[-73.946462,40.800665],[-73.944667,40.80313],[-73.956806,40.807546],[-73.958619,40.799977],[-73.94922,40.79691]]]},"properties":{"id":"10026","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.959921,40.782213],[-73.944337,40.773108],[-73.943154,40.782531],[-73.955778,40.787914],[-73.959921,40.782213]]]},"properties":{"id":"10128","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.973015,40.764279],[-73.973465,40.763659],[-73.971869,40.762964],[-73.971411,40.763588],[-73.973015,40.764279]]]},"properties":{"id":"10153","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.971451,40.757749],[-73.973068,40.758432],[-73.973524,40.757808],[-73.971907,40.757125],[-73.971451,40.757749]]]},"properties":{"id":"10154","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.977677,40.752119],[-73.979287,40.752801],[-73.979768,40.752141],[-73.978159,40.75146],[-73.977677,40.752119]]]},"properties":{"id":"10165","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.651103,43.257608],[-78.77935,43.219551],[-78.814201,43.220304],[-78.855553,43.189919],[-78.829469,43.126826],[-78.733606,43.084219],[-78.727647,43.08622],[-78.666733,43.085364],[-78.576065,43.079758],[-78.557474,43.117944],[-78.620206,43.265441],[-78.651103,43.257608]]]},"properties":{"id":"14094","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.906266,43.057952],[-78.886467,43.022311],[-78.828805,43.030139],[-78.733606,43.084219],[-78.829469,43.126826],[-78.884584,43.105028],[-78.906266,43.057952]]]},"properties":{"id":"14120","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.01084,43.09382],[-79.025893,43.078359],[-78.906266,43.057952],[-78.884584,43.105028],[-78.933696,43.142968],[-78.973006,43.144593],[-79.010708,43.093901],[-79.01084,43.09382]]]},"properties":{"id":"14304","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.55792,43.42487],[-75.53871,43.383215],[-75.466301,43.303916],[-75.454481,43.297969],[-75.394931,43.34933],[-75.462224,43.453036],[-75.55792,43.42487]]]},"properties":{"id":"13303","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.459115,43.021768],[-75.421214,42.948841],[-75.388076,42.987802],[-75.449052,43.025884],[-75.459115,43.021768]]]},"properties":{"id":"13328","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.736685,43.164131],[-75.709565,43.151437],[-75.641492,43.117273],[-75.607664,43.191923],[-75.645333,43.20945],[-75.71712,43.202585],[-75.728487,43.195199],[-75.736685,43.164131]]]},"properties":{"id":"13054","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.377424,43.111587],[-76.474314,43.161546],[-76.476997,43.145954],[-76.419617,43.067756],[-76.375858,43.067491],[-76.346641,43.080847],[-76.377424,43.111587]]]},"properties":{"id":"13112","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.083792,43.058295],[-76.115595,43.054776],[-76.109484,43.005509],[-76.092496,43.0308],[-76.083201,43.05552],[-76.083792,43.058295]]]},"properties":{"id":"13224","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.142757,43.035709],[-76.144203,43.050849],[-76.153576,43.056361],[-76.155609,43.033623],[-76.153989,43.031752],[-76.142757,43.035709]]]},"properties":{"id":"13202","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.0696,42.825401],[-76.028944,42.82899],[-76.018071,42.896538],[-76.015831,42.900423],[-76.135357,42.957882],[-76.221085,42.910369],[-76.238935,42.87854],[-76.077323,42.825126],[-76.0696,42.825401]]]},"properties":{"id":"13084","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.349717,43.017959],[-76.396289,42.994576],[-76.347179,42.923098],[-76.273877,42.927626],[-76.290565,43.014295],[-76.349717,43.017959]]]},"properties":{"id":"13108","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.304786,42.875783],[-76.261845,42.848137],[-76.235387,42.82609],[-76.238935,42.87854],[-76.221085,42.910369],[-76.245424,42.916919],[-76.273877,42.927626],[-76.347179,42.923098],[-76.304786,42.875783]]]},"properties":{"id":"13110","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.129814,43.148925],[-76.082603,43.136254],[-76.016851,43.141061],[-75.994217,43.18218],[-76.0754,43.199307],[-76.137813,43.188633],[-76.129814,43.148925]]]},"properties":{"id":"13039","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.092496,43.0308],[-76.066432,43.01692],[-76.051856,43.043368],[-76.083201,43.05552],[-76.092496,43.0308]]]},"properties":{"id":"13214","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.217307,42.976521],[-77.246156,42.971351],[-77.221949,42.961283],[-77.217307,42.976521]]]},"properties":{"id":"14504","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.078134,42.886087],[-77.105732,42.758085],[-77.031302,42.763752],[-77.014784,42.763863],[-77.007175,42.764031],[-77.000842,42.764079],[-76.971392,42.764223],[-76.933328,42.793694],[-76.857431,42.807263],[-76.951137,42.941896],[-77.093771,42.911347],[-77.078134,42.886087]]]},"properties":{"id":"14456","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.152742,43.03865],[-77.17101,43.039842],[-77.160115,43.032303],[-77.152742,43.03865]]]},"properties":{"id":"14537","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.132375,41.453266],[-74.200649,41.439866],[-74.198475,41.432121],[-74.194096,41.398258],[-74.139486,41.408036],[-74.132375,41.453266]]]},"properties":{"id":"10992","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.02079,41.452493],[-74.027264,41.42159],[-73.994382,41.415688],[-74.02079,41.452493]]]},"properties":{"id":"12520","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.251309,41.403777],[-74.32285,41.438849],[-74.356605,41.431963],[-74.377671,41.412095],[-74.469584,41.315209],[-74.438384,41.317736],[-74.399099,41.31147],[-74.329036,41.346746],[-74.251309,41.403777]]]},"properties":{"id":"10924","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.116379,41.505869],[-74.008981,41.488125],[-73.982218,41.559438],[-74.042259,41.58157],[-74.102581,41.576308],[-74.129831,41.546727],[-74.13217,41.512582],[-74.116379,41.505869]]]},"properties":{"id":"12550","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.073218,41.409596],[-74.097262,41.388669],[-74.071405,41.408883],[-74.073218,41.409596]]]},"properties":{"id":"10953","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.359417,41.541948],[-74.356426,41.54372],[-74.361775,41.542369],[-74.359417,41.541948]]],[[[-74.359417,41.541948],[-74.356145,41.537684],[-74.351593,41.540562],[-74.359417,41.541948]]]]},"properties":{"id":"10915","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.986402,41.379029],[-73.959756,41.376519],[-73.950948,41.396056],[-73.971186,41.408474],[-73.986402,41.379029]]]},"properties":{"id":"10996","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.157707,41.260845],[-74.140814,41.287314],[-74.145777,41.285971],[-74.193874,41.273941],[-74.181191,41.228],[-74.157707,41.260845]]]},"properties":{"id":"10975","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.087531,43.613476],[-76.166927,43.639451],[-76.201946,43.680312],[-76.239877,43.533913],[-76.117716,43.468333],[-76.049976,43.487586],[-76.037509,43.531041],[-76.062622,43.596111],[-76.087531,43.613476]]]},"properties":{"id":"13142","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.037509,43.531041],[-75.890768,43.551674],[-75.894699,43.604269],[-76.062622,43.596111],[-76.037509,43.531041]]]},"properties":{"id":"13144","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.995342,43.391784],[-76.078162,43.365871],[-76.108076,43.321587],[-76.109505,43.318487],[-76.118787,43.256335],[-76.059343,43.242725],[-75.980151,43.356514],[-75.995342,43.391784]]]},"properties":{"id":"13167","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.118445,42.471729],[-75.139081,42.517334],[-75.179525,42.53209],[-75.182532,42.532987],[-75.182299,42.513404],[-75.120891,42.472257],[-75.118445,42.471729]]]},"properties":{"id":"13861","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.638844,42.661558],[-74.670576,42.566948],[-74.638989,42.563159],[-74.638844,42.661558]]]},"properties":{"id":"12064","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.212337,42.646164],[-75.208783,42.627655],[-75.196449,42.607356],[-75.16976,42.642994],[-75.190515,42.70543],[-75.212337,42.646164]]]},"properties":{"id":"13342","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.73222,40.685121],[-73.753214,40.648278],[-73.746702,40.633387],[-73.740115,40.635511],[-73.728305,40.665917],[-73.725861,40.683223],[-73.73222,40.685121]]]},"properties":{"id":"11422","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.869425,40.749157],[-73.891457,40.74687],[-73.888523,40.734531],[-73.88724,40.727834],[-73.871429,40.729248],[-73.864618,40.734271],[-73.869425,40.749157]]]},"properties":{"id":"11373","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.891457,40.74687],[-73.869425,40.749157],[-73.872219,40.756943],[-73.882579,40.755854],[-73.896522,40.754389],[-73.891457,40.74687]]]},"properties":{"id":"11372","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.852145,40.702375],[-73.868917,40.69515],[-73.866295,40.683189],[-73.844307,40.689442],[-73.852145,40.702375]]]},"properties":{"id":"11421","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.792599,40.790455],[-73.791635,40.774239],[-73.789309,40.769708],[-73.762585,40.771686],[-73.774194,40.786707],[-73.781825,40.790631],[-73.792599,40.790455]]]},"properties":{"id":"11360","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.801663,40.666889],[-73.794578,40.686107],[-73.805768,40.682932],[-73.801663,40.666889]]]},"properties":{"id":"11436","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.777236,40.722284],[-73.80904,40.719912],[-73.811835,40.717873],[-73.80242,40.701786],[-73.798415,40.702741],[-73.79609,40.703294],[-73.780359,40.708464],[-73.777236,40.722284]]]},"properties":{"id":"11432","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.732168,40.729155],[-73.757642,40.717971],[-73.753708,40.713325],[-73.729661,40.720483],[-73.729633,40.722453],[-73.732168,40.729155]]]},"properties":{"id":"11428","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.685195,42.666802],[-73.724964,42.670179],[-73.729167,42.664999],[-73.747644,42.643233],[-73.759739,42.61379],[-73.752365,42.57668],[-73.719979,42.58028],[-73.662002,42.640638],[-73.685195,42.666802]]]},"properties":{"id":"12144","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.104783,40.645982],[-74.119669,40.621745],[-74.114871,40.602137],[-74.113023,40.600135],[-74.069353,40.638096],[-74.104783,40.645982]]]},"properties":{"id":"10301","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.068761,40.627408],[-74.098493,40.587106],[-74.091164,40.566718],[-74.068761,40.627408]]]},"properties":{"id":"10305","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.119669,40.621745],[-74.104783,40.645982],[-74.126962,40.640181],[-74.13061,40.623495],[-74.119669,40.621745]]]},"properties":{"id":"10310","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.050736,41.16741],[-74.14386,41.179033],[-74.158289,41.110996],[-74.112378,41.090734],[-74.06755,41.161498],[-74.050736,41.16741]]]},"properties":{"id":"10901","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.228451,44.844071],[-75.183056,44.696556],[-75.119344,44.70226],[-75.086817,44.734653],[-75.024405,44.77362],[-75.075949,44.773079],[-75.109714,44.842835],[-75.228451,44.844071]]]},"properties":{"id":"13660","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.677514,44.71032],[-74.74766,44.703353],[-74.70624,44.689807],[-74.611775,44.688004],[-74.653988,44.699635],[-74.677514,44.71032]]]},"properties":{"id":"12965","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.722055,44.925533],[-74.852905,44.868948],[-74.84163,44.843384],[-74.716355,44.771666],[-74.628489,44.855697],[-74.63121,44.875391],[-74.722055,44.925533]]]},"properties":{"id":"13613","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.185258,44.180827],[-75.170159,44.096959],[-75.075675,44.055971],[-75.034452,44.177914],[-75.041009,44.213003],[-75.089101,44.304386],[-75.185258,44.180827]]]},"properties":{"id":"13670","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.192228,44.373554],[-75.275332,44.346704],[-75.318664,44.236302],[-75.174183,44.299429],[-75.166665,44.305786],[-75.192228,44.373554]]]},"properties":{"id":"13635","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.918495,44.287509],[-75.041009,44.213003],[-75.034452,44.177914],[-74.909066,44.179495],[-74.918495,44.287509]]]},"properties":{"id":"13666","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.676762,42.783277],[-73.665759,42.833373],[-73.670823,42.840312],[-73.698782,42.854845],[-73.731669,42.821305],[-73.676762,42.783277]]]},"properties":{"id":"12188","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.0085,42.947174],[-74.084844,42.896869],[-74.073552,42.891623],[-74.031635,42.869371],[-73.948823,42.819886],[-73.930371,42.829904],[-73.921085,42.840634],[-73.898029,42.866728],[-73.891991,42.894459],[-73.919354,42.899389],[-74.0085,42.947174]]]},"properties":{"id":"12302","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.50728,42.660006],[-74.463531,42.564294],[-74.430945,42.593387],[-74.414819,42.637923],[-74.431363,42.644895],[-74.50728,42.660006]]]},"properties":{"id":"12187","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.790077,42.514512],[-76.780189,42.544633],[-76.800326,42.557638],[-76.880935,42.541902],[-76.879481,42.472115],[-76.790077,42.514512]]]},"properties":{"id":"14841","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.735345,43.172964],[-76.826116,43.141079],[-76.799234,43.021014],[-76.721805,43.006063],[-76.706085,43.137052],[-76.735345,43.172964]]]},"properties":{"id":"13146","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.836222,43.181149],[-76.851099,43.150627],[-76.862218,43.133174],[-76.878036,43.152122],[-76.953724,43.171639],[-76.942872,42.978464],[-76.914244,42.982757],[-76.846553,43.018948],[-76.799234,43.021014],[-76.826116,43.141079],[-76.836222,43.181149]]]},"properties":{"id":"14433","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.871362,42.681854],[-76.873303,42.678643],[-76.864064,42.676883],[-76.864729,42.681227],[-76.871293,42.68186],[-76.871362,42.681854]]]},"properties":{"id":"14588","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.709655,42.191315],[-77.548625,42.163132],[-77.53778,42.195998],[-77.471896,42.274761],[-77.509508,42.335959],[-77.632925,42.20185],[-77.709655,42.191315]]]},"properties":{"id":"14823","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.102265,42.327938],[-77.147579,42.388134],[-77.20343,42.371975],[-77.285148,42.272468],[-77.109518,42.283738],[-77.10143,42.310504],[-77.102265,42.327938]]]},"properties":{"id":"14879","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.106143,42.090872],[-77.208129,42.063606],[-77.22887,41.999723],[-77.021036,42.000494],[-77.022131,42.017187],[-77.106143,42.090872]]]},"properties":{"id":"14858","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.11247,40.75002],[-73.083038,40.764458],[-73.087855,40.78231],[-73.165459,40.792813],[-73.154709,40.764289],[-73.150293,40.747275],[-73.11247,40.75002]]]},"properties":{"id":"11716","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.313361,40.717099],[-73.332064,40.673199],[-73.313865,40.686002],[-73.313361,40.717099]]],[[[-73.423785,40.609499],[-73.351464,40.630503],[-73.420567,40.6187],[-73.423969,40.614801],[-73.423785,40.609499]]]]},"properties":{"id":"11702","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.429962,40.706699],[-73.425549,40.654611],[-73.391719,40.654161],[-73.397667,40.703061],[-73.405944,40.717654],[-73.429962,40.706699]]]},"properties":{"id":"11701","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.283513,40.955927],[-72.285319,40.899119],[-72.236727,40.914838],[-72.266038,40.960103],[-72.271968,40.968671],[-72.283513,40.955927]]]},"properties":{"id":"11962","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.135221,40.862075],[-73.122205,40.834903],[-73.101483,40.848719],[-73.106762,40.883124],[-73.131253,40.869094],[-73.135221,40.862075]]]},"properties":{"id":"11755","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.288521,40.873218],[-73.293683,40.818457],[-73.294652,40.803983],[-73.26936,40.805785],[-73.254694,40.830889],[-73.248614,40.866393],[-73.288521,40.873218]]]},"properties":{"id":"11725","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.143018,40.951968],[-72.114816,41.013764],[-72.265237,41.046146],[-72.287606,41.010723],[-72.271968,40.968671],[-72.266038,40.960103],[-72.221709,40.920795],[-72.143018,40.951968]]]},"properties":{"id":"11937","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.38555,40.8531],[-73.336808,40.856428],[-73.33884,40.881064],[-73.35247,40.875196],[-73.3729,40.879859],[-73.38601,40.853349],[-73.38555,40.8531]]]},"properties":{"id":"11740","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.127584,40.911875],[-73.119938,40.915793],[-73.12885,40.918339],[-73.127584,40.911875]]]},"properties":{"id":"11794","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.713331,41.73567],[-74.736142,41.706536],[-74.712398,41.715527],[-74.713331,41.73567]]]},"properties":{"id":"12742","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.57363,41.667928],[-74.56759,41.680276],[-74.572476,41.677523],[-74.57363,41.667928]]],[[[-74.57363,41.667928],[-74.525057,41.653373],[-74.514466,41.667073],[-74.466817,41.669181],[-74.464778,41.682519],[-74.49227,41.697862],[-74.53024,41.719448],[-74.550313,41.683907],[-74.57363,41.667928]]]]},"properties":{"id":"12763","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.050313,41.834039],[-75.059263,41.860606],[-75.113704,41.823492],[-75.083032,41.813054],[-75.050313,41.834039]]]},"properties":{"id":"12741","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.382578,41.590113],[-74.377974,41.586385],[-74.370324,41.588481],[-74.382578,41.590113]]]},"properties":{"id":"12722","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.615364,42.502739],[-76.614383,42.502762],[-76.614628,42.50659],[-76.615567,42.506974],[-76.615364,42.502739]]]},"properties":{"id":"14854","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.173817,41.749501],[-74.12695,41.673051],[-74.08026,41.674494],[-74.059522,41.716505],[-74.006217,41.80158],[-73.9883,41.8162],[-73.994048,41.844154],[-74.039581,41.832415],[-74.046966,41.82204],[-74.086006,41.826484],[-74.099915,41.823825],[-74.173817,41.749501]]]},"properties":{"id":"12561","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.983364,41.9153],[-73.988683,41.899284],[-73.968783,41.900301],[-73.973656,41.914884],[-73.983364,41.9153]]]},"properties":{"id":"12466","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.285193,41.875262],[-74.255064,41.739181],[-74.184156,41.8143],[-74.231592,41.887494],[-74.285193,41.875262]]]},"properties":{"id":"12404","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.954782,41.843994],[-73.966046,41.824705],[-73.958875,41.82468],[-73.954782,41.843994]]],[[[-73.967715,41.819703],[-73.994048,41.844154],[-73.9883,41.8162],[-73.967715,41.819703]]]]},"properties":{"id":"12429","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.045096,41.848129],[-74.046966,41.82204],[-74.039581,41.832415],[-74.045096,41.848129]]]},"properties":{"id":"12471","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.08026,41.674494],[-74.078222,41.6613],[-74.056768,41.656507],[-74.059522,41.716505],[-74.08026,41.674494]]]},"properties":{"id":"12515","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.006376,42.010249],[-73.976026,41.994566],[-74.004428,42.018784],[-74.006376,42.010249]]]},"properties":{"id":"12449","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.029504,41.880979],[-74.057088,41.866097],[-74.037806,41.873854],[-74.029504,41.880979]]]},"properties":{"id":"12411","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.82729,43.721177],[-73.775762,43.67593],[-73.738116,43.725524],[-73.785375,43.770641],[-73.86869,43.762803],[-73.878704,43.761701],[-73.82729,43.721177]]]},"properties":{"id":"12860","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.619685,43.520394],[-73.629648,43.480283],[-73.623458,43.457795],[-73.619685,43.520394]]]},"properties":{"id":"12844","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.412673,43.668761],[-73.396061,43.699361],[-73.444284,43.694908],[-73.426086,43.662637],[-73.412673,43.668761]]],[[[-73.429464,43.474526],[-73.40193,43.477309],[-73.27101,43.489821],[-73.289771,43.577791],[-73.470126,43.539071],[-73.429464,43.474526]]]]},"properties":{"id":"12887","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.553476,43.569096],[-73.426086,43.662637],[-73.444284,43.694908],[-73.483915,43.690619],[-73.584134,43.57298],[-73.553476,43.569096]]]},"properties":{"id":"12841","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.283491,43.433426],[-73.283186,43.462348],[-73.320794,43.446568],[-73.283491,43.433426]]]},"properties":{"id":"12849","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.399899,43.370751],[-73.420123,43.327302],[-73.408367,43.312418],[-73.399899,43.370751]]]},"properties":{"id":"12838","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.252316,43.278162],[-77.242507,43.199301],[-77.101465,43.194222],[-77.119919,43.286934],[-77.252316,43.278162]]]},"properties":{"id":"14589","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.883565,41.003851],[-73.858652,40.997989],[-73.845334,41.023229],[-73.877403,41.022584],[-73.883565,41.003851]]]},"properties":{"id":"10522","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.826693,41.028712],[-73.824515,41.017293],[-73.785289,41.021519],[-73.785071,41.034911],[-73.826693,41.028712]]]},"properties":{"id":"10530","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.847134,41.253368],[-73.817977,41.2374],[-73.766118,41.229274],[-73.729834,41.250445],[-73.709372,41.265112],[-73.702247,41.268127],[-73.768764,41.277282],[-73.76651,41.306291],[-73.779212,41.33742],[-73.767994,41.337458],[-73.748438,41.318206],[-73.746264,41.304493],[-73.753398,41.287372],[-73.728942,41.318273],[-73.734631,41.337191],[-73.734867,41.337616],[-73.757605,41.344904],[-73.78826,41.341737],[-73.813059,41.332277],[-73.826961,41.31905],[-73.838955,41.28875],[-73.847134,41.253368]]]},"properties":{"id":"10598","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.565873,41.261412],[-73.513993,41.198854],[-73.549879,41.301522],[-73.585567,41.30257],[-73.592598,41.277853],[-73.565873,41.261412]]]},"properties":{"id":"10590","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.754556,41.032708],[-73.769815,41.024777],[-73.763962,40.998468],[-73.744656,40.982445],[-73.726273,41.000038],[-73.724228,41.015908],[-73.754556,41.032708]]]},"properties":{"id":"10605","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.396382,42.678168],[-78.338313,42.651007],[-78.329593,42.607607],[-78.298009,42.667425],[-78.304019,42.693335],[-78.387929,42.704757],[-78.396382,42.678168]]]},"properties":{"id":"14113","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.0742,42.713472],[-78.172668,42.67097],[-78.08156,42.640826],[-78.026193,42.713333],[-78.0742,42.713472]]]},"properties":{"id":"14550","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.908536,42.682892],[-73.934434,42.613602],[-73.840076,42.642121],[-73.82808,42.661765],[-73.897925,42.685531],[-73.908536,42.682892]]]},"properties":{"id":"12159","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.721003,42.70869],[-73.703463,42.705578],[-73.694662,42.732078],[-73.698857,42.751143],[-73.732127,42.765655],[-73.745497,42.714073],[-73.721003,42.70869]]]},"properties":{"id":"12189","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.91417,42.807383],[-73.861895,42.761967],[-73.823538,42.757175],[-73.809369,42.778869],[-73.895938,42.851076],[-73.917178,42.843697],[-73.91417,42.807383]]]},"properties":{"id":"12309","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.772078,42.654939],[-73.82808,42.661765],[-73.840076,42.642121],[-73.809204,42.639149],[-73.772669,42.647334],[-73.768137,42.651338],[-73.772078,42.654939]]]},"properties":{"id":"12208","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.031461,42.568372],[-74.031914,42.678041],[-74.09674,42.628827],[-74.08061,42.555899],[-74.031461,42.568372]]]},"properties":{"id":"12059","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.08061,42.555899],[-74.116494,42.539842],[-74.102584,42.478223],[-74.096645,42.452345],[-73.970143,42.496056],[-73.997652,42.551067],[-74.031461,42.568372],[-74.08061,42.555899]]]},"properties":{"id":"12193","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.074088,42.194912],[-78.077264,42.103458],[-77.881402,42.173718],[-77.839024,42.226878],[-77.862305,42.252317],[-78.074088,42.194912]]]},"properties":{"id":"14880","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.103616,42.275764],[-78.068521,42.294666],[-78.041816,42.347457],[-78.074199,42.380078],[-78.226348,42.326677],[-78.17228,42.292806],[-78.103616,42.275764]]]},"properties":{"id":"14711","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.041816,42.347457],[-78.068521,42.294666],[-77.959498,42.299502],[-77.878796,42.368322],[-77.95477,42.435323],[-77.969416,42.435186],[-78.041816,42.347457]]]},"properties":{"id":"14709","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.229997,42.260772],[-78.208452,42.128094],[-78.146381,42.08952],[-78.138542,42.086561],[-78.126184,42.089328],[-78.077264,42.103458],[-78.074088,42.194912],[-78.103616,42.275764],[-78.17228,42.292806],[-78.229997,42.260772]]]},"properties":{"id":"14739","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.226348,42.326677],[-78.253191,42.339455],[-78.229997,42.260772],[-78.17228,42.292806],[-78.226348,42.326677]]]},"properties":{"id":"14714","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.884855,40.878545],[-73.887105,40.882923],[-73.908605,40.871678],[-73.91929,40.857475],[-73.899949,40.85742],[-73.884855,40.878545]]]},"properties":{"id":"10468","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.868383,40.857812],[-73.871896,40.854075],[-73.871745,40.844191],[-73.866806,40.834837],[-73.84572,40.828284],[-73.842683,40.828497],[-73.842279,40.831267],[-73.861791,40.857983],[-73.868383,40.857812]]]},"properties":{"id":"10462","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.824957,40.846105],[-73.842279,40.831267],[-73.842683,40.828497],[-73.842425,40.82806],[-73.815674,40.848855],[-73.824957,40.846105]]]},"properties":{"id":"10465","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.882579,40.755854],[-73.887671,40.76672],[-73.889068,40.773682],[-73.892348,40.775847],[-73.900659,40.767211],[-73.896522,40.754389],[-73.882579,40.755854]]]},"properties":{"id":"11370","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.004425,42.233526],[-75.948424,42.094791],[-75.917519,42.092131],[-75.928822,42.188917],[-75.934609,42.246608],[-75.945031,42.26368],[-76.004425,42.233526]]]},"properties":{"id":"13905","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.016936,42.258887],[-76.021647,42.252287],[-76.053785,42.20471],[-76.004425,42.233526],[-75.945031,42.26368],[-76.016936,42.258887]]]},"properties":{"id":"13777","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.934609,42.246608],[-75.928822,42.188917],[-75.914766,42.233574],[-75.934609,42.246608]]],[[[-75.915318,42.236288],[-75.88969,42.237866],[-75.887663,42.27196],[-75.915318,42.236288]]]]},"properties":{"id":"13744","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.468816,42.145666],[-75.605703,42.195486],[-75.602916,42.121259],[-75.517131,42.120348],[-75.468816,42.145666]]]},"properties":{"id":"13813","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.762432,41.997806],[-75.742217,41.997864],[-75.779128,42.124792],[-75.827732,42.091598],[-75.762432,41.997806]]]},"properties":{"id":"13795","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.061087,42.468091],[-79.134994,42.36994],[-79.060515,42.292942],[-78.978322,42.325091],[-78.986338,42.368134],[-79.017062,42.435243],[-79.061087,42.468091]]]},"properties":{"id":"14138","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.755963,42.346702],[-78.888053,42.23401],[-78.827501,42.120595],[-78.802371,42.139551],[-78.759613,42.299198],[-78.72439,42.331473],[-78.755963,42.346702]]]},"properties":{"id":"14755","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.622726,42.333261],[-78.670461,42.346636],[-78.72439,42.331473],[-78.759613,42.299198],[-78.69693,42.259099],[-78.574038,42.297645],[-78.555554,42.337497],[-78.622726,42.333261]]]},"properties":{"id":"14731","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.344229,42.831934],[-76.286878,42.803969],[-76.24488,42.820885],[-76.267591,42.846159],[-76.304786,42.875783],[-76.347179,42.923098],[-76.396289,42.994576],[-76.43993,42.995854],[-76.461737,42.988321],[-76.43471,42.823235],[-76.344229,42.831934]]]},"properties":{"id":"13152","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.685484,43.162766],[-76.617448,43.132637],[-76.537824,43.150141],[-76.476997,43.145954],[-76.474314,43.161546],[-76.479224,43.227519],[-76.479984,43.240296],[-76.56449,43.254986],[-76.654719,43.199437],[-76.685484,43.162766]],[[-76.546984,43.157433],[-76.540807,43.167004],[-76.53325,43.156246],[-76.546984,43.157433]]]},"properties":{"id":"13033","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.735345,43.172964],[-76.706085,43.137052],[-76.685484,43.162766],[-76.654719,43.199437],[-76.686962,43.278716],[-76.722491,43.343561],[-76.759,43.323715],[-76.735345,43.172964]]]},"properties":{"id":"13143","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.112619,42.555668],[-76.072768,42.671958],[-76.063317,42.72853],[-76.076567,42.731998],[-76.26851,42.6735],[-76.323463,42.666311],[-76.29586,42.630184],[-76.296244,42.573564],[-76.296677,42.556099],[-76.292303,42.556153],[-76.291011,42.549589],[-76.260957,42.540636],[-76.206304,42.479753],[-76.112619,42.555668]]]},"properties":{"id":"13045","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.536106,42.624908],[-76.487615,42.698975],[-76.500135,42.713106],[-76.586788,42.710231],[-76.555854,42.62415],[-76.536106,42.624908]]]},"properties":{"id":"13071","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.469566,42.207774],[-79.463454,42.210574],[-79.473382,42.215573],[-79.478025,42.21353],[-79.469566,42.207774]]]},"properties":{"id":"14722","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.645283,42.322848],[-79.761741,42.162695],[-79.725132,42.167925],[-79.624008,42.239822],[-79.645283,42.322848]]]},"properties":{"id":"14775","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.271054,42.462108],[-79.22001,42.383108],[-79.134994,42.36994],[-79.061087,42.468091],[-79.061017,42.51303],[-79.080904,42.512687],[-79.236757,42.484429],[-79.253073,42.477363],[-79.271054,42.462108]]]},"properties":{"id":"14062","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.097476,42.232844],[-79.128335,42.216487],[-79.101366,42.216643],[-79.097476,42.232844]]]},"properties":{"id":"14732","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.729177,42.222858],[-76.756107,42.163698],[-76.729403,42.164755],[-76.726122,42.16862],[-76.729177,42.222858]]]},"properties":{"id":"14816","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.095459,42.234963],[-77.106143,42.090872],[-77.022131,42.017187],[-76.925877,42.096461],[-76.965433,42.192226],[-76.96535,42.223288],[-77.095459,42.234963]]]},"properties":{"id":"14830","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.726122,42.16862],[-76.620605,42.123237],[-76.613077,42.130191],[-76.642256,42.233721],[-76.729688,42.232012],[-76.729177,42.222858],[-76.726122,42.16862]]]},"properties":{"id":"14838","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.787677,42.001573],[-76.705707,42.001319],[-76.724864,42.016907],[-76.731508,42.018043],[-76.77952,42.051496],[-76.781825,42.050539],[-76.787677,42.001573]]]},"properties":{"id":"14894","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.618711,42.300316],[-75.419203,42.168053],[-75.381162,42.243804],[-75.387441,42.327644],[-75.380064,42.345784],[-75.452691,42.394936],[-75.52225,42.375143],[-75.609221,42.337669],[-75.618711,42.300316]]]},"properties":{"id":"13733","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.727132,42.641242],[-75.581357,42.623993],[-75.58239,42.629404],[-75.704221,42.708036],[-75.732767,42.704723],[-75.727132,42.641242]]]},"properties":{"id":"13832","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.337713,42.809945],[-75.304945,42.846386],[-75.35391,42.867338],[-75.357649,42.826209],[-75.337713,42.809945]]],[[[-75.337713,42.809945],[-75.351668,42.80933],[-75.391451,42.770435],[-75.370166,42.740376],[-75.300725,42.715234],[-75.216318,42.787345],[-75.212289,42.798477],[-75.250267,42.797375],[-75.282316,42.809812],[-75.270455,42.825147],[-75.290371,42.822699],[-75.337713,42.809945]]]]},"properties":{"id":"13485","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.452691,42.394936],[-75.380064,42.345784],[-75.36057,42.405347],[-75.398385,42.45879],[-75.452691,42.394936]]]},"properties":{"id":"13809","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.836341,44.71046],[-73.927314,44.749068],[-73.977876,44.70834],[-73.959255,44.663219],[-73.836341,44.71046]]]},"properties":{"id":"12952","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.991793,44.747854],[-73.96691,44.852376],[-73.986594,44.887275],[-74.027389,44.995685],[-74.144696,44.99157],[-74.143957,44.857454],[-74.11819,44.788743],[-73.991793,44.747854]]]},"properties":{"id":"12920","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.463166,44.524736],[-73.466257,44.5202],[-73.46019,44.519793],[-73.459986,44.52345],[-73.463166,44.524736]]]},"properties":{"id":"12911","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.570055,42.156361],[-73.634884,42.221108],[-73.680662,42.209811],[-73.694441,42.204261],[-73.733169,42.137302],[-73.721926,42.123271],[-73.656674,42.132112],[-73.579681,42.142606],[-73.570055,42.156361]]]},"properties":{"id":"12521","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.734971,42.222454],[-73.73077,42.212624],[-73.702374,42.218558],[-73.722609,42.235288],[-73.734971,42.222454]]]},"properties":{"id":"12513","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.805312,42.099234],[-73.851917,42.18967],[-73.921576,42.077333],[-73.811398,42.076442],[-73.805312,42.099234]]]},"properties":{"id":"12526","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.160756,42.703403],[-76.150889,42.704039],[-76.154517,42.715278],[-76.160756,42.703403]]]},"properties":{"id":"13087","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.882294,42.420293],[-75.867514,42.45992],[-75.86968,42.490218],[-75.922225,42.458703],[-75.951629,42.411427],[-75.882294,42.420293]]]},"properties":{"id":"13863","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.072768,42.671958],[-76.024741,42.649125],[-75.905537,42.654574],[-75.883843,42.655634],[-75.885433,42.674327],[-75.91983,42.818506],[-76.005195,42.784871],[-76.063317,42.72853],[-76.072768,42.671958]]]},"properties":{"id":"13158","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.583337,42.168757],[-74.56458,42.044667],[-74.52959,42.027736],[-74.502964,42.038031],[-74.485462,42.099495],[-74.49673,42.148278],[-74.583337,42.168757]]]},"properties":{"id":"12406","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.810211,42.029108],[-74.692152,42.057477],[-74.733763,42.22626],[-74.831773,42.218638],[-74.869046,42.079167],[-74.869796,42.073349],[-74.810211,42.029108]]]},"properties":{"id":"13731","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.02003,42.383273],[-75.019674,42.347594],[-74.966891,42.355343],[-75.02003,42.383273]]],[[[-74.852078,42.401277],[-74.779837,42.433572],[-74.877845,42.464221],[-74.919674,42.446585],[-75.006329,42.384723],[-74.939333,42.373886],[-74.852078,42.401277]]]]},"properties":{"id":"13757","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.728915,42.429612],[-74.719049,42.419434],[-74.685824,42.413116],[-74.657011,42.462885],[-74.750672,42.451713],[-74.733061,42.433783],[-74.728915,42.429612]]]},"properties":{"id":"13786","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.950439,41.529368],[-73.979256,41.534307],[-73.978588,41.442119],[-73.918758,41.489783],[-73.932755,41.517519],[-73.941143,41.516061],[-73.950439,41.529368]]]},"properties":{"id":"12508","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.954833,41.913563],[-73.936453,41.913563],[-73.949569,41.925142],[-73.954833,41.913563]]]},"properties":{"id":"12574","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.954947,42.674921],[-79.065287,42.662384],[-79.113331,42.610471],[-79.073154,42.602712],[-79.069672,42.602702],[-79.01734,42.600451],[-78.963621,42.619647],[-78.954947,42.674921]]]},"properties":{"id":"14006","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.017884,43.020793],[-78.94164,42.997905],[-78.894084,43.02563],[-78.990923,43.063648],[-79.017884,43.020793]]]},"properties":{"id":"14072","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.790693,43.01251],[-78.802252,42.994255],[-78.797942,42.99078],[-78.782088,42.997009],[-78.780179,43.00827],[-78.790693,43.01251]]]},"properties":{"id":"14261","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.737883,43.024916],[-78.775512,43.006532],[-78.782088,42.997009],[-78.797942,42.99078],[-78.765507,42.949616],[-78.696925,42.949866],[-78.677082,42.947798],[-78.660457,42.955861],[-78.670013,43.00026],[-78.681987,43.000239],[-78.737883,43.024916]]]},"properties":{"id":"14221","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.84395,42.845103],[-78.79363,42.828632],[-78.795157,42.841135],[-78.848784,42.859464],[-78.84395,42.845103]]]},"properties":{"id":"14220","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.609888,42.584062],[-78.654381,42.58825],[-78.706067,42.591848],[-78.55983,42.513278],[-78.533186,42.531235],[-78.525062,42.533437],[-78.566847,42.58782],[-78.609888,42.584062]]]},"properties":{"id":"14055","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.790759,44.334175],[-73.748205,44.442813],[-73.909687,44.429699],[-73.941801,44.343643],[-73.891545,44.298158],[-73.806681,44.305628],[-73.790759,44.334175]]]},"properties":{"id":"12997","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.519221,44.07641],[-73.507232,44.061631],[-73.470953,44.084596],[-73.525072,44.109095],[-73.55069,44.106455],[-73.519221,44.07641]]]},"properties":{"id":"12956","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.401444,44.924319],[-74.377694,44.925923],[-74.255672,44.907712],[-74.243085,44.992165],[-74.441856,44.996488],[-74.401444,44.924319]]]},"properties":{"id":"12926","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.642167,44.289335],[-74.665933,44.219775],[-74.333374,44.115976],[-74.313764,44.199584],[-74.214257,44.263224],[-74.267622,44.268561],[-74.433015,44.395012],[-74.566447,44.439597],[-74.691433,44.37816],[-74.686129,44.349099],[-74.685027,44.342688],[-74.5535,44.351887],[-74.533821,44.231338],[-74.642167,44.289335]]]},"properties":{"id":"12986","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.191989,44.410528],[-74.145335,44.429841],[-74.147768,44.44436],[-74.178012,44.444245],[-74.191989,44.410528]]]},"properties":{"id":"12939","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.467094,43.12247],[-74.234535,43.049601],[-74.216995,43.04744],[-74.215127,43.070458],[-74.256796,43.097395],[-74.319063,43.192086],[-74.457719,43.232885],[-74.467094,43.12247]]]},"properties":{"id":"12078","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.319063,43.192086],[-74.256796,43.097395],[-74.182849,43.224268],[-74.319063,43.192086]]]},"properties":{"id":"12117","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.740093,42.998315],[-74.493675,43.015682],[-74.537703,43.086198],[-74.546406,43.08913],[-74.761889,43.059199],[-74.740093,42.998315]]]},"properties":{"id":"13452","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.908666,43.037266],[-78.080871,43.018557],[-78.02944,42.956342],[-77.955455,42.901193],[-77.919921,42.912802],[-77.878331,42.987862],[-77.907382,43.005979],[-77.908666,43.037266]]]},"properties":{"id":"14482","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.162265,42.335161],[-74.229985,42.372685],[-74.244692,42.377159],[-74.362976,42.36728],[-74.367572,42.332634],[-74.334532,42.31173],[-74.371034,42.300291],[-74.372574,42.285834],[-74.323066,42.284867],[-74.225797,42.280058],[-74.195298,42.29723],[-74.171372,42.311147],[-74.162265,42.335161]]]},"properties":{"id":"12496","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.89227,42.321565],[-73.922142,42.294037],[-73.879009,42.257479],[-73.850381,42.308661],[-73.89227,42.321565]]],[[[-74.013191,42.242092],[-73.996421,42.17339],[-73.926494,42.133456],[-73.918786,42.129159],[-73.830354,42.251524],[-73.882531,42.251703],[-73.940446,42.265562],[-73.974638,42.260613],[-73.985843,42.255266],[-74.013191,42.242092]]]]},"properties":{"id":"12414","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.204296,42.262577],[-74.168882,42.22339],[-74.13432,42.224861],[-74.064819,42.225216],[-74.096671,42.263316],[-74.104619,42.2678],[-74.190164,42.268284],[-74.204296,42.262577]]]},"properties":{"id":"12424","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.153061,43.272648],[-75.222391,43.288813],[-75.179093,43.194678],[-75.106448,43.218448],[-75.153061,43.272648]]]},"properties":{"id":"13304","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.119785,43.693737],[-75.085618,43.6611],[-74.997855,43.70799],[-75.123087,43.72037],[-75.119785,43.693737]]]},"properties":{"id":"13472","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.029275,43.121082],[-75.018811,43.016189],[-75.018005,43.016102],[-74.916816,43.018479],[-74.972755,43.130061],[-74.973276,43.130023],[-75.029275,43.121082]]]},"properties":{"id":"13350","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.827401,42.89524],[-74.830393,42.893986],[-74.833022,42.893445],[-74.834692,42.89237],[-74.834061,42.892093],[-74.832957,42.893173],[-74.828713,42.893995],[-74.826673,42.894904],[-74.827401,42.89524]]]},"properties":{"id":"13475","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.961611,40.724951],[-73.97588,40.701927],[-73.95702,40.698974],[-73.934802,40.713373],[-73.923734,40.716245],[-73.929223,40.727849],[-73.961611,40.724951]]]},"properties":{"id":"11211","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.024694,40.60996],[-74.016048,40.631033],[-74.038653,40.635635],[-74.01945,40.602828],[-74.021502,40.607828],[-74.023602,40.605613],[-74.026454,40.608],[-74.024694,40.60996]]]},"properties":{"id":"11209","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.997734,40.64341],[-74.024406,40.650922],[-74.038653,40.635635],[-74.016048,40.631033],[-74.012142,40.628684],[-73.997734,40.64341]]]},"properties":{"id":"11220","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.024694,40.60996],[-74.026454,40.608],[-74.023602,40.605613],[-74.021502,40.607828],[-74.024694,40.60996]]]},"properties":{"id":"11425","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.658189,43.495782],[-75.581467,43.477781],[-75.663693,43.645833],[-75.696283,43.569999],[-75.658189,43.495782]]],[[[-75.59319,43.267632],[-75.565126,43.298988],[-75.53871,43.383215],[-75.55792,43.42487],[-75.6395,43.444149],[-75.692598,43.312854],[-75.59319,43.267632]]]]},"properties":{"id":"13471","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.455746,43.698365],[-75.39869,43.676147],[-75.361123,43.686635],[-75.341176,43.708746],[-75.130204,43.776311],[-75.133487,43.802515],[-75.435473,43.722839],[-75.455763,43.727389],[-75.455746,43.698365]]]},"properties":{"id":"13343","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.446124,44.217655],[-75.507512,44.085106],[-75.357239,44.041948],[-75.164076,44.049035],[-75.170159,44.096959],[-75.185258,44.180827],[-75.174183,44.299429],[-75.318664,44.236302],[-75.446124,44.217655]]]},"properties":{"id":"13648","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.600823,42.671972],[-77.655648,42.669261],[-77.634755,42.627492],[-77.48998,42.641962],[-77.487678,42.659094],[-77.539342,42.74914],[-77.61167,42.763169],[-77.600823,42.671972]]]},"properties":{"id":"14560","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.887581,42.874684],[-77.893514,42.872295],[-77.903642,42.873757],[-77.906983,42.871466],[-77.887248,42.871132],[-77.887581,42.874684]]]},"properties":{"id":"14592","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.477552,42.797533],[-77.533065,42.825545],[-77.532579,42.789532],[-77.539342,42.74914],[-77.487678,42.659094],[-77.438589,42.730291],[-77.477552,42.797533]]]},"properties":{"id":"14471","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.813279,42.869539],[-75.904284,42.870473],[-75.913432,42.876177],[-75.920301,42.82033],[-75.837258,42.804621],[-75.795668,42.825064],[-75.813279,42.869539]]]},"properties":{"id":"13122","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.452778,42.86763],[-75.475014,42.847493],[-75.481053,42.796279],[-75.42951,42.75742],[-75.391451,42.770435],[-75.351668,42.80933],[-75.355599,42.8115],[-75.404148,42.853004],[-75.452778,42.86763]]]},"properties":{"id":"13355","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.544967,42.956309],[-75.440332,42.893691],[-75.421214,42.948841],[-75.459115,43.021768],[-75.525704,42.986176],[-75.517183,42.986269],[-75.516991,42.978179],[-75.526924,42.978176],[-75.526923,42.986172],[-75.54758,42.986036],[-75.544967,42.956309]]]},"properties":{"id":"13425","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.582098,43.175657],[-77.626145,43.173244],[-77.620232,43.165805],[-77.613103,43.160629],[-77.595667,43.159523],[-77.585157,43.161835],[-77.582098,43.175657]]]},"properties":{"id":"14605","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.847315,43.236037],[-77.763015,43.23681],[-77.737394,43.250471],[-77.70541,43.318934],[-77.890506,43.354935],[-77.932449,43.27023],[-77.847315,43.236037]]]},"properties":{"id":"14468","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.79669,43.090277],[-77.795984,43.070356],[-77.780377,43.089345],[-77.79669,43.090277]]],[[[-77.827886,43.130734],[-77.818504,43.107408],[-77.78769,43.110938],[-77.827886,43.130734]]]]},"properties":{"id":"14514","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.356274,42.835806],[-74.411384,42.77649],[-74.326951,42.742545],[-74.32722,42.717859],[-74.314667,42.710985],[-74.266508,42.727578],[-74.26287,42.823409],[-74.262926,42.831569],[-74.356274,42.835806]]]},"properties":{"id":"12066","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.483611,40.876613],[-73.517431,40.84578],[-73.555534,40.837281],[-73.555087,40.83193],[-73.535855,40.809305],[-73.517451,40.784579],[-73.50367,40.787862],[-73.487375,40.795092],[-73.455261,40.840872],[-73.467366,40.868098],[-73.483611,40.876613]]]},"properties":{"id":"11791","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.517451,40.784579],[-73.535855,40.809305],[-73.548363,40.799048],[-73.568826,40.777324],[-73.551026,40.77361],[-73.517451,40.784579]]]},"properties":{"id":"11753","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.745633,40.611756],[-73.746702,40.633387],[-73.766954,40.623172],[-73.763454,40.61369],[-73.745633,40.611756]]]},"properties":{"id":"11096","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.70613,40.590958],[-73.707045,40.583739],[-73.590007,40.585668],[-73.587257,40.593495],[-73.637571,40.595839],[-73.663572,40.595205],[-73.685975,40.591704],[-73.70613,40.590958]]]},"properties":{"id":"11561","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.57199,40.895195],[-73.568315,40.867187],[-73.548644,40.902962],[-73.57199,40.895195]]]},"properties":{"id":"11765","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.555534,40.837281],[-73.567301,40.832434],[-73.555087,40.83193],[-73.555534,40.837281]]],[[[-73.555534,40.837281],[-73.517431,40.84578],[-73.520561,40.852903],[-73.555534,40.837281]]]]},"properties":{"id":"11732","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.924715,40.861552],[-73.938947,40.856079],[-73.928151,40.851863],[-73.924715,40.861552]]]},"properties":{"id":"10040","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.005733,40.75597],[-73.984076,40.749102],[-73.981822,40.752197],[-74.001163,40.762021],[-74.001716,40.762208],[-74.006145,40.756189],[-74.005733,40.75597]]]},"properties":{"id":"10018","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.959263,40.759284],[-73.972553,40.764898],[-73.973015,40.764279],[-73.971411,40.763588],[-73.971869,40.762964],[-73.973465,40.763659],[-73.975831,40.760418],[-73.976289,40.759787],[-73.976744,40.759165],[-73.977201,40.758538],[-73.977655,40.757912],[-73.974439,40.756556],[-73.97282,40.75587],[-73.964492,40.751445],[-73.958599,40.758133],[-73.959263,40.759284]],[[-73.971451,40.757749],[-73.971907,40.757125],[-73.973524,40.757808],[-73.973068,40.758432],[-73.972613,40.759059],[-73.970993,40.758373],[-73.971451,40.757749]]]},"properties":{"id":"10022","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.974067,40.778805],[-73.986609,40.780712],[-73.989994,40.772242],[-73.982067,40.768481],[-73.974067,40.778805]]]},"properties":{"id":"10023","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.981341,40.758645],[-74.001163,40.762021],[-73.981822,40.752197],[-73.980888,40.753481],[-73.984118,40.754842],[-73.983624,40.755516],[-73.980395,40.754155],[-73.979937,40.754783],[-73.978116,40.757284],[-73.981341,40.758645]]]},"properties":{"id":"10036","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.964424,40.810755],[-73.963477,40.810373],[-73.963058,40.810947],[-73.964019,40.811331],[-73.964424,40.810755]]]},"properties":{"id":"10115","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975807,40.754678],[-73.975352,40.755303],[-73.976516,40.754974],[-73.975807,40.754678]]]},"properties":{"id":"10177","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975558,40.752116],[-73.976048,40.751441],[-73.974449,40.750766],[-73.973959,40.751441],[-73.975558,40.752116]]]},"properties":{"id":"10174","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.007666,40.712533],[-74.009254,40.713256],[-74.009668,40.712725],[-74.0081,40.711995],[-74.007666,40.712533]]]},"properties":{"id":"10279","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.047798,43.093214],[-79.048579,43.09478],[-79.050522,43.094698],[-79.0504,43.093103],[-79.047798,43.093214]]]},"properties":{"id":"14302","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.732281,43.221316],[-75.71712,43.202585],[-75.645333,43.20945],[-75.59319,43.267632],[-75.692598,43.312854],[-75.75301,43.239105],[-75.748971,43.224035],[-75.732281,43.221316]]]},"properties":{"id":"13308","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.449052,43.025884],[-75.460524,43.062644],[-75.550017,43.063855],[-75.574205,43.036461],[-75.54758,42.986036],[-75.526923,42.986172],[-75.525704,42.986176],[-75.459115,43.021768],[-75.449052,43.025884]]]},"properties":{"id":"13477","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.285046,43.101273],[-75.293948,43.111883],[-75.304226,43.096541],[-75.285046,43.101273]]]},"properties":{"id":"13417","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.273483,43.042188],[-75.276355,43.019196],[-75.269674,43.032985],[-75.273483,43.042188]]]},"properties":{"id":"13319","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.171353,43.137219],[-76.243284,43.116494],[-76.178592,43.077553],[-76.177628,43.078474],[-76.156142,43.091894],[-76.163629,43.093124],[-76.171353,43.137219]]]},"properties":{"id":"13088","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.141793,42.901274],[-77.214174,42.805733],[-77.162227,42.739098],[-77.152191,42.739205],[-77.105732,42.758085],[-77.078134,42.886087],[-77.141793,42.901274]]]},"properties":{"id":"14561","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.2939,42.944204],[-77.307723,43.008652],[-77.29516,43.034706],[-77.364968,43.034671],[-77.370591,42.944241],[-77.2939,42.944204]]]},"properties":{"id":"14425","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.455516,41.408104],[-74.493755,41.363002],[-74.485351,41.317087],[-74.469584,41.315209],[-74.377671,41.412095],[-74.455516,41.408104]]]},"properties":{"id":"10958","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.234473,41.142883],[-74.151001,41.203462],[-74.181191,41.228],[-74.193874,41.273941],[-74.261107,41.230573],[-74.308435,41.186995],[-74.314363,41.178955],[-74.234473,41.142883]]]},"properties":{"id":"10987","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.569298,41.299966],[-74.519327,41.306581],[-74.485351,41.317087],[-74.493755,41.363002],[-74.496767,41.367352],[-74.51973,41.368948],[-74.557326,41.37138],[-74.60746,41.317824],[-74.569298,41.299966]]]},"properties":{"id":"10998","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.464923,41.479659],[-74.458114,41.482067],[-74.467544,41.48551],[-74.469936,41.482077],[-74.464923,41.479659]]]},"properties":{"id":"10932","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.185244,43.339097],[-78.095522,43.29888],[-78.085579,43.3733],[-78.192323,43.371845],[-78.185244,43.339097]]]},"properties":{"id":"14477","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.980151,43.356514],[-76.059343,43.242725],[-75.972956,43.243261],[-75.960783,43.343057],[-75.980151,43.356514]]]},"properties":{"id":"13044","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.110014,42.810113],[-75.212289,42.798477],[-75.216318,42.787345],[-75.190515,42.70543],[-75.16976,42.642994],[-75.117463,42.671795],[-75.074717,42.787773],[-75.110014,42.810113]]]},"properties":{"id":"13315","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.854053,42.677319],[-74.83263,42.681445],[-74.824851,42.700824],[-74.854053,42.677319]]],[[[-74.813213,42.704277],[-74.764145,42.716395],[-74.797536,42.716896],[-74.813213,42.704277]]]]},"properties":{"id":"13450","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.801663,40.666889],[-73.767353,40.65616],[-73.762008,40.684551],[-73.769895,40.696359],[-73.794371,40.686913],[-73.794578,40.686107],[-73.801663,40.666889]]]},"properties":{"id":"11434","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.92392,40.714074],[-73.902854,40.713793],[-73.88724,40.727834],[-73.888523,40.734531],[-73.925851,40.735645],[-73.929223,40.727849],[-73.923734,40.716245],[-73.92392,40.714074]]]},"properties":{"id":"11378","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.721178,40.756871],[-73.707825,40.756216],[-73.712761,40.759415],[-73.713034,40.759566],[-73.721178,40.756871]]]},"properties":{"id":"11005","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.961544,40.743081],[-73.956208,40.743872],[-73.95685,40.748225],[-73.961544,40.743081]]]},"properties":{"id":"11109","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.470787,42.800624],[-73.45287,42.721095],[-73.47886,42.663195],[-73.428918,42.608887],[-73.406914,42.610484],[-73.358098,42.614289],[-73.361092,42.625928],[-73.366791,42.624795],[-73.27414,42.721195],[-73.278306,42.837558],[-73.417011,42.821285],[-73.465028,42.815794],[-73.470787,42.800624]]]},"properties":{"id":"12138","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.404033,42.925978],[-73.404188,42.998235],[-73.475336,42.976674],[-73.449578,42.872233],[-73.404033,42.925978]]]},"properties":{"id":"12028","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.662002,42.640638],[-73.719979,42.58028],[-73.65561,42.569907],[-73.625161,42.559763],[-73.584518,42.591847],[-73.601632,42.613268],[-73.659117,42.640149],[-73.662002,42.640638]]]},"properties":{"id":"12061","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.549963,42.579096],[-73.45055,42.53504],[-73.447894,42.561865],[-73.467214,42.606027],[-73.47886,42.663195],[-73.45287,42.721095],[-73.537105,42.712194],[-73.602663,42.684715],[-73.600775,42.666197],[-73.601632,42.613268],[-73.584518,42.591847],[-73.549963,42.579096]]]},"properties":{"id":"12018","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.195988,40.635172],[-74.14612,40.62493],[-74.143086,40.639784],[-74.195988,40.635172]]]},"properties":{"id":"10303","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.964282,41.122495],[-73.907089,41.143037],[-73.939727,41.176497],[-73.943808,41.175434],[-73.969284,41.123778],[-73.964282,41.122495]]]},"properties":{"id":"10920","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.040855,44.873242],[-74.979879,44.839614],[-74.976849,44.839942],[-74.852905,44.868948],[-74.722055,44.925533],[-74.690758,44.95291],[-74.725679,44.990797],[-75.119706,44.889216],[-75.119181,44.888673],[-75.040855,44.873242]]]},"properties":{"id":"13662","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.334113,44.677932],[-75.546416,44.552965],[-75.536744,44.477511],[-75.422798,44.519554],[-75.315535,44.655502],[-75.334113,44.677932]]]},"properties":{"id":"13654","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.976849,44.839942],[-74.979879,44.839614],[-74.983644,44.820821],[-74.994625,44.816528],[-74.996052,44.809101],[-74.980489,44.811498],[-74.978929,44.82502],[-74.971646,44.830503],[-74.976849,44.839942]]]},"properties":{"id":"13678","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.909066,44.179495],[-74.901293,44.15989],[-74.889302,44.066829],[-74.854171,44.070089],[-74.687816,44.219704],[-74.809743,44.329318],[-74.923242,44.326312],[-74.918495,44.287509],[-74.909066,44.179495]]]},"properties":{"id":"12927","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.973723,43.033764],[-74.068017,43.129578],[-74.082805,43.026224],[-74.052561,43.007276],[-73.99858,42.975194],[-73.981036,42.989565],[-73.973723,43.033764]]]},"properties":{"id":"12074","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.981036,42.989565],[-73.934554,42.933196],[-73.888171,42.941448],[-73.803189,42.952982],[-73.755966,42.950398],[-73.877888,43.071597],[-73.903929,43.06594],[-73.943965,43.044458],[-73.973723,43.033764],[-73.981036,42.989565]]]},"properties":{"id":"12020","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.80202,42.925965],[-73.875178,42.879454],[-73.768764,42.785877],[-73.76137,42.791771],[-73.731669,42.821305],[-73.698782,42.854845],[-73.770166,42.907758],[-73.80202,42.925965]]]},"properties":{"id":"12065","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.891991,42.894459],[-73.898029,42.866728],[-73.895938,42.851076],[-73.809369,42.778869],[-73.768764,42.785877],[-73.875178,42.879454],[-73.891274,42.894996],[-73.891991,42.894459]]]},"properties":{"id":"12148","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.594175,43.082257],[-73.585303,43.084909],[-73.58283,43.096185],[-73.600134,43.083646],[-73.594175,43.082257]]]},"properties":{"id":"12884","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.961427,43.284635],[-74.083988,43.210025],[-73.986197,43.266267],[-73.961427,43.284635]]],[[[-73.883299,43.397988],[-74.1601,43.371532],[-74.158083,43.359527],[-74.093698,43.213806],[-73.923174,43.319356],[-73.827072,43.289932],[-73.883299,43.397988]]]]},"properties":{"id":"12835","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.031635,42.869371],[-74.073552,42.891623],[-74.047792,42.870985],[-74.031635,42.869371]]]},"properties":{"id":"12150","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.251965,42.400783],[-74.326265,42.461298],[-74.432769,42.473398],[-74.440704,42.473243],[-74.441157,42.472863],[-74.484482,42.436133],[-74.526693,42.388085],[-74.443506,42.355017],[-74.362976,42.36728],[-74.244692,42.377159],[-74.251965,42.400783]]]},"properties":{"id":"12076","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.628535,42.566408],[-74.500177,42.560515],[-74.463531,42.564294],[-74.50728,42.660006],[-74.643407,42.675906],[-74.638844,42.661558],[-74.638989,42.563159],[-74.628535,42.566408]]]},"properties":{"id":"12149","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.41463,42.499358],[-74.430945,42.593387],[-74.463531,42.564294],[-74.442643,42.471739],[-74.441157,42.472863],[-74.440704,42.473243],[-74.41463,42.499358]]]},"properties":{"id":"12071","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.879481,42.472115],[-76.855585,42.387994],[-76.854869,42.387884],[-76.799405,42.386034],[-76.766194,42.430976],[-76.790077,42.514512],[-76.879481,42.472115]]]},"properties":{"id":"14818","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.895179,42.698294],[-76.754394,42.718338],[-76.763507,42.802897],[-76.82919,42.808257],[-76.857431,42.807263],[-76.933328,42.793694],[-76.895179,42.698294]]]},"properties":{"id":"14541","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.419582,42.140518],[-77.313526,42.205055],[-77.295845,42.272528],[-77.341587,42.251511],[-77.414704,42.173432],[-77.419582,42.140518]]]},"properties":{"id":"14820","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.312848,42.57625],[-77.397141,42.55204],[-77.412723,42.486304],[-77.338281,42.466896],[-77.306099,42.447759],[-77.179778,42.520116],[-77.155525,42.54729],[-77.312848,42.57625]]]},"properties":{"id":"14873","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.501599,42.552003],[-77.43483,42.56748],[-77.473903,42.577092],[-77.501599,42.552003]]]},"properties":{"id":"14808","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.154225,40.81737],[-73.197293,40.804726],[-73.171495,40.796684],[-73.154225,40.81737]]]},"properties":{"id":"11749","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.02025,40.823534],[-73.064762,40.818874],[-73.066868,40.818546],[-73.042453,40.785337],[-73.017275,40.795718],[-73.02025,40.823534]]]},"properties":{"id":"11742","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.349373,40.75104],[-73.398653,40.733923],[-73.394508,40.721184],[-73.345965,40.668],[-73.332064,40.673199],[-73.313361,40.717099],[-73.313453,40.718173],[-73.344931,40.742041],[-73.349373,40.75104]]]},"properties":{"id":"11704","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.306479,40.741467],[-73.344931,40.742041],[-73.313453,40.718173],[-73.306479,40.741467]]]},"properties":{"id":"11703","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.397667,40.703061],[-73.376166,40.6569],[-73.345965,40.668],[-73.394508,40.721184],[-73.405944,40.717654],[-73.397667,40.703061]]]},"properties":{"id":"11757","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.691233,40.804014],[-72.658057,40.808874],[-72.673937,40.859484],[-72.693829,40.85358],[-72.708103,40.831241],[-72.690971,40.818322],[-72.691233,40.804014]]]},"properties":{"id":"11977","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.477409,40.843862],[-72.540523,40.825869],[-72.54076,40.813709],[-72.472892,40.833188],[-72.477409,40.843862]]],[[[-72.561377,40.875952],[-72.548524,40.851239],[-72.483915,40.883238],[-72.478765,40.898164],[-72.565642,40.910965],[-72.561377,40.875952]]]]},"properties":{"id":"11946","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.285319,40.899119],[-72.283513,40.955927],[-72.337564,40.966443],[-72.323208,40.88452],[-72.285319,40.899119]]]},"properties":{"id":"11932","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.954815,40.974805],[-72.963457,40.945234],[-72.940603,40.916679],[-72.90321,40.929375],[-72.913477,40.9713],[-72.954815,40.974805]]]},"properties":{"id":"11778","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.148258,40.928737],[-73.106762,40.883124],[-73.069732,40.906212],[-73.072709,40.931544],[-73.100159,40.97885],[-73.151922,40.938242],[-73.148258,40.928737]]]},"properties":{"id":"11733","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.901625,40.865585],[-72.890798,40.844382],[-72.866312,40.850816],[-72.865925,40.892296],[-72.901625,40.865585]]]},"properties":{"id":"11973","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.5352,40.974213],[-72.49953,40.988691],[-72.526588,41.0457],[-72.598112,41.002946],[-72.582422,40.984897],[-72.5352,40.974213]]]},"properties":{"id":"11952","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.88596,40.802146],[-72.926345,40.801585],[-72.92379,40.759627],[-72.89062,40.762274],[-72.88596,40.802146]]]},"properties":{"id":"11719","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.974838,41.721267],[-74.928141,41.726754],[-74.950356,41.756615],[-74.974838,41.721267]]]},"properties":{"id":"12750","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.996366,41.673017],[-75.058448,41.669686],[-74.983831,41.505371],[-74.982581,41.506596],[-74.932836,41.531959],[-74.917163,41.615882],[-74.939124,41.657936],[-74.993317,41.671865],[-74.996366,41.673017]]]},"properties":{"id":"12764","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.799023,41.605665],[-74.790473,41.604722],[-74.785244,41.604279],[-74.815739,41.674875],[-74.819368,41.674968],[-74.799023,41.605665]]]},"properties":{"id":"12778","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.979735,41.839803],[-74.997832,41.822077],[-75.012429,41.794081],[-75.011694,41.792319],[-74.968003,41.791941],[-74.954798,41.822729],[-74.975943,41.838958],[-74.979735,41.839803]]]},"properties":{"id":"12766","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.347561,42.056908],[-76.361523,42.051938],[-76.355007,42.046227],[-76.347561,42.056908]]]},"properties":{"id":"13845","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.4007,42.478205],[-76.393193,42.482033],[-76.38678,42.480785],[-76.385167,42.485482],[-76.4007,42.478205]]]},"properties":{"id":"13062","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.296677,42.556099],[-76.291011,42.549589],[-76.292303,42.556153],[-76.296677,42.556099]]]},"properties":{"id":"13102","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.132189,41.930118],[-74.086069,41.975779],[-74.088822,41.98949],[-74.132189,41.930118]]]},"properties":{"id":"12443","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.136086,42.016036],[-74.189997,42.003121],[-74.17655,41.983268],[-74.134791,42.000884],[-74.131335,42.002184],[-74.136086,42.016036]]]},"properties":{"id":"12433","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.376242,41.783313],[-74.342286,41.712953],[-74.332138,41.684801],[-74.303632,41.694589],[-74.266032,41.701427],[-74.255064,41.739181],[-74.285193,41.875262],[-74.355392,41.91307],[-74.369504,41.900569],[-74.376242,41.783313]]]},"properties":{"id":"12446","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.976026,41.994566],[-73.950708,41.987076],[-73.942455,42.030347],[-73.944959,42.044591],[-73.939342,42.060129],[-73.937702,42.081066],[-73.937543,42.103384],[-73.931105,42.102959],[-73.923087,42.118441],[-73.926494,42.133456],[-73.996421,42.17339],[-74.042393,42.170386],[-74.047566,42.158633],[-74.074797,42.096589],[-74.057596,42.031569],[-74.027913,42.005578],[-74.004428,42.018784],[-73.976026,41.994566]],[[-74.001008,42.026673],[-73.999463,42.040253],[-73.989106,42.039254],[-74.001008,42.026673]]]},"properties":{"id":"12477","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.403933,41.666894],[-74.342881,41.678242],[-74.393773,41.692477],[-74.403933,41.666894]]]},"properties":{"id":"12420","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.082194,42.097153],[-74.127883,42.100718],[-74.199078,42.037824],[-74.24959,42.019474],[-74.248835,42.012426],[-74.189997,42.003121],[-74.136086,42.016036],[-74.082194,42.097153]]]},"properties":{"id":"12409","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.241747,41.929773],[-74.348368,41.928268],[-74.355392,41.91307],[-74.285193,41.875262],[-74.231592,41.887494],[-74.192505,41.925327],[-74.180772,41.94908],[-74.240622,41.933091],[-74.241747,41.929773]]]},"properties":{"id":"12461","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.289771,43.577791],[-73.27101,43.489821],[-73.249694,43.438101],[-73.289771,43.577791]]]},"properties":{"id":"12837","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.960463,41.265107],[-73.953691,41.249098],[-73.950506,41.255459],[-73.960463,41.265107]]]},"properties":{"id":"10596","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.818132,40.963585],[-73.79734,40.96411],[-73.771812,40.955677],[-73.757471,40.961611],[-73.744747,40.980429],[-73.744656,40.982445],[-73.763962,40.998468],[-73.785289,41.021519],[-73.824515,41.017293],[-73.843467,40.996169],[-73.845461,40.983509],[-73.823815,40.97759],[-73.818132,40.963585]]]},"properties":{"id":"10583","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.928735,41.26856],[-73.945712,41.295231],[-73.940746,41.272612],[-73.934461,41.268149],[-73.933775,41.268408],[-73.928735,41.26856]]]},"properties":{"id":"10566","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.718264,41.07203],[-73.70389,41.055179],[-73.694681,41.05979],[-73.717737,41.088803],[-73.718264,41.07203]]],[[[-73.754556,41.032708],[-73.724228,41.015908],[-73.717185,41.014191],[-73.718555,41.072022],[-73.754585,41.0952],[-73.766898,41.073993],[-73.758482,41.038637],[-73.754556,41.032708]]]]},"properties":{"id":"10604","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.768399,40.908986],[-73.798193,40.895068],[-73.788297,40.885088],[-73.768399,40.908986]]]},"properties":{"id":"10805","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.904259,40.934894],[-73.910516,40.915282],[-73.886124,40.908016],[-73.878189,40.905983],[-73.877855,40.905922],[-73.870855,40.932371],[-73.904259,40.934894]]]},"properties":{"id":"10705","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.748415,41.118355],[-73.793219,41.114017],[-73.784198,41.096574],[-73.748415,41.118355]]]},"properties":{"id":"10594","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.176431,42.821474],[-78.169754,42.811769],[-78.165159,42.858037],[-78.163365,42.864523],[-78.176431,42.821474]]]},"properties":{"id":"14039","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.15732,42.562794],[-78.162501,42.551427],[-78.145392,42.55133],[-78.133574,42.564883],[-78.15732,42.562794]]]},"properties":{"id":"14130","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.783968,42.626199],[-73.816076,42.594374],[-73.752168,42.56672],[-73.752365,42.57668],[-73.759739,42.61379],[-73.776897,42.62545],[-73.783968,42.626199]]]},"properties":{"id":"12077","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.808225,42.481545],[-73.791862,42.463367],[-73.787484,42.463831],[-73.783721,42.464231],[-73.786128,42.478017],[-73.808225,42.481545]]]},"properties":{"id":"12045","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.941014,42.454704],[-73.920665,42.461466],[-73.915632,42.447295],[-73.886126,42.451507],[-73.896088,42.520261],[-73.932892,42.538291],[-73.968043,42.479013],[-73.96774,42.476311],[-73.941014,42.454704]]]},"properties":{"id":"12046","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.931047,42.797714],[-73.879516,42.740509],[-73.861895,42.761967],[-73.91417,42.807383],[-73.921023,42.804406],[-73.931047,42.797714]]]},"properties":{"id":"12304","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.820366,42.689707],[-73.824572,42.677849],[-73.833268,42.687148],[-73.83074,42.69256],[-73.830238,42.694137],[-73.877814,42.719396],[-73.897925,42.685531],[-73.82808,42.661765],[-73.772078,42.654939],[-73.765392,42.657951],[-73.820366,42.689707]]]},"properties":{"id":"12203","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.201858,42.561837],[-74.216976,42.497958],[-74.177095,42.495301],[-74.102584,42.478223],[-74.116494,42.539842],[-74.201858,42.561837]]]},"properties":{"id":"12147","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.177095,42.495301],[-74.164963,42.418106],[-74.134527,42.411295],[-74.103811,42.408396],[-74.096645,42.452345],[-74.102584,42.478223],[-74.177095,42.495301]]]},"properties":{"id":"12120","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.736485,42.288231],[-77.839235,42.267465],[-77.862305,42.252317],[-77.839024,42.226878],[-77.740245,42.216741],[-77.736485,42.288231]],[[-77.803168,42.25379],[-77.781212,42.26226],[-77.782163,42.242437],[-77.803168,42.25379]]]},"properties":{"id":"14803","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.249724,42.466717],[-78.142451,42.419709],[-78.074199,42.380078],[-78.041816,42.347457],[-77.969416,42.435186],[-77.968373,42.452107],[-78.043418,42.518464],[-78.128068,42.521367],[-78.171078,42.529157],[-78.171247,42.521419],[-78.249653,42.471098],[-78.249724,42.466717]]]},"properties":{"id":"14735","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.074077,42.086204],[-78.126184,42.089328],[-78.138542,42.086561],[-78.157924,42.086665],[-78.153564,42.092597],[-78.146381,42.08952],[-78.208452,42.128094],[-78.248166,42.086524],[-78.220414,42.064708],[-78.167537,42.000015],[-78.121515,42.000389],[-78.06209,42.033784],[-78.054045,42.065697],[-78.074215,42.075552],[-78.074077,42.086204]]]},"properties":{"id":"14715","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.753219,42.348234],[-77.595866,42.407766],[-77.599489,42.437127],[-77.64142,42.477421],[-77.751401,42.47187],[-77.859063,42.368398],[-77.753219,42.348234]]]},"properties":{"id":"14807","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.260153,42.348661],[-78.253191,42.339455],[-78.226348,42.326677],[-78.074199,42.380078],[-78.142451,42.419709],[-78.177044,42.403205],[-78.251153,42.36565],[-78.261372,42.359412],[-78.260153,42.348661]]]},"properties":{"id":"14717","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.955993,42.474325],[-77.944536,42.43536],[-77.840638,42.503237],[-77.840694,42.517771],[-77.955993,42.474325]]]},"properties":{"id":"14884","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.171247,42.521419],[-78.308839,42.521217],[-78.432354,42.490076],[-78.369595,42.436169],[-78.249653,42.471098],[-78.171247,42.521419]]]},"properties":{"id":"14065","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.146381,42.08952],[-78.153564,42.092597],[-78.157924,42.086665],[-78.138542,42.086561],[-78.146381,42.08952]]]},"properties":{"id":"14774","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.861791,40.857983],[-73.842279,40.831267],[-73.824957,40.846105],[-73.828237,40.861021],[-73.861791,40.857983]]]},"properties":{"id":"10461","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.920461,40.830915],[-73.932941,40.828213],[-73.931681,40.807955],[-73.922088,40.813731],[-73.911948,40.820154],[-73.920461,40.830915]]]},"properties":{"id":"10451","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.910516,40.915282],[-73.920956,40.887288],[-73.89571,40.885781],[-73.886124,40.908016],[-73.910516,40.915282]]]},"properties":{"id":"10471","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.065572,42.399169],[-76.215763,42.428237],[-76.23101,42.427987],[-76.269718,42.407158],[-76.278356,42.378114],[-76.079773,42.356833],[-76.065572,42.399169]]]},"properties":{"id":"13835","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.948424,42.094791],[-76.004425,42.233526],[-76.053785,42.20471],[-76.055113,42.200257],[-75.994741,42.114292],[-75.948424,42.094791]]]},"properties":{"id":"13790","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.067906,42.225265],[-76.055113,42.200257],[-76.053785,42.20471],[-76.021647,42.252287],[-76.056226,42.271427],[-76.067906,42.225265]]]},"properties":{"id":"13802","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.779337,42.249305],[-75.733348,42.249324],[-75.756141,42.260052],[-75.779337,42.249305]]],[[[-75.88969,42.237866],[-75.837838,42.201275],[-75.779337,42.249305],[-75.871369,42.36361],[-75.887663,42.27196],[-75.88969,42.237866]]]]},"properties":{"id":"13746","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.620555,42.290181],[-75.605703,42.195486],[-75.468816,42.145666],[-75.419203,42.168053],[-75.618711,42.300316],[-75.620555,42.290181]]]},"properties":{"id":"13730","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.670461,42.346636],[-78.622726,42.333261],[-78.554323,42.444312],[-78.583417,42.465472],[-78.728576,42.455063],[-78.670461,42.346636]]]},"properties":{"id":"14171","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.492041,42.082796],[-78.485822,42.073299],[-78.480342,42.08158],[-78.492041,42.082796]]]},"properties":{"id":"14778","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.55983,42.513278],[-78.706067,42.591848],[-78.783894,42.589972],[-78.783784,42.584161],[-78.797883,42.554638],[-78.81049,42.511454],[-78.779668,42.465595],[-78.777603,42.461882],[-78.728576,42.455063],[-78.583417,42.465472],[-78.559836,42.509369],[-78.55983,42.513278]]]},"properties":{"id":"14141","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.386191,42.062831],[-78.376529,42.057616],[-78.375064,42.062777],[-78.379222,42.066015],[-78.386191,42.062831]]]},"properties":{"id":"14788","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.628272,42.78229],[-76.716553,42.784483],[-76.668641,42.678084],[-76.595275,42.724489],[-76.628272,42.78229]]]},"properties":{"id":"13026","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.716553,42.784483],[-76.628272,42.78229],[-76.599479,42.83758],[-76.633575,42.869391],[-76.698777,42.844961],[-76.716553,42.784483]]]},"properties":{"id":"13160","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.595275,42.724489],[-76.586788,42.710231],[-76.500135,42.713106],[-76.516577,42.811031],[-76.599479,42.83758],[-76.628272,42.78229],[-76.595275,42.724489]]]},"properties":{"id":"13147","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.356281,42.050158],[-79.353371,42.100845],[-79.431788,42.171175],[-79.481197,42.128623],[-79.517498,42.098584],[-79.411175,41.998613],[-79.351147,41.998687],[-79.356281,42.050158]]]},"properties":{"id":"14710","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.319615,42.282157],[-79.210412,42.348687],[-79.222615,42.380791],[-79.354606,42.363374],[-79.319615,42.282157]],[[-79.32694,42.350237],[-79.320887,42.355552],[-79.310663,42.349346],[-79.32694,42.350237]]]},"properties":{"id":"14718","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.080904,42.512687],[-79.137873,42.570462],[-79.249818,42.537275],[-79.226157,42.501498],[-79.236757,42.484429],[-79.080904,42.512687]]]},"properties":{"id":"14136","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.925449,42.145741],[-76.810646,42.136122],[-76.756107,42.163698],[-76.729177,42.222858],[-76.729688,42.232012],[-76.764835,42.294297],[-76.78797,42.294339],[-76.81329,42.29412],[-76.823463,42.29402],[-76.838264,42.229404],[-76.901381,42.270109],[-76.96535,42.223288],[-76.965433,42.192226],[-76.925449,42.145741]]]},"properties":{"id":"14845","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.840271,42.072777],[-76.888734,42.093023],[-76.925877,42.096461],[-77.022131,42.017187],[-77.021036,42.000494],[-76.787677,42.001573],[-76.781825,42.050539],[-76.840271,42.072777]]]},"properties":{"id":"14871","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.801985,42.596707],[-75.820128,42.580321],[-75.86968,42.490218],[-75.867514,42.45992],[-75.772834,42.42283],[-75.748972,42.453051],[-75.67656,42.532348],[-75.700643,42.58357],[-75.768483,42.609511],[-75.801985,42.596707]]]},"properties":{"id":"13801","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.304369,42.569065],[-75.201163,42.577566],[-75.208783,42.627655],[-75.212337,42.646164],[-75.300725,42.715234],[-75.370166,42.740376],[-75.419015,42.641573],[-75.304369,42.569065]]]},"properties":{"id":"13411","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.748205,44.442813],[-73.657762,44.343483],[-73.614453,44.454386],[-73.59475,44.48978],[-73.704821,44.527132],[-73.861308,44.518345],[-73.936169,44.512712],[-73.936128,44.501738],[-73.909687,44.429699],[-73.748205,44.442813]]]},"properties":{"id":"12912","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.849399,44.889928],[-73.846977,44.890024],[-73.842859,44.891751],[-73.845742,44.892046],[-73.849399,44.889928]]]},"properties":{"id":"12933","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.350162,42.077203],[-75.168428,41.981645],[-75.090905,42.102521],[-75.021117,42.131357],[-75.025587,42.26601],[-75.063186,42.323144],[-75.167239,42.27086],[-75.275124,42.206553],[-75.275627,42.202645],[-75.278362,42.188049],[-75.305959,42.201519],[-75.326136,42.200937],[-75.337984,42.205056],[-75.340545,42.185974],[-75.350162,42.077203]]]},"properties":{"id":"13856","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.591997,42.222896],[-74.585937,42.175206],[-74.48606,42.270902],[-74.486145,42.291726],[-74.591997,42.222896]]]},"properties":{"id":"12421","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.440799,42.219997],[-74.48606,42.270902],[-74.585937,42.175206],[-74.583337,42.168757],[-74.49673,42.148278],[-74.470022,42.171911],[-74.450322,42.168698],[-74.440799,42.219997]]]},"properties":{"id":"12430","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.231103,42.325773],[-75.260565,42.35241],[-75.245245,42.366474],[-75.253796,42.372575],[-75.243307,42.379478],[-75.303762,42.447898],[-75.36057,42.405347],[-75.380064,42.345784],[-75.387441,42.327644],[-75.362995,42.284421],[-75.337984,42.205056],[-75.326136,42.200937],[-75.231103,42.325773]]]},"properties":{"id":"13849","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.83196,42.230249],[-74.967022,42.21926],[-74.944055,42.094413],[-74.869046,42.079167],[-74.831773,42.218638],[-74.83196,42.230249]]]},"properties":{"id":"13752","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.381162,42.243804],[-75.419203,42.168053],[-75.340545,42.185974],[-75.337984,42.205056],[-75.362995,42.284421],[-75.381162,42.243804]]]},"properties":{"id":"13804","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.211835,42.495969],[-75.300899,42.472867],[-75.320448,42.46239],[-75.303762,42.447898],[-75.243307,42.379478],[-75.245245,42.366474],[-75.260565,42.35241],[-75.151783,42.383125],[-75.122597,42.470142],[-75.120142,42.471039],[-75.120891,42.472257],[-75.182299,42.513404],[-75.211835,42.495969]]]},"properties":{"id":"13825","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.367572,42.332634],[-74.362976,42.36728],[-74.443506,42.355017],[-74.486613,42.333588],[-74.486145,42.291726],[-74.48606,42.270902],[-74.440799,42.219997],[-74.376285,42.245934],[-74.372574,42.285834],[-74.371034,42.300291],[-74.367572,42.332634]]]},"properties":{"id":"12468","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.979256,41.534307],[-73.950439,41.529368],[-73.86648,41.560405],[-73.789078,41.629987],[-73.78932,41.636991],[-73.915789,41.616448],[-73.946521,41.615536],[-73.96923,41.555074],[-73.966351,41.545242],[-73.975036,41.544295],[-73.979256,41.534307]]]},"properties":{"id":"12590","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.663758,41.588457],[-73.621968,41.521831],[-73.530067,41.527194],[-73.521435,41.616612],[-73.637586,41.63627],[-73.663758,41.588457]]]},"properties":{"id":"12564","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.928439,41.872921],[-73.795698,41.884126],[-73.785534,41.938481],[-73.936439,41.979591],[-73.949569,41.925142],[-73.936453,41.913563],[-73.954833,41.913563],[-73.928439,41.872921]]]},"properties":{"id":"12572","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.982035,42.540604],[-78.943952,42.516789],[-78.81049,42.511454],[-78.797883,42.554638],[-78.980232,42.566705],[-78.982035,42.540604]]]},"properties":{"id":"14091","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.578048,42.954758],[-78.56252,42.86806],[-78.555852,42.856568],[-78.55567,42.850823],[-78.537002,42.841894],[-78.486799,42.832556],[-78.432237,42.840833],[-78.410015,42.868124],[-78.463856,42.900808],[-78.464249,42.948486],[-78.552723,42.963916],[-78.578048,42.954758]]]},"properties":{"id":"14004","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.909873,42.974633],[-78.873547,42.989692],[-78.822967,42.979692],[-78.822389,43.001374],[-78.828805,43.030139],[-78.886467,43.022311],[-78.91245,42.956248],[-78.909873,42.974633]]]},"properties":{"id":"14150","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.778911,42.804084],[-78.696915,42.811166],[-78.696956,42.860034],[-78.701222,42.863759],[-78.792349,42.871571],[-78.80008,42.864844],[-78.795157,42.841135],[-78.79363,42.828632],[-78.778911,42.804084]]]},"properties":{"id":"14224","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.86252,42.902163],[-78.859427,42.855819],[-78.853918,42.881845],[-78.853394,42.887817],[-78.853486,42.893154],[-78.852752,42.90382],[-78.859741,42.903909],[-78.86252,42.902163]]]},"properties":{"id":"14204","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.80008,42.864844],[-78.853918,42.881845],[-78.859427,42.855819],[-78.848784,42.859464],[-78.795157,42.841135],[-78.80008,42.864844]]]},"properties":{"id":"14210","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.665681,42.62585],[-78.654381,42.58825],[-78.609888,42.584062],[-78.623453,42.64289],[-78.665681,42.62585]]]},"properties":{"id":"14069","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.873547,42.989692],[-78.853259,42.958628],[-78.837037,42.958384],[-78.82384,42.958126],[-78.822967,42.979692],[-78.873547,42.989692]]]},"properties":{"id":"14223","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.873547,42.989692],[-78.909873,42.974633],[-78.889249,42.958572],[-78.853259,42.958628],[-78.873547,42.989692]]]},"properties":{"id":"14217","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.432237,42.840833],[-78.486799,42.832556],[-78.463233,42.780461],[-78.39775,42.791853],[-78.432237,42.840833]]]},"properties":{"id":"14037","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.426012,43.031636],[-78.336159,43.025363],[-78.330211,43.043781],[-78.340512,43.130803],[-78.419095,43.130341],[-78.464962,43.117341],[-78.426012,43.031636]]]},"properties":{"id":"14013","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.530227,42.76814],[-78.525126,42.762985],[-78.518665,42.766562],[-78.530227,42.76814]]]},"properties":{"id":"14169","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.731268,43.880918],[-73.726167,43.873212],[-73.715072,43.877933],[-73.731268,43.880918]]]},"properties":{"id":"12872","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.056991,44.288087],[-74.120871,44.297775],[-74.115775,44.261279],[-74.056991,44.288087]]]},"properties":{"id":"12977","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.536434,44.209729],[-73.701172,44.1734],[-73.687628,44.094553],[-73.629872,44.098484],[-73.55069,44.106455],[-73.52883,44.196792],[-73.536434,44.209729]]]},"properties":{"id":"12964","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.61757,43.989401],[-73.641899,43.9325],[-73.634234,43.914818],[-73.625254,43.883659],[-73.412956,43.92253],[-73.452188,44.005693],[-73.472961,44.004455],[-73.61757,43.989401]]]},"properties":{"id":"12928","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.725679,44.990797],[-74.690758,44.95291],[-74.571982,44.967286],[-74.571636,44.998872],[-74.725679,44.990797]]]},"properties":{"id":"13655","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.722055,44.925533],[-74.63121,44.875391],[-74.506194,44.930947],[-74.571982,44.967286],[-74.690758,44.95291],[-74.722055,44.925533]]]},"properties":{"id":"12914","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.330936,44.551057],[-74.297642,44.499909],[-74.280075,44.548896],[-74.330936,44.551057]]],[[[-74.471892,44.684761],[-74.346597,44.670952],[-74.350123,44.693162],[-74.471892,44.684761]]],[[[-74.288093,44.488016],[-74.435865,44.412008],[-74.313435,44.415057],[-74.258348,44.407263],[-74.191989,44.410528],[-74.178012,44.444245],[-74.288093,44.488016]]]]},"properties":{"id":"12970","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.052561,43.007276],[-74.082805,43.026224],[-74.10203,43.011771],[-74.052561,43.007276]]]},"properties":{"id":"12086","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.30805,42.902696],[-78.191396,42.870336],[-78.192369,42.913796],[-78.30271,42.950046],[-78.34617,42.923679],[-78.30805,42.902696]]]},"properties":{"id":"14005","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.117019,42.881337],[-78.165159,42.858037],[-78.169754,42.811769],[-78.074237,42.79156],[-78.026769,42.801786],[-78.117019,42.881337]]]},"properties":{"id":"14591","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.803156,42.433517],[-73.783625,42.43136],[-73.787484,42.463831],[-73.791862,42.463367],[-73.808021,42.46165],[-73.805843,42.440742],[-73.803156,42.433517]]]},"properties":{"id":"12124","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.31283,43.720416],[-74.303082,43.727239],[-74.298868,43.734054],[-74.309925,43.725087],[-74.31283,43.720416]]]},"properties":{"id":"12864","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.11016,43.615229],[-75.225253,43.562204],[-75.169369,43.542009],[-75.106727,43.585618],[-75.11016,43.615229]]],[[[-75.201375,43.410806],[-75.020388,43.377276],[-74.79239,43.460043],[-74.797795,43.653398],[-75.101669,43.541097],[-75.215217,43.498636],[-75.229227,43.424145],[-75.201375,43.410806]]]]},"properties":{"id":"13338","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.972755,43.130061],[-74.867885,43.154493],[-74.973276,43.130023],[-74.972755,43.130061]]]},"properties":{"id":"13406","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.160045,42.952614],[-75.188095,42.996576],[-75.255749,42.961721],[-75.20649,42.931426],[-75.160045,42.952614]]]},"properties":{"id":"13322","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.295807,44.046057],[-76.203449,44.073916],[-76.212253,44.105193],[-76.295807,44.046057]]]},"properties":{"id":"13693","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.738771,44.027866],[-75.735638,44.012294],[-75.737644,44.027905],[-75.738771,44.027866]]]},"properties":{"id":"13638","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.067267,44.140696],[-76.188604,44.132401],[-76.212253,44.105193],[-76.203449,44.073916],[-76.122263,44.038085],[-76.01415,44.071396],[-76.026179,44.111887],[-76.067267,44.140696]]]},"properties":{"id":"13622","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.737644,44.027905],[-75.735638,44.012294],[-75.80491,43.953947],[-75.741578,43.919372],[-75.590244,43.903513],[-75.568909,43.93137],[-75.431689,43.982736],[-75.5498,44.054734],[-75.667765,44.041217],[-75.737644,44.027905]],[[-75.715916,44.032786],[-75.717338,44.029474],[-75.719275,44.033724],[-75.715916,44.032786]],[[-75.680246,44.027123],[-75.685304,44.028998],[-75.683412,44.032615],[-75.6767,44.029831],[-75.680246,44.027123]]]},"properties":{"id":"13619","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.142662,43.728605],[-76.118287,43.739168],[-76.089194,43.730465],[-76.098087,43.759119],[-76.124507,43.761061],[-76.142662,43.728605]]]},"properties":{"id":"13636","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.832299,44.287766],[-75.628517,44.299102],[-75.61981,44.302183],[-75.61964,44.367424],[-75.803198,44.403481],[-75.867512,44.369818],[-75.88995,44.29449],[-75.8617,44.279057],[-75.832299,44.287766]]]},"properties":{"id":"13679","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.001017,44.280376],[-76.012545,44.266691],[-76.000254,44.272759],[-76.001017,44.280376]]]},"properties":{"id":"13641","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.019449,44.285977],[-76.024121,44.295571],[-76.031189,44.290246],[-76.019449,44.285977]]]},"properties":{"id":"13692","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.972994,40.608814],[-73.987255,40.607245],[-73.982812,40.583792],[-73.966614,40.584575],[-73.958998,40.591784],[-73.96238,40.609986],[-73.972994,40.608814]]]},"properties":{"id":"11223","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.927588,40.701762],[-73.945642,40.692033],[-73.938223,40.683894],[-73.916046,40.686072],[-73.908853,40.693405],[-73.927588,40.701762]]]},"properties":{"id":"11221","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.922207,40.641065],[-73.921776,40.654424],[-73.931607,40.663557],[-73.940029,40.663021],[-73.947264,40.656437],[-73.946509,40.640104],[-73.934305,40.633886],[-73.922207,40.641065]]]},"properties":{"id":"11203","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.993985,40.647101],[-73.998272,40.671153],[-74.024406,40.650922],[-73.997734,40.64341],[-73.993985,40.647101]]]},"properties":{"id":"11232","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.958998,40.591784],[-73.926104,40.584825],[-73.944827,40.616046],[-73.952577,40.611037],[-73.96238,40.609986],[-73.958998,40.591784]]]},"properties":{"id":"11229","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.961611,40.724951],[-73.929223,40.727849],[-73.962795,40.737674],[-73.961611,40.724951]]]},"properties":{"id":"11222","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.931607,40.663557],[-73.921776,40.654424],[-73.899041,40.657372],[-73.903475,40.675507],[-73.924244,40.666445],[-73.931607,40.663557]]]},"properties":{"id":"11212","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.899041,40.657372],[-73.889994,40.651138],[-73.878436,40.649327],[-73.889575,40.68418],[-73.902128,40.691843],[-73.908853,40.693405],[-73.916046,40.686072],[-73.903475,40.675507],[-73.899041,40.657372]]]},"properties":{"id":"11207","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.923734,40.716245],[-73.934802,40.713373],[-73.927588,40.701762],[-73.908853,40.693405],[-73.902128,40.691843],[-73.92392,40.714074],[-73.923734,40.716245]]]},"properties":{"id":"11237","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.016048,40.631033],[-74.024694,40.60996],[-74.021502,40.607828],[-74.01945,40.602828],[-74.014775,40.602014],[-74.000824,40.616314],[-73.997285,40.619712],[-74.012142,40.628684],[-74.016048,40.631033]]]},"properties":{"id":"11228","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.567227,43.656081],[-75.674873,43.651977],[-75.663693,43.645833],[-75.581467,43.477781],[-75.467092,43.517159],[-75.384474,43.580683],[-75.567227,43.656081]]]},"properties":{"id":"13325","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.225253,43.562204],[-75.383105,43.582143],[-75.384474,43.580683],[-75.467092,43.517159],[-75.462224,43.453036],[-75.394931,43.34933],[-75.247717,43.400493],[-75.238807,43.404832],[-75.229227,43.424145],[-75.215217,43.498636],[-75.169369,43.542009],[-75.225253,43.562204]]]},"properties":{"id":"13309","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.710411,42.82341],[-77.715993,42.823388],[-77.711766,42.819689],[-77.710411,42.82341]]],[[[-77.705428,42.855319],[-77.695498,42.829622],[-77.712314,42.763608],[-77.645738,42.771284],[-77.532579,42.789532],[-77.533065,42.825545],[-77.533081,42.826536],[-77.671059,42.865608],[-77.705428,42.855319]]]]},"properties":{"id":"14487","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.48998,42.641962],[-77.634755,42.627492],[-77.631899,42.542648],[-77.634512,42.536507],[-77.649414,42.54202],[-77.64142,42.477421],[-77.599489,42.437127],[-77.501599,42.552003],[-77.473903,42.577092],[-77.48998,42.641962]]]},"properties":{"id":"14572","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.956334,42.667322],[-77.852765,42.786599],[-77.856891,42.794249],[-77.955355,42.810524],[-77.955567,42.800477],[-77.956334,42.667322]]]},"properties":{"id":"14481","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.920992,42.884992],[-77.880723,42.903933],[-77.919921,42.912802],[-77.955455,42.901193],[-77.920992,42.884992]]]},"properties":{"id":"14486","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.687888,42.968185],[-75.68749,42.964856],[-75.68172,42.965144],[-75.683957,42.967376],[-75.687888,42.968185]]]},"properties":{"id":"13134","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.677632,42.859755],[-75.642296,42.851818],[-75.643213,42.880853],[-75.677632,42.859755]]]},"properties":{"id":"13484","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.582098,43.175657],[-77.576939,43.197937],[-77.577021,43.199045],[-77.611139,43.249121],[-77.626685,43.205991],[-77.620382,43.192635],[-77.628201,43.181017],[-77.626145,43.173244],[-77.582098,43.175657]]]},"properties":{"id":"14621","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.480912,43.123663],[-77.471687,43.111198],[-77.400514,43.166717],[-77.494292,43.175565],[-77.480912,43.123663]]]},"properties":{"id":"14526","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.620232,43.165805],[-77.609346,43.151139],[-77.613103,43.160629],[-77.620232,43.165805]]]},"properties":{"id":"14614","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.671353,43.238346],[-77.63027,43.224926],[-77.634827,43.252859],[-77.694393,43.238451],[-77.671353,43.238346]]]},"properties":{"id":"14616","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.085579,43.3733],[-78.095522,43.29888],[-78.075674,43.299393],[-77.980447,43.308935],[-77.996748,43.3652],[-78.085579,43.3733]]]},"properties":{"id":"14476","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.623908,43.131912],[-77.63256,43.130984],[-77.630184,43.123226],[-77.623908,43.131912]]]},"properties":{"id":"14627","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.492388,42.896262],[-74.356274,42.835806],[-74.262926,42.831569],[-74.314345,42.933458],[-74.473437,42.897793],[-74.492388,42.896262]]]},"properties":{"id":"12072","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.492388,42.896262],[-74.495956,42.788435],[-74.476343,42.781074],[-74.427902,42.77268],[-74.411384,42.77649],[-74.356274,42.835806],[-74.492388,42.896262]]]},"properties":{"id":"12166","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.448763,40.804999],[-73.487375,40.795092],[-73.50367,40.787862],[-73.500899,40.767567],[-73.468748,40.761599],[-73.444096,40.781606],[-73.448747,40.804901],[-73.448763,40.804999]]]},"properties":{"id":"11803","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.500899,40.767567],[-73.50367,40.787862],[-73.517451,40.784579],[-73.551026,40.77361],[-73.54569,40.743582],[-73.503875,40.743068],[-73.500899,40.767567]]]},"properties":{"id":"11801","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.487375,40.795092],[-73.448763,40.804999],[-73.454671,40.835908],[-73.455261,40.840872],[-73.487375,40.795092]]]},"properties":{"id":"11797","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.728305,40.665917],[-73.689896,40.657963],[-73.680542,40.668711],[-73.67979,40.683557],[-73.686636,40.695123],[-73.725956,40.685682],[-73.725861,40.683223],[-73.728305,40.665917]]]},"properties":{"id":"11580","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.527671,40.631802],[-73.560088,40.699066],[-73.575946,40.694497],[-73.575052,40.690846],[-73.569148,40.668855],[-73.559729,40.628886],[-73.527671,40.631802]]]},"properties":{"id":"11566","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.548644,40.902962],[-73.528378,40.914936],[-73.590862,40.909295],[-73.57199,40.895195],[-73.548644,40.902962]]]},"properties":{"id":"11709","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.707283,40.785434],[-73.711345,40.792164],[-73.74958,40.784666],[-73.749575,40.781898],[-73.73376,40.772469],[-73.707283,40.785434]]]},"properties":{"id":"11021","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.71313,40.806639],[-73.75358,40.797692],[-73.74958,40.784666],[-73.711345,40.792164],[-73.712253,40.806284],[-73.71313,40.806639]]]},"properties":{"id":"11023","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.651787,40.834987],[-73.652531,40.828725],[-73.652058,40.827595],[-73.639274,40.828133],[-73.632503,40.837168],[-73.651787,40.834987]]]},"properties":{"id":"11547","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.73815,40.594229],[-73.707045,40.583739],[-73.70613,40.590958],[-73.73815,40.594229]]]},"properties":{"id":"11509","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.012894,40.71719],[-74.004533,40.71628],[-74.002115,40.715159],[-74.000455,40.714365],[-73.997532,40.714023],[-73.994035,40.720323],[-74.00373,40.72625],[-74.011978,40.724333],[-74.013046,40.719086],[-74.012894,40.71719]]]},"properties":{"id":"10013","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.930891,40.794041],[-73.946462,40.800665],[-73.94922,40.79691],[-73.955778,40.787914],[-73.943154,40.782531],[-73.930891,40.794041]]]},"properties":{"id":"10029","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.956126,40.760401],[-73.959263,40.759284],[-73.958599,40.758133],[-73.956126,40.760401]]],[[[-73.951858,40.769441],[-73.965148,40.775045],[-73.968441,40.770517],[-73.952085,40.764474],[-73.947973,40.769512],[-73.94827,40.769505],[-73.949266,40.768355],[-73.951858,40.769441]]]]},"properties":{"id":"10021","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.000954,40.731706],[-74.009331,40.742572],[-74.011978,40.724333],[-74.00373,40.72625],[-74.000954,40.731706]]]},"properties":{"id":"10014","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.015905,40.706658],[-74.012508,40.706767],[-74.011248,40.708304],[-74.010846,40.708797],[-74.010542,40.709154],[-74.009988,40.709806],[-74.013754,40.71369],[-74.015905,40.706658]]]},"properties":{"id":"10006","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.980426,40.759899],[-73.97845,40.759065],[-73.977989,40.759689],[-73.980426,40.759899]]],[[[-73.980886,40.75927],[-73.981341,40.758645],[-73.978116,40.757284],[-73.977655,40.757912],[-73.977201,40.758538],[-73.97845,40.759065],[-73.980886,40.75927]]]]},"properties":{"id":"10020","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.004533,40.71628],[-74.00542,40.715236],[-74.003115,40.714082],[-74.002115,40.715159],[-74.004533,40.71628]]]},"properties":{"id":"10278","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.017368,40.713378],[-74.015531,40.700273],[-74.015905,40.706658],[-74.013754,40.71369],[-74.017368,40.713378]]]},"properties":{"id":"10280","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.973735,40.754619],[-73.973276,40.755243],[-73.974897,40.755928],[-73.975352,40.755303],[-73.973735,40.754619]]]},"properties":{"id":"10172","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975831,40.760418],[-73.979057,40.761771],[-73.979513,40.761148],[-73.976289,40.759787],[-73.975831,40.760418]]]},"properties":{"id":"10103","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.980888,40.753481],[-73.980395,40.754155],[-73.983624,40.755516],[-73.984118,40.754842],[-73.980888,40.753481]]]},"properties":{"id":"10110","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.060653,43.096026],[-79.01084,43.09382],[-79.010708,43.093901],[-79.060653,43.096026]]]},"properties":{"id":"14301","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.699478,43.346933],[-78.667745,43.294921],[-78.651103,43.257608],[-78.620206,43.265441],[-78.567637,43.304686],[-78.624751,43.360708],[-78.699478,43.346933]]]},"properties":{"id":"14008","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.304177,42.900487],[-75.242745,42.877869],[-75.20649,42.931426],[-75.255749,42.961721],[-75.293276,42.960166],[-75.304177,42.900487]]]},"properties":{"id":"13318","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.486497,43.121785],[-75.436612,43.079889],[-75.387357,43.0991],[-75.378127,43.123778],[-75.407907,43.158168],[-75.486497,43.121785]]]},"properties":{"id":"13490","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.584445,43.078139],[-75.611328,43.08179],[-75.616032,43.066269],[-75.585812,43.059244],[-75.583287,43.074293],[-75.584445,43.078139]]]},"properties":{"id":"13461","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.083792,43.058295],[-76.093698,43.092221],[-76.116747,43.092687],[-76.124999,43.072959],[-76.121868,43.053298],[-76.115595,43.054776],[-76.083792,43.058295]]]},"properties":{"id":"13206","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.066432,43.01692],[-76.092496,43.0308],[-76.109484,43.005509],[-76.124451,43.007835],[-76.133804,42.987623],[-76.135357,42.957882],[-76.015831,42.900423],[-76.01196,42.899357],[-75.995482,42.927108],[-76.023981,42.991839],[-76.066432,43.01692]]]},"properties":{"id":"13078","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.243279,43.052833],[-76.260774,43.079447],[-76.346641,43.080847],[-76.375858,43.067491],[-76.349717,43.017959],[-76.290565,43.014295],[-76.258014,43.029909],[-76.243279,43.052833]]]},"properties":{"id":"13031","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.093698,43.092221],[-76.083792,43.058295],[-76.083201,43.05552],[-76.051856,43.043368],[-76.006698,43.048986],[-76.001673,43.065795],[-76.017443,43.06527],[-76.021051,43.083027],[-76.001177,43.083555],[-75.983659,43.137319],[-76.016851,43.141061],[-76.082603,43.136254],[-76.094038,43.120461],[-76.093698,43.092221]]]},"properties":{"id":"13057","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.177628,43.078474],[-76.178592,43.077553],[-76.177961,43.067549],[-76.168207,43.069491],[-76.177628,43.078474]]]},"properties":{"id":"13290","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.167039,43.107764],[-77.147036,43.039865],[-77.098005,42.986343],[-77.033222,43.012781],[-77.065253,43.140762],[-77.085031,43.161666],[-77.167039,43.107764]]]},"properties":{"id":"14513","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.32999,41.336352],[-74.256875,41.277583],[-74.204828,41.397477],[-74.212443,41.415934],[-74.251309,41.403777],[-74.329036,41.346746],[-74.32999,41.336352]]]},"properties":{"id":"10918","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.416956,41.533479],[-74.40614,41.500934],[-74.399331,41.543086],[-74.416956,41.533479]]],[[[-74.405115,41.499675],[-74.356605,41.431963],[-74.32285,41.438849],[-74.287181,41.465552],[-74.351593,41.540562],[-74.356145,41.537684],[-74.405115,41.499675]]]]},"properties":{"id":"10941","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.473132,41.255276],[-74.341821,41.19186],[-74.288076,41.236885],[-74.256875,41.277583],[-74.32999,41.336352],[-74.399099,41.31147],[-74.438384,41.317736],[-74.473132,41.255276]]]},"properties":{"id":"10990","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.071405,41.408883],[-74.068191,41.383493],[-73.994382,41.415688],[-74.027264,41.42159],[-74.02079,41.452493],[-74.022584,41.454293],[-74.073218,41.409596],[-74.071405,41.408883]]]},"properties":{"id":"12518","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.118584,41.46477],[-74.111271,41.396779],[-74.097262,41.388669],[-74.073218,41.409596],[-74.022584,41.454293],[-74.008981,41.488125],[-74.116379,41.505869],[-74.134231,41.501344],[-74.118584,41.46477]]]},"properties":{"id":"12553","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.13917,41.329846],[-74.137749,41.313632],[-74.098103,41.319812],[-74.101887,41.338003],[-74.13917,41.329846]]]},"properties":{"id":"10917","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.23607,41.588672],[-74.150744,41.525736],[-74.129831,41.546727],[-74.102581,41.576308],[-74.236555,41.601846],[-74.23607,41.588672]]]},"properties":{"id":"12586","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.014966,41.317305],[-73.988305,41.320021],[-73.986746,41.320963],[-73.969102,41.344797],[-74.001472,41.342053],[-74.014966,41.317305]]]},"properties":{"id":"10922","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.194521,43.372104],[-76.116831,43.322807],[-76.110091,43.329356],[-76.118058,43.374637],[-76.194521,43.372104]]]},"properties":{"id":"13076","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.116831,43.322807],[-76.109505,43.318487],[-76.108076,43.321587],[-76.110091,43.329356],[-76.116831,43.322807]]]},"properties":{"id":"13103","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.472706,43.397555],[-76.476771,43.40545],[-76.489097,43.405075],[-76.474173,43.393297],[-76.471526,43.395829],[-76.472706,43.397555]]]},"properties":{"id":"13115","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.932491,42.590968],[-75.016428,42.659588],[-75.054615,42.61496],[-75.04854,42.594465],[-74.969457,42.522654],[-74.967692,42.528544],[-74.960491,42.54176],[-74.954259,42.544146],[-74.932491,42.590968]]]},"properties":{"id":"13807","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.054615,42.61496],[-75.117463,42.671795],[-75.16976,42.642994],[-75.196449,42.607356],[-75.184398,42.588173],[-75.181169,42.572945],[-75.073831,42.547884],[-75.04854,42.594465],[-75.054615,42.61496]]]},"properties":{"id":"13810","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.711478,42.689392],[-74.7708,42.67436],[-74.772888,42.505169],[-74.707573,42.522675],[-74.670576,42.566948],[-74.638844,42.661558],[-74.643407,42.675906],[-74.649074,42.694348],[-74.711478,42.689392]]]},"properties":{"id":"12197","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.243307,42.379478],[-75.253796,42.372575],[-75.245245,42.366474],[-75.243307,42.379478]]]},"properties":{"id":"13859","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.201163,42.577566],[-75.184398,42.588173],[-75.196449,42.607356],[-75.208783,42.627655],[-75.201163,42.577566]]]},"properties":{"id":"13415","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.892449,41.343747],[-73.89346,41.330892],[-73.875119,41.332785],[-73.892449,41.343747]]]},"properties":{"id":"10537","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.748643,40.701587],[-73.727707,40.701565],[-73.729176,40.719167],[-73.729661,40.720483],[-73.753708,40.713325],[-73.753356,40.710101],[-73.748643,40.701587]]]},"properties":{"id":"11429","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.713034,40.759566],[-73.73376,40.772469],[-73.753274,40.765551],[-73.7537,40.76529],[-73.758001,40.76038],[-73.740021,40.738712],[-73.732335,40.743576],[-73.722917,40.754098],[-73.721178,40.756871],[-73.713034,40.759566]]]},"properties":{"id":"11362","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.860878,40.763206],[-73.872219,40.756943],[-73.869425,40.749157],[-73.864618,40.734271],[-73.853392,40.73694],[-73.845319,40.740048],[-73.835902,40.743327],[-73.838002,40.754438],[-73.839167,40.765357],[-73.860274,40.763444],[-73.860878,40.763206]]]},"properties":{"id":"11368","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.753356,40.710101],[-73.775465,40.705319],[-73.769895,40.696359],[-73.762008,40.684551],[-73.748123,40.690131],[-73.748643,40.701587],[-73.753356,40.710101]]]},"properties":{"id":"11412","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.861318,40.675021],[-73.856652,40.650129],[-73.82641,40.648261],[-73.832644,40.665184],[-73.832906,40.666651],[-73.861318,40.675021]]]},"properties":{"id":"11414","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.73222,40.685121],[-73.748123,40.690131],[-73.762008,40.684551],[-73.767353,40.65616],[-73.753214,40.648278],[-73.73222,40.685121]]]},"properties":{"id":"11413","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.835111,40.685088],[-73.837544,40.691364],[-73.844307,40.689442],[-73.866295,40.683189],[-73.862346,40.679165],[-73.835111,40.685088]]]},"properties":{"id":"11416","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.665759,42.833373],[-73.676762,42.783277],[-73.683269,42.767949],[-73.573863,42.809352],[-73.665759,42.833373]]]},"properties":{"id":"12182","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.417011,42.821285],[-73.465536,42.846341],[-73.507722,42.82185],[-73.465028,42.815794],[-73.417011,42.821285]]],[[[-73.475336,42.976674],[-73.505037,42.982359],[-73.465709,42.84687],[-73.449578,42.872233],[-73.475336,42.976674]]]]},"properties":{"id":"12094","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.520172,42.819411],[-73.573863,42.809352],[-73.683269,42.767949],[-73.684362,42.760778],[-73.694662,42.732078],[-73.703463,42.705578],[-73.724964,42.670179],[-73.685195,42.666802],[-73.60008,42.704408],[-73.542359,42.711678],[-73.470787,42.800624],[-73.465028,42.815794],[-73.507722,42.82185],[-73.520172,42.819411]]]},"properties":{"id":"12180","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.625161,42.559763],[-73.65561,42.569907],[-73.658056,42.557671],[-73.625161,42.559763]]]},"properties":{"id":"12063","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.404188,42.998235],[-73.404033,42.925978],[-73.274294,42.943652],[-73.270703,43.017565],[-73.404188,42.998235]]]},"properties":{"id":"12057","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.169611,40.566443],[-74.114871,40.602137],[-74.119669,40.621745],[-74.13061,40.623495],[-74.14612,40.62493],[-74.195988,40.635172],[-74.212995,40.558325],[-74.206392,40.558102],[-74.169611,40.566443]],[[-74.179175,40.607867],[-74.176551,40.605863],[-74.180413,40.602927],[-74.182464,40.60421],[-74.179175,40.607867]]]},"properties":{"id":"10314","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.927845,41.033763],[-73.91383,41.037451],[-73.907893,41.038402],[-73.918846,41.05189],[-73.921988,41.041414],[-73.927845,41.033763]]]},"properties":{"id":"10968","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.034511,41.076095],[-74.050852,41.063458],[-73.999781,41.040835],[-73.981987,41.05451],[-73.985482,41.060395],[-73.994882,41.070847],[-73.99669,41.07403],[-74.034511,41.076095]]]},"properties":{"id":"10965","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.06755,41.161498],[-74.112378,41.090734],[-74.073586,41.073597],[-74.06755,41.161498]]]},"properties":{"id":"10952","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.990659,41.214996],[-73.964116,41.220899],[-73.976422,41.243841],[-74.02668,41.29283],[-74.131339,41.21756],[-74.124651,41.201898],[-74.029543,41.204462],[-74.00406,41.217788],[-73.990659,41.214996]]]},"properties":{"id":"10980","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.907089,41.143037],[-73.964282,41.122495],[-73.946428,41.100603],[-73.906101,41.13509],[-73.907089,41.143037]]]},"properties":{"id":"10989","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.989872,41.192448],[-73.943808,41.175434],[-73.939727,41.176497],[-73.962003,41.20499],[-73.98338,41.200932],[-73.989872,41.192448]]]},"properties":{"id":"10927","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.356307,44.788596],[-75.645083,44.596353],[-75.631663,44.581686],[-75.648884,44.573569],[-75.657574,44.583332],[-75.715063,44.543154],[-75.658186,44.516725],[-75.546416,44.552965],[-75.334113,44.677932],[-75.356307,44.788596]]]},"properties":{"id":"13669","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.732497,44.528949],[-75.658186,44.516725],[-75.715063,44.543154],[-75.732497,44.528949]]]},"properties":{"id":"13614","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.298959,44.431745],[-75.423711,44.46968],[-75.308761,44.385938],[-75.298959,44.431745]]]},"properties":{"id":"13681","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.92286,43.132905],[-73.85532,43.19665],[-73.978838,43.1789],[-73.977482,43.166942],[-73.92286,43.132905]]]},"properties":{"id":"12859","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.862295,43.095465],[-73.877888,43.071597],[-73.755966,42.950398],[-73.71382,42.992718],[-73.65976,43.054686],[-73.65213,43.134512],[-73.773712,43.113016],[-73.862295,43.095465]]]},"properties":{"id":"12866","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.919354,42.899389],[-73.891991,42.894459],[-73.891274,42.894996],[-73.888171,42.941448],[-73.934554,42.933196],[-73.919354,42.899389]]]},"properties":{"id":"12027","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.65976,43.054686],[-73.71382,42.992718],[-73.662943,42.929556],[-73.597445,43.007372],[-73.593635,43.01433],[-73.65976,43.054686]]]},"properties":{"id":"12170","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.935926,42.811655],[-73.943402,42.802544],[-73.931047,42.797714],[-73.921023,42.804406],[-73.935926,42.811655]]]},"properties":{"id":"12307","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.898029,42.866728],[-73.921085,42.840634],[-73.917178,42.843697],[-73.895938,42.851076],[-73.898029,42.866728]]]},"properties":{"id":"12008","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.108299,42.46874],[-77.120951,42.493173],[-77.150274,42.531217],[-77.179778,42.520116],[-77.306099,42.447759],[-77.20343,42.371975],[-77.147579,42.388134],[-77.094927,42.419826],[-77.106635,42.465888],[-77.109765,42.467855],[-77.108299,42.46874]]]},"properties":{"id":"14840","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.799405,42.386034],[-76.81329,42.29412],[-76.78797,42.294339],[-76.729901,42.433468],[-76.766194,42.430976],[-76.799405,42.386034]]]},"properties":{"id":"14869","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.857431,42.807263],[-76.82919,42.808257],[-76.846553,43.018948],[-76.914244,42.982757],[-76.951137,42.941896],[-76.857431,42.807263]]]},"properties":{"id":"13165","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.754394,42.718338],[-76.895179,42.698294],[-76.873661,42.632514],[-76.785242,42.629319],[-76.698153,42.660551],[-76.754394,42.718338]],[[-76.871293,42.68186],[-76.864729,42.681227],[-76.864064,42.676883],[-76.873303,42.678643],[-76.871362,42.681854],[-76.871293,42.68186]]]},"properties":{"id":"14521","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.414704,42.173432],[-77.53778,42.195998],[-77.548625,42.163132],[-77.56358,42.096683],[-77.513625,42.095283],[-77.442016,42.140918],[-77.419582,42.140518],[-77.414704,42.173432]]]},"properties":{"id":"14855","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.150274,42.531217],[-77.1539,42.555961],[-77.155525,42.54729],[-77.179778,42.520116],[-77.150274,42.531217]]]},"properties":{"id":"14874","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.061602,40.851378],[-73.059647,40.892459],[-73.069732,40.906212],[-73.106762,40.883124],[-73.101483,40.848719],[-73.076171,40.842201],[-73.061602,40.851378]]]},"properties":{"id":"11720","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.231246,40.804197],[-73.209223,40.755529],[-73.199935,40.755511],[-73.154709,40.764289],[-73.165459,40.792813],[-73.171495,40.796684],[-73.197293,40.804726],[-73.231246,40.804197]]]},"properties":{"id":"11722","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.061604,40.769076],[-73.069845,40.722565],[-73.038052,40.729618],[-73.043304,40.765948],[-73.046975,40.77224],[-73.061604,40.769076]]]},"properties":{"id":"11705","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.168311,40.827658],[-73.147861,40.826241],[-73.122205,40.834903],[-73.135221,40.862075],[-73.160437,40.859702],[-73.168311,40.827658]]]},"properties":{"id":"11767","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.579833,40.944709],[-72.565103,40.947527],[-72.569894,40.954395],[-72.572748,40.957647],[-72.579833,40.944709]]]},"properties":{"id":"11947","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.579833,40.944709],[-72.58864,40.931537],[-72.563324,40.945805],[-72.564692,40.947689],[-72.565103,40.947527],[-72.579833,40.944709]]]},"properties":{"id":"11970","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.397667,40.703061],[-73.391719,40.654161],[-73.376166,40.6569],[-73.397667,40.703061]]]},"properties":{"id":"11726","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.337564,40.966443],[-72.283513,40.955927],[-72.271968,40.968671],[-72.287606,41.010723],[-72.333133,41.036186],[-72.383227,40.976887],[-72.366957,40.961514],[-72.337564,40.966443]]]},"properties":{"id":"11963","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.483915,40.883238],[-72.477409,40.843862],[-72.472892,40.833188],[-72.349531,40.8751],[-72.366957,40.961514],[-72.383227,40.976887],[-72.478765,40.898164],[-72.483915,40.883238]]]},"properties":{"id":"11968","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.366957,40.961514],[-72.349531,40.8751],[-72.323208,40.88452],[-72.337564,40.966443],[-72.366957,40.961514]]]},"properties":{"id":"11976","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.020849,40.974603],[-72.984573,40.910711],[-72.941954,40.907818],[-72.940603,40.916679],[-72.963457,40.945234],[-72.983727,40.974946],[-73.020849,40.974603]]]},"properties":{"id":"11764","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.287921,40.883895],[-73.253102,40.92115],[-73.418379,40.928833],[-73.391663,40.908098],[-73.35247,40.875196],[-73.33884,40.881064],[-73.287921,40.883895]]]},"properties":{"id":"11768","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.148258,40.928737],[-73.131253,40.869094],[-73.106762,40.883124],[-73.148258,40.928737]],[[-73.127584,40.911875],[-73.12885,40.918339],[-73.119938,40.915793],[-73.127584,40.911875]]]},"properties":{"id":"11790","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.391663,40.908098],[-73.3729,40.879859],[-73.35247,40.875196],[-73.391663,40.908098]]]},"properties":{"id":"11721","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.36019,40.769245],[-73.421143,40.819861],[-73.448747,40.804901],[-73.444096,40.781606],[-73.440222,40.760415],[-73.402919,40.759464],[-73.36019,40.769245]]]},"properties":{"id":"11747","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.647338,41.816427],[-74.615676,41.787813],[-74.646364,41.818826],[-74.647338,41.816427]]],[[[-74.677079,41.77885],[-74.626121,41.762677],[-74.628992,41.78457],[-74.682304,41.79578],[-74.677079,41.77885]]]]},"properties":{"id":"12759","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.874301,41.802011],[-74.918397,41.829615],[-74.915447,41.838431],[-74.954798,41.822729],[-74.968003,41.791941],[-74.950356,41.756615],[-74.928141,41.726754],[-74.919162,41.723017],[-74.874301,41.802011]]]},"properties":{"id":"12748","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.771512,41.827336],[-74.673915,41.851571],[-74.635573,41.879907],[-74.63657,41.884075],[-74.771512,41.827336]]]},"properties":{"id":"12768","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.635573,41.879907],[-74.63894,41.828008],[-74.636831,41.825548],[-74.5756,41.837458],[-74.589327,41.889972],[-74.626225,41.889986],[-74.63657,41.884075],[-74.635573,41.879907]]]},"properties":{"id":"12765","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.630536,41.577487],[-74.783831,41.604143],[-74.752399,41.493743],[-74.721097,41.494835],[-74.697836,41.495678],[-74.601749,41.499082],[-74.602271,41.55657],[-74.624844,41.573086],[-74.630536,41.577487]]]},"properties":{"id":"12777","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.57363,41.667928],[-74.550313,41.683907],[-74.56759,41.680276],[-74.57363,41.667928]]],[[[-74.57363,41.667928],[-74.572476,41.677523],[-74.599477,41.689893],[-74.601246,41.67303],[-74.609391,41.656945],[-74.592431,41.64132],[-74.552102,41.619411],[-74.525057,41.653373],[-74.57363,41.667928]]]]},"properties":{"id":"12738","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.918397,41.829615],[-74.874301,41.802011],[-74.857458,41.78891],[-74.858277,41.805798],[-74.918397,41.829615]]]},"properties":{"id":"12791","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.815739,41.674875],[-74.785244,41.604279],[-74.783831,41.604143],[-74.762575,41.681053],[-74.77704,41.69415],[-74.813316,41.70274],[-74.810933,41.689635],[-74.815739,41.674875]]]},"properties":{"id":"12762","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.446233,41.639359],[-74.456356,41.640216],[-74.492513,41.621338],[-74.468447,41.596118],[-74.446233,41.639359]]]},"properties":{"id":"12781","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.452404,42.107327],[-76.43366,42.034944],[-76.331561,42.07617],[-76.416396,42.143716],[-76.461668,42.127576],[-76.452404,42.107327]],[[-76.347561,42.056908],[-76.355007,42.046227],[-76.361523,42.051938],[-76.347561,42.056908]]]},"properties":{"id":"13734","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.474457,42.447568],[-76.478416,42.452011],[-76.481134,42.44346],[-76.476569,42.443194],[-76.474457,42.447568]]]},"properties":{"id":"14853","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.006217,41.80158],[-73.952342,41.766009],[-73.958875,41.82468],[-73.966046,41.824705],[-73.967715,41.819703],[-73.9883,41.8162],[-74.006217,41.80158]]]},"properties":{"id":"12493","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.439853,42.16473],[-74.489047,42.135012],[-74.485462,42.099495],[-74.502964,42.038031],[-74.43589,42.002155],[-74.395836,42.06108],[-74.439853,42.16473]]]},"properties":{"id":"12410","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.004428,42.018784],[-74.027913,42.005578],[-74.006376,42.010249],[-74.004428,42.018784]]]},"properties":{"id":"12475","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.097599,41.853259],[-74.106474,41.839861],[-74.099915,41.823825],[-74.086006,41.826484],[-74.048267,41.854022],[-74.037806,41.873854],[-74.057088,41.866097],[-74.097599,41.853259]]]},"properties":{"id":"12472","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.376242,41.783313],[-74.366501,41.743237],[-74.342286,41.712953],[-74.376242,41.783313]]]},"properties":{"id":"12489","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.939342,42.060129],[-73.944959,42.044591],[-73.942455,42.030347],[-73.939342,42.060129]]]},"properties":{"id":"12432","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.248835,42.012426],[-74.277715,41.99217],[-74.241747,41.929773],[-74.240622,41.933091],[-74.175484,41.981179],[-74.17655,41.983268],[-74.189997,42.003121],[-74.248835,42.012426]]]},"properties":{"id":"12481","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.926494,42.133456],[-73.923087,42.118441],[-73.918786,42.129159],[-73.926494,42.133456]]]},"properties":{"id":"12490","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.217339,42.06144],[-74.268601,42.083941],[-74.286609,42.068265],[-74.305289,42.03363],[-74.24959,42.019474],[-74.199078,42.037824],[-74.217339,42.06144]]]},"properties":{"id":"12457","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.890021,43.610622],[-73.971656,43.535553],[-73.852709,43.454558],[-73.874126,43.424903],[-73.791931,43.424187],[-73.753549,43.487095],[-73.723923,43.543534],[-73.70684,43.634239],[-73.771083,43.670807],[-73.897811,43.661971],[-73.892313,43.631348],[-73.890021,43.610622]]]},"properties":{"id":"12885","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.753549,43.487095],[-73.791931,43.424187],[-73.764792,43.373532],[-73.641494,43.465817],[-73.682489,43.473929],[-73.753549,43.487095]]]},"properties":{"id":"12845","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.619736,43.30494],[-73.666457,43.323401],[-73.660461,43.290572],[-73.619736,43.30494]]]},"properties":{"id":"12801","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.396061,43.699361],[-73.38796,43.807686],[-73.43812,43.803687],[-73.483915,43.690619],[-73.444284,43.694908],[-73.396061,43.699361]]]},"properties":{"id":"12861","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.427171,43.175442],[-73.401821,43.218267],[-73.443055,43.185804],[-73.427171,43.175442]]]},"properties":{"id":"12823","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.878036,43.152122],[-76.862218,43.133174],[-76.851099,43.150627],[-76.878036,43.152122]]]},"properties":{"id":"14542","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.026265,43.270595],[-76.989798,43.236609],[-76.939492,43.250025],[-76.943921,43.273252],[-77.026265,43.270595]]]},"properties":{"id":"14555","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.87082,41.046962],[-73.83608,41.057353],[-73.828811,41.081661],[-73.801754,41.109787],[-73.845117,41.108425],[-73.868141,41.109701],[-73.87082,41.046962]]]},"properties":{"id":"10591","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.826693,41.028712],[-73.843676,41.025558],[-73.845334,41.023229],[-73.858652,40.997989],[-73.843467,40.996169],[-73.824515,41.017293],[-73.826693,41.028712]]]},"properties":{"id":"10502","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.803814,41.047273],[-73.839444,41.036307],[-73.843676,41.025558],[-73.826693,41.028712],[-73.785071,41.034911],[-73.776303,41.031867],[-73.775168,41.032534],[-73.803814,41.047273]]]},"properties":{"id":"10607","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.619728,41.229552],[-73.653966,41.229249],[-73.680138,41.207166],[-73.667753,41.175372],[-73.644074,41.139501],[-73.614367,41.152786],[-73.619728,41.229552]]]},"properties":{"id":"10506","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.772774,41.218388],[-73.724046,41.153036],[-73.667753,41.175372],[-73.680138,41.207166],[-73.727803,41.246365],[-73.729834,41.250445],[-73.766118,41.229274],[-73.772774,41.218388]]]},"properties":{"id":"10549","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.826853,41.081218],[-73.798107,41.055803],[-73.766898,41.073993],[-73.754585,41.0952],[-73.743234,41.124402],[-73.748415,41.118355],[-73.784198,41.096574],[-73.826853,41.081218]]]},"properties":{"id":"10595","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.62142,41.255742],[-73.619728,41.229552],[-73.614367,41.152786],[-73.513993,41.198854],[-73.565873,41.261412],[-73.62142,41.255742]]]},"properties":{"id":"10576","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.694765,41.351387],[-73.734631,41.337191],[-73.728942,41.318273],[-73.693258,41.296462],[-73.68155,41.297625],[-73.683694,41.321134],[-73.659996,41.323823],[-73.677526,41.302008],[-73.674947,41.302191],[-73.658844,41.332683],[-73.670005,41.354053],[-73.694765,41.351387]]]},"properties":{"id":"10589","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.844728,40.904732],[-73.823244,40.891199],[-73.815167,40.902608],[-73.825154,40.917899],[-73.840874,40.927722],[-73.844578,40.923898],[-73.852678,40.909899],[-73.844728,40.904732]]]},"properties":{"id":"10550","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.805023,40.926132],[-73.798193,40.895068],[-73.768399,40.908986],[-73.760084,40.912217],[-73.768809,40.934414],[-73.803896,40.928346],[-73.8054,40.926836],[-73.805023,40.926132]]]},"properties":{"id":"10801","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.798193,40.895068],[-73.805023,40.926132],[-73.81367,40.91108],[-73.815167,40.902608],[-73.823244,40.891199],[-73.819719,40.890206],[-73.815165,40.888999],[-73.792942,40.883411],[-73.788297,40.885088],[-73.798193,40.895068]]]},"properties":{"id":"10803","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.718555,41.072022],[-73.717185,41.014191],[-73.692476,41.004725],[-73.70389,41.055179],[-73.718264,41.07203],[-73.718555,41.072022]]]},"properties":{"id":"10577","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.834665,41.17521],[-73.831504,41.180407],[-73.835975,41.182446],[-73.839625,41.177402],[-73.834665,41.17521]]]},"properties":{"id":"10545","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.171078,42.529157],[-78.128068,42.521367],[-78.145392,42.55133],[-78.162501,42.551427],[-78.171078,42.529157]]],[[[-78.298009,42.667425],[-78.15732,42.562794],[-78.133574,42.564883],[-78.10394,42.564888],[-78.08156,42.640826],[-78.172668,42.67097],[-78.2647,42.695663],[-78.304019,42.693335],[-78.298009,42.667425]]]]},"properties":{"id":"14066","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.958416,42.695684],[-76.962789,42.684394],[-76.949391,42.684687],[-76.958416,42.695684]]]},"properties":{"id":"14441","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.997652,42.551067],[-73.970143,42.496056],[-73.968043,42.479013],[-73.932892,42.538291],[-73.867074,42.539338],[-73.866934,42.539606],[-73.855271,42.58849],[-73.943449,42.574544],[-73.973074,42.575388],[-73.997652,42.551067]]]},"properties":{"id":"12067","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.753959,42.670584],[-73.721003,42.70869],[-73.745497,42.714073],[-73.793759,42.726423],[-73.780018,42.681732],[-73.765761,42.673472],[-73.753959,42.670584]]]},"properties":{"id":"12211","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.820366,42.689707],[-73.765392,42.657951],[-73.765761,42.673472],[-73.780018,42.681732],[-73.830238,42.694137],[-73.83074,42.69256],[-73.820366,42.689707]]]},"properties":{"id":"12206","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.941014,42.454704],[-73.915632,42.447295],[-73.920665,42.461466],[-73.941014,42.454704]]]},"properties":{"id":"12007","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.698857,42.751143],[-73.694662,42.732078],[-73.684362,42.760778],[-73.698857,42.751143]]]},"properties":{"id":"12183","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.948602,42.40337],[-73.951812,42.399352],[-73.949051,42.391637],[-73.948602,42.40337]]],[[[-74.096645,42.452345],[-74.103811,42.408396],[-74.079713,42.398553],[-73.992424,42.37362],[-73.952851,42.399452],[-73.969502,42.441027],[-73.96774,42.476311],[-73.968043,42.479013],[-73.970143,42.496056],[-74.096645,42.452345]]]]},"properties":{"id":"12083","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.95677,42.5905],[-73.973074,42.575388],[-73.943449,42.574544],[-73.95677,42.5905]]]},"properties":{"id":"12041","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.847563,42.529541],[-73.866934,42.539606],[-73.867074,42.539338],[-73.847563,42.529541]]]},"properties":{"id":"12161","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.103616,42.275764],[-78.074088,42.194912],[-77.862305,42.252317],[-77.839235,42.267465],[-77.959498,42.299502],[-78.068521,42.294666],[-78.103616,42.275764]]]},"properties":{"id":"14813","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.074077,42.086204],[-78.054045,42.065697],[-78.06209,42.033784],[-78.02476,41.999319],[-77.863238,41.998447],[-77.801872,42.075487],[-77.881402,42.173718],[-78.077264,42.103458],[-78.126184,42.089328],[-78.074077,42.086204]]]},"properties":{"id":"14895","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.801872,42.075487],[-77.863238,41.998447],[-77.721894,41.998797],[-77.746741,42.072623],[-77.801872,42.075487]]]},"properties":{"id":"14897","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.257927,42.031783],[-78.284023,41.999138],[-78.268811,41.999005],[-78.257927,42.031783]]]},"properties":{"id":"14721","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.704053,42.101044],[-77.746741,42.072623],[-77.721894,41.998797],[-77.635718,41.999387],[-77.63231,42.088514],[-77.704053,42.101044]]]},"properties":{"id":"14877","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.884855,40.878545],[-73.87986,40.868274],[-73.870148,40.878314],[-73.868383,40.857812],[-73.861791,40.857983],[-73.855691,40.884212],[-73.862875,40.895154],[-73.878189,40.905983],[-73.886124,40.908016],[-73.89571,40.885781],[-73.887105,40.882923],[-73.884855,40.878545]]]},"properties":{"id":"10467","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.87986,40.868274],[-73.884855,40.878545],[-73.899949,40.85742],[-73.88382,40.851499],[-73.877418,40.85538],[-73.87986,40.868274]]]},"properties":{"id":"10458","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.866806,40.834837],[-73.879983,40.83468],[-73.884568,40.82316],[-73.84572,40.828284],[-73.866806,40.834837]]]},"properties":{"id":"10472","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.948424,42.094791],[-75.958407,41.998987],[-75.857635,41.998633],[-75.838212,42.094812],[-75.890539,42.103375],[-75.917519,42.092131],[-75.948424,42.094791]]]},"properties":{"id":"13903","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.914766,42.233574],[-75.928822,42.188917],[-75.917519,42.092131],[-75.890539,42.103375],[-75.849469,42.159964],[-75.837838,42.201275],[-75.88969,42.237866],[-75.915318,42.236288],[-75.914766,42.233574]]]},"properties":{"id":"13901","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.849469,42.159964],[-75.890539,42.103375],[-75.838212,42.094812],[-75.827732,42.091598],[-75.779128,42.124792],[-75.684959,42.130162],[-75.760629,42.156486],[-75.849469,42.159964]]]},"properties":{"id":"13904","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.013987,42.385677],[-76.022247,42.400597],[-76.004005,42.377896],[-75.983166,42.347983],[-75.951629,42.411427],[-75.922225,42.458703],[-76.018073,42.527308],[-76.112619,42.555668],[-76.206304,42.479753],[-76.215695,42.44304],[-76.215763,42.428237],[-76.065572,42.399169],[-76.013987,42.385677]]]},"properties":{"id":"13803","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.849469,42.159964],[-75.760629,42.156486],[-75.733348,42.249324],[-75.779337,42.249305],[-75.837838,42.201275],[-75.849469,42.159964]]]},"properties":{"id":"13833","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.647199,42.123378],[-75.684959,42.130162],[-75.779128,42.124792],[-75.742217,41.997864],[-75.501723,41.999269],[-75.517131,42.120348],[-75.602916,42.121259],[-75.647199,42.123378]],[[-75.647199,42.123378],[-75.64596,42.116064],[-75.635439,42.106059],[-75.63109,42.097926],[-75.631468,42.082249],[-75.640028,42.086955],[-75.636301,42.091705],[-75.641338,42.103374],[-75.650385,42.108094],[-75.647199,42.123378]]]},"properties":{"id":"13865","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.838212,42.094812],[-75.857635,41.998633],[-75.762432,41.997806],[-75.827732,42.091598],[-75.838212,42.094812]]]},"properties":{"id":"13748","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.695609,42.15148],[-78.714952,41.998544],[-78.519805,41.999897],[-78.517558,42.005903],[-78.618643,42.086329],[-78.695609,42.15148]]]},"properties":{"id":"14753","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.308812,42.370911],[-78.308448,42.413504],[-78.386669,42.435731],[-78.452444,42.402442],[-78.555554,42.337497],[-78.574038,42.297645],[-78.489375,42.236344],[-78.486312,42.237014],[-78.38894,42.261348],[-78.308812,42.370911]]]},"properties":{"id":"14737","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.158009,42.096441],[-79.145488,41.9994],[-78.977566,41.998882],[-78.942902,42.084263],[-79.019307,42.08571],[-79.134349,42.094199],[-79.158009,42.096441]]]},"properties":{"id":"14738","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.46237,42.606545],[-78.47017,42.533534],[-78.464228,42.524986],[-78.432354,42.490076],[-78.308839,42.521217],[-78.329593,42.607607],[-78.338313,42.651007],[-78.435995,42.672622],[-78.463636,42.678378],[-78.46237,42.606545]]]},"properties":{"id":"14009","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.654719,43.199437],[-76.56449,43.254986],[-76.609083,43.317115],[-76.686962,43.278716],[-76.654719,43.199437]]]},"properties":{"id":"13111","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.698777,42.844961],[-76.633575,42.869391],[-76.682242,42.954729],[-76.708388,43.004419],[-76.720879,43.0003],[-76.734327,42.944879],[-76.698777,42.844961]]]},"properties":{"id":"13034","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.286878,42.803969],[-76.286039,42.785563],[-76.26851,42.6735],[-76.076567,42.731998],[-76.132479,42.731956],[-76.150889,42.704039],[-76.160756,42.703403],[-76.195944,42.77473],[-76.196703,42.783691],[-76.24488,42.820885],[-76.286878,42.803969]]]},"properties":{"id":"13077","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.474314,43.161546],[-76.377424,43.111587],[-76.312261,43.105697],[-76.25155,43.122367],[-76.255227,43.189374],[-76.281027,43.201091],[-76.423952,43.229449],[-76.479224,43.227519],[-76.474314,43.161546]]]},"properties":{"id":"13027","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.570472,42.934863],[-76.578014,42.934982],[-76.577184,42.933131],[-76.570472,42.934863]]]},"properties":{"id":"13024","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.593146,42.103227],[-79.553126,41.998555],[-79.411175,41.998613],[-79.517498,42.098584],[-79.593146,42.103227]]]},"properties":{"id":"14767","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.613077,42.130191],[-76.536507,42.155403],[-76.538349,42.281755],[-76.657456,42.283672],[-76.642256,42.233721],[-76.613077,42.130191]]]},"properties":{"id":"14889","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.521928,42.443354],[-75.67656,42.532348],[-75.748972,42.453051],[-75.609221,42.337669],[-75.52225,42.375143],[-75.521928,42.443354]]]},"properties":{"id":"13830","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.419015,42.641573],[-75.370166,42.740376],[-75.391451,42.770435],[-75.42951,42.75742],[-75.549952,42.699319],[-75.540266,42.641115],[-75.423942,42.62937],[-75.419015,42.641573]]]},"properties":{"id":"13460","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.024741,42.649125],[-76.018073,42.527308],[-75.922225,42.458703],[-75.86968,42.490218],[-75.820128,42.580321],[-75.877159,42.575737],[-75.905537,42.654574],[-76.024741,42.649125]]]},"properties":{"id":"13040","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.423942,42.62937],[-75.42726,42.468591],[-75.398385,42.45879],[-75.36057,42.405347],[-75.303762,42.447898],[-75.320448,42.46239],[-75.32488,42.517994],[-75.304369,42.569065],[-75.419015,42.641573],[-75.423942,42.62937]]]},"properties":{"id":"13843","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.623006,44.789274],[-73.579656,44.733539],[-73.39161,44.814394],[-73.406564,44.84122],[-73.526832,44.911629],[-73.554859,44.897487],[-73.623006,44.789274]]]},"properties":{"id":"12992","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.704821,44.527132],[-73.59475,44.48978],[-73.56241,44.479308],[-73.463166,44.524736],[-73.459986,44.52345],[-73.460146,44.525873],[-73.419212,44.540986],[-73.438049,44.608441],[-73.549293,44.623769],[-73.704821,44.527132]]]},"properties":{"id":"12972","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.549293,44.623769],[-73.438049,44.608441],[-73.442327,44.672434],[-73.452692,44.671587],[-73.443862,44.69334],[-73.39161,44.814394],[-73.579656,44.733539],[-73.546701,44.626793],[-73.549293,44.623769]]]},"properties":{"id":"12901","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.737917,44.913014],[-73.637671,44.926609],[-73.675459,45.002907],[-73.762986,45.003239],[-73.737917,44.913014]]]},"properties":{"id":"12959","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.549933,42.373769],[-73.504826,42.347301],[-73.445833,42.366592],[-73.427301,42.456771],[-73.474893,42.485245],[-73.497691,42.495232],[-73.513312,42.493161],[-73.52069,42.492207],[-73.520521,42.484589],[-73.520455,42.481999],[-73.549933,42.373769]]]},"properties":{"id":"12060","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.722953,42.345228],[-73.773441,42.328953],[-73.851917,42.18967],[-73.805312,42.099234],[-73.733169,42.137302],[-73.694441,42.204261],[-73.695999,42.20512],[-73.680662,42.209811],[-73.634884,42.221108],[-73.633851,42.244461],[-73.665134,42.253236],[-73.704956,42.348963],[-73.719205,42.345894],[-73.722953,42.345228]],[[-73.744456,42.284161],[-73.743203,42.293735],[-73.735608,42.293426],[-73.737563,42.286475],[-73.744456,42.284161]],[[-73.734971,42.222454],[-73.722609,42.235288],[-73.702374,42.218558],[-73.73077,42.212624],[-73.734971,42.222454]]]},"properties":{"id":"12534","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.744456,42.284161],[-73.737563,42.286475],[-73.735608,42.293426],[-73.743203,42.293735],[-73.744456,42.284161]]]},"properties":{"id":"12172","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.78127,42.4638],[-73.779641,42.463574],[-73.778766,42.46468],[-73.781428,42.464438],[-73.78127,42.4638]]],[[[-73.722953,42.345228],[-73.720463,42.367571],[-73.731264,42.431733],[-73.772774,42.465225],[-73.773441,42.328953],[-73.722953,42.345228]]]]},"properties":{"id":"12173","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.720463,42.367571],[-73.722953,42.345228],[-73.719205,42.345894],[-73.720463,42.367571]]]},"properties":{"id":"12174","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.196703,42.783691],[-76.235387,42.82609],[-76.261845,42.848137],[-76.267591,42.846159],[-76.24488,42.820885],[-76.196703,42.783691]]],[[[-76.132479,42.731956],[-76.195944,42.77473],[-76.160756,42.703403],[-76.154517,42.715278],[-76.150889,42.704039],[-76.132479,42.731956]]]]},"properties":{"id":"13141","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.260565,42.35241],[-75.231103,42.325773],[-75.167239,42.27086],[-75.063186,42.323144],[-75.057225,42.342211],[-75.07437,42.390149],[-75.151783,42.383125],[-75.260565,42.35241]]]},"properties":{"id":"13775","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.563203,42.362207],[-74.685824,42.413116],[-74.719049,42.419434],[-74.738272,42.333981],[-74.741561,42.319239],[-74.663491,42.28568],[-74.563203,42.362207]]]},"properties":{"id":"13788","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.279223,42.202564],[-75.278362,42.188049],[-75.275627,42.202645],[-75.279223,42.202564]]],[[[-75.279223,42.202564],[-75.275124,42.206553],[-75.167239,42.27086],[-75.231103,42.325773],[-75.326136,42.200937],[-75.305959,42.201519],[-75.279223,42.202564]]]]},"properties":{"id":"13839","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.387441,42.327644],[-75.381162,42.243804],[-75.362995,42.284421],[-75.387441,42.327644]]]},"properties":{"id":"13838","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.649131,42.24568],[-74.67289,42.274067],[-74.71364,42.234229],[-74.649131,42.24568]]]},"properties":{"id":"12459","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.017021,41.886748],[-75.029328,41.903972],[-75.133589,41.963607],[-75.141981,41.946589],[-75.169289,41.870465],[-75.113704,41.823492],[-75.059263,41.860606],[-75.017021,41.886748]]]},"properties":{"id":"12760","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.824851,42.700824],[-74.83263,42.681445],[-74.854053,42.677319],[-74.892526,42.61285],[-74.877539,42.498202],[-74.787911,42.499211],[-74.772888,42.505169],[-74.7708,42.67436],[-74.779918,42.687859],[-74.764145,42.716395],[-74.813213,42.704277],[-74.824851,42.700824]]]},"properties":{"id":"12155","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.02003,42.383273],[-74.966891,42.355343],[-74.939333,42.373886],[-75.006329,42.384723],[-75.018205,42.388395],[-75.02003,42.383273]]]},"properties":{"id":"13806","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.858277,41.805798],[-74.813602,41.800818],[-74.771512,41.827336],[-74.63657,41.884075],[-74.626225,41.889986],[-74.52959,42.027736],[-74.56458,42.044667],[-74.692152,42.057477],[-74.810211,42.029108],[-74.898022,41.876718],[-74.915447,41.838431],[-74.918397,41.829615],[-74.858277,41.805798]]]},"properties":{"id":"12758","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.742801,41.715663],[-73.679234,41.709864],[-73.743165,41.731317],[-73.742801,41.715663]]]},"properties":{"id":"12585","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.624956,41.906018],[-73.650411,41.866038],[-73.593197,41.843528],[-73.505249,41.82185],[-73.498571,41.890138],[-73.601171,41.887702],[-73.624956,41.906018]]]},"properties":{"id":"12501","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.592101,41.787797],[-73.525943,41.753488],[-73.511564,41.747918],[-73.505249,41.82185],[-73.593197,41.843528],[-73.592101,41.787797]]]},"properties":{"id":"12592","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.464962,43.117341],[-78.524968,43.127969],[-78.557474,43.117944],[-78.576065,43.079758],[-78.576245,43.031222],[-78.552723,42.963916],[-78.464249,42.948486],[-78.426012,43.031636],[-78.464962,43.117341]]]},"properties":{"id":"14001","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.908044,42.696563],[-78.935917,42.696215],[-78.954947,42.674921],[-78.963621,42.619647],[-78.783784,42.584161],[-78.783894,42.589972],[-78.79891,42.636233],[-78.908044,42.696563]]]},"properties":{"id":"14057","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.889249,42.958572],[-78.877406,42.934226],[-78.865779,42.929484],[-78.837037,42.958384],[-78.853259,42.958628],[-78.889249,42.958572]]]},"properties":{"id":"14216","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.881902,42.904769],[-78.87302,42.902227],[-78.86779,42.927927],[-78.865779,42.929484],[-78.877406,42.934226],[-78.888804,42.936594],[-78.881902,42.904769]]]},"properties":{"id":"14222","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.577549,42.82302],[-78.683497,42.796328],[-78.688817,42.737683],[-78.61409,42.693716],[-78.475492,42.735813],[-78.463233,42.780461],[-78.486799,42.832556],[-78.537002,42.841894],[-78.577549,42.82302]],[[-78.530227,42.76814],[-78.518665,42.766562],[-78.525126,42.762985],[-78.530227,42.76814]]]},"properties":{"id":"14052","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.783894,42.589972],[-78.706067,42.591848],[-78.654381,42.58825],[-78.665681,42.62585],[-78.748854,42.664544],[-78.79891,42.636233],[-78.783894,42.589972]]]},"properties":{"id":"14025","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.904412,42.8945],[-78.87302,42.902227],[-78.881902,42.904769],[-78.902875,42.906291],[-78.904412,42.8945]]]},"properties":{"id":"14201","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.86779,42.927927],[-78.87302,42.902227],[-78.868971,42.90221],[-78.86252,42.902163],[-78.859741,42.903909],[-78.86779,42.927927]]]},"properties":{"id":"14209","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.555852,42.856568],[-78.56252,42.86806],[-78.562934,42.862751],[-78.555852,42.856568]]],[[[-78.55567,42.850823],[-78.577549,42.82302],[-78.537002,42.841894],[-78.55567,42.850823]]]]},"properties":{"id":"14102","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.433015,44.395012],[-74.267622,44.268561],[-74.313435,44.415057],[-74.435865,44.412008],[-74.433015,44.395012]]],[[[-74.214257,44.263224],[-74.313764,44.199584],[-74.098009,44.162367],[-74.115775,44.261279],[-74.120871,44.297775],[-74.056991,44.288087],[-73.941801,44.343643],[-73.909687,44.429699],[-74.088449,44.412479],[-74.145335,44.429841],[-74.191989,44.410528],[-74.258348,44.407263],[-74.214257,44.263224]]]]},"properties":{"id":"12983","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.638151,43.784852],[-73.638602,43.743025],[-73.632652,43.678377],[-73.603439,43.666741],[-73.498,43.725123],[-73.463893,43.801714],[-73.618204,43.786777],[-73.638151,43.784852]]]},"properties":{"id":"12836","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.875387,44.199409],[-73.707637,44.211087],[-73.712716,44.251456],[-73.721596,44.313438],[-73.806681,44.305628],[-73.891545,44.298158],[-73.875387,44.199409]]]},"properties":{"id":"12942","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.058313,43.744384],[-74.09002,43.590298],[-74.050546,43.60233],[-74.024212,43.619735],[-73.941054,43.63347],[-73.961816,43.696956],[-73.899106,43.664217],[-73.82729,43.721177],[-73.878704,43.761701],[-73.950925,43.761669],[-74.057005,43.744513],[-74.058313,43.744384]]]},"properties":{"id":"12853","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.34673,44.323062],[-73.482379,44.296079],[-73.353488,44.238627],[-73.34673,44.323062]]]},"properties":{"id":"12936","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.721596,44.313438],[-73.712716,44.251456],[-73.672837,44.278737],[-73.657743,44.342114],[-73.657762,44.343483],[-73.748205,44.442813],[-73.790759,44.334175],[-73.721596,44.313438]]]},"properties":{"id":"12941","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.506194,44.930947],[-74.617402,44.774552],[-74.614223,44.752374],[-74.486767,44.783469],[-74.430351,44.905294],[-74.506194,44.930947]]]},"properties":{"id":"12916","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.467094,43.12247],[-74.537703,43.086198],[-74.493675,43.015682],[-74.447193,42.993405],[-74.28856,42.9798],[-74.247595,43.024718],[-74.234535,43.049601],[-74.467094,43.12247]]]},"properties":{"id":"12095","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.30805,42.902696],[-78.410015,42.868124],[-78.432237,42.840833],[-78.39775,42.791853],[-78.384629,42.787468],[-78.248667,42.771663],[-78.176431,42.821474],[-78.163365,42.864523],[-78.191396,42.870336],[-78.30805,42.902696]]]},"properties":{"id":"14011","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.005262,42.284202],[-73.985843,42.255266],[-73.974638,42.260613],[-73.957516,42.27795],[-73.971581,42.299075],[-74.059107,42.361477],[-74.092872,42.341163],[-74.042273,42.296272],[-74.005262,42.284202]]]},"properties":{"id":"12413","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.212299,42.14139],[-74.224748,42.107975],[-74.198467,42.105981],[-74.127883,42.100718],[-74.082194,42.097153],[-74.074797,42.096589],[-74.047566,42.158633],[-74.11279,42.182208],[-74.171105,42.188943],[-74.212299,42.14139]]]},"properties":{"id":"12427","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.096671,42.263316],[-74.005262,42.284202],[-74.042273,42.296272],[-74.143122,42.314848],[-74.100466,42.293443],[-74.104619,42.2678],[-74.096671,42.263316]]]},"properties":{"id":"12470","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.837936,43.952966],[-74.820108,43.818507],[-74.801684,43.800628],[-74.55962,43.841822],[-74.336826,43.925223],[-74.265714,44.025799],[-74.333374,44.115976],[-74.665933,44.219775],[-74.687816,44.219704],[-74.854171,44.070089],[-74.837936,43.952966]]]},"properties":{"id":"12847","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.797795,43.653398],[-74.79239,43.460043],[-74.85156,43.334218],[-74.748005,43.298253],[-74.712615,43.286143],[-74.663197,43.272036],[-74.650818,43.371539],[-74.660611,43.673682],[-74.797795,43.653398]]]},"properties":{"id":"13353","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.650818,43.371539],[-74.457719,43.232885],[-74.472144,43.336536],[-74.478258,43.380476],[-74.538754,43.822278],[-74.55962,43.841822],[-74.668346,43.730944],[-74.660611,43.673682],[-74.650818,43.371539]]]},"properties":{"id":"12139","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.317264,43.320834],[-75.222391,43.288813],[-75.153061,43.272648],[-75.086692,43.287581],[-75.111775,43.310852],[-75.118255,43.312051],[-75.119403,43.315003],[-75.106495,43.313539],[-75.020388,43.377276],[-75.201375,43.410806],[-75.238807,43.404832],[-75.247717,43.400493],[-75.317264,43.320834]],[[-75.154411,43.301933],[-75.155325,43.30688],[-75.149454,43.304819],[-75.154411,43.301933]]]},"properties":{"id":"13438","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.322117,42.987944],[-75.293276,42.960166],[-75.255749,42.961721],[-75.188095,42.996576],[-75.1963,43.035533],[-75.273483,43.042188],[-75.269674,43.032985],[-75.276355,43.019196],[-75.303435,43.027632],[-75.322117,42.987944]]]},"properties":{"id":"13456","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.667765,44.041217],[-75.776079,44.073511],[-75.829336,44.052666],[-75.766027,44.023716],[-75.738771,44.027866],[-75.737644,44.027905],[-75.667765,44.041217]]]},"properties":{"id":"13602","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.766027,44.023716],[-75.829336,44.052666],[-75.833527,44.044172],[-75.814873,44.022739],[-75.766027,44.023716]]]},"properties":{"id":"13603","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.867512,44.369818],[-75.981769,44.301535],[-75.981821,44.301488],[-76.001017,44.280376],[-76.000254,44.272759],[-75.972795,44.260789],[-75.88995,44.29449],[-75.867512,44.369818]]]},"properties":{"id":"13607","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.980703,44.067309],[-76.000418,44.049956],[-75.981498,44.000612],[-75.980703,44.067309]]]},"properties":{"id":"13615","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.202551,43.864869],[-76.213536,43.750068],[-76.120772,43.778742],[-76.168284,43.857257],[-76.202551,43.864869]]]},"properties":{"id":"13650","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.202551,43.864869],[-76.168284,43.857257],[-76.132646,43.894393],[-76.202551,43.864869]]]},"properties":{"id":"13651","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.5498,44.054734],[-75.431689,43.982736],[-75.386362,43.986949],[-75.357239,44.041948],[-75.507512,44.085106],[-75.5498,44.054734]]]},"properties":{"id":"13665","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.981432,40.634955],[-73.997285,40.619712],[-74.000824,40.616314],[-73.987255,40.607245],[-73.972994,40.608814],[-73.977249,40.630707],[-73.981432,40.634955]]]},"properties":{"id":"11204","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.957132,40.688463],[-73.957974,40.670657],[-73.947731,40.669705],[-73.937398,40.67973],[-73.938223,40.683894],[-73.945642,40.692033],[-73.95181,40.691599],[-73.957132,40.688463]]]},"properties":{"id":"11216","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.981432,40.634955],[-73.989059,40.644119],[-73.993985,40.647101],[-73.997734,40.64341],[-74.012142,40.628684],[-73.997285,40.619712],[-73.981432,40.634955]]]},"properties":{"id":"11219","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.862346,40.679165],[-73.866295,40.683189],[-73.868917,40.69515],[-73.889575,40.68418],[-73.878436,40.649327],[-73.875052,40.64517],[-73.856652,40.650129],[-73.861318,40.675021],[-73.862346,40.679165]]]},"properties":{"id":"11208","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.903475,40.675507],[-73.916046,40.686072],[-73.938223,40.683894],[-73.937398,40.67973],[-73.924244,40.666445],[-73.903475,40.675507]]]},"properties":{"id":"11233","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.973126,40.689622],[-73.989992,40.683319],[-73.98856,40.679559],[-73.970749,40.672639],[-73.972687,40.687415],[-73.973126,40.689622]]]},"properties":{"id":"11217","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.957974,40.670657],[-73.962894,40.663798],[-73.9619,40.654871],[-73.947264,40.656437],[-73.940029,40.663021],[-73.947731,40.669705],[-73.957974,40.670657]]]},"properties":{"id":"11225","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.658189,43.495782],[-75.6395,43.444149],[-75.55792,43.42487],[-75.462224,43.453036],[-75.467092,43.517159],[-75.581467,43.477781],[-75.658189,43.495782]]]},"properties":{"id":"13489","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.391924,43.885959],[-75.164076,44.049035],[-75.357239,44.041948],[-75.386362,43.986949],[-75.401204,43.903849],[-75.4145,43.890044],[-75.391924,43.885959]]]},"properties":{"id":"13327","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.435473,43.722839],[-75.468112,43.746209],[-75.500416,43.741683],[-75.484957,43.727072],[-75.455763,43.727389],[-75.435473,43.722839]]]},"properties":{"id":"13404","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.830104,42.857337],[-77.715155,42.855871],[-77.705428,42.855319],[-77.671059,42.865608],[-77.644495,42.927138],[-77.69941,42.945584],[-77.758727,42.940461],[-77.821143,42.889834],[-77.830104,42.857337]]]},"properties":{"id":"14414","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.709535,43.088988],[-75.716991,43.07443],[-75.70321,43.07557],[-75.709535,43.088988]]]},"properties":{"id":"13163","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.620382,43.192635],[-77.659409,43.187376],[-77.637564,43.169527],[-77.628201,43.181017],[-77.620382,43.192635]]]},"properties":{"id":"14613","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.795984,43.070356],[-77.730461,43.0917],[-77.686064,43.098694],[-77.780377,43.089345],[-77.795984,43.070356]]],[[[-77.93942,43.136544],[-77.908666,43.037266],[-77.907382,43.005979],[-77.866084,42.99756],[-77.795984,43.070356],[-77.79669,43.090277],[-77.78769,43.110938],[-77.818504,43.107408],[-77.827886,43.130734],[-77.827481,43.137077],[-77.895314,43.145565],[-77.93942,43.136544]]]]},"properties":{"id":"14428","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.601951,43.25952],[-77.611139,43.249121],[-77.577021,43.199045],[-77.574489,43.245376],[-77.601951,43.25952]]]},"properties":{"id":"14617","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.374533,43.16651],[-77.388141,43.03456],[-77.364968,43.034671],[-77.29516,43.034706],[-77.268171,43.091817],[-77.320382,43.174225],[-77.365561,43.187947],[-77.374533,43.16651]]]},"properties":{"id":"14502","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.507236,43.110783],[-77.47945,43.099409],[-77.471687,43.111198],[-77.480912,43.123663],[-77.514169,43.122132],[-77.512657,43.1192],[-77.507236,43.110783]]]},"properties":{"id":"14445","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.595667,43.159523],[-77.613103,43.160629],[-77.609346,43.151139],[-77.607221,43.151059],[-77.595667,43.159523]]]},"properties":{"id":"14604","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.907382,43.005979],[-77.878331,42.987862],[-77.846364,42.98801],[-77.866084,42.99756],[-77.907382,43.005979]]]},"properties":{"id":"14511","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.514169,43.122132],[-77.480912,43.123663],[-77.494292,43.175565],[-77.519506,43.17446],[-77.528403,43.163304],[-77.514169,43.122132]]]},"properties":{"id":"14625","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.890506,43.354935],[-77.996748,43.3652],[-77.980447,43.308935],[-77.965723,43.28974],[-77.932449,43.27023],[-77.890506,43.354935]]]},"properties":{"id":"14464","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.575187,43.143347],[-77.607221,43.151059],[-77.609346,43.151139],[-77.623424,43.132963],[-77.623908,43.131912],[-77.630184,43.123226],[-77.603185,43.109618],[-77.5754,43.136255],[-77.575187,43.143347]]]},"properties":{"id":"14620","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.657632,40.751753],[-73.626601,40.759635],[-73.63655,40.766984],[-73.65875,40.760861],[-73.660393,40.760611],[-73.657632,40.751753]]]},"properties":{"id":"11596","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.398653,40.733923],[-73.402919,40.759464],[-73.440222,40.760415],[-73.469264,40.738162],[-73.471009,40.717274],[-73.450214,40.705561],[-73.442868,40.704529],[-73.429962,40.706699],[-73.405944,40.717654],[-73.394508,40.721184],[-73.398653,40.733923]]]},"properties":{"id":"11735","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.492249,40.705384],[-73.501053,40.645695],[-73.486542,40.648867],[-73.482723,40.712576],[-73.488728,40.722337],[-73.492249,40.705384]]]},"properties":{"id":"11783","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.71164,40.618546],[-73.732105,40.639535],[-73.736361,40.632362],[-73.71164,40.618546]]]},"properties":{"id":"11516","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.676865,40.643276],[-73.656875,40.64986],[-73.656246,40.649099],[-73.659389,40.667495],[-73.680542,40.668711],[-73.689896,40.657963],[-73.689862,40.649978],[-73.676865,40.643276]]]},"properties":{"id":"11563","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.689862,40.649978],[-73.714626,40.647607],[-73.68899,40.623137],[-73.677403,40.628768],[-73.676865,40.643276],[-73.689862,40.649978]]]},"properties":{"id":"11557","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.601666,40.672393],[-73.569148,40.668855],[-73.575052,40.690846],[-73.601439,40.686065],[-73.601666,40.672393]]]},"properties":{"id":"11575","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.569148,40.668855],[-73.601666,40.672393],[-73.585672,40.625902],[-73.559729,40.628886],[-73.569148,40.668855]]]},"properties":{"id":"11520","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.656875,40.64986],[-73.661943,40.623054],[-73.652467,40.617093],[-73.631873,40.609503],[-73.613673,40.624703],[-73.622758,40.657434],[-73.656246,40.649099],[-73.656875,40.64986]]]},"properties":{"id":"11572","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.560088,40.699066],[-73.527671,40.631802],[-73.51337,40.630801],[-73.53188,40.706379],[-73.560088,40.699066]]]},"properties":{"id":"11710","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.469264,40.738162],[-73.440222,40.760415],[-73.444096,40.781606],[-73.468748,40.761599],[-73.469264,40.738162]]]},"properties":{"id":"11804","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.668472,40.804791],[-73.652058,40.827595],[-73.652531,40.828725],[-73.704729,40.819327],[-73.668472,40.804791]]]},"properties":{"id":"11050","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.62128,40.756541],[-73.620736,40.742318],[-73.603449,40.745269],[-73.62128,40.756541]]]},"properties":{"id":"11514","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.58305,40.807167],[-73.591491,40.823832],[-73.59859,40.815299],[-73.58305,40.807167]]]},"properties":{"id":"11548","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.580662,40.718243],[-73.58201,40.721184],[-73.587728,40.719918],[-73.585546,40.718248],[-73.580662,40.718243]]]},"properties":{"id":"11556","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.940404,40.808958],[-73.944667,40.80313],[-73.946462,40.800665],[-73.930891,40.794041],[-73.933219,40.807255],[-73.940404,40.808958]]],[[[-73.927829,40.781075],[-73.914429,40.793052],[-73.922752,40.801922],[-73.935433,40.783249],[-73.927829,40.781075]]]]},"properties":{"id":"10035","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.981822,40.752197],[-73.984076,40.749102],[-73.987746,40.744066],[-73.971566,40.742993],[-73.968192,40.747251],[-73.981822,40.752197]]]},"properties":{"id":"10016","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.00373,40.72625],[-73.994035,40.720323],[-73.992604,40.724136],[-73.999604,40.731043],[-74.000954,40.731706],[-74.00373,40.72625]]]},"properties":{"id":"10012","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.987223,40.781007],[-73.986609,40.780712],[-73.974067,40.778805],[-73.96701,40.788476],[-73.9659,40.808806],[-73.964424,40.810755],[-73.964019,40.811331],[-73.962067,40.818099],[-73.987223,40.781007]]]},"properties":{"id":"10024","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.993948,40.751522],[-73.994844,40.750293],[-73.992006,40.749101],[-73.99111,40.750319],[-73.993948,40.751522]]]},"properties":{"id":"10119","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975209,40.754425],[-73.973735,40.754619],[-73.975352,40.755303],[-73.975807,40.754678],[-73.975209,40.754425]]]},"properties":{"id":"10167","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975558,40.752116],[-73.975702,40.753811],[-73.977107,40.751883],[-73.976048,40.751441],[-73.975558,40.752116]]]},"properties":{"id":"10170","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.95257,43.291847],[-79.046567,43.192742],[-78.967701,43.210517],[-78.95257,43.291847]]]},"properties":{"id":"14174","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.303435,43.027632],[-75.344133,43.095756],[-75.364413,43.093841],[-75.364422,43.085598],[-75.383566,43.084428],[-75.380904,43.092569],[-75.387357,43.0991],[-75.436612,43.079889],[-75.460524,43.062644],[-75.449052,43.025884],[-75.388076,42.987802],[-75.322117,42.987944],[-75.303435,43.027632]],[[-75.392465,43.035783],[-75.400578,43.032305],[-75.400853,43.038095],[-75.392465,43.035783]]]},"properties":{"id":"13323","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.181502,43.092873],[-75.28543,43.074511],[-75.209411,43.062765],[-75.181502,43.092873]]]},"properties":{"id":"13501","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.466301,43.303916],[-75.53871,43.383215],[-75.565126,43.298988],[-75.466301,43.303916]]]},"properties":{"id":"13363","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.784051,43.249982],[-75.782687,43.22106],[-75.748971,43.224035],[-75.75301,43.239105],[-75.784051,43.249982]]]},"properties":{"id":"13123","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.293948,43.111883],[-75.285046,43.101273],[-75.276041,43.102126],[-75.266603,43.114891],[-75.293948,43.111883]]]},"properties":{"id":"13495","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.171353,43.137219],[-76.163629,43.093124],[-76.094038,43.120461],[-76.082603,43.136254],[-76.129814,43.148925],[-76.134875,43.150792],[-76.171353,43.137219]]]},"properties":{"id":"13212","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.155609,43.033623],[-76.186846,43.032049],[-76.192867,43.029153],[-76.162747,42.984366],[-76.148277,42.991597],[-76.153989,43.031752],[-76.155609,43.033623]]]},"properties":{"id":"13207","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.192836,43.066498],[-76.243539,43.116424],[-76.25155,43.122367],[-76.312261,43.105697],[-76.260774,43.079447],[-76.243279,43.052833],[-76.197002,43.051376],[-76.192836,43.066498]]]},"properties":{"id":"13209","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.346641,43.080847],[-76.260774,43.079447],[-76.312261,43.105697],[-76.377424,43.111587],[-76.346641,43.080847]]]},"properties":{"id":"13164","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.20633,43.270582],[-76.201355,43.213977],[-76.137813,43.188633],[-76.0754,43.199307],[-76.129542,43.234972],[-76.20633,43.270582]]]},"properties":{"id":"13029","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.552162,42.903734],[-77.533081,42.826536],[-77.533065,42.825545],[-77.477552,42.797533],[-77.379959,42.924785],[-77.485537,42.931499],[-77.517891,42.934293],[-77.567615,42.931345],[-77.555629,42.90864],[-77.551336,42.907477],[-77.552162,42.903734]]]},"properties":{"id":"14469","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.242004,41.471917],[-74.287181,41.465552],[-74.32285,41.438849],[-74.251309,41.403777],[-74.212443,41.415934],[-74.198475,41.432121],[-74.200649,41.439866],[-74.200692,41.469269],[-74.242004,41.471917]]]},"properties":{"id":"10916","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.157562,41.514088],[-74.242004,41.471917],[-74.200692,41.469269],[-74.134231,41.501344],[-74.116379,41.505869],[-74.13217,41.512582],[-74.157562,41.514088]]]},"properties":{"id":"12543","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.515546,41.373288],[-74.496767,41.367352],[-74.493755,41.363002],[-74.455516,41.408104],[-74.515546,41.373288]]]},"properties":{"id":"10973","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.122466,41.387387],[-74.13917,41.329846],[-74.101887,41.338003],[-74.068191,41.383493],[-74.071405,41.408883],[-74.097262,41.388669],[-74.111271,41.396779],[-74.122466,41.387387]]]},"properties":{"id":"10930","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.466429,43.302775],[-78.310548,43.306215],[-78.304575,43.372038],[-78.465475,43.371422],[-78.466429,43.302775]]]},"properties":{"id":"14098","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.117716,43.468333],[-76.200033,43.399732],[-76.194521,43.372104],[-76.118058,43.374637],[-76.078162,43.365871],[-75.995342,43.391784],[-75.990433,43.403254],[-75.990998,43.435342],[-76.049976,43.487586],[-76.117716,43.468333]]]},"properties":{"id":"13131","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.027521,42.716286],[-75.016428,42.659588],[-74.932491,42.590968],[-74.892526,42.61285],[-74.854053,42.677319],[-74.824851,42.700824],[-74.813213,42.704277],[-74.797536,42.716896],[-74.814915,42.831096],[-74.834275,42.833839],[-74.898783,42.829455],[-74.942258,42.801711],[-75.027521,42.716286]]]},"properties":{"id":"13326","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.182532,42.532987],[-75.211835,42.495969],[-75.182299,42.513404],[-75.182532,42.532987]]],[[[-75.139081,42.517334],[-75.073831,42.547884],[-75.181169,42.572945],[-75.179525,42.53209],[-75.139081,42.517334]]]]},"properties":{"id":"13796","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.954259,42.544146],[-74.960491,42.54176],[-74.967692,42.528544],[-74.954259,42.544146]]]},"properties":{"id":"13834","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.320448,42.46239],[-75.300899,42.472867],[-75.32488,42.517994],[-75.320448,42.46239]]]},"properties":{"id":"13776","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.707573,42.522675],[-74.623889,42.560023],[-74.628535,42.566408],[-74.638989,42.563159],[-74.670576,42.566948],[-74.707573,42.522675]]]},"properties":{"id":"12036","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.848178,40.776706],[-73.839167,40.765357],[-73.838002,40.754438],[-73.807514,40.762179],[-73.803985,40.77419],[-73.8281,40.776031],[-73.848178,40.776706]]]},"properties":{"id":"11354","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.831746,40.679537],[-73.807858,40.687123],[-73.813659,40.697133],[-73.837544,40.691364],[-73.835111,40.685088],[-73.831746,40.679537]]]},"properties":{"id":"11419","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.871429,40.729248],[-73.862079,40.712201],[-73.857298,40.711465],[-73.853392,40.73694],[-73.864618,40.734271],[-73.871429,40.729248]]]},"properties":{"id":"11374","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.740021,40.738712],[-73.765558,40.732535],[-73.76631,40.726375],[-73.757642,40.717971],[-73.732168,40.729155],[-73.732335,40.743576],[-73.740021,40.738712]]]},"properties":{"id":"11427","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.909813,40.751574],[-73.925787,40.736139],[-73.925851,40.735645],[-73.888523,40.734531],[-73.891457,40.74687],[-73.896522,40.754389],[-73.900659,40.767211],[-73.905239,40.767776],[-73.911498,40.754557],[-73.909813,40.751574]]]},"properties":{"id":"11377","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.945111,40.763086],[-73.92246,40.757427],[-73.92188,40.763574],[-73.93519,40.771745],[-73.945111,40.763086]]]},"properties":{"id":"11106","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.789309,40.769708],[-73.780088,40.752494],[-73.779806,40.751895],[-73.758001,40.76038],[-73.7537,40.76529],[-73.762585,40.771686],[-73.789309,40.769708]]]},"properties":{"id":"11361","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.780088,40.752494],[-73.807506,40.750137],[-73.809441,40.738674],[-73.810886,40.727172],[-73.770107,40.731779],[-73.779806,40.751895],[-73.780088,40.752494]]]},"properties":{"id":"11365","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.740021,40.738712],[-73.758001,40.76038],[-73.779806,40.751895],[-73.770107,40.731779],[-73.765558,40.732535],[-73.740021,40.738712]]]},"properties":{"id":"11364","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.798415,40.702741],[-73.795722,40.699015],[-73.793577,40.699756],[-73.79609,40.703294],[-73.798415,40.702741]]]},"properties":{"id":"11451","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.274294,42.943652],[-73.404033,42.925978],[-73.449578,42.872233],[-73.465709,42.84687],[-73.465536,42.846341],[-73.417011,42.821285],[-73.278306,42.837558],[-73.274294,42.943652]],[[-73.324181,42.863577],[-73.313094,42.868458],[-73.299965,42.878787],[-73.299566,42.873816],[-73.306,42.871916],[-73.307893,42.86741],[-73.319234,42.860704],[-73.324181,42.863577]]]},"properties":{"id":"12090","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.179175,40.607867],[-74.182464,40.60421],[-74.180413,40.602927],[-74.176551,40.605863],[-74.179175,40.607867]]]},"properties":{"id":"10311","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.932045,41.019913],[-73.91383,41.037451],[-73.927845,41.033763],[-73.937305,41.037846],[-73.932045,41.019913]]]},"properties":{"id":"10976","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.981987,41.05451],[-73.927304,41.070437],[-73.94668,41.085734],[-73.967045,41.078323],[-73.985482,41.060395],[-73.981987,41.05451]]]},"properties":{"id":"10913","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.034511,41.076095],[-74.025349,41.122713],[-74.034093,41.169173],[-74.050736,41.16741],[-74.06755,41.161498],[-74.073586,41.073597],[-74.050852,41.063458],[-74.034511,41.076095]]]},"properties":{"id":"10977","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.034511,41.076095],[-73.99669,41.07403],[-73.986848,41.121196],[-74.025349,41.122713],[-74.034511,41.076095]]]},"properties":{"id":"10954","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.145199,41.183895],[-74.195395,41.126831],[-74.158289,41.110996],[-74.14386,41.179033],[-74.145199,41.183895]]]},"properties":{"id":"10931","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.145199,41.183895],[-74.14386,41.179033],[-74.050736,41.16741],[-74.034093,41.169173],[-74.005088,41.190659],[-74.022641,41.197053],[-74.029543,41.204462],[-74.124651,41.201898],[-74.145199,41.183895]]]},"properties":{"id":"10970","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.657574,44.583332],[-75.648884,44.573569],[-75.631663,44.581686],[-75.645083,44.596353],[-75.657574,44.583332]]]},"properties":{"id":"13664","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.899913,44.713054],[-74.902642,44.692912],[-74.88367,44.698737],[-74.899913,44.713054]]]},"properties":{"id":"13696","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.889302,44.066829],[-74.901293,44.15989],[-74.945918,44.06155],[-74.889302,44.066829]]]},"properties":{"id":"13695","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.0085,42.947174],[-73.919354,42.899389],[-73.934554,42.933196],[-73.981036,42.989565],[-73.99858,42.975194],[-74.0085,42.947174]]],[[[-73.875178,42.879454],[-73.80202,42.925965],[-73.803929,42.95088],[-73.803189,42.952982],[-73.888171,42.941448],[-73.891274,42.894996],[-73.875178,42.879454]]]]},"properties":{"id":"12019","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.986197,43.266267],[-73.978838,43.1789],[-73.85532,43.19665],[-73.786902,43.193],[-73.763515,43.22539],[-73.832086,43.248854],[-73.827072,43.289932],[-73.923174,43.319356],[-73.961427,43.284635],[-73.986197,43.266267]]]},"properties":{"id":"12822","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.470078,43.304683],[-73.499497,43.328312],[-73.600244,43.278807],[-73.663766,43.275212],[-73.74,43.262127],[-73.580363,43.156432],[-73.530591,43.194639],[-73.470078,43.304683]]]},"properties":{"id":"12828","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.600244,43.278807],[-73.59496,43.306118],[-73.619736,43.30494],[-73.660461,43.290572],[-73.663766,43.275212],[-73.600244,43.278807]]]},"properties":{"id":"12803","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.948823,42.819886],[-73.954155,42.803182],[-73.943402,42.802544],[-73.935926,42.811655],[-73.930371,42.829904],[-73.948823,42.819886]]]},"properties":{"id":"12305","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.41463,42.499358],[-74.440704,42.473243],[-74.432769,42.473398],[-74.41463,42.499358]]],[[[-74.484482,42.436133],[-74.441157,42.472863],[-74.442643,42.471739],[-74.45532,42.468803],[-74.484383,42.466114],[-74.484482,42.436133]]]]},"properties":{"id":"12131","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.691069,42.313319],[-76.687822,42.412175],[-76.729901,42.433468],[-76.78797,42.294339],[-76.764835,42.294297],[-76.691069,42.313319]]]},"properties":{"id":"14805","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.187096,42.182804],[-77.208129,42.063606],[-77.106143,42.090872],[-77.095459,42.234963],[-77.10143,42.310504],[-77.109518,42.283738],[-77.187096,42.182804]],[[-77.150133,42.182798],[-77.137328,42.180278],[-77.138365,42.178196],[-77.150133,42.182798]]]},"properties":{"id":"14870","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.846553,43.018948],[-76.82919,42.808257],[-76.763507,42.802897],[-76.734327,42.944879],[-76.720879,43.0003],[-76.721805,43.006063],[-76.799234,43.021014],[-76.846553,43.018948]]]},"properties":{"id":"13148","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.338281,42.466896],[-77.412723,42.486304],[-77.546118,42.410305],[-77.510231,42.345657],[-77.371179,42.382804],[-77.370824,42.383031],[-77.337933,42.44598],[-77.338278,42.462033],[-77.338281,42.466896]]]},"properties":{"id":"14809","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.53778,42.195998],[-77.414704,42.173432],[-77.341587,42.251511],[-77.471896,42.274761],[-77.53778,42.195998]]]},"properties":{"id":"14819","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.959603,40.797294],[-72.956224,40.742385],[-72.92379,40.759627],[-72.926345,40.801585],[-72.950195,40.797931],[-72.959603,40.797294]]]},"properties":{"id":"11713","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.105109,40.657268],[-73.145855,40.64928],[-73.145317,40.645589],[-73.10279,40.654946],[-73.105109,40.657268]]],[[[-73.306479,40.741467],[-73.273698,40.690206],[-73.250799,40.701931],[-73.272088,40.719469],[-73.262917,40.730265],[-73.248391,40.703672],[-73.225368,40.702978],[-73.228311,40.754848],[-73.291028,40.770168],[-73.306479,40.741467]]]]},"properties":{"id":"11706","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.313453,40.718173],[-73.313361,40.717099],[-73.313865,40.686002],[-73.273698,40.690206],[-73.306479,40.741467],[-73.313453,40.718173]]]},"properties":{"id":"11795","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.248391,40.703672],[-73.262917,40.730265],[-73.272088,40.719469],[-73.250799,40.701931],[-73.248391,40.703672]]]},"properties":{"id":"11718","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.863614,40.973824],[-72.864475,40.933387],[-72.842526,40.910914],[-72.834073,40.914604],[-72.77524,40.974045],[-72.863614,40.973824]]]},"properties":{"id":"11792","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.740113,40.848476],[-72.834073,40.914604],[-72.842526,40.910914],[-72.865925,40.892296],[-72.866312,40.850816],[-72.865056,40.833675],[-72.837361,40.820022],[-72.808948,40.823162],[-72.782438,40.824464],[-72.748697,40.833716],[-72.740113,40.848476]]]},"properties":{"id":"11949","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.608916,40.938431],[-72.618768,40.939143],[-72.631659,40.932025],[-72.615214,40.928819],[-72.608916,40.938431]]]},"properties":{"id":"11931","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.402919,40.759464],[-73.398653,40.733923],[-73.349373,40.75104],[-73.352132,40.766845],[-73.36019,40.769245],[-73.402919,40.759464]]]},"properties":{"id":"11798","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.385396,41.055976],[-72.264415,41.066575],[-72.301989,41.103086],[-72.311302,41.106756],[-72.342102,41.103943],[-72.393878,41.072461],[-72.385396,41.055976]]]},"properties":{"id":"11965","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.708103,40.831241],[-72.713164,40.820339],[-72.690971,40.818322],[-72.708103,40.831241]]],[[[-72.708103,40.831241],[-72.693829,40.85358],[-72.723383,40.844497],[-72.708103,40.831241]]]]},"properties":{"id":"11972","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.874014,40.732097],[-73.042485,40.673317],[-73.040035,40.670883],[-72.872892,40.729828],[-72.874014,40.732097]]],[[[-73.017275,40.795718],[-73.042453,40.785337],[-73.046975,40.77224],[-73.043304,40.765948],[-73.021221,40.74858],[-72.956224,40.742385],[-72.959603,40.797294],[-73.017275,40.795718]]]]},"properties":{"id":"11772","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.90321,40.929375],[-72.864475,40.933387],[-72.863614,40.973824],[-72.913477,40.9713],[-72.90321,40.929375]]]},"properties":{"id":"11786","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.054717,40.974434],[-73.038452,40.937698],[-73.012792,40.903423],[-72.976789,40.903614],[-72.984573,40.910711],[-73.020849,40.974603],[-73.054717,40.974434]]]},"properties":{"id":"11766","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.861185,41.472501],[-74.904194,41.459805],[-74.819749,41.43939],[-74.812966,41.443639],[-74.861185,41.472501]]]},"properties":{"id":"12770","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.861668,41.484305],[-74.859883,41.484412],[-74.86002,41.486084],[-74.861668,41.484305]]],[[[-74.859883,41.484412],[-74.840951,41.49175],[-74.849947,41.488753],[-74.859883,41.484412]]],[[[-74.921983,41.521702],[-74.982581,41.506596],[-74.983831,41.505371],[-74.904194,41.459805],[-74.861185,41.472501],[-74.861668,41.484305],[-74.862812,41.497069],[-74.921983,41.521702]]]]},"properties":{"id":"12719","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.592431,41.64132],[-74.630536,41.577487],[-74.624844,41.573086],[-74.552102,41.619411],[-74.592431,41.64132]]]},"properties":{"id":"12775","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.083032,41.813054],[-75.053958,41.729202],[-74.974838,41.721267],[-74.950356,41.756615],[-74.968003,41.791941],[-75.011694,41.792319],[-75.020183,41.772142],[-75.030813,41.771956],[-75.034451,41.796239],[-75.012429,41.794081],[-74.997832,41.822077],[-75.020653,41.826281],[-75.050313,41.834039],[-75.083032,41.813054]]]},"properties":{"id":"12723","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.862812,41.497069],[-74.861668,41.484305],[-74.86002,41.486084],[-74.862812,41.497069]]],[[[-74.862812,41.497069],[-74.849947,41.488753],[-74.840951,41.49175],[-74.854969,41.504542],[-74.868837,41.573322],[-74.818621,41.556861],[-74.790473,41.604722],[-74.799023,41.605665],[-74.910807,41.615382],[-74.917163,41.615882],[-74.932836,41.531959],[-74.921983,41.521702],[-74.862812,41.497069]]]]},"properties":{"id":"12732","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.059263,41.860606],[-75.050313,41.834039],[-75.020653,41.826281],[-75.002404,41.864599],[-75.017021,41.886748],[-75.059263,41.860606]]]},"properties":{"id":"12736","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.416599,42.226497],[-76.443064,42.306518],[-76.53706,42.368053],[-76.538349,42.281755],[-76.536507,42.155403],[-76.461668,42.127576],[-76.416396,42.143716],[-76.416084,42.154638],[-76.416599,42.226497]]]},"properties":{"id":"14883","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.351157,42.414384],[-76.374426,42.394227],[-76.34726,42.414509],[-76.351157,42.414384]]]},"properties":{"id":"14881","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.045096,41.848129],[-74.039581,41.832415],[-73.994048,41.844154],[-73.967715,41.819703],[-73.966046,41.824705],[-73.954782,41.843994],[-73.968783,41.900301],[-73.988683,41.899284],[-73.988861,41.899037],[-74.029504,41.880979],[-74.037806,41.873854],[-74.048267,41.854022],[-74.045096,41.848129]]]},"properties":{"id":"12487","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.001008,42.026673],[-73.989106,42.039254],[-73.999463,42.040253],[-74.001008,42.026673]]]},"properties":{"id":"12456","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.554671,41.766779],[-74.56207,41.737772],[-74.53024,41.719448],[-74.49227,41.697862],[-74.554671,41.766779]]]},"properties":{"id":"12435","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.12695,41.673051],[-74.119524,41.632043],[-74.078222,41.6613],[-74.08026,41.674494],[-74.12695,41.673051]]]},"properties":{"id":"12548","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.097599,41.853259],[-74.118619,41.866045],[-74.114363,41.845831],[-74.106474,41.839861],[-74.097599,41.853259]]]},"properties":{"id":"12419","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.971656,43.535553],[-74.151369,43.523019],[-74.182555,43.52088],[-74.172445,43.453823],[-73.852709,43.454558],[-73.971656,43.535553]]]},"properties":{"id":"12810","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.723923,43.543534],[-73.753549,43.487095],[-73.682489,43.473929],[-73.671544,43.51936],[-73.723923,43.543534]]]},"properties":{"id":"12824","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.897811,43.661971],[-73.771083,43.670807],[-73.775762,43.67593],[-73.82729,43.721177],[-73.899106,43.664217],[-73.897811,43.661971]]]},"properties":{"id":"12817","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.498,43.725123],[-73.603439,43.666741],[-73.546116,43.644296],[-73.498,43.725123]]]},"properties":{"id":"12874","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.899106,43.664217],[-73.961816,43.696956],[-73.892313,43.631348],[-73.897811,43.661971],[-73.899106,43.664217]]]},"properties":{"id":"12862","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.70684,43.634239],[-73.723923,43.543534],[-73.671544,43.51936],[-73.546116,43.644296],[-73.603439,43.666741],[-73.632652,43.678377],[-73.70684,43.634239]]]},"properties":{"id":"12814","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.530591,43.194639],[-73.580363,43.156432],[-73.580254,43.155539],[-73.593635,43.01433],[-73.597445,43.007372],[-73.538282,42.990557],[-73.535676,42.991529],[-73.414378,43.124553],[-73.427171,43.175442],[-73.443055,43.185804],[-73.530591,43.194639]]]},"properties":{"id":"12834","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.258697,43.230555],[-73.397222,43.293389],[-73.401821,43.218267],[-73.427171,43.175442],[-73.414378,43.124553],[-73.345881,43.112952],[-73.261631,43.169499],[-73.258697,43.230555]]]},"properties":{"id":"12865","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.953724,43.171639],[-76.878036,43.152122],[-76.851099,43.150627],[-76.836222,43.181149],[-76.939492,43.250025],[-76.989798,43.236609],[-76.965129,43.173868],[-76.953724,43.171639]]]},"properties":{"id":"14516","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.817977,41.2374],[-73.847134,41.253368],[-73.923534,41.248755],[-73.940359,41.233056],[-73.876196,41.17722],[-73.817977,41.2374]]]},"properties":{"id":"10520","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.83344,40.949478],[-73.849633,40.946863],[-73.85051,40.944977],[-73.844578,40.923898],[-73.840874,40.927722],[-73.8054,40.926836],[-73.803896,40.928346],[-73.801772,40.944289],[-73.818366,40.944418],[-73.83344,40.949478]]]},"properties":{"id":"10708","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.653966,41.229249],[-73.727803,41.246365],[-73.680138,41.207166],[-73.653966,41.229249]]]},"properties":{"id":"10507","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.658844,41.332683],[-73.674947,41.302191],[-73.614036,41.307878],[-73.585567,41.30257],[-73.549879,41.301522],[-73.544728,41.366375],[-73.63788,41.357348],[-73.658844,41.332683]]]},"properties":{"id":"10560","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.614036,41.307878],[-73.674947,41.302191],[-73.677526,41.302008],[-73.68155,41.297625],[-73.693258,41.296462],[-73.702247,41.268127],[-73.709372,41.265112],[-73.614497,41.302955],[-73.614036,41.307878]]]},"properties":{"id":"10526","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.825154,40.917899],[-73.815167,40.902608],[-73.81367,40.91108],[-73.825154,40.917899]]]},"properties":{"id":"10553","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.825154,40.917899],[-73.81367,40.91108],[-73.805023,40.926132],[-73.8054,40.926836],[-73.840874,40.927722],[-73.825154,40.917899]]]},"properties":{"id":"10552","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.692476,41.004725],[-73.657615,40.985499],[-73.694681,41.05979],[-73.70389,41.055179],[-73.692476,41.004725]]]},"properties":{"id":"10573","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.76651,41.306291],[-73.768764,41.277282],[-73.753398,41.287372],[-73.746264,41.304493],[-73.76651,41.306291]]]},"properties":{"id":"10501","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.826853,41.081218],[-73.784198,41.096574],[-73.793219,41.114017],[-73.801754,41.109787],[-73.828811,41.081661],[-73.826853,41.081218]]]},"properties":{"id":"10532","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.026193,42.713333],[-78.08156,42.640826],[-78.10394,42.564888],[-78.045237,42.570018],[-78.015381,42.609449],[-77.988904,42.625263],[-78.026193,42.713333]]]},"properties":{"id":"14427","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.304019,42.693335],[-78.2647,42.695663],[-78.248667,42.771663],[-78.384629,42.787468],[-78.387929,42.704757],[-78.304019,42.693335]]]},"properties":{"id":"14167","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.015034,42.757715],[-77.007175,42.764031],[-77.014784,42.763863],[-77.015034,42.757715]]],[[[-77.015034,42.757715],[-77.031302,42.763752],[-77.105732,42.758085],[-77.152191,42.739205],[-77.225109,42.669053],[-77.141754,42.629603],[-77.09058,42.620206],[-77.099999,42.541168],[-77.005779,42.555997],[-76.920724,42.630985],[-76.949391,42.684687],[-76.962789,42.684394],[-76.958416,42.695684],[-76.971392,42.764223],[-77.000842,42.764079],[-77.015034,42.757715]]]]},"properties":{"id":"14527","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.809204,42.639149],[-73.783968,42.626199],[-73.776897,42.62545],[-73.772669,42.647334],[-73.809204,42.639149]]]},"properties":{"id":"12209","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.839175,42.428109],[-73.803156,42.433517],[-73.805843,42.440742],[-73.839175,42.428109]]],[[[-73.839175,42.428109],[-73.838981,42.444617],[-73.886126,42.451507],[-73.915632,42.447295],[-73.941014,42.454704],[-73.96774,42.476311],[-73.969502,42.441027],[-73.948216,42.412315],[-73.904465,42.399567],[-73.881435,42.395874],[-73.839175,42.428109]]]]},"properties":{"id":"12087","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.954155,42.803182],[-73.948823,42.819886],[-74.031635,42.869371],[-74.047792,42.870985],[-74.112059,42.816269],[-74.143337,42.79342],[-74.029904,42.748447],[-73.963082,42.735629],[-73.954155,42.803182]]]},"properties":{"id":"12306","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.168867,42.681187],[-74.266508,42.727578],[-74.314667,42.710985],[-74.397764,42.679895],[-74.431363,42.644895],[-74.414819,42.637923],[-74.270091,42.629335],[-74.168867,42.681187]]]},"properties":{"id":"12157","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.803168,42.25379],[-77.782163,42.242437],[-77.781212,42.26226],[-77.803168,42.25379]]]},"properties":{"id":"14802","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.06209,42.033784],[-78.121515,42.000389],[-78.02476,41.999319],[-78.06209,42.033784]]]},"properties":{"id":"14708","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.595866,42.407766],[-77.753219,42.348234],[-77.736485,42.288231],[-77.740245,42.216741],[-77.632925,42.20185],[-77.509508,42.335959],[-77.510231,42.345657],[-77.546118,42.410305],[-77.595866,42.407766]]]},"properties":{"id":"14843","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.208452,42.128094],[-78.229997,42.260772],[-78.253191,42.339455],[-78.260153,42.348661],[-78.261372,42.359412],[-78.251153,42.36565],[-78.308812,42.370911],[-78.38894,42.261348],[-78.368112,42.133635],[-78.327012,42.093653],[-78.248166,42.086524],[-78.208452,42.128094]]]},"properties":{"id":"14727","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.045237,42.570018],[-78.043418,42.518464],[-77.968373,42.452107],[-77.955993,42.474325],[-77.954931,42.556431],[-77.980141,42.609937],[-78.015381,42.609449],[-78.045237,42.570018]]]},"properties":{"id":"14846","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.871745,40.844191],[-73.877418,40.85538],[-73.88382,40.851499],[-73.892169,40.84105],[-73.896966,40.834343],[-73.896302,40.833543],[-73.879983,40.83468],[-73.866806,40.834837],[-73.871745,40.844191]]]},"properties":{"id":"10460","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.900567,40.836592],[-73.913137,40.839814],[-73.920461,40.830915],[-73.911948,40.820154],[-73.90299,40.820027],[-73.896302,40.833543],[-73.896966,40.834343],[-73.900567,40.836592]]]},"properties":{"id":"10456","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.911049,40.845143],[-73.928242,40.845353],[-73.932941,40.828213],[-73.920461,40.830915],[-73.913137,40.839814],[-73.911049,40.845143]]]},"properties":{"id":"10452","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.870148,40.878314],[-73.871896,40.854075],[-73.868383,40.857812],[-73.870148,40.878314]]],[[[-73.855691,40.884212],[-73.861791,40.857983],[-73.828237,40.861021],[-73.838743,40.881266],[-73.855691,40.884212]]]]},"properties":{"id":"10469","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.819719,40.890206],[-73.838743,40.881266],[-73.828237,40.861021],[-73.824957,40.846105],[-73.815674,40.848855],[-73.816752,40.864544],[-73.815165,40.888999],[-73.819719,40.890206]]]},"properties":{"id":"10475","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.994741,42.114292],[-76.108922,42.074331],[-76.10584,41.998858],[-75.958407,41.998987],[-75.948424,42.094791],[-75.994741,42.114292]],[[-75.967205,42.09541],[-75.960508,42.090904],[-75.964678,42.081705],[-75.975779,42.085051],[-75.967205,42.09541]]]},"properties":{"id":"13850","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.983166,42.347983],[-76.016936,42.258887],[-75.945031,42.26368],[-75.934609,42.246608],[-75.914766,42.233574],[-75.915318,42.236288],[-75.887663,42.27196],[-75.871369,42.36361],[-75.878386,42.414927],[-75.882294,42.420293],[-75.951629,42.411427],[-75.983166,42.347983]]]},"properties":{"id":"13862","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.647199,42.123378],[-75.650385,42.108094],[-75.641338,42.103374],[-75.636301,42.091705],[-75.640028,42.086955],[-75.631468,42.082249],[-75.63109,42.097926],[-75.635439,42.106059],[-75.64596,42.116064],[-75.647199,42.123378]]]},"properties":{"id":"13826","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.958566,42.249165],[-78.978322,42.325091],[-79.060515,42.292942],[-79.08659,42.253813],[-79.097476,42.232844],[-79.101366,42.216643],[-79.034031,42.184279],[-78.958566,42.249165]]]},"properties":{"id":"14726","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.019307,42.08571],[-78.942902,42.084263],[-78.827501,42.120595],[-78.888053,42.23401],[-78.958566,42.249165],[-79.034031,42.184279],[-79.019307,42.08571]]]},"properties":{"id":"14772","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.486312,42.237014],[-78.443624,42.164464],[-78.368112,42.133635],[-78.38894,42.261348],[-78.486312,42.237014]]]},"properties":{"id":"14743","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.755963,42.346702],[-78.72439,42.331473],[-78.670461,42.346636],[-78.728576,42.455063],[-78.777603,42.461882],[-78.812924,42.456159],[-78.755963,42.346702]]]},"properties":{"id":"14729","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.721805,43.006063],[-76.708388,43.004419],[-76.682242,42.954729],[-76.595367,43.010298],[-76.617448,43.132637],[-76.685484,43.162766],[-76.706085,43.137052],[-76.721805,43.006063]]]},"properties":{"id":"13140","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.537824,43.150141],[-76.464264,43.031889],[-76.419617,43.067756],[-76.476997,43.145954],[-76.537824,43.150141]]]},"properties":{"id":"13080","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.361108,43.423272],[-76.270777,43.526379],[-76.617102,43.419064],[-76.605967,43.384826],[-76.529687,43.346712],[-76.471526,43.395829],[-76.474173,43.393297],[-76.489097,43.405075],[-76.476771,43.40545],[-76.472706,43.397555],[-76.361108,43.423272]]]},"properties":{"id":"13126","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.761698,42.107741],[-79.553126,41.998555],[-79.593146,42.103227],[-79.730951,42.120298],[-79.761698,42.107741]]]},"properties":{"id":"14724","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.210412,42.348687],[-79.319615,42.282157],[-79.38221,42.265146],[-79.33824,42.216329],[-79.323009,42.197914],[-79.273493,42.207107],[-79.148764,42.253677],[-79.210412,42.348687]]]},"properties":{"id":"14782","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.273493,42.207107],[-79.244262,42.17322],[-79.162721,42.204908],[-79.128335,42.216487],[-79.097476,42.232844],[-79.08659,42.253813],[-79.148764,42.253677],[-79.273493,42.207107]]]},"properties":{"id":"14740","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.226157,42.501498],[-79.253073,42.477363],[-79.236757,42.484429],[-79.226157,42.501498]]]},"properties":{"id":"14135","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.353371,42.100845],[-79.356281,42.050158],[-79.295063,42.100964],[-79.353371,42.100845]]]},"properties":{"id":"14750","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.838264,42.229404],[-76.869265,42.261329],[-76.901381,42.270109],[-76.838264,42.229404]]]},"properties":{"id":"14872","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.691069,42.313319],[-76.764835,42.294297],[-76.729688,42.232012],[-76.642256,42.233721],[-76.657456,42.283672],[-76.691069,42.313319]]]},"properties":{"id":"14824","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.704221,42.708036],[-75.58239,42.629404],[-75.540266,42.641115],[-75.549952,42.699319],[-75.684093,42.73415],[-75.704221,42.708036]]]},"properties":{"id":"13464","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.877159,42.575737],[-75.883843,42.655634],[-75.905537,42.654574],[-75.877159,42.575737]]],[[[-75.91983,42.818506],[-75.885433,42.674327],[-75.838639,42.639353],[-75.826601,42.644548],[-75.830731,42.685707],[-75.800979,42.697557],[-75.837258,42.804621],[-75.920301,42.82033],[-75.91983,42.818506]]]]},"properties":{"id":"13052","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.737917,44.913014],[-73.671913,44.78401],[-73.669488,44.773357],[-73.623006,44.789274],[-73.554859,44.897487],[-73.637671,44.926609],[-73.737917,44.913014]]]},"properties":{"id":"12910","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.738826,44.718723],[-73.836341,44.71046],[-73.959255,44.663219],[-73.954578,44.632385],[-73.936169,44.512712],[-73.861308,44.518345],[-73.719349,44.607578],[-73.708318,44.719009],[-73.738826,44.718723]],[[-73.811519,44.605428],[-73.828308,44.618628],[-73.805104,44.631762],[-73.794802,44.616227],[-73.811519,44.605428]]]},"properties":{"id":"12981","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.811519,44.605428],[-73.794802,44.616227],[-73.805104,44.631762],[-73.828308,44.618628],[-73.811519,44.605428]]]},"properties":{"id":"12978","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.96691,44.852376],[-73.991793,44.747854],[-73.977876,44.70834],[-73.927314,44.749068],[-73.897588,44.765385],[-73.96691,44.852376]]]},"properties":{"id":"12955","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.708318,44.719009],[-73.719349,44.607578],[-73.648631,44.63161],[-73.669488,44.773357],[-73.671913,44.78401],[-73.735511,44.720464],[-73.708318,44.719009]]]},"properties":{"id":"12918","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.445833,42.366592],[-73.414251,42.342469],[-73.382016,42.429488],[-73.427301,42.456771],[-73.445833,42.366592]]]},"properties":{"id":"12029","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.665134,42.253236],[-73.633851,42.244461],[-73.631208,42.256483],[-73.665134,42.253236]]]},"properties":{"id":"12565","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.260957,42.540636],[-76.289343,42.441694],[-76.269718,42.407158],[-76.23101,42.427987],[-76.215695,42.44304],[-76.206304,42.479753],[-76.260957,42.540636]]]},"properties":{"id":"13053","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.692152,42.057477],[-74.56458,42.044667],[-74.583337,42.168757],[-74.585937,42.175206],[-74.591997,42.222896],[-74.593049,42.213054],[-74.598905,42.20804],[-74.599679,42.20268],[-74.606473,42.204231],[-74.595466,42.213959],[-74.593992,42.223677],[-74.649131,42.24568],[-74.71364,42.234229],[-74.733763,42.22626],[-74.692152,42.057477]]]},"properties":{"id":"12455","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.047347,41.995437],[-74.869796,42.073349],[-74.869046,42.079167],[-74.944055,42.094413],[-75.021117,42.131357],[-75.090905,42.102521],[-75.047347,41.995437]]]},"properties":{"id":"13755","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.047347,41.995437],[-75.029328,41.903972],[-75.017021,41.886748],[-75.002404,41.864599],[-74.979735,41.839803],[-74.975943,41.838958],[-74.898022,41.876718],[-74.810211,42.029108],[-74.869796,42.073349],[-75.047347,41.995437]]]},"properties":{"id":"12776","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.118445,42.471729],[-75.120891,42.472257],[-75.120142,42.471039],[-75.118445,42.471729]]],[[[-75.118445,42.471729],[-75.122597,42.470142],[-75.151783,42.383125],[-75.07437,42.390149],[-75.018205,42.388395],[-75.006329,42.384723],[-74.919674,42.446585],[-74.920544,42.450026],[-74.92079,42.454105],[-74.935856,42.445905],[-74.955837,42.445689],[-74.968706,42.468435],[-74.969457,42.522654],[-75.04854,42.594465],[-75.073831,42.547884],[-75.139081,42.517334],[-75.118445,42.471729]]]]},"properties":{"id":"13820","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.817864,42.313255],[-74.83196,42.230249],[-74.831773,42.218638],[-74.733763,42.22626],[-74.71364,42.234229],[-74.67289,42.274067],[-74.663491,42.28568],[-74.741561,42.319239],[-74.817864,42.313255]]]},"properties":{"id":"13740","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.470022,42.171911],[-74.489047,42.135012],[-74.439853,42.16473],[-74.450322,42.168698],[-74.470022,42.171911]]]},"properties":{"id":"12465","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.852278,41.735793],[-73.805006,41.672416],[-73.742801,41.715663],[-73.743165,41.731317],[-73.750126,41.770864],[-73.81723,41.786513],[-73.855903,41.756536],[-73.852278,41.735793]]]},"properties":{"id":"12569","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.648605,41.708979],[-73.632837,41.674588],[-73.630526,41.647098],[-73.525943,41.753488],[-73.592101,41.787797],[-73.648605,41.708979]]]},"properties":{"id":"12522","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.902875,42.906291],[-78.881902,42.904769],[-78.888804,42.936594],[-78.902057,42.931238],[-78.902875,42.906291]]]},"properties":{"id":"14213","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.803659,42.898208],[-78.784477,42.890077],[-78.784346,42.900522],[-78.803659,42.898208]]],[[[-78.803659,42.898208],[-78.853486,42.893154],[-78.853394,42.887817],[-78.803659,42.898208]]]]},"properties":{"id":"14212","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.833947,42.923073],[-78.813902,42.959443],[-78.82384,42.958126],[-78.837037,42.958384],[-78.865779,42.929484],[-78.843426,42.921934],[-78.833947,42.923073]]]},"properties":{"id":"14214","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.696925,42.949866],[-78.696955,42.93673],[-78.677082,42.947798],[-78.696925,42.949866]]]},"properties":{"id":"14026","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.533186,42.531235],[-78.519078,42.51916],[-78.515775,42.531774],[-78.525062,42.533437],[-78.533186,42.531235]]]},"properties":{"id":"14134","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.782088,42.997009],[-78.775512,43.006532],[-78.780179,43.00827],[-78.782088,42.997009]]],[[[-78.828805,43.030139],[-78.822389,43.001374],[-78.802252,42.994255],[-78.790693,43.01251],[-78.734222,43.04713],[-78.727647,43.08622],[-78.733606,43.084219],[-78.828805,43.030139]]]]},"properties":{"id":"14228","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.475492,42.735813],[-78.61409,42.693716],[-78.495844,42.6933],[-78.475492,42.735813]]]},"properties":{"id":"14139","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.666733,43.085364],[-78.681987,43.000239],[-78.670013,43.00026],[-78.576245,43.031222],[-78.576065,43.079758],[-78.666733,43.085364]]]},"properties":{"id":"14032","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.696956,42.860034],[-78.696915,42.811166],[-78.683497,42.796328],[-78.577549,42.82302],[-78.55567,42.850823],[-78.555852,42.856568],[-78.562934,42.862751],[-78.685498,42.863958],[-78.696956,42.860034]]]},"properties":{"id":"14059","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.52883,44.196792],[-73.55069,44.106455],[-73.525072,44.109095],[-73.52883,44.196792]]],[[[-73.672837,44.278737],[-73.712716,44.251456],[-73.707637,44.211087],[-73.701172,44.1734],[-73.536434,44.209729],[-73.567434,44.247573],[-73.672837,44.278737]]]]},"properties":{"id":"12932","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.941801,44.343643],[-74.056991,44.288087],[-74.115775,44.261279],[-74.098009,44.162367],[-73.976197,44.140691],[-73.875387,44.199409],[-73.891545,44.298158],[-73.941801,44.343643]]]},"properties":{"id":"12946","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.625254,43.883659],[-73.634234,43.914818],[-73.628835,43.88356],[-73.625254,43.883659]]],[[[-73.864839,43.913857],[-73.86869,43.762803],[-73.785375,43.770641],[-73.638151,43.784852],[-73.618204,43.786777],[-73.627197,43.877396],[-73.715072,43.877933],[-73.726167,43.873212],[-73.731268,43.880918],[-73.692865,43.929821],[-73.864839,43.913857]]]]},"properties":{"id":"12870","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.02175,43.951568],[-74.235542,43.830984],[-74.057005,43.744513],[-73.950925,43.761669],[-73.929878,43.884268],[-74.02175,43.951568]]]},"properties":{"id":"12851","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.86869,43.762803],[-73.864839,43.913857],[-73.929878,43.884268],[-73.950925,43.761669],[-73.878704,43.761701],[-73.86869,43.762803]]]},"properties":{"id":"12857","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.629872,44.098484],[-73.617678,44.066167],[-73.557041,44.066898],[-73.519221,44.07641],[-73.55069,44.106455],[-73.629872,44.098484]]]},"properties":{"id":"12998","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.629872,44.098484],[-73.687628,44.094553],[-73.964014,44.067013],[-73.951675,43.993718],[-74.02175,43.951568],[-73.929878,43.884268],[-73.864839,43.913857],[-73.692865,43.929821],[-73.641899,43.9325],[-73.61757,43.989401],[-73.622367,44.024592],[-73.623193,44.030972],[-73.617678,44.066167],[-73.629872,44.098484]]]},"properties":{"id":"12855","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.875387,44.199409],[-73.976197,44.140691],[-73.964014,44.067013],[-73.687628,44.094553],[-73.701172,44.1734],[-73.707637,44.211087],[-73.875387,44.199409]]]},"properties":{"id":"12943","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.507232,44.061631],[-73.519221,44.07641],[-73.557041,44.066898],[-73.623193,44.030972],[-73.622367,44.024592],[-73.509386,44.061254],[-73.507232,44.061631]]]},"properties":{"id":"12961","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.785375,43.770641],[-73.738116,43.725524],[-73.638602,43.743025],[-73.638151,43.784852],[-73.785375,43.770641]]]},"properties":{"id":"12808","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.187951,44.648182],[-74.169761,44.559826],[-74.147768,44.44436],[-74.145335,44.429841],[-74.088449,44.412479],[-73.936128,44.501738],[-73.936169,44.512712],[-73.954578,44.632385],[-74.187951,44.648182]]]},"properties":{"id":"12989","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.187951,44.648182],[-73.954578,44.632385],[-73.959255,44.663219],[-73.977876,44.70834],[-73.991793,44.747854],[-74.11819,44.788743],[-74.187951,44.648182]]]},"properties":{"id":"12969","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.553389,43.154329],[-74.546406,43.08913],[-74.537703,43.086198],[-74.467094,43.12247],[-74.457719,43.232885],[-74.650818,43.371539],[-74.663197,43.272036],[-74.553389,43.154329]]]},"properties":{"id":"12032","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.314345,42.933458],[-74.262926,42.831569],[-74.26287,42.823409],[-74.207546,42.836553],[-74.084844,42.896869],[-74.0085,42.947174],[-73.99858,42.975194],[-74.052561,43.007276],[-74.10203,43.011771],[-74.216995,43.04744],[-74.234535,43.049601],[-74.247595,43.024718],[-74.271703,42.952993],[-74.292928,42.941667],[-74.314345,42.933458]]]},"properties":{"id":"12010","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.123854,43.044988],[-78.099229,43.131401],[-78.142152,43.165291],[-78.23451,43.131617],[-78.202686,43.049925],[-78.123854,43.044988]]]},"properties":{"id":"14058","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.016842,43.132419],[-78.123965,43.030301],[-78.115808,43.014158],[-78.080871,43.018557],[-77.908666,43.037266],[-77.93942,43.136544],[-77.99729,43.132981],[-78.016842,43.132419]]]},"properties":{"id":"14416","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.113781,42.96391],[-78.192369,42.913796],[-78.191396,42.870336],[-78.163365,42.864523],[-78.165159,42.858037],[-78.117019,42.881337],[-78.072073,42.936718],[-78.113781,42.96391]]]},"properties":{"id":"14054","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.419095,43.130341],[-78.465595,43.291165],[-78.519825,43.292106],[-78.524968,43.127969],[-78.464962,43.117341],[-78.419095,43.130341]]]},"properties":{"id":"14105","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.162265,42.335161],[-74.171372,42.311147],[-74.100466,42.293443],[-74.143122,42.314848],[-74.150226,42.338299],[-74.162265,42.335161]]],[[[-74.195298,42.29723],[-74.225797,42.280058],[-74.239653,42.266225],[-74.204296,42.262577],[-74.190164,42.268284],[-74.195298,42.29723]]]]},"properties":{"id":"12439","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.13432,42.224861],[-74.11279,42.182208],[-74.047566,42.158633],[-74.042393,42.170386],[-74.056377,42.221623],[-74.064819,42.225216],[-74.13432,42.224861]]]},"properties":{"id":"12436","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.372574,42.285834],[-74.376285,42.245934],[-74.325722,42.215722],[-74.32465,42.241622],[-74.323066,42.284867],[-74.372574,42.285834]]]},"properties":{"id":"12452","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.951301,42.356856],[-73.971581,42.299075],[-73.957516,42.27795],[-73.940446,42.265562],[-73.882531,42.251703],[-73.879009,42.257479],[-73.922142,42.294037],[-73.950374,42.356918],[-73.951301,42.356856]]]},"properties":{"id":"12451","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.005262,42.284202],[-74.096671,42.263316],[-74.064819,42.225216],[-74.056377,42.221623],[-74.013191,42.242092],[-73.985843,42.255266],[-74.005262,42.284202]]]},"properties":{"id":"12473","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.940446,42.265562],[-73.957516,42.27795],[-73.974638,42.260613],[-73.940446,42.265562]]]},"properties":{"id":"12482","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.326785,43.619568],[-74.333749,43.443568],[-74.478258,43.380476],[-74.472144,43.336536],[-74.158083,43.359527],[-74.1601,43.371532],[-74.172445,43.453823],[-74.182555,43.52088],[-74.203537,43.657166],[-74.326785,43.619568]]]},"properties":{"id":"12190","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.36332,43.655084],[-74.333749,43.443568],[-74.326785,43.619568],[-74.36332,43.655084]]]},"properties":{"id":"12164","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.064286,42.925472],[-75.110014,42.810113],[-75.074717,42.787773],[-75.017678,42.780896],[-74.942258,42.801711],[-74.898783,42.829455],[-74.84155,42.873654],[-74.875869,42.910862],[-75.03685,42.932627],[-75.064286,42.925472]]]},"properties":{"id":"13439","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.916816,43.018479],[-75.018005,43.016102],[-75.03685,42.932627],[-74.875869,42.910862],[-74.831523,42.944583],[-74.830157,42.955606],[-74.916816,43.018479]]]},"properties":{"id":"13407","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.018811,43.016189],[-75.157562,42.941789],[-75.064286,42.925472],[-75.03685,42.932627],[-75.018005,43.016102],[-75.018811,43.016189]]]},"properties":{"id":"13357","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.590244,43.903513],[-75.775472,43.696826],[-75.774553,43.688884],[-75.741106,43.646808],[-75.674873,43.651977],[-75.567227,43.656081],[-75.455746,43.698365],[-75.455763,43.727389],[-75.484957,43.727072],[-75.500416,43.741683],[-75.468112,43.746209],[-75.435473,43.722839],[-75.133487,43.802515],[-75.005764,43.85904],[-74.837936,43.952966],[-74.854171,44.070089],[-74.889302,44.066829],[-74.945918,44.06155],[-75.075675,44.055971],[-75.170159,44.096959],[-75.164076,44.049035],[-75.391924,43.885959],[-75.568909,43.93137],[-75.590244,43.903513]]]},"properties":{"id":"13367","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.802408,43.137034],[-74.72244,43.146222],[-74.712615,43.286143],[-74.748005,43.298253],[-74.821397,43.143143],[-74.802408,43.137034]]]},"properties":{"id":"13454","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.049969,43.129967],[-75.072762,43.151548],[-75.106448,43.218448],[-75.179093,43.194678],[-75.220586,43.178642],[-75.254011,43.112839],[-75.266603,43.114891],[-75.276041,43.102126],[-75.28543,43.074511],[-75.181502,43.092873],[-75.049969,43.129967]]]},"properties":{"id":"13502","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.28543,43.074511],[-75.276041,43.102126],[-75.285046,43.101273],[-75.304226,43.096541],[-75.344133,43.095756],[-75.303435,43.027632],[-75.276355,43.019196],[-75.273483,43.042188],[-75.1963,43.035533],[-75.209411,43.062765],[-75.28543,43.074511]]]},"properties":{"id":"13413","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.829408,44.019118],[-75.80491,43.953947],[-75.735638,44.012294],[-75.738771,44.027866],[-75.766027,44.023716],[-75.814873,44.022739],[-75.829408,44.019118]]]},"properties":{"id":"13612","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.88246,44.124679],[-76.026179,44.111887],[-76.01415,44.071396],[-75.980703,44.067309],[-75.981498,44.000612],[-76.057633,43.957114],[-76.054668,43.89967],[-75.912816,43.889781],[-75.84708,43.822246],[-75.741578,43.919372],[-75.80491,43.953947],[-75.829408,44.019118],[-75.880358,44.048138],[-75.88246,44.124679]]]},"properties":{"id":"13601","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.913002,43.784242],[-75.982968,43.72272],[-75.976531,43.703003],[-75.866141,43.695482],[-75.774553,43.688884],[-75.775472,43.696826],[-75.849899,43.791862],[-75.913002,43.784242]]]},"properties":{"id":"13659","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.775472,43.696826],[-75.590244,43.903513],[-75.741578,43.919372],[-75.84708,43.822246],[-75.849899,43.791862],[-75.775472,43.696826]]]},"properties":{"id":"13626","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.854062,44.270564],[-75.881647,44.137129],[-75.784204,44.135684],[-75.693971,44.229003],[-75.628517,44.299102],[-75.832299,44.287766],[-75.854062,44.270564]]]},"properties":{"id":"13691","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.067994,43.736173],[-76.058681,43.734974],[-76.067308,43.737923],[-76.067994,43.736173]]],[[[-76.067994,43.736173],[-76.0729,43.740668],[-76.120772,43.778742],[-76.213536,43.750068],[-76.201946,43.680312],[-76.166927,43.639451],[-76.066391,43.671328],[-75.976531,43.703003],[-75.982968,43.72272],[-76.044731,43.738596],[-76.058681,43.734974],[-76.067994,43.736173]],[[-76.142662,43.728605],[-76.124507,43.761061],[-76.098087,43.759119],[-76.089194,43.730465],[-76.118287,43.739168],[-76.142662,43.728605]]]]},"properties":{"id":"13661","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.913002,43.784242],[-75.849899,43.791862],[-75.84708,43.822246],[-75.912816,43.889781],[-75.975473,43.833146],[-75.913002,43.784242]]]},"properties":{"id":"13682","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.989992,40.683319],[-74.008117,40.686615],[-73.998659,40.671705],[-73.98856,40.679559],[-73.989992,40.683319]]]},"properties":{"id":"11231","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.921776,40.654424],[-73.922207,40.641065],[-73.898261,40.622989],[-73.878901,40.638038],[-73.889994,40.651138],[-73.899041,40.657372],[-73.921776,40.654424]]]},"properties":{"id":"11236","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.568909,43.93137],[-75.391924,43.885959],[-75.4145,43.890044],[-75.417005,43.901844],[-75.401204,43.903849],[-75.386362,43.986949],[-75.431689,43.982736],[-75.568909,43.93137]]]},"properties":{"id":"13620","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.820979,42.606605],[-77.840848,42.557438],[-77.840694,42.517771],[-77.840638,42.503237],[-77.751401,42.47187],[-77.64142,42.477421],[-77.649414,42.54202],[-77.631899,42.542648],[-77.634755,42.627492],[-77.655648,42.669261],[-77.685504,42.67227],[-77.718231,42.661444],[-77.784397,42.680686],[-77.820979,42.606605]]]},"properties":{"id":"14437","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.655648,42.669261],[-77.600823,42.671972],[-77.620656,42.763186],[-77.645738,42.771284],[-77.712314,42.763608],[-77.727841,42.752043],[-77.717375,42.710503],[-77.718062,42.672494],[-77.685504,42.67227],[-77.655648,42.669261]]]},"properties":{"id":"14435","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.717375,42.710503],[-77.727841,42.752043],[-77.711766,42.819689],[-77.715993,42.823388],[-77.715155,42.855871],[-77.830104,42.857337],[-77.856891,42.794249],[-77.852765,42.786599],[-77.752791,42.714478],[-77.717375,42.710503]]]},"properties":{"id":"14454","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.784397,42.680686],[-77.718231,42.661444],[-77.718062,42.672494],[-77.717375,42.710503],[-77.752791,42.714478],[-77.784397,42.680686]]]},"properties":{"id":"14462","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.643213,42.880853],[-75.584013,42.874826],[-75.606336,42.91019],[-75.644027,42.96543],[-75.669334,42.996914],[-75.691717,42.974637],[-75.687888,42.968185],[-75.683957,42.967376],[-75.68172,42.965144],[-75.68749,42.964856],[-75.746208,42.913396],[-75.685816,42.864297],[-75.677632,42.859755],[-75.643213,42.880853]]]},"properties":{"id":"13408","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.574205,43.036461],[-75.644027,42.96543],[-75.606336,42.91019],[-75.566898,42.931592],[-75.544967,42.956309],[-75.54758,42.986036],[-75.574205,43.036461]]]},"properties":{"id":"13409","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.907071,43.128682],[-75.879235,43.153704],[-75.994217,43.18218],[-76.016851,43.141061],[-75.983659,43.137319],[-75.907071,43.128682]]]},"properties":{"id":"13030","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.879235,43.153704],[-75.907071,43.128682],[-75.932381,43.058802],[-75.930496,43.04899],[-75.898543,43.025224],[-75.803937,42.992402],[-75.86451,43.146995],[-75.879235,43.153704]]]},"properties":{"id":"13037","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.930496,43.04899],[-76.023981,42.991839],[-75.995482,42.927108],[-75.916178,42.87746],[-75.905265,42.880418],[-75.898543,43.025224],[-75.930496,43.04899]]]},"properties":{"id":"13104","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.337713,42.809945],[-75.357649,42.826209],[-75.355599,42.8115],[-75.351668,42.80933],[-75.337713,42.809945]]],[[[-75.337713,42.809945],[-75.290371,42.822699],[-75.301721,42.846469],[-75.304945,42.846386],[-75.337713,42.809945]]]]},"properties":{"id":"13314","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.322117,42.987944],[-75.388076,42.987802],[-75.421214,42.948841],[-75.440332,42.893691],[-75.452778,42.86763],[-75.404148,42.853004],[-75.35391,42.867338],[-75.304945,42.846386],[-75.301721,42.846469],[-75.283162,42.870334],[-75.304177,42.900487],[-75.293276,42.960166],[-75.322117,42.987944]]]},"properties":{"id":"13480","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.965723,43.28974],[-77.99729,43.132981],[-77.93942,43.136544],[-77.895314,43.145565],[-77.847315,43.236037],[-77.932449,43.27023],[-77.965723,43.28974]]]},"properties":{"id":"14420","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.63027,43.224926],[-77.671353,43.238346],[-77.680342,43.192545],[-77.659409,43.187376],[-77.620382,43.192635],[-77.626685,43.205991],[-77.63027,43.224926]]]},"properties":{"id":"14615","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.514169,43.122132],[-77.528403,43.163304],[-77.569673,43.153357],[-77.575187,43.143347],[-77.5754,43.136255],[-77.512657,43.1192],[-77.514169,43.122132]]]},"properties":{"id":"14610","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.585283,42.909103],[-74.709837,42.847611],[-74.665249,42.80458],[-74.495956,42.788435],[-74.492388,42.896262],[-74.473437,42.897793],[-74.478675,42.899926],[-74.585283,42.909103]]]},"properties":{"id":"13317","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.503875,40.743068],[-73.488728,40.722337],[-73.482723,40.712576],[-73.471009,40.717274],[-73.469264,40.738162],[-73.468748,40.761599],[-73.500899,40.767567],[-73.503875,40.743068]]]},"properties":{"id":"11714","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.632503,40.837168],[-73.639274,40.828133],[-73.612371,40.8062],[-73.59859,40.815299],[-73.591491,40.823832],[-73.58305,40.807167],[-73.548363,40.799048],[-73.535855,40.809305],[-73.555087,40.83193],[-73.567301,40.832434],[-73.580806,40.847885],[-73.594242,40.856779],[-73.635706,40.846082],[-73.632503,40.837168]]]},"properties":{"id":"11545","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.609095,40.720783],[-73.643173,40.712701],[-73.632737,40.690284],[-73.619878,40.680358],[-73.607467,40.684493],[-73.599255,40.715797],[-73.603532,40.714288],[-73.609095,40.720783]]]},"properties":{"id":"11550","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.643173,40.712701],[-73.66377,40.710776],[-73.667481,40.682967],[-73.66014,40.671096],[-73.632737,40.690284],[-73.643173,40.712701]]]},"properties":{"id":"11552","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.677403,40.628768],[-73.661943,40.623054],[-73.656875,40.64986],[-73.676865,40.643276],[-73.677403,40.628768]]]},"properties":{"id":"11518","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.652467,40.617093],[-73.663572,40.595205],[-73.637571,40.595839],[-73.631873,40.609503],[-73.652467,40.617093]]]},"properties":{"id":"11558","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.635706,40.846082],[-73.648801,40.853566],[-73.651787,40.834987],[-73.632503,40.837168],[-73.635706,40.846082]]]},"properties":{"id":"11579","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.993948,40.751522],[-73.99679,40.752723],[-73.99768,40.751485],[-73.994844,40.750293],[-73.993948,40.751522]]]},"properties":{"id":"10199","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.007769,40.702879],[-74.012508,40.706767],[-74.015905,40.706658],[-74.015531,40.700273],[-74.007531,40.702596],[-74.007769,40.702879]]],[[[-74.02213,40.684541],[-74.012306,40.689281],[-74.020282,40.692201],[-74.02213,40.684541]]]]},"properties":{"id":"10004","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.001163,40.762021],[-73.981341,40.758645],[-73.980886,40.75927],[-73.980426,40.759899],[-73.977989,40.759689],[-73.976744,40.759165],[-73.976289,40.759787],[-73.979513,40.761148],[-73.979057,40.761771],[-73.975831,40.760418],[-73.973465,40.763659],[-73.973015,40.764279],[-73.982067,40.768481],[-73.989994,40.772242],[-73.993836,40.772825],[-74.001716,40.762208],[-74.001163,40.762021]]]},"properties":{"id":"10019","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.013754,40.71369],[-74.009988,40.709806],[-74.000455,40.714365],[-74.002115,40.715159],[-74.003115,40.714082],[-74.00542,40.715236],[-74.004533,40.71628],[-74.012894,40.71719],[-74.013754,40.71369]],[[-74.007666,40.712533],[-74.0081,40.711995],[-74.009668,40.712725],[-74.009254,40.713256],[-74.007666,40.712533]]]},"properties":{"id":"10007","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.971451,40.757749],[-73.970993,40.758373],[-73.972613,40.759059],[-73.973068,40.758432],[-73.971451,40.757749]]]},"properties":{"id":"10152","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.013754,40.71369],[-74.012894,40.71719],[-74.013046,40.719086],[-74.017368,40.713378],[-74.013754,40.71369]]]},"properties":{"id":"10282","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.951858,40.769441],[-73.949266,40.768355],[-73.94827,40.769505],[-73.951858,40.769441]]]},"properties":{"id":"10162","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.97282,40.75587],[-73.974439,40.756556],[-73.974897,40.755928],[-73.973276,40.755243],[-73.97282,40.75587]]]},"properties":{"id":"10171","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.020886,43.136097],[-79.003972,43.138035],[-79.022266,43.141602],[-79.020886,43.136097]]],[[[-78.988789,43.160996],[-78.973006,43.144593],[-78.933696,43.142968],[-78.930186,43.184822],[-78.967701,43.210517],[-79.046567,43.192742],[-79.039418,43.1414],[-79.038569,43.141371],[-78.988789,43.160996]]]]},"properties":{"id":"14092","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.95257,43.291847],[-78.967701,43.210517],[-78.930186,43.184822],[-78.855553,43.189919],[-78.814201,43.220304],[-78.900763,43.303755],[-78.95257,43.291847]]]},"properties":{"id":"14131","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.051034,43.132055],[-79.020886,43.136097],[-79.022266,43.141602],[-79.038569,43.141371],[-79.039418,43.1414],[-79.051034,43.132055]]]},"properties":{"id":"14109","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.667745,43.294921],[-78.755064,43.287187],[-78.77935,43.219551],[-78.651103,43.257608],[-78.667745,43.294921]]]},"properties":{"id":"14108","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.304177,42.900487],[-75.283162,42.870334],[-75.242745,42.877869],[-75.304177,42.900487]]]},"properties":{"id":"13313","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.317264,43.320834],[-75.372144,43.283053],[-75.304278,43.23117],[-75.263628,43.216139],[-75.220586,43.178642],[-75.179093,43.194678],[-75.222391,43.288813],[-75.317264,43.320834]]]},"properties":{"id":"13354","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.106727,43.585618],[-75.169369,43.542009],[-75.215217,43.498636],[-75.101669,43.541097],[-75.106727,43.585618]]]},"properties":{"id":"13494","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.293948,43.111883],[-75.266603,43.114891],[-75.254011,43.112839],[-75.323735,43.164102],[-75.378127,43.123778],[-75.387357,43.0991],[-75.380904,43.092569],[-75.364413,43.093841],[-75.344133,43.095756],[-75.304226,43.096541],[-75.293948,43.111883]]]},"properties":{"id":"13492","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.153576,43.056361],[-76.144203,43.050849],[-76.121868,43.053298],[-76.124999,43.072959],[-76.153576,43.056361]]]},"properties":{"id":"13203","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.018071,42.896538],[-76.028944,42.82899],[-76.005195,42.784871],[-75.91983,42.818506],[-75.920301,42.82033],[-75.913432,42.876177],[-75.916178,42.87746],[-75.995482,42.927108],[-76.01196,42.899357],[-76.018071,42.896538]]]},"properties":{"id":"13063","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.349717,43.017959],[-76.375858,43.067491],[-76.419617,43.067756],[-76.464264,43.031889],[-76.492667,43.015312],[-76.460009,43.001788],[-76.43993,42.995854],[-76.396289,42.994576],[-76.349717,43.017959]]]},"properties":{"id":"13060","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.001177,43.083555],[-76.021051,43.083027],[-76.017443,43.06527],[-76.001673,43.065795],[-76.001177,43.083555]]]},"properties":{"id":"13116","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.905265,42.880418],[-75.916178,42.87746],[-75.913432,42.876177],[-75.904284,42.870473],[-75.905265,42.880418]]]},"properties":{"id":"13051","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.438589,42.730291],[-77.487678,42.659094],[-77.48998,42.641962],[-77.473903,42.577092],[-77.43483,42.56748],[-77.397141,42.55204],[-77.312848,42.57625],[-77.265877,42.635127],[-77.35756,42.666585],[-77.336715,42.746654],[-77.438589,42.730291]]]},"properties":{"id":"14512","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.370591,42.944241],[-77.379959,42.924785],[-77.477552,42.797533],[-77.438589,42.730291],[-77.336715,42.746654],[-77.265979,42.799596],[-77.214174,42.805733],[-77.141793,42.901274],[-77.209791,42.922476],[-77.2939,42.944204],[-77.370591,42.944241]]]},"properties":{"id":"14424","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.416956,41.533479],[-74.494963,41.53352],[-74.522927,41.506256],[-74.519529,41.502781],[-74.582459,41.418],[-74.557326,41.37138],[-74.51973,41.368948],[-74.515546,41.373288],[-74.455516,41.408104],[-74.377671,41.412095],[-74.356605,41.431963],[-74.405115,41.499675],[-74.40614,41.500934],[-74.416956,41.533479]],[[-74.464923,41.479659],[-74.469936,41.482077],[-74.467544,41.48551],[-74.458114,41.482067],[-74.464923,41.479659]]]},"properties":{"id":"10940","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.329036,41.346746],[-74.399099,41.31147],[-74.32999,41.336352],[-74.329036,41.346746]]]},"properties":{"id":"10921","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.137749,41.313632],[-74.145777,41.285971],[-74.140814,41.287314],[-74.111863,41.286185],[-74.095386,41.320073],[-74.098103,41.319812],[-74.137749,41.313632]]]},"properties":{"id":"10926","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.129831,41.546727],[-74.150744,41.525736],[-74.157562,41.514088],[-74.13217,41.512582],[-74.129831,41.546727]]],[[[-74.134231,41.501344],[-74.200692,41.469269],[-74.200649,41.439866],[-74.132375,41.453266],[-74.118584,41.46477],[-74.134231,41.501344]]]]},"properties":{"id":"12575","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.618355,41.418288],[-74.690847,41.392518],[-74.722239,41.394781],[-74.60746,41.317824],[-74.557326,41.37138],[-74.582459,41.418],[-74.595489,41.433821],[-74.618355,41.418288]]]},"properties":{"id":"12771","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.140814,41.287314],[-74.157707,41.260845],[-74.111863,41.286185],[-74.140814,41.287314]]]},"properties":{"id":"10910","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.690847,41.392518],[-74.721097,41.494835],[-74.752399,41.493743],[-74.812966,41.443639],[-74.819749,41.43939],[-74.722239,41.394781],[-74.690847,41.392518]]]},"properties":{"id":"12780","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.697836,41.495678],[-74.618355,41.418288],[-74.595489,41.433821],[-74.554469,41.476528],[-74.601749,41.499082],[-74.697836,41.495678]]]},"properties":{"id":"12729","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.51973,41.368948],[-74.496767,41.367352],[-74.515546,41.373288],[-74.51973,41.368948]]]},"properties":{"id":"10933","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.042259,41.58157],[-73.982218,41.559438],[-73.953263,41.653836],[-74.0255,41.638525],[-74.026526,41.633898],[-74.042259,41.58157]]]},"properties":{"id":"12542","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.465595,43.291165],[-78.419095,43.130341],[-78.340512,43.130803],[-78.251949,43.131341],[-78.30871,43.228747],[-78.337249,43.236209],[-78.298375,43.246938],[-78.296871,43.306183],[-78.310548,43.306215],[-78.466429,43.302775],[-78.465595,43.291165]]]},"properties":{"id":"14103","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.310548,43.306215],[-78.296871,43.306183],[-78.185244,43.339097],[-78.192323,43.371845],[-78.304575,43.372038],[-78.310548,43.306215]]]},"properties":{"id":"14571","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.298375,43.246938],[-78.337249,43.236209],[-78.30871,43.228747],[-78.298375,43.246938]]]},"properties":{"id":"14479","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.890768,43.551674],[-76.037509,43.531041],[-76.049976,43.487586],[-75.990998,43.435342],[-75.890768,43.551674]]]},"properties":{"id":"13302","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.810702,42.845842],[-74.84155,42.873654],[-74.898783,42.829455],[-74.834275,42.833839],[-74.810702,42.845842]]]},"properties":{"id":"13468","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.212337,42.646164],[-75.190515,42.70543],[-75.216318,42.787345],[-75.300725,42.715234],[-75.212337,42.646164]]]},"properties":{"id":"13335","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.763012,41.430259],[-73.821149,41.468065],[-73.83694,41.45305],[-73.888259,41.409764],[-73.892449,41.343747],[-73.875119,41.332785],[-73.86201,41.334264],[-73.845821,41.335906],[-73.806522,41.357135],[-73.763012,41.430259]]]},"properties":{"id":"10579","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.902854,40.713793],[-73.92392,40.714074],[-73.902128,40.691843],[-73.889575,40.68418],[-73.868917,40.69515],[-73.852145,40.702375],[-73.854747,40.70683],[-73.857298,40.711465],[-73.862079,40.712201],[-73.902854,40.713793]]]},"properties":{"id":"11385","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.780359,40.708464],[-73.79609,40.703294],[-73.793577,40.699756],[-73.795722,40.699015],[-73.798415,40.702741],[-73.80242,40.701786],[-73.794371,40.686913],[-73.769895,40.696359],[-73.775465,40.705319],[-73.780359,40.708464]]]},"properties":{"id":"11433","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.824998,40.781732],[-73.832144,40.790095],[-73.848178,40.776706],[-73.8281,40.776031],[-73.825993,40.77982],[-73.824998,40.781732]]]},"properties":{"id":"11356","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.830627,40.714341],[-73.825956,40.716022],[-73.825793,40.716146],[-73.845319,40.740048],[-73.853392,40.73694],[-73.857298,40.711465],[-73.854747,40.70683],[-73.843016,40.709176],[-73.830627,40.714341]]]},"properties":{"id":"11375","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.925787,40.736139],[-73.909813,40.751574],[-73.925467,40.747432],[-73.925787,40.736139]]]},"properties":{"id":"11104","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.825993,40.77982],[-73.825667,40.77973],[-73.824998,40.781732],[-73.825993,40.77982]]]},"properties":{"id":"11351","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.803929,42.95088],[-73.770166,42.907758],[-73.698782,42.854845],[-73.670823,42.840312],[-73.662553,42.887596],[-73.662943,42.929556],[-73.71382,42.992718],[-73.755966,42.950398],[-73.803189,42.952982],[-73.803929,42.95088]]]},"properties":{"id":"12118","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.47886,42.663195],[-73.467214,42.606027],[-73.428918,42.608887],[-73.47886,42.663195]]]},"properties":{"id":"12153","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.658056,42.557671],[-73.679834,42.486151],[-73.679929,42.482923],[-73.643875,42.478382],[-73.624194,42.480398],[-73.52069,42.492207],[-73.513312,42.493161],[-73.549963,42.579096],[-73.584518,42.591847],[-73.625161,42.559763],[-73.658056,42.557671]]]},"properties":{"id":"12123","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.60008,42.704408],[-73.685195,42.666802],[-73.662002,42.640638],[-73.659117,42.640149],[-73.600775,42.666197],[-73.602663,42.684715],[-73.60008,42.704408]]]},"properties":{"id":"12198","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.600775,42.666197],[-73.659117,42.640149],[-73.601632,42.613268],[-73.600775,42.666197]]]},"properties":{"id":"12196","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.069353,40.638096],[-74.113023,40.600135],[-74.098493,40.587106],[-74.068761,40.627408],[-74.069353,40.638096]]]},"properties":{"id":"10304","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.197544,40.511187],[-74.206392,40.558102],[-74.212995,40.558325],[-74.242416,40.520325],[-74.225266,40.500998],[-74.197544,40.511187]]]},"properties":{"id":"10309","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.242416,40.520325],[-74.249977,40.496565],[-74.225266,40.500998],[-74.242416,40.520325]]]},"properties":{"id":"10307","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.967045,41.078323],[-73.994882,41.070847],[-73.985482,41.060395],[-73.967045,41.078323]]],[[[-73.927304,41.070437],[-73.981987,41.05451],[-73.999781,41.040835],[-73.966982,41.026197],[-73.937305,41.037846],[-73.927845,41.033763],[-73.921988,41.041414],[-73.927304,41.070437]]]]},"properties":{"id":"10962","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.938881,41.013597],[-73.907893,41.038402],[-73.91383,41.037451],[-73.932045,41.019913],[-73.938881,41.013597]]]},"properties":{"id":"10964","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.00406,41.217788],[-74.029543,41.204462],[-74.022641,41.197053],[-74.00406,41.217788]]]},"properties":{"id":"10984","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.151001,41.203462],[-74.234473,41.142883],[-74.195395,41.126831],[-74.145199,41.183895],[-74.124651,41.201898],[-74.131339,41.21756],[-74.151001,41.203462]]]},"properties":{"id":"10974","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.832307,44.549189],[-74.942156,44.465345],[-74.923242,44.326312],[-74.809743,44.329318],[-74.686129,44.349099],[-74.691433,44.37816],[-74.832307,44.549189]]]},"properties":{"id":"13687","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.205706,44.521539],[-75.298959,44.431745],[-75.308761,44.385938],[-75.275332,44.346704],[-75.192228,44.373554],[-75.071406,44.478189],[-75.171284,44.508903],[-75.186393,44.514725],[-75.205706,44.521539]]]},"properties":{"id":"13652","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.757124,44.453803],[-75.763135,44.441863],[-75.752095,44.441969],[-75.757124,44.453803]]]},"properties":{"id":"13623","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.91417,42.807383],[-73.917178,42.843697],[-73.921085,42.840634],[-73.930371,42.829904],[-73.935926,42.811655],[-73.921023,42.804406],[-73.91417,42.807383]]]},"properties":{"id":"12308","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.143337,42.79342],[-74.1128,42.738478],[-74.029904,42.748447],[-74.143337,42.79342]]]},"properties":{"id":"12056","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.889971,42.414601],[-76.98624,42.424583],[-77.049151,42.39661],[-77.046139,42.374515],[-76.897132,42.31726],[-76.854869,42.387884],[-76.855585,42.387994],[-76.889971,42.414601]]]},"properties":{"id":"14891","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.147579,42.388134],[-77.102265,42.327938],[-77.046139,42.374515],[-77.049151,42.39661],[-77.094927,42.419826],[-77.147579,42.388134]]]},"properties":{"id":"14815","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.910691,42.478282],[-76.98624,42.424583],[-76.889971,42.414601],[-76.910691,42.478282]]]},"properties":{"id":"14878","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.698153,42.660551],[-76.785242,42.629319],[-76.800326,42.557638],[-76.780189,42.544633],[-76.665839,42.593733],[-76.698153,42.660551]]]},"properties":{"id":"14847","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.442016,42.140918],[-77.338994,41.999855],[-77.22887,41.999723],[-77.208129,42.063606],[-77.187096,42.182804],[-77.313526,42.205055],[-77.419582,42.140518],[-77.442016,42.140918]]]},"properties":{"id":"14801","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.509508,42.335959],[-77.471896,42.274761],[-77.341587,42.251511],[-77.295845,42.272528],[-77.285148,42.272468],[-77.20343,42.371975],[-77.306099,42.447759],[-77.338281,42.466896],[-77.338278,42.462033],[-77.337933,42.44598],[-77.370824,42.383031],[-77.358129,42.375386],[-77.364192,42.369895],[-77.372645,42.376651],[-77.371179,42.382804],[-77.510231,42.345657],[-77.509508,42.335959]]]},"properties":{"id":"14810","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.56358,42.096683],[-77.63231,42.088514],[-77.635718,41.999387],[-77.502985,42.00007],[-77.513625,42.095283],[-77.56358,42.096683]]]},"properties":{"id":"14885","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.950195,40.797931],[-72.942672,40.863276],[-72.975258,40.868953],[-73.006036,40.841575],[-73.02025,40.823534],[-73.017275,40.795718],[-72.959603,40.797294],[-72.950195,40.797931]]]},"properties":{"id":"11763","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.864475,40.933387],[-72.90321,40.929375],[-72.940603,40.916679],[-72.941954,40.907818],[-72.928701,40.865889],[-72.901625,40.865585],[-72.865925,40.892296],[-72.842526,40.910914],[-72.864475,40.933387]]]},"properties":{"id":"11961","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.012792,40.903423],[-73.038452,40.937698],[-73.072709,40.931544],[-73.069732,40.906212],[-73.059647,40.892459],[-73.03515,40.888503],[-73.012792,40.903423]]]},"properties":{"id":"11776","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.03515,40.888503],[-73.021844,40.862605],[-73.006036,40.841575],[-72.975258,40.868953],[-72.976789,40.903614],[-73.012792,40.903423],[-73.03515,40.888503]]]},"properties":{"id":"11727","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.145855,40.64928],[-73.169227,40.643341],[-73.164499,40.641375],[-73.145317,40.645589],[-73.145855,40.64928]]]},"properties":{"id":"11770","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.834073,40.914604],[-72.740113,40.848476],[-72.723383,40.844497],[-72.713689,40.98578],[-72.77524,40.974045],[-72.834073,40.914604]]]},"properties":{"id":"11933","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.723923,40.816212],[-72.691233,40.804014],[-72.690971,40.818322],[-72.713164,40.820339],[-72.723923,40.816212]]]},"properties":{"id":"11960","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.038052,40.729618],[-73.021221,40.74858],[-73.043304,40.765948],[-73.038052,40.729618]]]},"properties":{"id":"11715","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.49953,40.988691],[-72.447528,41.018804],[-72.506525,41.051933],[-72.526588,41.0457],[-72.49953,40.988691]]]},"properties":{"id":"11935","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.38601,40.853349],[-73.421143,40.819861],[-73.36019,40.769245],[-73.352132,40.766845],[-73.294966,40.784297],[-73.294652,40.803983],[-73.293683,40.818457],[-73.335164,40.836086],[-73.38555,40.8531],[-73.38601,40.853349]]]},"properties":{"id":"11746","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.808948,40.823162],[-72.818963,40.783767],[-72.812202,40.776866],[-72.77196,40.783394],[-72.782438,40.824464],[-72.808948,40.823162]]]},"properties":{"id":"11934","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.336808,40.856428],[-73.38555,40.8531],[-73.335164,40.836086],[-73.336808,40.856428]]],[[[-73.448747,40.804901],[-73.421143,40.819861],[-73.38601,40.853349],[-73.3729,40.879859],[-73.391663,40.908098],[-73.418379,40.928833],[-73.473466,40.874498],[-73.443778,40.885022],[-73.437093,40.848999],[-73.454671,40.835908],[-73.448763,40.804999],[-73.448747,40.804901]]]]},"properties":{"id":"11743","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.467366,40.868098],[-73.455261,40.840872],[-73.454671,40.835908],[-73.437093,40.848999],[-73.443778,40.885022],[-73.473466,40.874498],[-73.467366,40.868098]]]},"properties":{"id":"11724","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.782438,40.824464],[-72.77196,40.783394],[-72.728759,40.814994],[-72.748697,40.833716],[-72.782438,40.824464]]]},"properties":{"id":"11940","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.021844,40.862605],[-73.061602,40.851378],[-73.076171,40.842201],[-73.064762,40.818874],[-73.02025,40.823534],[-73.006036,40.841575],[-73.021844,40.862605]]]},"properties":{"id":"11738","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.266038,40.960103],[-72.236727,40.914838],[-72.221709,40.920795],[-72.266038,40.960103]]]},"properties":{"id":"11975","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.664062,41.720114],[-74.679228,41.704285],[-74.654547,41.676713],[-74.664062,41.720114]]]},"properties":{"id":"12751","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.446233,41.639359],[-74.468447,41.596118],[-74.492513,41.621338],[-74.456356,41.640216],[-74.514466,41.667073],[-74.525057,41.653373],[-74.552102,41.619411],[-74.624844,41.573086],[-74.602271,41.55657],[-74.522927,41.506256],[-74.494963,41.53352],[-74.418297,41.627765],[-74.421428,41.658506],[-74.425389,41.660615],[-74.446233,41.639359]]]},"properties":{"id":"12790","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.012429,41.794081],[-75.034451,41.796239],[-75.030813,41.771956],[-75.020183,41.772142],[-75.011694,41.792319],[-75.012429,41.794081]]]},"properties":{"id":"12745","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.996366,41.673017],[-74.988766,41.694252],[-74.97064,41.680799],[-74.993317,41.671865],[-74.939124,41.657936],[-74.897272,41.706609],[-74.919162,41.723017],[-74.928141,41.726754],[-74.974838,41.721267],[-75.053958,41.729202],[-75.058448,41.669686],[-74.996366,41.673017]]]},"properties":{"id":"12726","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.993317,41.671865],[-74.97064,41.680799],[-74.988766,41.694252],[-74.996366,41.673017],[-74.993317,41.671865]]]},"properties":{"id":"12752","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.813316,41.70274],[-74.861814,41.680693],[-74.820745,41.682527],[-74.810933,41.689635],[-74.813316,41.70274]]]},"properties":{"id":"12749","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.813602,41.800818],[-74.858277,41.805798],[-74.857458,41.78891],[-74.817468,41.786967],[-74.813602,41.800818]]]},"properties":{"id":"12787","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.660045,42.416405],[-76.53706,42.368053],[-76.443064,42.306518],[-76.415205,42.336487],[-76.374426,42.394227],[-76.351157,42.414384],[-76.4007,42.478205],[-76.385167,42.485482],[-76.475292,42.524657],[-76.519431,42.509202],[-76.567804,42.530552],[-76.660045,42.416405]],[[-76.474457,42.447568],[-76.476569,42.443194],[-76.481134,42.44346],[-76.478416,42.452011],[-76.474457,42.447568]]]},"properties":{"id":"14850","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.4007,42.478205],[-76.351157,42.414384],[-76.34726,42.414509],[-76.289343,42.441694],[-76.260957,42.540636],[-76.291011,42.549589],[-76.296677,42.556099],[-76.296244,42.573564],[-76.482161,42.538822],[-76.475292,42.524657],[-76.385167,42.485482],[-76.38678,42.480785],[-76.393193,42.482033],[-76.4007,42.478205]]]},"properties":{"id":"13068","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.0255,41.638525],[-73.953263,41.653836],[-73.951581,41.670729],[-74.0255,41.638525]]]},"properties":{"id":"12547","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.249511,41.693172],[-74.119524,41.632043],[-74.12695,41.673051],[-74.173817,41.749501],[-74.249511,41.693172]]]},"properties":{"id":"12525","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.983364,41.9153],[-73.988861,41.899037],[-73.988683,41.899284],[-73.983364,41.9153]]]},"properties":{"id":"12417","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.961816,43.696956],[-73.941054,43.63347],[-73.890021,43.610622],[-73.892313,43.631348],[-73.961816,43.696956]]]},"properties":{"id":"12886","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.852709,43.454558],[-74.172445,43.453823],[-74.1601,43.371532],[-73.883299,43.397988],[-73.874126,43.424903],[-73.852709,43.454558]]]},"properties":{"id":"12878","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.485302,43.534469],[-73.412673,43.668761],[-73.426086,43.662637],[-73.553476,43.569096],[-73.485302,43.534469]]]},"properties":{"id":"12819","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.989798,43.236609],[-77.026265,43.270595],[-77.119919,43.286934],[-77.101465,43.194222],[-77.085031,43.161666],[-77.065253,43.140762],[-76.965129,43.173868],[-76.989798,43.236609]]]},"properties":{"id":"14551","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.320382,43.174225],[-77.268171,43.091817],[-77.241079,43.127389],[-77.242507,43.195435],[-77.320382,43.174225]]]},"properties":{"id":"14568","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.79734,40.96411],[-73.818132,40.963585],[-73.818366,40.944418],[-73.801772,40.944289],[-73.79734,40.96411]]]},"properties":{"id":"10709","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.839444,41.036307],[-73.803814,41.047273],[-73.798107,41.055803],[-73.826853,41.081218],[-73.828811,41.081661],[-73.83608,41.057353],[-73.839444,41.036307]]]},"properties":{"id":"10523","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.83608,41.057353],[-73.87082,41.046962],[-73.877403,41.022584],[-73.845334,41.023229],[-73.843676,41.025558],[-73.839444,41.036307],[-73.83608,41.057353]],[[-73.873834,41.025566],[-73.87631,41.025621],[-73.876173,41.027455],[-73.874526,41.027377],[-73.873834,41.025566]]]},"properties":{"id":"10533","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.868141,41.109701],[-73.845117,41.108425],[-73.792644,41.143535],[-73.807432,41.180124],[-73.807433,41.18474],[-73.867034,41.139577],[-73.868141,41.109701]]]},"properties":{"id":"10510","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.757605,41.344904],[-73.734867,41.337616],[-73.735816,41.347123],[-73.757605,41.344904]]]},"properties":{"id":"10505","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.85051,40.944977],[-73.870855,40.932371],[-73.877855,40.905922],[-73.852678,40.909899],[-73.844578,40.923898],[-73.85051,40.944977]]]},"properties":{"id":"10704","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.849633,40.946863],[-73.871857,40.95763],[-73.872624,40.97929],[-73.888824,40.976542],[-73.904259,40.934894],[-73.870855,40.932371],[-73.85051,40.944977],[-73.849633,40.946863]]]},"properties":{"id":"10701","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.760084,40.912217],[-73.73687,40.932645],[-73.757471,40.961611],[-73.771812,40.955677],[-73.768809,40.934414],[-73.760084,40.912217]]]},"properties":{"id":"10538","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.792644,41.143535],[-73.745298,41.129295],[-73.724046,41.153036],[-73.772774,41.218388],[-73.785253,41.208538],[-73.807432,41.180124],[-73.792644,41.143535]]]},"properties":{"id":"10514","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.776303,41.031867],[-73.769815,41.024777],[-73.754556,41.032708],[-73.758482,41.038637],[-73.775168,41.032534],[-73.776303,41.031867]]]},"properties":{"id":"10601","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.026769,42.801786],[-78.074237,42.79156],[-78.0742,42.713472],[-78.026193,42.713333],[-77.988904,42.625263],[-77.956334,42.667322],[-77.955567,42.800477],[-78.026769,42.801786]],[[-78.020461,42.6994],[-78.019148,42.70283],[-78.013548,42.702787],[-78.014662,42.697582],[-78.020461,42.6994]]]},"properties":{"id":"14530","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.435995,42.672622],[-78.338313,42.651007],[-78.396382,42.678168],[-78.435995,42.672622]]]},"properties":{"id":"14082","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.005779,42.555997],[-76.916832,42.574412],[-76.920724,42.630985],[-77.005779,42.555997]]]},"properties":{"id":"14842","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-73.847563,42.529541],[-73.867074,42.539338],[-73.932892,42.538291],[-73.896088,42.520261],[-73.847563,42.529541]]],[[[-73.847563,42.529541],[-73.808225,42.481545],[-73.786128,42.478017],[-73.773161,42.509377],[-73.752168,42.56672],[-73.816076,42.594374],[-73.855271,42.58849],[-73.866934,42.539606],[-73.847563,42.529541]]]]},"properties":{"id":"12158","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.783968,42.626199],[-73.809204,42.639149],[-73.840076,42.642121],[-73.934434,42.613602],[-73.95677,42.5905],[-73.943449,42.574544],[-73.855271,42.58849],[-73.816076,42.594374],[-73.783968,42.626199]]]},"properties":{"id":"12054","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.749046,42.669532],[-73.729167,42.664999],[-73.724964,42.670179],[-73.703463,42.705578],[-73.721003,42.70869],[-73.753959,42.670584],[-73.749046,42.669532]]]},"properties":{"id":"12204","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.879516,42.740509],[-73.931047,42.797714],[-73.943402,42.802544],[-73.954155,42.803182],[-73.963082,42.735629],[-73.920824,42.707029],[-73.877814,42.719396],[-73.879516,42.740509]]]},"properties":{"id":"12303","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.761956,42.649191],[-73.749046,42.669532],[-73.753959,42.670584],[-73.765761,42.673472],[-73.765392,42.657951],[-73.772078,42.654939],[-73.768137,42.651338],[-73.761956,42.649191]]]},"properties":{"id":"12210","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.031461,42.568372],[-73.997652,42.551067],[-73.973074,42.575388],[-73.95677,42.5905],[-73.934434,42.613602],[-73.908536,42.682892],[-73.920489,42.690931],[-74.031914,42.678041],[-74.031461,42.568372]]]},"properties":{"id":"12186","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.216976,42.497958],[-74.326265,42.461298],[-74.251965,42.400783],[-74.190568,42.415289],[-74.164963,42.418106],[-74.177095,42.495301],[-74.216976,42.497958]]]},"properties":{"id":"12469","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.327012,42.093653],[-78.385902,41.999606],[-78.284023,41.999138],[-78.257927,42.031783],[-78.220414,42.064708],[-78.248166,42.086524],[-78.327012,42.093653]]]},"properties":{"id":"14770","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.329593,42.607607],[-78.308839,42.521217],[-78.171247,42.521419],[-78.171078,42.529157],[-78.162501,42.551427],[-78.15732,42.562794],[-78.298009,42.667425],[-78.329593,42.607607]]]},"properties":{"id":"14024","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.855691,40.884212],[-73.838743,40.881266],[-73.819719,40.890206],[-73.823244,40.891199],[-73.844728,40.904732],[-73.862875,40.895154],[-73.855691,40.884212]]]},"properties":{"id":"10466","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.879983,40.83468],[-73.896302,40.833543],[-73.90299,40.820027],[-73.895428,40.815845],[-73.884568,40.82316],[-73.879983,40.83468]]]},"properties":{"id":"10459","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.899949,40.85742],[-73.91929,40.857475],[-73.928242,40.845353],[-73.911049,40.845143],[-73.899949,40.85742]]]},"properties":{"id":"10453","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.901892,40.805007],[-73.884328,40.822614],[-73.884568,40.82316],[-73.895428,40.815845],[-73.902988,40.809364],[-73.901892,40.805007]]]},"properties":{"id":"10474","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.17356,42.183635],[-76.157444,42.084407],[-76.108922,42.074331],[-75.994741,42.114292],[-76.055113,42.200257],[-76.067906,42.225265],[-76.17356,42.183635]]]},"properties":{"id":"13760","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.340545,42.185974],[-75.419203,42.168053],[-75.468816,42.145666],[-75.517131,42.120348],[-75.501723,41.999269],[-75.359579,41.999445],[-75.350162,42.077203],[-75.340545,42.185974]]]},"properties":{"id":"13754","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.017062,42.435243],[-78.986338,42.368134],[-78.812924,42.456159],[-78.777603,42.461882],[-78.779668,42.465595],[-78.943952,42.516789],[-78.982035,42.540604],[-78.988305,42.521684],[-78.968752,42.424632],[-78.976239,42.41599],[-78.986043,42.426471],[-79.017062,42.435243]]]},"properties":{"id":"14070","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.519805,41.999897],[-78.385902,41.999606],[-78.327012,42.093653],[-78.368112,42.133635],[-78.443624,42.164464],[-78.480342,42.08158],[-78.485822,42.073299],[-78.492041,42.082796],[-78.517558,42.005903],[-78.519805,41.999897]],[[-78.386191,42.062831],[-78.379222,42.066015],[-78.375064,42.062777],[-78.376529,42.057616],[-78.386191,42.062831]]]},"properties":{"id":"14760","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.942902,42.084263],[-78.977566,41.998882],[-78.94452,41.998421],[-78.802371,42.139551],[-78.827501,42.120595],[-78.942902,42.084263]]]},"properties":{"id":"14783","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.061087,42.468091],[-79.017062,42.435243],[-78.986043,42.426471],[-78.968752,42.424632],[-78.988305,42.521684],[-78.992326,42.521743],[-78.989239,42.521689],[-78.987762,42.519398],[-78.991224,42.519017],[-78.993082,42.521197],[-79.061017,42.51303],[-79.061087,42.468091]]]},"properties":{"id":"14129","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.802371,42.139551],[-78.94452,41.998421],[-78.714952,41.998544],[-78.695609,42.15148],[-78.676504,42.172325],[-78.69693,42.259099],[-78.759613,42.299198],[-78.802371,42.139551]]]},"properties":{"id":"14779","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.566847,42.58782],[-78.525062,42.533437],[-78.515775,42.531774],[-78.519078,42.51916],[-78.533186,42.531235],[-78.55983,42.513278],[-78.559836,42.509369],[-78.478568,42.530227],[-78.47017,42.533534],[-78.46237,42.606545],[-78.566847,42.58782]]]},"properties":{"id":"14030","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.993082,42.521197],[-78.991224,42.519017],[-78.987762,42.519398],[-78.989239,42.521689],[-78.992326,42.521743],[-78.993082,42.521197]]]},"properties":{"id":"14168","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.686962,43.278716],[-76.609083,43.317115],[-76.605967,43.384826],[-76.617102,43.419064],[-76.722491,43.343561],[-76.686962,43.278716]],[[-76.704959,43.316579],[-76.713004,43.327272],[-76.712915,43.344153],[-76.702538,43.344424],[-76.699742,43.32971],[-76.704959,43.316579]]]},"properties":{"id":"13156","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.617448,43.132637],[-76.595367,43.010298],[-76.492667,43.015312],[-76.464264,43.031889],[-76.537824,43.150141],[-76.617448,43.132637]]]},"properties":{"id":"13166","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.546984,43.157433],[-76.53325,43.156246],[-76.540807,43.167004],[-76.546984,43.157433]]]},"properties":{"id":"13113","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.721805,43.006063],[-76.720879,43.0003],[-76.708388,43.004419],[-76.721805,43.006063]]]},"properties":{"id":"13117","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.210412,42.348687],[-79.148764,42.253677],[-79.08659,42.253813],[-79.060515,42.292942],[-79.134994,42.36994],[-79.22001,42.383108],[-79.222615,42.380791],[-79.210412,42.348687]]]},"properties":{"id":"14723","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.439825,42.225479],[-79.312223,42.116009],[-79.305981,42.123019],[-79.323009,42.197914],[-79.33824,42.216329],[-79.439825,42.225479]],[[-79.421432,42.196222],[-79.424054,42.200788],[-79.419944,42.201644],[-79.417382,42.197769],[-79.421432,42.196222]]]},"properties":{"id":"14712","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.288139,42.107722],[-79.273665,42.10723],[-79.270465,42.111041],[-79.288139,42.107722]]]},"properties":{"id":"14720","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.32694,42.350237],[-79.310663,42.349346],[-79.320887,42.355552],[-79.32694,42.350237]]]},"properties":{"id":"14752","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.411545,42.359143],[-79.420945,42.445322],[-79.411395,42.462196],[-79.528482,42.385935],[-79.496706,42.390531],[-79.460755,42.337287],[-79.437455,42.33708],[-79.411545,42.359143]]]},"properties":{"id":"14716","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.810646,42.136122],[-76.925449,42.145741],[-76.925877,42.096461],[-76.888734,42.093023],[-76.835693,42.109518],[-76.810646,42.136122]]]},"properties":{"id":"14903","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.897132,42.31726],[-77.046139,42.374515],[-77.102265,42.327938],[-77.10143,42.310504],[-77.095459,42.234963],[-76.96535,42.223288],[-76.901381,42.270109],[-76.869265,42.261329],[-76.854562,42.314953],[-76.897132,42.31726]]]},"properties":{"id":"14812","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.826601,42.644548],[-75.838639,42.639353],[-75.815121,42.619732],[-75.830492,42.685084],[-75.826601,42.644548]]]},"properties":{"id":"13124","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.501932,44.395813],[-73.410368,44.426846],[-73.402983,44.523869],[-73.460146,44.525873],[-73.459986,44.52345],[-73.46019,44.519793],[-73.466257,44.5202],[-73.463166,44.524736],[-73.56241,44.479308],[-73.614453,44.454386],[-73.657762,44.343483],[-73.657743,44.342114],[-73.501932,44.395813]]]},"properties":{"id":"12944","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.546701,44.626793],[-73.648631,44.63161],[-73.719349,44.607578],[-73.861308,44.518345],[-73.704821,44.527132],[-73.549293,44.623769],[-73.546701,44.626793]]]},"properties":{"id":"12985","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.526832,44.911629],[-73.406564,44.84122],[-73.385728,44.93259],[-73.521015,44.917032],[-73.526832,44.911629]]]},"properties":{"id":"12921","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.027389,44.995685],[-73.986594,44.887275],[-73.849292,44.961343],[-73.815325,45.002468],[-74.027389,44.995685]]]},"properties":{"id":"12923","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.443862,44.69334],[-73.452692,44.671587],[-73.442327,44.672434],[-73.443862,44.69334]]]},"properties":{"id":"12903","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.806215,42.044541],[-73.90919,42.034793],[-73.908703,42.012016],[-73.92698,42.012025],[-73.936439,41.979591],[-73.785534,41.938481],[-73.754996,41.954727],[-73.715716,41.981074],[-73.733379,42.044045],[-73.806215,42.044541]]]},"properties":{"id":"12571","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.476856,42.172241],[-73.527392,42.137721],[-73.525785,42.073229],[-73.503143,42.069661],[-73.476856,42.172241]]]},"properties":{"id":"12517","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.694441,42.204261],[-73.680662,42.209811],[-73.695999,42.20512],[-73.694441,42.204261]]]},"properties":{"id":"12530","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.474893,42.485245],[-73.454579,42.489448],[-73.497691,42.495232],[-73.474893,42.485245]]]},"properties":{"id":"12195","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.494007,42.319863],[-73.517361,42.299779],[-73.508214,42.294837],[-73.494007,42.319863]]]},"properties":{"id":"12165","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.563203,42.362207],[-74.663491,42.28568],[-74.67289,42.274067],[-74.649131,42.24568],[-74.593992,42.223677],[-74.591997,42.222896],[-74.486145,42.291726],[-74.486613,42.333588],[-74.563207,42.362254],[-74.563203,42.362207]]]},"properties":{"id":"12474","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.817864,42.313255],[-74.852078,42.401277],[-74.939333,42.373886],[-74.966891,42.355343],[-75.019674,42.347594],[-75.057225,42.342211],[-75.063186,42.323144],[-75.025587,42.26601],[-74.967022,42.21926],[-74.83196,42.230249],[-74.817864,42.313255]]]},"properties":{"id":"13753","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.168428,41.981645],[-75.350162,42.077203],[-75.359579,41.999445],[-75.169289,41.870465],[-75.141981,41.946589],[-75.179975,41.969426],[-75.168428,41.981645]]]},"properties":{"id":"13783","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.07437,42.390149],[-75.057225,42.342211],[-75.019674,42.347594],[-75.02003,42.383273],[-75.018205,42.388395],[-75.07437,42.390149]]]},"properties":{"id":"13846","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.279223,42.202564],[-75.275627,42.202645],[-75.275124,42.206553],[-75.279223,42.202564]]],[[[-75.305959,42.201519],[-75.278362,42.188049],[-75.279223,42.202564],[-75.305959,42.201519]]]]},"properties":{"id":"13847","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975036,41.544295],[-73.966351,41.545242],[-73.96923,41.555074],[-73.975036,41.544295]]]},"properties":{"id":"12512","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.727329,41.593666],[-73.77068,41.504413],[-73.715337,41.510049],[-73.709483,41.557579],[-73.727329,41.593666]]]},"properties":{"id":"12582","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.624956,41.906018],[-73.715716,41.981074],[-73.754996,41.954727],[-73.709481,41.840761],[-73.650411,41.866038],[-73.624956,41.906018]]]},"properties":{"id":"12581","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.812186,41.843802],[-73.731894,41.818003],[-73.709481,41.840761],[-73.754996,41.954727],[-73.785534,41.938481],[-73.795698,41.884126],[-73.812186,41.843802]]]},"properties":{"id":"12514","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.525943,41.753488],[-73.630526,41.647098],[-73.637586,41.63627],[-73.521435,41.616612],[-73.511564,41.747918],[-73.525943,41.753488]]]},"properties":{"id":"12594","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.727329,41.593666],[-73.709483,41.557579],[-73.663758,41.588457],[-73.637586,41.63627],[-73.630526,41.647098],[-73.632837,41.674588],[-73.689308,41.627308],[-73.727329,41.593666]]]},"properties":{"id":"12570","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.932755,41.517519],[-73.936679,41.522386],[-73.941143,41.516061],[-73.932755,41.517519]]]},"properties":{"id":"12527","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.88788,41.691708],[-73.892298,41.689335],[-73.89432,41.685369],[-73.891373,41.684995],[-73.88788,41.691708]]]},"properties":{"id":"12604","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.853918,42.881845],[-78.80008,42.864844],[-78.792349,42.871571],[-78.784477,42.890077],[-78.803659,42.898208],[-78.853394,42.887817],[-78.853918,42.881845]]]},"properties":{"id":"14206","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.784297,42.900528],[-78.79963,42.919007],[-78.833947,42.923073],[-78.843426,42.921934],[-78.852752,42.90382],[-78.853486,42.893154],[-78.803659,42.898208],[-78.784346,42.900522],[-78.784297,42.900528]]]},"properties":{"id":"14211","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.91651,42.739966],[-78.908044,42.696563],[-78.79891,42.636233],[-78.748854,42.664544],[-78.744569,42.674613],[-78.807976,42.771407],[-78.869143,42.771294],[-78.91651,42.739966]]]},"properties":{"id":"14075","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.463893,43.801714],[-73.43812,43.803687],[-73.38796,43.807686],[-73.412956,43.92253],[-73.625254,43.883659],[-73.628835,43.88356],[-73.627197,43.877396],[-73.618204,43.786777],[-73.463893,43.801714]]]},"properties":{"id":"12883","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.634234,43.914818],[-73.641899,43.9325],[-73.692865,43.929821],[-73.731268,43.880918],[-73.715072,43.877933],[-73.627197,43.877396],[-73.628835,43.88356],[-73.634234,43.914818]]]},"properties":{"id":"12858","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.330936,44.551057],[-74.280075,44.548896],[-74.169761,44.559826],[-74.187951,44.648182],[-74.11819,44.788743],[-74.143957,44.857454],[-74.255672,44.907712],[-74.377694,44.925923],[-74.350123,44.693162],[-74.346597,44.670952],[-74.330936,44.551057]]]},"properties":{"id":"12953","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.255672,44.907712],[-74.143957,44.857454],[-74.144696,44.99157],[-74.243085,44.992165],[-74.255672,44.907712]]]},"properties":{"id":"12917","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.247595,43.024718],[-74.28856,42.9798],[-74.282913,42.957422],[-74.275898,42.95198],[-74.271703,42.952993],[-74.247595,43.024718]]]},"properties":{"id":"12070","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.712615,43.286143],[-74.72244,43.146222],[-74.553389,43.154329],[-74.663197,43.272036],[-74.712615,43.286143]]]},"properties":{"id":"13470","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.493675,43.015682],[-74.740093,42.998315],[-74.830157,42.955606],[-74.831523,42.944583],[-74.827401,42.89524],[-74.826673,42.894904],[-74.763303,42.863237],[-74.709837,42.847611],[-74.585283,42.909103],[-74.616249,42.934123],[-74.613784,42.93472],[-74.478234,42.923257],[-74.447193,42.993405],[-74.493675,43.015682]]]},"properties":{"id":"13339","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.426012,43.031636],[-78.464249,42.948486],[-78.463856,42.900808],[-78.34617,42.923679],[-78.30271,42.950046],[-78.336159,43.025363],[-78.426012,43.031636]]]},"properties":{"id":"14036","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.463856,42.900808],[-78.410015,42.868124],[-78.30805,42.902696],[-78.34617,42.923679],[-78.463856,42.900808]]]},"properties":{"id":"14040","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.059107,42.361477],[-73.971581,42.299075],[-73.951301,42.356856],[-73.992424,42.37362],[-74.079713,42.398553],[-74.059107,42.361477]]]},"properties":{"id":"12431","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.950374,42.356918],[-73.922142,42.294037],[-73.89227,42.321565],[-73.888937,42.382493],[-73.904465,42.399567],[-73.948216,42.412315],[-73.948602,42.40337],[-73.949051,42.391637],[-73.950374,42.356918]]]},"properties":{"id":"12058","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.371034,42.300291],[-74.334532,42.31173],[-74.367572,42.332634],[-74.371034,42.300291]]]},"properties":{"id":"12407","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.786866,42.321363],[-73.850381,42.308661],[-73.879009,42.257479],[-73.882531,42.251703],[-73.830354,42.251524],[-73.786866,42.321363]]]},"properties":{"id":"12015","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.042273,42.296272],[-74.092872,42.341163],[-74.105364,42.336624],[-74.150226,42.338299],[-74.143122,42.314848],[-74.042273,42.296272]]]},"properties":{"id":"12405","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.190164,42.268284],[-74.104619,42.2678],[-74.100466,42.293443],[-74.171372,42.311147],[-74.195298,42.29723],[-74.190164,42.268284]]]},"properties":{"id":"12454","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.235542,43.830984],[-74.2891,43.88085],[-74.516383,43.800991],[-74.36332,43.655084],[-74.326785,43.619568],[-74.203537,43.657166],[-74.058313,43.744384],[-74.057005,43.744513],[-74.235542,43.830984]],[[-74.31283,43.720416],[-74.309925,43.725087],[-74.298868,43.734054],[-74.303082,43.727239],[-74.31283,43.720416]]]},"properties":{"id":"12842","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.55962,43.841822],[-74.538754,43.822278],[-74.516383,43.800991],[-74.2891,43.88085],[-74.336826,43.925223],[-74.55962,43.841822]]]},"properties":{"id":"12812","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.85156,43.334218],[-74.880123,43.241401],[-74.867885,43.154493],[-74.972755,43.130061],[-74.916816,43.018479],[-74.830157,42.955606],[-74.740093,42.998315],[-74.761889,43.059199],[-74.824478,43.134287],[-74.821397,43.143143],[-74.748005,43.298253],[-74.85156,43.334218]]]},"properties":{"id":"13365","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.111775,43.310852],[-75.086692,43.287581],[-75.020546,43.22792],[-74.880123,43.241401],[-74.85156,43.334218],[-74.79239,43.460043],[-75.020388,43.377276],[-75.106495,43.313539],[-75.111775,43.310852]]]},"properties":{"id":"13324","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.076022,43.900952],[-75.975473,43.833146],[-75.912816,43.889781],[-76.054668,43.89967],[-76.076022,43.900952]]]},"properties":{"id":"13606","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.881647,44.137129],[-75.854062,44.270564],[-75.8617,44.279057],[-75.88995,44.29449],[-75.972795,44.260789],[-76.067267,44.140696],[-76.026179,44.111887],[-75.88246,44.124679],[-75.881647,44.137129]]]},"properties":{"id":"13656","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.192712,44.207412],[-76.188604,44.132401],[-76.067267,44.140696],[-75.972795,44.260789],[-76.000254,44.272759],[-76.012545,44.266691],[-76.192712,44.207412]]]},"properties":{"id":"13624","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.057633,43.957114],[-76.063008,43.995579],[-76.127549,43.903742],[-76.076022,43.900952],[-76.054668,43.89967],[-76.057633,43.957114]]]},"properties":{"id":"13685","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.000824,40.616314],[-74.014775,40.602014],[-73.988222,40.580281],[-73.982812,40.583792],[-73.987255,40.607245],[-74.000824,40.616314]]]},"properties":{"id":"11214","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.979233,40.705769],[-74.008117,40.686615],[-73.989992,40.683319],[-73.973126,40.689622],[-73.979233,40.705769]]]},"properties":{"id":"11201","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.975157,40.658723],[-73.989059,40.644119],[-73.981432,40.634955],[-73.977249,40.630707],[-73.965382,40.636279],[-73.966213,40.653189],[-73.975157,40.658723]]]},"properties":{"id":"11218","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.866141,43.695482],[-75.894699,43.604269],[-75.890768,43.551674],[-75.757569,43.492522],[-75.696283,43.569999],[-75.741106,43.646808],[-75.774553,43.688884],[-75.866141,43.695482]]]},"properties":{"id":"13437","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.341176,43.708746],[-75.310031,43.681925],[-75.115369,43.658032],[-75.119785,43.693737],[-75.123087,43.72037],[-75.130204,43.776311],[-75.341176,43.708746]]]},"properties":{"id":"13312","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.532579,42.789532],[-77.645738,42.771284],[-77.620656,42.763186],[-77.61167,42.763169],[-77.539342,42.74914],[-77.532579,42.789532]]]},"properties":{"id":"14466","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.821143,42.889834],[-77.880723,42.903933],[-77.920992,42.884992],[-77.955355,42.810524],[-77.856891,42.794249],[-77.830104,42.857337],[-77.821143,42.889834]],[[-77.887581,42.874684],[-77.887248,42.871132],[-77.906983,42.871466],[-77.903642,42.873757],[-77.893514,42.872295],[-77.887581,42.874684]],[[-77.870306,42.830125],[-77.880663,42.832409],[-77.881009,42.836882],[-77.868855,42.837221],[-77.870306,42.830125]]]},"properties":{"id":"14533","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.870306,42.830125],[-77.868855,42.837221],[-77.881009,42.836882],[-77.880663,42.832409],[-77.870306,42.830125]]]},"properties":{"id":"14539","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.803937,42.992402],[-75.898543,43.025224],[-75.905265,42.880418],[-75.904284,42.870473],[-75.813279,42.869539],[-75.746208,42.913396],[-75.68749,42.964856],[-75.687888,42.968185],[-75.691717,42.974637],[-75.803937,42.992402]]]},"properties":{"id":"13035","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.584013,42.874826],[-75.583425,42.861034],[-75.541364,42.884066],[-75.566898,42.931592],[-75.606336,42.91019],[-75.584013,42.874826]]]},"properties":{"id":"13310","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.376046,43.276211],[-77.533831,43.236397],[-77.525221,43.203563],[-77.519506,43.17446],[-77.494292,43.175565],[-77.400514,43.166717],[-77.374533,43.16651],[-77.365561,43.187947],[-77.376046,43.276211]]]},"properties":{"id":"14580","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.47945,43.099409],[-77.456757,43.034662],[-77.388141,43.03456],[-77.374533,43.16651],[-77.400514,43.166717],[-77.471687,43.111198],[-77.47945,43.099409]]]},"properties":{"id":"14450","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.634224,43.130863],[-77.630767,43.15941],[-77.673731,43.157125],[-77.665944,43.140222],[-77.63605,43.142226],[-77.645393,43.123223],[-77.642539,43.121661],[-77.634224,43.130863]]]},"properties":{"id":"14611","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.694393,43.238451],[-77.737394,43.250471],[-77.763015,43.23681],[-77.743904,43.189021],[-77.680342,43.192545],[-77.671353,43.238346],[-77.694393,43.238451]]]},"properties":{"id":"14626","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.895314,43.145565],[-77.827481,43.137077],[-77.753729,43.166525],[-77.743904,43.189021],[-77.763015,43.23681],[-77.847315,43.236037],[-77.895314,43.145565]]]},"properties":{"id":"14559","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.573327,43.087295],[-77.507236,43.110783],[-77.512657,43.1192],[-77.5754,43.136255],[-77.603185,43.109618],[-77.573327,43.087295]]]},"properties":{"id":"14618","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.69941,42.945584],[-77.595222,43.017563],[-77.648001,43.048026],[-77.723086,43.016962],[-77.730957,42.988372],[-77.758727,42.940461],[-77.69941,42.945584]]]},"properties":{"id":"14543","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.665249,42.80458],[-74.660305,42.730275],[-74.491583,42.76387],[-74.476343,42.781074],[-74.495956,42.788435],[-74.665249,42.80458]]]},"properties":{"id":"13459","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.660305,42.730275],[-74.665249,42.80458],[-74.709837,42.847611],[-74.763303,42.863237],[-74.80438,42.843798],[-74.814915,42.831096],[-74.797536,42.716896],[-74.764145,42.716395],[-74.779918,42.687859],[-74.711478,42.689392],[-74.649074,42.694348],[-74.660305,42.730275]]]},"properties":{"id":"13320","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.626601,40.759635],[-73.657632,40.751753],[-73.650997,40.736848],[-73.620736,40.742318],[-73.62128,40.756541],[-73.623794,40.759992],[-73.626601,40.759635]]]},"properties":{"id":"11501","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.700983,40.738896],[-73.71371,40.736175],[-73.729633,40.722453],[-73.729661,40.720483],[-73.729176,40.719167],[-73.686015,40.711934],[-73.684779,40.714708],[-73.687896,40.723318],[-73.700983,40.738896]]]},"properties":{"id":"11001","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.488728,40.722337],[-73.503875,40.743068],[-73.54569,40.743582],[-73.542955,40.733793],[-73.52627,40.707722],[-73.492249,40.705384],[-73.488728,40.722337]]]},"properties":{"id":"11756","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.680542,40.668711],[-73.659389,40.667495],[-73.66014,40.671096],[-73.667481,40.682967],[-73.67979,40.683557],[-73.680542,40.668711]]]},"properties":{"id":"11565","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.695834,40.767159],[-73.691638,40.768568],[-73.686552,40.766589],[-73.66169,40.779651],[-73.666362,40.789395],[-73.668472,40.804791],[-73.704729,40.819327],[-73.712253,40.806284],[-73.711345,40.792164],[-73.707283,40.785434],[-73.695834,40.767159]]]},"properties":{"id":"11030","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.938947,40.856079],[-73.924715,40.861552],[-73.92216,40.855607],[-73.910587,40.872456],[-73.928471,40.877243],[-73.947051,40.850517],[-73.943186,40.849745],[-73.938947,40.856079]]]},"properties":{"id":"10034","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.997532,40.714023],[-74.000455,40.714365],[-74.009988,40.709806],[-74.010542,40.709154],[-74.007769,40.702879],[-74.007531,40.702596],[-73.991896,40.709535],[-73.997532,40.714023]]]},"properties":{"id":"10038","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.924715,40.861552],[-73.928151,40.851863],[-73.938947,40.856079],[-73.943186,40.849745],[-73.934671,40.842958],[-73.929107,40.845586],[-73.92216,40.855607],[-73.924715,40.861552]]]},"properties":{"id":"10033","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.984076,40.749102],[-74.005733,40.75597],[-73.992063,40.743919],[-73.987746,40.744066],[-73.984076,40.749102]],[[-73.993948,40.751522],[-73.99111,40.750319],[-73.992006,40.749101],[-73.994844,40.750293],[-73.99768,40.751485],[-73.99679,40.752723],[-73.993948,40.751522]]]},"properties":{"id":"10001","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.999604,40.731043],[-73.992604,40.724136],[-73.988643,40.722933],[-73.979864,40.734975],[-73.990945,40.739673],[-73.999604,40.731043]]]},"properties":{"id":"10003","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.976744,40.759165],[-73.977989,40.759689],[-73.97845,40.759065],[-73.977201,40.758538],[-73.976744,40.759165]]]},"properties":{"id":"10111","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.993836,40.772825],[-73.989994,40.772242],[-73.986609,40.780712],[-73.987223,40.781007],[-73.993836,40.772825]]]},"properties":{"id":"10069","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.990945,40.739673],[-73.979864,40.734975],[-73.97365,40.73231],[-73.971566,40.742993],[-73.987746,40.744066],[-73.992063,40.743919],[-73.990945,40.739673]]]},"properties":{"id":"10010","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.977107,40.751883],[-73.977677,40.752119],[-73.978159,40.75146],[-73.976048,40.751441],[-73.977107,40.751883]]]},"properties":{"id":"10168","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.304278,43.23117],[-75.319077,43.207328],[-75.263628,43.216139],[-75.304278,43.23117]]]},"properties":{"id":"13469","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.550017,43.063855],[-75.460524,43.062644],[-75.436612,43.079889],[-75.486497,43.121785],[-75.513543,43.132449],[-75.588295,43.100981],[-75.584445,43.078139],[-75.583287,43.074293],[-75.550017,43.063855]]]},"properties":{"id":"13476","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.247717,43.400493],[-75.394931,43.34933],[-75.454481,43.297969],[-75.372144,43.283053],[-75.317264,43.320834],[-75.247717,43.400493]]]},"properties":{"id":"13486","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.407907,43.158168],[-75.378127,43.123778],[-75.323735,43.164102],[-75.341177,43.178441],[-75.407907,43.158168]]]},"properties":{"id":"13424","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.924738,43.242126],[-75.782687,43.22106],[-75.784051,43.249982],[-75.885857,43.324742],[-75.924738,43.242126]]]},"properties":{"id":"13042","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.380904,43.092569],[-75.383566,43.084428],[-75.364422,43.085598],[-75.364413,43.093841],[-75.380904,43.092569]]]},"properties":{"id":"13321","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.106495,43.313539],[-75.119403,43.315003],[-75.118255,43.312051],[-75.111775,43.310852],[-75.106495,43.313539]]]},"properties":{"id":"13352","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.526923,42.986172],[-75.526924,42.978176],[-75.516991,42.978179],[-75.517183,42.986269],[-75.525704,42.986176],[-75.526923,42.986172]]]},"properties":{"id":"13362","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.156142,43.091894],[-76.177628,43.078474],[-76.168207,43.069491],[-76.153576,43.056361],[-76.124999,43.072959],[-76.116747,43.092687],[-76.156142,43.091894]]]},"properties":{"id":"13208","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.015831,42.900423],[-76.018071,42.896538],[-76.01196,42.899357],[-76.015831,42.900423]]]},"properties":{"id":"13138","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.098005,42.986343],[-77.093771,42.911347],[-76.951137,42.941896],[-76.914244,42.982757],[-76.942872,42.978464],[-77.033222,43.012781],[-77.098005,42.986343]]]},"properties":{"id":"14532","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.033222,43.012781],[-76.942872,42.978464],[-76.953724,43.171639],[-76.965129,43.173868],[-77.065253,43.140762],[-77.033222,43.012781]]]},"properties":{"id":"14489","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.288076,41.236885],[-74.261107,41.230573],[-74.193874,41.273941],[-74.145777,41.285971],[-74.137749,41.313632],[-74.13917,41.329846],[-74.122466,41.387387],[-74.139486,41.408036],[-74.194096,41.398258],[-74.204828,41.397477],[-74.256875,41.277583],[-74.288076,41.236885]]]},"properties":{"id":"10950","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.23607,41.588672],[-74.356426,41.54372],[-74.359417,41.541948],[-74.351593,41.540562],[-74.287181,41.465552],[-74.242004,41.471917],[-74.157562,41.514088],[-74.150744,41.525736],[-74.23607,41.588672]]]},"properties":{"id":"12549","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.519529,41.502781],[-74.554469,41.476528],[-74.595489,41.433821],[-74.582459,41.418],[-74.519529,41.502781]]]},"properties":{"id":"10963","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.001472,41.342053],[-73.969102,41.344797],[-73.959756,41.376519],[-73.986402,41.379029],[-74.001472,41.342053]]]},"properties":{"id":"10928","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.341821,41.19186],[-74.321702,41.182697],[-74.308435,41.186995],[-74.261107,41.230573],[-74.288076,41.236885],[-74.341821,41.19186]]]},"properties":{"id":"10925","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.356145,41.537684],[-74.359417,41.541948],[-74.361775,41.542369],[-74.370723,41.543964],[-74.399331,41.543086],[-74.40614,41.500934],[-74.405115,41.499675],[-74.356145,41.537684]]]},"properties":{"id":"10919","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.399331,41.543086],[-74.370723,41.543964],[-74.369291,41.57912],[-74.377974,41.586385],[-74.382578,41.590113],[-74.418297,41.627765],[-74.494963,41.53352],[-74.416956,41.533479],[-74.399331,41.543086]]]},"properties":{"id":"12721","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.377974,41.586385],[-74.369291,41.57912],[-74.370324,41.588481],[-74.377974,41.586385]]]},"properties":{"id":"10985","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.601749,41.499082],[-74.554469,41.476528],[-74.519529,41.502781],[-74.522927,41.506256],[-74.602271,41.55657],[-74.601749,41.499082]]]},"properties":{"id":"12785","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.185244,43.339097],[-78.296871,43.306183],[-78.298375,43.246938],[-78.30871,43.228747],[-78.251949,43.131341],[-78.23451,43.131617],[-78.142152,43.165291],[-78.075674,43.299393],[-78.095522,43.29888],[-78.185244,43.339097]]]},"properties":{"id":"14411","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-76.268348,43.313216],[-76.273367,43.306158],[-76.241868,43.328277],[-76.24882,43.336982],[-76.268348,43.313216]]],[[[-76.529687,43.346712],[-76.479984,43.240296],[-76.479224,43.227519],[-76.423952,43.229449],[-76.27482,43.317569],[-76.263839,43.346918],[-76.256677,43.396928],[-76.361108,43.423272],[-76.472706,43.397555],[-76.471526,43.395829],[-76.529687,43.346712]]]]},"properties":{"id":"13069","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.074717,42.787773],[-75.117463,42.671795],[-75.054615,42.61496],[-75.016428,42.659588],[-75.027521,42.716286],[-75.017678,42.780896],[-75.074717,42.787773]]]},"properties":{"id":"13348","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.969457,42.522654],[-74.968706,42.468435],[-74.877539,42.498202],[-74.892526,42.61285],[-74.932491,42.590968],[-74.954259,42.544146],[-74.967692,42.528544],[-74.969457,42.522654]]]},"properties":{"id":"12116","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.7708,42.67436],[-74.711478,42.689392],[-74.779918,42.687859],[-74.7708,42.67436]]]},"properties":{"id":"13488","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.859995,41.495741],[-73.918758,41.489783],[-73.978588,41.442119],[-73.94694,41.402003],[-73.888259,41.409764],[-73.83694,41.45305],[-73.859995,41.495741]]]},"properties":{"id":"10516","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.621968,41.521831],[-73.650599,41.477103],[-73.62959,41.444106],[-73.533947,41.480115],[-73.530067,41.527194],[-73.621968,41.521831]]]},"properties":{"id":"12563","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.88724,40.727834],[-73.902854,40.713793],[-73.862079,40.712201],[-73.871429,40.729248],[-73.88724,40.727834]]]},"properties":{"id":"11379","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.80904,40.719912],[-73.810886,40.727172],[-73.809441,40.738674],[-73.835902,40.743327],[-73.845319,40.740048],[-73.825793,40.716146],[-73.811835,40.717873],[-73.80904,40.719912]]]},"properties":{"id":"11367","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.811835,40.717873],[-73.825793,40.716146],[-73.825956,40.716022],[-73.824304,40.713869],[-73.818383,40.706729],[-73.813659,40.697133],[-73.807858,40.687123],[-73.805768,40.682932],[-73.794578,40.686107],[-73.794371,40.686913],[-73.80242,40.701786],[-73.811835,40.717873]]]},"properties":{"id":"11435","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.770197,40.590913],[-73.789471,40.597611],[-73.802696,40.592785],[-73.801473,40.585849],[-73.770197,40.590913]]]},"properties":{"id":"11692","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.92246,40.757427],[-73.911498,40.754557],[-73.905239,40.767776],[-73.91431,40.771523],[-73.92188,40.763574],[-73.92246,40.757427]]]},"properties":{"id":"11103","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.93519,40.771745],[-73.92188,40.763574],[-73.91431,40.771523],[-73.920425,40.782874],[-73.93519,40.771745]]]},"properties":{"id":"11102","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.801473,40.585849],[-73.802696,40.592785],[-73.819093,40.588139],[-73.815506,40.582508],[-73.801473,40.585849]]]},"properties":{"id":"11693","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.887671,40.76672],[-73.882579,40.755854],[-73.872219,40.756943],[-73.860878,40.763206],[-73.887671,40.76672]]]},"properties":{"id":"11369","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.73376,40.772469],[-73.749575,40.781898],[-73.753274,40.765551],[-73.73376,40.772469]]]},"properties":{"id":"11363","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.887671,40.76672],[-73.860878,40.763206],[-73.860274,40.763444],[-73.889068,40.773682],[-73.887671,40.76672]]]},"properties":{"id":"11371","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.774194,40.786707],[-73.770862,40.788498],[-73.77551,40.795643],[-73.781825,40.790631],[-73.774194,40.786707]]]},"properties":{"id":"11359","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.719979,42.58028],[-73.752365,42.57668],[-73.752168,42.56672],[-73.773161,42.509377],[-73.679834,42.486151],[-73.658056,42.557671],[-73.65561,42.569907],[-73.719979,42.58028]]]},"properties":{"id":"12033","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.565822,42.869013],[-73.520172,42.819411],[-73.507722,42.82185],[-73.465536,42.846341],[-73.465709,42.84687],[-73.505037,42.982359],[-73.535676,42.991529],[-73.538282,42.990557],[-73.565822,42.869013]]]},"properties":{"id":"12185","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.45287,42.721095],[-73.470787,42.800624],[-73.542359,42.711678],[-73.537105,42.712194],[-73.45287,42.721095]]]},"properties":{"id":"12052","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.602663,42.684715],[-73.537105,42.712194],[-73.542359,42.711678],[-73.60008,42.704408],[-73.602663,42.684715]]]},"properties":{"id":"12140","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.361092,42.625928],[-73.33062,42.639841],[-73.349088,42.642486],[-73.368178,42.634795],[-73.366791,42.624795],[-73.361092,42.625928]]]},"properties":{"id":"12040","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.134558,40.551255],[-74.164941,40.560112],[-74.14517,40.535756],[-74.134558,40.551255]]]},"properties":{"id":"10308","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.969284,41.123778],[-73.943808,41.175434],[-73.989872,41.192448],[-74.005088,41.190659],[-74.034093,41.169173],[-74.025349,41.122713],[-73.986848,41.121196],[-73.969284,41.123778]]]},"properties":{"id":"10956","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.942156,44.465345],[-74.832307,44.549189],[-74.835884,44.626825],[-75.019669,44.563339],[-75.024389,44.476916],[-74.942156,44.465345]]]},"properties":{"id":"13625","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.356307,44.788596],[-75.334113,44.677932],[-75.315535,44.655502],[-75.23625,44.652069],[-75.183056,44.696556],[-75.228451,44.844071],[-75.25451,44.851137],[-75.356307,44.788596]]]},"properties":{"id":"13658","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.809743,44.329318],[-74.687816,44.219704],[-74.665933,44.219775],[-74.642167,44.289335],[-74.685027,44.342688],[-74.686129,44.349099],[-74.809743,44.329318]]]},"properties":{"id":"12922","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.174183,44.299429],[-75.185258,44.180827],[-75.089101,44.304386],[-75.166665,44.305786],[-75.174183,44.299429]]]},"properties":{"id":"13639","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.716355,44.771666],[-74.677514,44.71032],[-74.653988,44.699635],[-74.614223,44.752374],[-74.617402,44.774552],[-74.628489,44.855697],[-74.716355,44.771666]]]},"properties":{"id":"12967","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.034452,44.177914],[-75.075675,44.055971],[-74.945918,44.06155],[-74.901293,44.15989],[-74.909066,44.179495],[-75.034452,44.177914]]]},"properties":{"id":"13690","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.186393,44.514725],[-75.171284,44.508903],[-75.178894,44.514502],[-75.186393,44.514725]]]},"properties":{"id":"13677","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.977473,44.604383],[-74.978718,44.60927],[-74.982604,44.60927],[-74.982604,44.602543],[-74.977473,44.604383]]]},"properties":{"id":"13647","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.903929,43.06594],[-73.877888,43.071597],[-73.862295,43.095465],[-73.92286,43.132905],[-73.977482,43.166942],[-74.043244,43.160852],[-74.068017,43.129578],[-73.973723,43.033764],[-73.943965,43.044458],[-73.946556,43.074143],[-73.903929,43.06594]]]},"properties":{"id":"12850","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.943965,43.044458],[-73.903929,43.06594],[-73.946556,43.074143],[-73.943965,43.044458]]]},"properties":{"id":"12863","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.419948,42.723389],[-74.397764,42.679895],[-74.314667,42.710985],[-74.32722,42.717859],[-74.383667,42.728437],[-74.384207,42.728196],[-74.419948,42.723389]]]},"properties":{"id":"12092","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.546118,42.410305],[-77.412723,42.486304],[-77.397141,42.55204],[-77.43483,42.56748],[-77.501599,42.552003],[-77.599489,42.437127],[-77.595866,42.407766],[-77.546118,42.410305]]]},"properties":{"id":"14826","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.150133,42.182798],[-77.138365,42.178196],[-77.137328,42.180278],[-77.150133,42.182798]]]},"properties":{"id":"14827","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.059647,40.892459],[-73.061602,40.851378],[-73.021844,40.862605],[-73.03515,40.888503],[-73.059647,40.892459]]]},"properties":{"id":"11784","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.108872,40.742828],[-73.108039,40.717186],[-73.089667,40.720355],[-73.108872,40.742828]]]},"properties":{"id":"11796","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.264415,41.066575],[-72.385396,41.055976],[-72.333133,41.036186],[-72.287606,41.010723],[-72.265237,41.046146],[-72.264415,41.066575]]]},"properties":{"id":"11964","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-72.594258,40.810487],[-72.567605,40.817054],[-72.57165,40.821172],[-72.594258,40.810487]]],[[[-72.582512,40.831599],[-72.617574,40.833468],[-72.594258,40.810487],[-72.582512,40.831599]]]]},"properties":{"id":"11959","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.208725,40.898738],[-73.160437,40.859702],[-73.135221,40.862075],[-73.131253,40.869094],[-73.148258,40.928737],[-73.151922,40.938242],[-73.232677,40.913281],[-73.208725,40.898738]]]},"properties":{"id":"11780","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.506525,41.051933],[-72.447528,41.018804],[-72.436136,41.035653],[-72.483338,41.06026],[-72.506525,41.051933]]]},"properties":{"id":"11958","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.88596,40.802146],[-72.890798,40.844382],[-72.901625,40.865585],[-72.928701,40.865889],[-72.942672,40.863276],[-72.950195,40.797931],[-72.926345,40.801585],[-72.88596,40.802146]]]},"properties":{"id":"11980","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-72.852789,40.782773],[-72.862844,40.740694],[-72.812202,40.776866],[-72.818963,40.783767],[-72.823628,40.78856],[-72.852789,40.782773]]]},"properties":{"id":"11951","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.584924,41.738164],[-74.607326,41.705963],[-74.599477,41.689893],[-74.572476,41.677523],[-74.56759,41.680276],[-74.550313,41.683907],[-74.53024,41.719448],[-74.56207,41.737772],[-74.584924,41.738164]]]},"properties":{"id":"12789","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.619498,41.755554],[-74.60392,41.730241],[-74.584924,41.738164],[-74.56207,41.737772],[-74.554671,41.766779],[-74.525506,41.796817],[-74.5756,41.837458],[-74.636831,41.825548],[-74.646364,41.818826],[-74.615676,41.787813],[-74.628992,41.78457],[-74.626121,41.762677],[-74.619498,41.755554]]]},"properties":{"id":"12788","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-74.682304,41.79578],[-74.628992,41.78457],[-74.615676,41.787813],[-74.647338,41.816427],[-74.682304,41.79578]]],[[[-74.713331,41.73567],[-74.66782,41.726893],[-74.638923,41.735587],[-74.619498,41.755554],[-74.626121,41.762677],[-74.677079,41.77885],[-74.718571,41.757602],[-74.713331,41.73567]]]]},"properties":{"id":"12747","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.736142,41.706536],[-74.762575,41.681053],[-74.783831,41.604143],[-74.630536,41.577487],[-74.592431,41.64132],[-74.609391,41.656945],[-74.650301,41.672629],[-74.654547,41.676713],[-74.679228,41.704285],[-74.664062,41.720114],[-74.66782,41.726893],[-74.713331,41.73567],[-74.712398,41.715527],[-74.736142,41.706536]]]},"properties":{"id":"12701","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.770435,41.767123],[-74.718571,41.757602],[-74.677079,41.77885],[-74.682304,41.79578],[-74.647338,41.816427],[-74.646364,41.818826],[-74.636831,41.825548],[-74.63894,41.828008],[-74.673915,41.851571],[-74.771512,41.827336],[-74.813602,41.800818],[-74.817468,41.786967],[-74.770435,41.767123]]]},"properties":{"id":"12754","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.718571,41.757602],[-74.770435,41.767123],[-74.77704,41.69415],[-74.762575,41.681053],[-74.736142,41.706536],[-74.713331,41.73567],[-74.718571,41.757602]]]},"properties":{"id":"12734","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.864774,41.680427],[-74.910807,41.615382],[-74.799023,41.605665],[-74.819368,41.674968],[-74.820745,41.682527],[-74.861814,41.680693],[-74.864774,41.680427]]]},"properties":{"id":"12786","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.514466,41.667073],[-74.456356,41.640216],[-74.446233,41.639359],[-74.425389,41.660615],[-74.466817,41.669181],[-74.514466,41.667073]]]},"properties":{"id":"12769","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.932836,41.531959],[-74.982581,41.506596],[-74.921983,41.521702],[-74.932836,41.531959]]]},"properties":{"id":"12792","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.393773,41.692477],[-74.464778,41.682519],[-74.466817,41.669181],[-74.425389,41.660615],[-74.421428,41.658506],[-74.403933,41.666894],[-74.393773,41.692477]]]},"properties":{"id":"12483","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.430662,41.799541],[-74.366501,41.743237],[-74.376242,41.783313],[-74.369504,41.900569],[-74.484261,41.838445],[-74.430662,41.799541]]]},"properties":{"id":"12458","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.157444,42.084407],[-76.204853,41.998538],[-76.10584,41.998858],[-76.108922,42.074331],[-76.157444,42.084407]]]},"properties":{"id":"13732","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.34726,42.414509],[-76.374426,42.394227],[-76.415205,42.336487],[-76.315663,42.308305],[-76.314197,42.308299],[-76.278356,42.378114],[-76.269718,42.407158],[-76.289343,42.441694],[-76.34726,42.414509]]]},"properties":{"id":"14817","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.131335,42.002184],[-74.057596,42.031569],[-74.074797,42.096589],[-74.082194,42.097153],[-74.136086,42.016036],[-74.131335,42.002184]]]},"properties":{"id":"12498","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.057596,42.031569],[-74.131335,42.002184],[-74.134791,42.000884],[-74.088822,41.98949],[-74.086069,41.975779],[-74.132189,41.930118],[-74.180772,41.94908],[-74.192505,41.925327],[-74.118619,41.866045],[-74.097599,41.853259],[-74.057088,41.866097],[-74.029504,41.880979],[-73.988861,41.899037],[-73.983364,41.9153],[-73.973656,41.914884],[-73.950708,41.987076],[-73.976026,41.994566],[-74.006376,42.010249],[-74.027913,42.005578],[-74.057596,42.031569]]]},"properties":{"id":"12401","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.490335,41.831864],[-74.525506,41.796817],[-74.554671,41.766779],[-74.49227,41.697862],[-74.464778,41.682519],[-74.393773,41.692477],[-74.342881,41.678242],[-74.332138,41.684801],[-74.342286,41.712953],[-74.366501,41.743237],[-74.430662,41.799541],[-74.490335,41.831864]]]},"properties":{"id":"12428","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.048267,41.854022],[-74.086006,41.826484],[-74.046966,41.82204],[-74.045096,41.848129],[-74.048267,41.854022]]]},"properties":{"id":"12486","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-74.31745,42.000311],[-74.33779,41.951056],[-74.348368,41.928268],[-74.241747,41.929773],[-74.277715,41.99217],[-74.31745,42.000311]]]},"properties":{"id":"12494","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.414378,43.124553],[-73.535676,42.991529],[-73.505037,42.982359],[-73.475336,42.976674],[-73.404188,42.998235],[-73.270703,43.017565],[-73.266164,43.087998],[-73.345881,43.112952],[-73.414378,43.124553]]]},"properties":{"id":"12816","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.923534,41.248755],[-73.847134,41.253368],[-73.838955,41.28875],[-73.856268,41.292625],[-73.865577,41.294408],[-73.865057,41.303081],[-73.86201,41.334264],[-73.875119,41.332785],[-73.89346,41.330892],[-73.979528,41.322105],[-73.945712,41.295231],[-73.928735,41.26856],[-73.928985,41.263491],[-73.923534,41.248755]]]},"properties":{"id":"10567","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.83344,40.949478],[-73.818366,40.944418],[-73.818132,40.963585],[-73.823815,40.97759],[-73.83344,40.949478]]]},"properties":{"id":"10707","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.758482,41.038637],[-73.766898,41.073993],[-73.798107,41.055803],[-73.803814,41.047273],[-73.775168,41.032534],[-73.758482,41.038637]]]},"properties":{"id":"10603","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.940359,41.233056],[-73.923534,41.248755],[-73.928985,41.263491],[-73.933775,41.268408],[-73.934461,41.268149],[-73.950506,41.255459],[-73.953691,41.249098],[-73.940359,41.233056]]]},"properties":{"id":"10548","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.865057,41.303081],[-73.865577,41.294408],[-73.856268,41.292625],[-73.865057,41.303081]]]},"properties":{"id":"10517","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.785071,41.034911],[-73.785289,41.021519],[-73.763962,40.998468],[-73.769815,41.024777],[-73.776303,41.031867],[-73.785071,41.034911]]]},"properties":{"id":"10606","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.716529,40.945286],[-73.657615,40.985499],[-73.692476,41.004725],[-73.717185,41.014191],[-73.724228,41.015908],[-73.726273,41.000038],[-73.715712,40.959277],[-73.716529,40.945286]]]},"properties":{"id":"10580","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.62142,41.255742],[-73.565873,41.261412],[-73.592598,41.277853],[-73.612218,41.284586],[-73.62142,41.255742]]]},"properties":{"id":"10518","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.792644,41.143535],[-73.845117,41.108425],[-73.801754,41.109787],[-73.793219,41.114017],[-73.748415,41.118355],[-73.743234,41.124402],[-73.745298,41.129295],[-73.792644,41.143535]]]},"properties":{"id":"10570","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.612218,41.284586],[-73.592598,41.277853],[-73.585567,41.30257],[-73.614036,41.307878],[-73.614497,41.302955],[-73.612218,41.284586]]]},"properties":{"id":"10597","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.873834,41.025566],[-73.874526,41.027377],[-73.876173,41.027455],[-73.87631,41.025621],[-73.873834,41.025566]]]},"properties":{"id":"10503","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-73.797456,41.340647],[-73.813059,41.332277],[-73.78826,41.341737],[-73.797456,41.340647]]]},"properties":{"id":"10535","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.176431,42.821474],[-78.248667,42.771663],[-78.2647,42.695663],[-78.172668,42.67097],[-78.0742,42.713472],[-78.074237,42.79156],[-78.169754,42.811769],[-78.176431,42.821474]]]},"properties":{"id":"14569","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.09058,42.620206],[-77.141754,42.629603],[-77.140749,42.597252],[-77.09058,42.620206]]]},"properties":{"id":"14478","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.015034,42.757715],[-77.000842,42.764079],[-77.007175,42.764031],[-77.015034,42.757715]]],[[[-77.015034,42.757715],[-77.014784,42.763863],[-77.031302,42.763752],[-77.015034,42.757715]]]]},"properties":{"id":"14415","state_code":"36"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.375634,35.829913],[-79.291035,35.647508],[-79.211408,35.635432],[-79.210845,35.635676],[-79.071804,35.658958],[-79.032724,35.700823],[-79.024363,35.737387],[-79.052398,35.757447],[-79.084588,35.815955],[-79.236816,35.848771],[-79.326124,35.844075],[-79.375634,35.829913]]]},"properties":{"id":"27312","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.075417,36.016154],[-81.111514,35.852038],[-80.98079,35.90319],[-81.00532,36.000697],[-81.075417,36.016154]]]},"properties":{"id":"28636","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.431565,35.86834],[-81.530727,35.828498],[-81.55596,35.777332],[-81.547039,35.777186],[-81.533872,35.779705],[-81.450224,35.78522],[-81.43631,35.773342],[-81.426491,35.779796],[-81.420659,35.770632],[-81.408434,35.76527],[-81.337777,35.84479],[-81.339015,35.905047],[-81.431565,35.86834]]]},"properties":{"id":"28630","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.361922,36.435038],[-81.33267,36.370615],[-81.25258,36.349671],[-81.179657,36.411567],[-81.296377,36.506833],[-81.3074,36.480707],[-81.337056,36.473875],[-81.361922,36.435038]]]},"properties":{"id":"28644","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.972551,34.807575],[-79.97257,34.81473],[-79.976906,34.822083],[-79.968011,34.821765],[-79.968896,34.807476],[-79.927618,34.806555],[-79.909083,34.838859],[-79.967346,34.903986],[-80.172865,34.820119],[-80.174685,34.811761],[-79.972551,34.807575]]]},"properties":{"id":"28119","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.709512,36.320676],[-81.566583,36.272414],[-81.552221,36.27629],[-81.539829,36.330191],[-81.586682,36.407376],[-81.686256,36.371674],[-81.708061,36.346157],[-81.70739,36.33584],[-81.709512,36.320676]]]},"properties":{"id":"28684","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.778903,36.100166],[-81.706814,36.085238],[-81.639354,36.137299],[-81.773067,36.123281],[-81.81152,36.111544],[-81.778903,36.100166]]]},"properties":{"id":"28605","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.95752,35.946677],[-81.984721,35.951307],[-81.981723,35.91152],[-81.95752,35.946677]]]},"properties":{"id":"28647","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.907131,36.010453],[-81.884934,36.024813],[-81.91314,36.033231],[-81.907131,36.010453]]]},"properties":{"id":"28662","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.655185,35.550139],[-76.791825,35.705397],[-76.845119,35.705409],[-76.90593,35.683069],[-76.844449,35.513839],[-76.732959,35.531715],[-76.655185,35.550139]]]},"properties":{"id":"27865","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.910016,35.323907],[-76.858434,35.308843],[-76.8649,35.35952],[-76.910016,35.323907]]]},"properties":{"id":"27821","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.76318,34.655089],[-78.722169,34.652923],[-78.735568,34.670972],[-78.76318,34.655089]]]},"properties":{"id":"28332","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.403609,34.011952],[-78.365406,34.205827],[-78.391606,34.174003],[-78.494928,34.166772],[-78.545001,34.140541],[-78.616963,34.023213],[-78.644214,33.950248],[-78.554086,33.941224],[-78.533487,33.949838],[-78.51212,33.977614],[-78.513156,33.98292],[-78.536029,33.993959],[-78.561379,34.000924],[-78.559206,34.029603],[-78.521739,33.997253],[-78.487546,33.987381],[-78.403609,34.011952]]]},"properties":{"id":"28420","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.55596,35.777332],[-81.755861,35.925313],[-81.807157,35.961533],[-81.912772,35.900058],[-81.919242,35.848759],[-81.829611,35.592019],[-81.824184,35.574955],[-81.692329,35.579789],[-81.690991,35.584309],[-81.550258,35.69002],[-81.601945,35.748623],[-81.61034,35.765611],[-81.593397,35.771197],[-81.594904,35.751351],[-81.547039,35.777186],[-81.55596,35.777332]],[[-81.78962,35.721848],[-81.796651,35.717769],[-81.799664,35.720567],[-81.782932,35.728074],[-81.779168,35.732861],[-81.773223,35.728443],[-81.775286,35.725477],[-81.78362,35.726006],[-81.78962,35.721848]]]},"properties":{"id":"28655","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.527702,35.741551],[-81.550258,35.69002],[-81.690991,35.584309],[-81.574205,35.559718],[-81.511313,35.584721],[-81.485542,35.625443],[-81.46819,35.720431],[-81.476242,35.722996],[-81.473177,35.726411],[-81.462328,35.722768],[-81.464324,35.718885],[-81.443242,35.711324],[-81.421986,35.732118],[-81.450224,35.78522],[-81.533872,35.779705],[-81.532893,35.749153],[-81.5346,35.755677],[-81.51486,35.755472],[-81.527702,35.741551]]]},"properties":{"id":"28612","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.274142,35.503052],[-80.263375,35.567833],[-80.365223,35.564511],[-80.399684,35.480993],[-80.333596,35.445499],[-80.295453,35.486022],[-80.295236,35.502947],[-80.274142,35.503052]]]},"properties":{"id":"28071","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.674288,34.747782],[-76.691678,34.871655],[-76.957621,34.810692],[-77.008298,34.689272],[-76.912322,34.716423],[-76.867243,34.722599],[-76.845789,34.723406],[-76.674288,34.747782]]]},"properties":{"id":"28570","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.50003,34.836686],[-76.532481,34.788613],[-76.496424,34.777431],[-76.483862,34.839347],[-76.50003,34.836686]]]},"properties":{"id":"28589","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.518376,36.540779],[-79.440881,36.409585],[-79.393463,36.450774],[-79.411939,36.541357],[-79.518376,36.540779]]]},"properties":{"id":"27311","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.964531,36.542138],[-79.111861,36.541639],[-79.217587,36.480356],[-79.182488,36.449701],[-79.097467,36.460201],[-78.964531,36.542138]]]},"properties":{"id":"27343","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.148837,36.342322],[-79.153733,36.242117],[-79.119897,36.173827],[-79.027382,36.165909],[-79.05117,36.241071],[-79.010746,36.290563],[-79.113153,36.373618],[-79.148837,36.342322]]]},"properties":{"id":"27541","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.211408,35.635432],[-79.291035,35.647508],[-79.529398,35.61874],[-79.470805,35.516493],[-79.430107,35.516998],[-79.28249,35.624328],[-79.252528,35.56562],[-79.211408,35.635432]]]},"properties":{"id":"27207","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.032724,35.700823],[-78.900406,35.68281],[-78.813629,35.726217],[-78.821359,35.74359],[-79.024363,35.737387],[-79.032724,35.700823]]]},"properties":{"id":"27502","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.252528,35.56562],[-79.430107,35.516998],[-79.389849,35.461484],[-79.286686,35.378898],[-79.28044,35.381491],[-79.067662,35.462703],[-78.959216,35.50599],[-78.969761,35.521433],[-79.210845,35.635676],[-79.211408,35.635432],[-79.252528,35.56562]]]},"properties":{"id":"27330","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.856271,35.137537],[-83.893569,35.233282],[-83.96711,35.217962],[-83.862776,35.132496],[-83.856271,35.137537]]]},"properties":{"id":"28905","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.60955,35.17432],[-81.543302,35.171691],[-81.47401,35.235294],[-81.456342,35.274452],[-81.670881,35.316931],[-81.67096,35.318341],[-81.688789,35.309713],[-81.60955,35.17432]],[[-81.666378,35.247053],[-81.673615,35.244278],[-81.67443,35.249369],[-81.666378,35.247053]]]},"properties":{"id":"28152","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.886982,34.84794],[-78.996852,34.958753],[-78.909792,34.869333],[-78.886982,34.84794]]]},"properties":{"id":"28348","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.682082,36.055795],[-75.773237,36.231548],[-75.813189,36.223544],[-75.76667,36.086627],[-75.764027,36.06206],[-75.731737,36.036783],[-75.682082,36.055795]]]},"properties":{"id":"27949","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.63661,34.834425],[-77.832263,34.830414],[-77.708291,34.758407],[-77.63661,34.834425]]]},"properties":{"id":"28521","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.932939,35.992372],[-78.886475,35.968475],[-78.871393,36.004097],[-78.905338,36.020584],[-78.932939,35.992372]]]},"properties":{"id":"27701","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.905338,36.020584],[-78.871393,36.004097],[-78.748771,36.029926],[-78.791039,36.081521],[-78.804983,36.091883],[-78.811485,36.095033],[-78.914884,36.06866],[-78.905338,36.020584]]]},"properties":{"id":"27704","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.878661,35.957397],[-78.867117,35.87655],[-78.802172,35.92703],[-78.758174,35.921813],[-78.708335,35.984355],[-78.719592,36.029435],[-78.748771,36.029926],[-78.871393,36.004097],[-78.886475,35.968475],[-78.878661,35.957397]],[[-78.841263,35.93057],[-78.827113,35.927133],[-78.831347,35.920836],[-78.842178,35.922149],[-78.841263,35.93057]]]},"properties":{"id":"27703","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.802172,35.92703],[-78.867117,35.87655],[-78.890495,35.867842],[-78.84821,35.811649],[-78.777577,35.845369],[-78.769103,35.861052],[-78.75945,35.891893],[-78.802172,35.92703]]]},"properties":{"id":"27560","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.685703,35.987844],[-78.708335,35.984355],[-78.758174,35.921813],[-78.740845,35.884445],[-78.67911,35.875901],[-78.68631,35.94224],[-78.685703,35.987844]]]},"properties":{"id":"27613","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.802172,35.92703],[-78.75945,35.891893],[-78.740845,35.884445],[-78.758174,35.921813],[-78.802172,35.92703]]]},"properties":{"id":"27617","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.797886,35.936207],[-77.826133,35.871406],[-77.812616,35.85924],[-77.781483,35.843987],[-77.743037,35.83743],[-77.662073,35.865129],[-77.655124,35.940886],[-77.774125,35.998742],[-77.797886,35.936207]]]},"properties":{"id":"27801","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.774125,35.998742],[-77.655124,35.940886],[-77.602631,35.983873],[-77.893748,36.084212],[-77.906314,36.038411],[-77.774125,35.998742]]]},"properties":{"id":"27809","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.141339,36.159363],[-80.175445,36.141539],[-80.262756,36.102633],[-80.26461,36.092655],[-80.250753,36.075572],[-80.230402,36.08686],[-80.228933,36.086209],[-80.22776,36.093139],[-80.219235,36.089054],[-80.168012,36.102735],[-80.141339,36.159363]]]},"properties":{"id":"27101","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.373359,36.096434],[-80.365246,36.073596],[-80.26461,36.092655],[-80.262756,36.102633],[-80.261809,36.120508],[-80.373359,36.096434]]]},"properties":{"id":"27104","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.175445,36.141539],[-80.141339,36.159363],[-80.122919,36.178019],[-80.123239,36.215371],[-80.182888,36.2193],[-80.203517,36.206117],[-80.175445,36.141539]]]},"properties":{"id":"27051","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.039815,36.282926],[-80.123239,36.215371],[-80.122919,36.178019],[-80.036119,36.228695],[-80.039815,36.282926]]]},"properties":{"id":"27009","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.336472,36.137025],[-78.424227,36.039854],[-78.333182,35.95159],[-78.272085,35.932518],[-78.290098,35.958931],[-78.241062,35.988944],[-78.208852,35.938559],[-78.141197,36.060345],[-78.010693,36.19621],[-78.006554,36.202663],[-78.014437,36.20723],[-78.257523,36.24778],[-78.342966,36.199859],[-78.336472,36.137025]]]},"properties":{"id":"27549","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.342966,36.199859],[-78.483889,36.232337],[-78.56785,36.191316],[-78.336472,36.137025],[-78.342966,36.199859]]]},"properties":{"id":"27544","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.562632,36.065418],[-78.424227,36.039854],[-78.336472,36.137025],[-78.56785,36.191316],[-78.599338,36.169175],[-78.57829,36.095541],[-78.562632,36.065418]]]},"properties":{"id":"27525","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.685703,35.987844],[-78.557737,35.909878],[-78.47202,35.871566],[-78.449771,35.869345],[-78.422372,35.910218],[-78.452227,35.913045],[-78.468204,35.923978],[-78.441657,35.933221],[-78.438662,35.920441],[-78.415643,35.916113],[-78.389944,35.920139],[-78.562632,36.065418],[-78.57829,36.095541],[-78.719592,36.029435],[-78.708335,35.984355],[-78.685703,35.987844]]]},"properties":{"id":"27587","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.184564,36.544193],[-78.226527,36.291885],[-78.257523,36.24778],[-78.014437,36.20723],[-77.981435,36.288711],[-78.129679,36.543896],[-78.184564,36.544193]]]},"properties":{"id":"27589","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.199174,35.41029],[-81.210082,35.40768],[-81.203493,35.397309],[-81.199174,35.41029]]]},"properties":{"id":"28077","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.096849,35.255358],[-81.074109,35.219719],[-81.073096,35.249004],[-81.092238,35.254787],[-81.096849,35.255358]]]},"properties":{"id":"28032","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.350114,35.348603],[-81.270414,35.252064],[-81.240012,35.292107],[-81.253843,35.365535],[-81.277616,35.391534],[-81.310038,35.375837],[-81.350114,35.348603]]]},"properties":{"id":"28016","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.935646,36.478344],[-76.778708,36.443166],[-76.654765,36.458876],[-76.636123,36.469548],[-76.670852,36.550803],[-76.916001,36.543818],[-76.935646,36.478344]]]},"properties":{"id":"27937","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.45697,36.542474],[-78.685435,36.542065],[-78.593613,36.47267],[-78.558278,36.467942],[-78.492293,36.489939],[-78.45697,36.542474]]]},"properties":{"id":"27507","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.681016,35.961112],[-79.710339,35.966563],[-79.723868,35.863114],[-79.746885,35.846306],[-79.671084,35.836203],[-79.668429,35.894711],[-79.681016,35.961112]]]},"properties":{"id":"27233","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.903375,35.942095],[-79.9384,35.959271],[-80.047206,35.911849],[-80.018585,35.880277],[-79.904934,35.855321],[-79.864492,35.865424],[-79.855819,35.915096],[-79.869619,35.934019],[-79.903375,35.942095]]]},"properties":{"id":"27263","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.906041,36.278982],[-80.033531,36.309636],[-80.039815,36.282926],[-80.036119,36.228695],[-79.989803,36.212726],[-79.959359,36.212205],[-79.906041,36.278982]]]},"properties":{"id":"27357","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.589414,35.52807],[-78.746455,35.536234],[-78.810479,35.454466],[-78.714673,35.425268],[-78.618966,35.444654],[-78.589414,35.52807]]]},"properties":{"id":"27501","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.618966,35.444654],[-78.714673,35.425268],[-78.718634,35.382441],[-78.697856,35.374999],[-78.59811,35.386002],[-78.618966,35.444654]]]},"properties":{"id":"27521","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.959216,35.50599],[-78.923588,35.476886],[-78.810479,35.454466],[-78.746455,35.536234],[-78.695967,35.612517],[-78.71797,35.656058],[-78.767623,35.651008],[-78.963022,35.528874],[-78.968772,35.522528],[-78.969761,35.521433],[-78.959216,35.50599]]]},"properties":{"id":"27526","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.695967,35.612517],[-78.746455,35.536234],[-78.589414,35.52807],[-78.559837,35.551974],[-78.607334,35.564755],[-78.695967,35.612517]]]},"properties":{"id":"27592","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.957256,35.523561],[-82.880093,35.521282],[-82.846352,35.614451],[-82.883665,35.67819],[-83.097952,35.775679],[-83.111289,35.770856],[-82.957256,35.523561]]]},"properties":{"id":"28721","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.097952,35.775679],[-82.883665,35.67819],[-82.808316,35.700393],[-82.805856,35.935679],[-83.097952,35.775679]]]},"properties":{"id":"28743","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.75293,35.196664],[-75.765614,35.205284],[-75.864354,35.148828],[-75.750681,35.189378],[-75.75293,35.196664]]]},"properties":{"id":"27960","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.864277,35.639871],[-80.928408,35.624083],[-80.942447,35.605179],[-80.959954,35.548],[-80.960029,35.54781],[-80.94703,35.487699],[-80.874382,35.512469],[-80.855793,35.511064],[-80.864277,35.639871]]]},"properties":{"id":"28117","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.64235,35.707565],[-80.610765,35.745723],[-80.665271,35.84515],[-80.70767,35.852913],[-80.74481,35.749758],[-80.811943,35.692417],[-80.799647,35.667911],[-80.786727,35.663772],[-80.64235,35.707565]]]},"properties":{"id":"27013","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.078884,35.433708],[-83.075428,35.368771],[-83.02798,35.383166],[-83.078884,35.433708]]]},"properties":{"id":"28707","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.307216,35.057703],[-83.296814,34.996257],[-83.108614,35.000659],[-83.186063,35.130057],[-83.209903,35.154261],[-83.307216,35.057703]]]},"properties":{"id":"28741","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.371641,35.455937],[-83.224757,35.443033],[-83.180144,35.518821],[-83.477968,35.449578],[-83.371641,35.455937]]]},"properties":{"id":"28719","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.432033,35.285857],[-78.477312,35.175019],[-78.451696,35.14299],[-78.289282,35.15881],[-78.261423,35.203683],[-78.29282,35.27438],[-78.363474,35.338456],[-78.432033,35.285857]]]},"properties":{"id":"28366","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.460036,35.705691],[-78.356091,35.583728],[-78.328065,35.589656],[-78.336329,35.681755],[-78.341367,35.690702],[-78.431929,35.720512],[-78.460036,35.705691]]]},"properties":{"id":"27527","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.331783,35.053947],[-83.537904,35.035277],[-83.482779,34.993256],[-83.367759,34.995105],[-83.331783,35.053947]]]},"properties":{"id":"28763","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.177616,35.82766],[-77.167392,35.838242],[-77.183182,35.836087],[-77.184272,35.830983],[-77.177616,35.82766]]]},"properties":{"id":"27825","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.245153,36.131011],[-82.267735,36.014438],[-82.148395,35.932976],[-82.059126,35.988378],[-82.080443,36.10302],[-82.245153,36.131011]]]},"properties":{"id":"28705","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.396397,35.221346],[-79.437137,35.239629],[-79.536545,35.1998],[-79.425664,35.160641],[-79.396397,35.221346]]]},"properties":{"id":"28374","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.581717,35.525678],[-79.711644,35.432504],[-79.660074,35.40201],[-79.563604,35.391516],[-79.389849,35.461484],[-79.430107,35.516998],[-79.470805,35.516493],[-79.581717,35.525678]]]},"properties":{"id":"27325","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.536545,35.1998],[-79.437137,35.239629],[-79.583678,35.302153],[-79.641693,35.266485],[-79.555805,35.181862],[-79.536545,35.1998]]]},"properties":{"id":"27376","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.641693,35.266485],[-79.583678,35.302153],[-79.563604,35.391516],[-79.660074,35.40201],[-79.677216,35.388037],[-79.710222,35.302748],[-79.71265,35.283316],[-79.641693,35.266485]]]},"properties":{"id":"27242","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.774125,35.998742],[-77.906314,36.038411],[-77.908498,35.94794],[-77.797886,35.936207],[-77.774125,35.998742]]]},"properties":{"id":"27804","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.91994,34.193472],[-77.95669,34.191996],[-77.930956,34.050324],[-77.899696,34.055741],[-77.888883,34.198002],[-77.91994,34.193472]]]},"properties":{"id":"28412","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.568107,34.670331],[-77.595629,34.730338],[-77.680353,34.725794],[-77.735208,34.656237],[-77.696289,34.6273],[-77.630547,34.606258],[-77.593737,34.620518],[-77.568107,34.670331]]]},"properties":{"id":"28454","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.617517,34.576379],[-77.604171,34.406567],[-77.50994,34.445332],[-77.460901,34.583025],[-77.593737,34.620518],[-77.630547,34.606258],[-77.617517,34.576379]]]},"properties":{"id":"28445","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.774169,35.222854],[-76.819125,35.161716],[-76.811879,35.09877],[-76.804217,35.1033],[-76.767554,35.139187],[-76.720602,35.142986],[-76.663347,35.168162],[-76.690076,35.196037],[-76.653951,35.201399],[-76.657077,35.170446],[-76.579041,35.1871],[-76.61625,35.267013],[-76.774169,35.222854]]]},"properties":{"id":"28515","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.143678,34.445295],[-78.256316,34.399763],[-78.078669,34.339233],[-78.029922,34.331768],[-77.993323,34.337194],[-77.969679,34.378033],[-78.041824,34.457133],[-78.129011,34.504891],[-78.143678,34.445295]]]},"properties":{"id":"28435","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.581118,36.171615],[-76.406841,36.074663],[-76.184203,36.11473],[-76.418737,36.324583],[-76.549495,36.268097],[-76.581118,36.171615]],[[-76.469453,36.217039],[-76.446656,36.215719],[-76.463799,36.201353],[-76.469453,36.217039]]]},"properties":{"id":"27944","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.469453,36.217039],[-76.463799,36.201353],[-76.446656,36.215719],[-76.469453,36.217039]]]},"properties":{"id":"27985","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.305534,35.326836],[-82.204606,35.288674],[-82.117025,35.28539],[-82.073288,35.277827],[-82.151535,35.407581],[-82.257304,35.435392],[-82.276925,35.448402],[-82.305069,35.33153],[-82.305534,35.326836]]]},"properties":{"id":"28756","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.997424,34.729334],[-79.151859,34.576736],[-79.024716,34.534697],[-78.947283,34.449382],[-78.87153,34.482668],[-78.805221,34.674598],[-78.830345,34.713967],[-78.997424,34.729334]]]},"properties":{"id":"28358","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.179953,34.641751],[-79.156631,34.58135],[-79.151859,34.576736],[-78.997424,34.729334],[-79.041712,34.756598],[-79.085277,34.746291],[-79.142539,34.725036],[-79.179953,34.641751]]]},"properties":{"id":"28360","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.57346,34.721521],[-79.461754,34.630432],[-79.351821,34.831226],[-79.459694,34.891302],[-79.471579,34.899026],[-79.536299,34.766115],[-79.57346,34.721521]]]},"properties":{"id":"28352","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.125682,35.415852],[-80.342275,35.388018],[-80.339941,35.334745],[-80.319072,35.306889],[-80.265779,35.236183],[-80.093966,35.287087],[-80.080208,35.308638],[-80.083904,35.395094],[-80.125682,35.415852]]]},"properties":{"id":"28001","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.488189,36.485996],[-80.37203,36.436196],[-80.359625,36.378936],[-80.318816,36.393305],[-80.253323,36.412528],[-80.283329,36.483342],[-80.295081,36.543977],[-80.382614,36.547877],[-80.488189,36.485996]]]},"properties":{"id":"27053","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.642591,36.371259],[-80.605798,36.335045],[-80.563662,36.363555],[-80.570829,36.428285],[-80.642591,36.371259]]]},"properties":{"id":"27007","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.671459,35.187636],[-82.643131,35.130569],[-82.574907,35.145487],[-82.590677,35.162061],[-82.620358,35.201118],[-82.671459,35.187636]]]},"properties":{"id":"28718","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.380919,35.790939],[-76.194362,35.71979],[-76.168538,35.69871],[-76.097727,35.670003],[-76.045987,35.666117],[-75.98108,35.957406],[-76.358148,35.952595],[-76.366304,35.936618],[-76.380919,35.790939]]]},"properties":{"id":"27925","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.599683,35.164005],[-80.670138,35.019136],[-80.533356,35.189956],[-80.555394,35.204306],[-80.599683,35.164005]]]},"properties":{"id":"28079","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.226527,36.291885],[-78.184564,36.544193],[-78.270487,36.544264],[-78.298023,36.412867],[-78.322198,36.392186],[-78.226527,36.291885]]]},"properties":{"id":"27563","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.76376,35.792338],[-78.828437,35.760051],[-78.821359,35.74359],[-78.813629,35.726217],[-78.745078,35.762154],[-78.747061,35.789359],[-78.76376,35.792338]]]},"properties":{"id":"27511","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.569185,35.897076],[-78.597411,35.859885],[-78.603768,35.84982],[-78.49366,35.830086],[-78.480886,35.851078],[-78.47202,35.871566],[-78.557737,35.909878],[-78.569185,35.897076]]]},"properties":{"id":"27616","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.011209,36.150762],[-81.099727,36.104763],[-81.040455,36.060607],[-80.896578,36.083312],[-80.903074,36.12484],[-80.957617,36.133928],[-81.011209,36.150762]]],[[[-81.1893,36.269249],[-81.225811,36.167208],[-81.025511,36.198534],[-81.061132,36.269908],[-81.162056,36.269647],[-81.1893,36.269249]]]]},"properties":{"id":"28659","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.33267,36.370615],[-81.351782,36.357481],[-81.361682,36.313399],[-81.378809,36.277983],[-81.269677,36.160024],[-81.225811,36.167208],[-81.1893,36.269249],[-81.25258,36.349671],[-81.33267,36.370615]]]},"properties":{"id":"28651","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.722703,36.2696],[-80.873259,36.236383],[-80.875939,36.180811],[-80.791221,36.196779],[-80.722703,36.2696]]]},"properties":{"id":"28642","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.534687,36.087604],[-79.483886,36.107197],[-79.391114,36.281496],[-79.415612,36.315292],[-79.519059,36.278488],[-79.534687,36.087604]]]},"properties":{"id":"27244","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.391114,36.281496],[-79.483886,36.107197],[-79.413041,36.073641],[-79.385568,36.088704],[-79.348949,36.102974],[-79.296044,36.342421],[-79.391114,36.281496]]]},"properties":{"id":"27217","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.348949,36.102974],[-79.28004,35.975389],[-79.264152,35.930242],[-79.22612,35.961135],[-79.231281,36.170898],[-79.223779,36.242915],[-79.206037,36.350392],[-79.243123,36.328717],[-79.296044,36.342421],[-79.348949,36.102974]]]},"properties":{"id":"27302","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.17864,35.149886],[-80.261714,34.907798],[-80.172865,34.820119],[-79.967346,34.903986],[-79.986559,35.088769],[-80.07539,35.143081],[-80.17864,35.149886]],[[-80.106044,35.115592],[-80.097993,35.095994],[-80.113984,35.094586],[-80.120737,35.111702],[-80.106044,35.115592]]]},"properties":{"id":"28170","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.285943,35.11802],[-80.32041,34.814008],[-80.174685,34.811761],[-80.172865,34.820119],[-80.261714,34.907798],[-80.285943,35.11802]]]},"properties":{"id":"28133","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.897002,36.261763],[-81.927607,36.130996],[-81.879415,36.113865],[-81.81152,36.111544],[-81.773067,36.123281],[-81.74752,36.183411],[-81.786241,36.21684],[-81.897002,36.261763]]]},"properties":{"id":"28604","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.818172,36.072462],[-76.678535,36.027831],[-76.736418,36.147821],[-76.818172,36.072462]]]},"properties":{"id":"27957","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.554086,33.941224],[-78.644214,33.950248],[-78.650324,33.944242],[-78.558817,33.866456],[-78.554086,33.941224]]]},"properties":{"id":"28467","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.380211,33.903942],[-78.235858,33.914454],[-78.223612,33.92441],[-78.19924,34.107741],[-78.197574,34.107775],[-78.225097,34.13602],[-78.368494,34.013038],[-78.380211,33.903942]]]},"properties":{"id":"28462","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.236882,34.32683],[-78.336373,34.279583],[-78.198978,34.300725],[-78.195202,34.30653],[-78.236882,34.32683]]]},"properties":{"id":"28436","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.425903,35.678405],[-82.450145,35.557099],[-82.346318,35.571675],[-82.383715,35.687219],[-82.398741,35.698187],[-82.425903,35.678405]]]},"properties":{"id":"28778","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.544475,35.583844],[-82.452163,35.556435],[-82.450145,35.557099],[-82.425903,35.678405],[-82.47814,35.664289],[-82.540988,35.599929],[-82.544475,35.583844]]]},"properties":{"id":"28805","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.527702,35.741551],[-81.51486,35.755472],[-81.5346,35.755677],[-81.532893,35.749153],[-81.527702,35.741551]]]},"properties":{"id":"28671","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.614289,35.539909],[-80.634787,35.539897],[-80.675629,35.545702],[-80.737069,35.507163],[-80.713677,35.494634],[-80.617628,35.456846],[-80.62046,35.465305],[-80.614289,35.539909]]]},"properties":{"id":"28081","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.693698,35.346848],[-80.615988,35.338709],[-80.617628,35.456846],[-80.713677,35.494634],[-80.755106,35.450073],[-80.74561,35.405342],[-80.740448,35.377745],[-80.693698,35.346848]]]},"properties":{"id":"28027","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.828183,35.471088],[-80.956609,35.42751],[-80.981695,35.381557],[-80.939077,35.353648],[-80.843057,35.367824],[-80.74561,35.405342],[-80.755106,35.450073],[-80.828183,35.471088]]]},"properties":{"id":"28078","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.148004,36.394685],[-76.068178,36.276362],[-76.045965,36.358339],[-76.026946,36.389453],[-76.042895,36.390655],[-76.148004,36.394685]]]},"properties":{"id":"27973","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.483862,34.839347],[-76.417192,34.79876],[-76.385784,34.830063],[-76.400601,34.891679],[-76.50003,34.836686],[-76.483862,34.839347]]]},"properties":{"id":"28581","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.365963,36.488079],[-79.232092,36.405058],[-79.182488,36.449701],[-79.217587,36.480356],[-79.326224,36.525565],[-79.365963,36.488079]]]},"properties":{"id":"27212","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.272256,35.705849],[-81.161086,35.660486],[-81.1882,35.822311],[-81.2281,35.810388],[-81.266864,35.723573],[-81.272256,35.705849]]]},"properties":{"id":"28613","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.381409,35.614218],[-81.323121,35.584214],[-81.285519,35.594264],[-81.111726,35.604881],[-81.120541,35.637192],[-81.161086,35.660486],[-81.272256,35.705849],[-81.381409,35.614218]]]},"properties":{"id":"28658","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.1882,35.822311],[-81.161086,35.660486],[-81.120541,35.637192],[-81.106174,35.773299],[-81.142327,35.827167],[-81.1882,35.822311]]]},"properties":{"id":"28610","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.53436,35.822366],[-79.546793,35.731532],[-79.576255,35.641486],[-79.529398,35.61874],[-79.291035,35.647508],[-79.375634,35.829913],[-79.432778,35.843749],[-79.53436,35.822366]]]},"properties":{"id":"27344","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.642953,35.802102],[-79.594834,35.780309],[-79.546793,35.731532],[-79.53436,35.822366],[-79.642159,35.813025],[-79.642953,35.802102]]]},"properties":{"id":"27355","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.581717,35.525678],[-79.470805,35.516493],[-79.529398,35.61874],[-79.576255,35.641486],[-79.606542,35.591917],[-79.581717,35.525678]]]},"properties":{"id":"27208","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.02911,35.292183],[-84.005403,34.987218],[-83.926848,35.066997],[-83.862776,35.132496],[-83.96711,35.217962],[-84.02911,35.292183]]]},"properties":{"id":"28906","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.738106,35.155911],[-83.773499,35.245759],[-83.893569,35.233282],[-83.856271,35.137537],[-83.738106,35.155911]]]},"properties":{"id":"28901","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.693163,36.236642],[-76.581118,36.171615],[-76.549495,36.268097],[-76.585113,36.301985],[-76.670255,36.314731],[-76.683915,36.300773],[-76.693163,36.236642]]]},"properties":{"id":"27980","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.549495,36.268097],[-76.418737,36.324583],[-76.45369,36.378042],[-76.476473,36.37257],[-76.585113,36.301985],[-76.549495,36.268097]]]},"properties":{"id":"27919","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.858323,34.987349],[-83.482779,34.993256],[-83.537904,35.035277],[-83.651922,35.135538],[-83.738106,35.155911],[-83.856271,35.137537],[-83.862776,35.132496],[-83.926848,35.066997],[-83.898335,35.031543],[-83.858323,34.987349]]]},"properties":{"id":"28904","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.666378,35.247053],[-81.67443,35.249369],[-81.673615,35.244278],[-81.666378,35.247053]]]},"properties":{"id":"28017","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.47401,35.235294],[-81.406559,35.166367],[-81.293751,35.162144],[-81.270414,35.252064],[-81.350114,35.348603],[-81.409567,35.338092],[-81.456342,35.274452],[-81.47401,35.235294]]]},"properties":{"id":"28086","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.57602,34.385833],[-78.677141,34.301534],[-78.552969,34.234206],[-78.57602,34.385833]]]},"properties":{"id":"28442","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.988196,34.229645],[-78.650324,33.944242],[-78.644214,33.950248],[-78.616963,34.023213],[-78.714045,34.084947],[-78.786279,34.17729],[-78.877724,34.236164],[-78.881612,34.243765],[-78.969481,34.241587],[-78.988196,34.229645]]]},"properties":{"id":"28463","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.89803,35.070522],[-78.914383,35.040997],[-78.897492,35.040864],[-78.892224,35.042815],[-78.89803,35.070522]]]},"properties":{"id":"28305","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.034488,35.178729],[-78.996171,35.174043],[-78.944666,35.118693],[-78.867499,35.245065],[-78.901695,35.272613],[-78.943218,35.287169],[-79.009431,35.274342],[-79.0043,35.266574],[-79.046165,35.20361],[-79.034488,35.178729]]]},"properties":{"id":"28390","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.65763,35.199265],[-78.660581,35.183879],[-78.639975,35.186176],[-78.639624,35.199293],[-78.65763,35.199265]]]},"properties":{"id":"28342","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.187569,36.449748],[-76.148004,36.394685],[-76.042895,36.390655],[-76.038768,36.415891],[-75.98101,36.462342],[-76.151514,36.550552],[-76.313225,36.550574],[-76.187569,36.449748]]]},"properties":{"id":"27958","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.954416,36.340923],[-75.939705,36.320858],[-75.912183,36.347311],[-75.89129,36.304419],[-75.858635,36.306031],[-75.917514,36.46677],[-75.96587,36.45067],[-75.95334,36.407802],[-75.954416,36.340923]]]},"properties":{"id":"27923","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.802799,36.100239],[-75.821023,36.119305],[-75.840605,36.097425],[-75.802799,36.100239]]]},"properties":{"id":"27941","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.548314,35.771907],[-75.473766,35.606132],[-75.467732,35.569506],[-75.460698,35.572884],[-75.527574,35.780686],[-75.548314,35.771907]]]},"properties":{"id":"27968","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.531116,35.274879],[-75.517643,35.267833],[-75.466843,35.535808],[-75.479342,35.536957],[-75.531116,35.274879]]]},"properties":{"id":"27915","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.182682,35.504335],[-80.065626,35.505691],[-79.973526,35.5536],[-79.977088,35.712134],[-80.057812,35.718678],[-80.237128,35.607142],[-80.182682,35.504335]]]},"properties":{"id":"27239","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.050099,35.751029],[-80.196983,35.853667],[-80.326653,35.771596],[-80.332228,35.719047],[-80.24122,35.612455],[-80.237128,35.607142],[-80.057812,35.718678],[-80.050099,35.751029]]]},"properties":{"id":"27292","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.196983,35.853667],[-80.050099,35.751029],[-80.018585,35.880277],[-80.047206,35.911849],[-80.046709,35.920663],[-80.103855,35.942536],[-80.132061,35.9509],[-80.179545,35.894681],[-80.196983,35.853667]]]},"properties":{"id":"27360","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.024855,36.053837],[-80.119492,36.004722],[-80.132061,35.9509],[-80.103855,35.942536],[-79.980181,35.980436],[-79.955223,35.997989],[-79.943148,36.044194],[-79.970743,36.083793],[-80.024855,36.053837]]]},"properties":{"id":"27265","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.939633,34.802836],[-78.097225,34.767608],[-78.008221,34.740238],[-77.939633,34.802836]]]},"properties":{"id":"28464","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.968397,35.230947],[-77.867365,35.137053],[-77.767168,35.143915],[-77.742952,35.181592],[-77.766784,35.224608],[-77.880923,35.248598],[-77.939418,35.268044],[-77.968397,35.230947]]]},"properties":{"id":"28578","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.15087,35.044366],[-78.222272,35.045411],[-78.213976,34.890076],[-78.147081,34.902448],[-78.15087,35.044366]]]},"properties":{"id":"28393","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.036123,36.038461],[-79.02735,35.986298],[-78.932939,35.992372],[-78.905338,36.020584],[-78.914884,36.06866],[-78.97383,36.103738],[-79.036123,36.038461]]]},"properties":{"id":"27705","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.886475,35.968475],[-78.932939,35.992372],[-79.02735,35.986298],[-79.001737,35.949549],[-78.967761,35.903982],[-78.878661,35.957397],[-78.886475,35.968475]]]},"properties":{"id":"27707","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.96329,36.164685],[-78.937266,36.137786],[-78.811485,36.095033],[-78.804983,36.091883],[-78.803242,36.179065],[-78.96329,36.164685]]]},"properties":{"id":"27503","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.662073,35.865129],[-77.743037,35.83743],[-77.70938,35.801231],[-77.558096,35.791932],[-77.662073,35.865129]]]},"properties":{"id":"27864","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.277767,36.271873],[-80.342941,36.260656],[-80.352731,36.216278],[-80.314736,36.19929],[-80.309088,36.202514],[-80.241346,36.224615],[-80.277767,36.271873]]]},"properties":{"id":"27045","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.281904,36.370728],[-80.182888,36.2193],[-80.123239,36.215371],[-80.039815,36.282926],[-80.033531,36.309636],[-80.033338,36.319954],[-80.068456,36.369378],[-80.140891,36.403313],[-80.253323,36.412528],[-80.318816,36.393305],[-80.281904,36.370728]]]},"properties":{"id":"27052","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.439006,36.138746],[-80.508245,36.103412],[-80.496403,36.046536],[-80.486967,36.043105],[-80.46381,36.062545],[-80.365246,36.073596],[-80.373359,36.096434],[-80.386118,36.109364],[-80.439006,36.138746]]]},"properties":{"id":"27023","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.981695,35.381557],[-80.956609,35.42751],[-80.957638,35.435286],[-81.033612,35.454194],[-81.061011,35.415283],[-81.107532,35.389637],[-81.127569,35.320236],[-81.1074,35.329323],[-80.981695,35.381557]]]},"properties":{"id":"28164","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.203493,35.397309],[-81.253843,35.365535],[-81.240012,35.292107],[-81.181854,35.292111],[-81.120598,35.308074],[-81.127569,35.320236],[-81.107532,35.389637],[-81.129735,35.407769],[-81.178112,35.409481],[-81.199174,35.41029],[-81.203493,35.397309]]]},"properties":{"id":"28034","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.077594,35.264211],[-81.092238,35.254787],[-81.073096,35.249004],[-81.068731,35.264115],[-81.077594,35.264211]]]},"properties":{"id":"28101","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.585544,35.434867],[-83.606885,35.36459],[-83.582346,35.434102],[-83.585544,35.434867]]]},"properties":{"id":"28702","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.672454,36.170213],[-78.802851,36.208343],[-78.803242,36.179065],[-78.728702,36.150212],[-78.672454,36.170213]]]},"properties":{"id":"27581","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.593613,36.47267],[-78.580765,36.449819],[-78.558278,36.467942],[-78.593613,36.47267]]]},"properties":{"id":"27582","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.546651,36.079197],[-79.62152,36.059043],[-79.662085,36.021257],[-79.652421,35.996655],[-79.585332,35.997723],[-79.546651,36.079197]]]},"properties":{"id":"27377","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.944307,36.157278],[-79.83881,36.17284],[-79.783941,36.248465],[-79.884182,36.310221],[-79.906041,36.278982],[-79.959359,36.212205],[-79.953042,36.172826],[-79.944307,36.157278]]]},"properties":{"id":"27358","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.944307,36.157278],[-79.876554,36.068489],[-79.847772,36.072665],[-79.835179,36.084491],[-79.843382,36.125395],[-79.83881,36.17284],[-79.944307,36.157278]]]},"properties":{"id":"27410","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.09613,35.192181],[-79.046165,35.20361],[-79.0043,35.266574],[-79.28044,35.381491],[-79.286686,35.378898],[-79.271539,35.284689],[-79.09613,35.192181]]]},"properties":{"id":"28326","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.59811,35.386002],[-78.432033,35.285857],[-78.363474,35.338456],[-78.534401,35.535313],[-78.558354,35.551963],[-78.559837,35.551974],[-78.589414,35.52807],[-78.618966,35.444654],[-78.59811,35.386002]]]},"properties":{"id":"27504","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.647037,35.328709],[-82.622882,35.307297],[-82.605253,35.344917],[-82.647037,35.328709]]],[[[-82.603613,35.34575],[-82.575707,35.324221],[-82.553317,35.343009],[-82.552223,35.350123],[-82.603613,35.34575]]],[[[-82.601002,35.416114],[-82.656584,35.431663],[-82.66663,35.347454],[-82.654762,35.336021],[-82.601002,35.416114]]]]},"properties":{"id":"28742","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.159483,36.365771],[-77.304545,36.368656],[-77.306561,36.342337],[-77.206736,36.251085],[-77.132074,36.328512],[-77.159483,36.365771]]]},"properties":{"id":"27897","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.356091,35.583728],[-78.460036,35.705691],[-78.522983,35.68073],[-78.558354,35.551963],[-78.534401,35.535313],[-78.502453,35.528486],[-78.356091,35.583728]]]},"properties":{"id":"27520","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.198324,35.565742],[-78.204771,35.565859],[-78.207858,35.560978],[-78.197978,35.559449],[-78.198324,35.565742]]]},"properties":{"id":"27555","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.584817,35.366707],[-77.567487,35.061168],[-77.567463,35.051649],[-77.475215,35.150523],[-77.411563,35.337997],[-77.540235,35.376075],[-77.584817,35.366707]]]},"properties":{"id":"28501","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.766784,35.224608],[-77.742952,35.181592],[-77.599574,35.076243],[-77.567487,35.061168],[-77.584817,35.366707],[-77.611062,35.378802],[-77.660811,35.352757],[-77.766784,35.224608]]]},"properties":{"id":"28504","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.736366,35.159765],[-80.729405,35.136742],[-80.769836,35.059872],[-80.767587,35.036357],[-80.639991,35.140736],[-80.725343,35.168786],[-80.736366,35.159765]]]},"properties":{"id":"28105","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.85463,35.224383],[-80.844591,35.213782],[-80.82816,35.225669],[-80.850497,35.241046],[-80.855789,35.235923],[-80.85463,35.224383]],[[-80.844204,35.22471],[-80.845254,35.223974],[-80.846379,35.22492],[-80.845304,35.225653],[-80.844204,35.22471]],[[-80.844204,35.22471],[-80.843097,35.225469],[-80.84199,35.224511],[-80.843106,35.22373],[-80.844204,35.22471]],[[-80.843097,35.225469],[-80.844207,35.226414],[-80.843083,35.2272],[-80.841973,35.226243],[-80.843097,35.225469]]]},"properties":{"id":"28202","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.769836,35.059872],[-80.729405,35.136742],[-80.745542,35.148232],[-80.79497,35.154443],[-80.778741,35.100013],[-80.769836,35.059872]]]},"properties":{"id":"28270","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.896875,35.276785],[-80.953545,35.350655],[-81.013791,35.257687],[-81.006244,35.210362],[-80.961266,35.223806],[-80.896875,35.276785]]]},"properties":{"id":"28214","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.748699,35.224333],[-80.776402,35.182152],[-80.743247,35.148923],[-80.736366,35.159765],[-80.725343,35.168786],[-80.724913,35.223178],[-80.748699,35.224333]]]},"properties":{"id":"28212","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.010693,36.19621],[-78.064342,36.022576],[-77.990106,35.869797],[-77.911542,35.885446],[-77.908498,35.94794],[-77.906314,36.038411],[-77.893748,36.084212],[-77.964609,36.191976],[-78.006554,36.202663],[-78.010693,36.19621]]]},"properties":{"id":"27856","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.888883,34.198002],[-77.899696,34.055741],[-77.872892,34.078886],[-77.811122,34.185148],[-77.817037,34.213432],[-77.888883,34.198002]]]},"properties":{"id":"28409","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.815706,36.545149],[-77.922287,36.544601],[-77.818913,36.500806],[-77.809247,36.498562],[-77.815706,36.545149]]]},"properties":{"id":"27842","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.198603,36.545282],[-77.302159,36.471463],[-77.265015,36.46476],[-77.166789,36.455772],[-77.093418,36.472639],[-77.1645,36.54633],[-77.198603,36.545282]]]},"properties":{"id":"27862","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.767554,35.139187],[-76.804217,35.1033],[-76.551944,35.069857],[-76.491985,35.10178],[-76.528587,35.16618],[-76.579041,35.1871],[-76.657077,35.170446],[-76.663347,35.168162],[-76.720602,35.142986],[-76.744804,35.122704],[-76.767554,35.139187]]]},"properties":{"id":"28556","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.057812,35.718678],[-79.977088,35.712134],[-79.9435,35.790715],[-79.904934,35.855321],[-80.018585,35.880277],[-80.050099,35.751029],[-80.057812,35.718678]]]},"properties":{"id":"27370","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.459176,35.043096],[-79.473295,35.050523],[-79.478522,35.057103],[-79.519617,35.020631],[-79.459176,35.043096]]],[[[-79.659826,34.958436],[-79.561826,34.910363],[-79.471579,34.899026],[-79.459694,34.891302],[-79.462539,34.969483],[-79.542922,35.009387],[-79.639912,35.011269],[-79.659826,34.958436]]]]},"properties":{"id":"28363","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.605369,36.541468],[-79.865601,36.542596],[-79.795295,36.42808],[-79.788096,36.427005],[-79.647123,36.448861],[-79.605369,36.541468]]]},"properties":{"id":"27288","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.795295,36.42808],[-79.865601,36.542596],[-80.027269,36.542495],[-80.028713,36.507058],[-79.937675,36.407779],[-79.795295,36.42808]]]},"properties":{"id":"27048","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.634787,35.539897],[-80.609732,35.558269],[-80.603764,35.53905],[-80.545509,35.515763],[-80.509876,35.545207],[-80.545414,35.587903],[-80.652894,35.636605],[-80.672271,35.610652],[-80.675629,35.545702],[-80.634787,35.539897]]]},"properties":{"id":"28023","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.487854,35.766276],[-80.481687,35.642904],[-80.390884,35.720523],[-80.458904,35.742811],[-80.487854,35.766276]],[[-80.435267,35.680072],[-80.43644,35.677115],[-80.439304,35.680679],[-80.435028,35.682897],[-80.435267,35.680072]],[[-80.45091,35.688408],[-80.445327,35.699511],[-80.418943,35.706039],[-80.425955,35.689301],[-80.45091,35.688408]]]},"properties":{"id":"28144","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.438181,35.604641],[-80.431306,35.614042],[-80.450629,35.61649],[-80.438181,35.604641]]]},"properties":{"id":"28072","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.810039,35.2598],[-81.785587,35.27372],[-81.793161,35.278267],[-81.796349,35.271986],[-81.810039,35.2598]]]},"properties":{"id":"28076","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.921547,35.383908],[-81.943606,35.354842],[-81.921539,35.335746],[-81.921547,35.383908]]]},"properties":{"id":"28160","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.169018,35.527841],[-82.240101,35.472133],[-82.257304,35.435392],[-82.151535,35.407581],[-82.092175,35.494055],[-82.12789,35.523736],[-82.155053,35.520351],[-82.169018,35.527841]]]},"properties":{"id":"28746","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.477254,35.097867],[-78.452574,34.986566],[-78.430715,34.892434],[-78.313637,34.805101],[-78.241134,34.841035],[-78.213976,34.890076],[-78.222272,35.045411],[-78.289282,35.15881],[-78.451696,35.14299],[-78.477254,35.097867]]]},"properties":{"id":"28328","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.459694,34.891302],[-79.351821,34.831226],[-79.349143,34.838476],[-79.348866,34.900259],[-79.428411,35.016075],[-79.442691,35.022986],[-79.462539,34.969483],[-79.459694,34.891302]]]},"properties":{"id":"28396","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.468219,35.24087],[-80.503541,35.181783],[-80.396255,35.1656],[-80.370976,35.179907],[-80.378289,35.260716],[-80.468219,35.24087]]]},"properties":{"id":"28163","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.552679,36.345346],[-80.37203,36.436196],[-80.488189,36.485996],[-80.570829,36.428285],[-80.563662,36.363555],[-80.552679,36.345346]]]},"properties":{"id":"27041","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.984004,36.376807],[-80.961033,36.346018],[-80.919228,36.337408],[-80.840638,36.375302],[-80.840647,36.396974],[-80.949396,36.421828],[-80.984004,36.376807]]]},"properties":{"id":"28683","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.927059,36.501106],[-80.846734,36.475902],[-80.724383,36.562319],[-80.901732,36.561835],[-80.927059,36.501106]]]},"properties":{"id":"27024","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.838673,35.261261],[-82.790428,35.373245],[-82.920819,35.291868],[-82.962019,35.202934],[-82.856858,35.20144],[-82.855905,35.201563],[-82.838673,35.261261]]]},"properties":{"id":"28708","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.840297,35.00171],[-80.626077,34.818208],[-80.668107,34.934066],[-80.679354,34.934261],[-80.690556,34.930763],[-80.692855,34.93354],[-80.668859,34.938215],[-80.643937,34.950668],[-80.682071,34.99437],[-80.779816,35.032728],[-80.840297,35.00171]]]},"properties":{"id":"28173","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.302782,36.425704],[-78.322198,36.392186],[-78.298023,36.412867],[-78.302782,36.425704]]]},"properties":{"id":"27556","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.67911,35.875901],[-78.651074,35.870772],[-78.597411,35.859885],[-78.569185,35.897076],[-78.68631,35.94224],[-78.67911,35.875901]]]},"properties":{"id":"27615","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.84821,35.811649],[-78.844027,35.762838],[-78.828437,35.760051],[-78.76376,35.792338],[-78.777577,35.845369],[-78.84821,35.811649]]]},"properties":{"id":"27513","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.515365,35.812851],[-78.444637,35.737809],[-78.480886,35.851078],[-78.49366,35.830086],[-78.515365,35.812851]]]},"properties":{"id":"27545","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.773067,36.123281],[-81.639354,36.137299],[-81.545603,36.16353],[-81.566583,36.272414],[-81.709512,36.320676],[-81.746019,36.271857],[-81.74752,36.183411],[-81.773067,36.123281]]]},"properties":{"id":"28607","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.316304,36.156202],[-81.447151,36.189477],[-81.510785,36.136712],[-81.392045,36.048187],[-81.308413,36.111466],[-81.316304,36.156202]]]},"properties":{"id":"28624","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.873259,36.236383],[-80.941822,36.262281],[-80.957617,36.133928],[-80.903074,36.12484],[-80.875939,36.180811],[-80.873259,36.236383]]]},"properties":{"id":"28670","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.778596,36.153758],[-80.607056,36.208014],[-80.562185,36.282455],[-80.631328,36.285822],[-80.722703,36.2696],[-80.791221,36.196779],[-80.778596,36.153758]]]},"properties":{"id":"27011","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.00532,36.000697],[-80.927961,36.020288],[-80.858863,36.010416],[-80.806852,36.030508],[-80.896578,36.083312],[-81.040455,36.060607],[-81.057461,36.031429],[-81.075417,36.016154],[-81.00532,36.000697]]]},"properties":{"id":"28689","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.431135,35.055672],[-80.4037,34.885884],[-80.413659,34.815413],[-80.32041,34.814008],[-80.285943,35.11802],[-80.277052,35.196136],[-80.370976,35.179907],[-80.396255,35.1656],[-80.431135,35.055672]]]},"properties":{"id":"28103","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.106044,35.115592],[-80.120737,35.111702],[-80.113984,35.094586],[-80.097993,35.095994],[-80.106044,35.115592]]]},"properties":{"id":"28007","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.48391,36.458565],[-81.380529,36.345205],[-81.351782,36.357481],[-81.33267,36.370615],[-81.361922,36.435038],[-81.436317,36.460759],[-81.48391,36.458565]]]},"properties":{"id":"28640","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.927607,36.130996],[-82.016445,36.145078],[-82.080443,36.10302],[-82.059126,35.988378],[-81.984721,35.951307],[-81.95752,35.946677],[-81.912772,35.900058],[-81.807157,35.961533],[-81.793122,36.032573],[-81.879415,36.113865],[-81.927607,36.130996]],[[-81.990328,36.097911],[-81.991954,36.098935],[-81.98959,36.102456],[-81.986946,36.09976],[-81.990328,36.097911]],[[-81.907131,36.010453],[-81.937578,36.022393],[-81.91314,36.033231],[-81.884934,36.024813],[-81.907131,36.010453]]]},"properties":{"id":"28657","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.927607,36.130996],[-81.897002,36.261763],[-81.920755,36.283162],[-82.016445,36.145078],[-81.927607,36.130996]]]},"properties":{"id":"28622","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.054756,35.349918],[-77.189582,35.429295],[-77.243681,35.407779],[-77.260977,35.351731],[-77.30219,35.312524],[-77.285097,35.291799],[-77.19574,35.235655],[-77.144259,35.217247],[-77.100509,35.192009],[-77.054756,35.349918]]]},"properties":{"id":"28586","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.282527,35.575497],[-77.277023,35.430043],[-77.243681,35.407779],[-77.189582,35.429295],[-77.100801,35.551168],[-77.263114,35.597819],[-77.273453,35.579069],[-77.276022,35.568521],[-77.282527,35.575497]]]},"properties":{"id":"27837","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.131936,36.130665],[-76.964162,36.120821],[-77.132074,36.328512],[-77.206736,36.251085],[-77.20879,36.246875],[-77.208289,36.246774],[-77.131936,36.130665]]]},"properties":{"id":"27805","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.208289,36.246774],[-77.29223,36.168674],[-77.29181,36.16849],[-77.251855,36.12199],[-77.143955,36.132158],[-77.131936,36.130665],[-77.208289,36.246774]]]},"properties":{"id":"27847","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.464067,34.678091],[-78.284381,34.758861],[-78.313637,34.805101],[-78.430715,34.892434],[-78.557322,34.851215],[-78.5986,34.81382],[-78.464067,34.678091]]]},"properties":{"id":"28441","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.921005,34.359721],[-78.856247,34.416192],[-78.848246,34.435641],[-78.87153,34.482668],[-78.947283,34.449382],[-79.034463,34.322547],[-78.979848,34.355152],[-78.921005,34.359721]]]},"properties":{"id":"28438","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.153302,33.927301],[-78.016695,33.883883],[-77.937053,33.928089],[-77.948309,33.969873],[-78.064937,34.068867],[-78.153302,33.927301]]]},"properties":{"id":"28461","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.383715,35.687219],[-82.346318,35.571675],[-82.288283,35.47861],[-82.266966,35.465619],[-82.240101,35.472133],[-82.169018,35.527841],[-82.275394,35.704423],[-82.335152,35.749462],[-82.383715,35.687219]],[[-82.297546,35.652835],[-82.318644,35.639217],[-82.313606,35.657549],[-82.297546,35.652835]]]},"properties":{"id":"28711","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.460147,35.512261],[-82.380363,35.463413],[-82.364639,35.460883],[-82.339528,35.493715],[-82.288283,35.47861],[-82.346318,35.571675],[-82.450145,35.557099],[-82.452163,35.556435],[-82.460147,35.512261]]]},"properties":{"id":"28730","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.627385,35.755002],[-82.677475,35.709002],[-82.647005,35.646138],[-82.626781,35.642891],[-82.614727,35.694714],[-82.627385,35.755002]]]},"properties":{"id":"28701","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.594904,35.751351],[-81.593397,35.771197],[-81.61034,35.765611],[-81.601945,35.748623],[-81.594904,35.751351]]]},"properties":{"id":"28619","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.272256,35.705849],[-81.266864,35.723573],[-81.402475,35.724135],[-81.443242,35.711324],[-81.464324,35.718885],[-81.46819,35.720431],[-81.485542,35.625443],[-81.381409,35.614218],[-81.272256,35.705849]]]},"properties":{"id":"28602","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.464324,35.718885],[-81.462328,35.722768],[-81.473177,35.726411],[-81.476242,35.722996],[-81.46819,35.720431],[-81.464324,35.718885]]]},"properties":{"id":"28666","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.468219,35.24087],[-80.437047,35.297062],[-80.44998,35.308179],[-80.599802,35.234052],[-80.555394,35.204306],[-80.533356,35.189956],[-80.503541,35.181783],[-80.468219,35.24087]]]},"properties":{"id":"28107","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.809067,35.276659],[-80.740448,35.377745],[-80.74561,35.405342],[-80.843057,35.367824],[-80.846742,35.272566],[-80.809067,35.276659]]]},"properties":{"id":"28269","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.874382,35.512469],[-80.828183,35.471088],[-80.755106,35.450073],[-80.713677,35.494634],[-80.737069,35.507163],[-80.855793,35.511064],[-80.874382,35.512469]]]},"properties":{"id":"28036","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.308413,36.111466],[-81.392045,36.048187],[-81.328385,35.99655],[-81.260274,36.025893],[-81.238413,36.096687],[-81.308413,36.111466]]]},"properties":{"id":"28606","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.867243,34.722599],[-76.866675,34.688369],[-76.681779,34.703645],[-76.692721,34.715555],[-76.845789,34.723406],[-76.867243,34.722599]]]},"properties":{"id":"28512","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.106174,35.773299],[-81.120541,35.637192],[-81.111726,35.604881],[-81.075455,35.603403],[-80.960653,35.660041],[-81.074713,35.743088],[-81.08929,35.755856],[-81.106174,35.773299]]]},"properties":{"id":"28609","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.046133,35.577212],[-81.101424,35.524552],[-81.105296,35.498406],[-81.033612,35.454194],[-80.957638,35.435286],[-80.94703,35.487699],[-80.960029,35.54781],[-81.046133,35.577212]]]},"properties":{"id":"28037","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.430107,35.516998],[-79.252528,35.56562],[-79.28249,35.624328],[-79.430107,35.516998]]]},"properties":{"id":"27252","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.264152,35.930242],[-79.236816,35.848771],[-79.084588,35.815955],[-79.057435,35.893462],[-79.075555,36.017264],[-79.205148,35.973563],[-79.22612,35.961135],[-79.264152,35.930242]],[[-79.068054,35.916656],[-79.080923,35.889968],[-79.099447,35.919801],[-79.068054,35.916656]]]},"properties":{"id":"27516","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.967761,35.903982],[-79.001737,35.949549],[-79.057435,35.893462],[-79.084588,35.815955],[-79.052398,35.757447],[-78.963619,35.83003],[-78.967761,35.903982]]]},"properties":{"id":"27517","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.890495,35.867842],[-78.929614,35.828816],[-78.844027,35.762838],[-78.84821,35.811649],[-78.890495,35.867842]]]},"properties":{"id":"27519","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.476473,36.37257],[-76.642869,36.394605],[-76.670255,36.314731],[-76.585113,36.301985],[-76.476473,36.37257]]]},"properties":{"id":"27946","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.80591,35.315067],[-81.798111,35.27933],[-81.793161,35.278267],[-81.785587,35.27372],[-81.703608,35.335471],[-81.676354,35.430974],[-81.696155,35.484214],[-81.719527,35.511551],[-81.80591,35.315067]],[[-81.794196,35.280997],[-81.796456,35.285868],[-81.786099,35.28353],[-81.792736,35.282799],[-81.794196,35.280997]]]},"properties":{"id":"28040","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.670881,35.316931],[-81.456342,35.274452],[-81.409567,35.338092],[-81.421956,35.356757],[-81.432858,35.351396],[-81.474568,35.389163],[-81.676354,35.430974],[-81.703608,35.335471],[-81.688789,35.309713],[-81.67096,35.318341],[-81.67057,35.319704],[-81.658317,35.318183],[-81.657805,35.323221],[-81.650796,35.317722],[-81.656834,35.318004],[-81.670881,35.316931]]]},"properties":{"id":"28150","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.798111,35.27933],[-81.796349,35.271986],[-81.793161,35.278267],[-81.798111,35.27933]]],[[[-81.875338,35.183942],[-81.60955,35.17432],[-81.688789,35.309713],[-81.703608,35.335471],[-81.785587,35.27372],[-81.810039,35.2598],[-81.871526,35.210015],[-81.875338,35.183942]]]]},"properties":{"id":"28114","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.877724,34.236164],[-78.796343,34.203474],[-78.856247,34.416192],[-78.921005,34.359721],[-78.881612,34.243765],[-78.877724,34.236164]]]},"properties":{"id":"28431","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.696682,34.292162],[-78.69879,34.295052],[-78.703698,34.290442],[-78.699361,34.289076],[-78.696682,34.292162]]]},"properties":{"id":"28424","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.144259,35.217247],[-76.948484,34.929826],[-76.896514,34.981764],[-76.854931,35.163581],[-76.895546,35.253532],[-76.911816,35.265612],[-77.100509,35.192009],[-77.144259,35.217247]],[[-77.02735,35.128797],[-77.014128,35.117798],[-77.026449,35.111976],[-77.034877,35.123991],[-77.02735,35.128797]]]},"properties":{"id":"28560","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.285097,35.291799],[-77.429567,35.206425],[-77.329312,35.149582],[-77.19574,35.235655],[-77.285097,35.291799]]]},"properties":{"id":"28523","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.540235,35.376075],[-77.411563,35.337997],[-77.30219,35.312524],[-77.260977,35.351731],[-77.50534,35.460624],[-77.540235,35.376075]]]},"properties":{"id":"28530","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.939297,35.044111],[-79.036679,35.088018],[-79.089995,35.053838],[-79.083163,35.032662],[-78.939297,35.044111]]]},"properties":{"id":"28314","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.867499,35.245065],[-78.944666,35.118693],[-78.924186,35.08904],[-78.857858,35.101658],[-78.829957,35.166439],[-78.867499,35.245065]]]},"properties":{"id":"28311","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.001112,35.159397],[-78.999193,35.109474],[-78.944666,35.118693],[-78.996171,35.174043],[-79.001112,35.159397]]]},"properties":{"id":"28307","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.999193,35.109474],[-79.001112,35.159397],[-79.037133,35.177993],[-79.015127,35.117567],[-78.999193,35.109474]]]},"properties":{"id":"28310","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.554565,35.075598],[-78.619629,34.914028],[-78.557322,34.851215],[-78.430715,34.892434],[-78.452574,34.986566],[-78.535921,35.001888],[-78.477254,35.097867],[-78.451696,35.14299],[-78.477312,35.175019],[-78.540783,35.164819],[-78.533917,35.141203],[-78.554565,35.075598]]]},"properties":{"id":"28382","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.840605,36.097425],[-75.821023,36.119305],[-75.802799,36.100239],[-75.76667,36.086627],[-75.813189,36.223544],[-75.833382,36.189854],[-75.872944,36.154027],[-75.86972,36.125171],[-75.840605,36.097425]]]},"properties":{"id":"27966","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.026946,36.389453],[-76.045965,36.358339],[-75.971076,36.308359],[-75.954416,36.340923],[-75.95334,36.407802],[-75.985767,36.412158],[-76.026946,36.389453]]]},"properties":{"id":"27917","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.939705,36.320858],[-75.89129,36.304419],[-75.912183,36.347311],[-75.939705,36.320858]]]},"properties":{"id":"27916","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.75293,35.196664],[-75.750681,35.189378],[-75.667359,35.216153],[-75.64393,35.231348],[-75.75293,35.196664]]]},"properties":{"id":"27943","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.660014,35.854512],[-75.627205,35.895701],[-75.64444,35.9211],[-75.660014,35.854512]]]},"properties":{"id":"27954","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.721267,35.809374],[-75.98108,35.957406],[-76.045987,35.666117],[-75.960054,35.669015],[-75.89105,35.645646],[-75.867279,35.57294],[-75.75567,35.678561],[-75.721267,35.809374]]]},"properties":{"id":"27953","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.519099,35.256216],[-75.588214,35.270651],[-75.64393,35.231348],[-75.667359,35.216153],[-75.519099,35.256216]]]},"properties":{"id":"27936","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.479342,35.536957],[-75.466843,35.535808],[-75.461489,35.561509],[-75.467732,35.569506],[-75.473766,35.606132],[-75.479342,35.536957]]]},"properties":{"id":"27972","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.326653,35.771596],[-80.467094,35.813932],[-80.458904,35.742811],[-80.390884,35.720523],[-80.332228,35.719047],[-80.326653,35.771596]]]},"properties":{"id":"27299","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.103855,35.942536],[-80.046709,35.920663],[-79.980181,35.980436],[-80.103855,35.942536]]]},"properties":{"id":"27262","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.704404,35.907884],[-80.70767,35.852913],[-80.665271,35.84515],[-80.564482,35.81084],[-80.556342,35.820956],[-80.547663,35.808019],[-80.556428,35.802759],[-80.534023,35.786212],[-80.487854,35.766276],[-80.458904,35.742811],[-80.467094,35.813932],[-80.470166,35.837099],[-80.486967,36.043105],[-80.496403,36.046536],[-80.694692,36.031004],[-80.704404,35.907884]]]},"properties":{"id":"27028","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.607056,36.208014],[-80.778596,36.153758],[-80.75394,36.052645],[-80.694692,36.031004],[-80.496403,36.046536],[-80.508245,36.103412],[-80.607056,36.208014]]]},"properties":{"id":"27055","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.999416,34.955542],[-77.946554,35.055736],[-78.022858,35.08883],[-78.15087,35.044366],[-78.147081,34.902448],[-77.999416,34.955542]]]},"properties":{"id":"28398","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.261423,35.203683],[-78.022858,35.08883],[-77.946554,35.055736],[-77.901505,35.04367],[-77.867365,35.137053],[-77.968397,35.230947],[-78.103934,35.280512],[-78.223592,35.298626],[-78.29282,35.27438],[-78.261423,35.203683]],[[-78.10955,35.146585],[-78.115544,35.152636],[-78.105975,35.164626],[-78.093774,35.154406],[-78.10955,35.146585]]]},"properties":{"id":"28365","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.67783,34.968599],[-77.624709,35.016862],[-77.567463,35.051649],[-77.567487,35.061168],[-77.599574,35.076243],[-77.772411,35.089809],[-77.846101,35.025098],[-77.835141,34.960721],[-77.67783,34.968599]]]},"properties":{"id":"28572","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.809708,36.359896],[-79.05117,36.241071],[-79.027382,36.165909],[-78.96329,36.164685],[-78.803242,36.179065],[-78.802851,36.208343],[-78.809708,36.359896]]]},"properties":{"id":"27572","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.841263,35.93057],[-78.842178,35.922149],[-78.831347,35.920836],[-78.827113,35.927133],[-78.841263,35.93057]]]},"properties":{"id":"27709","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.283335,35.908398],[-77.352898,35.934093],[-77.39361,35.875856],[-77.446333,35.808529],[-77.449319,35.805705],[-77.349563,35.691855],[-77.334031,35.710319],[-77.280134,35.904614],[-77.283335,35.908398]]]},"properties":{"id":"27812","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.010693,36.19621],[-78.141197,36.060345],[-78.064342,36.022576],[-78.010693,36.19621]]]},"properties":{"id":"27816","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.946593,36.431075],[-76.845178,36.374906],[-76.794503,36.359915],[-76.775555,36.360609],[-76.778708,36.443166],[-76.935646,36.478344],[-76.946593,36.431075]]]},"properties":{"id":"27935","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.980181,35.980436],[-80.046709,35.920663],[-80.047206,35.911849],[-79.9384,35.959271],[-79.955223,35.997989],[-79.980181,35.980436]]]},"properties":{"id":"27260","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.681016,35.961112],[-79.668429,35.894711],[-79.568079,35.994121],[-79.585332,35.997723],[-79.652421,35.996655],[-79.681016,35.961112]]]},"properties":{"id":"27283","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.876554,36.068489],[-79.944307,36.157278],[-79.953042,36.172826],[-79.969115,36.141059],[-79.970743,36.083793],[-79.943148,36.044194],[-79.905911,36.045713],[-79.876554,36.068489]]]},"properties":{"id":"27409","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.685431,36.186756],[-79.687544,36.059576],[-79.662085,36.021257],[-79.62152,36.059043],[-79.628389,36.197041],[-79.685431,36.186756]]]},"properties":{"id":"27301","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.006554,36.202663],[-77.964609,36.191976],[-77.94465,36.189836],[-77.890454,36.277263],[-77.966759,36.325952],[-77.981435,36.288711],[-78.014437,36.20723],[-78.006554,36.202663]]]},"properties":{"id":"27844","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.981708,35.523912],[-83.028955,35.525948],[-83.109235,35.463901],[-83.078884,35.433708],[-83.02798,35.383166],[-82.990348,35.367643],[-82.880093,35.521282],[-82.957256,35.523561],[-82.963301,35.520197],[-82.981708,35.523912]]]},"properties":{"id":"28786","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.464694,35.252045],[-82.398196,35.239826],[-82.305534,35.326836],[-82.305069,35.33153],[-82.4161,35.296935],[-82.406746,35.278233],[-82.414351,35.271619],[-82.431347,35.290251],[-82.44484,35.283939],[-82.464694,35.252045]]]},"properties":{"id":"28731","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.398196,35.239826],[-82.464694,35.252045],[-82.590677,35.162061],[-82.574907,35.145487],[-82.395418,35.213488],[-82.398196,35.239826]]]},"properties":{"id":"28790","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.497412,35.395685],[-82.462116,35.319442],[-82.44484,35.283939],[-82.431347,35.290251],[-82.4161,35.296935],[-82.305069,35.33153],[-82.276925,35.448402],[-82.266966,35.465619],[-82.288283,35.47861],[-82.339528,35.493715],[-82.338653,35.464101],[-82.364639,35.460883],[-82.380363,35.463413],[-82.497412,35.395685]]]},"properties":{"id":"28792","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.605253,35.344917],[-82.622882,35.307297],[-82.619513,35.303758],[-82.575707,35.324221],[-82.603613,35.34575],[-82.605253,35.344917]]]},"properties":{"id":"28729","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.926154,36.368063],[-76.877125,36.242849],[-76.83329,36.268968],[-76.794503,36.359915],[-76.845178,36.374906],[-76.926154,36.368063]]]},"properties":{"id":"27922","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.442691,35.022986],[-79.428411,35.016075],[-79.34031,35.05576],[-79.380441,35.117983],[-79.425664,35.160641],[-79.536545,35.1998],[-79.555805,35.181862],[-79.516404,35.091585],[-79.473295,35.050523],[-79.459176,35.043096],[-79.442691,35.022986]]]},"properties":{"id":"28315","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.320637,35.576325],[-76.348745,35.497669],[-76.061198,35.516369],[-76.065184,35.565282],[-76.127576,35.651215],[-76.127792,35.652706],[-76.168538,35.69871],[-76.194362,35.71979],[-76.320454,35.576651],[-76.320637,35.576325]]]},"properties":{"id":"27826","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-75.960054,35.669015],[-76.045987,35.666117],[-76.097727,35.670003],[-76.127792,35.652706],[-76.127576,35.651215],[-75.960054,35.669015]]],[[[-76.234368,35.338857],[-75.867279,35.57294],[-75.89105,35.645646],[-76.065184,35.565282],[-76.061198,35.516369],[-76.234368,35.338857]]]]},"properties":{"id":"27824","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.008597,35.027547],[-82.908778,35.053197],[-82.911429,35.078929],[-83.023786,35.162644],[-83.065345,35.130409],[-83.008597,35.027547]]]},"properties":{"id":"28774","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.224757,35.443033],[-83.371641,35.455937],[-83.335848,35.343264],[-83.207348,35.43643],[-83.224757,35.443033]]]},"properties":{"id":"28789","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.480886,35.851078],[-78.444637,35.737809],[-78.431929,35.720512],[-78.341367,35.690702],[-78.449771,35.869345],[-78.47202,35.871566],[-78.480886,35.851078]]]},"properties":{"id":"27591","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.631014,35.731013],[-78.607334,35.564755],[-78.559837,35.551974],[-78.558354,35.551963],[-78.522983,35.68073],[-78.631014,35.731013]]]},"properties":{"id":"27529","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.502453,35.528486],[-78.28384,35.408201],[-78.231777,35.480579],[-78.328065,35.589656],[-78.356091,35.583728],[-78.502453,35.528486]]]},"properties":{"id":"27577","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.903262,35.825762],[-77.034767,35.67938],[-76.90593,35.683069],[-76.845119,35.705409],[-76.760842,35.864822],[-76.903262,35.825762]]]},"properties":{"id":"27846","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.828183,35.471088],[-80.874382,35.512469],[-80.94703,35.487699],[-80.957638,35.435286],[-80.956609,35.42751],[-80.828183,35.471088]]]},"properties":{"id":"28031","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.838393,35.196276],[-80.869442,35.199404],[-80.875944,35.17272],[-80.836215,35.155925],[-80.829193,35.1799],[-80.838393,35.196276]]]},"properties":{"id":"28209","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.870582,35.036772],[-80.875291,35.061534],[-80.870608,35.089328],[-80.880871,35.101757],[-80.919948,35.091235],[-80.870582,35.036772]]]},"properties":{"id":"28134","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.698182,35.178312],[-79.71265,35.283316],[-79.710222,35.302748],[-79.853594,35.343041],[-79.919839,35.312739],[-79.85002,35.183072],[-79.733651,35.17818],[-79.717526,35.173596],[-79.698182,35.178312]]]},"properties":{"id":"27229","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.317982,35.167848],[-79.09613,35.192181],[-79.271539,35.284689],[-79.303396,35.248381],[-79.327764,35.23688],[-79.317982,35.167848]]]},"properties":{"id":"28394","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.927606,34.237085],[-77.91994,34.193472],[-77.888883,34.198002],[-77.817037,34.213432],[-77.812617,34.2183],[-77.927606,34.237085]]]},"properties":{"id":"28403","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.872892,34.078886],[-77.899696,34.055741],[-77.930956,34.050324],[-77.933953,34.017108],[-77.89892,34.01356],[-77.872892,34.078886]]]},"properties":{"id":"28428","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.265015,36.46476],[-77.357667,36.435164],[-77.304545,36.368656],[-77.159483,36.365771],[-77.166789,36.455772],[-77.265015,36.46476]]]},"properties":{"id":"27820","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.068054,35.916656],[-79.099447,35.919801],[-79.080923,35.889968],[-79.068054,35.916656]]]},"properties":{"id":"27510","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.231281,36.170898],[-79.22612,35.961135],[-79.205148,35.973563],[-79.179746,36.159327],[-79.231281,36.170898]]]},"properties":{"id":"27243","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.323459,35.471299],[-77.38561,35.556285],[-77.504657,35.527711],[-77.323459,35.471299]]]},"properties":{"id":"28590","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.282527,35.575497],[-77.276022,35.568521],[-77.273453,35.579069],[-77.282527,35.575497]]]},"properties":{"id":"27879","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.671084,35.836203],[-79.746885,35.846306],[-79.731835,35.751083],[-79.713601,35.724224],[-79.676759,35.710792],[-79.664008,35.775031],[-79.642953,35.802102],[-79.642159,35.813025],[-79.671084,35.836203]]]},"properties":{"id":"27248","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.864492,35.865424],[-79.904934,35.855321],[-79.9435,35.790715],[-79.853808,35.760206],[-79.864492,35.865424]]]},"properties":{"id":"27350","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.909083,34.838859],[-79.927618,34.806555],[-79.773744,34.805932],[-79.659826,34.958436],[-79.639912,35.011269],[-79.689573,35.055056],[-79.88387,35.051628],[-79.909083,34.838859]],[[-79.824867,34.903288],[-79.825559,34.913341],[-79.815972,34.90697],[-79.824867,34.903288]]]},"properties":{"id":"28379","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.414003,34.591475],[-79.275105,34.472299],[-79.156631,34.58135],[-79.179953,34.641751],[-79.243198,34.657954],[-79.414003,34.591475]]]},"properties":{"id":"28383","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.068456,36.369378],[-80.032604,36.355866],[-80.033338,36.319954],[-80.033531,36.309636],[-79.906041,36.278982],[-79.884182,36.310221],[-79.788096,36.427005],[-79.795295,36.42808],[-79.937675,36.407779],[-80.029823,36.461778],[-80.144489,36.417606],[-80.140891,36.403313],[-80.068456,36.369378]]]},"properties":{"id":"27025","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.481687,35.642904],[-80.487854,35.766276],[-80.534023,35.786212],[-80.610765,35.745723],[-80.64235,35.707565],[-80.652894,35.636605],[-80.545414,35.587903],[-80.481687,35.642904]]]},"properties":{"id":"28147","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.295453,35.486022],[-80.333596,35.445499],[-80.331781,35.406988],[-80.182682,35.504335],[-80.237128,35.607142],[-80.24122,35.612455],[-80.263375,35.567833],[-80.274142,35.503052],[-80.295453,35.486022]]]},"properties":{"id":"28137","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.435267,35.680072],[-80.435028,35.682897],[-80.439304,35.680679],[-80.43644,35.677115],[-80.435267,35.680072]]]},"properties":{"id":"28039","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.253323,36.412528],[-80.140891,36.403313],[-80.144489,36.417606],[-80.149712,36.461634],[-80.283329,36.483342],[-80.253323,36.412528]]]},"properties":{"id":"27016","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.027269,36.542495],[-80.150245,36.542959],[-80.149712,36.461634],[-80.144489,36.417606],[-80.029823,36.461778],[-80.028713,36.507058],[-80.027269,36.542495]]]},"properties":{"id":"27046","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.961033,36.346018],[-80.982643,36.299906],[-80.941822,36.262281],[-80.873259,36.236383],[-80.722703,36.2696],[-80.840647,36.396974],[-80.840638,36.375302],[-80.797379,36.291252],[-80.8913,36.291419],[-80.919228,36.337408],[-80.961033,36.346018]]]},"properties":{"id":"28621","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.838673,35.261261],[-82.671459,35.187636],[-82.620358,35.201118],[-82.616631,35.224347],[-82.631177,35.269244],[-82.619513,35.303758],[-82.622882,35.307297],[-82.647037,35.328709],[-82.654762,35.336021],[-82.66663,35.347454],[-82.745209,35.422924],[-82.790428,35.373245],[-82.838673,35.261261]]]},"properties":{"id":"28768","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.783438,35.701156],[-78.703843,35.691152],[-78.657935,35.780408],[-78.665304,35.783114],[-78.747061,35.789359],[-78.745078,35.762154],[-78.783438,35.701156]]]},"properties":{"id":"27606","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.747061,35.789359],[-78.665304,35.783114],[-78.660208,35.799844],[-78.668746,35.820207],[-78.769103,35.861052],[-78.777577,35.845369],[-78.76376,35.792338],[-78.747061,35.789359]]]},"properties":{"id":"27607","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.515365,35.812851],[-78.623329,35.784511],[-78.634826,35.758567],[-78.631014,35.731013],[-78.522983,35.68073],[-78.460036,35.705691],[-78.431929,35.720512],[-78.444637,35.737809],[-78.515365,35.812851]]]},"properties":{"id":"27610","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.637818,35.786058],[-78.634826,35.758567],[-78.623329,35.784511],[-78.637818,35.786058]]]},"properties":{"id":"27601","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.415643,35.916113],[-78.438662,35.920441],[-78.441657,35.933221],[-78.468204,35.923978],[-78.452227,35.913045],[-78.422372,35.910218],[-78.415643,35.916113]]]},"properties":{"id":"27571","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.954854,35.449027],[-77.97986,35.348432],[-77.970588,35.321634],[-77.939418,35.268044],[-77.880923,35.248598],[-77.826886,35.456075],[-77.954854,35.449027]]]},"properties":{"id":"27534","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.970588,35.321634],[-77.97986,35.348432],[-77.994401,35.337896],[-77.970588,35.321634]]]},"properties":{"id":"27531","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.025511,36.198534],[-81.011209,36.150762],[-80.957617,36.133928],[-80.941822,36.262281],[-80.982643,36.299906],[-81.057181,36.273832],[-81.061132,36.269908],[-81.025511,36.198534]]]},"properties":{"id":"28669","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.308413,36.111466],[-81.238413,36.096687],[-81.099727,36.104763],[-81.011209,36.150762],[-81.025511,36.198534],[-81.225811,36.167208],[-81.269677,36.160024],[-81.316304,36.156202],[-81.308413,36.111466]]]},"properties":{"id":"28697","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.477298,36.240272],[-81.447151,36.189477],[-81.316304,36.156202],[-81.269677,36.160024],[-81.378809,36.277983],[-81.434907,36.264601],[-81.477298,36.240272]]]},"properties":{"id":"28665","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.144967,36.374264],[-81.162056,36.269647],[-81.061132,36.269908],[-81.057181,36.273832],[-81.144967,36.374264]]]},"properties":{"id":"28635","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.783807,35.651941],[-77.774788,35.645592],[-77.765135,35.654049],[-77.780879,35.657421],[-77.783807,35.651941]]]},"properties":{"id":"27873","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.335152,35.749462],[-82.275394,35.704423],[-82.192635,35.736392],[-82.133182,35.824446],[-82.148395,35.932976],[-82.267735,36.014438],[-82.416708,36.07278],[-82.505384,35.97768],[-82.408728,35.818061],[-82.335152,35.749462]]]},"properties":{"id":"28714","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.385568,36.088704],[-79.413041,36.073641],[-79.42486,35.985232],[-79.326124,35.844075],[-79.236816,35.848771],[-79.264152,35.930242],[-79.28004,35.975389],[-79.385568,36.088704]],[[-79.321776,35.946828],[-79.317555,35.947054],[-79.315461,35.946045],[-79.315825,35.942951],[-79.319043,35.945692],[-79.321776,35.946828]]]},"properties":{"id":"27253","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.628389,36.197041],[-79.62152,36.059043],[-79.546651,36.079197],[-79.534687,36.087604],[-79.519059,36.278488],[-79.563307,36.251032],[-79.628389,36.197041]]]},"properties":{"id":"27249","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.901732,36.561835],[-81.028974,36.565851],[-81.004946,36.494136],[-80.946588,36.478456],[-80.927059,36.501106],[-80.901732,36.561835]]]},"properties":{"id":"28623","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.720066,36.423905],[-81.686256,36.371674],[-81.586682,36.407376],[-81.565409,36.431822],[-81.629378,36.514713],[-81.649767,36.587774],[-81.720066,36.423905]]]},"properties":{"id":"28615","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.879415,36.113865],[-81.793122,36.032573],[-81.778903,36.100166],[-81.81152,36.111544],[-81.879415,36.113865]]]},"properties":{"id":"28646","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.778903,36.100166],[-81.793122,36.032573],[-81.807157,35.961533],[-81.755861,35.925313],[-81.706814,36.085238],[-81.778903,36.100166]]]},"properties":{"id":"28611","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.148395,35.932976],[-82.133182,35.824446],[-82.114147,35.834006],[-82.10034,35.85255],[-82.075155,35.84833],[-81.981723,35.91152],[-81.984721,35.951307],[-82.059126,35.988378],[-82.148395,35.932976]]]},"properties":{"id":"28777","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.732959,35.531715],[-76.844449,35.513839],[-76.830556,35.42815],[-76.822215,35.425656],[-76.666373,35.378284],[-76.732959,35.531715]]]},"properties":{"id":"27808","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.280134,35.904614],[-77.334031,35.710319],[-77.191696,35.739161],[-77.166695,35.725023],[-77.156937,35.737056],[-77.177616,35.82766],[-77.184272,35.830983],[-77.187162,35.910745],[-77.270534,35.911103],[-77.280134,35.904614]],[[-77.312909,35.818014],[-77.314128,35.806589],[-77.324565,35.814861],[-77.312909,35.818014]]]},"properties":{"id":"27871","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.818172,36.072462],[-76.736418,36.147821],[-76.706556,36.243869],[-76.83329,36.268968],[-76.877125,36.242849],[-76.89407,36.210879],[-76.938224,36.222101],[-76.922104,36.145071],[-76.818172,36.072462]]]},"properties":{"id":"27924","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.3689,34.474342],[-78.256316,34.399763],[-78.143678,34.445295],[-78.200392,34.474799],[-78.428386,34.630992],[-78.44693,34.610063],[-78.436231,34.548819],[-78.3689,34.474342]]]},"properties":{"id":"28448","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.19924,34.107741],[-78.223612,33.92441],[-78.153302,33.927301],[-78.064937,34.068867],[-78.19924,34.107741]]]},"properties":{"id":"28422","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.521739,33.997253],[-78.559206,34.029603],[-78.561379,34.000924],[-78.536029,33.993959],[-78.521739,33.997253]]]},"properties":{"id":"28452","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.808316,35.700393],[-82.883665,35.67819],[-82.846352,35.614451],[-82.765991,35.567516],[-82.664532,35.597548],[-82.647005,35.646138],[-82.677475,35.709002],[-82.808316,35.700393]]]},"properties":{"id":"28748","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.560264,35.43134],[-82.487649,35.485879],[-82.592192,35.500144],[-82.616506,35.494086],[-82.660301,35.452787],[-82.656584,35.431663],[-82.601002,35.416114],[-82.560264,35.43134]]]},"properties":{"id":"28704","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.639354,36.137299],[-81.706814,36.085238],[-81.755861,35.925313],[-81.55596,35.777332],[-81.530727,35.828498],[-81.472525,35.888803],[-81.431565,35.86834],[-81.339015,35.905047],[-81.328385,35.99655],[-81.392045,36.048187],[-81.510785,36.136712],[-81.545603,36.16353],[-81.639354,36.137299]]]},"properties":{"id":"28645","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.617628,35.456846],[-80.615988,35.338709],[-80.627978,35.248604],[-80.605229,35.236915],[-80.599802,35.234052],[-80.44998,35.308179],[-80.439082,35.457372],[-80.513364,35.485888],[-80.62046,35.465305],[-80.617628,35.456846]]]},"properties":{"id":"28025","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.615988,35.338709],[-80.693698,35.346848],[-80.68294,35.315609],[-80.67476,35.291155],[-80.627978,35.248604],[-80.615988,35.338709]]]},"properties":{"id":"28075","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.439082,35.457372],[-80.44998,35.308179],[-80.437047,35.297062],[-80.339941,35.334745],[-80.342275,35.388018],[-80.331781,35.406988],[-80.333596,35.445499],[-80.399684,35.480993],[-80.439082,35.457372]]]},"properties":{"id":"28124","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.365223,35.564511],[-80.509876,35.545207],[-80.545509,35.515763],[-80.513364,35.485888],[-80.439082,35.457372],[-80.399684,35.480993],[-80.365223,35.564511]]]},"properties":{"id":"28138","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.437047,35.297062],[-80.468219,35.24087],[-80.378289,35.260716],[-80.319072,35.306889],[-80.339941,35.334745],[-80.437047,35.297062]]]},"properties":{"id":"28097","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.740448,35.377745],[-80.809067,35.276659],[-80.793526,35.278725],[-80.68294,35.315609],[-80.693698,35.346848],[-80.740448,35.377745]]]},"properties":{"id":"28262","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.780009,35.253838],[-80.67476,35.291155],[-80.68294,35.315609],[-80.793526,35.278725],[-80.791124,35.251456],[-80.780009,35.253838]]]},"properties":{"id":"28213","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.431565,35.86834],[-81.472525,35.888803],[-81.530727,35.828498],[-81.431565,35.86834]]]},"properties":{"id":"28638","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.257348,36.38199],[-76.13994,36.274498],[-76.068178,36.276362],[-76.148004,36.394685],[-76.187569,36.449748],[-76.257348,36.38199]]]},"properties":{"id":"27921","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.674288,34.747782],[-76.845789,34.723406],[-76.692721,34.715555],[-76.674288,34.747782]]]},"properties":{"id":"28557","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.169216,34.722945],[-77.107305,34.64734],[-77.008298,34.689272],[-76.957621,34.810692],[-77.090323,34.802755],[-77.131738,34.798126],[-77.169216,34.722945]]]},"properties":{"id":"28584","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.36413,34.926567],[-76.383217,34.898112],[-76.348674,34.855008],[-76.287097,34.898137],[-76.36413,34.926567]]]},"properties":{"id":"28511","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.383217,34.898112],[-76.400601,34.891679],[-76.385784,34.830063],[-76.348674,34.855008],[-76.383217,34.898112]]]},"properties":{"id":"28577","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.44088,36.366222],[-79.415612,36.315292],[-79.391114,36.281496],[-79.296044,36.342421],[-79.243123,36.328717],[-79.232092,36.405058],[-79.365963,36.488079],[-79.393463,36.450774],[-79.440881,36.409585],[-79.44088,36.366222]]]},"properties":{"id":"27379","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.518376,36.540779],[-79.605369,36.541468],[-79.647123,36.448861],[-79.44088,36.366222],[-79.440881,36.409585],[-79.518376,36.540779]]]},"properties":{"id":"27326","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.47505,35.484757],[-81.397961,35.470444],[-81.323121,35.584214],[-81.381409,35.614218],[-81.485542,35.625443],[-81.511313,35.584721],[-81.47505,35.484757]]]},"properties":{"id":"28168","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.900406,35.68281],[-79.032724,35.700823],[-79.071804,35.658958],[-78.963408,35.565584],[-78.937856,35.592056],[-78.917511,35.630104],[-78.900406,35.68281]]]},"properties":{"id":"27562","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.680043,35.279414],[-83.773499,35.245759],[-83.738106,35.155911],[-83.651922,35.135538],[-83.580775,35.290901],[-83.680043,35.279414]]]},"properties":{"id":"28781","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.786279,34.17729],[-78.796343,34.203474],[-78.877724,34.236164],[-78.786279,34.17729]]],[[[-78.786279,34.17729],[-78.714045,34.084947],[-78.705151,34.183128],[-78.786279,34.17729]]]]},"properties":{"id":"28432","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.948484,34.929826],[-77.144259,35.217247],[-77.19574,35.235655],[-77.329312,35.149582],[-77.221446,35.057174],[-77.106672,34.933832],[-77.108813,34.887572],[-76.948484,34.929826]]]},"properties":{"id":"28562","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.858141,35.100152],[-78.701231,35.085654],[-78.672277,35.085286],[-78.652729,35.102711],[-78.731442,35.174516],[-78.735461,35.175001],[-78.829957,35.166439],[-78.857858,35.101658],[-78.858141,35.100152]]]},"properties":{"id":"28395","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.701231,35.085654],[-78.699958,34.952799],[-78.672277,35.085286],[-78.701231,35.085654]]]},"properties":{"id":"28391","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.230402,36.08686],[-80.232349,35.957584],[-80.179545,35.894681],[-80.132061,35.9509],[-80.119492,36.004722],[-80.168012,36.102735],[-80.219235,36.089054],[-80.228933,36.086209],[-80.230402,36.08686]]]},"properties":{"id":"27107","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.556428,35.802759],[-80.547663,35.808019],[-80.556342,35.820956],[-80.564482,35.81084],[-80.556428,35.802759]]]},"properties":{"id":"27014","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.895913,34.885321],[-77.872908,34.816112],[-77.832263,34.830414],[-77.63661,34.834425],[-77.67783,34.968599],[-77.835141,34.960721],[-77.895913,34.885321]]]},"properties":{"id":"28518","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.677614,35.64886],[-77.551889,35.638027],[-77.511677,35.69415],[-77.513298,35.698202],[-77.530303,35.722583],[-77.72001,35.730171],[-77.744379,35.706373],[-77.700502,35.652256],[-77.677614,35.64886]]]},"properties":{"id":"27829","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.442903,35.971556],[-77.445657,35.964506],[-77.4283,35.984348],[-77.43519,35.991027],[-77.442903,35.971556]]]},"properties":{"id":"27881","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.492293,36.489939],[-78.483889,36.232337],[-78.342966,36.199859],[-78.257523,36.24778],[-78.226527,36.291885],[-78.322198,36.392186],[-78.302782,36.425704],[-78.323912,36.543809],[-78.45697,36.542474],[-78.492293,36.489939]],[[-78.417585,36.344635],[-78.371683,36.318806],[-78.405336,36.292656],[-78.444499,36.310348],[-78.417585,36.344635]]]},"properties":{"id":"27537","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.061011,35.415283],[-81.129735,35.407769],[-81.107532,35.389637],[-81.061011,35.415283]]]},"properties":{"id":"28006","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.778708,36.443166],[-76.775555,36.360609],[-76.696601,36.296211],[-76.683915,36.300773],[-76.670255,36.314731],[-76.642869,36.394605],[-76.654765,36.458876],[-76.778708,36.443166]]]},"properties":{"id":"27938","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.476473,36.37257],[-76.45369,36.378042],[-76.491477,36.510536],[-76.525013,36.537271],[-76.636123,36.469548],[-76.654765,36.458876],[-76.642869,36.394605],[-76.476473,36.37257]]]},"properties":{"id":"27979","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.86589,35.448292],[-83.819424,35.421272],[-83.766657,35.443436],[-83.86589,35.448292]]]},"properties":{"id":"28733","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.7963,36.541713],[-78.835303,36.371018],[-78.809708,36.359896],[-78.802851,36.208343],[-78.672454,36.170213],[-78.599338,36.169175],[-78.56785,36.191316],[-78.483889,36.232337],[-78.492293,36.489939],[-78.558278,36.467942],[-78.580765,36.449819],[-78.593613,36.47267],[-78.685435,36.542065],[-78.7963,36.541713]]]},"properties":{"id":"27565","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.522704,35.52509],[-77.551889,35.638027],[-77.677614,35.64886],[-77.652476,35.531627],[-77.530583,35.518517],[-77.522704,35.52509]]]},"properties":{"id":"27828","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.826886,35.456075],[-77.880923,35.248598],[-77.766784,35.224608],[-77.660811,35.352757],[-77.820042,35.474215],[-77.826886,35.456075]]]},"properties":{"id":"28551","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.796191,35.919553],[-79.710339,35.966563],[-79.681016,35.961112],[-79.652421,35.996655],[-79.662085,36.021257],[-79.687544,36.059576],[-79.73912,36.053272],[-79.799388,36.064239],[-79.819763,36.03572],[-79.869619,35.934019],[-79.855819,35.915096],[-79.796191,35.919553]]]},"properties":{"id":"27406","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.73912,36.053272],[-79.788557,36.094003],[-79.807694,36.079518],[-79.799388,36.064239],[-79.73912,36.053272]]]},"properties":{"id":"27401","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.757725,36.247734],[-79.776221,36.14258],[-79.685431,36.186756],[-79.628389,36.197041],[-79.563307,36.251032],[-79.757725,36.247734]]]},"properties":{"id":"27214","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.776221,36.14258],[-79.801806,36.126222],[-79.788557,36.094003],[-79.73912,36.053272],[-79.687544,36.059576],[-79.685431,36.186756],[-79.776221,36.14258]]]},"properties":{"id":"27405","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.746885,35.846306],[-79.723868,35.863114],[-79.796191,35.919553],[-79.855819,35.915096],[-79.864492,35.865424],[-79.853808,35.760206],[-79.827768,35.763123],[-79.731835,35.751083],[-79.746885,35.846306]]]},"properties":{"id":"27317","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.923588,35.476886],[-79.030396,35.312551],[-79.009431,35.274342],[-78.943218,35.287169],[-78.746932,35.33399],[-78.718634,35.382441],[-78.714673,35.425268],[-78.810479,35.454466],[-78.923588,35.476886]]]},"properties":{"id":"27546","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.959216,35.50599],[-79.067662,35.462703],[-79.030396,35.312551],[-78.923588,35.476886],[-78.959216,35.50599]]]},"properties":{"id":"27505","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.398196,35.239826],[-82.395418,35.213488],[-82.313529,35.191426],[-82.243471,35.254562],[-82.204606,35.288674],[-82.305534,35.326836],[-82.398196,35.239826]]]},"properties":{"id":"28773","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.093418,36.472639],[-76.956978,36.43629],[-76.946593,36.431075],[-76.935646,36.478344],[-76.916001,36.543818],[-77.1645,36.54633],[-77.093418,36.472639]]]},"properties":{"id":"27818","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.74481,35.749758],[-81.074713,35.743088],[-80.960653,35.660041],[-80.943656,35.637643],[-80.811943,35.692417],[-80.74481,35.749758]]]},"properties":{"id":"28677","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.855793,35.511064],[-80.737069,35.507163],[-80.675629,35.545702],[-80.672271,35.610652],[-80.786727,35.663772],[-80.799647,35.667911],[-80.864277,35.639871],[-80.855793,35.511064]]]},"properties":{"id":"28115","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.642481,35.796678],[-78.657935,35.780408],[-78.703843,35.691152],[-78.71797,35.656058],[-78.695967,35.612517],[-78.607334,35.564755],[-78.631014,35.731013],[-78.634826,35.758567],[-78.637818,35.786058],[-78.625206,35.804513],[-78.642481,35.796678]]]},"properties":{"id":"27603","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.131738,34.798126],[-77.090323,34.802755],[-77.108813,34.887572],[-77.106672,34.933832],[-77.271806,34.957502],[-77.339245,34.842672],[-77.263672,34.77498],[-77.18118,34.786995],[-77.131738,34.798126]]]},"properties":{"id":"28555","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.155053,35.520351],[-82.192635,35.736392],[-82.275394,35.704423],[-82.169018,35.527841],[-82.155053,35.520351]]]},"properties":{"id":"28762","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.270534,35.911103],[-77.283335,35.908398],[-77.280134,35.904614],[-77.270534,35.911103]]]},"properties":{"id":"27841","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.776402,35.182152],[-80.812522,35.19951],[-80.829193,35.1799],[-80.836215,35.155925],[-80.825446,35.146843],[-80.79497,35.154443],[-80.745542,35.148232],[-80.743247,35.148923],[-80.776402,35.182152]]]},"properties":{"id":"28211","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.882985,35.207836],[-80.959034,35.193626],[-80.960868,35.171999],[-80.876335,35.137719],[-80.875944,35.17272],[-80.869442,35.199404],[-80.882985,35.207836]]]},"properties":{"id":"28217","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.953545,35.350655],[-80.896875,35.276785],[-80.855789,35.235923],[-80.850497,35.241046],[-80.846742,35.272566],[-80.843057,35.367824],[-80.939077,35.353648],[-80.953545,35.350655]]]},"properties":{"id":"28216","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.71265,35.283316],[-79.698182,35.178312],[-79.582828,35.083977],[-79.516088,35.08631],[-79.516404,35.091585],[-79.555805,35.181862],[-79.641693,35.266485],[-79.71265,35.283316]]]},"properties":{"id":"27281","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.303396,35.248381],[-79.32849,35.237017],[-79.327764,35.23688],[-79.303396,35.248381]]]},"properties":{"id":"28350","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.392987,34.744915],[-77.429355,34.684551],[-77.343779,34.72761],[-77.353595,34.734655],[-77.392987,34.744915]]]},"properties":{"id":"28547","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.471147,34.893234],[-77.595629,34.730338],[-77.568107,34.670331],[-77.429355,34.684551],[-77.392987,34.744915],[-77.387048,34.74402],[-77.47777,34.867807],[-77.471147,34.893234]]]},"properties":{"id":"28540","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.657077,35.170446],[-76.653951,35.201399],[-76.690076,35.196037],[-76.663347,35.168162],[-76.657077,35.170446]]]},"properties":{"id":"28587","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.937313,36.419161],[-78.989464,36.429293],[-79.0132,36.371741],[-78.977024,36.369801],[-78.937313,36.419161]]]},"properties":{"id":"27573","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.594834,35.780309],[-79.664008,35.775031],[-79.676759,35.710792],[-79.671944,35.61231],[-79.606542,35.591917],[-79.576255,35.641486],[-79.546793,35.731532],[-79.594834,35.780309]]]},"properties":{"id":"27316","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.773744,34.805932],[-79.667175,34.79806],[-79.561826,34.910363],[-79.659826,34.958436],[-79.773744,34.805932]]]},"properties":{"id":"28345","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.564482,35.81084],[-80.665271,35.84515],[-80.610765,35.745723],[-80.534023,35.786212],[-80.556428,35.802759],[-80.564482,35.81084]]]},"properties":{"id":"27054","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.257304,35.435392],[-82.240101,35.472133],[-82.266966,35.465619],[-82.276925,35.448402],[-82.257304,35.435392]]]},"properties":{"id":"28720","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.540848,35.128489],[-78.554565,35.075598],[-78.533917,35.141203],[-78.540848,35.128489]]],[[[-78.477254,35.097867],[-78.535921,35.001888],[-78.452574,34.986566],[-78.477254,35.097867]]]]},"properties":{"id":"28385","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.093966,35.287087],[-80.265779,35.236183],[-80.253114,35.210874],[-80.17864,35.149886],[-80.07539,35.143081],[-80.093966,35.287087]]]},"properties":{"id":"28128","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.919228,36.337408],[-80.8913,36.291419],[-80.797379,36.291252],[-80.840638,36.375302],[-80.919228,36.337408]]]},"properties":{"id":"28676","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.4037,34.885884],[-80.431135,35.055672],[-80.472347,34.982111],[-80.4037,34.885884]]]},"properties":{"id":"28174","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.298023,36.412867],[-78.270487,36.544264],[-78.323912,36.543809],[-78.302782,36.425704],[-78.298023,36.412867]]]},"properties":{"id":"27553","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.745078,35.762154],[-78.813629,35.726217],[-78.783438,35.701156],[-78.745078,35.762154]]]},"properties":{"id":"27518","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.49366,35.830086],[-78.603768,35.84982],[-78.619673,35.813902],[-78.625206,35.804513],[-78.637818,35.786058],[-78.623329,35.784511],[-78.515365,35.812851],[-78.49366,35.830086]]]},"properties":{"id":"27604","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.791825,35.705397],[-76.648928,35.718153],[-76.70686,35.92304],[-76.760842,35.864822],[-76.845119,35.705409],[-76.791825,35.705397]]]},"properties":{"id":"27962","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.144967,36.374264],[-81.057181,36.273832],[-80.982643,36.299906],[-80.961033,36.346018],[-80.984004,36.376807],[-81.043865,36.398336],[-81.16446,36.430676],[-81.144967,36.374264]]]},"properties":{"id":"28685","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.095367,35.686259],[-78.048574,35.595873],[-77.953376,35.606013],[-78.061088,35.697602],[-78.095367,35.686259]]]},"properties":{"id":"27851","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-79.594834,35.780309],[-79.642953,35.802102],[-79.664008,35.775031],[-79.594834,35.780309]]],[[[-79.432778,35.843749],[-79.496279,35.968485],[-79.568079,35.994121],[-79.668429,35.894711],[-79.671084,35.836203],[-79.642159,35.813025],[-79.53436,35.822366],[-79.432778,35.843749]]]]},"properties":{"id":"27298","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.704404,35.907884],[-80.808405,35.913029],[-80.927961,36.020288],[-81.00532,36.000697],[-80.98079,35.90319],[-81.08929,35.755856],[-81.074713,35.743088],[-80.74481,35.749758],[-80.70767,35.852913],[-80.704404,35.907884]]]},"properties":{"id":"28625","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.324425,36.575362],[-81.476362,36.580206],[-81.410048,36.51874],[-81.353221,36.545824],[-81.324425,36.575362]]]},"properties":{"id":"28631","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.708061,36.346157],[-81.686256,36.371674],[-81.720066,36.423905],[-81.708061,36.346157]]],[[[-81.791069,36.352016],[-81.746019,36.271857],[-81.709512,36.320676],[-81.70739,36.33584],[-81.791069,36.352016]]]]},"properties":{"id":"28698","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.990328,36.097911],[-81.986946,36.09976],[-81.98959,36.102456],[-81.991954,36.098935],[-81.990328,36.097911]]]},"properties":{"id":"28652","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.189582,35.429295],[-77.054756,35.349918],[-76.995716,35.319388],[-77.008261,35.499164],[-77.100801,35.551168],[-77.189582,35.429295]]]},"properties":{"id":"27817","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.143955,36.132158],[-77.215274,36.013805],[-77.198362,35.9391],[-77.172318,35.92912],[-76.903262,35.825762],[-76.760842,35.864822],[-76.70686,35.92304],[-76.691619,35.946755],[-76.678535,36.027831],[-76.818172,36.072462],[-76.922104,36.145071],[-76.964162,36.120821],[-77.131936,36.130665],[-77.143955,36.132158]]]},"properties":{"id":"27983","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.877125,36.242849],[-76.938224,36.222101],[-76.89407,36.210879],[-76.877125,36.242849]]]},"properties":{"id":"27967","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.701231,35.085654],[-78.858141,35.100152],[-78.859846,35.032164],[-78.825488,34.848302],[-78.642149,34.779167],[-78.5986,34.81382],[-78.557322,34.851215],[-78.619629,34.914028],[-78.699958,34.952799],[-78.701231,35.085654]]]},"properties":{"id":"28312","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.87153,34.482668],[-78.848246,34.435641],[-78.796602,34.436492],[-78.652692,34.580697],[-78.722169,34.652923],[-78.76318,34.655089],[-78.805221,34.674598],[-78.87153,34.482668]]]},"properties":{"id":"28320","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.996852,34.958753],[-78.886982,34.84794],[-78.844249,34.821238],[-78.825488,34.848302],[-78.859846,35.032164],[-78.892224,35.042815],[-78.897492,35.040864],[-79.059334,35.003032],[-79.036926,34.956926],[-78.996852,34.958753]]]},"properties":{"id":"28306","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.576826,35.615593],[-82.563903,35.568568],[-82.544475,35.583844],[-82.540988,35.599929],[-82.576826,35.615593]]]},"properties":{"id":"28801","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.805856,35.935679],[-82.808316,35.700393],[-82.677475,35.709002],[-82.627385,35.755002],[-82.599715,35.783658],[-82.582878,35.965344],[-82.805856,35.935679]]]},"properties":{"id":"28753","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.919242,35.848759],[-81.969928,35.525166],[-81.829611,35.592019],[-81.919242,35.848759]]]},"properties":{"id":"28761","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.43631,35.773342],[-81.408434,35.76527],[-81.420659,35.770632],[-81.426491,35.779796],[-81.43631,35.773342]]]},"properties":{"id":"28667","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.474568,35.389163],[-81.47505,35.484757],[-81.511313,35.584721],[-81.574205,35.559718],[-81.696155,35.484214],[-81.676354,35.430974],[-81.474568,35.389163]]]},"properties":{"id":"28090","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.187569,36.449748],[-76.313225,36.550574],[-76.541599,36.550475],[-76.525013,36.537271],[-76.491477,36.510536],[-76.257348,36.38199],[-76.187569,36.449748]]]},"properties":{"id":"27976","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.008298,34.689272],[-77.107305,34.64734],[-77.112238,34.641904],[-76.910524,34.682325],[-76.912322,34.716423],[-77.008298,34.689272]]]},"properties":{"id":"28594","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.528998,34.735637],[-76.522262,34.71537],[-76.509552,34.714953],[-76.528998,34.735637]]]},"properties":{"id":"28553","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.111726,35.604881],[-81.285519,35.594264],[-81.101424,35.524552],[-81.046133,35.577212],[-81.075455,35.603403],[-81.111726,35.604881]]]},"properties":{"id":"28650","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.101424,35.524552],[-81.285519,35.594264],[-81.323121,35.584214],[-81.397961,35.470444],[-81.378749,35.441573],[-81.277616,35.391534],[-81.253843,35.365535],[-81.203493,35.397309],[-81.210082,35.40768],[-81.199174,35.41029],[-81.178112,35.409481],[-81.105296,35.498406],[-81.101424,35.524552]]]},"properties":{"id":"28092","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.915426,35.624041],[-78.937856,35.592056],[-78.963408,35.565584],[-78.932594,35.562211],[-78.963022,35.528874],[-78.767623,35.651008],[-78.915426,35.624041]]]},"properties":{"id":"27540","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.692329,35.579789],[-81.719527,35.511551],[-81.696155,35.484214],[-81.574205,35.559718],[-81.690991,35.584309],[-81.692329,35.579789]]]},"properties":{"id":"28020","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.670881,35.316931],[-81.656834,35.318004],[-81.650796,35.317722],[-81.657805,35.323221],[-81.658317,35.318183],[-81.67057,35.319704],[-81.67096,35.318341],[-81.670881,35.316931]]]},"properties":{"id":"28089","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.071169,34.29924],[-78.988196,34.229645],[-78.969481,34.241587],[-78.979848,34.355152],[-79.034463,34.322547],[-79.071169,34.29924]]]},"properties":{"id":"28439","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.02735,35.128797],[-77.034877,35.123991],[-77.026449,35.111976],[-77.014128,35.117798],[-77.02735,35.128797]]]},"properties":{"id":"28519","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.897701,34.906459],[-76.905672,34.90849],[-76.905079,34.893767],[-76.893642,34.903024],[-76.897701,34.906459]]]},"properties":{"id":"28533","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.914383,35.040997],[-78.939297,35.044111],[-79.083163,35.032662],[-79.059334,35.003032],[-78.897492,35.040864],[-78.914383,35.040997]]]},"properties":{"id":"28304","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.036679,35.088018],[-78.939297,35.044111],[-78.914383,35.040997],[-78.89803,35.070522],[-78.924186,35.08904],[-78.944666,35.118693],[-78.999193,35.109474],[-79.015127,35.117567],[-79.036679,35.088018]]]},"properties":{"id":"28303","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.59811,35.386002],[-78.697856,35.374999],[-78.702756,35.256728],[-78.735461,35.175001],[-78.731442,35.174516],[-78.65763,35.199265],[-78.639624,35.199293],[-78.540783,35.164819],[-78.477312,35.175019],[-78.432033,35.285857],[-78.59811,35.386002]]]},"properties":{"id":"28334","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.773237,36.231548],[-75.906829,36.550654],[-75.917514,36.46677],[-75.858635,36.306031],[-75.852777,36.289633],[-75.813189,36.223544],[-75.773237,36.231548]]]},"properties":{"id":"27927","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.939705,36.320858],[-75.954416,36.340923],[-75.971076,36.308359],[-75.887997,36.163348],[-75.891944,36.253775],[-75.852777,36.289633],[-75.858635,36.306031],[-75.89129,36.304419],[-75.939705,36.320858]]]},"properties":{"id":"27965","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.038768,36.415891],[-75.985767,36.412158],[-75.95334,36.407802],[-75.96587,36.45067],[-75.98101,36.462342],[-76.038768,36.415891]]]},"properties":{"id":"27929","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.985767,36.412158],[-76.038768,36.415891],[-76.042895,36.390655],[-76.026946,36.389453],[-75.985767,36.412158]]]},"properties":{"id":"27956","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.467732,35.569506],[-75.461489,35.561509],[-75.460698,35.572884],[-75.467732,35.569506]]]},"properties":{"id":"27982","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.153163,34.721957],[-77.906768,34.658932],[-77.735208,34.656237],[-77.680353,34.725794],[-77.708291,34.758407],[-77.832263,34.830414],[-77.872908,34.816112],[-77.939633,34.802836],[-78.008221,34.740238],[-78.097225,34.767608],[-78.193852,34.755661],[-78.153163,34.721957]]]},"properties":{"id":"28466","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.10955,35.146585],[-78.093774,35.154406],[-78.105975,35.164626],[-78.115544,35.152636],[-78.10955,35.146585]]]},"properties":{"id":"28325","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.602631,35.983873],[-77.655124,35.940886],[-77.662073,35.865129],[-77.558096,35.791932],[-77.48975,35.696176],[-77.449319,35.805705],[-77.466874,35.820152],[-77.440963,35.816103],[-77.446333,35.808529],[-77.39361,35.875856],[-77.445657,35.964506],[-77.442903,35.971556],[-77.496678,36.037367],[-77.536232,36.056409],[-77.602631,35.983873]]]},"properties":{"id":"27886","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.893748,36.084212],[-77.602631,35.983873],[-77.536232,36.056409],[-77.528096,36.073381],[-77.94465,36.189836],[-77.964609,36.191976],[-77.893748,36.084212]]]},"properties":{"id":"27891","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.352898,35.934093],[-77.339263,36.064681],[-77.496678,36.037367],[-77.442903,35.971556],[-77.43519,35.991027],[-77.4283,35.984348],[-77.445657,35.964506],[-77.39361,35.875856],[-77.352898,35.934093]]]},"properties":{"id":"27843","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.386118,36.109364],[-80.314736,36.19929],[-80.352731,36.216278],[-80.442932,36.183772],[-80.439006,36.138746],[-80.386118,36.109364]]]},"properties":{"id":"27040","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.281904,36.370728],[-80.277767,36.271873],[-80.241346,36.224615],[-80.203517,36.206117],[-80.182888,36.2193],[-80.281904,36.370728]]]},"properties":{"id":"27019","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.342941,36.260656],[-80.425402,36.292061],[-80.452319,36.241222],[-80.442932,36.183772],[-80.352731,36.216278],[-80.342941,36.260656]]]},"properties":{"id":"27050","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.425402,36.292061],[-80.359625,36.378936],[-80.37203,36.436196],[-80.552679,36.345346],[-80.544657,36.284458],[-80.452319,36.241222],[-80.425402,36.292061]]]},"properties":{"id":"27043","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.27708,36.135068],[-80.280668,36.130557],[-80.274145,36.130318],[-80.27708,36.135068]]]},"properties":{"id":"27109","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.424227,36.039854],[-78.562632,36.065418],[-78.389944,35.920139],[-78.333182,35.95159],[-78.424227,36.039854]]]},"properties":{"id":"27596","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.181854,35.292111],[-81.194498,35.232576],[-81.114875,35.261763],[-81.106607,35.282991],[-81.120598,35.308074],[-81.181854,35.292111]]]},"properties":{"id":"28054","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.106607,35.282991],[-81.114875,35.261763],[-81.096849,35.255358],[-81.092238,35.254787],[-81.077594,35.264211],[-81.106607,35.282991]]]},"properties":{"id":"28098","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.728702,36.150212],[-78.791039,36.081521],[-78.748771,36.029926],[-78.719592,36.029435],[-78.57829,36.095541],[-78.599338,36.169175],[-78.672454,36.170213],[-78.728702,36.150212]]]},"properties":{"id":"27522","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.585463,35.472826],[-77.580478,35.48137],[-77.595498,35.478654],[-77.588514,35.473346],[-77.585463,35.472826]]]},"properties":{"id":"28554","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.560994,36.180137],[-77.528096,36.073381],[-77.536232,36.056409],[-77.496678,36.037367],[-77.339263,36.064681],[-77.327686,36.074512],[-77.251855,36.12199],[-77.29181,36.16849],[-77.383795,36.208848],[-77.560994,36.180137]]]},"properties":{"id":"27874","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.890454,36.277263],[-77.94465,36.189836],[-77.528096,36.073381],[-77.560994,36.180137],[-77.707035,36.31267],[-77.890454,36.277263]]]},"properties":{"id":"27823","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.943218,35.287169],[-78.901695,35.272613],[-78.81688,35.290041],[-78.746932,35.33399],[-78.943218,35.287169]]]},"properties":{"id":"28323","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.44484,35.283939],[-82.462116,35.319442],[-82.553317,35.343009],[-82.575707,35.324221],[-82.619513,35.303758],[-82.631177,35.269244],[-82.598858,35.24957],[-82.616631,35.224347],[-82.620358,35.201118],[-82.590677,35.162061],[-82.464694,35.252045],[-82.44484,35.283939]]]},"properties":{"id":"28739","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.553317,35.343009],[-82.462116,35.319442],[-82.497412,35.395685],[-82.532055,35.388672],[-82.552223,35.350123],[-82.553317,35.343009]]]},"properties":{"id":"28791","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.431347,35.290251],[-82.414351,35.271619],[-82.406746,35.278233],[-82.4161,35.296935],[-82.431347,35.290251]]]},"properties":{"id":"28726","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.64235,35.707565],[-80.786727,35.663772],[-80.672271,35.610652],[-80.652894,35.636605],[-80.64235,35.707565]]]},"properties":{"id":"28125","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.075428,35.368771],[-83.078884,35.433708],[-83.109235,35.463901],[-83.207348,35.43643],[-83.335848,35.343264],[-83.339707,35.331004],[-83.242869,35.239139],[-83.075428,35.368771]],[[-83.262467,35.367803],[-83.269807,35.375266],[-83.270811,35.378502],[-83.266148,35.379187],[-83.267948,35.375695],[-83.266711,35.372686],[-83.262467,35.367803]]]},"properties":{"id":"28779","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.140347,35.222927],[-82.964815,35.315181],[-82.990348,35.367643],[-83.02798,35.383166],[-83.075428,35.368771],[-83.242869,35.239139],[-83.209903,35.154261],[-83.186063,35.130057],[-83.1253,35.136723],[-83.140347,35.222927]]]},"properties":{"id":"28723","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.236571,35.508445],[-78.244255,35.510972],[-78.235636,35.507036],[-78.236571,35.508445]]]},"properties":{"id":"27568","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.215274,36.013805],[-77.243997,35.988425],[-77.198362,35.9391],[-77.215274,36.013805]]]},"properties":{"id":"27840","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.876335,35.137719],[-80.880871,35.101757],[-80.870608,35.089328],[-80.825446,35.146843],[-80.836215,35.155925],[-80.875944,35.17272],[-80.876335,35.137719]]]},"properties":{"id":"28210","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.844591,35.213782],[-80.837166,35.202982],[-80.811589,35.202148],[-80.825462,35.227508],[-80.82816,35.225669],[-80.844591,35.213782]]]},"properties":{"id":"28204","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.767587,35.036357],[-80.769836,35.059872],[-80.778741,35.100013],[-80.875291,35.061534],[-80.870582,35.036772],[-80.840297,35.00171],[-80.779816,35.032728],[-80.767587,35.036357]]]},"properties":{"id":"28277","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.843097,35.225469],[-80.844204,35.22471],[-80.843106,35.22373],[-80.84199,35.224511],[-80.843097,35.225469]]]},"properties":{"id":"28244","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.671944,35.61231],[-79.812786,35.510994],[-79.711644,35.432504],[-79.581717,35.525678],[-79.606542,35.591917],[-79.671944,35.61231]]]},"properties":{"id":"27341","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.973526,35.5536],[-80.065626,35.505691],[-80.093272,35.419928],[-80.083904,35.395094],[-80.080208,35.308638],[-79.919839,35.312739],[-79.853594,35.343041],[-79.826025,35.375614],[-79.874167,35.510253],[-79.973526,35.5536]]]},"properties":{"id":"27371","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.583678,35.302153],[-79.437137,35.239629],[-79.396397,35.221346],[-79.32849,35.237017],[-79.303396,35.248381],[-79.271539,35.284689],[-79.286686,35.378898],[-79.389849,35.461484],[-79.563604,35.391516],[-79.583678,35.302153]]]},"properties":{"id":"28327","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.516088,35.08631],[-79.478522,35.057103],[-79.473295,35.050523],[-79.516404,35.091585],[-79.516088,35.08631]]]},"properties":{"id":"28373","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.066789,35.776453],[-78.012679,35.855261],[-78.12772,35.861411],[-78.148251,35.741986],[-78.066789,35.776453]]]},"properties":{"id":"27807","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.148251,35.741986],[-78.170099,35.715315],[-78.095367,35.686259],[-78.061088,35.697602],[-78.028239,35.761888],[-78.066789,35.776453],[-78.148251,35.741986]]]},"properties":{"id":"27880","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.871808,34.318432],[-77.785489,34.247618],[-77.770721,34.237204],[-77.723111,34.320015],[-77.804079,34.367049],[-77.815179,34.367028],[-77.871808,34.318432]]]},"properties":{"id":"28411","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.306561,36.342337],[-77.413357,36.279923],[-77.383795,36.208848],[-77.29181,36.16849],[-77.29223,36.168674],[-77.20879,36.246875],[-77.206736,36.251085],[-77.306561,36.342337]]]},"properties":{"id":"27869","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.392987,34.744915],[-77.353595,34.734655],[-77.387048,34.74402],[-77.392987,34.744915]]]},"properties":{"id":"28543","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.075555,36.017264],[-79.057435,35.893462],[-79.001737,35.949549],[-79.02735,35.986298],[-79.036123,36.038461],[-79.075555,36.017264]]]},"properties":{"id":"27514","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.041824,34.457133],[-77.793021,34.590631],[-77.696289,34.6273],[-77.735208,34.656237],[-77.906768,34.658932],[-78.110208,34.552118],[-78.129011,34.504891],[-78.041824,34.457133]]]},"properties":{"id":"28425","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.804079,34.367049],[-77.741684,34.47304],[-77.793021,34.590631],[-78.041824,34.457133],[-77.969679,34.378033],[-77.815179,34.367028],[-77.804079,34.367049]]]},"properties":{"id":"28457","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.349563,35.691855],[-77.191696,35.739161],[-77.334031,35.710319],[-77.349563,35.691855]]]},"properties":{"id":"27884","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.38561,35.556285],[-77.323459,35.471299],[-77.277023,35.430043],[-77.282527,35.575497],[-77.273453,35.579069],[-77.263114,35.597819],[-77.38561,35.556285]]]},"properties":{"id":"27858","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.937675,36.407779],[-80.028713,36.507058],[-80.029823,36.461778],[-79.937675,36.407779]]]},"properties":{"id":"27027","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.921539,35.335746],[-81.871526,35.210015],[-81.810039,35.2598],[-81.796349,35.271986],[-81.798111,35.27933],[-81.80591,35.315067],[-81.884642,35.436109],[-81.921547,35.383908],[-81.921539,35.335746]]]},"properties":{"id":"28043","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.794196,35.280997],[-81.792736,35.282799],[-81.786099,35.28353],[-81.796456,35.285868],[-81.794196,35.280997]]]},"properties":{"id":"28019","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.536299,34.766115],[-79.634336,34.771107],[-79.57346,34.721521],[-79.536299,34.766115]]]},"properties":{"id":"28343","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.634336,34.771107],[-79.536299,34.766115],[-79.471579,34.899026],[-79.561826,34.910363],[-79.667175,34.79806],[-79.634336,34.771107]]]},"properties":{"id":"28351","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.295453,35.486022],[-80.274142,35.503052],[-80.295236,35.502947],[-80.295453,35.486022]]]},"properties":{"id":"28109","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.475334,35.908791],[-76.380919,35.790939],[-76.366304,35.936618],[-76.475334,35.908791]]]},"properties":{"id":"27928","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.783438,35.701156],[-78.813629,35.726217],[-78.900406,35.68281],[-78.917511,35.630104],[-78.915426,35.624041],[-78.767623,35.651008],[-78.71797,35.656058],[-78.703843,35.691152],[-78.783438,35.701156]]]},"properties":{"id":"27539","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.67911,35.875901],[-78.740845,35.884445],[-78.75945,35.891893],[-78.769103,35.861052],[-78.668746,35.820207],[-78.664193,35.821312],[-78.651074,35.870772],[-78.67911,35.875901]]]},"properties":{"id":"27612","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.566583,36.272414],[-81.545603,36.16353],[-81.510785,36.136712],[-81.447151,36.189477],[-81.477298,36.240272],[-81.552221,36.27629],[-81.566583,36.272414]]]},"properties":{"id":"28618","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.786241,36.21684],[-81.74752,36.183411],[-81.746019,36.271857],[-81.791069,36.352016],[-81.889085,36.308832],[-81.786241,36.21684]]]},"properties":{"id":"28692","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.103934,35.280512],[-77.968397,35.230947],[-77.939418,35.268044],[-77.970588,35.321634],[-77.994401,35.337896],[-78.103934,35.280512]]]},"properties":{"id":"28333","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.028239,35.761888],[-78.061088,35.697602],[-77.953376,35.606013],[-77.913146,35.604075],[-77.783807,35.651941],[-77.780879,35.657421],[-77.768721,35.713253],[-77.906041,35.749157],[-78.028239,35.761888]],[[-77.938885,35.615133],[-77.940838,35.640291],[-77.927118,35.640966],[-77.938885,35.615133]]]},"properties":{"id":"27893","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.42486,35.985232],[-79.496279,35.968485],[-79.432778,35.843749],[-79.375634,35.829913],[-79.326124,35.844075],[-79.42486,35.985232]]]},"properties":{"id":"27349","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.487445,36.040121],[-79.48555,36.035886],[-79.487344,36.030442],[-79.483263,36.030075],[-79.48194,36.035222],[-79.487445,36.040121]]]},"properties":{"id":"27201","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.402475,35.724135],[-81.266864,35.723573],[-81.2281,35.810388],[-81.337777,35.84479],[-81.408434,35.76527],[-81.43631,35.773342],[-81.450224,35.78522],[-81.421986,35.732118],[-81.402475,35.724135]]]},"properties":{"id":"28601","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.004946,36.494136],[-81.016831,36.396844],[-80.967953,36.456541],[-80.946588,36.478456],[-81.004946,36.494136]]]},"properties":{"id":"28627","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.016831,36.396844],[-81.043865,36.398336],[-80.984004,36.376807],[-80.949396,36.421828],[-80.967953,36.456541],[-81.016831,36.396844]]]},"properties":{"id":"28668","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.968896,34.807476],[-79.968011,34.821765],[-79.976906,34.822083],[-79.97257,34.81473],[-79.972551,34.807575],[-79.968896,34.807476]]]},"properties":{"id":"28102","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.380529,36.345205],[-81.48391,36.458565],[-81.492722,36.45877],[-81.565409,36.431822],[-81.586682,36.407376],[-81.539829,36.330191],[-81.434907,36.264601],[-81.378809,36.277983],[-81.361682,36.313399],[-81.380529,36.345205]]]},"properties":{"id":"28694","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.552221,36.27629],[-81.477298,36.240272],[-81.434907,36.264601],[-81.539829,36.330191],[-81.552221,36.27629]]]},"properties":{"id":"28626","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.3244,36.499653],[-81.353221,36.545824],[-81.410048,36.51874],[-81.436317,36.460759],[-81.361922,36.435038],[-81.337056,36.473875],[-81.3244,36.499653]]]},"properties":{"id":"28617","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.91314,36.033231],[-81.937578,36.022393],[-81.907131,36.010453],[-81.91314,36.033231]]]},"properties":{"id":"28616","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.786241,36.21684],[-81.889085,36.308832],[-81.920755,36.283162],[-81.897002,36.261763],[-81.786241,36.21684]]]},"properties":{"id":"28679","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.008261,35.499164],[-76.995716,35.319388],[-76.942749,35.288554],[-76.910016,35.323907],[-76.8649,35.35952],[-76.822215,35.425656],[-76.830556,35.42815],[-77.008261,35.499164]]]},"properties":{"id":"27814","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.166695,35.725023],[-77.263114,35.597819],[-77.100801,35.551168],[-77.008261,35.499164],[-76.830556,35.42815],[-76.844449,35.513839],[-76.90593,35.683069],[-77.034767,35.67938],[-77.156937,35.737056],[-77.166695,35.725023]]]},"properties":{"id":"27889","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.732959,35.531715],[-76.666373,35.378284],[-76.590497,35.361857],[-76.521864,35.353593],[-76.439888,35.543399],[-76.320637,35.576325],[-76.320454,35.576651],[-76.539998,35.625835],[-76.655185,35.550139],[-76.732959,35.531715]]]},"properties":{"id":"27810","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.648928,35.718153],[-76.791825,35.705397],[-76.655185,35.550139],[-76.539998,35.625835],[-76.592294,35.791173],[-76.648928,35.718153]]]},"properties":{"id":"27860","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.124062,34.58947],[-78.178756,34.649884],[-78.428386,34.630992],[-78.200392,34.474799],[-78.124062,34.58947]]]},"properties":{"id":"28447","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.041712,34.756598],[-78.997424,34.729334],[-78.830345,34.713967],[-78.844249,34.821238],[-78.886982,34.84794],[-78.909792,34.869333],[-79.003182,34.869517],[-79.07248,34.854669],[-79.041712,34.756598]]]},"properties":{"id":"28384","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-78.521739,33.997253],[-78.536029,33.993959],[-78.513156,33.98292],[-78.487546,33.987381],[-78.521739,33.997253]]],[[[-78.384416,33.903197],[-78.51212,33.977614],[-78.533487,33.949838],[-78.484909,33.873206],[-78.384416,33.903197]]]]},"properties":{"id":"28469","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.664532,35.597548],[-82.616506,35.494086],[-82.592192,35.500144],[-82.563903,35.568568],[-82.576826,35.615593],[-82.626781,35.642891],[-82.647005,35.646138],[-82.664532,35.597548]]]},"properties":{"id":"28806","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.532055,35.388672],[-82.560264,35.43134],[-82.601002,35.416114],[-82.654762,35.336021],[-82.647037,35.328709],[-82.605253,35.344917],[-82.603613,35.34575],[-82.552223,35.350123],[-82.532055,35.388672]]]},"properties":{"id":"28759","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.601945,35.748623],[-81.550258,35.69002],[-81.527702,35.741551],[-81.532893,35.749153],[-81.533872,35.779705],[-81.547039,35.777186],[-81.594904,35.751351],[-81.601945,35.748623]]]},"properties":{"id":"28690","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.545509,35.515763],[-80.603764,35.53905],[-80.614289,35.539909],[-80.62046,35.465305],[-80.513364,35.485888],[-80.545509,35.515763]]]},"properties":{"id":"28083","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.691678,34.871655],[-76.674288,34.747782],[-76.692721,34.715555],[-76.681779,34.703645],[-76.554176,34.717396],[-76.532564,34.762082],[-76.532481,34.788613],[-76.50003,34.836686],[-76.400601,34.891679],[-76.383217,34.898112],[-76.36413,34.926567],[-76.382695,35.042237],[-76.491985,35.10178],[-76.551944,35.069857],[-76.711278,34.981971],[-76.691678,34.871655]]]},"properties":{"id":"28516","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.528998,34.735637],[-76.509552,34.714953],[-76.479413,34.736614],[-76.496424,34.777431],[-76.532481,34.788613],[-76.532564,34.762082],[-76.528998,34.735637]]]},"properties":{"id":"28579","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.528998,34.735637],[-76.532564,34.762082],[-76.554176,34.717396],[-76.522262,34.71537],[-76.528998,34.735637]]]},"properties":{"id":"28528","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.148837,36.342322],[-79.113153,36.373618],[-79.097467,36.460201],[-79.182488,36.449701],[-79.232092,36.405058],[-79.243123,36.328717],[-79.206037,36.350392],[-79.148837,36.342322]]]},"properties":{"id":"27291","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.111861,36.541639],[-79.343001,36.541367],[-79.326224,36.525565],[-79.217587,36.480356],[-79.111861,36.541639]]]},"properties":{"id":"27305","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.929614,35.828816],[-78.963619,35.83003],[-79.052398,35.757447],[-79.024363,35.737387],[-78.821359,35.74359],[-78.828437,35.760051],[-78.844027,35.762838],[-78.929614,35.828816]]]},"properties":{"id":"27523","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.210845,35.635676],[-78.969761,35.521433],[-78.968772,35.522528],[-78.932594,35.562211],[-78.963408,35.565584],[-79.071804,35.658958],[-79.210845,35.635676]]]},"properties":{"id":"27559","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.926848,35.066997],[-84.005403,34.987218],[-83.972661,34.987351],[-83.898335,35.031543],[-83.926848,35.066997]]]},"properties":{"id":"28902","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.474568,35.389163],[-81.432858,35.351396],[-81.436425,35.358519],[-81.43105,35.365317],[-81.421956,35.356757],[-81.409567,35.338092],[-81.350114,35.348603],[-81.310038,35.375837],[-81.378749,35.441573],[-81.397961,35.470444],[-81.47505,35.484757],[-81.474568,35.389163]]]},"properties":{"id":"28021","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.421956,35.356757],[-81.43105,35.365317],[-81.436425,35.358519],[-81.432858,35.351396],[-81.421956,35.356757]]]},"properties":{"id":"28169","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.896514,34.981764],[-76.948484,34.929826],[-77.108813,34.887572],[-77.090323,34.802755],[-76.957621,34.810692],[-76.691678,34.871655],[-76.711278,34.981971],[-76.737553,34.966778],[-76.867977,34.970565],[-76.896514,34.981764]],[[-76.897701,34.906459],[-76.893642,34.903024],[-76.905079,34.893767],[-76.905672,34.90849],[-76.897701,34.906459]]]},"properties":{"id":"28532","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.891944,36.253775],[-75.833382,36.189854],[-75.813189,36.223544],[-75.852777,36.289633],[-75.891944,36.253775]]]},"properties":{"id":"27939","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.802799,36.100239],[-75.840605,36.097425],[-75.86972,36.125171],[-75.764027,36.06206],[-75.76667,36.086627],[-75.802799,36.100239]]]},"properties":{"id":"27964","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.276085,35.985768],[-80.232349,35.957584],[-80.230402,36.08686],[-80.250753,36.075572],[-80.354583,36.016167],[-80.276085,35.985768]]]},"properties":{"id":"27127","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.097225,34.767608],[-77.939633,34.802836],[-77.872908,34.816112],[-77.895913,34.885321],[-77.907069,34.891045],[-78.241134,34.841035],[-78.313637,34.805101],[-78.284381,34.758861],[-78.193852,34.755661],[-78.097225,34.767608]]]},"properties":{"id":"28458","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.289282,35.15881],[-78.222272,35.045411],[-78.15087,35.044366],[-78.022858,35.08883],[-78.261423,35.203683],[-78.289282,35.15881]]]},"properties":{"id":"28341","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.502727,34.9696],[-77.624709,35.016862],[-77.67783,34.968599],[-77.63661,34.834425],[-77.708291,34.758407],[-77.680353,34.725794],[-77.595629,34.730338],[-77.471147,34.893234],[-77.502727,34.9696]]]},"properties":{"id":"28574","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.901505,35.04367],[-77.846101,35.025098],[-77.772411,35.089809],[-77.767168,35.143915],[-77.867365,35.137053],[-77.901505,35.04367]]]},"properties":{"id":"28508","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.826133,35.871406],[-77.850928,35.865806],[-77.827446,35.857006],[-77.812616,35.85924],[-77.826133,35.871406]]]},"properties":{"id":"27878","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.314736,36.19929],[-80.386118,36.109364],[-80.373359,36.096434],[-80.261809,36.120508],[-80.309088,36.202514],[-80.314736,36.19929]],[[-80.27708,36.135068],[-80.274145,36.130318],[-80.280668,36.130557],[-80.27708,36.135068]]]},"properties":{"id":"27106","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.241346,36.224615],[-80.309088,36.202514],[-80.261809,36.120508],[-80.262756,36.102633],[-80.175445,36.141539],[-80.203517,36.206117],[-80.241346,36.224615]]]},"properties":{"id":"27105","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.26461,36.092655],[-80.365246,36.073596],[-80.354583,36.016167],[-80.250753,36.075572],[-80.26461,36.092655]]]},"properties":{"id":"27103","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.208852,35.938559],[-78.222045,35.86316],[-78.12772,35.861411],[-78.012679,35.855261],[-77.972099,35.848768],[-77.990106,35.869797],[-78.064342,36.022576],[-78.141197,36.060345],[-78.208852,35.938559]]]},"properties":{"id":"27882","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.333182,35.95159],[-78.389944,35.920139],[-78.415643,35.916113],[-78.422372,35.910218],[-78.449771,35.869345],[-78.341367,35.690702],[-78.336329,35.681755],[-78.259919,35.667883],[-78.222045,35.86316],[-78.208852,35.938559],[-78.272085,35.932518],[-78.333182,35.95159]]]},"properties":{"id":"27597","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.541599,36.550475],[-76.670852,36.550803],[-76.636123,36.469548],[-76.525013,36.537271],[-76.541599,36.550475]]]},"properties":{"id":"27926","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.606885,35.36459],[-83.585544,35.434867],[-83.766657,35.443436],[-83.819424,35.421272],[-83.86589,35.448292],[-84.02911,35.292183],[-83.96711,35.217962],[-83.893569,35.233282],[-83.773499,35.245759],[-83.680043,35.279414],[-83.606885,35.36459]]]},"properties":{"id":"28771","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.791039,36.081521],[-78.728702,36.150212],[-78.803242,36.179065],[-78.804983,36.091883],[-78.791039,36.081521]]]},"properties":{"id":"27509","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.50534,35.460624],[-77.260977,35.351731],[-77.243681,35.407779],[-77.277023,35.430043],[-77.323459,35.471299],[-77.504657,35.527711],[-77.522704,35.52509],[-77.530583,35.518517],[-77.558024,35.461528],[-77.50534,35.460624]]]},"properties":{"id":"28513","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.710339,35.966563],[-79.796191,35.919553],[-79.723868,35.863114],[-79.710339,35.966563]]]},"properties":{"id":"27313","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.955223,35.997989],[-79.9384,35.959271],[-79.903375,35.942095],[-79.905911,36.045713],[-79.943148,36.044194],[-79.955223,35.997989]]]},"properties":{"id":"27282","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.728897,36.379329],[-77.707035,36.31267],[-77.560994,36.180137],[-77.383795,36.208848],[-77.413357,36.279923],[-77.470789,36.320176],[-77.546007,36.383751],[-77.622078,36.40828],[-77.728897,36.379329]]]},"properties":{"id":"27839","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.180144,35.518821],[-83.224757,35.443033],[-83.207348,35.43643],[-83.109235,35.463901],[-83.028955,35.525948],[-83.156448,35.551971],[-83.180144,35.518821]]]},"properties":{"id":"28751","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.339528,35.493715],[-82.364639,35.460883],[-82.338653,35.464101],[-82.339528,35.493715]]]},"properties":{"id":"28735","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.956978,36.43629],[-76.98536,36.424588],[-76.926154,36.368063],[-76.845178,36.374906],[-76.946593,36.431075],[-76.956978,36.43629]]]},"properties":{"id":"27986","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.794503,36.359915],[-76.83329,36.268968],[-76.706556,36.243869],[-76.696601,36.296211],[-76.775555,36.360609],[-76.794503,36.359915]]]},"properties":{"id":"27942","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.210339,34.923937],[-79.085277,34.746291],[-79.041712,34.756598],[-79.07248,34.854669],[-79.153739,34.929462],[-79.210339,34.923937]]]},"properties":{"id":"28386","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.210169,34.762499],[-79.349143,34.838476],[-79.351821,34.831226],[-79.461754,34.630432],[-79.414003,34.591475],[-79.243198,34.657954],[-79.210169,34.762499]]]},"properties":{"id":"28364","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.927961,36.020288],[-80.808405,35.913029],[-80.858863,36.010416],[-80.927961,36.020288]]]},"properties":{"id":"28660","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.140347,35.222927],[-83.1253,35.136723],[-83.065345,35.130409],[-83.023786,35.162644],[-82.991701,35.199449],[-83.140347,35.222927]]]},"properties":{"id":"28736","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.222045,35.86316],[-78.259919,35.667883],[-78.244386,35.67417],[-78.170099,35.715315],[-78.148251,35.741986],[-78.12772,35.861411],[-78.222045,35.86316]]]},"properties":{"id":"27557","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.235636,35.507036],[-78.231777,35.480579],[-78.28384,35.408201],[-78.215298,35.341225],[-78.093993,35.466322],[-78.096104,35.526878],[-78.158847,35.519905],[-78.236571,35.508445],[-78.235636,35.507036]]]},"properties":{"id":"27569","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.105296,35.498406],[-81.178112,35.409481],[-81.129735,35.407769],[-81.061011,35.415283],[-81.033612,35.454194],[-81.105296,35.498406]]]},"properties":{"id":"28080","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.075155,35.84833],[-82.114147,35.834006],[-82.133182,35.824446],[-82.192635,35.736392],[-82.155053,35.520351],[-82.12789,35.523736],[-81.969928,35.525166],[-81.919242,35.848759],[-81.912772,35.900058],[-81.95752,35.946677],[-81.981723,35.91152],[-82.075155,35.84833]]]},"properties":{"id":"28752","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.075155,35.84833],[-82.10034,35.85255],[-82.114147,35.834006],[-82.075155,35.84833]]]},"properties":{"id":"28749","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.969928,35.525166],[-82.12789,35.523736],[-82.092175,35.494055],[-81.855665,35.49204],[-81.824184,35.574955],[-81.829611,35.592019],[-81.969928,35.525166]]]},"properties":{"id":"28167","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.855789,35.235923],[-80.896875,35.276785],[-80.961266,35.223806],[-80.959034,35.193626],[-80.882985,35.207836],[-80.85463,35.224383],[-80.855789,35.235923]]]},"properties":{"id":"28208","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.812522,35.19951],[-80.776402,35.182152],[-80.748699,35.224333],[-80.780009,35.253838],[-80.791124,35.251456],[-80.825462,35.227508],[-80.811589,35.202148],[-80.812522,35.19951]]]},"properties":{"id":"28205","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.033195,35.148685],[-80.989166,35.074734],[-80.960868,35.171999],[-80.959034,35.193626],[-80.961266,35.223806],[-81.006244,35.210362],[-81.033195,35.148685]]]},"properties":{"id":"28278","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.639991,35.140736],[-80.767587,35.036357],[-80.779816,35.032728],[-80.682071,34.99437],[-80.670138,35.019136],[-80.599683,35.164005],[-80.639991,35.140736]]]},"properties":{"id":"28104","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.838393,35.196276],[-80.829193,35.1799],[-80.812522,35.19951],[-80.811589,35.202148],[-80.837166,35.202982],[-80.838393,35.196276]]]},"properties":{"id":"28207","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.844204,35.22471],[-80.845304,35.225653],[-80.846379,35.22492],[-80.845254,35.223974],[-80.844204,35.22471]]]},"properties":{"id":"28282","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.843097,35.225469],[-80.841973,35.226243],[-80.843083,35.2272],[-80.844207,35.226414],[-80.843097,35.225469]]]},"properties":{"id":"28280","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.267735,36.014438],[-82.245153,36.131011],[-82.416708,36.07278],[-82.267735,36.014438]]]},"properties":{"id":"28740","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.906041,35.749157],[-77.972099,35.848768],[-78.012679,35.855261],[-78.066789,35.776453],[-78.028239,35.761888],[-77.906041,35.749157]]]},"properties":{"id":"27896","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.948309,33.969873],[-77.937053,33.928089],[-77.89892,34.01356],[-77.933953,34.017108],[-77.948309,33.969873]]]},"properties":{"id":"28449","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.400838,36.54476],[-77.302159,36.471463],[-77.198603,36.545282],[-77.400838,36.54476]]]},"properties":{"id":"27853","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.343779,34.72761],[-77.273885,34.7074],[-77.288617,34.758164],[-77.353595,34.734655],[-77.343779,34.72761]]]},"properties":{"id":"28544","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.47777,34.867807],[-77.387048,34.74402],[-77.353595,34.734655],[-77.288617,34.758164],[-77.263672,34.77498],[-77.339245,34.842672],[-77.47777,34.867807]]]},"properties":{"id":"28546","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.819125,35.161716],[-76.854931,35.163581],[-76.896514,34.981764],[-76.867977,34.970565],[-76.828004,35.044127],[-76.811879,35.09877],[-76.819125,35.161716]]]},"properties":{"id":"28529","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.828004,35.044127],[-76.737553,34.966778],[-76.711278,34.981971],[-76.551944,35.069857],[-76.804217,35.1033],[-76.811879,35.09877],[-76.828004,35.044127]]]},"properties":{"id":"28571","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.737553,34.966778],[-76.828004,35.044127],[-76.867977,34.970565],[-76.737553,34.966778]]]},"properties":{"id":"28510","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.124062,34.58947],[-78.200392,34.474799],[-78.143678,34.445295],[-78.129011,34.504891],[-78.110208,34.552118],[-78.124062,34.58947]]]},"properties":{"id":"28421","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.964531,36.542138],[-79.097467,36.460201],[-79.113153,36.373618],[-79.010746,36.290563],[-78.835303,36.371018],[-78.7963,36.541713],[-78.964531,36.542138]],[[-78.937313,36.419161],[-78.977024,36.369801],[-79.0132,36.371741],[-78.989464,36.429293],[-78.937313,36.419161]]]},"properties":{"id":"27574","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.117025,35.28539],[-82.204606,35.288674],[-82.243471,35.254562],[-82.117025,35.28539]]],[[[-81.998496,35.229466],[-82.138954,35.193123],[-82.00171,35.1885],[-81.998496,35.229466]]]]},"properties":{"id":"28722","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.151535,35.407581],[-82.073288,35.277827],[-81.998496,35.229466],[-82.00171,35.1885],[-81.875338,35.183942],[-81.871526,35.210015],[-81.921539,35.335746],[-81.943606,35.354842],[-81.921547,35.383908],[-81.884642,35.436109],[-81.855665,35.49204],[-82.092175,35.494055],[-82.151535,35.407581]]]},"properties":{"id":"28139","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.977088,35.712134],[-79.973526,35.5536],[-79.874167,35.510253],[-79.812786,35.510994],[-79.671944,35.61231],[-79.676759,35.710792],[-79.713601,35.724224],[-79.827768,35.763123],[-79.853808,35.760206],[-79.9435,35.790715],[-79.977088,35.712134]]]},"properties":{"id":"27205","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.824867,34.903288],[-79.815972,34.90697],[-79.825559,34.913341],[-79.824867,34.903288]]]},"properties":{"id":"28330","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.041456,34.481461],[-79.024716,34.534697],[-79.151859,34.576736],[-79.156631,34.58135],[-79.275105,34.472299],[-79.108868,34.33088],[-79.041399,34.477361],[-79.041456,34.481461]]]},"properties":{"id":"28340","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.108868,34.33088],[-79.071169,34.29924],[-79.034463,34.322547],[-78.947283,34.449382],[-79.024716,34.534697],[-79.041456,34.481461],[-79.034573,34.481469],[-79.037795,34.468818],[-79.041984,34.469658],[-79.041399,34.477361],[-79.108868,34.33088]]]},"properties":{"id":"28369","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.142539,34.725036],[-79.210169,34.762499],[-79.243198,34.657954],[-79.179953,34.641751],[-79.142539,34.725036]]]},"properties":{"id":"28372","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.45091,35.688408],[-80.425955,35.689301],[-80.418943,35.706039],[-80.445327,35.699511],[-80.45091,35.688408]]]},"properties":{"id":"28159","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.603764,35.53905],[-80.609732,35.558269],[-80.634787,35.539897],[-80.614289,35.539909],[-80.603764,35.53905]]]},"properties":{"id":"28088","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.884642,35.436109],[-81.80591,35.315067],[-81.719527,35.511551],[-81.692329,35.579789],[-81.824184,35.574955],[-81.855665,35.49204],[-81.884642,35.436109]]]},"properties":{"id":"28018","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.846734,36.475902],[-80.642591,36.371259],[-80.570829,36.428285],[-80.488189,36.485996],[-80.382614,36.547877],[-80.724383,36.562319],[-80.846734,36.475902]]]},"properties":{"id":"27030","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.949396,36.421828],[-80.840647,36.396974],[-80.722703,36.2696],[-80.631328,36.285822],[-80.605798,36.335045],[-80.642591,36.371259],[-80.846734,36.475902],[-80.927059,36.501106],[-80.946588,36.478456],[-80.967953,36.456541],[-80.949396,36.421828]]]},"properties":{"id":"27017","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.582346,35.434102],[-83.606885,35.36459],[-83.680043,35.279414],[-83.580775,35.290901],[-83.339707,35.331004],[-83.335848,35.343264],[-83.371641,35.455937],[-83.477968,35.449578],[-83.582346,35.434102]]]},"properties":{"id":"28713","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.670138,35.019136],[-80.682071,34.99437],[-80.643937,34.950668],[-80.472347,34.982111],[-80.431135,35.055672],[-80.396255,35.1656],[-80.503541,35.181783],[-80.533356,35.189956],[-80.670138,35.019136]]]},"properties":{"id":"28110","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.668746,35.820207],[-78.660208,35.799844],[-78.642481,35.796678],[-78.625206,35.804513],[-78.619673,35.813902],[-78.664193,35.821312],[-78.668746,35.820207]]]},"properties":{"id":"27608","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.607056,36.208014],[-80.508245,36.103412],[-80.439006,36.138746],[-80.442932,36.183772],[-80.452319,36.241222],[-80.544657,36.284458],[-80.562185,36.282455],[-80.607056,36.208014]]]},"properties":{"id":"27018","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.534687,36.087604],[-79.546651,36.079197],[-79.585332,35.997723],[-79.568079,35.994121],[-79.496279,35.968485],[-79.42486,35.985232],[-79.413041,36.073641],[-79.483886,36.107197],[-79.534687,36.087604]],[[-79.487445,36.040121],[-79.48194,36.035222],[-79.483263,36.030075],[-79.487344,36.030442],[-79.48555,36.035886],[-79.487445,36.040121]]]},"properties":{"id":"27215","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.321776,35.946828],[-79.319043,35.945692],[-79.315825,35.942951],[-79.315461,35.946045],[-79.317555,35.947054],[-79.321776,35.946828]]]},"properties":{"id":"27340","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.099727,36.104763],[-81.238413,36.096687],[-81.260274,36.025893],[-81.057461,36.031429],[-81.040455,36.060607],[-81.099727,36.104763]]]},"properties":{"id":"28654","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.353221,36.545824],[-81.3244,36.499653],[-81.3074,36.480707],[-81.296377,36.506833],[-81.27058,36.574001],[-81.324425,36.575362],[-81.353221,36.545824]]]},"properties":{"id":"28663","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.986559,35.088769],[-79.967346,34.903986],[-79.909083,34.838859],[-79.88387,35.051628],[-79.912026,35.093657],[-79.986559,35.088769]]]},"properties":{"id":"28091","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.492722,36.45877],[-81.629378,36.514713],[-81.565409,36.431822],[-81.492722,36.45877]]]},"properties":{"id":"28693","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.3244,36.499653],[-81.337056,36.473875],[-81.3074,36.480707],[-81.3244,36.499653]]]},"properties":{"id":"28672","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.349563,35.691855],[-77.449319,35.805705],[-77.48975,35.696176],[-77.530303,35.722583],[-77.513298,35.698202],[-77.508037,35.696282],[-77.511677,35.69415],[-77.551889,35.638027],[-77.522704,35.52509],[-77.504657,35.527711],[-77.38561,35.556285],[-77.263114,35.597819],[-77.166695,35.725023],[-77.191696,35.739161],[-77.349563,35.691855]]]},"properties":{"id":"27834","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.034767,35.67938],[-76.903262,35.825762],[-77.172318,35.92912],[-77.187162,35.910745],[-77.184272,35.830983],[-77.183182,35.836087],[-77.167392,35.838242],[-77.177616,35.82766],[-77.156937,35.737056],[-77.034767,35.67938]]]},"properties":{"id":"27892","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.132074,36.328512],[-76.964162,36.120821],[-76.922104,36.145071],[-76.938224,36.222101],[-76.877125,36.242849],[-76.926154,36.368063],[-76.98536,36.424588],[-77.159483,36.365771],[-77.132074,36.328512]]]},"properties":{"id":"27910","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.284381,34.758861],[-78.464067,34.678091],[-78.44693,34.610063],[-78.428386,34.630992],[-78.178756,34.649884],[-78.153163,34.721957],[-78.193852,34.755661],[-78.284381,34.758861]]]},"properties":{"id":"28444","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.796602,34.436492],[-78.585938,34.394838],[-78.54536,34.416816],[-78.505914,34.566912],[-78.652692,34.580697],[-78.796602,34.436492]]]},"properties":{"id":"28433","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.436231,34.548819],[-78.505914,34.566912],[-78.54536,34.416816],[-78.490798,34.394063],[-78.382122,34.38896],[-78.3689,34.474342],[-78.436231,34.548819]]]},"properties":{"id":"28434","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.382122,34.38896],[-78.236882,34.32683],[-78.195202,34.30653],[-78.078669,34.339233],[-78.256316,34.399763],[-78.3689,34.474342],[-78.382122,34.38896]]]},"properties":{"id":"28456","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.336373,34.279583],[-78.236882,34.32683],[-78.382122,34.38896],[-78.490798,34.394063],[-78.459541,34.28306],[-78.391606,34.174003],[-78.365406,34.205827],[-78.336373,34.279583]]]},"properties":{"id":"28423","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.198978,34.300725],[-78.225097,34.13602],[-78.197574,34.107775],[-77.959505,34.201889],[-78.029922,34.331768],[-78.078669,34.339233],[-78.195202,34.30653],[-78.198978,34.300725]]]},"properties":{"id":"28451","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.235858,33.914454],[-78.016695,33.883883],[-78.153302,33.927301],[-78.223612,33.92441],[-78.235858,33.914454]]]},"properties":{"id":"28465","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.460812,35.809827],[-82.599715,35.783658],[-82.627385,35.755002],[-82.614727,35.694714],[-82.47814,35.664289],[-82.425903,35.678405],[-82.398741,35.698187],[-82.460812,35.809827]]]},"properties":{"id":"28787","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.544475,35.583844],[-82.563903,35.568568],[-82.592192,35.500144],[-82.487649,35.485879],[-82.460147,35.512261],[-82.452163,35.556435],[-82.544475,35.583844]]]},"properties":{"id":"28803","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.497412,35.395685],[-82.380363,35.463413],[-82.460147,35.512261],[-82.487649,35.485879],[-82.560264,35.43134],[-82.532055,35.388672],[-82.497412,35.395685]]]},"properties":{"id":"28732","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.297546,35.652835],[-82.313606,35.657549],[-82.318644,35.639217],[-82.297546,35.652835]]]},"properties":{"id":"28757","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.443242,35.711324],[-81.402475,35.724135],[-81.421986,35.732118],[-81.443242,35.711324]]]},"properties":{"id":"28637","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.78962,35.721848],[-81.78362,35.726006],[-81.775286,35.725477],[-81.773223,35.728443],[-81.779168,35.732861],[-81.782932,35.728074],[-81.799664,35.720567],[-81.796651,35.717769],[-81.78962,35.721848]]]},"properties":{"id":"28628","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.034872,36.167598],[-75.887173,36.158145],[-75.887997,36.163348],[-75.971076,36.308359],[-76.045965,36.358339],[-76.068178,36.276362],[-76.13994,36.274498],[-76.034872,36.167598]]]},"properties":{"id":"27974","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.18118,34.786995],[-77.169216,34.722945],[-77.131738,34.798126],[-77.18118,34.786995]]]},"properties":{"id":"28582","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.867243,34.722599],[-76.912322,34.716423],[-76.910524,34.682325],[-76.866675,34.688369],[-76.867243,34.722599]]]},"properties":{"id":"28575","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.483862,34.839347],[-76.496424,34.777431],[-76.479413,34.736614],[-76.417192,34.79876],[-76.483862,34.839347]]]},"properties":{"id":"28524","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.36413,34.926567],[-76.287097,34.898137],[-76.382695,35.042237],[-76.36413,34.926567]]]},"properties":{"id":"28520","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.525046,34.692654],[-76.563573,34.711752],[-76.57837,34.695903],[-76.535401,34.685623],[-76.525046,34.692654]]]},"properties":{"id":"28531","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.788096,36.427005],[-79.884182,36.310221],[-79.783941,36.248465],[-79.757725,36.247734],[-79.563307,36.251032],[-79.519059,36.278488],[-79.415612,36.315292],[-79.44088,36.366222],[-79.647123,36.448861],[-79.788096,36.427005]]]},"properties":{"id":"27320","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.343001,36.541367],[-79.411939,36.541357],[-79.393463,36.450774],[-79.365963,36.488079],[-79.326224,36.525565],[-79.343001,36.541367]]]},"properties":{"id":"27315","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.960653,35.660041],[-81.075455,35.603403],[-81.046133,35.577212],[-80.960029,35.54781],[-80.959954,35.548],[-80.991472,35.582381],[-80.942447,35.605179],[-80.928408,35.624083],[-80.943656,35.637643],[-80.960653,35.660041]]]},"properties":{"id":"28673","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.942447,35.605179],[-80.991472,35.582381],[-80.959954,35.548],[-80.942447,35.605179]]]},"properties":{"id":"28682","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.47401,35.235294],[-81.543302,35.171691],[-81.406559,35.166367],[-81.47401,35.235294]]]},"properties":{"id":"28073","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.786279,34.17729],[-78.705151,34.183128],[-78.545001,34.140541],[-78.494928,34.166772],[-78.548381,34.236203],[-78.552969,34.234206],[-78.677141,34.301534],[-78.57602,34.385833],[-78.585938,34.394838],[-78.796602,34.436492],[-78.848246,34.435641],[-78.856247,34.416192],[-78.796343,34.203474],[-78.786279,34.17729]],[[-78.696682,34.292162],[-78.699361,34.289076],[-78.703698,34.290442],[-78.69879,34.295052],[-78.696682,34.292162]]]},"properties":{"id":"28472","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.969481,34.241587],[-78.881612,34.243765],[-78.921005,34.359721],[-78.979848,34.355152],[-78.969481,34.241587]]]},"properties":{"id":"28430","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.714045,34.084947],[-78.616963,34.023213],[-78.545001,34.140541],[-78.705151,34.183128],[-78.714045,34.084947]]]},"properties":{"id":"28455","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.106672,34.933832],[-77.221446,35.057174],[-77.339999,34.990598],[-77.271806,34.957502],[-77.106672,34.933832]]]},"properties":{"id":"28573","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.996171,35.174043],[-79.034488,35.178729],[-79.037133,35.177993],[-79.001112,35.159397],[-78.996171,35.174043]]]},"properties":{"id":"28308","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.056794,34.949868],[-79.003182,34.869517],[-78.909792,34.869333],[-78.996852,34.958753],[-79.036926,34.956926],[-79.056794,34.949868]]]},"properties":{"id":"28371","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.639624,35.199293],[-78.639975,35.186176],[-78.660581,35.183879],[-78.65763,35.199265],[-78.731442,35.174516],[-78.652729,35.102711],[-78.540848,35.128489],[-78.533917,35.141203],[-78.540783,35.164819],[-78.639624,35.199293]]]},"properties":{"id":"28344","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.891944,36.253775],[-75.887997,36.163348],[-75.887173,36.158145],[-75.872944,36.154027],[-75.833382,36.189854],[-75.891944,36.253775]]]},"properties":{"id":"27947","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.674472,35.983238],[-75.643977,35.994946],[-75.682082,36.055795],[-75.731737,36.036783],[-75.674472,35.983238]]]},"properties":{"id":"27948","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.618062,35.875172],[-75.548314,35.771907],[-75.527574,35.780686],[-75.643977,35.994946],[-75.674472,35.983238],[-75.64444,35.9211],[-75.627205,35.895701],[-75.618062,35.875172]]]},"properties":{"id":"27959","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.627205,35.895701],[-75.660014,35.854512],[-75.618062,35.875172],[-75.627205,35.895701]]]},"properties":{"id":"27981","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.065626,35.505691],[-80.182682,35.504335],[-80.331781,35.406988],[-80.342275,35.388018],[-80.125682,35.415852],[-80.093272,35.419928],[-80.065626,35.505691]]]},"properties":{"id":"28127","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.036119,36.228695],[-80.122919,36.178019],[-80.141339,36.159363],[-80.168012,36.102735],[-80.119492,36.004722],[-80.024855,36.053837],[-80.014221,36.13354],[-79.989803,36.212726],[-80.036119,36.228695]]]},"properties":{"id":"27284","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.486967,36.043105],[-80.470166,35.837099],[-80.369114,35.930373],[-80.46381,36.062545],[-80.486967,36.043105]]]},"properties":{"id":"27006","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.772411,35.089809],[-77.599574,35.076243],[-77.742952,35.181592],[-77.767168,35.143915],[-77.772411,35.089809]]]},"properties":{"id":"28525","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.97383,36.103738],[-78.914884,36.06866],[-78.811485,36.095033],[-78.937266,36.137786],[-78.97383,36.103738]]]},"properties":{"id":"27712","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.179746,36.159327],[-79.205148,35.973563],[-79.075555,36.017264],[-79.036123,36.038461],[-78.97383,36.103738],[-78.937266,36.137786],[-78.96329,36.164685],[-79.027382,36.165909],[-79.119897,36.173827],[-79.179746,36.159327]]]},"properties":{"id":"27278","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.72001,35.730171],[-77.530303,35.722583],[-77.48975,35.696176],[-77.558096,35.791932],[-77.70938,35.801231],[-77.72001,35.730171]]]},"properties":{"id":"27852","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.446333,35.808529],[-77.440963,35.816103],[-77.466874,35.820152],[-77.449319,35.805705],[-77.446333,35.808529]]]},"properties":{"id":"27819","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.378749,35.441573],[-81.310038,35.375837],[-81.277616,35.391534],[-81.378749,35.441573]]]},"properties":{"id":"28033","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.074109,35.219719],[-81.083968,35.152217],[-81.033195,35.148685],[-81.006244,35.210362],[-81.013791,35.257687],[-81.040499,35.287013],[-81.068731,35.264115],[-81.073096,35.249004],[-81.074109,35.219719]]]},"properties":{"id":"28012","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.981695,35.381557],[-81.1074,35.329323],[-81.040499,35.287013],[-81.013791,35.257687],[-80.953545,35.350655],[-80.939077,35.353648],[-80.981695,35.381557]]]},"properties":{"id":"28120","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.954854,35.449027],[-77.826886,35.456075],[-77.820042,35.474215],[-77.807658,35.490588],[-77.844428,35.511411],[-78.090405,35.537467],[-78.096104,35.526878],[-78.093993,35.466322],[-77.954854,35.449027]]]},"properties":{"id":"27863","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.835179,36.084491],[-79.807694,36.079518],[-79.788557,36.094003],[-79.801806,36.126222],[-79.843382,36.125395],[-79.835179,36.084491]]]},"properties":{"id":"27408","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.922287,36.544601],[-78.046203,36.544156],[-77.966759,36.325952],[-77.890454,36.277263],[-77.707035,36.31267],[-77.728897,36.379329],[-77.818913,36.500806],[-77.922287,36.544601]]]},"properties":{"id":"27850","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.028955,35.525948],[-82.981708,35.523912],[-82.964251,35.531852],[-82.963301,35.520197],[-82.957256,35.523561],[-83.111289,35.770856],[-83.156448,35.551971],[-83.028955,35.525948]]]},"properties":{"id":"28785","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.981708,35.523912],[-82.963301,35.520197],[-82.964251,35.531852],[-82.981708,35.523912]]]},"properties":{"id":"28745","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.880093,35.521282],[-82.990348,35.367643],[-82.964815,35.315181],[-82.920819,35.291868],[-82.790428,35.373245],[-82.745209,35.422924],[-82.744383,35.423342],[-82.765991,35.567516],[-82.846352,35.614451],[-82.880093,35.521282]]]},"properties":{"id":"28716","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.093418,36.472639],[-77.166789,36.455772],[-77.159483,36.365771],[-76.98536,36.424588],[-76.956978,36.43629],[-77.093418,36.472639]]]},"properties":{"id":"27855","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.153739,34.929462],[-79.07248,34.854669],[-79.003182,34.869517],[-79.056794,34.949868],[-79.153739,34.929462]]]},"properties":{"id":"28357","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.262467,35.367803],[-83.266711,35.372686],[-83.267948,35.375695],[-83.266148,35.379187],[-83.270811,35.378502],[-83.269807,35.375266],[-83.262467,35.367803]]]},"properties":{"id":"28725","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.244386,35.67417],[-78.158847,35.519905],[-78.096104,35.526878],[-78.090405,35.537467],[-78.048574,35.595873],[-78.095367,35.686259],[-78.170099,35.715315],[-78.244386,35.67417]]]},"properties":{"id":"27542","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.534401,35.535313],[-78.363474,35.338456],[-78.29282,35.27438],[-78.223592,35.298626],[-78.215298,35.341225],[-78.28384,35.408201],[-78.502453,35.528486],[-78.534401,35.535313]]]},"properties":{"id":"27524","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.651922,35.135538],[-83.537904,35.035277],[-83.331783,35.053947],[-83.307216,35.057703],[-83.209903,35.154261],[-83.242869,35.239139],[-83.339707,35.331004],[-83.580775,35.290901],[-83.651922,35.135538]]]},"properties":{"id":"28734","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.270534,35.911103],[-77.187162,35.910745],[-77.172318,35.92912],[-77.198362,35.9391],[-77.243997,35.988425],[-77.215274,36.013805],[-77.327686,36.074512],[-77.339263,36.064681],[-77.352898,35.934093],[-77.283335,35.908398],[-77.270534,35.911103]]]},"properties":{"id":"27857","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.844591,35.213782],[-80.85463,35.224383],[-80.882985,35.207836],[-80.869442,35.199404],[-80.838393,35.196276],[-80.837166,35.202982],[-80.844591,35.213782]]]},"properties":{"id":"28203","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.825446,35.146843],[-80.870608,35.089328],[-80.875291,35.061534],[-80.778741,35.100013],[-80.79497,35.154443],[-80.825446,35.146843]]]},"properties":{"id":"28226","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.850497,35.241046],[-80.82816,35.225669],[-80.825462,35.227508],[-80.791124,35.251456],[-80.793526,35.278725],[-80.809067,35.276659],[-80.846742,35.272566],[-80.850497,35.241046]]]},"properties":{"id":"28206","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.960868,35.171999],[-80.989166,35.074734],[-80.919948,35.091235],[-80.880871,35.101757],[-80.876335,35.137719],[-80.960868,35.171999]]]},"properties":{"id":"28273","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.826025,35.375614],[-79.853594,35.343041],[-79.710222,35.302748],[-79.677216,35.388037],[-79.826025,35.375614]]]},"properties":{"id":"27209","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.919839,35.312739],[-80.080208,35.308638],[-80.093966,35.287087],[-80.07539,35.143081],[-79.986559,35.088769],[-79.912026,35.093657],[-79.85002,35.183072],[-79.919839,35.312739]]]},"properties":{"id":"27306","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.927606,34.237085],[-77.812617,34.2183],[-77.785489,34.247618],[-77.871808,34.318432],[-77.910263,34.289349],[-77.927606,34.237085]]]},"properties":{"id":"28405","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.91994,34.193472],[-77.927606,34.237085],[-77.910263,34.289349],[-77.993323,34.337194],[-78.029922,34.331768],[-77.959505,34.201889],[-77.95669,34.191996],[-77.91994,34.193472]]]},"properties":{"id":"28401","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.645559,36.545265],[-77.815706,36.545149],[-77.809247,36.498562],[-77.646465,36.481079],[-77.645559,36.545265]]]},"properties":{"id":"27832","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.484039,36.472536],[-77.590868,36.54528],[-77.645559,36.545265],[-77.646465,36.481079],[-77.625184,36.445146],[-77.546007,36.383751],[-77.470789,36.320176],[-77.433375,36.446362],[-77.484039,36.472536]]]},"properties":{"id":"27831","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.470789,36.320176],[-77.413357,36.279923],[-77.306561,36.342337],[-77.304545,36.368656],[-77.357667,36.435164],[-77.433375,36.446362],[-77.470789,36.320176]]]},"properties":{"id":"27845","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.153733,36.242117],[-79.223779,36.242915],[-79.231281,36.170898],[-79.179746,36.159327],[-79.119897,36.173827],[-79.153733,36.242117]]]},"properties":{"id":"27231","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.613064,35.281936],[-76.476268,35.248731],[-76.521864,35.353593],[-76.590497,35.361857],[-76.613064,35.281936]]]},"properties":{"id":"28552","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.767554,35.139187],[-76.744804,35.122704],[-76.720602,35.142986],[-76.767554,35.139187]]]},"properties":{"id":"28583","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.513298,35.698202],[-77.511677,35.69415],[-77.508037,35.696282],[-77.513298,35.698202]]]},"properties":{"id":"27827","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.731835,35.751083],[-79.827768,35.763123],[-79.713601,35.724224],[-79.731835,35.751083]]]},"properties":{"id":"27203","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.88387,35.051628],[-79.689573,35.055056],[-79.582828,35.083977],[-79.698182,35.178312],[-79.717526,35.173596],[-79.727904,35.168432],[-79.733651,35.17818],[-79.85002,35.183072],[-79.912026,35.093657],[-79.88387,35.051628]]]},"properties":{"id":"28338","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.733651,35.17818],[-79.727904,35.168432],[-79.717526,35.173596],[-79.733651,35.17818]]]},"properties":{"id":"28367","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.041399,34.477361],[-79.041984,34.469658],[-79.037795,34.468818],[-79.034573,34.481469],[-79.041456,34.481461],[-79.041399,34.477361]]]},"properties":{"id":"28375","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.509876,35.545207],[-80.365223,35.564511],[-80.263375,35.567833],[-80.24122,35.612455],[-80.332228,35.719047],[-80.390884,35.720523],[-80.481687,35.642904],[-80.545414,35.587903],[-80.509876,35.545207]],[[-80.438181,35.604641],[-80.450629,35.61649],[-80.431306,35.614042],[-80.438181,35.604641]]]},"properties":{"id":"28146","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.125682,35.415852],[-80.083904,35.395094],[-80.093272,35.419928],[-80.125682,35.415852]]]},"properties":{"id":"28009","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.378289,35.260716],[-80.370976,35.179907],[-80.277052,35.196136],[-80.253114,35.210874],[-80.265779,35.236183],[-80.319072,35.306889],[-80.378289,35.260716]]]},"properties":{"id":"28129","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.068456,36.369378],[-80.033338,36.319954],[-80.032604,36.355866],[-80.068456,36.369378]]]},"properties":{"id":"27042","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.563662,36.363555],[-80.605798,36.335045],[-80.631328,36.285822],[-80.562185,36.282455],[-80.544657,36.284458],[-80.552679,36.345346],[-80.563662,36.363555]]]},"properties":{"id":"27047","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.643937,34.950668],[-80.668859,34.938215],[-80.668107,34.934066],[-80.626077,34.818208],[-80.413659,34.815413],[-80.4037,34.885884],[-80.472347,34.982111],[-80.643937,34.950668]]]},"properties":{"id":"28112","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.417585,36.344635],[-78.444499,36.310348],[-78.405336,36.292656],[-78.371683,36.318806],[-78.417585,36.344635]]]},"properties":{"id":"27536","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.68631,35.94224],[-78.569185,35.897076],[-78.557737,35.909878],[-78.685703,35.987844],[-78.68631,35.94224]]]},"properties":{"id":"27614","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.046203,36.544156],[-78.129679,36.543896],[-77.981435,36.288711],[-77.966759,36.325952],[-78.046203,36.544156]]]},"properties":{"id":"27551","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.97986,35.348432],[-77.954854,35.449027],[-78.093993,35.466322],[-78.215298,35.341225],[-78.223592,35.298626],[-78.103934,35.280512],[-77.994401,35.337896],[-77.97986,35.348432]]]},"properties":{"id":"27530","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.090405,35.537467],[-77.844428,35.511411],[-77.913146,35.604075],[-77.953376,35.606013],[-78.048574,35.595873],[-78.090405,35.537467]]]},"properties":{"id":"27830","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.162056,36.269647],[-81.144967,36.374264],[-81.16446,36.430676],[-81.179657,36.411567],[-81.25258,36.349671],[-81.1893,36.269249],[-81.162056,36.269647]]]},"properties":{"id":"28649","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.385568,36.088704],[-79.28004,35.975389],[-79.348949,36.102974],[-79.385568,36.088704]]]},"properties":{"id":"27258","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.111514,35.852038],[-81.075417,36.016154],[-81.057461,36.031429],[-81.260274,36.025893],[-81.328385,35.99655],[-81.339015,35.905047],[-81.337777,35.84479],[-81.2281,35.810388],[-81.1882,35.822311],[-81.142327,35.827167],[-81.111514,35.852038]]]},"properties":{"id":"28681","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.111514,35.852038],[-81.142327,35.827167],[-81.106174,35.773299],[-81.08929,35.755856],[-80.98079,35.90319],[-81.111514,35.852038]]]},"properties":{"id":"28678","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.296377,36.506833],[-81.179657,36.411567],[-81.16446,36.430676],[-81.043865,36.398336],[-81.016831,36.396844],[-81.004946,36.494136],[-81.028974,36.565851],[-81.27058,36.574001],[-81.296377,36.506833]]]},"properties":{"id":"28675","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.285943,35.11802],[-80.261714,34.907798],[-80.17864,35.149886],[-80.253114,35.210874],[-80.277052,35.196136],[-80.285943,35.11802]]]},"properties":{"id":"28135","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.410048,36.51874],[-81.476362,36.580206],[-81.649767,36.587774],[-81.629378,36.514713],[-81.492722,36.45877],[-81.48391,36.458565],[-81.436317,36.460759],[-81.410048,36.51874]]]},"properties":{"id":"28643","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.380529,36.345205],[-81.361682,36.313399],[-81.351782,36.357481],[-81.380529,36.345205]]]},"properties":{"id":"28629","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.8649,35.35952],[-76.858434,35.308843],[-76.910016,35.323907],[-76.942749,35.288554],[-76.911816,35.265612],[-76.895546,35.253532],[-76.774169,35.222854],[-76.61625,35.267013],[-76.613064,35.281936],[-76.590497,35.361857],[-76.666373,35.378284],[-76.822215,35.425656],[-76.8649,35.35952]]]},"properties":{"id":"27806","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.215274,36.013805],[-77.143955,36.132158],[-77.251855,36.12199],[-77.327686,36.074512],[-77.215274,36.013805]]]},"properties":{"id":"27849","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.208289,36.246774],[-77.20879,36.246875],[-77.29223,36.168674],[-77.208289,36.246774]]]},"properties":{"id":"27872","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.825488,34.848302],[-78.740232,34.715479],[-78.642149,34.779167],[-78.825488,34.848302]]]},"properties":{"id":"28399","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.735568,34.670972],[-78.722169,34.652923],[-78.652692,34.580697],[-78.505914,34.566912],[-78.436231,34.548819],[-78.44693,34.610063],[-78.464067,34.678091],[-78.5986,34.81382],[-78.642149,34.779167],[-78.740232,34.715479],[-78.735568,34.670972]]]},"properties":{"id":"28337","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.844249,34.821238],[-78.830345,34.713967],[-78.805221,34.674598],[-78.76318,34.655089],[-78.735568,34.670972],[-78.740232,34.715479],[-78.825488,34.848302],[-78.844249,34.821238]]]},"properties":{"id":"28392","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.51212,33.977614],[-78.384416,33.903197],[-78.380211,33.903942],[-78.368494,34.013038],[-78.403609,34.011952],[-78.487546,33.987381],[-78.513156,33.98292],[-78.51212,33.977614]]]},"properties":{"id":"28470","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.558817,33.866456],[-78.484909,33.873206],[-78.533487,33.949838],[-78.554086,33.941224],[-78.558817,33.866456]]]},"properties":{"id":"28468","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.064937,34.068867],[-77.948309,33.969873],[-77.933953,34.017108],[-77.930956,34.050324],[-77.95669,34.191996],[-77.959505,34.201889],[-78.197574,34.107775],[-78.19924,34.107741],[-78.064937,34.068867]]]},"properties":{"id":"28479","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.765991,35.567516],[-82.744383,35.423342],[-82.660301,35.452787],[-82.616506,35.494086],[-82.664532,35.597548],[-82.765991,35.567516]]]},"properties":{"id":"28715","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.47814,35.664289],[-82.614727,35.694714],[-82.626781,35.642891],[-82.576826,35.615593],[-82.540988,35.599929],[-82.47814,35.664289]]]},"properties":{"id":"28804","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.460812,35.809827],[-82.398741,35.698187],[-82.383715,35.687219],[-82.335152,35.749462],[-82.408728,35.818061],[-82.460812,35.809827]]]},"properties":{"id":"28709","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.67476,35.291155],[-80.780009,35.253838],[-80.748699,35.224333],[-80.724913,35.223178],[-80.605229,35.236915],[-80.627978,35.248604],[-80.67476,35.291155]]]},"properties":{"id":"28215","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.13994,36.274498],[-76.257348,36.38199],[-76.491477,36.510536],[-76.45369,36.378042],[-76.418737,36.324583],[-76.184203,36.11473],[-76.034872,36.167598],[-76.13994,36.274498]]]},"properties":{"id":"27909","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.223779,36.242915],[-79.153733,36.242117],[-79.148837,36.342322],[-79.206037,36.350392],[-79.223779,36.242915]]]},"properties":{"id":"27314","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.867117,35.87655],[-78.878661,35.957397],[-78.967761,35.903982],[-78.963619,35.83003],[-78.929614,35.828816],[-78.890495,35.867842],[-78.867117,35.87655]]]},"properties":{"id":"27713","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.693163,36.236642],[-76.674522,36.04383],[-76.406841,36.074663],[-76.581118,36.171615],[-76.693163,36.236642]]]},"properties":{"id":"27932","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.898335,35.031543],[-83.972661,34.987351],[-83.858323,34.987349],[-83.898335,35.031543]]]},"properties":{"id":"28909","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.57602,34.385833],[-78.552969,34.234206],[-78.548381,34.236203],[-78.459541,34.28306],[-78.490798,34.394063],[-78.54536,34.416816],[-78.585938,34.394838],[-78.57602,34.385833]]]},"properties":{"id":"28450","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.054756,35.349918],[-77.100509,35.192009],[-76.911816,35.265612],[-76.942749,35.288554],[-76.995716,35.319388],[-77.054756,35.349918]]]},"properties":{"id":"28527","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.411563,35.337997],[-77.475215,35.150523],[-77.429567,35.206425],[-77.285097,35.291799],[-77.30219,35.312524],[-77.411563,35.337997]]]},"properties":{"id":"28526","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.867499,35.245065],[-78.829957,35.166439],[-78.735461,35.175001],[-78.702756,35.256728],[-78.81688,35.290041],[-78.901695,35.272613],[-78.867499,35.245065]]]},"properties":{"id":"28356","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.892224,35.042815],[-78.859846,35.032164],[-78.858141,35.100152],[-78.857858,35.101658],[-78.924186,35.08904],[-78.89803,35.070522],[-78.892224,35.042815]]]},"properties":{"id":"28301","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.699958,34.952799],[-78.619629,34.914028],[-78.554565,35.075598],[-78.540848,35.128489],[-78.652729,35.102711],[-78.672277,35.085286],[-78.699958,34.952799]]]},"properties":{"id":"28318","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.151514,36.550552],[-75.98101,36.462342],[-75.96587,36.45067],[-75.917514,36.46677],[-75.906829,36.550654],[-76.151514,36.550552]]]},"properties":{"id":"27950","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.721267,35.809374],[-75.75567,35.678561],[-75.708618,35.706887],[-75.721267,35.809374]]]},"properties":{"id":"27978","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-75.588214,35.270651],[-75.519099,35.256216],[-75.517643,35.267833],[-75.531116,35.274879],[-75.588214,35.270651]]]},"properties":{"id":"27920","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.276085,35.985768],[-80.369114,35.930373],[-80.470166,35.837099],[-80.467094,35.813932],[-80.326653,35.771596],[-80.196983,35.853667],[-80.179545,35.894681],[-80.232349,35.957584],[-80.276085,35.985768]]]},"properties":{"id":"27295","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.46381,36.062545],[-80.369114,35.930373],[-80.276085,35.985768],[-80.354583,36.016167],[-80.365246,36.073596],[-80.46381,36.062545]]]},"properties":{"id":"27012","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.806852,36.030508],[-80.858863,36.010416],[-80.808405,35.913029],[-80.704404,35.907884],[-80.694692,36.031004],[-80.75394,36.052645],[-80.806852,36.030508]]]},"properties":{"id":"28634","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.241134,34.841035],[-77.907069,34.891045],[-77.999416,34.955542],[-78.147081,34.902448],[-78.213976,34.890076],[-78.241134,34.841035]]]},"properties":{"id":"28453","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.946554,35.055736],[-77.999416,34.955542],[-77.907069,34.891045],[-77.895913,34.885321],[-77.835141,34.960721],[-77.846101,35.025098],[-77.901505,35.04367],[-77.946554,35.055736]]]},"properties":{"id":"28349","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.906768,34.658932],[-78.153163,34.721957],[-78.178756,34.649884],[-78.124062,34.58947],[-78.110208,34.552118],[-77.906768,34.658932]]]},"properties":{"id":"28478","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.906041,35.749157],[-77.768721,35.713253],[-77.744379,35.706373],[-77.72001,35.730171],[-77.70938,35.801231],[-77.743037,35.83743],[-77.781483,35.843987],[-77.827446,35.857006],[-77.850928,35.865806],[-77.911542,35.885446],[-77.990106,35.869797],[-77.972099,35.848768],[-77.906041,35.749157]]]},"properties":{"id":"27822","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-77.812616,35.85924],[-77.827446,35.857006],[-77.781483,35.843987],[-77.812616,35.85924]]],[[[-77.908498,35.94794],[-77.911542,35.885446],[-77.850928,35.865806],[-77.826133,35.871406],[-77.797886,35.936207],[-77.908498,35.94794]]]]},"properties":{"id":"27803","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.219235,36.089054],[-80.22776,36.093139],[-80.228933,36.086209],[-80.219235,36.089054]]]},"properties":{"id":"27110","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.359625,36.378936],[-80.425402,36.292061],[-80.342941,36.260656],[-80.277767,36.271873],[-80.281904,36.370728],[-80.318816,36.393305],[-80.359625,36.378936]]]},"properties":{"id":"27021","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.208852,35.938559],[-78.241062,35.988944],[-78.290098,35.958931],[-78.272085,35.932518],[-78.208852,35.938559]]]},"properties":{"id":"27508","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.068731,35.264115],[-81.040499,35.287013],[-81.1074,35.329323],[-81.127569,35.320236],[-81.120598,35.308074],[-81.106607,35.282991],[-81.077594,35.264211],[-81.068731,35.264115]]],[[[-81.194498,35.232576],[-81.180784,35.157221],[-81.083968,35.152217],[-81.074109,35.219719],[-81.096849,35.255358],[-81.114875,35.261763],[-81.194498,35.232576]]]]},"properties":{"id":"28056","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.240012,35.292107],[-81.270414,35.252064],[-81.293751,35.162144],[-81.180784,35.157221],[-81.194498,35.232576],[-81.181854,35.292111],[-81.240012,35.292107]]]},"properties":{"id":"28052","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.700502,35.652256],[-77.73747,35.518976],[-77.652476,35.531627],[-77.677614,35.64886],[-77.700502,35.652256]]]},"properties":{"id":"27888","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.73747,35.518976],[-77.807658,35.490588],[-77.820042,35.474215],[-77.660811,35.352757],[-77.611062,35.378802],[-77.588514,35.473346],[-77.595498,35.478654],[-77.580478,35.48137],[-77.585463,35.472826],[-77.558024,35.461528],[-77.530583,35.518517],[-77.652476,35.531627],[-77.73747,35.518976]]]},"properties":{"id":"28580","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.844428,35.511411],[-77.807658,35.490588],[-77.73747,35.518976],[-77.700502,35.652256],[-77.744379,35.706373],[-77.768721,35.713253],[-77.780879,35.657421],[-77.765135,35.654049],[-77.774788,35.645592],[-77.783807,35.651941],[-77.913146,35.604075],[-77.844428,35.511411]]]},"properties":{"id":"27883","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.588514,35.473346],[-77.611062,35.378802],[-77.584817,35.366707],[-77.540235,35.376075],[-77.50534,35.460624],[-77.558024,35.461528],[-77.585463,35.472826],[-77.588514,35.473346]]]},"properties":{"id":"28538","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.783941,36.248465],[-79.83881,36.17284],[-79.843382,36.125395],[-79.801806,36.126222],[-79.776221,36.14258],[-79.757725,36.247734],[-79.783941,36.248465]]]},"properties":{"id":"27455","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.905911,36.045713],[-79.903375,35.942095],[-79.869619,35.934019],[-79.819763,36.03572],[-79.847772,36.072665],[-79.876554,36.068489],[-79.905911,36.045713]]]},"properties":{"id":"27407","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.014221,36.13354],[-80.024855,36.053837],[-79.970743,36.083793],[-79.969115,36.141059],[-80.014221,36.13354]]]},"properties":{"id":"27235","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.835179,36.084491],[-79.847772,36.072665],[-79.819763,36.03572],[-79.799388,36.064239],[-79.807694,36.079518],[-79.835179,36.084491]]]},"properties":{"id":"27403","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.989803,36.212726],[-80.014221,36.13354],[-79.969115,36.141059],[-79.953042,36.172826],[-79.959359,36.212205],[-79.989803,36.212726]]]},"properties":{"id":"27310","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.818913,36.500806],[-77.728897,36.379329],[-77.622078,36.40828],[-77.625184,36.445146],[-77.646465,36.481079],[-77.809247,36.498562],[-77.818913,36.500806]]]},"properties":{"id":"27870","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.625184,36.445146],[-77.622078,36.40828],[-77.546007,36.383751],[-77.625184,36.445146]]]},"properties":{"id":"27890","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.81688,35.290041],[-78.702756,35.256728],[-78.697856,35.374999],[-78.718634,35.382441],[-78.746932,35.33399],[-78.81688,35.290041]]]},"properties":{"id":"28339","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.28044,35.381491],[-79.0043,35.266574],[-79.009431,35.274342],[-79.030396,35.312551],[-79.067662,35.462703],[-79.28044,35.381491]]]},"properties":{"id":"27332","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.616631,35.224347],[-82.598858,35.24957],[-82.631177,35.269244],[-82.616631,35.224347]]]},"properties":{"id":"28766","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.34031,35.05576],[-79.428411,35.016075],[-79.348866,34.900259],[-79.210339,34.923937],[-79.153739,34.929462],[-79.056794,34.949868],[-79.036926,34.956926],[-79.059334,35.003032],[-79.083163,35.032662],[-79.089995,35.053838],[-79.34031,35.05576]]]},"properties":{"id":"28376","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.085277,34.746291],[-79.210339,34.923937],[-79.348866,34.900259],[-79.349143,34.838476],[-79.210169,34.762499],[-79.142539,34.725036],[-79.085277,34.746291]]]},"properties":{"id":"28377","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.414015,35.463365],[-76.439888,35.543399],[-76.521864,35.353593],[-76.426148,35.375166],[-76.414015,35.463365]]]},"properties":{"id":"27875","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.061198,35.516369],[-76.348745,35.497669],[-76.414015,35.463365],[-76.426148,35.375166],[-76.234368,35.338857],[-76.061198,35.516369]]]},"properties":{"id":"27885","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.811943,35.692417],[-80.943656,35.637643],[-80.928408,35.624083],[-80.864277,35.639871],[-80.799647,35.667911],[-80.811943,35.692417]]]},"properties":{"id":"28166","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.903074,36.12484],[-80.896578,36.083312],[-80.806852,36.030508],[-80.75394,36.052645],[-80.778596,36.153758],[-80.791221,36.196779],[-80.875939,36.180811],[-80.903074,36.12484]]]},"properties":{"id":"27020","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.186063,35.130057],[-83.108614,35.000659],[-83.008597,35.027547],[-83.065345,35.130409],[-83.1253,35.136723],[-83.186063,35.130057]]]},"properties":{"id":"28717","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.964815,35.315181],[-83.140347,35.222927],[-82.991701,35.199449],[-82.962019,35.202934],[-82.920819,35.291868],[-82.964815,35.315181]]]},"properties":{"id":"28783","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.023786,35.162644],[-82.911429,35.078929],[-82.867228,35.136948],[-82.856858,35.20144],[-82.962019,35.202934],[-82.991701,35.199449],[-83.023786,35.162644]]]},"properties":{"id":"28747","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.259919,35.667883],[-78.336329,35.681755],[-78.328065,35.589656],[-78.231777,35.480579],[-78.235636,35.507036],[-78.244255,35.510972],[-78.236571,35.508445],[-78.158847,35.519905],[-78.244386,35.67417],[-78.259919,35.667883]],[[-78.198324,35.565742],[-78.197978,35.559449],[-78.207858,35.560978],[-78.204771,35.565859],[-78.198324,35.565742]]]},"properties":{"id":"27576","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.502727,34.9696],[-77.339999,34.990598],[-77.221446,35.057174],[-77.329312,35.149582],[-77.429567,35.206425],[-77.475215,35.150523],[-77.567463,35.051649],[-77.624709,35.016862],[-77.502727,34.9696]]]},"properties":{"id":"28585","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.296814,34.996257],[-83.307216,35.057703],[-83.331783,35.053947],[-83.367759,34.995105],[-83.296814,34.996257]]]},"properties":{"id":"28775","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.599715,35.783658],[-82.460812,35.809827],[-82.408728,35.818061],[-82.505384,35.97768],[-82.582878,35.965344],[-82.599715,35.783658]]]},"properties":{"id":"28754","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.312909,35.818014],[-77.324565,35.814861],[-77.314128,35.806589],[-77.312909,35.818014]]]},"properties":{"id":"27861","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.736366,35.159765],[-80.743247,35.148923],[-80.745542,35.148232],[-80.729405,35.136742],[-80.736366,35.159765]]],[[[-80.724913,35.223178],[-80.725343,35.168786],[-80.639991,35.140736],[-80.599683,35.164005],[-80.555394,35.204306],[-80.599802,35.234052],[-80.605229,35.236915],[-80.724913,35.223178]]]]},"properties":{"id":"28227","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.826025,35.375614],[-79.677216,35.388037],[-79.660074,35.40201],[-79.711644,35.432504],[-79.812786,35.510994],[-79.874167,35.510253],[-79.826025,35.375614]]]},"properties":{"id":"27356","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.32849,35.237017],[-79.396397,35.221346],[-79.425664,35.160641],[-79.380441,35.117983],[-79.317982,35.167848],[-79.327764,35.23688],[-79.32849,35.237017]]]},"properties":{"id":"28387","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.689573,35.055056],[-79.639912,35.011269],[-79.542922,35.009387],[-79.519617,35.020631],[-79.478522,35.057103],[-79.516088,35.08631],[-79.582828,35.083977],[-79.689573,35.055056]]]},"properties":{"id":"28347","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.910263,34.289349],[-77.871808,34.318432],[-77.815179,34.367028],[-77.969679,34.378033],[-77.993323,34.337194],[-77.910263,34.289349]]]},"properties":{"id":"28429","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.785489,34.247618],[-77.812617,34.2183],[-77.817037,34.213432],[-77.811122,34.185148],[-77.770721,34.237204],[-77.785489,34.247618]]]},"properties":{"id":"28480","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.400838,36.54476],[-77.449212,36.544839],[-77.484039,36.472536],[-77.433375,36.446362],[-77.357667,36.435164],[-77.265015,36.46476],[-77.302159,36.471463],[-77.400838,36.54476]]]},"properties":{"id":"27876","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.449212,36.544839],[-77.590868,36.54528],[-77.484039,36.472536],[-77.449212,36.544839]]]},"properties":{"id":"27866","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.460901,34.583025],[-77.50994,34.445332],[-77.332625,34.530351],[-77.460901,34.583025]]]},"properties":{"id":"28460","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.169216,34.722945],[-77.18118,34.786995],[-77.263672,34.77498],[-77.288617,34.758164],[-77.273885,34.7074],[-77.112238,34.641904],[-77.107305,34.64734],[-77.169216,34.722945]]]},"properties":{"id":"28539","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-79.010746,36.290563],[-79.05117,36.241071],[-78.809708,36.359896],[-78.835303,36.371018],[-79.010746,36.290563]]]},"properties":{"id":"27583","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.61625,35.267013],[-76.579041,35.1871],[-76.528587,35.16618],[-76.476268,35.248731],[-76.613064,35.281936],[-76.61625,35.267013]]]},"properties":{"id":"28537","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.741684,34.47304],[-77.804079,34.367049],[-77.723111,34.320015],[-77.604171,34.406567],[-77.617517,34.576379],[-77.741684,34.47304]]]},"properties":{"id":"28443","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.243471,35.254562],[-82.313529,35.191426],[-82.138954,35.193123],[-81.998496,35.229466],[-82.073288,35.277827],[-82.117025,35.28539],[-82.243471,35.254562]]]},"properties":{"id":"28782","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.283329,36.483342],[-80.149712,36.461634],[-80.150245,36.542959],[-80.295081,36.543977],[-80.283329,36.483342]]]},"properties":{"id":"27022","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.837201,35.071612],[-82.867228,35.136948],[-82.911429,35.078929],[-82.908778,35.053197],[-82.837201,35.071612]]],[[[-82.855905,35.201563],[-82.788055,35.084973],[-82.643131,35.130569],[-82.671459,35.187636],[-82.838673,35.261261],[-82.855905,35.201563]]]]},"properties":{"id":"28712","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.855905,35.201563],[-82.856858,35.20144],[-82.867228,35.136948],[-82.837201,35.071612],[-82.788055,35.084973],[-82.855905,35.201563]]]},"properties":{"id":"28772","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.668107,34.934066],[-80.668859,34.938215],[-80.692855,34.93354],[-80.690556,34.930763],[-80.679354,34.934261],[-80.668107,34.934066]]]},"properties":{"id":"28108","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.657935,35.780408],[-78.642481,35.796678],[-78.660208,35.799844],[-78.665304,35.783114],[-78.657935,35.780408]]]},"properties":{"id":"27605","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-78.597411,35.859885],[-78.651074,35.870772],[-78.664193,35.821312],[-78.619673,35.813902],[-78.603768,35.84982],[-78.597411,35.859885]]]},"properties":{"id":"27609","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-76.70686,35.92304],[-76.648928,35.718153],[-76.592294,35.791173],[-76.475334,35.908791],[-76.366304,35.936618],[-76.358148,35.952595],[-76.691619,35.946755],[-76.70686,35.92304]]]},"properties":{"id":"27970","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-77.938885,35.615133],[-77.927118,35.640966],[-77.940838,35.640291],[-77.938885,35.615133]]]},"properties":{"id":"27813","state_code":"37"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.243557,46.48568],[-98.159755,46.442337],[-98.033725,46.514515],[-98.033652,46.543552],[-98.102531,46.746934],[-98.185643,46.834202],[-98.311904,46.77643],[-98.243557,46.48568]]]},"properties":{"id":"58461","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.854115,47.095064],[-98.03224,47.116961],[-98.171292,47.067022],[-98.185643,46.834202],[-98.102531,46.746934],[-97.980383,46.7603],[-97.80791,46.810505],[-97.854115,47.095064]]]},"properties":{"id":"58072","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.348303,46.485739],[-98.243557,46.48568],[-98.311904,46.77643],[-98.333036,46.805417],[-98.375941,46.790983],[-98.460169,46.674607],[-98.515527,46.587447],[-98.348303,46.485739]]]},"properties":{"id":"58466","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.643885,47.484334],[-97.900851,47.354904],[-97.897158,47.167699],[-97.833287,47.167609],[-97.769411,47.225273],[-97.705863,47.210666],[-97.558005,47.253487],[-97.515243,47.311462],[-97.580795,47.411657],[-97.643885,47.484334]]]},"properties":{"id":"58046","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.99257,47.240697],[-98.310635,47.240733],[-98.340524,47.168137],[-98.012187,47.153414],[-97.99257,47.240697]]]},"properties":{"id":"58429","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.43907,46.819648],[-98.670589,46.703377],[-98.460169,46.674607],[-98.375941,46.790983],[-98.43907,46.819648]]]},"properties":{"id":"58497","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.453097,48.094745],[-100.25864,48.050922],[-100.069069,47.992057],[-99.940905,48.021565],[-99.782747,48.209497],[-99.798152,48.371159],[-99.798002,48.544889],[-99.928203,48.545041],[-100.276424,48.472539],[-100.561497,48.193727],[-100.496334,48.123573],[-100.453097,48.094745]]]},"properties":{"id":"58368","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.033064,47.110716],[-103.393081,47.146551],[-103.482887,46.672323],[-103.300521,46.629829],[-103.114212,46.629876],[-103.105268,46.890616],[-103.033064,47.110716]]]},"properties":{"id":"58622","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.800654,48.681832],[-100.668066,48.56744],[-100.537925,48.581137],[-100.472647,48.690264],[-100.665114,48.762893],[-100.872438,48.777384],[-100.800654,48.681832]]]},"properties":{"id":"58748","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.665114,48.762893],[-100.55564,48.999317],[-100.958349,48.999485],[-100.893622,48.806708],[-100.872438,48.777384],[-100.665114,48.762893]]]},"properties":{"id":"58783","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.300042,48.734224],[-101.635507,48.633818],[-101.6178,48.613812],[-101.587192,48.54639],[-101.4513,48.502534],[-101.190214,48.574542],[-101.300042,48.734224]]]},"properties":{"id":"58750","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.107062,48.937562],[-102.502641,48.807382],[-102.546301,48.764111],[-102.451692,48.633458],[-102.107062,48.937562]]]},"properties":{"id":"58721","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.568014,48.92321],[-102.502641,48.807382],[-102.107062,48.937562],[-102.173725,48.998648],[-102.552331,48.998993],[-102.568014,48.92321]]]},"properties":{"id":"58737","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.435619,48.603296],[-102.624864,48.380059],[-102.547933,48.146178],[-102.246053,48.153463],[-102.320418,48.546302],[-102.435619,48.603296]]]},"properties":{"id":"58784","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.87262,46.847513],[-96.853319,46.876404],[-96.903738,46.934479],[-96.967585,46.920647],[-96.989926,46.804377],[-96.901442,46.80396],[-96.87262,46.847513]]]},"properties":{"id":"58078","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.429509,46.977965],[-97.578503,47.050626],[-97.621083,47.036176],[-97.630255,46.818192],[-97.450889,46.832384],[-97.429531,46.962727],[-97.429509,46.977965]]]},"properties":{"id":"58011","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.451178,47.180959],[-97.451217,47.064918],[-97.378235,47.050257],[-97.303574,47.064507],[-97.345273,47.137082],[-97.366591,47.180966],[-97.451178,47.180959]]]},"properties":{"id":"58029","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.989926,46.804377],[-96.947073,46.717289],[-96.865736,46.616013],[-96.851808,46.616131],[-96.798128,46.630326],[-96.784225,46.730976],[-96.901442,46.80396],[-96.989926,46.804377]]]},"properties":{"id":"58047","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.318098,48.543773],[-98.318336,48.398718],[-98.122603,48.543266],[-98.209405,48.586782],[-98.252924,48.586942],[-98.318098,48.543773]]]},"properties":{"id":"58229","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.714889,47.573582],[-102.672747,47.256062],[-102.631251,47.212756],[-102.525192,47.212836],[-102.587828,47.533576],[-102.714889,47.573582]]]},"properties":{"id":"58626","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.410002,48.032781],[-103.329705,47.59262],[-103.099509,47.548956],[-102.785275,47.594895],[-102.784761,47.734196],[-103.044049,47.954703],[-103.087187,48.124645],[-103.20271,48.134905],[-103.410002,48.032781]]]},"properties":{"id":"58854","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.045447,46.685828],[-103.902575,46.760042],[-103.667067,47.241346],[-103.666723,47.329354],[-104.045371,47.330128],[-104.045447,46.685828]]]},"properties":{"id":"58621","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.472103,48.020683],[-97.406763,47.98721],[-97.364239,47.972998],[-97.303678,47.976671],[-97.237257,48.0198],[-97.363145,48.09301],[-97.384241,48.093329],[-97.472103,48.020683]]]},"properties":{"id":"58258","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.324866,47.730219],[-97.261225,47.649558],[-96.902811,47.672109],[-97.324866,47.730219]]]},"properties":{"id":"58275","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.371692,47.6724],[-98.499905,47.45649],[-98.499833,47.442454],[-98.354938,47.413444],[-98.248968,47.543808],[-98.219941,47.558274],[-98.176746,47.601783],[-98.263044,47.672442],[-98.371692,47.6724]]]},"properties":{"id":"58416","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.663591,46.629985],[-102.999684,46.687885],[-103.114212,46.629876],[-103.300521,46.629829],[-103.217031,46.366708],[-103.175736,46.366721],[-102.925322,46.336015],[-102.799883,46.368667],[-102.656216,46.629878],[-102.663591,46.629985]]]},"properties":{"id":"58647","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.287747,46.456794],[-99.315952,46.142891],[-99.024702,46.113673],[-99.037292,46.3992],[-99.287747,46.456794]]]},"properties":{"id":"58440","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.043598,47.660527],[-104.045371,47.330128],[-103.666723,47.329354],[-103.656837,47.329259],[-103.480673,47.52519],[-103.55781,47.58829],[-103.800674,47.618498],[-104.043598,47.660527]]]},"properties":{"id":"59270","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.042498,47.806791],[-103.992012,47.970746],[-104.04412,47.996107],[-104.042498,47.806791]]]},"properties":{"id":"59221","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.719494,47.674142],[-100.886521,47.349324],[-100.801465,47.338865],[-100.673445,47.32772],[-100.611861,47.673824],[-100.719494,47.674142]]]},"properties":{"id":"58559","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.465416,48.136929],[-98.525582,47.947692],[-98.31172,47.882587],[-98.268732,47.846087],[-98.204169,47.860591],[-98.184419,48.151507],[-98.465416,48.136929]]]},"properties":{"id":"58344","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.294143,48.832698],[-97.556538,48.819068],[-97.512532,48.73229],[-97.447282,48.710286],[-97.22949,48.78948],[-97.294143,48.832698]]]},"properties":{"id":"58238","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.818366,48.673498],[-97.818405,48.702494],[-97.92767,48.717682],[-97.927126,48.644438],[-97.818366,48.673498]]]},"properties":{"id":"58262","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.465416,48.136929],[-98.551824,48.238364],[-98.551857,48.252855],[-98.660289,48.281783],[-98.697263,48.003463],[-98.616639,47.91644],[-98.525582,47.947692],[-98.465416,48.136929]]]},"properties":{"id":"58327","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.928203,48.545041],[-99.798002,48.544889],[-99.710933,48.544941],[-99.623228,48.718473],[-99.635381,48.718466],[-99.920122,48.791234],[-100.102754,48.69042],[-99.928203,48.545041]]]},"properties":{"id":"58366","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.55079,46.025146],[-97.419931,45.994189],[-97.467536,46.123493],[-97.528793,46.152945],[-97.55079,46.025146]]]},"properties":{"id":"58067","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.611861,47.673824],[-100.673445,47.32772],[-100.621529,47.327419],[-100.389452,47.327484],[-100.260922,47.327219],[-100.327376,47.68831],[-100.611861,47.673824]]]},"properties":{"id":"58463","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.720504,46.379745],[-100.752136,46.261823],[-100.571263,46.262317],[-100.554299,46.371294],[-100.590199,46.427894],[-100.720504,46.379745]]]},"properties":{"id":"58528","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.787456,46.030694],[-100.752205,46.195722],[-101.025662,46.284219],[-101.333771,46.091599],[-101.335143,46.083779],[-101.333889,45.944097],[-100.793764,45.943786],[-100.787456,46.030694]]]},"properties":{"id":"58568","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.369573,46.630185],[-102.348353,46.730923],[-102.475443,46.702468],[-102.600932,46.70235],[-102.663591,46.629985],[-102.656216,46.629878],[-102.502569,46.629968],[-102.369573,46.630185]]]},"properties":{"id":"58641","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.882405,47.672179],[-97.984929,47.574243],[-97.985083,47.557603],[-97.707647,47.57185],[-97.686442,47.643924],[-97.882405,47.672179]]]},"properties":{"id":"58277","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.367898,47.411108],[-97.580795,47.411657],[-97.515243,47.311462],[-97.346567,47.332768],[-97.326506,47.381975],[-97.367898,47.411108]]]},"properties":{"id":"58016","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.253715,48.602532],[-98.970337,48.558717],[-99.088038,48.732635],[-99.231722,48.689466],[-99.253715,48.602532]]]},"properties":{"id":"58331","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.339404,48.42299],[-101.329259,48.418232],[-101.326444,48.42088],[-101.339376,48.425844],[-101.339404,48.42299]]]},"properties":{"id":"58705","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.166163,48.237848],[-101.402622,48.250947],[-101.533826,48.096118],[-101.55544,48.051927],[-101.534468,48.02289],[-101.357393,48.022903],[-101.274391,48.051868],[-101.088513,48.109651],[-101.10125,48.138603],[-101.101283,48.225582],[-101.166163,48.237848]],[[-101.293011,48.231639],[-101.294561,48.231643],[-101.294554,48.232649],[-101.293024,48.232639],[-101.293011,48.231639]]]},"properties":{"id":"58701","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.143723,48.371548],[-101.166163,48.237848],[-101.101283,48.225582],[-101.014153,48.341489],[-101.103429,48.371484],[-101.143723,48.371548]]]},"properties":{"id":"58785","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.320184,48.466764],[-103.20271,48.134905],[-103.087187,48.124645],[-103.081318,48.502985],[-103.320216,48.502968],[-103.320184,48.466764]]]},"properties":{"id":"58849","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.102531,46.746934],[-98.033652,46.543552],[-97.889306,46.543334],[-97.892039,46.717269],[-97.980383,46.7603],[-98.102531,46.746934]]]},"properties":{"id":"58049","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.340601,47.095633],[-98.171292,47.067022],[-98.03224,47.116961],[-98.012187,47.153414],[-98.340524,47.168137],[-98.340601,47.095633]]]},"properties":{"id":"58479","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.845659,47.877024],[-98.845569,47.934741],[-98.9529,47.963646],[-99.082133,47.934569],[-99.33154,47.846805],[-99.447503,47.817993],[-99.447548,47.788984],[-99.404592,47.774463],[-98.866863,47.760358],[-98.845659,47.877024]]]},"properties":{"id":"58374","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.175736,46.366721],[-103.252742,45.945171],[-102.995668,45.945163],[-102.925322,46.336015],[-103.175736,46.366721]]]},"properties":{"id":"58653","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.435619,48.603296],[-102.320418,48.546302],[-102.233892,48.546339],[-101.842591,48.647846],[-101.911861,48.905639],[-101.96684,48.999],[-102.173725,48.998648],[-102.107062,48.937562],[-102.451692,48.633458],[-102.435619,48.603296]]]},"properties":{"id":"58746","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.655804,48.937858],[-102.805208,48.999332],[-102.938777,48.999329],[-102.939932,48.720469],[-102.885748,48.662254],[-102.633844,48.720467],[-102.655804,48.937858]]]},"properties":{"id":"58727","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.005943,48.720568],[-103.037524,48.589956],[-102.842713,48.546404],[-102.885748,48.662254],[-102.939932,48.720469],[-103.005943,48.720568]]]},"properties":{"id":"58755","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.935983,46.98284],[-100.834058,46.822035],[-100.583134,46.836956],[-100.601092,46.982599],[-100.909052,46.997587],[-100.935983,46.98284]]]},"properties":{"id":"58503","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.409147,46.629445],[-97.280202,46.456504],[-97.155612,46.514128],[-97.156017,46.601585],[-97.15729,46.687507],[-97.221221,46.754043],[-97.284332,46.759809],[-97.388298,46.759855],[-97.430106,46.730985],[-97.409147,46.629445]]]},"properties":{"id":"58052","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.776208,46.87386],[-96.853319,46.876404],[-96.87262,46.847513],[-96.792698,46.832566],[-96.776208,46.87386]]]},"properties":{"id":"58103","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.606093,48.732324],[-98.427371,48.630519],[-98.317863,48.64899],[-98.057253,48.761098],[-98.026491,48.761054],[-98.206438,49.000279],[-98.605944,49.000107],[-98.474866,48.935062],[-98.606093,48.732324]]]},"properties":{"id":"58249","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.759043,48.81932],[-98.649923,48.732373],[-98.606093,48.732324],[-98.474866,48.935062],[-98.759065,48.848288],[-98.759043,48.81932]]]},"properties":{"id":"58281","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.8689,48.920843],[-99.00067,48.862893],[-99.000839,48.81946],[-98.759043,48.81932],[-98.759065,48.848288],[-98.8689,48.920843]]]},"properties":{"id":"58323","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.536645,46.442381],[-98.599928,46.529304],[-98.704328,46.558504],[-98.955049,46.456979],[-98.796832,46.185069],[-98.556992,46.282873],[-98.536645,46.442381]]]},"properties":{"id":"58433","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.671894,45.94101],[-98.880321,45.939355],[-98.880943,46.112871],[-99.024702,46.113673],[-99.315952,46.142891],[-99.419779,46.17201],[-99.753275,46.129322],[-99.671894,45.94101]]]},"properties":{"id":"58413","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.880943,46.112871],[-98.880321,45.939355],[-98.675308,45.938413],[-98.828773,46.112549],[-98.880943,46.112871]]]},"properties":{"id":"58439","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.348303,46.485739],[-98.494583,46.442314],[-98.473472,46.282942],[-98.269402,46.240295],[-98.139318,46.282759],[-98.159755,46.442337],[-98.243557,46.48568],[-98.348303,46.485739]]]},"properties":{"id":"58458","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.464907,48.999105],[-103.530956,48.764017],[-103.298252,48.705691],[-103.136774,48.807389],[-103.158408,48.999336],[-103.464907,48.999105]]]},"properties":{"id":"58730","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.530956,48.764017],[-103.464907,48.999105],[-103.596652,48.999191],[-103.574814,48.771923],[-103.530956,48.764017]]]},"properties":{"id":"58833","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.699747,47.082452],[-102.600932,46.70235],[-102.475443,46.702468],[-102.52523,47.183838],[-102.525192,47.212836],[-102.631251,47.212756],[-102.699747,47.082452]]]},"properties":{"id":"58630","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.587828,47.533576],[-102.525192,47.212836],[-102.52523,47.183838],[-102.42014,47.145173],[-102.229292,47.157462],[-102.205533,47.429317],[-102.20535,47.574506],[-102.263452,47.619607],[-102.587828,47.533576]]]},"properties":{"id":"58636","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.988919,46.633058],[-100.037407,46.720122],[-100.079871,46.711292],[-100.241939,46.633535],[-100.042989,46.487226],[-99.988919,46.633058]]]},"properties":{"id":"58524","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.554299,46.371294],[-100.105993,46.414532],[-100.042989,46.487226],[-100.241939,46.633535],[-100.459463,46.634341],[-100.501281,46.634464],[-100.66189,46.63445],[-100.590199,46.427894],[-100.554299,46.371294]]]},"properties":{"id":"58544","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.628976,46.146001],[-100.511949,45.943712],[-100.430319,45.943534],[-100.628976,46.146001]]]},"properties":{"id":"57648","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.73496,47.529563],[-98.819712,47.471833],[-98.796079,47.418096],[-98.584675,47.347929],[-98.499833,47.442454],[-98.499905,47.45649],[-98.73496,47.529563]]]},"properties":{"id":"58443","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.076128,47.918547],[-97.238844,47.918487],[-97.239013,47.817269],[-96.993983,47.846],[-97.028467,47.92692],[-97.066248,47.91853],[-97.076128,47.918547]]]},"properties":{"id":"58201","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.665555,48.194812],[-97.709245,48.122312],[-97.708944,48.078898],[-97.644196,48.078816],[-97.514137,48.165737],[-97.665555,48.194812]]]},"properties":{"id":"58244","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.665555,48.194812],[-97.514137,48.165737],[-97.38379,48.19496],[-97.491881,48.267182],[-97.687249,48.23829],[-97.665555,48.194812]]]},"properties":{"id":"58233","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.854368,46.617198],[-101.873845,46.190931],[-101.873891,46.147408],[-101.708081,46.180773],[-101.6965,46.577873],[-101.717101,46.588673],[-101.854368,46.617198]]]},"properties":{"id":"58533","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.994615,47.326157],[-98.341536,47.406163],[-98.414109,47.313142],[-98.310635,47.240733],[-97.99257,47.240697],[-97.994615,47.326157]]]},"properties":{"id":"58448","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.455726,46.457048],[-99.419779,46.17201],[-99.315952,46.142891],[-99.287747,46.456794],[-99.361581,46.500158],[-99.401572,46.500128],[-99.455726,46.457048]]]},"properties":{"id":"58460","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.646873,48.545504],[-100.537881,48.558901],[-100.537925,48.581137],[-100.668066,48.56744],[-100.668103,48.562992],[-100.646873,48.545504]]]},"properties":{"id":"58713","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.88006,45.941423],[-99.671894,45.94101],[-99.753275,46.129322],[-99.878763,46.173505],[-99.88006,45.941423]]]},"properties":{"id":"58581","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.992012,47.970746],[-104.042498,47.806791],[-104.043598,47.660527],[-103.800674,47.618498],[-103.780928,47.953416],[-103.992012,47.970746]]]},"properties":{"id":"58838","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.711409,47.731915],[-101.893371,47.789719],[-102.021984,47.688415],[-101.711409,47.731915]]]},"properties":{"id":"58775","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.534468,48.02289],[-101.678895,47.746403],[-101.37889,47.776039],[-101.357393,48.022903],[-101.534468,48.02289]]]},"properties":{"id":"58735","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.719494,47.674142],[-100.821353,47.688727],[-101.078874,47.717782],[-101.056659,47.661444],[-101.036002,47.50202],[-101.014476,47.458438],[-100.886521,47.349324],[-100.719494,47.674142]]]},"properties":{"id":"58575","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.31172,47.882587],[-98.525602,47.730342],[-98.371692,47.6724],[-98.263044,47.672442],[-98.204296,47.672376],[-98.268732,47.846087],[-98.31172,47.882587]]]},"properties":{"id":"58361","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.60095,48.44165],[-97.512918,48.543053],[-97.557321,48.543088],[-97.796673,48.572073],[-97.818533,48.470825],[-97.60095,48.44165]]]},"properties":{"id":"58243","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.075659,47.64622],[-100.068934,47.91197],[-100.203333,47.673184],[-100.075659,47.64622]]]},"properties":{"id":"58758","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.005575,48.281801],[-98.660289,48.281783],[-98.551857,48.252855],[-98.557724,48.37576],[-98.663439,48.370104],[-99.00562,48.354383],[-99.005575,48.281801]]]},"properties":{"id":"58382","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.94842,46.210875],[-96.92522,46.354768],[-97.009199,46.398129],[-97.050752,46.369188],[-97.031252,46.210255],[-96.94842,46.210875]]]},"properties":{"id":"58008","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.350712,48.7182],[-99.416567,48.833988],[-99.460505,48.834007],[-99.547775,48.732872],[-99.49309,48.675225],[-99.350712,48.7182]]]},"properties":{"id":"58363","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.571072,47.080919],[-98.46705,47.298442],[-98.563214,47.326548],[-98.721122,47.167924],[-98.604816,47.080816],[-98.571072,47.080919]]]},"properties":{"id":"58426","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.122603,48.543266],[-98.318336,48.398718],[-98.31867,48.369722],[-98.141495,48.281806],[-97.94891,48.369351],[-97.927113,48.456208],[-98.057245,48.543258],[-98.122603,48.543266]]]},"properties":{"id":"58210","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.339376,48.425844],[-101.326444,48.42088],[-101.329259,48.418232],[-101.339404,48.42299],[-101.299765,48.418683],[-101.342655,48.429623],[-101.339376,48.425844]]]},"properties":{"id":"58704","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.519025,46.249285],[-102.502569,46.629968],[-102.656216,46.629878],[-102.799883,46.368667],[-102.74701,46.249265],[-102.519025,46.249285]]]},"properties":{"id":"58650","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.519025,46.249285],[-102.33113,46.162226],[-102.123019,46.162113],[-102.050998,46.630557],[-102.096585,46.631024],[-102.369573,46.630185],[-102.502569,46.629968],[-102.519025,46.249285]]]},"properties":{"id":"58646","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.854115,47.095064],[-97.80791,46.810505],[-97.723738,46.832994],[-97.747903,47.094627],[-97.833046,47.12406],[-97.854115,47.095064]]]},"properties":{"id":"58063","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.388298,46.759855],[-97.450889,46.832384],[-97.630255,46.818192],[-97.723738,46.832994],[-97.80791,46.810505],[-97.980383,46.7603],[-97.892039,46.717269],[-97.765901,46.690517],[-97.430106,46.730985],[-97.388298,46.759855]]]},"properties":{"id":"58031","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.705863,47.210666],[-97.769411,47.225273],[-97.833287,47.167609],[-97.705863,47.210666]]]},"properties":{"id":"58065","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.082133,47.934569],[-99.047564,48.040056],[-99.137849,48.041549],[-99.345645,47.977138],[-99.33154,47.846805],[-99.082133,47.934569]]]},"properties":{"id":"58357","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.447503,47.817993],[-99.720118,47.89053],[-99.940905,48.021565],[-100.069069,47.992057],[-100.068934,47.91197],[-100.075659,47.64622],[-100.032869,47.588237],[-99.905144,47.588214],[-99.777238,47.544632],[-99.71439,47.573663],[-99.447548,47.788984],[-99.447503,47.817993]]]},"properties":{"id":"58341","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.480673,47.52519],[-103.656837,47.329259],[-103.656346,47.329255],[-103.096291,47.269886],[-103.085789,47.328754],[-103.099509,47.548956],[-103.329705,47.59262],[-103.480673,47.52519]]]},"properties":{"id":"58634","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.537881,48.558901],[-100.276424,48.472539],[-99.928203,48.545041],[-100.102754,48.69042],[-100.21285,48.779091],[-100.472647,48.690264],[-100.537925,48.581137],[-100.537881,48.558901]]]},"properties":{"id":"58384","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.046044,46.08779],[-103.812751,46.54075],[-104.045108,46.540929],[-104.046044,46.08779]]]},"properties":{"id":"58643","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.502641,48.807382],[-102.568014,48.92321],[-102.655804,48.937858],[-102.633844,48.720467],[-102.546301,48.764111],[-102.502641,48.807382]]]},"properties":{"id":"58752","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.416488,46.715567],[-100.454441,46.98228],[-100.601092,46.982599],[-100.583134,46.836956],[-100.636507,46.794361],[-100.501281,46.634464],[-100.459463,46.634341],[-100.416488,46.715567]]]},"properties":{"id":"58558","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.452215,46.98235],[-100.222883,46.981746],[-100.115139,47.025183],[-100.113261,47.327262],[-100.203335,47.327364],[-100.260922,47.327219],[-100.389452,47.327484],[-100.474491,47.069342],[-100.452215,46.98235]]]},"properties":{"id":"58494","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.937422,47.093479],[-100.51638,47.069571],[-100.621529,47.327419],[-100.673445,47.32772],[-100.801465,47.338865],[-100.965156,47.156631],[-100.937422,47.093479]]]},"properties":{"id":"58579","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.241939,46.633535],[-100.079871,46.711292],[-100.289642,46.764424],[-100.416488,46.715567],[-100.459463,46.634341],[-100.241939,46.633535]]]},"properties":{"id":"58560","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.800685,46.897596],[-96.821098,46.8976],[-96.815593,46.890399],[-96.802064,46.890377],[-96.800685,46.897596]]]},"properties":{"id":"58105","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.070041,47.166542],[-96.833393,47.238041],[-96.84165,47.29562],[-97.133786,47.282176],[-97.155056,47.282242],[-97.070041,47.166542]]]},"properties":{"id":"58038","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.133059,48.999882],[-99.00067,48.862893],[-98.8689,48.920843],[-98.737106,49.000188],[-99.133059,48.999882]]]},"properties":{"id":"58372","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.556992,46.282873],[-98.796832,46.185069],[-98.828773,46.112549],[-98.675308,45.938413],[-98.444684,45.936664],[-98.359454,46.066273],[-98.481026,46.282931],[-98.556992,46.282873]]]},"properties":{"id":"58436","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.348353,46.730923],[-102.369573,46.630185],[-102.096585,46.631024],[-102.165579,47.098525],[-102.229292,47.157462],[-102.42014,47.145173],[-102.348353,46.730923]]]},"properties":{"id":"58652","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.205533,47.429317],[-102.229292,47.157462],[-102.165579,47.098525],[-102.1445,47.12613],[-102.205533,47.429317]]]},"properties":{"id":"58625","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.385758,47.758008],[-102.784704,47.862013],[-102.784761,47.734196],[-102.785275,47.594895],[-102.714889,47.573582],[-102.587828,47.533576],[-102.263452,47.619607],[-102.261551,47.724308],[-102.385758,47.758008]]]},"properties":{"id":"58757","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.755982,47.645488],[-98.883488,47.558403],[-98.819712,47.471833],[-98.73496,47.529563],[-98.755982,47.645488]]]},"properties":{"id":"58445","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.628976,46.146001],[-100.430319,45.943534],[-100.28559,45.942938],[-100.004054,46.195609],[-99.878647,46.216946],[-99.878431,46.282811],[-100.105993,46.414532],[-100.554299,46.371294],[-100.571263,46.262317],[-100.628976,46.146001]]]},"properties":{"id":"58552","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.840294,47.326906],[-98.782656,47.153464],[-98.721122,47.167924],[-98.563214,47.326548],[-98.584675,47.347929],[-98.796079,47.418096],[-98.840294,47.326906]]]},"properties":{"id":"58455","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.584675,47.347929],[-98.563214,47.326548],[-98.46705,47.298442],[-98.414109,47.313142],[-98.341536,47.406163],[-98.354938,47.413444],[-98.499833,47.442454],[-98.584675,47.347929]]]},"properties":{"id":"58484","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.237257,48.0198],[-97.303678,47.976671],[-97.238844,47.918487],[-97.076128,47.918547],[-97.081069,47.922277],[-97.066392,47.922272],[-97.066248,47.91853],[-97.028467,47.92692],[-97.123202,48.10665],[-97.237257,48.0198]]]},"properties":{"id":"58203","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.473388,47.831191],[-97.581316,47.845695],[-97.882112,47.845994],[-97.882157,47.831628],[-97.882405,47.672179],[-97.686442,47.643924],[-97.324866,47.730219],[-97.366352,47.788555],[-97.473388,47.831191]]]},"properties":{"id":"58267","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.708944,48.078898],[-97.882112,47.845994],[-97.581316,47.845695],[-97.514534,48.020734],[-97.644196,48.078816],[-97.708944,48.078898]]]},"properties":{"id":"58251","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.903454,48.093465],[-97.946708,47.875077],[-97.882157,47.831628],[-97.882112,47.845994],[-97.708944,48.078898],[-97.709245,48.122312],[-97.903454,48.093465]]]},"properties":{"id":"58266","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.38026,47.955863],[-97.382915,47.947633],[-97.375825,47.947636],[-97.38026,47.955863]]]},"properties":{"id":"58205","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.189627,48.338719],[-97.188859,48.193702],[-97.141233,48.193602],[-97.145618,48.358032],[-97.189627,48.338719]]]},"properties":{"id":"56744","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.891047,47.024605],[-102.013044,46.630836],[-101.854368,46.617198],[-101.717101,46.588673],[-101.650509,46.803982],[-101.700975,46.981253],[-101.869028,47.067219],[-101.891047,47.024605]]]},"properties":{"id":"58631","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.115139,47.025183],[-99.98796,47.039491],[-99.861983,47.067846],[-99.863262,47.230685],[-99.862311,47.327047],[-100.033292,47.327192],[-100.113261,47.327262],[-100.115139,47.025183]]]},"properties":{"id":"58488","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.480726,47.327076],[-99.608031,47.241028],[-99.671573,47.067576],[-99.618661,47.038596],[-99.481304,47.023694],[-99.480916,47.291631],[-99.480726,47.327076]]]},"properties":{"id":"58475","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.575097,46.631965],[-99.401572,46.500128],[-99.361581,46.500158],[-99.300994,46.732805],[-99.469107,46.762148],[-99.575097,46.631965]]]},"properties":{"id":"58483","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.599928,46.529304],[-98.515527,46.587447],[-98.460169,46.674607],[-98.670589,46.703377],[-98.881971,46.725236],[-98.704328,46.558504],[-98.599928,46.529304]]]},"properties":{"id":"58472","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.361581,46.500158],[-99.287747,46.456794],[-99.037292,46.3992],[-99.036931,46.486198],[-99.174916,46.732232],[-99.195471,46.776005],[-99.300994,46.732805],[-99.361581,46.500158]]]},"properties":{"id":"58442","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-100.689789,48.530945],[-100.646873,48.545504],[-100.668103,48.562992],[-100.689789,48.530945]]],[[[-100.689789,48.530945],[-100.773599,48.429451],[-100.712483,48.181711],[-100.561497,48.193727],[-100.276424,48.472539],[-100.537881,48.558901],[-100.646873,48.545504],[-100.689789,48.530945]]]]},"properties":{"id":"58788","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.798767,48.138292],[-100.842943,47.848373],[-100.735684,47.847972],[-100.58525,47.86223],[-100.649875,48.022373],[-100.755396,48.150031],[-100.798767,48.138292]]]},"properties":{"id":"58792","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.036002,47.50202],[-101.369986,47.478066],[-101.420009,47.494477],[-101.406251,47.416018],[-101.358224,47.342276],[-101.014476,47.458438],[-101.036002,47.50202]]]},"properties":{"id":"58576","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.369986,47.478066],[-101.416347,47.496746],[-101.420009,47.494477],[-101.369986,47.478066]]]},"properties":{"id":"58565","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.261551,47.724308],[-102.107713,47.701752],[-101.990141,47.83316],[-102.116825,48.095544],[-102.181156,48.095476],[-102.385758,47.758008],[-102.261551,47.724308]]]},"properties":{"id":"58770","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.834058,46.822035],[-100.935983,46.98284],[-101.235657,46.98124],[-101.215346,46.660043],[-101.04856,46.631581],[-100.816328,46.516147],[-100.720504,46.379745],[-100.590199,46.427894],[-100.66189,46.63445],[-100.826458,46.818127],[-100.834058,46.822035]]]},"properties":{"id":"58554","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.511207,47.212446],[-101.637454,47.096961],[-101.700975,46.981253],[-101.650509,46.803982],[-101.361655,46.630354],[-101.215346,46.660043],[-101.235657,46.98124],[-101.384298,47.169181],[-101.511207,47.212446]]]},"properties":{"id":"58563","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.011472,48.1945],[-98.05395,47.875111],[-98.032367,47.87513],[-97.946708,47.875077],[-97.903454,48.093465],[-97.903352,48.150688],[-97.989932,48.194791],[-98.011472,48.1945]]]},"properties":{"id":"58272","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.465416,48.136929],[-98.184419,48.151507],[-98.097957,48.1948],[-98.168047,48.252871],[-98.551824,48.238364],[-98.465416,48.136929]]]},"properties":{"id":"58321","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.447282,48.710286],[-97.365317,48.542908],[-97.137372,48.413436],[-97.157625,48.787544],[-97.22949,48.78948],[-97.447282,48.710286]]]},"properties":{"id":"58225","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.409147,46.629445],[-97.531263,46.514789],[-97.406147,46.441915],[-97.280202,46.456504],[-97.409147,46.629445]]]},"properties":{"id":"58068","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.710933,48.544941],[-99.493185,48.573809],[-99.49309,48.675225],[-99.547775,48.732872],[-99.623228,48.718473],[-99.710933,48.544941]]]},"properties":{"id":"58353","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.55079,46.025146],[-97.63405,45.935345],[-97.395887,45.935967],[-97.419931,45.994189],[-97.55079,46.025146]]]},"properties":{"id":"58043","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.326506,47.381975],[-97.21882,47.367453],[-97.177543,47.454477],[-97.199033,47.526744],[-97.282709,47.642793],[-97.346414,47.570594],[-97.367898,47.411108],[-97.326506,47.381975]]]},"properties":{"id":"58257","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.600649,48.339712],[-97.810746,48.296355],[-97.687249,48.23829],[-97.491881,48.267182],[-97.535569,48.296147],[-97.600649,48.339712]]]},"properties":{"id":"58273","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.60883,48.312849],[-101.6631,48.124492],[-101.55544,48.051927],[-101.533826,48.096118],[-101.60883,48.312849]]]},"properties":{"id":"58733","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.74701,46.249265],[-102.870629,45.945069],[-102.390523,45.944936],[-102.33113,46.162226],[-102.519025,46.249285],[-102.74701,46.249265]]]},"properties":{"id":"58639","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.925322,46.336015],[-102.995668,45.945163],[-102.870629,45.945069],[-102.74701,46.249265],[-102.799883,46.368667],[-102.925322,46.336015]]]},"properties":{"id":"58649","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.760736,47.970912],[-98.94524,48.002699],[-98.9529,47.963646],[-98.845569,47.934741],[-98.7763,47.934576],[-98.760736,47.970912]]]},"properties":{"id":"58370","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.480749,48.151418],[-99.345645,47.977138],[-99.137849,48.041549],[-99.135416,48.180358],[-99.286719,48.206692],[-99.480749,48.151418]]]},"properties":{"id":"58351","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.345645,47.977138],[-99.480749,48.151418],[-99.524086,48.151502],[-99.653515,48.143887],[-99.720118,47.89053],[-99.447503,47.817993],[-99.33154,47.846805],[-99.345645,47.977138]]]},"properties":{"id":"58348","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.940905,48.021565],[-99.720118,47.89053],[-99.653515,48.143887],[-99.718219,48.195248],[-99.782747,48.209497],[-99.940905,48.021565]]]},"properties":{"id":"58332","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.524086,48.151502],[-99.493744,48.443388],[-99.493242,48.486929],[-99.621797,48.371066],[-99.718219,48.195248],[-99.653515,48.143887],[-99.524086,48.151502]]]},"properties":{"id":"58386","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.866863,47.760358],[-98.761056,47.68703],[-98.664955,47.694369],[-98.616639,47.91644],[-98.697263,48.003463],[-98.760736,47.970912],[-98.7763,47.934576],[-98.845659,47.877024],[-98.866863,47.760358]]]},"properties":{"id":"58381","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.217031,46.366708],[-103.300521,46.629829],[-103.482887,46.672323],[-103.482847,46.629759],[-103.217031,46.366708]]]},"properties":{"id":"58620","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.715499,48.82135],[-101.635507,48.633818],[-101.300042,48.734224],[-101.278114,48.821064],[-101.453422,48.937015],[-101.715499,48.82135]]]},"properties":{"id":"58761","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.921248,48.99905],[-100.314582,48.999283],[-100.21285,48.779091],[-100.102754,48.69042],[-99.920122,48.791234],[-99.919749,48.907325],[-99.921248,48.99905]]]},"properties":{"id":"58329","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.972869,48.560082],[-100.800654,48.681832],[-100.872438,48.777384],[-100.893622,48.806708],[-101.037446,48.719526],[-101.05966,48.545337],[-100.972869,48.560082]]]},"properties":{"id":"58762","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.689789,48.530945],[-100.668103,48.562992],[-100.668066,48.56744],[-100.800654,48.681832],[-100.972869,48.560082],[-100.842053,48.443995],[-100.773599,48.429451],[-100.689789,48.530945]]]},"properties":{"id":"58789","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.046044,46.08779],[-103.733406,45.945087],[-103.559052,46.629775],[-103.60921,46.629797],[-103.812751,46.54075],[-104.046044,46.08779]]]},"properties":{"id":"58651","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.451692,48.633458],[-102.546301,48.764111],[-102.633844,48.720467],[-102.885748,48.662254],[-102.842713,48.546404],[-102.788194,48.430295],[-102.699307,48.372589],[-102.624864,48.380059],[-102.435619,48.603296],[-102.451692,48.633458]]]},"properties":{"id":"58773","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.655804,48.937858],[-102.568014,48.92321],[-102.552331,48.998993],[-102.805208,48.999332],[-102.655804,48.937858]]]},"properties":{"id":"58772","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.474491,47.069342],[-100.389452,47.327484],[-100.621529,47.327419],[-100.51638,47.069571],[-100.474491,47.069342]]]},"properties":{"id":"58477","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.221221,46.754043],[-96.989926,46.804377],[-96.967585,46.920647],[-97.070361,46.977319],[-97.210766,46.873844],[-97.284332,46.759809],[-97.221221,46.754043]]]},"properties":{"id":"58059","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.15729,46.687507],[-96.947073,46.717289],[-96.989926,46.804377],[-97.221221,46.754043],[-97.15729,46.687507]]]},"properties":{"id":"58021","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.853319,46.876404],[-96.776208,46.87386],[-96.820037,46.976818],[-96.858357,46.97705],[-96.903738,46.934479],[-96.853319,46.876404]],[[-96.800685,46.897596],[-96.802064,46.890377],[-96.815593,46.890399],[-96.821098,46.8976],[-96.800685,46.897596]]]},"properties":{"id":"58102","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.070321,46.980652],[-96.858357,46.97705],[-96.820037,46.976818],[-96.840122,47.150475],[-97.069547,47.079396],[-97.133234,47.064744],[-97.133373,47.035226],[-97.070321,46.980652]]]},"properties":{"id":"58005","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.239996,46.992009],[-97.366983,46.97777],[-97.388546,46.977839],[-97.429531,46.962727],[-97.450889,46.832384],[-97.388298,46.759855],[-97.284332,46.759809],[-97.210766,46.873844],[-97.239996,46.992009]]]},"properties":{"id":"58079","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.451178,47.180959],[-97.366591,47.180966],[-97.303694,47.296578],[-97.346567,47.332768],[-97.515243,47.311462],[-97.558005,47.253487],[-97.451178,47.180959]]]},"properties":{"id":"58035","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.069547,47.079396],[-96.840122,47.150475],[-96.833393,47.238041],[-97.070041,47.166542],[-97.069852,47.122926],[-97.069547,47.079396]]]},"properties":{"id":"58036","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.345273,47.137082],[-97.069852,47.122926],[-97.070041,47.166542],[-97.155056,47.282242],[-97.303694,47.296578],[-97.366591,47.180966],[-97.345273,47.137082]]]},"properties":{"id":"58048","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.797787,49.000555],[-98.206438,49.000279],[-98.026491,48.761054],[-97.949917,48.758821],[-97.709087,48.905771],[-97.797787,49.000555]]]},"properties":{"id":"58282","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.298252,48.705691],[-103.320216,48.502968],[-103.081318,48.502985],[-103.037524,48.589956],[-103.005943,48.720568],[-103.136774,48.807389],[-103.298252,48.705691]]]},"properties":{"id":"58795","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.52523,47.183838],[-102.475443,46.702468],[-102.348353,46.730923],[-102.42014,47.145173],[-102.52523,47.183838]]]},"properties":{"id":"58656","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.165579,47.098525],[-102.096585,46.631024],[-102.050998,46.630557],[-102.013044,46.630836],[-101.891047,47.024605],[-101.997649,47.157051],[-102.1445,47.12613],[-102.165579,47.098525]]]},"properties":{"id":"58638","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.085789,47.328754],[-102.672747,47.256062],[-102.714889,47.573582],[-102.785275,47.594895],[-103.099509,47.548956],[-103.085789,47.328754]]]},"properties":{"id":"58640","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.191714,45.942645],[-99.88006,45.941423],[-99.878763,46.173505],[-99.878647,46.216946],[-100.004054,46.195609],[-100.191714,45.942645]]]},"properties":{"id":"58542","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.324866,47.730219],[-97.686442,47.643924],[-97.707647,47.57185],[-97.643696,47.556708],[-97.346414,47.570594],[-97.282709,47.642793],[-97.261225,47.649558],[-97.324866,47.730219]]]},"properties":{"id":"58240","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.644196,48.078816],[-97.514534,48.020734],[-97.472103,48.020683],[-97.384241,48.093329],[-97.38379,48.19496],[-97.514137,48.165737],[-97.644196,48.078816]]]},"properties":{"id":"58235","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.363145,48.09301],[-97.188859,48.193702],[-97.189627,48.338719],[-97.535569,48.296147],[-97.491881,48.267182],[-97.38379,48.19496],[-97.384241,48.093329],[-97.363145,48.09301]]]},"properties":{"id":"58261","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.903352,48.150688],[-97.903454,48.093465],[-97.709245,48.122312],[-97.665555,48.194812],[-97.687249,48.23829],[-97.810746,48.296355],[-97.860194,48.297572],[-98.033052,48.194646],[-98.011472,48.1945],[-97.989932,48.194791],[-97.903434,48.194897],[-97.903352,48.150688]]]},"properties":{"id":"58231","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.066248,47.91853],[-97.066392,47.922272],[-97.081069,47.922277],[-97.076128,47.918547],[-97.066248,47.91853]]]},"properties":{"id":"58202","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.361655,46.630354],[-101.650509,46.803982],[-101.717101,46.588673],[-101.6965,46.577873],[-101.466069,46.554123],[-101.361655,46.630354]]]},"properties":{"id":"58520","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.873891,46.147408],[-101.874437,45.944743],[-101.64535,45.94426],[-101.624794,46.048876],[-101.708081,46.180773],[-101.873891,46.147408]]]},"properties":{"id":"57645","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.790302,47.067751],[-99.861983,47.067846],[-99.98796,47.039491],[-100.037407,46.720122],[-99.988919,46.633058],[-99.852513,46.632534],[-99.828417,46.632476],[-99.790302,47.067751]]]},"properties":{"id":"58482","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.861983,47.067846],[-99.790302,47.067751],[-99.671573,47.067576],[-99.608031,47.241028],[-99.774219,47.327239],[-99.863262,47.230685],[-99.861983,47.067846]]]},"properties":{"id":"58478","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.556992,46.282873],[-98.481026,46.282931],[-98.473472,46.282942],[-98.494583,46.442314],[-98.536645,46.442381],[-98.556992,46.282873]]]},"properties":{"id":"58415","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.455726,46.457048],[-99.874793,46.282948],[-99.878431,46.282811],[-99.878647,46.216946],[-99.878763,46.173505],[-99.753275,46.129322],[-99.419779,46.17201],[-99.455726,46.457048]]]},"properties":{"id":"58495","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.649875,48.022373],[-100.496334,48.123573],[-100.561497,48.193727],[-100.712483,48.181711],[-100.755396,48.150031],[-100.649875,48.022373]]]},"properties":{"id":"58744","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.496334,48.123573],[-100.649875,48.022373],[-100.58525,47.86223],[-100.509838,47.862147],[-100.348892,47.963386],[-100.453097,48.094745],[-100.496334,48.123573]]]},"properties":{"id":"58712","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.990141,47.83316],[-102.107713,47.701752],[-102.021984,47.688415],[-101.893371,47.789719],[-101.711409,47.731915],[-101.678895,47.746403],[-101.534468,48.02289],[-101.55544,48.051927],[-101.6631,48.124492],[-101.696696,48.110093],[-101.708954,48.098726],[-101.89338,47.847639],[-101.990141,47.83316]]]},"properties":{"id":"58779","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.185914,47.674277],[-101.056659,47.661444],[-101.078874,47.717782],[-100.971546,47.877107],[-100.993109,47.938944],[-101.099559,47.891963],[-101.185914,47.674277]]]},"properties":{"id":"58716","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.20535,47.574506],[-102.205533,47.429317],[-102.1445,47.12613],[-101.997649,47.157051],[-101.918559,47.457925],[-101.850549,47.500002],[-102.20535,47.574506]]]},"properties":{"id":"58541","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.320418,48.546302],[-102.246053,48.153463],[-102.181156,48.095476],[-102.116825,48.095544],[-102.138388,48.283759],[-102.103674,48.444848],[-102.233892,48.546339],[-102.320418,48.546302]]]},"properties":{"id":"58769","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.103674,48.444848],[-101.821406,48.401309],[-101.691057,48.618727],[-101.842591,48.647846],[-102.233892,48.546339],[-102.103674,48.444848]]]},"properties":{"id":"58734","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.081318,48.502985],[-103.087187,48.124645],[-102.828477,48.123664],[-102.788194,48.430295],[-102.842713,48.546404],[-103.037524,48.589956],[-103.081318,48.502985]]]},"properties":{"id":"58852","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.788194,48.430295],[-102.828477,48.123664],[-102.698685,48.110435],[-102.699307,48.372589],[-102.788194,48.430295]]]},"properties":{"id":"58794","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.965156,47.156631],[-101.257031,47.264895],[-101.384298,47.169181],[-101.235657,46.98124],[-100.935983,46.98284],[-100.909052,46.997587],[-100.937422,47.093479],[-100.965156,47.156631]]]},"properties":{"id":"58530","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.709087,48.905771],[-97.534509,48.862609],[-97.447322,48.948823],[-97.425171,49.000498],[-97.797787,49.000555],[-97.709087,48.905771]]]},"properties":{"id":"58265","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.621797,48.371066],[-99.493242,48.486929],[-99.493185,48.573809],[-99.710933,48.544941],[-99.798002,48.544889],[-99.798152,48.371159],[-99.621797,48.371066]]]},"properties":{"id":"58385","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.882428,46.354717],[-96.834487,46.211044],[-96.783547,46.080323],[-96.568418,46.123308],[-96.717941,46.441867],[-96.725258,46.441932],[-96.721811,46.448683],[-96.738508,46.498866],[-96.757818,46.499849],[-96.882428,46.354717]],[[-96.607512,46.271498],[-96.60966,46.271523],[-96.609584,46.27429],[-96.605349,46.273631],[-96.607512,46.271498]]]},"properties":{"id":"58075","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.92522,46.354768],[-96.94842,46.210875],[-96.897483,46.196443],[-96.834487,46.211044],[-96.882428,46.354717],[-96.92522,46.354768]]]},"properties":{"id":"58061","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.032111,46.153049],[-97.061247,45.935749],[-96.763769,45.935777],[-96.783547,46.080323],[-96.834487,46.211044],[-96.897483,46.196443],[-97.032256,46.1676],[-97.032111,46.153049]]]},"properties":{"id":"58041","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.280197,46.180791],[-97.322088,45.935746],[-97.061247,45.935749],[-97.032111,46.153049],[-97.259319,46.209852],[-97.280197,46.180791]]]},"properties":{"id":"58053","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.607512,46.271498],[-96.605349,46.273631],[-96.609584,46.27429],[-96.60966,46.271523],[-96.607512,46.271498]]]},"properties":{"id":"58076","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.945668,46.979176],[-98.604816,47.080816],[-98.721122,47.167924],[-98.782656,47.153464],[-99.10102,47.066522],[-98.945668,46.979176]]]},"properties":{"id":"58420","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.782656,47.153464],[-98.840294,47.326906],[-99.227809,47.326748],[-99.22748,47.269459],[-99.185738,47.066706],[-99.10102,47.066522],[-98.782656,47.153464]]]},"properties":{"id":"58476","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.416567,48.833988],[-99.350712,48.7182],[-99.231722,48.689466],[-99.088038,48.732635],[-99.000839,48.81946],[-99.00067,48.862893],[-99.133059,48.999882],[-99.5257,48.999307],[-99.525575,48.920849],[-99.416567,48.833988]]]},"properties":{"id":"58365","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.416567,48.833988],[-99.525575,48.920849],[-99.460505,48.834007],[-99.416567,48.833988]]]},"properties":{"id":"58339","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.274391,48.051868],[-101.099559,47.891963],[-100.993109,47.938944],[-101.088513,48.109651],[-101.274391,48.051868]]]},"properties":{"id":"58781","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.301647,48.245071],[-101.296058,48.245065],[-101.296034,48.247186],[-101.304911,48.247253],[-101.301647,48.245071]]]},"properties":{"id":"58707","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.447548,47.788984],[-99.71439,47.573663],[-99.554341,47.544375],[-99.425873,47.754937],[-99.404592,47.774463],[-99.447548,47.788984]]]},"properties":{"id":"58438","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.425873,47.754937],[-99.554341,47.544375],[-99.501032,47.500781],[-99.266152,47.50076],[-99.265811,47.594829],[-99.425873,47.754937]]]},"properties":{"id":"58422","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.873845,46.190931],[-101.854368,46.617198],[-102.013044,46.630836],[-102.050998,46.630557],[-102.123019,46.162113],[-101.873845,46.190931]]]},"properties":{"id":"58562","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.409147,46.629445],[-97.430106,46.730985],[-97.765901,46.690517],[-97.784081,46.558163],[-97.531263,46.514789],[-97.409147,46.629445]]]},"properties":{"id":"58027","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.46705,47.298442],[-98.571072,47.080919],[-98.530903,47.037595],[-98.404096,47.008526],[-98.340601,47.095633],[-98.340524,47.168137],[-98.310635,47.240733],[-98.414109,47.313142],[-98.46705,47.298442]]]},"properties":{"id":"58492","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.559052,46.629775],[-103.733406,45.945087],[-103.252742,45.945171],[-103.175736,46.366721],[-103.217031,46.366708],[-103.482847,46.629759],[-103.559052,46.629775]]]},"properties":{"id":"58623","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.583134,46.836956],[-100.834058,46.822035],[-100.826458,46.818127],[-100.636507,46.794361],[-100.583134,46.836956]],[[-100.784365,46.816588],[-100.785718,46.823626],[-100.774048,46.823605],[-100.777428,46.816535],[-100.784365,46.816588]]]},"properties":{"id":"58501","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.037407,46.720122],[-99.98796,47.039491],[-100.115139,47.025183],[-100.222883,46.981746],[-100.289642,46.764424],[-100.079871,46.711292],[-100.037407,46.720122]]]},"properties":{"id":"58532","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.454441,46.98228],[-100.416488,46.715567],[-100.289642,46.764424],[-100.222883,46.981746],[-100.452215,46.98235],[-100.454441,46.98228]]]},"properties":{"id":"58572","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.366983,46.97777],[-97.239996,46.992009],[-97.133373,47.035226],[-97.133234,47.064744],[-97.303574,47.064507],[-97.378235,47.050257],[-97.366983,46.97777]]]},"properties":{"id":"58004","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.057245,48.543258],[-97.927113,48.456208],[-97.818533,48.470825],[-97.796673,48.572073],[-97.796522,48.673498],[-97.818366,48.673498],[-97.927126,48.644438],[-98.057245,48.543258]]]},"properties":{"id":"58227","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.663439,48.370104],[-98.666239,48.428048],[-98.709551,48.572845],[-98.717352,48.587338],[-98.970392,48.544232],[-99.079736,48.37057],[-99.00562,48.354383],[-98.663439,48.370104]]]},"properties":{"id":"58377","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.024702,46.113673],[-98.880943,46.112871],[-98.828773,46.112549],[-98.796832,46.185069],[-98.955049,46.456979],[-99.036931,46.486198],[-99.037292,46.3992],[-99.024702,46.113673]]]},"properties":{"id":"58456","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.574814,48.771923],[-103.596652,48.999191],[-103.951052,48.999539],[-103.925412,48.909137],[-103.797568,48.691507],[-103.662361,48.735135],[-103.574814,48.771923]]]},"properties":{"id":"58844","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.033064,47.110716],[-103.105268,46.890616],[-102.999684,46.687885],[-102.663591,46.629985],[-102.600932,46.70235],[-102.699747,47.082452],[-103.032953,47.168727],[-103.033064,47.110716]]]},"properties":{"id":"58601","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.004054,46.195609],[-100.28559,45.942938],[-100.191714,45.942645],[-100.004054,46.195609]]]},"properties":{"id":"58573","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.045447,46.685828],[-104.045185,46.583662],[-103.902575,46.760042],[-104.045447,46.685828]]]},"properties":{"id":"58632","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.473388,47.831191],[-97.366352,47.788555],[-97.239013,47.817269],[-97.238844,47.918487],[-97.303678,47.976671],[-97.364239,47.972998],[-97.418984,47.954575],[-97.473388,47.831191]]]},"properties":{"id":"58228","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.6965,46.577873],[-101.708081,46.180773],[-101.624794,46.048876],[-101.561806,46.012792],[-101.374899,46.076716],[-101.445133,46.426144],[-101.466069,46.554123],[-101.6965,46.577873]]]},"properties":{"id":"58529","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.088513,48.109651],[-100.993109,47.938944],[-100.971546,47.877107],[-100.842943,47.848373],[-100.798767,48.138292],[-100.928912,48.181911],[-101.10125,48.138603],[-101.088513,48.109651]]]},"properties":{"id":"58790","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.928912,48.181911],[-100.798767,48.138292],[-100.755396,48.150031],[-100.712483,48.181711],[-100.773599,48.429451],[-100.842053,48.443995],[-100.941553,48.327109],[-100.928912,48.181911]]]},"properties":{"id":"58741","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.103429,48.371484],[-101.014153,48.341489],[-100.941553,48.327109],[-100.842053,48.443995],[-100.972869,48.560082],[-101.05966,48.545337],[-101.103429,48.371484]]]},"properties":{"id":"58731","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.044049,47.954703],[-102.784704,47.862013],[-102.385758,47.758008],[-102.181156,48.095476],[-102.246053,48.153463],[-102.547933,48.146178],[-102.698685,48.110435],[-102.828477,48.123664],[-103.087187,48.124645],[-103.044049,47.954703]]]},"properties":{"id":"58763","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.099559,47.891963],[-101.274391,48.051868],[-101.357393,48.022903],[-101.37889,47.776039],[-101.250256,47.688758],[-101.185914,47.674277],[-101.099559,47.891963]]]},"properties":{"id":"58759","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.918559,47.457925],[-101.869028,47.067219],[-101.700975,46.981253],[-101.637454,47.096961],[-101.694278,47.538625],[-101.850549,47.500002],[-101.918559,47.457925]]]},"properties":{"id":"58523","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.918559,47.457925],[-101.997649,47.157051],[-101.891047,47.024605],[-101.869028,47.067219],[-101.918559,47.457925]]]},"properties":{"id":"58580","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.816328,46.516147],[-101.061103,46.574233],[-101.048532,46.371393],[-101.025662,46.284219],[-100.752205,46.195722],[-100.752136,46.261823],[-100.720504,46.379745],[-100.816328,46.516147]]]},"properties":{"id":"58570","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.204296,47.672376],[-98.032367,47.87513],[-98.05395,47.875111],[-98.204169,47.860591],[-98.268732,47.846087],[-98.204296,47.672376]]]},"properties":{"id":"58254","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.184419,48.151507],[-98.204169,47.860591],[-98.05395,47.875111],[-98.011472,48.1945],[-98.033052,48.194646],[-98.097957,48.1948],[-98.184419,48.151507]]]},"properties":{"id":"58259","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.556876,48.717761],[-97.818405,48.702494],[-97.818366,48.673498],[-97.796522,48.673498],[-97.557302,48.644599],[-97.556876,48.717761]]]},"properties":{"id":"58241","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.709087,48.905771],[-97.949917,48.758821],[-97.92767,48.717682],[-97.818405,48.702494],[-97.556876,48.717761],[-97.512532,48.73229],[-97.556538,48.819068],[-97.534509,48.862609],[-97.709087,48.905771]]]},"properties":{"id":"58220","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.033725,46.514515],[-97.909259,46.282408],[-97.825483,46.311567],[-97.857738,46.535871],[-97.889306,46.543334],[-98.033652,46.543552],[-98.033725,46.514515]]]},"properties":{"id":"58033","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.965322,46.282503],[-97.694879,46.269123],[-97.694776,46.282429],[-97.825483,46.311567],[-97.909259,46.282408],[-97.965322,46.282503]]]},"properties":{"id":"58069","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.032256,46.1676],[-96.897483,46.196443],[-96.94842,46.210875],[-97.031252,46.210255],[-97.032256,46.1676]]]},"properties":{"id":"58058","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.721811,46.448683],[-96.725258,46.441932],[-96.717941,46.441867],[-96.721811,46.448683]]]},"properties":{"id":"58001","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.5257,48.999307],[-99.715365,48.999117],[-99.657023,48.913513],[-99.635381,48.718466],[-99.623228,48.718473],[-99.547775,48.732872],[-99.460505,48.834007],[-99.525575,48.920849],[-99.5257,48.999307]]]},"properties":{"id":"58367","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.861468,46.182181],[-98.008102,45.935896],[-97.696434,45.935469],[-97.674744,46.167713],[-97.861468,46.182181]]]},"properties":{"id":"58017","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.694879,46.269123],[-97.965322,46.282503],[-98.006715,46.282626],[-97.861468,46.182181],[-97.674744,46.167713],[-97.59115,46.189335],[-97.694879,46.269123]]]},"properties":{"id":"58040","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.787456,46.030694],[-100.793764,45.943786],[-100.648156,45.94366],[-100.787456,46.030694]]]},"properties":{"id":"57642","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.900851,47.354904],[-97.643885,47.484334],[-97.643696,47.556708],[-97.707647,47.57185],[-97.985083,47.557603],[-97.985961,47.369524],[-97.900851,47.354904]]]},"properties":{"id":"58230","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.881971,46.725236],[-98.670589,46.703377],[-98.43907,46.819648],[-98.530903,47.037595],[-98.571072,47.080919],[-98.604816,47.080816],[-98.945668,46.979176],[-98.987262,46.73235],[-98.881971,46.725236]],[[-98.700173,46.913514],[-98.702514,46.916747],[-98.697074,46.915984],[-98.700173,46.913514]],[[-98.691724,46.877478],[-98.690888,46.882864],[-98.682208,46.881926],[-98.682239,46.877395],[-98.691724,46.877478]]]},"properties":{"id":"58401","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.858148,47.544908],[-97.199033,47.526744],[-97.177543,47.454477],[-96.899492,47.4614],[-96.850712,47.500006],[-96.858148,47.544908]]]},"properties":{"id":"58223","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.512918,48.543053],[-97.60095,48.44165],[-97.600649,48.339712],[-97.535569,48.296147],[-97.189627,48.338719],[-97.145618,48.358032],[-97.137372,48.413436],[-97.365317,48.542908],[-97.512918,48.543053]]]},"properties":{"id":"58237","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.032869,47.588237],[-100.033292,47.327192],[-99.862311,47.327047],[-99.905144,47.588214],[-100.032869,47.588237]]]},"properties":{"id":"58451","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.823358,48.074539],[-103.857817,48.092524],[-103.862984,48.070816],[-103.823358,48.074539]]]},"properties":{"id":"58853","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.33113,46.162226],[-102.390523,45.944936],[-101.874437,45.944743],[-101.873891,46.147408],[-101.873845,46.190931],[-102.123019,46.162113],[-102.33113,46.162226]]]},"properties":{"id":"57638","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.99257,47.240697],[-98.012187,47.153414],[-98.03224,47.116961],[-97.854115,47.095064],[-97.833046,47.12406],[-97.897158,47.167699],[-97.900851,47.354904],[-97.985961,47.369524],[-97.994615,47.326157],[-97.99257,47.240697]]]},"properties":{"id":"58056","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.137849,48.041549],[-99.047564,48.040056],[-98.94524,48.002699],[-98.760736,47.970912],[-98.697263,48.003463],[-98.660289,48.281783],[-99.005575,48.281801],[-99.005671,48.227504],[-99.135416,48.180358],[-99.137849,48.041549]]]},"properties":{"id":"58301","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.493744,48.443388],[-99.524086,48.151502],[-99.480749,48.151418],[-99.286719,48.206692],[-99.221839,48.370688],[-99.449767,48.457847],[-99.493744,48.443388]]]},"properties":{"id":"58346","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.718219,48.195248],[-99.621797,48.371066],[-99.798152,48.371159],[-99.782747,48.209497],[-99.718219,48.195248]]]},"properties":{"id":"58343","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.845569,47.934741],[-98.845659,47.877024],[-98.7763,47.934576],[-98.845569,47.934741]]]},"properties":{"id":"58379","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.472647,48.690264],[-100.21285,48.779091],[-100.314582,48.999283],[-100.55564,48.999317],[-100.665114,48.762893],[-100.472647,48.690264]]]},"properties":{"id":"58318","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.102848,48.999297],[-101.234307,48.821019],[-101.037446,48.719526],[-100.893622,48.806708],[-100.958349,48.999485],[-101.102848,48.999297]]]},"properties":{"id":"58793","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.300042,48.734224],[-101.190214,48.574542],[-101.05966,48.545337],[-101.037446,48.719526],[-101.234307,48.821019],[-101.278114,48.821064],[-101.300042,48.734224]]]},"properties":{"id":"58760","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.451438,48.458987],[-101.342655,48.429623],[-101.299765,48.418683],[-101.143723,48.371548],[-101.103429,48.371484],[-101.05966,48.545337],[-101.190214,48.574542],[-101.4513,48.502534],[-101.451438,48.458987]]]},"properties":{"id":"58740","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.784365,46.816588],[-100.777428,46.816535],[-100.774048,46.823605],[-100.785718,46.823626],[-100.784365,46.816588]]]},"properties":{"id":"58505","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.239996,46.992009],[-97.210766,46.873844],[-97.070361,46.977319],[-97.070321,46.980652],[-97.133373,47.035226],[-97.239996,46.992009]]]},"properties":{"id":"58012","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.8689,48.920843],[-98.759065,48.848288],[-98.474866,48.935062],[-98.605944,49.000107],[-98.737106,49.000188],[-98.8689,48.920843]]]},"properties":{"id":"58239","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.951052,48.999539],[-104.048351,48.720996],[-103.925412,48.909137],[-103.951052,48.999539]]]},"properties":{"id":"59275","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-104.048351,48.720996],[-104.047295,48.445887],[-103.798105,48.474324],[-103.797568,48.691507],[-103.925412,48.909137],[-104.048351,48.720996]]]},"properties":{"id":"58845","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.525602,47.730342],[-98.664955,47.694369],[-98.761056,47.68703],[-98.755982,47.645488],[-98.73496,47.529563],[-98.499905,47.45649],[-98.371692,47.6724],[-98.525602,47.730342]]]},"properties":{"id":"58464","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.874793,46.282948],[-99.852513,46.632534],[-99.988919,46.633058],[-100.042989,46.487226],[-100.105993,46.414532],[-99.878431,46.282811],[-99.874793,46.282948]]]},"properties":{"id":"58549","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.581316,47.845695],[-97.473388,47.831191],[-97.418984,47.954575],[-97.406763,47.98721],[-97.472103,48.020683],[-97.514534,48.020734],[-97.581316,47.845695]]]},"properties":{"id":"58214","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.445133,46.426144],[-101.257405,46.371477],[-101.048532,46.371393],[-101.061103,46.574233],[-101.04856,46.631581],[-101.215346,46.660043],[-101.361655,46.630354],[-101.466069,46.554123],[-101.445133,46.426144]]]},"properties":{"id":"58535","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.618661,47.038596],[-99.702185,46.632455],[-99.575097,46.631965],[-99.469107,46.762148],[-99.446423,46.980889],[-99.481304,47.023694],[-99.618661,47.038596]]]},"properties":{"id":"58487","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.790302,47.067751],[-99.828417,46.632476],[-99.702185,46.632455],[-99.618661,47.038596],[-99.671573,47.067576],[-99.790302,47.067751]]]},"properties":{"id":"58428","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.259513,47.052226],[-99.446423,46.980889],[-99.469107,46.762148],[-99.300994,46.732805],[-99.195471,46.776005],[-99.259513,47.052226]]]},"properties":{"id":"58467","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.159755,46.442337],[-98.139318,46.282759],[-98.006715,46.282626],[-97.965322,46.282503],[-97.909259,46.282408],[-98.033725,46.514515],[-98.159755,46.442337]]]},"properties":{"id":"58490","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.852513,46.632534],[-99.874793,46.282948],[-99.455726,46.457048],[-99.401572,46.500128],[-99.575097,46.631965],[-99.702185,46.632455],[-99.828417,46.632476],[-99.852513,46.632534]]]},"properties":{"id":"58561","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-100.348892,47.963386],[-100.25864,48.050922],[-100.453097,48.094745],[-100.348892,47.963386]]],[[[-100.348892,47.963386],[-100.509838,47.862147],[-100.327492,47.731789],[-100.348892,47.963386]]]]},"properties":{"id":"58736","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.821353,47.688727],[-100.719494,47.674142],[-100.611861,47.673824],[-100.327376,47.68831],[-100.32716,47.702825],[-100.327492,47.731789],[-100.509838,47.862147],[-100.58525,47.86223],[-100.735684,47.847972],[-100.821353,47.688727]]]},"properties":{"id":"58723","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.078874,47.717782],[-100.821353,47.688727],[-100.735684,47.847972],[-100.842943,47.848373],[-100.971546,47.877107],[-101.078874,47.717782]]]},"properties":{"id":"58778","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.780928,47.953416],[-103.800674,47.618498],[-103.55781,47.58829],[-103.499048,48.005597],[-103.780928,47.953416]]]},"properties":{"id":"58831","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.62437,48.488856],[-103.798105,48.474324],[-104.047295,48.445887],[-104.04412,47.996107],[-103.992012,47.970746],[-103.780928,47.953416],[-103.499048,48.005597],[-103.47536,48.012247],[-103.472317,48.430745],[-103.62437,48.488856]],[[-103.823358,48.074539],[-103.862984,48.070816],[-103.857817,48.092524],[-103.823358,48.074539]]]},"properties":{"id":"58801","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.89338,47.847639],[-101.914748,47.978054],[-101.708954,48.098726],[-101.696696,48.110093],[-102.138388,48.283759],[-102.116825,48.095544],[-101.990141,47.83316],[-101.89338,47.847639]]]},"properties":{"id":"58771","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.816328,46.516147],[-101.04856,46.631581],[-101.061103,46.574233],[-100.816328,46.516147]]]},"properties":{"id":"58566","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.699307,48.372589],[-102.698685,48.110435],[-102.547933,48.146178],[-102.624864,48.380059],[-102.699307,48.372589]]]},"properties":{"id":"58776","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-102.103674,48.444848],[-102.138388,48.283759],[-101.696696,48.110093],[-101.6631,48.124492],[-101.60883,48.312849],[-101.440602,48.319041],[-101.451438,48.458987],[-101.4513,48.502534],[-101.587192,48.54639],[-101.821406,48.401309],[-102.103674,48.444848]]]},"properties":{"id":"58718","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.796673,48.572073],[-97.557321,48.543088],[-97.557302,48.644599],[-97.796522,48.673498],[-97.796673,48.572073]]]},"properties":{"id":"58222","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.135416,48.180358],[-99.005671,48.227504],[-99.17893,48.238328],[-99.135416,48.180358]]]},"properties":{"id":"58362","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.259319,46.209852],[-97.280691,46.369603],[-97.406355,46.413316],[-97.694776,46.282429],[-97.694879,46.269123],[-97.59115,46.189335],[-97.528793,46.152945],[-97.467536,46.123493],[-97.280197,46.180791],[-97.259319,46.209852]]]},"properties":{"id":"58060","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.882428,46.354717],[-96.757818,46.499849],[-97.009199,46.398129],[-96.92522,46.354768],[-96.882428,46.354717]]]},"properties":{"id":"58018","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.763769,45.935777],[-96.568418,46.123308],[-96.783547,46.080323],[-96.763769,45.935777]]]},"properties":{"id":"58030","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.259319,46.209852],[-97.032111,46.153049],[-97.032256,46.1676],[-97.031252,46.210255],[-97.050752,46.369188],[-97.134701,46.500455],[-97.280691,46.369603],[-97.259319,46.209852]]]},"properties":{"id":"58081","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.59115,46.189335],[-97.674744,46.167713],[-97.696434,45.935469],[-97.63405,45.935345],[-97.55079,46.025146],[-97.528793,46.152945],[-97.59115,46.189335]]]},"properties":{"id":"58032","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.322088,45.935746],[-97.280197,46.180791],[-97.467536,46.123493],[-97.419931,45.994189],[-97.395887,45.935967],[-97.322088,45.935746]]]},"properties":{"id":"58013","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.219795,47.673778],[-100.32716,47.702825],[-100.327376,47.68831],[-100.260922,47.327219],[-100.203335,47.327364],[-100.219795,47.673778]]]},"properties":{"id":"58430","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.561806,46.012792],[-101.540936,45.944168],[-101.333889,45.944097],[-101.335143,46.083779],[-101.374899,46.076716],[-101.561806,46.012792]]]},"properties":{"id":"57641","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.221839,48.370688],[-99.079736,48.37057],[-98.970392,48.544232],[-98.970337,48.558717],[-99.253715,48.602532],[-99.449767,48.457847],[-99.221839,48.370688]]]},"properties":{"id":"58324","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.350712,48.7182],[-99.49309,48.675225],[-99.493185,48.573809],[-99.493242,48.486929],[-99.493744,48.443388],[-99.449767,48.457847],[-99.253715,48.602532],[-99.231722,48.689466],[-99.350712,48.7182]]]},"properties":{"id":"58317","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.899492,47.4614],[-97.177543,47.454477],[-97.21882,47.367453],[-97.133786,47.282176],[-96.84165,47.29562],[-96.85528,47.45951],[-96.899492,47.4614]]]},"properties":{"id":"58045","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.94891,48.369351],[-98.141495,48.281806],[-98.168047,48.252871],[-98.097957,48.1948],[-98.033052,48.194646],[-97.860194,48.297572],[-97.94891,48.369351]]]},"properties":{"id":"58250","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.402622,48.250947],[-101.166163,48.237848],[-101.143723,48.371548],[-101.299765,48.418683],[-101.339404,48.42299],[-101.339376,48.425844],[-101.342655,48.429623],[-101.451438,48.458987],[-101.440602,48.319041],[-101.402622,48.250947]],[[-101.301647,48.245071],[-101.304911,48.247253],[-101.296034,48.247186],[-101.296058,48.245065],[-101.301647,48.245071]]]},"properties":{"id":"58703","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.293011,48.231639],[-101.293024,48.232639],[-101.294554,48.232649],[-101.294561,48.231643],[-101.293011,48.231639]]]},"properties":{"id":"58702","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.20271,48.134905],[-103.320184,48.466764],[-103.472317,48.430745],[-103.47536,48.012247],[-103.410002,48.032781],[-103.20271,48.134905]]]},"properties":{"id":"58843","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.530903,47.037595],[-98.43907,46.819648],[-98.375941,46.790983],[-98.333036,46.805417],[-98.404096,47.008526],[-98.530903,47.037595]]]},"properties":{"id":"58481","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.082133,47.934569],[-98.9529,47.963646],[-98.94524,48.002699],[-99.047564,48.040056],[-99.082133,47.934569]]]},"properties":{"id":"58335","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.616639,47.91644],[-98.664955,47.694369],[-98.525602,47.730342],[-98.31172,47.882587],[-98.525582,47.947692],[-98.616639,47.91644]]]},"properties":{"id":"58380","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.393081,47.146551],[-103.033064,47.110716],[-103.032953,47.168727],[-103.096291,47.269886],[-103.656346,47.329255],[-103.393081,47.146551]]]},"properties":{"id":"58627","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.032953,47.168727],[-102.699747,47.082452],[-102.631251,47.212756],[-102.672747,47.256062],[-103.085789,47.328754],[-103.096291,47.269886],[-103.032953,47.168727]]]},"properties":{"id":"58642","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.453422,48.937015],[-101.278114,48.821064],[-101.234307,48.821019],[-101.102848,48.999297],[-101.311897,48.99939],[-101.453422,48.937015]]]},"properties":{"id":"58711","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.937422,47.093479],[-100.909052,46.997587],[-100.601092,46.982599],[-100.454441,46.98228],[-100.452215,46.98235],[-100.474491,47.069342],[-100.51638,47.069571],[-100.937422,47.093479]]]},"properties":{"id":"58521","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.901442,46.80396],[-96.784225,46.730976],[-96.792698,46.832566],[-96.87262,46.847513],[-96.901442,46.80396]]]},"properties":{"id":"58104","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.15729,46.687507],[-97.156017,46.601585],[-96.865736,46.616013],[-96.947073,46.717289],[-97.15729,46.687507]]]},"properties":{"id":"58051","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.388546,46.977839],[-97.429509,46.977965],[-97.429531,46.962727],[-97.388546,46.977839]]]},"properties":{"id":"58002","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.451217,47.064918],[-97.578503,47.050626],[-97.429509,46.977965],[-97.388546,46.977839],[-97.366983,46.97777],[-97.378235,47.050257],[-97.451217,47.064918]]]},"properties":{"id":"58007","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.069852,47.122926],[-97.345273,47.137082],[-97.303574,47.064507],[-97.133234,47.064744],[-97.069547,47.079396],[-97.069852,47.122926]]]},"properties":{"id":"58006","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.709551,48.572845],[-98.448756,48.572532],[-98.427371,48.630519],[-98.606093,48.732324],[-98.649923,48.732373],[-98.717352,48.587338],[-98.709551,48.572845]]]},"properties":{"id":"58311","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.717352,48.587338],[-98.649923,48.732373],[-98.759043,48.81932],[-99.000839,48.81946],[-99.088038,48.732635],[-98.970337,48.558717],[-98.970392,48.544232],[-98.717352,48.587338]]]},"properties":{"id":"58352","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.666239,48.428048],[-98.448794,48.543605],[-98.448756,48.572532],[-98.709551,48.572845],[-98.666239,48.428048]]]},"properties":{"id":"58338","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.317863,48.64899],[-98.252924,48.586942],[-98.209405,48.586782],[-98.057253,48.761098],[-98.317863,48.64899]]]},"properties":{"id":"58269","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.448756,48.572532],[-98.448794,48.543605],[-98.318098,48.543773],[-98.252924,48.586942],[-98.317863,48.64899],[-98.427371,48.630519],[-98.448756,48.572532]]]},"properties":{"id":"58355","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.122603,48.543266],[-98.057245,48.543258],[-97.927126,48.644438],[-97.92767,48.717682],[-97.949917,48.758821],[-98.026491,48.761054],[-98.057253,48.761098],[-98.209405,48.586782],[-98.122603,48.543266]]]},"properties":{"id":"58260","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.269402,46.240295],[-98.473472,46.282942],[-98.481026,46.282931],[-98.359454,46.066273],[-98.269402,46.240295]]]},"properties":{"id":"58441","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.265811,47.594829],[-98.883488,47.558403],[-98.755982,47.645488],[-98.761056,47.68703],[-98.866863,47.760358],[-99.404592,47.774463],[-99.425873,47.754937],[-99.265811,47.594829]]]},"properties":{"id":"58356","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.667067,47.241346],[-103.902575,46.760042],[-104.045185,46.583662],[-104.045108,46.540929],[-103.812751,46.54075],[-103.60921,46.629797],[-103.667067,47.241346]]]},"properties":{"id":"58654","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.993983,47.846],[-97.239013,47.817269],[-97.366352,47.788555],[-97.324866,47.730219],[-96.902811,47.672109],[-96.886846,47.672063],[-96.993983,47.846]]]},"properties":{"id":"58278","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.032367,47.87513],[-98.204296,47.672376],[-98.263044,47.672442],[-98.176746,47.601783],[-97.984929,47.574243],[-97.882405,47.672179],[-97.882157,47.831628],[-97.946708,47.875077],[-98.032367,47.87513]]]},"properties":{"id":"58212","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.445133,46.426144],[-101.374899,46.076716],[-101.335143,46.083779],[-101.333771,46.091599],[-101.257405,46.371477],[-101.445133,46.426144]]]},"properties":{"id":"58564","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.248968,47.543808],[-98.219943,47.53329],[-98.219941,47.558274],[-98.248968,47.543808]]]},"properties":{"id":"58452","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.704328,46.558504],[-98.881971,46.725236],[-98.987262,46.73235],[-99.174916,46.732232],[-99.036931,46.486198],[-98.955049,46.456979],[-98.704328,46.558504]]]},"properties":{"id":"58454","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.941553,48.327109],[-101.014153,48.341489],[-101.101283,48.225582],[-101.10125,48.138603],[-100.928912,48.181911],[-100.941553,48.327109]]]},"properties":{"id":"58768","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.416347,47.496746],[-101.369986,47.478066],[-101.036002,47.50202],[-101.056659,47.661444],[-101.185914,47.674277],[-101.250256,47.688758],[-101.417397,47.554707],[-101.416347,47.496746]]]},"properties":{"id":"58531","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.22949,48.78948],[-97.157625,48.787544],[-97.425171,49.000498],[-97.447322,48.948823],[-97.294143,48.832698],[-97.22949,48.78948]]]},"properties":{"id":"58271","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.534509,48.862609],[-97.556538,48.819068],[-97.294143,48.832698],[-97.447322,48.948823],[-97.534509,48.862609]]]},"properties":{"id":"58216","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.365317,48.542908],[-97.447282,48.710286],[-97.512532,48.73229],[-97.556876,48.717761],[-97.557302,48.644599],[-97.557321,48.543088],[-97.512918,48.543053],[-97.365317,48.542908]]]},"properties":{"id":"58276","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.448794,48.543605],[-98.666239,48.428048],[-98.663439,48.370104],[-98.557724,48.37576],[-98.31867,48.369722],[-98.318336,48.398718],[-98.318098,48.543773],[-98.448794,48.543605]]]},"properties":{"id":"58330","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.406147,46.441915],[-97.531263,46.514789],[-97.784081,46.558163],[-97.857738,46.535871],[-97.825483,46.311567],[-97.694776,46.282429],[-97.406355,46.413316],[-97.406147,46.441915]]]},"properties":{"id":"58054","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.406355,46.413316],[-97.280691,46.369603],[-97.134701,46.500455],[-97.155612,46.514128],[-97.280202,46.456504],[-97.406147,46.441915],[-97.406355,46.413316]]]},"properties":{"id":"58057","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.821406,48.401309],[-101.587192,48.54639],[-101.6178,48.613812],[-101.691057,48.618727],[-101.821406,48.401309]]]},"properties":{"id":"58725","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.752779,46.572385],[-96.851808,46.616131],[-96.865736,46.616013],[-97.156017,46.601585],[-97.155612,46.514128],[-97.134701,46.500455],[-97.050752,46.369188],[-97.009199,46.398129],[-96.757818,46.499849],[-96.738508,46.498866],[-96.752779,46.572385]]]},"properties":{"id":"58077","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.219795,47.673778],[-100.203335,47.327364],[-100.113261,47.327262],[-100.033292,47.327192],[-100.032869,47.588237],[-100.075659,47.64622],[-100.203333,47.673184],[-100.219795,47.673778]]]},"properties":{"id":"58444","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.787456,46.030694],[-100.648156,45.94366],[-100.511949,45.943712],[-100.628976,46.146001],[-100.571263,46.262317],[-100.752136,46.261823],[-100.752205,46.195722],[-100.787456,46.030694]]]},"properties":{"id":"58538","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.185738,47.066706],[-99.22748,47.269459],[-99.480916,47.291631],[-99.481304,47.023694],[-99.446423,46.980889],[-99.259513,47.052226],[-99.185738,47.066706]]]},"properties":{"id":"58496","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.259513,47.052226],[-99.195471,46.776005],[-99.174916,46.732232],[-98.987262,46.73235],[-98.945668,46.979176],[-99.10102,47.066522],[-99.185738,47.066706],[-99.259513,47.052226]]]},"properties":{"id":"58424","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.700173,46.913514],[-98.697074,46.915984],[-98.702514,46.916747],[-98.700173,46.913514]]]},"properties":{"id":"58405","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.691724,46.877478],[-98.682239,46.877395],[-98.682208,46.881926],[-98.690888,46.882864],[-98.691724,46.877478]]]},"properties":{"id":"58402","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.902811,47.672109],[-97.261225,47.649558],[-97.282709,47.642793],[-97.199033,47.526744],[-96.858148,47.544908],[-96.886846,47.672063],[-96.902811,47.672109]]]},"properties":{"id":"58218","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.850712,47.500006],[-96.899492,47.4614],[-96.85528,47.45951],[-96.850712,47.500006]]]},"properties":{"id":"58219","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.723738,46.832994],[-97.630255,46.818192],[-97.621083,47.036176],[-97.747903,47.094627],[-97.723738,46.832994]]]},"properties":{"id":"58071","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.185643,46.834202],[-98.171292,47.067022],[-98.340601,47.095633],[-98.404096,47.008526],[-98.333036,46.805417],[-98.311904,46.77643],[-98.185643,46.834202]]]},"properties":{"id":"58480","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.892039,46.717269],[-97.889306,46.543334],[-97.857738,46.535871],[-97.784081,46.558163],[-97.765901,46.690517],[-97.892039,46.717269]]]},"properties":{"id":"58062","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.747903,47.094627],[-97.621083,47.036176],[-97.578503,47.050626],[-97.451217,47.064918],[-97.451178,47.180959],[-97.558005,47.253487],[-97.705863,47.210666],[-97.833287,47.167609],[-97.897158,47.167699],[-97.833046,47.12406],[-97.747903,47.094627]]]},"properties":{"id":"58064","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.221839,48.370688],[-99.286719,48.206692],[-99.135416,48.180358],[-99.17893,48.238328],[-99.005671,48.227504],[-99.005575,48.281801],[-99.00562,48.354383],[-99.079736,48.37057],[-99.221839,48.370688]]]},"properties":{"id":"58325","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.667067,47.241346],[-103.60921,46.629797],[-103.559052,46.629775],[-103.482847,46.629759],[-103.482887,46.672323],[-103.393081,47.146551],[-103.656346,47.329255],[-103.656837,47.329259],[-103.666723,47.329354],[-103.667067,47.241346]]]},"properties":{"id":"58645","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.96684,48.999],[-101.911861,48.905639],[-101.715499,48.82135],[-101.453422,48.937015],[-101.311897,48.99939],[-101.96684,48.999]]]},"properties":{"id":"58782","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.826458,46.818127],[-100.66189,46.63445],[-100.501281,46.634464],[-100.636507,46.794361],[-100.826458,46.818127]]]},"properties":{"id":"58504","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.070361,46.977319],[-96.967585,46.920647],[-96.903738,46.934479],[-96.858357,46.97705],[-97.070321,46.980652],[-97.070361,46.977319]]]},"properties":{"id":"58042","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.861468,46.182181],[-98.006715,46.282626],[-98.139318,46.282759],[-98.269402,46.240295],[-98.359454,46.066273],[-98.444684,45.936664],[-98.008102,45.935896],[-97.861468,46.182181]]]},"properties":{"id":"58474","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.005943,48.720568],[-102.939932,48.720469],[-102.938777,48.999329],[-103.158408,48.999336],[-103.136774,48.807389],[-103.005943,48.720568]]]},"properties":{"id":"58765","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.662361,48.735135],[-103.62437,48.488856],[-103.472317,48.430745],[-103.320184,48.466764],[-103.320216,48.502968],[-103.298252,48.705691],[-103.530956,48.764017],[-103.574814,48.771923],[-103.662361,48.735135]]]},"properties":{"id":"58830","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.798105,48.474324],[-103.62437,48.488856],[-103.662361,48.735135],[-103.797568,48.691507],[-103.798105,48.474324]]]},"properties":{"id":"58856","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.266152,47.50076],[-99.227809,47.326748],[-98.840294,47.326906],[-98.796079,47.418096],[-98.819712,47.471833],[-98.883488,47.558403],[-99.265811,47.594829],[-99.266152,47.50076]]]},"properties":{"id":"58421","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.418984,47.954575],[-97.364239,47.972998],[-97.406763,47.98721],[-97.418984,47.954575]]]},"properties":{"id":"58204","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.188859,48.193702],[-97.363145,48.09301],[-97.237257,48.0198],[-97.123202,48.10665],[-97.141233,48.193602],[-97.188859,48.193702]]]},"properties":{"id":"58256","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.257405,46.371477],[-101.333771,46.091599],[-101.025662,46.284219],[-101.048532,46.371393],[-101.257405,46.371477]]]},"properties":{"id":"58569","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.624794,46.048876],[-101.64535,45.94426],[-101.540936,45.944168],[-101.561806,46.012792],[-101.624794,46.048876]]]},"properties":{"id":"57660","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.341536,47.406163],[-97.994615,47.326157],[-97.985961,47.369524],[-97.985083,47.557603],[-97.984929,47.574243],[-98.176746,47.601783],[-98.219941,47.558274],[-98.219943,47.53329],[-98.248968,47.543808],[-98.354938,47.413444],[-98.341536,47.406163]]]},"properties":{"id":"58425","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.862311,47.327047],[-99.863262,47.230685],[-99.774219,47.327239],[-99.777238,47.544632],[-99.905144,47.588214],[-99.862311,47.327047]]]},"properties":{"id":"58423","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.480726,47.327076],[-99.501032,47.500781],[-99.554341,47.544375],[-99.71439,47.573663],[-99.777238,47.544632],[-99.774219,47.327239],[-99.608031,47.241028],[-99.480726,47.327076]]]},"properties":{"id":"58418","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.599928,46.529304],[-98.536645,46.442381],[-98.494583,46.442314],[-98.348303,46.485739],[-98.515527,46.587447],[-98.599928,46.529304]]]},"properties":{"id":"58431","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-100.32716,47.702825],[-100.219795,47.673778],[-100.203333,47.673184],[-100.068934,47.91197],[-100.069069,47.992057],[-100.25864,48.050922],[-100.348892,47.963386],[-100.327492,47.731789],[-100.32716,47.702825]]]},"properties":{"id":"58710","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.499048,48.005597],[-103.55781,47.58829],[-103.480673,47.52519],[-103.329705,47.59262],[-103.410002,48.032781],[-103.47536,48.012247],[-103.499048,48.005597]]]},"properties":{"id":"58835","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.044049,47.954703],[-102.784761,47.734196],[-102.784704,47.862013],[-103.044049,47.954703]]]},"properties":{"id":"58847","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.014476,47.458438],[-101.358224,47.342276],[-101.257031,47.264895],[-100.965156,47.156631],[-100.801465,47.338865],[-100.886521,47.349324],[-101.014476,47.458438]]]},"properties":{"id":"58577","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.711409,47.731915],[-102.021984,47.688415],[-102.107713,47.701752],[-102.261551,47.724308],[-102.263452,47.619607],[-102.20535,47.574506],[-101.850549,47.500002],[-101.694278,47.538625],[-101.417397,47.554707],[-101.250256,47.688758],[-101.37889,47.776039],[-101.678895,47.746403],[-101.711409,47.731915]]]},"properties":{"id":"58540","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.694278,47.538625],[-101.637454,47.096961],[-101.511207,47.212446],[-101.406251,47.416018],[-101.420009,47.494477],[-101.416347,47.496746],[-101.417397,47.554707],[-101.694278,47.538625]]]},"properties":{"id":"58545","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.406251,47.416018],[-101.511207,47.212446],[-101.384298,47.169181],[-101.257031,47.264895],[-101.358224,47.342276],[-101.406251,47.416018]]]},"properties":{"id":"58571","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.708954,48.098726],[-101.914748,47.978054],[-101.89338,47.847639],[-101.708954,48.098726]]]},"properties":{"id":"58756","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.989932,48.194791],[-97.903352,48.150688],[-97.903434,48.194897],[-97.989932,48.194791]]]},"properties":{"id":"58224","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-98.551824,48.238364],[-98.168047,48.252871],[-98.141495,48.281806],[-98.31867,48.369722],[-98.557724,48.37576],[-98.551857,48.252855],[-98.551824,48.238364]]]},"properties":{"id":"58345","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.635507,48.633818],[-101.715499,48.82135],[-101.911861,48.905639],[-101.842591,48.647846],[-101.691057,48.618727],[-101.6178,48.613812],[-101.635507,48.633818]]]},"properties":{"id":"58787","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-96.798128,46.630326],[-96.851808,46.616131],[-96.752779,46.572385],[-96.798128,46.630326]]]},"properties":{"id":"58015","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.920122,48.791234],[-99.635381,48.718466],[-99.657023,48.913513],[-99.919749,48.907325],[-99.920122,48.791234]]]},"properties":{"id":"58316","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.919749,48.907325],[-99.657023,48.913513],[-99.715365,48.999117],[-99.921248,48.99905],[-99.919749,48.907325]]]},"properties":{"id":"58369","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-103.105268,46.890616],[-103.114212,46.629876],[-102.999684,46.687885],[-103.105268,46.890616]]]},"properties":{"id":"58655","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.346414,47.570594],[-97.643696,47.556708],[-97.643885,47.484334],[-97.580795,47.411657],[-97.367898,47.411108],[-97.346414,47.570594]]]},"properties":{"id":"58274","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-99.480916,47.291631],[-99.22748,47.269459],[-99.227809,47.326748],[-99.266152,47.50076],[-99.501032,47.500781],[-99.480726,47.327076],[-99.480916,47.291631]]]},"properties":{"id":"58486","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.346567,47.332768],[-97.303694,47.296578],[-97.155056,47.282242],[-97.133786,47.282176],[-97.21882,47.367453],[-97.326506,47.381975],[-97.346567,47.332768]]]},"properties":{"id":"58009","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-97.60095,48.44165],[-97.818533,48.470825],[-97.927113,48.456208],[-97.94891,48.369351],[-97.860194,48.297572],[-97.810746,48.296355],[-97.600649,48.339712],[-97.60095,48.44165]]]},"properties":{"id":"58270","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-101.60883,48.312849],[-101.533826,48.096118],[-101.402622,48.250947],[-101.440602,48.319041],[-101.60883,48.312849]]]},"properties":{"id":"58722","state_code":"38"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.051797,40.846578],[-83.988423,40.824992],[-83.975823,40.836472],[-83.976065,40.914953],[-83.995506,40.956853],[-84.183509,40.987353],[-84.196132,40.979814],[-84.192843,40.859665],[-84.051798,40.861042],[-84.051797,40.846578]]]},"properties":{"id":"45830","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.976065,40.914953],[-83.870346,40.951232],[-83.880603,40.993155],[-83.995506,40.956853],[-83.976065,40.914953]]]},"properties":{"id":"45877","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.407647,40.967725],[-82.369409,40.961498],[-82.369814,40.973735],[-82.360081,40.969717],[-82.280766,40.998919],[-82.2797,41.065343],[-82.28967,41.07369],[-82.293631,41.073728],[-82.374108,41.016533],[-82.381856,40.975445],[-82.407647,40.967725]]]},"properties":{"id":"44859","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.2797,41.065343],[-82.220789,41.084773],[-82.28967,41.07369],[-82.2797,41.065343]]],[[[-82.537444,41.102994],[-82.374108,41.016533],[-82.293631,41.073728],[-82.34109,41.173834],[-82.458054,41.174756],[-82.496981,41.155329],[-82.538473,41.145861],[-82.537444,41.102994]]]]},"properties":{"id":"44851","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.362674,40.74153],[-82.453695,40.652744],[-82.389094,40.638588],[-82.366038,40.732198],[-82.362674,40.74153]]]},"properties":{"id":"44843","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.108143,41.817567],[-81.137141,41.727902],[-81.127798,41.707052],[-81.003631,41.715137],[-81.006104,41.851535],[-81.108143,41.817567]]]},"properties":{"id":"44057","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.617823,41.781714],[-80.622537,41.748514],[-80.614757,41.714141],[-80.519412,41.714731],[-80.519378,41.81915],[-80.597592,41.81869],[-80.617823,41.781714]]]},"properties":{"id":"44082","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.904565,41.493522],[-80.939114,41.508526],[-81.04782,41.534908],[-81.099929,41.501136],[-81.068085,41.373543],[-81.035781,41.377423],[-80.935034,41.435183],[-80.954661,41.458333],[-80.954975,41.448009],[-80.959624,41.456207],[-80.954241,41.464022],[-80.904565,41.493522]]]},"properties":{"id":"44062","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.027751,39.453559],[-82.167647,39.463124],[-82.170671,39.431533],[-82.130062,39.405031],[-82.116292,39.406277],[-82.122907,39.392598],[-82.131073,39.393558],[-82.016025,39.405669],[-82.027751,39.453559]]]},"properties":{"id":"45761","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.212268,39.370038],[-82.290233,39.378412],[-82.295486,39.325259],[-82.3046,39.274868],[-82.211877,39.280206],[-82.212268,39.370038]]]},"properties":{"id":"45766","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.079769,39.169976],[-81.978028,39.164733],[-81.971882,39.189219],[-82.010475,39.221892],[-82.075486,39.193641],[-82.079769,39.169976]]]},"properties":{"id":"45776","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.797573,39.299606],[-81.725622,39.215905],[-81.668976,39.275993],[-81.684375,39.306647],[-81.755235,39.334864],[-81.797573,39.299606]]]},"properties":{"id":"45742","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.078791,40.555964],[-84.089234,40.556325],[-84.078925,40.549832],[-84.078791,40.555964]]]},"properties":{"id":"45884","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.455473,40.370937],[-84.323074,40.355548],[-84.297393,40.358474],[-84.317321,40.423267],[-84.455276,40.422079],[-84.455473,40.370937]]]},"properties":{"id":"45865","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.43415,40.299396],[-84.409873,40.29214],[-84.335533,40.296688],[-84.323074,40.355548],[-84.455473,40.370937],[-84.434631,40.354259],[-84.434066,40.306613],[-84.43415,40.299396]]]},"properties":{"id":"45845","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.834871,39.956697],[-80.80491,39.918077],[-80.740349,39.994291],[-80.834871,39.956697]]]},"properties":{"id":"43947","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.882892,40.159495],[-81.051432,40.165474],[-81.014266,40.113117],[-81.009746,40.112845],[-81.004522,40.108461],[-81.016449,40.109359],[-81.028158,40.095662],[-80.94234,40.029923],[-80.938113,40.029787],[-80.937779,40.022531],[-80.902917,40.015432],[-80.877294,40.003322],[-80.847437,40.013263],[-80.831762,40.037531],[-80.831233,40.053639],[-80.831512,40.099375],[-80.845691,40.09977],[-80.841464,40.109722],[-80.843917,40.1414],[-80.842174,40.149892],[-80.882892,40.159495]],[[-80.975507,40.10429],[-80.969359,40.104045],[-80.971299,40.101296],[-80.975691,40.100422],[-80.975507,40.10429]],[[-80.944548,40.122817],[-80.94898,40.119128],[-80.948598,40.113846],[-80.951272,40.112396],[-80.955915,40.114832],[-80.950746,40.116799],[-80.944548,40.122817]]]},"properties":{"id":"43950","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.211468,39.867281],[-81.08628,39.789484],[-81.064059,39.905682],[-81.086467,39.932246],[-81.211468,39.867281]]]},"properties":{"id":"43747","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.975507,40.10429],[-80.975691,40.100422],[-80.971299,40.101296],[-80.969359,40.104045],[-80.975507,40.10429]]]},"properties":{"id":"43972","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.200389,40.218052],[-81.228674,40.097158],[-81.147843,40.101533],[-81.200389,40.218052]]]},"properties":{"id":"43983","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.788088,40.074087],[-80.784319,40.07524],[-80.792882,40.086153],[-80.79354,40.07723],[-80.788088,40.074087]]]},"properties":{"id":"43934","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.938289,39.119709],[-83.996751,38.981581],[-83.886599,39.002031],[-83.842935,39.105367],[-83.846229,39.131113],[-83.938289,39.119709]]]},"properties":{"id":"45154","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.003417,39.144199],[-83.938289,39.119709],[-83.846229,39.131113],[-83.895165,39.225289],[-84.02298,39.196532],[-84.015731,39.185732],[-84.003163,39.146288],[-84.003417,39.144199]]]},"properties":{"id":"45118","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.839874,38.837056],[-83.695551,38.768614],[-83.691393,38.812926],[-83.696084,38.834509],[-83.741744,38.879606],[-83.839874,38.837056]]]},"properties":{"id":"45168","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.396207,39.237946],[-84.365994,39.282012],[-84.353209,39.292287],[-84.351007,39.317509],[-84.439499,39.299963],[-84.440804,39.260325],[-84.414809,39.231345],[-84.396207,39.237946]]]},"properties":{"id":"45241","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.558305,39.479486],[-84.54799,39.474364],[-84.552679,39.48444],[-84.560949,39.482339],[-84.558305,39.479486]]]},"properties":{"id":"45062","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.012802,40.668731],[-80.928302,40.560535],[-80.886342,40.555765],[-80.940303,40.663046],[-80.999308,40.696094],[-81.016943,40.685859],[-81.012802,40.668731]]]},"properties":{"id":"44651","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.885453,40.682487],[-80.865327,40.676839],[-80.88175,40.697313],[-80.885453,40.682487]]],[[[-80.755856,40.63435],[-80.737602,40.668897],[-80.828661,40.702263],[-80.865124,40.676877],[-80.898386,40.672318],[-80.940303,40.663046],[-80.886342,40.555765],[-80.831681,40.542144],[-80.755856,40.63435]]]]},"properties":{"id":"43945","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.137844,40.671201],[-81.198803,40.725715],[-81.237845,40.712905],[-81.204447,40.642579],[-81.137844,40.671201]]]},"properties":{"id":"44644","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.378288,40.524069],[-81.304798,40.517038],[-81.265891,40.540302],[-81.274003,40.582626],[-81.369491,40.635944],[-81.37285,40.63615],[-81.372995,40.633895],[-81.413367,40.612807],[-81.413683,40.603059],[-81.378288,40.524069]],[[-81.36346,40.56865],[-81.350874,40.576029],[-81.338092,40.56543],[-81.35343,40.557274],[-81.36346,40.56865]]]},"properties":{"id":"44656","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.265891,40.540302],[-81.304798,40.517038],[-81.317216,40.471475],[-81.225055,40.450646],[-81.177919,40.497958],[-81.194601,40.528116],[-81.265891,40.540302]]]},"properties":{"id":"44675","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.237845,40.712905],[-81.310087,40.733391],[-81.313933,40.726059],[-81.305287,40.703761],[-81.244445,40.626787],[-81.2042,40.632285],[-81.204447,40.642579],[-81.237845,40.712905]]]},"properties":{"id":"44688","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.937161,40.424613],[-80.90437,40.423812],[-80.864543,40.450961],[-80.946852,40.51787],[-81.008661,40.461925],[-81.012128,40.433391],[-80.937161,40.424613]],[[-80.892077,40.459708],[-80.892065,40.468062],[-80.888732,40.468118],[-80.884784,40.459552],[-80.892077,40.459708]]]},"properties":{"id":"43903","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.356629,40.427672],[-81.216962,40.420842],[-81.225055,40.450646],[-81.317216,40.471475],[-81.37322,40.44358],[-81.356629,40.427672]]]},"properties":{"id":"44621","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.88175,40.697313],[-80.955618,40.777828],[-80.999308,40.696094],[-80.940303,40.663046],[-80.898386,40.672318],[-80.885453,40.682487],[-80.88175,40.697313]]]},"properties":{"id":"44427","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.934394,39.901871],[-83.813464,39.875637],[-83.810479,39.921309],[-83.816023,39.924462],[-83.932536,39.91931],[-83.934394,39.901871]]]},"properties":{"id":"45506","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.810479,39.921309],[-83.813464,39.875637],[-83.704891,39.911359],[-83.682637,39.928449],[-83.682583,39.928899],[-83.732118,39.93433],[-83.806763,39.924384],[-83.810479,39.921309]]]},"properties":{"id":"45505","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.949845,39.982633],[-83.939542,39.985265],[-83.946359,39.988815],[-83.949845,39.982633]]]},"properties":{"id":"45349","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.033569,39.241156],[-84.145285,39.2814],[-84.158661,39.283361],[-84.18054,39.200036],[-84.160774,39.172587],[-84.095311,39.179634],[-84.095047,39.183925],[-84.085015,39.183216],[-84.046886,39.162453],[-84.015731,39.185732],[-84.02298,39.196532],[-84.033569,39.241156]]]},"properties":{"id":"45122","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.003163,39.146288],[-84.004577,39.14663],[-84.007904,39.145161],[-84.003417,39.144199],[-84.003163,39.146288]]]},"properties":{"id":"45145","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.755881,39.280292],[-83.743383,39.258438],[-83.625858,39.319822],[-83.638175,39.372847],[-83.661012,39.414169],[-83.757898,39.354302],[-83.755881,39.280292]]]},"properties":{"id":"45159","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.600483,40.745411],[-80.51894,40.725822],[-80.519108,40.812869],[-80.574037,40.798763],[-80.600483,40.745411]]]},"properties":{"id":"44441","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.737706,40.944227],[-80.857303,40.987943],[-80.935087,40.974023],[-80.986074,40.842392],[-80.974598,40.813783],[-80.955566,40.79403],[-80.896199,40.824954],[-80.883187,40.827976],[-80.884427,40.81885],[-80.872612,40.813394],[-80.823238,40.825068],[-80.770432,40.892891],[-80.777987,40.908024],[-80.759259,40.900594],[-80.755607,40.892841],[-80.734374,40.900402],[-80.737706,40.944227]]]},"properties":{"id":"44460","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.986074,40.842392],[-80.935087,40.974023],[-80.993508,40.987768],[-81.050727,40.959446],[-81.031823,40.937699],[-81.006668,40.930435],[-81.027412,40.901478],[-81.012311,40.871241],[-80.986074,40.842392]]]},"properties":{"id":"44609","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.755607,40.892841],[-80.759259,40.900594],[-80.777987,40.908024],[-80.770432,40.892891],[-80.755607,40.892841]]]},"properties":{"id":"44490","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.684631,41.447347],[-81.581759,41.435048],[-81.584604,41.45279],[-81.603556,41.463655],[-81.626552,41.465927],[-81.669018,41.46988],[-81.684631,41.447347]]]},"properties":{"id":"44105","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.969465,41.478017],[-81.873653,41.476866],[-81.8738,41.489473],[-81.967697,41.510786],[-81.969465,41.478017]]]},"properties":{"id":"44140","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.581759,41.435048],[-81.684631,41.447347],[-81.666592,41.432469],[-81.598061,41.350913],[-81.58525,41.350942],[-81.584745,41.384553],[-81.582384,41.424226],[-81.581759,41.435048]]]},"properties":{"id":"44125","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.537317,41.424286],[-81.584745,41.384553],[-81.58525,41.350942],[-81.51864,41.350154],[-81.488988,41.349753],[-81.488696,41.424268],[-81.537317,41.424286]]]},"properties":{"id":"44146","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.85595,41.419033],[-81.827336,41.45023],[-81.832143,41.464651],[-81.831958,41.463248],[-81.874492,41.449944],[-81.874667,41.448107],[-81.875359,41.417648],[-81.85595,41.419033]]]},"properties":{"id":"44126","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.769606,39.968021],[-84.79662,40.043351],[-84.809737,40.048911],[-84.811674,39.964087],[-84.769606,39.968021]]]},"properties":{"id":"45332","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.389126,41.427346],[-84.422683,41.427343],[-84.429368,41.427272],[-84.389126,41.427346]]]},"properties":{"id":"43519","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.804484,41.500348],[-84.803917,41.375308],[-84.64232,41.383226],[-84.691581,41.513504],[-84.697508,41.519236],[-84.804484,41.500348]]]},"properties":{"id":"43517","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.031692,40.137296],[-83.005204,40.136336],[-83.004458,40.136305],[-82.954962,40.149002],[-82.953001,40.150354],[-82.966099,40.238951],[-83.021777,40.175134],[-83.031692,40.137296]]]},"properties":{"id":"43035","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.458054,41.174756],[-82.34109,41.173834],[-82.320699,41.237645],[-82.316983,41.320159],[-82.344851,41.348945],[-82.424292,41.346346],[-82.442548,41.283874],[-82.461683,41.212526],[-82.456908,41.203275],[-82.458054,41.174756]],[[-82.3509,41.331608],[-82.35121,41.329156],[-82.3541,41.328968],[-82.354152,41.331114],[-82.3509,41.331608]]]},"properties":{"id":"44889","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.004561,40.127281],[-83.034686,40.07477],[-83.000887,40.080265],[-82.977417,40.110511],[-83.004561,40.127281]]]},"properties":{"id":"43085","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.143873,39.911239],[-83.187283,39.979078],[-83.2494,39.980501],[-83.250077,39.918254],[-83.143873,39.911239]]]},"properties":{"id":"43119","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.949166,40.110604],[-82.956895,40.05976],[-82.924234,40.058175],[-82.919418,40.081274],[-82.949166,40.110604]]]},"properties":{"id":"43231","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.856277,39.943574],[-82.914815,39.956579],[-82.915805,39.93181],[-82.856277,39.943574]]]},"properties":{"id":"43227","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.064123,39.921523],[-83.090432,39.927573],[-83.092634,39.918855],[-83.064123,39.921523]]],[[[-83.064123,39.921523],[-83.017926,39.880377],[-82.999708,39.939398],[-83.017381,39.951016],[-83.05202,39.962659],[-83.064123,39.921523]]]]},"properties":{"id":"43223","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.786342,41.604346],[-83.791139,41.668551],[-83.800133,41.673265],[-83.879335,41.686285],[-83.951319,41.671493],[-83.949101,41.495758],[-83.882932,41.497593],[-83.865728,41.495735],[-83.805523,41.55234],[-83.786342,41.604346]]]},"properties":{"id":"43558","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.44857,38.90256],[-82.438721,38.858497],[-82.380109,38.841278],[-82.379558,38.871117],[-82.382556,38.891196],[-82.382736,38.893173],[-82.44857,38.90256]]],[[[-82.44857,38.90256],[-82.438009,39.003839],[-82.497174,38.958175],[-82.44857,38.90256]]]]},"properties":{"id":"45685","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.382736,38.893173],[-82.280228,39.010945],[-82.318613,39.074071],[-82.329173,39.086823],[-82.426259,39.122211],[-82.435309,39.035079],[-82.438009,39.003839],[-82.44857,38.90256],[-82.382736,38.893173]]]},"properties":{"id":"45686","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.269532,41.641006],[-81.295015,41.615488],[-81.29588,41.56995],[-81.243739,41.499964],[-81.189931,41.495615],[-81.138304,41.526235],[-81.091997,41.571593],[-81.078807,41.64156],[-81.112673,41.658171],[-81.269532,41.641006]]]},"properties":{"id":"44024","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.134294,41.264921],[-81.00288,41.271842],[-81.003125,41.339351],[-81.039252,41.354014],[-81.06603,41.369235],[-81.126331,41.382496],[-81.159745,41.276496],[-81.134294,41.264921]]]},"properties":{"id":"44231","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.003125,41.339351],[-80.913311,41.308572],[-80.897175,41.334081],[-80.904246,41.42555],[-80.935034,41.435183],[-81.035781,41.377423],[-81.039252,41.354014],[-81.003125,41.339351]]]},"properties":{"id":"44491","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.129623,39.739581],[-84.104042,39.721404],[-84.059388,39.72076],[-84.039796,39.745134],[-84.126997,39.759164],[-84.129031,39.748005],[-84.129623,39.739581]]]},"properties":{"id":"45432","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.039796,39.745134],[-84.059388,39.72076],[-84.066326,39.693942],[-84.043675,39.691991],[-84.007722,39.76739],[-84.0161,39.767906],[-84.039796,39.745134]],[[-84.023384,39.709853],[-84.021212,39.713919],[-84.019112,39.713053],[-84.019418,39.709384],[-84.023384,39.709853]]]},"properties":{"id":"45434","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.471309,39.15715],[-84.455014,39.145661],[-84.434782,39.164465],[-84.444529,39.18178],[-84.465976,39.167994],[-84.471309,39.15715]]]},"properties":{"id":"45212","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.562964,39.11728],[-84.579765,39.09938],[-84.626513,39.07919],[-84.624146,39.074474],[-84.543555,39.099752],[-84.542646,39.121201],[-84.542945,39.124523],[-84.562964,39.11728]]]},"properties":{"id":"45204","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.71216,39.130388],[-84.624146,39.074474],[-84.626513,39.07919],[-84.634006,39.138133],[-84.702355,39.143019],[-84.711561,39.130748],[-84.71216,39.130388]],[[-84.649203,39.09583],[-84.648501,39.101301],[-84.642853,39.09747],[-84.649203,39.09583]]]},"properties":{"id":"45233","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.468141,39.201559],[-84.492444,39.207158],[-84.503114,39.199779],[-84.497144,39.183557],[-84.486143,39.179559],[-84.468141,39.201559]]]},"properties":{"id":"45216","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.519584,39.176032],[-84.527807,39.160784],[-84.509835,39.163637],[-84.497144,39.183557],[-84.503114,39.199779],[-84.519584,39.176032]]]},"properties":{"id":"45232","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.6885,41.124125],[-83.585737,41.123564],[-83.573331,41.137888],[-83.592716,41.167194],[-83.707865,41.160134],[-83.6885,41.124125]]]},"properties":{"id":"45889","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.477544,41.026604],[-83.538129,40.962583],[-83.541571,40.949783],[-83.476823,40.952525],[-83.453899,40.991688],[-83.477544,41.026604]]]},"properties":{"id":"45890","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.264874,41.203839],[-83.386107,41.254447],[-83.477523,41.23911],[-83.530366,41.15548],[-83.477354,41.07079],[-83.362705,41.088581],[-83.304829,41.099835],[-83.285315,41.130387],[-83.304505,41.133513],[-83.304474,41.137163],[-83.264874,41.203839]]]},"properties":{"id":"44830","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.585737,41.123564],[-83.477354,41.07079],[-83.530366,41.15548],[-83.573331,41.137888],[-83.585737,41.123564]]]},"properties":{"id":"44804","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.530366,41.15548],[-83.477523,41.23911],[-83.492345,41.246185],[-83.496503,41.253708],[-83.563708,41.251482],[-83.611532,41.211194],[-83.592716,41.167194],[-83.573331,41.137888],[-83.530366,41.15548]]]},"properties":{"id":"44817","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.12465,41.101721],[-83.880653,41.051189],[-83.869299,41.152635],[-84.016209,41.177],[-84.06739,41.181194],[-84.170513,41.166147],[-84.160197,41.100913],[-84.12465,41.101721]]]},"properties":{"id":"45856","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.36909,40.28065],[-81.200295,40.346394],[-81.204204,40.367907],[-81.216962,40.420842],[-81.356629,40.427672],[-81.364045,40.427659],[-81.390577,40.397441],[-81.408489,40.311207],[-81.36909,40.28065]]]},"properties":{"id":"44683","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.42378,39.219242],[-83.349528,39.171569],[-83.344794,39.168808],[-83.318598,39.162508],[-83.238817,39.116906],[-83.235208,39.117014],[-83.118936,39.212849],[-83.152345,39.277157],[-83.16319,39.278153],[-83.339138,39.300311],[-83.42378,39.219242]]]},"properties":{"id":"45612","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.597033,39.341099],[-83.59662,39.347115],[-83.605507,39.345437],[-83.597033,39.341099]]]},"properties":{"id":"45132","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.246486,39.502491],[-82.241813,39.49545],[-82.235856,39.497842],[-82.241006,39.509129],[-82.246486,39.502491]]]},"properties":{"id":"43111","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.720822,40.541501],[-81.720072,40.533969],[-81.718437,40.53807],[-81.720822,40.541501]]]},"properties":{"id":"44687","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.729087,40.6315],[-81.66522,40.56723],[-81.579109,40.552336],[-81.560318,40.558914],[-81.562145,40.633991],[-81.654062,40.634514],[-81.64982,40.668381],[-81.697123,40.692557],[-81.704905,40.69091],[-81.729136,40.674812],[-81.729087,40.6315]],[[-81.700318,40.612728],[-81.688456,40.618315],[-81.685673,40.612484],[-81.69188,40.610386],[-81.700318,40.612728]]]},"properties":{"id":"44624","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.115699,40.595092],[-82.110716,40.594082],[-82.110431,40.596186],[-82.115789,40.597415],[-82.115699,40.595092]]]},"properties":{"id":"44661","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.115789,40.597415],[-82.110431,40.596186],[-82.110716,40.594082],[-82.115699,40.595092],[-82.131659,40.578668],[-82.069932,40.564463],[-82.058699,40.573278],[-82.038302,40.60948],[-82.100044,40.678839],[-82.115789,40.597415]]]},"properties":{"id":"44611","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.700318,40.612728],[-81.69188,40.610386],[-81.685673,40.612484],[-81.688456,40.618315],[-81.700318,40.612728]]]},"properties":{"id":"44690","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.988733,38.979925],[-82.856746,38.942055],[-82.80709,38.948073],[-82.792729,39.10264],[-82.865889,39.135389],[-82.930426,39.033099],[-82.988733,38.979925]]]},"properties":{"id":"45613","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.323347,40.52604],[-82.362322,40.506321],[-82.370104,40.391367],[-82.260631,40.305375],[-82.188629,40.37538],[-82.323347,40.52604]]]},"properties":{"id":"43028","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.323347,40.52604],[-82.188629,40.37538],[-82.185424,40.436794],[-82.181716,40.51136],[-82.2609,40.563463],[-82.303527,40.558713],[-82.336962,40.555001],[-82.323347,40.52604]]]},"properties":{"id":"43014","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.488739,41.624048],[-81.452689,41.623859],[-81.408843,41.694601],[-81.488643,41.631522],[-81.488739,41.624048]]]},"properties":{"id":"44095","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.765355,38.657449],[-82.60733,38.583481],[-82.503799,38.635839],[-82.46708,38.681978],[-82.585133,38.75687],[-82.667951,38.74829],[-82.758286,38.679734],[-82.765355,38.657449]]]},"properties":{"id":"45659","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.088867,40.084406],[-82.212021,40.137975],[-82.22039,40.056973],[-82.195103,40.010362],[-82.13967,40.018932],[-82.020243,40.052054],[-82.088867,40.084406]]]},"properties":{"id":"43830","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.195103,40.010362],[-82.214281,39.956098],[-82.222272,39.948626],[-82.168487,39.922358],[-82.13967,40.018932],[-82.195103,40.010362]]]},"properties":{"id":"43746","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.879847,40.483768],[-83.868729,40.4623],[-83.86457,40.410476],[-83.74217,40.461196],[-83.857214,40.498614],[-83.879847,40.483768]]]},"properties":{"id":"43324","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.158878,41.418163],[-82.108118,41.447147],[-82.130079,41.489922],[-82.199951,41.464626],[-82.184596,41.41824],[-82.160709,41.418165],[-82.158878,41.418163]]]},"properties":{"id":"44052","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.228283,41.338041],[-82.133036,41.311566],[-82.105836,41.300298],[-82.020534,41.351675],[-82.067767,41.418342],[-82.067869,41.430027],[-82.097326,41.421029],[-82.158878,41.418163],[-82.160709,41.418165],[-82.179514,41.399684],[-82.228283,41.338041]]]},"properties":{"id":"44035","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.990488,41.351373],[-81.980695,41.259884],[-81.88352,41.275008],[-81.878053,41.275044],[-81.87696,41.350685],[-81.971262,41.351268],[-81.990488,41.351373]]]},"properties":{"id":"44028","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.066972,41.478655],[-81.969465,41.478017],[-81.967697,41.510786],[-82.066554,41.508772],[-82.066972,41.478655]]]},"properties":{"id":"44012","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.311068,41.266357],[-82.302637,41.261322],[-82.301927,41.271444],[-82.311068,41.266357]]]},"properties":{"id":"44049","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.097788,41.143851],[-82.200942,41.090188],[-82.171355,41.069855],[-82.076613,41.062831],[-82.022551,41.077757],[-82.004025,41.135954],[-82.097788,41.143851]]]},"properties":{"id":"44275","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.606408,41.580266],[-83.674153,41.6102],[-83.6842,41.613713],[-83.751583,41.602803],[-83.722899,41.54621],[-83.682841,41.545283],[-83.606408,41.580266]]]},"properties":{"id":"43537","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.630268,41.619501],[-83.674153,41.6102],[-83.606408,41.580266],[-83.588034,41.60095],[-83.630268,41.619501]]]},"properties":{"id":"43614","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.505632,41.617848],[-83.469878,41.692866],[-83.51146,41.659698],[-83.53193,41.632551],[-83.569934,41.61715],[-83.543033,41.61405],[-83.505632,41.617848]]]},"properties":{"id":"43605","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.786342,41.604346],[-83.751583,41.602803],[-83.6842,41.613713],[-83.722993,41.646533],[-83.760523,41.65863],[-83.791139,41.668551],[-83.786342,41.604346]]]},"properties":{"id":"43528","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.35691,40.008301],[-83.247652,39.865133],[-83.250077,39.918254],[-83.2494,39.980501],[-83.260877,40.002807],[-83.35691,40.008301]]]},"properties":{"id":"43162","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.811887,41.084437],[-80.86792,41.012282],[-80.857303,40.987943],[-80.737706,40.944227],[-80.706846,40.963205],[-80.679849,40.973442],[-80.688246,40.988014],[-80.711139,41.046061],[-80.73237,41.068796],[-80.811887,41.084437]]]},"properties":{"id":"44406","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.001797,41.00786],[-81.058451,41.003627],[-81.086818,40.98587],[-81.050727,40.959446],[-80.993508,40.987768],[-81.001797,41.00786]]]},"properties":{"id":"44449","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.706846,40.963205],[-80.636916,40.914315],[-80.606009,40.958153],[-80.66276,40.987936],[-80.679849,40.973442],[-80.706846,40.963205]]]},"properties":{"id":"44452","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.644286,41.102731],[-80.639986,41.096095],[-80.612447,41.091217],[-80.568996,41.083629],[-80.56692,41.133324],[-80.649703,41.171377],[-80.670397,41.133681],[-80.665185,41.131059],[-80.645507,41.11133],[-80.644286,41.102731]]]},"properties":{"id":"44505","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.655282,41.111913],[-80.665185,41.131059],[-80.670397,41.133681],[-80.699083,41.133893],[-80.675598,41.105948],[-80.655282,41.111913]]]},"properties":{"id":"44510","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.0196,40.502682],[-83.00206,40.455695],[-82.909275,40.422048],[-82.838228,40.452232],[-82.835656,40.465936],[-82.822056,40.460289],[-82.699562,40.422781],[-82.683813,40.474742],[-82.686437,40.476881],[-82.85894,40.523475],[-82.958063,40.557934],[-82.976589,40.557802],[-83.0196,40.502682]]]},"properties":{"id":"43315","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.211729,40.530216],[-83.205678,40.522403],[-83.203974,40.539002],[-83.211729,40.530216]]]},"properties":{"id":"43322","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.691982,41.130091],[-81.805786,41.05193],[-81.788548,41.010002],[-81.728744,40.98841],[-81.688608,41.005747],[-81.686452,41.070234],[-81.691982,41.130091]],[[-81.735538,41.091914],[-81.738725,41.104346],[-81.725653,41.10162],[-81.735538,41.091914]]]},"properties":{"id":"44281","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.788548,41.010002],[-81.805786,41.05193],[-81.892776,41.062434],[-81.904411,41.057506],[-81.946109,41.062624],[-81.976744,41.007325],[-81.961478,41.003049],[-81.853399,40.971849],[-81.806433,40.988251],[-81.788548,41.010002]],[[-81.933405,41.026034],[-81.938438,41.038912],[-81.915,41.034107],[-81.918584,41.019673],[-81.933405,41.026034]]]},"properties":{"id":"44273","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.590708,40.45076],[-84.688789,40.443403],[-84.632827,40.351747],[-84.58048,40.353371],[-84.568626,40.422107],[-84.590708,40.45076]]]},"properties":{"id":"45883","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.297642,40.077859],[-84.400617,40.051001],[-84.391549,40.022076],[-84.306168,40.041278],[-84.297642,40.077859]]]},"properties":{"id":"45359","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.277187,39.854259],[-81.310582,39.707305],[-81.281109,39.707115],[-81.278782,39.71344],[-81.272241,39.712366],[-81.272307,39.706986],[-81.280588,39.707112],[-81.225342,39.629775],[-81.223211,39.709381],[-81.263063,39.848209],[-81.277187,39.854259]]]},"properties":{"id":"43754","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.199513,39.764739],[-84.246797,39.771222],[-84.192936,39.740054],[-84.183992,39.750248],[-84.177135,39.757938],[-84.166294,39.768808],[-84.188029,39.766791],[-84.199513,39.764739]]]},"properties":{"id":"45402","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.348975,39.775637],[-84.270318,39.786356],[-84.273356,39.797758],[-84.254836,39.819825],[-84.292016,39.833079],[-84.296658,39.833014],[-84.33648,39.832496],[-84.348975,39.775637]]]},"properties":{"id":"45426","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.285056,39.869889],[-84.166668,39.866605],[-84.157223,39.88563],[-84.278085,39.921554],[-84.286062,39.921554],[-84.285056,39.869889]]]},"properties":{"id":"45377","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.254836,39.819825],[-84.273356,39.797758],[-84.244742,39.801857],[-84.23516,39.81129],[-84.254836,39.819825]]]},"properties":{"id":"45416","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.335426,39.511353],[-84.319577,39.471037],[-84.244053,39.500448],[-84.251253,39.578498],[-84.327249,39.599291],[-84.365124,39.583999],[-84.335426,39.511353]]]},"properties":{"id":"45005","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.986278,39.811859],[-81.902312,39.865726],[-81.927361,39.872869],[-81.990818,39.837078],[-81.986278,39.811859]]]},"properties":{"id":"43771","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.778609,39.933765],[-81.784306,40.103215],[-81.841133,40.029803],[-81.841947,39.936026],[-81.778609,39.933765]]]},"properties":{"id":"43767","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.212537,41.538328],[-83.240032,41.6232],[-83.280213,41.544945],[-83.280166,41.539342],[-83.222273,41.519257],[-83.222279,41.523729],[-83.212537,41.538328]]]},"properties":{"id":"43432","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.052741,39.769164],[-83.069497,39.776244],[-83.070099,39.763573],[-83.052741,39.769164]]]},"properties":{"id":"43116","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.385518,41.080007],[-81.416676,41.060525],[-81.416735,41.054193],[-81.395657,40.999062],[-81.367954,40.982176],[-81.302535,41.003732],[-81.257127,41.033082],[-81.256485,41.041101],[-81.261668,41.058985],[-81.268667,41.068242],[-81.385518,41.080007]]]},"properties":{"id":"44260","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.465535,41.094756],[-81.416676,41.060525],[-81.385518,41.080007],[-81.392114,41.135722],[-81.414298,41.13556],[-81.452634,41.124785],[-81.465034,41.118851],[-81.465535,41.094756]]]},"properties":{"id":"44278","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.257127,41.033082],[-81.24818,41.032927],[-81.256485,41.041101],[-81.257127,41.033082]]]},"properties":{"id":"44265","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.652168,39.866283],[-84.673567,39.916602],[-84.704293,39.916528],[-84.696891,39.83572],[-84.652168,39.866283]]]},"properties":{"id":"45321","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.154659,39.282863],[-83.16319,39.278153],[-83.152345,39.277157],[-83.154659,39.282863]]]},"properties":{"id":"45617","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.870696,41.153566],[-82.957016,41.197776],[-83.024523,41.218695],[-83.054199,41.213194],[-83.054182,41.082576],[-82.976677,41.095757],[-82.870696,41.153566]]]},"properties":{"id":"44867","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.304829,41.099835],[-83.362705,41.088581],[-83.374585,41.028777],[-83.304873,41.014272],[-83.247458,41.028998],[-83.304829,41.099835]]]},"properties":{"id":"44853","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.409873,40.29214],[-84.355949,40.197593],[-84.327362,40.197986],[-84.272323,40.250667],[-84.335533,40.296688],[-84.409873,40.29214]]]},"properties":{"id":"45333","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.269493,40.438713],[-84.259635,40.4314],[-84.250396,40.438949],[-84.269493,40.438713]]]},"properties":{"id":"45336","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.43415,40.299396],[-84.432575,40.197037],[-84.355949,40.197593],[-84.409873,40.29214],[-84.43415,40.299396]]]},"properties":{"id":"45363","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.463435,41.059556],[-81.494429,41.075417],[-81.504149,41.071146],[-81.505457,41.054472],[-81.505736,41.029071],[-81.475936,41.006856],[-81.463435,41.059556]]]},"properties":{"id":"44306","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.416676,41.060525],[-81.465535,41.094756],[-81.490814,41.087588],[-81.494429,41.075417],[-81.463435,41.059556],[-81.416735,41.054193],[-81.416676,41.060525]]]},"properties":{"id":"44305","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.685744,41.200904],[-81.590442,41.202655],[-81.590708,41.251372],[-81.67051,41.277255],[-81.68495,41.277146],[-81.685646,41.202132],[-81.685744,41.200904]]]},"properties":{"id":"44286","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.913311,41.308572],[-81.003125,41.339351],[-81.00288,41.271842],[-81.002746,41.250001],[-80.948529,41.240267],[-80.913562,41.272189],[-80.913311,41.308572]]]},"properties":{"id":"44470","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.904246,41.42555],[-80.897175,41.334081],[-80.821844,41.340658],[-80.773323,41.41878],[-80.904246,41.42555]]]},"properties":{"id":"44402","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.43918,40.618429],[-81.502373,40.602922],[-81.560318,40.558914],[-81.579109,40.552336],[-81.587052,40.507967],[-81.378288,40.524069],[-81.413683,40.603059],[-81.43918,40.618429]]]},"properties":{"id":"44622","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.340747,40.140471],[-83.345879,40.137005],[-83.339778,40.131191],[-83.337431,40.138183],[-83.340747,40.140471]]]},"properties":{"id":"43077","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.408077,40.917659],[-84.39893,40.946719],[-84.399097,40.961104],[-84.476265,40.960978],[-84.456497,40.851992],[-84.408077,40.917659]]]},"properties":{"id":"45863","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.684375,39.306647],[-81.668976,39.275993],[-81.559111,39.33297],[-81.599308,39.376935],[-81.654441,39.355602],[-81.684375,39.306647]]]},"properties":{"id":"45714","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.281426,39.435776],[-81.304332,39.370791],[-81.151257,39.443022],[-81.250207,39.464396],[-81.281426,39.435776]]]},"properties":{"id":"45768","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.495421,41.639546],[-84.514609,41.703242],[-84.670182,41.699973],[-84.536391,41.59361],[-84.495551,41.629329],[-84.495421,41.639546]]]},"properties":{"id":"43554","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.697508,41.519236],[-84.747529,41.698125],[-84.804484,41.500348],[-84.697508,41.519236]],[[-84.732734,41.527702],[-84.728222,41.526476],[-84.729567,41.520489],[-84.733542,41.520461],[-84.732734,41.527702]]]},"properties":{"id":"43518","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.825979,41.30629],[-83.834368,41.306194],[-83.834388,41.295161],[-83.824651,41.295253],[-83.825979,41.30629]]]},"properties":{"id":"43541","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.362214,40.687302],[-83.169257,40.731283],[-83.161156,40.789306],[-83.362371,40.717937],[-83.362214,40.687302]]]},"properties":{"id":"43323","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.20906,38.697504],[-83.37581,38.730188],[-83.443882,38.690782],[-83.456695,38.673828],[-83.079881,38.682671],[-83.086285,38.697381],[-83.090018,38.697162],[-83.20906,38.697504]]]},"properties":{"id":"45684","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.455522,38.725044],[-83.696194,38.761722],[-83.736854,38.748813],[-83.74792,38.649219],[-83.456695,38.673828],[-83.443882,38.690782],[-83.455522,38.725044]]]},"properties":{"id":"45144","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.186049,40.846592],[-84.190303,40.843112],[-84.182178,40.840784],[-84.180235,40.845537],[-84.186049,40.846592]]]},"properties":{"id":"45809","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.923688,41.579139],[-80.939114,41.508526],[-80.904565,41.493522],[-80.737636,41.491267],[-80.665249,41.499327],[-80.647729,41.508705],[-80.71322,41.571258],[-80.719264,41.571137],[-80.923688,41.579139]]]},"properties":{"id":"44076","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.666563,41.904327],[-80.683638,41.825117],[-80.617823,41.781714],[-80.597592,41.81869],[-80.666563,41.904327]]]},"properties":{"id":"44048","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.002802,41.564339],[-81.04782,41.534908],[-80.939114,41.508526],[-80.923688,41.579139],[-80.992284,41.605706],[-81.002802,41.564339]]]},"properties":{"id":"44099","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.078807,41.64156],[-81.091997,41.571593],[-81.002802,41.564339],[-80.992284,41.605706],[-81.002795,41.63539],[-81.078807,41.64156]]]},"properties":{"id":"44064","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.167647,39.463124],[-82.027751,39.453559],[-81.958661,39.48591],[-81.94802,39.533848],[-81.953232,39.545568],[-82.035874,39.592846],[-82.150528,39.581339],[-82.16114,39.534844],[-82.165379,39.488289],[-82.172039,39.466581],[-82.170934,39.464077],[-82.167647,39.463124]],[[-82.083622,39.480736],[-82.084767,39.491169],[-82.071425,39.48935],[-82.072781,39.480371],[-82.074982,39.473953],[-82.087958,39.474304],[-82.083622,39.480736]]]},"properties":{"id":"45732","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.141552,39.387349],[-82.136415,39.351631],[-82.111476,39.363489],[-82.140784,39.38885],[-82.141552,39.387349]]]},"properties":{"id":"45780","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.807868,39.914512],[-80.919265,39.871534],[-80.904709,39.833548],[-80.824658,39.846553],[-80.807868,39.914512]]]},"properties":{"id":"43942","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.033569,39.241156],[-84.02298,39.196532],[-83.895165,39.225289],[-83.904881,39.249452],[-83.859204,39.32909],[-83.846748,39.358449],[-83.930043,39.381067],[-84.027152,39.337059],[-84.086389,39.305899],[-84.033569,39.241156]]]},"properties":{"id":"45107","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.946421,38.961059],[-84.047051,38.881253],[-83.987553,38.84375],[-83.946421,38.961059]]]},"properties":{"id":"45130","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.15188,38.793939],[-84.131211,38.789106],[-83.987553,38.84375],[-84.047051,38.881253],[-84.133842,38.851537],[-84.216057,38.821836],[-84.211048,38.804918],[-84.15188,38.793939]]]},"properties":{"id":"45120","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.761246,39.39131],[-84.81741,39.406398],[-84.81945,39.305153],[-84.746139,39.304769],[-84.761246,39.39131]]]},"properties":{"id":"45053","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.107749,40.453352],[-81.204204,40.367907],[-81.200295,40.346394],[-81.181181,40.334659],[-81.056602,40.362558],[-81.012128,40.433391],[-81.008661,40.461925],[-81.107749,40.453352]]]},"properties":{"id":"43988","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.204204,40.367907],[-81.107749,40.453352],[-81.177919,40.497958],[-81.225055,40.450646],[-81.216962,40.420842],[-81.204204,40.367907]]]},"properties":{"id":"44695","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.949728,40.196367],[-84.029857,40.111431],[-84.034075,40.061294],[-84.02076,40.059861],[-84.024923,40.052125],[-84.026132,40.047484],[-83.939563,40.034945],[-83.918855,40.054683],[-83.90465,40.201029],[-83.949728,40.196367]]]},"properties":{"id":"43072","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.034075,40.061294],[-84.024923,40.052125],[-84.02076,40.059861],[-84.034075,40.061294]]]},"properties":{"id":"45389","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.865203,40.213466],[-83.841394,40.263288],[-83.87158,40.390114],[-83.974513,40.417402],[-83.967612,40.241429],[-83.961437,40.219013],[-83.955528,40.21975],[-83.955598,40.207523],[-83.949728,40.196367],[-83.90465,40.201029],[-83.865203,40.213466]]]},"properties":{"id":"43318","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.4946,40.224445],[-83.548246,40.256562],[-83.590653,40.257501],[-83.625946,40.248505],[-83.628729,40.246315],[-83.623883,40.234539],[-83.585319,40.179341],[-83.532215,40.201679],[-83.4946,40.224445]]]},"properties":{"id":"43060","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.829105,39.800535],[-83.82649,39.794517],[-83.821943,39.797051],[-83.829105,39.800535]]]},"properties":{"id":"45316","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.139555,38.917616],[-84.201125,38.967836],[-84.246625,39.018357],[-84.311003,39.029669],[-84.319985,39.021534],[-84.231858,38.871906],[-84.139555,38.917616]]]},"properties":{"id":"45157","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.352897,39.091999],[-84.396655,39.045768],[-84.319985,39.021534],[-84.311003,39.029669],[-84.30037,39.085794],[-84.352897,39.091999]]]},"properties":{"id":"45255","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.70341,39.476687],[-83.750611,39.546382],[-83.785828,39.550094],[-83.788735,39.553273],[-83.830994,39.560361],[-83.972755,39.568898],[-83.977005,39.569169],[-83.982644,39.464105],[-83.930043,39.381067],[-83.846748,39.358449],[-83.757898,39.354302],[-83.661012,39.414169],[-83.698335,39.471663],[-83.70341,39.476687]]]},"properties":{"id":"45177","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.788735,39.553273],[-83.785828,39.550094],[-83.780446,39.553385],[-83.786159,39.557436],[-83.788735,39.553273]]]},"properties":{"id":"45164","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.457289,39.377772],[-83.545617,39.428832],[-83.638175,39.372847],[-83.625858,39.319822],[-83.465405,39.275308],[-83.457289,39.377772]],[[-83.597033,39.341099],[-83.605507,39.345437],[-83.59662,39.347115],[-83.597033,39.341099]]]},"properties":{"id":"45135","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.930043,39.381067],[-83.982644,39.464105],[-84.008894,39.453639],[-84.073628,39.401565],[-84.027152,39.337059],[-83.930043,39.381067]]]},"properties":{"id":"45113","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.125067,39.477421],[-84.008894,39.453639],[-83.982644,39.464105],[-83.977005,39.569169],[-84.114195,39.577983],[-84.168282,39.568069],[-84.168777,39.534036],[-84.125067,39.477421]],[[-84.011626,39.505533],[-84.000461,39.499709],[-84.013361,39.498997],[-84.011626,39.505533]]]},"properties":{"id":"45068","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.986074,40.842392],[-81.012311,40.871241],[-81.131447,40.850409],[-81.125261,40.836996],[-81.01309,40.813754],[-80.974598,40.813783],[-80.986074,40.842392]]]},"properties":{"id":"44634","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.668331,40.369519],[-81.688625,40.287633],[-81.697055,40.223788],[-81.695061,40.184089],[-81.437058,40.216638],[-81.55578,40.39405],[-81.668331,40.369519]]]},"properties":{"id":"43832","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.960355,40.450511],[-81.849958,40.41132],[-81.805195,40.437191],[-81.769914,40.495757],[-81.720072,40.533969],[-81.720822,40.541501],[-81.66522,40.56723],[-81.729087,40.6315],[-81.877604,40.619456],[-81.996003,40.602098],[-82.038302,40.60948],[-82.058699,40.573278],[-81.960355,40.450511]],[[-81.801631,40.561757],[-81.795184,40.563372],[-81.79578,40.557497],[-81.80561,40.55799],[-81.801631,40.561757]]]},"properties":{"id":"44654","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.11136,40.702915],[-83.074051,40.877512],[-83.170083,40.891538],[-83.161156,40.789306],[-83.169257,40.731283],[-83.161361,40.702513],[-83.11136,40.702915]]]},"properties":{"id":"44849","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.711092,41.502635],[-81.716494,41.469664],[-81.673227,41.47819],[-81.692118,41.497924],[-81.711092,41.502635]]]},"properties":{"id":"44113","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.876559,41.389449],[-81.84286,41.389338],[-81.780984,41.41845],[-81.85595,41.419033],[-81.875359,41.417648],[-81.881059,41.404548],[-81.876559,41.389449]]]},"properties":{"id":"44142","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.711092,41.502635],[-81.692118,41.497924],[-81.658343,41.505423],[-81.652344,41.541588],[-81.714806,41.507297],[-81.711092,41.502635]]]},"properties":{"id":"44114","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.763945,41.444977],[-81.769644,41.422017],[-81.756995,41.421536],[-81.734353,41.421181],[-81.716973,41.449969],[-81.759417,41.448437],[-81.763945,41.444977]]]},"properties":{"id":"44144","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.874667,41.448107],[-81.970263,41.411045],[-81.970464,41.386748],[-81.881059,41.404548],[-81.875359,41.417648],[-81.874667,41.448107]]]},"properties":{"id":"44070","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.785057,41.350697],[-81.785097,41.27645],[-81.705151,41.277254],[-81.703675,41.350911],[-81.734229,41.352805],[-81.753963,41.350733],[-81.785057,41.350697]]]},"properties":{"id":"44133","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.753963,41.350733],[-81.756995,41.421536],[-81.769644,41.422017],[-81.780984,41.41845],[-81.84286,41.389338],[-81.838914,41.350618],[-81.824515,41.350719],[-81.785057,41.350697],[-81.753963,41.350733]]]},"properties":{"id":"44130","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.971262,41.351268],[-81.87696,41.350685],[-81.876559,41.389449],[-81.881059,41.404548],[-81.970464,41.386748],[-81.971262,41.351268]]]},"properties":{"id":"44138","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.628425,39.855358],[-84.54769,39.917542],[-84.6244,39.927555],[-84.673567,39.916602],[-84.652168,39.866283],[-84.628425,39.855358]]]},"properties":{"id":"45382","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.489126,40.341532],[-84.499736,40.343079],[-84.500174,40.338539],[-84.489355,40.336272],[-84.489126,40.341532]]]},"properties":{"id":"45351","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.811674,39.964087],[-84.814285,39.787846],[-84.696891,39.83572],[-84.704293,39.916528],[-84.769606,39.968021],[-84.811674,39.964087]]]},"properties":{"id":"45347","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.691581,41.513504],[-84.64232,41.383226],[-84.630769,41.361359],[-84.592523,41.383499],[-84.458868,41.412878],[-84.429368,41.427272],[-84.422683,41.427343],[-84.465631,41.528461],[-84.516961,41.556624],[-84.691581,41.513504]]]},"properties":{"id":"43506","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.631349,41.208547],[-84.476106,41.194614],[-84.524802,41.25281],[-84.611228,41.25281],[-84.630522,41.252736],[-84.66912,41.252569],[-84.631349,41.208547]]]},"properties":{"id":"45821","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.085402,41.297445],[-84.22737,41.29603],[-84.247264,41.194828],[-84.093542,41.19828],[-84.071533,41.282962],[-84.085402,41.297445]]]},"properties":{"id":"43527","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.114767,40.132767],[-83.090965,40.109018],[-83.067252,40.117296],[-83.114767,40.132767]]],[[[-83.182333,40.060133],[-83.179297,40.07543],[-83.159907,40.055186],[-83.132368,40.063231],[-83.112597,40.067543],[-83.17424,40.156419],[-83.21028,40.069072],[-83.182333,40.060133]]]]},"properties":{"id":"43016","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.768116,39.551271],[-82.685207,39.551924],[-82.676421,39.56625],[-82.767533,39.732709],[-82.82967,39.736698],[-82.835281,39.676169],[-82.831317,39.666322],[-82.77569,39.557275],[-82.768116,39.551271]]]},"properties":{"id":"43102","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.629659,39.931964],[-82.512656,39.839368],[-82.468866,39.871042],[-82.482141,39.925499],[-82.500022,39.92642],[-82.599204,39.957914],[-82.607697,39.957794],[-82.629659,39.931964]]]},"properties":{"id":"43046","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.257771,39.627109],[-83.213155,39.620754],[-83.150793,39.684404],[-83.243839,39.809327],[-83.413422,39.719142],[-83.437775,39.703962],[-83.35557,39.624145],[-83.257771,39.627109]]]},"properties":{"id":"43143","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.86603,39.986487],[-82.924234,40.058175],[-82.935363,40.027019],[-82.97239,39.990787],[-82.979946,39.979797],[-82.951482,39.967835],[-82.950013,39.967996],[-82.913002,39.979128],[-82.86603,39.986487]]]},"properties":{"id":"43219","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.104881,40.068727],[-83.107394,40.068158],[-83.03817,40.029553],[-83.046525,40.063352],[-83.104881,40.068727]]]},"properties":{"id":"43220","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.21028,40.069072],[-83.17424,40.156419],[-83.170734,40.175551],[-83.170874,40.182775],[-83.189067,40.20453],[-83.340747,40.140471],[-83.337431,40.138183],[-83.339778,40.131191],[-83.345879,40.137005],[-83.36468,40.139629],[-83.392629,40.110405],[-83.407738,40.060504],[-83.408259,40.056031],[-83.35691,40.008301],[-83.260877,40.002807],[-83.21028,40.069072]]]},"properties":{"id":"43064","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.249242,41.603389],[-84.358446,41.601344],[-84.380897,41.546936],[-84.28643,41.44614],[-84.260644,41.438693],[-84.256812,41.437818],[-84.211529,41.474179],[-84.223931,41.528955],[-84.23453,41.532536],[-84.249242,41.603389]]]},"properties":{"id":"43502","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.358446,41.601344],[-84.249242,41.603389],[-84.17789,41.695778],[-84.173125,41.711638],[-84.399546,41.705816],[-84.409963,41.65144],[-84.358446,41.601344]]]},"properties":{"id":"43521","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.438721,38.858497],[-82.484902,38.772375],[-82.46708,38.681978],[-82.422643,38.679568],[-82.368968,38.676859],[-82.275053,38.690051],[-82.380109,38.841278],[-82.438721,38.858497]]]},"properties":{"id":"45658","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.356018,38.873855],[-82.382556,38.891196],[-82.379558,38.871117],[-82.356018,38.873855]]]},"properties":{"id":"45674","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.002802,41.564339],[-81.091997,41.571593],[-81.138304,41.526235],[-81.099929,41.501136],[-81.04782,41.534908],[-81.002802,41.564339]]]},"properties":{"id":"44046","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.269532,41.641006],[-81.291226,41.735943],[-81.290829,41.757452],[-81.388631,41.707256],[-81.295015,41.615488],[-81.269532,41.641006]]]},"properties":{"id":"44060","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.105982,39.697207],[-84.066326,39.693942],[-84.059388,39.72076],[-84.104042,39.721404],[-84.105982,39.697207]]]},"properties":{"id":"45430","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.719504,39.579626],[-83.726643,39.584732],[-83.725165,39.575644],[-83.719504,39.579626]]]},"properties":{"id":"45307","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.210539,39.588655],[-84.168282,39.568069],[-84.114195,39.577983],[-84.113652,39.583961],[-84.108181,39.627466],[-84.218973,39.631134],[-84.210539,39.588655]]]},"properties":{"id":"45458","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.442067,39.960753],[-81.385976,39.921784],[-81.37757,40.09099],[-81.442067,39.960753]]]},"properties":{"id":"43778","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.350111,39.878963],[-81.277187,39.854259],[-81.263063,39.848209],[-81.237316,39.867828],[-81.230133,40.055227],[-81.240507,40.057433],[-81.23602,40.060859],[-81.2301,40.056151],[-81.228674,40.097158],[-81.38142,40.14212],[-81.38399,40.12955],[-81.37757,40.09099],[-81.385976,39.921784],[-81.350111,39.878963]]]},"properties":{"id":"43773","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.497783,39.983692],[-81.531773,39.934987],[-81.52709,39.91705],[-81.52386,39.92537],[-81.51023,39.924338],[-81.515024,39.914643],[-81.434829,39.883879],[-81.359665,39.866717],[-81.350111,39.878963],[-81.385976,39.921784],[-81.442067,39.960753],[-81.495483,39.990211],[-81.49738,39.98678],[-81.497783,39.983692]]]},"properties":{"id":"43780","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.628117,39.235077],[-84.599836,39.227116],[-84.572165,39.223876],[-84.571327,39.308048],[-84.630772,39.311981],[-84.628117,39.235077]]]},"properties":{"id":"45251","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.618175,39.141301],[-84.628816,39.178035],[-84.67628,39.210745],[-84.705289,39.168224],[-84.702733,39.143201],[-84.702355,39.143019],[-84.634006,39.138133],[-84.618175,39.141301]]]},"properties":{"id":"45248","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.492444,39.207158],[-84.468141,39.201559],[-84.435793,39.214675],[-84.414809,39.231345],[-84.440804,39.260325],[-84.487503,39.266062],[-84.48811,39.260316],[-84.502845,39.216788],[-84.492444,39.207158]]]},"properties":{"id":"45215","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.483099,39.172358],[-84.465976,39.167994],[-84.444529,39.18178],[-84.411132,39.193151],[-84.435793,39.214675],[-84.468141,39.201559],[-84.486143,39.179559],[-84.483099,39.172358]]]},"properties":{"id":"45237","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.562347,39.308079],[-84.537477,39.265261],[-84.512424,39.275396],[-84.505218,39.264295],[-84.518749,39.258291],[-84.48811,39.260316],[-84.487503,39.266062],[-84.50273,39.305502],[-84.562347,39.308079]]]},"properties":{"id":"45240","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.575855,40.862415],[-83.689307,40.856177],[-83.737429,40.819301],[-83.670526,40.819542],[-83.660974,40.827407],[-83.660794,40.848492],[-83.651315,40.848591],[-83.651426,40.819487],[-83.601916,40.745731],[-83.419836,40.686823],[-83.394173,40.696622],[-83.457565,40.84315],[-83.515425,40.854491],[-83.575855,40.862415]]]},"properties":{"id":"45843","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.27245,40.956622],[-83.304873,41.014272],[-83.374585,41.028777],[-83.453899,40.991688],[-83.476823,40.952525],[-83.486092,40.904945],[-83.380732,40.875759],[-83.27245,40.956622]]]},"properties":{"id":"43316","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.789513,41.00065],[-83.799183,41.007928],[-83.79915,41.000556],[-83.789513,41.00065]]]},"properties":{"id":"45816","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.879834,40.732443],[-83.806334,40.637647],[-83.730398,40.70352],[-83.879834,40.732443]],[[-83.785343,40.688389],[-83.766349,40.678705],[-83.785621,40.669055],[-83.785343,40.688389]]]},"properties":{"id":"45812","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.181903,40.311463],[-81.180161,40.285291],[-81.177671,40.297071],[-81.181903,40.311463]]]},"properties":{"id":"44693","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.883234,41.414503],[-83.883532,41.343514],[-83.786524,41.415756],[-83.780638,41.450152],[-83.830942,41.466352],[-83.86518,41.495216],[-83.87467,41.487673],[-83.883234,41.414503]]]},"properties":{"id":"43522","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.002602,41.341849],[-83.980201,41.327152],[-83.983757,41.349281],[-84.002602,41.341849]]]},"properties":{"id":"43523","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.596765,39.439664],[-82.572398,39.449425],[-82.571927,39.451383],[-82.592867,39.460295],[-82.596765,39.439664]]],[[[-82.671609,39.353958],[-82.642306,39.330348],[-82.552234,39.371295],[-82.524433,39.416673],[-82.56563,39.440368],[-82.596765,39.439664],[-82.671609,39.353958]]]]},"properties":{"id":"43152","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.596765,39.439664],[-82.56563,39.440368],[-82.572398,39.449425],[-82.596765,39.439664]]],[[[-82.764815,39.402448],[-82.749709,39.357164],[-82.671609,39.353958],[-82.596765,39.439664],[-82.592867,39.460295],[-82.685207,39.551924],[-82.768116,39.551271],[-82.780321,39.551934],[-82.780385,39.528243],[-82.764815,39.402448]],[[-82.747695,39.468756],[-82.740793,39.46826],[-82.741316,39.460459],[-82.750685,39.460955],[-82.747695,39.468756]]]]},"properties":{"id":"43135","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.290233,39.378412],[-82.351358,39.432677],[-82.364352,39.443758],[-82.45866,39.387567],[-82.476512,39.343982],[-82.409262,39.300778],[-82.398542,39.300229],[-82.295486,39.325259],[-82.290233,39.378412]]]},"properties":{"id":"45654","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.45866,39.387567],[-82.524433,39.416673],[-82.552234,39.371295],[-82.476512,39.343982],[-82.45866,39.387567]]]},"properties":{"id":"45622","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.340908,39.45123],[-82.364352,39.443758],[-82.351358,39.432677],[-82.340908,39.45123]]]},"properties":{"id":"43158","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.720822,40.541501],[-81.718437,40.53807],[-81.720072,40.533969],[-81.769914,40.495757],[-81.66345,40.41357],[-81.593888,40.458004],[-81.587052,40.507967],[-81.579109,40.552336],[-81.66522,40.56723],[-81.720822,40.541501]]]},"properties":{"id":"44681","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.64982,40.668381],[-81.654062,40.634514],[-81.646769,40.666498],[-81.64982,40.668381]]]},"properties":{"id":"44689","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.480699,38.502334],[-82.487112,38.417899],[-82.389609,38.434378],[-82.402081,38.520111],[-82.43116,38.54536],[-82.473215,38.521227],[-82.480699,38.502334]]]},"properties":{"id":"45619","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.503799,38.635839],[-82.473215,38.521227],[-82.43116,38.54536],[-82.422643,38.679568],[-82.46708,38.681978],[-82.503799,38.635839]]]},"properties":{"id":"45696","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.76174,38.866744],[-82.798317,38.825417],[-82.667951,38.74829],[-82.585133,38.75687],[-82.582683,38.779077],[-82.76174,38.866744]]]},"properties":{"id":"45682","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.590653,40.257501],[-83.548246,40.256562],[-83.543776,40.297073],[-83.53735,40.356351],[-83.587524,40.350393],[-83.623581,40.30343],[-83.624939,40.2892],[-83.590653,40.257501]],[[-83.5853,40.28842],[-83.581617,40.292118],[-83.579479,40.28858],[-83.5853,40.28842]]]},"properties":{"id":"43319","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.53735,40.356351],[-83.452873,40.414516],[-83.445999,40.462732],[-83.520227,40.504077],[-83.579834,40.467208],[-83.630176,40.435889],[-83.604232,40.383547],[-83.587524,40.350393],[-83.53735,40.356351]]]},"properties":{"id":"43358","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.097788,41.143851],[-82.004025,41.135954],[-81.974144,41.199833],[-82.081871,41.186446],[-82.097788,41.143851]]]},"properties":{"id":"44253","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.722993,41.646533],[-83.6842,41.613713],[-83.674153,41.6102],[-83.630268,41.619501],[-83.625926,41.631009],[-83.641148,41.661359],[-83.644451,41.688094],[-83.69568,41.683669],[-83.695449,41.683059],[-83.722993,41.646533]]]},"properties":{"id":"43615","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.353305,41.58892],[-83.368176,41.582153],[-83.358565,41.562513],[-83.280213,41.544945],[-83.240032,41.6232],[-83.164963,41.623237],[-83.197756,41.632559],[-83.339071,41.58882],[-83.353305,41.58892]]]},"properties":{"id":"43445","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519,40.98738],[-80.560733,40.987765],[-80.576691,40.958243],[-80.55144,40.942038],[-80.519002,40.953362],[-80.519,40.98738]]]},"properties":{"id":"44442","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.711029,41.089158],[-80.711162,41.133955],[-80.738183,41.133021],[-80.811163,41.134226],[-80.811887,41.084437],[-80.73237,41.068796],[-80.711029,41.089158]]]},"properties":{"id":"44515","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.738183,41.133021],[-80.711162,41.133955],[-80.736037,41.134056],[-80.738183,41.133021]]],[[[-80.719274,41.174042],[-80.756141,41.1555],[-80.738265,41.133985],[-80.719274,41.174042]]]]},"properties":{"id":"44437","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.052105,41.013964],[-82.039198,40.996858],[-81.976744,41.007325],[-81.946109,41.062624],[-82.022551,41.077757],[-82.076613,41.062831],[-82.052105,41.013964]]]},"properties":{"id":"44254","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.686452,41.070234],[-81.688608,41.005747],[-81.647653,40.980301],[-81.565716,40.963023],[-81.555835,41.022298],[-81.58179,41.039642],[-81.599143,41.062355],[-81.686452,41.070234]]]},"properties":{"id":"44203","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.720804,40.938185],[-81.728744,40.98841],[-81.788548,41.010002],[-81.806433,40.988251],[-81.797632,40.909237],[-81.720804,40.938185]]]},"properties":{"id":"44270","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.735538,41.091914],[-81.725653,41.10162],[-81.738725,41.104346],[-81.735538,41.091914]]]},"properties":{"id":"44274","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.935814,38.98965],[-81.820921,38.946908],[-81.84282,39.027926],[-81.918669,39.075256],[-81.993909,39.021226],[-81.984016,39.005997],[-81.935814,38.98965]]]},"properties":{"id":"45771","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.802667,40.528232],[-84.764999,40.527293],[-84.590708,40.45076],[-84.568626,40.422107],[-84.499512,40.436897],[-84.489371,40.436909],[-84.474246,40.436884],[-84.455165,40.494842],[-84.456037,40.626197],[-84.604321,40.64777],[-84.802272,40.6224],[-84.802667,40.528232]],[[-84.546467,40.487391],[-84.552393,40.488975],[-84.548891,40.494101],[-84.546467,40.487391]]]},"properties":{"id":"45822","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.499512,40.436897],[-84.568626,40.422107],[-84.58048,40.353371],[-84.515776,40.354037],[-84.434631,40.354259],[-84.455473,40.370937],[-84.455276,40.422079],[-84.474246,40.436884],[-84.489371,40.436909],[-84.489186,40.431532],[-84.498182,40.433114],[-84.499512,40.436897]]]},"properties":{"id":"45860","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.108093,40.188819],[-84.272323,40.250667],[-84.327362,40.197986],[-84.239864,40.096546],[-84.144234,40.103236],[-84.108093,40.188819]]]},"properties":{"id":"45356","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.23615,39.70316],[-84.252996,39.689661],[-84.216507,39.672629],[-84.202947,39.674683],[-84.20161,39.701009],[-84.207127,39.728999],[-84.23615,39.70316]]]},"properties":{"id":"45439","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.190336,39.699037],[-84.141362,39.710338],[-84.168934,39.732924],[-84.190336,39.699037]]]},"properties":{"id":"45419","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.576606,39.773159],[-84.536903,39.654647],[-84.518112,39.656646],[-84.487319,39.656955],[-84.480149,39.65705],[-84.481411,39.722809],[-84.458542,39.774031],[-84.483137,39.809343],[-84.576606,39.773159]]]},"properties":{"id":"45381","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.741821,39.451562],[-81.706302,39.586251],[-81.706632,39.588451],[-81.794507,39.591409],[-81.953232,39.545568],[-81.94802,39.533848],[-81.825194,39.45962],[-81.741821,39.451562]]]},"properties":{"id":"43787","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.679121,40.478605],[-82.686437,40.476881],[-82.683813,40.474742],[-82.679121,40.478605]]]},"properties":{"id":"43317","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.012311,40.143481],[-82.017236,40.132608],[-82.008123,40.13034],[-82.012311,40.143481]]]},"properties":{"id":"43842","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.39893,40.946719],[-84.214862,40.991162],[-84.207414,40.991381],[-84.198172,41.050066],[-84.341929,41.063351],[-84.382148,41.033999],[-84.399097,40.961104],[-84.39893,40.946719]]]},"properties":{"id":"45827","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.166605,39.807214],[-82.271974,39.744966],[-82.254477,39.676227],[-82.256083,39.655723],[-82.166115,39.600897],[-82.131044,39.658226],[-82.162196,39.675373],[-82.148261,39.772571],[-82.166605,39.807214]]]},"properties":{"id":"43764","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.271974,39.744966],[-82.346652,39.741201],[-82.367155,39.731148],[-82.359443,39.657005],[-82.33757,39.652819],[-82.254477,39.676227],[-82.271974,39.744966]]]},"properties":{"id":"43748","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.937725,39.801007],[-83.025687,39.769929],[-83.052741,39.769164],[-83.070099,39.763573],[-83.069443,39.726483],[-82.835281,39.676169],[-82.82967,39.736698],[-82.836347,39.780864],[-82.921101,39.800159],[-82.937725,39.801007]]]},"properties":{"id":"43103","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.235208,39.117014],[-82.930426,39.033099],[-82.865889,39.135389],[-83.118936,39.212849],[-83.235208,39.117014]]]},"properties":{"id":"45690","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.344794,39.168808],[-83.349528,39.171569],[-83.350138,39.166362],[-83.344794,39.168808]]]},"properties":{"id":"45624","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.261668,41.058985],[-81.099016,41.062462],[-81.071633,41.062325],[-81.098618,41.099107],[-81.27821,41.110744],[-81.268667,41.068242],[-81.261668,41.058985]]]},"properties":{"id":"44272","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.475936,41.006856],[-81.495578,40.97484],[-81.395657,40.999062],[-81.416735,41.054193],[-81.463435,41.059556],[-81.475936,41.006856]],[[-81.431434,41.026456],[-81.434023,41.014686],[-81.445053,41.014854],[-81.444335,41.024156],[-81.431434,41.026456]]]},"properties":{"id":"44312","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.493659,39.901931],[-84.491317,39.906509],[-84.54769,39.917542],[-84.628425,39.855358],[-84.576606,39.773159],[-84.483137,39.809343],[-84.493659,39.901931]]]},"properties":{"id":"45338","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.536903,39.654647],[-84.537173,39.646474],[-84.518112,39.656646],[-84.536903,39.654647]]]},"properties":{"id":"45330","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.214862,40.991162],[-84.196132,40.979814],[-84.183509,40.987353],[-84.207414,40.991381],[-84.214862,40.991162]]]},"properties":{"id":"45853","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.539416,40.747303],[-82.571598,40.723804],[-82.570025,40.700293],[-82.539416,40.747303]]]},"properties":{"id":"44906","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.761953,39.218075],[-82.6513,39.267666],[-82.642306,39.330348],[-82.671609,39.353958],[-82.749709,39.357164],[-82.761953,39.218075]]]},"properties":{"id":"45647","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.415601,41.421448],[-83.416371,41.38472],[-83.407867,41.334038],[-83.241842,41.37038],[-83.221847,41.392493],[-83.268788,41.428917],[-83.299449,41.450531],[-83.415601,41.421448]]]},"properties":{"id":"43431","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.024523,41.218695],[-82.957016,41.197776],[-82.936611,41.2559],[-83.024523,41.218695]]],[[[-83.14568,41.239111],[-83.054199,41.213194],[-83.052238,41.288579],[-83.154557,41.25575],[-83.144491,41.240033],[-83.14568,41.239111]]]]},"properties":{"id":"44836","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.386107,41.254447],[-83.360321,41.272908],[-83.388233,41.298065],[-83.447842,41.297367],[-83.496503,41.253708],[-83.492345,41.246185],[-83.477637,41.248056],[-83.477523,41.23911],[-83.386107,41.254447]]]},"properties":{"id":"43457","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.304873,41.014272],[-83.27245,40.956622],[-83.268738,40.956411],[-83.20504,40.992962],[-83.247458,41.028998],[-83.304873,41.014272]]]},"properties":{"id":"44844","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.400918,40.798667],[-81.446411,40.78558],[-81.476958,40.762277],[-81.430954,40.715057],[-81.365256,40.727631],[-81.389076,40.793597],[-81.400918,40.798667]]]},"properties":{"id":"44706","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.39479,40.823561],[-81.48244,40.846723],[-81.448536,40.792245],[-81.400918,40.798667],[-81.389076,40.793597],[-81.390435,40.796618],[-81.387543,40.800712],[-81.390806,40.80971],[-81.39479,40.823561]]]},"properties":{"id":"44708","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.369161,40.798097],[-81.381734,40.799871],[-81.384674,40.80045],[-81.390435,40.796618],[-81.389076,40.793597],[-81.365256,40.727631],[-81.313933,40.726059],[-81.310087,40.733391],[-81.320201,40.789154],[-81.369161,40.798097]]]},"properties":{"id":"44707","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.283898,40.931409],[-81.362409,40.917231],[-81.364618,40.860113],[-81.348441,40.85973],[-81.306134,40.868957],[-81.283898,40.931409]],[[-81.32729,40.897898],[-81.32722,40.90381],[-81.324337,40.903708],[-81.325218,40.89968],[-81.32729,40.897898]]]},"properties":{"id":"44721","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.204016,40.79901],[-81.195986,40.762387],[-81.182684,40.761137],[-81.125261,40.836996],[-81.131447,40.850409],[-81.14792,40.850542],[-81.204016,40.79901]]]},"properties":{"id":"44669","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.182684,40.761137],[-81.195986,40.762387],[-81.196227,40.759959],[-81.182684,40.761137]]]},"properties":{"id":"44670","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.32729,40.897898],[-81.325218,40.89968],[-81.324337,40.903708],[-81.32722,40.90381],[-81.32729,40.897898]]]},"properties":{"id":"44652","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.488988,41.349753],[-81.463397,41.286695],[-81.391759,41.284053],[-81.419012,41.348753],[-81.488988,41.349753]]]},"properties":{"id":"44087","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.862417,41.225206],[-80.864809,41.257567],[-80.913562,41.272189],[-80.948529,41.240267],[-80.947174,41.202976],[-80.862417,41.225206]]]},"properties":{"id":"44430","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.864636,41.284327],[-80.864809,41.257567],[-80.862417,41.225206],[-80.815179,41.214805],[-80.864636,41.284327]]]},"properties":{"id":"44485","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.611213,41.267151],[-80.62644,41.21732],[-80.616891,41.213582],[-80.554671,41.217976],[-80.56032,41.272639],[-80.568367,41.272321],[-80.611213,41.267151]]]},"properties":{"id":"44403","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.011626,39.505533],[-84.013361,39.498997],[-84.000461,39.499709],[-84.011626,39.505533]]]},"properties":{"id":"45032","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.250207,39.464396],[-81.272019,39.484126],[-81.281426,39.435776],[-81.250207,39.464396]]]},"properties":{"id":"45773","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.720804,40.938185],[-81.797632,40.909237],[-81.802768,40.901903],[-81.695945,40.870077],[-81.647822,40.894992],[-81.647872,40.918859],[-81.64785,40.925197],[-81.720804,40.938185]]]},"properties":{"id":"44645","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.495421,41.639546],[-84.495551,41.629329],[-84.48742,41.636728],[-84.495421,41.639546]]]},"properties":{"id":"43531","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.515167,41.456915],[-83.543361,41.348582],[-83.534087,41.32612],[-83.47495,41.340838],[-83.416371,41.38472],[-83.415601,41.421448],[-83.415202,41.44942],[-83.515167,41.456915]]]},"properties":{"id":"43450","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.508223,41.508021],[-83.507247,41.507878],[-83.507841,41.510002],[-83.509096,41.508401],[-83.508223,41.508021]]]},"properties":{"id":"43463","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.614748,38.886382],[-83.617875,38.886543],[-83.617848,38.884685],[-83.614922,38.884576],[-83.614748,38.886382]]]},"properties":{"id":"45618","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.975657,40.663025],[-83.864554,40.594022],[-83.806334,40.637647],[-83.879834,40.732443],[-83.880063,40.819919],[-83.937461,40.819093],[-83.958228,40.75742],[-83.945288,40.758706],[-83.994156,40.731146],[-83.994107,40.701924],[-83.993844,40.687694],[-83.975657,40.663025]]]},"properties":{"id":"45850","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.945288,40.758706],[-83.958228,40.75742],[-83.958638,40.753375],[-83.945351,40.755138],[-83.945288,40.758706]]]},"properties":{"id":"45854","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.508199,40.77538],[-82.475444,40.742168],[-82.51147,40.696701],[-82.48784,40.659755],[-82.453695,40.652744],[-82.362674,40.74153],[-82.429384,40.883153],[-82.532773,40.876352],[-82.65783,40.813687],[-82.698205,40.760696],[-82.690001,40.699918],[-82.571598,40.723804],[-82.539416,40.747303],[-82.516075,40.752707],[-82.508199,40.77538]],[[-82.510844,40.789472],[-82.510929,40.794584],[-82.506616,40.79395],[-82.506887,40.788748],[-82.510844,40.789472]]]},"properties":{"id":"44903","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.2599,40.755163],[-82.303527,40.558713],[-82.2609,40.563463],[-82.166087,40.574701],[-82.167817,40.712274],[-82.2599,40.755163]]]},"properties":{"id":"44842","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.144683,40.868192],[-82.224833,40.974105],[-82.174462,40.857114],[-82.144683,40.868192]]]},"properties":{"id":"44866","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.171355,41.069855],[-82.173359,40.992082],[-82.052105,41.013964],[-82.076613,41.062831],[-82.171355,41.069855]]]},"properties":{"id":"44235","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.174462,40.857114],[-82.250834,40.773509],[-82.259678,40.768712],[-82.261564,40.768278],[-82.2599,40.755163],[-82.167817,40.712274],[-82.126457,40.733862],[-82.126537,40.749318],[-82.125495,40.85817],[-82.144683,40.868192],[-82.174462,40.857114]]]},"properties":{"id":"44840","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.666563,41.904327],[-80.71334,41.926929],[-80.921855,41.870594],[-80.880457,41.789171],[-80.83416,41.789103],[-80.683638,41.825117],[-80.666563,41.904327]]]},"properties":{"id":"44004","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.880457,41.789171],[-80.921855,41.870594],[-81.006104,41.851535],[-81.003631,41.715137],[-80.998766,41.673412],[-80.88403,41.730799],[-80.880457,41.789171]]]},"properties":{"id":"44041","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.947149,39.366452],[-81.84819,39.396385],[-81.829435,39.449303],[-81.958661,39.48591],[-82.027751,39.453559],[-82.016025,39.405669],[-81.947149,39.366452]]]},"properties":{"id":"45711","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.072781,39.480371],[-82.071425,39.48935],[-82.084767,39.491169],[-82.083622,39.480736],[-82.072781,39.480371]]]},"properties":{"id":"45782","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.857297,39.310966],[-81.971882,39.189219],[-81.978028,39.164733],[-81.927329,39.172627],[-81.75608,39.18056],[-81.725622,39.215905],[-81.797573,39.299606],[-81.85293,39.325373],[-81.857297,39.310966]]]},"properties":{"id":"45723","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.949652,39.332396],[-82.010475,39.221892],[-81.971882,39.189219],[-81.857297,39.310966],[-81.949652,39.332396]]]},"properties":{"id":"45735","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.3046,39.274868],[-82.344216,39.2075],[-82.329173,39.086823],[-82.318128,39.07933],[-82.230523,39.123382],[-82.079769,39.169976],[-82.075486,39.193641],[-82.211877,39.280206],[-82.3046,39.274868]]]},"properties":{"id":"45710","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.946472,40.560342],[-83.95853,40.560871],[-83.94811,40.547421],[-83.946472,40.560342]]]},"properties":{"id":"45870","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.257237,40.626296],[-84.265208,40.627637],[-84.26515,40.620407],[-84.256149,40.620361],[-84.257237,40.626296]]]},"properties":{"id":"45819","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.841464,40.109722],[-80.845691,40.09977],[-80.831512,40.099375],[-80.841464,40.109722]]]},"properties":{"id":"43905","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.987553,38.84375],[-84.131211,38.789106],[-84.131741,38.788469],[-83.975132,38.787377],[-83.9728,38.793535],[-83.959033,38.787091],[-83.915861,38.769766],[-83.839874,38.837056],[-83.741744,38.879606],[-83.758846,38.908012],[-83.910987,38.965505],[-83.946421,38.961059],[-83.987553,38.84375]]]},"properties":{"id":"45121","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.127108,39.01736],[-83.996751,38.981581],[-83.938289,39.119709],[-84.003417,39.144199],[-84.007904,39.145161],[-84.004577,39.14663],[-84.003163,39.146288],[-84.015731,39.185732],[-84.046886,39.162453],[-84.127108,39.01736]]]},"properties":{"id":"45176","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.996751,38.981581],[-84.127108,39.01736],[-84.129623,39.017397],[-84.201125,38.967836],[-84.139555,38.917616],[-84.133842,38.851537],[-84.047051,38.881253],[-83.946421,38.961059],[-83.910987,38.965505],[-83.886599,39.002031],[-83.996751,38.981581]]]},"properties":{"id":"45106","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.736854,38.748813],[-83.783822,38.695826],[-83.74792,38.649219],[-83.736854,38.748813]]]},"properties":{"id":"45101","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.756072,39.030402],[-83.75618,39.033303],[-83.757769,39.033147],[-83.756072,39.030402]]],[[[-83.756072,39.030402],[-83.706132,39.013394],[-83.623012,39.03109],[-83.512632,39.036119],[-83.393831,39.078083],[-83.379805,39.079534],[-83.344128,39.099652],[-83.318598,39.162508],[-83.344794,39.168808],[-83.350138,39.166362],[-83.349528,39.171569],[-83.42378,39.219242],[-83.465405,39.275308],[-83.625858,39.319822],[-83.743383,39.258438],[-83.784997,39.114992],[-83.756798,39.046288],[-83.745796,39.036351],[-83.756072,39.030402]]]]},"properties":{"id":"45133","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.859204,39.32909],[-83.904881,39.249452],[-83.841108,39.277443],[-83.859204,39.32909]]]},"properties":{"id":"45148","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.320726,39.455172],[-84.385252,39.441019],[-84.341405,39.412423],[-84.320726,39.455172]]]},"properties":{"id":"45050","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.81479,39.615964],[-84.815155,39.560551],[-84.74636,39.566955],[-84.81479,39.615964]]]},"properties":{"id":"45003","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.198803,40.725715],[-81.137844,40.671201],[-81.032929,40.690832],[-81.043296,40.702904],[-81.01309,40.813754],[-81.125261,40.836996],[-81.182684,40.761137],[-81.196227,40.759959],[-81.198803,40.725715]]]},"properties":{"id":"44657","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.016943,40.685859],[-81.043296,40.702904],[-81.032929,40.690832],[-81.012802,40.668731],[-81.016943,40.685859]]]},"properties":{"id":"44607","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.64491,40.235932],[-83.709645,40.210959],[-83.639037,40.082256],[-83.590522,40.130596],[-83.585319,40.179341],[-83.623883,40.234539],[-83.64491,40.235932]]]},"properties":{"id":"43009","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.010101,40.3176],[-84.109008,40.379055],[-84.297393,40.358474],[-84.323074,40.355548],[-84.335533,40.296688],[-84.272323,40.250667],[-84.108093,40.188819],[-84.079582,40.186927],[-84.008419,40.228531],[-84.012663,40.294857],[-84.033248,40.279724],[-84.062492,40.281463],[-84.060409,40.303157],[-84.012032,40.30065],[-84.010101,40.3176]],[[-84.097816,40.32732],[-84.094083,40.335918],[-84.086402,40.32946],[-84.097816,40.32732]]]},"properties":{"id":"45365","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.932536,39.91931],[-83.816023,39.924462],[-83.808233,39.927021],[-83.820504,39.963943],[-83.834008,40.010876],[-83.835462,40.010798],[-83.916596,39.947411],[-83.944973,39.92726],[-83.940426,39.919792],[-83.932536,39.91931]]]},"properties":{"id":"45504","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.672897,40.019096],[-83.682583,39.928899],[-83.682637,39.928449],[-83.54876,39.898817],[-83.542995,39.994041],[-83.672897,40.019096]]]},"properties":{"id":"45369","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.129623,39.017397],[-84.244988,39.067916],[-84.246625,39.018357],[-84.201125,38.967836],[-84.129623,39.017397]]]},"properties":{"id":"45102","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.085015,39.183216],[-84.095047,39.183925],[-84.095311,39.179634],[-84.085015,39.183216]]]},"properties":{"id":"45158","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.74776,39.696688],[-83.830994,39.560361],[-83.788735,39.553273],[-83.786159,39.557436],[-83.780446,39.553385],[-83.785828,39.550094],[-83.750611,39.546382],[-83.725165,39.575644],[-83.726643,39.584732],[-83.719504,39.579626],[-83.644561,39.614377],[-83.655686,39.693649],[-83.651048,39.739954],[-83.66346,39.743386],[-83.74776,39.696688]]]},"properties":{"id":"45335","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.632563,40.617199],[-80.51894,40.725822],[-80.600483,40.745411],[-80.62048,40.735634],[-80.664609,40.698322],[-80.632563,40.617199]]]},"properties":{"id":"43920","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.574037,40.798763],[-80.596938,40.829278],[-80.641402,40.79637],[-80.62048,40.735634],[-80.600483,40.745411],[-80.574037,40.798763]]]},"properties":{"id":"44455","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.875681,40.871062],[-82.876617,40.868902],[-82.873043,40.869415],[-82.873013,40.871048],[-82.875681,40.871062]]]},"properties":{"id":"44881","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.743298,40.962238],[-82.619278,40.942606],[-82.606019,40.994466],[-82.67381,41.037655],[-82.692687,41.031805],[-82.781525,41.025797],[-82.780155,40.995926],[-82.743298,40.962238]]]},"properties":{"id":"44865","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.858833,40.794296],[-82.854791,40.792198],[-82.854846,40.795802],[-82.857017,40.795819],[-82.858833,40.794296]]]},"properties":{"id":"44856","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.548878,41.574782],[-81.568219,41.590101],[-81.592852,41.5728],[-81.591051,41.545116],[-81.553655,41.561669],[-81.548878,41.574782]]]},"properties":{"id":"44110","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.716973,41.449969],[-81.734353,41.421181],[-81.725301,41.421082],[-81.685656,41.417367],[-81.666592,41.432469],[-81.684631,41.447347],[-81.669018,41.46988],[-81.669003,41.470438],[-81.673227,41.47819],[-81.716494,41.469664],[-81.716973,41.449969]]]},"properties":{"id":"44109","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.548878,41.574782],[-81.553655,41.561669],[-81.547793,41.558553],[-81.527599,41.545331],[-81.502639,41.587461],[-81.52673,41.586339],[-81.548878,41.574782]]]},"properties":{"id":"44117","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.669149,41.489133],[-81.666768,41.487341],[-81.665749,41.489456],[-81.668215,41.491409],[-81.669149,41.489133]]]},"properties":{"id":"44101","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.685656,41.417367],[-81.684585,41.350966],[-81.641013,41.351125],[-81.598061,41.350913],[-81.666592,41.432469],[-81.685656,41.417367]]]},"properties":{"id":"44131","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.438444,41.500842],[-81.390851,41.500748],[-81.391005,41.569716],[-81.419936,41.56995],[-81.43235,41.52723],[-81.438444,41.500842]]]},"properties":{"id":"44040","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.407621,39.963452],[-84.374376,39.989273],[-84.391549,40.022076],[-84.400617,40.051001],[-84.428777,40.050793],[-84.464432,39.926671],[-84.40437,39.91996],[-84.417318,39.959346],[-84.422004,39.968036],[-84.407675,39.96801],[-84.407621,39.963452]]]},"properties":{"id":"45337","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.393164,39.920148],[-84.392679,39.904818],[-84.404669,39.907955],[-84.40437,39.91996],[-84.464432,39.926671],[-84.485472,39.908958],[-84.493659,39.901931],[-84.483137,39.809343],[-84.458542,39.774031],[-84.366627,39.760199],[-84.348975,39.775637],[-84.33648,39.832496],[-84.368516,39.890388],[-84.388108,39.920227],[-84.393164,39.920148]]]},"properties":{"id":"45309","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.305675,41.442113],[-84.389126,41.427346],[-84.429368,41.427272],[-84.458868,41.412878],[-84.515967,41.325536],[-84.524802,41.25281],[-84.476106,41.194614],[-84.432099,41.165414],[-84.341602,41.165524],[-84.26667,41.180216],[-84.247264,41.194828],[-84.22737,41.29603],[-84.247781,41.427813],[-84.251635,41.427801],[-84.305675,41.442113]]]},"properties":{"id":"43512","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.64232,41.383226],[-84.803917,41.375308],[-84.803527,41.222551],[-84.66912,41.252569],[-84.630522,41.252736],[-84.630716,41.356675],[-84.630769,41.361359],[-84.64232,41.383226]]]},"properties":{"id":"43526","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.260644,41.438693],[-84.28643,41.44614],[-84.305675,41.442113],[-84.251635,41.427801],[-84.260644,41.438693]]],[[[-84.247781,41.427813],[-84.22737,41.29603],[-84.085402,41.297445],[-84.002602,41.341849],[-83.983757,41.349281],[-83.926429,41.417295],[-84.095704,41.486029],[-84.211529,41.474179],[-84.256812,41.437818],[-84.247781,41.427813]]]]},"properties":{"id":"43545","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.021777,40.175134],[-83.170874,40.182775],[-83.170734,40.175551],[-83.114767,40.132767],[-83.067252,40.117296],[-83.031692,40.137296],[-83.021777,40.175134]]]},"properties":{"id":"43065","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.645597,41.169175],[-82.673173,41.104781],[-82.626897,41.122711],[-82.639949,41.162554],[-82.645597,41.169175]]],[[[-82.642288,41.286474],[-82.66833,41.354949],[-82.747149,41.324381],[-82.766325,41.157236],[-82.653051,41.176869],[-82.642288,41.286474]]]]},"properties":{"id":"44847","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.3509,41.331608],[-82.354152,41.331114],[-82.3541,41.328968],[-82.35121,41.329156],[-82.3509,41.331608]]]},"properties":{"id":"44816","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.079357,39.997441],[-83.090432,39.927573],[-83.064123,39.921523],[-83.05202,39.962659],[-83.057651,39.968069],[-83.069056,39.991347],[-83.079357,39.997441]]]},"properties":{"id":"43204","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.090965,40.109018],[-83.104881,40.068727],[-83.046525,40.063352],[-83.034686,40.07477],[-83.004561,40.127281],[-83.005204,40.136336],[-83.031692,40.137296],[-83.067252,40.117296],[-83.090965,40.109018]]]},"properties":{"id":"43235","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.035191,40.018597],[-83.030585,40.003963],[-83.023509,39.990793],[-83.017594,40.009969],[-83.035191,40.018597]]]},"properties":{"id":"43210","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.171049,39.820638],[-83.172938,39.808974],[-83.157062,39.813042],[-83.169655,39.821249],[-83.171049,39.820638]]]},"properties":{"id":"43126","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.037823,41.48682],[-84.064086,41.646075],[-84.17789,41.695778],[-84.249242,41.603389],[-84.23453,41.532536],[-84.222216,41.533887],[-84.223931,41.528955],[-84.211529,41.474179],[-84.095704,41.486029],[-84.037823,41.48682]]]},"properties":{"id":"43567","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.28643,41.44614],[-84.380897,41.546936],[-84.465631,41.528461],[-84.422683,41.427343],[-84.389126,41.427346],[-84.305675,41.442113],[-84.28643,41.44614]]]},"properties":{"id":"43557","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.76174,38.866744],[-82.582683,38.779077],[-82.484902,38.772375],[-82.438721,38.858497],[-82.44857,38.90256],[-82.497174,38.958175],[-82.756173,38.914608],[-82.76174,38.866744]]]},"properties":{"id":"45656","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.585133,38.75687],[-82.46708,38.681978],[-82.484902,38.772375],[-82.582683,38.779077],[-82.585133,38.75687]]]},"properties":{"id":"45688","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.096937,39.793498],[-84.079707,39.775011],[-84.037252,39.849511],[-84.053736,39.850458],[-84.096937,39.793498]]]},"properties":{"id":"45433","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.126997,39.759164],[-84.039796,39.745134],[-84.0161,39.767906],[-84.079707,39.775011],[-84.096937,39.793498],[-84.131506,39.781529],[-84.126997,39.759164]]]},"properties":{"id":"45431","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.68358,40.09316],[-81.678848,39.936111],[-81.611414,39.935291],[-81.580856,39.93195],[-81.52253,39.99197],[-81.49738,39.98678],[-81.495483,39.990211],[-81.457089,40.034692],[-81.44736,40.04125],[-81.447325,40.128626],[-81.68358,40.09316]]]},"properties":{"id":"43725","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.515024,39.914643],[-81.51023,39.924338],[-81.52386,39.92537],[-81.52709,39.91705],[-81.515024,39.914643]]]},"properties":{"id":"43722","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.531773,39.934987],[-81.497783,39.983692],[-81.52253,39.99197],[-81.580856,39.93195],[-81.54724,39.92445],[-81.531773,39.934987]]]},"properties":{"id":"43723","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.497724,39.134862],[-84.483482,39.141327],[-84.471309,39.15715],[-84.465976,39.167994],[-84.483099,39.172358],[-84.501937,39.150201],[-84.50544,39.136908],[-84.497724,39.134862]]]},"properties":{"id":"45229","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.579765,39.09938],[-84.562964,39.11728],[-84.573363,39.127572],[-84.573923,39.127603],[-84.57411,39.126488],[-84.583108,39.122698],[-84.579765,39.09938]]]},"properties":{"id":"45205","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.583108,39.122698],[-84.57411,39.126488],[-84.576653,39.128539],[-84.583108,39.122698]]],[[[-84.618175,39.141301],[-84.634006,39.138133],[-84.626513,39.07919],[-84.579765,39.09938],[-84.583108,39.122698],[-84.618175,39.141301]]]]},"properties":{"id":"45238","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.601948,39.188104],[-84.628816,39.178035],[-84.618175,39.141301],[-84.583108,39.122698],[-84.576653,39.128539],[-84.573923,39.127603],[-84.573363,39.127572],[-84.564335,39.135536],[-84.567526,39.157716],[-84.57866,39.175857],[-84.601948,39.188104]]]},"properties":{"id":"45211","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.518749,39.258291],[-84.505218,39.264295],[-84.512424,39.275396],[-84.537477,39.265261],[-84.518749,39.258291]]]},"properties":{"id":"45218","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.672295,40.906797],[-83.689307,40.856177],[-83.575855,40.862415],[-83.541571,40.949783],[-83.538129,40.962583],[-83.672295,40.906797]]]},"properties":{"id":"45814","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.283619,40.527349],[-83.361998,40.658182],[-83.362214,40.687302],[-83.362371,40.717937],[-83.394173,40.696622],[-83.419836,40.686823],[-83.439247,40.608971],[-83.406173,40.505408],[-83.310129,40.506471],[-83.283619,40.527349]]]},"properties":{"id":"43332","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.887566,40.183436],[-80.883181,40.180674],[-80.88313,40.183358],[-80.887566,40.183436]]]},"properties":{"id":"43974","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.745796,39.036351],[-83.756798,39.046288],[-83.757769,39.033147],[-83.75618,39.033303],[-83.745796,39.036351]]]},"properties":{"id":"45155","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.692687,41.031805],[-82.67381,41.037655],[-82.689652,41.038603],[-82.692687,41.031805]]]},"properties":{"id":"44850","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.712331,40.430106],[-80.612876,40.429375],[-80.61365,40.495748],[-80.629029,40.516853],[-80.623379,40.523617],[-80.667957,40.582496],[-80.668717,40.58248],[-80.670757,40.562654],[-80.747788,40.467078],[-80.712331,40.430106]]]},"properties":{"id":"43964","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.68748,40.179568],[-80.705661,40.166403],[-80.700017,40.161647],[-80.68748,40.179568]]]},"properties":{"id":"43963","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.892077,40.459708],[-80.884784,40.459552],[-80.888732,40.468118],[-80.892065,40.468062],[-80.892077,40.459708]]]},"properties":{"id":"43970","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.108143,41.817567],[-81.191078,41.784403],[-81.137141,41.727902],[-81.108143,41.817567]]]},"properties":{"id":"44081","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.316993,38.536698],[-82.402081,38.520111],[-82.389609,38.434378],[-82.303238,38.521162],[-82.316993,38.536698]]]},"properties":{"id":"45669","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.462813,39.93038],[-82.500022,39.92642],[-82.482141,39.925499],[-82.462813,39.93038]]]},"properties":{"id":"43008","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.256277,39.97314],[-82.288377,39.966043],[-82.334547,39.859067],[-82.252157,39.872617],[-82.251155,39.946476],[-82.259036,39.94313],[-82.261452,39.952067],[-82.253147,39.951799],[-82.256277,39.97314]]]},"properties":{"id":"43739","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.320699,41.237645],[-82.34109,41.173834],[-82.293631,41.073728],[-82.28967,41.07369],[-82.220789,41.084773],[-82.200942,41.090188],[-82.097788,41.143851],[-82.081871,41.186446],[-82.098724,41.199823],[-82.180331,41.237497],[-82.320699,41.237645]]]},"properties":{"id":"44090","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.974235,41.208924],[-81.869149,41.218513],[-81.88352,41.275008],[-81.980695,41.259884],[-81.974235,41.208924]]]},"properties":{"id":"44280","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.882932,41.497593],[-83.87467,41.487673],[-83.86518,41.495216],[-83.865728,41.495735],[-83.882932,41.497593]]]},"properties":{"id":"43547","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.481619,39.733478],[-83.481759,39.729465],[-83.472548,39.728915],[-83.472038,39.733214],[-83.481619,39.733478]]]},"properties":{"id":"43151","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.711139,41.046061],[-80.688246,40.988014],[-80.66276,40.987936],[-80.628405,41.060682],[-80.644975,41.060793],[-80.663947,41.060939],[-80.711139,41.046061]]]},"properties":{"id":"44512","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.956162,41.121913],[-81.002415,41.110677],[-81.002307,41.063468],[-81.00236,41.062093],[-80.959859,41.085244],[-80.956162,41.121913]]]},"properties":{"id":"44429","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.959859,41.085244],[-80.91806,41.061457],[-80.930776,41.114369],[-80.956162,41.121913],[-80.959859,41.085244]]],[[[-81.037267,41.138594],[-81.098618,41.099107],[-81.071633,41.062325],[-81.002307,41.063468],[-81.002415,41.110677],[-81.037267,41.138594]]]]},"properties":{"id":"44412","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.640473,41.094366],[-80.644286,41.102731],[-80.645507,41.11133],[-80.655282,41.111913],[-80.675598,41.105948],[-80.674928,41.088365],[-80.663983,41.087634],[-80.644975,41.060793],[-80.628405,41.060682],[-80.615899,41.060609],[-80.600891,41.064179],[-80.615481,41.07587],[-80.640473,41.094366]]]},"properties":{"id":"44502","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.864809,41.257567],[-80.864636,41.284327],[-80.778746,41.277074],[-80.821844,41.340658],[-80.897175,41.334081],[-80.913311,41.308572],[-80.913562,41.272189],[-80.864809,41.257567]]],[[[-80.947174,41.202976],[-80.905972,41.134388],[-80.824546,41.134308],[-80.794473,41.1763],[-80.805318,41.203162],[-80.815179,41.214805],[-80.862417,41.225206],[-80.947174,41.202976]]]]},"properties":{"id":"44481","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.665185,41.131059],[-80.655282,41.111913],[-80.645507,41.11133],[-80.665185,41.131059]]]},"properties":{"id":"44504","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.947174,41.202976],[-80.948529,41.240267],[-81.002746,41.250001],[-81.002758,41.229527],[-81.02944,41.18456],[-81.037267,41.138594],[-81.002415,41.110677],[-80.956162,41.121913],[-80.930776,41.114369],[-80.905972,41.134388],[-80.947174,41.202976]]]},"properties":{"id":"44444","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.551879,40.728066],[-84.689163,40.727705],[-84.802143,40.657555],[-84.802272,40.6224],[-84.604321,40.64777],[-84.513561,40.728204],[-84.551879,40.728066]]]},"properties":{"id":"45882","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.764999,40.527293],[-84.688789,40.443403],[-84.590708,40.45076],[-84.764999,40.527293]]]},"properties":{"id":"45828","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.277187,39.854259],[-81.350111,39.878963],[-81.359665,39.866717],[-81.384677,39.755838],[-81.319125,39.70736],[-81.310582,39.707305],[-81.277187,39.854259]]]},"properties":{"id":"43788","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.481411,39.722809],[-84.347561,39.701876],[-84.366627,39.760199],[-84.458542,39.774031],[-84.481411,39.722809]]]},"properties":{"id":"45345","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.384677,39.755838],[-81.359665,39.866717],[-81.434829,39.883879],[-81.490435,39.82114],[-81.384677,39.755838]]]},"properties":{"id":"43779","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.562212,39.794159],[-81.552921,39.785556],[-81.554437,39.794318],[-81.562212,39.794159]]]},"properties":{"id":"43717","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.339071,41.58882],[-83.342395,41.60291],[-83.353305,41.58892],[-83.339071,41.58882]]]},"properties":{"id":"43468","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.368227,41.574123],[-83.368419,41.562463],[-83.358565,41.562513],[-83.368176,41.582153],[-83.368227,41.574123]]]},"properties":{"id":"43408","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.821119,41.492797],[-82.730731,41.503202],[-82.812284,41.565374],[-82.821119,41.492797]]]},"properties":{"id":"43440","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.419035,41.070324],[-84.382148,41.033999],[-84.341929,41.063351],[-84.341602,41.165524],[-84.432099,41.165414],[-84.419452,41.095743],[-84.419035,41.070324]]]},"properties":{"id":"45873","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.72653,40.989572],[-84.630251,40.975073],[-84.497785,40.9779],[-84.534178,41.004513],[-84.707326,41.018007],[-84.72653,40.989572]]]},"properties":{"id":"45886","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.578131,41.087695],[-84.578251,41.091631],[-84.588275,41.091567],[-84.588147,41.085766],[-84.578131,41.087695]]]},"properties":{"id":"45855","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.803292,41.004016],[-84.802551,40.873326],[-84.74602,40.822337],[-84.630251,40.975073],[-84.72653,40.989572],[-84.803292,41.004016]]]},"properties":{"id":"45832","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.988733,38.979925],[-83.20162,38.988871],[-83.203574,38.959959],[-83.118309,38.869696],[-83.017872,38.834732],[-82.906846,38.849557],[-82.856746,38.942055],[-82.988733,38.979925]]]},"properties":{"id":"45648","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.298898,41.240578],[-81.148427,41.242948],[-81.134294,41.264921],[-81.159745,41.276496],[-81.196391,41.347555],[-81.28074,41.34743],[-81.322381,41.275667],[-81.298898,41.240578]]]},"properties":{"id":"44255","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.345833,40.925743],[-84.335463,40.925054],[-84.335695,40.934469],[-84.343964,40.933896],[-84.345833,40.925743]]]},"properties":{"id":"45876","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.539416,40.747303],[-82.570025,40.700293],[-82.51147,40.696701],[-82.475444,40.742168],[-82.498917,40.755003],[-82.516075,40.752707],[-82.539416,40.747303]]]},"properties":{"id":"44907","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.747695,39.468756],[-82.750685,39.460955],[-82.741316,39.460459],[-82.740793,39.46826],[-82.747695,39.468756]]]},"properties":{"id":"43101","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.821753,39.205952],[-82.811374,39.196955],[-82.800537,39.209447],[-82.821753,39.205952]]]},"properties":{"id":"45673","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.240313,41.242602],[-83.264874,41.203839],[-83.304474,41.137163],[-83.285315,41.130387],[-83.304829,41.099835],[-83.247458,41.028998],[-83.20504,40.992962],[-83.074156,41.012396],[-83.054182,41.082576],[-83.054199,41.213194],[-83.14568,41.239111],[-83.148028,41.238043],[-83.147958,41.239891],[-83.144491,41.240033],[-83.154557,41.25575],[-83.219406,41.253195],[-83.224998,41.252975],[-83.240313,41.242602]]]},"properties":{"id":"44883","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.144491,41.240033],[-83.147958,41.239891],[-83.148028,41.238043],[-83.14568,41.239111],[-83.144491,41.240033]]]},"properties":{"id":"44861","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.012032,40.30065],[-84.060409,40.303157],[-84.062492,40.281463],[-84.033248,40.279724],[-84.012663,40.294857],[-84.012032,40.30065]]]},"properties":{"id":"45353","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.454517,40.67894],[-81.533427,40.648488],[-81.537606,40.647102],[-81.502373,40.602922],[-81.43918,40.618429],[-81.413367,40.612807],[-81.372995,40.633895],[-81.454517,40.67894]]]},"properties":{"id":"44612","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.384674,40.80045],[-81.381734,40.799871],[-81.372722,40.809163],[-81.368334,40.818276],[-81.390806,40.80971],[-81.387543,40.800712],[-81.384674,40.80045]]]},"properties":{"id":"44703","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.348441,40.85973],[-81.362791,40.809403],[-81.323673,40.812063],[-81.314232,40.817158],[-81.306134,40.868957],[-81.348441,40.85973]]]},"properties":{"id":"44705","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.48244,40.846723],[-81.39479,40.823561],[-81.422763,40.858303],[-81.479696,40.878053],[-81.48244,40.846723]]]},"properties":{"id":"44718","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.630137,40.775858],[-81.579313,40.857726],[-81.647822,40.894992],[-81.695945,40.870077],[-81.6767,40.846181],[-81.630137,40.775858]]]},"properties":{"id":"44666","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.524794,41.096957],[-81.518652,41.088565],[-81.518746,41.075216],[-81.504149,41.071146],[-81.494429,41.075417],[-81.490814,41.087588],[-81.524794,41.096957]]]},"properties":{"id":"44304","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.431434,41.026456],[-81.444335,41.024156],[-81.445053,41.014854],[-81.434023,41.014686],[-81.431434,41.026456]]]},"properties":{"id":"44250","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.527458,41.103764],[-81.548234,41.098893],[-81.519645,41.085015],[-81.518652,41.088565],[-81.524794,41.096957],[-81.527458,41.103764]]]},"properties":{"id":"44303","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.515487,40.943927],[-81.507522,40.929468],[-81.495514,40.938611],[-81.515487,40.943927]]],[[[-81.565716,40.963023],[-81.517303,40.945259],[-81.495578,40.97484],[-81.475936,41.006856],[-81.505736,41.029071],[-81.536939,41.032855],[-81.555835,41.022298],[-81.565716,40.963023]]]]},"properties":{"id":"44319","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.51864,41.350154],[-81.58525,41.350942],[-81.598061,41.350913],[-81.566284,41.28776],[-81.537502,41.276146],[-81.489435,41.277053],[-81.51864,41.350154]]]},"properties":{"id":"44067","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.414298,41.13556],[-81.449934,41.148056],[-81.452634,41.124785],[-81.414298,41.13556]]]},"properties":{"id":"44262","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.773982,41.272575],[-80.79844,41.203161],[-80.711122,41.205288],[-80.704238,41.27232],[-80.773982,41.272575]]]},"properties":{"id":"44484","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.773323,41.41878],[-80.821844,41.340658],[-80.778746,41.277074],[-80.773982,41.272575],[-80.704238,41.27232],[-80.645436,41.287619],[-80.637184,41.35019],[-80.713231,41.443741],[-80.773323,41.41878]]]},"properties":{"id":"44410","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.769139,40.797641],[-84.769058,40.804416],[-84.780211,40.804435],[-84.775279,40.796621],[-84.769139,40.797641]]]},"properties":{"id":"45899","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.329173,39.086823],[-82.318613,39.074071],[-82.318128,39.07933],[-82.329173,39.086823]]],[[[-82.329173,39.086823],[-82.344216,39.2075],[-82.417748,39.211243],[-82.447181,39.138797],[-82.426259,39.122211],[-82.329173,39.086823]]]]},"properties":{"id":"45695","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.295077,39.545974],[-81.442619,39.491322],[-81.533335,39.476862],[-81.549484,39.455174],[-81.599308,39.376935],[-81.559111,39.33297],[-81.304332,39.370791],[-81.281426,39.435776],[-81.272019,39.484126],[-81.24878,39.510985],[-81.295077,39.545974]]]},"properties":{"id":"45750","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.806433,40.988251],[-81.853399,40.971849],[-81.878526,40.916275],[-81.82281,40.885793],[-81.802768,40.901903],[-81.797632,40.909237],[-81.806433,40.988251]]]},"properties":{"id":"44276","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.543033,41.61405],[-83.55443,41.594135],[-83.537927,41.594076],[-83.465758,41.591229],[-83.415773,41.604132],[-83.415919,41.618938],[-83.505632,41.617848],[-83.543033,41.61405]]]},"properties":{"id":"43619","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.537927,41.594076],[-83.483247,41.529398],[-83.465758,41.591229],[-83.537927,41.594076]]]},"properties":{"id":"43465","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.55443,41.594135],[-83.588034,41.60095],[-83.606408,41.580266],[-83.682841,41.545283],[-83.712843,41.492494],[-83.531888,41.477738],[-83.415019,41.471717],[-83.414626,41.500228],[-83.450474,41.542848],[-83.483247,41.529398],[-83.537927,41.594076],[-83.55443,41.594135]],[[-83.508223,41.508021],[-83.509096,41.508401],[-83.507841,41.510002],[-83.507247,41.507878],[-83.508223,41.508021]]]},"properties":{"id":"43551","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.786524,41.415756],[-83.883532,41.343514],[-83.883407,41.335294],[-83.805265,41.313798],[-83.727745,41.309301],[-83.786524,41.415756]]]},"properties":{"id":"43569","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.74259,41.421511],[-83.742569,41.414853],[-83.737708,41.414852],[-83.737771,41.425054],[-83.74259,41.421511]]]},"properties":{"id":"43565","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.64297,41.376968],[-83.629449,41.374526],[-83.629564,41.378126],[-83.64297,41.376968]]]},"properties":{"id":"43403","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.623012,39.03109],[-83.542333,38.896661],[-83.543726,38.907868],[-83.512632,39.036119],[-83.623012,39.03109]]]},"properties":{"id":"45679","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.01322,40.731125],[-84.119139,40.732253],[-84.139909,40.708644],[-83.993844,40.687694],[-83.994107,40.701924],[-84.01322,40.731125]]]},"properties":{"id":"45804","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.57595,41.009697],[-82.606019,40.994466],[-82.619278,40.942606],[-82.532773,40.876352],[-82.429384,40.883153],[-82.455454,40.889858],[-82.57595,41.009697]]]},"properties":{"id":"44878","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.71334,41.926929],[-80.666563,41.904327],[-80.597592,41.81869],[-80.519378,41.81915],[-80.71334,41.926929]]]},"properties":{"id":"44030","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.003631,41.715137],[-81.127798,41.707052],[-81.112673,41.658171],[-81.078807,41.64156],[-81.002795,41.63539],[-81.002781,41.642],[-80.998766,41.673412],[-81.003631,41.715137]]]},"properties":{"id":"44086","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.84819,39.396385],[-81.947149,39.366452],[-81.949652,39.332396],[-81.857297,39.310966],[-81.85293,39.325373],[-81.84819,39.396385]]]},"properties":{"id":"45778","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.09,40.602287],[-84.082666,40.599084],[-84.084768,40.606652],[-84.09,40.602287]]]},"properties":{"id":"45888","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.94234,40.029923],[-81.028158,40.095662],[-81.065736,40.086306],[-81.060707,40.06618],[-81.062312,40.06577],[-81.030069,39.944062],[-81.013216,39.909134],[-80.990764,39.93854],[-80.890322,40.004336],[-80.902917,40.015432],[-80.937779,40.022531],[-80.94234,40.029923]]]},"properties":{"id":"43718","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.944548,40.122817],[-80.950746,40.116799],[-80.955915,40.114832],[-80.951272,40.112396],[-80.948598,40.113846],[-80.94898,40.119128],[-80.944548,40.122817]]]},"properties":{"id":"43927","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.140838,40.171051],[-81.141213,40.163648],[-81.131687,40.160053],[-81.130259,40.171058],[-81.140838,40.171051]]]},"properties":{"id":"43985","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.627724,40.2594],[-83.628729,40.246315],[-83.625946,40.248505],[-83.627724,40.2594]]],[[[-83.627724,40.2594],[-83.624939,40.2892],[-83.623581,40.30343],[-83.688433,40.311919],[-83.841394,40.263288],[-83.865203,40.213466],[-83.709645,40.210959],[-83.64491,40.235932],[-83.627724,40.2594]]]]},"properties":{"id":"43357","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.590522,40.130596],[-83.503084,40.120206],[-83.490745,40.132432],[-83.532215,40.201679],[-83.585319,40.179341],[-83.590522,40.130596]]]},"properties":{"id":"43084","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.820504,39.963943],[-83.808233,39.927021],[-83.806763,39.924384],[-83.732118,39.93433],[-83.820504,39.963943]]]},"properties":{"id":"45503","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.037252,39.849511],[-84.079707,39.775011],[-84.0161,39.767906],[-84.007722,39.76739],[-83.961659,39.785783],[-83.939806,39.843449],[-83.939692,39.886995],[-83.954368,39.888473],[-83.995342,39.875372],[-84.037252,39.849511]]]},"properties":{"id":"45324","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.139555,38.917616],[-84.231858,38.871906],[-84.227317,38.821343],[-84.216057,38.821836],[-84.133842,38.851537],[-84.139555,38.917616]]]},"properties":{"id":"45153","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.085015,39.183216],[-84.095311,39.179634],[-84.160774,39.172587],[-84.25827,39.11907],[-84.244988,39.067916],[-84.129623,39.017397],[-84.127108,39.01736],[-84.046886,39.162453],[-84.085015,39.183216]],[[-84.141525,39.123252],[-84.130156,39.121463],[-84.136351,39.117105],[-84.141525,39.123252]]]},"properties":{"id":"45103","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.293273,39.210758],[-84.312411,39.211104],[-84.296099,39.209712],[-84.293273,39.210758]]],[[[-84.293273,39.210758],[-84.288698,39.186526],[-84.288288,39.186487],[-84.293273,39.210758]]]]},"properties":{"id":"45147","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.141525,39.123252],[-84.136351,39.117105],[-84.130156,39.121463],[-84.141525,39.123252]]]},"properties":{"id":"45160","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.70341,39.476687],[-83.676519,39.482853],[-83.672587,39.475658],[-83.698335,39.471663],[-83.661012,39.414169],[-83.638175,39.372847],[-83.545617,39.428832],[-83.613621,39.607183],[-83.644561,39.614377],[-83.719504,39.579626],[-83.725165,39.575644],[-83.750611,39.546382],[-83.70341,39.476687]]]},"properties":{"id":"45169","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.841108,39.277443],[-83.755881,39.280292],[-83.757898,39.354302],[-83.846748,39.358449],[-83.859204,39.32909],[-83.841108,39.277443]]]},"properties":{"id":"45146","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.594439,40.89436],[-80.596938,40.829278],[-80.574037,40.798763],[-80.519108,40.812869],[-80.519071,40.898504],[-80.572193,40.897732],[-80.594439,40.89436]]]},"properties":{"id":"44413","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.737602,40.668897],[-80.755856,40.63435],[-80.668717,40.58248],[-80.667957,40.582496],[-80.632563,40.617199],[-80.664609,40.698322],[-80.737602,40.668897]]]},"properties":{"id":"43968","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.572193,40.897732],[-80.55144,40.942038],[-80.576691,40.958243],[-80.606009,40.958153],[-80.636916,40.914315],[-80.62562,40.895419],[-80.594439,40.89436],[-80.572193,40.897732]]]},"properties":{"id":"44443","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.68358,40.09316],[-81.447325,40.128626],[-81.38399,40.12955],[-81.38142,40.14212],[-81.41066,40.19819],[-81.437058,40.216638],[-81.695061,40.184089],[-81.776365,40.154168],[-81.68358,40.09316]]]},"properties":{"id":"43749","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.68645,40.41982],[-81.668331,40.369519],[-81.55578,40.39405],[-81.52977,40.39467],[-81.593888,40.458004],[-81.66345,40.41357],[-81.68645,40.41982]]]},"properties":{"id":"43840","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.769914,40.495757],[-81.805195,40.437191],[-81.68645,40.41982],[-81.66345,40.41357],[-81.769914,40.495757]]]},"properties":{"id":"43804","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.170083,40.891538],[-83.074051,40.877512],[-83.016496,40.935756],[-83.074156,41.012396],[-83.20504,40.992962],[-83.268738,40.956411],[-83.170083,40.891538]]]},"properties":{"id":"44882","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.054182,41.082576],[-83.074156,41.012396],[-83.016496,40.935756],[-82.940173,40.921169],[-82.901156,40.993659],[-82.976677,41.095757],[-83.054182,41.082576]],[[-82.945487,40.950285],[-82.943554,40.954411],[-82.94373,40.957497],[-82.940422,40.957544],[-82.943944,40.950292],[-82.945487,40.950285]]]},"properties":{"id":"44818","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.824515,41.350719],[-81.835917,41.275597],[-81.785097,41.27645],[-81.785057,41.350697],[-81.824515,41.350719]]]},"properties":{"id":"44136","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.626482,41.548673],[-81.628448,41.520406],[-81.598906,41.523634],[-81.591051,41.545116],[-81.592852,41.5728],[-81.626482,41.548673]]]},"properties":{"id":"44108","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.54608,41.608007],[-81.526729,41.587272],[-81.509808,41.624043],[-81.54608,41.608007]]]},"properties":{"id":"44123","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.586702,40.192445],[-84.708585,40.182166],[-84.808916,40.092644],[-84.809737,40.048911],[-84.79662,40.043351],[-84.750264,40.049792],[-84.744331,40.051587],[-84.74109,40.049903],[-84.652958,39.97454],[-84.467822,40.064792],[-84.523923,40.180755],[-84.586702,40.192445]]]},"properties":{"id":"45331","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.488271,39.99024],[-84.490577,39.983773],[-84.483485,39.983982],[-84.488271,39.99024]]]},"properties":{"id":"45358","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.66912,41.252569],[-84.803527,41.222551],[-84.803504,41.121331],[-84.688271,41.133428],[-84.631349,41.208547],[-84.66912,41.252569]]]},"properties":{"id":"45813","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.26667,41.180216],[-84.341602,41.165524],[-84.341929,41.063351],[-84.198172,41.050066],[-84.131291,41.093463],[-84.160197,41.100913],[-84.170513,41.166147],[-84.26667,41.180216]]]},"properties":{"id":"45831","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.954962,40.149002],[-83.004458,40.136305],[-82.971307,40.134933],[-82.954962,40.149002]]]},"properties":{"id":"43240","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.735887,40.260317],[-82.616493,40.237993],[-82.582568,40.245542],[-82.585739,40.269944],[-82.67134,40.384242],[-82.79365,40.339824],[-82.735887,40.260317]]]},"properties":{"id":"43011","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.73987,40.208234],[-82.778205,40.181768],[-82.789021,40.164821],[-82.78107,40.126764],[-82.765615,40.083674],[-82.666467,40.103667],[-82.560402,40.125652],[-82.554455,40.151882],[-82.517762,40.18982],[-82.582568,40.245542],[-82.616493,40.237993],[-82.73987,40.208234]]]},"properties":{"id":"43031","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.249558,40.376507],[-83.221477,40.423855],[-83.289613,40.499409],[-83.310129,40.506471],[-83.406173,40.505408],[-83.445999,40.462732],[-83.452873,40.414516],[-83.39738,40.350227],[-83.249593,40.362767],[-83.249558,40.376507]]]},"properties":{"id":"43344","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.093573,40.462994],[-83.205678,40.522403],[-83.211729,40.530216],[-83.289613,40.499409],[-83.221477,40.423855],[-83.092105,40.436376],[-83.093573,40.462994]]]},"properties":{"id":"43342","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.39738,40.350227],[-83.543776,40.297073],[-83.548246,40.256562],[-83.4946,40.224445],[-83.36468,40.139629],[-83.345879,40.137005],[-83.340747,40.140471],[-83.189067,40.20453],[-83.249593,40.362767],[-83.39738,40.350227]],[[-83.263861,40.348601],[-83.264263,40.356438],[-83.257276,40.354512],[-83.263861,40.348601]]]},"properties":{"id":"43040","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.457787,41.357469],[-82.543436,41.325581],[-82.542379,41.300992],[-82.534741,41.289416],[-82.442548,41.283874],[-82.424292,41.346346],[-82.457787,41.357469]]]},"properties":{"id":"44814","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.766085,41.330907],[-82.847634,41.428756],[-82.860033,41.39275],[-82.844356,41.357709],[-82.766085,41.330907]]]},"properties":{"id":"44824","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.66833,41.354949],[-82.642288,41.286474],[-82.542379,41.300992],[-82.543436,41.325581],[-82.614126,41.358625],[-82.66833,41.354949]]]},"properties":{"id":"44846","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.479271,39.773706],[-82.43598,39.746438],[-82.367155,39.731148],[-82.346652,39.741201],[-82.385514,39.807235],[-82.446488,39.806081],[-82.479271,39.773706]]]},"properties":{"id":"43150","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.629659,39.931964],[-82.68444,39.934666],[-82.692042,39.846221],[-82.633757,39.811566],[-82.577316,39.797734],[-82.512656,39.839368],[-82.629659,39.931964]],[[-82.549585,39.838539],[-82.552813,39.843491],[-82.545806,39.847377],[-82.539716,39.840638],[-82.549585,39.838539]]]},"properties":{"id":"43105","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.781039,39.557565],[-82.77569,39.557275],[-82.831317,39.666322],[-82.781039,39.557565]]]},"properties":{"id":"43154","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.919418,40.081274],[-82.862012,40.078854],[-82.78107,40.126764],[-82.971307,40.134933],[-83.004458,40.136305],[-83.005204,40.136336],[-83.004561,40.127281],[-82.977417,40.110511],[-82.949166,40.110604],[-82.919418,40.081274]]]},"properties":{"id":"43081","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.996855,40.02419],[-82.935363,40.027019],[-82.924234,40.058175],[-82.956895,40.05976],[-82.999551,40.061666],[-82.997358,40.031183],[-82.996855,40.02419]]]},"properties":{"id":"43224","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.034686,40.07477],[-83.046525,40.063352],[-83.03817,40.029553],[-83.036695,40.024117],[-82.997358,40.031183],[-82.999551,40.061666],[-83.000887,40.080265],[-83.034686,40.07477]]]},"properties":{"id":"43214","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.950013,39.967996],[-82.94769,39.948572],[-82.945377,39.933681],[-82.92055,39.917759],[-82.915805,39.93181],[-82.914815,39.956579],[-82.913002,39.979128],[-82.950013,39.967996]]]},"properties":{"id":"43209","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.030585,40.003963],[-83.06789,39.991263],[-83.020879,39.980922],[-83.023509,39.990793],[-83.030585,40.003963]]]},"properties":{"id":"43212","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.813735,39.98204],[-82.825507,39.984069],[-82.826836,39.981294],[-82.813735,39.98204]]],[[[-82.914815,39.956579],[-82.856277,39.943574],[-82.831115,39.954782],[-82.828901,39.981063],[-82.86603,39.986487],[-82.913002,39.979128],[-82.914815,39.956579]]]]},"properties":{"id":"43213","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.998694,41.716813],[-83.997708,41.685235],[-83.951319,41.671493],[-83.879335,41.686285],[-83.880387,41.720081],[-83.998694,41.716813]]]},"properties":{"id":"43540","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.17789,41.695778],[-84.064086,41.646075],[-83.997708,41.685235],[-83.998694,41.716813],[-84.173125,41.711638],[-84.17789,41.695778]]]},"properties":{"id":"43533","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.280228,39.010945],[-82.382736,38.893173],[-82.382556,38.891196],[-82.356018,38.873855],[-82.195866,38.945132],[-82.14868,39.005159],[-82.201654,39.007638],[-82.280228,39.010945]]]},"properties":{"id":"45614","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.391005,41.569716],[-81.390851,41.500748],[-81.376136,41.500412],[-81.287289,41.49961],[-81.243739,41.499964],[-81.29588,41.56995],[-81.391005,41.569716]]]},"properties":{"id":"44026","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.126331,41.382496],[-81.190863,41.383957],[-81.196391,41.347555],[-81.159745,41.276496],[-81.126331,41.382496]]]},"properties":{"id":"44234","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.068085,41.373543],[-81.06603,41.369235],[-81.039252,41.354014],[-81.035781,41.377423],[-81.068085,41.373543]]]},"properties":{"id":"44080","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.961659,39.785783],[-84.007722,39.76739],[-84.043675,39.691991],[-84.056243,39.685299],[-84.042366,39.64437],[-83.972755,39.568898],[-83.830994,39.560361],[-83.74776,39.696688],[-83.865964,39.7671],[-83.961659,39.785783]],[[-83.902072,39.727762],[-83.898138,39.729332],[-83.873266,39.717311],[-83.884141,39.708294],[-83.888057,39.720594],[-83.902072,39.727762]]]},"properties":{"id":"45385","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.114195,39.577983],[-83.977005,39.569169],[-83.972755,39.568898],[-84.042366,39.64437],[-84.113652,39.583961],[-84.114195,39.577983]]]},"properties":{"id":"45370","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.113652,39.583961],[-84.042366,39.64437],[-84.056243,39.685299],[-84.109666,39.655968],[-84.108181,39.627466],[-84.113652,39.583961]]]},"properties":{"id":"45305","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.54724,39.92445],[-81.52709,39.91705],[-81.531773,39.934987],[-81.54724,39.92445]]]},"properties":{"id":"43733","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.760771,39.176279],[-84.761073,39.177722],[-84.766789,39.177717],[-84.76681,39.175878],[-84.760771,39.176279]]]},"properties":{"id":"45033","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.649203,39.09583],[-84.642853,39.09747],[-84.648501,39.101301],[-84.649203,39.09583]]]},"properties":{"id":"45051","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.880603,40.993155],[-83.870346,40.951232],[-83.804143,40.936653],[-83.837004,40.993372],[-83.880563,41.020108],[-83.880603,40.993155]]]},"properties":{"id":"45868","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.921425,41.283915],[-84.030174,41.2832],[-84.016209,41.177],[-83.869299,41.152635],[-83.780247,41.168289],[-83.771391,41.182856],[-83.794135,41.193034],[-83.902047,41.28409],[-83.921425,41.283915]]]},"properties":{"id":"43516","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.561108,40.561428],[-83.520227,40.504077],[-83.445999,40.462732],[-83.406173,40.505408],[-83.439247,40.608971],[-83.561108,40.561428]]]},"properties":{"id":"43340","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.864554,40.594022],[-83.855915,40.530438],[-83.857214,40.498614],[-83.74217,40.461196],[-83.708788,40.475131],[-83.677942,40.533501],[-83.806334,40.637647],[-83.864554,40.594022]]]},"properties":{"id":"43310","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.498417,39.57772],[-82.571927,39.451383],[-82.572398,39.449425],[-82.56563,39.440368],[-82.524433,39.416673],[-82.45866,39.387567],[-82.364352,39.443758],[-82.340908,39.45123],[-82.330406,39.474203],[-82.333582,39.482948],[-82.320416,39.486134],[-82.313374,39.474398],[-82.225034,39.557627],[-82.224246,39.559948],[-82.33757,39.652819],[-82.359443,39.657005],[-82.448869,39.659078],[-82.498417,39.57772]]]},"properties":{"id":"43138","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.729136,40.674812],[-81.864114,40.726159],[-81.93318,40.696615],[-81.931782,40.667231],[-81.877604,40.619456],[-81.729087,40.6315],[-81.729136,40.674812]]]},"properties":{"id":"44627","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.447181,39.138797],[-82.590246,39.185513],[-82.633601,39.18792],[-82.658477,39.125354],[-82.435309,39.035079],[-82.426259,39.122211],[-82.447181,39.138797]],[[-82.61373,39.113053],[-82.601804,39.115545],[-82.602482,39.108444],[-82.611935,39.10637],[-82.61373,39.113053]]]},"properties":{"id":"45692","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.633601,39.18792],[-82.6513,39.267666],[-82.761953,39.218075],[-82.74802,39.14682],[-82.658477,39.125354],[-82.633601,39.18792]]]},"properties":{"id":"45672","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.642141,40.34169],[-80.729061,40.395695],[-80.753483,40.379011],[-80.742645,40.321382],[-80.642141,40.34169]]]},"properties":{"id":"43953","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.370104,40.391367],[-82.377151,40.283172],[-82.359847,40.270168],[-82.286777,40.289671],[-82.261941,40.2922],[-82.260631,40.305375],[-82.370104,40.391367]]]},"properties":{"id":"43022","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.470297,40.539379],[-82.41153,40.471358],[-82.362322,40.506321],[-82.323347,40.52604],[-82.336962,40.555001],[-82.407086,40.610938],[-82.470297,40.539379]]]},"properties":{"id":"44822","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.291226,41.735943],[-81.280326,41.760344],[-81.290829,41.757452],[-81.291226,41.735943]]]},"properties":{"id":"44045","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.418939,39.960822],[-82.484696,40.02697],[-82.572277,39.997338],[-82.5903,39.961343],[-82.599204,39.957914],[-82.500022,39.92642],[-82.462813,39.93038],[-82.419067,39.959624],[-82.418939,39.960822]]]},"properties":{"id":"43025","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.444007,40.192421],[-82.268889,40.199407],[-82.28124,40.22269],[-82.444007,40.192421]]]},"properties":{"id":"43071","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.252157,39.872617],[-82.188746,39.821671],[-82.141397,39.857194],[-82.135195,39.855678],[-82.116207,39.876855],[-82.168487,39.922358],[-82.222272,39.948626],[-82.224175,39.951043],[-82.256277,39.97314],[-82.253147,39.951799],[-82.251155,39.946476],[-82.252157,39.872617]]]},"properties":{"id":"43760","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.627724,40.2594],[-83.64491,40.235932],[-83.623883,40.234539],[-83.628729,40.246315],[-83.627724,40.2594]]],[[[-83.627724,40.2594],[-83.625946,40.248505],[-83.590653,40.257501],[-83.624939,40.2892],[-83.627724,40.2594]]],[[[-83.604232,40.383547],[-83.688433,40.311919],[-83.623581,40.30343],[-83.587524,40.350393],[-83.604232,40.383547]]]]},"properties":{"id":"43360","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.133036,41.311566],[-82.180331,41.237497],[-82.098724,41.199823],[-82.105836,41.300298],[-82.133036,41.311566]]]},"properties":{"id":"44050","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.969963,41.418913],[-82.067767,41.418342],[-82.020534,41.351675],[-81.990488,41.351373],[-81.971262,41.351268],[-81.970464,41.386748],[-81.970263,41.411045],[-81.969963,41.418913]]]},"properties":{"id":"44039","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.685646,41.202132],[-81.784902,41.201512],[-81.869149,41.218513],[-81.974235,41.208924],[-81.974144,41.199833],[-82.004025,41.135954],[-82.022551,41.077757],[-81.946109,41.062624],[-81.904411,41.057506],[-81.912488,41.076814],[-81.88591,41.083036],[-81.892776,41.062434],[-81.805786,41.05193],[-81.691982,41.130091],[-81.683889,41.139664],[-81.685744,41.200904],[-81.685646,41.202132]]]},"properties":{"id":"44256","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.563687,41.638223],[-83.53193,41.632551],[-83.51146,41.659698],[-83.529653,41.664229],[-83.535368,41.660983],[-83.56128,41.657522],[-83.563687,41.638223]]]},"properties":{"id":"43604","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.545706,41.671317],[-83.57238,41.669661],[-83.56128,41.657522],[-83.535368,41.660983],[-83.545706,41.671317]]]},"properties":{"id":"43620","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.830942,41.466352],[-83.780638,41.450152],[-83.712843,41.492494],[-83.682841,41.545283],[-83.722899,41.54621],[-83.752637,41.529452],[-83.830942,41.466352]]]},"properties":{"id":"43566","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.469878,41.692866],[-83.505632,41.617848],[-83.415919,41.618938],[-83.283227,41.675846],[-83.432378,41.711667],[-83.469878,41.692866]],[[-83.445339,41.690738],[-83.445341,41.695297],[-83.44405,41.695282],[-83.444025,41.690759],[-83.445339,41.690738]]]},"properties":{"id":"43616","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.490745,40.132432],[-83.503084,40.120206],[-83.407738,40.060504],[-83.392629,40.110405],[-83.490745,40.132432]]]},"properties":{"id":"43029","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.86792,41.012282],[-80.811887,41.084437],[-80.811163,41.134226],[-80.824546,41.134308],[-80.905972,41.134388],[-80.930776,41.114369],[-80.91806,41.061457],[-80.86792,41.012282]]]},"properties":{"id":"44451","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.027412,40.901478],[-81.006668,40.930435],[-81.031823,40.937699],[-81.027412,40.901478]]]},"properties":{"id":"44672","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.103096,39.045606],[-82.181144,39.047253],[-82.201654,39.007638],[-82.14868,39.005159],[-82.085996,38.977412],[-82.044439,39.008048],[-82.103096,39.045606]]]},"properties":{"id":"45760","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.318613,39.074071],[-82.280228,39.010945],[-82.201654,39.007638],[-82.181144,39.047253],[-82.20174,39.119667],[-82.230523,39.123382],[-82.318128,39.07933],[-82.318613,39.074071]]]},"properties":{"id":"45741","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.080432,39.9692],[-84.275087,39.950276],[-84.278085,39.921554],[-84.157223,39.88563],[-84.094495,39.89178],[-84.080432,39.9692]]]},"properties":{"id":"45371","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.306168,40.041278],[-84.391549,40.022076],[-84.374376,39.989273],[-84.304603,39.997971],[-84.306168,40.041278]]]},"properties":{"id":"45339","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.988214,39.576698],[-80.865641,39.667253],[-80.866421,39.667253],[-80.873821,39.667376],[-80.958679,39.693407],[-81.068254,39.631445],[-80.988214,39.576698]]]},"properties":{"id":"43946","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.280588,39.707112],[-81.272307,39.706986],[-81.272241,39.712366],[-81.278782,39.71344],[-81.281109,39.707115],[-81.280588,39.707112]]]},"properties":{"id":"43786","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.368516,39.890388],[-84.33648,39.832496],[-84.296658,39.833014],[-84.368516,39.890388]]]},"properties":{"id":"45315","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.770519,39.758497],[-81.996583,39.767852],[-81.970757,39.710331],[-81.794507,39.591409],[-81.706632,39.588451],[-81.622432,39.658057],[-81.697442,39.755572],[-81.770519,39.758497]]]},"properties":{"id":"43756","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.148261,39.772571],[-82.162196,39.675373],[-82.128976,39.657231],[-82.026534,39.69791],[-81.970757,39.710331],[-81.996583,39.767852],[-82.022489,39.768149],[-82.148261,39.772571]]]},"properties":{"id":"43731","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.139261,39.853495],[-82.119758,39.84511],[-82.116207,39.876855],[-82.135195,39.855678],[-82.139261,39.853495]]]},"properties":{"id":"43735","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.812284,41.565374],[-83.048878,41.574825],[-83.067459,41.457169],[-83.038646,41.457515],[-82.821119,41.492797],[-82.812284,41.565374]],[[-83.038826,41.519272],[-83.038805,41.516365],[-83.043639,41.51583],[-83.043536,41.519283],[-83.038826,41.519272]],[[-82.870725,41.507024],[-82.870823,41.500801],[-82.888428,41.500944],[-82.889483,41.508521],[-82.870725,41.507024]]]},"properties":{"id":"43452","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.450474,41.542848],[-83.414626,41.500228],[-83.321818,41.492744],[-83.280166,41.539342],[-83.280213,41.544945],[-83.358565,41.562513],[-83.368419,41.562463],[-83.450474,41.542848]]]},"properties":{"id":"43430","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.212537,41.538328],[-83.222279,41.523729],[-83.20295,41.523804],[-83.212537,41.538328]]]},"properties":{"id":"43458","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.321818,41.492744],[-83.299449,41.450531],[-83.268788,41.428917],[-83.220947,41.458189],[-83.222273,41.519257],[-83.280166,41.539342],[-83.321818,41.492744]]]},"properties":{"id":"43416","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.803292,41.004016],[-84.72653,40.989572],[-84.707326,41.018007],[-84.630878,41.062496],[-84.688271,41.133428],[-84.803504,41.121331],[-84.803292,41.004016]]]},"properties":{"id":"45880","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.534178,41.004513],[-84.497785,40.9779],[-84.476265,40.960978],[-84.399097,40.961104],[-84.382148,41.033999],[-84.419035,41.070324],[-84.438181,41.070341],[-84.495928,41.062781],[-84.534178,41.004513]]]},"properties":{"id":"45849","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.20162,38.988871],[-83.270775,39.012971],[-83.261125,38.910242],[-83.203574,38.959959],[-83.20162,38.988871]]]},"properties":{"id":"45671","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.002746,41.250001],[-81.00288,41.271842],[-81.134294,41.264921],[-81.148427,41.242948],[-81.157329,41.202154],[-81.002758,41.229527],[-81.002746,41.250001]]]},"properties":{"id":"44288","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.256485,41.041101],[-81.24818,41.032927],[-81.257127,41.033082],[-81.302535,41.003732],[-81.24939,40.988359],[-81.15331,40.988221],[-81.144786,40.988214],[-81.086818,40.98587],[-81.058451,41.003627],[-81.099016,41.062462],[-81.261668,41.058985],[-81.256485,41.041101]]]},"properties":{"id":"44201","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.479416,39.604091],[-84.487319,39.656955],[-84.518112,39.656646],[-84.537173,39.646474],[-84.8146,39.685149],[-84.81479,39.615964],[-84.74636,39.566955],[-84.702718,39.567578],[-84.494766,39.60231],[-84.479416,39.604091]]]},"properties":{"id":"45311","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.8146,39.685149],[-84.537173,39.646474],[-84.536903,39.654647],[-84.576606,39.773159],[-84.628425,39.855358],[-84.652168,39.866283],[-84.696891,39.83572],[-84.814285,39.787846],[-84.8146,39.685149]]]},"properties":{"id":"45320","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.12465,41.101721],[-84.160197,41.100913],[-84.131291,41.093463],[-84.12465,41.101721]]]},"properties":{"id":"45864","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.510844,40.789472],[-82.506887,40.788748],[-82.506616,40.79395],[-82.510929,40.794584],[-82.510844,40.789472]]]},"properties":{"id":"44901","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.860033,41.39275],[-82.973958,41.357656],[-83.052238,41.288579],[-83.054199,41.213194],[-83.024523,41.218695],[-82.936611,41.2559],[-82.844356,41.357709],[-82.860033,41.39275]]]},"properties":{"id":"43410","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.808431,38.592107],[-82.839282,38.586108],[-82.827009,38.577194],[-82.808431,38.592107]]]},"properties":{"id":"45636","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.109008,40.379055],[-84.010101,40.3176],[-84.001976,40.39363],[-84.093292,40.410373],[-84.109008,40.379055]]]},"properties":{"id":"45340","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.588266,40.70592],[-81.590941,40.726428],[-81.615662,40.721315],[-81.616747,40.707858],[-81.588266,40.70592]]]},"properties":{"id":"44613","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-81.387543,40.800712],[-81.390435,40.796618],[-81.384674,40.80045],[-81.387543,40.800712]]],[[[-81.381734,40.799871],[-81.369161,40.798097],[-81.370542,40.809113],[-81.372722,40.809163],[-81.381734,40.799871]]]]},"properties":{"id":"44702","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.534081,40.758259],[-81.532486,40.880798],[-81.579313,40.857726],[-81.630137,40.775858],[-81.611104,40.757729],[-81.534081,40.758259]]]},"properties":{"id":"44647","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.14792,40.850542],[-81.244702,40.951904],[-81.283898,40.931409],[-81.306134,40.868957],[-81.314232,40.817158],[-81.204016,40.79901],[-81.14792,40.850542]]]},"properties":{"id":"44641","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.519645,41.085015],[-81.52681,41.078399],[-81.525021,41.076361],[-81.518746,41.075216],[-81.518652,41.088565],[-81.519645,41.085015]]]},"properties":{"id":"44308","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.518746,41.075216],[-81.525021,41.076361],[-81.541765,41.06192],[-81.541982,41.061559],[-81.505457,41.054472],[-81.504149,41.071146],[-81.518746,41.075216]]]},"properties":{"id":"44311","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.557361,41.092525],[-81.609786,41.113936],[-81.614755,41.112242],[-81.599143,41.062355],[-81.58179,41.039642],[-81.568017,41.060703],[-81.539008,41.084329],[-81.557361,41.092525]]]},"properties":{"id":"44320","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.568329,41.200819],[-81.518938,41.123855],[-81.497108,41.123252],[-81.493571,41.162215],[-81.507126,41.180983],[-81.568329,41.200819]]]},"properties":{"id":"44223","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.699083,41.133893],[-80.670397,41.133681],[-80.649703,41.171377],[-80.616891,41.213582],[-80.62644,41.21732],[-80.6804,41.202987],[-80.719274,41.174042],[-80.738265,41.133985],[-80.736037,41.134056],[-80.711162,41.133955],[-80.699083,41.133893]]]},"properties":{"id":"44420","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.815179,41.214805],[-80.805318,41.203162],[-80.79844,41.203161],[-80.773982,41.272575],[-80.778746,41.277074],[-80.864636,41.284327],[-80.815179,41.214805]]]},"properties":{"id":"44483","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.317216,40.471475],[-81.304798,40.517038],[-81.378288,40.524069],[-81.587052,40.507967],[-81.593888,40.458004],[-81.52977,40.39467],[-81.49257,40.37359],[-81.412048,40.392757],[-81.402736,40.401084],[-81.390577,40.397441],[-81.364045,40.427659],[-81.37322,40.44358],[-81.317216,40.471475]]]},"properties":{"id":"44663","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.37322,40.44358],[-81.364045,40.427659],[-81.356629,40.427672],[-81.37322,40.44358]]]},"properties":{"id":"44653","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.357273,40.652358],[-81.37285,40.63615],[-81.369491,40.635944],[-81.357273,40.652358]]]},"properties":{"id":"44671","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.409262,39.300778],[-82.398658,39.267469],[-82.370448,39.295296],[-82.398542,39.300229],[-82.409262,39.300778]]]},"properties":{"id":"45698","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.185048,39.397786],[-84.224889,39.368465],[-84.205318,39.351039],[-84.185048,39.397786]]]},"properties":{"id":"45065","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.244053,39.500448],[-84.319577,39.471037],[-84.320726,39.455172],[-84.341405,39.412423],[-84.249631,39.37097],[-84.239438,39.365396],[-84.224889,39.368465],[-84.185048,39.397786],[-84.115027,39.39861],[-84.125067,39.477421],[-84.168777,39.534036],[-84.244053,39.500448]]]},"properties":{"id":"45036","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.442619,39.491322],[-81.295077,39.545974],[-81.305937,39.562641],[-81.414414,39.536453],[-81.442619,39.491322]]]},"properties":{"id":"45788","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.549484,39.455174],[-81.638649,39.442613],[-81.654441,39.355602],[-81.599308,39.376935],[-81.549484,39.455174]]]},"properties":{"id":"45729","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.802768,40.901903],[-81.82281,40.885793],[-81.827011,40.838079],[-81.827874,40.798161],[-81.749499,40.751441],[-81.6767,40.846181],[-81.695945,40.870077],[-81.802768,40.901903]]]},"properties":{"id":"44667","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.514609,41.703242],[-84.495421,41.639546],[-84.48742,41.636728],[-84.409963,41.65144],[-84.399546,41.705816],[-84.514609,41.703242]]]},"properties":{"id":"43501","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.747529,41.698125],[-84.697508,41.519236],[-84.691581,41.513504],[-84.516961,41.556624],[-84.536391,41.59361],[-84.670182,41.699973],[-84.747529,41.698125]]]},"properties":{"id":"43543","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.732734,41.527702],[-84.733542,41.520461],[-84.729567,41.520489],[-84.728222,41.526476],[-84.732734,41.527702]]]},"properties":{"id":"43505","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.765244,41.189986],[-83.794135,41.193034],[-83.771391,41.182856],[-83.765244,41.189986]]]},"properties":{"id":"43529","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-83.344128,39.099652],[-83.245328,39.104963],[-83.238817,39.116906],[-83.318598,39.162508],[-83.344128,39.099652]]],[[[-83.270775,39.012971],[-83.245173,39.099445],[-83.382297,39.069151],[-83.393831,39.078083],[-83.512632,39.036119],[-83.543726,38.907868],[-83.427459,38.862372],[-83.352734,38.856322],[-83.261125,38.910242],[-83.270775,39.012971]]]]},"properties":{"id":"45660","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.01322,40.731125],[-83.994107,40.701924],[-83.994156,40.731146],[-84.01322,40.731125]]],[[[-84.280601,40.671152],[-84.032224,40.617489],[-83.975657,40.663025],[-83.993844,40.687694],[-84.139909,40.708644],[-84.204336,40.729924],[-84.241599,40.744686],[-84.256044,40.72928],[-84.280601,40.671152]]]]},"properties":{"id":"45806","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.032224,40.617489],[-83.946509,40.57168],[-83.855915,40.530438],[-83.864554,40.594022],[-83.975657,40.663025],[-84.032224,40.617489]]]},"properties":{"id":"45896","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.57595,41.009697],[-82.455454,40.889858],[-82.447994,40.931541],[-82.381856,40.975445],[-82.374108,41.016533],[-82.537444,41.102994],[-82.58601,41.067424],[-82.57595,41.009697]]]},"properties":{"id":"44837","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.695382,41.606891],[-80.614757,41.714141],[-80.622537,41.748514],[-80.695382,41.606891]]]},"properties":{"id":"44032","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.072781,39.480371],[-82.083622,39.480736],[-82.087958,39.474304],[-82.074982,39.473953],[-82.072781,39.480371]]]},"properties":{"id":"45740","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.94802,39.533848],[-81.958661,39.48591],[-81.829435,39.449303],[-81.825194,39.45962],[-81.94802,39.533848]]]},"properties":{"id":"43728","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.94811,40.547421],[-83.9938,40.485106],[-83.907022,40.451295],[-83.879847,40.483768],[-83.857214,40.498614],[-83.855915,40.530438],[-83.946509,40.57168],[-83.946472,40.560342],[-83.94811,40.547421]]]},"properties":{"id":"43331","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.456037,40.626197],[-84.455165,40.494842],[-84.358359,40.48805],[-84.29924,40.534148],[-84.353749,40.648563],[-84.437114,40.655045],[-84.456037,40.626197]]]},"properties":{"id":"45885","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.068984,40.483135],[-84.096702,40.439422],[-84.093292,40.410373],[-84.001976,40.39363],[-83.993473,40.417439],[-84.002372,40.483115],[-84.068984,40.483135]]]},"properties":{"id":"45334","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.513561,40.728204],[-84.604321,40.64777],[-84.456037,40.626197],[-84.437114,40.655045],[-84.475364,40.728219],[-84.513561,40.728204]]]},"properties":{"id":"45862","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.834871,39.956697],[-80.847437,40.013263],[-80.877294,40.003322],[-80.890322,40.004336],[-80.990764,39.93854],[-80.919942,39.871607],[-80.919265,39.871534],[-80.807868,39.914512],[-80.80491,39.918077],[-80.834871,39.956697]]]},"properties":{"id":"43933","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.841464,40.109722],[-80.831512,40.099375],[-80.831233,40.053639],[-80.737316,40.064584],[-80.733028,40.084537],[-80.80892,40.12601],[-80.843917,40.1414],[-80.841464,40.109722]],[[-80.788088,40.074087],[-80.79354,40.07723],[-80.792882,40.086153],[-80.784319,40.07524],[-80.788088,40.074087]]]},"properties":{"id":"43912","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.716545,40.157864],[-80.710055,40.137584],[-80.700017,40.161647],[-80.705661,40.166403],[-80.716545,40.157864]]]},"properties":{"id":"43971","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.902917,40.015432],[-80.890322,40.004336],[-80.877294,40.003322],[-80.902917,40.015432]]]},"properties":{"id":"43928","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.016449,40.109359],[-81.004522,40.108461],[-81.009746,40.112845],[-81.014266,40.113117],[-81.016449,40.109359]]]},"properties":{"id":"43951","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.060707,40.06618],[-81.07999,40.066603],[-81.075338,40.064562],[-81.070164,40.064192],[-81.062312,40.06577],[-81.060707,40.06618]]]},"properties":{"id":"43759","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.94234,40.029923],[-80.937779,40.022531],[-80.938113,40.029787],[-80.94234,40.029923]]]},"properties":{"id":"43967","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.815155,39.560551],[-84.81741,39.406398],[-84.761246,39.39131],[-84.671994,39.523186],[-84.702718,39.567578],[-84.74636,39.566955],[-84.815155,39.560551]]]},"properties":{"id":"45056","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.137844,40.671201],[-81.204447,40.642579],[-81.2042,40.632285],[-81.194601,40.528116],[-81.177919,40.497958],[-81.107749,40.453352],[-81.008661,40.461925],[-80.946852,40.51787],[-80.928302,40.560535],[-81.012802,40.668731],[-81.032929,40.690832],[-81.137844,40.671201]]]},"properties":{"id":"44615","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.928302,40.560535],[-80.946852,40.51787],[-80.864543,40.450961],[-80.85794,40.450145],[-80.807173,40.46853],[-80.806369,40.477697],[-80.825503,40.524084],[-80.831681,40.542144],[-80.886342,40.555765],[-80.928302,40.560535]]]},"properties":{"id":"43908","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.917365,40.326055],[-80.937161,40.424613],[-81.012128,40.433391],[-81.056602,40.362558],[-80.917365,40.326055]]]},"properties":{"id":"43986","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.639037,40.082256],[-83.709645,40.210959],[-83.865203,40.213466],[-83.90465,40.201029],[-83.918855,40.054683],[-83.83542,40.017343],[-83.832549,40.011774],[-83.689952,40.019939],[-83.639037,40.082256]]]},"properties":{"id":"43078","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.766656,39.791141],[-83.66346,39.743386],[-83.651048,39.739954],[-83.576303,39.80873],[-83.54876,39.898817],[-83.682637,39.928449],[-83.704891,39.911359],[-83.781262,39.811905],[-83.766656,39.791141]]]},"properties":{"id":"45368","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.83542,40.017343],[-83.835462,40.010798],[-83.834008,40.010876],[-83.832549,40.011774],[-83.83542,40.017343]]]},"properties":{"id":"45372","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.66346,39.743386],[-83.766656,39.791141],[-83.81101,39.795876],[-83.821943,39.797051],[-83.82649,39.794517],[-83.865964,39.7671],[-83.74776,39.696688],[-83.66346,39.743386]]]},"properties":{"id":"45314","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.246625,39.018357],[-84.244988,39.067916],[-84.25827,39.11907],[-84.260544,39.131027],[-84.30037,39.085794],[-84.311003,39.029669],[-84.246625,39.018357]]]},"properties":{"id":"45245","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.188909,39.311976],[-84.300156,39.306352],[-84.305683,39.301391],[-84.299161,39.240815],[-84.326196,39.223629],[-84.312411,39.211104],[-84.293273,39.210758],[-84.288288,39.186487],[-84.18054,39.200036],[-84.158661,39.283361],[-84.188909,39.311976]]]},"properties":{"id":"45140","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.131211,38.789106],[-84.15188,38.793939],[-84.131741,38.788469],[-84.131211,38.789106]]]},"properties":{"id":"45112","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.698335,39.471663],[-83.672587,39.475658],[-83.676519,39.482853],[-83.70341,39.476687],[-83.698335,39.471663]]]},"properties":{"id":"45166","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.825503,40.524084],[-80.670757,40.562654],[-80.668717,40.58248],[-80.755856,40.63435],[-80.831681,40.542144],[-80.825503,40.524084]]]},"properties":{"id":"43930","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.896199,40.824954],[-80.884427,40.81885],[-80.883187,40.827976],[-80.896199,40.824954]]]},"properties":{"id":"44493","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.955618,40.777828],[-80.88175,40.697313],[-80.865327,40.676839],[-80.865124,40.676877],[-80.828661,40.702263],[-80.872612,40.813394],[-80.884427,40.81885],[-80.896199,40.824954],[-80.955566,40.79403],[-80.955618,40.777828]]]},"properties":{"id":"44423","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.55144,40.942038],[-80.572193,40.897732],[-80.519071,40.898504],[-80.519002,40.953362],[-80.55144,40.942038]]]},"properties":{"id":"44454","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.690001,40.699918],[-82.698205,40.760696],[-82.857214,40.784243],[-82.894754,40.704789],[-82.906716,40.645965],[-82.860145,40.630693],[-82.718512,40.638514],[-82.690001,40.699918]]]},"properties":{"id":"44833","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.743598,40.901713],[-82.837396,40.83615],[-82.857017,40.795819],[-82.854846,40.795802],[-82.854791,40.792198],[-82.858833,40.794296],[-82.857214,40.784243],[-82.698205,40.760696],[-82.65783,40.813687],[-82.743598,40.901713]]]},"properties":{"id":"44827","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.945487,40.950285],[-82.943944,40.950292],[-82.940422,40.957544],[-82.94373,40.957497],[-82.943554,40.954411],[-82.945487,40.950285]]]},"properties":{"id":"44825","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.669018,41.46988],[-81.626552,41.465927],[-81.655299,41.481997],[-81.669003,41.470438],[-81.669018,41.46988]]]},"properties":{"id":"44127","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.628448,41.520406],[-81.628529,41.496351],[-81.601617,41.494644],[-81.583024,41.490605],[-81.591256,41.510623],[-81.598906,41.523634],[-81.628448,41.520406]]]},"properties":{"id":"44106","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.656989,41.496473],[-81.655299,41.481997],[-81.626552,41.465927],[-81.603556,41.463655],[-81.601617,41.494644],[-81.628529,41.496351],[-81.656989,41.496473]]]},"properties":{"id":"44104","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.488988,41.349753],[-81.419012,41.348753],[-81.391761,41.35729],[-81.391909,41.391263],[-81.488696,41.424268],[-81.488988,41.349753]]]},"properties":{"id":"44139","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.48794,41.586249],[-81.502639,41.587461],[-81.527599,41.545331],[-81.510244,41.535129],[-81.43235,41.52723],[-81.419936,41.56995],[-81.447155,41.569966],[-81.48794,41.586249]]]},"properties":{"id":"44143","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.838914,41.350618],[-81.87696,41.350685],[-81.878053,41.275044],[-81.835917,41.275597],[-81.824515,41.350719],[-81.838914,41.350618]]]},"properties":{"id":"44149","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.843982,41.494593],[-81.831958,41.463248],[-81.832143,41.464651],[-81.768814,41.475553],[-81.769032,41.497326],[-81.843982,41.494593]]]},"properties":{"id":"44107","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.511358,41.519484],[-81.463363,41.4559],[-81.438444,41.500842],[-81.43235,41.52723],[-81.510244,41.535129],[-81.511358,41.519484]]]},"properties":{"id":"44124","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.584745,41.384553],[-81.537317,41.424286],[-81.582384,41.424226],[-81.584745,41.384553]]]},"properties":{"id":"44137","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.67051,41.277255],[-81.590708,41.251372],[-81.566284,41.28776],[-81.598061,41.350913],[-81.641013,41.351125],[-81.67051,41.277255]]]},"properties":{"id":"44141","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.703675,41.350911],[-81.705151,41.277254],[-81.68495,41.277146],[-81.67051,41.277255],[-81.641013,41.351125],[-81.684585,41.350966],[-81.703675,41.350911]]]},"properties":{"id":"44147","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.535996,40.324938],[-84.434066,40.306613],[-84.434631,40.354259],[-84.515776,40.354037],[-84.552371,40.338993],[-84.535996,40.324938]],[[-84.489126,40.341532],[-84.489355,40.336272],[-84.500174,40.338539],[-84.499736,40.343079],[-84.489126,40.341532]]]},"properties":{"id":"45388","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.764999,40.527293],[-84.802667,40.528232],[-84.803721,40.338093],[-84.766137,40.294451],[-84.647907,40.351811],[-84.638734,40.356493],[-84.638217,40.35178],[-84.632827,40.351747],[-84.688789,40.443403],[-84.764999,40.527293]]]},"properties":{"id":"45846","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.489974,40.114995],[-84.497556,40.119018],[-84.502197,40.112759],[-84.49391,40.109827],[-84.489974,40.114995]]]},"properties":{"id":"45328","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.74109,40.049903],[-84.744331,40.051587],[-84.750264,40.049792],[-84.74109,40.049903]]]},"properties":{"id":"45352","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.647907,40.351811],[-84.638217,40.35178],[-84.638734,40.356493],[-84.647907,40.351811]]]},"properties":{"id":"45310","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.576568,40.324449],[-84.576525,40.31961],[-84.570991,40.319609],[-84.571074,40.32917],[-84.576568,40.324449]]]},"properties":{"id":"45350","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.186004,40.372829],[-83.189067,40.20453],[-83.170874,40.182775],[-83.021777,40.175134],[-82.966099,40.238951],[-82.94964,40.268413],[-82.929588,40.358123],[-83.055915,40.399337],[-83.080718,40.400348],[-83.186004,40.372829]],[[-82.956905,40.331779],[-82.957925,40.326203],[-82.960896,40.326344],[-82.960098,40.330181],[-82.956905,40.331779]]]},"properties":{"id":"43015","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.778205,40.181768],[-82.94964,40.268413],[-82.966099,40.238951],[-82.953001,40.150354],[-82.789021,40.164821],[-82.778205,40.181768]]]},"properties":{"id":"43021","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.055915,40.399337],[-82.929588,40.358123],[-82.920143,40.357669],[-82.909275,40.422048],[-83.00206,40.455695],[-83.055915,40.399337]]]},"properties":{"id":"43003","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.080718,40.400348],[-83.055915,40.399337],[-83.00206,40.455695],[-83.0196,40.502682],[-83.093573,40.462994],[-83.092105,40.436376],[-83.080718,40.400348]]]},"properties":{"id":"43356","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.114767,40.132767],[-83.170734,40.175551],[-83.17424,40.156419],[-83.112597,40.067543],[-83.107394,40.068158],[-83.104881,40.068727],[-83.090965,40.109018],[-83.114767,40.132767]]]},"properties":{"id":"43017","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.971307,40.134933],[-82.78107,40.126764],[-82.789021,40.164821],[-82.953001,40.150354],[-82.954962,40.149002],[-82.971307,40.134933]]]},"properties":{"id":"43082","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.751789,39.764005],[-82.633757,39.811566],[-82.692042,39.846221],[-82.714053,39.8554],[-82.751789,39.764005]]]},"properties":{"id":"43112","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.468866,39.871042],[-82.446488,39.806081],[-82.385514,39.807235],[-82.334547,39.859067],[-82.288377,39.966043],[-82.413944,39.959427],[-82.419067,39.959624],[-82.462813,39.93038],[-82.482141,39.925499],[-82.468866,39.871042]]]},"properties":{"id":"43076","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.829061,39.91773],[-82.841116,39.90767],[-82.863791,39.890669],[-82.836347,39.780864],[-82.82967,39.736698],[-82.767533,39.732709],[-82.751789,39.764005],[-82.714053,39.8554],[-82.809682,39.91155],[-82.829061,39.91773]],[[-82.824062,39.797065],[-82.811219,39.806017],[-82.808828,39.797159],[-82.824062,39.797065]]]},"properties":{"id":"43110","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.618327,39.59571],[-82.498417,39.57772],[-82.448869,39.659078],[-82.478126,39.664393],[-82.618327,39.59571]]]},"properties":{"id":"43155","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.549585,39.838539],[-82.539716,39.840638],[-82.545806,39.847377],[-82.552813,39.843491],[-82.549585,39.838539]]]},"properties":{"id":"43157","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.822641,40.04241],[-82.862012,40.078854],[-82.919418,40.081274],[-82.924234,40.058175],[-82.86603,39.986487],[-82.828901,39.981063],[-82.826836,39.981294],[-82.825507,39.984069],[-82.822641,40.04241]]]},"properties":{"id":"43230","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.020879,39.980922],[-82.983202,39.97983],[-82.979946,39.979797],[-82.97239,39.990787],[-82.995858,40.010895],[-83.017594,40.009969],[-83.023509,39.990793],[-83.020879,39.980922]]]},"properties":{"id":"43201","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.017926,39.880377],[-82.938402,39.856233],[-82.898421,39.902221],[-82.92055,39.917759],[-82.945377,39.933681],[-82.998542,39.939389],[-82.999708,39.939398],[-83.017926,39.880377]]]},"properties":{"id":"43207","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.032719,39.822353],[-83.169655,39.821249],[-83.157062,39.813042],[-83.172938,39.808974],[-83.171049,39.820638],[-83.21006,39.827364],[-83.243839,39.809327],[-83.150793,39.684404],[-83.108448,39.690229],[-83.069443,39.726483],[-83.070099,39.763573],[-83.069497,39.776244],[-83.052741,39.769164],[-83.025687,39.769929],[-83.032719,39.822353]],[[-83.206667,39.768091],[-83.20643,39.769531],[-83.204697,39.768024],[-83.206667,39.768091]]]},"properties":{"id":"43146","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.064086,41.646075],[-84.037823,41.48682],[-83.949101,41.495758],[-83.951319,41.671493],[-83.997708,41.685235],[-84.064086,41.646075]]]},"properties":{"id":"43515","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.037823,41.48682],[-84.095704,41.486029],[-83.926429,41.417295],[-83.883234,41.414503],[-83.87467,41.487673],[-83.882932,41.497593],[-83.949101,41.495758],[-84.037823,41.48682]]]},"properties":{"id":"43532","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.190863,41.383957],[-81.126331,41.382496],[-81.06603,41.369235],[-81.068085,41.373543],[-81.099929,41.501136],[-81.138304,41.526235],[-81.189931,41.495615],[-81.23105,41.425434],[-81.190863,41.383957]]]},"properties":{"id":"44021","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.52977,40.39467],[-81.55578,40.39405],[-81.437058,40.216638],[-81.41066,40.19819],[-81.389575,40.215508],[-81.36909,40.28065],[-81.408489,40.311207],[-81.49257,40.37359],[-81.52977,40.39467]]]},"properties":{"id":"43837","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.2301,40.056151],[-81.23602,40.060859],[-81.240507,40.057433],[-81.230133,40.055227],[-81.2301,40.056151]]]},"properties":{"id":"43736","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.501832,39.126684],[-84.463639,39.127547],[-84.464063,39.134296],[-84.483482,39.141327],[-84.497724,39.134862],[-84.501832,39.126684]]]},"properties":{"id":"45206","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.599836,39.227116],[-84.601948,39.188104],[-84.57866,39.175857],[-84.560721,39.188763],[-84.549475,39.217817],[-84.572165,39.223876],[-84.599836,39.227116]]]},"properties":{"id":"45239","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.703067,39.216276],[-84.696834,39.222786],[-84.787607,39.1668],[-84.705289,39.168224],[-84.67628,39.210745],[-84.703067,39.216276]],[[-84.760771,39.176279],[-84.76681,39.175878],[-84.766789,39.177717],[-84.761073,39.177722],[-84.760771,39.176279]],[[-84.703067,39.216276],[-84.696899,39.210156],[-84.705295,39.206854],[-84.703067,39.216276]]]},"properties":{"id":"45002","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.483482,39.141327],[-84.464063,39.134296],[-84.45488,39.145621],[-84.455014,39.145661],[-84.471309,39.15715],[-84.483482,39.141327]]]},"properties":{"id":"45207","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.352897,39.091999],[-84.401598,39.109215],[-84.433385,39.078],[-84.396655,39.045768],[-84.352897,39.091999]]]},"properties":{"id":"45230","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.414809,39.231345],[-84.435793,39.214675],[-84.411132,39.193151],[-84.39678,39.180709],[-84.390621,39.187355],[-84.357731,39.213903],[-84.396207,39.237946],[-84.414809,39.231345]]]},"properties":{"id":"45236","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.703067,39.216276],[-84.705295,39.206854],[-84.696899,39.210156],[-84.703067,39.216276]]]},"properties":{"id":"45041","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-84.57411,39.126488],[-84.573923,39.127603],[-84.576653,39.128539],[-84.57411,39.126488]]],[[[-84.542945,39.124523],[-84.564335,39.135536],[-84.573363,39.127572],[-84.562964,39.11728],[-84.542945,39.124523]]],[[[-84.542945,39.124523],[-84.542646,39.121201],[-84.52114,39.107354],[-84.521338,39.119184],[-84.529525,39.130966],[-84.542945,39.124523]]]]},"properties":{"id":"45214","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.296725,39.187037],[-84.288698,39.186526],[-84.293273,39.210758],[-84.296099,39.209712],[-84.296725,39.187037]]]},"properties":{"id":"45111","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.869299,41.152635],[-83.880653,41.051189],[-83.880674,41.027561],[-83.6885,41.124125],[-83.707865,41.160134],[-83.780247,41.168289],[-83.869299,41.152635]]]},"properties":{"id":"45858","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.873862,40.328518],[-80.852957,40.307438],[-80.778775,40.297115],[-80.735082,40.297292],[-80.742645,40.321382],[-80.753483,40.379011],[-80.807173,40.46853],[-80.85794,40.450145],[-80.861195,40.448501],[-80.864543,40.450961],[-80.90437,40.423812],[-80.873862,40.328518]]]},"properties":{"id":"43910","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.071533,41.282962],[-84.030174,41.2832],[-83.921425,41.283915],[-83.980201,41.327152],[-84.002602,41.341849],[-84.085402,41.297445],[-84.071533,41.282962]]]},"properties":{"id":"43535","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.883532,41.343514],[-83.883234,41.414503],[-83.926429,41.417295],[-83.983757,41.349281],[-83.980201,41.327152],[-83.921425,41.283915],[-83.902047,41.28409],[-83.883407,41.335294],[-83.883532,41.343514]]]},"properties":{"id":"43534","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.883407,41.335294],[-83.902047,41.28409],[-83.794135,41.193034],[-83.765244,41.189986],[-83.740056,41.207219],[-83.755961,41.255417],[-83.805265,41.313798],[-83.883407,41.335294]],[[-83.825979,41.30629],[-83.824651,41.295253],[-83.834388,41.295161],[-83.834368,41.306194],[-83.825979,41.30629]]]},"properties":{"id":"43511","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.618327,39.59571],[-82.676421,39.56625],[-82.685207,39.551924],[-82.592867,39.460295],[-82.571927,39.451383],[-82.498417,39.57772],[-82.618327,39.59571]]]},"properties":{"id":"43149","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.996003,40.602098],[-81.877604,40.619456],[-81.931782,40.667231],[-81.996003,40.602098]]]},"properties":{"id":"44633","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-82.639949,41.162554],[-82.626897,41.122711],[-82.623634,41.141338],[-82.639949,41.162554]]],[[[-82.67381,41.037655],[-82.606019,40.994466],[-82.57595,41.009697],[-82.58601,41.067424],[-82.673173,41.104781],[-82.645597,41.169175],[-82.653051,41.176869],[-82.766325,41.157236],[-82.835458,41.144053],[-82.781525,41.025797],[-82.692687,41.031805],[-82.689652,41.038603],[-82.67381,41.037655]]]]},"properties":{"id":"44890","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.435309,39.035079],[-82.658477,39.125354],[-82.74802,39.14682],[-82.792729,39.10264],[-82.80709,38.948073],[-82.756173,38.914608],[-82.497174,38.958175],[-82.438009,39.003839],[-82.435309,39.035079]]]},"properties":{"id":"45640","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.61373,39.113053],[-82.611935,39.10637],[-82.602482,39.108444],[-82.601804,39.115545],[-82.61373,39.113053]]]},"properties":{"id":"45621","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.629029,40.516853],[-80.61365,40.495748],[-80.621573,40.515925],[-80.629029,40.516853]]]},"properties":{"id":"43926","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.729061,40.395695],[-80.642141,40.34169],[-80.610796,40.340868],[-80.612876,40.429375],[-80.712331,40.430106],[-80.729061,40.395695]]]},"properties":{"id":"43952","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.610796,40.340868],[-80.642141,40.34169],[-80.742645,40.321382],[-80.735082,40.297292],[-80.649456,40.255675],[-80.61565,40.288711],[-80.610796,40.340868]]]},"properties":{"id":"43938","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.370104,40.391367],[-82.362322,40.506321],[-82.41153,40.471358],[-82.651863,40.403881],[-82.67134,40.384242],[-82.585739,40.269944],[-82.377151,40.283172],[-82.370104,40.391367]]]},"properties":{"id":"43050","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.452689,41.623859],[-81.447155,41.569966],[-81.419936,41.56995],[-81.391005,41.569716],[-81.29588,41.56995],[-81.295015,41.615488],[-81.388631,41.707256],[-81.408843,41.694601],[-81.452689,41.623859]]]},"properties":{"id":"44094","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.60733,38.583481],[-82.5209,38.506612],[-82.480699,38.502334],[-82.473215,38.521227],[-82.503799,38.635839],[-82.60733,38.583481]]]},"properties":{"id":"45645","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.419067,39.959624],[-82.413944,39.959427],[-82.418939,39.960822],[-82.419067,39.959624]]]},"properties":{"id":"43030","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.616493,40.237993],[-82.735887,40.260317],[-82.73987,40.208234],[-82.616493,40.237993]]]},"properties":{"id":"43013","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.253147,39.951799],[-82.261452,39.952067],[-82.259036,39.94313],[-82.251155,39.946476],[-82.253147,39.951799]]]},"properties":{"id":"43721","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.688433,40.311919],[-83.604232,40.383547],[-83.630176,40.435889],[-83.708788,40.475131],[-83.74217,40.461196],[-83.86457,40.410476],[-83.87158,40.390114],[-83.841394,40.263288],[-83.688433,40.311919]]]},"properties":{"id":"43311","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.133036,41.311566],[-82.228283,41.338041],[-82.316983,41.320159],[-82.320699,41.237645],[-82.180331,41.237497],[-82.133036,41.311566]],[[-82.311068,41.266357],[-82.301927,41.271444],[-82.302637,41.261322],[-82.311068,41.266357]]]},"properties":{"id":"44074","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.529653,41.664229],[-83.511982,41.693524],[-83.555626,41.687265],[-83.545706,41.671317],[-83.535368,41.660983],[-83.529653,41.664229]]]},"properties":{"id":"43608","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.800133,41.673265],[-83.806136,41.72237],[-83.880387,41.720081],[-83.879335,41.686285],[-83.800133,41.673265]]]},"properties":{"id":"43504","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"MultiPolygon","coordinates":[[[[-80.66276,40.987936],[-80.688246,40.988014],[-80.679849,40.973442],[-80.66276,40.987936]]],[[[-80.66276,40.987936],[-80.606009,40.958153],[-80.576691,40.958243],[-80.560733,40.987765],[-80.572019,41.029245],[-80.615899,41.060609],[-80.628405,41.060682],[-80.66276,40.987936]]]]},"properties":{"id":"44514","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.711029,41.089158],[-80.674928,41.088365],[-80.675598,41.105948],[-80.699083,41.133893],[-80.711162,41.133955],[-80.711029,41.089158]]]},"properties":{"id":"44509","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.283619,40.527349],[-83.324012,40.658186],[-83.361998,40.658182],[-83.283619,40.527349]]]},"properties":{"id":"43341","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.103096,39.045606],[-82.044439,39.008048],[-81.993909,39.021226],[-81.918669,39.075256],[-81.923521,39.127888],[-81.927329,39.172627],[-81.978028,39.164733],[-82.079769,39.169976],[-82.230523,39.123382],[-82.20174,39.119667],[-82.103096,39.045606]]]},"properties":{"id":"45769","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.75608,39.18056],[-81.927329,39.172627],[-81.923521,39.127888],[-81.758122,39.085832],[-81.75608,39.18056]]]},"properties":{"id":"45772","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.758122,39.085832],[-81.923521,39.127888],[-81.918669,39.075256],[-81.84282,39.027926],[-81.777854,39.029279],[-81.758122,39.085832]]]},"properties":{"id":"45743","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.935814,38.98965],[-81.984016,39.005997],[-81.981159,38.994351],[-81.935814,38.98965]]]},"properties":{"id":"45779","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.417318,39.959346],[-84.407621,39.963452],[-84.407675,39.96801],[-84.422004,39.968036],[-84.417318,39.959346]]]},"properties":{"id":"45361","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.263063,39.848209],[-81.223211,39.709381],[-81.120317,39.64299],[-81.068254,39.631445],[-80.958679,39.693407],[-80.944656,39.766517],[-80.94886,39.766917],[-81.08628,39.789484],[-81.211468,39.867281],[-81.237316,39.867828],[-81.263063,39.848209]]]},"properties":{"id":"43793","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.866421,39.667253],[-80.867435,39.675617],[-80.88133,39.677023],[-80.873821,39.667376],[-80.866421,39.667253]]]},"properties":{"id":"43931","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.244742,39.801857],[-84.199513,39.764739],[-84.188029,39.766791],[-84.19934,39.777858],[-84.217634,39.813985],[-84.23516,39.81129],[-84.244742,39.801857]]]},"properties":{"id":"45405","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.20161,39.701009],[-84.202947,39.674683],[-84.136832,39.658372],[-84.125529,39.688508],[-84.141362,39.710338],[-84.190336,39.699037],[-84.20161,39.701009]]]},"properties":{"id":"45429","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.166668,39.866605],[-84.285056,39.869889],[-84.268898,39.861393],[-84.217634,39.813985],[-84.19934,39.777858],[-84.156146,39.820113],[-84.166668,39.866605]]]},"properties":{"id":"45414","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.23615,39.70316],[-84.276432,39.695403],[-84.267357,39.681219],[-84.252996,39.689661],[-84.23615,39.70316]]]},"properties":{"id":"45418","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.141362,39.710338],[-84.125529,39.688508],[-84.105982,39.697207],[-84.104042,39.721404],[-84.129623,39.739581],[-84.168795,39.739972],[-84.168934,39.732924],[-84.141362,39.710338]]]},"properties":{"id":"45420","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.177135,39.757938],[-84.129031,39.748005],[-84.126997,39.759164],[-84.131506,39.781529],[-84.127485,39.793125],[-84.166294,39.768808],[-84.177135,39.757938]]]},"properties":{"id":"45403","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.251253,39.578498],[-84.244053,39.500448],[-84.168777,39.534036],[-84.168282,39.568069],[-84.210539,39.588655],[-84.251253,39.578498]]]},"properties":{"id":"45066","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.741821,39.451562],[-81.72326,39.403235],[-81.638649,39.442613],[-81.549484,39.455174],[-81.533335,39.476862],[-81.58066,39.564942],[-81.706302,39.586251],[-81.741821,39.451562]],[[-81.631521,39.466961],[-81.633198,39.467092],[-81.632376,39.469816],[-81.630737,39.469496],[-81.631521,39.466961]]]},"properties":{"id":"45786","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.663179,40.623187],[-82.48784,40.659755],[-82.51147,40.696701],[-82.570025,40.700293],[-82.571598,40.723804],[-82.690001,40.699918],[-82.718512,40.638514],[-82.663179,40.623187]]]},"properties":{"id":"44904","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.990818,39.837078],[-82.119758,39.84511],[-82.139261,39.853495],[-82.141397,39.857194],[-82.188746,39.821671],[-82.166605,39.807214],[-82.148261,39.772571],[-82.022489,39.768149],[-81.986278,39.811859],[-81.990818,39.837078]]]},"properties":{"id":"43777","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.806575,41.663406],[-82.835129,41.656389],[-82.83521,41.629194],[-82.812743,41.644772],[-82.806575,41.663406]]]},"properties":{"id":"43456","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.067459,41.457169],[-83.048878,41.574825],[-83.164963,41.623237],[-83.240032,41.6232],[-83.212537,41.538328],[-83.20295,41.523804],[-83.222279,41.523729],[-83.222273,41.519257],[-83.220947,41.458189],[-83.159301,41.436104],[-83.067459,41.457169]]]},"properties":{"id":"43449","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.495928,41.062781],[-84.630878,41.062496],[-84.707326,41.018007],[-84.534178,41.004513],[-84.495928,41.062781]]]},"properties":{"id":"45851","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.166115,39.600897],[-82.256083,39.655723],[-82.20161,39.587428],[-82.166115,39.600897]]]},"properties":{"id":"43782","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.930426,39.033099],[-83.235208,39.117014],[-83.238817,39.116906],[-83.245328,39.104963],[-83.245173,39.099445],[-83.270775,39.012971],[-83.20162,38.988871],[-82.988733,38.979925],[-82.930426,39.033099]],[[-83.050522,39.043478],[-83.054073,39.044452],[-83.054488,39.048013],[-83.050299,39.047089],[-83.050522,39.043478]]]},"properties":{"id":"45661","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.245328,39.104963],[-83.344128,39.099652],[-83.379805,39.079534],[-83.382297,39.069151],[-83.245173,39.099445],[-83.245328,39.104963]]]},"properties":{"id":"45646","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.392114,41.135722],[-81.385518,41.080007],[-81.268667,41.068242],[-81.27821,41.110744],[-81.311602,41.202385],[-81.391931,41.202855],[-81.392114,41.135722]],[[-81.351358,41.15014],[-81.336186,41.152036],[-81.339517,41.142783],[-81.351358,41.15014]]]},"properties":{"id":"44240","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.351358,41.15014],[-81.339517,41.142783],[-81.336186,41.152036],[-81.351358,41.15014]]]},"properties":{"id":"44243","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.07127,41.161406],[-81.07599,41.161208],[-81.07452,41.156365],[-81.067458,41.158738],[-81.07127,41.161406]]]},"properties":{"id":"44285","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.232434,39.456702],[-83.33344,39.42881],[-83.275497,39.356244],[-83.190836,39.311775],[-83.123176,39.446831],[-83.232434,39.456702]]]},"properties":{"id":"45628","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.275497,39.356244],[-83.339138,39.300311],[-83.16319,39.278153],[-83.154659,39.282863],[-83.190836,39.311775],[-83.275497,39.356244]]]},"properties":{"id":"45681","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.407867,41.334038],[-83.388233,41.298065],[-83.360321,41.272908],[-83.301194,41.279644],[-83.240457,41.303925],[-83.241842,41.37038],[-83.407867,41.334038]]]},"properties":{"id":"43435","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.301194,41.279644],[-83.254702,41.253136],[-83.224998,41.252975],[-83.219406,41.253195],[-83.240457,41.303925],[-83.301194,41.279644]]]},"properties":{"id":"43407","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.906846,38.849557],[-82.856037,38.851795],[-82.798317,38.825417],[-82.76174,38.866744],[-82.756173,38.914608],[-82.80709,38.948073],[-82.856746,38.942055],[-82.906846,38.849557]]]},"properties":{"id":"45653","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.304474,41.137163],[-83.304505,41.133513],[-83.285315,41.130387],[-83.304474,41.137163]]]},"properties":{"id":"44809","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.861737,41.233974],[-82.867257,41.226184],[-82.859791,41.226115],[-82.861737,41.233974]]]},"properties":{"id":"44828","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.259635,40.4314],[-84.269493,40.438713],[-84.297981,40.438453],[-84.317321,40.423267],[-84.297393,40.358474],[-84.109008,40.379055],[-84.093292,40.410373],[-84.096702,40.439422],[-84.259635,40.4314]]]},"properties":{"id":"45302","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.400918,40.798667],[-81.448536,40.792245],[-81.446411,40.78558],[-81.400918,40.798667]]]},"properties":{"id":"44710","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.495514,40.938611],[-81.507522,40.929468],[-81.506078,40.902375],[-81.479696,40.878053],[-81.422763,40.858303],[-81.364618,40.860113],[-81.362409,40.917231],[-81.368917,40.918195],[-81.495514,40.938611]]]},"properties":{"id":"44720","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.518938,41.123855],[-81.568329,41.200819],[-81.58012,41.176656],[-81.609786,41.113936],[-81.557361,41.092525],[-81.548234,41.098893],[-81.527458,41.103764],[-81.518938,41.123855]]]},"properties":{"id":"44313","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.557361,41.092525],[-81.539008,41.084329],[-81.52681,41.078399],[-81.519645,41.085015],[-81.548234,41.098893],[-81.557361,41.092525]]]},"properties":{"id":"44302","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.713231,41.443741],[-80.737636,41.491267],[-80.904565,41.493522],[-80.954241,41.464022],[-80.954661,41.458333],[-80.935034,41.435183],[-80.904246,41.42555],[-80.773323,41.41878],[-80.713231,41.443741]]]},"properties":{"id":"44450","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.624053,41.356232],[-80.665249,41.499327],[-80.737636,41.491267],[-80.713231,41.443741],[-80.637184,41.35019],[-80.624053,41.356232]]]},"properties":{"id":"44417","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.598185,41.350382],[-80.568367,41.272321],[-80.56032,41.272639],[-80.519088,41.289912],[-80.519246,41.37888],[-80.598185,41.350382]]]},"properties":{"id":"44404","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.413367,40.612807],[-81.43918,40.618429],[-81.413683,40.603059],[-81.413367,40.612807]]]},"properties":{"id":"44697","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.390577,40.397441],[-81.402736,40.401084],[-81.412048,40.392757],[-81.390577,40.397441]]]},"properties":{"id":"44682","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.409262,39.300778],[-82.476512,39.343982],[-82.552234,39.371295],[-82.642306,39.330348],[-82.6513,39.267666],[-82.633601,39.18792],[-82.590246,39.185513],[-82.417748,39.211243],[-82.344216,39.2075],[-82.3046,39.274868],[-82.295486,39.325259],[-82.398542,39.300229],[-82.370448,39.295296],[-82.398658,39.267469],[-82.409262,39.300778]]]},"properties":{"id":"45651","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.602004,41.246427],[-83.5929,41.260948],[-83.61201,41.260983],[-83.602004,41.246427]]]},"properties":{"id":"43437","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.375237,38.760992],[-83.427459,38.862372],[-83.543726,38.907868],[-83.542333,38.896661],[-83.689991,38.827903],[-83.691393,38.812926],[-83.695551,38.768614],[-83.696194,38.761722],[-83.455522,38.725044],[-83.375237,38.760992]]]},"properties":{"id":"45693","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.741744,38.879606],[-83.696084,38.834509],[-83.689991,38.827903],[-83.542333,38.896661],[-83.623012,39.03109],[-83.706132,39.013394],[-83.758846,38.908012],[-83.741744,38.879606]],[[-83.614748,38.886382],[-83.614922,38.884576],[-83.617848,38.884685],[-83.617875,38.886543],[-83.614748,38.886382]]]},"properties":{"id":"45697","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.272343,40.837797],[-84.291618,40.786822],[-84.272939,40.747219],[-84.241599,40.744686],[-84.204336,40.729924],[-84.128524,40.776126],[-83.958691,40.833261],[-83.963122,40.833156],[-83.988423,40.824992],[-84.051797,40.846578],[-84.051798,40.861042],[-84.192843,40.859665],[-84.272343,40.837797]],[[-84.186049,40.846592],[-84.180235,40.845537],[-84.182178,40.840784],[-84.190303,40.843112],[-84.186049,40.846592]],[[-84.090463,40.832301],[-84.084182,40.846497],[-84.077309,40.846535],[-84.080809,40.832978],[-84.090463,40.832301]]]},"properties":{"id":"45807","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.397115,40.765045],[-84.475364,40.728219],[-84.437114,40.655045],[-84.353749,40.648563],[-84.280601,40.671152],[-84.256044,40.72928],[-84.272939,40.747219],[-84.291618,40.786822],[-84.397115,40.765045]]]},"properties":{"id":"45887","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.366038,40.732198],[-82.389094,40.638588],[-82.407086,40.610938],[-82.336962,40.555001],[-82.303527,40.558713],[-82.2599,40.755163],[-82.261564,40.768278],[-82.366038,40.732198]]]},"properties":{"id":"44864","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.224833,40.974105],[-82.144683,40.868192],[-82.125495,40.85817],[-82.029665,40.867904],[-82.039198,40.996858],[-82.052105,41.013964],[-82.173359,40.992082],[-82.222964,40.992308],[-82.224833,40.974105]]]},"properties":{"id":"44287","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.100044,40.678839],[-82.126457,40.733862],[-82.167817,40.712274],[-82.166087,40.574701],[-82.131659,40.578668],[-82.115699,40.595092],[-82.115789,40.597415],[-82.100044,40.678839]]]},"properties":{"id":"44638","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.809743,41.654628],[-81.002781,41.642],[-81.002795,41.63539],[-80.992284,41.605706],[-80.923688,41.579139],[-80.719264,41.571137],[-80.809743,41.654628]]]},"properties":{"id":"44085","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.880457,41.789171],[-80.88403,41.730799],[-80.852759,41.714166],[-80.83416,41.789103],[-80.880457,41.789171]]]},"properties":{"id":"44010","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.519246,41.37888],[-80.519225,41.499924],[-80.643497,41.534222],[-80.647729,41.508705],[-80.665249,41.499327],[-80.624053,41.356232],[-80.598185,41.350382],[-80.519246,41.37888]]]},"properties":{"id":"44428","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.170934,39.464077],[-82.183096,39.463211],[-82.187605,39.467496],[-82.172039,39.466581],[-82.165379,39.488289],[-82.225034,39.557627],[-82.313374,39.474398],[-82.330406,39.474203],[-82.340908,39.45123],[-82.351358,39.432677],[-82.290233,39.378412],[-82.212268,39.370038],[-82.141552,39.387349],[-82.140784,39.38885],[-82.170671,39.431533],[-82.167647,39.463124],[-82.170934,39.464077]],[[-82.246486,39.502491],[-82.241006,39.509129],[-82.235856,39.497842],[-82.241813,39.49545],[-82.246486,39.502491]]]},"properties":{"id":"45764","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.259635,40.4314],[-84.096702,40.439422],[-84.068984,40.483135],[-84.230985,40.493713],[-84.298072,40.453269],[-84.297981,40.438453],[-84.269493,40.438713],[-84.250396,40.438949],[-84.259635,40.4314]]]},"properties":{"id":"45306","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.768845,40.264271],[-80.794164,40.178205],[-80.792203,40.156208],[-80.787774,40.155954],[-80.716545,40.157864],[-80.705661,40.166403],[-80.68748,40.179568],[-80.647218,40.249161],[-80.649456,40.255675],[-80.735082,40.297292],[-80.778775,40.297115],[-80.783542,40.276982],[-80.768845,40.264271]]]},"properties":{"id":"43943","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.743383,39.258438],[-83.755881,39.280292],[-83.841108,39.277443],[-83.904881,39.249452],[-83.895165,39.225289],[-83.846229,39.131113],[-83.842935,39.105367],[-83.784997,39.114992],[-83.743383,39.258438]]]},"properties":{"id":"45142","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.671994,39.523186],[-84.761246,39.39131],[-84.746139,39.304769],[-84.644442,39.305039],[-84.592246,39.350856],[-84.571572,39.387456],[-84.558305,39.479486],[-84.560949,39.482339],[-84.579673,39.529547],[-84.671994,39.523186]]]},"properties":{"id":"45013","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.436828,39.443843],[-84.402824,39.377571],[-84.346331,39.368446],[-84.341405,39.412423],[-84.385252,39.441019],[-84.320726,39.455172],[-84.319577,39.471037],[-84.335426,39.511353],[-84.439122,39.488668],[-84.436828,39.443843]]]},"properties":{"id":"45044","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.244445,40.626787],[-81.274003,40.582626],[-81.265891,40.540302],[-81.194601,40.528116],[-81.2042,40.632285],[-81.244445,40.626787]]]},"properties":{"id":"44620","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.024923,40.052125],[-84.034075,40.061294],[-84.029857,40.111431],[-84.059808,40.098468],[-84.125668,40.093494],[-84.049679,40.025446],[-84.026132,40.047484],[-84.024923,40.052125]]]},"properties":{"id":"45312","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.001976,40.39363],[-84.010101,40.3176],[-84.012032,40.30065],[-84.012663,40.294857],[-84.008419,40.228531],[-83.967612,40.241429],[-83.974513,40.417402],[-83.993473,40.417439],[-84.001976,40.39363]]]},"properties":{"id":"43343","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.36468,40.139629],[-83.4946,40.224445],[-83.532215,40.201679],[-83.490745,40.132432],[-83.392629,40.110405],[-83.36468,40.139629]]]},"properties":{"id":"43045","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.051656,39.876534],[-84.053736,39.850458],[-84.037252,39.849511],[-83.995342,39.875372],[-84.051656,39.876534]]]},"properties":{"id":"45341","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.939692,39.886995],[-83.939806,39.843449],[-83.888515,39.826074],[-83.939692,39.886995]]]},"properties":{"id":"45323","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.655686,39.693649],[-83.481922,39.68382],[-83.481512,39.683687],[-83.437775,39.703962],[-83.413422,39.719142],[-83.472038,39.733214],[-83.472548,39.728915],[-83.481759,39.729465],[-83.481619,39.733478],[-83.576303,39.80873],[-83.651048,39.739954],[-83.655686,39.693649]]]},"properties":{"id":"43153","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.62562,40.895419],[-80.64447,40.813041],[-80.641402,40.79637],[-80.596938,40.829278],[-80.594439,40.89436],[-80.62562,40.895419]]]},"properties":{"id":"44445","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.734374,40.900402],[-80.708017,40.813218],[-80.64447,40.813041],[-80.62562,40.895419],[-80.636916,40.914315],[-80.706846,40.963205],[-80.737706,40.944227],[-80.734374,40.900402]]]},"properties":{"id":"44408","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.708017,40.813218],[-80.734374,40.900402],[-80.755607,40.892841],[-80.770432,40.892891],[-80.823238,40.825068],[-80.708017,40.813218]]]},"properties":{"id":"44431","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-80.898386,40.672318],[-80.865124,40.676877],[-80.865327,40.676839],[-80.885453,40.682487],[-80.898386,40.672318]]]},"properties":{"id":"43962","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.849958,40.41132],[-81.787824,40.288888],[-81.688625,40.287633],[-81.668331,40.369519],[-81.68645,40.41982],[-81.805195,40.437191],[-81.849958,40.41132]]]},"properties":{"id":"43824","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.697055,40.223788],[-81.688625,40.287633],[-81.787824,40.288888],[-81.697055,40.223788]]]},"properties":{"id":"43845","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.268889,40.199407],[-82.212021,40.137975],[-82.088867,40.084406],[-82.100799,40.210878],[-82.195411,40.250908],[-82.268991,40.285403],[-82.272217,40.276236],[-82.28124,40.22269],[-82.268889,40.199407]]]},"properties":{"id":"43822","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.100799,40.210878],[-82.088867,40.084406],[-82.020243,40.052054],[-81.916564,40.07809],[-81.869809,40.156964],[-81.875555,40.157153],[-81.999068,40.201468],[-82.024791,40.231539],[-82.100799,40.210878]],[[-82.012311,40.143481],[-82.008123,40.13034],[-82.017236,40.132608],[-82.012311,40.143481]]]},"properties":{"id":"43821","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.984617,40.396064],[-81.977087,40.387717],[-81.969332,40.394817],[-81.967864,40.40728],[-81.984617,40.396064]]]},"properties":{"id":"43805","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.723102,40.210686],[-81.725148,40.206887],[-81.713633,40.206815],[-81.714437,40.210515],[-81.723102,40.210686]]]},"properties":{"id":"43836","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.836148,40.884758],[-82.940173,40.921169],[-83.016496,40.935756],[-83.074051,40.877512],[-83.11136,40.702915],[-82.995986,40.73208],[-82.894754,40.704789],[-82.857214,40.784243],[-82.858833,40.794296],[-82.857017,40.795819],[-82.837396,40.83615],[-82.836148,40.884758]],[[-82.875681,40.871062],[-82.873013,40.871048],[-82.873043,40.869415],[-82.876617,40.868902],[-82.875681,40.871062]]]},"properties":{"id":"44820","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.995986,40.73208],[-82.976589,40.557802],[-82.958063,40.557934],[-82.906716,40.645965],[-82.894754,40.704789],[-82.995986,40.73208]]]},"properties":{"id":"43314","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.619278,40.942606],[-82.743298,40.962238],[-82.743598,40.901713],[-82.65783,40.813687],[-82.532773,40.876352],[-82.619278,40.942606]]]},"properties":{"id":"44875","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.205678,40.522403],[-83.093573,40.462994],[-83.0196,40.502682],[-82.976589,40.557802],[-82.995986,40.73208],[-83.11136,40.702915],[-83.161361,40.702513],[-83.324012,40.658186],[-83.283619,40.527349],[-83.310129,40.506471],[-83.289613,40.499409],[-83.211729,40.530216],[-83.203974,40.539002],[-83.205678,40.522403]]]},"properties":{"id":"43302","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.759417,41.448437],[-81.768814,41.475553],[-81.832143,41.464651],[-81.827336,41.45023],[-81.763945,41.444977],[-81.759417,41.448437]]]},"properties":{"id":"44111","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.725301,41.421082],[-81.734229,41.352805],[-81.703675,41.350911],[-81.684585,41.350966],[-81.685656,41.417367],[-81.725301,41.421082]]]},"properties":{"id":"44134","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.658343,41.505423],[-81.692118,41.497924],[-81.673227,41.47819],[-81.669003,41.470438],[-81.655299,41.481997],[-81.656989,41.496473],[-81.658343,41.505423]],[[-81.669149,41.489133],[-81.668215,41.491409],[-81.665749,41.489456],[-81.666768,41.487341],[-81.669149,41.489133]]]},"properties":{"id":"44115","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.502639,41.587461],[-81.48794,41.586249],[-81.488739,41.624048],[-81.488643,41.631522],[-81.509808,41.624043],[-81.526729,41.587272],[-81.52673,41.586339],[-81.502639,41.587461]]]},"properties":{"id":"44132","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.84286,41.389338],[-81.876559,41.389449],[-81.87696,41.350685],[-81.838914,41.350618],[-81.84286,41.389338]]]},"properties":{"id":"44017","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.467822,40.064792],[-84.652958,39.97454],[-84.6244,39.927555],[-84.54769,39.917542],[-84.491317,39.906509],[-84.485472,39.908958],[-84.464432,39.926671],[-84.428777,40.050793],[-84.467822,40.064792]],[[-84.488271,39.99024],[-84.483485,39.983982],[-84.490577,39.983773],[-84.488271,39.99024]]]},"properties":{"id":"45304","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.709383,40.251267],[-84.737821,40.291319],[-84.766137,40.294451],[-84.803721,40.338093],[-84.808916,40.092644],[-84.708585,40.182166],[-84.709383,40.251267]]]},"properties":{"id":"45390","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.94964,40.268413],[-82.778205,40.181768],[-82.73987,40.208234],[-82.735887,40.260317],[-82.79365,40.339824],[-82.920143,40.357669],[-82.929588,40.358123],[-82.94964,40.268413]]]},"properties":{"id":"43074","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.956905,40.331779],[-82.960098,40.330181],[-82.960896,40.326344],[-82.957925,40.326203],[-82.956905,40.331779]]]},"properties":{"id":"43032","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.457787,41.357469],[-82.46889,41.389447],[-82.610538,41.437007],[-82.614126,41.358625],[-82.543436,41.325581],[-82.457787,41.357469]]]},"properties":{"id":"44839","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.844356,41.357709],[-82.936611,41.2559],[-82.957016,41.197776],[-82.870696,41.153566],[-82.835458,41.144053],[-82.766325,41.157236],[-82.747149,41.324381],[-82.766085,41.330907],[-82.844356,41.357709]],[[-82.861737,41.233974],[-82.859791,41.226115],[-82.867257,41.226184],[-82.861737,41.233974]]]},"properties":{"id":"44811","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.642288,41.286474],[-82.653051,41.176869],[-82.645597,41.169175],[-82.639949,41.162554],[-82.623634,41.141338],[-82.538473,41.145861],[-82.496981,41.155329],[-82.456908,41.203275],[-82.461683,41.212526],[-82.534741,41.289416],[-82.542379,41.300992],[-82.642288,41.286474]]]},"properties":{"id":"44857","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.999916,41.457846],[-82.973958,41.357656],[-82.860033,41.39275],[-82.847634,41.428756],[-82.952224,41.458239],[-82.999916,41.457846]]]},"properties":{"id":"43464","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.577316,39.797734],[-82.479271,39.773706],[-82.446488,39.806081],[-82.468866,39.871042],[-82.512656,39.839368],[-82.577316,39.797734]]]},"properties":{"id":"43148","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.831115,39.954782],[-82.833772,39.920136],[-82.829061,39.91773],[-82.809682,39.91155],[-82.730558,39.944303],[-82.771881,39.998341],[-82.813735,39.98204],[-82.826836,39.981294],[-82.828901,39.981063],[-82.831115,39.954782]]]},"properties":{"id":"43068","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.730558,39.944303],[-82.809682,39.91155],[-82.714053,39.8554],[-82.692042,39.846221],[-82.68444,39.934666],[-82.730558,39.944303]]]},"properties":{"id":"43147","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.448869,39.659078],[-82.359443,39.657005],[-82.367155,39.731148],[-82.43598,39.746438],[-82.478126,39.664393],[-82.448869,39.659078]]]},"properties":{"id":"43107","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.35609,39.623605],[-83.275352,39.504788],[-83.16373,39.538],[-83.213155,39.620754],[-83.257771,39.627109],[-83.35609,39.623605]]]},"properties":{"id":"43145","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.481512,39.683687],[-83.35609,39.623605],[-83.35557,39.624145],[-83.437775,39.703962],[-83.481512,39.683687]]]},"properties":{"id":"43106","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.938402,39.856233],[-82.944538,39.83062],[-82.921101,39.800159],[-82.836347,39.780864],[-82.863791,39.890669],[-82.898421,39.902221],[-82.938402,39.856233]]]},"properties":{"id":"43125","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.833772,39.920136],[-82.841116,39.90767],[-82.829061,39.91773],[-82.833772,39.920136]]]},"properties":{"id":"43109","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.983202,39.97983],[-82.981627,39.964395],[-82.951482,39.967835],[-82.979946,39.979797],[-82.983202,39.97983]]]},"properties":{"id":"43203","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.169655,39.821249],[-83.032719,39.822353],[-83.017926,39.880377],[-83.064123,39.921523],[-83.092634,39.918855],[-83.143873,39.911239],[-83.250077,39.918254],[-83.247652,39.865133],[-83.21006,39.827364],[-83.171049,39.820638],[-83.169655,39.821249]]]},"properties":{"id":"43123","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.813735,39.98204],[-82.771881,39.998341],[-82.767898,40.046273],[-82.822641,40.04241],[-82.825507,39.984069],[-82.813735,39.98204]]]},"properties":{"id":"43004","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.000887,40.080265],[-82.999551,40.061666],[-82.956895,40.05976],[-82.949166,40.110604],[-82.977417,40.110511],[-83.000887,40.080265]]]},"properties":{"id":"43229","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-82.935363,40.027019],[-82.996855,40.02419],[-82.995858,40.010895],[-82.97239,39.990787],[-82.935363,40.027019]]]},"properties":{"id":"43211","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.057651,39.968069],[-83.05202,39.962659],[-83.017381,39.951016],[-83.057651,39.968069]]]},"properties":{"id":"43222","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.536391,41.59361],[-84.516961,41.556624],[-84.465631,41.528461],[-84.380897,41.546936],[-84.358446,41.601344],[-84.409963,41.65144],[-84.48742,41.636728],[-84.495551,41.629329],[-84.536391,41.59361]]]},"properties":{"id":"43570","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.223931,41.528955],[-84.222216,41.533887],[-84.23453,41.532536],[-84.223931,41.528955]]]},"properties":{"id":"43553","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.376136,41.500412],[-81.297821,41.439674],[-81.287289,41.49961],[-81.376136,41.500412]]]},"properties":{"id":"44072","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.322381,41.275667],[-81.28074,41.34743],[-81.391761,41.35729],[-81.419012,41.348753],[-81.391759,41.284053],[-81.382178,41.276552],[-81.322381,41.275667]]]},"properties":{"id":"44202","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.109666,39.655968],[-84.056243,39.685299],[-84.043675,39.691991],[-84.066326,39.693942],[-84.105982,39.697207],[-84.125529,39.688508],[-84.136832,39.658372],[-84.109666,39.655968]]]},"properties":{"id":"45440","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-83.902072,39.727762],[-83.888057,39.720594],[-83.884141,39.708294],[-83.873266,39.717311],[-83.898138,39.729332],[-83.902072,39.727762]]]},"properties":{"id":"45384","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.216507,39.672629],[-84.225163,39.637842],[-84.218973,39.631134],[-84.108181,39.627466],[-84.109666,39.655968],[-84.136832,39.658372],[-84.202947,39.674683],[-84.216507,39.672629]]]},"properties":{"id":"45459","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-84.023384,39.709853],[-84.019418,39.709384],[-84.019112,39.713053],[-84.021212,39.713919],[-84.023384,39.709853]]]},"properties":{"id":"45301","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.678848,39.936111],[-81.68358,40.09316],[-81.776365,40.154168],[-81.810176,40.141726],[-81.784306,40.103215],[-81.778609,39.933765],[-81.694365,39.891327],[-81.678848,39.936111]]]},"properties":{"id":"43762","state_code":"39"}},
    {"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[-81.52253,39.99197],[-81.497783,39.983692],[-81.49738,39.98678],[-81.52253,39.99197]]]},"properties":{"id":"43750","state_code":"39"}}
]};
export default map;