import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import shortid from "shortid";
import { BUTTON_TYPE, BUTTON_VARIANT, SEGMENTS_TITLES, SIZES } from "../class/constants";
import { convertPxToViewport } from "../class/formatting";
import { tryParse } from "../class/utils";
import Button from "../newComponents/Button";
import "../styles/filterBy.css";
import { lang } from "../language/messages_en";
import AppliedBasicFilter from "./AppliedBasicFilter";

const AppliedFilters = (
    {
      filterProps,
      handleCheckboxChange,
      sectionsData,
      userAllowedVectors,
      basicFilterCountLimit,
      fetchData,
      clearFilter,
      quadrantsDisabled,
      psFilterDisabled,
      parentMessage,
      filterFinal,
      clearBasicFilter,
      saveFilter,
      forgetFilter,
      clearFilterFromDialog,
      className,
      tooltip,
      vectors,
      filterIsEditable,
      isEntityStacks
    },
    ref
  ) => {
    const [filter, setFilter] = useState(filterProps);

    useImperativeHandle(ref, () => ({
        setFilterFromOutsideRef: (filter) => {
            setFilterFromOutside(filter);
        }
    }));

    function setFilterFromOutside(filter) {
        setFilter(filter);
    }

    useEffect(() => {
        setFilter(filterProps)
    }, [filterProps]);

    function applyChanges(vector, entities){
        let basicAppliedFilter = typeof filter === 'string' ? JSON.parse(filter) : filter;
        basicAppliedFilter.find(e=>e.vector === vector).entities = entities;// fill entities on apply
        basicAppliedFilter.map(function(item){item.isBasicFilter = true});
        fetchData(basicAppliedFilter);
    }

    function saveBasicFilter(){
        let basicAppliedFilter = typeof filterFinal === 'string' ? JSON.parse(filterFinal) : filterFinal
        saveFilter(basicAppliedFilter, true)
    }

    function forgetBasicFilter(){
        forgetFilter(clearFilters);
    }

    function renderSaveClear() {
        let basicAppliedFilter = typeof filter === 'string' ? JSON.parse(filter) : filter;
        let disabled = basicAppliedFilter?.filter(f => f.entities.length === 0)?.length > 0;
        let isAdvancedFilterApplied = filterFinal && filterFinal !== "[]" && (typeof filterFinal === "object"? filterFinal.find(e=>!e.isBasicFilter): JSON.parse(filterFinal).find(e=>!e.isBasicFilter));

        if (basicAppliedFilter?.length > 0 && !isAdvancedFilterApplied) {
        return (
            <div className="basic-filter-container">
                <Button 
                    id={"save-basic-filter-button"}
                    label={lang.save_filter}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    disabled={disabled}
                    onBtnClick={saveBasicFilter}
                />
                {basicAppliedFilter[0].hasOwnProperty("isBasicFilter") && basicAppliedFilter[0]?.filter_display_name && filterIsEditable ?
                    <Button 
                        id={"forget-basic-filter-button"}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.ICON}
                        type={BUTTON_TYPE.DEFAULT}
                        leftIcon={<i class="fal fa-trash" />}
                        onBtnClick={forgetBasicFilter}
                    />
                : ""
                }
                <Button 
                    id={"clear-filter-button"}
                    label={lang.clear_filters}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={clearFilters}
                />
            </div>
        )
        }
    }

    function clearFilters(){
        clearBasicFilter();
        clearFilterFromDialog();
    }

    function renderBasicAppliedFilter(){
        let appliedFilters = [];
        let isAdvancedFilterApplied = filterFinal && filterFinal !== "[]" && (typeof filterFinal === "object"? filterFinal.find(e=>!e.isBasicFilter): JSON.parse(filterFinal).find(e=>!e.isBasicFilter));
        let basicAppliedFilter = typeof filter === 'string' ? JSON.parse(filter) : filter;
        if(basicAppliedFilter && basicAppliedFilter[0] && basicAppliedFilter[0].hasOwnProperty("isBasicFilter") && basicAppliedFilter[0]?.filter_display_name && !isEntityStacks){
            appliedFilters.push(<span className="uk-text-xmedium uk-small-padding uk-text-bold uk-margin-left filter_applied_name">{basicAppliedFilter[0].filter_display_name}</span>)
        }
        if( !isAdvancedFilterApplied){
            for (let e in basicAppliedFilter) {
                appliedFilters.push(
                    <AppliedBasicFilter key={Math.random()} filterObject={basicAppliedFilter[e]} filterFinal={tryParse(filterFinal)}
                    userAllowedVectors={userAllowedVectors} vectors={vectors} basicFilterCountLimit={basicFilterCountLimit} 
                    handleCheckboxChange={handleCheckboxChange} sectionsData = {sectionsData} applyChanges={applyChanges}/>
                );
            }
        }
        return appliedFilters;
    }

    function renderAppliedFilters(){
        let appliedFilters = [];
        filterFinal = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
        let isAdvancedFilterApplied = filterFinal && filterFinal !== "[]" && (typeof filterFinal === "object"? filterFinal.find(e=>!e.isBasicFilter): JSON.parse(filterFinal).find(e=>!e.isBasicFilter));
        if(isAdvancedFilterApplied){
            let quadTierNotWorkingMsgAddition = quadrantsDisabled ? ", " +((SEGMENTS_TITLES.PROFIT_SEGMENTS.label + " and " + SEGMENTS_TITLES.PROFIT_TIERS.label)) : "";
            let not = quadTierNotWorkingMsgAddition !== "" ? " "+lang.are_not : " "+lang.is_not;
            let psNotWorkingMsg = lang.note_that_ps_filter + quadTierNotWorkingMsgAddition + not +" "+lang.applied_in_this_report;
            let quadTierNotWorkingMsg = "Note that "+((SEGMENTS_TITLES.PROFIT_SEGMENTS.label + " and " + SEGMENTS_TITLES.PROFIT_TIERS.label))+" are not applied in this report.";
            let message = quadrantsDisabled ?  psFilterDisabled ? "- " + psNotWorkingMsg : "-" + quadTierNotWorkingMsg : psFilterDisabled ? "- " + psNotWorkingMsg : "";
            message = message === "" && parentMessage ? parentMessage : message;
            appliedFilters.push(<span className={"uk-text-xmedium uk-small-padding uk-text-bold uk-margin-left " + className}>{isAdvancedFilterApplied && isAdvancedFilterApplied.filter_display_name && !isEntityStacks ? isAdvancedFilterApplied.filter_display_name : lang.advanced_filters}</span>)
            appliedFilters.push(<span className={"uk-text-xmedium uk-margin-medium-left fs-14 uk-text-decoration-underline uk-cursor-pointer " + className} uk-toggle={"target: #filterModal"}>{lang.show_details}</span>)
            {message.length > 0 ?
                appliedFilters.push(<span className={"close-filter-note uk-margin-small-left " + className}><i className="fas fa-exclamation pi-text-yellow fa-lg uk-margin-small-right" title={message}/></span>)
                : ""
            }
            appliedFilters.push(<span className={"uk-text-xmedium uk-margin-small-left gray-hover " + className} onClick={clearFilter}>{lang.clear_filters}</span>)
        }

        return <span className={"uk-display-flex " + (appliedFilters.length > 0 ? "applied-filters-span" : "")}><span className={"uk-display-flex align-items-center "} uk-tooltip={tooltip}>{appliedFilters}</span></span>;
    }

    function renderFilterAppliedSpan() {
        let filtersAppliedSpan = [];
        let basicAppliedFilter = typeof filter === 'string' ? JSON.parse(filter) : filter;
        filterFinal = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
        let isAdvancedFilterApplied = filterFinal && filterFinal !== "[]" && (typeof filterFinal === "object"? filterFinal.find(e=>!e.isBasicFilter): JSON.parse(filterFinal).find(e=>!e.isBasicFilter));
        if (basicAppliedFilter?.length > 0 || isAdvancedFilterApplied) {
            filtersAppliedSpan.push(<span className="uk-text-small uk-display-block uk-small-padding uk-text-bold uk-margin-left filter_applied_name">{lang.basic_filter.applied_filters_text}</span>)
        }

        return filtersAppliedSpan;
    }

  return (
      <div key={shortid.generate()} >
          {renderFilterAppliedSpan()}
          {renderAppliedFilters()}
          <div className="basic-filter-container">
            <div className="uk-display-flex" uk-tooltip={tooltip}>
                <div className={"uk-display-flex " + className} style={{ columnGap: convertPxToViewport(8), alignItems: "center" }}>
                    {renderBasicAppliedFilter()}
                    {renderSaveClear()}
                </div>
            </div>
          </div>
      </div>
  );
};

export default forwardRef(AppliedFilters);
