import React, { Component } from 'react';
import shortid from 'shortid';
import GLHeader from './GLHeader';
import GLTable from './GLTable';
import {ALL_WIDGETS, PS_MAPPING} from '../../class/constants';
import { copyObjectValues, arrayIn, parseBoolean, capitalizeFirstLetter } from '../../class/utils';
import { updateAllElements, addElements, checkLineUnderParent } from '../../class/array';
import ExcelDetailsTable from '../../tables/ExcelDetailsTable';
import { getMonthName } from '../../class/date';
/**
 *  GLCombinations is a component in the profit stack mapping page
 * @author [Mostafa Haydar]
 * @extends Component
 * **/
const _treeChildren = "children";
const _checked = "checked";
const _expanded = "expanded";
const _label = "label";
const _netrevenue = "netrevenue";
const _name = PS_MAPPING.FIELDS.NAME;
const $ = require('jquery');
const _id = PS_MAPPING.FIELDS.PSS_ID;
const _isMatched = PS_MAPPING.EXCEPTION_FIELDS.isMatched;
class GLCombinations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
            id: shortid.generate(),
            excludedCombinations: [],
            excludeDisabled: true,
            unassignDisabled: true,
            flipSignDisabled: true,
            pssFilterStructureData:this.props.pssFilterStructureData,
            assignedToFilter:[]
        };
        this.applyFilter = this.applyFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.onChange = this.onChange.bind(this);
        this.isLoading = false;
    }

    // JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)
    componentDidUpdate(prevProps) {
        if ((this.props.id !== prevProps.id) || (this.props.data && !prevProps.data)) {
            this.setState({
                data:this.props.data,
                columns: this.props.columns,
                id: shortid.generate(),
            })
        } 
        if(JSON.stringify(this.props.pssFilterStructureData) !== JSON.stringify(prevProps.pssFilterStructureData)){
            this.setState({
                pssFilterStructureData:this.props.pssFilterStructureData
            })
        }
    }

    /**
     * this function is to replace the main parent name with the new inherited name in the assigned to field
     * @param {*} rowData 
     * @param {*} parentName 
     */
    inheritAssignTo=(rowData, parentName)=>{
        let _this = this;
        let originalData = this.GLTableref.tabulator.getData();
        originalData.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].indexOf(parentName)>-1).forEach(line =>{
            let index = line[PS_MAPPING.FIELDS.ASSIGNED_TO].indexOf(parentName);
            if(index > -1){
                line[PS_MAPPING.FIELDS.ASSIGNED_TO].splice(index, 1);
                line[PS_MAPPING.FIELDS.ASSIGNED_TO].push(rowData[_name])
            }
        });
        _this.GLTableref.tabulator.replaceData(originalData);
    }

    /**
     * function clears the filter from FilterByGLSource component applied preserving the filter applied from filterByPSS component on tabulator 
     * called from FilterByGLSource component
     */
    clearFilter(){
        let mainFilter = [];
        mainFilter = this.GLTableref.tabulator.getFilters(true);
        mainFilter = mainFilter.filter(e=> e.field.valueOf("key") === this.props.customFilter);
        this.GLTableref.tabulator.clearFilter();
        if (mainFilter.length > 0) {
            this.GLTableref.tabulator.setFilter(mainFilter);
        }
        this.GLTableref.addFooterText(this.GLTableref.tabulator.getData('active'))
    }

     /**
     * function applies the filter set in FilterByGLSrouce component drop down on and filter applied in FilterByPSS component on tabulator 
     * called from FilterByGLSource component
     * also filters linked transition columns if any 
     * @param { Array } combination filter eg: transition columns
     * @param { Array } linkedToTransition filter eg: account group name
     */
      applyFilter(filter, linkedTranistionFilter, cols){
        let mainFilter = [];
        mainFilter = this.GLTableref.tabulator.getFilters(true);
        mainFilter = mainFilter.filter(e=> e.field.valueOf("key") === this.props.customFilter);

        this.GLTableref.tabulator.clearFilter();
        let finalFilter = [];
        let glsourceFilter = [];
        let combinationFilter = {};
        let keys = [];
        if (filter && Object.keys(filter).length>0) {
            keys = Object.keys(filter); 
            keys.forEach((key) => {
                combinationFilter[key] = filter[key].toLowerCase().split(",");
            });
        }


        function customSourceFilter(data, filterParams){
            let filterData = filterParams.data;//[{value:['selling','non-selling']},{value:['09']}]
            let tabCell = data[PS_MAPPING.FIELDS.FIELDS_COMBINATION].toLowerCase().split(","); // ['B1','non-selling','09']
            // const isEqual = (currentValue) => tabCell[cols.indexOf[tabCell]].includes(currentValue);
            keys = Object.keys(filterData);
            for (e in keys){
                let index = cols.indexOf(keys[e]);
                if(!(index != -1 && filterData[keys[e]].includes(tabCell[index]))){
                    return false;
                }
            };
            return true;
        }
         this.GLTableref.tabulator.setFilter(customSourceFilter,{data:combinationFilter}); //setting pss filter
         mainFilter = mainFilter.concat(this.GLTableref.tabulator.getFilters());

        if (linkedTranistionFilter && Object.keys(linkedTranistionFilter).length>0) {
            keys = Object.keys(linkedTranistionFilter);
            for (var e in keys) {
                if(typeof Number(keys[e]) !== "number"){ // to check if the object has keys, for not to take the index
                    glsourceFilter.push({field:keys[e], type: 'in',value:[linkedTranistionFilter[keys[e]]]})
                }
            }
        }
        finalFilter =  mainFilter.length > 0 ? mainFilter.concat(glsourceFilter) : glsourceFilter
        this.GLTableref.tabulator.setFilter(finalFilter);
        this.GLTableref.addFooterText(this.GLTableref.tabulator.getData('active'))
    }

    getLeafNodes(nodes, result = []){
        for(var i = 0, length = nodes.length; i < length; i++){
          if(!nodes[i].children || nodes[i].children.length === 0){
            result.push(nodes[i]);
          }else{
            result = this.getLeafNodes(nodes[i].children, result);
          }
        }
        return result;
    }
   
    extractSelectedNodes = (selectedNodes)=> {
        let result = [];
        for(var e in selectedNodes){
            let currentNode = selectedNodes[e];
            let path =currentNode.path;
            if(currentNode.label === PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL){
                result.push(PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL)
            } else if(currentNode.label === PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL){
                result.push(PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL)
            } else if (currentNode.label === PS_MAPPING.PSS_FILTER_LABELS.ASSIGNED_LABEL){
                let assignedObj = this.state.pssFilterStructureData.filter(e=>e.label === PS_MAPPING.PSS_FILTER_LABELS.ASSIGNED_LABEL)[0];
                let assignedLeafChildren = this.getLeafNodes(assignedObj.children);
                let assignedLeafChildrenLabels = assignedLeafChildren.map((e)=> {
                    return e.label;
                })
                result.push(...assignedLeafChildrenLabels);
            } else {
                var obj = this.findDFS(this.state.pssFilterStructureData,path);
                if(obj.children){
                    let nodeLeafNodes = this.getLeafNodes(obj.children);
                    let nodeLeafChildrenLabels = nodeLeafNodes.map((e)=> {
                        return e.label;
                    });
                    result.push(...nodeLeafChildrenLabels);
                } else {
                    result.push(currentNode.label);
                }
            }
        }
        return result;
    }
    updateStructure(data,selectedNodes){
        for(var e in selectedNodes){
            let currentNode = selectedNodes[e];
            let path =currentNode.path;
            var obj = this.findDFS(data,path);
            if(obj.children){
                obj.children = updateAllElements(obj.children, _treeChildren, _checked, true);
            }
            obj[_checked] = true;
        }
        return data;
    }

    onChange(currentNode, selectedNodes) {
        let _this = this;
        let filter = this.extractSelectedNodes(selectedNodes);
        _this.setState({
            assignedToFilter: filter
        });
        let mainFilter = this.GLTableref.tabulator.getFilters();
        mainFilter = mainFilter.filter(e=> e.field.valueOf("key") !== this.props.customFilter);
        if(filter.length === 0){
            this.GLTableref.tabulator.clearFilter();
            this.GLTableref.tabulator.setFilter(mainFilter);
            this.GLTableref.addFooterText(this.GLTableref.tabulator.getData('active'))
        } else {
            let allFilters = this.GLTableref.tabulator.getFilters();
            let sourceGLFilter = allFilters.filter(e=> e.field.valueOf("key") !== this.props.customFilter); //glsource filter
            this.GLTableref.tabulator.setFilter(_this.props.customFilter,{data:filter}); //setting pss filter
            let finalFilter = sourceGLFilter.concat(this.GLTableref.tabulator.getFilters()); // combining glsource filter with pss filter
            this.GLTableref.tabulator.setFilter(finalFilter); //setting filter
            this.GLTableref.addFooterText(this.GLTableref.tabulator.getData('active'))
        }
        let pssFilterStructureData = copyObjectValues(this.state.pssFilterStructureData);
        pssFilterStructureData = updateAllElements(pssFilterStructureData, _treeChildren, _checked, false);
        let updatePssFilterStructure = this.updateStructure(pssFilterStructureData,selectedNodes);
        let data = _this.GLTableref.tabulator.getData();
        _this.props.resetCheckedCombinations(data);
        this.setState({
            pssFilterStructureData:updatePssFilterStructure,
            showDropdown:true,
            data: data,
            selectedNodes:selectedNodes
        },()=>{
            $(".search").val("");
        }) 
    };

    findDFS(objects, path) {
        for (let o of objects || []) {
          if (o.path == path) return o
          const o_ = this.findDFS(o.children, path)
          if (o_) return o_
        }
    }

    findObj(objects, id) {
        for (let o of objects || []) {
          if (o[PS_MAPPING.FIELDS.PSS_ID] == id) return o
          const o_ = this.findObj(o.children, id)
          if (o_) return o_
        }
    }


    onNodeToggle = (currentNode) => {

        let pssFilterStructureData = copyObjectValues(this.state.pssFilterStructureData);
        let path = currentNode.path
        var obj = this.findDFS(pssFilterStructureData,path);
        obj.expanded = currentNode.expanded
        if(obj.children && !obj.expanded){
            obj.children = updateAllElements(obj.children, _treeChildren, _expanded, currentNode.expanded);
        }
        
    
        this.setState({
            pssFilterStructureData:pssFilterStructureData,
            showDropdown:true
        },()=>{
            $(".search").val("");
        });
    }

    /**
     * to enable and disable the assign and the exclude buttons based on the selected line
     * @param {*} data 
     */
    setButtonsStatus=(data)=>{
        let _this = this;
        let excludeDisabled = false;
        let unassignDisabled = false;
        let flipSignDisabled = false;
        if(data.length===0){
            excludeDisabled = true;
            unassignDisabled = true;
            flipSignDisabled = true;
        }else{
            for(var line in data){
                if(data[line][PS_MAPPING.FIELDS.ASSIGNED_TO][0] === PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL){
                    excludeDisabled = true;
                }
                if(data[line][PS_MAPPING.FIELDS.ASSIGNED_TO][0] === PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL){
                    unassignDisabled = true;
                }
                // if(excludeDisabled && unassignDisabled){// on checkAll, stop searching when the two buttons are disabled
                //     break;
                // }
            }
        }
        _this.setState({
            excludeDisabled: excludeDisabled,//getSectionExists(data.map(line=>{return line[PS_MAPPING.FIELDS.ASSIGNED_TO]=== "EXCLUDED" }), "false")
            unassignDisabled: unassignDisabled,
            flipSignDisabled: flipSignDisabled
        });
    }

    getCombinationsTotalAmount = (data,assignedTo) => {
        let totalAmount = data.filter(e=>e.assigned_to.join(",") === assignedTo).reduce((accumulator, currentObject) => {
            return accumulator + currentObject.total;
        }, 0);
        return totalAmount;
    }
 /**
  * updating amounts after flipping combinations
  */
    setNewAmounts=(data, names, totalCombinationsAmount )=>{
    for (var e in data) {
            if (names && names.includes(data[e].name) && (!parseBoolean(data[e].is_matched) && data[e].mappingException !== 'NONE')){ // if not matched
                let relatedLinesAmount = data.filter(f=>f.pssLeadingCostKey === data[e].pssLeadingCostKey && parseBoolean(f.is_matched)).reduce((accumulator, currentObject) => {
                    return accumulator + currentObject.amount;
                }, 0); // Get All lines related to unmatched line
                data[e].amount = totalCombinationsAmount - relatedLinesAmount;
                // if splitted by NONE
            } else if (names && names.includes(data[e].name) && data[e].split_percentage && data[e].mappingException === 'NONE') {
                data[e].amount = totalCombinationsAmount*(Number(data[e].split_percentage)/100);
            } else if(names && names.includes(data[e].name) && data[e].mappingException === 'NONE' ) {
                data[e].amount = totalCombinationsAmount; // Mapped To None Without Split Percentage
            }
        }
    }

    flipSign=(combinations,uncheck, selectedRow, pss_action, fromProceed)=>{
        let _this = this;
        let newlyFlipped = _this.state.newlyFlipped || [];
        let newlyUnFlipped = _this.state.newlyUnFlipped || [];
        let data = combinations || copyObjectValues(_this.GLTableref.tabulator.getData());
        let periods = _this.props.getSelectedPeriods();
        let mappedLines = copyObjectValues(_this.props.getMappedLines());
        let pslData = _this.props.getPSLData();
        // let checkedDataLength2 = copyObjectValues(data).filter(e=>e.checked==="true").length;
        data.map(function(item){
            if(parseBoolean(item.checked)){
                let assignedTo = item.assigned_to.join(",");
                let totalCombinationsAmountAssigned = 0;
                let underNetRevenue = false;
                if (fromProceed && !combinations) { // assigning multiple combinations to a PSL line  
                    for (var e in item[PS_MAPPING.FIELDS.ASSIGNED_TO]) {
                        if (checkLineUnderParent(pslData, _netrevenue, _name, item[PS_MAPPING.FIELDS.ASSIGNED_TO][e])) {
                            underNetRevenue = true;
                        }
                    }
                    if(underNetRevenue){
                        item.flipped = !item.flipped;
                        item.modified = true;
                        if(periods.length > 0) {
                            for(var e in periods) {
                                item[periods[e]] = -1*(item[periods[e]]); 
                            }
                            item.total = -1*(item.total);
                        }
                        totalCombinationsAmountAssigned= _this.getCombinationsTotalAmount(data,assignedTo);
                        _this.setNewAmounts(mappedLines, item[PS_MAPPING.FIELDS.ASSIGNED_TO], totalCombinationsAmountAssigned);
                        if (uncheck) {
                            item.checked = "false";
                        }
                        if(data.length < 100 ) {//checking less than 100 combination
                            _this.GLTableref.tabulator.updateRow(item[PS_MAPPING.FIELDS.FIELDS_COMBINATION], item);
                        }
                    } 
                } else {
                    item.modified = true;
                    item.flipped = !item.flipped;
                    if(periods.length > 0) {
                        for(var e in periods) {
                            item[periods[e]] = -1*(item[periods[e]]); 
                        }
                        item.total = -1*(item.total);
                    }
                    totalCombinationsAmountAssigned = _this.getCombinationsTotalAmount(data,assignedTo); //Get Total Combinations Amount 
                    _this.setNewAmounts(mappedLines, item[PS_MAPPING.FIELDS.ASSIGNED_TO], totalCombinationsAmountAssigned);
                    if (uncheck) {
                        item.checked = "false";
                    }
                    if(data.length < 100) {
                        _this.GLTableref.tabulator.updateRow(item[PS_MAPPING.FIELDS.FIELDS_COMBINATION], item);
                    }
                }
            }
        });
        _this.setState({
            excludeDisabled: true,
            unassignDisabled: true,
            flipSignDisabled: true,
            newlyFlipped:newlyFlipped,
            newlyUnFlipped:newlyUnFlipped,
            data:data,
        });

        _this.GLTableref.setState({
            checked_combinations: selectedRow && selectedRow !== null ? _this.GLTableref.state.checked_combinations : [],
            checked: selectedRow && selectedRow !== null ?_this.GLTableref.state.checked:  0,
        });

        if (!fromProceed || ((!selectedRow || selectedRow === null) && pss_action === ALL_WIDGETS.FIELDS.PS_MAPPING.ASSIGN)){
            _this.props.resetCheckedCombinations(data,data.length>=100);// if data.length> 100, we replaced data, so no need to uncheck all lines 
            _this.props.updateTotals(undefined,undefined,data);
            _this.props.refreshAmounts(mappedLines);
            _this.GLTableref.unCheckCheckAll();
        }
        if (data.length >=100) {
            _this.GLTableref.tabulator.replaceData(data);
        }
        if(fromProceed && ((selectedRow && selectedRow !== null) ||  pss_action !== ALL_WIDGETS.FIELDS.PS_MAPPING.ASSIGN)) {
            _this.updateAssignedStatus(pss_action, selectedRow, true);
        }
        if(_this.props.pslSectionRef?.pslTableRef?.isParsingAmount) { //In case you flipped a sign of a combination while a parse amount is happening you need to parse again to bring correct values
            _this.props.pslSectionRef?.pslTableRef?.parseAmount();
        }
      _this.props.setNotSavedWarning(true, false, true);
    }

    updateLineStatus=(line,linesToRemove,excludedCombinations, mappedLines, lineTable, counter, pss_action, isAssigned, data)=>{
        let _this = this;
        counter++;// stop searching when all elements of checked_combinations are found
        line[PS_MAPPING.FIELDS.ASSIGNED_TO] = [];
        if(pss_action === ALL_WIDGETS.FIELDS.PS_MAPPING.UNASSIGN){// unassigning
            line[PS_MAPPING.FIELDS.ASSIGNED_TO][0] = PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL;
            //newMappedLines = _this.props.removeLineFromMappedLines(line, newMappedLines); // moved to outside the for loop
            linesToRemove.push(line);
        }else if(pss_action === ALL_WIDGETS.FIELDS.PS_MAPPING.EXCLUDE){// excluding
            line[PS_MAPPING.FIELDS.ASSIGNED_TO][0] = PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL;
            //newMappedLines = _this.props.removeLineFromMappedLines(line, newMappedLines);
            linesToRemove.push(line);
            excludedCombinations.push(line[PS_MAPPING.FIELDS.FIELDS_COMBINATION]);
        }else{// if assigning a new combination
            linesToRemove.push(line);
            //newMappedLines =  _this.props.removeLineFromMappedLines(line, newMappedLines);
            for (var mline in mappedLines){
                line[PS_MAPPING.FIELDS.ASSIGNED_TO].push(mappedLines[mline].name);
            }
            line[PS_MAPPING.FIELDS.ASSIGNED_TO] = line[PS_MAPPING.FIELDS.ASSIGNED_TO].sort();
            // newMappedLines = _this.props.assignLine(_this.props.checked_combinations, selectedRow, newMappedLines);
            isAssigned = true;
        }
        line["checked"]= "false";
        lineTable[0] = line;
        _this.setButtonsStatus(lineTable);
        if(data.length < 100){// if we are assigning a small number, use updateRow, for big data updateRow cause big performance issue, so we should replaceData
            _this.GLTableref.tabulator.updateRow(line[PS_MAPPING.FIELDS.FIELDS_COMBINATION], line);
        }
    }

    /**
     * this function is to set "ASSIGNED" when assigning and "EXCLUDE" whenexcluding and the mapped line name when assigning a line
     * @param {*} pss_action 
     * @param {*} selectedRow 
     */
    updateAssignedStatus=(pss_action, selectedRow, passCheck,chosenCombinations)=>{
        let _this = this;
        let excludedCombinations = _this.state.excludedCombinations;
        if(_this.GLTableref && _this.GLTableref.tabulator){
            let counter = 0;
            let lineTable =[];
            let mappedLines ="";
            let data = copyObjectValues(_this.GLTableref.tabulator.getData());
            let len = !!chosenCombinations ? chosenCombinations.length :_this.props.checked_combinations? _this.props.checked_combinations.length : 0;
            let checked_combinations =!!chosenCombinations ? chosenCombinations : _this.props.checked_combinations? _this.props.checked_combinations : "";
            let allMappedLines = _this.props.getMappedLines();
            let leadingcostkey =_this.props.getLeadingCostKey(selectedRow);
            leadingcostkey = leadingcostkey === "" ? selectedRow[PS_MAPPING.FIELDS.COST_KEY] : leadingcostkey;
            mappedLines = allMappedLines.filter(e=>e.pssLeadingCostKey === leadingcostkey || e[PS_MAPPING.FIELDS.COST_KEY] === leadingcostkey || e[_id] === selectedRow[PS_MAPPING.FIELDS.ACTUAL_ID]);
            let newMappedLines = allMappedLines; 
            let pssFilterStructureData = copyObjectValues(_this.state.pssFilterStructureData);
            let newPssFilterStructure  = [];
            let isAssigned = false;
            let linesToRemove = [];
            let underNetRevenue = false;
            let pslData = _this.props.getPSLData();
            let movedToNetRevenue = checkLineUnderParent(pslData, _netrevenue, _name, selectedRow[_name]);
            let breakFunc = false;
            data.forEach(line => {
                if(checked_combinations.length === data.length/*don't filter if all combinations are selected*/ || (checked_combinations && checked_combinations.filter(e => e[PS_MAPPING.FIELDS.FIELDS_COMBINATION] === line[PS_MAPPING.FIELDS.FIELDS_COMBINATION]).length > 0) && counter <= len){
                    for (var e in line[PS_MAPPING.FIELDS.ASSIGNED_TO]) {
                        if (checkLineUnderParent(pslData, _netrevenue, _name, line[PS_MAPPING.FIELDS.ASSIGNED_TO][e])) {
                            underNetRevenue = true;
                        }
                    }
                    if (underNetRevenue && !movedToNetRevenue && !passCheck && pss_action === ALL_WIDGETS.FIELDS.PS_MAPPING.ASSIGN) {
                        _this.selectedRow = selectedRow;
                        _this.pss_action = pss_action;
                        $("#flip_checkbox").prop('checked',true);
                        _this.props.setOpenCombinationFlipWarningDialog(true,undefined)
                        breakFunc = true;
                    }
                    if (!breakFunc)
                    counter++;// stop searching when all elements of checked_combinations are found
                    line[PS_MAPPING.FIELDS.ASSIGNED_TO] = [];
                    if(pss_action === ALL_WIDGETS.FIELDS.PS_MAPPING.UNASSIGN){// unassigning
                        line[PS_MAPPING.FIELDS.ASSIGNED_TO][0] = PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL;
                        //newMappedLines = _this.props.removeLineFromMappedLines(line, newMappedLines); // moved to outside the for loop
                        linesToRemove.push(line);
                    }else if(pss_action === ALL_WIDGETS.FIELDS.PS_MAPPING.EXCLUDE){// excluding
                        line[PS_MAPPING.FIELDS.ASSIGNED_TO][0] = PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL;
                        //newMappedLines = _this.props.removeLineFromMappedLines(line, newMappedLines);
                        linesToRemove.push(line);
                        excludedCombinations.push(line[PS_MAPPING.FIELDS.FIELDS_COMBINATION]);
                    }else{// if assigning a new combination
                        linesToRemove.push(line);
                        //newMappedLines =  _this.props.removeLineFromMappedLines(line, newMappedLines);
                        for (var mline in mappedLines){
                            line[PS_MAPPING.FIELDS.ASSIGNED_TO].push(mappedLines[mline].name);
                        }
                        line[PS_MAPPING.FIELDS.ASSIGNED_TO] = line[PS_MAPPING.FIELDS.ASSIGNED_TO].sort();
                        // newMappedLines = _this.props.assignLine(_this.props.checked_combinations, selectedRow, newMappedLines);
                        isAssigned = true;
                    }
                    line["checked"]= "false";
                    lineTable[0] = line;
                    _this.setButtonsStatus(lineTable);
                    if(data.length < 100){// if we are assigning a small number, use updateRow, for big data updateRow cause big performance issue, so we should replaceData
                        _this.GLTableref.tabulator.updateRow(line[PS_MAPPING.FIELDS.FIELDS_COMBINATION], line);
                    }
                }
            });
            if (breakFunc) return "true";
            if(data.length>=100){// if assigning big number
                _this.GLTableref.tabulator.replaceData(data);
            }
            newMappedLines =  _this.props.removeLineFromMappedLines(linesToRemove, newMappedLines, selectedRow);//remove selected lines from mappedlines, this line was in the for loop for better performance, I moved it to here, I am filling the lines "toRemove" in the for loop and removing them here, outside the loop
            if(isAssigned){
                newMappedLines = _this.props.assignLine(_this.props.checked_combinations, selectedRow, newMappedLines);// if we are assigning a line, add it to mappedLine after removing old values
                const getAllCheckedLines = (arr,result=[]) => {
                    for(var e in arr){
                        if(arr[e][_checked]){
                            result.push(arr[e])
                        }
                        if(arr[e][_treeChildren]){
                            getAllCheckedLines(arr[e][_treeChildren],result);
                        }
                    }
                    return result;
                }
                let checkedLines = getAllCheckedLines(pssFilterStructureData[2][_treeChildren]);
                newPssFilterStructure = copyObjectValues(_this.props.generateNewPssFilterStructure());
                for(var e in checkedLines){
                    let pssId = checkedLines[e][PS_MAPPING.FIELDS.PSS_ID];
                    let obj = this.findObj(newPssFilterStructure,pssId);
                    if (obj){
                        obj[_checked] = true;
                    }
                }
                newPssFilterStructure = addElements(newPssFilterStructure, _treeChildren, _label,_name);
                pssFilterStructureData[2][_treeChildren] = newPssFilterStructure;
            }
            _this.setState({
                excludedCombinations: excludedCombinations,
                excludeDisabled: true,
                unassignDisabled: true,
                flipSignDisabled: true,
                data:data,
                pssFilterStructureData:pssFilterStructureData
            });
            _this.GLTableref.setState({
                checked_combinations: [],
                checked: 0,
            });
              _this.GLTableref.unCheckCheckAll();
            _this.props.resetCheckedCombinations(data,data.length>=100);// if data.length> 100, we replaced data, so no need to uncheck all lines 
            _this.props.updateTotals(undefined,undefined,data);
            _this.props.setMappedLines(newMappedLines);
            _this.props.setNotSavedWarning(true, false);
        }
    }

    /**
     * function assigns the leading line with all it siblings to the combination the leading line is assigned to 
     * function called from outsde component on click of submit of a standard psl
     * @param {*} mappedLines 
     * @param {*} line 
     */
    assignLineFromSetup=(mappedLines, line)=>{
        let _this = this;
        let leadingCostKey = line[PS_MAPPING.FIELDS.COST_KEY];
        let siblings = mappedLines.filter(e=>e[PS_MAPPING.FIELDS.LEADING_COSTKEY] === leadingCostKey);
        let assignedTo = [];
        siblings.map(function(item){ assignedTo.push(item[PS_MAPPING.FIELDS.NAME]); });
        let data = _this.GLTableref.tabulator.getData();
        data.forEach(line => {
            if(arrayIn(line[PS_MAPPING.FIELDS.ASSIGNED_TO], assignedTo)){
                line[PS_MAPPING.FIELDS.ASSIGNED_TO] = [];
                for (var mline in assignedTo){
                    line[PS_MAPPING.FIELDS.ASSIGNED_TO].push(assignedTo[mline]);
                }
            }
        });
        _this.setState({
            data:data,
        },function(){
            _this.GLTableref.tabulator.replaceData(data)
            .then(function(){
                _this.props.resetCheckedCombinations(data);
                _this.props.updateTotals(undefined,undefined,data);
            });
        });
    }


    exportTableToExcel = () => {
        this.GLTableref.exportTableToExcel();
    }
    
    getParams = () => {
        let _this = this;
        if(_this.props.user && _this.props.scenarioObject && this.GLTableref && this.glHeaderRef && _this.props.getSelectedPeriods){
            let pssFilter = _this.state.selectedNodes ? _this.state.selectedNodes.length > 0 ? copyObjectValues(_this.state.selectedNodes).map(e=>e.label) : [] :[PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL];
            let combinationFilter = this.glHeaderRef.filterGLRef.state.selectedColExport;
            let  keys = combinationFilter &&  combinationFilter !== null ? Object.keys(combinationFilter) : [];
            let colOrder = [...new Set(this.glHeaderRef.filterGLRef.colOrder)];
            let combinationFilterResult = new Array(colOrder.length);
            let selectedPeriods = _this.props.getSelectedPeriods();
            
            
            keys.forEach((key, index) => {
                let fullName = "";
                
                let i = colOrder.indexOf(key);
                let str = key.split("_");
                
                let finalStr = str.map(e=>capitalizeFirstLetter(e)).toString().replace(","," ");
               
                fullName = finalStr  + ": " +combinationFilter[key] ;
               
                combinationFilterResult[i] = fullName
               
            });
            if(combinationFilterResult.length > 0){
                combinationFilterResult = combinationFilterResult.filter(e=>e); //Remove empty fields
            }
           
            let params = {
                    "Report": "General Ledger Combinations",
                    "User": _this.props.user.first_name + " " + _this.props.user.last_name,
                    "Assigned to Filter" : pssFilter.length > 0 ? pssFilter.join(", ") : "None" ,
                    "Combinations Filter" : combinationFilterResult.length > 0 ? combinationFilterResult.join(", ") : "None" ,
                    "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
                    "Scenario": _this.props.scenarioObject["scenario_number"] + "-" + _this.props.scenarioObject["scenario_name"] + "-" + _this.props.scenarioObject["scenario_status"],
                    "Start Date":selectedPeriods[0],
                    "End Date":selectedPeriods[selectedPeriods.length-1]
            }
               
                
            params = this.formatParams(params);
            return params;
        }
    }

    formatParams = (params)=>{
        let data = []
        for(const property in params){
            let obj = {title:property,detail:params[property]}
            data.push(obj)
        }
        return data;
    }
   
    render() {
        let params = this.getParams();
        return(
            <div className="pi-box-shadow">
                <div className="uk-border">
                    <div className="gl-header">{this.props.title}</div>
                    <GLHeader ref={el=>this.glHeaderRef = el} onChange = {this.onChange} pssFilterStructureData={this.state.pssFilterStructureData} resetCheckedCombinations={this.props.resetCheckedCombinations} applyFilter={this.applyFilter} updateAssignedStatus={this.updateAssignedStatus} flipSignDisabled={this.state.flipSignDisabled} flipSign={this.flipSign}
                    clearFilter={this.clearFilter} glSourceData={this.props.glSourceData} status={this.state.status} id={this.state.id} excludeDisabled={this.state.excludeDisabled} unassignDisabled={this.state.unassignDisabled} onNodeToggle = {this.onNodeToggle} showDropdown= {this.state.showDropdown} exportTableToExcel={this.exportTableToExcel}/>
                </div>
                <GLTable ref = {el =>this.GLTableref= el} columns={this.state.columns} onLinkClick={this.props.onLinkClick} setButtonsStatus={this.setButtonsStatus} data={this.props.data} id={this.state.id} getSelectedPeriods={this.props.getSelectedPeriods} checkAllLines={this.props.checkAllLines} sendCheckedLinesToPSLTable={this.props.sendCheckedLinesToPSLTable} resetCheckedCombinations={this.props.resetCheckedCombinations} checked_combinations={this.props.checked_combinations}
                customFilter={this.props.customFilter} getParams={this.getParams} applyFilter={this.applyFilter} selectedCol={this.glHeaderRef && this.glHeaderRef.filterGLRef ? this.glHeaderRef.filterGLRef.state.selectedCol : undefined} cols={this.glHeaderRef && this.glHeaderRef.filterGLRef ? this.glHeaderRef.filterGLRef.state.cols : undefined} assignedToFilters={this.state.assignedToFilter} selectedNodes={this.state.selectedNodes} pssFilterStructureData={this.state.pssFilterStructureData} notFirstLoad={this.props.notFirstLoad}/>
               <div className="uk-hidden">
                        <ExcelDetailsTable ref={el=>this.excelDetails= el} params = {params}/>
                </div>
            </div>
        );

    }

}

export default GLCombinations;