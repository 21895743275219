import React, { Component } from 'react';
import DefineAccrualsTable from './DefineAccrualsTable';
import { getTranslationFile, copyObjectValues, parseBoolean, tryParse} from '../../class/utils.js';
import { MANAGE_ACCRUALS_STATUSES, ACCRUALS, FOCUS_ACCRUALS, ALL_WIDGETS, API_URL, FISCAL_YEARS, ALL_REPORTS, ROW_STATUS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE } from '../../class/constants.js';
import { fetchAPI } from '../../class/networkUtils';
import { getNumericValue } from '../../class/number';
import { toggleLoader } from '../../class/common';
import { alertAndLogError } from '../../class/jqueries';
import '../../styles/dataModeling.css';
import FocusAccrual from "./FocusAccrual";
import shortid from 'shortid';
import Popup from 'react-popup';
import { getCostClassification } from '../../class/commonRequests';

import { setLocalStorageValueByParameter } from '../../class/common';
import { linearizeHierarchy } from '../../class/array';
import Button from '../../newComponents/Button';
import Modal from '../../newComponents/Modal';

const baseUrl = process.env.REACT_APP_BASE_URL;
const $ = require('jquery');

const lang = getTranslationFile();
const chartTitles = [MANAGE_ACCRUALS_STATUSES.ACCURED, MANAGE_ACCRUALS_STATUSES.EXCLUDED, MANAGE_ACCRUALS_STATUSES.UNDEFINED];

/**
 * Manage Accruals Screen in Engine UI
 * @author [Lara Jannoun]
 */
class ManageAccruals extends Component {
    constructor(props) {
    super(props);
        this.state = {
            showEvaluation: false,
            dataProvider: [],
            predictionConfigData: [],
        };
        
        this.fetchAPI = fetchAPI.bind(this);
        this.getCostClassification = getCostClassification.bind(this);
        this.applyAction = this.applyAction.bind(this);
        this.showEvaluation = this.showEvaluation.bind(this);
        this.showPrediction = this.showPrediction.bind(this);
        this.prepareEvaluateData = this.prepareEvaluateData.bind(this);
        this.setChangedStatus = this.setChangedStatus.bind(this);
        this.unfocusAccrual = this.unfocusAccrual.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.setRowData = this.setRowData.bind(this);
        this.onChangeScenario = this.onChangeScenario.bind(this);
        this.setDataProvider = this.setDataProvider.bind(this);
        this.getPredictionConfig = this.getPredictionConfig.bind(this);
        this.getFYCost = this.getFYCost.bind(this);
        this.calculatePeriodPredictedData = this.calculatePeriodPredictedData.bind(this);
        this.fetchNewFYCost = this.fetchNewFYCost.bind(this);

        this.focusAccrRef = null;
        this.includeBtnRef = React.createRef();
        this.excludeBtnRef = React.createRef();
        this.accrueBtnRef = React.createRef();
        this.removeBtnRef = React.createRef();
    }

    prepareEvaluateData(row) {
        let data = this.state.costFYData;
        let costKey = row[ACCRUALS.FIELDS.COSTKEY];
        this.setState({
            rowCostKey:costKey
        })
        let formatted = data.filter(tempRow => tempRow[ACCRUALS.FIELDS.COSTKEY] === costKey)[0];
        let returnedData = [];
        if (!!formatted) {
            for (var e in formatted[ACCRUALS.FIELDS.COST_AMOUNT_ARR]) {
                let fyProjection = formatted[ACCRUALS.FIELDS.PROJECTION_AMOUNT_ARR][e];
                let cost = formatted[ACCRUALS.FIELDS.COST_AMOUNT_ARR][e];
                var object = {
                    [FOCUS_ACCRUALS.FIELDS.PERIOD]: formatted[ACCRUALS.FIELDS.PERIODS][e],
                    [FOCUS_ACCRUALS.FIELDS.FY_PROJECTION]: fyProjection,
                    [FOCUS_ACCRUALS.FIELDS.COST]: cost,
                    [FOCUS_ACCRUALS.FIELDS.VARIANCE]: (fyProjection - cost)/ fyProjection * 100,
                    [FOCUS_ACCRUALS.FIELDS.CALCULATED_ACCRUAL]: fyProjection - cost
                };
                returnedData.push(object);
            }
        }
        return returnedData;
    }

    preparePredictData(row, predictionConfigData=this.state.predictionConfigData, costFYData=this.state.costFYData) {
        let costKey = row[ACCRUALS.FIELDS.COSTKEY];
        let costkeyData = costFYData.filter(tempRow => tempRow[ACCRUALS.FIELDS.COSTKEY] === costKey)[0];
        let configData = predictionConfigData.filter(tempRow => tempRow[FOCUS_ACCRUALS.CONFIG_FIELDS.COSTKEY] === costKey)[0];

        let predictionTableData = [];
        if (!!costkeyData) {
            for (var e in costkeyData[ACCRUALS.FIELDS.COST_AMOUNT_ARR]) {                
                //period config data may not exist if costkey configuration is_by_year is true
                let periodConfigData = configData[FOCUS_ACCRUALS.CONFIG_FIELDS.ACCRUAL_PERIOD_DATA] ? configData[FOCUS_ACCRUALS.CONFIG_FIELDS.ACCRUAL_PERIOD_DATA].filter(periodData => periodData[FOCUS_ACCRUALS.CONFIG_FIELDS.PERIOD_NUMBER] === costkeyData[ACCRUALS.FIELDS.PERIODS][e].slice(4,7))[0] : undefined;
                let isHistorical = configData[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_TYPE] === lang.accruals.prediction_config.options.type.historical.value;
                let predictedData = 0;
                if(isHistorical){
                    predictedData = this.calculatePeriodPredictedData(costkeyData[ACCRUALS.FIELDS.PROJECTION_AMOUNT_ARR][e], costkeyData[ACCRUALS.FIELDS.COST_AMOUNT_ARR][e],
                        periodConfigData ? periodConfigData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC] : configData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC]);
                } else if(periodConfigData){
                    //periodConfigData always exists if not historical, but preventing a type error
                    predictedData = this.calculatePeriodPredictedData(costkeyData[ACCRUALS.FIELDS.PROJECTION_AMOUNT_ARR][e], 
                        costkeyData[ACCRUALS.FIELDS.COST_AMOUNT_ARR][e], periodConfigData[FOCUS_ACCRUALS.CONFIG_FIELDS.AMOUNT], false);
                }
                
                var object = {
                    [FOCUS_ACCRUALS.FIELDS.PERIOD]: costkeyData[ACCRUALS.FIELDS.PERIODS][e],
                    [FOCUS_ACCRUALS.FIELDS.FY_PROJECTION]: costkeyData[ACCRUALS.FIELDS.PROJECTION_AMOUNT_ARR][e],
                    [FOCUS_ACCRUALS.FIELDS.COST]: costkeyData[ACCRUALS.FIELDS.COST_AMOUNT_ARR][e],
                    [FOCUS_ACCRUALS.FIELDS.RATIO_PERC]: predictedData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC],
                    [FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION]: predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION],
                    [FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL]: predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL],
                    [FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE]: predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE]
                };
                predictionTableData.push(object);
            }
        }
        return predictionTableData;
    }

    calculatePeriodPredictedData(projectionAmnt, periodCost, ratioOrAmnt, isHistorical=true) {
        let ratio = 0;
        let predictedFY = 0;
        ratioOrAmnt = ratioOrAmnt || 0;
        if(isHistorical) {
            ratio = getNumericValue(ratioOrAmnt);     //remove unit
            predictedFY = Number(projectionAmnt) * Number(ratio) / 100;   //FY Build = (Reference FY Build) * Ratio %
        } else {
            predictedFY = getNumericValue(ratioOrAmnt);     //just for clarity
            ratio = Number(predictedFY) / Number(projectionAmnt) * 100; //Ratio % = FY Build / Reference FY Build
        }
        
        let calculatedAccr = predictedFY - Number(periodCost);     //Accrual Cost = FY Build - Period Build
        let predictedVariance = (calculatedAccr / predictedFY) * 100;       //Variance (%) = (Calculated Accrual / FY Build) * 100

        return {
            [FOCUS_ACCRUALS.FIELDS.RATIO_PERC]: ratio,
            [FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION]: predictedFY,
            [FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL]: calculatedAccr,
            [FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE]: predictedVariance
        }
    }

    showEvaluation(rowData) {
        var data = this.prepareEvaluateData(rowData);
        this.setState({
            showEvaluation: true,
            rowData: rowData,
            evaluateData: data,
            dataUpdated_id: shortid.generate(),
            originalData: copyObjectValues(this.childRef.tabulator.getData()),
            originalrowData: rowData
        })
        this.props.setIsMainAccrualView(false);
    }

    showPrediction(rowData) {
        var data = this.preparePredictData(rowData);
        this.setState({
            showPrediction: true,
            rowData: rowData,
            predictionData: data,
            dataUpdated_id: shortid.generate(),
            originalData: copyObjectValues(this.childRef.tabulator.getData()),
            originalrowData: rowData
        });

        this.props.toggleYearDisablement(true);
        this.props.setIsMainAccrualView(false);
    }

    unfocusAccrual(_submit) {
        const _this = this;
        if (_submit) {
            let tempState = {
                showEvaluation: false,
                showPrediction: false
            }
            if(this.state.showPrediction) {
                this.updatePredictionConfigData(tempState);
            } else {
                this.setState(tempState, () => {
                  // this was added to refresh table data when clicking submit because after submit we had table with no data
                  _this.childRef.tabulator.setData(_this.childRef.tabulator.getData());
                  _this.setDataProvider(this.childRef.returnDataCountByStatus());
                });
            }
        } else {
            this.setState({
                showEvaluation: false,
                showPrediction: false,
                rowData: this.state.originalrowData,
                originalData:this.state.originalData,
                predictionConfigData: this.state.predictionConfigData_original,
                isChanged:false
            }, function () {
                _this.childRef.tabulator.setData(_this.state.originalData)
                _this.setDataProvider(this.childRef.returnDataCountByStatus());
            });
        }
        _this.props.toggleYearDisablement(false);
        this.props.setIsMainAccrualView(true);
    }

    getUpdatedConfigData() {
        let prediction = copyObjectValues(this.state.predictionConfigData);
        let costkey = this.state.rowData[ACCRUALS.FIELDS.COSTKEY];
        let updatedConfigData = this.focusAccrRef.getUpdatedConfigData();
        prediction.filter(configCostkey=> configCostkey[FOCUS_ACCRUALS.CONFIG_FIELDS.COSTKEY] === costkey).map(configCostkey=>{
            configCostkey[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_TYPE] = updatedConfigData[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_TYPE];
            configCostkey[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_REF_YEAR] = updatedConfigData[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_REF_YEAR];
            configCostkey[FOCUS_ACCRUALS.CONFIG_FIELDS.IS_BY_YEAR] = updatedConfigData[FOCUS_ACCRUALS.CONFIG_FIELDS.IS_BY_YEAR];
            configCostkey[FOCUS_ACCRUALS.CONFIG_FIELDS.RATIO] = updatedConfigData[FOCUS_ACCRUALS.CONFIG_FIELDS.RATIO];
            configCostkey[FOCUS_ACCRUALS.CONFIG_FIELDS.ACCRUAL_PERIOD_DATA] = updatedConfigData[FOCUS_ACCRUALS.CONFIG_FIELDS.ACCRUAL_PERIOD_DATA];
            configCostkey[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
        });

        return prediction;
    }

    /**
     * this function updates the configuration data after a certain costkey has been predicted and changed
     * @param {*} tempState 
     */
    updatePredictionConfigData(tempState={}) {
        let prediction = this.getUpdatedConfigData();

        tempState.predictionConfigData = prediction;
        tempState.predictionConfigData_original = prediction;
        this.setState(tempState);
        this.checkForUnbuiltRefYears(prediction);
    }

    setChosenFiscalYear(option) {
        if(this.childRef) {
            this.childRef.setChosenFiscalYear(option);
        }
        // this.fetchUpdatedTableData(option);
    }

    setDataProvider(data){
        this.setState({ 
            dataProvider: this.fillCounts(chartTitles,data) 
        });
    }

    onChangeScenario(option) {
        this.setState({
            scenario: option,
            predictionConfigData: []    //empty configuration data on change sceanrio to fetch it again for the new one
        },function(){
            this.getCostClassification();
            this.fetchUpdatedTableData();
            if(this.childRef) {
                this.childRef.onChangeScenario(option);
            }
        })
    }

    fetchUpdatedTableData() {
        let callback = this.getFYCost;
        if (this.state.predictionConfigData.length > 0) {
            callback(this.state.predictionConfigData);
        } else {
            this.getPredictionConfig(callback);
        }
    }
    
    getPredictionConfig(callback) {
        if (this.props.fiscalYear) {
            var incompleteYears = this.props.fiscalYears.filter(year=> parseBoolean(year[FISCAL_YEARS.is_complete]) === false);    
            var dataBody = { 
                action: "getPredictionConfig",
                scenario_id: this.props.scenarioId,
                fiscalYear: incompleteYears && incompleteYears.length > 0 ? incompleteYears[0].value : "",
            };
          setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
          fetch(`${baseUrl}${API_URL.DATA_MODELING}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
                .then((response)=>{
                    return response.json();
                }).then((data)=>{
                    if(data) {
                        callback(data.data, true);
                    }
                })
            .catch((error)=>{
            }).then(()=>{
            });
        }
    }
    
    getFYCost(predictionConfigData, fromMount=true) {
        if (this.props.fiscalYear) {
            const _this = this;
            var dataBody = { 
                action: "getFYCost",
                scenario_id: this.props.scenarioId,
                fiscalYear: this.props.fiscalYear ? this.props.fiscalYear.value : "",
                isYearComplete: this.props.fiscalYear ? this.props.fiscalYear[FISCAL_YEARS.is_complete] : "",
                predictionConfigData: predictionConfigData || [],
            };
          setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
          fetch(`${baseUrl}${API_URL.DATA_MODELING}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
                .then((response)=>{
                    return response.json();
                }).then((data)=>{
                    if(data) {
                        let tempState = {
                            costFYData: data.data,
                            predictionConfigData: predictionConfigData || [],
                            dataUpdated_id: shortid.generate()
                        }
                        if(_this.state.showEvaluation){
                            _this.setState({
                                costFYData: data.data
                            })
                            let dataRow = linearizeHierarchy(data.data)
                            let rowDataArray = dataRow.filter(e => {return e.costKey === _this.state.rowCostKey})
                            if(rowDataArray.length > 0){
                                _this.showEvaluation(rowDataArray[0])
                            }
                        }
                        if(fromMount) {
                            tempState.predictionConfigData_original = tempState.predictionConfigData;
                        } else {
                            //update the data so that any unsubmitted changes would not be lost when request is received
                            tempState.predictionData = _this.preparePredictData(_this.state.rowData, predictionConfigData, data.data);
                        }
                        tempState.mappedLines = data.mappedLines;
                        _this.setState(tempState, () => {
                            if(fromMount) {
                                _this.checkForUnbuiltRefYears(predictionConfigData);
                            }
                        });
                    }
                })
            .catch((error)=>{
            }).then(()=>{
            });
        } 
    }

    saveChanges(redirectToBuild){
        const _this = this;
        toggleLoader(true, "saveAccruals");

        var object = {
            profitStackFields:  JSON.stringify(this.childRef.tabulator.getData()),
            deletedItems: JSON.stringify(this.childRef.state.toBeDeleted),
            configData: JSON.stringify(this.state.predictionConfigData)
        };

        var query = {
            action: "saveAccruals",
            scenario_id: this.props.scenarioId,
            prefix: this.props.prefix,
            accrualData: JSON.stringify(object),
            fiscalYear: this.props.fiscalYear ? this.props.fiscalYear.value : "",
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + API_URL.DATA_MODELING, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function (result, response, xhr) {
                result = tryParse(result);
                if(result.data === 'SUCCESS') {
                    _this.setInfoDialogOpen(true, "Your scenario has been updated!");
                    _this.setChangedStatus(false);                    
                } else {
                    _this.setInfoDialogOpen(true, result.ERROR);
                    return; 
                }
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                toggleLoader(false, "saveAccruals");
                if (redirectToBuild) {
                    setTimeout(() => {
                        _this.props.goToBuild();
                    }, 1500);
                }else{
                    // _this.getProfitStackFields();
                }

            }
        });
    }

    fetchNewFYCost() {
        let configData = this.getUpdatedConfigData();
        this.getFYCost(configData, false);
    }

    checkForUnbuiltRefYears(predictionConfigData) {
        const fiscalComplete = !!this.props.fiscalYear ? this.props.fiscalYear[FISCAL_YEARS.is_complete] : false;

        if(!fiscalComplete) {
            let previousYear = (Number(this.props.fiscalYear[FISCAL_YEARS.fiscal_year]) - 1) + "";
            let notBuiltYears = this.props.fiscalYears.filter(year=> parseBoolean(year[FISCAL_YEARS.all_built]) === false).map(year=>{return year[FISCAL_YEARS.fiscal_year];});
            let notBuiltRefYears = [];

            predictionConfigData.map(costKeyConfig=>{
                let tempYear = costKeyConfig[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_REF_YEAR] === lang.accruals.prediction_config.options.ref_fiscal_year.previous.value ?
                    previousYear : costKeyConfig[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_REF_YEAR];

                if(notBuiltYears.includes(tempYear) && !notBuiltRefYears.includes(tempYear)) {
                    notBuiltRefYears.push(tempYear);
                }
            });

            if(notBuiltRefYears.length) {
                this.props.setNotBuiltRefYears(notBuiltRefYears);
            }
        }
    }

    getRowData(data, fromEvaluateRow, row) {
        for (var elt in data) {
            if (data[elt][ACCRUALS.FIELDS.COSTKEY] === fromEvaluateRow["costKey"]) {
                row.push(data[elt]);
            } else if (data[elt][ACCRUALS.FIELDS.CHILDREN] && data[elt][ACCRUALS.FIELDS.CHILDREN].length > 0) {
                this.getRowData(data[elt][ACCRUALS.FIELDS.CHILDREN], fromEvaluateRow, row);
            }
        }
    }

    setRowData(data, fromEvaluateRow) {
        var row = [];
        this.getRowData(data, fromEvaluateRow, row);
        if (row.length > 0) {
            this.setState({
                rowData: row[0]
            })
        }
    }

    applyAction(status, action, fromEvaluateRow) {
        
        var data =  this.childRef.tabulator.getData();
        this.setState({
            isChanged: true,
            // originalData: copyObjectValues(data)
        },function(){
            this.childRef.modifyStatus(status, data, data, action, fromEvaluateRow);
            this.childRef.tabulator.setData(data);
            this.setDataProvider(this.childRef.returnDataCountByStatus());
            if(fromEvaluateRow){
                this.setRowData(data, fromEvaluateRow);
                this.childRef.tabulator.replaceData(data);
            }
            
            this.includeBtnRef.current?.setIsDisabled(true)
            this.excludeBtnRef.current?.setIsDisabled(true)
            this.accrueBtnRef.current?.setIsDisabled(true)
            this.removeBtnRef.current?.setIsDisabled(true)
        });
    }

    setChangedStatus(value) {
        this.setState({
            isChanged: value
        })
    }

    fetchData = (selectedYear, scenarioId) => {
      const _this = this;

      _this.setState({
        selectedYear: selectedYear,
        scenarioId: scenarioId
      }, ()=>{
          _this.fetchUpdatedTableData();
          _this.getCostClassification();
      });
    }

    componentDidUpdate(prevProps) {
      if(prevProps.fiscalYear !== this.props.fiscalYear) {
        this.fetchData(this.props.fiscalYear, this.props.scenarioId);
      }
    }

    
    fillCounts(titles,data){
        if(data){
            var dataProvider = [];
            data.forEach(function(item, key) {
                dataProvider.push({ "type": titles[key], "count": item});
            });
            return dataProvider;
        }
       
    }

    renderTitles(dataProvider){
        if(dataProvider){
            var listCount = dataProvider.map(item =>
                <span key={item.type} className="fs-14 uk-margin-default-right">{item.type + " : "+item.count}</span>
            );
            return  listCount;
        }
    }

    disableSpecificButtons = (status) => {
        let _this = this;
        if (status === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED) {
            //disable exclude and remove accrual
            _this.excludeBtnRef.current?.setIsDisabled(true);
            _this.removeBtnRef.current?.setIsDisabled(true);
        } else if (status === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED) {
            //disable include and accrue
            _this.includeBtnRef.current?.setIsDisabled(true);
            _this.accrueBtnRef.current?.setIsDisabled(true);
        } else if (status === ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED) {
            //disable include and remove accrual
            _this.includeBtnRef.current?.setIsDisabled(true);
            _this.removeBtnRef.current?.setIsDisabled(true);
        }
    }

    disableAllButtons = (disable) => {
        let _this = this;
        _this.includeBtnRef.current?.setIsDisabled(disable);
        _this.removeBtnRef.current?.setIsDisabled(disable);
        _this.accrueBtnRef.current?.setIsDisabled(disable);
        _this.excludeBtnRef.current?.setIsDisabled(disable);
    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }
  
    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }

	render() {
        const _this = this;
        return (
          <div className="dm-parent-container"  style={{height: "100%"}}>
            {this.state.isChanged && !this.state.showEvaluation ? (
              <div className="uk-text-medium red" style={{paddingLeft: "0.8vw"}}>{lang.unsaved_changes}</div>
            ) : (
              ""
            )}
            {_this.state.showEvaluation || _this.state.showPrediction ? (
              <FocusAccrual
                ref={(r) => (_this.focusAccrRef = r)}
                applyAction={_this.applyAction}
                fyCost={_this.state.fyCost}
                isEvaluation={_this.state.showEvaluation}
                costCenter={_this.props.costCenter}
                glCosts={_this.state.glCosts}
                fiscalYear={this.props.fiscalYear}
                costClassification={_this.state.costClassification}
                metricFields={_this.state.metricFields}
                rowData={_this.state.rowData}
                fetchNewFYCost={_this.fetchNewFYCost}
                configValues={
                  _this.state.predictionConfigData.filter(
                    (configCostkey) => configCostkey[FOCUS_ACCRUALS.CONFIG_FIELDS.COSTKEY] === _this.state.rowData[ACCRUALS.FIELDS.COSTKEY]
                  )[0]
                }
                data={_this.state.showEvaluation ? _this.state.evaluateData : _this.state.predictionData}
                dataUpdated_id={_this.state.dataUpdated_id}
                fiscalYears={_this.props.fiscalYears.filter((year) => year[FISCAL_YEARS.is_complete] === true)}
                periods={_this.props.periods}
                calculatePeriodPredictedData={_this.calculatePeriodPredictedData}
                vectorOptions={_this.props.vectorOptions}
              />
            ) : (
              ""
            )}
            <div
              id="manageAccruals"
              style={{height: "100%"}}
              className={(this.state.showEvaluation || this.state.showPrediction ? "uk-hidden " : "uk-display-inline-flex ") + " uk-width-1-1"}
            >
              <div id="manageAccrualsMainTable" className={"uk-background-default"} style={{height: "100%", display: "flex", flexDirection: "column", rowGap: 16}}>
                  <div className="mapping-header uk-flex-between uk-padding-large-left-right" style={{paddingLeft: 2, borderBottom: 0}}>
                    <div className="display-contents align-items-center">
                      <div className="uk-display-inline-flex align-items-center">
                        {this.childRef && this.state.dataProvider ? this.renderTitles(this.state.dataProvider) : ""}
                      </div>
                      <div id="action_buttons_container" className="uk-display-inline-flex">
                        <Button
                          id="exclude_icon"
                          ref={this.excludeBtnRef}
                          label={lang.accruals.actions.exclude}
                          variant={BUTTON_VARIANT.SECONDARY}
                          size={SIZES.DEFAULT}
                          type={BUTTON_TYPE.DEFAULT}
                          title="Exclude"
                          disabled={true}
                          leftIcon={<i className="fa-lg fal fa-file-minus uk-margin-small-right" />}
                          onBtnClick={() => {
                            this.applyAction(ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED);
                          }}
                          className="uk-margin-default-right"
                        />

                        <Button
                          id="include_icon"
                          ref={this.includeBtnRef}
                          label={lang.accruals.actions.include}
                          variant={BUTTON_VARIANT.SECONDARY}
                          size={SIZES.DEFAULT}
                          type={BUTTON_TYPE.DEFAULT}
                          title="Include"
                          disabled={true}
                          leftIcon={<i className="fa-lg fal fa-file-plus uk-margin-small-right" />}
                          className="uk-margin-default-right"
                          onBtnClick={() => {
                            this.applyAction(ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED, ACCRUALS.ACTIONS.INCLUDE);
                          }}
                        />
                        <Button
                          id="accrue_icon"
                          ref={this.accrueBtnRef}
                          label={lang.accruals.actions.accrue}
                          variant={BUTTON_VARIANT.SECONDARY}
                          size={SIZES.DEFAULT}
                          type={BUTTON_TYPE.DEFAULT}
                          title="Accrue"
                          disabled={true}
                          className="uk-margin-default-right"
                          leftIcon={<i className="fa-lg fal fa-money-check-edit-alt uk-margin-small-right" />}
                          onBtnClick={() => {
                            this.applyAction(ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED);
                          }}
                        />
                        <Button
                          id="remove_accrual_icon"
                          ref={this.removeBtnRef}
                          label={lang.accruals.actions.remove_accrual}
                          variant={BUTTON_VARIANT.SECONDARY}
                          size={SIZES.DEFAULT}
                          disabled={true}
                          type={BUTTON_TYPE.DEFAULT}
                          title="Remove Accrual"
                          leftIcon={<i className="fa-lg fal fa-ban uk-margin-small-right" />}
                          onBtnClick={() => {
                            this.applyAction(ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED, ACCRUALS.ACTIONS.REMOVE_ACCRUAL);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="manageAccruals uk-border"  style={{height: "95%"}}>
                    <DefineAccrualsTable
                      ref={(el) => (this.childRef = el)}
                      scenarioId={this.props.scenarioId}
                      fiscalYear={this.props.fiscalYear}
                      periods={this.props.periods}
                      fyThreshold={this.props.FY_COST_Threshold}
                      rThreshold={this.props.R_Squared_Threshold}
                      showEvaluation={this.showEvaluation}
                      setDataProvider={this.setDataProvider}
                      disableAllButtons={this.disableAllButtons}
                      disableSpecificButtons={this.disableSpecificButtons}
                      showPrediction={this.showPrediction}
                      dataUpdated_id={this.state.dataUpdated_id}
                      costFYData={this.state.costFYData}
                      setChangedStatus={this.setChangedStatus}
                      mappedLines={this.state.mappedLines}
                    />
                  </div>
             
              </div>
              {/* <div id="accountsChart" className={"height-75vh accounts-chart-parent"}>
                        <div className="mapping-header justify-content-between">
                            <span className="uk-text-bold uk-text-xmedium uk-margin-medium-left uk-display-flex uk-flex-middle">{lang.manage_accruals_chart_title}
                                <i className="fs-12 fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={lang.manage_accruals_chart_info}></i>
                            </span>
                        </div>
                        <div className="accounts-chart uk-padding-small-left-right uk-background-default">
                            <div id="chartdiv"></div>
                        </div>
                    </div> */}
            </div>

            <Modal
              id={"info-dialog"}
              openDialog={this.state.openInfoDialog}
              bodyContent={() => <h4>{this.state.infoMsg}</h4>}
              dialogActions={this.openInfoDialogActions}
              closeClick={() => this.setInfoDialogOpen(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
          </div>
        );
	}

    }

export default ManageAccruals;