import React, { Component } from 'react';
import Popup from 'react-popup';
import { logout, toggleLoader, setLocalStorageValueByParameter } from '../class/common.js';
import {
    ALL_WIDGETS, API_URL,
    BUTTON_TYPE,
    BUTTON_VARIANT,
    DIALOG_SIZE,
    FormatTypes, FY_VALUES, PERIOD_STATUS_ENUM, SCENARIO_FIELDS, SCENARIO_STATUS, SIZES, STAGE_FIELDS, STAGE_TITLES, STAGING_SECTIONS, STAGING_SECTIONS_TITLES
} from '../class/constants.js';
import { formatValReact } from "../class/format";
import { alertAndLogError } from '../class/jqueries';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../class/networkUtils';
import { copyObjectValues, getSectionExists, getTranslationFile } from '../class/utils.js';
import { ToggleValue } from '../form/elements';
import { lang } from '../language/messages_en';
import StageDataTable from '../tables/StageDataTable';
import CalculatedColumns from './stageData/CalculatedColumns.js';
import { stageCCNewArch, stageExclusionNewArch, stageMetricsNewArch } from './stageData/CommonRequests';
import ExcludedLines from './stageData/ExcludedLines';
import ProfitStackMapping from './stageData/ProfitStackMapping.js';
import ProfitStackMappingNew from './stageData/ProfitStackMappingNew';
import VectorMapping from './stageData/VectorMapping.js';
import ViewMoreInvoiceLine from './stageData/ViewMoreInvoiceLine';

import Button from '../newComponents/Button.js';
import Modal from '../newComponents/Modal.js';

const $ = require('jquery');


const baseUrl = process.env.REACT_APP_BASE_URL;
const path = API_URL.DATA_MODELING;
const MESSAGES = getTranslationFile();

var transactionDataColumns = [
	{
		title: STAGE_TITLES.TIME_PERIOD_TITLE,
		field: STAGE_FIELDS.TIME_PERIOD_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 100
	},
	{
		title: STAGE_TITLES.STATUS_TITLE,
		field: STAGE_FIELDS.STATUS_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
    {
		title: STAGE_TITLES.ORDERS_TITLE,
		field: STAGE_FIELDS.ORDERS_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.NUMERIC,
        minWidth: 200
    },
	{
		title: STAGE_TITLES.LINES_TITLE,
		field: STAGE_FIELDS.LINES_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.NUMERIC,
        minWidth: 200
    },
    {
		title: STAGE_TITLES.REVENUE_TITLE,
		field: STAGE_FIELDS.REVENUE_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.AMOUNT,
        minWidth: 200
	},
	{
		title: STAGE_TITLES.COGS_TITLE,
		field: STAGE_FIELDS.COGS_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.AMOUNT,
        minWidth: 200
	},
	// {
	// 	title: STAGE_TITLES.GROSS_MARGIN_TITLE,
	// 	field: STAGE_FIELDS.GROSS_MARGIN_FIELD,
	// 	sorter: "string", 
    //     sorterParams: { alignEmptyValues: "bottom" },
    //     format_type: FormatTypes.AMOUNT,
    //     minWidth: 200
	// },
	{
		title: STAGE_TITLES.START_DATE_TITLE,
		field: STAGE_FIELDS.START_DATE_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        formatter: "date",
        formatterParams: { format: "MM/dd/yyyy" },
        minWidth: 200,
        visible: false
    },
	{
		title: STAGE_TITLES.END_DATE_TITLE,
		field: STAGE_FIELDS.END_DATE_FIELD,
        sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        formatter: "date",
        formatterParams: { format: "MM/dd/yyyy" },
        minWidth: 200,
        visible: false
    },
    {
        title: STAGE_TITLES.PROGRESS_TITLE,
        field: STAGE_FIELDS.PROGRESS_FIELD,
		tooltip: false,
        headerSort: false,
        formatter: "progress",
        hozAlign: "center",
        minWidth: 200
    }
];

const stagingVectorColumns = [
    {
		title: STAGE_TITLES.TIME_PERIOD_TITLE,
		field: STAGE_FIELDS.TIME_PERIOD_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200,
    },
    {
    
		title: STAGE_TITLES.STATUS_TITLE,
		field: STAGE_FIELDS.VECTOR_STATUS,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
    {
    
		title: STAGE_TITLES.STAGED_VECTORS,
		field: STAGE_FIELDS.STAGED,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.NUMERIC,
        minWidth: 200
    },
    {
    
		title: STAGE_TITLES.NOT_STAGED_VECTORS,
		field: STAGE_FIELDS.NOT_STAGED,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.NUMERIC,
        minWidth: 200
    },
    {
    
		title: STAGE_TITLES.INVALID_VECTORS,
		field: STAGE_FIELDS.INVALID,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.NUMERIC,
        minWidth: 200
    },
	{
		title: STAGE_TITLES.TOTAL,
		field: STAGE_FIELDS.TOTAL,
		// sorter: "string", 
        // sorterParams: { alignEmptyValues: "bottom" },
        // format_type: FormatTypes.TEXT,
        minWidth: 200,
        visible: false
    },
    {
        title: STAGE_TITLES.PROGRESS_TITLE,
        field: STAGE_FIELDS.PROGRESS_FIELD,
		tooltip: false,
		headerSort: false,
        formatter: "progress",
        hozAlign: "center",
        minWidth: 200
    }
];

const stagingProfitStackColumns = [
    {
        title: STAGE_TITLES.TIME_PERIOD_TITLE,
		field: STAGE_FIELDS.TIME_PERIOD_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 100
    },
    {
        title: STAGE_TITLES.STATUS_TITLE,
        field: STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD,
		sorter: "string", 
        sorterParams: { alignEmptyValues:"bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
    {
        title: STAGE_TITLES.COSTCENTER_STATUS_TITLE,
        field: STAGE_FIELDS.COST_CENTER_STATUS,
		sorter: "string", 
        sorterParams: { alignEmptyValues:"bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
    {
        title: STAGE_TITLES.PROGRESS_TITLE,
        field: STAGE_FIELDS.PROGRESS_FIELD,
		tooltip: false,
		headerSort: false,
        formatter: "progress",
        hozAlign: "center",
        minWidth: 200
    }
];

const metricsColumns = [
    {
        title: STAGE_TITLES.TIME_PERIOD_TITLE,
        field: STAGE_FIELDS.TIME_PERIOD_FIELD,
        sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
    {
        title: STAGE_TITLES.STATUS_TITLE,
        field: STAGE_FIELDS.METRIC_STATUS,
        sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
    {
    
		title: STAGE_TITLES.STAGED_METRICS,
		field: STAGE_FIELDS.STAGED,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
    {
    
		title: STAGE_TITLES.NOT_STAGED_METRICS,
		field: STAGE_FIELDS.NOT_STAGED,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
    {
    
		title: STAGE_TITLES.INVALID_METRICS,
		field: STAGE_FIELDS.INVALID,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200
    },
	{
		title: STAGE_TITLES.TOTAL,
		field: STAGE_FIELDS.TOTAL,
		sorter: "string", 
        sorterParams: { alignEmptyValues: "bottom" },
        format_type: FormatTypes.TEXT,
        minWidth: 200,
        visible: false
    },
    {
        title: STAGE_TITLES.PROGRESS_TITLE,
        field: STAGE_FIELDS.PROGRESS_FIELD,
		tooltip: false,
		headerSort: false,
        formatter: "progress",
        hozAlign: "center",
        minWidth: 200
    }
]

class StageData extends Component {
    constructor(props) {
		super(props);
		this.state = {
            stagingReport: ALL_WIDGETS.FIELDS.STAGE,
            isProfitStackMappingAllowed: true,
            stagedVectors: "-",
            notStagedVectors: "-",
            invalidVectors:"-",
            periods: props.periods
        };

        this.fetchAPI = fetchAPI.bind(this);
        this.getStagingData =  this.getStagingData.bind(this);
        this.onChangeScenario = this.onChangeScenario.bind(this);
        this.stageProfitStack = this.stageProfitStack.bind(this);
        this.setStagingReport = this.setStagingReport.bind(this);
        this.getPeriodSummaryData = this.getPeriodSummaryData.bind(this);
        this.logout = logout.bind(this);
        this.stageMetrics = this.stageMetrics.bind(this);
        this.goBackToStaging = this.goBackToStaging.bind(this);
        this.getInvoiceLineDetail = this.getInvoiceLineDetail.bind(this);
        this.stageVectors = this.stageVectors.bind(this); 
        this.getFileCensusSectionsData = this.getFileCensusSectionsData.bind(this);
    }



    getStagingData(fromPSMSave, scenario=this.props.scenarioId) {
        const obj = this;
        toggleLoader(true, "getStagingData");
        $(".stage-table #stage_button").each((key, e) => $(e).addClass("uk-button-disabled"));

        const query = {
            action: "getStagingData",
            scenario_id: scenario,
            periodsData: obj.props.periodList
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                if(response.status === 403) {
                    //this.logout();
                }
                return response.json()
            }).then((data) => {
                if (data.error) {
                  obj.props.openInfoDialog(data.error.message)
                } else {
                    let allData = data.data;
                    let periods = allData.map(row=>{
                        return {
                            label: row[STAGE_FIELDS.TIME_PERIOD_FIELD],
                            value: row[STAGE_FIELDS.TIME_PERIOD_FIELD],
                            period_name: row[STAGE_FIELDS.TIME_PERIOD_FIELD],
                            client_period_id: row[STAGE_FIELDS.PERIOD_ID],
                            count: row[STAGE_FIELDS.COUNT]
                        }
                    });

                    let profitStackPeriodCountData = this.getPeriodSummaryData(allData, STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD);
                    let metricPeriodCountData = this.getPeriodSummaryData(allData, STAGE_FIELDS.METRIC_STATUS);
                    let transactionPeriodCountData = this.getPeriodSummaryData(allData, STAGE_FIELDS.STATUS_FIELD);
                    let vectorPeriodCountData = this.getPeriodSummaryData(allData, STAGE_FIELDS.VECTOR_STATUS);

                    this.setState({
                        stagingData: allData,
                        stagingDataStatus: copyObjectValues(allData),
                        stagingProfitStackData: copyObjectValues(allData),
                        profitStackPeriodCountData: profitStackPeriodCountData,
                        metricPeriodCountData: metricPeriodCountData,
                        vectorPeriodCountData: vectorPeriodCountData,
                        isProfitStackMappingAllowed: data.isTransitionCombinationGenerated,
                        transactionPeriodCountData: transactionPeriodCountData,
                        isInvoiceLineQueryAdded: data.isInvoiceLineQueryAdded,
                        periods: periods
                    }, function() {
                        // this.props.setPeriodOptions(periods);
                        toggleLoader(false, "getStagingData");
                        if(fromPSMSave){
                            this.launchToast();
                        }
                        obj.getVectorStatus();
                        obj.getMetricStatus();
                    });
                }
            }).catch((error)=>{
                alertAndLogError(error);
                toggleLoader(false, "getStagingData");
            });
    }
    
    getVectorStatus() {
        toggleLoader(true, "getVectorStatus");
        $(".stage-table #stage_button").each((key, e) => $(e).addClass("uk-button-disabled"));
        var obj = this;
        var query = {
            action: "getVectorStatus",
            scenario_id: this.props.scenarioId,
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                if(response.status === 403) {
                    //this.logout();
                }
                return response.json()
            }).then((data) => {
                if (data.error) {
                  obj.props.openInfoDialog(data.error.message)
                } else {
                    var stagedVectors = data.data.filter(e => e[STAGE_FIELDS.VECTOR_STATUS] === 'STAGED').length;
                    var notStagedVectors = data.data.filter(e => e[STAGE_FIELDS.VECTOR_STATUS] === 'NOT_STAGED').length;
                    var invalidVectors = data.data.filter(e => e[STAGE_FIELDS.VECTOR_STATUS] === 'INVALID').length;
                    for (var elt in data.data) {
                        data.data[elt]["invoiceline_staging_status"] = obj.state.stagingData.filter(e=>e["period_name"] === data.data[elt]["period_name"]).length > 0 ? obj.state.stagingData.filter(e=>e["period_name"] === data.data[elt]["period_name"])[0]["invoiceline_staging_status"] : "";
                    }
                    this.setState({
                        vectorsData: data.data,
                        stagedVectors: stagedVectors,
                        notStagedVectors: notStagedVectors,
                        invalidVectors: invalidVectors
                    }, function() {
                        toggleLoader(false, "getVectorStatus");
                        obj.forceUpdate();
                    });
                }
            }).catch((error)=>{
                alertAndLogError(error);
                toggleLoader(false, "getVectorStatus");
            });
    }


    getMetricStatus() {
        toggleLoader(true, "getMetricStatus");
        $(".stage-table #stage_button").each((key, e) => $(e).addClass("uk-button-disabled"));
        var obj = this;
        var query = {
            action: "getMetricStatus",
            scenario_id: this.props.scenarioId,
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                if(response.status === 403) {
                    //this.logout();
                }
                return response.json()
            }).then((data) => {
                if (data.error) {
                  obj.props.openInfoDialog(data.error.message)
                } else {
                    var stagedMetrics = data.data.filter(e => e[STAGE_FIELDS.METRIC_STATUS] === 'STAGED').length;
                    var notStagedMetrics = data.data.filter(e => e[STAGE_FIELDS.METRIC_STATUS] === 'NOT_STAGED').length;
                    var invalidMetrics = data.data.filter(e => e[STAGE_FIELDS.METRIC_STATUS] === 'INVALID').length;
                    for (var elt in data.data) {
                        data.data[elt]["validForMetricStaging"] = obj.state.stagingData.filter(e=>e["period_name"] === data.data[elt]["period_name"])[0]["validForMetricStaging"];
                    }
                    this.setState({
                        metricsData: data.data,
                        stagedMetrics: stagedMetrics,
                        notStagedMetrics: notStagedMetrics,
                        invalidMetrics: invalidMetrics
                    }, function() {
                        toggleLoader(false, "getMetricStatus");
                    });
                }
            }).catch((error)=>{
                alertAndLogError(error);
                toggleLoader(false, "getMetricStatus");
            });
    }

    getPeriodSummaryData(allData, periodStatusColumnName) {
        var returnArray = [];
        var periodStatusCount = {};

        var stagedCount = 0;
        var notStagedCount = 0;
        var invalidCount = 0;
        for (var row in allData) {
            let status = allData[row][periodStatusColumnName] === undefined ? PERIOD_STATUS_ENUM.INVALID.toLowerCase() : allData[row][periodStatusColumnName];
            if (status.toLowerCase() === PERIOD_STATUS_ENUM.STAGED.toLowerCase()) {
                stagedCount++;
            } else if ([PERIOD_STATUS_ENUM.NOT_STAGED.toLowerCase(),PERIOD_STATUS_ENUM.NOT_STAGED_NEW].includes(status.toLowerCase())) {  
                //because on API it is "NOT_STAGED", on UI "NOT STAGED"
                notStagedCount++;
            } else if (status.toLowerCase() === PERIOD_STATUS_ENUM.INVALID.toLowerCase()) {
                invalidCount++;
            } 
        }

        periodStatusCount.staged_periods = stagedCount;
        periodStatusCount.not_staged_periods = notStagedCount;
        periodStatusCount.invalid_periods = invalidCount;

        returnArray.push(periodStatusCount);   //because tables only read arrays of data
        return returnArray;
    }

    concatenateTotalsData(data, totals) {
        for(var row in data) {
            let periodName = data[row].period_name;
            data[row][STAGE_FIELDS.STATUS_FIELD] = PERIOD_STATUS_ENUM[data[row][STAGE_FIELDS.STATUS_FIELD]];
            data[row][STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD] = PERIOD_STATUS_ENUM[data[row][STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD]];
            data[row][STAGE_FIELDS.METRIC_STATUS] = PERIOD_STATUS_ENUM[data[row][STAGE_FIELDS.METRIC_STATUS]];
            data[row][STAGE_FIELDS.VECTOR_STATUS] = PERIOD_STATUS_ENUM[data[row][STAGE_FIELDS.VECTOR_STATUS]];
            
            //get totals of this period
            for(var totalRow in totals) {
                totalRow = totals[totalRow];
                if(totalRow.time_periods === periodName) {
                    //append row of totals to the main row if period is matching
                    data[row] = {...data[row], ... totalRow};
                    break;
                }
            }
        }

        return data;
    }

    

    stageProfitStack(isNewArchitecture=false) {
        var allSelectedPeriods = [];
        var obj = this;
        $('#'+ STAGING_SECTIONS.PROFIT_STACK_MAPPING +"_table").find('input[type=checkbox]:checked').closest(".tabulator-row").each(function(index, el) {
            allSelectedPeriods.push($(el).find("[tabulator-field="+ STAGE_FIELDS.TIME_PERIOD_FIELD +"]").attr('title'));
        });

        if (allSelectedPeriods.length === 0) {
          obj.props.openInfoDialog(MESSAGES.stage_no_period_selected)
          return;
        }

        toggleLoader(true, "stageProfitStack");
        $(".loader-waiting-message").show();
        var query = {
            action: "stageProfitStack",
            scenario_id: this.props.scenarioId,
            periodsData: allSelectedPeriods
        };
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        $.ajax({
            url: isNewArchitecture ? baseUrl + API_URL.ASSIGN_COST : baseUrl + path, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success:function(result, response, xhr){
                if(result !== "") {
                    result = JSON.parse(result);
                    if(result.ERROR) {
                      obj.props.openInfoDialog(result.ERROR)
                    } else {
                      obj.props.openInfoDialog("Selected periods staged. Reloading data...")
                    }
                }
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                toggleLoader(false, "stageProfitStack");
                obj.getStagingData();
                $(".loader-waiting-message").hide();
            }
        });
    }

    stageMetrics() {
        var obj = this;
        var allSelectedPeriods = [];
        $('#'+ STAGING_SECTIONS.METRICS_MAPPING +"_table").find("input[type='checkbox']:checked").each( function() {
            var selectedPeriod = $(this).parent().next().text();
            allSelectedPeriods.push(selectedPeriod);
        });

        if (allSelectedPeriods.length === 0) {
            obj.props.openInfoDialog(MESSAGES.stage_no_period_selected)
            return;
        }

        toggleLoader(true, "stageMetrics");
        $(".loader-waiting-message").show();

        var query = {
            action: "stageMetrics",
            scenario_id: this.props.scenarioId,
            periodsData: allSelectedPeriods,
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        $.ajax({
            url: baseUrl+path, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function (result, response, xhr) {
                result = JSON.parse(result);
                if(result.ERROR) {
                  obj.props.openInfoDialog(result.ERROR)
                }else {
                  obj.props.openInfoDialog("Selected periods staged. Reloading data...")
                } 
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                obj.getStagingData();
                toggleLoader(false, "stageMetrics");
                $(".loader-waiting-message").hide();
            }
        });
    }

    stageVectors() {
        var obj = this;
        var allSelectedPeriods = [];
        $('#'+ STAGING_SECTIONS.VECTOR_MAPPING +"_table").find("input[type='checkbox']:checked").each( function() {
            var selectedPeriod = $(this).parent().next().text();
            allSelectedPeriods.push(selectedPeriod);
        });
        toggleLoader(true, "stageVectors");
        $(".loader-waiting-message").show();
        var query = {
            action: "stageVectors",
            scenario_id: this.props.scenarioId,
            selectedStagingPeriods: allSelectedPeriods
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        $.ajax({
            url: baseUrl+path, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function (result, response, xhr) {
                result = JSON.parse(result);

                if (result.result === "SUCCESS") {
                  obj.props.openInfoDialog("Selected periods staged. Reloading data...")
                } else if(result.ERROR && result.ERROR.includes("Duplicate")) {
                  var strings = [];
                  strings.push("-You can’t use a Vector name that was already used, please use a new one.");
                  strings.push("-Selected periods ("+ allSelectedPeriods.join(", ") +") have not been staged.");
                  obj.props.openInfoDialog(strings.join(" \n "))
                }else if(result.ERROR) {
                  obj.props.openInfoDialog("Something went wrong! Selected periods ("+ allSelectedPeriods.join(", ") +") have not been staged.")
                }
                return;
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                obj.getStagingData();
                toggleLoader(false, "stageVectors");
                $(".loader-waiting-message").hide();
            }
        });
    }

    stageVectorsNewArch() {
        const obj = this;
        let allSelectedPeriods = [];
        $('#'+ STAGING_SECTIONS.VECTOR_MAPPING +"_table").find("input[type='checkbox']:checked").each( function() {
            var selectedPeriod = $(this).parent().next().text();
            allSelectedPeriods.push(selectedPeriod);
        });
        toggleLoader(true, "stageVectorsNewArch");
        $(".loader-waiting-message").show();
        let query = {
            action: "stageVectorsNewArch",
            scenario_id: this.props.scenarioId,
            selectedStagingPeriods: allSelectedPeriods
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        $.ajax({
            url: baseUrl+path,
            async: true,
            type: 'POST',
            crossDomain:true,
            xhrFields: { withCredentials: true },
            data:JSON.stringify(query),
            success: function (result, response, xhr) {
                $(".loader-waiting-message").hide();
                toggleLoader(false, "stageVectorsNewArch");

                result = JSON.parse(result);

                if (result.result === "SUCCESS") {
                  obj.props.openInfoDialog("Selected periods staged. Reloading data...")
                } else if(result.ERROR && result.ERROR.includes("Duplicate")) {
                  let strings = [];
                  strings.push("-You can’t use a Vector name that was already used, please use a new one.");
                  strings.push("-Selected periods ("+ allSelectedPeriods.join(", ") +") have not been staged.");
                  obj.props.openInfoDialog(strings.join(" \n "))
                }else if(result.ERROR) {
                  obj.props.openInfoDialog("Something went wrong! Selected periods ("+ allSelectedPeriods.join(", ") +") have not been staged.")
                }

            },
            error: function(error) {
                $(".loader-waiting-message").hide();
                toggleLoader(false, "stageVectorsNewArch");
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                obj.getStagingData();
                toggleLoader(false, "stageVectorsNewArch");
                $(".loader-waiting-message").hide();
            }
        });
    }

    onChangeScenario(option) {
        // this.getScenarioType((scenarioType)=>{
        //     this.props.getAllFileTypeColumns(scenarioType);
        // }); // commented because we ha the scenario type in sccenarioObject
      
        this.getStagingData(undefined, option);
        this.props.fetchUsedAttributes(option);
        var elements = document.getElementsByClassName('stage-button-div');
        if (this.props.scenarioStatus !== SCENARIO_STATUS.SANDBOX) {
            for (var i=0; i < elements.length; i++) {
                elements[i].setAttribute('uk-tooltip', MESSAGES.stage_published_scenario);
            }
        } else{
            for (var i=0; i < elements.length; i++) {
                elements[i].removeAttribute('uk-tooltip', MESSAGES.stage_published_scenario);
            }
        }
        
        
    }

    disableHeaderButtons = (disable) => {
      this.props.setSavedDisabled(disable);
      this.props.setBackDisabled(disable);

    }

    setStagingReport(newStagingReport, reload) {
        let _this = this;
          _this.props.setStagingReport(newStagingReport, reload);
          if (reload) {
            if (newStagingReport === ALL_WIDGETS.FIELDS.STAGE) {
                _this.getStagingData();
            }
          }
    }

    goBackToStaging(reload, hasChanges) {
        if(hasChanges) {
            this.props.setConfirmCancelDialogOpen(true)
        } else {
            this.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, reload);
        }
    }

    onBackClick = (report) => {
      switch (report) {
        case STAGING_SECTIONS.MAPPING_TIE_OFF:
          this.excludedLinesRef?.discardChangesAndGoBack();
          break;
        case STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW:
          this.profitStackReff?.discardChangesAndGoBack();
          break;
        case STAGING_SECTIONS.VECTOR_MAPPING:
            this.vectorRef.onBackClick();
            break;
        case STAGING_SECTIONS.METRICS_MAPPING:
            this.profitStackRef.onBackClick();
            break;
        case STAGING_SECTIONS.CALCULATED_COLUMNS:
            this.calculatedColumnRef.discardChangesAndGoBack();
            break;
        default:
          break;
      }
    }


    getInvoiceLineDetail(timeperiod) {
        toggleLoader(true, "getInvoiceLineDetail");

        var obj = this;
        var timePeriodVal = timeperiod ? timeperiod : this.props.selectedPeriod.value;
        var query = {
            action: "getInvoiceLineDetail",
            scenario_id: this.props.scenarioId,
            timePeriod: timePeriodVal
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        $.ajax({
            url: baseUrl + API_URL.DATA_MODELING, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function(response, result, xhr){
                response = JSON.parse(response);
                if (response.data) {
                    obj.setState({
                        invoiceLineDetails: response.data
                    });
                }
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function() {
                toggleLoader(false, "getInvoiceLineDetail");
            }
        });
    }

    setChosenPeriod(option) {
        if (this.vectorRef) {
            this.vectorRef.setChosenTimePeriod(option);
        } 
        if (this.profitStackRef) {
            this.profitStackRef.setChosenTimePeriod(option);
        }
        if (this.excludedLinesRef) {
            this.excludedLinesRef.setChosenTimePeriod(option);
        }
    }

    
    getFileCensusSectionsData() {
        var obj = this;
        toggleLoader(true, 'getFileCensusSectionsData');
        var query = {
            action: "getFileCensusSectionsData"
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${API_URL.DATA_MODELING}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then((response)=>{
            if(response.status === 403) {
                this.logout();
            }
            return response.json()})
        .then((data)=>{
            if (data) {
                obj.setState({
                    fileCensusData: data.data
                }, function () {
                    obj.forceUpdate();
                });
            }
        }).catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{
            toggleLoader(false, 'getFileCensusSectionsData');
        });
    }

    componentDidUpdate() {
        if(this.props.userAllowedSections) {
            this.areStageButtonsAccessible = getSectionExists(this.props.userAllowedSections, ALL_WIDGETS.STAGE_BUTTONS);
        }
    }

    componentDidMount() {
        //hiding the period drop down list in header
        $("#periodList-header").addClass("uk-hidden");
        // $("#header-buttons").addClass("uk-hidden");
        $("#header-next").unbind('click');
        // $("#header-back").unbind('click');
        //enabling the scenario drop down list
        $("#scenarioList-header").removeClass("disabled");
        // this.props.getScenarios();

        // this.props.getClientCostTerms();
        this.checkCalculatedColumn();
        this.getStagingData();
        // this.props.fetchUsedAttributes();
    }

    getScenarioType=(callback)=> {
        var _this = this;
        var query = {
            action: "getScenarioType",
            scenario_id: this.props.scenarioId,
        }
        
        var onThenCallback = (data)=>{
            if(data && data.scenarioType){
                _this.setState({
                    scenarioType: data.scenarioType
                },()=>{
                    if(callback && typeof callback === "function"){
                        callback(_this.state.scenarioType);
                    }
                })
            }
        };
      
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getScenarioType",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
           
        }
        this.fetchAPI(fetchOptions);
    }

    checkCalculatedColumn=()=> {
        var _this = this;
        var query = {
            action: "checkCalculatedColumn",
            scenario_id: this.props.scenarioId,
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${API_URL.DATA_MODELING}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();
                }
                return response.json()})
            .then((data)=>{
                   if(data.data){
                       this.setState({
                            checkCalculatedColumn:data.data
                       })
                   }
            }).catch((error)=>{
                alertAndLogError(error);
            });
    }

    inputOnclick(e){
        $("#input-required").removeClass("uk-form-danger");
        if (e) {
            var value = $(e.currentTarget).val();
            this.checkForExistingName(value);
        }
    }

    checkForExistingName(name) {
        if (name) {
            var message = "";
            var data = this.tabulator.getData();
            var found = data.filter(e=>e[SCENARIO_FIELDS.NAME].toLowerCase() === name.toLowerCase());
            if (found && found.length > 0) {
                message = MESSAGES.invalid_scenario_name.replace('{x}',found[0][SCENARIO_FIELDS.NAME]);
            } else{
                message="";
            }
            this.isNameValid = message;
            this.forceUpdate();
        }
    
    }
    launchToast() {
        $("#toastPSLTableStage").addClass("show");
        setTimeout(function(){
            $("#toastPSLTableStage").removeClass("show");
        }, 4000);
    }

     /**
     * new API request for stage Exclusion for new Arch
     * @returns 
     */
      stageExclusionNewArch = () => {
        $(".loader-waiting-message").show();
        let allSelectedPeriods = [];
        $('#'+ STAGING_SECTIONS.TRANSACTION_MAPPING +"_table").find("input[type='checkbox']:checked").each( function() {
            let selectedPeriod = $(this).parent().next().text();
            allSelectedPeriods.push(selectedPeriod);
        });
        if (allSelectedPeriods.length === 0) {
          obj.props.openInfoDialog(MESSAGES.stage_no_period_selected)
          $(".loader-waiting-message").hide();
          return;
        }
        const callback = (data) => {
            if (data) {
                if (data.data && data.data.length) {
                    console.log(data);
                    obj.props.openInfoDialog("Selected periods staged. Reloading data...")
                    this.getStagingData();
                }
            }
            $(".loader-waiting-message").hide();
        }
        stageExclusionNewArch(this.props.scenarioId, allSelectedPeriods, callback);
    }

    /**
     * new API request for stage Metric for new Arch
     * @returns 
     */
    stageMetricsNewArch = () => {

        $(".loader-waiting-message").show();
        let allSelectedPeriods = [];
        $('#'+ STAGING_SECTIONS.METRICS_MAPPING +"_table").find("input[type='checkbox']:checked").each( function() {
            let selectedPeriod = $(this).parent().next().text();
            allSelectedPeriods.push(selectedPeriod);
        });

        if (allSelectedPeriods.length === 0) {
          obj.props.openInfoDialog(MESSAGES.stage_no_period_selected)
          $(".loader-waiting-message").hide();
          return;
        }

        const callback = (data) => {
            if (data) {
                if (data.data && data.data.length) {
                    console.log(data);
                    obj.props.openInfoDialog("Selected periods staged. Reloading data...")
                    this.getStagingData();
                }
            }
            $(".loader-waiting-message").hide();
        }
        stageMetricsNewArch(this.props.scenarioId, allSelectedPeriods, callback);
    }

    stageCCNewArch = () => {

        $(".loader-waiting-message").show();
        let allSelectedPeriods = [];
        $('#'+ STAGING_SECTIONS.TRANSACTION_MAPPING +"_table").find("input[type='checkbox']:checked").each( function() {
            let selectedPeriod = $(this).parent().next().text();
            allSelectedPeriods.push(selectedPeriod);
        });

        if (allSelectedPeriods.length === 0) {
          obj.props.openInfoDialog(MESSAGES.stage_no_period_selected)
          $(".loader-waiting-message").hide();
          return;
        }

        const callback = (data) => {
            if (data) {
                if (data.data && data.data.length) {
                    console.log(data);
                    obj.props.openInfoDialog("Selected periods staged. Reloading data...")
                    this.getStagingData();
                }
            }
            $(".loader-waiting-message").hide();
        }
        stageCCNewArch(this.props.scenarioId, allSelectedPeriods, false, callback);
    }
    
    loadExclusionData = () => {
        this.excludedLinesRef.getExclusionData()
    }
    
    getVectorsEntityCount = () => {
        this.vectorRef.getVectorsEntityCount();
    }

    getMetricsData = () => {
        this.profitStackRef.getMetricsData(null, "getMetrics");
    }

    loadCalculatedColumnsTotalAmounts = () => {
        this.calculatedColumnRef.loadCalculatedColumnsTotalAmounts();
    }

    render() {
        var psMapProgress = 0;
        var psStageProgress = "0%";
        var ilMapProgress = this.state.isInvoiceLineQueryAdded ? 1 : 0;
        var ilStageProgress = "0%";
        var mMapProgress = 0;
        var mStageProgress = "0%";
        var vStageProgress = "0%";
        let obj = this;
        if(this.state.stagingProfitStackData) {
            psMapProgress = this.state.stagingProfitStackData.filter(el => el[STAGE_FIELDS.ASSIGNED_COMBINATION_PERC_FIELD] > 0).length;
            var psStageProgressValue = this.state.profitStackPeriodCountData[0].staged_periods * 100 / this.state.stagingProfitStackData.length;
            psStageProgress = formatValReact(psStageProgressValue, FormatTypes.PERCENTAGE);
            var psStageProgressTitle =  $(psStageProgress.props.dangerouslySetInnerHTML.__html).text()
            
            mMapProgress = this.state.stagingProfitStackData.filter(el => el.metricscount > 0).length;
            var mStageProgressValue = this.state.metricPeriodCountData[0].staged_periods * 100 / this.state.stagingProfitStackData.length;
            mStageProgress = formatValReact(mStageProgressValue, FormatTypes.PERCENTAGE);
            var mStageProgressTitle =  $(mStageProgress.props.dangerouslySetInnerHTML.__html).text()
        }
        
        if (this.state.stagingData) {
            var ilStageProgressValue = this.state.transactionPeriodCountData[0].staged_periods * 100 / this.state.stagingData.length;
            ilStageProgress = formatValReact(ilStageProgressValue, FormatTypes.PERCENTAGE);
            var ilStageProgressTitle =  $(ilStageProgress.props.dangerouslySetInnerHTML.__html).text()
            var vStageProgressValue = this.state.vectorPeriodCountData[0].staged_periods * 100 / this.state.stagingData.length;
            vStageProgress = formatValReact(vStageProgressValue, FormatTypes.PERCENTAGE);
            var vStageProgressTitle =  $(vStageProgress.props.dangerouslySetInnerHTML.__html).text()
        }

        //unused code, not deleted to test for regressions
        // var psMappingStageProgress = "0%";
        var ilMappingStageProgress = this.state.isInvoiceLineQueryAdded ? "100%" : 0;
        // var mMappingStageProgress = "0%";

        // if(this.state.stagingProfitStackData) {
        //     var AssignedCombinations = this.state.psCombinations.assComb;
        //     var ExcludedCombinations = this.state.psCombinations.exComb;
        //     var UnassignedCombinations = this.state.psCombinations.unAssComb;

        //     var totalAssignedExcludedCombinations = AssignedCombinations + ExcludedCombinations + UnassignedCombinations;
        //     var psMappingStageProgressValue = Math.round((AssignedCombinations + ExcludedCombinations) * 100 / totalAssignedExcludedCombinations);
        //     psMappingStageProgress = formatValHTML(psMappingStageProgressValue, FormatTypes.PERCENTAGE);

        //     mMappingStageProgress = this.state.stagingProfitStackData.filter(el => el.metricscount > 0).length ? formatValHTML(100, FormatTypes.PERCENTAGE) : formatValHTML(0, FormatTypes.PERCENTAGE);
        // }

        var topStatuses = [];
        if (this.state.stagingProfitStackData) {
            topStatuses.push({ name: STAGING_SECTIONS_TITLES.PROFIT_STACK, value: this.state.profitStackPeriodCountData[0].staged_periods / this.state.stagingProfitStackData.length });
            topStatuses.push({ name: STAGING_SECTIONS_TITLES.VECTORS, value: this.state.vectorPeriodCountData[0].staged_periods / this.state.stagingData.length });
            topStatuses.push({ name: STAGING_SECTIONS_TITLES.TRANSACTION, value: this.state.transactionPeriodCountData[0].staged_periods / this.state.stagingData.length });
            topStatuses.push({ name: STAGING_SECTIONS_TITLES.METRICS, value: this.state.metricPeriodCountData[0].staged_periods / this.state.stagingProfitStackData.length });
        }

        var object = [];
        for (var topStatus in topStatuses) {
            var status =  topStatuses[topStatus].value === 0 ? "fa-times-circle" : topStatuses[topStatus].value < 1 ? "fal fa-minus-circle orangeText" : "fa-check-circle" ;
            object.push(<div key={topStatuses[topStatus].name}>
                <i className={"fal fa-3x "+status}></i>
                <div className="width-105 fs-14 uk-margin-xsmall-top" key={topStatuses[topStatus].name}>{topStatuses[topStatus].name}</div>
            </div>);
        }
        return(
            <div style={{height:"100%"}}>
                
                <div id="toastPSLTableStage" className={"toast toast-success"}>
                    <div id="desc">
                      <i className={"fa-lg fas fa-check-circle  uk-margin-small-right greenText"}aria-hidden="true" />
                      {lang.saved}
                    </div>
                </div>
                <div className="dm-parent-container stage-container" style={{height:"100%"}}>
                    { this.props.stagingReport === STAGING_SECTIONS.VECTOR_MAPPING ?
                        <VectorMapping ref={el => this.vectorRef = el}
                            setChosenPeriod={this.props.setChosenPeriod}
                            setStagingReport={this.setStagingReport}
                            selectedPeriod={this.props.selectedPeriod}
                            projectId={this.props.project_id}
                            scenarioId={this.props.scenarioId}
                            scenarioStatus={this.props.scenarioStatus}
                            periodDetails={this.props.periodDetails}
                            periodList={this.props.periodList}
                            periods={this.state.periods}
                            goBackToStaging={this.goBackToStaging}
                            data={this.state.vectorsData}
                            userAllowedSections={this.props.userAllowedSections}
                            dataColumns={this.props.dataColumns}
                            usedAttributes={this.props.usedAttrs_vec}
                            showScenarioPopUp={this.props.showScenarioPopUp}
                            allTypeColumns={this.props.allTypeColumns}
                            setSaveDialogOpen={this.props.openInfoDialog}
                            disableHeaderButtons={this.disableHeaderButtons}
                        />
                    : "" }
                  
                     { this.props.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW ?
                        <ProfitStackMappingNew ref={el=>this.profitStackReff = el}
                            setChosenPeriod={this.props.setChosenPeriod}
                            showScenarioPopUp={this.props.showScenarioPopUp}
                            setStagingReport={this.setStagingReport}
                            setNotSavedWarning={this.props.setNotSavedWarning}
                            stagingReport={STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW}
                            onChangeScenario={this.onChangeScenario}
                            scenarioId={this.props.scenarioId}
                            scenarioStatus={this.props.scenarioStatus}
                            periods={this.state.periods}
                            periodDetails={this.props.periodDetails}
                            periodList={this.props.periodList}
                            costCenter={this.props.costCenter}
                            industry={this.props.industry}
                            selectedPeriod={this.props.selectedPeriod}
                            clientName={this.props.clientName}
                            allAncillaryColumns={this.props.allAncillaryColumns}
                            clientCostTerms= {this.props.clientCostTerms}
                            userAllowedSections={this.props.userAllowedSections}
                            vectors={this.state.vectorsData}
                            allTypeColumns={this.props.allTypeColumns}
                            getSelectedPeriods={this.props.getSelectedPeriods}
                            fetchUsedAttributes = {this.props.fetchUsedAttributes}
                            isChanged = {this.props.isChanged}
                            rollBack={this.props.rollBack}
                            scenarioObject ={this.props.scenarioObject}
                            user = {this.props.user} fromStage = {true}
                            launchToast = {this.launchToast}
                            onDiscardClick={this.props.onDiscardClick}
                            setActionToken={this.props.setActionToken}
                            stagingDataStatus={this.state.stagingDataStatus}
                            // setChosenPeriods = {this.props.setChosenPeriods}
                            getSelectedYears = {this.props.getSelectedYears}
                            getStagingData = {this.getStagingData}
                            setShowBanner={this.props.setShowBanner}
                            setBannerConfig={this.props.setBannerConfig}
                            disableHeaderButtons={this.disableHeaderButtons}
                        />
                    : "" }
                    { this.props.stagingReport === STAGING_SECTIONS.METRICS_MAPPING ?
                        <ProfitStackMapping ref={el=>this.profitStackRef = el}
                            setStagingReport={this.setStagingReport}
                            showScenarioPopUp={this.props.showScenarioPopUp}
                            costCenter={this.props.costCenter}
                            setChosenPeriod={this.props.setChosenPeriod}
                            stagingReport={STAGING_SECTIONS.METRICS_MAPPING}
                            onChangeScenario={this.onChangeScenario}
                            scenarioId={this.props.scenarioId}
                            scenarioStatus={this.props.scenarioStatus}
                            periods={this.state.periods}
                            clientId={this.props.clientId}
                            periodDetails={this.props.periodDetails}
                            periodList={this.props.periodList}
                            industry={this.props.industry}
                            selectedPeriod={this.props.selectedPeriod}
                            clientName={this.props.clientName}
                            allAncillaryColumns={this.props.allAncillaryColumns}
                            clientCostTerms= {this.props.clientCostTerms}
                            userAllowedSections={this.props.userAllowedSections}
                            vectors={this.state.vectorsData}
                            vectorOptions = {this.props.vectorOptions}
                            allTypeColumns={this.props.allTypeColumns}
                            scenarioType = {obj.state.scenarioType}
                            goBackToStaging={this.goBackToStaging}
                            disableHeaderButtons={this.disableHeaderButtons}
                        />
                    : "" }
                    { this.props.stagingReport === STAGING_SECTIONS.CALCULATED_COLUMNS ?
                        <CalculatedColumns ref={el=>this.calculatedColumnRef = el}
                            scenarioId={this.props.scenarioId}
                            scenarioStatus={this.props.scenarioStatus}
                            showScenarioPopUp={this.props.showScenarioPopUp}
                            goBackToStaging={this.goBackToStaging}
                            selectedPeriod={this.props.selectedPeriod}
                            setStagingReport={this.setStagingReport}
                            stagingReport={STAGING_SECTIONS.CALCULATED_COLUMNS}
                            dataColumns={this.props.dataColumns}
                            usedAttributes={this.props.usedAttrs_calc}
                            checkCalculatedColumn={this.state.checkCalculatedColumn}
                            checkCalculatedColumnFunc ={this.checkCalculatedColumn}
                            setSaveDialogOpen={this.props.openInfoDialog}
                            disableHeaderButtons={this.disableHeaderButtons}
                            setSavedDisabled={this.props.setSavedDisabled}
                            setNotSavedWarning={this.props.setNotSavedWarning}
                        />
                    : "" }
                    { this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF ?
                        <ExcludedLines ref={el => this.excludedLinesRef = el}
                            setChosenPeriod={this.props.setChosenPeriod}
                            showScenarioPopUp={this.props.showScenarioPopUp}
                            selectedPeriod={this.props.selectedPeriod}
                            setStagingReport={this.setStagingReport}
                            stagingReport={STAGING_SECTIONS.MAPPING_TIE_OFF}
                            onChangeScenario={this.onChangeScenario}
                            scenarioId={this.props.scenarioId}
                            scenarioStatus={this.props.scenarioStatus}
                            periods={this.state.periods}
                            periodDetails={this.props.periodDetails}
                            periodList={this.props.periodList}
                            dataColumns={this.props.dataColumns}
                            goBackToStaging={this.goBackToStaging}
                            clientName={this.props.clientName}
                            setSaveDialogOpen={this.props.openInfoDialog}
                        />
                    : "" }
                    { this.state.stagingReport === STAGING_SECTIONS.VIEW_MORE ?
                        <ViewMoreInvoiceLine
                            getInvoiceLineDetail={this.getInvoiceLineDetail}
                            selectedPeriod={this.props.selectedPeriod}
                            setStagingReport={this.setStagingReport}
                            stagingReport={STAGING_SECTIONS.VIEW_MORE}
                            onChangeScenario={this.onChangeScenario}
                            scenarioId={this.props.scenarioId}
                            scenarioStatus={this.props.scenarioStatus}
                            periodDetails={this.props.periodDetails}
                            periodList={this.props.periodList}
                            periods={this.state.periods}
                            invoiceLineDetails={this.state.invoiceLineDetails}/>
                    : "" }
                    {this.props.stagingReport === ALL_WIDGETS.FIELDS.STAGE ?
                        <>
                    <div className="uk-text-center">
                        <div id="stage-status-top" className="status-top uk-display-inline-flex">
                            {object}                                        
                        </div>
                    </div>
                    <div id="dm-staging" className={obj.state.stagingReport === ALL_WIDGETS.FIELDS.STAGE ? "stage-tables-container" : "uk-hidden"}>
                        {/* Start of Profit Stack Mapping */}
                        <div className="stage-table-layout" id="staging-profit-stack-table">
                            <div className="stage-header-table d-flex justify-content-between">
                                <div className="d-inline-flex align-items-center">
                                    <h5 className="uk-text-bold uk-float-left uk-margin-remove">{STAGING_SECTIONS_TITLES.PROFIT_STACK}
                                    <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip="title: This section is for mapping General Ledger combinations to Profit Stack lines. <br> Stage will be disabled until all expected General Ledger Files are uploaded and at least one Profit Stack line is mapped.; pos: bottom-left"/></h5>
                                    <div className="progress uk-float-left uk-width-small" title={psStageProgressTitle}>
                                        <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: psStageProgressValue + "%" }}>
                                            <div className="progress-bar-div">{psStageProgress}</div>
                                        </div>
                                    </div>
                                    <div className="period-stage-div">
                                        <p className="uk-margin-xmedium-right fs-14">Staged Periods: {this.state.profitStackPeriodCountData ? formatValReact(this.state.profitStackPeriodCountData[0][STAGE_FIELDS.STAGED_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                        <p className="uk-margin-xmedium-right fs-14">Not Staged Periods: {this.state.profitStackPeriodCountData ? formatValReact(this.state.profitStackPeriodCountData[0][STAGE_FIELDS.NOT_STAGED_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                        <p className="uk-margin-xmedium-right fs-14">Invalid Periods: {this.state.profitStackPeriodCountData ? formatValReact(this.state.profitStackPeriodCountData[0][STAGE_FIELDS.INVALID_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC)  : ""}</p>
                                    </div>
                                </div>
                                {/* <div className="uk-float-right align-items-center d-flex">
                                    <button uk-tooltip={"title: "+ MESSAGES.pss_mapping_title +"; pos: bottom-left"} type="button" id="stage_mapping_button" className="uk-button-secondary disabled" onClick={()=>this.setStagingReport(STAGING_SECTIONS.PROFIT_STACK_MAPPING)}>
                                        <i className="fal fa-tasks-alt uk-padding-xsmall-right"/>Mapping
                                    </button>
                                </div> */}
                                <div className="uk-float-right align-items-center d-flex">
                                    <Button
                                        ref={el => this.pssMappingNewRef = el}
                                        id="stage_mapping_button" 
                                        label={"Mapping"}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        disabled={false}
                                        leftIcon={<i className={"fal fa-tasks-alt"} />}
                                        uk-tooltip={"title: "+ MESSAGES.pss_mapping_title +"; pos: bottom-left"}
                                        onBtnClick={()=>this.setStagingReport(STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW)}
                                    /> 
                                </div>
                            </div>
                            <div className="stage-table uk-position-relative">
                                <div className={psMapProgress === 0 ? "overlay" : "overlay uk-hidden"}>
                                    <div className="message">Please complete mapping to be able to stage periods.</div>
                                </div> 
                                <StageDataTable ref={r => this.pssTableRef = r}  id={STAGING_SECTIONS.PROFIT_STACK_MAPPING + "_table"} 
                                 tableData={this.state.stagingProfitStackData} tableColumns={stagingProfitStackColumns}
                                 showCheckBox={true} scenarioStatus={this.props.scenarioStatus} chosenCostCenter={this.props.costCenter} 
                                 costCenterStateData={this.state.costCenterStateData} periodList={this.props.periodList} 
                                 userAllowedSections={this.props.userAllowedSections}/>
                                <div className="stage-button-div uk-display-flex justify-content-end uk-padding-xxsmall-top-bottom uk-padding-large-right">
                                    {this.areStageButtonsAccessible && 
                                    <Button 
                                            label={"5 - Stage Profit Stack"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}                   
                                            onBtnClick={()=>this.stageProfitStack(true)}
                                        />}
                                </div>
                            </div>
                        </div>
                        {/* End of Profit Stack Mapping */}
                        {/* Start of vector mapping */}
                        <div className="stage-table-layout" id="staging-vectors-table">
                            <div className="stage-header-table d-flex align-items-center justify-content-between">
                                <div className="d-inline-flex align-items-center">
                                    <h5 className="uk-text-bold uk-float-left uk-margin-remove">{STAGING_SECTIONS_TITLES.VECTORS}
                                    <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={MESSAGES.vector_table_title}/></h5>
                                    <div className="progress uk-float-left uk-width-small" title={vStageProgressTitle}>
                                        <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: vStageProgressValue + "%" }}>
                                            <div className="progress-bar-div">{vStageProgress}</div>
                                        </div>
                                    </div>
                                    <div className="period-stage-div">
                                        <p className="uk-margin-xmedium-right fs-14">Total Vectors: {this.state.vectorsData  && this.state.vectorsData[0] ? formatValReact(this.state.vectorsData[0][STAGE_FIELDS.TOTAL], FormatTypes.NUMERIC) : ""}</p>
                                        <p className="uk-margin-xmedium-right fs-14">Staged Periods: {this.state.vectorPeriodCountData ? formatValReact(this.state.vectorPeriodCountData[0][STAGE_FIELDS.STAGED_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                        <p className="uk-margin-xmedium-right fs-14">Not Staged Periods: {this.state.vectorPeriodCountData ? formatValReact(this.state.vectorPeriodCountData[0][STAGE_FIELDS.NOT_STAGED_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                        <p className="uk-margin-xmedium-right fs-14">Invalid Periods: {this.state.vectorPeriodCountData ? formatValReact(this.state.vectorPeriodCountData[0][STAGE_FIELDS.INVALID_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC)  : ""}</p>

                                    </div>
                                </div>
                                <div className="uk-float-right align-items-center d-flex">
                                    <Button 
                                    ref={el => this.vectorMappingRef = el}
                                        id="stage_mapping_button" 
                                        label={"Mapping"}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        disabled={false}
                                        leftIcon={<i className={"fal fa-tasks-alt"} />}
                                        onBtnClick={()=>this.setStagingReport(STAGING_SECTIONS.VECTOR_MAPPING)}
                                    /> 
                                    <div className="uk-float-right uk-line-height-xmedium" title={ilMappingStageProgress}>
                                    </div>
                                </div>
                            </div>
                            <div className="stage-table uk-position-relative">
                                <StageDataTable id={STAGING_SECTIONS.VECTOR_MAPPING + "_table"} stagedVectors={this.state.stagedVectors} invalidVectors={this.state.invalidVectors} notStagedVectors={this.state.notStagedVectors} tableData={this.state.vectorsData} tableColumns={stagingVectorColumns} table_type={STAGING_SECTIONS.VECTOR_MAPPING} VECTOR_MAPPING_SECTION={STAGING_SECTIONS.VECTOR_MAPPING} showCheckBox={true} scenarioStatus={this.props.scenarioStatus}/>
                                <div className="stage-button-div uk-display-flex justify-content-end uk-padding-xxsmall-top-bottom uk-padding-large-right">
                                    {this.areStageButtonsAccessible && 
                                    <Button 
                                        label={"3 - Stage Vectors"}
                                        variant={BUTTON_VARIANT.PRIMARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}                   
                                        onBtnClick={()=>this.stageVectorsNewArch()}
                                    />}
                                </div>
                            </div>
                        </div>
                        {/* End of vectors mapping */}
                        {/* Start of Transaction Data */}
                        <div className="stage-table-layout" id="staging-invoice-line-table">
                            <div className="stage-header-table d-flex justify-content-between align-items-center">
                                <div className="d-inline-flex align-items-center">
                                    <h5 className="uk-text-bold uk-float-left uk-margin-remove">{STAGING_SECTIONS_TITLES.TRANSACTION}
                                    <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer " uk-tooltip={MESSAGES.invoiceLine_table_title}/></h5>
                                    <div className="progress uk-float-left uk-width-small" title={ilStageProgressTitle}>
                                        <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: ilStageProgressValue + "%" }}>
                                            <div className="progress-bar-div">{ilStageProgress}</div>
                                        </div>
                                    </div>
                                    <div className="period-stage-div">
                                        <p className="uk-margin-xmedium-right fs-14">Staged Periods: {this.state.transactionPeriodCountData ? formatValReact(this.state.transactionPeriodCountData[0][STAGE_FIELDS.STAGED_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                        <p className="uk-margin-xmedium-right fs-14">Not Staged Periods: {this.state.transactionPeriodCountData ? formatValReact(this.state.transactionPeriodCountData[0][STAGE_FIELDS.NOT_STAGED_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                        <p className="uk-margin-xmedium-right fs-14">Invalid Periods: {this.state.transactionPeriodCountData ? formatValReact(this.state.transactionPeriodCountData[0][STAGE_FIELDS.INVALID_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                    </div>
                                </div>
                                <div className="uk-display-flex">
                                    <Button 
                                        id="stage_invoiceLine_CC_button"
                                        label={MESSAGES.calculated_columns}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className="uk-margin-default-right"
                                        leftIcon={<i className={"fal fa-money-check-edit-alt"} />}
                                        onBtnClick={() => this.setStagingReport(STAGING_SECTIONS.CALCULATED_COLUMNS)}
                                    /> 
                                    <Button 
                                        id="stage_invoiceLine_EL_button"
                                        label={MESSAGES.mapping_tie_off}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className="uk-margin-default-right"
                                        leftIcon={<i className={"fal fa-tasks-alt"} />}
                                        onBtnClick={() => this.setStagingReport(STAGING_SECTIONS.MAPPING_TIE_OFF)}
                                    /> 
                                    <Button 
                                       id="stage_invoiceLine_RC_button"
                                        label={"Rename Columns"}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className="uk-margin-default-right cursorNotAllowed"
                                        disabled={true}
                                        leftIcon={<i className={"fal fa-money-check-edit"} />}
                                    /> 
                                </div> 
                            </div>
                            <div className="stage-table uk-position-relative">
                                <div className={ilMapProgress === 0 ? "overlay" : "overlay uk-hidden"}>
                                    <div className="message">Please upload Transaction Data files to be able to stage periods.</div>
                                </div>                                
                                <StageDataTable id={STAGING_SECTIONS.TRANSACTION_MAPPING + "_table"} tableData={this.state.stagingData} tableColumns={transactionDataColumns} table_type={STAGING_SECTIONS.TRANSACTION_MAPPING} TRANSACTION_MAPPING_SECTION={STAGING_SECTIONS.TRANSACTION_MAPPING} showCheckBox={true} setStagingReport={this.setStagingReport} getInvoiceLineDetail={this.getInvoiceLineDetail} setChosenPeriod={this.props.setChosenPeriod} scenarioStatus={this.props.scenarioStatus}/>
                                <div className="stage-button-div uk-display-flex justify-content-end uk-padding-xxsmall-top-bottom uk-padding-large-right">
                                    {this.areStageButtonsAccessible && 
                                    <Button 
                                        id="stage_button_new_arch3"
                                        label={"1 - Stage Exclusions"}
                                        variant={BUTTON_VARIANT.PRIMARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}  
                                        className="stageExclusionsNewArch"                 
                                        onBtnClick={()=>this.stageExclusionNewArch()}
                                    />
                                }
                                    {this.areStageButtonsAccessible && 
                                    <Button 
                                        id="stage_button_new_arch"
                                        label={"2 - Stage Calculated Columns"}
                                        variant={BUTTON_VARIANT.PRIMARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}                
                                        onBtnClick={()=>this.stageCCNewArch()}
                                    />
                                    }
                                </div>
                            </div>
                        </div>
                        {/* End of Transaction Data */}
                        {/* Start of Metrics Mapping */}
                        <div className="stage-table-layout" id="staging-profit-stack-table">
                            <div className="">
                                <div className="stage-header-table d-flex justify-content-between">
                                    <div className="d-inline-flex align-items-center">
                                        <h5 className="uk-text-bold uk-float-left uk-margin-remove">{STAGING_SECTIONS_TITLES.METRICS}
                                            <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={MESSAGES.metrics_table_title}/>
                                        </h5>
                                        <div className="progress uk-float-left uk-width-small" title={mStageProgressTitle}>
                                            <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: mStageProgressValue + "%" }}>
                                                <div className="progress-bar-div">{mStageProgress}</div>
                                            </div>
                                        </div>
                                        <div className="period-stage-div">
                                            <p className="uk-margin-xmedium-right fs-14">Total Metrics: {this.state.metricsData  && this.state.metricsData[0] ? formatValReact(this.state.metricsData[0][STAGE_FIELDS.TOTAL], FormatTypes.NUMERIC) : ""}</p>
                                            <p className="uk-margin-xmedium-right fs-14">Staged Periods: {this.state.metricPeriodCountData ? formatValReact(this.state.metricPeriodCountData[0][STAGE_FIELDS.STAGED_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                            <p className="uk-margin-xmedium-right fs-14">Not Staged Periods: {this.state.metricPeriodCountData ? formatValReact(this.state.metricPeriodCountData[0][STAGE_FIELDS.NOT_STAGED_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                            <p className="uk-margin-xmedium-right fs-14">Invalid Periods: {this.state.metricPeriodCountData ? formatValReact(this.state.metricPeriodCountData[0][STAGE_FIELDS.INVALID_PERIODS_COUNT_FIELD], FormatTypes.NUMERIC) : ""}</p>
                                        </div>
                                    </div>
                                    <div className="uk-float-right align-items-center d-flex">
                                        <Button 
                                            ref={el => this.metricsMappingRef = el}
                                            id="stage_mapping_button" 
                                            label={"Mapping"}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            uk-tooltip={MESSAGES.metrics_mapping_title}
                                            disabled={false}
                                            leftIcon={<i className={"fal fa-tasks-alt"} />}
                                            onBtnClick={() => this.setStagingReport(STAGING_SECTIONS.METRICS_MAPPING)}
                                        /> 
                                    </div> 
                                </div>
                                    <div className="stage-table uk-position-relative">
                                        <div className={mMapProgress === 0 ? "overlay" : "overlay uk-hidden"}>
                                            <div className="message">Please add some metrics to be able to stage
                                                periods.
                                            </div>
                                        </div>
                                        <StageDataTable id={STAGING_SECTIONS.METRICS_MAPPING + "_table"}
                                                        tableData={this.state.metricsData} tableColumns={metricsColumns}
                                                        showCheckBox={true} scenarioStatus={this.props.scenarioStatus}/>
                                        <div
                                            className="stage-button-div uk-display-flex justify-content-end uk-padding-xxsmall-top-bottom uk-padding-large-right">
                                            {this.areStageButtonsAccessible &&
                                                <Button
                                                    id="stage_new_button"
                                                    label={"4 - Stage Metrics"}
                                                    variant={BUTTON_VARIANT.PRIMARY}
                                                    size={SIZES.DEFAULT}
                                                    type={BUTTON_TYPE.DEFAULT}
                                                    className="uk-margin-small-left"
                                                    onBtnClick={() => this.stageMetricsNewArch()}
                                                />}</div>
                                    </div>
                            </div>
                        </div>
                        {/* End of Metrics Mapping */}
                    </div>
                        </>:""}
                </div>
            </div>
		);

	}

}

export default StageData;