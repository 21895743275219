import React, { Component } from 'react';
import { alertAndLogError, validateVectors } from '../../class/jqueries.js';
import { cleanUpTabulatorColumns, logout, toggleLoader, setLocalStorageValueByParameter} from '../../class/common.js'
import { VECTOR_STAGING_ATTRIBUTES, FormatTypes, API_URL, DM_SET_REPORT_ACTIONS, PS_MAPPING, CONFIGURE_SECTIONS, VECTOR_MAPPING, ALL_WIDGETS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE, MENU_ITEM } from '../../class/constants.js';
import { copyObjectValues, getSectionExists, getTranslationFile } from '../../class/utils.js';
import Tabulator from "tabulator-tables"; //import Tabulator library
import ReactTable from "react-table";
import "react-table/react-table.css";
import '../../styles/dataModeling.css';
import '../../styles/configureVectors.css';
import { formatValHTML, formatValString } from "../../class/format";
import { convertPxToViewport, convertViewportToPx } from '../../class/formatting';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../../class/networkUtils';

import Button from '../../newComponents/Button.js';
import Modal from '../../newComponents/Modal';
import { updateColumnProfiles, updateCustomGroups, updateProfiles } from '../../actions/manageColumnsActions.js';
import { updateTiers } from '../../actions/scenariosActions.js';
import { createDropdown } from '../../newComponents/DropdownTabulator.js';

const $ = require('jquery');

const baseUrl = process.env.REACT_APP_BASE_URL;
const MESSAGES = getTranslationFile();
const path = API_URL.DATA_MODELING;
const lang = getTranslationFile();
const toBeReplaced = /[^A-Za-z0-9_]/g; // handle what to remove from vectors machine names when working with tabulator

// var dataParent = null;
// var customerGroupParent  = null;
// var productGroupParent  = null;
// var operationsGroupParent  = null;
// var originalDataParent = null;
// var genOriginalDataParent = null;
const generate_by_options = [{value:"Key",label:"Key"},{value:"Name",label:"Name"},{value:"Number",label:"Number"}]
const _sandbox = "SANDBOX";
const _modified = "modified";
var VECTORS_MAPPING_COLUMNS = [
    {   
        field: VECTOR_STAGING_ATTRIBUTES.HANDLE_ROW,
        rowHandle: true, 
        formatter: "handle", 
        headerSort: false,
        title: VECTOR_STAGING_ATTRIBUTES.TITLES.ORDER,
         frozen:true, 
         width: convertViewportToPx(100),
       
    },
    {
        title: VECTOR_STAGING_ATTRIBUTES.IS_EDITED,
        field:VECTOR_STAGING_ATTRIBUTES.IS_EDITED,
        headerSort: false,
        visible: false
    },
    {   
        field:  VECTOR_STAGING_ATTRIBUTES.NAME,
        title:  VECTOR_STAGING_ATTRIBUTES.TITLES.VECTORS,
        format_type: FormatTypes.TEXT,
        headerSort: false,
        dontFilter: true,
        minWidth: convertViewportToPx(310),
    },
    {
        title: VECTOR_STAGING_ATTRIBUTES.TITLES.GROUP,
        field: VECTOR_STAGING_ATTRIBUTES.GROUP,
        format_type: FormatTypes.TEXT,
        headerSort: false,
        dontFilter: true
    },
    {
        title: VECTOR_STAGING_ATTRIBUTES.TITLES.MAX_ENTITY_COUNT,
        field: VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT,
        format_type: FormatTypes.TEXT,
        headerSort: false,
        dontFilter: true,
        tooltip: false
    },
    
    {
        title: VECTOR_STAGING_ATTRIBUTES.TITLES.SHOW_VECTOR,
        field:  VECTOR_STAGING_ATTRIBUTES.VISIBILITY,
        dontFilter: true,
        headerSort: false,
        tooltip: false
    },
    {
        title: VECTOR_STAGING_ATTRIBUTES.TITLES.GENERATE_QUADS,
        field:  VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS,
        dontFilter: true,
        headerSort: false,
        tooltip: false
    },
    {
        title: VECTOR_STAGING_ATTRIBUTES.TITLES.GENERATE_BY,
        field:  VECTOR_STAGING_ATTRIBUTES.GENERATE_BY,
        dontFilter: true,
        headerSort: false,
        tooltip: false
    },
    {
        title: VECTOR_STAGING_ATTRIBUTES.TITLES.DEFAULT_VECTOR,
        field:  VECTOR_STAGING_ATTRIBUTES.IS_DEFAULT_GROUP_VECTOR,
        
        dontFilter: true,
        headerSort: false,
        visible:false,
        tooltip: false
    },
    {
        title:  VECTOR_STAGING_ATTRIBUTES.STAGING_STATUS,
        field:  VECTOR_STAGING_ATTRIBUTES.STAGING_STATUS,
        headerSort: false,
        dontFilter: true,
        visible: false
    },
    {
        title: VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER,
        field:VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER,
        format_type: FormatTypes.NUMERIC,
        headerSort: false,
        visible: false
    },
];

const vectorGroups = [{value:"Customer"} ,{value:"Product"} ,{value:"Operations"}];

/**
 * configure Vectors Screen in Engine UI
 * @author [Sarah Farjallah]
 */
class ConfigureVector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: "",
            data: [],
            vectorsList: [],
            defaultVector: "",
            info: false,
            vectorKeys : [],
            vectorCounts : [],
            lengthCount : [],
            vectorRevenue : [],
            gridData : [],
            defaultPageSize: 10,
            operationsGroup : [],
            customerGroup : [],
            productGroup : [],
            originalData : [],
            values: copyObjectValues(vectorGroups),
        };

        this.showDetailsTables = this.showDetailsTables.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.getVectorDetail = this.getVectorDetail.bind(this);
        this.onChangeGroup = this.onChangeGroup.bind(this);
        this.updateVectorGroup = this.updateVectorGroup.bind(this);
        this.saveTable = this.saveTable.bind(this);
        this.getVectors = this.getVectors.bind(this);
        this.updateVectors = this.updateVectors.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.onTabulatorRenderComplete = this.onTabulatorRenderComplete.bind(this);
        this.logout = logout.bind(this);
        this.addTooltipTitleFormatter = this.addTooltipTitleFormatter.bind(this);
        this.onChangeScenario = this.onChangeScenario.bind(this);
        this.goToNextReport = this.goToNextReport.bind(this);
        this.fetchAPI = fetchAPI.bind(this);
    }

    onChangeScenario(option) {
        this.props.resetMultipleRequestsData(["getCostFunction", "getVectorList", "getQuadrantConfiguration", "getProfitStackLines"]);
        this.getVectorList();
        
    }

    goToNextReport(){
        this.props.setReport(DM_SET_REPORT_ACTIONS.NEXT, true);
    }

    getVectorList() {
        let _this = this;
        var query = {
            action: "getVectorList",
            scenario_id: this.props.scenarioId,
            selectedPeriods: this.props.periods.filter(e=>e.is_active === "ACTIVE").map(e=>e.value)
        }

        let onThenCallback = (data) => {
            if(data && data.data) {   
                let thresholds = {high_threshold:data.high_threshold_configure_vectors,low_threshold:data.low_threshold_configure_vectors}  
                let editedVectors = this.enableSave(data.data);        
                _this.fillVectorListInStates(data.data,thresholds,editedVectors,()=>{
                    _this.getVectorListFetched = false;
                    _this.props.setTabsData("getVectorList", copyObjectValues(_this.state.dataParent));
                    toggleLoader(false,"getVectorList");
                });
                toggleLoader(false, 'onChangeScenario');
            } else if(data.ERROR) {
              _this.setInfoDialogOpen(true, "There's something wrong with your query.");
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getVectorList",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.showCustomizedLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        
        _this.fetchAPI(fetchOptions);
        _this.getVectorListFetched = true;
    }
    

    onChange(e, cell,type) {
        let _this = this;
        if(e.currentTarget.value === 'on'){
            cell.setValue(true); 
        } else {
            cell.setValue(false); 
        }
        let generateQuadsId = '#'+VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS + "_" +cell?.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME].replace(toBeReplaced, '_');
        let generateById = '#'+VECTOR_STAGING_ATTRIBUTES.GENERATE_BY + "_" +cell?.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME].replace(toBeReplaced, '_');
        if(type === VECTOR_STAGING_ATTRIBUTES.VISIBILITY){
            if(e.currentTarget.value !== 'on'){
                $(generateQuadsId).prop("disabled",true);
                $(generateQuadsId).parent().css('opacity','0.2');
                $(generateQuadsId).siblings('span').addClass("disabled");
                cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] = false;    
                $(generateById).prop("disabled",true);
                $(generateById).css('opacity','0.4');
                $(generateById).addClass("disabled");
            } else {
                if(_this.props.scenarioStatus === _sandbox && !_this.state.disableGenerateSegments){
                    $(generateQuadsId).prop("disabled",false);
                    $(generateQuadsId).parent().css('opacity','1');
                    $(generateQuadsId).siblings('span').removeClass("disabled");
                }
            }
            if(_this.props.scenarioStatus === _sandbox){
                cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] = false;
            }
        } else if(type === VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS){
            if(e.currentTarget.value !== 'on'){
                $(generateById).prop("disabled",true);
                $(generateById).css('opacity','0.4');
                $(generateById).addClass("disabled");      
                cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] = false;          
            } else {
                $(generateById).prop("disabled",false);
                $(generateById).css('opacity','1');
                $(generateById).removeClass("disabled"); 
                cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] = true;    
            }
            cell.getRow().getData()[_modified] = true;   
            
        }
        this.props.setIsChanged(true);
        this.disableGenerateSegments();
        let rowIndex = cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME];
        this.tabulator.updateRow(rowIndex, cell.getRow().getData());
    }

    setSelectValue(e,cell){
        cell.setValue(e.target.value);
        this.props.setIsChanged(true);
        let rowIndex = cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME];
        this.tabulator.updateRow(rowIndex, cell.getRow().getData());
    }

    sortVectors(vectorList){
        let newList = []
        let noGroupVectors  = copyObjectValues(vectorList).filter(e => !e.vector_group);
        let groupVectors = copyObjectValues(vectorList).filter(e => e.vector_group);
        if(noGroupVectors.length > 0){
            noGroupVectors.sort((a, b) => (a.display_name.toLowerCase() > b.display_name.toLowerCase()) ? 1 : -1)
            newList.push(...noGroupVectors);
        }
        if(groupVectors.length > 0){
            newList.push(...groupVectors);
        }
       
        return newList;
    }
    disableGenerateSegments(){
        let _this = this;
        var data = _this.tabulator.getData();
        var disableGenerateSegments = data.filter(e => e.generate_quads).length >= _this.props.generateSegmentVectorsLimit;
        var countOfSelectedVectors = data.filter(e => e.generate_quads).length;
        if(disableGenerateSegments != _this.state.disableGenerateSegments){
            data.map((cell) => {
                let generateQuadsId = '#'+VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS + "_" +cell[VECTOR_STAGING_ATTRIBUTES.NAME].replace(toBeReplaced, '_');
                if(!cell[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS]){
                    if(disableGenerateSegments){
                        $(generateQuadsId).prop("disabled",true);
                        $(generateQuadsId).parent().css('opacity','0.2');
                        $(generateQuadsId).siblings('span').addClass("disabled");
                    } else {
                        $(generateQuadsId).prop("disabled",false);
                        $(generateQuadsId).parent().css('opacity','1');
                        $(generateQuadsId).siblings('span').removeClass("disabled");
                    }
            }});
            _this.setState({disableGenerateSegments: disableGenerateSegments});
        }
        _this.setState({countOfSelectedVectors: countOfSelectedVectors});
    }

    enableSave=(data)=>{
        let editedVectors = []
        if(!data) {
            return [];
        }
        let isEdited = data.filter(e=>e[VECTOR_STAGING_ATTRIBUTES.IS_EDITED] && e[VECTOR_STAGING_ATTRIBUTES.VISIBILITY] || e[VECTOR_STAGING_ATTRIBUTES.GROUP] == null);
        for(let e in isEdited){
            editedVectors.push(isEdited[e]);
        }
        if(isEdited.length > 0 && this.props.scenarioStatus == _sandbox){ 
            this.props.setIsChanged(true,true); 
        }

        // for(let e in isEdited){
        //     let entity_count = isEdited[e][VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT];
        //     if(entity_count){
        //         entity_count =  Number(entity_count.replace(/,/g,""));
        //         if(isEdited[e][VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] !== this.thresholdComputation(entity_count,Number(thresholds.high_threshold),Number(thresholds.low_threshold))){
        //             this.props.setIsChanged(true);
        //             break;
        //         }
        //     }
        // }
       
        return editedVectors;
    }

    fillVectorListInStates(vectorList,thresholds={},editedVectors=[],callback) {
        let high_threshold =  thresholds.high_threshold;
        let low_threshold = thresholds.low_threshold;
        var customerGroup = copyObjectValues(this.state.customerGroupParent);
        var productGroup = copyObjectValues(this.state.productGroupParent);
        var operationsGroup = copyObjectValues(this.state.operationsGroupParent);
        var originalData = copyObjectValues(this.state.originalDataParent);
        vectorList.map(function(item) {
            if(item[VECTOR_STAGING_ATTRIBUTES.GROUP]) {
                if(item[VECTOR_STAGING_ATTRIBUTES.GROUP]=== ""){
                    originalData.push(item);
                } else if(item[VECTOR_STAGING_ATTRIBUTES.GROUP].toLowerCase() === VECTOR_STAGING_ATTRIBUTES.GROUP_VALUES.CUSTOMER){
                    customerGroup.push(item);
                } else if(item[VECTOR_STAGING_ATTRIBUTES.GROUP].toLowerCase() === VECTOR_STAGING_ATTRIBUTES.GROUP_VALUES.PRODUCT){
                    productGroup.push(item);
                } else if(item[VECTOR_STAGING_ATTRIBUTES.GROUP].toLowerCase() === VECTOR_STAGING_ATTRIBUTES.GROUP_VALUES.OPERATIONS){
                    operationsGroup.push(item);
                }
            }   
        });
        
        this.setState({
            dataParent: this.sortVectors(vectorList),
            customerGroupParent : customerGroup,
            productGroupParent : productGroup,
            operationsGroupParent : operationsGroup,
            originalData:this.sortVectors(vectorList),
            originalDataParent: this.sortVectors(vectorList),
            high_threshold:high_threshold,
            low_threshold:low_threshold,
            editedVectors:editedVectors
        },function(){
            this.tabulator.replaceData(this.state.dataParent);
            this.props.onPropertyChange(this.state.dataParent);
            if(typeof callback === "function"){
                callback();
            }
            
        });
      
    }

  validateConfigureVectors = () => {
    let data = this.tabulator.getData();
    let message = "";
    if (data.filter(e => e[VECTOR_STAGING_ATTRIBUTES.GROUP] === undefined && e[VECTOR_STAGING_ATTRIBUTES.VISIBILITY]).length > 0) {
      message = lang.configure_vectors_group_validation;
    }
    if (message.length > 0) {
      this.props.setValidation(message);
      return false;
    } else {
      return true;
    }
  }

    updateVectors(switchAfter,openModalAfter) {
       
        var obj = this;
        var tableData = this.tabulator.getData();
        if(!obj.validateConfigureVectors()){
            return;
        }
        toggleLoader(true, 'updateVectors',false,true);
        
        var dataBody = { 
            action: "updateVectors",
            scenario_id: this.props.scenarioId,
            vectors: "{vectors:" + (JSON.stringify(tableData)) + "}",
            selectedPeriods: this.props.periods.filter(e=>e.is_active === "ACTIVE").map(e=>e.value)
        };
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{
                if(response.status === 403) {
                    window.location.href = process.env.REACT_APP_CLIENT_BASE_URL;                                  
                } else if(response.status === 412) {
                    obj.setInfoDialogOpen(true, 'There is something wrong with your query!');
                } else if(response.status === 409) {
                    obj.setInfoDialogOpen(true, 'Table has duplicate primary keys!');
                } else if(response.status === 201) {
                    obj.setInfoDialogOpen(true, 'Table has been updated successfully.');
                }else if (response.status === 500) {
                    obj.setInfoDialogOpen(true, "An error occured. Please retry.");
                }

                return response.json();
            }).then((data)=>{
                if(data.result === 'SUCCESS') {
                    if(data.data) {
                        let thresholds = {high_threshold:obj.state.high_threshold,low_threshold:obj.state.low_threshold}  
                        let newData = data.data;
                        let originalData = copyObjectValues(obj.state.originalDataParent);
                        for(let d in newData){
                            let filteredOriginalData =  originalData.filter(e=>e[VECTOR_MAPPING.FIELDS.VECTOR_ID] === newData[d][VECTOR_MAPPING.FIELDS.VECTOR_ID]);
                            let vector = filteredOriginalData.length > 0 ? filteredOriginalData[0] : undefined;
                            if(vector && vector[VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT]){
                                newData[d][VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT] = vector[VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT];
                            }
                        }
                        obj.fillVectorListInStates(newData,thresholds,[],()=>{
                                obj.props.setTabsData("getVectorList", copyObjectValues(obj.state.dataParent));
                                obj.props.launchToast(true);
                                obj.props.setIsChanged(false);
                            // obj.getVectorList();
                        })
                    }
                    
                    // toggleLoader(false, 'updateVectors');

                } else if(data.error){
                  obj.setInfoDialogOpen(true, 'An error occured. Please retry.');
                }
            }).then(()=>{
                if(switchAfter){
                    // setTimeout(() => {
                        obj.props.changeTab(obj.props.getNextTab());
                        toggleLoader(false, 'updateVectors',false,true);
                    // }, 1000);
                }
                if(openModalAfter){
                    obj.props.drawerToggleClickHandler();
                    toggleLoader(false, 'updateVectors',false,true);
                }
                else{
                    toggleLoader(false, 'updateVectors',false,true);
                }
            })
            .catch((error)=>{
                alertAndLogError(error);
                toggleLoader(false, 'updateVectors',false,true);
            });
    }

    showDetailsTables(row){
        var fieldKey = "key";
        var fieldCount = "count";
        var fieldLength = "length";
        var fieldRevenue = "revenue";
        var vectorName = row.original[VECTOR_STAGING_ATTRIBUTES.NAME];

        this.getVectorDetail(vectorName, fieldKey);
        this.getVectorDetail(vectorName, fieldCount);
        this.getVectorDetail(vectorName, fieldLength);
        this.getVectorDetail(vectorName, fieldRevenue);
    }

    getVectorDetail(vector, field) {
        toggleLoader(true, 'getVectorDetail');

        var dataToPush = [];

        const dataBody =  
        { 
            "action": "getVectorDetails",
            "scenario_id": this.props.scenarioId,
            "field": field,
            "vectorName": vector,
            "timePeriod": this.props.selectedPeriod,
        };
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());    fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
        .then((response)=>{
            if(response.status === 403) {
                 window.location.href = process.env.REACT_APP_CLIENT_BASE_URL;                                  
            }
            return response.json()})
        .then((data)=>{
            for(var i = 0; i < data.length ; i++){

                var vectorDetail = {
                    key: data[i]['key'] , 
                    count: data[i]['count'], 
                    length: data[i]['length'], 
                    revenue: data[i]['revenue'], 
                }
                
                dataToPush.push(vectorDetail);
            }
          
            if(field === "key") {
                this.setState({
                    vectorKeys : dataToPush,
                    info : true,
                });
            } else if(field === "count") {
                this.setState({
                    vectorCounts : dataToPush,
                    info : true,
                });
            } else if(field === "length"){
                this.setState({
                    lengthCount : dataToPush,
                    info : true,
                });
            } else if(field === "revenue") {
                this.setState({
                    vectorRevenue: dataToPush,
                    info: true,
                });
            }

            toggleLoader(false, 'getVectorDetail');
         
            window.scrollTo(0, this.myRef.current.offsetTop);           
        }).catch((error)=>{
            alertAndLogError(error);;
        });
    }

    getVectors(){
            return this.state.dataParent;
    }

    componentDidUpdate(nextProps, prevState) {
        //set tabulator data
        // if(this.tabulator.getColumns().length > 0){
        //     return;
        // }
        var tableData = prevState.dataParent;
        let isNewArch = getSectionExists(this.props.userAllowedSections, MENU_ITEM.FIELDS.TEMPORARY_DATA_MODELING);
        if(!isNewArch){
            VECTORS_MAPPING_COLUMNS = VECTORS_MAPPING_COLUMNS.filter(e=>![VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT, VECTOR_STAGING_ATTRIBUTES.GENERATE_BY].includes(e.field));
        }
        var tempColumns = copyObjectValues(VECTORS_MAPPING_COLUMNS);
        // this.tabulator.setData(tableData);

        var tableColumns = cleanUpTabulatorColumns(tempColumns, tableData, this.refreshFilterDivs, this.tabulator);
        
        //table columns
        var obj = this;
        tableColumns.forEach(col => {
            var columnField = col.field;
            var columnFieldMessage = "configure_vectors_" + columnField;
           
            if(![VECTOR_STAGING_ATTRIBUTES.NAME,VECTOR_STAGING_ATTRIBUTES.GROUP,VECTOR_STAGING_ATTRIBUTES.IS_DEFAULT_GROUP_VECTOR].includes(col.field)){
                if(columnField === VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS){
                    let message = MESSAGES[columnFieldMessage].replace("[Y]",obj.props.generateSegmentVectorsLimit);
                    col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, message);
                } else {
                    col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, MESSAGES[columnFieldMessage]);
                }
               
            }
            col.formatter = obj.getColumnFormatter(col.field, col.formatter);
            if (col.field === VECTOR_STAGING_ATTRIBUTES.month) {
                col.cssClass = "psl-handle-row";
            }
        });
        if(this.state.disableGenerateSegments != prevState.disableGenerateSegments){
            this.tabulator.setColumns(tableColumns);
            //set the columns as the actual columns of the table
        }
     }

    addTooltipTitleFormatter(cell, tooltipMessage){
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-small-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);

        p.appendChild(el);

        div.appendChild(p);

        return div;
    }
    
    thresholdComputation=(entityCount,high_threshold,low_threshold)=>{
        if(entityCount < low_threshold){
            return false;
       } else if (entityCount >= low_threshold &&  entityCount <= high_threshold){
            return false;
       } else {
           return true;
       }
    }

    getColumnFormatter(colField) {
        var obj = this;
		var columnFormatter;		
        switch(colField) {
			
            case VECTOR_STAGING_ATTRIBUTES.VISIBILITY:
                columnFormatter = function(cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let div = document.createElement("div");
                    let label = document.createElement("label");
                    label.classList.add("switch");
                    ////////////SWITCH
                    let input = document.createElement("input");
                    input.type = "checkbox";
                    input.id =  VECTOR_STAGING_ATTRIBUTES.VISIBILITY + "_" +cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME].replace(/ /g,'_');
                    input.name =  VECTOR_STAGING_ATTRIBUTES.VISIBILITY;
                    input.checked = rowData[VECTOR_STAGING_ATTRIBUTES.VISIBILITY] === undefined ? false : rowData[VECTOR_STAGING_ATTRIBUTES.VISIBILITY];
                    input.value = input.checked ? 'off' : 'on';
                    input.onchange = (e) => {obj.onChange(e, cell,VECTOR_STAGING_ATTRIBUTES.VISIBILITY);}
                    label.appendChild(input);
                    let span = document.createElement("span");
                    span.classList.add("slider","round");
                    // if(rowData[VECTOR_STAGING_ATTRIBUTES.VISIBILITY] === undefined) {
                    //     input.disabled = true;
                    //     label.title = "The vector is still not staged";
                    //     label.style.opacity = "0.2";
                    //     label.disabled = true;
                    //     span.classList.add("disabled");
                    // }
                    label.appendChild(span)
                    div.appendChild(label);
                    div.style.marginTop = convertPxToViewport(9);
                    return div
                  };
            break;
            case VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS:
                columnFormatter = function(cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let high_threshold = Number(obj.state.high_threshold);
                    let low_threshold = Number(obj.state.low_threshold);
                    let div = document.createElement("div");
                    let label = document.createElement("label");
                    ////////////SWITCH
                    label.classList.add("switch");
                    let entity_count = rowData[VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT];
                    let input = document.createElement("input");
                    input.type = "checkbox";
                    input.id =  VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS + "_" +cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME].replace(toBeReplaced, '_');
                    input.name =  VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS;
                    input.checked = rowData[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] === undefined ? false : rowData[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS];
                    input.value = input.checked ? 'off' : 'on';
                    input.onchange = (e) => {obj.onChange(e, cell,VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS);}
                    label.appendChild(input);
                    label.title = "";
                    let span = document.createElement("span");
                    span.classList.add("slider","round");
                    if(entity_count){
                        entity_count = Number(entity_count);
                    }
                    if(obj.props.scenarioStatus !== _sandbox){
                        input.disabled = true;
                        input.checked =  rowData[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] === undefined ? false : rowData[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS];
                        label.title = lang.configure_vectors_checkout_validation;
                        label.style.opacity = "0.2";
                        label.disabled = true;
                        span.classList.add("disabled");
                    }else if(!rowData[VECTOR_STAGING_ATTRIBUTES.VISIBILITY]) {
                        input.disabled = true;
                        input.checked = false;
                        label.title = "Vector is hidden";
                        label.style.opacity = "0.2";
                        label.disabled = true;
                        span.classList.add("disabled");
                    } else if(obj.state.disableGenerateSegments && !input.checked){
                        input.disabled = true;
                        input.checked = false;
                        label.title = lang.configure_vectors_generate_quads_limit.replace("[Y]",obj.props.generateSegmentVectorsLimit);
                        label.style.opacity = "0.2";
                        label.disabled = true;
                        span.classList.add("disabled");
                    }
                    // else if(rowData[VECTOR_STAGING_ATTRIBUTES.VISIBILITY] && rowData[VECTOR_STAGING_ATTRIBUTES.IS_EDITED] === true  && !rowData[_modified]){ //Apply threshold logic
                    //     if(entity_count){
                    //         if(entity_count < low_threshold || (entity_count >= low_threshold &&  entity_count <= high_threshold)){
                    //                 input.checked = false;
                    //                 input.value = input.checked ? 'off' : 'on';
                    //                 rowData[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] = false;
                                
                    //     } else {
                    //             input.checked = true;
                    //             input.value = input.checked ? 'off' : 'on';
                    //             rowData[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS] = true; 
                    //     }
                    //   }
                    // }
                    if(entity_count && entity_count < low_threshold){
                        return ""; // hide toggle always if entity count is less than low_threshold
                    }
                    label.appendChild(span)
                    div.appendChild(label);
                    div.style.marginTop = convertPxToViewport(-6);
                    return div
                  };
            break;

            case VECTOR_STAGING_ATTRIBUTES.GROUP:
                columnFormatter = function(cell, formatterParams) {    
                    let select = createDropdown(["form-control-d"]);
                    select.onchange = (e) => obj.onChangeGroup(cell, e);
                    select.style.cursor = "pointer";
                    // select.classList.add("form-control-d");
                    select.style.display = "inline-block";
                    select.style.width = "9vw";
                    var values = obj.state.values;
                    let placeholderOption = document.createElement("option");
                    placeholderOption.innerHTML = "Choose a group";
                    placeholderOption.setAttribute("disabled","disabled");
                    placeholderOption.setAttribute("hidden","hidden");
                    placeholderOption.value = "";
                    select.appendChild(placeholderOption);
                    for(var key in values) {
                        let value = values[key]["value"];
                        let option = document.createElement("option");
                        option["group"] = values[key]["value"];
                        option.value = value;
                        option.innerHTML = value;
                        select.appendChild(option);
                    }
                    
                    select.value = cell.getValue() ? cell.getValue() : ""		//set the cell value as the selected option in the SELECT dropdown
                    return select;
                }
                break;
            case VECTOR_STAGING_ATTRIBUTES.GENERATE_BY:
                columnFormatter = function(cell) {
                    var rowData = cell.getRow().getData();
                    let entity_count = rowData[VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT];
                    if(entity_count){
                         entity_count = Number(entity_count);
                    }
                    if(!entity_count  || entity_count >= Number(obj.state.low_threshold)){
                        var selectList = createDropdown(["form-control-d"]);
                        selectList.style.display = "inline-block";
                        selectList.style.width = convertPxToViewport(104);
                        selectList.id = VECTOR_STAGING_ATTRIBUTES.GENERATE_BY  + "_" + rowData[VECTOR_STAGING_ATTRIBUTES.NAME].replace(/ /g,'_');
                        for (var i = 0; i < generate_by_options.length; i++) {
                            // selectList.classList.add("form-control-d");
                            var option = document.createElement("option");
                            option.value = generate_by_options[i].value;
                            option.text = generate_by_options[i].label;
                            
                            selectList.appendChild(option);
                        }
                        selectList.onchange = (e) => obj.setSelectValue(e,cell);
                        selectList.value = cell.getValue() === undefined ? generate_by_options[0].value: cell.getValue();
                        if(obj.props.scenarioStatus !== _sandbox){
                            selectList.disabled = true;
                            selectList.classList.add("disabled");
                            selectList.style.opacity = "0.4";
                            selectList.title = lang.configure_vectors_checkout_validation;
                        }
                        else if(!rowData[VECTOR_STAGING_ATTRIBUTES.VISIBILITY] || !rowData[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS]) {
                            selectList.disabled = true;
                            selectList.classList.add("disabled");
                            selectList.style.opacity = "0.4";
                        } 
                        return selectList;
                    }
                    
                };
                break;

            case VECTOR_STAGING_ATTRIBUTES.HANDLE_ROW:
            columnFormatter = function(cell, formatterParams) {
                var i = document.createElement("i");
                i.classList.add("fas","fa-grip-vertical");
                i.id = "handleRow_"+cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME].replace(toBeReplaced, '_').toLowerCase();
                i.style.visibility = "hidden";
                i.style.cursor = "grab";
                

                cell.getElement().addEventListener("mouseenter", (event)=>{
                    var selectCell = cell.getRow().getCell(VECTOR_STAGING_ATTRIBUTES.GROUP);
                    $(selectCell.getElement()).find("option").each(function(i){
                        if(i!==0){
                            $(this).remove();
                        }
                    });
                    
                    if(selectCell.getValue() && selectCell.getValue() !== "") {
                        let valueOption = document.createElement("option");
                        valueOption["group"] = selectCell.getValue();
                        valueOption.value = selectCell.getValue();
                        valueOption.innerHTML = selectCell.getValue();
                        $(selectCell.getElement()).find("select").append(valueOption);
                    } else {
                        let valueOption = document.createElement("option");
                        valueOption.innerHTML = "Choose a group";
                        valueOption.setAttribute("disabled","disabled");
                        valueOption.setAttribute("hidden","hidden");
                        valueOption.value = "";
                        $(selectCell.getElement()).find("select").append(valueOption);
                    }
                })

                cell.getElement().addEventListener("mouseleave", (event)=>{
                    var selectCell = cell.getRow().getCell(VECTOR_STAGING_ATTRIBUTES.GROUP);
                    $(selectCell.getElement()).find("option").each(function(){
                        $(this).remove();       //delete previously added options before appending the new ones
                    });
                    var select = $(selectCell.getElement()).find("select");
                    var values = obj.state.values;
                    let placeholderOption = document.createElement("option");
                    placeholderOption.innerHTML = "Choose a group";
                    placeholderOption.setAttribute("disabled","disabled");
                    placeholderOption.setAttribute("hidden","hidden");
                    placeholderOption.value = "";
                    select.append(placeholderOption);
                    for(var key in values) {
                        let value = values[key]["value"];
                        let option = document.createElement("option");
                        option["group"] = values[key]["value"];
                        option.value = value;
                        option.innerHTML = value;
                        select.append(option);
                    }
                    let value = selectCell.getValue() ?  selectCell.getValue() : "";
                    select.val(value);
                })
                return i;
            }
                break;
            case VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT:
                columnFormatter = function (cell, formatterParams) {
                    // return formatValString(cell.getValue(), FormatTypes.NUMERIC)
                    return '-';
                }
                break;
            default:      
            columnFormatter = function(cell, formatterParams) {
                return cell.getValue();
            }
            break;
		}
		
		return columnFormatter;
    }

    onTabulatorRenderComplete() {
        if(!this.tabulator) {
            return;
        }
        this.disableGenerateSegments();
        this.tabulatorData_undoed = [];
        for (var row in this.tabulator.rowManager.displayRows[0]) {
            this.tabulatorData_undoed.push(this.tabulator.rowManager.displayRows[0][row].getData());
        }
    }

    componentDidMount() {
        
        
        $("#PeriodRange").addClass("uk-hidden");
        $("#period_label").addClass("uk-hidden");
        var obj = this;
        var options = {
            index: VECTOR_STAGING_ATTRIBUTES.NAME,
            layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: false,  //hide columns that dont fit on the table
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false, //"local",       //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: true,
            movableRows: true,
            resizableColumns: false,
            autoResize: true,
            resizableRows: false,       //allow row order to be changed
            virtualDomBuffer: 1500,
            placeholder: MESSAGES.no_data_available,
            height: convertPxToViewport(600),
            width: "100%",
            reactiveData:true,      //tabulator listens to any change in the data array and updates the table
            dataLoaded: this.onTabulatorRenderComplete,
            // renderComplete: this.onTabulatorRenderComplete,
            initialSort: [
                            {column: "display_order", dir:"desc"},
                            {column:"vector_group", dir:"asc"}
                        ],
            tooltips:function(column){
                return column._cell.value;
            },
            rowMoved: function(row, index, index2){
                var movedRowName = row.getData()[VECTOR_STAGING_ATTRIBUTES.NAME];
                var movedRowGroup = row.getData()[VECTOR_STAGING_ATTRIBUTES.GROUP];
                var displayedRows = obj.tabulator.rowManager.displayRows[0];    //an array in tabulator that contains all displayed rows in order
                let displayedRowIndex = obj.getRowIndex(displayedRows, movedRowName);
                let previousItem = displayedRowIndex === 0 ? null : displayedRows[displayedRowIndex -1];
                let previousItemGroup = previousItem ? previousItem.getData()[VECTOR_STAGING_ATTRIBUTES.GROUP] : "";
                let nextItem = displayedRowIndex === displayedRows.length ? null : displayedRows[displayedRowIndex +1];
                let nextItemGroup = nextItem ? nextItem.getData()[VECTOR_STAGING_ATTRIBUTES.GROUP] : "";
                let movedRowGroupCount = obj.tabulator.getData().filter(row => row[VECTOR_STAGING_ATTRIBUTES.GROUP] === movedRowGroup).length;
                let allowed = true;
                // obj.props.setIsChanged(true);
                
                if(previousItem && nextItem) {
                    if(previousItemGroup === nextItemGroup && previousItemGroup !== movedRowGroup) {
                        //if the row is being moved between two other rows that belong to the same group which is different than its own, do not allow
                        allowed = false;
                    }
                    if(previousItemGroup !== nextItemGroup && previousItemGroup !== movedRowGroup && movedRowGroup !== nextItemGroup && movedRowGroupCount > 1) {
                        //if the row is moved between two different groups that are also different than its own, only allow if this is the only vector that belongs to its group
                        allowed = false;
                    }
                }

                if(!previousItem && nextItemGroup !== movedRowGroup && movedRowGroupCount > 1) {
                    //if the row is being put as the first one, only allow it if this vector's group count is 1
                        allowed = false;
                }

                if(!nextItem && previousItemGroup !== movedRowGroup && movedRowGroupCount > 1) {
                    //if the row is being put as the last one, only allow it if this vector's group count is 1
                    allowed = false;
                }
                if(!movedRowGroup){
                    allowed = true;
                }

                if(allowed) {
                    // obj.redrawTable = true;
                    obj.props.setIsChanged(true);
                     obj.tabulator.redraw(false);
                } else {
                    obj.setResortingVectorsDialogOpen(true)
                    let dataParent = copyObjectValues(obj.tabulatorData_undoed);
                    obj.tabulator.replaceData(dataParent);
                }
            }, 
            rowMouseOver:function(e,row){
              let machine_name = row.getData()[VECTOR_STAGING_ATTRIBUTES.NAME].replace(toBeReplaced, '_').toLowerCase();
              $("#handleRow_"+machine_name).css("visibility","visible");
            },
            rowMouseOut:function(e,row){
              let machine_name = row.getData()[VECTOR_STAGING_ATTRIBUTES.NAME].replace(toBeReplaced, '_').toLowerCase();
              $("#handleRow_"+machine_name).css("visibility","hidden");
            }
        }
        this.tabulator = new Tabulator(this.refs.mainTable, options);
        if(!this.props.getTabsData('getVectorList')){
            // this.getVectorList();
        } else {
            let data = this.props.getTabsData('getVectorList')
            this.fillVectorListInStates(data);
            this.enableSave(data);  
        }
    }

    getRowIndex(displayedRows, name) {
        var index = -1;
        displayedRows.forEach(function(item, key) {
            if(item.getData()[VECTOR_STAGING_ATTRIBUTES.NAME] === name) {
                index = Number(key);
            }
        });
        return index;
    }

    saveTable(switchAfter,openModalAfter) {
        var validatedVectors = validateVectors(this.tabulator.getData());

        if (validatedVectors) {
            this.props.resetTabsData(CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS);
            this.updateVectors(switchAfter,openModalAfter);
            return true;
        } else {
          this.setInfoDialogOpen(true, "Please make sure all chosen vectors have selected groups ");
            return false;
        }
    }

    checkStatus(){
        $(".DISABLED").prop('checked',false);
    }

    onChangeGroup(cell, e){
        let index = cell.getRow().getData().display_name
        this.updateVectorGroup(cell, e.currentTarget.value);
        // this.tabulator.scrollToRow(index);
    }

    // launchToast(param) {
    //     this.setState({
    //         message: param? lang.saved : lang.not_saved,
    //         isError: param? false : true,
    //     },function(){
    //         $("#toastConfigureVectors").addClass("show");
    //         setTimeout(function(){
    //             $("#toastConfigureVectors").removeClass("show");
    //         }, 4000);
    //     })
    // }


    updateVectorGroup(cell, group){
        let _this = this;
        var gridData = copyObjectValues(this.state.dataParent);
        var originalData = [];
        var customerGroup = [];
        var productGroup = [];
        var operationsGroup = [];
        let groupFilter = gridData.filter(e=>e.vector_group === group).length;
        let indexOfirstVectorGroup  =  gridData.findIndex(i => i.vector_group === group);
        var vectorInfo ={
            [VECTOR_STAGING_ATTRIBUTES.NAME]: cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME],
            [VECTOR_STAGING_ATTRIBUTES.STAGING_STATUS]: cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.STAGING_STATUS],
            [VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER]: cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER],
            [VECTOR_STAGING_ATTRIBUTES.DISPLAY_GROUP_ORDER]: cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.DISPLAY_GROUP_ORDER],
            [VECTOR_STAGING_ATTRIBUTES.GROUP]: group,
            [VECTOR_STAGING_ATTRIBUTES.VISIBILITY]: cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.VISIBILITY],
            [VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT]:cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.MAX_ENTITY_COUNT],
            [VECTOR_STAGING_ATTRIBUTES.GENERATE_BY]:cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.GENERATE_BY],
            [VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS]:cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.GENERATE_QUADS],
            [VECTOR_STAGING_ATTRIBUTES.IS_EDITED]:cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.IS_EDITED],
        }
        var originalIndex = gridData.map(function(item) { 
            return item[VECTOR_STAGING_ATTRIBUTES.NAME];
         }).indexOf(cell.getRow().getData()[VECTOR_STAGING_ATTRIBUTES.NAME]);
        gridData.splice(originalIndex, 1); 
        let indexToInsert = indexOfirstVectorGroup + groupFilter;
        gridData.splice(indexToInsert, 0, vectorInfo);
        
       

        gridData.map(function(item) {
            // if(item[VECTOR_STAGING_ATTRIBUTES.GROUP] !== undefined) {
                if(item[VECTOR_STAGING_ATTRIBUTES.GROUP] === "" || item[VECTOR_STAGING_ATTRIBUTES.GROUP] === undefined ){
                    originalData.push(item);
                } else if(item[VECTOR_STAGING_ATTRIBUTES.GROUP].toLowerCase() === VECTOR_STAGING_ATTRIBUTES.GROUP_VALUES.CUSTOMER){
                    customerGroup.push(item);
                } else if(item[VECTOR_STAGING_ATTRIBUTES.GROUP].toLowerCase() === VECTOR_STAGING_ATTRIBUTES.GROUP_VALUES.PRODUCT){
                    productGroup.push(item);
                } else if(item[VECTOR_STAGING_ATTRIBUTES.GROUP].toLowerCase() === VECTOR_STAGING_ATTRIBUTES.GROUP_VALUES.OPERATIONS){
                    operationsGroup.push(item);
                }
            // }
        });

        gridData = originalData.concat(customerGroup).concat(productGroup).concat(operationsGroup);

        // dataParent = gridData;
        // customerGroupParent = customerGroup;
        // productGroupParent = productGroup;
        // operationsGroupParent = operationsGroup;
        // originalDataParent = originalData;

        // this.checkStatus();
        // this.props.setIsChanged(true)
        // this.tabulator.replaceData(gridData);

        this.setState({
            customerGroupParent: customerGroup,
            productGroupParent: productGroup,
            operationsGroupParent: operationsGroup,
            originalData: originalData,
            dataParent: gridData
        },function(){
            // let rowpos = cell.getRow().getData().display_name;
            // this.tabulator.scrollToRow(rowpos);
        })
        this.checkStatus();
        this.props.setIsChanged(true)
        this.tabulator.replaceData(gridData);
       
    }   

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }
    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }

    setResortingVectorsDialogOpen = (isOpen) => {
      let _this = this;
      _this.setState({
        openResortingVectorsDialog: isOpen,
      })
    }

  resortingVectorsDialogActions = () => {
    return (
      <Button
        id="close-btn"
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setResortingVectorsDialogOpen(false)}
      />
    )
  }



    render() {

        const { vectorKeys } =  this.state;
        const { vectorCounts } =  this.state;
        const { lengthCount } =  this.state;
        const { vectorRevenue } =  this.state;
        let isNewArch = getSectionExists(this.props.userAllowedSections, MENU_ITEM.FIELDS.TEMPORARY_DATA_MODELING);
        let newArchClassName = !isNewArch ? "not_new_arch_transition_style" : "";

        return (
            <div>
                {/* <h3>{this.state.countOfSelectedVectors}</h3>
                <h3>{this.state.disableGenerateSegments ? "true": "false"}</h3> */}
                 {/* <div id="toastConfigureVectors" className="toast toast-success">
                    <div id="desc"><i className={"fa-lg fas" +(this.state.isError ? " fa-minus-circle uk-text-primary" : " fa-check-circle  uk-margin-small-right greenText" )}aria-hidden="true"></i>{this.state.message}</div>
                </div> */}
                <div>
                    <div className="uk-flex-column uk-width-1-1">
                        {/* <h3 className="uk-text-left">Suggested Vectors:</h3> */}
                    </div>
                </div>
                <div className={newArchClassName + " vector-mapping-table"} id ="configure_vectors" ref="mainTable">                    
                </div>
                { this.state.info ? 
                    <div className ="vector-info-tables uk-grid uk-child-width-1-4 uk-flex uk-grid-small uk-flex-left" uk-grid="" ref={ this.myRef }>
                        <div>

                            <fieldset>
                                <legend className="uk-text-medium uk-width-auto uk-margin-remove">Vector Keys:</legend>
                                <ReactTable
                                    data={vectorKeys}
                                    columns={[
                                        {
                                        Header: "Vector Keys",
                                        accessor: "key"
                                        }
                                    ]}
                                    minRows={0}
                                    defaultPageSize={10}
                                    defaultSorted={[{ id: 'key' }]}
                                    previousText={"Top 10"}
                                    nextText={"Bottom 10"}
                                />
                            </fieldset>
                        </div>
                        <div>

                            <fieldset>
                                <legend className="uk-text-medium uk-width-auto uk-margin-remove">Key Counts:</legend>
                                <ReactTable
                                    data={vectorCounts}
                                    columns={[
                                        {
                                        Header: "Key",
                                        accessor: "key"
                                        },
                                        {
                                        Header: "Count",
                                        accessor: "count",
                                        }
                                    ]}
                                    minRows={0}
                                    defaultPageSize={10}
                                    previousText={"Top 10"}
                                    nextText={"Bottom 10"}
                                    defaultSorted={[{ id: 'count', desc: true }]}
                                />
                            </fieldset>
                        </div>
                        <div>
                            <fieldset>
                                <legend className="uk-text-medium uk-width-auto uk-margin-remove">Length Counts:</legend>
                                <ReactTable
                                    data={lengthCount}
                                    columns={[
                                        {
                                         Header: "Length",
                                        accessor: "length"
                                        },
                                        {
                                        Header: "Count",
                                        accessor: "count",
                                        }
                                    ]}
                                    minRows={0}
                                    defaultPageSize={10}
                                    previousText={"Top 10"}
                                    nextText={"Bottom 10"}
                                    defaultSorted ={[{ id: 'length', desc: true }]}
                                />
                            </fieldset>
                        </div>

                        <div>   
                            <fieldset>     
                                <legend className="uk-text-medium uk-width-auto uk-margin-remove">Revenue:</legend>
                                <ReactTable
                                    data={vectorRevenue}
                                    columns={[
                                        {
                                            Header: "Key",
                                            accessor: "key"
                                        },
                                        {
                                            Header: "Revenue",
                                            accessor: "revenue",
                                            Cell: row => (
                                                parseFloat(row.value) >= 0 ? <div className = "greenText" >{formatValHTML(parseFloat(row.value), FormatTypes.AMOUNT)}</div> : <div className = "red" >{formatValHTML(parseFloat(row.value), FormatTypes.AMOUNT)}</div>
                                            )
                                        }
                                    ]}
                                    minRows={0}
                                    defaultPageSize={10}
                                    defaultSorted={[{ id: 'revenue', desc: true }]}
                                    previousText={"Top 10"}
                                    nextText={"Bottom 10"}
                                />
                            </fieldset>
                        </div>
                        <br/>
                    </div>
                : ""
                }
            <Modal
              id={"resorting-vectors-error-dialog"}
              openDialog={this.state.openResortingVectorsDialog}
              bodyContent={() => <h4>Re-sorting vectors is only allowed within the same group.</h4>}
              dialogActions={this.resortingVectorsDialogActions}
              closeClick={() => this.setResortingVectorsDialogOpen(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"info-dialog"}
              openDialog={this.state.openInfoDialog}
              bodyContent={() => <h4>{this.state.infoMsg}</h4>}
              dialogActions={this.openInfoDialogActions}
              closeClick={() => this.setInfoDialogOpen(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
          </div>
        );
        
    } 
}
export default ConfigureVector;