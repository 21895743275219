import React, { Component } from 'react';
import { PROFILE_COLUMN } from '../../class/constants';
import { convertPxToViewport } from '../../class/formatting';
import { deepCompareObjects } from '../../class/utils';

const _returnName = PROFILE_COLUMN.RETURN_NAME;
const _isExpanded = PROFILE_COLUMN.IS_EXPANDED;

//column attributes


class Column extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.toggleColumn = this.toggleColumn.bind(this);
    }

    toggleColumn(key) {
        this.props.toggleColumn(this.props.value, key);
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = !deepCompareObjects(nextProps, this.props);
        return shouldUpdate;
    }

    render() {
        let indexOfValue = this.props.name && this.props.searchValue ? this.props.name.toLowerCase().indexOf(this.props.searchValue.toLowerCase()):"";
        let searchValueInName = this.props.name && this.props.searchValue? this.props.name.substr(indexOfValue, this.props.searchValue.length):"";
        return (
             // indenting the child 10px for each level (10px because uk-margin-small is 10px)
            <div className="profile-column" style={this.props.displayAsParent ? {} : {paddingLeft: convertPxToViewport(this.props.level * 20)}}>
                <div className="uk-flex uk-flex-middle ">
                    {this.props.isCheckable ?
                        <div className={"uk-position-none border-square uk-margin-xsmall-right uk-cursor-pointer"}  node_return_name={this.props.node_return_name} node_name={this.props.node_name}  node_costkey={this.props.node_costkey}
                            node_index= {this.props.node_index} node_formula={this.props.node_formula} onClick={(e)=>this.props.appendToFormula(e)}><span  node_return_name={this.props.node_return_name} node_name={this.props.node_name}  node_costkey={this.props.node_costkey}
                            node_index= {this.props.node_index} node_formula={this.props.node_formula}>{this.props.node_index}</span></div> :""}
                    {this.props.isExpandable?
                        <div className="uk-button-icon uk-flex uk-flex-center uk-flex-middle uk-margin-small-right transparent-bg" onClick={()=>this.toggleColumn(_isExpanded)}>
                            <i className={"fs-14 far " + (this.props.isExpanded ? "fa-chevron-down" : "fa-chevron-right")}></i>
                        </div>
                    :
                    <div style={{"width":convertPxToViewport(45),"height":convertPxToViewport(33)}}></div>
                    }
                    {/** the highlight span */}
                    {this.props.searchValue && this.props.searchValue !== "" && this.props.isInSearchResult ?
                        <h5>
                            {this.props.name.substr(0, indexOfValue)}
                            <span className="search-value-highlight">{searchValueInName}</span>
                            {this.props.name.substr(indexOfValue + this.props.searchValue.length)}
                        </h5>
                        :
                        <h5>{this.props.name}</h5>
                    }
                </div>
               
            </div>
        );
    }
}

export default Column