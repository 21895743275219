import React, { Component } from 'react';
import {ACCOUNT_AMOUNT_TITLES, ACCOUNT_AMOUNT_FIELDS, FormatTypes, PS_MAPPING} from '../../class/constants';
import Tabulator from "tabulator-tables"; //import Tabulator library
import { copyObjectValues, capitalizeFirstLetter } from '../../class/utils.js';
import { formatValHTML, formatValString } from '../../class/format';
import { convertPxToViewport } from '../../class/formatting.js'
const _excluded = "excluded";
const _unassigned = "unassigned";
const _total = "total";

class SimpleTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
            data: this.props.data
        };

        this.isLoading = false;
    }

    componentDidMount() {
        var options = {
            layout: "fitColumns",      //fit columns to width of table
			responsiveLayout: false,  //hide columns that dont fit on the table
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
			pagination: false,      //paginate the data
			movableColumns: false,     //allow column order to be changed
			autoResize: true,
			resizableRows: false,       //allow row order to be changed
			selectable: false,
			resizableColumns: false,
			virtualDomBuffer: 800,
			placeholder: "",
			height: this.props.height,
			width: this.props.width,
			renderComplete: this.onTabulatorRenderComplete,
			tooltips:function(column){
				return column._cell.value;
			}
        }
        
        this.tabulator = new Tabulator(this.refs.mainTable, options);
        var  tableColumns = copyObjectValues(this.props.columns);
        tableColumns = this.getTabulatorColumns(tableColumns);	//set column formatters and return column objects
        this.tabulator.setColumns(tableColumns);		//set the columns as the actual columns of the table
        
        var data = this.props.data;
        this.tabulator.setData(data);
    }

    componentDidUpdate(prevProps) {
     if ((this.props.id !== prevProps.id)) {
         this.tabulator.replaceData(this.props.data);
         this.tabulator.setColumns(this.getTabulatorColumns(this.props.columns));
         if(this.props.updateParent){
             this.props.updateParent();
         }
     }
    }

    onTabulatorRenderComplete(test) {
        if(!this.tabulator)
            return;
    }

    getTabulatorColumns(columns) {
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.field);
        });
        return columns;
    }

    /**
     * function called from outside component to updte the values of totals 
     * called from ProfitStackMappingNew after assigning or excluding or unassiging a combination
     * @param {*} data 
     */
    updateData=(data, periods)=>{
        let _this = this;
        let assigned = data.filter(e=>!e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(capitalizeFirstLetter(_excluded)) && !e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(capitalizeFirstLetter(_unassigned))).length;
        let unassigned = data.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(capitalizeFirstLetter(_unassigned))).length;
        let excluded = data.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(capitalizeFirstLetter(_excluded))).length;
        let assignedAmount = 0;
        let unassignedAmout = 0;
        let excludedAmount = 0;
        data.filter(e=>!e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(capitalizeFirstLetter(_excluded)) && !e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(capitalizeFirstLetter(_unassigned))).map(function(item){
            assignedAmount+= periods.length > 0 ? item[_total] : item[periods[0]];
        })
        data.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(capitalizeFirstLetter(_unassigned))).map(function(item){
            unassignedAmout+= periods.length > 0 ? item[_total] : item[periods[0]];
        })
        data.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(capitalizeFirstLetter(_excluded))).map(function(item){
            excludedAmount+= periods.length > 0 ? item[_total] : item[periods[0]];
        })
        let newData = [{assigned_combinations:assigned, assigned_amount:assignedAmount, unassigned_combinations:unassigned, unassigned_amount:unassignedAmout, excluded_combinations:excluded, excluded_amount:excludedAmount}];
        _this.tabulator.replaceData(newData);
    }

    getColumnFormatter() {
        var obj = this;            
        var columnFormatter = function(cell, formatterParams) {
            var p = document.createElement("p");
            if(obj.isLoading) {
                p = document.createElement("p");
                let img = document.createElement("i");
                img = document.createElement("img");
                img.src = '/images/FhHRx.gif';
                img.style.width=convertPxToViewport(15);
                img.style.height=convertPxToViewport(15);
                p.appendChild(img);
            } else {
                var amount = cell.getValue();
                p.innerHTML = formatValHTML(amount, cell.getColumn().getDefinition().format_type);
                p.title = formatValString(amount, cell.getColumn().getDefinition().format_type);
                if(Number(amount) < 0) {
                    p.classList.add("red");
                }
            }
            return p;
        }
        return columnFormatter;
    }

    render() {
        return(
            <div className="pi-box-shadow totals-table uk-margin-default-top uk-border-top uk-border-right uk-border-left uk-width-1-1">
                <div className="mapping-header">
                    <span className="uk-text-bold uk-text-xmedium">{this.props.title}</span>
                </div>
                <div id="Totals" ref="mainTable"/>
            </div>
            
        );

    }

}

export default SimpleTable;