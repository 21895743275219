import React, { Component } from 'react';
import shortid from 'shortid';
import { toggleLoader } from "../../class/common";
import {
    ALL_WIDGETS,
    API_URL,
    FILE_CENSUS_FIELDS,
    FISCAL_YEAR,
    HEADER_ELEMENT,
    FILECENSUS_NAMING_TEMPLATE_KEYWORDS,
    PERIOD_ONWARD,
    Delimiters,
    UPLOAD_SECTIONS,
    SCENARIO_TYPE,
    BUTTON_VARIANT,
    SIZES,
    BUTTON_TYPE,
    DIALOG_SIZE,
    MENU_ITEM,
    DASHBOARDS
} from "../../class/constants";
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../../class/networkUtils';
import { copyObjectValues, getTranslationFile ,getSectionExists} from '../../class/utils.js';
import { Tabs } from "../../form/elements.js";
import '../../styles/modelingSettings.css';
import DataSources from "./DataSources";
import Periods from "./Periods";

import { setLocalStorageValueByParameter } from '../../class/common';
import Button from '../../newComponents/Button';
import Modal from '../../newComponents/Modal';
import { getMenuLinksChildren } from '../../class/acl.js';

const headerElements = [HEADER_ELEMENT.STACKS_INFO,HEADER_ELEMENT.ADD_STACK];

const baseUrl = process.env.REACT_APP_BASE_URL;
const $ = require('jquery');

/** @author Bassem Arnaout 
 *  Landing page of Modeling Settings screen
 *  Component renders the main stacks tables and the Stack Configuration component 
 */
const _modelingSettings = ALL_WIDGETS.MODELING_SETTINGS;
const _dataModeling = MENU_ITEM.FIELDS.DATA_MODELING;
const lang = getTranslationFile();

class ModelingSettings extends Component {
    constructor(props){
        super(props);
        this.state = {
           tabLables:[],
           sendOriginalData: false
        }
    }

   componentDidMount(){
       this.getFileCensusSectionsData();
    }

    getFileCensusSectionsData = (replaceData=false, switchAfter) =>{
        const _this = this;
        var query = {
            action: "getFileCensusSectionsData"
        }
        let onThenCallback = (data)=>{
            if (data && data.data) {
                let modifiedData = data.data;
                let users = data.users;
                let userOptions = _this.formatUserOptions(users);
                modifiedData = this.formatData(modifiedData)
                let isBudgetingAllowed =  _this.props.userAllowedMenuLinks ? getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.BUDGETING) : false;
                let isAutomationAllowed =  _this.props.userAllowedMenuLinks ? getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.MANUAL_AUTOMATION) : false;
                if(!isBudgetingAllowed){
                    modifiedData = modifiedData.filter(e=>e.file_type !== UPLOAD_SECTIONS.FIELDS.BUDGETING_DATA);
                }
                modifiedData = modifiedData.filter(e=>e.file_type !== UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA || e.sub_category_module !== SCENARIO_TYPE.BUDGETING );
                _this.setState({
                    fileCensusData: modifiedData,
                    originalFileCensusData: copyObjectValues(modifiedData),// save original data to be used on reset
                    userOptions:userOptions,
                    userNotifications:data.user_notifications,
                    originalUserNotifications:copyObjectValues(data.user_notifications),
                    isBudgetingAllowed: isBudgetingAllowed,
                    isAutomationAllowed: isAutomationAllowed
                },() => {
                    if(replaceData && _this.dataSourcesRef && _this.dataSourcesRef.tabulator) {
                        _this.dataSourcesRef.tabulator.replaceData(_this.state.fileCensusData).then(function (){
                            if(switchAfter){
                                _this.changeColumns(switchAfter);
                            }else{
                                _this.dataSourcesRef.launchToast(true);
                            }
                        });
                    }
                })
            }  
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getFileCensusSectionsData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    formatUserOptions = (usersData) => {
        let userOptions = []
        userOptions.push({
            value: lang.modeling_settings.user.company_users + " ",
            label: lang.modeling_settings.user.company_users + " ",
            className: "tier-group",
            isDisabled: true,
            isGroupTitle: true,
        })
        usersData.map((item) => {
            userOptions.push({
                value:item.id,
                label:item.name,
                email:item.email,
                isSystem:item.is_system,
                className: "tier-name",
            })
        })

        var index =  userOptions.findIndex(item => item.isSystem);
        userOptions.splice(index,0, {
            value: lang.modeling_settings.user.system_users + " ",
            label: lang.modeling_settings.user.system_users + " ",
            className: "tier-group",
            isDisabled: true,
            isGroupTitle: true,
        })
        return userOptions;
    }

    componentDidUpdate(nextProps) {
        if ((nextProps.userAllowedMenuLinks !== this.props.userAllowedMenuLinks) || this.state.tabLables.length === 0) {
            var data = nextProps.userAllowedMenuLinks || this.props.userAllowedMenuLinks;
            // var dataModeling = data && data.filter(e => e.section_return_name.toLowerCase() === _dataModeling)[0];
            // var modelingSettings = dataModeling && dataModeling.children && dataModeling.children.filter((e) => e.section_return_name === _modelingSettings)[0];
            var labels = [];
            let menuLinksChildren = getMenuLinksChildren(data, MENU_ITEM.FIELDS.DATA_MODELING, ALL_WIDGETS.MODELING_SETTINGS)
            if (menuLinksChildren && menuLinksChildren.children) {
                for (var e in menuLinksChildren.children) {
                    labels.push(menuLinksChildren.children[e].menu_item_display_name);
                }
                var tabSelected = this.state.tabSelected ? this.state.tabSelected : labels.includes(lang.modeling_settings.data_source.titles.periods) ? lang.modeling_settings.data_source.titles.periods : lang.modeling_settings.data_source.titles.data_sources

                this.setState({
                    tabLables: labels,
                    tabSelected: tabSelected,
                });
            }
        }
    }

    checkHasChange = () =>{
        return (this.periodsRef && this.periodsRef.fiscalCalendarRef.state.isChanged) || (this.dataSourcesRef && this.dataSourcesRef.state.changesNotSaved);
    }

    /**
     * show confirmation dialog if the user tries to change the selected tab while having changes
     * @param {*} tab 
     */
    showOnChangeColumnModal=(tab)=>{
        if((this.periodsRef && this.periodsRef.fiscalCalendarRef.state.isChanged) || (this.dataSourcesRef && this.dataSourcesRef.state.changesNotSaved)){
            this.setState({
                tabToSelect:tab,
                isChangingTab:true
            },function(){
                this.setChangeColumnDefinitionOpen(true)
            })
        }else{
            this.changeColumns(tab)
        }
    }

    /**
     * if the user confirms that he want to save his changes before switching to the new tab
     */
    saveChangesThenChangeColumn=(callback)=>{
        let _this = this;
        // if(this.state.tabToSelect === lang.modeling_settings.data_source.titles.periods){
        //     this.dataSourcesRef.save(this.state.tabToSelect);
        //     if (callback && typeof callback === "function") {
        //         callback();
        //     }
        // } else {
            if(_this.dataSourcesRef){
                _this.dataSourcesRef.save(this.state.tabToSelect,callback);
            }else{
            _this.periodsRef?.fiscalCalendarRef.saveFiscalPeriods(this.state.tabToSelect, callback);
            }
        // }
        this.setChangeColumnDefinitionOpen(false)
    }


    /**
     *  if the user confirms that he want to discard his changes before switching to the new tab
     */
    resetThenChangeColumn=()=>{
        let _this = this;
        this.setState({
            sendOriginalData: true,
            fileCensusData: _this.state.tabToSelect === lang.modeling_settings.data_source.titles.periods? copyObjectValues(_this.state.originalFileCensusData): _this.state.fileCensusData
        },function(){
            _this.changeColumns(_this.state.tabToSelect);
        })
        this.setChangeColumnDefinitionOpen(false)
    }


    changeColumns = (tab) =>{
        let _this = this;
        this.setState({
            tabSelected: tab,
        }, function () {
            if(_this.periodsRef){
                let activePeriodsLength = this.props.fiscalPeriods.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
                _this.periodsRef.fiscalCalendarRef.setState({
                    activePeriodsLength:activePeriodsLength,
                })
            }
        });
    }

    /**
     * called from dataSources when saving fileCesnsus
     * @param {*} data 
     * @param {*} switchAfter 
     */
    setData=(data, switchAfter, switchCallback)=> {
        let _this = this;
        _this.setState({
            fileCensusData: data
        },function(){
            _this.saveFileCensusSections(switchAfter, switchCallback);
        });
    }

    saveFileCensusSections=(switchAfter, switchCallback)=> {
        toggleLoader(true, 'saveFileCensusSections');
        var obj = this;
        var query = {
            action: "saveFileCensusSections",
            fileCensusSections: obj.state.fileCensusData,
            userNotifications:JSON.stringify(obj.dataSourcesRef.state.multiValue)
        }
        console.log(obj.state)
        var myUrl = baseUrl+"/DataModeling";
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        $.ajax({
            url: myUrl, 
            type: 'POST',
            async: true, 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query), 
            success: function () {
                if(obj.dataSourcesRef){
                    obj.dataSourcesRef.setState({
                        changesNotSaved:false,
                        isError:false
                    },function(){
                        toggleLoader(false, 'saveFileCensusSections');
                        if(switchAfter){
                            obj.dataSourcesRef.launchToast(true);
                        }
                        obj.getFileCensusSectionsData(true,switchAfter);
                    })  
                } else{
                    toggleLoader(false, 'saveFileCensusSections');
                    obj.getFileCensusSectionsData(true);
                }
                if(switchCallback && typeof switchCallback === 'function'){
                    switchCallback()
                }
            },
            error:function(jqXHR, exception) {
                toggleLoader(false, 'saveFileCensusSections');
                obj.setState({
                    isError:true,
                    changesNotSaved:true,
                },function(){
                    obj.dataSourcesRef.launchToast(false);
                })
            }
        });
    }

    formatData=(data)=> {
        for(var e in data) {
            data[e].index = shortid.generate();
            if(data[e].subsection === undefined) {
                data[e].subsection = data[e].section.replace(/[^\w\s]/g, '').replace(/  /g, ' ');
                data[e].update_flag = "-1";
            }
            if(data[e].insert_flag === "1") {
                data[e][FILE_CENSUS_FIELDS.EXPECTED_FILES_FIELD] = "1";
                data[e][FILE_CENSUS_FIELDS.PRIVATE] = false;
                data[e][FILE_CENSUS_FIELDS.DELIMITER] = Delimiters.Comma;
                data[e][FILE_CENSUS_FIELDS.HEADER_ROWS_TO_SKIP] = 1;
                data[e][FILE_CENSUS_FIELDS.NAMING_TEMPLATE] = data[e].subsection.toLowerCase().replace(/ /g,"_");
            }

            if(data[e].end_period === undefined && data[e].start_period !== undefined) {
                data[e].end_period = PERIOD_ONWARD;
            }else if( data[e].start_period === undefined && data[e].end_period === undefined) {
                data[e].update_flag = "-1";
            }
            if(data[e][FILE_CENSUS_FIELDS.EXPECTED_FILES_FIELD] === undefined) {
                data[e][FILE_CENSUS_FIELDS.EXPECTED_FILES_FIELD] = "1";
            }
        }
        return data;
    }

    /**
     * reset File census to the original data
     * @returns 
     */
    resetFileCensus=()=>{
        let _this = this;
        this.setState({
            fileCensusData: copyObjectValues(_this.state.originalFileCensusData),
            userNotifications:copyObjectValues(_this.state.originalUserNotifications)
        })
        _this.dataSourcesRef?.setState({
                multiValue:copyObjectValues(_this.state.originalUserNotifications)
            })
        
    }

    setChangeColumnDefinitionOpen = (isOpen) => {
      let _this = this;
      _this.setState({
          openChangeColumnDefinition: isOpen,
      })
    }

    changeColumnDefinitionContent = () => {
      return <h4>{lang.dashboards.messages.want_to_save_first}</h4>   
    }

    changeColumnDefinitionActions = () => {
      return (
        <>
          <Button
            id="create-scenario"
            label={lang.modal.buttons.yes}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={this.saveChangesThenChangeColumn}
          />
          <Button
            label={lang.modal.buttons.no}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={this.resetThenChangeColumn}
          />
          <Button
            label={lang.modal.buttons.cancel}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => this.setChangeColumnDefinitionOpen(false)}
          />
        </>
      )
    }

    render(){
      let _this = this;
      return (
        <div>
          <div className="uk-margin-default-bottom">
            <Tabs ref={(el) => (this.tabsRef = el)} activeTab={!this.state.tabSelected ? lang.modeling_settings.data_source.titles.periods : this.state.tabSelected} onChange={this.showOnChangeColumnModal} tabs={this.state.tabLables} isModelingSettings={true} />
          </div>

          {this.state.tabSelected === lang.modeling_settings.data_source.titles.data_sources &&
            <div className='dataSourcesContainer'>
              <DataSources ref={(el) => (this.dataSourcesRef = el)} resetFileCensus={_this.resetFileCensus} originalFileCensusData={_this.state.originalFileCensusData} data={_this.state.fileCensusData} periods={this.props.periods} userAllowedMenuLinks={this.props.userAllowedMenuLinks} setData={this.setData} userOptions={this.state.userOptions}
                userNotifications={this.state.userNotifications} clientId={this.props.clientId} user={this.props.user} machineName={this.props.machineName} isBudgetingAllowed={_this.state.isBudgetingAllowed} isAutomationAllowed={_this.state.isAutomationAllowed} />
            </div>
          }
          {this.state.tabSelected === lang.modeling_settings.data_source.titles.periods &&
            <Periods ref={(el) => (this.periodsRef = el)} machineName={this.props.machineName} periods={_this.state.sendOriginalData ? copyObjectValues(this.props.fiscalOriginalPeriods) : copyObjectValues(_this.props.fiscalPeriods)} clientId={this.props.clientId}
              active_period_limit={this.props.active_period_limit} fiscalOriginalPeriods={this.props.fiscalOriginalPeriods} getClientPeriods={this.props.getClientPeriods} isChangingTab={this.state.isChangingTab} dispatch={_this.props.dispatch} changingReport={_this.props.changingReport} />
          }
          <Modal
            id={"change-column-definition-dialog"}
            openDialog={this.state.openChangeColumnDefinition}
            bodyContent={this.changeColumnDefinitionContent}
            dialogActions={this.changeColumnDefinitionActions}
            closeClick={() => this.setChangeColumnDefinitionOpen(false)}
            size={DIALOG_SIZE.MEDIUM}
          />
        </div>
       )
    }
}



export default ModelingSettings