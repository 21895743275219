import React, { useState } from 'react'
// import ToggleSwitch from './ToggleSwitch'
import { BUTTON_TYPE, BUTTON_VARIANT, SIDE_PANEL_BUILD, SIZES } from '../../class/constants'
import '../../styles/colors.css'
import './sidePanelNew.css'
import Button from '../../newComponents/Button'
import CheckBox from '../../newComponents/CheckBox'


const SidePanelNew = ({
  close,
  show,
  body,
  buttons,
  onSubmit,
  addNotify,
  largeDisplay,
  title,
  showTooltipMessage,
  disableCheckbox,
  id,
  columnButtonsDisplay,
  notifyMsg=SIDE_PANEL_BUILD.CHECKBOXES.NOTIFY_EMAIL,
  notifyTooltip="Choose to be notified by email of your build status",
  halfScreenDisplay,
  xLargeDisplay,
  columnButtonsDisplayReverse
}) => {
    const [checked, setIsChecked] = useState(true)
    const submit =(button)=>{
        let callback = (fromSave) => { close(fromSave) }
        let validate = "";
        if(button.onClick && typeof button.onClick === 'function') {
            button.onClick(callback);
        } else if(button.isSubmit) {
            validate = onSubmit(checked, callback);
        }
        if(validate !== -1){
            // close();
        }
    }
    const renderButtons = () => {
        let returnButtons=[]
        returnButtons=buttons.map(button => {
            return(
                <div key={button.button} uk-tooltip={button.hoverMessage?button.hoverMessage:undefined} className='max_width'>
                    <Button 
                        key={button.button}
                        id={button?.id}
                        label= {button.button}
                        variant={button.isSubmit ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
                        size={SIZES.LARGE}
                        type={BUTTON_TYPE.DEFAULT}
                        disabled={button.isDisabled}
                        className={"uk-margin-small-right max_width"}
                        onBtnClick={() => submit(button)}
                    />
                </div>  
            )
        });
        return returnButtons
    }

    var drawerClassName = largeDisplay ? " side-drawer-large " : "";
    drawerClassName = xLargeDisplay ? " side-drawer-xlarge " : drawerClassName;
    drawerClassName = halfScreenDisplay ? " side-drawer-half" : drawerClassName;
    
    return (
        <div id={id ? id : ""} className={show ? ("side-drawer open uk-border" + drawerClassName) : "side-drawer uk-border "}>
            {largeDisplay?
                <div className="inner-container-padding drawer_title_body">
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <div>
                            <span className="main_side_panel_title">
                                {title}
                            {showTooltipMessage ?
                                <i className="fal fa-info-circle uk-margin-small-left info-icon" uk-tooltip={showTooltipMessage} />
                                : ""}  
                            </span> 
                        </div>
                        <Button  
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            className="close-button uk-margin-remove"
                            aria-label="Close"
                            leftIcon={<span aria-hidden="true"><i className="fal fa-times"></i></span>}
                            onBtnClick={close} 
                        />
                    </div>
                    <div id="drawer_body_container" className="uk-margin-medium-top drawer_body_container">
                        {body}
                    </div>
                </div>
                :
                <div className='drawer_title_body'>
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <div>
                            <span className="main_side_panel_title">
                                {title}
                            </span>
                            {showTooltipMessage ?
                                <i className="fal fa-info-circle uk-margin-small-left info-icon" uk-tooltip={showTooltipMessage} />
                                : ""} 
                        </div>
                        <Button
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            className="close-button uk-margin-remove"
                            aria-label="Close"
                            leftIcon={<span aria-hidden="true"><i className="fal fa-times"></i></span>}
                            onBtnClick={close}
                        />
                    </div>
                    <div id="drawer_body_container" className="drawer_body_container">
                        {body}
                    </div>
                </div>
            }
            <div style={{ flexDirection: columnButtonsDisplayReverse ? "column-reverse" : columnButtonsDisplay ? "column" : "unset" }} className="drawer_button_checkbox_container">
                {addNotify?
                    <label className="uk-flex-inline uk-flex-middle uk-margin-small-right uk-cursor-pointer uk-width-1-1" htmlFor="notify-by-email">
                        <CheckBox className="uk-margin-xsmall-right" id="notify-by-email" defaultChecked={checked} onClick={() => {setIsChecked(!checked)}} disabled={disableCheckbox} />
                        <span className="notify-text fs-14">{notifyMsg}
                            <i className="fal fa-info-circle uk-margin-small-left fs-12" uk-tooltip={notifyTooltip} />
                        </span>
                    </label>
                :""}
                {renderButtons()}
            </div>
        </div>
    )
}
export default SidePanelNew