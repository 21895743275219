import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { convertPxToViewport } from '../../class/formatting.js';
import { buildNewObject, capitaliseFirstLetterAfterChar, getIndexOf } from '../../class/utils.js';
import { lang } from '../../language/messages_en';
import Select from 'react-select';
import Button from "../../newComponents/Button.js";
import { BUTTON_TYPE, BUTTON_VARIANT, DROPDOWN_TYPE, SIZES } from '../../class/constants.js';
import DropDown from '../../newComponents/DropDown.js';


/**
 *  FilterByGLSource found in stage->ProfitStackMapping header above GL combinations table
 * component recieves data and columns displays them along with apply and clear filter 
 * it filters the data of the GL Combination table
 * @author [Sarah Farjallah]
 * @extends Component
 * **/
const $ = require('jquery');
const customStyles = {
    option: (styles) => ({
        ...styles,
        padding: convertPxToViewport(8) + ' ' + convertPxToViewport(12),
    }),
    control: (provided) => ({
        ...provided,
        minHeight: convertPxToViewport(38),
        borderRadius: convertPxToViewport(4),
        borderWidth: convertPxToViewport(1),
    }),
};
class FilterByGLSource extends Component {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            showContainer: false,
            selectedCol:{},
            selectedColExport:{},
            data:[],
            cols:[],
            selections:[],
            options:[]
        };

        this.colOrder = [];
    }

    

    componentDidUpdate(prevProps) {
        if(!prevProps.data && this.props.data) {
            this.setState({
                data: this.props.data
            })
            this.prepareGLSourceElements();
        }
    }
    
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        // this.prepareGLSourceElements();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
    * Set the wrapper ref
    */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !event.target.className["combinations-filter__option"] && !event.target.className["split__button--modal"] && event.target.closest("split__button--modal") == null && !this.state.clearSelectedValues) {
            this.showHideContainer();
        }
    }
    /**
     * function calls a parent function to apply filter on tabulator and closes container
     */
    apply=()=>{
        let _this = this;
        let selectedColKeyslength = _this.state.selectedCol ? Object.keys(_this.state.selectedCol).length : 0;
        let selectCol = selectedColKeyslength.length === 0 ?  undefined : _this.state.selectedCol;
        _this.props.applyFilter(selectCol,_this.state.linkedTransitionSelecSted, _this.state.cols);
        _this.showHideContainer();
        _this.props.resetCheckedCombinations(_this.props.data);
    }
    /**
     * function sets selectedCol state to undefined to clear drop downs and calls a parent function to clear filter on tabulator and closes container
     */
    clear=(hide)=>{
        let _this = this;
        this.setState({
            selectedCol: undefined,
            selectedColExport:undefined,
            linkedTransitionColumn: undefined,
            linkedTransitionSelected: undefined,
            selections: []
        },function(){
            if (!hide){
                _this.showHideContainer();
            }
            _this.props.clearFilter();
            _this.props.resetCheckedCombinations(_this.props.data);
        });
    }

    /**
     * delete the col we are resetting from state.selectedCol
     * @param {*} col 
     * @returns 
     */
    deleteColFromSelectedCol=(col)=>{
        let selectedCol = this.state.selectedCol;
        delete selectedCol[col];
        return selectedCol;
    }

    handleChange=(selectedOptions, col)=>{
        let _this = this;
        let selectedCol = _this.state.selectedCol ? _this.state.selectedCol : {};
        let selections = _this.state.selections ? _this.state.selections : [];
        let optionsArr = _this.state.options[col];
        if(selectedOptions === null || (selectedOptions && selectedOptions.length === 0)){
            selectedCol = this.deleteColFromSelectedCol(col);
            delete selectedCol.col;
            _this.setState({
                clearSelectedValues: selectedOptions && selectedOptions.length === 0? true : false,
                ["reset-"+col]:true,
            })
            selections[col] = selectedOptions;
            
        } else {
            
            var arrayOfValues = [];
            if (selectedOptions && typeof selectedOptions === "object" && !Array.isArray(selectedOptions)) {
                selectedOptions = [selectedOptions];
            }
            if (selectedOptions && selectedOptions.length) {
                selectedOptions.map(labelValObj => {
                    if (labelValObj.action === "set-value" && labelValObj.value.includes(",")) {
                        let tempValues = labelValObj.value.split(",");
                        tempValues.map(stringVal => {
                            if (stringVal === "") {
                                return;
                            }
                            arrayOfValues.push({ label: stringVal.trim(), value: stringVal.toLowerCase().trim(), name: labelValObj.name, col:labelValObj.col, transition: optionsArr.find(e=> e.label.split('-')[0] === stringVal.trim()).transition });
                        });
                    } else {
                        labelValObj.value = labelValObj.value.toLowerCase();
                        arrayOfValues.push(labelValObj);
                    }
                });
                let selectedColExport = this.state.selectedColExport || {};	
                let linkedTransitionSelected = [];	
                let values = "";	
                arrayOfValues.forEach(element=>{	
                    if(values !== ""){	
                        values += ","	
                    }	
                    values += element.value;	
                });	
                selectedCol[col] = values;
                selectedColExport[col] = values;	
                selectedOptions.map(function(option){
                    if (option.transition !== "") {	
                        linkedTransitionSelected.push(option.transition);	
                        selectedColExport[option.col] = option.value + "-" + option.transition;
                        } 
                    }
                )	
                	
                var uniq = new Set(arrayOfValues.map(e => JSON.stringify(e)));	
                arrayOfValues = Array.from(uniq).map(e => JSON.parse(e));
                arrayOfValues.map(e=>e.label = e.value);
                selections[col] = arrayOfValues;
                _this.setState({		
                    selectedColExport:selectedColExport,	
                    linkedTransitionSelected: linkedTransitionSelected,	
                    resetAll: false,	
                    ["reset-"+col]: false,	
                    clearSelectedValues: false,	
                });
            } else {
                this.loadOptionsOnInputChange();
                var uniq = new Set(arrayOfValues.map(e => JSON.stringify(e)));
                arrayOfValues = Array.from(uniq).map(e => JSON.parse(e));
                selections[col] = arrayOfValues;
            }
        }
        _this.setState({
            selections: selections,
            selectedCol: selectedCol
        });
    }
    /**
     * function to show/hide container on click of apply, clear and filter
     */
    showHideContainer=()=>{
        this.setState({
            showContainer: !this.state.showContainer,
            clearSelectedValues: false
        })
    }
    /**
     * function to load data while typing in filter
     */
    loadOptionsOnInputChange = (inputValue, callback, col) => {
        let _this = this;
        clearTimeout(this.entitiesTimeout);
        this.entitiesTimeout = setTimeout(function(){
            var keys = ["label", "value", "name", "action" ,"col", "transition"];
            var inputValObj = [inputValue, inputValue, "entity", "set-value", col, ""];
            inputValue = inputValue === undefined ? "" : inputValue;

            var optionsArr = _this.state.options[col].filter(e => e.label.toLowerCase().includes(inputValue.toLowerCase()));
            let optionsSet = new Set(optionsArr.map(e => JSON.stringify(e)));
            optionsArr = Array.from(optionsSet).map(e => JSON.parse(e));

            let inputOption = buildNewObject(keys, inputValObj);
            if (inputValue !== "") {
                optionsArr.unshift(inputOption);
            }
            // Allows input value to be added directly to dropdwon options
            if(callback && typeof callback === "function") {
                callback(optionsArr);
            }
        }, 300);

    }

    /**
     * function takes data and return an array of labels and drop downs
     */
     prepareGLSourceElements=()=>{
        let cols = [];
        let _this = this;
        let data = _this.state.data.length > 0 ? _this.state.data : _this.props.data;
        let options = [];
        let col;
        let data_col;
        for (var e in data) {
            col = Object.keys(data[e])[0];
            _this.colOrder.push(col)
            data_col = data[e][col];
            for(var i=0 ; i < data_col.length ; i++){
                data_col[i].label = data_col[i].label?capitaliseFirstLetterAfterChar(data_col[i].label).replaceAll("_"," "):"";
            }
            options[col] = data_col;
            cols.push(col);
        }
        _this.setState({
            cols: cols,
            options: options,
        });
    }

    onClearClick=()=>{
        this.clear();
    }

    render() {
        let _this = this;
        // let glsourceElements = this.renderGLSourceElements();
        return(
            <div className="uk-position-relative">
                <div className={`${(!this.state.showContainer ? "" : "dark")} split__button--modal uk-button-icon`} onClick={this.showHideContainer}>
                    <i className={(this.state.selectedCol && Object.keys(this.state.selectedCol).length > 0 ? "fas" : "far") + " fa-filter uk-padding-xsmall-right"} aria-hidden="true"></i>
                    <span>{lang.filter_text.filter}</span>
                    <i className="far fa-chevron-down uk-padding-xsmall-left"></i>
                </div>
                {this.state.showContainer ? 
                    <div ref={this.setWrapperRef} className="glsource-container">
                         {this.state.cols.map(col =>(
                                <div className="uk-margin-small-bottom">
                                    <div className="uk-margin-xsmall-bottom fs-14">{capitaliseFirstLetterAfterChar(col.replace(/_/g," ")," ")}
                                    </div>
                                    <AsyncSelect
                                        id="select-set"
                                        className="width-250 uk-cursor-pointer"
                                        classNamePrefix="combinations-filter"
                                        name={col}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        placeholder={"Choose "+ capitaliseFirstLetterAfterChar(col.replace(/_/g," ")," ")}
                                        value={_this.state.selections ? _this.state.selections[col] : ""}
                                        onChange={(e) => _this.handleChange(e, col)}
                                        options={_this.state.options[col]}
                                        defaultOptions={_this.state.options[col]}
                                        styles={customStyles}
                                        maxMenuHeight= {convertPxToViewport(300)}
                                        loadOptions={(e, callback) => _this.loadOptionsOnInputChange(e, callback, col)}
                                    />
                                 {/* <DropDown // To remove comment and comment <AsyncSelect
                                        id="select-set"
                                        className="width-250 uk-cursor-pointer"
                                        classNamePrefix="combinations-filter"
                                        name={col}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        placeholder={"Choose "+ capitaliseFirstLetterAfterChar(col.replace(/_/g," ")," ")}
                                        value={_this.state.selections ? _this.state.selections[col] : ""}
                                        onChange={(e) => _this.handleChange(e, col)}
                                        options={_this.state.options[col]}
                                        defaultOptions={_this.state.options[col]}
                                        maxMenuHeight= {convertPxToViewport(300)}
                                        loadOptions={(e, callback) => _this.loadOptionsOnInputChange(e, callback, col)}
                                        type={DROPDOWN_TYPE.INPUT}
                                        isAsync = {true}
                                    /> */}
                                </div>
                            ))}
                        <div className="uk-flex uk-flex-between">
                            <button className={"text-link uk-margin-xsmall-left "} onClick={this.onClearClick}>{lang.modal.buttons.clear}</button>
                            <Button 
                                label={lang.modal.buttons.apply}
                                variant={BUTTON_VARIANT.PRIMARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}  
                                onBtnClick={this.apply}
                            />
                        </div>
                    </div>
                : ""}

            </div>
     
        );

    }

}

export default FilterByGLSource;