// ChatbotWrapper.js
import React from 'react';
import CustomChatBot from "./customChatBot";
import "./ChatBotWrapper.css";

function ChatbotWrapper() {
    return (
        <div className="chat-wrapper">
            <CustomChatBot />
        </div>
    );
}

export default ChatbotWrapper;