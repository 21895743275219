import React, { Component } from "react";
import { CustomSelect } from '../form/elements.js';
import { DROPDOWN_TYPE, MANAGE_COLUMNS } from "../class/constants";
import { findOptionByKey } from "../class/utils";
import { FormComponent } from "../form/FormElements";
import { lang } from "../language/messages_en";
import Input from "../newComponents/Input.js";
import Dropdown from "../newComponents/DropDown.js";
const $ = require('jquery');
/**
 * Dynamic client format componenr to be used with all fields sent as props
 * @author [Mostafa Haydar]
 */

class ClientFormat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formatValue: this.props.formatType,
            unitValue:"",
            showUnit:false
        }
        this.formatHandleChange = this.formatHandleChange.bind(this);
        this.unitHandleChange = this.unitHandleChange.bind(this);
        this.getSelectedFormat = this.getSelectedFormat.bind(this);
        this.getSelectedUnit = this.getSelectedUnit.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    validateFields(formatType) {
        var result = true;
        let isFormatValid = "";

        if ((this.state.formatValue === undefined || this.state.formatValue === "") && !formatType) {
            isFormatValid = lang.manage_columns.format_empty;
            result = false;
        }
        this.setState({
            isFormatValid: isFormatValid
        })
       return result;
    }
    
    formatHandleChange(event){
        this.setState({
            formatValue: event,
            showUnit: event.value === MANAGE_COLUMNS.MEASUREMENT,
            isFormatValid: ""
        })
    }

    unitHandleChange(event){
        const _this = this;
        var val = $(event.currentTarget).val();
        this.setState({
            unitValue: val
        },function(){
            if(typeof _this.props.handleUnitChange === "function") {
                _this.props.handleUnitChange();
            }
        });
    }

    setSelectedFormat() {
        this.setState({
            formatValue: "",
            showUnit: ""
        }) 
    }
    
    getSelectedFormat(){
        return this.state.formatValue;
    }

    getSelectedUnit(){
        return this.state.unitValue;
    }

    componentDidUpdate() {
        if(this.state.formatValue === "" && this.props.formatType !== "") {
            this.setState({
                formatValue: findOptionByKey(this.props.formats, this.props.formatType),
                unitValue: this.props.unit,
                showUnit: this.props.formatType === MANAGE_COLUMNS.MEASUREMENT
            })
        }
    }

    render() {
        const selectOption = ({label, value, unit_sample}) => (
            <div className={"uk-flex-between uk-display-flex"}>
                {label} 
                <em className="text-grey">{unit_sample}</em>
            </div>
        );
        
        return (
            <div className="uk-flex-inline uk-width-1-1">
                <div className="uk-width-1-1">
                    <label htmlFor={lang.manage_columns.format} className="fs-14">{lang.manage_columns.format}<span className="input_required_text">{"(Required)"}</span></label>
                    <Dropdown
                        id={lang.manage_columns.format}
                        value={findOptionByKey(this.props.formats, this.state.formatValue || this.props.formatType)}
                        onChange={this.formatHandleChange}
                        options={this.props.formats}
                        formatOptionLabel={selectOption}
                        menuPlacement="top"
                        placeholder={lang.manage_columns.select_format}
                        type={DROPDOWN_TYPE.INPUT}
                        className="input__dropdown"
                    />
                    {this.state.isFormatValid && this.state.isFormatValid !== "" ? <div className="fs-12 red italic">{this.state.isFormatValid}</div> : ""}
                </div>
                {this.state.showUnit?
                    <div className="uk-width-1-1 uk-margin-default-left">
                        <label htmlFor={lang.manage_columns.unit} className="fs-14">{lang.manage_columns.unit}</label>
                        <FormComponent tag="input" className="uk-input" placeholder={lang.manage_columns.type_unit}
                            onChange={(e)=>{this.unitHandleChange(e)}} value={this.state.unitValue}/>
                    </div>
                :""
                }
            </div>
        );
    }
}

export default ClientFormat;
