import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React, { Component } from 'react';
import { components } from 'react-select';
import shortid from 'shortid';
import { addElement, addElements, deleteEmptyIndices, findIndexOfValue, getEmbeddedChild, getEmbeddedChildren, getHighestKey, getLowestKey, getMissingItems, getObjectValues, getPathToEmbeddedChild, linearizeHierarchy, removeDuplicateKeyValues, removeDuplicates, updateAllElements, updateElementsWithChildren } from '../../class/array';
import {
    ALL_WIDGETS,
    API_URL,
    COLUMN_PROFILE,
    column_suffixes,
    column_suffixes_values,
    DASHBOARDS,
    IDENTIFIERS,
    INVOICE_LINE,
    MACHINE_NAME_SUFFIXES,
    MANAGE_COLUMNS,
    PROFILE_COLUMN,
    PSL_RETURN_NAMES,
    SECTION,
    SELECTED_DRILL_LIST,
    SELECTED_PROFILE_EROSION,
    STAGING_SECTIONS_ARRAY_TITLES,
    VECTOR_STAGING_ATTRIBUTES,
    DATA_LAYER_PARAMS,
    BUTTON_VARIANT,
    SIZES,
    BUTTON_TYPE,
    DROPDOWN_TYPE,
    DIALOG_SIZE,
    SELECTED_VECTOR_VIEWS,
    vectorViews,
    SORT_TITLES
} from '../../class/constants';
import { convertPxToViewport } from '../../class/formatting';
import { vectorSpacing } from '../../class/jqueries';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../../class/networkUtils';
import { getItemFromStore } from '../../class/reduxStoreUtils';
import { checkStartsWithDigit, replaceAllInstancesOf, replaceSpecialChars } from '../../class/string';
import { capitalizeFirstLetter, copyObjectValues, deepCompareObjects, findOptionByKey, findOptionByKeyValue, getTranslationFile, parseBoolean, removeAttributes, shallowCompare, sliceCharLength, tryParse } from '../../class/utils';
import { CustomSelect, ToggleTab } from '../../form/elements.js';
import { FormComponent } from '../../form/FormElements';
import { updateColumnProfiles, updateColumns, updateCustomGroups, updateProfiles, updateStateProfiles } from '../../actions/manageColumnsActions';
import { getVectorAllowedAttributes } from '../../sections/manageAccess/ManageAccessUtils';
import '../../styles/manageColumns.css';
import ClientFormat from '../ClientFormat';
import SearchableSelect from '../SearchableSelect';
import SidePanel from '../SidePanel';
import ManageColumnsFormat from './ManageColumnsFormat';
import ProfileColumn from './ProfileColumn';
import Button from '../../newComponents/Button';
import Input from '../../newComponents/Input';
import Dropdown from '../../newComponents/DropDown';
import Modal from '../../newComponents/Modal';
import SidePanelNew from '../sidePanelNew/SidePanelNew';
import Backdrop from '../sidePanelNew/Backdrop';
import { logout } from '../../templateLayout/api/api';

const lang = getTranslationFile();
const UIkit = require('uikit');
const $ = require('jquery');
const _selectedBubbleList = "selectedBubbleList";
//profile attributes
const _profileId = COLUMN_PROFILE.ID;
const _profileName = COLUMN_PROFILE.NAME;
const _label = COLUMN_PROFILE.LABEL;
const _visibility = COLUMN_PROFILE.VISIBILITY;
const _columnProfileId = COLUMN_PROFILE.COLUMN_PROFILE_ID;
const _isSaved = COLUMN_PROFILE.IS_SAVED;
const _isApplied = COLUMN_PROFILE.IS_APPLIED;
const _isCompanyDefault = COLUMN_PROFILE.IS_COMPANY_DEFAULT;
const _isUserDefault = COLUMN_PROFILE.IS_USER_DEFAULT;
const _isValid = COLUMN_PROFILE.IS_VALID;
const _isModified = COLUMN_PROFILE.IS_MODIFIED;
const _profileColumns = COLUMN_PROFILE.COLUMNS;
const _createdBy = COLUMN_PROFILE.CREATED_BY;
const _isFavorite = COLUMN_PROFILE.IS_FAVORITE;
const _isCreatorSystem = COLUMN_PROFILE.IS_CREATOR_SYSTEM;
const _updatedBy = COLUMN_PROFILE.UPDATED_BY;
const _creatorName = COLUMN_PROFILE.CREATOR_NAME;
const _checkedItems = COLUMN_PROFILE.CHECKED_ITEMS;
const _simplifiedColumns = COLUMN_PROFILE.SIMPLIFIED_COLUMNS;
const _visibilityOptions = COLUMN_PROFILE.VISIBILITY_OPTIONS;
const _displayName = COLUMN_PROFILE.DISPLAY_NAME;
const _formatType = PROFILE_COLUMN.FORMAT_NAME;
const _unit = PROFILE_COLUMN.UNIT;
const _vectors = "Vectors";
const _treeChildren = "children"; 
const _class = "className";
const _profit = "profit";
const _profit_perc = "_perc";
const _opp = "opp";
const _cc = "_cc";
const _cpcf = "_cpcf";

//column attributes
const _machineName = PROFILE_COLUMN.MACHINE_NAME;
const _columnName = PROFILE_COLUMN.NAME;
const _returnName = PROFILE_COLUMN.RETURN_NAME;
const _columnOrder = PROFILE_COLUMN.COLUMN_ORDER;
const _isChecked = PROFILE_COLUMN.IS_CHECKED;
const _isExpandable = PROFILE_COLUMN.IS_EXPANDABLE;
const _columnDescription = PROFILE_COLUMN.COLUMN_DESCRIPTION;
const _isExpanded = PROFILE_COLUMN.IS_EXPANDED;
const _hasBlueDot = PROFILE_COLUMN.HAS_BLUE_DOT;
const _children = PROFILE_COLUMN.CHILDREN;
const _level = PROFILE_COLUMN.LEVEL;
const _isGroup = PROFILE_COLUMN.IS_GROUP;
const _isInSearchResult = PROFILE_COLUMN.IS_IN_SEARCH_RESULT;
const _order = PROFILE_COLUMN.ORDER;
const _hasWarning = PROFILE_COLUMN.HAS_WARNING;
const _warningMessage = PROFILE_COLUMN.WARNING_MSG;
const _type = PROFILE_COLUMN.TYPE;
const _lineType = PROFILE_COLUMN.LINE_TYPE;
const _columnGroup = PROFILE_COLUMN.COLUMN_GROUP;
const _columnGroupLine = PROFILE_COLUMN.COLUMN_GROUP_LINE;
const _columnType = PROFILE_COLUMN.COLUMN_TYPE;
const _columnTypeLine = PROFILE_COLUMN.COLUMN_TYPE_LINE;
const _formula = PROFILE_COLUMN.FORMULA;
const _SelectedVectorLabel = MANAGE_COLUMNS.SELECTED_VECTOR_DISPLAY_NAME;


const _customSuffix = "_cpcf";
const _custom = "custom";
const _columnFieldId = PROFILE_COLUMN.COLUMN_FIELD_ID;
const _customGroupId = PROFILE_COLUMN.COLUMN_PROFILE_CUSTOM_GROUP;
const _formatId = PROFILE_COLUMN.FORMAT_ID;

const _vectorType = MANAGE_COLUMNS.VECTOR_TYPE;
const _vectorGroup = VECTOR_STAGING_ATTRIBUTES.GROUP;
const _vectorLabel = STAGING_SECTIONS_ARRAY_TITLES[2];
const _selectedVectorDisplay = MANAGE_COLUMNS.SELECTED_VECTOR_DISPLAY_NAME;
const _selectedVector = MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME;
const _invoiceLineType = INVOICE_LINE.value;
const _operations = INVOICE_LINE.vector_group;

const _accesschanged = "accesschanged";

const textLabelLength = 20;

/**
 * a bit of insight:
 * state.savedProfile refers to the profile that is being displayed in the board with its data unchanged as it came from API
 * state.modifiedProfile refers to a copy of the savedProfile after a change has been made on it
 * state.customProfile refers to the data object representing a profile after the "apply" button has been clicked -- it is an unsaved configuration
 */

class ManageColumns extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isBoardOpen: false,
            profileColumns: [],
            searchValue: "",
            allChecked: false,
            profileOptions: [],
            checkedItems: [],
            displayPanel:false,
            name: "",
            type: MANAGE_COLUMNS.VALUES[0],
            customGroup: "",
            formatType:"",
            customGroups: [],
            access: {
                edit_user_cols: this.props.manageColsAccess[ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS],
                view_company_cols: this.props.manageColsAccess[ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS],
                edit_company_cols: this.props.manageColsAccess[ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS],
                view_vector_cols: this.props.manageColsAccess[ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]
            },
            isProfileNameUnique: true,
            originalName: undefined,
            originalVisiblity:undefined,
            VisibilitySelected:_visibilityOptions.USER,
            isEditingColumn : false,
            namesFromIds:[],
            drawerOpen: false
        }
        
        let visibilityOptions = [{label: capitalizeFirstLetter(lang.manage_columns.visibility_options.user), value:lang.manage_columns.visibility_options.user, iconClass: "fa-lg fal fa-user"}];
        if(this.initialState.access.edit_company_cols) {
            visibilityOptions.push({label: capitalizeFirstLetter(lang.manage_columns.visibility_options.company), value:lang.manage_columns.visibility_options.company, iconClass: "fa-lg fal fa-users"});
        }
        this.initialState.visibilityOptions = visibilityOptions;
        this.state = Object.assign({}, this.state, this.initialState);

        this.toggleBoard = this.toggleBoard.bind(this);
        this.discardChanges = this.discardChanges.bind(this);
        this.discardAndClose = this.discardAndClose.bind(this);
        this.cancelSave = this.cancelSave.bind(this);
        this.fetchColumns = this.fetchColumns.bind(this);
        this.toggleColumn = this.toggleColumn.bind(this);
        this.filterColumns = this.filterColumns.bind(this);
        this.toggleAllColumns = this.toggleAllColumns.bind(this);
        this.showSaveProfileDialog = this.showSaveProfileDialog.bind(this);
        this.validateProfileName = this.validateProfileName.bind(this);
        this.makeProfileDefault = this.makeProfileDefault.bind(this);
        this.applyProfileChanges = this.applyProfileChanges.bind(this);
        this.onChangeProfile = this.onChangeProfile.bind(this);
        this.getProfileColumns = this.getProfileColumns.bind(this);
        this.setWarnings = this.setWarnings.bind(this);
        this.setAdditionalColumns = this.setAdditionalColumns.bind(this);
        this.startDelete = this.startDelete.bind(this);
        this.deleteColumnProfile = this.deleteColumnProfile.bind(this);
        this.startSave = this.startSave.bind(this);
        this.updateCheckedItems = this.updateCheckedItems.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onToggleBoard = this.onToggleBoard.bind(this);
        this.cancel = this.cancel.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.saveCreatedColumn = this.saveCreatedColumn.bind(this);
        this.handleChangeCustomGroup = this.handleChangeCustomGroup.bind(this);
        this.fetchCustomGroups = this.fetchCustomGroups.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.resetInputs = this.resetInputs.bind(this);
        this.isNameUnique = this.isNameUnique.bind(this);
        this.validateVisibility = this.validateVisibility.bind(this);
        this.checkIsProfileValid = this.checkIsProfileValid.bind(this);
        this.editColumn = this.editColumn.bind(this);
        this.isColumnInList = this.isColumnInList.bind(this);
        this.getProfilesUsingColumn = this.getProfilesUsingColumn.bind(this);
        this.onDeleteColumn = this.onDeleteColumn.bind(this);
        this.deleteField = this.deleteField.bind(this);
        this.getProfileInvalidColumns = this.getProfileInvalidColumns.bind(this);
        this.fetchAPI = fetchAPI.bind(this);
    }

    resetInputs(removeSearchValues = true){
        const _this = this;
        _this.nameInputRef.reset();
        _this.descriptionInputRef.reset();
        _this.nameInputRef.state.value = undefined;
        _this.descriptionInputRef.state.value = undefined;
        $("#"+MANAGE_COLUMNS.NAME_ID).val("");
        $("#"+MANAGE_COLUMNS.DESCRIPTION_ID).val("");
        let tempState = copyObjectValues(_this.state);
        if(removeSearchValues) {
            tempState.searchValue = "";
            tempState.isSearching = false;
        }

        tempState[MANAGE_COLUMNS.NAME_ID] = "";
        tempState[MANAGE_COLUMNS.DESCRIPTION_ID] = "";
        tempState.customGroup = "";
        tempState.VisibilitySelected = _visibilityOptions.USER;
        tempState.type = lang.manage_columns.TYPE_DEFAULT_VALUE;
        tempState.name = "";
        tempState.isNameValid = "";
        tempState.nameNotValidMessage = "";
        tempState.isTypeValid = "";
        tempState.isCustomValid = "";
        tempState.isEditingColumn = false;
        tempState.columnBeingEdited = undefined;
        tempState.nameWasEdited = false;
        tempState.formatType = "";
        tempState.visibilityChecked = undefined;
        tempState.visibilityCheckedlist = [];

        _this.setState(tempState, function(){
            _this.clientFormatRef.setSelectedFormat();
            _this.ManageColumnsFormat.resetInputs();
            
            let calculatedPSLState = copyObjectValues(_this.ManageColumnsFormat.calculatedPslRef.state);
            calculatedPSLState.pslFormula = {formula:[]};

            if(removeSearchValues) {
              calculatedPSLState.isSearching = false;
              calculatedPSLState.searchValue = "";
              $("#search_input_manage_columns").val('')
              $('#psl-search').val("");
            }
            _this.ManageColumnsFormat.calculatedPslRef.setState(calculatedPSLState);
        })
      }

    launchToast() {
        $(this.props.isLandscapeBubble ? "#toastManageCol-" + this.props.sendUniqueId : "#toastManageCol").addClass("show");
        setTimeout(function(){
            $('[id^="toastManageCol"]').removeClass("show");
        }, 4000);
    }

    handleChange(event) {
        const _this = this;
        if (event !== undefined && event !== "") {
            this.state.isTypeValid = "";
        }
        this.setState({
            type: event.value
        },function(){
            if(_this.ManageColumnsFormat) {
                _this.ManageColumnsFormat.resetInputs();
            }
        });
    }

    handleChangeCustomGroup(event) {
        let tempState = {}
        if (event !== undefined && event !== "") {
            tempState.isCustomValid = "";
        }
        tempState.customGroup = event;
        this.setState(tempState);
    }

    onInputChange(input, params) {
        if(input !== "") {
            var _this = this;
            clearTimeout(this.inputTimeout)
            this.inputTimeout =  setTimeout(function () {
                let tempState = {}
                tempState.customGroups = copyObjectValues(_this.state.customGroups);
                if (input !== "" && _this.state.customGroups.filter(e=>e.label.replace(MANAGE_COLUMNS.NEW_GROUP_LABEL,"").toLowerCase() === input.toLowerCase()).length ===0/*&& _this.state.customGroups.filter(e=>e.label.toLowerCase().includes(input.toLowerCase()) || e.value.toLowerCase().includes(input.toLowerCase())).length == 0*/){
                    tempState.elementAdded = true;
                    tempState.customGroups.unshift({label: input+ MANAGE_COLUMNS.NEW_GROUP_LABEL , value:input , column_profile_custom_group_id: 0})
                }
                _this.setState(tempState);
            }, 1000);
        }
    }

    updateFields(eventOrValue, attr) {
        let value = $(eventOrValue.currentTarget).val();
        let isNameValid = "";
        let nameWasEdited = false;
        let tempState={};
        if(attr === MANAGE_COLUMNS.NAME_ID){
            nameWasEdited = true;
            if (!!value) {
                if (!this.isNameUnique(value)) {
                    isNameValid = lang.manage_access.name_already_used;
                }
                if (checkStartsWithDigit(value)) {
                    isNameValid = lang.name_starting_number;
                }
            }
            tempState.name_id = value
        } else{
            tempState.description_id = value
        }
        tempState.isNameValid = isNameValid;
        tempState.nameWasEdited = nameWasEdited;
        this.setState(tempState);
    }

    init(fromMount) {
        let _this = this;
        if(fromMount && this.props.isCompareScenarios){
            return;
        }
        this.setState({
            columnProfiles: undefined,
            defaultProfileId: undefined,
            originalColumnProfiles: undefined,
            profileOptions: undefined,
        }, ()=>{
            this.fetchColumns();
            this.fetchProfiles();
            this.fetchCustomGroups();
        });
    }

    fetchAll(id, type){
        this.fetchColumns(id,type);
        this.fetchProfiles(id, type);
        this.fetchCustomGroups(type);
    }

    /**
     * this function takes the default profile column_profile_id from fetchColumns request,
     * and updates the saved profile according to this ID, from the data coming from fetchProfiles
     * @param {*} profiles 
     * @param {*} defaultProfileId 
     * @param {*} tempState 
     * @param {*} fromMount 
     */
    setDefaultProfileAsSelectedProfile(profiles, defaultProfileId, tempState, fromDelete=false, isProfileIdFromOutside=false) {
        const _this = this;
        if(profiles && defaultProfileId) {
            let tempProfile = profiles.filter(prf=>prf[_profileId] === defaultProfileId)[0];
            if(fromDelete) {
                this.profileBeforeChanges = copyObjectValues(tempProfile);// when deleting from main component replace the initial value
                this.applyProfileChanges(tempProfile, true, tempState, fromDelete);
                this.discardChanges(); // to discard all changes made on the deleted default profile
            } else {
                if (typeof _this.props.updateDrillProfile === "function") {
                    _this.props.updateDrillProfile(tempProfile);
                }
                if (this.props.isEditing || isProfileIdFromOutside) {
                    this.setState(tempState,function(){
                        _this.applyProfileChanges(tempProfile, false);
                    })
                } else {
                    //when fetching data from API, we are setting the default profile as selected, however if it happens after changing scenario and the
                    //selected profile was applied, the new must have the same flag. if it is not a case of change scenario, selected profile would be undefined
                    if(!!this.state.selectedProfile) {
                        if(tempProfile){
                            tempProfile[_isApplied] = this.state.selectedProfile[_isApplied] || false;
                        }
                    }
                    this.onChangeProfile(tempProfile, tempState, undefined, true);       //update profile for parent
                }
            }
        } else {
            if(this._isMounted) {
                this.setState(tempState, ()=>{_this.forceUpdate()});
            }
        }
    }

    clearManageColumnsFromStore=(scenarioId)=>{
        let _this = this;
        if (_this.props.dispatch) {
            _this.props.dispatch(updateProfiles([],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint));
            _this.props.dispatch(updateColumns([],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint));
            _this.props.dispatch(updateStateProfiles([],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint));
            _this.props.dispatch(updateColumnProfiles([],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint));
        }
    }

    clearAllManageColumnsFromStore=(scenarioId)=>{
        let _this = this;
        if (_this.props.dispatch) {
            _this.props.dispatch(updateProfiles([],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint), true);
            _this.props.dispatch(updateColumns([],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint), true);
            _this.props.dispatch(updateStateProfiles([],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint), true);
            _this.props.dispatch(updateColumnProfiles([],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint), true);
        }
    }

    simplifyProfileReturnNames(profile, fromDropDown) {
        // profile[_profileColumns] = profile[_profileColumns].map(col=>typeof col === "string" ? col : col[_returnName]);     //we only need the return names
        profile[_profileColumns] = this.getCheckedColumns(fromDropDown ? profile.originalColumns : undefined).map(col=>typeof col === "string" ? col : col[_returnName]);     //we only need the return names fromDropDown ? profile.originalColumns : undefined added ti scondition because when we select a profile from drop down we need to remove the applied and use the original columns       
        profile[_simplifiedColumns] = this.state.checkedItems ? this.state.checkedItems.filter(col=>profile[_profileColumns].includes(col[_returnName])) : [];

        return profile;
    }

    /**
     * this function calls the functions that prepare the data of the columns
     * for rendering. Only called when the selected profile changes (ex when discarding changes or selecting a new profile)
     * @param {*} columns               -- list of all columns
     * @param {*} profileCols    -- list of checked columns, when this is not provided, the _isChecked key data would be prepared on the API
     */
    updateDataFlags(columns, profileCols=undefined) {
        if(profileCols) {
            let tempObj = {
                returnNames: profileCols.map(col=>col[_returnName]),
            }
            columns = this.setupData(columns, tempObj);
        } else {
            columns = this.setupData(columns);
        }
        columns = this.setBlueDotFlags(columns, profileCols);

        return columns;
    }

    addNumber(input) {
        var counter = 0;
      
        function rec(data) {
          data.forEach(function(e) {
            if (typeof e == 'object' && !Array.isArray(e)) {
              e.number = counter++;
              for (var p in e) {
                if (typeof e[p] == 'object') rec(e[p])
              }
            } 
          })
        }
        rec(input)
      }
    
    getProfitStackLineColumns(data){

        data = data.filter(e => e.type === MANAGE_COLUMNS.PSL_ID)
        this.addNumber(data);
        return data;
    }

    addVectorGroup(data,vectorList){
        var vectorObject = {
            [_columnName]:_vectorLabel,
            [_returnName]:_vectorLabel,
            [_isGroup]:true,
            label:_vectorLabel,
            [_lineType]: _vectorType,
            [_type]: _vectorType,
            [_level]:0,
            [_children]:[]
        };
        var groups = vectorList ? vectorList.filter(e=>e.isGroupTitle) : [];
        var selectedVectorObject = {
            [_columnName]:_selectedVectorDisplay,
            [_returnName]:_selectedVector,
            [_isGroup]:false,
            label:_vectorLabel,
            [_lineType]:_vectorType,
            [_type]: _selectedVector,
            [_level]:1,
        };
        vectorObject[_children].push(selectedVectorObject);
        
         //if user has no access to the vectors, only return the selected vector
        if(this.state.access.view_vector_cols) {
            for (var g in groups){
                let obj = {};
                obj[_columnName]= groups[g][_vectorGroup];
                obj[_returnName]= groups[g][_vectorGroup]+"_group";
                obj[_isGroup] = true;
                obj[_type] = _vectorType;
                obj[_lineType]=_vectorType;
                obj[_level] = 1;
                obj[_children] = this.formatVectors(vectorList.filter(e=>e.vector_group ===  obj[_columnName] && !e.isGroupTitle));
                
            
                vectorObject[_children].push(obj);
            }
        }
        data.unshift(vectorObject);
    }

    formatVectors(vectorList){
        var formattedVectors = []
        for (var v in vectorList){
            let obj  = {};
            obj[_columnName]= vectorList[v].label;
            obj[_returnName]= vectorList[v].value;
            obj[_type] = _vectorType;
            obj[_level] = 2;
            obj[_isGroup] = false;
            formattedVectors.push(obj)
        }
        return formattedVectors;
    }

    addVector(vectorsList,vectorValue,vectorTitle,vectorGroup,vectorOrder){
        vectorsList.push({ 
            value: vectorValue,
            label: vectorTitle,
            className: "tier-option",
            [VECTOR_STAGING_ATTRIBUTES.GROUP]: vectorGroup,
            [VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER]: vectorOrder
        });
        return vectorsList;                              
    }

    //#region API requests
    fetchColumns(prfId, type) {
        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId
        if(!scenarioId) {
            return;
        }
        const _this = this;
        var query = {
            action: "fetchUserDefaultProfileColumns" + (this.props.comparisonScenarioId ? "Intersection" : ""),
            profitFormat: type || this.props.profitFormat.replaceAll(" ","_").toLowerCase(),
            scenario_id: scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId,
            comparison_scenario_id: this.props.comparisonScenarioId
        }
    
        let onThenCallback = (data)=>{
            let hasInvalidAccess = typeof _this.props.checkForLimitAccessMessage === "function" && _this.props.checkForLimitAccessMessage(data);
            if(hasInvalidAccess) {
                return;
            }
            if(_this.props.dispatch && !_this.props.isDashboards){
                // _this.props.dispatch(updateColumns([copyObjectValues(data)],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type ||_this.props.profitFormat)+"_"+_this.props.constraint));
            }
            if (data && data.data) {
                let clientProfiles = data.client_profiles;
                let defaultProfileId = data.default_profile_id;
                let viewOptions = data.viewOptions;
                let customGroupFields = data.customGroupFields;
                let lineAttributeOptions = data.data[1][_children];
                let formats = data.formats;
                var dataArr = tryParse(data.data);
                var groups =  dataArr.map(d => {
                    d.label= d[MANAGE_COLUMNS.COLUMN_RETURN_NAME];
                    d.value= d[MANAGE_COLUMNS.COLUMN_RETURN_NAME];
                    return d;
                });
                let vectorOptions = copyObjectValues(_this.props.vectorOptions);
                let newVectorOptions = this.addVector(vectorOptions,_invoiceLineType,vectorSpacing(_invoiceLineType),_operations,vectorOptions.length + 1);
                if(_this.props.profitFormat.toLowerCase() !== ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS){
                    this.addVectorGroup(dataArr,newVectorOptions);
                }
                groups.unshift({label:MANAGE_COLUMNS.VECTORS, value:MANAGE_COLUMNS.VECTORS});
                groups = groups.filter(e=>e.type !== MANAGE_COLUMNS.CUSTOM_ID);
                var profitStackLineColumns = this.getProfitStackLineColumns(dataArr,MANAGE_COLUMNS.LINE_ATTRIBUTE);
                var customGroups = dataArr.filter(e=>e.type === MANAGE_COLUMNS.CUSTOM_ID);
                dataArr = _this.updateDataFlags(dataArr);
                if (_this.props.dispatch && !_this.props.isDashboards) {
                    _this.props.dispatch(updateColumnProfiles([copyObjectValues(dataArr)],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type || _this.props.profitFormat)+"_"+_this.props.constraint))
                }
                let tempState = {
                    profileColumns: dataArr,
                    profileColumns_unchanged: dataArr,
                    profileColumns_linear: linearizeHierarchy(dataArr, _children),
                    profitStackLineColumns:profitStackLineColumns,
                    originalProfitStackLineColumns:copyObjectValues(profitStackLineColumns),
                    clientProfiles: clientProfiles,
                    defaultProfileId: defaultProfileId,
                    viewOptions: viewOptions,
                    formats:  formats.map(f=>{
                        f.value = f[MANAGE_COLUMNS.MACHINE_NAME];
                        f.label = f[MANAGE_COLUMNS.DISPLAY_NAME];
                        f.unit_sample = f[MANAGE_COLUMNS.UNIT_SAMPLE]
                        return f;
                    }),
                    vectorsList:newVectorOptions,
                    groups:groups,
                    lineAttributeOptions: lineAttributeOptions.map(lineAttribute => {
                        lineAttribute.label = lineAttribute[MANAGE_COLUMNS.COLUMN_NAME];
                        lineAttribute.value = lineAttribute[MANAGE_COLUMNS.COLUMN_RETURN_NAME];
                        lineAttribute.tooltipText= lineAttribute[MANAGE_COLUMNS.DESCRIPTION];
                        return lineAttribute;
                    }),
                    customGroupFields: customGroupFields
                };
                let profiles = _this.state.columnProfiles ||  getItemFromStore("stateProfiles", _this, (scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type ||_this.props.profitFormat)+"_"+_this.props?.constraint);

                // if(!this.props.isDashboards){
                let companyDefaultProfileId = !!profiles ? profiles.filter(col => col[_isCompanyDefault])[0][_profileId] : undefined;
                let tempProfileId = prfId ? prfId : !!_this.props.companyDefaultIsDefault ? companyDefaultProfileId : defaultProfileId;
                let isPropProfileDifferent = false;
                if (!!tempProfileId && !!_this.props.outsideColumnProfileId && _this.state.columnProfiles && _this.state.columnProfiles.filter(col => col[_profileId] === _this.props.outsideColumnProfileId).length > 0) {
                    //if profile id is not undefined and the parent sent a profile id to use, use it here
                    isPropProfileDifferent = _this.props.outsideColumnProfileId !== tempProfileId;
                    tempProfileId = _this.props.outsideColumnProfileId;
                }

                let selectedProfileFromSession = _this.props.isDrilling ? tryParse(sessionStorage.getItem(SELECTED_DRILL_LIST)) : sessionStorage.getItem(SELECTED_PROFILE_EROSION)? tryParse(sessionStorage.getItem(SELECTED_PROFILE_EROSION)) : typeof tryParse(sessionStorage.getItem("selectedProfile_" + _this.props.profitFormat.toLowerCase())) === "object" ? tryParse(sessionStorage.getItem("selectedProfile_" + _this.props.profitFormat.toLowerCase())) : undefined;
                let profileId = selectedProfileFromSession && selectedProfileFromSession !== null ? selectedProfileFromSession[_profileId] : tempProfileId;

                _this.setDefaultProfileAsSelectedProfile(profiles, profileId, tempState, undefined,
                    isPropProfileDifferent && !!_this.props.outsideColumnProfileId);
                // }else{
                //     _this.setState(tempState,function(){
                //         _this.props.onSelectProfile({})
                //     });
                // }
                if(_this.props.isDashboards){
                    if(_this.props.updateStateFromParent){
                        let tempParentState = {
                            lineAttributeOptions : lineAttributeOptions,
                            profitStackLines:updateElementsWithChildren(copyObjectValues(profitStackLineColumns[0][PROFILE_COLUMN.CHILDREN]), _treeChildren, PROFILE_COLUMN.CHILDREN, _treeChildren),
                            originalProfitStackLines:updateElementsWithChildren(copyObjectValues(profitStackLineColumns[0][PROFILE_COLUMN.CHILDREN]), _treeChildren, PROFILE_COLUMN.CHILDREN, _treeChildren),
                            columns:_this.updateElements(customGroups),
                        }
                        _this.props.updateStateFromParent(tempParentState);
                    }
                }
              
            }
        }
    
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchColumns",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]:  _this.props.hideManage || (_this.props.isDashboards && _this.props.showLoader) || [ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS.toLowerCase(), ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.List.toLowerCase(), ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS.toLowerCase()].includes(_this.props.profitFormat.replaceAll(" ","_").toLowerCase()),
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.sidePanelLoader]: _this.props.isDashboards,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.useStore]: true,
            [FETCHAPI_PARAMS.logout]: logout,
            // [FETCHAPI_PARAMS.dataKey]: "profileColumns",
            // [FETCHAPI_PARAMS.scenarioId]: (scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type ||_this.props.profitFormat)+"_"+_this.props.constraint,
        }
    
        _this.fetchAPI(fetchOptions);
    }

    updateElements(data){
        updateElementsWithChildren(data, _treeChildren, PROFILE_COLUMN.CHILDREN, _treeChildren);
        addElements(data,_treeChildren,_label,PROFILE_COLUMN.NAME);
        addElement(data,_class,"hideMe disableMe");
        return data;
    }
    
    fetchProfiles(prfId, type) {
        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId
        if(!scenarioId) {
            return;
        }
        const _this = this;
        var query = {
            action: "fetchAllUserProfiles" + (this.props.comparisonScenarioId ? "Intersection" : ""),
            profitFormat: type || this.props.profitFormat.replaceAll(" ","_").toLowerCase(),
            scenario_id: scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId,
            comparison_scenario_id: this.props.comparisonScenarioId,
            getAllSectionsDefaults: this.props.isDashboards
        }
        let dataFromStore = getItemFromStore("profiles", _this, (scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type ||_this.props.profitFormat)+"_"+_this.props.constraint);
        let onThenCallback = (data)=>{
            if(_this.props.dispatch && !dataFromStore){
                _this.props.dispatch(updateProfiles([copyObjectValues(data)],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type ||_this.props.profitFormat)+"_"+_this.props.constraint));
            }
            if (data && data.data) {
                var profileData = copyObjectValues(data.data);

                if(_this.props.isDefaultProfileQT) { // remove name and number from System Default to become a qt list
                    let systemProfile = copyObjectValues(profileData.find(f => f.name===ALL_WIDGETS.TITLES.COMPANY_DEFAULT));
                    systemProfile.columns = systemProfile.columns.filter(f=> !["name", "number"].includes(f.machine_name)); // remove name&number from system default
                    if(!systemProfile.columns.find(f => f.machine_name === "quadrant_tier")) { // if system default doesn't have QT, we add it to System default
                        systemProfile.columns.push({column_order: 200, column_return_name:  MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME, column_view_option_id: 7, machine_name:  SELECTED_VECTOR_VIEWS.QUADRANT_TIER, type: MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME, view_order: 4});
                    }
                    systemProfile.columns.push({column_order: 200, column_return_name:  MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME, column_view_option_id: 7, machine_name:  PSL_RETURN_NAMES.ENTITY_COUNT, type: MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME, view_order: 4});
                    systemProfile.columns.push({column_order: 200, column_return_name:  MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME, column_view_option_id: 7, machine_name:  PSL_RETURN_NAMES.COUNT_PER_SELECTION, type: MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME, view_order: 4});
                    profileData.find(f => f.name===ALL_WIDGETS.TITLES.COMPANY_DEFAULT).columns = systemProfile.columns                
                }
                var profiles = copyObjectValues(tryParse(profileData));
                if(_this.props.isDashboards) {
                    profiles = profiles.filter(p=>p[SECTION.RETURN_NAME] === this.props.profitFormat || !p[SECTION.RETURN_NAME]);
                }
                var originalProfiles = tryParse(profileData);
                profiles.forEach(prf=>prf[_isSaved] = true);
                let profileOptions = this.getProfileOptions(profiles);
                let companyDefaultProfileId = profiles.filter(col=>col[_isCompanyDefault])[0][_profileId];
                let dashboardWidgets = data.dashboardWidgets;

                // remove all toggled on columns that are not value, in compare scenarios
                if(_this.props.profitFormat.toLowerCase() === ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS) {  
                  profiles.forEach(profile => {
                    profile.columns = profile.columns.filter(m => m.machine_name === "value");
                    profile.originalColumns = profile.originalColumns.filter(m => m.machine_name === "value");
                  });
                  originalProfiles.forEach(profile => {
                    profile.columns = profile.columns.filter(m => m.machine_name === "value");
                  });
                }
                
                let tempState = {
                    columnProfiles: profiles,           //data as fetched from API
                    originalColumnProfiles: originalProfiles,           //data as fetched from API
                    profileOptions: profileOptions,     //data after adding label and value to each element
                    companyDefaultProfileId: companyDefaultProfileId,
                    dashboardWidgets: dashboardWidgets,
                }
                let tempProfileId = prfId ? prfId : !!_this.props.companyDefaultIsDefault ? companyDefaultProfileId : _this.state.defaultProfileId ?  _this.state.defaultProfileId : getItemFromStore("profileColumns", _this, (scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type ||_this.props.profitFormat)+"_"+_this.props.constraint)?.default_profile_id;
                
                let isDrilling = sessionStorage.getItem(SELECTED_DRILL_LIST) && typeof tryParse(sessionStorage.getItem(SELECTED_DRILL_LIST)) === "object" && this.props.isDrilling;
                let selectedProfileFromSession = _this.props.sendUniqueId === _selectedBubbleList ? undefined : isDrilling ? tryParse(sessionStorage.getItem(SELECTED_DRILL_LIST)) || this.props.drillProfile : sessionStorage.getItem(SELECTED_PROFILE_EROSION)? tryParse(sessionStorage.getItem(SELECTED_PROFILE_EROSION)) : typeof tryParse(sessionStorage.getItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase())) === "object" ? tryParse(sessionStorage.getItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase())) : undefined ;
                
                let profileId = selectedProfileFromSession && selectedProfileFromSession !== null  ? selectedProfileFromSession[_profileId] : tempProfileId;
                if(selectedProfileFromSession && selectedProfileFromSession !== null ){
                    profiles = profiles.filter(e => e[_profileId] !== profileId);
                    profiles.push(selectedProfileFromSession);
                    tempState.columnProfiles = profiles; // get modified profile columns
                }
                let isPropProfileDifferent = false;
                    if(!!tempProfileId && !!_this.props.outsideColumnProfileId) {
                        //if profile id is not undefined and the parent sent a profile id to use, use it here
                        isPropProfileDifferent = _this.props.outsideColumnProfileId !== tempProfileId;
                        tempProfileId = _this.props.outsideColumnProfileId;
                    }
                if (_this.props.dispatch && !_this.props.isDashboards) {
                    _this.props.dispatch(updateStateProfiles([profiles],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type || _this.props.profitFormat)+"_"+_this.props.constraint))
                }
                _this.setDefaultProfileAsSelectedProfile(profiles, profileId, tempState, undefined, 
                    isPropProfileDifferent && !!_this.props.outsideColumnProfileId);
            }
        }
    
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchProfiles",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: _this.props.hideManage || (_this.props.isDashboards && _this.props.showLoader) || [ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS, ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.List, ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS].includes(_this.props.profitFormat.replaceAll(" ","_").toLowerCase()),
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.sidePanelLoader]: _this.props.isDashboards,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.useStore]: true,
            [FETCHAPI_PARAMS.dataKey]: "profiles",
            [FETCHAPI_PARAMS.scenarioId]: (scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)+"_"+(type || _this.props.profitFormat)+"_"+_this.props.constraint,
        }
    
        _this.fetchAPI(fetchOptions);
    }

    // updateColumnsOrderInState = (_this,newColOrders) => {
    //     let tempColumnProfiles = copyObjectValues(_this.state.columnProfiles);
    //     let profileIndex = findIndexOfValue(tempColumnProfiles, _profileId, _this.state.selectedProfile[_profileId]);
    //     tempColumnProfiles[profileIndex][_profileColumns].map(col=>{
    //         let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
    //         if(!!tempObj) {
    //             col[_columnOrder] = tempObj[_columnOrder];
    //         }
    //         return col;
    //     });

    //     let tempProfileOptions = copyObjectValues(_this.state.profileOptions);
    //     profileIndex = findIndexOfValue(tempProfileOptions, _profileId, _this.state.selectedProfile[_profileId]);
    //     tempProfileOptions[profileIndex][_profileColumns].map(col=>{
    //         let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
    //         if(!!tempObj) {
    //             col[_columnOrder] = tempObj[_columnOrder];
    //         }
    //         return col;
    //     });

    //     _this.setState({
    //         checkedItems: _this.state.checkedItems.map(col=>{
    //             let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
    //             if(!!tempObj) {
    //                 col[_columnOrder] = tempObj[_columnOrder];
    //             }
    //             return col;
    //         }),
    //         columnProfiles: tempColumnProfiles,
    //         profileOptions: tempProfileOptions
    //     })
    // }

    updateColumnsOrder = (tableColumns, columnMovedField, callback) => {
        let _this = this;
        const allSuffixes = getObjectValues(column_suffixes);
        let combinationArr = [SORT_TITLES.FIELD.NAME_COMBINATION, SORT_TITLES.FIELD.NUMBER_COMBINATION, SORT_TITLES.FIELD.QUADRANT_COMBINATION, SORT_TITLES.FIELD.QUADRANTTIER_COMBINATION];

        const vectorViewsValues = vectorViews;
        let newColOrders = tableColumns.map((col, index)=>{
            if(!!col.getField()) {
                // let colField = col.getField();
                // colField = replaceAllInstancesOf(colField, allSuffixes);
                // let vectorCol = _this.state.checkedItems.filter(item=>item[_type] === _vectorType && item[_returnName] + item[PROFILE_COLUMN.MACHINE_NAME] === colField)[0];
                // if(!!vectorCol) {
                //     //this is a vector column, otherwise vectorCol would be undefined
                //     colField = vectorCol[_returnName];    //extract the vector return name, cz column field in the name is concatenated to the machine name
                // }

                return {
                    [_returnName]: col.getField(),
                    [_columnOrder]: combinationArr.includes(col.getField()) ? 0 : index*100
                }
            }
        });
        newColOrders = deleteEmptyIndices(newColOrders);      //remove indices of check and drill if they exist since they will return "undefined"

        if(!!this.state.tableColumnsOrder && deepCompareObjects(newColOrders, this.state.tableColumnsOrder)) {
            return;     //do not save order if user moved a column and returned it to the same index
        }

        let retNamesRegex = allSuffixes.map(reg=>{		//adding regex of all possible suffixes that belong to each column
            return new RegExp(String(reg).replace(/\//g,""));
        });
        retNamesRegex = (vectorViewsValues.map(iden=>{		//adding regex of all identifiers suffixes that belong to each column
            return new RegExp("_" + iden + "$");		//$ to mark the end of the word
        })).concat(retNamesRegex);

        //prepare the correct order. If a column with view was moved, we have to change
        //the index of the original one since views are not saved in the DB
        let isMovedColumnAnIdentifier = vectorViewsValues.includes(columnMovedField);
        if(!isMovedColumnAnIdentifier) {        //if not an identifier of the selected vector
            let cleanReturnName = replaceAllInstancesOf(columnMovedField, retNamesRegex);    //get the return name without any suffixes
            let changedRetNameNewIndex = findOptionByKeyValue(newColOrders, _returnName, columnMovedField)[_columnOrder];               //extract the new order
            //remove all columns relating to the one that was moved (remove all the views)
            newColOrders = newColOrders.filter(col=>retNamesRegex.filter(reg=>new RegExp("^"+ cleanReturnName + String(reg).replace(/\//g,"")).test(col[_returnName])).length === 0 && col[_returnName] !== cleanReturnName);
            newColOrders.push({[_returnName]: cleanReturnName, [_columnOrder]: changedRetNameNewIndex });   //re-add the column without any suffixes
        }

        //remove all identifier columns as they are not saved in the DB as columns, but as views, and replace them with "selected vector" column.
        //fetching the new index for identifiers: if the column moved is one of the identifiers, we take the new index it was moved to,
        //otherwise we take the index of the first identifier in the list
        let identifierCols = newColOrders.filter(col=>vectorViewsValues.includes(col[_returnName]));
        let selectedVector = isMovedColumnAnIdentifier ? findOptionByKeyValue(newColOrders, _returnName, columnMovedField)
            : identifierCols[getLowestKey(identifierCols, _columnOrder)];

        let selectedVectorIndex = selectedVector ? selectedVector[_columnOrder] : -1;
        newColOrders = newColOrders.filter(col=>!vectorViewsValues.includes(col[_returnName]));               //remove all identifiers
        if(selectedVectorIndex > -1) {
            newColOrders.push({[_returnName]: _selectedVector, [_columnOrder]: selectedVectorIndex});      //add the "selected vector" column with the lowest colIndex among identifiers
        }

        newColOrders = newColOrders.map(col=>{
            let colField = replaceAllInstancesOf(col[_returnName], retNamesRegex.concat(vectorViewsValues));
            // let vectorCol = _this.state.checkedItems.filter(item=>item[_type] === _vectorType && item[_returnName] + item[PROFILE_COLUMN.MACHINE_NAME] === colField)[0];
            // if(!!vectorCol) {
            //     //this is a vector column, otherwise vectorCol would be undefined
            //     colField = vectorCol[_returnName];    //extract the vector return name, cz column field in the name is concatenated to the machine name
            // }
            col[_returnName] = colField;
            return col;
        });

        //after removing all views of moved column and adding one object correctly, and doing the same for identifers,
        //remove all duplicate return names keeping the first index of each different return name
        newColOrders = removeDuplicateKeyValues(newColOrders, _returnName);
        
        if(_this.state.selectedProfile[_isApplied] || (this.state.selectedProfile[_isCompanyDefault] && !parseBoolean(this.props.user.is_system)) || (!_this.state.access.edit_company_cols  &&  _this.state.selectedProfile[_visibility] === _visibilityOptions.COMPANY)){
            let tempSelectedProfile = copyObjectValues(_this.state.selectedProfile);
            tempSelectedProfile[_isModified] = true;
            //I tried putting all of this in a function since it is being used twice but it is causing a random type error in tabulator.js - line 19997 (best guess of binding)
            let tempColumnProfiles = copyObjectValues(_this.state.columnProfiles);
            let profileIndex = findIndexOfValue(tempColumnProfiles, _profileId, _this.state.selectedProfile[_profileId]);
            tempColumnProfiles[profileIndex][_profileColumns].map(col=>{
            let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
            if(!!tempObj) {
                col[_columnOrder] = tempObj[_columnOrder]*100;
            }
            return col;
            });
            tempColumnProfiles[profileIndex][_isModified] = true;
            let tempProfileOptions = copyObjectValues(_this.state.profileOptions);
            profileIndex = findIndexOfValue(tempProfileOptions, _profileId, _this.state.selectedProfile[_profileId]);
            tempProfileOptions[profileIndex][_profileColumns].map(col=>{
                let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
                if(!!tempObj) {
                    col[_columnOrder] = tempObj[_columnOrder]*100;
                }
                return col;
            });
            tempProfileOptions[profileIndex][_isModified] = true;
            tempSelectedProfile.columns = _this.state.checkedItems.map(col=>{
                let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
                if(!!tempObj) {
                    col[_columnOrder] = tempObj[_columnOrder];
                }
                return col;
            });
           
            _this.setState({
                checkedItems: tempSelectedProfile.columns,
                columnProfiles: tempColumnProfiles,
                profileOptions: tempProfileOptions,
                selectedProfile: tempSelectedProfile
            },()=> {
                sessionStorage.setItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase(),JSON.stringify(tempSelectedProfile));
                _this.props.updateManageColumnsProfile(tempSelectedProfile);
            })
        } else if(!this.state.selectedProfile[_isApplied] && 
            ((this.state.selectedProfile[_isCompanyDefault] && parseBoolean(this.props.user.is_system) && _this.state.access.edit_company_cols) 
                || (!this.state.selectedProfile[_isCompanyDefault] && (this.state.selectedProfile[_visibility] !== _visibilityOptions.COMPANY || _this.state.access.edit_company_cols)))) {
                var query = {
                    action: "updateColumnsOrder",
                    columnsViews: newColOrders,
                    profileId: this.state.selectedProfile[_profileId],
                    scenario_id: this.props.scenarioId
                }
                let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId;
                _this.clearAllManageColumnsFromStore(scenarioId);
                let onThenCallback = (data)=>{
                    if(parseBoolean(data.success)) {
                        
                        var tempColumnProfiles = copyObjectValues(_this.state.columnProfiles);
                        var profileIndex = findIndexOfValue(tempColumnProfiles, _profileId, _this.state.selectedProfile[_profileId]);
                        tempColumnProfiles[profileIndex][_profileColumns].map(col=>{
                            let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
                            if(!!tempObj) {
                                col[_columnOrder] = tempObj[_columnOrder];
                            }
                            return col;
                        });
                        tempColumnProfiles[profileIndex].originalColumns.map(col=>{
                            let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
                            if(!!tempObj) {
                                col[_columnOrder] = tempObj[_columnOrder];
                            }
                            return col;
                        });
                        tempColumnProfiles[profileIndex].simplified_columns = undefined;
                        var tempProfileOptions = copyObjectValues(_this.state.profileOptions);
                        profileIndex = findIndexOfValue(tempProfileOptions, _profileId, _this.state.selectedProfile[_profileId]);
                        tempProfileOptions[profileIndex][_profileColumns].map(col=>{
                            let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
                            if(!!tempObj) {
                                col[_columnOrder] = tempObj[_columnOrder];
                            }
                            return col;
                        });
                        let currentProfile = _this.state.selectedProfile;
                        currentProfile[_profileColumns].map(col=>{
                            let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
                            if(!!tempObj) {
                                col[_columnOrder] = tempObj[_columnOrder];
                            }
                            return col;
                        });
                        currentProfile.originalColumns.map(col=>{
                            let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
                            if(!!tempObj) {
                                col[_columnOrder] = tempObj[_columnOrder];
                            }
                            return col;
                        });
                        currentProfile.simplified_columns = undefined;
                        let orderedCols = _this.state.checkedItems.map(col=>{
                            let tempObj = newColOrders.filter(colOrder=>col[_returnName] === colOrder[_returnName])[0];
                            if(!!tempObj) {
                                col[_columnOrder] = tempObj[_columnOrder];
                            }
                            return col;
                        });
                      
                        _this.setState({
                            selectedProfile: currentProfile,
                            checkedItems:orderedCols,
                            columnProfiles: tempColumnProfiles,
                            profileOptions: tempProfileOptions
                        }, () => {
                            if(typeof callback === "function") {
                                callback(newColOrders);
                            }
                            if (typeof _this.props.onSelectProfile === "function") {
                                _this.props.onSelectProfile(_this.state.selectedProfile);
                            }
                        })
                }
                
             
            }
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "updateTableColumnsOrder",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
            }
                
            fetchAPI(fetchOptions);
        }
        return newColOrders.sort((a, b) => ((a[_columnOrder]) > (b[_columnOrder])) ? 1 : -1);
    }
    //#endregion

    getProfileInvalidColumns(profile) {
        let allProfileColumns;
        if(!profile[_isApplied]) {
            //take the name of the profile that is received, but do not use the array of checked columns, 
            //instead, extract the columns from the main profiles received from API to use all checked and unchecked columns
            let initialProfile = this.state.columnProfiles ? this.state.columnProfiles.filter(p=>p[_profileName] === profile[_profileName])[0] : undefined;
            allProfileColumns = initialProfile ? initialProfile[_profileColumns] : [];
        } else {
            //if the profile was applied, the initial profile will include the saved changes coming from API
            //we should instead use the ones that the user checked before applying
            allProfileColumns = copyObjectValues(this.state.checkedItems);
            let checkedRetNames = this.getCheckedColumns().map(col=>typeof col === "string" ? col : col[_returnName]);     //we only need the return names       
            allProfileColumns = allProfileColumns.filter(col=>checkedRetNames.includes(col[_returnName]));
        }

        return getMissingItems(linearizeHierarchy(this.state.profileColumns, _children), allProfileColumns, {"main": _returnName, "secondary": _returnName});
    }

    getMissingItemsAsText(missingItems) {
        const _this = this;
        let missingItemsText = [];
        missingItems.forEach(item=>{
            //for each item, extract type and display name, then match the type to its display value from its group
            //ex: type: psl --> extract 'Profit Stack Lines' from the group
            let colType = item[_type];
            let colName = item[_columnName];
            let colRetName = _this.removeColumnTypeSuffixes(colType, item[PROFILE_COLUMN.RETURN_NAME]);

            let typeDisplayName = "";
            if(colType === lang.manage_columns.column_types.custom.value) {
                typeDisplayName = lang.manage_columns.custom_column;
            } else {
                let colGroup = findOptionByKeyValue(_this.state.profileColumns, _type, colType);
                typeDisplayName = colGroup ? colGroup.label : "";
            }
            if (!missingItemsText.includes(typeDisplayName + ":  " + (colName || colRetName))) {
                missingItemsText.push(typeDisplayName + ":  " + (colName || colRetName));
            }
        });

        return missingItemsText;
    }

    removeColumnTypeSuffixes(type, returnName) {
        switch(type) {
            case lang.manage_columns.column_types.psl.value:
                return returnName.replace(MACHINE_NAME_SUFFIXES._AC, "");
            case lang.manage_columns.column_types.line_attribute.value:
                return returnName.replace(MACHINE_NAME_SUFFIXES._CC, "");
            case lang.manage_columns.column_types.custom.value:
                return returnName.replace(MACHINE_NAME_SUFFIXES._CPCF, "");
            case lang.manage_columns.column_types.vector.value:
            case lang.manage_columns.column_types.selected_vector.value:
                return returnName.replace(MACHINE_NAME_SUFFIXES._NUMBER, "");
        }
    }

    checkIsProfileValid(profile) {
        const _this = this;
        if(_this.props.comparisonScenarioId) {
            //if having more than 2 scenarios, do not check anything
            return;
        }
    }
    
    /**
     * this function is used to specifiy which profile's data is displayed in the board
     * @param {*} profile 
     * @param {*} tempState 
     * @param {*} callback 
     */
    setSelectedProfile(profile, tempState={}, callback=()=>{},fromReset,isDiscard,fromEdit) {
        if (this.state.originalColumnProfiles && this.state.originalColumnProfiles.length !== 0 || (tempState.originalColumnProfiles && tempState.originalColumnProfiles.length !== 0 && !this.state.selectedProfile)) {
            profile = typeof profile === "string" ? tryParse(profile) : profile;
            tempState.selectedProfile = profile;
            let tempKey = !!profile[_simplifiedColumns] ? _simplifiedColumns : _profileColumns; //this should be removed when profileColumns are always objects instead of strings
      
            tempState.checkedItems = this.state.checkedItems && this.state.checkedItems.length > 0 && this.state.checkedItems[0][COLUMN_PROFILE.ID] && profile[tempKey]
                    && profile[tempKey][0][COLUMN_PROFILE.ID] && this.state.checkedItems[0][COLUMN_PROFILE.ID] === profile[tempKey][0][COLUMN_PROFILE.ID]
                    ? this.state.checkedItems : profile[tempKey];

            let originalColumnProfiles = copyObjectValues(this.state.originalColumnProfiles)
            let isDrilling = this.props.isDrilling;
            let selectedProfileFromSession = isDrilling
                ? sessionStorage.getItem(SELECTED_DRILL_LIST) &&
                typeof tryParse(sessionStorage.getItem(SELECTED_DRILL_LIST)) === "object"
                ? tryParse(sessionStorage.getItem(SELECTED_DRILL_LIST))
                : this.state.drillProfileBeforeChanges || this.props.drillProfile
                : sessionStorage.getItem(SELECTED_PROFILE_EROSION)
                ? tryParse(sessionStorage.getItem(SELECTED_PROFILE_EROSION))
                : typeof tryParse(sessionStorage.getItem("selectedProfile_" + this.props.profitFormat.toLowerCase())) === "object"
                ? tryParse(sessionStorage.getItem("selectedProfile_" + this.props.profitFormat.toLowerCase()))
                : undefined;
            
            let profileId = selectedProfileFromSession && selectedProfileFromSession !== null ? selectedProfileFromSession[_profileId] : undefined;
            if (profileId && profileId === profile[_profileId] && /*((isDiscard && !fromReset) || )*/fromEdit) {  // On edit or discard take the modified columns from session in case it has same profile id
                tempState.checkedItems = selectedProfileFromSession.simplified_columns || selectedProfileFromSession.originalColumns;
                tempState.selectedProfile[_profileName] = selectedProfileFromSession[_profileName];
                tempState.selectedProfile[_visibility] = selectedProfileFromSession[_visibility];
                tempState.selectedProfile[_isModified] = true;
            } else if (isDiscard && !fromReset) {
                tempState.messageNotMatch = "";
                tempState.isVisibilityValid = false;// on discard take original column
                if (this.state.profileBeforeEdit) {
                    if (profileId && profileId === this.state.profileBeforeEdit[_profileId]) {
                        tempState.selectedProfile = selectedProfileFromSession;
                        tempState.checkedItems = selectedProfileFromSession.columns;
                    } else {
                        tempState.profileBeforeEdit = this.state.profileBeforeEdit;
                        tempState.profileBeforeEdit[_isModified] = false;
                        tempState.profileBeforeEdit[_isApplied] = false;
                        tempState.profileBeforeEdit[_profileName] = originalColumnProfiles.filter(e => e[_profileId] === tempState.profileBeforeEdit[_profileId])[0][_profileName];
                        tempState.profileBeforeEdit[_label] = tempState.profileBeforeEdit[_profileName];
                        tempState.profileBeforeEdit[_profileColumns] = originalColumnProfiles.filter(e => e[_profileId] === tempState.profileBeforeEdit[_profileId])[0][_profileColumns];
                        tempState.profileBeforeEdit.originalColumns = tempState.profileBeforeEdit[_profileColumns];
                        tempState.selectedProfile = tempState.profileBeforeEdit;
                        tempState.checkedItems = tempState.profileBeforeEdit[_profileColumns];
                    }
                } else if (this.state.appliedProfile) { // if a profile applied, get its checkedItems       
                    tempState.checkedItems = this.state.appliedProfile[_profileColumns]
                } else{
                    tempState.checkedItems = (originalColumnProfiles?.filter(e => e[_profileId] === profile[_profileId])[0] && originalColumnProfiles?.filter(e => e[_profileId] === profile[_profileId])[0][_profileColumns]) || [];
                    tempState.selectedProfile[_profileColumns] = originalColumnProfiles.filter(e => e[_profileId] === profile[_profileId])[0] && originalColumnProfiles?.filter(e => e[_profileId] === profile[_profileId])[0][_profileColumns] || [];
                    tempState.selectedProfile.originalColumns = tempState.selectedProfile[_profileColumns];
                    tempState.selectedProfile[_isModified] = false;
                    tempState.selectedProfile[_isApplied] = false;
                }
            } else if (fromEdit) {
                tempState.checkedItems = profile.originalColumns;
                tempState.selectedProfile[_isModified] = false;
                tempState.messageNotMatch = "";
                tempState.isVisibilityValid = false;
            } else if (fromReset) {
                tempState.selectedProfile[_isModified] = false;
                tempState.selectedProfile.simplified_columns = tempState.checkedItems;
                tempState.messageNotMatch = "";
                tempState.isVisibilityValid = false;
                if (typeof this.props.onSelectProfile === "function") {
                    this.props.onSelectProfile(tempState.selectedProfile);
                }
            }
        }
        
        if(this._isMounted) {
            tempState.disableToggles = this.props.manageColumnsSelectionLimit &&tempState.checkedItems && this.getAllToggledOnOptionsCount(tempState.checkedItems) >= Number(this.props.manageColumnsSelectionLimit)
            this.setState(tempState, ()=>{
                if(typeof callback === "function") {
                    callback();
                }
            })
        }
    }
    
    /**
     * called when input or <ToggleTab /> is changed in save popup,
     * determines whether to udpate the customProfile or the modifiedProfile
     * @param {*} key 
     * @param {*} value 
     */
    updateSelectedProfile(keyValueObject, tempState={}, callback=undefined) {
        let profile = this.state.selectedProfile;
        Object.keys(keyValueObject).forEach(key=>{
            profile[key] = keyValueObject[key];
        });

        this.setSelectedProfile(profile, tempState, callback);
    }

    /**
     * this function only prepares the profile options to be displayed in the dropdown
     * @param {*} profiles 
     */
    getProfileOptions(profiles) {
        let _this = this;
        let profileOptions =  profiles.filter(prf=>prf[_isFavorite] === true).length > 0 ?
        [{label: "Favorites", [_isFavorite]: true, isDisabled: true, isProfileGroup: true, [_visibility]: _visibilityOptions.COMPANY}] : [];
        profileOptions = profileOptions.concat(profiles.filter(prf=>prf[_isFavorite] === true).map(prf=>{
            if (((prf[_visibility] === _visibilityOptions.COMPANY  || prf[_visibility] === _visibilityOptions.USER) && _this.props.isDashboards && _this.props.selectedDashboard[DASHBOARDS.VISIBILITY] === DASHBOARDS.VISIBILITY_OPTIONS.USER || !_this.props.isDashboards)
            ||  (prf[_visibility] === _visibilityOptions.COMPANY && _this.props.isDashboards && _this.props.selectedDashboard[DASHBOARDS.VISIBILITY] === DASHBOARDS.VISIBILITY_OPTIONS.COMPANY || !_this.props.isDashboards)) {
                prf.label = prf[_profileName];
                prf.value = prf[_profileId];
                prf[_isFavorite] = true;
                prf.creatorName = "";
                prf.originalColumns= copyObjectValues(prf[_profileColumns]);
                return prf;
            }
        }));
        profileOptions.push({label: capitalizeFirstLetter(_visibilityOptions.COMPANY), isDisabled: true, isProfileGroup: true, [_visibility]: _visibilityOptions.COMPANY});
        profileOptions = profileOptions.concat(profiles.filter(prf=>prf[_visibility] === _visibilityOptions.COMPANY && prf[_isFavorite] !== true).map(prf=>{
            prf.label = prf[_profileName];
            prf.creatorName = (prf[_isCompanyDefault] ? "" : (prf[_creatorName]));
            prf.value = prf[_profileId];
            // prf.isDisabled = !prf[_isValid];
            prf.originalColumns= copyObjectValues(prf[_profileColumns]);
            return prf;
        }));
        if (this.props.isDashboards && this.props.selectedDashboard[DASHBOARDS.VISIBILITY] === DASHBOARDS.VISIBILITY_OPTIONS.USER || !this.props.isDashboards) {
            profileOptions.push({label: capitalizeFirstLetter(_visibilityOptions.USER), isDisabled: true, isProfileGroup: true,  [_visibility]: _visibilityOptions.USER});
            profileOptions = profileOptions.concat(profiles.filter(prf=>prf[_visibility] === _visibilityOptions.USER && prf[_isFavorite] !== true).map(prf=>{
                prf.label = prf[_profileName];
                prf.value = prf[_profileId];
                // prf.isDisabled = !prf[_isValid];
                prf.originalColumns= copyObjectValues(prf[_profileColumns]);
                return prf;
            }));
        }
        profileOptions = profileOptions.filter(e=>e!== undefined);
        return profileOptions;
    }

    /**
     * this function is used after saving a new profile, to add it to the list of profiles
     * that appears in the dropdown, so that to avoid having to reload the data from the API
     * @param {*} newProfile 
     * @param {*} isAdded 
     * @param {*} profileId 
     */
     getUpdatedColumnProfiles(newProfile, isAdded, profileId) {
        let profiles = copyObjectValues(this.state.columnProfiles);
        let currentIndex = findIndexOfValue(profiles, _profileId, profileId);  //remove it from the array
        if(!!newProfile && newProfile[_isUserDefault]) {
            profiles.map(prf=>prf[_isUserDefault] = false);     //reset flag for other profiles
        }

        if(currentIndex > -1) {
            //remove it to either re-add it (if added), change its index (if visibility changed), or not at all (if deleted)
            profiles.splice(findIndexOfValue(profiles, _profileId, profileId), 1);
        }

        if(isAdded) {   //when saving a new profile
            let newIndex = -1;
            if(currentIndex > -1) {
                newIndex = currentIndex;        //re-insert at same index
            } else if(newProfile[_visibility] === _visibilityOptions.COMPANY) {
                newIndex = findIndexOfValue(profiles, _visibility, _visibilityOptions.USER);    //insert as last option before "user"
            } else {
                newIndex = profiles.length;     //insert at the end of the array
            }
            profiles.splice(newIndex, 0, newProfile);    //append at previous index or end of array if not found
        }
        return profiles;
    }

    /**
     * this function takes the data array and adds some needed parameters
     * to each element, like "is_expanded, is_checked, level, etc."
     * -- average ~ 8-14 ms
     * @param {*} data 
     * @param {*} level 
     */
    setupData(data, profileCols=undefined, level=0) {
        for (let index in data) {
            let element = data[index];

            if(profileCols) {
                //used when resetting changes
                element[_isChecked] = profileCols.returnNames.includes(element[_returnName]);
            } else {
                //only update these keys when called from API callback
                element[_level] = level;
                element[_isExpandable] = !!element[_children] && element[_children].length > 0;
                element[_isExpanded] = false;   //all parents are collapsed by default
                element[_isInSearchResult] = true;      //show all elements by default
            }

            if(!!element[_children]) {
                this.setupData(element[_children], profileCols, level + 1);   //increase the level for children
            }
        }

        return data;
    }

    /**
     * 
     * this function updates each element in the data, setting all the keys
     * in keyValueObject to their corresponding values
     * @param {*} data 
     * @param {*} keyValueObject 
     * @param {*} returnNames   -- omit this parameter to update for all columns
     */
    updateDataKeys(data, keyValueObject, returnNames=undefined) {
        for (let index in data) {
            let element = data[index];

            if(!returnNames || returnNames.includes(element[_returnName])) {
                Object.keys(keyValueObject).forEach(key=>{
                    element[key] = keyValueObject[key];
                });
            }
            if(!!element[_children]) {
                this.updateDataKeys(element[_children], keyValueObject, returnNames);
            }
        }

        return data;
    }

    /**
     * -- average ~ 4-8 ms
     */
    toggleAllColumns(key, value) {
        let data = copyObjectValues(this.state.profileColumns);
        let tempData = this.toggleColumns(data, key, value);
        data = tempData.data;
        data = this.setBlueDotFlags(data);

        let tempState = {
            profileColumns: data,
            allChecked: value,
        };
        
        //set selected profile as modified
        this.updateSelectedProfile({[_isModified]: true, [_profileColumns]: tempData.orderedReturnNames}, tempState);
    }

    /**
     * this function takes a key and updates its value
     * for all the columns in the list
     * -- average < 1 ms
     * @param {*} data 
     * @param {*} key 
     * @param {*} value 
     */
    toggleColumns(data, key, value, orderedColumns=copyObjectValues(this.state.selectedProfile[_profileColumns])) {
        for(let index in data) {
            let element = data[index];
            let isApproved = true;
            if(typeof this.props.approveBulkChange === "function") {
                isApproved = this.props.approveBulkChange(key, element);
            }

            if(isApproved) {
                element[key] = value;
                if(key === _isChecked) {    //update order
                    let columnIndexInList = findIndexOfValue(orderedColumns, _returnName, element[_returnName]);
                    if(element[key] && columnIndexInList === -1) {   //if value became true
                        element[_order] = orderedColumns.length;     //set rank as last one
                        orderedColumns.push(element);
                    } else if(!element[key] && columnIndexInList > -1) {
                        orderedColumns.splice(columnIndexInList, 1);
                    }
                }
            }

            if(element[_children]) {
                this.toggleColumns(element[_children], key, value, orderedColumns);
            }
        }

        return {data: data, orderedReturnNames: orderedColumns};
    }

    /**
     * this function takes a key and toggles its value for the element
     * having the specified columnReturnName
     * -- average ~ 11-31 ms with rendering
     * @param {*} columnReturnName 
     * @param {*} key 
     * @param {*} forceValue        -- this value is sent to overwrite the original behavior which is toggling
     */
    toggleColumn =(columnReturnName, key, viewMachineName, forceValue=undefined)=>{
        let _this = this;
        let data = copyObjectValues(_this.state.profileColumns);
        let column = getEmbeddedChild(data, _children, _returnName, columnReturnName);
        if(!column) {
            return;     //if column not found, do not continue
        }
        column[_machineName] =  viewMachineName;
        column[key] = forceValue !== undefined ? forceValue : !column[key];
        let orderedColumns = copyObjectValues(_this.state.selectedProfile ? _this.state.selectedProfile[_profileColumns] : []);
        
        if(key === _isChecked && orderedColumns.length > 0) {
            //when checking/unchecking a column, update flag for all the data
            data = _this.setBlueDotFlags(data);

            //update order
            let columnIndexInList = orderedColumns.findIndex((row) => {return row[_returnName] === column[_returnName] && (viewMachineName === undefined || row[_machineName] === viewMachineName)});
            if(column[key] && columnIndexInList === -1) {   //if value became true
                column[_order] = orderedColumns.length;     //set rank as last one
                orderedColumns.push(column);
            } else if(!column[key] && columnIndexInList > -1) {
                orderedColumns.splice(columnIndexInList, 1);
            }
        }
        let tempState = {
            profileColumns: data
        }
        
        if(key === _isChecked) {
            //set selected profile as modified
            _this.updateSelectedProfile({[_isModified]: true, [_profileColumns]: orderedColumns}, tempState);
        } else {
            _this.setState(tempState);
        }
    }

    /**
     * this function resets the _hasBlueDotFlag to false for all elements,
     * and then loops over the checked children and sets the flag true for all their parents
     * -- average ~ 4-6 ms
     * @param {*} data 
     * @param {*} level 
     */
    setBlueDotFlags(data, columns) {
        //set isChecked flag false for all the elements before re-updating it to true for the parents
        data = updateAllElements(data, _children, _hasBlueDot, false);
        let checkedColumnsIndices = this.getCheckedColumnsIndices(data, columns);    //two dimensional array

        for(let index in checkedColumnsIndices) {
            let path = checkedColumnsIndices[index];    //path is an array of indices leading to a checked child
            let loopedArray = data;
            path.pop();     //remove the last index from the path, this is the index for the checked child, it shouldn't have a blue dot

            for(let i in path) {
                let elementIndex = path[i];
                if (loopedArray) {
                    let element = loopedArray[elementIndex];

                    element[_hasBlueDot] = true;
                    loopedArray = element[_children];   //next index will be for a child under this element
                }
           
            }
        }

        return data;
    }

    /**
     * this function filters the columns per an input value
     * -- average ~ 100ms with render (over multiple trials between 8 and 170ms depending on the data and the matches)
     * @param {*} e 
     */
    filterColumns(e, callback) {
        const _this = this;
        clearTimeout(this.searchTimeout);
        let inputValue =  e === "" ? e : e.target.value;

        this.searchTimeout = setTimeout(()=>{
            let data = copyObjectValues(_this.state.profileColumns);
            data = _this.updateSearchResult(data, inputValue);

            _this.setState({
                profileColumns: data,
                isSearching: inputValue !== "",
                searchValue: inputValue
            },function(){
                if(typeof callback === 'function') {
                    callback();
                }
            });
        }, 300);
    }

    /**
     * this function loops over all the data and flags each element
     * whether it should appear in the search results or not
     * @param {*} data 
     * @param {*} inputValue 
     */
    updateSearchResult(data, inputValue) {
        for(let index in data) {
            let element = data[index];
            element[_isInSearchResult] = element[_columnName]? element[_columnName].toLowerCase().includes(inputValue.toLowerCase()):"";

            if(element[_children]) {
                this.updateSearchResult(element[_children], inputValue);
            }
        }

        return data;
    }

    getCheckedColumns(data=this.state.profileColumns, checkedColumns=this.state.checkedItems) {
        data = linearizeHierarchy(data, _children);
        let columnsReturnNames = checkedColumns ? checkedColumns.map(col=>col[_returnName] || col) : [];
        return data.filter(col=>columnsReturnNames.includes(col[_returnName]));
    }

    getCheckedColumnsIndices(data, columns) {
        let checkedColumns = this.getCheckedColumns(data, columns);
        let checkedColumnsIndices = [];
        
        for(let column in checkedColumns) {
            column = checkedColumns[column];
            let path = getPathToEmbeddedChild(data, _children, _returnName, column[_returnName]);
            checkedColumnsIndices.push(path);
        }

        return checkedColumnsIndices;
    }

    addNewColumn(){
        if(!this.state.isEditingColumn){
            this.setState({
                isEditingColumn:false
            })
        }
        // this.onToggleBoard();
    }

    onToggleBoard(close, largeDisplay=false){
        const _this = this;
        let hide = false;
        if (_this.props.hideBoard) {
            _this.toggleBoard();
        }
        if (this.state.displayPanel) {
            // this.manageColsOverlay.toggleBoard(close);
            hide = true;
        }

        this.setState({
            displayPanel: !hide,
            largeDisplay: !!largeDisplay,
            customGroups: hide?this.state.customGroups.filter(e=>!e.label.includes(MANAGE_COLUMNS.NEW_GROUP_LABEL)):this.state.customGroups
        },function(){
            if (!hide){
                // _this.manageColsOverlay.toggleBoard(close);
            }
        })
    }

    toggleBoard() {
        //only toggle a css class that will handle the open/close of the menu
        this.boardRef.classList.toggle("is-board-open");
        let willShow = this.boardRef.classList.contains("is-board-open");
        if(typeof this.props.onToggleBoard === "function") {
            this.props.onToggleBoard();
        }
        if(willShow) {
            this.initialCheckAll = this.state.allChecked;
            this.profileBeforeChanges = copyObjectValues(this.state.selectedProfile);
            this.state.drillProfileBeforeChanges = copyObjectValues(this.state.selectedProfile);
        }
        this.closeManageColumnsDropDown();
    }

    discardChanges(callback, fromReset) {
     
        let profileBeforeEdit = this.state.profileBeforeEdit;
        let originalColumnProfiles = copyObjectValues(this.state.originalColumnProfiles);

        let tempState = {
            allChecked: this.initialCheckAll,
            nameNotValidMessage: "",
            visibilityChecked: undefined,
            visibilityCheckedlist: [],
            visibiliyChecked: undefined,
            ["tempSaved"+_visibility] : "",
            ["tempSaved"+_profileName] : "",
        }
        let cb = ()=>{
            if(typeof callback === "function") {
                callback();
            }
        };
        this.state.drillProfileBeforeChanges = copyObjectValues(this.state.selectedProfile)
        if(this.profileBeforeChanges && fromReset){// On reset remove modified from manage columns
            this.profileBeforeChanges[_isApplied] = false;
            this.profileBeforeChanges[_isModified] = false;
            this.profileBeforeChanges[_profileName] = originalColumnProfiles.filter(e=>e[_profileId]=== this.profileBeforeChanges[_profileId])[0][_profileName];
            this.profileBeforeChanges[_visibility] = originalColumnProfiles.filter(e=>e[_profileId]=== this.profileBeforeChanges[_profileId])[0][_visibility];
            //we need this because profileBeforeEdit could be a different profile when we reset a certain profile
        }
        let profileColumns = copyObjectValues(this.state.profileColumns);
        for(let e in profileColumns){
            profileColumns[e][_isExpanded] = false;
        }
        let newProfile = copyObjectValues(this.profileBeforeChanges || profileBeforeEdit) 
        tempState.profileColumns = this.updateDataFlags(profileColumns,  newProfile[_profileColumns]);    //when columns flags when profile has changed
        this.setSelectedProfile(newProfile, tempState, cb,fromReset,true);
    }

    discardAndClose() {
        if(this.state.displayPanel) {
            this.onToggleBoard();
        } else {
            this.discardChanges(this.toggleBoard);
            // this.cancel(this.toggleBoard);    
        }
        $("#search_input_manage_columns").val('');
        let data = copyObjectValues(this.state.profileColumns);
        data = this.updateSearchResult(data, '');

        this.setState({
            profileColumns: data,
            isSearching: false,
            searchValue:""
    })
    }
    /**
     * API function mean to be called from outside
     * @param {*} disable 
     */
    disableButton=(disable)=> {
        this.setState({
            disabled: disable
        })
    }
    componentDidMount() {
        const _this = this;
        this._isMounted = true;
        this.init(true);
        if (this.props.sendUniqueId) {
            $(document).on("click", "#overlay-"+this.props.sendUniqueId, this.discardAndClose);
        }else if (this.props.id) {
            $(document).on("click", "#"+this.props.id, this.discardAndClose);
        }else{
            $(document).on("click", "#overlay-manage-columns", this.discardAndClose);
        }
        // $(document).on("click",function(e){ // closing managecolumns menu drop down when a value is inserted in the input field and clicking outside 
        //     if (e.target.id && e.target.id !== "input_field" && _this.menuRef  && _this.menuRef.state && _this.menuRef !== null) {
        //         _this.menuRef.setState({
        //             isFocused:false,
        //             inputValue:""
        //         });
        //         _this.menuRef.forceUpdate();
        //         if ( _this.menuRef?.dropdownRef?.current?.getDropDown()) {
        //             _this.menuRef?.dropdownRef?.current?.showDropdown();
        //         }
        //     }
        // })
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.props.id) {
            $("#"+this.props.id).off("click", this.discardAndClose);
        }else{
            $("#overlay-manage-columns").off("click", this.discardAndClose);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.scenarioId !== prevProps.scenarioId
            || this.props.profitFormat !== prevProps.profitFormat
            || this.props.comparisonScenarioId !== prevProps.comparisonScenarioId
            || (this.props.selectedDashboard && (this.props.selectedDashboard.value !== prevProps.selectedDashboard.value || this.props.selectedDashboard[_visibility] !== prevProps.selectedDashboard[_visibility]))) {
            //on change of scenario or report format, refetch the columns from the API
            if(this.props.isDashboards) {
                this.fetchAll();
            }else if(!this.props.isDashboards){
                if(this.props.isCompareScenarios && !(this.props.comparisonScenarioId || this.props.scenarioId)){
                    return;
                }
                this.init();
            } 
        }
    }

    //#region save profile modal functions
    showSaveProfileDialog=()=> {
        let _this = this;
        if(_this.customProfileInputRef) {
            _this.customProfileInputRef.reset();
        }
        let tempColumnProfiles = _this.state.columnProfiles;
        let tempSelectedProfile = _this.state.selectedProfile;
        let modifiedName = tempSelectedProfile[_profileName].substr(0, tempSelectedProfile[_profileName].indexOf('(')).trim(); // Get name without ([number])
        let name = modifiedName !== "" ? modifiedName : tempSelectedProfile[_profileName]; //if name didn't contain ([number]) take original name else take modified name
        
        let counter = "";
        let duplicateNames = tempColumnProfiles.filter(e=>e[_profileName].startsWith(name) && e[_visibility] === tempSelectedProfile[_visibility]); // find duplicate names
        if(duplicateNames.length > 0){
            let greatestProfileIdObj = duplicateNames.reduce(function(prev, current) {
                return (prev[_profileId] > current[_profileId]) ? prev : current
            }) // take latest profileId 
            counter = greatestProfileIdObj[_profileName][ greatestProfileIdObj[_profileName].length - 2]; //extract counter from name
            if(!isNaN(counter)){
                counter = Number(counter) + 1; // increment counter by 1
            } else {
                counter = 1;
            }
            
        } 
        _this.setState({
            visibiliyChecked:undefined,
            ["tempSaved"+_profileName]:  name + (duplicateNames.length  > 0 ? " ("+(counter)+")" : ""),
            ["tempSaved"+_visibility]: _this.state.selectedProfile[_isApplied] ? _visibilityOptions.USER : this.state.selectedProfile[_visibility]      //set as user by default
        }, ()=>{
            _this.setSaveProfileDialogOpen(true)
        })
    }

    cancelSave() {
        if(this.customProfileInputRef) {
            this.customProfileInputRef.reset();
        }
        this.setState({
            ["tempSaved"+_profileName]: undefined,
            ["tempSaved"+_visibility]: undefined,
            isProfileNameUnique: true,
            messageNotMatch: "",
            isVisibilityValid: false,
            visibilityCheckedlist:[],
            visibilityChecked: undefined,
            visibilityCheckedMessage: undefined,
            openSaveProfileDialog: false
        });
    }

    /**
     * when editing the name of the profile using the edit icon in the top of the side panel (if name valid and unique, change the state.selectedProfile.name )
     * @param {*} e 
     */
     startEditProfileName=(e)=>{
        let _this = this;
        let tempSelectedProfileId = this.state.selectedProfile[_profileId];
        let tempSavedvisibility = this.state.tempSavedvisibility;
        let nameContainerDiv = document.getElementById(this.props.isLandscapeBubble?"profile-name" + this.props.sendUniqueId:"profile-name");
        nameContainerDiv.setAttribute("contentEditable","true");
        nameContainerDiv.focus();
        nameContainerDiv.onblur = (e) => {
            let tempState= {};
            tempState.nameNotValidMessage = "";
            let newValue = e.target.outerText;
            let notValid = checkStartsWithDigit(newValue);
            let nameWithoutSpaces = newValue.replace(/\s/g, '');
            let visibility = tempSavedvisibility && tempSavedvisibility !== "" ? tempSavedvisibility : _this.state.selectedProfile[_visibility];
            let isUnique = _this.validateProfileName(newValue.trim(),visibility,tempSelectedProfileId);
            if(notValid || !isUnique || nameWithoutSpaces === ""){ 
                tempState.nameNotValidMessage = nameWithoutSpaces === ""? lang.fill_empty_fields : notValid? lang.name_starting_number : !isUnique? lang.manage_columns.text.profile_name_is_not_unique : "";
            }else{
                let profile = copyObjectValues(_this.state.selectedProfile);
                if(newValue.trim() !== profile[_profileName].trim()){
                    profile[_isModified] = true;
                }
                profile[_profileName] = newValue;
                profile[_label] = newValue;
                tempState.selectedProfile = profile;
                tempState["tempSaved"+_profileName]= newValue
            }
            _this.setState(tempState,function(){
               nameContainerDiv.setAttribute("contentEditable","false")
            })
        };
    }

    /**
     * to edit the visibility of a profile from the top of the side panel (change state.selectedProfile.visibility)
     * @param {*} visibility 
     */
    startEditVisibility=(visibility)=>{
        // let isUnique = this.validateProfileName(this.state.selectedProfile[_profileName], visibility);
        
        let nameContainerDiv = document.getElementById(this.props.isLandscapeBubble?"profile-name" + this.props.sendUniqueId:"profile-name");
        let isUnique = this.validateProfileName($(this.props.isLandscapeBubble?"#profile-name" + this.props.sendUniqueId:"#profile-name").text().trim(), visibility);
        let tempSelectedProfileId = this.state.selectedProfile[_profileId];
        let columnProfiles = copyObjectValues(this.state.columnProfiles);
        let originalSelectedProfileVisibiltiy = columnProfiles.filter(e=>e[_profileId] === tempSelectedProfileId)[0][_visibility];
        let profile = copyObjectValues(this.state.selectedProfile);
        let tempState = {};
        if(isUnique){
            if(visibility === _visibilityOptions.USER){
                tempState.messageNotMatch = "";
                tempState.isVisibilityValid = false;
            }
            let checkVisibility = this.checkVisibility();
            
            if (checkVisibility !== true) {
                if(visibility === _visibilityOptions.USER){
                    tempState.visibiliyChecked = lang.manage_columns.text.check_visibility;
                   
                } else{
                    tempState.visibiliyChecked = undefined;
                }
                tempState.visibilityCheckedlist = checkVisibility;
                tempState["tempSaved"+_visibility]= visibility;
                // profile.visibility = visibility;
                // tempState.selectedProfile = profile;
            }else {
                if(originalSelectedProfileVisibiltiy.toLowerCase() === _visibilityOptions.COMPANY && visibility.toLowerCase() === _visibilityOptions.USER){
                    profile[_accesschanged] = true;
                }else{
                    profile[_accesschanged] = false;
                }
                if(originalSelectedProfileVisibiltiy !== visibility){
                    profile[_isModified] = true;
                } else {
                    profile[_isModified] = false;
                }
                profile.visibility = visibility;
                tempState.selectedProfile = profile;
                tempState["tempSaved"+_visibility]= visibility;
                tempState.nameNotValidMessage = "";
            }
        }else{
            tempState["tempSaved"+_visibility]= visibility
            tempState.nameNotValidMessage = lang.manage_columns.text.profile_name_is_not_unique;
        }
       
        this.setState(tempState,()=> {nameContainerDiv.setAttribute("contentEditable","false")});
    }

    deleteProfileDialogContent = () => {
        let _this = this;

        let profileId = _this.state.selectedProfileId;
        let profile = _this.state.columnProfiles?.filter(e=>e[_profileId] === profileId)[0];

        if(!profileId) {return;}

        return (
            <div>
                <div className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall">
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    <div className="fs-16">{lang.dashboards.messages.confirm_delete_list}</div>
                </div>
                <div key="b" className="uk-flex-center uk-padding-large fs-14">
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.name}</span>
                        <span>{profile[DASHBOARDS.LABEL]}</span>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.created_by}</span>
                        <span>{profile[DASHBOARDS.CREATOR_NAME]}</span>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.visibility}</span>
                        <span>
                            {profile[DASHBOARDS.VISIBILITY] === _visibilityOptions.COMPANY ? 
                                <i title="By Company" className="fa-lg fal fa-users uk-margin-small-right"></i>
                                : profile[DASHBOARDS.VISIBILITY] === _visibilityOptions.USER ?
                                <i title="You" className="fa-lg fal fa-user uk-margin-small-right"></i> 
                            :""}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    deleteProfileDialogActions = () => {
        let _this = this;
        let profileId = _this.state.selectedProfileId;
        let fromOptions = _this.state.fromOptions;

        return (
            <>
                <Button 
                    label={lang.modal.buttons.delete}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => {
                        _this.deleteColumnProfile(profileId, fromOptions);
                        _this.setDeleteProfileDialogOpen(false, undefined);
                    }}
                />
                <Button 
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    data-dismiss="modal"
                    className="uk-margin-default-right"
                    onBtnClick={() => _this.setDeleteProfileDialogOpen(false, undefined)}
                />
            </>
        )
    }
    setDeleteProfileDialogOpen = (isOpen, profileId, fromOptions=false) => {
        let _this = this;
        _this.setState({
            openDeleteProfileDialog: isOpen,
            selectedProfileId: profileId,
            fromOptions: fromOptions
        })
    }

    startDelete(e, profileId, fromOptions=true) {
        const _this = this;
        
        e.stopPropagation();
        _this.closeManageColumnsDropDown();
        _this.setDeleteProfileDialogOpen(true, profileId, fromOptions);
    }

    deleteColumnProfile(deletedProfileId, fromOptions) {
        const _this = this;
        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId
        _this.clearManageColumnsFromStore(scenarioId)
        let query = {
            action: "deleteColumnProfile",
            profileId: deletedProfileId,
            scenario_id: this.props.scenarioId,
            profitFormat: this.props.profitFormat.replaceAll(" ","_").toLowerCase(),
        }

        let onThenCallback = (data) => {
            let clientProfiles = copyObjectValues(this.state.clientProfiles);
            clientProfiles = clientProfiles.filter(e=>e[_profileId] !== deletedProfileId);
            if(data.success) {
                let tempState = {
                    message: lang.manage_columns.text.deleted_successfully,
                    isError: false,
                    columnProfiles: _this.getUpdatedColumnProfiles(undefined, false, deletedProfileId),
                    clientProfiles: clientProfiles
                }
                let columnProfiles = copyObjectValues(tempState.columnProfiles);
                tempState.profileOptions = _this.getProfileOptions(tempState.columnProfiles);

                let defaultProfileId = deletedProfileId === _this.state.defaultProfileId ? _this.state.companyDefaultProfileId : _this.state.defaultProfileId;
                tempState.defaultProfileId = defaultProfileId;
                //resetting to default when deleting from main component
                let selectedProfileFromSession= typeof tryParse(sessionStorage.getItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase())) === "object" ? tryParse(sessionStorage.getItem("selectedProfile_"+_this.props.profitFormat.toLowerCase())) : undefined ;
                let profileId = selectedProfileFromSession && selectedProfileFromSession !== null  ? selectedProfileFromSession[_profileId] : undefined;
                if(profileId && deletedProfileId === _this.state.selectedProfile[_profileId]){
                    _this.setDefaultProfileAsSelectedProfile(columnProfiles, defaultProfileId, tempState, true);
                    if(profileId === deletedProfileId){
                        sessionStorage.removeItem("selectedProfile_"+this.props.profitFormat); 
                    }
                } else if (deletedProfileId === _this.state.selectedProfile[_profileId]){
                    _this.setDefaultProfileAsSelectedProfile(columnProfiles, defaultProfileId, tempState, true);
                } else {
                    if(profileId && _this.state.selectedProfile[_profileId] === profileId){ 
                        columnProfiles = columnProfiles.filter(e=>e[_profileId] !==  profileId);
                        columnProfiles.push(selectedProfileFromSession);
                        tempState.columnProfiles = columnProfiles;
                    } 
                    _this.setDefaultProfileAsSelectedProfile(columnProfiles,_this.state.selectedProfile[_profileId], tempState, true);
                }
                if(typeof _this.props.fetchColumnsOnSave === "function"){
                    _this.props.fetchColumnsOnSave()
                }
                _this.launchToast();
            } else {
                _this.setState({
                    message: lang.execution_error,
                    isError: true
                }, function(){
                    
                    _this.launchToast()
                })
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "deleteColumnProfile",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
    
        fetchAPI(fetchOptions);
    }

    validateVisibility(visibility) {
        var visibility = visibility ? visibility : this.state["tempSaved"+_visibility] || this.state.selectedProfile[_visibility];
        if (visibility === _visibilityOptions.COMPANY) {
            let checkedItems = copyObjectValues(this.state.originalCheckedItems);
            return checkedItems.filter(e=>e[_visibility] === _visibilityOptions.USER)
        }
        return [];
    }
    
    startSave(saveAsNew=true) {
        const _this = this;
        this.setSaveProfileDialogOpen(false)
        let checkVisibility = this.checkVisibility();
        
        if (checkVisibility !== true && _this.state.tempSavedvisibility === _visibilityOptions.USER && !saveAsNew) {
            let visibilityCheckedMessage = lang.manage_columns.text.check_visibility;
            let tempState = {};
            tempState.visibilityChecked = checkVisibility === true ? undefined : visibilityCheckedMessage;
            tempState.visibilityCheckedlist = checkVisibility === true ? [] : checkVisibility;
            this.setState(tempState);
        }
        if(checkVisibility!= true && !saveAsNew && _this.state.tempSavedvisibility === _visibilityOptions.USER) {
            return;
        }
        // let missingItems = this.getProfileInvalidColumns(this.state.selectedProfile);
        // if(missingItems.length) {
        //     //extract missing items if any
        //     let message = lang.manage_columns.text.profile_and_scenario_do_not_match_1;
        //     message += lang.modal.variable_place_holder;
        //     message += lang.manage_columns.text.missing_cols_will_be_unselected;

        //     let missingItemsText = this.getMissingItemsAsText(missingItems);
        //     this.props.modalRef.setVariableList(missingItemsText);
        //     this.props.modalRef.showModal(undefined, message, lang.modal.buttons.confirm, ()=>{
        //         setTimeout(()=>{
        //             _this.setSelectedProfile(_this.simplifyProfileReturnNames(_this.state.selectedProfile), undefined, ()=>{
        //                 _this.saveProfile(saveAsNew);
        //             });
        //         }, 200);        //to give the modal some time to hide before showing the next one so they don't collide
        //     }, lang.modal.buttons.cancel, ()=>{});
        // } else {
            this.saveProfile(saveAsNew);
        // }
    }

    saveProfile(saveAsNew=true) {
        const _this = this;
        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId
        _this.clearManageColumnsFromStore(scenarioId)
        var userColumns = this.validateVisibility();
        var messageAppend = [];
        userColumns.forEach(function(item){if (!messageAppend.includes(item.groupName +":" + item[_columnName]))messageAppend.push(item.groupName +":" + item[_columnName])});
        if (userColumns.length > 0) {
            this.setState({
                messageNotMatch: lang.manage_columns.text.visibility_not_match.replace('[x]',messageAppend.join(", \n")),
                isVisibilityValid: true
            },function(){
                if(saveAsNew){
                    this.showSaveProfileDialog();
                }
            });
        }else{
            sessionStorage.removeItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase());
            let selectedProfile = copyObjectValues(this.state.selectedProfile);
            let columnProfile = this.state.selectedProfile;
            let clientProfiles = copyObjectValues(this.state.clientProfiles);
            let originalColumnProfiles = copyObjectValues(this.state.originalColumnProfiles);
            
            //replace old columns with list of ordered ones
            columnProfile = copyObjectValues(columnProfile);
            //read temp data
            columnProfile[_isFavorite] = saveAsNew ? false : columnProfile[_isFavorite];
            if(this.state["tempSaved"+_profileName]){
                columnProfile[_profileName] = this.state["tempSaved"+_profileName].trim();
            }
            columnProfile[_visibility] = this.state.access.edit_company_cols ? this.state["tempSaved"+_visibility] || columnProfile[_visibility] : _visibilityOptions.USER;
            columnProfile[_updatedBy] = Number(this.props.user.userId);
            
            let editFromCompanyToUser = originalColumnProfiles.filter(f=> f[_columnProfileId] == columnProfile[_columnProfileId])[0][_visibility] === 'company' && columnProfile[_visibility] === 'user';
            if(saveAsNew || editFromCompanyToUser) {
                columnProfile[_createdBy] = Number(this.props.user.userId);
                columnProfile[_creatorName] = this.props.user.first_name + " " + this.props.user.last_name;
            }

            let profileToSend = this.simplifyProfileReturnNames(copyObjectValues(columnProfile));
            profileToSend[_checkedItems] = removeAttributes(profileToSend[_simplifiedColumns]);
            if (!saveAsNew) {
                profileToSend.name = profileToSend.label;
                columnProfile.name = columnProfile.label;
                selectedProfile.name = selectedProfile.label;
            }
            // } else {
            //     profileToSend.label = profileToSend.name;
            //     columnProfile.label = columnProfile.name;
            //     selectedProfile.label = selectedProfile.name;
            // }
          

            columnProfile[_checkedItems] = profileToSend[_checkedItems];    //update the checked columns for this profile
            columnProfile[_profileColumns] = profileToSend[_checkedItems];
    
            let query = {
                action: saveAsNew ? "saveNewColumnProfile" : "updateColumnProfile",
                profileData: profileToSend,
                profileId: saveAsNew ? 0 : this.state.selectedProfile[_profileId],
                sectionReturnNames: [this.props.profitFormat.toLowerCase().replaceAll(" ","_")],
                scenario_id: this.props.scenarioId,
            }
            
            let onThenCallback = (data) => {
                // _this.hideSaveProfileDialog();
                _this.setSaveProfileDialogOpen(false);
                if(data.success) {
                    columnProfile[_isCompanyDefault] = !saveAsNew && parseBoolean(_this.props.user.is_system) ? columnProfile[_isCompanyDefault] : false; // it was originally false but now system user can edit the system default profile
                    columnProfile[_profileId] = saveAsNew ? data.new_profile_id : this.state.selectedProfile[_profileId];    //read new inserted id from API
                    columnProfile[_isSaved] = true;             //you just saved it...
                    columnProfile[_isModified] = false;         //if it was modified, well, not anymore
                    columnProfile[_isApplied] = false;          //if it was applied, well, not anymore
                    columnProfile[_isUserDefault] = saveAsNew ? false : columnProfile[_isUserDefault];      //if saving from a default, do not take this attribute if not overwriting
                    columnProfile[_isValid] = true;     //just saved it, cannot have invalid columns
                    columnProfile[_profileColumns].map(function(item){
                        item[_profileId] = data.new_profile_id
                    });
                    columnProfile.originalColumns = columnProfile[_profileColumns];

                    let clientProfileToAdd = {
                        [_profileId]: data.new_profile_id,
                        [_visibility]:columnProfile[_visibility],
                        [_profileName]:columnProfile[_profileName]
                    }
                    
                    if(!saveAsNew){
                        originalColumnProfiles = originalColumnProfiles.filter(e=>e[_profileId]!==columnProfile[_profileId]);
                        originalColumnProfiles.push(columnProfile);
                        
                    }
                    
                    if (saveAsNew) { // resetting columns to the original columns if the sleected profile is not being saved and only used as a base 
                        selectedProfile[_profileColumns] = this.state.selectedProfile.originalColumns;
                        originalColumnProfiles.push(columnProfile); //pushing new profile if it doesnt exist
                        clientProfiles.push(clientProfileToAdd);
                    }

                    let columnProfiles = _this.getUpdatedColumnProfiles(columnProfile, true, columnProfile[_profileId]);
                    if (saveAsNew) { // when saving a new profile based on another profile => the onther profile should be reverted to its old state since it's being changed 
                        for (var e in columnProfiles) {
                            if (columnProfiles[e][_profileId] === _this.state.selectedProfile[_profileId]) {
                                columnProfiles[e][_profileColumns] = columnProfiles[e].originalColumns;
                                columnProfiles[e][_isModified] = false;
                                columnProfiles[e][_isApplied] = false;
                            }
                        }
                    }
                    
                    selectedProfile[_accesschanged] = false;
                    let tempState = {
                        selectedProfile: selectedProfile,
                        message: this.state.isEditingColumn ? lang.manage_columns.text.edited_successfully : lang.manage_columns.text.saved_successfully,
                        isError: false,
                        columnProfiles: columnProfiles,
                        isProfileNameUnique: true,
                        messageNotMatch: "",
                        isVisibilityValid: false,
                        visibilityChecked: undefined,
                        visibilityCheckedlist: [],
                        clientProfiles:clientProfiles,
                        originalColumnProfiles:originalColumnProfiles
                    }
                    
                    columnProfile[_simplifiedColumns] = profileToSend[_simplifiedColumns];

                    tempState.profileOptions = _this.getProfileOptions(tempState.columnProfiles);
                    _this.applyProfileChanges(columnProfile, false, tempState);      //apply the changes and set the newly saved profile as the one selected (inside)
                    _this.toggleBoard();
                    if(typeof _this.props.fetchColumnsOnSave === "function"){
                        _this.props.fetchColumnsOnSave()
                    }
                    _this.launchToast();
                } else {
                    _this.setState({
                        isError:true,
                        message: lang.execution_error
                    },function(){
                        _this.launchToast();
                    })
                }
            }
    
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "saveProfile",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
                [FETCHAPI_PARAMS.showLoader]: true,
                [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
            }
        
            fetchAPI(fetchOptions);
        }
        
    }

    startApply() {
        const _this = this;
        _this.applyChanges();
        $("#search_input_manage_columns").val('');
        let data = copyObjectValues(this.state.profileColumns);
        data = this.updateSearchResult(data, '');

        this.setState({
            profileColumns: data,
            isSearching: false,
            searchValue:""
        })
    }

    applyChanges() {
        const _this = this;
        //set selected profile as modified
        let keyVal = {
            [_isApplied]: true,
        }
        sessionStorage.removeItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase()); // remove list from session when pressing apply
        sessionStorage.removeItem(SELECTED_DRILL_LIST); // remove list from session when pressing apply

        this.updateSelectedProfile(keyVal, undefined, ()=>{
            _this.applyProfileChanges(_this.state.selectedProfile, true, undefined, undefined, true);
            // _this.setDefaultProfileAsSelectedProfile(_this.state.selectedProfile[_profileColumns],_this.state.selectedProfile[_profileId] );
        });
    }
/**
 * function sends request to th api to either add or remove profile from list of profiles 
 * @param {*} e 
 * @param {*} profile 
 * @param {*} isFav 
 */
    makeProfileFavorite=(e, profile, isFav)=>{
        e.stopPropagation();
        const _this = this;
        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId
        _this.clearManageColumnsFromStore(scenarioId)
        let query = {
            action:"saveProfileFavorite",
            profileId:profile[_profileId],
            profitFormat: _this.props.profitFormat.replaceAll(" ","_").toLowerCase(),
            scenario_id: _this.props.scenarioId,
            isFavorite: isFav
        }

        let onThenCallback = (data) => {
            if(data.success) {
                profile[_isFavorite] = isFav;
                let tempState = {
                    message: isFav ? lang.manage_columns.text.made_favorite_successfully.replace("[List Name]", profile[_profileName]) 
                    : lang.manage_columns.text.made_unfavorite_successfully.replace("[List Name]", profile[_profileName]) ,
                    isError: false,
                    columnProfiles: _this.getUpdatedColumnProfiles(profile, true, profile[_profileId]),
                }
                tempState.profileOptions = _this.getProfileOptions(tempState.columnProfiles);
                if(typeof _this.props.fetchColumnsOnSave === "function"){
                    _this.props.fetchColumnsOnSave()
                }
                _this.setState(tempState ,function(){
                    _this.launchToast();
                    _this.closeManageColumnsDropDown();
                });
            } else {
                _this.setState({
                    isError: true,
                    message: lang.execution_error
                }, function(){
                    _this.launchToast();
                    _this.closeManageColumnsDropDown();
                })
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveProfileFavorite",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
    
        fetchAPI(fetchOptions);
    }

    closeManageColumnsDropDown=()=>{
        let _this = this;
        _this.menuRef.setState({
            isFocused:false,
            inputValue:""
        });
        _this.menuRef.forceUpdate();
    }

    makeProfileDefault(e, profile) {
        e.stopPropagation();    //do not proceed to select the option
        const _this = this;
        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId
        _this.clearManageColumnsFromStore(scenarioId)
        let query = {
            action: "saveProfileAsDefault",
            profileId: profile[_profileId],
            profitFormat: this.props.profitFormat.replaceAll(" ","_").toLowerCase(),
            scenario_id: this.props.scenarioId
        }

        let onThenCallback = (data) => {
            if(data.success) {
                profile[_isUserDefault] = true;
                let tempState = {
                    message: lang.manage_columns.text.made_default_successfully.replace("[List Name]", profile[_profileName]),
                    isError: false,
                    columnProfiles: _this.getUpdatedColumnProfiles(profile, true, profile[_profileId]),
                    defaultProfileId: profile[_profileId]   //set the new id after setting a new column as default
                }
                tempState.profileOptions = _this.getProfileOptions(tempState.columnProfiles);
                if(typeof _this.props.fetchColumnsOnSave === "function"){
                    _this.props.fetchColumnsOnSave()
                }
                _this.setState(tempState ,function(){
                    //sessionStorage.removeItem("selectedProfile_"+_this.props.profitFormat); not sure if we need this tbd
                    _this.launchToast();
                    _this.closeManageColumnsDropDown();
                });
            } else {
                _this.setState({
                    isError: true,
                    message: lang.execution_error
                }, function(){
                    _this.launchToast();
                    _this.closeManageColumnsDropDown();
                })
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "makeProfileDefault",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
    
        fetchAPI(fetchOptions);
    }

    startEditProfile(e, profileToEdit) {
        e.stopPropagation();    //do not proceed to select the option
        
        this.setState({
            checkedItems: []
        },function(){
            let originalColumnProfiles = copyObjectValues(this.state.originalColumnProfiles);
            let originalColumns = originalColumnProfiles.filter( e=> e[_profileId] === profileToEdit[_profileId])[0].columns;
            let profileColumns = copyObjectValues(this.state.profileColumns);
            for(let e in profileColumns){  //This is done for the purpose of redrawing the correct checked columns for the profile to edit
                profileColumns[e][_isExpanded] = false;
            }
            let selectedProfileFromSession= typeof tryParse(sessionStorage.getItem("selectedProfile_"+ this.props.profitFormat.toLowerCase())) === "object" ? tryParse(sessionStorage.getItem("selectedProfile_"+this.props.profitFormat.toLowerCase())) : undefined ;
            let profileId = selectedProfileFromSession && selectedProfileFromSession !== null  ? selectedProfileFromSession[_profileId] : undefined;
            if(profileId && profileId === profileToEdit[_profileId]){
                profileToEdit = selectedProfileFromSession;
            } else {
                profileToEdit[_isApplied] = false;
                profileToEdit[_isModified] = false;
                profileToEdit[_profileColumns] = profileToEdit.originalColumns;
            }
            let tempState = {
                profileBeforeEdit: this.state.selectedProfile,
                profileColumns: this.updateDataFlags(profileColumns, profileToEdit[_profileColumns]),    //update column flags when profile has changed
                checkedItems:profileToEdit[_isModified] || profileToEdit[_isApplied] ? profileToEdit.originalColumns : originalColumns,
                ["tempSaved"+_visibility]:profileToEdit[_visibility],
                messageNotMatch:"",
                isVisibilityValid:false
            }
            this.setSelectedProfile(copyObjectValues(profileToEdit), tempState, this.toggleBoard, undefined,undefined,true);
            // this.closeManageColumnsDropDown();
            // this.menuRef?.dropdownRef?.current?.showDropdown(true);
        })
    }

    validateProfileName(name, visibility,profileId=this.state.selectedProfile[_profileId]) {
        let isUnique = false;
        let nameToCheckTwo  = this.state.columnProfiles.filter(e=>e[_profileName].toLowerCase().trim() === name.toLowerCase().trim() && e[_visibility] === visibility && e[_profileId] !== profileId);
        if(this.state?.openSaveProfileDialog){ // if save modal is open
            let nameToCheck  = this.state.columnProfiles.filter(e=>e[_profileName].toLowerCase().trim() === name.toLowerCase().trim() && e[_visibility] === visibility);
            if(nameToCheck.length === 0) {
                isUnique = true;
            }     
        } else if (nameToCheckTwo.length === 0){
          
                isUnique = true;
            
         } else {
            let usedNames = this.state.columnProfiles.filter(p=>p[_visibility] === _visibilityOptions.USER || p[_isCompanyDefault]);
            if(visibility === _visibilityOptions.COMPANY /*&& this.state.access.view_company_cols*/) {
                usedNames = usedNames.concat(this.state.clientProfiles.filter(p=>p[_visibility] === _visibilityOptions.COMPANY));
            }
            usedNames = usedNames.map(elem=>elem[_profileName].toLowerCase());
            isUnique = !usedNames.includes(name.toLowerCase())
        }

        return isUnique;
    }

    checkVisibility(visibility,checkColumnsInOther,isUnique){
        let prfId = this.state.selectedProfile[COLUMN_PROFILE.ID];
        let widgets = this.state.dashboardWidgets;
        let columnBeingEdited = this.state.columnBeingEdited;
        let list = [];
        let dict = {}
        let _this = this;
        if (visibility && visibility.toLowerCase() === _visibilityOptions.COMPANY.toLowerCase()) {
            return true;
        }
        if(this.state?.openSaveProfileDialog){
            let profileName = $(this.props.isLandscapeBubble ? "#_profileName" + this.props.sendUniqueId : '#_profileName').val();
             if(profileName.toLowerCase().trim() !== this.state.selectedProfile[_profileName].toLowerCase().trim()){
                return true;
            } else if(isUnique){
                return true;
            }
        }

        
        for (var e in widgets) {
            let config = tryParse(widgets[e][DASHBOARDS.WIDGET.CONFIGURATION]);
            let widget_type = widgets[e][DASHBOARDS.WIDGET.WIDGET_TYPE];
            let prfWidgetId = config ? config[COLUMN_PROFILE.ID] : 0;
            if (prfId === prfWidgetId && widgets[e][DASHBOARDS.VISIBILITY] === DASHBOARDS.VISIBILITY_OPTIONS.COMPANY && !checkColumnsInOther) {
                // if (!list.includes(widgets[e][DASHBOARDS.WIDGET.NAME] + ":" + this.state.selectedProfile[COLUMN_PROFILE.NAME])) {
                //     list.push(widgets[e][DASHBOARDS.DASHBOARD_NAME] + " :" + widgets[e][DASHBOARDS.WIDGET.NAME]);
                // }
                if(dict[widgets[e][DASHBOARDS.ID]])
                     dict[widgets[e][DASHBOARDS.ID]].push(widgets[e][DASHBOARDS.NAME]);
                else {
                    dict[widgets[e][DASHBOARDS.ID]] = [widgets[e][DASHBOARDS.NAME]];
                }
            }
            if(widget_type === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC){
                let column = config[DASHBOARDS.CONFIG_FIELD.COLUMN];
                if(columnBeingEdited && columnBeingEdited[MANAGE_COLUMNS.COLUMN_RETURN_NAME] === column[MANAGE_COLUMNS.COLUMN_RETURN_NAME] && widgets[e][DASHBOARDS.VISIBILITY] === DASHBOARDS.VISIBILITY_OPTIONS.COMPANY && checkColumnsInOther ){
                    if(dict[widgets[e][DASHBOARDS.ID]])
                        dict[widgets[e][DASHBOARDS.ID]].push(widgets[e][DASHBOARDS.NAME]);
                    else {
                        dict[widgets[e][DASHBOARDS.ID]] = [widgets[e][DASHBOARDS.NAME]];
                    }
                }
            }
        }
        Object.keys(dict).forEach(function(key) {
            let value = dict[key];
            let dashboard_name = _this.state.dashboardWidgets.filter(e=>e[DASHBOARDS.ID] === Number(key))[0][DASHBOARDS.DASHBOARD_NAME];
            list.push(dashboard_name);
            for (var e in value){
                list.push("- "+value[e]);
            }
        });
        if(list.length === 0) {
            return true;
        }
        return list;
    }

    storeTempSavedData(key, value) {
        let isUnique = "";
        let tempState = {};
        let checkVisibility = true;
        if( key === _profileName) {
            isUnique = this.validateProfileName(value, this.state["tempSaved"+_visibility]);
            
        } else {
            isUnique = this.validateProfileName(this.state["tempSaved"+_profileName], value);
        }
       
        // if (key === _visibility) {
        //     checkVisibility = this.checkVisibility(value);
        // }
        // let checkVisibility = true;
        // if(value ===  _visibilityOptions.USER){
        //     tempState.messageNotMatch = "";
        //     tempState.isVisibilityValid = false;
        // }
        // if (checkVisibility !== true) {
        //     let visibilityCheckedMessage = lang.manage_columns.text.check_visibility;
        //     tempState.visibilityChecked = checkVisibility === true ? undefined : visibilityCheckedMessage,
        //     tempState.visibilityCheckedlist = checkVisibility === true ? [] : checkVisibility
        // }else {
        //     tempState.visibilityChecked = undefined;
        //     tempState.visibilityCheckedlist = [];
        // }
        if(key === _visibility){
            var userColumns = this.validateVisibility(value);
            var messageAppend = [];
            userColumns.forEach(function(item){if (!messageAppend.includes(item.groupName +":" + item[_columnName]))messageAppend.push(item.groupName +":" + item[_columnName])});
            if (userColumns.length > 0 && value === _visibilityOptions.COMPANY) {
                    tempState.messageNotMatch = lang.manage_columns.text.visibility_not_match.replace('[x]',messageAppend.join(", \n"));
                    tempState.isVisibilityValid = true;
                
            } else {
                tempState.messageNotMatch = undefined;
                tempState.isVisibilityValid = false;
            }
        }
        
        checkVisibility = this.checkVisibility(undefined,undefined,isUnique);
        if (checkVisibility !== true) {
            if(key === _visibility && value === _visibilityOptions.USER){
                tempState.visibilityChecked = lang.manage_columns.text.check_visibility;
                tempState.visibilityCheckedlist = checkVisibility;
            } else if (key === _profileName && isUnique){
                tempState.visibilityChecked = lang.manage_columns.text.check_visibility;
                tempState.visibilityCheckedlist = checkVisibility;
            } else {
                tempState.visibilityChecked = undefined;
            }
        } else {
            tempState.visibilityChecked = undefined;
        }
       
        tempState["tempSaved"+key] = value;
        tempState.isProfileNameUnique = isUnique;
        this.updateSelectedProfile({}, tempState);
    }
    //#endregion

    //#region APIs for outer components
    getProfileColumns() {
        let columns = this.getCheckedColumns();
        return columns.map(col=>col[_returnName]);
    }

    setWarnings(warningReturnNames, globalWarningMsg, individualWarningMsg) {
        if(!this.state.selectedProfile) {
            return;
        }

        let data = this.state.profileColumns;
        let tempObj = {
            [_hasWarning]: false,
            [_warningMessage]: undefined
        }
        data = this.updateDataKeys(data, tempObj);      //resetting those keys for all data

        if(warningReturnNames.length > 0) {     //if we have return names with warnings, we update them
            tempObj = {
                [_hasWarning]: true,
                [_warningMessage]: individualWarningMsg
            }
            data = this.updateDataKeys(data, tempObj, warningReturnNames);
        }

        let tempState = {
            profileColumns: data,
            warningMessage: warningReturnNames.length ? globalWarningMsg : undefined
        }
        this.setState(tempState);
    }

    /**
     * this function is used when there are specific columns that are fetched
     * from the API without being requested from the UI, they need to appear checked
     * @param {*} columnReturnNames 
     */
    setAdditionalColumns(columnReturnNames) {
        const _this = this;
        if(!this.state.selectedProfile) {
            return;
        }
        let originalModifiedValue = this.state.selectedProfile[_isModified];
        let currentColumns = this.getCheckedColumns().map(c=>c[_returnName]);
        currentColumns = currentColumns.concat(columnReturnNames);
        currentColumns = removeDuplicates(currentColumns);

        currentColumns.forEach(retName=>{
            //update using this function to mimic the user's manual checking of the cols,
            //to continue with the expected behavior normally (updating the selected profile, etc.)
            _this.toggleColumn(retName, _isChecked, undefined, true);
        });

        if(!originalModifiedValue) {
            //if the profile wasn't previously modified, update the is modified key so that it doesn't
            //show as if there are unsaved changes because of toggling columns
            this.updateSelectedProfile({[_isModified]: originalModifiedValue});
        }
    }

    /**
     * called when clicking on "apply". Updates the profile for the parents first,
     * and then calls the change data callback
     * @param {*} profile 
     * @param {*} tempState 
     * @param {*} callback 
     */
    applyProfileChanges(profile, fetchData=true, tempState={}, fromDelete=false, fromApply=false, dontReload, fromDropDown) {
        const _this = this;
        tempState.drillProfileBeforeChanges = undefined;
       

        if(_this.props.uncheckPSEntities){
            _this.props.uncheckPSEntities(); // to remove checked entities when changing in manage columns in drill screen
        }
        tempState.nameNotValidMessage="";
        profile = copyObjectValues(profile);
        if (this.props.isDashboards && this.props.onSelectProfile) {
            this.props.onSelectProfile(profile);
            this.setSelectedProfile(profile, tempState);
            return;
        }

        if ((this.props.isDrilling && (fromApply || fromDropDown))) {
            this.props.onChangeProfileData();
        }
        
        let callback = ()=> {   
            if(!this.props.isDrilling)   {
                _this.props.onChangeProfileData(copyObjectValues(profile));    //call the change data callback
            }
            if(fetchData && !fromDelete) {
                _this.toggleBoard();        //toggle the manage columns board
            }
        }
        let profileColumns = copyObjectValues(this.state.profileColumns);
        for(let e in profileColumns){
            profileColumns[e][_isExpanded] = false;
        }
        tempState.profileColumns = profileColumns;
        if (!fromApply && !(fromDropDown && this.props.isDrilling)) {
            this.setState({checkedItems:[]},function(){
                tempState.messageNotMatch= "";
                tempState.isVisibilityValid=false;
                _this.onChangeProfile(profile, tempState, callback, undefined, dontReload, fromDropDown);  
            })
        } else {
            // profile.columns = this.state.checkedItems.map(m => m.column_return_name);
            _this.onChangeProfile(profile, tempState, callback,  undefined, dontReload, fromDropDown);
            profile.columns = tempState.checkedItems; // this was done because everything that was newly checked didn't contain certain attributes which made them unchecked in manage columns
            let originalColumnProfiles = copyObjectValues(_this.state.originalColumnProfiles);
            let originalColumns  = originalColumnProfiles.filter(e=>e[_profileId] === profile[_profileId])[0].columns;
            let profileToCheck = originalColumnProfiles.filter(e=>e[_profileId] === profile[_profileId])[0];
            
            let appliedProfile = copyObjectValues(profile);
            this.setState({
              appliedProfile: appliedProfile
            })
            if(JSON.stringify(profile.columns) === JSON.stringify(originalColumns)  && profileToCheck[_visibility] === profile[_visibility] && profileToCheck[_profileName] === profile[_profileName] && !this.props.isDrilling ){
                profile[_isModified] = false;
                profile[_isApplied] = false;
                _this.props.updateManageColumnsProfile(profileToCheck);
                sessionStorage.removeItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase());
            } else {
                if(!this.props.isDrilling) { // if in drill screen don't update the selected list because profile will be for the drill list 
                    // sessionS]torage.setItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase(), JSON.stringify(profile)); // set selectedProfile on apply
                }
            }
        }

        if(fromDelete) {
            // to return the original columns of the default list
            profile.columns = linearizeHierarchy(profile.originalColumns, "children").map(col => col[PROFILE_COLUMN.RETURN_NAME]);
        }
    }

    /**
     * called when a profile is selected, updates the checked columns for this profile
     * and sets the profile data in the parent
     * @param {*} profile 
     * @param {*} tempState 
     * @param {*} fromApply 
     * @param {*} fromMount 
     */
    onChangeProfile(profile, tempState={}, callback, isDefault, dontReload, fromDropDown) {
        const _this = this;
        if (_this.props.dispatch && !this.props.isDashboards && !tempState.profileColumns && this.state.profileColumns.length === 0) {
            tempState.profileColumns = getItemFromStore("columnProfiles",_this, (this.props.scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint);
        }
        tempState.profileColumns = this.updateDataFlags(tempState.profileColumns || this.state.profileColumns, profile ?
                                    profile[_profileColumns] : this.state.checkedItems.length > 0 ? this.state.checkedItems : profile ? profile[_profileColumns]:"");
        if (fromDropDown) { // clicking on profile from drop down should reset it and disply original columns 
            tempState.checkedItems = profile[_profileColumns];
            profile[_isApplied] = false;
            profile[_isModified] = false;
            profile[_profileColumns] = tempState.checkedItems;
            profile.originalColumns = profile[_profileColumns];
            // Clicking on the dropdown after applying columns for the same profile
            if(profile.simplified_columns){
                _this.state.originalColumnProfiles.map((object, index) => {
                    if(object.column_profile_id == profile.column_profile_id){
                        profile.simplified_columns = _this.state.originalColumnProfiles[index].columns;
                    }
                })
            }
            tempState.appliedProfile = undefined;
            tempState.profileColumns = this.updateDataFlags(tempState.profileColumns?.length > 0 ? tempState.profileColumns : this.state.profileColumns?.length > 0 ? this.state.profileColumns : _this.props.dispatch && !_this.props.isDashboards ? getItemFromStore("columnProfiles",_this, (this.props.scenarioId)+"_"+(_this.props.profitFormat)+"_"+_this.props.constraint) : [],  profile[_profileColumns]);
            sessionStorage.removeItem("selectedProfile_"+ _this.props.profitFormat.toLowerCase()); //Remove when changing profile
            sessionStorage.removeItem(SELECTED_DRILL_LIST); //Remove when changing profile
        }
        tempState.profileBeforeEdit = undefined;
        this.setSelectedProfile(profile, tempState, ()=>{
            //when selecting from dropdown, column[_profileColumns] would be a list of object
            profile = copyObjectValues(profile);
            profile = this.simplifyProfileReturnNames(profile, fromDropDown);
            if(!this.props.isDashboards) {
                _this.checkIsProfileValid(profile);
            }
            if (typeof _this.props.onSelectProfile === "function" && !dontReload) {
              if(!_this.props.isLandscapeBubble || (_this.props.isLandscapeBubble && !isDefault)) {
                _this.props.onSelectProfile(profile, ()=>{
                    if(callback) {
                        callback();
                    }
                });
                if (isDefault && typeof _this.props.onSelectDefaultProfile === "function") {
                    _this.props.onSelectDefaultProfile(profile, ()=>{
                        if(callback) {
                            callback();
                        }
                    });
                }
              }
            }
        });
    }

    //#endregion

    //#region rendering functions
    getBoardTextForRendering() {
        let tempSelectedProfile = this.state.selectedProfile;
        let columnProfiles = copyObjectValues(this.state.columnProfiles);
        let tempName = this.state["tempSaved"+_profileName] ? this.state["tempSaved"+_profileName] : ""; //this.customProfileInputRef && this.customProfileInputRef.state.value ? this.customProfileInputRef.state.value.trim() : "";
        let nameAlreadyUsed = columnProfiles.filter(e=>e[_profileName.trim()] === tempName.trim() && e[_visibility] === this.state.tempSavedvisibility).length > 0;
        if(!tempSelectedProfile) {
            return {};
        }
        let profileName = ""
        let profileGrayText = "";
        let isModified = "";
        if(tempSelectedProfile[_isApplied] && tempSelectedProfile[_isModified]) {
            profileGrayText = tempSelectedProfile[_profileName];
            isModified += " " +  lang.manage_columns.titles.modified;
            let creator = "";
            if(Number(tempSelectedProfile[_createdBy]) === Number(this.props.user.userId)) {
                creator = lang.COMMON.you;
            } else {
                creator = tempSelectedProfile[_creatorName];
            }
            
            profileGrayText = tempSelectedProfile[_isCompanyDefault] || tempSelectedProfile[_isCreatorSystem] ? "" : " "+ lang.manage_columns.titles.created_by + " " + creator;
            profileName = tempSelectedProfile[_profileName];
        } else {
            let creator = "";
            if(Number(tempSelectedProfile[_createdBy]) === Number(this.props.user.userId)) {
                creator = lang.COMMON.you;
            } else {
                creator = tempSelectedProfile[_creatorName];
            }
            
            profileGrayText = tempSelectedProfile[_isCompanyDefault] || tempSelectedProfile[_isCreatorSystem] ? "" : " "+ lang.manage_columns.titles.created_by + " " + creator;
            profileName = tempSelectedProfile[_profileName];
            if(tempSelectedProfile[_isModified]) {
                isModified += " " +  lang.manage_columns.titles.modified;
            }
            
        }

        //TODO @georges continue on the creator name text for column being edited
        // let columnGrayText = "";
        // if(this.state.isEditingColumn) {
        //     columnGrayText = <React.Fragment><span></span><i title={title} className={"fa-lg fal "+icon+" uk-margin-small-right"}></i><span>{this.state[MANAGE_COLUMNS.NAME_ID] + " by " + prf.creator}</span></React.Fragment>
        // }

        let isSaveAsNewDisabled = false;
        if(!tempName || !this.state.isProfileNameUnique || nameAlreadyUsed || this.state.visibilityChecked || this.state.isVisibilityValid) {
            //if custom profile and no name is defined, if not unique, if saving an already saved profile without changing the name
            isSaveAsNewDisabled = true;
        }
        
        let isOverwriteDisabled = false;
        if(!isSaveAsNewDisabled || tempSelectedProfile[_isCompanyDefault] || (tempSelectedProfile[_visibility] === _visibilityOptions.COMPANY && !this.state.access.edit_company_cols)
            || !tempName || !this.state.isProfileNameUnique) {
            isOverwriteDisabled = true;
        }

        let isDeleteVisible = true;
        if( tempSelectedProfile[_isCompanyDefault] || (tempSelectedProfile[_visibility] === _visibilityOptions.COMPANY && !this.state.access.edit_company_cols)) {
                isDeleteVisible = false;        //if no access or working with an "applied" profile
        }

        let showTooltip = false;
        if(tempSelectedProfile[_visibility] === _visibilityOptions.COMPANY && !this.state.access.edit_company_cols && !tempSelectedProfile[_isApplied]
            || (tempSelectedProfile[_isCompanyDefault] && !tempSelectedProfile[_isApplied])) {
                showTooltip = true;
        }

        let returnObj = {
            profileName: profileName,
            grayText: profileGrayText,
            isOverwriteDisabled: isOverwriteDisabled ? "uk-button-disabled" : "",
            isDeleteAllowed: isDeleteVisible,
            isSaveAsNewDisabled: isSaveAsNewDisabled ? "uk-button-disabled" : "",
            overwriteTooltipMsg: showTooltip ? lang.manage_columns.text.no_access_to_edit_company_profiles : undefined,
            isModified: isModified
        };
        return returnObj;
    }

    /**
     ** @API function meant to be called from outside 
     */
    resetSelectedProfile() {
        this.setDefaultProfileAsSelectedProfile(this.state.columnProfiles, this.state.defaultProfileId);
    }

    /**
     ** @API function meant to be called from outside 
     */
    setProfile(id, type) {
        if (type.toLowerCase() !== ALL_WIDGETS.FIELDS.LIST) {
            this.fetchAll(id, type.toLowerCase().replace(" ",""));
        }else{
            this.setDefaultProfileAsSelectedProfile(this.state.columnProfiles, id);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let update = shallowCompare(this, nextProps, nextState);
        update = update || deepCompareObjects(nextState.profileColumns, this.state.profileColumns);
        return update;
    }

    /**API function meant to be called from outside */
    setCheckedItemsFromOutside(elements) {
        this.setState({
            checkedItems: elements,
        })
    }

     /**
     * function updates profit stack lines according to manage columns
     * @param {*} views 
     * @param {*} pslLines 
     * @param {*} allLines 
     */
      updatePSSOrder=(views, pslLines, allLines)=> {
        let _this = this;
        let originalColumns = copyObjectValues(_this.state.originalColumnProfiles);
        for (var e in pslLines) {
            let viewOptions = views.filter(elt=>elt[_type] === lang.manage_columns.column_types.psl.value && elt[_returnName] === pslLines[e][_returnName])
            for (let i in viewOptions) {
                let colOrder = viewOptions[i][_columnOrder] ? viewOptions[i][_columnOrder] : 1; // selected_fields
                if ((!_this.state.selectedProfile[_isModified] && originalColumns.filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId])[0][_profileColumns]
                    .filter(elt=>elt[_returnName] === viewOptions[i][_returnName]).length === 0)
                    || (_this.state.selectedProfile[_isModified] && views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName] && elt[_columnOrder] !== 0 && elt[_columnOrder] !== undefined).length === 0)) { // if the column is newly selected
                let currentIndex = parseInt(getEmbeddedChild(allLines,_children,_returnName, viewOptions[i][_returnName]).rank);
                   let previousVectorHM = getEmbeddedChildren(allLines, _children, "rank", currentIndex,"<");
                    let maxOrder = 0;
                    let previousVector = undefined;
                    for (let j in previousVectorHM) {
                        if (parseInt(previousVectorHM[j].rank) > maxOrder && views.filter(elt=>elt[_returnName]=== previousVectorHM[j][_returnName]).length > 0) {
                            maxOrder = parseInt(previousVectorHM[j].rank);
                            previousVector = previousVectorHM[j];
                        }
                    }
                    if (!previousVector) {
                        colOrder = views[getHighestKey(views, _columnOrder,undefined,[lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value])][_columnOrder] + 100;
                    }else{
                        let previousVectorMachineName = previousVector[_returnName];
                        let previousVectorViews = views.filter(e=>e[_returnName] === previousVectorMachineName && e[_type] === lang.manage_columns.column_types.psl.value);
                        if (previousVectorViews.length > 0) {
                            colOrder = previousVectorViews[0][_columnOrder] ? previousVectorViews[0][_columnOrder]  + 10 : views[getHighestKey(views, _columnOrder,undefined,[lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value])][_columnOrder] + 100;
                        }else{
                            colOrder = views[getHighestKey(views, _columnOrder,undefined,[lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value])][_columnOrder] + 100;
                        }
                    }
                }else{
                    if (_this.state.selectedProfile[_isModified]) {
                        colOrder = views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder];
                    }else {
                        colOrder = views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName]).length > 0 && views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder] !== 0 ? 
                        views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder] : 
                        originalColumns.filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId])[0][_profileColumns]
                        .filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder];
                    }
                }
                if (views.filter(elt=>elt[_columnOrder] === colOrder && elt[_returnName] !== viewOptions[i][_returnName]).length > 0) {
                    colOrder = colOrder -1;
                }
                viewOptions[i][PROFILE_COLUMN.COLUMN_ORDER] = colOrder;
            }   
            if (pslLines[e][_children]) {
                views = this.updatePSSOrder(views, pslLines[e][_children],allLines);
            }
        }
       return views;
    }

    updateCustomOrder=(data, views, type)=>{
        let _this = this;
        let originalColumns = copyObjectValues(_this.state.originalColumnProfiles);
        for (var e in data) {
            let viewOptions = views.filter(elt=>type.includes(elt[_type]) && (elt[_returnName] === data[e].value || elt[_returnName] === data[e][_returnName]))
            for (let i in viewOptions) {
                let colOrder = viewOptions[i][_columnOrder] ? viewOptions[i][_columnOrder] : 1; 
                if ((!_this.state.selectedProfile[_isModified] && originalColumns.filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId])[0][_profileColumns]
                .filter(elt=>elt[_returnName] === viewOptions[i][_returnName]).length === 0)
                || (_this.state.selectedProfile[_isModified] && views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName] && elt[_columnOrder] !== 0 && elt[_columnOrder] !== undefined).length === 0)) { // if the column is newly selected
            let currentIndex = data.findIndex(e=>(e.value === viewOptions[i][_returnName] || e[_returnName] === viewOptions[i][_returnName]));
                   let previousVectorHM = data.filter(function(value,index,arr){ return index < currentIndex })
                    let maxOrder = 0;
                    for (let j in previousVectorHM) {
                        if (Number(j) > maxOrder &&  views.filter(elt=>(elt[_returnName]=== previousVectorHM[j].value || elt[_returnName]=== previousVectorHM[j][_returnName])).length > 0) {
                            maxOrder = Number(j);
                        }
                    }
                    let orderMax = maxOrder;
                    let previousVector = previousVectorHM.filter(function(value,index,arr){ return index === orderMax })[0]; 
                    if (!previousVector) {
                        colOrder = views[getHighestKey(views, _columnOrder,undefined,viewOptions[i][_type] === lang.manage_columns.column_types.custom.value ?  
                            [lang.manage_columns.column_types.line_attribute.value,lang.manage_columns.column_types.psl.value,lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value]
                            : [lang.manage_columns.column_types.psl.value,lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value])][_columnOrder] + (viewOptions[i][_type] === lang.manage_columns.column_types.custom.value ? 1000 : 100);
                    }else {
                        let previousVectorMachineName =  previousVector[_returnName] || previousVector.value;
                        let previousVectorViews = views.filter(e=>e[_returnName] === previousVectorMachineName && type.includes(e[_type]));
                         if (previousVectorViews.length > 0) {
                            colOrder = previousVectorViews[0][_columnOrder] ? previousVectorViews[0][_columnOrder]  + 10 :  views[getHighestKey(views, _columnOrder,undefined,viewOptions[i][_type] === lang.manage_columns.column_types.custom.value ? [lang.manage_columns.column_types.line_attribute.value,lang.manage_columns.column_types.psl.value,lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value] : [lang.manage_columns.column_types.psl.value,lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value])][_columnOrder] + (viewOptions[i][_type] === lang.manage_columns.column_types.custom.value ? 1000 : 100);
                        }else{
                            colOrder = views[getHighestKey(views, _columnOrder,undefined,viewOptions[i][_type] === lang.manage_columns.column_types.custom.value ?
                                 [lang.manage_columns.column_types.line_attribute.value,lang.manage_columns.column_types.psl.value,lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value] :
                                  [lang.manage_columns.column_types.psl.value,lang.manage_columns.column_types.vector.value,lang.manage_columns.column_types.selected_vector.value])][_columnOrder] +  (viewOptions[i][_type] === lang.manage_columns.column_types.custom.value ? 1000 : 100);
                        }
                    }
                }else{
                    if (_this.state.selectedProfile[_isModified]) {
                        colOrder = views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder];
                    }else {
                        colOrder = views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName]).length > 0 && views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder] !== 0 ? 
                            views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder] : 
                            originalColumns.filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId])[0][_profileColumns]
                            .filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder];
                    }
                }
                if (views.filter(elt=>elt[_columnOrder] === colOrder && elt[_returnName] !== viewOptions[i][_returnName]).length > 0) {
                    colOrder = colOrder -1;
                }
                viewOptions[i][PROFILE_COLUMN.COLUMN_ORDER] = colOrder;

            }   
        }
        return views;
    }

    /**
     * function updates order of vectors similary to manage columns if the columns are newly selected and calls other functions to update line attributes, custom fields and profit stackLines
     * @param {*} views 
     */
    updateOrder=(views)=>{
        let _this = this;
        let vectors = this.state.vectorsList;
        let pslLines = this.state.profitStackLineColumns[0][_children];
        let lineAttributeOptions = this.state.lineAttributeOptions;
        let customGroups = copyObjectValues(this.state.profileColumns).filter(e=>e[_type] ===  lang.manage_columns.column_types.custom.value);
        let customLines = [];
        let originalColumns = copyObjectValues(_this.state.originalColumnProfiles);
        for (let elt in customGroups) {
            customLines = customLines.concat(customGroups[elt][_children]);
        }
        let selectedVector = this.props.vector;
        for (var e in vectors) {
            let viewOptions = views.filter(elt=>elt[_type]=== lang.manage_columns.column_types.vector.value && elt[_returnName] === vectors[e].value)
            for (let i in viewOptions) {
                let colOrder = viewOptions[i][_columnOrder] ? viewOptions[i][_columnOrder] : 1; // selected_fields
                if ((!_this.state.selectedProfile[_isModified] && originalColumns.filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId])[0][_profileColumns]
                    .filter(elt=>elt[_returnName] === viewOptions[i][_returnName]).length === 0)
                    || (_this.state.selectedProfile[_isModified] && views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName] && elt[_columnOrder] !== 0 && elt[_columnOrder] !== undefined).length === 0)) { // if the column is newly selected
                let currentIndex = vectors.filter(elt=>elt.value === viewOptions[i][_returnName])[0].display_order; //get its displayorder
                   let previousVectorHM = vectors.filter(elt=>elt.display_order < currentIndex); // get all columns with displayORder less than the selected column
                    let maxOrder = 0;
                    for (let j in previousVectorHM) { // loops over all previous column with display order less than the selected column and gets the nearest column
                        if (previousVectorHM[j].display_order > maxOrder && (previousVectorHM[j].label === this.props.vector 
                                || views.filter(elt=>elt[_returnName]=== previousVectorHM[j].value).length > 0)) {
                            maxOrder = previousVectorHM[j].display_order;
                        }
                    }
                    let orderMax = maxOrder;
                    let previousVector = previousVectorHM.filter(elt=>elt.display_order == orderMax && !elt.isGroupTitle)[0];
                    if (!previousVector) {
                        colOrder = views[getHighestKey(views, _columnOrder,undefined, [lang.manage_columns.column_types.selected_vector.value])][_columnOrder] + 100;
                    }else{
                        let previousVectorMachineName = previousVector.label === selectedVector ? _selectedVector : previousVector.value;
                        let previousVectorViews = views.filter(e=>e[_returnName] === previousVectorMachineName && (e[_type] === _selectedVector|| e[_type] ===  lang.manage_columns.column_types.vector.value));
                        if (previousVectorViews.length > 0) { // if the nearest column exist in the views we take its order and add 1 
                            colOrder = previousVectorViews[0][_columnOrder] ? previousVectorViews[0][_columnOrder]  + 10 : views[getHighestKey(views, _columnOrder,undefined, [lang.manage_columns.column_types.selected_vector.value,lang.manage_columns.column_types.vector.value])][_columnOrder] + 100;
                        }else{ // if not we take the highest index of columns of types vectors and add 1 to it
                            colOrder = views[getHighestKey(views, _columnOrder,undefined, [lang.manage_columns.column_types.selected_vector.value,lang.manage_columns.column_types.vector.value])][_columnOrder] + 100;
                        }
                    }
                    // if (views.filter(elt=>elt[_columnOrder] === colOrder && elt[_returnName] !== viewOptions[i][_returnName]).length > 0) {
                    //     colOrder = colOrder -1;
                    // }
                }else{
                    if (_this.state.selectedProfile[_isModified]) {
                        colOrder = views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder];
                    }else {
                        colOrder = views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName]).length > 0 && views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder] !== 0 ? 
                        views.filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder] : 
                        originalColumns.filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId])[0][_profileColumns]
                            .filter(elt=>elt[_returnName] === viewOptions[i][_returnName])[0][_columnOrder]; 
                    }
                }
                if (views.filter(elt=>elt[_columnOrder] === colOrder && elt[_returnName] !== viewOptions[i][_returnName]).length > 0) {
                    colOrder = colOrder -1;
                }
                viewOptions[i][PROFILE_COLUMN.COLUMN_ORDER] = colOrder;

            }   
        }
        views = this.updatePSSOrder(views,pslLines,pslLines);
        let arr = lineAttributeOptions.concat(customLines);
        views = this.updateCustomOrder(arr, views, [lang.manage_columns.column_types.line_attribute.value, lang.manage_columns.column_types.custom.value]);
        return views;
    }

    createNewCheckedElement = (profileColumnData, group, viewMachineName, viewOrder, viewOptionId, highestOrder) => {
        return {
            [PROFILE_COLUMN.VIEW_OPTION_ID]: viewOptionId,
            [PROFILE_COLUMN.MACHINE_NAME]: viewMachineName,
            groupName: group,
            [_returnName]: profileColumnData[_returnName],
            [_type]: profileColumnData[_type],
            [_visibility]: profileColumnData[_visibility],
            [_columnName]: profileColumnData[_columnName],
            [_columnGroup]: profileColumnData[_columnGroup],
            [_columnGroupLine]: profileColumnData[_columnGroupLine],
            [_columnType]: profileColumnData[_columnType],
            [_columnTypeLine]: profileColumnData[_columnTypeLine],
            [_columnOrder]: highestOrder,
            [PROFILE_COLUMN.VIEW_ORDER]: viewOrder,
        };
    }

    /**
     * If we toggle on segment and tier was already on, we toggle segment off and vice-versa.
     * If we toggle on segment/tier and name/number are both off, we add entity count annd count per selection to checkedItems.
     * If we toggle on name/number and entity count was on, we toggle entoty count and remove it from checkedItems with count per selection.
     * @param {*} viewMachineName 
     * @param {*} columnData 
     * @param {*} checkedItems 
     * @param {*} group 
     * @param {*} highestOrder 
     * @returns 
     */
    switchToggles = (viewMachineName, columnData, checkedItems, group, highestOrder) => {//unit test
        let isVectorColumn = [MANAGE_COLUMNS.VECTOR_MACHINE_NAME, MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME].includes(columnData.type);
        if(isVectorColumn) {
            let isCurrSelectedSegmentOption = [PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT, PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT_TIER].includes(viewMachineName); // check if the toggle currently checked is tier/segment
            let isCurrSelectedNameNumb = [PROFILE_COLUMN.VIEW_OPTIONS.NAME, PROFILE_COLUMN.VIEW_OPTIONS.NUMBER].includes(viewMachineName); // check if the toggle currently checked is name/number
            let isCurrSelectedEntityCount = [PROFILE_COLUMN.VIEW_OPTIONS.ENTITY_COUNT].includes(viewMachineName); // check if the toggle currently checked is entity count
            let isEntityCountChecked = checkedItems.filter(f => columnData.column_return_name === f.column_return_name && f.machine_name === PSL_RETURN_NAMES.ENTITY_COUNT)?.length > 0;
            let isNameNumbChecked = checkedItems.filter(f => columnData.column_return_name === f.column_return_name && [PROFILE_COLUMN.VIEW_OPTIONS.NAME, PROFILE_COLUMN.VIEW_OPTIONS.NUMBER].includes(f.machine_name))?.length > 0;
            let isVectorInvoiceLine = columnData.column_return_name === INVOICE_LINE.value;

            let countPerSelectiontObj = this.state.viewOptions.find(f => f.machine_name === PSL_RETURN_NAMES.COUNT_PER_SELECTION);
            if(isCurrSelectedSegmentOption) {
                let selectedSegmentToggle = checkedItems.filter(f => columnData.column_return_name === f.column_return_name && [PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT, PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT_TIER].includes(f.machine_name));
                if(selectedSegmentToggle.length > 0 && selectedSegmentToggle[0].machine_name !== viewMachineName) {
                    checkedItems = checkedItems.filter(f => columnData.column_return_name !== f.column_return_name || (columnData.column_return_name === f.column_return_name && f.machine_name !== selectedSegmentToggle[0].machine_name));
                } 
                if (!isEntityCountChecked && !isNameNumbChecked) {
                    let entityCountObj = this.state.viewOptions.find(f => f.machine_name === PSL_RETURN_NAMES.ENTITY_COUNT);
                    let newEntityCountElement = this.createNewCheckedElement(columnData, group, entityCountObj?.machine_name, entityCountObj?.view_order, entityCountObj?.column_view_option_id, highestOrder);
                    checkedItems.push(newEntityCountElement);
                    let newCountSelectionElement = this.createNewCheckedElement(columnData, group, countPerSelectiontObj?.machine_name, countPerSelectiontObj?.view_order, countPerSelectiontObj?.column_view_option_id, highestOrder);
                    checkedItems.push(newCountSelectionElement);
                }
                return checkedItems;
            } else if (isCurrSelectedEntityCount && !isVectorInvoiceLine) { // if we toggle entity count manually, we add count per selection to checkedItems. If the vector is invoice line, we should not add count per selection
                let newCountSelectionElement = this.createNewCheckedElement(columnData, group, countPerSelectiontObj?.machine_name, countPerSelectiontObj?.view_order, countPerSelectiontObj?.column_view_option_id, highestOrder);
                checkedItems.push(newCountSelectionElement);
            }

            // Remove entity count and count per selection if the selected toggle is name/number
            if (isCurrSelectedNameNumb) {
                if(isEntityCountChecked) {
                    return checkedItems.filter(f => columnData.column_return_name !== f.column_return_name || (columnData.column_return_name === f.column_return_name && ![PSL_RETURN_NAMES.ENTITY_COUNT, PSL_RETURN_NAMES.COUNT_PER_SELECTION].includes(f.machine_name)));
                }
            }
        }
        return checkedItems;
    }


    /**
     * This function is called in updateCheckedItems when we toggle name/number off. 
     * Ex: It checks if the toggle is name, if it is, then it checks if the number is toggled on. If it is on then nothing happens to checkedItems in this function.
     * If name is already off and we toggle number off, and we have tier/segment on, then we add entity count and count per selection to the checkedItems.
     * @param {*} viewMachineName 
     * @param {*} columnData 
     * @param {*} checkedItems 
     * @param {*} group 
     * @param {*} highestOrder 
     * @returns checkedItems
     */
    addColumnsWhenTogglingNameNumbOff = (viewMachineName, columnData, checkedItems, group, highestOrder) => { // unit test
        let isVectorColumn = [MANAGE_COLUMNS.VECTOR_MACHINE_NAME, MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME].includes(columnData.type);
        if(isVectorColumn) {
            let isCurrUnselectedNameNumb = [PROFILE_COLUMN.VIEW_OPTIONS.NAME, PROFILE_COLUMN.VIEW_OPTIONS.NUMBER].includes(viewMachineName); //check if the toggle currently checked is name/number
            let isCurrUnselectedEntityCount = [PROFILE_COLUMN.VIEW_OPTIONS.ENTITY_COUNT].includes(viewMachineName); // check if the toggle currently checked is entity count
            let isNameNumbChecked = checkedItems.filter(f => columnData.column_return_name === f.column_return_name && [PROFILE_COLUMN.VIEW_OPTIONS.NAME, PROFILE_COLUMN.VIEW_OPTIONS.NUMBER].includes(f.machine_name))?.length > 0;
            let iSegmentChecked = checkedItems.filter(f => columnData.column_return_name === f.column_return_name && [PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT, PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT_TIER].includes(f.machine_name))?.length > 0;
    
            // Remove entity count and count per selection if the selected toggle is name/number
            if (isCurrUnselectedNameNumb && !isNameNumbChecked && iSegmentChecked) {
                let entityCountObj = this.state.viewOptions.find(f => f.machine_name === PSL_RETURN_NAMES.ENTITY_COUNT);
                let countPerSelectiontObj = this.state.viewOptions.find(f => f.machine_name === PSL_RETURN_NAMES.COUNT_PER_SELECTION);
                let newEntityCountElement = this.createNewCheckedElement(columnData, group, entityCountObj?.machine_name, entityCountObj?.view_order, entityCountObj?.column_view_option_id, highestOrder);
                checkedItems.push(newEntityCountElement);
                let newCountSelectionElement = this.createNewCheckedElement(columnData, group, countPerSelectiontObj?.machine_name, countPerSelectiontObj?.view_order, countPerSelectiontObj?.column_view_option_id, highestOrder);
                checkedItems.push(newCountSelectionElement);
                
            } else if(isCurrUnselectedEntityCount) { // if we toggle entity count off manually, we should remove count per selection from checked items.
                checkedItems = checkedItems.filter(f => columnData.column_return_name !== f.column_return_name || (columnData.column_return_name === f.column_return_name && ![PSL_RETURN_NAMES.COUNT_PER_SELECTION].includes(f.machine_name)));
            }
        }
        return checkedItems;
    }

    // TODO @georges delete commented code
    // updateCheckedItems(viewOptionId, returnName, checked, type, machineName, visibility="", 
    //     name="", group="", column_group="", column_group_line="", column_type="", column_type_line="") {
    updateCheckedItems(viewOptionId, viewMachineName, group, checked, profileColumnData, viewOrder) {
        var elements = this.state.checkedItems;
        var _this = this;
        let typeElements = copyObjectValues(this.state.checkedItems).filter(e=>e[_type] === profileColumnData[_type]);
        let existingElement = elements.filter(elt=>elt[_returnName] === profileColumnData[_returnName])[0];
        let highestOrder = 0;
        if (!!existingElement) {
            highestOrder = existingElement[_columnOrder];
        }
        if (checked) {
            elements = this.switchToggles(viewMachineName, profileColumnData, elements, group, highestOrder);
            let newElement = this.createNewCheckedElement(profileColumnData, group, viewMachineName, viewOrder, viewOptionId, highestOrder);

            elements.push(newElement);
            elements = this.updateOrder(elements);
        } else {
            var newElements = [];
            for (var item in elements) {
                if(!(elements[item][PROFILE_COLUMN.VIEW_OPTION_ID] === viewOptionId && elements[item][_returnName] === profileColumnData[_returnName])){
                    newElements.push(elements[item])
                }
            }
            newElements = this.addColumnsWhenTogglingNameNumbOff(viewMachineName, profileColumnData, newElements, group, highestOrder)
            elements = newElements;
        }
        if (document.getElementById(viewOptionId+"_"+profileColumnData[_returnName]+"_default") !== null) {
            document.getElementById(viewOptionId+"_"+profileColumnData[_returnName]+"_default").checked = checked;
        }
        if (document.getElementById(viewOptionId+"_"+profileColumnData[_returnName]) !== null) {
            document.getElementById(viewOptionId+"_"+profileColumnData[_returnName]).checked = checked
        }
        let e = copyObjectValues(elements);
        
        this.state.selectedProfile[_simplifiedColumns] = elements;
        this.setState({
            checkedItems: elements,
            originalCheckedItems:e,
            disableToggles: _this.props.manageColumnsSelectionLimit && this.getAllToggledOnOptionsCount(elements) >= Number(_this.props.manageColumnsSelectionLimit)
        },function(){
            this.toggleColumn(profileColumnData[_returnName], _isChecked, viewMachineName);
        })
    }

    renderData(data, displayAsParent=true, level=0, type, lineType, groupName) {
        let renderedArr = [];
        var obj = this;
        var checkedItems =  this.state.checkedItems;
        for(let index in data) {
            let column = data[index];
            let isGroup = column[_isGroup];
            let columnType = column[_type] ? column[_type] : type; 
            let columnLineType = column[_lineType] ? column[_lineType] : lineType; 
            if (groupName) {
                var groupVect = obj.props.vectorOptions?.find(e=>e.value === obj.props.vector && !e.isGroupTitle)?.vector_group;
                var allowed = getVectorAllowedAttributes(column[_returnName].replace(_selectedVector,obj.props.vector), groupName.replace(_vectors, groupVect), obj.props.user);
                var viewOptions = obj.state.viewOptions.filter(e=> e.type === (!columnLineType ? "" : columnLineType.replace(MANAGE_COLUMNS.CALCULATED_PSL_LINE,MANAGE_COLUMNS.PSL_LINE).replace(_profit,_opp).replace(_profit_perc,'')))
                if (!allowed.includes(IDENTIFIERS.NAME)) {
                    viewOptions = viewOptions.filter(e=>e.machine_name !== IDENTIFIERS.NAME);
                    checkedItems = checkedItems.filter(e=>e.machine_name !== IDENTIFIERS.NAME);
                }
                if (!allowed.includes(IDENTIFIERS.NUMBER)) {
                    viewOptions = viewOptions.filter(e=>e.machine_name !== IDENTIFIERS.NUMBER);
                    checkedItems = checkedItems.filter(e=>e.machine_name !== IDENTIFIERS.NUMBER);
                }
                if (ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS === obj.props.profitFormat) {
                    viewOptions = viewOptions.filter(e=>e.machine_name !== PROFILE_COLUMN.VIEW_OPTIONS.MEDIAN);
                    checkedItems = checkedItems.filter(e=>e.machine_name !== PROFILE_COLUMN.VIEW_OPTIONS.MEDIAN);
                }
                if(column[_returnName] === _invoiceLineType){
                    viewOptions = viewOptions.filter(e=>e.machine_name === PROFILE_COLUMN.VIEW_OPTIONS.ENTITY_COUNT);
                    checkedItems = checkedItems.filter(e=>e.machine_name === PROFILE_COLUMN.VIEW_OPTIONS.ENTITY_COUNT);
                }
                
            }
            column[_columnDescription] = column[_columnDescription];

            let isVectorGenerated = this.isVectorColumnGenerated(column);

            if(column[_isInSearchResult] || !displayAsParent) {
                //on child level: if it is in the search result, or its parent is expanded
                if(!(column.is_group && !column.column_children?.length)){
                    renderedArr.push(
                        <ProfileColumn key={"profile-column-"+column[_returnName]+"_"+displayAsParent+"_"+level+column[_createdBy]}
                            name={column[_columnName]} value={column[_returnName]} isCreatorSystem = {column[_isCreatorSystem]}
                            isChecked={column[_isChecked]} isCheckable={!isGroup} displayAsParent={displayAsParent}
                            isExpandable={column[_isExpandable]} isExpanded={column[_isExpanded]} toggleColumn={this.toggleColumn}
                            hasBlueDot={column[_hasBlueDot]} level={column[_level]} hasWarning={column[_hasWarning]} warningMessage={column[_warningMessage]}
                            searchValue={this.state.searchValue} isInSearchResult={column[_isInSearchResult]} viewOptions={viewOptions}
                            updateCheckedItems={this.updateCheckedItems} checkedItems={checkedItems} type={columnType} visibility={column[_visibility]}
                            creatorName={column[_creatorName]} createdBy={column[_createdBy]} groupName={groupName} description={column[_isExpandable]? "" : column[_columnDescription]} 
                            editColumn={this.editColumn} column_group={column[_columnGroup]} isColumnInList={this.isColumnInList} customGroupId={column[_customGroupId]}
                            formatId={column[_formatId]} column_group_line={column[_columnGroupLine]} column_type={column[_columnType]}
                            column_type_line={column[_columnTypeLine]} columnFieldId={column[_columnFieldId]} profitFormat = {this.props.profitFormat}
                            displayName={column[_displayName]} columnLineType={columnLineType} formatType={column[_formatType]} 
                            formula={column[_formula]} access={this.state.access} //modalRef={this.props.modalRef} getColumnsWithSameName={this.getColumnsWithSameName} 
                            onDeleteColumn={this.onDeleteColumn} unit={column[_unit]} returnName={column[_returnName]} disableToggles={this.state.disableToggles}
                            isVectorGenerated={isVectorGenerated}
                        />
                    );
                }
            }

            if(column[_children] && column[_isExpanded] && (column[_isInSearchResult] || !displayAsParent)) {
                //if the parent is expanded and is in the search result, render the children
                renderedArr = renderedArr.concat(this.renderData(column[_children], false, level+1, columnType, columnLineType, column[_columnName]));   //increase the level to indicate a level deeper in the tree
            }
            
            if(column[_children] && this.state.isSearching && (displayAsParent || level === 0)) {
                //even if the parent is not in the search results, we want the children to be rendered
                //if the parent AND the children are in the search results, and the parent is expanded, children render twice

                //adding a check on the level because if an element and its grand parent are in the search result:
                //if its grand parent gets expanded, it would appear three times
                //- once with the initial iteration on the children (is in the search result)
                //- once because its grand parent is in the search result and has been expanded (displayAsParent = false)
                //- when the gp got expanded, we also look for its subtree to display matching results,
                //but this time we shouldn't display them cz they were displayed in the initial iteration, hence check on level
                renderedArr = renderedArr.concat(this.renderData(column[_children], true, level, columnType, columnLineType, column[_columnName]));
            }
        }

        return renderedArr;
    }

    /**
     * Checks if the vector column in manage columns is generated or not.
     * It checks for selected vector and other vectors.
     * This flag is used to display the segmentation icon next to the vector in manage columns if the vector is generated.
     * @param {*} column 
     * @returns 
     */
    isVectorColumnGenerated = (column) => {
        // check if each vector in manage columns in generated or not
        if(MANAGE_COLUMNS.VECTOR_TYPE === column.type && !column.is_group) {
            let vector = this.props.vectorOptions?.filter(f => f.value === column.column_return_name);
            if (vector.length) {
                return vector[0].generated;
            }
        }
        // check if the selected vector is generated or not
        if(MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME === column.type) {
            let vectorValue = this.props.isDrilling ? $("#Next_Tier").text().trim() : this.props.vector;
            let selectedVector = this.props.vectorOptions?.filter(f => f.value === vectorValue);
            if (selectedVector.length) {
                return selectedVector[0].generated;
            }
        }

        return false;
    }

    cancel(callback) {
        let customGroupsOriginal = this.state.customGroupsOriginal;
        // this.onToggleBoard();
        if(typeof callback === "function"){
            callback();
        }
        if(this.state.elementAdded) {
            this.state.customGroupsOriginal.shift();
            this.state.elementAdded = false; 
        }
        // this.resetInputs(false);
        this.backdropClickHandler();
        
        //this.filterColumns(""); // this is to remove searched value and re display all columns when clicking on cancel
        this.setState({
            customGroupsOriginal: customGroupsOriginal,
            visibiliyChecked:  undefined ,
            visibilityCheckedlist: [],
            columnFieldId: undefined,
            customGroups: this.state.customGroups.filter(e=>!e.label.includes(MANAGE_COLUMNS.NEW_GROUP_LABEL))
            
        })
    }

    isNameUnique(name){
        var _this = this;
        var data = this.state.customGroupFields;
        let userCond = data.filter(e=>((e[PROFILE_COLUMN.DISPLAY_NAME].toLowerCase() === name.toLowerCase() && e[_visibility] === _visibilityOptions.USER) 
                && Number(e[COLUMN_PROFILE.CREATED_BY]) === Number(_this.props.user.userId) && (Number(e[_columnFieldId]) !== Number(this.state.columnFieldId)) || 
                (_this.state.access.view_company_cols && e[PROFILE_COLUMN.DISPLAY_NAME].toLowerCase() === name.toLowerCase() && e[_visibility] === _visibilityOptions.COMPANY)
                && (Number(e[_columnFieldId]) !== Number(this.state.columnFieldId))
                ))
        let cond = data.filter(e=>((e[PROFILE_COLUMN.DISPLAY_NAME].toLowerCase() === name.toLowerCase() && e[_visibility] === _visibilityOptions.COMPANY && _this.state.access.view_company_cols)
                    && (Number(e[_columnFieldId]) !== Number(this.state.columnFieldId)) ||
                    (e[PROFILE_COLUMN.DISPLAY_NAME].toLowerCase() === name.toLowerCase() && e[_visibility] === _visibilityOptions.USER) 
                    && Number(e[COLUMN_PROFILE.CREATED_BY]) === Number(_this.props.user.userId)
                    && (Number(e[_columnFieldId]) !== Number(this.state.columnFieldId))
                    ))// name already exists in company
        if (this.state.VisibilitySelected === _visibilityOptions.USER){
            if(userCond.length > 0){
                return false;
            }
        } else{
            if (cond.length > 0){
                return false
            }
        }
        return true;
    }

    validateFields() {
        var res = true;
        let isNameValid = "";
        let isTypeValid = "";
        let isCustomValid = "";
        let isFormatValid = "";

        if (this.state[MANAGE_COLUMNS.NAME_ID] === undefined || this.state[MANAGE_COLUMNS.NAME_ID] === "") {
            isNameValid = lang.manage_access.name_empty;
            res = false;
        }
        if (this.state.type === undefined|| this.state.type === "") {
            isTypeValid = lang.manage_columns.type_empty;
            res = false;
        }
        if (this.state.customGroup === undefined || !this.state.customGroup.value) {
            isCustomValid = lang.manage_columns.custom_empty;
            res = false;
        }
        if (!this.ManageColumnsFormat.validateFields()) {
            res = false;
        }
        if (!this.clientFormatRef.validateFields(this.state.formatType) && !this.state.formatType) {
            this.state.isFormatValid = lang.manage_columns.unit_empty;
            res = false;
        }
        if (this.state.nameWasEdited && !this.isNameUnique(this.state[MANAGE_COLUMNS.NAME_ID])) {
            isNameValid = lang.manage_access.name_already_used;
            res = false;
        }
        if (checkStartsWithDigit(this.state[MANAGE_COLUMNS.NAME_ID])) {
            isNameValid = lang.name_starting_number;
            res = false;
        }
        if(this.ManageColumnsFormat.calculatedPslRef && this.ManageColumnsFormat.calculatedPslRef !== null && 
        (!this.ManageColumnsFormat.calculatedPslRef.formulaDRef.validateFormulaResult(this.ManageColumnsFormat.calculatedPslRef.state.pslFormula.formula) ||
         this.ManageColumnsFormat.calculatedPslRef.state.pslFormula.formula.length === 0)){
           res = false;
        }
        this.setState({
            isNameValid: isNameValid,
            isTypeValid: isTypeValid,
            isCustomValid: isCustomValid,
            isFormatValid: isFormatValid
        })

        return res;
    }

    cantEditColumnDialogContent = () => {
        let _this = this;
        let itemsInList = _this.getProfilesUsingColumn(
            _this.state.creatorName, _this.state.VisibilitySelected, 
            _this.state.displayName, _this.state.value
        );

        if(!itemsInList) { return; }

        return (
            <div>
                <div key="a" className="pi-warning-background uk-border-rounded uk-padding-xsmall">
                    <div className="uk-display-flex uk-flex-middle">
                        <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                        <div className="fs-16">{lang.manage_columns.cant_edit_warning}</div>
                    </div>
                </div>
                <div className="uk-margin-medium-top">
                    <h5>{lang.manage_columns.cant_edit_text + _this.state[MANAGE_COLUMNS.NAME_ID] +": "}</h5>
                    {itemsInList?.map(item => <h5>{"-  " + item[_profileName]}</h5>)}
                </div>
            </div>
        )
    }

    cantEditColumnDialogActions = () => {
        let _this = this;

        return (
            <Button 
                label={lang.modal.buttons.ok}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => _this.setCantEditColumnDialogOpen(false)}
            />
        )
    }

    setCantEditColumnDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openCantEditColumnDialog: isOpen
        })
    }

 /**
  * This function increments the machineName if it exists. if we have machineName=test__cpcf already exists in data, we increment it to be test__1_cpcf. 
  * @param {*} originalVal the value without any suffix (ex: test_, test, test__1, ...)
  * @param {*} data the custom fields array where we search for existing machine names
  * @param {*} counter 
  * @returns 
  */
  incrementMachineName = (originalVal, data, counter=0) => {
    let newValue = originalVal + (counter > 0 ? "_" + counter : "");
    newValue = newValue.toLowerCase();
    let valWithSuffix = newValue + _cpcf;

    if(data.filter(f => f.machine_name.toLowerCase() === valWithSuffix).length > 0) {
      return this.incrementMachineName(originalVal, data, ++counter);
    }
    return newValue + _cpcf;
  }

    saveCreatedColumn(checked, callback) {
        const _this = this;
        let itemsInList = [];
        let customGroupFields = copyObjectValues(_this.state.customGroupFields);

        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId;
        _this.clearManageColumnsFromStore(scenarioId);
        if (_this.props.dispatch) {
            _this.props.dispatch(updateCustomGroups([]));
        }
        let originalProfileColumns = copyObjectValues(_this.state.profileColumns); // save original columns before saving

        let isEdit = this.state.isEditingColumn;
        if(this.state.visibilityCheckedlist && this.state.visibilityCheckedlist.length > 0){
            return;
        }
        if (this.state.isEditingColumn && this.state.visibilityField === _visibilityOptions.COMPANY && this.state.VisibilitySelected === _visibilityOptions.USER) {
            itemsInList = _this.getProfilesUsingColumn(
                _this.state.creatorName, _this.state.VisibilitySelected, 
                _this.state.displayName, _this.state.value
            );

            this.setState({
                visibility: this.state.VisibilitySelected,
                isEditingColumn: itemsInList.length > 0,
            },function(){
                if(itemsInList.length > 0){
                    _this.setCantEditColumnDialogOpen(true);
                }
            })
        }

        if(itemsInList.length > 0) {    //show modal and stop here
            return;
        }

        var first_option = "";
        var second_option = "";
        var formula = "";
        
        if(this.ManageColumnsFormat){
            if(this.ManageColumnsFormat.state.group === lang.manage_columns.group_labels[0]){
                first_option = this.ManageColumnsFormat.state.selectedProfitStackLine[PROFILE_COLUMN.RETURN_NAME];
            } else {
                first_option = this.ManageColumnsFormat.state.option;
            } 
            if( this.state.type === MANAGE_COLUMNS.VALUES[1] ){
                second_option = this.ManageColumnsFormat.state.vector;
            } else if (this.state.type ===  MANAGE_COLUMNS.VALUES[2]){
                second_option = this.ManageColumnsFormat.state.lineAttribute;
            } else {
                if(this.ManageColumnsFormat.calculatedPslRef){
                    formula = this.ManageColumnsFormat.calculatedPslRef.state.pslFormula;
                }
            }
        }

        let selectedF = this.clientFormatRef.getSelectedFormat();
        this.setState({
            column_id: this.state.columnFieldId,
            selectedFormat: !!selectedF ? selectedF.format_type_id : "",
            selectedUnit: this.clientFormatRef.getSelectedUnit(),
            group: this.ManageColumnsFormat.state.group,
            column_group_line: first_option,
            column_type_line: second_option,
            formula: formula.formula ? JSON.stringify({formula:formula.formula}) : JSON.stringify({formula:formula}),
            accesschanged: this.state.accesschanged    
        },function(){
            if (!_this.validateFields()) {
                _this.setState({save:false});
                return;
            }
            let embededChild = getEmbeddedChild(_this.state.profileColumns, PROFILE_COLUMN.CHILDREN,  PROFILE_COLUMN.COLUMN_FIELD_ID, _this.state.columnFieldId);
            let isEditFromCompanyToUser = embededChild && embededChild[_visibility] === 'company' && this.state.VisibilitySelected === 'user';
            let createdColumnMachineName = replaceSpecialChars(_this.state[MANAGE_COLUMNS.NAME_ID], "_");
            createdColumnMachineName = this.incrementMachineName(createdColumnMachineName, this.state.customGroupFields);
           
            let createdColumn = {
                [_createdBy]: Number(!isEditFromCompanyToUser && !!_this.state.columnBeingEdited ? _this.state.columnBeingEdited[_createdBy] : _this.props.user.userId),
                [_creatorName]: !isEditFromCompanyToUser && !!_this.state.columnBeingEdited ? _this.state.columnBeingEdited[_creatorName] : _this.props.user.first_name +" "+ _this.props.user.last_name,
                display_name: _this.state[MANAGE_COLUMNS.NAME_ID],
                name: _this.state[MANAGE_COLUMNS.NAME_ID],
                description: _this.state[MANAGE_COLUMNS.DESCRIPTION_ID] || "", 
                format_type_id: Number(_this.state.selectedFormat),
                [_unit]: _this.state.selectedUnit,
                customGroup: _this.state.customGroup,
                column_type: _this.state.type,
                machine_name: createdColumnMachineName,
                column_group: _this.state.group || "",
                column_group_line: _this.state.column_group_line || "",
                column_type_line: _this.state.column_type_line || "",
                visibility: _this.state.VisibilitySelected,
                formula: _this.state.formula,
                [_formatType]: selectedF.value,
                [_isCreatorSystem]: embededChild? embededChild[_isCreatorSystem] :"",
                accesschanged: this.state.accesschanged
            }
            
            let newColumn = {
                column_group: _this.state.group || "",
                column_group_line: _this.state.column_group_line,
                column_name: _this.state[MANAGE_COLUMNS.NAME_ID],
                column_return_name: createdColumnMachineName,
                column_type: _this.state.type,
                column_type_line: _this.state.column_type_line,
                display_name: _this.state[MANAGE_COLUMNS.NAME_ID],
                group: _this.state.customGroup.value,
                [_createdBy]: Number(!isEditFromCompanyToUser && !!_this.state.columnBeingEdited ? _this.state.columnBeingEdited[_createdBy] : _this.props.user.userId),
                [_creatorName]: !isEditFromCompanyToUser && !!_this.state.columnBeingEdited ? _this.state.columnBeingEdited[_creatorName] : _this.props.user.first_name +" "+ _this.props.user.last_name,
                visibility: _this.state.VisibilitySelected,
                formula: _this.state.formula,
                [_formatType]: selectedF.value,
                [_unit]: _this.state.selectedUnit,
                [PROFILE_COLUMN.LINE_TYPE]: _this.state.type,
                [_columnFieldId]: _this.state.columnFieldId,
                [_columnDescription]: _this.state[MANAGE_COLUMNS.DESCRIPTION_ID],
                [_isCreatorSystem]: embededChild? embededChild[_isCreatorSystem] :""
            };
            
            // remove column from old group on edit
            if(_this.state.columnFieldId) {
                for (let e in _this.state.profileColumns) {
                    for (let k in _this.state.profileColumns[e][_children]) {
                        if(_this.state.profileColumns[e][_children][k][_columnFieldId] === _this.state.columnFieldId){
                            _this.state.profileColumns[e][_children].splice(k, 1);
                            break;
                        }
                    }
                }    
            }

            if (this.state.profileColumns.filter(e=>e[_columnName] === this.state.customGroup.value).length == 0) {
                let createdCustomGroups = {
                    column_name: this.state.customGroup.value,
                    column_return_name: this.state.customGroup.value,
                    column_profile_custom_group_id: this.state.customGroup.id,
                    is_group: true,
                    line_type: this.state.column_type_line,
                    type: _custom,
                    [PROFILE_COLUMN.IS_EXPANDABLE]: true,
                    [_children]:[newColumn],
                    
                }
                this.state.profileColumns.push(createdCustomGroups);
            } else {
                for (let e in this.state.profileColumns) {
                    if(this.state.profileColumns[e][_columnName] === this.state.customGroup.value){
                        this.state.profileColumns[e][_children].push(newColumn)
                    }
                }
            }

            if(isEdit) {
              // remove the edited column from customGroupFields when we are editing it because it will be re-added in onThenCallback
              customGroupFields = customGroupFields.filter(f => f.machine_name !== _this.state.columnBeingEdited.column_return_name);
            }
            
            _this.backdropClickHandler();

            this.state.profileColumns = _this.updateDataFlags(this.state.profileColumns);
            let columnViews = this.state.columnProfiles.find(f => f.column_profile_id === this.state.selectedProfile.column_profile_id).simplified_columns;
            let customGroupValue = _this.state.customGroup.value
            _this.resetInputs();
            let query = {
                action: "saveCreatedColumn",
                createdColumn: createdColumn,
                column_id: this.state.columnFieldId || "",
                scenario_id: this.props.scenarioId,
                columnsViews: JSON.stringify(columnViews),
                vector: this.props.vector,
                dataset: this.props.dataset
            }
            let onThenCallback = (data) => {
                if(data.success) {
                    if(_this.state.customGroups.find(e=>e.label.includes("(New Group)") && e.value === createdColumn.customGroup.value)?.label){
                        _this.state.customGroups.find(e=>e.label.includes("(New Group)") && e.value === createdColumn.customGroup.value).label = _this.state.customGroups.find(e=>e.label.includes("(New Group)") && e.value === createdColumn.customGroup.value).label.replace("(New Group)","")
                    }
                    let index = -1;
                    
                    for (let e in _this.state.profileColumns) {
                        if(_this.state.profileColumns[e][_columnName] === customGroupValue){
                            if(_this.state.columnFieldId) {
                                for (let k in _this.state.profileColumns[e][_children]) {
                                    if(_this.state.profileColumns[e][_children][k][_columnFieldId] === _this.state.columnFieldId){
                                        index = k;
                                        break;
                                    }
                                }
                            } else {
                                index = _this.state.profileColumns[e][_children].length - 1;
                            }
                            //adding the field id returned after save
                            if(index > -1) {
                                _this.state.profileColumns[e][_children][index][_columnFieldId] = data.newFieldId;
                                break;
                            }
                        }   
                    }

                    for(let e in _this.state.profileColumns) {
                        if (_this.state.profileColumns[e][_type] === "custom" && _this.state.profileColumns[e][_children]) {
                            let children = _this.state.profileColumns[e][_children];
                            let newSortedChildren = [];
                            children.sort((function(a, b){
                                if(a.display_name.toLowerCase() < b.display_name.toLowerCase()) { return -1; }
                                if(a.display_name.toLowerCase() > b.display_name.toLowerCase()) { return 1; }
                                return 0;
                            })).forEach(d=> {
                                newSortedChildren.push(d);
                            });
                            this.state.profileColumns[e][_children] = newSortedChildren
                        }
                    }
                    // _this.onToggleBoard();
                    if(typeof callback === "function"){
                        callback();
                    }
                    if(typeof _this.props.fetchColumnsOnSave === "function"){
                        _this.props.fetchColumnsOnSave()
                    }
                    customGroupFields.push(createdColumn);
                    _this.state.message = isEdit ? lang.manage_columns.text.edited_successfully : lang.manage_columns.text.column_saved_successfully;
                    // _this.message = isEdit ? lang.manage_columns.text.edited_successfully : lang.manage_columns.text.column_saved_successfully;
                    _this.state.isError = false;
                    if(this.state.elementAdded) {
                        this.state.elementAdded = false;
                    }
                } else if(data.invalidFormula) {
                   _this.setState({
                        invalidFormula: true,
                        profileColumns: originalProfileColumns
                   });
                   return;
                } else {
                    _this.resetInputs();
                    _this.state.message = lang.execution_error_two;
                    _this.state.isError = true;
                }

                let tempState = _this.state;
                tempState.customGroupFields = customGroupFields;
                tempState.accesschanged = false;
                this.setState(tempState,function(){
                    _this.launchToast();
                    // _this.resetInputs();

                })
            }
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "saveCreatedColumn",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
            }
            fetchAPI(fetchOptions);
        })
    }

    setVisibility(visibility) {
        let accesschanged = false;
        if (this.state.VisibilitySelected.toLowerCase() === _visibilityOptions.COMPANY && visibility.toLowerCase() === _visibilityOptions.USER ) {
            accesschanged = true;
        }else {
            accesschanged =  false;
        }
        this.setState({VisibilitySelected:visibility.toLowerCase()
        },function(){
            let isNameValid = "";
            if(this.state[MANAGE_COLUMNS.NAME_ID] && !this.isNameUnique(this.state[MANAGE_COLUMNS.NAME_ID])){
                isNameValid = lang.manage_access.name_already_used
            }
            let visibiliyChecked = this.checkVisibility(visibility,true);
            let visibilityCheckedMessage = lang.dashboards.messages.visibility_check_two;
            this.setState({
                isNameValid : isNameValid,
                visibiliyChecked: visibiliyChecked === true ? undefined : visibilityCheckedMessage,
                visibilityCheckedlist: visibiliyChecked === true ? [] : visibiliyChecked,
                accesschanged: visibiliyChecked === true ? accesschanged : false
            });
           
                
        })
    }

    editColumn(customGroup, columnData){
        //when filling this object in ProfileColumn, we used those constants as keys. Using this notation, we can extract those values from the object "columnData".
        //what's before the ":" is the key whose value is being extracted, what's after it is the variable in which the value is being saved
        const {
            [_columnFieldId]: columnFieldId,
            [_columnName]: columnName,
            [_columnDescription]: description,
            [_formatType]: formatType,
            [_visibility]: visibility,
            [_returnName]: value,
            [_columnType]: column_type,
            [_columnTypeLine]: column_type_line,
            [_columnGroupLine]: column_group_line,
            [_columnGroup]: column_group,
            [_formatId]: formatId,
            [_formula]: formula
        } = columnData;
        
        let groupId = this.state.customGroups.filter(e => e.value === customGroup)[0][_customGroupId];
        if(!!formula && formula.length){
            this.ManageColumnsFormat?.calculatedPslRef?.setState({
                pslFormula: tryParse(formula),
                formula_unchanged: tryParse(formula),
            });
        }

        this.ManageColumnsFormat.handleChange({value: !!MANAGE_COLUMNS.GROUPS[column_group] ? MANAGE_COLUMNS.GROUPS[column_group] : column_group})
        this.ManageColumnsFormat.handleChangeOptions({value:column_group_line, label:column_group_line}, !!MANAGE_COLUMNS.GROUPS[column_group] ? MANAGE_COLUMNS.GROUPS[column_group] : column_group);
        if (column_type === MANAGE_COLUMNS.PER_VECTOR) {
            this.ManageColumnsFormat.vectorHandleChange({value:column_type_line, label:column_type_line});
        }else {
            this.ManageColumnsFormat.lineAttributeHandleChange({value:column_type_line, label:column_type_line});
        }

        this.setState({
            isEditingColumn: true,
            columnBeingEdited: columnData,
            columnFieldId: columnFieldId,
            formatType: formatType,
            visibilityField: visibility,
            [MANAGE_COLUMNS.NAME_ID]: columnName,
            [MANAGE_COLUMNS.DESCRIPTION_ID]: description,
            group: customGroup,
            customGroup: {value:customGroup, label:customGroup, column_profile_custom_group_id:groupId},
            value: value,
            selectedFormat: formatId,
            VisibilitySelected: visibility,
            formula: formula ? (formula.formula? tryParse(formula.formula): tryParse(formula)):"",
            type: column_type
        });

        // this.onToggleBoard(false, true);
        this.drawerToggleClickHandler();
    }

    setDeleteColumnDialogOpen = (isOpen, selectedColumnData, customGroup) => {
        let _this = this;
        _this.setState({
            openDeleteColumnDialog: isOpen,
            selectedColumnData: selectedColumnData,
            selectedCustomGroup: customGroup
        })
    }

    deleteColumnDialogContent = () => {
        let _this = this;
        let columnData = _this.state.selectedColumnData;
        if(!columnData) { return; }

        const {
            [_creatorName]: creatorName,
            [_visibility]: visibility,
            [COLUMN_PROFILE.DISPLAY_NAME]: displayName,
            [_returnName]: colReturnName
        } = columnData;

        let colsWithSameName = this.getProfilesUsingColumn(creatorName, visibility, displayName, colReturnName);
        let usedProfiles = [];
        var prfs = [];
        colsWithSameName.forEach(prf => {
            let title = prf[_visibility] === _visibilityOptions.COMPANY ? "By Company" : "You";
            let icon = prf[_visibility] === _visibilityOptions.COMPANY ? "fa-users" : "fa-user";
            if (!prfs.includes(prf[_profileName] +" - "+ prf[_creatorName])) {
                usedProfiles.push(<React.Fragment><span>{usedProfiles.length > 0 ? ", " : ""}</span><i title={title} className={"fa-lg fal "+icon+" uk-margin-small-right"}></i><span>{prf[_profileName] + " by " + prf[_creatorName]}</span></React.Fragment>);
                prfs.push(prf[_profileName] +" - "+ prf[_creatorName]);
            }
        });

        return (
            <>
                <div key="a" className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall">
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    <div className="fs-16">{lang.manage_columns.text.column_in_saved_list}</div>
                </div>
                <div key="b" className="uk-flex-center uk-padding-large fs-14">
                    {usedProfiles?.length > 0 && 
                        <div className="uk-margin-default-bottom">
                            <span>{lang.manage_columns.text.column_saved_in}</span>
                            {usedProfiles}
                        </div>
                    }
                    {usedProfiles?.length > 0 && 
                        <div className="uk-margin-default-bottom">
                            <span>{lang.manage_columns.text.column_removed}</span>
                        </div>
                    }
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.name}</span>
                        <span>{displayName}</span>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.created_by}</span>
                        <span>{creatorName}</span>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.visibility}</span>
                        <span>
                            {visibility === _visibilityOptions.COMPANY ? 
                                <i title="By Company" className="fa-lg fal fa-users uk-margin-small-right"/>
                                :   
                                visibility === _visibilityOptions.USER ?
                                    <i title="You" className="fa-lg fal fa-user uk-margin-small-right" /> 
                                :   ""
                            }
                        </span>
                    </div>
                </div>
            </>
        )
    }
    deleteColumnDialogActions = () => {
        let _this = this;
        let columnData = _this.state.selectedColumnData;
        let customGroup = _this.state.selectedCustomGroup;

        return (
            <>
                <Button 
                    label={lang.modal.buttons.delete}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => {
                        _this.deleteField(columnData[_columnFieldId], customGroup);
                        _this.setDeleteColumnDialogOpen(false, undefined, undefined);
                    }}
                />
                <Button 
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => _this.setDeleteColumnDialogOpen(false, undefined, undefined)}
                />
            </>
        )
    }

    onDeleteColumn(customGroup, columnData) {
        const _this = this;
        _this.setDeleteColumnDialogOpen(true, columnData, customGroup);
    }

    isColumnInList(creatorName, visibility, returnName) {
        return this.getProfilesUsingColumn(creatorName, visibility, "", returnName).length > 0;
    }

    getProfilesUsingColumn(creatorName, visibility, displayName, returnName, columnFieldId, customGroup, fromEdit=false) {
        let list = [];
        for(let i in this.state.columnProfiles) {
            let profile = this.state.columnProfiles[i];
            let columns = profile[MANAGE_COLUMNS.COLUMNS];
            columns?.map(column => {
                if(column[MANAGE_COLUMNS.COLUMN_RETURN_NAME] === returnName) {
                    let tempObj = {[_profileName]: profile[_profileName], [_visibility]: visibility, [_creatorName]: creatorName};
                    if(!findOptionByKeyValue(list, _profileName, tempObj[_profileName])) {
                        list.push(tempObj);
                    }
                }
            });
        }

        return list;
    }

    deleteField(columnFieldId, customGroup){
        let _this=this;
        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId
        var query = {
            action: "deleteCustomField",
            scenario_id: _this.props.scenarioId,
            field_id: columnFieldId
        }
        _this.clearManageColumnsFromStore(scenarioId);
        if (_this.props.dispatch) {
            _this.props.dispatch(updateCustomGroups([]));
        }
        let onThenCallback = (data)=>{
            if(data.success) {
                let deletedField = _this.state.profileColumns.filter(e=> e[MANAGE_COLUMNS.COLUMN_NAME] === customGroup)[0][_children].filter(e=>e[_columnFieldId] === columnFieldId)[0];
                let tempColumnProfiles = copyObjectValues(_this.state.columnProfiles);
                for(let e in tempColumnProfiles){
                    tempColumnProfiles[e].columns = tempColumnProfiles[e].columns.filter(e=>e[_returnName] !== deletedField[_returnName]);
                    tempColumnProfiles[e].originalColumns = tempColumnProfiles[e].originalColumns.filter(e=>e[_returnName] !== deletedField[_returnName]);
                }
                let groupColumns = _this.state.profileColumns.filter(e=> e[MANAGE_COLUMNS.COLUMN_NAME] === customGroup);
                let updatedChildren = groupColumns.length > 0 ? groupColumns[0][_children].filter(e=> e[_columnFieldId] !== columnFieldId) : [];
                let updatedProfileColumns = _this.state.profileColumns;
                if(updatedChildren.length === 0) {
                    updatedProfileColumns = _this.state.profileColumns.filter(e=> e[MANAGE_COLUMNS.COLUMN_NAME] !== customGroup);
                } else {
                    updatedProfileColumns.filter(e=> e[MANAGE_COLUMNS.COLUMN_NAME] === customGroup)[0][_children] = updatedChildren;
                }
                updatedProfileColumns = _this.setBlueDotFlags(updatedProfileColumns);
                let profile = copyObjectValues(this.state.selectedProfile);
                profile.columns = profile.columns?.filter(f => f.column_return_name !== deletedField.column_return_name);
                profile.simplified_columns = profile.simplified_columns?.filter(f => f.column_return_name !== deletedField.column_return_name);

                let checkedItems = _this.state.checkedItems.filter(e=>e.column_name !== deletedField.column_name && (e.column_return_name !== deletedField.column_return_name));
                sessionStorage.setItem("selectedProfile_list", JSON.stringify(profile));
                
                _this.setState({
                    profileColumns: updatedProfileColumns,
                    customGroupFields : _this.state.customGroupFields.filter(e=>e.display_name !== deletedField.column_name),
                    message: lang.manage_columns.text.column_deleted_successfully,
                    isError: false,
                    columnProfiles:tempColumnProfiles,
                    checkedItems: checkedItems,
                    disableToggles: _this.props.manageColumnsSelectionLimit && checkedItems && this.getAllToggledOnOptionsCount(checkedItems) >= Number(_this.props.manageColumnsSelectionLimit)
                },function(){
                    if(typeof _this.props.fetchColumnsOnSave === "function"){
                        _this.props.fetchColumnsOnSave()
                    }
                    _this.launchToast();
                })
            } else {
                _this.state.message = lang.execution_error_two;
                _this.state.isError = true;
                _this.launchToast();
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "deleteField",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
        this.resetInputs();
        _this.setDeleteColumnDialogOpen(false, undefined, undefined)
    }
    
    warningDialogActions = () => {
        let _this = this;
		return(
            <Button 
                label={"Ok"}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={()=>{_this.setInvalidFormulaDialogOpen(false)}}
                aria-label="Close"
            />
		)
	}

    /**
   * Creates 'Define column header warning' dialog body content
   * @returns a component
   */
    invalidFormulaWarningContent = () => {
        return (
            <div className='dialog-text margin-dialog-content uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall margin-right-left'>
                <DialogContentText id="alert-dialog-description" fontSize={"0.83vw"} className="text">
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    {lang.manage_columns.text.invalid_formula_warning}
                </DialogContentText>
            </div>
        )
    }

    /**
  * Function that opens the 'Define columns warning' dialog
  * @param {*} open
  */
    setInvalidFormulaDialogOpen = (open) => {
        let _this = this;
        _this.setState({ invalidFormula: open });
    }

    renderBody() {    
        let types = [];
        const len = lang.manage_columns.labels.length;
        for (var i=0; i<len; i++) {
            types.push({value: MANAGE_COLUMNS.VALUES[i], label: lang.manage_columns.labels[i], tooltipText: lang.manage_columns.type_tooltips[i]});
        }

        const calculatedColumnDescription = ({label, tooltipText}) => (
            <div className={"uk-flex-between uk-display-flex"}>
                {label} 
                <i className="fal fa-info-circle text-white uk-margin-xsmall-top" 
                    uk-tooltip={!!tooltipText ? tooltipText : lang.formula.tooltip}/>
            </div>
        );

        const customGroupsformatOptionLabel = ({label}) => (
            <div className={"uk-flex-between uk-display-flex"}>
                {label.includes(MANAGE_COLUMNS.NEW_GROUP_LABEL) ? 
                <span>{String(label).substr(0,label.indexOf(MANAGE_COLUMNS.NEW_GROUP_LABEL))} <i>{String(label).substr(label.indexOf(MANAGE_COLUMNS.NEW_GROUP_LABEL))}</i></span>
                : label}
            </div>
        );

        return(
            <div>
                <div>
                    <div className="uk-flex-inline uk-width-1-1 uk-margin-default-top-bottom">
                        <div className="uk-width-1-1 uk-margin-default-right">
                            <label htmlFor={lang.manage_columns.titles.name} className="fs-14">{lang.manage_columns.titles.name}<span className="input_required_text">{"(Required)"}</span></label>
                            <FormComponent tag="input" ref={r=>this.nameInputRef=r} id={MANAGE_COLUMNS.NAME_ID} className="uk-input" placeholder={lang.manage_columns.column_name}
                                onChange={(e)=>{this.updateFields(e, MANAGE_COLUMNS.NAME_ID)}} value={this.state[MANAGE_COLUMNS.NAME_ID]}
                            />
                            {this.state.isNameValid && this.state.isNameValid !== "" ? <div className="fs-12 red italic">{this.state.isNameValid}</div> : ""}
                        </div>
                        <div className="uk-width-1-1">
                            <label htmlFor={MANAGE_COLUMNS.CUSTOM_GROUP_ID} className="fs-14">{lang.manage_columns.titles.custom_group}<span className="input_required_text">{"(Required)"}</span></label>
                            <Dropdown
                                id={MANAGE_COLUMNS.CUSTOM_GROUP_ID} 
                                // classNamePrefix={"manage-columns-dropdowns"}
                                value={findOptionByKey(this.state.customGroups, this.state.customGroup.value)}
                                onChange={this.handleChangeCustomGroup}
                                isSearchable
                                onInputChange={(inputVal, params) => this.onInputChange(inputVal, params)}
                                options={this.state.customGroups.filter(group=>(group.label.includes(MANAGE_COLUMNS.NEW_GROUP_LABEL)) || this.state.profileColumns.filter(e=>!(e.is_group && !e.column_children?.length)).map(f=>f.column_return_name).includes(group.value))}
                                placeholder={lang.manage_columns.select_custom_group}
                                formatOptionLabel={customGroupsformatOptionLabel}
                                type={DROPDOWN_TYPE.INPUT}
                                className="input__dropdown"
                                />
                            {this.state.isCustomValid && this.isCustomValid !== "" ? <div className="fs-12 red italic">{this.state.isCustomValid}</div> : ""}
                        </div>
                    </div>
                    <div className="uk-margin-default-bottom uk-flex uk-flex-column">
                        <label htmlFor={MANAGE_COLUMNS.DESCRIPTION_ID} className="fs-14">{lang.manage_columns.titles.description}<span className="input_required_text_disabled">{"(Optional)"}</span></label>
                        <FormComponent tag="textarea" ref={r=>this.descriptionInputRef=r} id={MANAGE_COLUMNS.DESCRIPTION_ID} className="uk-textarea" placeholder={lang.manage_columns.column_description}
                            value={this.state[MANAGE_COLUMNS.DESCRIPTION_ID]} onChange={(e)=>{this.updateFields(e, MANAGE_COLUMNS.DESCRIPTION_ID)}}/>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <label htmlFor={MANAGE_COLUMNS.TYPE_ID} className="fs-14">{lang.manage_columns.titles.type}<span className="input_required_text">{"(Required)"}</span></label>
                        <Dropdown
                            className = {this.state.isEditingColumn? "disabled" : "input__dropdown"}
                            id={MANAGE_COLUMNS.TYPE_ID} 
                            value={findOptionByKey(types, this.state.type)} 
                            onChange={this.handleChange}
                            options={types}
                            formatOptionLabel={calculatedColumnDescription}
                            type={DROPDOWN_TYPE.INPUT}
                            />
                        {this.state.isTypeValid && this.isTypeValid !== "" ? <div className="fs-12 red italic">{this.state.isTypeValid}</div> : ""}
                    </div>
                </div>
                
                <ManageColumnsFormat ref={r=>this.ManageColumnsFormat = r} profileColumns={this.state.profileColumns} vectorsList={this.state.vectorsList} type={this.state.type}
                    groups={this.state.groups} lineAttributeOptions={this.state.lineAttributeOptions} profitStackLineColumns={this.state.profitStackLineColumns} profitFormat={this.props.profitFormat}>
                </ManageColumnsFormat>

                <ClientFormat ref={r=>this.clientFormatRef = r} formats={this.state.formats} formatType={this.state.formatType}
                    unit={!!this.state.columnBeingEdited ? this.state.columnBeingEdited[_unit] : ""}
                />
                
                {this.state.access.edit_company_cols ? 
                    <div style={{width:"10.46875vw"}}className="uk-margin-default-top">
                       <h5 className="uk-margin-xsmall-bottom">{lang.manage_columns.titles.visibility}</h5>
                        <ToggleTab options={this.state.visibilityOptions} onSelectTab={(visibility)=>this.setVisibility(visibility)}
                            defaultValue={this.state.VisibilitySelected ? capitalizeFirstLetter(this.state.VisibilitySelected) : ""}
                        />
                    </div>
                :""}
                {this.state.visibiliyChecked ? 
                <div className="fs-12 red italic" style={{display:"inline-grid"}}>
                    <span className={"uk-text-medium"}>{this.state.visibiliyChecked}</span>
                    {this.state.visibilityCheckedlist.map(function(item){
                        return <span className={"uk-text-medium"}>{item}</span>
                    })
                }
                </div> : ""}
            </div>
        )
    }


    fetchCustomGroups(type) {
        let _this = this;
        let scenarioId = this.props.scenarioId || this.props.comparisonScenarioId
        if (!scenarioId) {
            return;
        }
        let query = {
            action: "fetchCustomGroups",
            scenario_id: scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId,
            profitFormat: type || _this.props.profitFormat.replaceAll(" ","_").toLowerCase(),
        }
        let dataFromStore = getItemFromStore("customGroups", _this, (scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId));
        let onThenCallback = (data)=>{
            if(data) {
                if(_this.props.dispatch && !dataFromStore){
                    _this.props.dispatch(updateCustomGroups([copyObjectValues(data)],(scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId)));
                }
                let customGroups = data.data.map(grp => {
                    grp.label = grp[MANAGE_COLUMNS.DISPLAY_NAME];
                    grp.value = grp[MANAGE_COLUMNS.DISPLAY_NAME];
                    return grp;
                });
                this.setState({
                    customGroups: copyObjectValues(customGroups),
                    customGroupsOriginal: copyObjectValues(customGroups),
                })
            }
        }
    
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchCustomGroups",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]:  _this.props.hideManage || (_this.props.isDashboards && _this.props.showLoader) ,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.sidePanelLoader]: _this.props.isDashboards,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.useStore]: true,
            [FETCHAPI_PARAMS.dataKey]: "customGroups",
            [FETCHAPI_PARAMS.scenarioId]: (scenarioId && isNaN(scenarioId) ? scenarioId.value : scenarioId),

        }
        _this.fetchAPI(fetchOptions);
    }

    reset(){
        var _this = this;
        var columnProfiles = _this.state.columnProfiles.filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId]);
        var originalColumnProfiles = tryParse(_this.state.originalColumnProfiles).filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId]);
        let profileColumns = copyObjectValues(this.state.profileColumns);
       
        let selectedProfile  = copyObjectValues(this.state.selectedProfile);
        selectedProfile[_isModified] = false;
        selectedProfile[_isApplied] = false;
        if (columnProfiles && columnProfiles[0] && originalColumnProfiles && originalColumnProfiles[0]){
            columnProfiles[0][_profileColumns] = copyObjectValues(originalColumnProfiles[0][_profileColumns]);
           
            _this.setState({
                profileColumns: _this.updateDataFlags(profileColumns, _this.state.selectedProfile[_profileColumns]),    //update column flags when profile has changed
                checkedItems: copyObjectValues(tryParse(_this.state.originalColumnProfiles).filter(e=>e[_profileId] === _this.state.selectedProfile[_profileId])[0][_profileColumns]),
                selectedProfile:selectedProfile,
                appliedProfile: undefined
                
            },function(){
                _this.resetInputs();
                let cb = ()=>{
                   $(this.props.isLandscapeBubble?"#profile-name" + this.props.sendUniqueId:"#profile-name").contentEditable = "false";
                   $(this.props.isLandscapeBubble?"#profile-name" + this.props.sendUniqueId:"#profile-name").text(_this.state.selectedProfile[_profileName]);
                };
                _this.discardChanges(cb,true);
                _this.filterColumns("");
            });
        }
    }

    getEmbdedChildName=(returnName, data)=>{
        for (var e in data) {
            if (data[e][_returnName] === returnName){
                return data[e][_columnName]
            }else if (data[e][_children]) {
                let name = this.getEmbdedChildName(returnName, data[e][_children]);
                if (name !== returnName) {
                    return name;
                }
                continue;
            }
         }
         return returnName
    }

    /**
     * function loops through profilColumns and extract the columnName of the returnNAme provided 
     * @param {*} returnName 
     * @param {*} arrData 
     * @returns column name of the returnname found in arrData
     */
    getColumnName=(returnName, arrData)=> {
        let _this = this;
        if (returnName.endsWith(_cc)) {
            return returnName;
        }
        let data = arrData || _this.state.profileColumns;
        for (var e in data) {
            let children = data[e][_children];
            let name = children? children.filter(e=>e[_returnName] === returnName):"";
            if (name.length > 0) {
                return name[0][_columnName];
            }else if (data[e][_lineType] === "psl_line"){
                let newName = _this.getEmbdedChildName(returnName, children)
                if(newName !== returnName) {
                    return newName;
                }
            }
        }
        return returnName;
    }

    saveProfileDialogContent = () => {
        return (
            <>
                <div>
                    <h5 className="uk-margin-xsmall-bottom">{lang.manage_columns.titles.name}</h5>
                    <FormComponent ref={r => this.customProfileInputRef = r} tag="input" id={this.props.isLandscapeBubble ? "_profileName" + this.props.sendUniqueId : "_profileName"} className="form-control width-250" placeholder={lang.manage_columns.text.name_your_profile}
                        onChange={(e) => { this.storeTempSavedData(_profileName, e.target.value.trim()) }} value={this.state["tempSaved" + _profileName]} />

                    {!this.state.isProfileNameUnique &&
                        <p className="red italic uk-margin-default-top uk-text-medium">{lang.manage_columns.text.profile_name_is_not_unique}</p>
                    }
                </div>

                {this.state.access.edit_company_cols &&
                    <div className="uk-margin-default-top">
                        <h5 className="uk-margin-xsmall-bottom">{lang.manage_columns.titles.visibility}</h5>
                        <ToggleTab options={this.state.visibilityOptions} onSelectTab={(visibility) => this.storeTempSavedData(_visibility, visibility.toLowerCase())}
                            defaultValue={this.state["tempSaved" + _visibility] ? capitalizeFirstLetter(this.state["tempSaved" + _visibility]) : ""}
                        />
                    </div>
                }
                {this.state.isVisibilityValid &&
                    <div className="fs-12 red italic">{this.state.messageNotMatch}</div>
                }
                {this.state.visibilityChecked &&
                    <div className="fs-12 red italic" style={{ display: "inline-grid" }}>
                        <span className={"uk-text-medium"}>{this.state.visibilityChecked}</span>
                        {this.state.visibilityCheckedlist.map(function (item) {
                            return <span className={"uk-text-medium"}>{item}</span>
                        })
                        }
                    </div>
                }
            </>
        )
    }

    saveProfileDialogActions = () => {
        let text = this.getBoardTextForRendering();

        return <>
            <Button 
                id="manage-columns-apply"
                label={lang.modal.buttons.save}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => this.startSave(true)}
                disabled={text.isSaveAsNewDisabled}
            />
            <Button 
                label={lang.modal.buttons.cancel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={this.cancelSave}
            />
        </>
    }

    setSaveProfileDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openSaveProfileDialog: isOpen
        })
    }
    backdropClickHandler = () => {
      this.resetInputs(false);
      this.setState({
          drawerOpen: false
      })
    }

    drawerToggleClickHandler = (resetInputs) => {
        let _this = this
        _this.setState({
            drawerOpen: !_this.state.drawerOpen,          
        },function(){
            if(resetInputs){
                _this.resetInputs(false);
            }
        })
    }

    /**
     * Get the number of all toggled on options in manage columns but remove 'entity count' and 'count per selection' for the selected vector from the count.
     * Plus, remove 'count per selection' for the vectors from the count.
     * @returns 
     */
    getAllToggledOnOptionsCount = (checkedItems) => { // unit test
        let filteredCheckedItems = checkedItems?.filter(
          (f) =>
            !(f.type === MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME && [PSL_RETURN_NAMES.ENTITY_COUNT, PSL_RETURN_NAMES.COUNT_PER_SELECTION].includes(f.machine_name)) &&
            !(f.type === MANAGE_COLUMNS.VECTOR_MACHINE_NAME && [PSL_RETURN_NAMES.COUNT_PER_SELECTION].includes(f.machine_name))
        );
    
        return filteredCheckedItems?.length;
    };

    render() {
        const _this = this;
        if(this.props.hideManage || ((!this.props.user) && !this.props.isDashboards)) {
            return <div></div>;
        }
        let disabled = this.props.isDashboards ? " uk-button-disabled-manage disabled " : "";
        let text = this.getBoardTextForRendering();
        const {isOverwriteDisabled, isSaveAsNewDisabled, isDeleteAllowed, grayText, overwriteTooltipMsg, profileName, isModified} = text;
        let buttons = [];
        buttons.push(
            {button:lang.modal.buttons.save, isSubmit: true, isDisabled :false, id: "save-created-column-btn"},
            {button:lang.modal.buttons.cancel, isSubmit: false, isDisabled :false, onClick:this.cancel}
        );
        let backdrop = "";
        if (this.state.drawerOpen) {
            backdrop = <Backdrop close={this.backdropClickHandler} />;
        }

        /* split dropdown button custom style */
        const splitDropdownCustomStyles = {
            control: (styles) => ({ ...styles, cursor: 'pointer', border: 'unset', background: '#d9d9d9' }),
            placeholder: (styles) => ({ ...styles, color: '#FFF', fontSize: convertPxToViewport(12) }),
            option: (styles) => ({ ...styles, cursor: 'pointer', padding: convertPxToViewport(8)+' '+convertPxToViewport(12) }),
            menu: styles => ({ ...styles, width: convertPxToViewport(330) })
        };

        const OptionComponent = props => {
            let data = props.data;
            let faClass = data.isProfileGroup ? data[_isFavorite] ? "text-black fa-lg fas fa-star" : data[_visibility] === _visibilityOptions.COMPANY ? "text-black fa-lg fal fa-users" : "text-black fa-lg fal fa-user" : "";
            let eyetooltip= [];
            let columnProfiles = _this.state.columnProfiles.filter(e=>e[_profileId] === data[_profileId]);
            let columns = [];
            if(columnProfiles.length > 0){
                if(typeof columnProfiles[0].originalColumns[0] === "string" && _this.props.isLandscapeBubble){
                    columns = columnProfiles[0].simplified_columns;
                }else{
                    columns = columnProfiles[0].originalColumns;
                }
            }
            // let columns = data.originalColumns;
            if(data && data.originalColumns){
                for (let e in columns) {
                    let colValue = columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME];
                    if(columns[e][MANAGE_COLUMNS.MACHINE_NAME] !== column_suffixes_values.value.value){
                        colValue += "_" + columns[e][MANAGE_COLUMNS.MACHINE_NAME];  //if view machine_name != "value", append it to the value
                    }
                    if(columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME] === _selectedVector) {
                        //for selected_vector column, only use view machine name as value, for "name", "number"...
                        //also "quadrant_tier" (view machine name) should be "quadrant" to match identifier return name
                        colValue = columns[e][MANAGE_COLUMNS.MACHINE_NAME].replace(/_/g,"");
                    }
                    let label =  (columns[e] && columns[e][MANAGE_COLUMNS.COLUMN_NAME] ? 
                            !columns[e][MANAGE_COLUMNS.MACHINE_NAME] ? columns[e][MANAGE_COLUMNS.COLUMN_NAME] : [column_suffixes_values.minimum.value,column_suffixes_values.maximum.value,column_suffixes_values.range.value,column_suffixes_values.median.value,column_suffixes_values.average.value].includes(columns[e][MANAGE_COLUMNS.MACHINE_NAME]) ?
                            column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label + " " + columns[e][MANAGE_COLUMNS.COLUMN_NAME].replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"")
                            :columns[e][MANAGE_COLUMNS.COLUMN_NAME] + " " + (columns[e][MANAGE_COLUMNS.MACHINE_NAME] === column_suffixes_values.value.value ? 
                            ""  : column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label) : 
                            [column_suffixes_values.minimum.value,column_suffixes_values.maximum.value,column_suffixes_values.range.value,column_suffixes_values.median.value,column_suffixes_values.average.value].includes(columns[e][MANAGE_COLUMNS.MACHINE_NAME]) ?
                             column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label + " " + _this.getColumnName(columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME]).replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"")
                              : _this.getColumnName(columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME]).replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"") + " " +column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label);
                    if(lang.manage_columns.column_types.vector || lang.manage_columns.column_types.selected_vector){
                            label = vectorSpacing(label);
                    }
                    eyetooltip.push(label);
                }
                eyetooltip.sort();   
            }
            if(eyetooltip.length > 15){
                eyetooltip.splice(15,15, '. . .'); //second argument to replace 15 elements after the limit
                // eyetooltip.push('...');
            }
            eyetooltip = eyetooltip.join().replaceAll(',','</br>');

            return (
                <components.Option {...props}>
                    {data.isProfileGroup ?
                        <div className="uk-flex uk-flex-middle manage_columns_group">
                            {/* <i className={faClass + " uk-padding-xsmall-right"}></i> */}
                            <span className='manage_columns_label_text'>{props.label}</span>
                        </div>
                    :
                    data[_isFavorite] ? 
                        <div className="uk-flex uk-flex-between uk-flex-middle dropdown_row_fullheight">
                            <div className="fs-12 uk-flex uk-flex-middle">
                                <i className="far fa-eye uk-margin-small-right uk-cursor-pointer" uk-tooltip={eyetooltip}></i>
                                <span uk-tooltip={"title:"+props.label} className="no-wrap uk-padding-xxsmall-right manage_columns_text">{sliceCharLength(props.label,textLabelLength)}</span>
                                <em className="user-full-name manage_columns_hover_options">{data[_isCompanyDefault] || data[_isCreatorSystem] ? "" : data[_creatorName]}</em>
                            </div>
                            <div className="uk-flex uk-flex-between uk-flex-middle">
                                {this.props.isDashboards ||  ((data[_isCompanyDefault] && !parseBoolean(_this.props.user.is_system)) || (data[_visibility] === _visibilityOptions.COMPANY && !this.state.access.edit_company_cols)) ? "" :
                                    <React.Fragment>
                                        <i className="fa-lg fal fa-edit uk-margin-small-right manage_columns_hover_options" onClick={(e)=>this.startEditProfile(e, data)}></i>
                                        {
                                            this.props.isDashboards || (data[_isCompanyDefault] || (data[_visibility] === _visibilityOptions.COMPANY && !this.state.access.edit_company_cols)) ? "":
                                            <i className="fa-lg fal fa-trash-alt uk-margin-small-right manage_columns_hover_options" onClick={(e)=>this.startDelete(e, data[_profileId])}></i>
                                        }
                                    </React.Fragment>
                                }
                                {this.props.isDashboards ? "" : 
                                    data[_isUserDefault] || (data[_isCompanyDefault] && _this.state.companyDefaultProfileId === _this.state.defaultProfileId) ?
                                    <React.Fragment>
                                        <i className=" fas fa-star uk-cursor manage_columns_options" title = "Remove From Favorites" onClick={(e)=>this.makeProfileFavorite(e, data, false)} />
                                        <span className="disabled uk-margin-large-left-right uk-margin-small-left  default_manage_columns">{lang.manage_columns.text.default}</span>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <i className="fas fa-star uk-cursor manage_columns_options" title = "Remove From Favorites" onClick={(e)=>this.makeProfileFavorite(e, data, false)} />
                                        <a className="manage_columns_hover_options uk-margin-small-left  fs-12 uk-text-nowrap" onClick={(e)=>this.makeProfileDefault(e, data)}>{lang.manage_columns.text.make_default}</a>
                                    </React.Fragment>
                                        
                                }
                            </div>
                        </div> 
                    :
                        <div className="uk-flex uk-flex-between uk-flex-middle dropdown_row_fullheight">
                            <div className="fs-12 uk-flex uk-flex-middle">
                                <i className="far fa-eye uk-margin-small-right uk-cursor-pointer" uk-tooltip={eyetooltip}></i>
                                <span uk-tooltip={"title:"+props.label} className="manage_columns_text no-wrap uk-padding-xxsmall-right">{sliceCharLength(props.label,textLabelLength)}</span>
                                <em className="user-full-name manage_columns_hover_options">{data[_isCompanyDefault] || data[_isCreatorSystem] ? "" : data[_creatorName]}</em>
                            </div>
                            <div className="uk-flex uk-flex-between uk-flex-middle">
                                {this.props.isDashboards ||  ((data[_isCompanyDefault] && !parseBoolean(_this.props.user.is_system)) || (data[_visibility] === _visibilityOptions.COMPANY && !this.state.access.edit_company_cols)) ? "" :
                                    <React.Fragment>
                                        <i className="manage_columns_hover_options fa-lg fal fa-edit uk-margin-small-right" onClick={(e)=>this.startEditProfile(e, data)}></i>
                                        {
                                            this.props.isDashboards || (data[_isCompanyDefault] || (data[_visibility] === _visibilityOptions.COMPANY && !this.state.access.edit_company_cols)) ? "":
                                            <i className="manage_columns_hover_options fa-lg fal fa-trash-alt uk-margin-small-right" onClick={(e)=>this.startDelete(e, data[_profileId])}></i>
                                        }
                                    </React.Fragment>
                                }
                                {this.props.isDashboards ? "" : 
                                    data[_isUserDefault] || (data[_isCompanyDefault] && _this.state.companyDefaultProfileId === _this.state.defaultProfileId) ?
                                    <React.Fragment>
                                        <i className="far fa-star uk-cursor manage_columns_options" title="Add to Favorites" onClick={(e)=>this.makeProfileFavorite(e, data, true)} />
                                        <span className="default_manage_columns uk-margin-xsmall-left-right uk-margin-small-left">{lang.manage_columns.text.default}</span>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <i className="far fa-star uk-cursor manage_columns_hover_options" title="Add to Favorites" onClick={(e)=>this.makeProfileFavorite(e, data, true)} />
                                        <a className="manage_columns_hover_options bold text-white fs-12 uk-text-nowrap uk-margin-small-left " onClick={(e)=>this.makeProfileDefault(e, data)}>{lang.manage_columns.text.make_default}</a>
                                    </React.Fragment>
                                        
                                }
                            </div>
                        </div>
                    }
                </components.Option>
            );
        }
        let embededChild = getEmbeddedChild(this.state.profileColumns, PROFILE_COLUMN.CHILDREN,  PROFILE_COLUMN.COLUMN_FIELD_ID, _this.state.columnFieldId);
        return (
            <React.Fragment>
                <div id={this.props.isLandscapeBubble ? "toastManageCol-" + this.props.sendUniqueId : "toastManageCol"} className="toast toast-success">
                    <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (this.state.isError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i>{this.state.message}</div>
                </div>
                {this.props.isDashboards ? "" : 
                <div>
                    {/* <div className="index"> */}
                        {/* <SidePanel ref={r=>this.manageColsOverlay=r} onToggleBoard={this.onToggleBoard} body={this.renderBody()} onSave={this.saveCreatedColumn}
                            onCancel={this.cancel} largeDisplay={this.state.largeDisplay}
                            title={this.state.isEditingColumn ? lang.manage_columns.titles.edit_column + (embededChild && embededChild[_isCreatorSystem] ? "" :" - "+ lang.manage_columns.titles.created_by + " " + this.state.columnBeingEdited[_creatorName]) : lang.manage_columns.titles.create_column}>
                        </SidePanel> */}
                        <SidePanelNew 
                        // ref={r => this.manageColsOverlay = r}
                            // onToggleBoard={this.onToggleBoard}
                            body={this.renderBody()}
                            show={this.state.drawerOpen}
                            close={this.backdropClickHandler}
                            onSubmit={this.saveCreatedColumn}
                            largeDisplay={true}
                            buttons={buttons}
                            title={this.state.isEditingColumn ? lang.manage_columns.titles.edit_column + (embededChild && embededChild[_isCreatorSystem] ? "" : " - " + lang.manage_columns.titles.created_by + " " + this.state.columnBeingEdited[_creatorName]) : lang.manage_columns.titles.create_column}>
                        </SidePanelNew>
                        {backdrop}
                    {/* </div> */}
                    </div>
                }
                {!this.props.hideDropdown ?
                    <div className={"manage-columns split__button uk-flex"+(!!this.state.disabled && this.state.disabled ? " disabled" : "")}>
                        {/* <button className={(!this.props.hideArrow ? "split__button--modal " : "") + (!this.props.isDashboards ? " uk-button-icon  uk-margin-xmedium-left" : "") + disabled}onClick={this.toggleBoard}>
                        <i className="far fa-plus-circle fa-lg uk-padding-xsmall-right" aria-hidden="true"></i>
                            {lang.manage_columns.titles.manage_columns}
                        </button> */}
                    <Button  
                        label= {lang.manage_columns.titles.manage_columns}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT} 
                        aria-hidden="true"
                        disabled={this.props.isDashboards}
                        className={"split__button--modal"}
                        onBtnClick={this.toggleBoard}
                        leftIcon={<i className="far fa-plus-circle fa-lg" />}
                    />
                        {!this.props.hideArrow && 
                            <div id={'manage_columns_dropdown'+this.props.constraint} className={_this.props.isDashboards ? "manage_columns_dropdown_dashboard" : 'manage_columns_dropdown'}>
                                <SearchableSelect ref={el=>_this.menuRef=el} constraint={_this.props.constraint} options={_this.state.profileOptions} menuWidth={_this.props.menuWidth} OptionComponent={OptionComponent} splitDropdownCustomStyles={splitDropdownCustomStyles} onChange={_this.applyProfileChanges} value={_this.state.selectedProfile} placeholder={lang.manage_columns.text.search_lists_placeholder} isFromExpandingList={_this.props.isFromExpandingList} ></SearchableSelect>
                            </div>}
                        {this.state.warningMessage ?
                            <span className={'uk-display-flex uk-flex-middle width-15 uk-margin-xsmall-left uk-cursor-pointer fas fa-lg fa-exclamation-triangle'} uk-tooltip={this.state.warningMessage}></span>
                        :""}
                    </div>
                :""}
            {this.props.isDashboards ? "" : 
                <div ref={r=>this.boardRef=r} id="manage-columns-board" className="">
                    <div>
                        <div id="manage-columns-board-header">
                            <div className="uk-flex uk-flex-between">
                                <span className='main_side_panel_title'>{lang.manage_columns.titles.manage_columns}
                                </span>
                                <Button  
                                    variant={BUTTON_VARIANT.TERTIARY}
                                    size={SIZES.ICON}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="close-button uk-margin-remove align_items_start"
                                    onBtnClick={this.discardAndClose}
                                    aria-label="Close" 
                                    leftIcon={<span aria-hidden="true"><i className="fal fa-times" /></span>}
                                />
                            </div>
                            <div className="uk-flex uk-flex-middle uk-margin-medium-top">
                                { this.state.selectedProfile && this.state.selectedProfile[_visibility] === _visibilityOptions.COMPANY ? 
                                    <i title="By Company" className="fal fa-2x fa-users uk-margin-small-right side_panel_icon"></i>
                                    : <i title="By You" className="fal fa-2x fa-user uk-margin-small-right"></i>
                                }
                                {(profileName || grayText) ?
                                    <div className="uk-flex-inline"> 
                                        <span id={this.props.isLandscapeBubble?"profile-name" + this.props.sendUniqueId:"profile-name"} contentEditable="false" suppressContentEditableWarning={true} className={"profile-name secondary_side_panel_title"} title={profileName? profileName : grayText}>
                                            {profileName? profileName.length >= 40 ? profileName.slice(0,40)+"..." : profileName : grayText.length >=40 ?grayText.slice(0,40)+"..." : grayText} 
                                        </span>
                                        <h4 className="modified uk-margin-small-left" style={{marginTop: convertPxToViewport(3)}}>{isModified !== "" ? isModified : ""}</h4>
                                    </div>
                                    :""
                                }
                                {isDeleteAllowed ?
                                    <div className="uk-flex uk-position-absolute right-zero" style={{marginRight: '3%'}}>
                                        <Button  
                                            variant={BUTTON_VARIANT.TERTIARY}
                                            size={SIZES.ICON}
                                            type={BUTTON_TYPE.DEFAULT}
                                            onBtnClick={(e)=>this.startEditProfileName(e)}
                                            leftIcon={<i className="fal fa-pen fa-lg" />}
                                        />
                                        <Button  
                                            variant={BUTTON_VARIANT.TERTIARY}
                                            size={SIZES.ICON}
                                            type={BUTTON_TYPE.DEFAULT}
                                            onBtnClick={(e)=>this.startDelete(e, this.state.selectedProfile[_profileId], false)}
                                            leftIcon={<i className="fal fa-trash-alt fa-lg" />}
                                        />
                                    </div>
                                :""}
                               
                            </div>
                            <div>
                                { this.state.nameNotValidMessage ?
                                    <p className="red italic uk-margin-default-top uk-text-medium">{this.state.nameNotValidMessage}</p>
                                    :""
                                }
                                {this.state.visibiliyChecked ? 
                                    <div className="fs-12 red italic" style={{display:"inline-grid"}}>
                                        <span className={"uk-text-medium"}>{this.state.visibiliyChecked}</span>
                                        {this.state.visibilityCheckedlist.map(function(item){
                                            return <span className={"uk-text-medium"}>{item}</span>
                                        })
                                    }
                                    </div> : ""}
                                {this.state.isVisibilityValid ? 
                                        <div className="fs-12 red italic">{this.state.messageNotMatch}</div>
                                :""}

                                {<h5 className={"disabled-text uk-margin-small-bottom"} style={{marginTop: convertPxToViewport(3)}}>
                                        {profileName? grayText :""}
                                    </h5>
                                }
                                {this.state.access.edit_company_cols && this.state.selectedProfile && !this.state.selectedProfile[_isCompanyDefault] ? 
                                    <div className=".uk-margin-small-top-bottom-15">
                                        <ToggleTab options={this.state.visibilityOptions} onSelectTab={(visibility)=>this.startEditVisibility(visibility.toLowerCase())}
                                            defaultValue={this.state["tempSaved"+_visibility]? capitalizeFirstLetter(this.state["tempSaved"+_visibility]) : this.state.selectedProfile[_visibility] ? capitalizeFirstLetter(this.state.selectedProfile[_visibility]) : ""}
                                        />
                                    </div>
                                :""}
                            </div>
                          
                             
                            <div className="uk-flex uk-flex-between align_items_center gap_between_buttons">
                                <div style={{width:"61%"}} className="uk-margin-default-top uk-flex">
                                    <Input type="text" id="search_input_manage_columns"
                                    className="" 
                                    onChange={this.filterColumns} placeholder={lang.manage_columns.text.search_placeholder} isSearch={true}/>
                                </div>
                                { <Button  
                                        label={lang.manage_columns.titles.create_column}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className="uk-margin-default-top"
                                        onBtnClick={() => this.drawerToggleClickHandler(true)}
                                        leftIcon={<i className="fa-lg far fa-plus-circle" />}
                                    />
                                }
                            </div>
                        </div>

                        <div id="manage-columns-board-body">
                            {this.state.profileColumns.length ? 
                                <div id="columns-container">
                                    {this.renderData(this.state.profileColumns)}
                                </div>
                            :""}
                        </div>
                    </div>
                    <div id="manage-columns-board-footer uk-display-flex" /*style={{position:"fixed", bottom:"3px", width: "95%"}}*/>
                    {_this.props.manageColumnsSelectionLimit?
                        <div className='uk-margin-small-bottom'>
                            { this.getAllToggledOnOptionsCount(_this.state.checkedItems) +"/"+ Number(_this.props.manageColumnsSelectionLimit)}
                        </div>
                    :""}
                        {(this.state.selectedProfile && this.state.selectedProfile[_visibility] === _visibilityOptions.COMPANY && this.state.access.edit_company_cols) ||
                            (this.state.selectedProfile && this.state.selectedProfile[_visibility] === _visibilityOptions.USER && this.state.access.edit_user_cols) 
                                ?
                                <h6 className="color-light-grey">{lang.manage_columns.text.footer_text}</h6>
                        :""}
                         {/* {this.state.visibilityChecked ? 
                                        <div className="fs-12 red italic" style={{display:"inline-grid"}}>
                                            <span className={"uk-text-medium"}>{this.state.visibilityChecked}</span>
                                            {this.state.visibilityCheckedlist.map(function(item){
                                                return <span className={"uk-text-medium"}>{item}</span>
                                            })
                                        }
                                        </div> 
                                    : ""} */}
                        <div className="uk-flex uk-flex-between uk-margin-default-top uk-margin-small-bottom flex_direction_column">
                            <div style={{padding:"0.83vw 0"}} className="uk-flex max_width">
                                    {
                                      this.state.selectedProfile && this.state.selectedProfile[_isCompanyDefault] && !parseBoolean(this.props.user.is_system)|| (this.state.selectedProfile && this.state.selectedProfile[_visibility] === _visibilityOptions.COMPANY && !this.state.access.edit_company_cols) ? 
                                      <Button  
                                        label={lang.modal.buttons.save_as}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        onBtnClick={this.showSaveProfileDialog}
                                        leftIcon={<i  className="fa-lg fal fa-save" />}
                                    /> : 
                                      <div style={{width:"75%"}} className={"uk-button-group"+(this.state.nameNotValidMessage ? " disabled" :"")}>
                                        <Button 
                                            label = {lang.modal.buttons.overwrite}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            className={"uk-button max_width "}
                                            disabled = {this.state.visibiliyChecked}
                                            onBtnClick={()=>this.startSave(false)}
                                            leftIcon={<i className="fa-lg fal fa-save"></i>}
                                        />
                                        <div className="uk-inline">
                                            <Button 
                                                variant={BUTTON_VARIANT.SECONDARY}
                                                size={SIZES.ICON}
                                                type={BUTTON_TYPE.DEFAULT}
                                                className={"uk-icon-container uk-button no_radius_left"}
                                                leftIcon={<span className="uk-icon far fa-chevron-down" />}
                                            /> 
                                            <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;" className='save_as_dropdown'>
                                                <ul className="uk-nav uk-dropdown-nav">
                                                    <li><a className="text-align-center" onClick={this.showSaveProfileDialog}> {lang.modal.buttons.save_as}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                     </div>}
                                {(this.state.selectedProfile /*&& !this.state.selectedProfile[_isApplied]*/) ? //after applying, the side panel will be closed, so we don't need this condition "&& !this.state.selectedProfile[_isApplied]", so I removed it to fix a bug PI-16329
                                    // <h5 id="manage-columns-reset" className="text-link uk-margin-xsmall-left" onClick={()=>this.reset()}>
                                    //     {lang.modal.buttons.reset}
                                    // </h5>
                                        <Button
                                            label={lang.modal.buttons.reset}
                                            title={lang.modal.buttons.reset}
                                            variant={BUTTON_VARIANT.TERTIARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            style={{width:"25%"}}
                                            className="uk-button-icon uk-margin-xsmall-left"
                                            onBtnClick={() => this.reset()}
                                        />
                                :""}
                            </div>
                            <Button 
                                id="manage-columns-apply"
                                label={lang.modal.buttons.apply}
                                variant={BUTTON_VARIANT.PRIMARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                // className={"uk-margin-right"}
                                onBtnClick={()=>_this.startApply()}
                            />
                        </div>
                    </div>
                </div>  
            }
        
                <Modal 
                    id={"warning-manage-columns"}
                    title={"Warning"}
                    openDialog={this.state.invalidFormula}
                    bodyContent={this.invalidFormulaWarningContent}
                    dialogActions={this.warningDialogActions}
                    closeClick={()=>{_this.setInvalidFormulaDialogOpen(false)}}
                    size={DIALOG_SIZE.MEDIUM}
                />
                <Modal 
                    id={"delete-profile-dialog"}
                    title={lang.modal.buttons.delete_column_list}
                    openDialog={this.state.openDeleteProfileDialog}
                    bodyContent={this.deleteProfileDialogContent}
                    dialogActions={this.deleteProfileDialogActions}
                    closeClick={() => this.setDeleteProfileDialogOpen(false, undefined)}
                    size={DIALOG_SIZE.LARGE}
                />
                <Modal 
                    id={"delete-column-dialog"}
                    title={lang.manage_columns.titles.delete_column}
                    openDialog={this.state.openDeleteColumnDialog}
                    bodyContent={this.deleteColumnDialogContent}
                    dialogActions={this.deleteColumnDialogActions}
                    closeClick={() => this.setDeleteColumnDialogOpen(false, undefined)}
                    size={DIALOG_SIZE.LARGE}
                />
                <Modal 
                    id={"cant-edit-column-dialog"}
                    title={lang.manage_columns.cant_edit_title}
                    openDialog={this.state.openCantEditColumnDialog}
                    bodyContent={this.cantEditColumnDialogContent}
                    dialogActions={this.cantEditColumnDialogActions}
                    closeClick={() => this.setCantEditColumnDialogOpen(false)}
                    size={DIALOG_SIZE.MEDIUM}
                />

                <Modal 
                    id={"save-profile-dialog"}
                    title={lang.manage_columns.titles.save_column}
                    openDialog={this.state.openSaveProfileDialog}
                    bodyContent={this.saveProfileDialogContent}
                    dialogActions={this.saveProfileDialogActions}
                    closeClick={() => this.setSaveProfileDialogOpen(false)}
                    size={DIALOG_SIZE.MEDIUM}
                />
            </React.Fragment>
        );
    }
    //#endregion
}

export default ManageColumns