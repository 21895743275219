import React, {Suspense, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom/cjs/react-router-dom";
import {useAuthenticated} from "../CustomHooks";
import ModelingSettings from "../../components/modelingSettings/ModelingSettings";
import Button from "../../newComponents/Button";
import {ReportHeader} from "../ReportHeader";
import Modal from "../../newComponents/Modal";
import {Loader} from "../../form/elements";
import {ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, PERIOD_ONWARD, SIZES} from "../../class/constants";
import {lang} from "../../language/messages_en";
import {renderTitle} from "../functions/componentFunctions";
import {copyObjectValues} from "../../class/utils";
import {getClientPeriods} from "../api/api";
import {logout} from "../../class/common";
import {useDispatch} from "react-redux";

/**
 * @author Ralph Bejjani
 * @description This component holds the the header of the modeling settings and modeling settings component named ModelingSettings where the header contains title
 * @param {*} props
 * @returns Report header and Modeling Settings
 */
const ModelingSettingsWrapper = (props) => {
    const userAllowedMenuLinks = props.userAllowedMenuLinks;
    const userSettings = props.userSettings;
    const { checkingForUnsavedChanges } = props;
    const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
    const [headerElements, setHeaderElements] = useState([]);
    const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.DATA_MODELING.MODELING_SETTINGS);
    const [clientPeriodsState, setClientPeriodsState] = useState([]);
    const [profitFormat, setProfitFormat] = useState(ALL_WIDGETS.MODELING_SETTINGS);
    const [openModal, setOpenModal] = useState(false);
    const [divIdState, setDivIdState] = useState();
    const [destinationClientIdState, setDestinationClientIdState] = useState();
    const params = useParams();
    const dispatch = useDispatch();
    const reportRef = useRef();

    /**
     * @function useEffect()
     * on screen initialization if isAuthenticated is false user gets logged out
     * isAuthenticated is a boolean read from a custom hook named useAuthenticated
     **/
    useEffect(() => {
        if (!isAuthenticated) {
            logout();
        }
    }, [isAuthenticated]);

    /**
     * @function useEffect()
     * On mount set header elements which is the title only in our case
     */
    useEffect(() => {
            let headerElements = getHeaderElements();
            setHeaderElements(headerElements);
    }, []);

    /**
     * @function useEffect()
     * On mount getPeriods function is being called in order to call getClientPeriods
     */
    useEffect(() => {
        getPeriods(undefined, undefined);

    }, []);

    /**
     * @function getPeriods()
     * Using this function to pass only params that are changing Call getClientPeriods
     */
    const getPeriods = (fromSave, switchAfter, switchCallback) =>{
        getClientPeriods(fromSave, switchAfter, setClientPeriodsState, profitFormat, params, userSettings, undefined, setNewClientPeriods, switchCallback);

    }

    /**
     * @function useEffect()
     * To check for unsaved changes when changing client or report without saving
     */
    useEffect(() => {
        if (checkingForUnsavedChanges) {
            setDivIdState(props.divId);
            setDestinationClientIdState(props.destinationClientId);
            if (reportRef?.current?.checkHasChange()) {
                openModelingSettingsModal();
            } else {
                props.setCheckingForUnsavedChanges(false);
                props.setCheckedUnsavedChangesRef(true, props.divId && Object.keys(props.divId).length > 0, !!props.destinationClientId);
            }
        }
    }, [checkingForUnsavedChanges, props.divId, props.destinationClientId]);

    const setSaveFirstDialogOpen = (isOpen) => {
        setOpenModal(isOpen);
    }

    const openModelingSettingsModal = () => {
        setOpenModal(true);
    };

    /**
     * @function closeModelingSettingsModal()
     * Closing modal and emptying states
     */
    const closeModelingSettingsModal = () => {
        setOpenModal(false);
        setDestinationClientIdState(undefined);
        setDivIdState(undefined);
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(false, false, false);
        props.setChangingReport(false);
    };

    const messageDialogContent = (msg) => {
        return (<span className='fs-16'>{msg}</span>)
    }

    const saveFirstDialogActions = () =>{
        return(
            <>
                <Button
                    label={lang.modal.buttons.yes}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={onButtonYes}
                />
                <Button
                    label={lang.modal.buttons.no}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={onButtonNo}
                />
                <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={closeModelingSettingsModal}
                />
            </>
        )
    }

    const onButtonNo = () => {
        props.setChangingReport(false)
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
    };

    const onButtonYes = () => {
        let cb = () => {
            props.setCheckingForUnsavedChanges(false);
            props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
        }
        reportRef?.current?.saveChangesThenChangeColumn(cb);
    }


    const getHeaderElements = () => {
        let headerElements = [];
        headerElements.push(
            <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
                {renderTitle(reportTitle)}
            </div>
        );

        return headerElements;
    };

    /**
     * @function useEffect()
     * Adding onward for period dropdowns in Data Sources Screen
     */
    useEffect(() => {
            let periods = clientPeriodsState?.allPeriods;
            periods?.unshift({ value: PERIOD_ONWARD, label: PERIOD_ONWARD });
    }, [clientPeriodsState]);

    /**
     * @function setNewClientPeriods()
     * This function is called from getClientPeriods to refetch periods and change data and is changed state
     * and switch to tab if clicking on data sources tab when have changes not saved
     */
    const setNewClientPeriods=(tempState, switchAfter)=>{
        let data = copyObjectValues(tempState.fiscalPeriods);
        setClientPeriodsState(tempState)

            if(reportRef.current){
                reportRef.current?.periodsRef?.fiscalCalendarRef.setState({
                    data: data,
                    isChanged: false
                },function(){
                    reportRef.current.periodsRef.fiscalCalendarRef.periodTableRef.tabulator.replaceData(data);
                    reportRef.current.periodsRef.fiscalCalendarRef.periodTableRef.addListeners();
                    if(switchAfter){
                        reportRef.current.changeColumns(switchAfter)
                    }
                })
            }
    }

    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <div
                    id={"main-component-container"}
                    className={"main-component-container"}
                    style={{ "--banner-height": 0 }}
                >
                    <div className={"header-banner-div-hidden"} />
                    <div className="main-report-header">
                        <ReportHeader headerElements={headerElements} />
                    </div>
                    <div className="main_report">
                        <div id="main_report_container" className="main_report_container" style={{border: "none"}}>
                            <ModelingSettings ref={reportRef} userAllowedMenuLinks={userAllowedMenuLinks} periods={clientPeriodsState.allPeriods} machineName={props.userSettings.machineName} user={props.userSettings.user}
                                              clientId={props.userSettings.clientId} active_period_limit={props.userSettings.active_period_limit} fiscalPeriods={copyObjectValues(clientPeriodsState.fiscalPeriods)}
                                              fiscalOriginalPeriods={clientPeriodsState.fiscalOriginalPeriods}
                                              getClientPeriods={getPeriods} dispatch={dispatch} changingReport={props.changingReport}
                            />
                        </div>
                    </div>
                </div>
            </Suspense>
            <Modal
                id={"menu-save-first-dialog"}
                openDialog={openModal}
                closeClick={() => setSaveFirstDialogOpen(false)}
                bodyContent={() => messageDialogContent(lang.dashboards.messages.want_to_save_first)}
                dialogActions={saveFirstDialogActions}
                size={DIALOG_SIZE.MEDIUM}
            />
        </>
    );
};

export {ModelingSettingsWrapper};
