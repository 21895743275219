import React, { Component } from 'react';
import { PS_MAPPING, API_URL, UPLOAD_SECTIONS, ALL_WIDGETS, DEFINE_FIELDS, IS_TRANSITIONED, ROW_STATUS, GLACCOUNTS_FIELDS, RAW_ITEMS, ACCRUALS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE, DIALOG_SIZE } from '../../class/constants';
import { getTranslationFile, getSectionExists, copyObjectValues, findOptionByKeyValue} from '../../class/utils';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import '../../styles/dataModeling.css';
import ExceptionDriver from './ExceptionDriver';
import { FormComponent } from '../../form/FormElements';
import { CustomSelect } from "../../form/elements";
import Popup from 'react-popup';
import Button from '../../newComponents/Button';
import DropDown from '../../newComponents/DropDown';
import Modal from '../../newComponents/Modal';

const lang = getTranslationFile();
const $ = require('jquery');

const _empty = lang.ui_filter.dropdowns.engine_filter_functions.empty.value;
const _nempty = lang.ui_filter.dropdowns.engine_filter_functions.not_empty.value;
const _ct = lang.ui_filter.dropdowns.engine_filter_functions.contains.value;
const _nct = lang.ui_filter.dropdowns.engine_filter_functions.not_contains.value;
const _id = PS_MAPPING.FIELDS.PSS_ID;
const COST_CENTER_STATE = "PI_Cost_center_State";
const _name = PS_MAPPING.FIELDS.NAME;
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _costKey = PS_MAPPING.FIELDS.COST_KEY;
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _matchedSuffix = lang.pss_map_exception.suffixes.matched;
const _unMatchedSuffix = lang.pss_map_exception.suffixes.unmatched;
const ANCILLARY = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;


/**
 *  ExceptionDriver conatiner hold ExceptionDrives conainer and handles changes done  
 * @author [Sarah Farjallah]
 * @extends Component
 * **/

class ExceptionDriverContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter:""
        };
        this.inputRefs = {};
        this.fetchAPI = fetchAPI.bind(this);
    }

    componentDidMount() {
        let tempState = {};
        let _this = this;
        let option = {};
        let costKey = this.props.pslLine && this.props.pslLine.costKey ? this.props.pslLine.costKey  : "";
        let line = copyObjectValues(this.props.mappedLine);

        tempState.isVar = this.checkIfVarOrMat(this.props.pssFields, costKey);
        this.getRawFileFieldNames({ section: UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER });

        tempState.filter = this.props.pslLine.filter !== undefined ? this.props.pslLine.filter : "";
        tempState.ancillaryFilter = this.props.pslLine.ancillaryFilter !== undefined ? this.props.pslLine.ancillaryFilter : "";
        tempState.mapExceptionQuery = this.props.pslLine.stagingQuery !== undefined ? this.props.pslLine.stagingQuery : this.state.defaultStagingQuery;
        tempState.ancillaryFile = this.props.pslLine.fileName;
        tempState.line = line;
        if (this.props.pslLine.mappingException !== undefined && this.props.pslLine.mappingException !== "") {
            option = {value: this.props.pslLine.mappingException, label: this.props.pslLine.mappingException};
        }
        this.setState(tempState,function(){
            if (this.props.currentRow[PS_MAPPING.FIELDS.ACCRUAL_STATUS] !== ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                this.exclDriverRef.onChangeException(option, false);
                this.exclDriverRef.setAssignedAmount(this.props.calculatedAssignedAmountCount.sum, this.props.calculatedAssignedAmountCount.count);
    
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.mappedLine) !== JSON.stringify(this.props.mappedLine)) {
            this.setState({
                line: copyObjectValues(this.props.mappedLine)
            })
        }
    }

    /**
     * function checks whether pss line is matched or var 
     * @param {*} pssFields 
     * @param {*} costKey 
     * @returns 
     */
    checkIfVarOrMat(pssFields, costKey) {
        for (var e in pssFields) {
            if (pssFields[e][PS_MAPPING.FIELDS.NAME].toLowerCase() === 'net revenue' || pssFields[e][PS_MAPPING.FIELDS.NAME].toLowerCase() === 'cost of sales') {
               if(this.matchCostKey(pssFields[e][_children], costKey) === true ){
                   return true
                }else {
                    continue ;
                } 
            }
        }
        return false;
    }

    /**
     * function fetches raw file field names
     */
    getRawFileFieldNames=()=> {
        var _this = this;
        var query = {
            action: "getRawFileFieldNames",
            scenario_id: _this.props.scenario
        }
        let onThenCallback = (data) => {
            let fields = [];
            data.data.map(function(item) {
                if (item[IS_TRANSITIONED]) {
                    fields.push({ 
                        label: item[DEFINE_FIELDS.DATA_FIELD],
                        value: item[DEFINE_FIELDS.DATA_FIELD].replace(/ /g, "_"),
                        type: item[DEFINE_FIELDS.DATA_TYPE_FIELD]
                    });
                }
            });
            
            _this.setState({
                fields: fields
            });
        }  
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getRawFileFieldNames",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        this.fetchAPI(fetchOptions);
    }
    
    /**
     * function called from child component ExceptionDriver when fetching values for filter 
     * @param {*} subtypeId 
     * @param {*} fieldDataType 
     * @param {*} value 
     * @param {*} index 
     * @param {*} functionValue 
     * @param {*} rowIndex 
     * @param {*} inputValue 
     * @param {*} callback 
     * @param {*} type 
     */
    getColumnValues=(subtypeId, fieldDataType, value, index, functionValue, rowIndex, inputValue, callback, type)=> {
        let _this = this;
        var isDisabled = [_ct, _nct].indexOf(functionValue) > -1 ? true : false;
        let query = { 
            action: "getColumnValues",
            rawFileSubTypeId: subtypeId,
            field_dataType: fieldDataType,
            attribute: value,
            input_value : inputValue ? inputValue : "",
            type: type ? type : "",
            scenario_id: _this.props.scenario
        }
        let onThenCallback = (data) => {
            if(data.data !== null){
                var values=[];
                data.data.map(function(item){
                    if (![_nempty, _empty].includes(functionValue)) {
                        if (item.attribute !== "" && item.attribute !== undefined) {
                            values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                        }
                    } else {
                        values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                    }
                });
                callback(values);
            }
        }  
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getColumnValues",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        this.fetchAPI(fetchOptions);
    }
    /**
     * function coped from old code it calculates the amount 
     * @param {*} filter 
     * @param {*} fileType 
     * @param {*} field 
     * @param {*} callback 
     * @returns 
     */
    parseMapExceptionQuery=(filter, fileType, field, callback)=> {
        let _this = this;
        if (_this.state.line[PS_MAPPING.FIELDS.MAPPING_EXCEPTION] !== ANCILLARY) {
            return;
        }
        if(!this.props.fetchAmounts){
            let totalAmount = 0;
            callback(totalAmount);
            return;
        }
        var query = {
            action: "parseMapExceptionQuery",
            timePeriod: _this.props.getSelectedPeriods().join("','"),
            scenario_id: _this.props.scenario,
            fileType: fileType,
            fields: _this.props.allAncillaryColumns ? JSON.stringify(_this.props.allAncillaryColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === fileType)) : [],
            filter: filter && filter !== "" ? "{'filter':"+JSON.stringify(filter)+"}" : "",
            startDate: _this.props.periodDetails && _this.props.periodDetails[_this.props.getSelectedPeriods()[0]] && _this.props.periodDetails[_this.props.getSelectedPeriods()[0]]["start_date"] ? _this.props.periodDetails[_this.props.getSelectedPeriods()[0]]["start_date"] : "",
            endDate: _this.props.periodDetails && _this.props.periodDetails[_this.props.getSelectedPeriods()[0]] && _this.props.periodDetails[_this.props.getSelectedPeriods()[this.props.getSelectedPeriods().length - 1]]["end_date"] ? _this.props.periodDetails[_this.props.getSelectedPeriods()[0]]["end_date"] : "",
            rawFileFieldName: field,
        }
        let onThenCallback = (data) => {
            if (data.parsedAmount) {
                let totalAmount = 0;
                let amounts = data.parsedAmount;
                for(let e in amounts){
                    totalAmount+=parseFloat(amounts[e].amount);
                }
                callback(totalAmount);
            } else if(data.ERROR) {
                this.setInfoDialogOpen(true, "There's something wrong with your query.");
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "parseMapExceptionQuery",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.periods]:_this.props.getSelectedPeriods()?.length ? _this.props.getSelectedPeriods().join("','") : ""
        };
        this.fetchAPI(fetchOptions);
    }
    /**
     * function copied from old code it calculated the amount of metrics
     * @param {*} filter 
     * @param {*} fileType 
     * @param {*} field 
     * @param {*} callback 
     * @returns 
     */
    parseMetricAmount=(filter, fileType, field, metricId, callback)=> {
        let _this = this;
        if (_this.state.line[PS_MAPPING.FIELDS.MAPPING_EXCEPTION] !== ANCILLARY) {
            return;
        }
        if(!this.props.fetchAmounts){
            let parsedAmount = 0;
            callback(parsedAmount);
            return;
        }
        var query = {
            action: "parseMetricAmount",
            timePeriod: _this.props.getSelectedPeriods().join("','"),
            scenario_id: _this.props.scenario,
            fileType: fileType,
            filter: filter && filter !== "" ? "{'filter':"+JSON.stringify(filter)+"}" : "",
            rawFileFieldName: field,
            metricId:metricId
        }
        let onThenCallback = (data) => {
            if (data.parsedAmount) {
                callback(parseFloat(data.parsedAmount[0].amount));
            } else if(data.ERROR) {
                this.setInfoDialogOpen(true, "There's something wrong with your query.");
            } 
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "parseMetricAmount",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.periods]:_this.props.getSelectedPeriods()?.length ? _this.props.getSelectedPeriods().join("','") : ""
        };
        this.fetchAPI(fetchOptions);
    }
    /**
     * updated mapped Lined object key values based on type sent 
     * @param {*} e 
     * @param {*} type 
     * @param {*} reset 
     */
    updateMappedRow=(e, type, reset)=>{
        let tempState = {};
        let _this = this;
        let hasChanged = false;
        let mappedLine = _this.state.line;
        
        if (type === PS_MAPPING.FIELDS.DESCRIPTION) {
            mappedLine[PS_MAPPING.FIELDS.DESCRIPTION] = $(e.currentTarget).val();
        }
        if (type === PS_MAPPING.FIELDS.NAME) {
            mappedLine[PS_MAPPING.FIELDS.NAME] = $(e.currentTarget).val(); //! add validation on name
        }
        if (type === PS_MAPPING.FIELDS.MAPPING_EXCEPTION) {
            if (this.state.line[PS_MAPPING.FIELDS.MAPPING_EXCEPTION].includes(e)) {
                hasChanged = true;
            }
            // if(this.state.line[PS_MAPPING.FIELDS.LEADING_COSTKEY] && this.state.mappedLine[PS_MAPPING.FIELDS.LEADING_COSTKEY] !== this.state.line[PS_MAPPING.FIELDS.COST_KEY] && reset) {
            //     window._profitIsleOpenModal('combinationsChanges');
            // }
            mappedLine[PS_MAPPING.FIELDS.MAPPING_EXCEPTION] = e;
            tempState.changed = hasChanged;
            tempState.parsedAmount = null;
        }
        if (type === PS_MAPPING.FIELDS.COST_TERM_ID) {
            mappedLine[PS_MAPPING.FIELDS.COST_TERM_ID] = e.cost_term_id;
        }
        tempState.line = mappedLine;
        this.setState(tempState);
    }

    /**
     * function meant to be called from outside Component to chekck if name exists or not
     * @param {*} name 
     * @param {*} data 
     * @param {*} costKey 
     * @param {*} oldValue 
     * @returns 
     */
     checkExistingName=(name, data, costKey, oldValue)=> {
        for(var e in data) {
            if (data[e]){
                if (data[e][_name].toLowerCase().includes(_unMatchedSuffix) && data[e][_name].replace(_unMatchedSuffix,"")  === oldValue.replace(_matchedSuffix,"")) {
                    data[e][_name] = name.replace(_matchedSuffix,"") + _unMatchedSuffix;
                }
                if((data[e][_name] && data[e][_name].toLowerCase().replace(_matchedSuffix,"").replace(_unMatchedSuffix,"") === name.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '') && data[e][_costKey] !== costKey && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED)) {
                    return -1;
                } else if(data[e].name.toLowerCase().replace(/ /g,"" ).replace(_matchedSuffix,"") === name.toLowerCase().replace(/ /g,"" ) && data[e][_costKey] !== costKey && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
                    return 1;
                }else {
                    if(data[e][_children]) {
                        var res = this.checkExistingName(name, data[e][_children], costKey, oldValue);
                        if(res !== 1 && res !== -1) {
                            continue;
                        } else{
                            return res;
                        }
                    }
                }
            } 
        }
    }

    /**
     * copied from old code
     * @param {*} filter 
     */
    saveFilter=(filter)=> {
        this.setState({
            filter: "{\"filter\":"+ JSON.stringify(filter) +"}",
            showFilter: false,
            combFlag: false
        });
    }
    
    /**
     * function sets warning message to user on click of submit
     * @param {*} message 
     */
    setWarning=(message)=> {
        let _this = this;
        _this.setState({
            isNameValid: message
        })
    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }
  
    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }

    render() {
        let _this = this;
        let line = copyObjectValues(this.state.line);
        if(this.props.currentRow[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL){
            if(this.props.mappedLines){
                let mappedLines = this.props.mappedLines;
                let costTermIdActualArr = mappedLines.filter(e => Number(e[PS_MAPPING.FIELDS.PSS_ID]) === Number(this.props.currentRow[PS_MAPPING.FIELDS.ACTUAL_ID]))
                if(costTermIdActualArr.length){
                    line[PS_MAPPING.FIELDS.COST_TERM_ID] = Number(costTermIdActualArr[0][PS_MAPPING.FIELDS.COST_TERM_ID]);
                }
            }
        }
        return(<div>
                 <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                 <div className="uk-margin-medium-top">
                    <div className="uk-margin-xmedium-bottom">
                        <label htmlFor="Name" className="uk-text-xmedium">{lang.dashboards.titles.name}<sup className="red">*</sup></label>
                        <FormComponent tag="input" id={PS_MAPPING.FIELDS.NAME} ref={r => this.inputRefs[PS_MAPPING.NAME] = r} className="uk-input" placeholder={lang.psl_placeholders.standard_line_name}
                        onChange={(e)=>{this.updateMappedRow(e, PS_MAPPING.FIELDS.NAME)}} value={this.props.currentRow ? this.props.currentRow[PS_MAPPING.FIELDS.NAME] : ""}
                        />
                        {_this.props.isNameValid && _this.props.isNameValid !== "" ? <div id="calc-col-warn" className="fs-12 red italic">{_this.props.isNameValid}</div> : ""}
                    </div>
                    <div>
                        <label htmlFor="Description" className="uk-text-xmedium">{lang.manage_access.DESCRIPTION}</label>
                        <FormComponent tag="textarea" id={PS_MAPPING.FIELDS.DESCRIPTION} ref={r => this.inputRefs[PS_MAPPING.FIELDS.DESCRIPTION] = r} className="uk-textarea" placeholder={lang.standard_mapping_description_placeholder}
                        onChange={(e)=>{this.updateMappedRow(e, PS_MAPPING.FIELDS.DESCRIPTION)}} value={this.props.currentRow ? this.props.currentRow[PS_MAPPING.FIELDS.DESCRIPTION] : ""}
                        />
                    </div>
                    <hr className="uk-hr"></hr>
                    
                    <div className="uk-margin-xmedium-bottom">
                        <div className="fs-14 width-150 uk-display-inline-flex align-items-center uk-margin-xsmall-bottom">
                            {lang.pss_map_exception.titles.plan_term}
                            {/*<i className="fs-12 fal fa-info-circle uk-margin-small-right uk-margin-small-left" uk-tooltip={lang.profit_stack_plan_term_title } />*/}
                        </div>
                        <DropDown
                            id="select-plan-term"
                            className="width-150 input__dropdown"
                            name="plan-term-list"
                            placeholder="Choose a plan"
                            value={findOptionByKeyValue(this.props.clientCostTerms, "cost_term_id", (line ?  line[PS_MAPPING.FIELDS.COST_TERM_ID] ? line[PS_MAPPING.FIELDS.COST_TERM_ID] : "" : undefined))}
                            options={this.props.clientCostTerms}
                            onChange={(option)=>this.updateMappedRow(option, PS_MAPPING.FIELDS.COST_TERM_ID)}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                    </div>
                    
                </div>
                {this.props.currentRow[PS_MAPPING.FIELDS.ACCRUAL_STATUS] !== ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL ? 
                        <ExceptionDriver
                        ref={r => this.exclDriverRef = r}   
                        onChangeExceptionDriver={this.updateMappedRow}
                        onChangeCostTerm = {this.updateMappedRow}
                        onChangeAncillaryFile={this.updateMappedRow}
                        ancillaryFiles={this.props.ancillaryFiles}
                        ancillaryColumns={this.props.ancillaryColumns}
                        pssSiblings={this.props.pssSiblings || []}
                        exceptionPSLOptions={this.props.exceptionPSLOptions  || []}
                        leadingLine={this.props.pslLine}
                        mappedLine={this.props.mappedLine}
                        isVar={this.props.isVar}
                        scenario={this.props.scenario}
                        leadingPssId={this.props.leadingPssId}
                        leadingPssCostKey={this.props.leadingPssCostKey}
                        getColumnValues={this.getColumnValues}
                        allAncillaryColumns={this.props.allAncillaryColumns.concat(this.props.exceptionMetrics)}
                        parseAncillaryColumnAmount={this.parseMapExceptionQuery}
                        parseMetricAmount={this.parseMetricAmount}
                        clientCostTerms={this.props.clientCostTerms}
                        costTerm={this.props.costTerm}
                        filter={this.state.filter}
                        costCenter = {this.props.costCenter}
                        calculatedCols={this.props.calculatedCols}
                        exceptionMetrics={this.props.exceptionMetrics}
                        metricsInfo = {this.props.metricsInfo}
                        timePeriod={this.props.getSelectedPeriods()}
                        mappedLines={this.props.mappedLines}
                        calculatedAssignedAmountCount={this.props.calculatedAssignedAmountCount}
                        profitStackFields = {this.props.profitStackFields}
                        stagingReport = {this.props.stagingReport}
                        fetchAmounts = {this.props.fetchAmounts}
                    />
                :""}
                
            </div>
        );

    }

}

export default ExceptionDriverContainer;