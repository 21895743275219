// CustomChatBot.js
import React, { useEffect } from 'react';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";

function CustomChatBot(props) {
  // Define styles as constants
  const chatBotButtonStyles = {
    size: "60px",
    bgcolor: "#00bfa5",
    color: "#ffffff",
    bgcolorHover: "#008e76",
    borderColor: "transparent",
    borderWidth: "0px",
    borderStyle: "none",
    borderRadius: "50%",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const chainlitUrl = process.env.REACT_APP_CHATBOT_APP_BASE_URL;

  useEffect(() => {
    let cleanupFunc;

    const initializeChatBot = async () => {
      const token = await getAccessToken(baseUrl, chainlitUrl);
      cleanupFunc = loadCopilotWidget(chainlitUrl, token);
    };

    initializeChatBot();

    return () => {
      if (cleanupFunc) {
        cleanupFunc();
      }
    };
  }, []);
// Function to get sessionId
  const getSessionId = () => {
    return new Promise((resolve, reject) => {
      let query = { action: "getSessionId" };
      let onThenCallback = (data) => {
        if (data?.sessionId) {
          resolve(data.sessionId); // Resolve the promise with the sessionId
        } else {
          reject(new Error('Failed to get sessionId'));
        }
      };
      let fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getSessionId",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: "/get-session-id",
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      };
      fetchAPI(fetchOptions);
    });
  };

// Function to get chatbotVectorsInfo
  const getChatbotVectorsInfo = () => {
    return new Promise((resolve, reject) => {
      let query = { action: "getChatbotVectorsInfo" };
      let onThenCallback = (data) => {
        if (data?.chatbotVectorsInfo) {
          resolve(data.chatbotVectorsInfo); // Resolve the promise
        } else {
          reject(new Error('Failed to get chatbotVectorsInfo'));
        }
      };
      let fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getChatbotVectorsInfo",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: "/get-chat-bot-vectors-info",
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      };
      fetchAPI(fetchOptions);
    });
  };

// Function to fetch the access token from the /custom-auth endpoint
  const getAccessToken = async (baseUrl, chainlitUrl) => {
    try {
      // Fetch sessionId and chatbotVectorsInfo
      const sessionId = await getSessionId();
      const chatbotVectorsInfoArray = await getChatbotVectorsInfo();
      const chatbotVectorsInfo = { data: chatbotVectorsInfoArray };

      // Prepare the request body without user information
      const requestBody = {
        chatbotVectorsInfo: chatbotVectorsInfo,
        sessionId: sessionId,
        url: `${baseUrl}/chat-bot-get-vector`,
        authority: baseUrl,
        origin: window.location.origin,
        firstName: props.userSettings.user.first_name,
        userId: props.userSettings.user.userId
      };

      // Make the POST request to your custom-auth endpoint
      const response = await fetch(`${chainlitUrl}/custom-auth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',  // Ensure credentials are included if required
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (response.ok) {
        return data.token;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

// Function to load the Copilot script and initialize the widget
  const loadCopilotWidget = (chainlitUrl, token) => {
    // Load the Copilot script
    const script = document.createElement('script');
    script.src = `${chainlitUrl}/chainlit/copilot/index.js`;
    script.async = true;

    script.onload = () => {
      if (token) {
        // Initialize the Copilot widget with the token and styles
        window.mountChainlitWidget({
          chainlitServer: `${chainlitUrl}/chainlit`,
          accessToken: token,
          theme: "light",
          fontFamily: "Arial, sans-serif",
          button: {
            imageUrl: "https://profitisle.com/wp-content/uploads/2024/08/cropped-favicon-192x192.png",
            style: chatBotButtonStyles,
          },
        });
      }
    };

    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  };

  return null; // This component doesn't render anything visible
}

export default CustomChatBot;
