import React, { Component } from "react";
import cookie from 'react-cookies';
import GridLayout from 'react-grid-layout';
import Popup from 'react-popup';
import { connect } from "react-redux";
import shortid from "shortid";
import { findIndexOfValue, getEmbeddedChild, getEmbeddedChildren, getPathToEmbeddedChild, updateAllElements } from '../../class/array';
import {getMonthsFromQuarters, goToReport, updateWindowLoading} from "../../class/common";
import { ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, COLUMN_PROFILE, COST_FUNCTIONS_FIELDS, DASHBOARDS, DIALOG_SIZE, DROPDOWN_TYPE, FILTER, FY_VALUES, HEADER_ELEMENT, INVOICE_LINE, LOG_OBJ_KEYS, MANAGE_ACCESS, MANAGE_COLUMNS, MENU_ITEM, PROFILE_COLUMN, PSL_RETURN_NAMES, PSS, ROLLING_SEGMENTS, SEGMENTS, SIZES, TOAST, TOTAL_COMPANY, VECTOR_ANALYSIS, VECTOR_STAGING_ATTRIBUTES, column_suffixes_values, comparison_suffixes, costtype } from "../../class/constants";
import { getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getPeriodFromDate, getPeriodsObject, getPeriodsObjectNew, getQuarterFromDate } from '../../class/date';
import { convertPxToViewport } from "../../class/formatting";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI, getDataFromSession, logUITracking } from "../../class/networkUtils";
import { replaceSpecialChars } from "../../class/string";
import { copyObjectValues, findOptionByKey, findOptionByKeyValue, getSectionExists, getTranslationFile, parseBoolean, tryParse } from "../../class/utils";
import Container from "../../components/manageColumns/Container";
import ManageColumns from '../../components/manageColumns/ManageColumns';
import Backdrop from "../../components/sidePanelNew/Backdrop";
import SidePanelNew from "../../components/sidePanelNew/SidePanelNew";
import { SkeletonLoader, Tabs, ToggleTab } from "../../form/elements";
import { FormComponent } from '../../form/FormElements';
import Button from "../../newComponents/Button";
import { default as DropDown, default as Dropdown } from "../../newComponents/DropDown";
import Modal from "../../newComponents/Modal";
import '../../styles/dashboard.css';
import { ReactComponent as MultipleFilters } from "../../styles/images/icons/multiplefilters.svg";
import { getValidPeriods } from "../../templateLayout/functions/periodFunctions";
import FilterDialog from '../filter/FilterDialog';
import { loadBridgeOptions, loadSecondaryReports } from "../profitStack/helpers";
import CategoryWidgets from "./CategoryWidgets";
import Widget from './Widget';
import { Segment } from "../../components/Segment";
// import { updateUserSettings } from '../../actions';
const Inflector = require('inflected');
const _filterModal = "filterModal_1";
const _filterMessageModal = "filterMessageModal_1";
/**
 * dashboards main screen
 * @author [Mostafa Haydar]
 */
const $  = require('jquery');
const UIkit = require('uikit');
const lang = getTranslationFile();
const _cc = "_cc";
const _cpcf = "_cpcf";
const widgetWidthProportional = true; //it true, widget size will be proportional by screen else it will be fixed by pixels
const _treeChildren = "children";       //this is the "children" key understood by react-tree-select
const _checked = "checked";             //this is the flag sent by the library that indicates whether or not the selectedNode is selected or deleted
const _expanded = "expanded";
const _widgetId = DASHBOARDS.WIDGET.ID;
const _charts = "_charts";
const _percentages="percentages";
const _attributes="attributes";
const _averages="averages"; 
const _medians= "medians";
const _category = "category";
const _bestPractice = "BestPractice";
const _allNotGenerated ="ALL_NOT_GENERATED";

const _selectedVector = MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME;
const _vector = MANAGE_COLUMNS.VECTOR_MACHINE_NAME;
const _SelectedVectorLabel = MANAGE_COLUMNS.SELECTED_VECTOR_DISPLAY_NAME;
const _visibilityOptions = COLUMN_PROFILE.VISIBILITY_OPTIONS;
const _visibility = DASHBOARDS.VISIBILITY;
const _isCompanyDefault = DASHBOARDS.IS_COMPANY_DEFAULT;
const _isSystem = MANAGE_ACCESS.FIELDS.IS_SYSTEM;


// const observer = new PerformanceObserver((list) => {
//     const entries = list.getEntries();
    
//     entries.forEach((entry) => {
//       // filter out only the network requests
//       if (entry.initiatorType === 'xmlhttprequest' || entry.initiatorType === 'fetch') {
//         console.log('Request URL:', entry.name);
//         // console.log('Request Type:', entry.initiatorType);
//         // console.log('Request Duration:', entry.duration);
//         // console.log('--------------------------------');
//       }
//     });
//   });
  
//   // Observe the 'resource' event type
//   observer.observe({ entryTypes: ['resource'] });

class Dashboards extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            headerElements: [HEADER_ELEMENT.SELECTION_RANGE, HEADER_ELEMENT.GO, HEADER_ELEMENT.ADD_DASHBOARDS],
            currentWidget:{},
            reportTitle: ALL_WIDGETS.TITLES.COMPANY_DEFAULT,
            profitFormatManageColumns: ALL_WIDGETS.FIELDS.LIST,
            activeTab: lang.CATEGORIES.titles[0],
            messages:{},
            sizesOptions:[],
            dashBoardWidgets:[],
            selectedDashboard: props.selectedDashboard,
            isEditing: false,
            compOptions: lang.widget.options.comparison,
            orderModified: false,
            profitStackTypes: lang.widget.options.mom_period_range,
            widgetRefs:[],
            nextMixVector: props?.vectorState?.originalMixVector,
            areWidgetsLoading: false,
            drawerOpen: false,
            dashboardFormats: [{label:"List", value:"List", name:"List"}],
            key: shortid.generate()
        }   
        this.state = Object.assign({}, this.state, this.initialState);
        this.isMainReport = { isDashboards: true }
        
        this.goToReport = goToReport.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.fetchWidgets = this.fetchWidgets.bind(this);
        // this.onToggleBoard = this.onToggleBoard.bind(this);
        this.addWidget = this.addWidget.bind(this);
        this.updateWidgetFields = this.updateWidgetFields.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.startSaveWidget = this.startSaveWidget.bind(this);
        this.saveWidget = this.saveWidget.bind(this);
        this.validateWidgetFields = this.validateWidgetFields.bind(this);
        this.isNameUnique = this.isNameUnique.bind(this);
        this.launchToast = this.launchToast.bind(this);
        this.resetInputs = this.resetInputs.bind(this);
        this.startCancel = this.startCancel.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.startCancelWidget = this.startCancelWidget.bind(this);
        this.deleteWidget = this.deleteWidget.bind(this);
        this.confirmDeleteWidget = this.confirmDeleteWidget.bind(this);
        this.saveWidgetsWithNewOrder = this.saveWidgetsWithNewOrder.bind(this);
        this.updateStateFromParent = this.updateStateFromParent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.inputRefs = {};
        this.loadSecondaryReports = loadSecondaryReports.bind(this);
        this.loadBridgeOptions= loadBridgeOptions.bind(this);
        this.listsToFetch=[];
        this.getPeriodsObject = getPeriodsObject.bind(this);
        this.observer = null;
        this.requestsInBackground = [];

        this.comparisonOptions = copyObjectValues(lang.header.options.comparison).map(function(item){item.description = item.description?.replaceAll(SEGMENTS.PEAK.label,new Segment().getSegmentObject(SEGMENTS.PEAK.label)?.label); return item});
    }
    /** Changes drawer state on click */
    drawerToggleClickHandler = () => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        },()=>{
            this.setAddWidgetdDialogOpen(!this.state.drawerOpen)
        })
    }
    /** close drawer */
    backdropClickHandler = (fromSave) => {
        let isEditing = this.state.isEditing;
        this.setState({
            drawerOpen: false
        },()=>{
            if(!isEditing && fromSave !== true){
                this.setAddWidgetdDialogOpen(true)
            }
        })
    }

    changeKey = () => {
        let _this = this;
        // setTimeout=(()=>{
            _this.setState({
                key: Date.now()
            })
        // },300)
        
    }

    getProfitStackHierarchy = (stack, originalWidget, key, isEdit) => {
        let _this = this;
        var query = {
            action: "getCostHierarchyByStack",
            scenario_id: this.props.scenarioState.scenario,
            view_id: stack || 0
        }
        let tempState = {};
        var onThenCallback = (data)=>{
            if (data) {
                let pslOptions = _this.formatPslLines(data.firstLevelPsl);
                let pslOptionsAttrs = _this.formatPslLines(data.attributes);
                _this.setState({
                    stackPSLLines: !stack || stack === 0  ? pslOptions.concat(pslOptionsAttrs) : pslOptions
                },()=>{
                    if (isEdit) {
                        _this.onEditWidget(originalWidget, key, pslOptions)
                    }
                })
            }
        }

        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getProfitStackHierarchy",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: isEdit,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_STACK,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        }
        fetchAPI(fetchOptions);
    }

    formatPslLines = (pslLines) => {
        let _this = this;
        for (var e in pslLines) {
            pslLines[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME] = pslLines[e][PSS.RETURN_NAME];
            pslLines[e][MANAGE_COLUMNS.COLUMN_NAME] = pslLines[e][PSS.NAME];
            pslLines[e].label = pslLines[e][PSS.NAME];
            pslLines[e].value = pslLines[e][PSS.RETURN_NAME];
            if (pslLines[e].children && pslLines[e].children.length > 0) {
                _this.formatPslLines(pslLines[e].children);
            }
        }
        return pslLines;
    }

    componentDidMount(){ 
        let _this = this;
        _this.getProfitStackHierarchy()
        var hasMOMAccess = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_MOM);
        if(!hasMOMAccess) {
          _this.setState({ profitStackTypes: lang.widget.options.period_range })
        }

        _this.init();
        $(window).on("resize", () => {
            _this.setState({
                resizedWindow: true // this state is set to trigger the render function so that it will rerender when we resize the screen
            })
        })
        
    }


    onChange = (currentNode, selectedNodes) => {
        
        if(currentNode[DASHBOARDS.WIDGET.CATEGORY]===DASHBOARDS.WIDGET.ATTRIBUTES){
            this.setState({
                selectedProfitStackLine:""
            });
            return;
        }
        let messages = this.state.messages;
        if (selectedNodes.length > 10) {
            messages[DASHBOARDS.WIDGET.PS_LINE_LIMIT] = lang.dashboards.messages.ps_line_limit;
            this.setState({
                messages:messages
            })
        } else {
            let currentWidget  = this.state.currentWidget;
            let psLines = [];
            psLines = copyObjectValues(this.state.stackPSLLines);
                

            psLines = updateAllElements(psLines, _treeChildren, _checked, false);
            let selectedLine;
            if(currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] !== DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS) {
                selectedLine = getEmbeddedChild(psLines, _treeChildren, PROFILE_COLUMN.RETURN_NAME, currentNode[PROFILE_COLUMN.RETURN_NAME]);
                selectedLine[_checked] = currentNode[_checked];
                currentWidget[DASHBOARDS.PROFIT_STACK_LINE] = selectedNodes.length > 0 ? selectedNodes[0] : selectedLine[_checked] ? selectedLine : {};
            } else {
                //the following key is either the ps line return name when selecting default PS view, or group name
                let tempKey =  PROFILE_COLUMN.RETURN_NAME;
                let selectedNodeIdentifiers = selectedNodes.map(function(item){return item[tempKey]});

                selectedLine = getEmbeddedChildren(psLines, _treeChildren, tempKey, selectedNodeIdentifiers);
                selectedLine.map(function(item, index){return item[_checked] = selectedNodes[index][_checked];});
                currentWidget[DASHBOARDS.PROFIT_STACK_LINE] = selectedNodes;
            }
            
            if(selectedNodes.length > 0 && currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] !== DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS && selectedLine && selectedLine['path']){
                messages[DASHBOARDS.PROFIT_STACK_LINE] = "";
                let revenueCostKey = psLines.filter((e) => e[PROFILE_COLUMN.RETURN_NAME] === PSL_RETURN_NAMES.NET_REVENUE)[0]?.id;
                let costType = selectedLine[COST_FUNCTIONS_FIELDS.COST_TYPE] || selectedLine.costtype;
                let selectedPSLPath = selectedLine['path'].split(",");     
                currentWidget[DASHBOARDS.CHANGE_COLOR_LOGIC] = (selectedPSLPath[0] == revenueCostKey || [costtype.calculated,costtype.attribute,costtype.count].includes(costType)) ? lang.arrow.green_up.value :lang.arrow.red_up.value
            } else if(selectedNodes.length > 0 && currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS){
                messages[DASHBOARDS.PROFIT_STACK_LINE] = "";
            }
            let isMultiSelectionAllowed = this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS && this.state.currentWidget[DASHBOARDS.PROFIT_STACKS_TYPE] === this.state.profitStackTypes[this.state.profitStackTypes.length-1].value;
            messages[DASHBOARDS.WIDGET.PS_LINE_LIMIT]= "";
            this.setState({
                selectedNodes:selectedNodes,
                stackPSLLines: psLines,
                profitStackLines: psLines,
                currentWidget: currentWidget,
                messages: messages,
                showDropdown: isMultiSelectionAllowed ? 'always' : undefined
            });
        }
    };

    onChangeColumn  = (currentNode, selectedNodes) => {
        let messages = this.state.messages;
        let currentWidget  = this.state.currentWidget;
        let columns = updateAllElements(copyObjectValues(this.state.columns), _treeChildren, _checked, false);
        let selectedLine = getEmbeddedChild(columns, _treeChildren, PROFILE_COLUMN.RETURN_NAME, currentNode[PROFILE_COLUMN.RETURN_NAME]);
        selectedLine[_checked] = currentNode[_checked];
        currentWidget[DASHBOARDS.COLUMN] = selectedNodes.length > 0 ? selectedNodes[0] : selectedLine[_checked] ? selectedLine : {};
        if(selectedNodes.length > 0){
            if(selectedLine[PROFILE_COLUMN.LINE_TYPE] === MANAGE_COLUMNS.VALUES[0]){
                currentWidget[DASHBOARDS.COUNT_TYPE] = lang.total.entity_count.value;
            } else if (selectedLine[PROFILE_COLUMN.LINE_TYPE] === MANAGE_COLUMNS.VALUES[1]){
                currentWidget[DASHBOARDS.COUNT_TYPE] = lang.other_per_vector.average.value;
            } else {
                currentWidget[DASHBOARDS.COUNT_TYPE] = lang.other_per_line_attribute.average.value;
            }
            messages[DASHBOARDS.COLUMN] = "";
               
        }
        this.setState({
            selectedColumn: selectedLine[_checked] ? selectedLine : {},
            columns: columns,
            currentWidget:currentWidget,
            messages:messages
        });
    }

    onNodeToggle = (currentNode) => {
        var _this = this;
        let psLines = this.state.profitStackLines;
        let selectedLine = getEmbeddedChild(psLines, _treeChildren, PROFILE_COLUMN.RETURN_NAME, currentNode[PROFILE_COLUMN.RETURN_NAME]);
        selectedLine[_expanded] = currentNode[_expanded];
        if(!currentNode[_expanded]){
            psLines = this.updateChildren(psLines,false);
        }
        
        _this.setState({
            profitStackLines:psLines,
            showDropdown:'always'
        });
    }

    updateChildren(psLines,expanded ){
        for(var e in psLines){
            var element = psLines[e];
            if(!!element[_treeChildren]){
                element[_expanded] = expanded;
                this.updateChildren(element[_treeChildren],false)
            }
            
        }
        return psLines;
    }

    updateCountTypeTogglesOptions(){
        let options = [];
        let currentWidget  = this.state.currentWidget;
        let widgetType = currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE];
        if(widgetType === DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC){
            options = [lang.count.entity_count, lang.count.count_per_total];
        } else if(widgetType === DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC || widgetType === DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE){
            options = [lang.sum.entity_count, lang.sum.count_per_total];
        } else if(widgetType === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC){
            if(currentWidget[DASHBOARDS.CONFIG_FIELD.COLUMN] && currentWidget[DASHBOARDS.CONFIG_FIELD.COLUMN][PROFILE_COLUMN.LINE_TYPE]){
                let line_type = currentWidget[DASHBOARDS.CONFIG_FIELD.COLUMN][PROFILE_COLUMN.LINE_TYPE];
                if(line_type === MANAGE_COLUMNS.VALUES[0]){
                    options = [lang.total.entity_count, lang.total.count_per_total];
                } else if(line_type === MANAGE_COLUMNS.VALUES[1]){
                    options = [lang.other_per_vector.average, lang.other_per_vector.median,lang.other_per_vector.range,lang.other_per_vector.minimum,lang.other_per_vector.maximum];
                } else {
                    options = [lang.other_per_line_attribute.average];
                }
            }
        }
        return options;
    }

    setLoader = (loading, widgets, name, id)=> {
        let _this = this;
        let dashboardWidgets =  widgets || this.state.dashBoardWidgets;
        for (var e in dashboardWidgets) {
            if(dashboardWidgets[e][DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC && dashboardWidgets[e][DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]){
                if (id) {
                    if(id === dashboardWidgets[e][DASHBOARDS.WIDGET.ID] && dashboardWidgets[e][DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]) {
                        dashboardWidgets[e].loading=loading;
                    }
                }
                if (name && !id){
                    if((name ===  dashboardWidgets[e][DASHBOARDS.NAME]) && dashboardWidgets[e][DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]) {
                        dashboardWidgets[e].loading=loading;
                    }
                } else{
                    dashboardWidgets[e].loading=loading;
                }
            }
        }
        return dashboardWidgets;
    }

    hideDashboardDropDown = (key)=> {
        var element = document.getElementById("widget_icons_dropdown_"+key);
        UIkit.dropdown(element).hide();
        // $("#"+widget_icons_dropdown).hide();
    }

    componentDidUpdate(prevProps, prevState) {
        let _this = this;
        if (_this.props.isMenuOpen !== prevProps.isMenuOpen) {
            setTimeout(() => {
                _this.changeKey();
            }, 800);
        }
        if (prevProps.selectedDashboard[DASHBOARDS.ID] && this.props.selectedDashboard[DASHBOARDS.ID] && prevProps.selectedDashboard[DASHBOARDS.ID] !== this.props.selectedDashboard[DASHBOARDS.ID]) {
            this.setState({
                dashBoardWidgets: _this.setLoader(true)
            }, function () {
                _this.fetchMetricWidgetsData(_this.state.dashBoardWidgets.filter(w => (w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE
                    || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC) && w[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]));
            })
        }

        if(this.state.areWidgetsLoading !== prevState.areWidgetsLoading) {
          this.checkLoadingWidgets()
        }

    }


    init() {
        this.fetchWidgets();
    }

   /**
    * function fetches all categories, list of size of each category section and list of widgets with their dashboardsand sets them in state 
    */
    fetchWidgets(resetCloned=false){
        const _this = this;
        const query = {
            action: "fetchWidgets"  
        };
        let onThenCallback = (data)=>{
            if (data && data.data) {
                _this.setState({
                    allWidgets: data.data,
                    sizes: data.sizes,
                    cloned: resetCloned ? false : _this.state.cloned,
                    dashBoardWidgets: data.dashBoardWidgets,
                    categories: data.categories.map(function(item) {
                        return item[DASHBOARDS.CATEGORY.ORIGINAL_DISPLAY_NAME];
                    }),
                    categoryWidgets: findOptionByKeyValue(data.data, DASHBOARDS.CATEGORY.MACHINE_NAME, lang.CATEGORIES.values[0])
                }, ()=>{
                    _this.fetchMetricWidgetsData(data.dashBoardWidgets.filter(w=>(w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE
                        || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC) && w[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]));
                })
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchWidgets",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: resetCloned,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    /**
     * gets the valid period from tcustmDate
     * @returns
     */
    // getValidPeriods = () => {
    //     let startDate = this.props.periodsStatusState.customStartDate;
    //     let endDate = this.props.periodsStatusState.customEndDate;

    //     // checks if the customDate has a built period or not
    //     if (this.props.periodsStatusState?.customStartDate && this.props.clientPeriodsState?.periods && this.props.periodsStatusState.builtPeriods) {
    //         startDate = this.headerRef.getDateFromPeriod(this.props.periodsStatusState?.customStartDate, this.props.clientPeriodsState?.periods);
    //         endDate = this.headerRef.getDateFromPeriod(this.props.periodsStatusState?.customEndDate, this.props.clientPeriodsState?.periods);
    //         startDate = startDate.start_date ? new Date(startDate.start_date) : startDate;
    //         endDate = endDate.end_date ? new Date(endDate.end_date) : endDate;
    //         let isStartPeriodAvailable = this.headerRef.isPeriodBuilt(getPeriodFromDate(startDate));
    //         let isEndPeriodAvailable = this.headerRef.isPeriodBuilt(getPeriodFromDate(endDate));

    //         // if the period is available, no need to put it in a state
    //         if (!isStartPeriodAvailable && !isEndPeriodAvailable) {
    //             startDate = this.headerRef.checkPeriodAvailability(startDate);
    //             endDate = this.headerRef.checkPeriodAvailability(endDate);
    //             startDate = typeof startDate !== 'string' ? startDate : this.props.clientPeriodsState.periods?.find(f => f.label === startDate) ? new Date(this.props.clientPeriodsState?.periods.find(f => f.label === startDate).start_date) : this.props.periodsStatusState.customStartDate; // if startDate is a period -> find its start_date
    //             endDate = typeof endDate !== 'string' ? endDate : this.props.clientPeriodsState?.periods.find(f => f.label === endDate) ? new Date(this.props.clientPeriodsState?.periods.find(f => f.label === endDate).end_date) : this.props.periodsStatusState.customEndDate;
    //         }

    //     }

    //     return { startDate: startDate, endDate: endDate }
    // }
    fetchMetricWidgetsData(metricWidgets) {
        const _this = this;
        if (!this.props.periodsStatusState.customStartDate) {
            return;
        }
        
        let periodsObject = getPeriodsObjectNew(_this.props.periodsStatusState, _this.props.clientPeriodsState);
        let metricsConfig = {};
        var type = "";
        metricWidgets.forEach(wid=>{
            let concatenatedFilter = wid[DASHBOARDS.FILTER]?copyObjectValues(tryParse(wid[DASHBOARDS.FILTER])):"";
            let dashboardFilterObj = "";
            let dashBoardFilter = tryParse(_this.props.filterFinal);
            if(_this.props.filterFinal && dashBoardFilter.length>0){
                if(concatenatedFilter.filter.length>0){
                    concatenatedFilter.filter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
                    concatenatedFilter.filter[concatenatedFilter.filter.length -1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
                }
                dashBoardFilter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
                dashBoardFilter[0].logical_operator = "AND";
                dashBoardFilter[dashBoardFilter.length-1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
                dashBoardFilter.forEach(e=>{
                    dashboardFilterObj = e;
                    dashboardFilterObj.logical_operator = e.logical_operator || "AND";
                    concatenatedFilter.filter.push(dashboardFilterObj);
                })
                concatenatedFilter = JSON.stringify(concatenatedFilter);
            }

            metricsConfig[wid[_widgetId]] = {[DASHBOARDS.WIDGET.CONFIGURATION]: wid[DASHBOARDS.WIDGET.CONFIGURATION], [DASHBOARDS.FILTER]: dashboardFilterObj? concatenatedFilter :wid[DASHBOARDS.FILTER]
                ,[DASHBOARDS.WIDGET.WIDGET_TYPE]: wid[DASHBOARDS.WIDGET.WIDGET_TYPE]};
        });
        if(Object.keys(metricsConfig).length == 0) {
            return;
        }

        this.setState({
            metricWidgetsData: undefined    //by emptying this state, we are setting all metric widgets data as obsolete and showing loader until new data is fetched
        })

        const query = {
            action: "fetchMetricWidgetsData",
            metricsConfig: JSON.stringify(metricsConfig),
            quarter: periodsObject.quarter,
            quarterPre: periodsObject.preQuarter,
            months: periodsObject.months,
            periods: periodsObject.periods,
            periods_yoy:periodsObject.periods_yoy,
            periods_pa:periodsObject.periods_pa,
            scenario_id: this.props.scenarioState.scenario,
            dashboardId: this.props.selectedDashboard[DASHBOARDS.ID],
            rollingPeriod: periodsObject.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            filter: JSON.stringify({ filter: tryParse(_this.props.filterFinal) }),
        };

        const onThenCallback = (data) => {
            if(data.data) {
                if(data.dashboardId !== _this.props.selectedDashboard[DASHBOARDS.ID]){
                    return;
                }
                Object.keys(metricsConfig).forEach(widId=>metricsConfig[widId].data = [])
                _this.setState({
                    // dashBoardWidgets: _this.setLoader(false),
                    metricWidgetsData: data.data,
                    fetching:false
                },()=>{
                    _this.state.widgetRefs.forEach(function(item,key){
                        // set isLoading to false for all metrics widgets
                        if (_this.state.widgetRefs[key] && _this.state.widgetRefs[key].current && _this.state.widgetRefs[key].current.props.widget.widget_type === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC) {
                            _this.state.widgetRefs[key].current.setIsLoading(false)
                        }
                    })
                });
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchMetricWidgetsData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    changeCategory(tab){
        this.setState({
            activeTab: tab,
            categoryWidgets: findOptionByKeyValue(this.state.allWidgets, DASHBOARDS.CATEGORY.DISPLAY_NAME, tab)
        });
    }

    /**
     * called from add widget in addwidget modal to configure the widget selected 
     * @param {event}
     */
    addWidget(evt){
        let _this = this;
        _this.setAddWidgetdDialogOpen(false);

        let widgetType = $(evt.currentTarget).attr('widgetType');
        if (widgetType === DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC) {
            _this.getProfitStackHierarchy();
        }
        let currentDashBoardId = this.props.selectedDashboard[DASHBOARDS.ID];
        let currentDashboardWidgets = this.state.dashBoardWidgets.filter(e=>e[DASHBOARDS.ID] === currentDashBoardId);

        let categoryMachineName = $(evt.currentTarget).attr(DASHBOARDS.CATEGORY.MACHINE_NAME);
        let widgetCategorySectionId = Number($(evt.currentTarget).attr(DASHBOARDS.WIDGET.SECTION_ID));
        let sizes = _this.state.sizes.filter(e=>e[DASHBOARDS.MACHINE_NAME] === categoryMachineName);
        let defaultSize = sizes.filter(e=>e[DASHBOARDS.IS_DEFAULT] === true)[0];
        let currentWidget = {
            [DASHBOARDS.ID]: _this.props.selectedDashboard[DASHBOARDS.ID],
            [DASHBOARDS.WIDGET.SECTION_ID]: widgetCategorySectionId,
            [DASHBOARDS.ORDER]: lang.LIST_ORDER.TOP.value,
            [DASHBOARDS.COUNT_TYPE]: widgetType === DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE ? lang.sum.entity_count.value : lang.count.entity_count.value,
            [DASHBOARDS.COMPARISON]: widgetType === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT ? lang.widget.options.comparison[1].value : widgetType === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS ? "": lang.widget.options.comparison[0].value,
            [DASHBOARDS.PROFIT_STACKS_TYPE]: _this.state.profitStackTypes[1].value,
            [DASHBOARDS.CUSTOM_VIEW]: this.props.PSViewsState?.profitStackViews[0]?.value,
            [DASHBOARDS.SIZE]: defaultSize ? defaultSize[DASHBOARDS.WIDGET.SIZE_ID] : sizes[0][DASHBOARDS.WIDGET.SIZE_ID],
            [DASHBOARDS.WIDGET.WIDGET_TYPE]: widgetType,
            [DASHBOARDS.WIDGET.WIDGET_TITLE]: lang.dashboards.configure_title + $(evt.currentTarget).attr('category') + " - " + $(evt.currentTarget).attr('name'),
            [COLUMN_PROFILE.ID]: this.state.currentWidget[COLUMN_PROFILE.ID],
            [COLUMN_PROFILE.PROFILE]: this.state.currentWidget[COLUMN_PROFILE.PROFILE],
            [COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS]: this.state.currentWidget[COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS],
            [DASHBOARDS.WIDGET.DISPLAY_ORDER]: currentDashboardWidgets.length,
            [DASHBOARDS.CHANGE_COLOR_LOGIC]:lang.arrow.green_up.value,
            [DASHBOARDS.BRIDGE]:this.state.nextBridgeObj,
            [DASHBOARDS.VECTOR]: widgetType === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT ? this.state.nextMixVector : ""

        } 

        let psLines = updateAllElements(copyObjectValues(this.state.stackPSLLines), _treeChildren, _checked, false);
        let columns = updateAllElements(copyObjectValues(this.state.columns), _treeChildren, _checked, false);
        _this.setState({
            profitFormatManageColumns: currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS? MENU_ITEM.FIELDS.EROSION_AND_GROWTH :ALL_WIDGETS.FIELDS.LIST,
            compOptions:widgetType=== DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT? this.comparisonOptions.slice(1,3) : widgetType === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS ? this.comparisonOptions : lang.widget.options.comparison,
            currentWidget: currentWidget,
            sizesOptions: sizes.map(function(item){
                return {value: item[DASHBOARDS.WIDGET.SIZE_ID], label:item[DASHBOARDS.ORDER], isDefault:item[DASHBOARDS.IS_DEFAULT]}
            }),
            categoryWidgets: findOptionByKeyValue(this.state.allWidgets, DASHBOARDS.CATEGORY.MACHINE_NAME, lang.CATEGORIES.values[0]),
            activeTab: lang.CATEGORIES.titles[0],
            profitStackLines:psLines,
            stackPSLLines: psLines,
            columns:columns,
            selectedNodes:[],
        },function(){
            _this.filerWidgetRef?.clearFilter();
        })
        this.drawerToggleClickHandler();
    }

    callOnEditwidget = (originalWidget, key) =>{
        let _this = this;
        _this.onToggleBoard();
        let widget_configuration = JSON.parse(originalWidget[DASHBOARDS.WIDGET.CONFIGURATION]);
        if (originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC 
            || (originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS && widget_configuration[DASHBOARDS.PROFIT_STACK_LINE])) {
            _this.getProfitStackHierarchy(widget_configuration[DASHBOARDS.CUSTOM_VIEW], originalWidget, key , true);      
        } else {
            _this.onEditWidget(originalWidget, key);
        }
    }

    onEditWidget = (originalWidget, key, pslOptions) => {
        let _this = this;
        //TODO make a switch case in case we added another type ,switch(originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]) { case ALL_WIDGETS.FIELDS.LIST: {
        let widget_state = this.prepareWidgetForRender(originalWidget);
        let sizes = widget_state.sizes;
        let psLines = [];
        let columns = [];
        let profile_id = widget_state.profile && widget_state.profile.id? widget_state.profile.id: widget_state.profile_id;
        let profile = widget_state.profile;
        let widget = widget_state.widget;
        let widget_configuration = JSON.parse(widget[DASHBOARDS.WIDGET.CONFIGURATION]);
        if(widget[DASHBOARDS.CUSTOM_VIEW] === "" || widget[DASHBOARDS.CUSTOM_VIEW] === undefined){
            widget[DASHBOARDS.CUSTOM_VIEW] = this.props.PSViewsState?.profitStackViews[0]?.value;
        }
        if(originalWidget.widget_type === DASHBOARDS.CONFIG_FIELD.BRIDGE){
            widget[DASHBOARDS.CONFIG_FIELD.BRIDGE] = this.props.bridgeOptionsState?.bridgeOptions?.filter(e=>e.value === originalWidget[DASHBOARDS.CONFIG_FIELD.BRIDGE].value)[0];
        }
        if(originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS && widget_configuration[DASHBOARDS.PROFIT_STACK_LINE]){
            psLines = updateAllElements(copyObjectValues(_this.state.stackPSLLines || pslOptions), _treeChildren, _checked, false);
            let tempKey = PROFILE_COLUMN.RETURN_NAME;
            let selectedLines = getEmbeddedChildren(psLines, _treeChildren, tempKey,  widget_configuration[DASHBOARDS.PROFIT_STACK_LINE].map(function(item){return item.value}));
            if(selectedLines){
                selectedLines.map(function(item){
                    item[_checked] = true;
                });
                widget[DASHBOARDS.PROFIT_STACK_LINE] = selectedLines;
            }
        }
        else if(widget_configuration[DASHBOARDS.PROFIT_STACK_LINE]){
            let profit_stack_line_return_name = widget_configuration[DASHBOARDS.PROFIT_STACK_LINE][PROFILE_COLUMN.RETURN_NAME];
            psLines = updateAllElements(copyObjectValues(_this.state.stackPSLLines), _treeChildren, _checked, false);
            let selectedLine = getEmbeddedChild(psLines, _treeChildren, PROFILE_COLUMN.RETURN_NAME, profit_stack_line_return_name);
            if(selectedLine){
                selectedLine[_checked] = true;
                widget[DASHBOARDS.PROFIT_STACK_LINE] = selectedLine;
            }
        } 
        else if(widget_configuration[DASHBOARDS.COLUMN]){
            let column_return_name = widget_configuration[DASHBOARDS.COLUMN][PROFILE_COLUMN.RETURN_NAME];
            columns = updateAllElements(copyObjectValues(_this.state.columns), _treeChildren, _checked, false);
            let selectedLine = getEmbeddedChild(columns, _treeChildren, PROFILE_COLUMN.RETURN_NAME, column_return_name);
            if(selectedLine){
                selectedLine[_checked] = true;
                widget[DASHBOARDS.COLUMN] = selectedLine;
            }
        } 
         if(psLines.length === 0){
            psLines = updateAllElements(copyObjectValues(_this.state.stackPSLLines), _treeChildren, _checked, false);
        } 
        if (columns.length === 0){
            columns = updateAllElements(copyObjectValues(_this.state.columns), _treeChildren, _checked, false);
        }
    
        this.setState({
            profile: originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS? profile : _this.state.profile,
            profitFormatManageColumns: originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS? MENU_ITEM.FIELDS.EROSION_AND_GROWTH :ALL_WIDGETS.FIELDS.LIST,
            compOptions: originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS ? (this.comparisonOptions) :  originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT? this.comparisonOptions.slice(1,3) : lang.widget.options.comparison,
            isEditing: true,
            currentWidget: widget,
            sizesOptions: sizes.map(function(item){
                return {value: item[DASHBOARDS.WIDGET.SIZE_ID], label:item[DASHBOARDS.ORDER], isDefault:item[DASHBOARDS.IS_DEFAULT]}
            }),
            profitStackLines: psLines,
            stackPSLLines: psLines,
            columns:columns       
        }, function(){
            _this.hideDashboardDropDown(key);
            if (widget[DASHBOARDS.FILTER] && tryParse(widget[DASHBOARDS.FILTER]).filter.length > 0) {
                this.filerWidgetRef.onChangeSavedFilter(JSON.stringify(JSON.parse(widget[DASHBOARDS.FILTER]).filter), true);
            }else if(widget[DASHBOARDS.FILTER] && tryParse(widget[DASHBOARDS.FILTER]).filter.length === 0 && _this.filerWidgetRef?.state?.outsideFilter?.length ===0 && _this.filerWidgetRef?.state?.initialFilter?.length>0){
                this.filerWidgetRef.clearFilter();
            }
            if(originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === ALL_WIDGETS.FIELDS.LIST && _this.manageColumnsRef){
                _this.manageColumnsRef.setProfile(profile_id, widget_state.format);
            }
            if(originalWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS && _this.manageColumnsRefVect){
                // _this.manageColumnsRefVect.setSelectedProfile(profile);
                _this.manageColumnsRefVect.setProfile(profile_id, MENU_ITEM.FIELDS.EROSION_AND_GROWTH);
            }
        });
        this.drawerToggleClickHandler();
    }

    goToWidgetReport = (widgetData, sectionUrl, isConcatenationOfFilters) => {
        let nextStartDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState, this.props.periodsStatusState.customStartDate, this.props.periodsStatusState.customEndDate).startDate;
        let nextEndDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState, this.props.periodsStatusState.customStartDate, this.props.periodsStatusState.customEndDate).endDate;
        let _this = this;
        let startQuarter = getQuarterFromDate(nextStartDate);
        let endQuarter = getGeneratedQuarterRange(this.props.datasetState.datasetOptions,startQuarter,getQuarterFromDate(nextEndDate))[1] ; // End Quarter should be the last generated quarter
        cookie.save('vector',widgetData.bridge? TOTAL_COMPANY :widgetData[DASHBOARDS.VECTOR]);
        var trackingData = window._pi_getTrackingDataObj();
        if(trackingData){
            trackingData[LOG_OBJ_KEYS.HREF] = "/"+sectionUrl;
            logUITracking(JSON.stringify(trackingData));
        }
        let additionalProps = {isYTD:false, isYOY:false};
        if (sectionUrl.includes("mom")) {
            additionalProps.isMoM = true;
        }
        if (sectionUrl.includes("ytd")) {
            additionalProps.isYTD = true;
        }
        if (sectionUrl.includes("yoy")) {
            additionalProps.isYOY = true;
        }

        let widgetDataFilterObj = tryParse(widgetData.filter).filter;
        let widgetFilterArray = this.props?.filterDialRef?.current?.state?.allSavedFilters.filter(f=>f.name === widgetDataFilterObj[0]?.filter_display_name);
        this.goToReport(false, sectionUrl, {
            profitFormat: widgetData[DASHBOARDS.PROFIT_FORMAT],
            vectors: [widgetData[DASHBOARDS.VECTOR]],
            vector:widgetData[DASHBOARDS.VECTOR],
            comparison_value: widgetData[DASHBOARDS.CONFIG_FIELD.COMPARISON],
            dataset: endQuarter,
            months: getMonthsFromQuarters(startQuarter, endQuarter),
            outsideFilter: widgetData[DASHBOARDS.FILTER],
            order: widgetData[DASHBOARDS.CONFIG_FIELD.SORTING_DIRECTION],
            columnProfileId: widgetData[COLUMN_PROFILE.ID],
            profitStackViewId: widgetData[DASHBOARDS.CONFIG_FIELD.CUSTOM_VIEW],
            periodRange: widgetData[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_TYPE],
            period: getPeriodFromDate(nextEndDate),
            startQuarter: startQuarter,
            endQuarter: endQuarter,
            scenarios: [this.props.scenarioState.scenario],
            headerOptionChanged:false,
            callOnMount: true,
            checkedPsLines : widgetData.profit_stack_line,
            inheritedPeriod: true,
            useFilterCookies: false,
            scenarioState:this.props.history.scenarioState,
            additionalProps:additionalProps,
            savedFilterDataState: isConcatenationOfFilters ? {} : widgetFilterArray?.length > 0 ? widgetFilterArray[0] : this.props?.filterDialRef?.current?.state?.savedFilterData, // if we have concatenated filters, they are displayed as advanced filters when redirecting to a screen
            isConcatenationOfFilters:isConcatenationOfFilters,
            isFromDashboards:true
        });
    }

    fetchMetricWidgets = () =>{
        let _this = this;
        _this.fetchMetricWidgetsData(_this.state.dashBoardWidgets.filter(w=>(w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE
            || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC) && w[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]))
    }

    go=(id, name)=>{
        let _this = this;
        let startQuarter ="";
        let endQuarter = "";
        if (_this.props.periodsStatusState.customStartDate) {
            startQuarter = getQuarterFromDate(_this.props.periodsStatusState.customStartDate);
            endQuarter = getGeneratedQuarterRange(_this.props.datasetState.datasetOptions,startQuarter,getQuarterFromDate(_this.props.periodsStatusState.customEndDate))[1] ; // End Quarter should be the last generated quarter
        }
        _this.fetchMetricWidgetsData(_this.state.dashBoardWidgets.filter(w=>(w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE
            || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC) && w[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]));
        // !found this the only way to trigger the profitstacks to call fetch Function 
        this.state.widgetRefs.forEach(function(item,key){
          if (_this.state.widgetRefs[key] && _this.state.widgetRefs[key].current && _this.state.widgetRefs[key].current.widgetRef) {
                let isWidgetMoM = _this.state.widgetRefs[key].current.widgetRef?.props?.isMoM;
                if (id) {
                    if(id === _this.state.widgetRefs[key].current.props.widget[DASHBOARDS.WIDGET.ID] && _this.state.widgetRefs[key].current.props.widget[DASHBOARDS.WIDGET.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]) {
                        if (_this.state.widgetRefs[key].current.widgetRef.isMainReport.isVectorAnalysis) {
                            _this.state.widgetRefs[key].current.widgetRef.fetchReportData(undefined, undefined,startQuarter, endQuarter, _this.props.periodsStatusState.customStartDate, _this.props.periodsStatusState.customEndDate, _this.props.scenarioState.scenario);
                        } else if (isWidgetMoM){
                          _this.state.widgetRefs[key].current.widgetRef.getProfitStackByConfigurationData();
                        } else {
                          _this.state.widgetRefs[key].current.widgetRef.fetchProfitStackData(_this.props.filterFinal);
                        }
                        return;
                    }
                }
                if (name && !id){
                    if((name ===  _this.state.widgetRefs[key].current.props.widget[DASHBOARDS.WIDGET.NAME]) && _this.state.widgetRefs[key].current.props.widget[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]) {
                        if (_this.state.widgetRefs[key].current.widgetRef.isMainReport.isVectorAnalysis) {
                            _this.state.widgetRefs[key].current.widgetRef.fetchReportData(undefined, undefined,startQuarter, endQuarter, _this.props.periodsStatusState.customStartDate, _this.props.periodsStatusState.customEndDate, _this.props.scenarioState.scenario);
                        } else if (isWidgetMoM){
                          _this.state.widgetRefs[key].current.widgetRef.getProfitStackByConfigurationData();
                        } else {
                          _this.state.widgetRefs[key].current.widgetRef.fetchProfitStackData(_this.props.filterFinal);
                        }
                        return;
                    }
                }
                if (_this.state.widgetRefs[key].current.widgetRef && _this.state.widgetRefs[key].current.widgetRef.isMainReport && _this.state.widgetRefs[key].current.widgetRef.isMainReport.isVectorAnalysis) {
                    _this.state.widgetRefs[key].current.widgetRef.fetchReportData(undefined, undefined,startQuarter, endQuarter, _this.props.periodsStatusState.customStartDate, _this.props.periodsStatusState.customEndDate, _this.props.scenarioState.scenario);
                }else if(_this.state.widgetRefs[key].current.widgetRef.props.dontRenderTable){
                    _this.state.widgetRefs[key].current.widgetRef.fetchBridgeData();
                }else if (_this.state.widgetRefs[key].current.widgetRef && _this.state.widgetRefs[key].current.widgetRef.isMainReport && _this.state.widgetRefs[key].current.widgetRef.isMainReport.isList) {
                    _this.state.widgetRefs[key].current.widgetRef.fetchProfitInfo();
                } else if (isWidgetMoM){
                  _this.state.widgetRefs[key].current.widgetRef.getProfitStackByConfigurationData();
                } else {
                  _this.state.widgetRefs[key].current.widgetRef.fetchProfitStackData(_this.props.filterFinal);
                }
             }
        })
    }

    onToggleBoard(){
        // let _this = this;
        // if (this.state.displayPanel) {
        //     this.manageDashboardsOverlay.toggleBoard();
        // }
        // this.setState({
        //     displayPanel: !this.state.displayPanel,
        // },function(){
        //     if (this.state.displayPanel){
        //         $('body').css('overflow-y','hidden');
        //         _this.manageDashboardsOverlay.toggleBoard()
        //     }else{
        //         $('body').css('overflow-y','unset');
        //     }
        // })
    }

    shouldComponentUpdate(){
       return  true;
    }

    isNameUnique(name, id){
        let currentDashBoardId = this.props.selectedDashboard[DASHBOARDS.ID];
        let dashboardWidgets = this.state.dashBoardWidgets.filter(e=>e[DASHBOARDS.ID] === currentDashBoardId);
        return (dashboardWidgets.filter(e=>e[DASHBOARDS.NAME].toLowerCase() === name.toLowerCase() && (!id || e[_widgetId] === id)).length === 0) || (name === this.state.currentWidget.name);
    }

    /**
     * update widgets fields of the configured widget 
     * @param {*} eventOrValue 
     * @param {*} attr 
     */
    updateWidgetFields(eventOrValue, attr) {
        let currentWidget = this.state.currentWidget;
        let messages = this.state.messages;
        var params = {action:"listFilters"};
        let tempState = {};
        let formattedValue = "";
        let isWidgetNameValid = "";
        let attributesList = [DASHBOARDS.LINE_ATTRIBUTE, DASHBOARDS.LIST_TYPE, DASHBOARDS.VECTOR, DASHBOARDS.ORDER_BY,
            DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS, DASHBOARDS.SIZE, DASHBOARDS.ORDER, DASHBOARDS.COUNT_TYPE, DASHBOARDS.CONFIG_FIELD.COMPARISON,
            DASHBOARDS.PROFIT_STACKS_TYPE, DASHBOARDS.PROFIT_STACK, DASHBOARDS.CHANGE_COLOR_LOGIC, DASHBOARDS.CUSTOM_VIEW,DASHBOARDS.BRIDGE]
        if (!attributesList.includes(attr)) {
            let value = $(eventOrValue.currentTarget).val();
            formattedValue = value;
            if(attr === DASHBOARDS.NAME_ID){
                messages[attr] = isWidgetNameValid;
                tempState.messages = messages;
                if (formattedValue && formattedValue !== "") { 
                    if (!this.isNameUnique(formattedValue, currentWidget[_widgetId])) {
                        messages[attr] = lang.manage_access.name_already_used;
                    }
                }
            }
        } else {
            formattedValue = attr === DASHBOARDS.SIZE ? Number(eventOrValue) : eventOrValue;
            messages[attr] = "";
        }
        if (attr === DASHBOARDS.ORDER_BY) {
            currentWidget[COLUMN_PROFILE.ID] = this.manageColumnsRef.state.selectedProfile[COLUMN_PROFILE.ID];
        }
        if (attr === DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS) {
            currentWidget[COLUMN_PROFILE.ID] = this.manageColumnsRefVect.state.selectedProfile[COLUMN_PROFILE.ID];
        }
        if(attr === DASHBOARDS.BRIDGE){
            currentWidget[DASHBOARDS.BRIDGE]=formattedValue;
        }
        currentWidget[attr] = formattedValue;
        if (attr === DASHBOARDS.COUNT_TYPE && currentWidget[DASHBOARDS.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC) {
            currentWidget.column.machine_name = formattedValue;
        }
        
        if([DASHBOARDS.PROFIT_STACKS_TYPE, DASHBOARDS.CUSTOM_VIEW, DASHBOARDS.CONFIG_FIELD.COMPARISON].includes(attr)){
            currentWidget[attr] = formattedValue.value;
            if(attr === DASHBOARDS.CUSTOM_VIEW){
                this.getProfitStackHierarchy(eventOrValue.value);
                //! we should not read from sessionStorage directly, we should call the function that fetches the saved
                //! filters so they can be fetched from API if they didnt exist in the session
                let dataFromSession = getDataFromSession(JSON.stringify(params));
                let listFilters  =  dataFromSession && JSON.parse(dataFromSession)? JSON.parse(dataFromSession).data:[];
                if(formattedValue[FILTER.SAVED_FILTER.ID]){
                    let savedFilter = listFilters.filter(e=>e[FILTER.SAVED_FILTER.ID] === formattedValue[FILTER.SAVED_FILTER.ID])[0][FILTER.VALUES.FILTER_ROW_TYPE.FILTER];
                    currentWidget[DASHBOARDS.FILTER] = "{\""+FILTER.VALUES.FILTER_ROW_TYPE.FILTER+"\":"+savedFilter+"}";
                    this.filerWidgetRef.onChangeSavedFilter(JSON.stringify(JSON.parse(currentWidget[DASHBOARDS.FILTER]).filter), true);
                } else {
                    currentWidget[DASHBOARDS.FILTER] = "{\""+FILTER.VALUES.FILTER_ROW_TYPE.FILTER+"\":[]}";
                    this.filerWidgetRef.onChangeSavedFilter(JSON.stringify(JSON.parse(currentWidget[DASHBOARDS.FILTER]).filter), true);
                }
                tempState.profitStackLines = this.state.stackPSLLines;
                // clear profit stack lines when changing profit stack
                currentWidget[DASHBOARDS.PROFIT_STACK_LINE] = [];
                tempState.selectedNodes = [];
            } else if(attr === DASHBOARDS.PROFIT_STACKS_TYPE && formattedValue.value !== this.state.profitStackTypes[this.state.profitStackTypes.length-1].value) {
                if(!!currentWidget[DASHBOARDS.PROFIT_STACK_LINE] && currentWidget[DASHBOARDS.PROFIT_STACK_LINE].length > 1) {
                    //if the user had previously selected multiple ps lines and changed to ytd/qtd: keep only the first selected ps line
                    currentWidget[DASHBOARDS.PROFIT_STACK_LINE].length = 1;
                }
            }
        }
        if(attr === DASHBOARDS.PROFIT_STACKS_TYPE && eventOrValue[DASHBOARDS.CONFIG_FIELD.VALUE] !== DASHBOARDS.WIDGET.PERIODS){
            messages[DASHBOARDS.WIDGET.PS_LINE_LIMIT]="";
        }
       

        tempState.currentWidget = currentWidget;
        this.setState(tempState);
    }

    setFilter(filterRef) {
        this.filerWidgetRef = filterRef
    }

    updateFilter(filter) {
        this.setState({
            currentFilter: filter
        })
    }

    validateWidgetFields(){
        let widgetConfigElement = [];
        switch(this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]) {
            case DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC: {
                widgetConfigElement = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.VECTOR, DASHBOARDS.CONFIG_FIELD.COMPARISON, DASHBOARDS.COUNT_TYPE,  DASHBOARDS.FILTER];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT: {
                widgetConfigElement = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.COMPARISON,DASHBOARDS.BRIDGE,DASHBOARDS.VECTOR];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC: {
                widgetConfigElement = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.PROFIT_STACK_LINE, DASHBOARDS.COUNT_TYPE, DASHBOARDS.CONFIG_FIELD.COMPARISON,DASHBOARDS.FILTER];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS: {
                widgetConfigElement = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.PROFIT_STACK_LINE, DASHBOARDS.SIZE, DASHBOARDS.FILTER, DASHBOARDS.PROFIT_STACKS_TYPE];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC: {
                widgetConfigElement = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.COLUMN, DASHBOARDS.COUNT_TYPE, DASHBOARDS.CONFIG_FIELD.COMPARISON,DASHBOARDS.FILTER];
                break;
            } 
            case DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE: {
                widgetConfigElement = [DASHBOARDS.NAME, DASHBOARDS.LINE_ATTRIBUTE];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS: {
                widgetConfigElement = [DASHBOARDS.NAME, DASHBOARDS.VECTOR, DASHBOARDS.COMPARISON, DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS];
                break;
            }
            case ALL_WIDGETS.FIELDS.LIST:
            default: 
                widgetConfigElement = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.LIST_TYPE, DASHBOARDS.VECTOR, DASHBOARDS.MANAGE_COLUMNS, DASHBOARDS.ORDER_BY, DASHBOARDS.SIZE, DASHBOARDS.FILTER];
                break;
        }
        

        let widget = this.state.currentWidget;
        let messages = {};
        if (!widget[DASHBOARDS.NAME_ID] && widgetConfigElement.includes(DASHBOARDS.NAME)) {
            messages[DASHBOARDS.NAME_ID] = lang.dashboards.messages.name_is_empty;
        }
        if (widget[DASHBOARDS.NAME_ID] && widgetConfigElement.includes(DASHBOARDS.NAME) ) {
            if (!this.isNameUnique(widget[DASHBOARDS.NAME_ID])) {
                messages[DASHBOARDS.NAME_ID] = lang.manage_access.name_already_used;
            }
        }
        // if (!widget[DASHBOARDS.LIST_TYPE] && widgetConfigElement.includes(DASHBOARDS.LIST_TYPE)) {
        //     messages[DASHBOARDS.LIST_TYPE] = lang.dashboards.messages.list_type_is_empty;
        // }
        if (!widget[DASHBOARDS.VECTOR] && widgetConfigElement.includes(DASHBOARDS.VECTOR)) {
            messages[DASHBOARDS.VECTOR] = lang.dashboards.messages.vector_is_empty;
        }
        if (!widget[DASHBOARDS.ORDER_BY] && widgetConfigElement.includes(DASHBOARDS.ORDER_BY)) {
            messages[DASHBOARDS.ORDER_BY] = lang.dashboards.messages.order_by_is_empty;
        }
        // if (!widget[DASHBOARDS.PROFIT_STACKS_TYPE] && widgetConfigElement.includes(DASHBOARDS.PROFIT_STACKS_TYPE)) {
        //     messages[DASHBOARDS.PROFIT_STACKS_TYPE] = lang.dashboards.messages.profit_stack_type_is_empty;
        // }
        if (!widget[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS] && widgetConfigElement.includes(DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS)) {
            messages[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS] = lang.dashboards.messages.order_by_is_empty;
        }
        if (!widget[DASHBOARDS.LINE_ATTRIBUTE] && widgetConfigElement.includes(DASHBOARDS.LINE_ATTRIBUTE)) {
            messages[DASHBOARDS.LINE_ATTRIBUTE] = lang.dashboards.messages.line_attribute_is_empty;
        }
        if (!widget[DASHBOARDS.COMPARISON] && widgetConfigElement.includes(DASHBOARDS.COMPARISON)) {
            messages[DASHBOARDS.COMPARISON] = lang.dashboards.messages.comparison_type_is_empty;
        }
        if ((!widget[DASHBOARDS.PROFIT_STACK_LINE] || !Object.keys(widget[DASHBOARDS.PROFIT_STACK_LINE]).length) && widgetConfigElement.includes(DASHBOARDS.PROFIT_STACK_LINE) && this.state.selectedNodes && this.state.selectedNodes.length === 0) {
            messages[DASHBOARDS.PROFIT_STACK_LINE] = lang.dashboards.messages.profit_stack_line_is_empty;
        }
        if ((!widget[DASHBOARDS.COLUMN] || !Object.keys(widget[DASHBOARDS.COLUMN]).length) && widgetConfigElement.includes(DASHBOARDS.COLUMN)) {
            messages[DASHBOARDS.COLUMN] = lang.dashboards.messages.column_is_empty;
        }
        if (!widget[DASHBOARDS.BRIDGE] && widgetConfigElement.includes(DASHBOARDS.BRIDGE)) {
            messages[DASHBOARDS.BRIDGE] = lang.dashboards.messages.bridge_is_empty;
        }
        if(!Object.keys(messages).length){
            return true;
        } 
        return messages; 
    }

    selectProfileVectorAnalysis=(profile)=> {
        let _this = this;

        if (profile && Object.keys(profile).length > 0) {
            let profiles = _this.manageColumnsRefVect ? copyObjectValues(_this.manageColumnsRefVect.state.originalColumnProfiles) : [];
            let isVectorAnalysisPrf = false;
            if (profiles.filter(e=>e[COLUMN_PROFILE.ID] === profile[COLUMN_PROFILE.ID]).length > 0) {
                isVectorAnalysisPrf = true;
            }
            let columns = profile.originalColumns || profile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] || profile[COLUMN_PROFILE.COLUMNS];
            let currentWidget = this.state.currentWidget;
            if (currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === MENU_ITEM.FIELDS.EROSION_AND_GROWTH){
                currentWidget[COLUMN_PROFILE.ID] = profile[COLUMN_PROFILE.ID];
            }   
            currentWidget[COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS] = profile;
            let orders = [];
            for (let e in columns) {
                let colValue = columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME];
                if(columns[e][MANAGE_COLUMNS.MACHINE_NAME] !== column_suffixes_values.value.value){
                    colValue += "_" + columns[e][MANAGE_COLUMNS.MACHINE_NAME];  //if view machine_name != "value", append it to the value
                }
                if(columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME] === _selectedVector) {
                    //for selected_vector column, only use view machine name as value, for "name", "number"...
                    //also "quadrant_tier" (view machine name) should be "quadrant" to match identifier return name
                    colValue = isVectorAnalysisPrf ? columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME] +columns[e][MANAGE_COLUMNS.MACHINE_NAME].replace(/_/g,"")  :columns[e][MANAGE_COLUMNS.MACHINE_NAME].replace(/_/g,"");
                }
                if(columns[e].type === _vector && isVectorAnalysisPrf) {
                    colValue = columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME] + columns[e][MANAGE_COLUMNS.MACHINE_NAME];
                }
                let obj = {
                    value: colValue,
                    label: (columns[e][MANAGE_COLUMNS.COLUMN_NAME] ? 
                        [column_suffixes_values.minimum.value,column_suffixes_values.maximum.value,column_suffixes_values.range.value,column_suffixes_values.median.value,column_suffixes_values.average.value].includes(columns[e][MANAGE_COLUMNS.MACHINE_NAME]) ?
                        column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label + " " + columns[e][MANAGE_COLUMNS.COLUMN_NAME].replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"")
                        :columns[e][MANAGE_COLUMNS.COLUMN_NAME] + " " + (columns[e][MANAGE_COLUMNS.MACHINE_NAME] === column_suffixes_values.value.value ? 
                        ""  : column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label) : 
                        [column_suffixes_values.minimum.value,column_suffixes_values.maximum.value,column_suffixes_values.range.value,column_suffixes_values.median.value,column_suffixes_values.average.value].includes(columns[e][MANAGE_COLUMNS.MACHINE_NAME]) ?
                         column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label + " " + columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME].replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"")
                          : columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME].replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"") + " " +column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label),
                     [COLUMN_PROFILE.ID]: columns[e][COLUMN_PROFILE.ID]
                }
                if(![PROFILE_COLUMN.VIEW_OPTIONS.NAME, PROFILE_COLUMN.VIEW_OPTIONS.NUMBER, PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT_TIER, PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT].includes(columns[e][MANAGE_COLUMNS.MACHINE_NAME])){
                    let objCopy = copyObjectValues(obj);
                    objCopy.value += comparison_suffixes.comparison;
                    objCopy.label += " " + VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL;
                    orders.push(objCopy);

                    objCopy = copyObjectValues(obj);
                    objCopy.value += comparison_suffixes.selection;
                    objCopy.label += " " + VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL;
                    orders.push(objCopy);

                    objCopy = copyObjectValues(obj);
                    objCopy.value += comparison_suffixes.difference
                    objCopy.label += " " + VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL;
                    orders.push(objCopy);
                }else{
                    orders.push(obj);
                }
               orders.sort((a, b) => (a.label > b.label) ? 1 : -1);
            }
            let conf = tryParse(_this.state.currentWidget[DASHBOARDS.WIDGET.CONFIGURATION]) || {};
            let orderBy = orders.filter(e=>e.value === conf[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS]);
            currentWidget[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS] = orderBy.length > 0 ? orderBy[0] : "";
            this.setState({
                ordersOptionsVectorAnalysis: orders,
                currentWidget: currentWidget,
                profileVectorAnalysis: profile
            },function(){
                this.setState(this.state);
            })
        } else {
            this.setState(this.state);
        }
    }

    selectProfileList=(profile)=> {
        let _this = this;

        if (profile && Object.keys(profile).length > 0) {        
            let columns = profile.originalColumns || profile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] || profile[COLUMN_PROFILE.COLUMNS];
            let currentWidget = this.state.currentWidget;
            if (currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] ===  ALL_WIDGETS.FIELDS.LIST){
                currentWidget[COLUMN_PROFILE.ID] = profile[COLUMN_PROFILE.ID];
            }          
            currentWidget[COLUMN_PROFILE.PROFILE] = profile;
            let orders = [];
            for (let e in columns) {
                let colValue = columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME];
                if(columns[e][MANAGE_COLUMNS.MACHINE_NAME] !== column_suffixes_values.value.value){
                    colValue += "_" + columns[e][MANAGE_COLUMNS.MACHINE_NAME];  //if view machine_name != "value", append it to the value
                }
                if(columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME] === _selectedVector) {
                    //for selected_vector column, only use view machine name as value, for "name", "number"...
                    //also "quadrant_tier" (view machine name) should be "quadrant" to match identifier return name
                    colValue = columns[e][MANAGE_COLUMNS.MACHINE_NAME].replace(/_/g,"");
                }
              
                let obj = {
                    value: colValue,
                    label: (columns[e][MANAGE_COLUMNS.COLUMN_NAME] ? 
                        [column_suffixes_values.minimum.value,column_suffixes_values.maximum.value,column_suffixes_values.range.value,column_suffixes_values.median.value,column_suffixes_values.average.value].includes(columns[e][MANAGE_COLUMNS.MACHINE_NAME]) ?
                        column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label + " " + columns[e][MANAGE_COLUMNS.COLUMN_NAME].replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"")
                        :columns[e][MANAGE_COLUMNS.COLUMN_NAME] + " " + (columns[e][MANAGE_COLUMNS.MACHINE_NAME] === column_suffixes_values.value.value ? 
                        ""  : column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label) : 
                        [column_suffixes_values.minimum.value,column_suffixes_values.maximum.value,column_suffixes_values.range.value,column_suffixes_values.median.value,column_suffixes_values.average.value].includes(columns[e][MANAGE_COLUMNS.MACHINE_NAME]) ?
                         column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label + " " + columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME].replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"")
                          : columns[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME].replace(_selectedVector, _SelectedVectorLabel).replace(_cc,"").replace(_cpcf,"") + " " +column_suffixes_values[columns[e][MANAGE_COLUMNS.MACHINE_NAME]].label),
                     [COLUMN_PROFILE.ID]: columns[e][COLUMN_PROFILE.ID]
                }
               orders.push(obj);
               orders.sort((a, b) => (a.label > b.label) ? 1 : -1);
            }
            let conf = tryParse(_this.state.currentWidget[DASHBOARDS.WIDGET.CONFIGURATION]) || {};
            let orderBy = orders.filter(e=>e.value === conf[DASHBOARDS.CONFIG_FIELD.SORTING_PSL]);
            currentWidget[DASHBOARDS.ORDER_BY] = orderBy.length > 0 ? orderBy[0] : "";
            this.setState({
                ordersOptions: orders,
                currentWidget: currentWidget,
                profile: profile
            },function(){
                this.setState(this.state);
            })
        } else {
            this.setState(this.state);
        }
    }

    prepareWidget(widget) {
        widget[DASHBOARDS.MACHINE_NAME] = replaceSpecialChars(widget[DASHBOARDS.NAME_ID]);
        let configuration = {};
        switch(this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]){
            case ALL_WIDGETS.FIELDS.LIST:{
                configuration = {
                    [DASHBOARDS.CONFIG_FIELD.PROFIT_FORMAT]: this.state.dashboardFormats?.filter(e=>e.name === "List")[0].value,
                    [DASHBOARDS.CONFIG_FIELD.VECTOR]:widget[DASHBOARDS.VECTOR].value,
                    [DASHBOARDS.CONFIG_FIELD.SORTING_PSL]:widget[DASHBOARDS.ORDER_BY].value, 
                    [DASHBOARDS.CONFIG_FIELD.SORTING_DIRECTION]:widget[DASHBOARDS.ORDER],
                    [COLUMN_PROFILE.ID]: widget[COLUMN_PROFILE.ID]
                }
                break;
            } 
            case DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE:{
                configuration = {
                    [DASHBOARDS.COUNT_TYPE]: widget[DASHBOARDS.COUNT_TYPE],
                    [DASHBOARDS.LINE_ATTRIBUTE]: widget[DASHBOARDS.LINE_ATTRIBUTE].value === undefined ? widget[DASHBOARDS.LINE_ATTRIBUTE] : widget[DASHBOARDS.LINE_ATTRIBUTE].value,
                    [DASHBOARDS.CONFIG_FIELD.COMPARISON]:widget[DASHBOARDS.CONFIG_FIELD.COMPARISON],
                    [DASHBOARDS.CHANGE_COLOR_LOGIC]:widget[DASHBOARDS.CHANGE_COLOR_LOGIC]
                }
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC:{
                configuration = {
                    [DASHBOARDS.CONFIG_FIELD.VECTOR]: widget[DASHBOARDS.VECTOR].value,
                    [DASHBOARDS.COUNT_TYPE]: widget[DASHBOARDS.COUNT_TYPE],
                    [DASHBOARDS.CONFIG_FIELD.COMPARISON]: widget[DASHBOARDS.CONFIG_FIELD.COMPARISON],
                    [DASHBOARDS.CHANGE_COLOR_LOGIC]:widget[DASHBOARDS.CHANGE_COLOR_LOGIC]
                }
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC:{
                let isCustomView = (!["",0].includes(this.state.currentWidget[DASHBOARDS.CUSTOM_VIEW]))
                configuration = {
                    [DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_LINE]: widget[DASHBOARDS.PROFIT_STACK_LINE].length >0? widget[DASHBOARDS.PROFIT_STACK_LINE].map(function(item){
                        return {
                            [DASHBOARDS.CONFIG_FIELD.VALUE]: isCustomView ? item.value : item[PROFILE_COLUMN.RETURN_NAME],
                            [DASHBOARDS.CONFIG_FIELD.LABEL]: isCustomView ? item.label : item[PROFILE_COLUMN.NAME],
                            [DASHBOARDS.CONFIG_FIELD.FORMAT]: item[DASHBOARDS.CONFIG_FIELD.FORMAT]
                        }
                    }):widget[DASHBOARDS.PROFIT_STACK_LINE],
                    [DASHBOARDS.COUNT_TYPE]: widget[DASHBOARDS.COUNT_TYPE],
                    [DASHBOARDS.CONFIG_FIELD.COMPARISON]: widget[DASHBOARDS.CONFIG_FIELD.COMPARISON],
                    [DASHBOARDS.CHANGE_COLOR_LOGIC]:widget[DASHBOARDS.CHANGE_COLOR_LOGIC]
                };
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS:{
                configuration = {
                    [DASHBOARDS.CONFIG_FIELD.VECTOR]:widget[DASHBOARDS.VECTOR].value,
                    [DASHBOARDS.CONFIG_FIELD.COMPARISON]: widget[DASHBOARDS.CONFIG_FIELD.COMPARISON],
                    [DASHBOARDS.CONFIG_FIELD.SORTING_DIRECTION]:widget[DASHBOARDS.ORDER],
                    [DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS]:widget[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS].value,
                    [COLUMN_PROFILE.ID]: widget[COLUMN_PROFILE.ID],
                    // [COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS]: widget[COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS],
                    vName: widget[COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS].name,
                };
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT:{
                configuration = {
                    [DASHBOARDS.CONFIG_FIELD.COMPARISON]: widget[DASHBOARDS.CONFIG_FIELD.COMPARISON],
                    [DASHBOARDS.CONFIG_FIELD.BRIDGE]: this.props.bridgeOptionsState?.bridgeOptions?.filter(e=>e.value === widget[DASHBOARDS.CONFIG_FIELD.BRIDGE].value)[0], //widget[DASHBOARDS.BRIDGE],
                    [DASHBOARDS.CONFIG_FIELD.VECTOR]:widget[DASHBOARDS.VECTOR].value || widget[DASHBOARDS.VECTOR]
                };
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS:{
                let config = tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]);
                let ps_line = config && config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_LINE];
                let isCustomView = !["",0].includes(this.state.currentWidget[DASHBOARDS.CUSTOM_VIEW])
                configuration = {
                    [DASHBOARDS.PROFIT_STACKS_TYPE]: widget[DASHBOARDS.PROFIT_STACKS_TYPE],
                    [DASHBOARDS.CUSTOM_VIEW]: widget[DASHBOARDS.CUSTOM_VIEW],
                    [DASHBOARDS.PROFIT_STACK_LINE]: widget[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_LINE] && widget[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_LINE].length > 0 ? widget[DASHBOARDS.PROFIT_STACK_LINE].map(function(item){
                        return {
                            [DASHBOARDS.CONFIG_FIELD.VALUE]: isCustomView ? item.value : item[PROFILE_COLUMN.RETURN_NAME],
                            [DASHBOARDS.CONFIG_FIELD.LABEL]: isCustomView ? item.label : item[PROFILE_COLUMN.NAME],
                            [DASHBOARDS.CONFIG_FIELD.FORMAT]: item[DASHBOARDS.CONFIG_FIELD.FORMAT]
                        }
                    }) : ps_line
                }
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC:{
                widget[DASHBOARDS.COLUMN].machine_name = widget[DASHBOARDS.COUNT_TYPE];
                configuration = {
                    [DASHBOARDS.CONFIG_FIELD.COLUMN]: widget[DASHBOARDS.COLUMN],
                    [DASHBOARDS.COUNT_TYPE]: widget[DASHBOARDS.COUNT_TYPE],
                    [DASHBOARDS.CONFIG_FIELD.COMPARISON]: widget[DASHBOARDS.CONFIG_FIELD.COMPARISON],
                    [DASHBOARDS.CHANGE_COLOR_LOGIC]:widget[DASHBOARDS.CHANGE_COLOR_LOGIC]
                };
                break;
            }
        }
        widget[DASHBOARDS.WIDGET.CONFIGURATION] = configuration;
        if(this.state.currentFilter) {
            let formattedValue = JSON.stringify({filter: tryParse(this.state.currentFilter)});
            widget[DASHBOARDS.FILTER] = formattedValue
        }
        return widget;
    }
    prepareWidgetForRender(widget) {
        let conf = tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]);
        //fetch the index of all the parent categories of the widget's section
        let pathToSection = getPathToEmbeddedChild(this.state.allWidgets, DASHBOARDS.CATEGORY.CHILDREN, DASHBOARDS.WIDGET.SECTION_ID, widget[DASHBOARDS.WIDGET.SECTION_ID]+"");
        let categoryName = pathToSection.length > 0 ? this.state.allWidgets[pathToSection[0]][DASHBOARDS.CATEGORY.DISPLAY_NAME] : "";   //the first index is the index of the first parent of that section
        categoryName = Inflector.singularize(categoryName);

        let compOptions = widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS ? (this.comparisonOptions) : widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT ?  this.comparisonOptions.slice(1,3) : lang.widget.options.comparison;
        widget[DASHBOARDS.LINE_ATTRIBUTE] = conf[DASHBOARDS.LINE_ATTRIBUTE];
        widget[DASHBOARDS.NAME_ID] = widget[DASHBOARDS.NAME];
        widget[DASHBOARDS.DESCRIPTION_ID] = widget[DASHBOARDS.WIDGET.DESCRIPTION];
        widget[DASHBOARDS.LIST_TYPE] = this.state.dashboardFormats[0];
        widget[COLUMN_PROFILE.ID] = conf[COLUMN_PROFILE.ID];
        widget[COLUMN_PROFILE.PROFILE] = conf[COLUMN_PROFILE.PROFILE];
        widget[COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS] = conf[COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS];
        widget[DASHBOARDS.VECTOR] = copyObjectValues(this.props.bridgeOptionsState?.bridgeOptions)?.filter(e=>e.value === conf[DASHBOARDS.VECTOR])[0] || {value:conf.vector ,label: conf.vector};
        widget[DASHBOARDS.SIZE] = widget[DASHBOARDS.WIDGET.SIZE_ID];
        widget[DASHBOARDS.ORDER] = conf[DASHBOARDS.CONFIG_FIELD.SORTING_DIRECTION];
        widget[DASHBOARDS.ORDER_BY] = conf[DASHBOARDS.CONFIG_FIELD.SORTING_PSL];
        widget[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS] = conf[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS];
        widget.vName = conf.vName;
        widget[DASHBOARDS.CONFIG_FIELD.COMPARISON] = conf[DASHBOARDS.CONFIG_FIELD.COMPARISON]? compOptions.filter(e=>e.value === conf[DASHBOARDS.CONFIG_FIELD.COMPARISON])[0].value:"";
        widget[DASHBOARDS.CUSTOM_VIEW] = conf[DASHBOARDS.CUSTOM_VIEW] || conf[DASHBOARDS.CUSTOM_VIEW] === 0? this.props.PSViewsState?.profitStackViews?.filter(e=>e.value === conf[DASHBOARDS.CUSTOM_VIEW]).length > 0 ? this.props.PSViewsState?.profitStackViews.filter(e=>e.value === conf[DASHBOARDS.CUSTOM_VIEW])[0].value:"" :"";
        widget[DASHBOARDS.COUNT_TYPE] = conf[DASHBOARDS.COUNT_TYPE];
        widget[DASHBOARDS.BRIDGE] = conf[DASHBOARDS.BRIDGE];
        widget[DASHBOARDS.CHANGE_COLOR_LOGIC] = conf[DASHBOARDS.CHANGE_COLOR_LOGIC];
        widget[DASHBOARDS.WIDGET.WIDGET_TITLE] = lang.dashboards.configure_title + categoryName + " - " + widget[DASHBOARDS.WIDGET.NAME];
        widget[DASHBOARDS.PROFIT_STACKS_TYPE] = conf[DASHBOARDS.PROFIT_STACKS_TYPE]? this.state.profitStackTypes.filter(e=>e.value === conf[DASHBOARDS.PROFIT_STACKS_TYPE])[0]?.value:"";
        let category_machine_name = this.state.sizes.filter(e=>e[DASHBOARDS.WIDGET.SIZE_ID] === widget[DASHBOARDS.WIDGET.SIZE_ID])[0][DASHBOARDS.CATEGORY.MACHINE_NAME];
        let sizes = this.state.sizes.filter(e=>e[DASHBOARDS.CATEGORY.MACHINE_NAME] === category_machine_name)
        return {widget:widget, sizes:sizes, profile:widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS? widget[COLUMN_PROFILE.PROFILE_VECTOR_ANALYSIS] : widget[COLUMN_PROFILE.PROFILE], format:conf[DASHBOARDS.PROFIT_FORMAT], profile_id:widget[COLUMN_PROFILE.ID]};
    }

    saveWidget() {
        let _this = this;
        let widget = _this.state.currentWidget;
        widget= this.prepareWidget(widget);
        let query = {
            action: "saveWidget",
            widget: JSON.stringify(widget),
            scenario_id: this.props.scenarioState.scenario,
        }
        let onThenCallback = (data) => {
            let isError = false;
            let messages = _this.state.messages;
            let dashBoardWidgets = _this.state.dashBoardWidgets;
            if(data.success) {
                messages[TOAST] = widget[DASHBOARDS.WIDGET.ID] ? lang.dashboards.messages.widget_edited : lang.dashboards.messages.widget_saved;
                dashBoardWidgets =  data.dashBoardWidgets;
            } else {
                messages[TOAST] = lang.execution_error_two;
                isError = true;
            }
            // super.fetchWidgetsMain();
            _this.setState({
                messages:messages,
                isError: isError,
                dashBoardWidgets: dashBoardWidgets,//_this.setLoader(true, dashBoardWidgets, widget[DASHBOARDS.NAME_ID], widget[DASHBOARDS.WIDGET.ID]),
                ordersOptions: [],
                isEditing: false,
                areWidgetsLoading: true
            },function(){
                _this.setAddWidgetdDialogOpen(false)
                _this.launchToast();
                _this.filerWidgetRef.clearFilter();
                _this.resetInputs(messages[TOAST]);
                _this.fetchWidgets();
                _this.fetchMetricWidgetsData(_this.state.dashBoardWidgets.filter(w=>(w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE
                    || w[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC) && w[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]));
            })
        }
        
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveWidget",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }


    confirmDeleteWidget(widgetId, key){
        this.hideDashboardDropDown(key);
        this.setState({
            widgetId: widgetId
        },function(){
            this.setDeleteWidgetdDialogOpen(true)
        });
    }

    deleteWidget(widgetId){
        const _this = this;
        let query = {
            action: "deleteWidget",
            widgetId: widgetId,
            scenario_id: this.props.scenarioState.scenario,
        }

        let onThenCallback = (data)=>{
            let isError;
            let messages = _this.state.messages;
            if(data.success) { 
                messages[TOAST] = lang.dashboards.messages.widget_deleted_successfully;
                isError = false;
            } else {
                messages[TOAST] = lang.execution_error_two;
                isError = true;
            }
            let dashBoardWidgets = copyObjectValues(_this.state.dashBoardWidgets);
            dashBoardWidgets.splice(findIndexOfValue(dashBoardWidgets, "dashboard_widget_id", widgetId), 1);
            _this.setState({
                messages: messages,
                isError: isError,
                dashBoardWidgets: dashBoardWidgets,
                //dashBoardWidgets :this.state.dashBoardWidgets,
            }, function(){
                _this.launchToast();
                // _this.headerRef.secondaryHeader.manageDashboardsRef.setState({
                //     dashBoardWidgets:dashBoardWidgets
                // })
            })
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "deleteWidget",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
        this.setDeleteWidgetdDialogOpen(false);
    }

    startSaveWidget(checked, callback) {
        const _this = this;
        let messages = _this.validateWidgetFields();
        if(messages === true) {
            _this.saveWidget();
            // _this.onToggleBoard();
            if(typeof callback === "function"){
                callback(true);
            }
        } else {
            _this.setState({
                messages: messages
            });
        }
    }

    launchToast() {
        $("#toastDashboard").addClass("show");
        setTimeout(function(){
            $("#toastDashboard").removeClass("show");
        }, 4000);
    }

    resetInputs(message){
        let _this = this;
        _this.setState({
            currentWidget: {},
            messages: message ? {[TOAST]:message} : {},
            ordersOptions:[],
            currentFilter:undefined,
            showDropdown:false,
            profitStackLines:updateAllElements(copyObjectValues(this.state.profitStackLines), _treeChildren, _checked, false),
            columns:updateAllElements(copyObjectValues(this.state.columns), _treeChildren, _checked, false),
        },function(){
            if(_this.filerWidgetRef){
                _this.filerWidgetRef.clearFilter();
                _this.filerWidgetRef = null;
                if(document.getElementById(_filterModal) !== null && this.state.currentFilter) document.getElementById(_filterModal).remove();
                if(document.getElementById(_filterMessageModal) !== null && this.state.currentFilter) document.getElementById(_filterMessageModal).remove();
            }
            if(_this.manageColumnsRef){
                _this.manageColumnsRef.resetSelectedProfile();
            }
            if(_this.manageColumnsRefVect){
                _this.manageColumnsRefVect.resetSelectedProfile();
            }
        });

        Object.keys(this.inputRefs).forEach(key=>{
            if(!!_this.inputRefs[key]) {
                _this.inputRefs[key].reset();
            }
        });
    }

    manageComparisons =(selectedVector,compOptions,vectorOptions)=>{
        if(selectedVector && compOptions && vectorOptions){
            let bestPracticeOptions = compOptions.filter(e=>e.type === _bestPractice);
            let vector = vectorOptions.filter(e=>e.value === selectedVector.value);
            if(vector.length > 0 && vector[0].generated_quarters === _allNotGenerated){
                for(let e in bestPracticeOptions){
                    bestPracticeOptions[e].isDisabled = true;
                    bestPracticeOptions[e].no_quads = true;
                    bestPracticeOptions[e].disableOption = true;
                }
            }
            else {
                for(let e in bestPracticeOptions){
                    bestPracticeOptions[e].isDisabled = false;
                    bestPracticeOptions[e].no_quads = false;
                    bestPracticeOptions[e].disableOption = false;
                    
                }
            }
        }
    }
    

    renderConfigWidget(elem){
        const _this = this;
        let config = this.state.currentWidget[DASHBOARDS.WIDGET.CONFIGURATION] ? tryParse(this.state.currentWidget[DASHBOARDS.WIDGET.CONFIGURATION]) :{}
        let comparison = this.state.currentWidget[DASHBOARDS.COMPARISON];
        let column = this.state.currentWidget[DASHBOARDS.CONFIG_FIELD.COLUMN];
        let quadrantFilterDisabled = this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS|| this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT;
        let countTypeCondition = [DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC,DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC].includes(this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]) 
        || (this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]  === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC  && !!column);
        let changeColorLogicCondition = comparison && comparison !== DASHBOARDS.COMPARISON_VALUES.NONE && this.state.currentWidget[DASHBOARDS.COUNT_TYPE] !== lang.other_per_vector.range.value;
        const optionLabel = ({label, isDisabled, vector_description, is_default, isGroupTitle, tooltipText}) => (
            <div className={(isGroupTitle ? "option-group-header" : "") +" option-padding uk-flex-between uk-display-flex"} uk-tooltip={!!tooltipText ? tooltipText : ""}>
                {label} {!isGroupTitle ? <i className="fal fa-info-circle" uk-tooltip={(!vector_description || is_default) ? lang.no_vector_description : vector_description} 
                style={{"color":"white","marginTop": convertPxToViewport(5)}}></i> : "" }
            </div>
        );
        let options = this.updateCountTypeTogglesOptions()
        let vectors = [];
        let compOptions = this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT?  this.comparisonOptions.slice(1,3) : this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS ? this.comparisonOptions :  lang.widget.options.comparison;
        let selectedVector = this.state.currentWidget[DASHBOARDS.VECTOR] ? this.state.currentWidget[DASHBOARDS.VECTOR] : config[DASHBOARDS.VECTOR];
        let uniqueProp = elem + "_" + shortid.generate();
        if(this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.LIST){
            vectors = copyObjectValues(this.props.vectorState.vectorOptions);
        } else {
            vectors = this.addVector(copyObjectValues(this.props.vectorState.vectorOptions));
        }
        this.manageComparisons(selectedVector,compOptions,vectors);
        switch (elem) {
            case DASHBOARDS.NAME: {
                return <div key={uniqueProp} className="uk-width-1-1 uk-margin-xmedium-top-bottom">
                            <label htmlFor={DASHBOARDS.NAME_ID} className="fs-14 uk-margin-xsmall-bottom">{ lang.dashboards.titles.name}<span className="input_required_text">{"(Required)"}</span></label>
                            <FormComponent tag="input" id={DASHBOARDS.NAME_ID} ref={r => this.inputRefs[DASHBOARDS.NAME_ID] = r} className="uk-input" placeholder={this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT? lang.dashboards.titles.chart_name : lang.dashboards.titles.name}
                                onChange={(e)=>{this.updateWidgetFields(e, DASHBOARDS.NAME_ID)}} value={this.state.currentWidget[DASHBOARDS.NAME_ID]}
                            />
                            {this.state.messages[DASHBOARDS.NAME_ID] && this.state.messages[DASHBOARDS.NAME_ID] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.NAME_ID]}</div> : ""}
                        </div>
            }
            case DASHBOARDS.DESCRIPTION: {
            return <div key={uniqueProp} className="uk-margin-xmedium-bottom">
                        <label htmlFor={DASHBOARDS.DESCRIPTION_ID} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.description}<span className="input_required_text_disabled">{"(Optional)"}</span></label>
                        <FormComponent tag="textarea" id={DASHBOARDS.DESCRIPTION_ID} ref={r => this.inputRefs[DASHBOARDS.DESCRIPTION_ID] = r} className="uk-textarea" placeholder={this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT? lang.dashboards.titles.chart_description : lang.dashboards.titles.description}
                            value={this.state.currentWidget[DASHBOARDS.DESCRIPTION_ID]} onChange={(e)=>{this.updateWidgetFields(e, DASHBOARDS.DESCRIPTION_ID)}}/>
                    </div>
            }
            case DASHBOARDS.PROFIT_STACKS_TYPE:{
                const psOptionLabel = ({label, isDisabled, description}) => (
                    <div className={(isDisabled ? "option-group-header" : "") +" option-padding"}>
                        {label} {!isDisabled && description ?<i className="fal fa-info-circle" uk-tooltip={description}
                        style={{"float":"right","color":"white","marginTop":convertPxToViewport(5)}} /> :""}
                    </div>
                );
                return <div className="uk-margin-xmedium-bottom">
                        <label htmlFor={DASHBOARDS.PROFIT_STACKS_TYPE} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.profit_stack_type}</label>
                            <Dropdown  //Remove Comment from <DropDown and comment <Select
                                id={DASHBOARDS.PROFIT_STACKS_TYPE}
                                // classNamePrefix={"dashboard-dropdown"}
                                className="input__dropdown"
                                name={DASHBOARDS.PROFIT_STACKS_TYPE}
                                value={findOptionByKey(this.state.profitStackTypes, this.state.currentWidget[DASHBOARDS.PROFIT_STACKS_TYPE] ? this.state.currentWidget[DASHBOARDS.PROFIT_STACKS_TYPE]: /*this.state?.currentWidget?.configuration && JSON.parse(this.state.currentWidget.configuration)?.profit_stack_type !==""?"":*/ this.state.profitStackTypes[1])}
                                onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.PROFIT_STACKS_TYPE)}
                                options={this.state.profitStackTypes}
                                formatOptionLabel={psOptionLabel}
                                maxMenuHeight= {convertPxToViewport(190)}
                                type={DROPDOWN_TYPE.INPUT}
                            />
                </div>
            }
            case DASHBOARDS.LIST_TYPE:{
            return  <div key={uniqueProp} className="uk-margin-xmedium-bottom">
                        <label htmlFor={DASHBOARDS.LIST_TYPE} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.list_type}<span className="input_required_text">{"(Required)"}</span></label>
                        <Dropdown // to remove comment and comment <Select
                            isDisabled={true}
                            id={DASHBOARDS.LIST_TYPE}
                            // classNamePrefix={"dashboard-dropdown"}
                            className="input__dropdown"
                            name={DASHBOARDS.LIST_TYPE}
                            value={findOptionByKey(this.state.dashboardFormats, (this.state.dashboardFormats ? this.state.dashboardFormats?.filter(e=>e.name === "List")[0] : ""))}
                            onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.LIST_TYPE)}
                            options={this.state.dashboardFormats}
                            placeholder={lang.dashboards.select_list_type}
                            type={DROPDOWN_TYPE.INPUT}
                            disabled={true}
                        />
                        {this.state.messages[DASHBOARDS.LIST_TYPE] && this.state.messages[DASHBOARDS.LIST_TYPE] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.LIST_TYPE]}</div> : ""}
                    </div>
            }
            case DASHBOARDS.LINE_ATTRIBUTE:{
                return  <div key={uniqueProp} className="uk-margin-xmedium-bottom">
                            <label htmlFor={DASHBOARDS.LINE_ATTRIBUTE} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.line_attribute}<span className="input_required_text">{"(Required)"}</span></label>
                            <div className="uk-flex uk-flex-top max_width">
                                <div className="max_width">
                                    <DropDown
                                        id={DASHBOARDS.LINE_ATTRIBUTE}
                                        className="input__dropdown max_width"
                                        // classNamePrefix={"dashboard-dropdown"}
                                        name={DASHBOARDS.LINE_ATTRIBUTE}
                                        value={findOptionByKey(this.state.lineAttributeOptions, this.state.currentWidget[DASHBOARDS.LINE_ATTRIBUTE] ? this.state.currentWidget[DASHBOARDS.LINE_ATTRIBUTE] : "")}
                                        onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.LINE_ATTRIBUTE)}
                                        options={this.state.lineAttributeOptions}
                                        type={DROPDOWN_TYPE.INPUT}
                                        placeholder={lang.dashboards.select_line_attribute}
                                        maxMenuHeight= {convertPxToViewport(190)}
                                    />
                                    {this.state.messages[DASHBOARDS.LINE_ATTRIBUTE] && this.state.messages[DASHBOARDS.LINE_ATTRIBUTE] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.LINE_ATTRIBUTE]}</div> : ""}
                                </div>
                                <div className="uk-flex-inline uk-margin-default-left max_width">
                                    <ToggleTab options={options} defaultValue={this.state.currentWidget[DASHBOARDS.COUNT_TYPE]}
                                        onSelectTab={(order) => this.updateWidgetFields(order, DASHBOARDS.COUNT_TYPE)}
                                    />
                                </div>
                            </div>
                        </div>
                }  
            case DASHBOARDS.VECTOR:{
            return <div key={uniqueProp} className="uk-display-inline-block uk-margin-xmedium-bottom max_width">
                   { this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT?  <label htmlFor={DASHBOARDS.VECTOR} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.mix_vector}</label>
                   :
                   <><label htmlFor={DASHBOARDS.VECTOR} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.vector}<span className="input_required_text">{"(Required)"}</span></label></>}
                        <Dropdown // to remove comment and comment <Select
                            id={DASHBOARDS.VECTOR}
                            filterOption={(option, searchText)=>{return option.label.toLowerCase().includes(searchText.toLowerCase())}}
                            className="input__dropdown max_width"
                            // classNamePrefix={"dashboard-dropdown"}
                            name={DASHBOARDS.VECTOR}
                            value={findOptionByKey(vectors, this.state.currentWidget[DASHBOARDS.VECTOR] ? this.state.currentWidget[DASHBOARDS.VECTOR] : config[DASHBOARDS.VECTOR])}
                            onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.VECTOR)}
                            options={this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT ? vectors.filter(f => f.value !== INVOICE_LINE.value ) : vectors}
                            formatOptionLabel={optionLabel}
                            placeholder={lang.dashboards.select_vector}
                            maxMenuHeight= {convertPxToViewport(190)}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                        {this.state.messages[DASHBOARDS.VECTOR] && this.state.messages[DASHBOARDS.VECTOR] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.VECTOR]}</div> : ""}
                    </div>
            }
            case DASHBOARDS.CUSTOM_VIEW:{
            const profitStackViewsDD = ({label, isDisabled, tooltipText}) => (
                <div className={"option-padding uk-flex-between uk-display-flex"} disabled={isDisabled} uk-tooltip={!!tooltipText ? tooltipText : ""}> {label} </div>
            );
                return <div className={"uk-margin-default-bottom"}>
                        <label htmlFor={DASHBOARDS.CUSTOM_VIEW} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.profit_stack}</label>
                        <Dropdown
                            id=""
                            className="input__dropdown"
                            placeholder={lang.header.placeholders.profit_stack}
                            value={findOptionByKey(this.props.PSViewsState?.profitStackViews, this.state.currentWidget[DASHBOARDS.CUSTOM_VIEW]? this.state.currentWidget[DASHBOARDS.CUSTOM_VIEW]: this.props.PSViewsState?.profitStackViews[0])}
                            onChange={(e)=>this.updateWidgetFields(e, DASHBOARDS.CUSTOM_VIEW)}
                            options={this.props.PSViewsState?.profitStackViews}
                            formatOptionLabel={profitStackViewsDD}
                            isSearchable={false}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                    </div>
            }
            case DASHBOARDS.PROFIT_STACK_LINE: {
                let isPsChart = this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS;
                let psLines = [];
                psLines = copyObjectValues(this.state.stackPSLLines);
                psLines = psLines.filter(e=>![_percentages,_medians,_averages,isPsChart?costtype.attribute:""].includes(e.costtype));
                let isMultiSelectionAllowed = this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS && this.state.currentWidget[DASHBOARDS.PROFIT_STACKS_TYPE] === this.state.profitStackTypes[this.state.profitStackTypes.length-1].value;
                return <div key={uniqueProp} className="uk-margin-xmedium-bottom uk-display-inline-block" style={{width:"100%"}}>
                    <label className="fs-14 uk-margin-xsmall-bottom" htmlFor={MANAGE_COLUMNS.CHOSEN_GROUP_ID}>{lang.dashboards.titles.profit_stack_line}<span className="input_required_text">{"(Required)"}</span></label>
                    <Container texts={{ placeholder: lang.heatmap_configure.select_ps_line }}
                        data={psLines} onChange={this.onChange} mode={isMultiSelectionAllowed ? "hierarchical" : "radioSelect"}
                        onNodeToggle = {isMultiSelectionAllowed ? this.onNodeToggle : undefined} showDropdown={this.state.showDropdown} className={isMultiSelectionAllowed? "dropdown-tree multi-select-drop-down" :"dropdown-tree"}/* multi-select-drop-down className must be added everytime we need to use <container> component in multiselect mode */
                    />
                    {this.state.messages[DASHBOARDS.PROFIT_STACK_LINE] && this.state.messages[DASHBOARDS.PROFIT_STACK_LINE] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.PROFIT_STACK_LINE]}</div> : ""}
                    {this.state.messages[DASHBOARDS.WIDGET.PS_LINE_LIMIT] && this.state.messages[DASHBOARDS.WIDGET.PS_LINE_LIMIT] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.WIDGET.PS_LINE_LIMIT]}</div> : ""}

                </div>
            }
            case DASHBOARDS.COUNT_TYPE: {
                return countTypeCondition &&
                 <div key={uniqueProp} className="dashboards-count-type uk-flex-inline uk-margin-xmedium-bottom max_width">
                    <ToggleTab options={options} defaultValue={this.state.currentWidget[DASHBOARDS.COUNT_TYPE]}
                        onSelectTab={(order) => this.updateWidgetFields(order, DASHBOARDS.COUNT_TYPE)}
                    />
                </div>
            }
            case DASHBOARDS.CONFIG_FIELD.COMPARISON:{
                const comparsionOptionLabel = ({label, value, isDisabled, description,disableOption,tooltipText,no_quads}) => (
                    <div className={(isDisabled && !disableOption ? "option-group-header" : "") +" option-padding"}>
                        {label} {!isDisabled && !disableOption ? 
                        <i className="fal fa-info-circle" uk-tooltip={description} style={{"float":"right","color":"white","marginTop":convertPxToViewport(5)}} /> :
                        isDisabled && disableOption && !no_quads ?  <i className="fal fa-info-circle black" uk-tooltip={tooltipText} style={{"float":"right","marginTop":convertPxToViewport(5)}} /> 
                        : isDisabled && disableOption && no_quads ?  <i className="fal fa-info-circle black" uk-tooltip={lang.quadrants_not_generated} style={{"float":"right","marginTop":convertPxToViewport(5)}} /> 
                        : ""}
                        
                    </div>
                );
                return <div key={uniqueProp} className="uk-margin-xmedium-bottom">
                            <label htmlFor="select-set" className="fs-14 uk-margin-xsmall-bottom" id="comparison_label">{lang.header.titles.comparison_type}</label>
                            <Dropdown //to remove comment and comment <Select
                                id="comparison-dropdown"
                                className="input__dropdown"
                                // classNamePrefix={"dashboard-dropdown"}
                                name="comparison"
                                value={ findOptionByKey(compOptions, this.state.currentWidget[DASHBOARDS.CONFIG_FIELD.COMPARISON]? this.state.currentWidget[DASHBOARDS.CONFIG_FIELD.COMPARISON] : this.state.compOptions[0])}
                                onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.CONFIG_FIELD.COMPARISON)}
                                options={compOptions}
                                formatOptionLabel={this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] !== DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS ? undefined : comparsionOptionLabel}
                                placeholder={lang.dashboards.select_comparison_type}
                                maxMenuHeight= {convertPxToViewport(190)}
                                type={DROPDOWN_TYPE.INPUT}
                            />
                            {this.state.messages[DASHBOARDS.CONFIG_FIELD.COMPARISON] && this.state.messages[DASHBOARDS.CONFIG_FIELD.COMPARISON] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.CONFIG_FIELD.COMPARISON]}</div> : ""}
                        </div>
            }
            case DASHBOARDS.CHANGE_COLOR_LOGIC: {
                return changeColorLogicCondition &&
                    <div key={uniqueProp} className="uk-margin-default-bottom">
                        <label htmlFor={DASHBOARDS.CHANGE_COLOR_LOGIC} className="fs-14">{lang.dashboards.titles.change_color_logic}</label>
                        <div className="uk-flex uk-flex-middle">
                            <div className="uk-flex-inline">
                                <ToggleTab options={[lang.arrow.green_up, lang.arrow.red_up]} defaultValue={this.state.currentWidget[DASHBOARDS.CHANGE_COLOR_LOGIC]}
                                    onSelectTab={(arrow) => this.updateWidgetFields(arrow, DASHBOARDS.CHANGE_COLOR_LOGIC)}
                                />
                            </div>
                            <div className="uk-flex-inline uk-flex-middle uk-padding-small-left">
                                <div className="uk-flex uk-flex-column fs-12 text-grey">
                                    <em>Positive Change:</em>
                                    <em>Negative Change:</em>
                                </div>
                                <div className="uk-flex uk-flex-column uk-padding-xxsmall-left">
                                    <div className={`${this.state.currentWidget[DASHBOARDS.CHANGE_COLOR_LOGIC] === lang.arrow.green_up.value ? "greenText" : "uk-text-primary"} uk-padding-xxsmall-left uk-flex uk-flex-middle`}><i className={"fa-2x fas fa-caret-up"}></i><span className="fs-12 uk-padding-xxsmall-left">+12%</span></div>
                                    <div className={`${this.state.currentWidget[DASHBOARDS.CHANGE_COLOR_LOGIC] === lang.arrow.green_up.value ? "uk-text-primary" : "greenText"} uk-padding-xxsmall-left uk-flex uk-flex-middle`}><i className={"fa-2x fas fa-caret-down"}></i><span className="fs-12 uk-padding-xxsmall-left">-12%</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            case DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS :{
                const orderByOptionLabel = ({label, isDisabled}) => (
                    <div className={(isDisabled ? "option-group-header" : "") +" option-padding"}>
                        {label} 
                    </div>
                );
                return <div key={uniqueProp} className="uk-margin-xmedium-bottom">
                        <label htmlFor={DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.order_by}<span className="input_required_text">{"(Required)"}</span></label>
                        <div className="uk-flex uk-flex-bottom">
                            {/* <Select
                                id={DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS}
                                classNamePrefix={"dashboard-dropdown"}
                                className="uk-form-width-medium uk-display-inline-block uk-cursor-pointer"
                                name={DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS}
                                value={findOptionByKey(this.state.ordersOptionsVectorAnalysis, this.state.currentWidget[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS])}
                                onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS)}
                                options={this.state.ordersOptionsVectorAnalysis}
                                styles={placeholderColor}
                                placeholder={lang.dashboards.select_psl}
                                formatOptionLabel={orderByOptionLabel}
                            /> */}
                            <Dropdown
                                id={DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS}
                                // classNamePrefix={"dashboard-dropdown"}
                                className="input__dropdown max_width"
                                name={DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS}
                                value={findOptionByKey(this.state.ordersOptionsVectorAnalysis, this.state.currentWidget[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS])}
                                onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS)}
                                options={this.state.ordersOptionsVectorAnalysis}
                                placeholder={lang.dashboards.select_psl}
                                // formatOptionLabel={orderByOptionLabel}
                                type={DROPDOWN_TYPE.INPUT}
                            />
                            <div className="uk-flex-inline uk-margin-default-left max_width">
                                <ToggleTab options={[lang.LIST_ORDER.TOP, lang.LIST_ORDER.BOTTOM]} defaultValue={this.state.currentWidget[DASHBOARDS.ORDER]}
                                    onSelectTab={(order) => this.updateWidgetFields(order, DASHBOARDS.ORDER)}
                                />
                            </div>
                            {this.state.messages[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS] && this.state.messages[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS]}</div> : ""}
                        </div>
                    </div>
            }
            case DASHBOARDS.ORDER_BY :{
                const orderByOptionLabel = ({label, isDisabled}) => (
                    <div className={(isDisabled ? "option-group-header" : "") +" option-padding"}>
                        {label} 
                    </div>
                );
                return <div key={uniqueProp} className="uk-margin-xmedium-bottom">
                        <label htmlFor={DASHBOARDS.ORDER_BY} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.order_by}<span className="input_required_text">{"(Required)"}</span></label>
                        <div className="uk-flex uk-flex-bottom">
                            {/* <Select
                                id={DASHBOARDS.ORDER_BY}
                                classNamePrefix={"dashboard-dropdown"}
                                className="uk-form-width-medium uk-display-inline-block uk-cursor-pointer"
                                name={DASHBOARDS.ORDER_BY}
                                value={findOptionByKey(this.state.ordersOptions, this.state.currentWidget[DASHBOARDS.ORDER_BY])}
                                onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.ORDER_BY)}
                                options={this.state.ordersOptions}
                                styles={placeholderColor}
                                placeholder={lang.dashboards.select_psl}
                                formatOptionLabel={orderByOptionLabel}
                                maxMenuHeight= {convertPxToViewport(190)}
                            /> */}
                            <Dropdown //to remove comment and comment <Select
                                id={DASHBOARDS.ORDER_BY}
                                // classNamePrefix={"dashboard-dropdown"}
                                className="input__dropdown max_width"
                                name={DASHBOARDS.ORDER_BY}
                                value={findOptionByKey(this.state.ordersOptions, this.state.currentWidget[DASHBOARDS.ORDER_BY])}
                                onChange={(e) => this.updateWidgetFields(e, DASHBOARDS.ORDER_BY)}
                                options={this.state.ordersOptions}
                                placeholder={lang.dashboards.select_psl}
                                formatOptionLabel={orderByOptionLabel}
                                maxMenuHeight= {convertPxToViewport(190)}
                                type={DROPDOWN_TYPE.INPUT}
                            />
                            <div className="uk-flex-inline uk-margin-default-left max_width">
                                <ToggleTab options={[lang.LIST_ORDER.TOP, lang.LIST_ORDER.BOTTOM]} defaultValue={this.state.currentWidget[DASHBOARDS.ORDER]}
                                    onSelectTab={(order) => this.updateWidgetFields(order, DASHBOARDS.ORDER)} splitRows={true}
                                />
                            </div>
                            {this.state.messages[DASHBOARDS.ORDER_BY] && this.state.messages[DASHBOARDS.ORDER_BY] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.ORDER_BY]}</div> : ""}
                        </div>
                    </div>
            }
            case DASHBOARDS.SIZE: {
            return <div key={uniqueProp} className="uk-margin-medium-bottom">
                        <label htmlFor={DASHBOARDS.SIZE} className="fs-14 uk-margin-xsmall-bottom uk-margin-medium-top">{lang.dashboards.titles.size}</label>
                        <ToggleTab options={this.state.sizesOptions} defaultValue={this.state.currentWidget[DASHBOARDS.SIZE]}
                            onSelectTab={(size) => this.updateWidgetFields(size, DASHBOARDS.SIZE)} 
                            splitRows={true}
                        />
                    </div>  
            } 
            case DASHBOARDS.FILTER:{ 
                let hasFilter = this.state.currentFilter && this.state.currentFilter !== "[]";
            return <div key={"filter"} className="uk-margin-large-bottom">           
                        {hasFilter?"":<div htmlFor={DASHBOARDS.FILTER} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.filter}</div>}
                        <div className="split__button filter-list-filled uk-width-expand uk-display-flex uk-padding-remove-left filter_dashboard_wdiget">
                            <div>
                                {hasFilter?<span className="uk-text-medium uk-display-block uk-small-padding uk-text-bold uk-text-left">{lang.basic_filter.applied_filters_text}</span>:""}
                                <div className="uk-display-flex gap_between_buttons">
                                    <Button 
                                        label={lang.modal.buttons.advanced_filter}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        leftIcon={<MultipleFilters />}
                                        className={"mui-secondary-button-widget uk-margin-small-top"}
                                        uk-toggle="target: #filterModal_1"
                                    />
                                    <div className="uk-padding-remove-left uk-margin-small-top">
                                            <FilterDialog
                                                id ={"dashboard_"+uniqueProp}
                                                compNumber={1}
                                                ref={el => this.setFilter(el)}
                                                section_id={this.props.section_id}
                                                vectorOptions={vectors}
                                                useOutsideFilter={true}
                                                onChangeFilter={this.updateFilter}
                                                scenario_id={this.props.scenarioState.scenario}
                                                mustFetchDatasets={false}
                                                useCookies={false}
                                                user={this.props.userSettingsState.user}
                                                isDrilling={false}
                                                fromOptions={this.props.datasetState.datasetOptions}
                                                datasetOptions_id={this.props.datasetState.datasetOptions_id}
                                                checkIfMonthsValid={this.checkIfMonthsValid}
                                                quadrantsDisabled={quadrantFilterDisabled}
                                                quadrantDefault={false}
                                                filterDisplayEditable={true}
                                                hideFilterDisplay={false}
                                                dashboard={"_1"}
                                                savedFilter={true}
                                                isWidgetFilter={true}
                                                savedFilterDataState={this.state.savedFilterDataState}
                                                psLinesOptions={this.props.psLinesOptions}
                                                quadrantTierOptions={this.props.quadrantTierOptions}
                                                dispatch={this.props.dispatch}
                                                headerRef={this.headerRef}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            case DASHBOARDS.COLUMN: {
                return <div key={uniqueProp} className="uk-margin-xmedium-bottom uk-display-inline-block dropdown-tree-truncate">
                    <label className="fs-14 uk-margin-xsmall-bottom" htmlFor={MANAGE_COLUMNS.CHOSEN_GROUP_ID}>{lang.dashboards.titles.column}<span className="input_required_text">{"(Required)"}</span></label>
                    <Container texts={{ placeholder: lang.heatmap_configure.select_ps_line }}
                        data={this.state.columns} onChange={this.onChangeColumn} mode={"radioSelect"} className={"dropdown-tree"}
                        />
                         {this.state.messages[DASHBOARDS.COLUMN] && this.state.messages[DASHBOARDS.COLUMN] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.COLUMN]}</div> : ""}
                </div>
            }
            case DASHBOARDS.BRIDGE: {
                const bridgeOptions = ({title, label, isDisabled,value }) => (
                    <div title={label || value} className={(isDisabled && title? "option-group-header option-padding" : isDisabled ? " disabled " : "") + " uk-text-overflow-ellipsis"}>
                        {(label || value)}
                    </div>
                );

                return <div className="uk-margin-default-bottom">
                    <div className="uk-display-inline-flex">
                        <label htmlFor="select-set" id="comparison_label" className="fs-14 uk-margin-xsmall-bottom">{lang.header.titles.bridge}</label>
                       
                    </div>
                    {/* <Select
                        id="bridge-dropdown"
                        className="uk-form-width-medium uk-cursor-pointer"
                        classNamePrefix={"dashboard-dropdown"}
                        name="Bridge"
                        value={findOptionByKey(this.state.bridgeOptions, this.state.currentWidget[DASHBOARDS.BRIDGE] ? this.state.currentWidget[DASHBOARDS.BRIDGE] : config[DASHBOARDS.BRIDGE])}
                        onChange={(e) => this.updateWidgetFields(e,DASHBOARDS.BRIDGE)}
                        options={this.state.bridgeOptions}
                        formatOptionLabel={bridgeOptions}
                        styles={placeholderColor}
                        placeholder={lang.dashboards.select_bridge}
                    /> */}
                    <Dropdown // to remove comment and comment <Select
                        id="bridge-dropdown"
                        className="input__dropdown"
                        // classNamePrefix={"dashboard-dropdown"}
                        name="Bridge"
                        value={findOptionByKey(this.props.bridgeOptionsState?.bridgeOptions, this.state.currentWidget[DASHBOARDS.BRIDGE] ? this.state.currentWidget[DASHBOARDS.BRIDGE] : config[DASHBOARDS.BRIDGE])}
                        onChange={(e) => this.updateWidgetFields(e,DASHBOARDS.BRIDGE)}
                        options={this.props.bridgeOptionsState?.bridgeOptions}
                        formatOptionLabel={bridgeOptions}
                        placeholder={lang.dashboards.select_bridge}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                    <div>
                    </div>
                            {this.state.messages[DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT] && this.state.messages[DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT] !== "" ? <div className="fs-12 red italic">{this.state.messages[DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT]}</div> : ""}
                        </div>
                   
            }
        }
    }

    startCancelWidget(){
        let _this = this;
        _this.setState({
            categoryWidgets: findOptionByKeyValue(_this.state.allWidgets, DASHBOARDS.CATEGORY.MACHINE_NAME, lang.CATEGORIES.values[0]),
            activeTab: lang.CATEGORIES.titles[0]
        },function(){
            _this.setAddWidgetdDialogOpen(false);
        });
        
    }

    startCancel(callback){
        if (this.state.isEditing === true){
            this.setState({
                isEditing: false
            })
        }

        if(!this.state.isEditing){
            this.setAddWidgetdDialogOpen(true);
        }
        // this.onToggleBoard();
        if(typeof callback ===  "function"){
            callback();
        }
        this.resetInputs();
    }

    addWidgetdDialogContent = () => {
        return (
            <div className="uk-flex pi-vertical-tab uk-padding-large-top uk-padding-xsmall-bottom">
                <div className="uk-padding-large-right">
                    {/* <div className="text-grey fs-14 uk-margin-xsmall-bottom">{lang.CATEGORIES.categories_label}</div> */}
                    <Tabs id="dashboards_tabs" tabs={this.state.categories} onChange={this.changeCategory} activeTab={this.state.activeTab}></Tabs>
                </div>
                <div className="category-widgets-container">
                    <CategoryWidgets widgets={this.state.categoryWidgets} onAddWidget={this.addWidget} vectors={copyObjectValues(this.props.vectorState?.vectorOptions)} user={this.props.userSettingsState?.user} userAllowedMenuLinks={this.props.userAllowedMenuLinks}/>
                </div>
            </div>
        )
    }
    addWidgetdDialogActions = () => {
        return (
            <Button
                label={lang.modal.buttons.cancel}
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={this.startCancelWidget}
            />
        )
    }

    setAddWidgetdDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openAddWidgetDialog: isOpen,
        })
    }

    getLayout(data) {
        let layout = { items:[] };
        for (var e in data) {
            let height = window.innerHeight > 1400 ? data[e][DASHBOARDS.WIDGET.HEIGHT]*(this.props.userSettingsState?.dashboard_widget_height)/10*1.5: data[e][DASHBOARDS.WIDGET.HEIGHT]*(this.props.userSettingsState?.dashboard_widget_height)/10;
      
            let temp = {
                i: data[e][_widgetId]+"_"+data[e][DASHBOARDS.MACHINE_NAME], 
                w: data[e][DASHBOARDS.WIDGET.WIDTH] * (widgetWidthProportional ? 1 : this.props.userSettingsState?.dashboard_widget_width),
                h: height,
                x: data[e][DASHBOARDS.WIDGET.POSITION_X],
                y: data[e][DASHBOARDS.WIDGET.POSITION_Y],
                isResizable: false
            }
            this.addItemtoLayout(layout, temp);
        }
        return layout.items;
    }

    addItemtoLayout(layout, item) {
        let windowWidth = widgetWidthProportional ? this.props?.userSettingsState?.dashboard_grid_columns : (window.innerWidth || 2000);
        let yIndices = [0]; //hold the max height per layer
        let layer = 0; //incremented everytime we drop to a new row
        let layerWidth = 0; //hold the current last layer width

        layout.items.forEach(i => {
            if(layerWidth + i.w > windowWidth) { //if current layer width with current widget exceeds the window width then increment layer by 1
                layerWidth = 0;
                layer++;        
            } 
            layerWidth += i.w;
            yIndices[layer] = yIndices[layer] > i.h ? yIndices[layer] : i.h; //if current widget height is greater than current layer height, replace it
        });

        if(layerWidth + item.w > windowWidth) { //if current layer width with current widget exceeds the window width then increment layer by 1
            layerWidth = 0;
            layer++;        
        } 
        yIndices[layer] = yIndices[layer] > item.h ? yIndices[layer] : item.h; //if current widget height is greater than current layer height, replace it

        // Add a new item. It must have a unique key!
        layout.items.push({
            i: item.i,
            x: item.x !== undefined ? item.x : layerWidth,
            y: item.y !== undefined ? item.y : layer > 0 ? yIndices.reduce(function(a, b) { return a + b; }, 0) - yIndices[layer] : 0,
            w: item.w,
            h: item.h,
            isResizable: item.isResizable
        });
    }

    getProfileInvalidColumns=(profile)=>{
        let _this = this;
        if (profile.section_return_name === DASHBOARDS.WIDGET_TYPES.LIST) {
            return _this.manageColumnsRef.getProfileInvalidColumns(profile);
        }else{
            return _this.manageColumnsRefVect.getProfileInvalidColumns(profile);
        }
    }

    addVector = (vectors)=>{
        if (vectors ){
           if(vectors.filter(e=>e.value === INVOICE_LINE.value).length === 0) {
                vectors.push({
                    label:INVOICE_LINE.label,
                    value:INVOICE_LINE.value,
                    [VECTOR_STAGING_ATTRIBUTES.GROUP]:INVOICE_LINE.vector_group,
                    [VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER]:vectors.length
                });
                return vectors;
            }
        }
      return vectors;
    }

    checkLoadingWidgets=()=>{
        let _this = this;
        let widgetsLoadingPrevState = this.state.areWidgetsLoading;
        let areWidgetsLoading = this.state.widgetRefs.filter(e=>e.current?.state.isLoading)?.length>0
        if(widgetsLoadingPrevState !== areWidgetsLoading){
          widgetsLoadingPrevState = areWidgetsLoading;
            this.setState({
                areWidgetsLoading: areWidgetsLoading
            }, function(){
            })
          }
        updateWindowLoading(areWidgetsLoading, "skeleton-loader");

    }

    renderWidgets() {
        let _this = this;
        let dashboardWidgets = this.state.dashBoardWidgets;
        let displayedwidgets = [];
        let periodsObject = getPeriodsObjectNew(_this.props.periodsStatusState, _this.props.clientPeriodsState);
        let periods = periodsObject.periods;
        let quarter = periodsObject.quarter;
        let months = periodsObject.months;
        let lastSelectedPeriod = periods[periods.length - 1];
        let builtPeriods = _this.props.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
        let rollingPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
        let startQuarter ="";
        let endQuarter = "";
        let vectorOptions = copyObjectValues(this.props.vectorState.vectorOptions);
        if (_this.props.periodsStatusState.customStartDate) {
            startQuarter = getQuarterFromDate(_this.props.periodsStatusState.customStartDate);
            endQuarter = getGeneratedQuarterRange(_this.props.datasetState.datasetOptions,startQuarter,getQuarterFromDate(_this.props.periodsStatusState.customEndDate))[1] ; // End Quarter should be the last generated quarter
        }
        dashboardWidgets.filter(widget=>widget[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]).map((widget,index)=>{
            if ((widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC && _this.state.metricWidgetsData && _this.state.metricWidgetsData[widget[_widgetId]]) || ((_this.manageColumnsRef && _this.manageColumnsRef.state.profileColumns.length > 0 && _this.manageColumnsRefVect.state.profileColumns.length > 0 && _this.manageColumnsRef.state.originalColumnProfiles
                && _this.manageColumnsRef.state.originalColumnProfiles.length > 0 && _this.manageColumnsRefVect.state.originalColumnProfiles 
                &&_this.manageColumnsRefVect.state.originalColumnProfiles.length > 0) && (_this.manageColumnsRef.state.columnProfiles && _this.manageColumnsRef.state.columnProfiles.length > 0 && _this.manageColumnsRefVect.state.columnProfiles && 
                _this.manageColumnsRefVect.state.columnProfiles.length > 0))) {
                let config = tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]);
                _this.state.widgetRefs[index] = _this.state.widgetRefs[index] || React.createRef();
                displayedwidgets.push(
                  <div key={widget[_widgetId] + "_" + widget[DASHBOARDS.MACHINE_NAME]}>
                    <Widget
                      ref={_this.state.widgetRefs[index]}
                      index={index}
                      key={widget[_widgetId] + "_" + widget[DASHBOARDS.MACHINE_NAME] + "_" + index}
                      machine_name={_this.props.userSettingsState.machine_name}
                      headerRef={_this.headerRef}
                      widget={widget}
                      scenario={_this.props.scenarioState.scenario}
                      user={_this.props.userSettingsState.user}
                      vectorOptions={vectorOptions}
                      scenarioNumber={_this.props.scenarioState.scenarioNumber}
                      profiles={
                        copyObjectValues(_this.manageColumnsRef.state.originalColumnProfiles).length > 0
                          ? copyObjectValues(_this.manageColumnsRef.state.originalColumnProfiles)
                          : copyObjectValues(_this.manageColumnsRef.state.columnProfiles)
                      }
                      profilesVect={
                        copyObjectValues(_this.manageColumnsRefVect.state.originalColumnProfiles).length > 0
                          ? copyObjectValues(_this.manageColumnsRefVect.state.originalColumnProfiles)
                          : copyObjectValues(_this.manageColumnsRefVect.state.columnProfiles)
                      }
                      dbDataSet={_this.state.dbDataSet}
                      project_id={_this.state.project_id}
                      tablePrefix={_this.state.tablePrefix}
                      idToken={_this.state.idToken}
                      dataset={quarter}
                      months={months}
                      headerGoEnabled={_this.props.showGreyOverLay}
                      userAllowedMenuLinks={_this.props.userAllowedMenuLinks}
                      selectedDashboardId={_this.props.selectedDashboard[DASHBOARDS.ID]}
                      selectedPeriods={periods}
                      getProfileInvalidColumns={_this.getProfileInvalidColumns}
                      onEditWidget={_this.callOnEditwidget}
                      onDelete={_this.confirmDeleteWidget}
                      data={_this.state.metricWidgetsData ? _this.state.metricWidgetsData[widget[_widgetId]] : undefined}
                      selectedDashboard={_this.props.selectedDashboard}
                      uniqueKey={
                        periods.join(",") +
                        "_" +
                        quarter +
                        "_" +
                        quarter +
                        "_" +
                        _this.props.selectedDashboard[DASHBOARDS.ID] +
                        "_" +
                        widget[DASHBOARDS.FILTER] +
                        "_" +
                        (config && config[DASHBOARDS.CONFIG_FIELD.COMPARISON] ? config[DASHBOARDS.CONFIG_FIELD.COMPARISON] : "")
                      }
                      fetching={widget.loading || false}
                      match={_this.props.match}
                      location={_this.props.location}
                      history={_this.props.history}
                      periodOptions={_this.props.clientPeriodsState.periodOptions}
                      periods={_this.props.clientPeriodsState?.periods}
                      goToWidgetReport={_this.goToWidgetReport}
                      widgetHeight={widget[DASHBOARDS.WIDGET.HEIGHT] * _this.props.userSettingsState?.dashboard_widget_height}
                      profitStackViews={_this.props.PSViewsState?.profitStackViews}
                      startQuarter={startQuarter}
                      endQuarter={endQuarter}
                      customStartDate={_this.props.periodsStatusState.customStartDate}
                      customEndDate={_this.props.periodsStatusState.customEndDate}
                      vectorObjects={_this.props.vectorState.vectorObjects ? _this.props.vectorState.vectorObjects[0] || {} : {}}
                      datasetOptions={this.props.datasetState?.datasetOptions}
                      periodsObject={periodsObject}
                      bridgeOptions={this.props.bridgeOptionsState?.bridgeOptions}
                      getPeriodsObject={() => getPeriodsObjectNew(_this.props.periodsStatusState, _this.props.clientPeriodsState)}
                      dashboardFilter={_this.props.filterFinal}
                      checkLoadingWidgets={this.checkLoadingWidgets}
                      rollingPeriod={rollingPeriod}
                      vectorState={this.props.vectorState}
                      datasetState={this.props.datasetState}
                      scenarioState={this.props.scenarioState}
                      periodsStatusState={this.props.periodsStatusState}
                      clientPeriodsState={this.props.clientPeriodsState}
                      userSettingsState={this.props.userSettingsState} 
                      checkIfAtLeastOnePeriodIsGenerated={this.props?.checkIfAtLeastOnePeriodIsGenerated}
                    />
                  </div>
                );
            } else {
                displayedwidgets.push(
                    <div key={widget[_widgetId]+"_"+widget[DASHBOARDS.MACHINE_NAME]} style={{width:"1400px", height:"400px"}}>
                        <div className="dashboard-skeleton-container-height">
                                <SkeletonLoader />
                            </div>
                    </div>
                );
            }
        });
        return displayedwidgets;
    }

    onLayoutChange() {
        if(!this.state.orderModified) {
        this.setState({
        orderModified: true
        });
        }
    }

    saveWidgetsWithNewOrder() {
        const _this = this;
        let dashBoardWidgetsWithOrder = this.state.dashBoardWidgets;

        let currentLayout = this.gridLayout.state.layout;
        let layouts = currentLayout.sort((a, b) => a.y === b.y ? (a.x <= b.x ? -1 : 1) : (a.y < b.y ? -1 : 1));
        let widgetsToUpdate = layouts.map((item, index) => {
            const returnItem = {};
            returnItem[DASHBOARDS.WIDGET.ID] = parseInt(item.i.split("_")[0]);
            returnItem[DASHBOARDS.WIDGET.DISPLAY_ORDER] = index;
            returnItem[DASHBOARDS.WIDGET.POSITION_X] = item.x;
            returnItem[DASHBOARDS.WIDGET.POSITION_Y] = item.y;

            let currItem = dashBoardWidgetsWithOrder.filter(e=>e[DASHBOARDS.WIDGET.ID] === returnItem[DASHBOARDS.WIDGET.ID])[0];
            currItem[DASHBOARDS.WIDGET.POSITION_X] = item.x;
            currItem[DASHBOARDS.WIDGET.POSITION_Y] = item.y;
            return returnItem;
        });
        // console.log(widgetsToUpdate);

        let query = {
            action: "updateWidgetOrder",
            widgetsToUpdate: widgetsToUpdate
        }

        let onThenCallback = (data)=>{
            let messages = _this.state.messages;
            if(data.success) { 
                messages[TOAST] = lang.dashboards.messages.widget_order_updated_successfully;
                this.setState({
                    orderModified: false,
                    dashBoardWidgets: dashBoardWidgetsWithOrder,
                });
            } else {
                messages[TOAST] = lang.execution_error_two;
            }
            _this.launchToast();
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "updateWidgetOrder",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
        //TODO save order of widget will be done here based on the row order in the layout
    }

    renderConfigWidgetElements(){
        var _this = this;
        let widgetConfigElements = [];
        let widgetConfigElementsAppend = [];

        switch(this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]) {
            case DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC: {
                widgetConfigElements = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.VECTOR, DASHBOARDS.COUNT_TYPE, DASHBOARDS.CONFIG_FIELD.COMPARISON,DASHBOARDS.CHANGE_COLOR_LOGIC,DASHBOARDS.FILTER];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC: {
                widgetConfigElements = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.PROFIT_STACK_LINE, DASHBOARDS.COUNT_TYPE, DASHBOARDS.CONFIG_FIELD.COMPARISON,DASHBOARDS.CHANGE_COLOR_LOGIC, DASHBOARDS.FILTER];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS: {
                widgetConfigElements = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.CUSTOM_VIEW, DASHBOARDS.PROFIT_STACKS_TYPE, DASHBOARDS.PROFIT_STACK_LINE, DASHBOARDS.SIZE, DASHBOARDS.FILTER];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC: {
                widgetConfigElements = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.COLUMN, DASHBOARDS.COUNT_TYPE, DASHBOARDS.CONFIG_FIELD.COMPARISON,DASHBOARDS.CHANGE_COLOR_LOGIC, DASHBOARDS.FILTER];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS: {
                widgetConfigElements = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.VECTOR, DASHBOARDS.COMPARISON]
                widgetConfigElementsAppend = [DASHBOARDS.ORDER_BY_VECTOR_ANALYSIS, DASHBOARDS.SIZE, DASHBOARDS.FILTER]
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE: {
                widgetConfigElements = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.LINE_ATTRIBUTE,DASHBOARDS.CONFIG_FIELD.COMPARISON,DASHBOARDS.CHANGE_COLOR_LOGIC, DASHBOARDS.FILTER];
                break;
            }
            case DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT:{
                widgetConfigElements = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.COMPARISON, DASHBOARDS.BRIDGE, DASHBOARDS.VECTOR, DASHBOARDS.SIZE, DASHBOARDS.FILTER]
                break;
            }
            case ALL_WIDGETS.FIELDS.LIST:
            default:{
                widgetConfigElements = [DASHBOARDS.NAME, DASHBOARDS.DESCRIPTION, DASHBOARDS.LIST_TYPE, DASHBOARDS.VECTOR,];
                widgetConfigElementsAppend = [DASHBOARDS.ORDER_BY, DASHBOARDS.SIZE, DASHBOARDS.FILTER]
                break;
            }
        }

        let comps = widgetConfigElements ? widgetConfigElements.map((value)=> {
            return _this.renderConfigWidget(value);
        }) : [];
        let compsAppend =  widgetConfigElementsAppend ? widgetConfigElementsAppend.map((value)=>{
            return _this.renderConfigWidget(value);
        }) : [];
        let manageColsAccess = {
            [ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]: getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS),
            [ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS]: getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS),
            [ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS]: getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS),
            [ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]: getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS)
        }
        if (_this.props.userAllowedMenuLinks) {
            return(
                <div className="config_widget_container">
                    {comps}
                     <div className ={(![DASHBOARDS.WIDGET_TYPES.LIST].includes(this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]) ? " uk-hidden " : "uk-display-flex")}>
                        <div className="uk-margin-xmedium-bottom">
                                <ManageColumns ref={r=>this.manageColumnsRef = r} key={HEADER_ELEMENT.ADD_COLUMNS} scenarioId={this.props.scenarioState.scenario}
                                    user={this.props.userSettingsState.user} onSelectProfile={this.selectProfileList} onSelectDefaultProfile={this.props.onSelectManageColumnsDefaultProfile}
                                    manageColsAccess={manageColsAccess} hideDropdown={false} showLoader={this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]}
                                    isDashboards={true} vectorOptions={this.props.vectorState.vectorOptions} companyDefaultIsDefault={true}
                                    profitFormat={ALL_WIDGETS.FIELDS.LIST} constraint={"2"}
                                    isEditing={this.state.isEditing} selectedDashboard={this.props.selectedDashboard}  updateStateFromParent = {this.updateStateFromParent}
                                    menuWidth={330}
                                />
                        </div>
                        <span className="uk-text-medium uk-margin-small-left uk-margin-xsmall-top disabled">{this.state.profile ? this.state.profile[COLUMN_PROFILE.NAME] : ""}</span>
                    </div>
                    <div className ={(![DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS].includes(this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]) ? " uk-hidden " : "uk-display-flex")}>
                        <div className="uk-margin-xmedium-bottom">
                                <ManageColumns ref={r=>this.manageColumnsRefVect = r} key={HEADER_ELEMENT.ADD_COLUMNS} scenarioId={this.props.scenarioState.scenario}
                                    user={this.props.userSettingsState.user} onSelectProfile={this.selectProfileVectorAnalysis} onSelectDefaultProfile={this.props.onSelectManageColumnsDefaultProfile}
                                    manageColsAccess={manageColsAccess} hideDropdown={false} constraint={"1"}
                                    isDashboards={true} vectorOptions={this.props.vectorState.vectorOptions} companyDefaultIsDefault={true}
                                    profitFormat={MENU_ITEM.FIELDS.EROSION_AND_GROWTH} showLoader={this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE]}
                                    isEditing={this.state.isEditing} selectedDashboard={this.props.selectedDashboard}  updateStateFromParent = {this.updateStateFromParent}
                                />
                        </div>
                        <span className="uk-text-medium uk-margin-small-left uk-margin-xsmall-top disabled">{this.state.profileVectorAnalysis ? this.state.profileVectorAnalysis[COLUMN_PROFILE.NAME] : ""}</span>
                    </div>
                    {compsAppend}
                </div>
            );
        }
        }
        

    updateStateFromParent(tempState){
        let dashboardVisibility = this.props.selectedDashboard[_visibility];
        if(dashboardVisibility === DASHBOARDS.VISIBILITY_OPTIONS.COMPANY){
            let columns = copyObjectValues(tempState.columns); 
            tempState.columns = this.updateColumns(columns);
        }
        this.setState(tempState)
      
    }

    updateColumns(data){
        let newColumns = [];
        for (var e in data){
           let children = [];
           if(data[e][_treeChildren].filter(e=> e[_visibility] ===  DASHBOARDS.VISIBILITY_OPTIONS.COMPANY).length > 0){
                children = data[e][_treeChildren].filter(e=> e[_visibility] ===  DASHBOARDS.VISIBILITY_OPTIONS.COMPANY);
                delete data[e][_treeChildren];
                data[e][_treeChildren] = children
                newColumns.push(data[e]);
            }
        }
        return newColumns;
    }

    deleteWidgetdDialogContent = () => {
        return (
            <div className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall">
                <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                <div className="fs-16">{lang.dashboards.messages.confirm_delete_widget}</div>
            </div>
        )
    }
    deleteWidgetdDialogActions = () => {
        return (
            <>
                <Button
                    id="delete-widget"
                    label={lang.modal.buttons.delete}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.deleteWidget(this.state.widgetId)}
                />
                <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.setDeleteWidgetdDialogOpen(false)}
                />
            </>
        )
    }
    setDeleteWidgetdDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openDeleteWidgetDialog: isOpen,
        })
    }
    
    render() {
        let _this = this;
        $("#manage-columns-boardmanage_columns_sidepanel").on("click", function(e){
            let isMultiSelectionAllowed = _this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS && _this.state.currentWidget[DASHBOARDS.PROFIT_STACKS_TYPE] === _this.state.profitStackTypes[_this.state.profitStackTypes.length-1].value;
            if (isMultiSelectionAllowed && e.target !== undefined && e.target.parentNode !== undefined && e.target.parentNode.className !== undefined && typeof e.target.className === "string" && e.target.className.includes("dropdown-trigger")
                //e.target.parentNode.className !== "infinite-scroll-component " && !e.target.parentNode.className.includes("node tree") && !e.target.className.includes("checkbox-item")
                ) {
                _this.setState({
                    showDropdown: _this.state.showDropdown === "always"  ? false : 'always'
                },()=>{
                    e.stopImmediatePropagation();
                })
            }
        })

        let renderWidgets = this.renderWidgets();
        let data = _this.props.selectedDashboard && this.state.dashBoardWidgets ? this.state.dashBoardWidgets.filter(e=>e[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]) : [];
        let layout = this.getLayout(data);   
        let cols = widgetWidthProportional ? this.props?.userSettingsState?.dashboard_grid_columns : $("#main_report_container").width();
        let width = $("#main_report_container").width();
        let gridDefaultProps = {
            className: "layout",
            cols: cols,
            rowHeight: 1,
            width:width,
            compactType: null,
            // verticalCompact: false,
            draggableHandle: ".grabbable-header",
            // resizeHandles: ['s','w','e','n','sw','nw','se','ne'],
            preventCollision: true            
        }; 
        if (this.state.displayPanel) {
            $("#overlay-dashboards").off("click");
            $("#overlay-dashboards").on("click", function(){
                _this.startCancel();
            });
        }
        let isManageDashboardsAllowed = (getSectionExists(_this.props.userAllowedMenuLinks,MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS) && _this.props.selectedDashboard[_visibility] === _visibilityOptions.USER) || 
                                        (getSectionExists(_this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS) && _this.props.selectedDashboard[_visibility] === _visibilityOptions.COMPANY
                                        && (!_this.props.selectedDashboard[_isCompanyDefault] || (_this.props.userSettingsState?.user && parseBoolean(_this.props.userSettingsState.user[_isSystem]))));
        
        let isOldManageDashboardsAllowed = (getSectionExists(_this.props.userAllowedMenuLinks,ALL_WIDGETS.MANAGE_USER_DASHBOARDS) && _this.props.selectedDashboard[_visibility] === _visibilityOptions.USER) || 
        (getSectionExists(_this.props.userAllowedMenuLinks,ALL_WIDGETS.MANAGE_COMPANY_DASHBOARDS) && _this.props.selectedDashboard[_visibility] === _visibilityOptions.COMPANY
        && (!_this.props.selectedDashboard[_isCompanyDefault] || (_this.props.userSettingsState?.user && parseBoolean(_this.props.userSettingsState.user[_isSystem]))));

        isManageDashboardsAllowed = isManageDashboardsAllowed || isOldManageDashboardsAllowed;

        let headerOffsetHeight = document.getElementsByClassName('header').length > 0 ? document.getElementsByClassName('header')[0].offsetHeight : "";
        let buttons = [];
        buttons.push(
            {button:lang.modal.buttons.save, isSubmit: true, isDisabled :false},
            {button:lang.modal.buttons.cancel, isSubmit: false, isDisabled :false, onClick:this.startCancel}
        );
        let backdrop = "";
        if (this.state.drawerOpen) {
            backdrop = <Backdrop close={this.backdropClickHandler} />;
        }
        return (
            <div className="dashboard-container">
                {/* <SessionTimeout ref={r=>this.sessionTimeoutRef = r} isAuthenticated={this.props.userSettingsState?.user && this.props.userSettingsState?.machine_name !== ""} logout={this.logout} session_timeout_ui={this.state.session_timeout_ui} session_timeout_api={this.state.session_timeout_api}/> */}
                <Popup/>
                <div id="toastDashboard" className="toast toast-success">
                    <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (this.state.isError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i>{this.state.messages[TOAST]}</div>
                </div>
                <div className="uk-hidden">{_this.state.key}</div>
                <div className="index">
                    {/* {this.state.displayPanel ? 
                        <div id="overlay-dashboards"></div>
                    :""} */}
                    {/* <SidePanel ref={r=>this.manageDashboardsOverlay=r} onToggleBoard={this.onToggleBoard} onCancel={()=>this.startCancel()} title={this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TITLE]} 
                        body={this.renderConfigWidgetElements()} onSave={this.startSaveWidget}/> */}
                    <SidePanelNew 
                      id="manage-columns-boardmanage_columns_sidepanel"
                      body={this.renderConfigWidgetElements()} 
                      buttons={buttons} 
                      show={this.state.drawerOpen} 
                      close={(fromSave) => this.backdropClickHandler(fromSave)} 
                      title={this.state.currentWidget[DASHBOARDS.WIDGET.WIDGET_TITLE]} 
                      onSubmit={this.startSaveWidget} 
                    />
                    {backdrop}
                </div>  
                {_this.state.dashBoardWidgets //&& _this.state.dashBoardWidgets.length > 0 &&  _this.state.dashBoardWidgets.filter(e=>e[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID])
                        && _this.state.dashBoardWidgets.filter(e=>e[DASHBOARDS.ID] === _this.props.selectedDashboard[DASHBOARDS.ID]).length === 0 ?    
                    <div className="add-widget-container">
                        {isManageDashboardsAllowed ? <div className="overlay-text text-link text-black" onClick={() => this.setAddWidgetdDialogOpen(true)}>{lang.dashboards.add_widget}</div> : "" }
                    </div>
                : ""}    
                <div>
                
                </div>
                <div>
                    {this.state.orderModified === true && isManageDashboardsAllowed ? 
                        <div style={{top:-10}} className="sticky-banner uk-flex uk-flex-middle uk-padding-xsmall-top-bottom uk-padding-large-left uk-padding-large-right pi-background-light-yellow">
                            <i className="fa-lg fas fa-exclamation-circle uk-margin-small-right pi-text-yellow" aria-hidden="true"></i>
                            <span className="fs-14">{lang.dashboards.messages.widget_order_update_confirmation}</span>
                            <button className="uk-margin-default-left text-link" onClick={()=> this.saveWidgetsWithNewOrder()}>{lang.navigation.btns.save}</button>
                        </div>
                        : ""
                    }

                    {this.state.dashBoardWidgets && this.state.dashBoardWidgets.length > 0 && renderWidgets.length > 0? 
                        <GridLayout ref={r => this.gridLayout = r} {...gridDefaultProps} layout={layout} onDragStop={this.onLayoutChange} isBounded={false} width={$("#main_report_container").width()}>
                            {renderWidgets}
                        </GridLayout>
                        // <ResponsiveGridLayout {...gridDefaultProps} layout={layout} onLayoutChange={this.onLayoutChange}
                        // breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                        // cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
                        // {renderWidgets}
                        // </ResponsiveGridLayout>
                        
                    : ""}      
                </div>     
                
                <Modal
                    id={"add-widget-dialog"}
                    title={lang.dashboards.add_a_widget}
                    openDialog={this.state.openAddWidgetDialog}
                    bodyContent={this.addWidgetdDialogContent}
                    dialogActions={this.addWidgetdDialogActions}
                    closeClick={() => this.setAddWidgetdDialogOpen(false)}
                    size={DIALOG_SIZE.XLARGE}
                />
                <Modal
                    id={"delete-widget-confirmation-dialog"}
                    title={lang.modal.buttons.delete_widget}
                    openDialog={this.state.openDeleteWidgetDialog}
                    bodyContent={this.deleteWidgetdDialogContent}
                    dialogActions={this.deleteWidgetdDialogActions}
                    closeClick={() => this.setDeleteWidgetdDialogOpen(false)}
                    size={DIALOG_SIZE.MEDIUM}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userSettings: state.userSettings,
        datasetOptions: state.datasetOptions,
        tiers: state.tiers,
        scenarios: state.scenarios,
        periodsStatus: state.periodsStatus,
        userSections: state.userSections,
        psLinesOptions: state.psLinesOptions,
        quadrantTierOptions:state.quadrantTierOptions,
        profitFormats:state.profitFormats,
    };
}
export default connect(mapStateToProps,null,null,{forwardRef: true})(Dashboards); 
